import React from 'react';

import { CommonStatusTag } from 'components/CommonStatusTag';

import { SupplyOrderState } from 'data/__generated__';
import { SUPPLY_ORDER_STATES_LABELS, getColorsFromState } from '../constants/states';

export const StatusTag = ({ state, showHeading }: { state: SupplyOrderState; showHeading?: boolean }) => {
    const { backgroundColor, labelColor } = getColorsFromState(state);
    return (
        <CommonStatusTag
            backgroundColor={backgroundColor}
            labelColor={labelColor}
            label={SUPPLY_ORDER_STATES_LABELS[state]}
            showHeading={showHeading}
        />
    );
};

import React, { useContext } from 'react';

import styled, { ThemeContext } from 'styled-components';
import { AiFillCloseCircle, AiFillWarning } from 'react-icons/ai';

import { ProductFlow } from 'data/__generated__';

import {
    MicrostoreColumnFormValues,
    microstoreColumnSomeLocationHelper,
    MicrostoreLocationExpiryDateFormValues,
    MicrostoreLocationFormValues,
    MicrostoreProductsStockInfoFormValues,
    MicrostoreShelfFormValues,
} from './FormHelper/DataMapper';
import { computeColorFromName } from 'helpers/computeColorFromName';
import { dateFromString, formatDateAsAnniversary } from 'helpers/dateTimes';
import { Spinner } from 'components/Spinner';

function getExpiryDateDisplay(expiryDates: MicrostoreLocationExpiryDateFormValues[], theme: any) {
    if (!expiryDates.length) {
        return {
            label: 'No DLC',
            color: 'inherit',
            icon: null,
        };
    }
    const sortedExpiryDates = [...expiryDates].sort((expiryDateA, expiryDateB) =>
        dateFromString(expiryDateA.expiryDate as string) < dateFromString(expiryDateB.expiryDate as string) ? -1 : 1,
    );

    const label = sortedExpiryDates[0].expiryDate + (sortedExpiryDates.length > 1 ? '...' : '');

    const endOfToday = new Date();
    endOfToday.setHours(23, 59, 59);
    const endOfYesterday = new Date();
    endOfYesterday.setDate(endOfYesterday.getDate() - 1);
    endOfYesterday.setHours(23, 59, 59);
    if (expiryDates.some((expiryDate) => dateFromString(expiryDate.expiryDate) < endOfYesterday)) {
        const hasCorrectExpiryDates = expiryDates.some(
            (expiryDate) => dateFromString(expiryDate.expiryDate) >= endOfYesterday,
        );
        return {
            label,
            color: theme.errorColor,
            icon: hasCorrectExpiryDates ? (
                <AiFillWarning size={15} color={theme.errorColor} />
            ) : (
                <AiFillCloseCircle size={15} color={theme.errorColor} />
            ),
        };
    }

    if (expiryDates.some((expiryDate) => formatDateAsAnniversary({ dateTime: endOfToday }) === expiryDate.expiryDate)) {
        const hasFutureExpiryDates = expiryDates.some(
            (expiryDate) => dateFromString(expiryDate.expiryDate) > endOfToday,
        );
        return {
            label,
            color: theme.warningColor,
            icon: hasFutureExpiryDates ? (
                <AiFillWarning size={15} color={theme.warningColor} />
            ) : (
                <AiFillCloseCircle size={15} color={theme.warningColor} />
            ),
        };
    }

    return {
        label,
        color: 'inherit',
        icon: null,
    };
}

function getLocationBackgroundColor({
    isRelocated,
    flow,
    productId,
    replacementProductId,
    trackExpiryDate,
}: {
    flow: ProductFlow | undefined;
    isRelocated: boolean;
    productId: string | null;
    replacementProductId: string | null;
    trackExpiryDate: boolean;
}) {
    if (productId === null) {
        return 'inherit';
    }

    // we don't use Colors constants because we don't want them to change (the colors are important).
    if (isRelocated) {
        return '#fffb00c8';
    }

    if (trackExpiryDate && flow !== ProductFlow.Fresh) {
        return '#e181ff60';
    }

    if (productId === replacementProductId) {
        return 'inherit';
    }

    return '#ff001157';
}

export const TodayShelf = ({
    allSiteLocations,
    canBeUpdated = true,
    hideTodayInfo,
    isToday,
    microstoreProductsStockInfo,
    getMicrostoreProductsStockInfoLoading,
    selectedColumn,
    selectedLocationPosition,
    selectedShelfIndex,
    setIsToday,
    setSelectedLocationPosition,
    setSelectedShelfIndex,
    shelf,
    shelfIndex,
    switchColumn,
}: {
    allSiteLocations: MicrostoreLocationFormValues[];
    canBeUpdated?: boolean;
    hideTodayInfo?: boolean;
    isToday: boolean;
    microstoreProductsStockInfo: MicrostoreProductsStockInfoFormValues | null;
    getMicrostoreProductsStockInfoLoading?: boolean;
    selectedColumn: MicrostoreColumnFormValues;
    selectedLocationPosition: { column: number; row: number } | null;
    selectedShelfIndex: number | null;
    setIsToday: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedLocationPosition: React.Dispatch<React.SetStateAction<{ column: number; row: number } | null>>;
    setSelectedShelfIndex: React.Dispatch<React.SetStateAction<number | null>>;
    shelf: MicrostoreShelfFormValues;
    shelfIndex: number;
    switchColumn: MicrostoreColumnFormValues | null;
}) => {
    const theme = useContext(ThemeContext);

    return (
        <Shelf>
            <ShelfHeader>J</ShelfHeader>
            <ShelfBody>
                {shelf.locationTable.map((locationRow, locationRowIndex) => {
                    return (
                        <LocationRow key={locationRowIndex}>
                            {locationRow.map((location, locationColumnIndex) => {
                                if (hideTodayInfo) {
                                    return (
                                        <EmptyLocation
                                            key={locationColumnIndex}
                                            canSelectLocation={canBeUpdated}
                                            isSelected={false}
                                            backgroundColor="inherit"
                                        />
                                    );
                                }

                                const {
                                    _id: locationId,
                                    expiryDates,
                                    microstoreLocationTemplate: {
                                        productArrangement,
                                        productArrangementSingleProductInfo,
                                    },
                                    productId,
                                    replacementProductId,
                                } = location;

                                const locationProduct = microstoreProductsStockInfo?.find(
                                    ({ _id }) => _id === productId,
                                );

                                const {
                                    label: expiryDateLabel,
                                    color: expiryDateColor,
                                    icon: expiryDateIcon,
                                } = getExpiryDateDisplay(expiryDates, theme);

                                const productArrangementColor = productArrangement
                                    ? computeColorFromName(productArrangement.name)
                                    : theme.textColor;

                                const isPresentTomorrow = microstoreColumnSomeLocationHelper({
                                    microstoreColumnFormValues: selectedColumn,
                                    someFunction: (otherLocation) => productId === otherLocation.replacementProductId,
                                });
                                const isPresentInSwitchTomorrow =
                                    !!switchColumn &&
                                    microstoreColumnSomeLocationHelper({
                                        microstoreColumnFormValues: switchColumn,
                                        someFunction: (otherLocation) =>
                                            productId === otherLocation.replacementProductId,
                                    });

                                const isRelocated =
                                    productId !== replacementProductId &&
                                    (isPresentTomorrow || isPresentInSwitchTomorrow);

                                const locationBackgroundColor = getLocationBackgroundColor({
                                    isRelocated,
                                    flow: locationProduct?.flow,
                                    productId: productId || null,
                                    replacementProductId: replacementProductId || null,
                                    trackExpiryDate: !!locationProduct?.trackExpiryDate,
                                });

                                const productStockInSite = locationProduct?.stockOnSite ?? 0;
                                const locationsWithSameProduct = allSiteLocations.filter(
                                    ({ productId: otherLocationProductId }) => otherLocationProductId === productId,
                                );
                                const locationIndexInLocationsWithSameProduct = locationsWithSameProduct.findIndex(
                                    ({ _id }) => _id === locationId,
                                );

                                // to distribute evenly the product stock between the different location, each location will have at least the quotient of the euclidian division
                                // then we distribute the remainder to the first locations.
                                // Ex : if I have 8 products in stock, and 3 locations. Then each location will have at least 2 (8/3) product.
                                //      the remainder is 2, then the 2 first location will have one more product.
                                //      the stocks in the locations will be : 3, 3, 2
                                const productStockInLocation =
                                    Math.floor(productStockInSite / (locationsWithSameProduct.length || 1)) +
                                    (locationIndexInLocationsWithSameProduct <
                                    productStockInSite % locationsWithSameProduct.length
                                        ? 1
                                        : 0);

                                return (
                                    <Location
                                        key={locationColumnIndex}
                                        canSelectLocation={canBeUpdated}
                                        isSelected={
                                            canBeUpdated &&
                                            shelfIndex === selectedShelfIndex &&
                                            locationColumnIndex === selectedLocationPosition?.column &&
                                            locationRowIndex === selectedLocationPosition?.row &&
                                            isToday
                                        }
                                        onClick={(event) => {
                                            if (!canBeUpdated) {
                                                return;
                                            }
                                            event.stopPropagation();
                                            setSelectedShelfIndex(shelfIndex);
                                            setSelectedLocationPosition({
                                                column: locationColumnIndex,
                                                row: locationRowIndex,
                                            });
                                            setIsToday(true);
                                        }}
                                        backgroundColor={locationBackgroundColor}
                                    >
                                        <>
                                            {productArrangement ? (
                                                <LocationProductArrangementName>
                                                    <LocationProductArrangementColor color={productArrangementColor} />
                                                    {productArrangement.name}
                                                    <LocationProductArrangementColor color={productArrangementColor} />
                                                </LocationProductArrangementName>
                                            ) : null}
                                            {productArrangementSingleProductInfo ? (
                                                <LocationProductArrangementName>
                                                    <LocationProductArrangementColor color={productArrangementColor} />
                                                    {productArrangementSingleProductInfo.product.name}
                                                    <LocationProductArrangementColor color={productArrangementColor} />
                                                </LocationProductArrangementName>
                                            ) : null}
                                            {getMicrostoreProductsStockInfoLoading ? <Spinner size={20} /> : null}
                                            {locationProduct ? (
                                                <>
                                                    <LocationProductName>{locationProduct.name}</LocationProductName>

                                                    {/* web section */}
                                                    <LocationProductInfoRow hideOnPrint={true}>
                                                        {expiryDateIcon}
                                                    </LocationProductInfoRow>
                                                    <LocationProductInfoRow hideOnPrint={true}>
                                                        <LocationProductInfo color={expiryDateColor}>
                                                            {expiryDateLabel}
                                                        </LocationProductInfo>
                                                    </LocationProductInfoRow>
                                                    <LocationProductInfoRow hideOnPrint={true}>
                                                        <LocationProductInfo
                                                            color={
                                                                productStockInLocation ? 'inherit' : theme.errorColor
                                                            }
                                                        >
                                                            S:&nbsp;
                                                            {microstoreProductsStockInfo
                                                                ? productStockInLocation
                                                                : 'Loading'}
                                                        </LocationProductInfo>
                                                        <LocationProductInfo> | </LocationProductInfo>
                                                        <LocationProductInfo>
                                                            V:&nbsp;{locationProduct.transactionsCountForToday}/
                                                            {locationProduct.transactionsCountForToday +
                                                                locationProduct.stockOnSite}
                                                        </LocationProductInfo>
                                                    </LocationProductInfoRow>

                                                    {/* printer section */}
                                                    <LocationProductInfoRow hideOnWeb={true}>
                                                        {expiryDateIcon}
                                                        <LocationProductInfo color={expiryDateColor}>
                                                            {expiryDateLabel}
                                                        </LocationProductInfo>
                                                        <LocationProductInfo> | </LocationProductInfo>
                                                        <LocationProductInfo
                                                            color={
                                                                productStockInLocation ? 'inherit' : theme.errorColor
                                                            }
                                                        >
                                                            S:&nbsp;{productStockInLocation}
                                                        </LocationProductInfo>
                                                    </LocationProductInfoRow>
                                                </>
                                            ) : null}
                                        </>
                                    </Location>
                                );
                            })}
                        </LocationRow>
                    );
                })}
            </ShelfBody>
        </Shelf>
    );
};

const Shelf = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    flex: 1 0 0;
    background-color: ${({ theme }) => theme.cardBackgroundColor};
`;

const ShelfHeader = styled.div`
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    flex-shrink: 0;
    border-right: 1px solid ${({ theme }) => theme.lightBorderColor};

    @media print {
        font-size: 17px;
    }
`;

const ShelfBody = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    border-radius: ${({ theme }) => theme.borderRadius};
    padding: 2px;

    & > :not(:first-child) {
        margin-top: 2px;
    }

    @media print {
        & > :not(:first-child) {
            margin-top: 1px;
        }
    }
`;

const LocationRow = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1 0 0;

    & > :not(:first-child) {
        margin-left: 2px;
    }

    @media print {
        & > :not(:first-child) {
            margin-left: 1px;
        }
    }
`;

const Location = styled.div<{ canSelectLocation: boolean; isSelected: boolean; backgroundColor?: string }>`
    cursor: ${({ canSelectLocation }) => (canSelectLocation ? 'pointer' : 'not-allowed')};
    flex: 1 0 0;
    border: 1px solid ${({ theme, isSelected }) => (isSelected ? theme.ctaPrimaryColor : theme.lightBorderColor)};
    box-shadow: ${({ theme, isSelected }) => (isSelected ? theme.boxShadow : 'none')};
    background: ${({ backgroundColor }) => backgroundColor ?? 'initial'};
    border-radius: ${({ theme }) => theme.borderRadius};
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    & > :not(:first-child) {
        margin-top: 5px;
    }

    @media print {
        padding: 1px;

        & > :not(:first-child) {
            margin-top: 1px;
        }
    }
`;

const EmptyLocation = styled(Location)`
    min-height: 25px;
`;

const LocationProductArrangementName = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    font-size: 12px;
    width: 100%;

    @media print {
        display: none;
    }
`;

const LocationProductArrangementColor = styled.div`
    min-width: 5px;
    min-height: 5px;
    background-color: ${({ color }) => color};

    @media print {
        display: none;
    }
`;

const LocationProductName = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-grow: 1;
    font-size: 13px;

    @media print {
        font-size: 9px;
    }
`;

const LocationProductInfoRow = styled.div<{ hideOnPrint?: boolean; hideOnWeb?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    display: ${({ hideOnWeb }) => (hideOnWeb ? 'none' : 'initial')};

    & > :not(:first-child) {
        margin-left: 2px;
    }

    @media print {
        display: ${({ hideOnPrint }) => (hideOnPrint ? 'none' : 'initial')};
    }
`;

const LocationProductInfo = styled.span<{ hideOnPrint?: boolean }>`
    text-align: center;
    font-size: 13px;
    color: ${({ color }) => color ?? 'inherit'};

    @media print {
        display: ${({ hideOnPrint }) => (hideOnPrint ? 'none' : 'initial')};
        font-size: 9px;
    }
`;

import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';

import { UPDATE_PRODUCT_STATE, UPDATE_PRODUCT_STATEVariables } from 'data/mutations/__generated__/UPDATE_PRODUCT_STATE';
import { UPDATE_PRODUCT_STATE_MUTATION } from 'data/mutations/product';
import { GET_PRODUCT_STOCK_QUERY } from 'data/queries/product';
import { GET_PRODUCT_STOCK, GET_PRODUCT_STOCKVariables } from 'data/queries/__generated__/GET_PRODUCT_STOCK';

import { SectionColumn, SectionContainer, ColumnsSectionContainer } from 'components/DetailsView/Section';
import { StateHistory } from 'components/DetailsView/StateHistory';
import { StatusTag } from '../components/StatusTag';
import { Loader, LoaderModeType } from 'components/Loader';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { GetDetailedProductQuery, ProductState } from 'data/__generated__';
import { StatusSection } from 'pages/Products/ProductDetails/components/StatusSection';
import { FcQuestions } from 'react-icons/fc';

export const StatesSection = ({
    product,
    isDirty,
}: {
    product: NonNullable<GetDetailedProductQuery['detailedProduct']>;
    isDirty: boolean;
}) => {
    const { _id: productId, state, stateHistory } = product;
    const [resetFields, setResetFields] = useState(false);
    const { setValue } = useFormContext();

    useEffect(() => {
        if (resetFields) {
            setValue('isBeingTested', false);
            setValue('isToBeArchived', false);
            setValue('supplierShortageInfo.inShortage', false);
            setValue('supplierShortageInfo.returnDate', null);
            setValue('newUntil', null);
            setResetFields(false);
        }
    }, [resetFields]);

    const [updateProductState, { loading: loadingStateUpdate }] = useMutation<
        UPDATE_PRODUCT_STATE,
        UPDATE_PRODUCT_STATEVariables
    >(UPDATE_PRODUCT_STATE_MUTATION);

    const handleStateUpdate = async (stateToSet: ProductState) => {
        const { data } = await updateProductState({
            variables: {
                stateToSet,
                productId,
            },
        });
        if (data) {
            const {
                updateProductStateResolver: { success, error, formErrors, product },
            } = data;

            if (success && product) {
                toast.success('Le statut du produit a bien été modifié !');
                if (stateToSet === ProductState.Archived) {
                    setResetFields(true);
                }
            } else if (formErrors?.length) {
                formErrors.forEach(({ sectionName, sectionErrors }) => {
                    toast.error(
                        <span>
                            Erreur dans la section "{sectionName}" :
                            {sectionErrors.map(({ fieldName, fieldError }, index) => (
                                <span key={index}>
                                    <br />- {fieldName} : "{fieldError}"
                                </span>
                            ))}
                        </span>,
                        { autoClose: false },
                    );
                });

                if (formErrors.length > 1) {
                    toast.info('Cliquez pour fermer toutes les notifications', {
                        autoClose: false,
                        onClick: () => toast.dismiss(),
                    });
                }
            } else if (error) {
                toast.error(error, { autoClose: false });
            } else {
                throw Error("Une erreur inconnue s'est produite");
            }
        } else {
            throw Error("Une erreur inconnue s'est produite");
        }
    };

    const handleStockData = (data: GET_PRODUCT_STOCK): void => {
        if (data) {
            const { productStock } = data;
            if (productStock) {
                const { error, success, sitesWithStock } = productStock;
                if (success && sitesWithStock) {
                    if (sitesWithStock?.length) {
                        const totalStock = sitesWithStock.reduce<number>(
                            (acc, { stock }: { stock: number }) => (acc += stock),
                            0,
                        );

                        const toastInfo: JSX.Element = (
                            <>
                                <div>
                                    Produit ayant encore du stock ne peut pas être archivé. Stock total restant -{' '}
                                    <b>{totalStock}</b>:
                                </div>
                                {sitesWithStock.map(({ name, stock }, index) => (
                                    <div key={index}>
                                        •{name}: <b>{stock}</b>
                                    </div>
                                ))}
                                <div>Vous pouvez mettre l'option 'À sortir' pour épuiser le stock automatiquement</div>
                            </>
                        );

                        toast.info(toastInfo, {
                            autoClose: false,
                        });
                    } else {
                        handleStateUpdate(ProductState.Archived);
                    }
                    return;
                } else if (!success && error) {
                    toast.error(error);
                }
            }
        }
        toast.error('Une erreur inconnue est survenue lors de la récupération du stock du produit');
    };

    const [getProductStock, { loading: loadingStock }] = useLazyQuery<GET_PRODUCT_STOCK, GET_PRODUCT_STOCKVariables>(
        GET_PRODUCT_STOCK_QUERY,
        {
            fetchPolicy: 'network-only',
            onError: () => toast.error('Une erreur inconnue est survenue lors de la récupération du stock du produit'),
            onCompleted: handleStockData,
        },
    );

    const formattedStateHistory = stateHistory.map((stateRecord) => ({
        ...stateRecord,
        state: <StatusTag state={stateRecord.state} />,
    }));

    const handleStateUpdateWithStockCheck = async (stateToSet: ProductState) => {
        if (stateToSet === ProductState.Archived) {
            await getProductStock({
                variables: {
                    productId,
                },
            });
        } else {
            await handleStateUpdate(stateToSet);
        }
    };

    const STATE_CHANGE_ACTIONS = [
        {
            label: 'Passer produit en statut référencé (Créé)',
            stateToSet: ProductState.Created,
            isDisabled: state === ProductState.Created,
        },
        {
            label: 'Passer produit en statut régulier',
            stateToSet: ProductState.Regular,
            isDisabled: state === ProductState.Regular,
        },
        {
            label: 'Archiver le produit',
            stateToSet: ProductState.Archived,
            isDisabled: state === ProductState.Archived,
        },
    ];

    const loading = loadingStateUpdate || loadingStock;

    return (
        <SectionContainer title="Gestion des statuts">
            <ColumnsSectionContainer numberOfColumns={3}>
                <SectionColumn>
                    <StatusTag state={state} showHeading />
                    <StateHistory records={formattedStateHistory} />
                </SectionColumn>
                <SectionColumn>
                    <StatusSection product={product} />
                </SectionColumn>
                {loading ? (
                    <LoaderContainer>
                        <Loader mode={LoaderModeType.Spin} />
                    </LoaderContainer>
                ) : (
                    <SectionColumn>
                        <>
                            {isDirty ? (
                                <WarningText>
                                    ⚠️ Veuillez vous assurer d'avoir sauvegardé ou annulé vos ajustements avant de
                                    mettre à jour le statut du produit
                                </WarningText>
                            ) : null}
                            <OverlaySectionColumn isDirty={isDirty}>
                                {STATE_CHANGE_ACTIONS.map((stateChange, index) => {
                                    const { label, stateToSet, isDisabled } = stateChange;

                                    function updateState(e: React.MouseEvent<HTMLElement, MouseEvent>) {
                                        e.preventDefault();
                                        const hasConfirmed = window.confirm(
                                            `Êtes-vous sûr.e de vouloir ${label.toLowerCase()} ?`,
                                        );
                                        if (hasConfirmed) {
                                            handleStateUpdateWithStockCheck(stateToSet);
                                        }
                                    }

                                    return (
                                        <TotemPrimaryButton
                                            key={index}
                                            onClick={updateState}
                                            disabled={isDisabled || isDirty}
                                        >
                                            {label}
                                        </TotemPrimaryButton>
                                    );
                                })}
                            </OverlaySectionColumn>
                            <Link
                                href="https://www.notion.so/thetotem/Les-r-f-rences-produits-Products-b4364c0ab3ca4bdabdaac1fcbe3b2865"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FcQuestions size={24} />
                                Comprendre les statuts de produit
                            </Link>
                        </>
                    </SectionColumn>
                )}
            </ColumnsSectionContainer>
        </SectionContainer>
    );
};

const Link = styled.a`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.ctaPrimaryColor};
`;

const WarningText = styled.div`
    color: ${({ theme }) => theme.textColor};
    font-weight: 800;
`;

const OverlaySectionColumn = styled(SectionColumn)`
    opacity: ${({ isDirty }: { isDirty: boolean }) => (isDirty ? 0.4 : 1)};
`;

const LoaderContainer = styled.div`
    flex: 1;
    justify-content: center;
`;

import { DELIVERY_FRAGMENT } from 'data/fragments/delivery';
import { SITE_SETUP_FRAGMENT } from 'data/fragments/siteSetup';
import gql from 'graphql-tag';

export const GET_DELIVERIES_QUERY = gql`
    query GET_DELIVERIES($searchDate: String) {
        deliveries(searchDate: $searchDate) {
            ...DeliveryFragment
        }
    }
    ${DELIVERY_FRAGMENT}
`;

export const GET_DAILY_DELIVERIES_WITH_PHOTOS_QUERY = gql`
    query GET_DAILY_DELIVERIES_WITH_PHOTOS($searchDate: String!) {
        deliveries(searchDate: $searchDate) {
            ...DeliveryFragment
            photos {
                type
                urls
            }
            siteSetup {
                ...SiteSetupFragment
            }
        }
    }
    ${DELIVERY_FRAGMENT}
    ${SITE_SETUP_FRAGMENT}
`;

export const GET_DELIVERY_QUERY = gql`
    query GET_DELIVERY($deliveryId: ID!) {
        delivery(deliveryId: $deliveryId) {
            _id
            label
            boxes {
                _id
                createdAt
                code
                label
                number
                preparedAt
                products {
                    _id
                    boxId
                    location
                    photoUrl
                    productLabel
                    quantity
                    quantityPicked
                }
                type
                volumeTaken
                weightTaken
            }
            createdAt
            deliveryDate
            orders {
                _id
                number
                user {
                    profile {
                        fullname
                    }
                }
            }
            organizationName
            photos {
                type
                urls
            }
            roundColor
            roundName
            serviceTime
            siteId
            siteSetup {
                ...SiteSetupFragment
            }
            state
            stateHistory {
                createdAt
                createdByName
                state
            }
            theoricServiceTime
            times {
                preferenceSlot {
                    start
                    end
                }
                actualSlot {
                    start
                    end
                }
            }
            totem {
                address
                number
            }
            trackingUrl
            updatedAt
        }
    }
    ${SITE_SETUP_FRAGMENT}
`;

export const GET_SITE_DELIVERY_OF_DATE_QUERY = gql`
    query GET_SITE_DELIVERY_OF_DATE($deliveryDateFormatted: String!, $siteId: String!) {
        getSiteDeliveryOfDate(deliveryDateFormatted: $deliveryDateFormatted, siteId: $siteId) {
            ...DeliveryFragment
            boxes {
                _id
                orderId
                products {
                    _id
                    boxId
                    quantity
                    quantityPicked
                }
                siteId
            }
        }
    }
    ${DELIVERY_FRAGMENT}
`;

import React from 'react';
import styled from 'styled-components';

import { WarehouseReturnFragment } from 'data/fragments/__generated__/WarehouseReturnFragment';
import { GET_MICROSTORE_PRODUCTS_getMicrostoreProducts } from 'data/queries/__generated__/GET_MICROSTORE_PRODUCTS';

import { SectionContainer } from 'components/DetailsView/Section';
import { ProductDetail } from './components/ProductDetail';

export const ProductsSection = ({
    products,
    warehouseReturn,
}: {
    products: GET_MICROSTORE_PRODUCTS_getMicrostoreProducts[];
    warehouseReturn: WarehouseReturnFragment;
}) => {
    return (
        <SectionContainer title="Détail du retour" isInitiallyOpen>
            <>
                <SectionText>Produits retour en stock</SectionText>
                {warehouseReturn.returnedProducts.map((warehouseReturnProduct) => {
                    const product = products.find(({ _id }) => _id === warehouseReturnProduct.productId);
                    return (
                        <ProductDetail
                            key={warehouseReturnProduct.productId}
                            productFromCatalog={product}
                            warehouseReturnProduct={warehouseReturnProduct}
                        />
                    );
                })}
                <SectionText>Produits retour sans update de stock (car non prévus dans la livraison)</SectionText>
                {warehouseReturn.returnedWithNoStockUpdateProducts.map((warehouseReturnProduct) => {
                    const product = products.find(({ _id }) => _id === warehouseReturnProduct.productId);
                    return (
                        <ProductDetail
                            key={warehouseReturnProduct.productId}
                            productFromCatalog={product}
                            warehouseReturnProduct={warehouseReturnProduct}
                        />
                    );
                })}
                <SectionText>Produits expirés</SectionText>
                {warehouseReturn.expiredProducts.map((warehouseReturnProduct) => {
                    const product = products.find(({ _id }) => _id === warehouseReturnProduct.productId);
                    return (
                        <ProductDetail
                            key={warehouseReturnProduct.productId}
                            productFromCatalog={product}
                            warehouseReturnProduct={warehouseReturnProduct}
                        />
                    );
                })}
                <SectionText>Produits cassés</SectionText>
                {warehouseReturn.brokenProducts.map((warehouseReturnProduct) => {
                    const product = products.find(({ _id }) => _id === warehouseReturnProduct.productId);
                    return (
                        <ProductDetail
                            key={warehouseReturnProduct.productId}
                            productFromCatalog={product}
                            warehouseReturnProduct={warehouseReturnProduct}
                        />
                    );
                })}
            </>
        </SectionContainer>
    );
};

const SectionText = styled.div`
    font-weight: bold;
    font-size: 22px;
    margin-top: 20px;
`;

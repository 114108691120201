import React from 'react';
import styled from 'styled-components';

import { colors } from 'constants/colors';

import { MicrostoreColumnWithShelvesFragment } from 'data/fragments/__generated__/MicrostoreColumnWithShelvesFragment';
import { MicrostoreColumnInventoryFragment } from 'data/fragments/__generated__/MicrostoreColumnInventoryFragment';

import { dateFromString } from 'helpers/dateTimes';

export const InventoryColumnDisplay = ({
    column,
    columnInventory,
    setSelectedProductId,
    selectedProductId,
    weekdateBeforeNextSetup,
}: {
    column: MicrostoreColumnWithShelvesFragment;
    columnInventory: MicrostoreColumnInventoryFragment;
    setSelectedProductId: React.Dispatch<React.SetStateAction<string | null>>;
    selectedProductId: string | null;
    weekdateBeforeNextSetup: Date;
}) => {
    return (
        <>
            <Column>
                {column.shelves.map((shelf) => (
                    <Shelf key={shelf._id} numberOfLocationRow={shelf.locationTable.length}>
                        {shelf.locationTable.map((locationRow, rowIndex) => (
                            <LocationRow key={rowIndex} isFirstRow={rowIndex === 0}>
                                {locationRow.map((location) => {
                                    const locationInventoryInfo = columnInventory.checkedLocations.find(
                                        ({ microstoreLocationId }) => microstoreLocationId === location._id,
                                    );

                                    const totalInventoryQuantity =
                                        locationInventoryInfo?.quantityDetails?.reduce(
                                            (acc, { quantity }) => acc + quantity,
                                            0,
                                        ) ?? null;

                                    const totalExpired =
                                        locationInventoryInfo?.quantityDetails?.reduce(
                                            (acc, { expiryDate, quantity }) => {
                                                const isExpired =
                                                    expiryDate && dateFromString(expiryDate) < weekdateBeforeNextSetup;
                                                return acc + (isExpired ? quantity : 0);
                                            },
                                            0,
                                        ) ?? null;

                                    return (
                                        <Location
                                            key={location._id}
                                            onClick={() =>
                                                setSelectedProductId(locationInventoryInfo?.productId ?? null)
                                            }
                                            isSelected={
                                                !!locationInventoryInfo &&
                                                locationInventoryInfo?.productId === selectedProductId
                                            }
                                        >
                                            <LocationText>{totalInventoryQuantity}</LocationText>
                                            <LocationText color={colors.red}>
                                                {totalInventoryQuantity ? `(${totalExpired})` : null}
                                            </LocationText>
                                        </Location>
                                    );
                                })}
                            </LocationRow>
                        ))}
                    </Shelf>
                ))}
            </Column>
            <LegendsContainer>
                <Legend>
                    <LegendSymbol>
                        <Location isSelected={false}>
                            <LocationText>4</LocationText>
                            <LocationText color={colors.red}>(2)</LocationText>
                        </Location>
                    </LegendSymbol>
                    <LegendText>L'emplacement possède 4 produits dont 2 périmés</LegendText>
                </Legend>
                <Legend>
                    <LegendSymbol>
                        <Location isSelected={true}>
                            <LocationText>4</LocationText>
                            <LocationText color={colors.red}>(2)</LocationText>
                        </Location>
                    </LegendSymbol>
                    <LegendText>Emplacement sélectionné</LegendText>
                </Legend>
            </LegendsContainer>
        </>
    );
};

const Column = styled.div`
    border-radius: 5px;
    padding: 5px;
    background-color: ${colors.pureWhite};
    width: 100%;
`;

const Shelf = styled.div<{ numberOfLocationRow: number }>`
    width: 90%;
    margin: auto;
    transform: perspective(1200px) rotateX(45deg);
    /* Because of perspective, we need to readjust the margins */
    margin-top: ${({ numberOfLocationRow }) => 15 - numberOfLocationRow * 7}px;
    margin-bottom: ${({ numberOfLocationRow }) => -numberOfLocationRow * 5}px;
`;

const LocationRow = styled.div<{ isFirstRow: boolean }>`
    display: flex;
    flex-direction: row;
    border: 1px solid ${colors.darkGrey};
    border-top: ${({ isFirstRow }) => (isFirstRow ? 1 : 0)}px solid ${colors.darkGrey};
    background-color: ${colors.pureWhite};
`;

const Location = styled.div<{ isSelected: boolean }>`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-basis: 0;
    flex-grow: 1;
    border: ${({ isSelected }) => (isSelected ? `2px solid ${colors.red}` : `1px solid ${colors.darkGrey}`)};
    padding: 1px;
    margin: 2px;
    height: 35px;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const LocationText = styled.span<{ color?: string }>`
    font-size: 14px;
    color: ${({ color }) => color || colors.pureBlack};
`;

const LegendsContainer = styled.div`
    margin-top: 20px;
    padding: 10px;
`;
const Legend = styled.div`
    display: flex;
    align-items: center;
`;
const LegendSymbol = styled.div`
    width: 60px;
`;
const LegendText = styled.div`
    margin-left: 10px;
`;

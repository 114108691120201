import gql from 'graphql-tag';
import { PREVIEW_TRANSACTION_FRAGMENT } from 'data/fragments/transaction';
import { PREVIEW_CREDIT_FRAGMENT } from 'data/fragments/credit';
import { WALLET_FRAGMENT } from 'data/fragments/wallet';

export const GET_TOTEAM_MESSAGES_QUERY = gql`
    query GET_TOTEAM_MESSAGES {
        toteamMessages {
            message
            userFullName
        }
    }
`;

export const GET_TOTEAM_USERS_QUERY = gql`
    query GET_TOTEAM_USERS {
        toteamUsers {
            _id
            profile {
                fullname
            }
        }
    }
`;

export const GET_USER_QUERY = gql`
    query GET_USER($userId: ID!) {
        userFromAdmin(userId: $userId) {
            _id
            username
            createdAt
            emails {
                address
                verified
                bounced {
                    createdAt
                    reason
                }
            }
            getLastTransactions {
                ...PreviewTransactionFragment
            }
            lastSeenAt
            notificationPreferences {
                customRitualActivity
                dailyDelivery
                feedbacks
                newFeatures
                recommendations
                reminders
                ritualInvite
                siteActivity
                specialOffers
                startingRitual
                weeklySummary
            }
            organization {
                _id
                name
            }
            userGroupIds
            profile {
                firstname
                lastname
                fullname
                state
                phone
                salesforceId
                salesforceLeadId
            }
            roles
            stripeCustomerId
            visitedSites {
                _id
                name
                visitedAt
            }
            wallet {
                ...WalletFragment
            }
        }
    }
    ${WALLET_FRAGMENT}
    ${PREVIEW_TRANSACTION_FRAGMENT}
    ${PREVIEW_CREDIT_FRAGMENT}
`;

export const GET_USERS_QUERY = gql`
    query GET_USERS($offset: Int!, $filterString: String) {
        users(offset: $offset, filterString: $filterString) {
            users {
                _id
                createdAt
                lastSeenAt
                username
                profile {
                    fullname
                    state
                }
            }
            hasMore
        }
    }
`;

export const GET_DELIVERY_PEOPLE_QUERY = gql`
    query GET_DELIVERY_PEOPLE {
        getDeliveryPeople {
            _id
            profile {
                fullname
            }
            username
        }
    }
`;

export const GET_TEST_SENSEI_GATE_TOKEN_QUERY = gql`
    query GET_TEST_SENSEI_GATE_TOKEN($senseiUserId: ID!, $isStaff: Boolean!) {
        testSenseiGateToken(senseiUserId: $senseiUserId, isStaff: $isStaff)
    }
`;

import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { formatPrice } from '@totem/constants';
import { useGetSenseiBasketQuery } from 'data/__generated__';

import { Header, HeaderTitle } from 'components/Header';
import { PageTitle } from 'components/PageTitle';
import { PAGES } from 'constants/pages';
import { Loader } from 'components/Loader';
import { SectionColumn, SectionContainer } from 'components/DetailsView/Section';
import { CopyValue } from 'components/CopyValue';
import { DetailLink, DetailValue } from 'components/DetailsView';
import { getExtendedFormattedDatetime } from 'helpers/dateTimes';
import { TotemLabel } from 'components/TotemLabel';
import { FieldToDisplay, ListView } from 'components/ListView';
import { StatusTag } from 'pages/Transactions/components/StatusTag';

type ParamTypes = {
    basketId: string;
};

type FormattedTransactionType = {
    _id: string;
    createdAt: string;
    siteName: string;
    state: JSX.Element;
    productName: string;
    price: string;
};

const TRANSACTIONS_FIELDS_TO_DISPLAY: FieldToDisplay<FormattedTransactionType>[] = [
    {
        fieldName: 'createdAt',
        label: 'Date',
    },
    {
        fieldName: 'state',
        label: 'Statut',
    },
    {
        fieldName: 'siteName',
        label: 'Site',
    },
    {
        fieldName: 'productName',
        label: 'Produit',
    },
    {
        fieldName: 'price',
        label: 'Montant',
    },
];

export const SenseiBasket = () => {
    const { basketId = '' } = useParams<ParamTypes>();

    const { data: senseiBasketData, loading, error } = useGetSenseiBasketQuery({ variables: { basketId } });
    if (loading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }
    if (error || !senseiBasketData?.senseiBasket) {
        throw new Error('Une erreur est survenue lors de la récupération du panier');
    }

    const { senseiBasket } = senseiBasketData;

    const {
        _id,
        createdAt,
        items,
        senseiBasketId,
        senseiColumnId,
        userId,
        basketCreationDate,
        storeEntryDate,
        storeExitDate,
        transactions,
    } = senseiBasket;

    const { userFullName } = transactions?.[0] || 'utilisateur inconnu - impossible';

    const sortedTransactions = [...transactions].sort(
        (transactionA, transactionB) => transactionB.createdAt - transactionA.createdAt,
    );

    const formattedTransactions: FormattedTransactionType[] = sortedTransactions.map(
        ({ _id, createdAt, productName, siteName, priceHT, newState, tva }) => ({
            _id,
            siteName,
            state: <StatusTag state={newState} />,
            productName,
            price: formatPrice(priceHT * (1 + tva)),
            createdAt: getExtendedFormattedDatetime(createdAt),
        }),
    );
    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.senseiBasket} />
                </HeaderTitle>
            </Header>
            <Content>
                <ScrollableContent>
                    <SectionContainer title="Informations générales" keepOpen>
                        <SectionColumn>
                            <CopyValue value={_id} />
                            <CopyValue label="Identifiant Sensei" value={senseiBasketId.toString()} />
                            <DetailLink
                                label="Utilisateur"
                                name={userFullName || 'inconnu'}
                                value={userId}
                                path="user"
                            />
                            <DetailValue label="Colonne" value={senseiColumnId || 'inconnue'} />
                            <DetailValue label="Date de création" value={getExtendedFormattedDatetime(createdAt)} />
                            <DetailValue
                                label="Ouverture de la porte"
                                value={getExtendedFormattedDatetime(storeEntryDate)}
                            />
                            <DetailValue
                                label="Fermeture de la porte"
                                value={getExtendedFormattedDatetime(storeExitDate)}
                            />
                            <DetailValue
                                label="Creation du panier - Sensei"
                                value={getExtendedFormattedDatetime(basketCreationDate)}
                            />
                            <TotemLabel>Produits détectés</TotemLabel>
                            <TableContainer>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>sku (productId)</th>
                                            <th>quantité</th>
                                            <th>montant</th>
                                            <th>taxes</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {items.map(({ quantity, sku, lineAmount, lineTax }, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <DetailLink
                                                        name={
                                                            (sku &&
                                                                transactions.find(({ productId }) => productId === sku)
                                                                    ?.productName) ||
                                                            sku ||
                                                            'produit inconnu'
                                                        }
                                                        value={sku}
                                                        path="product"
                                                    />
                                                </td>
                                                <td>{quantity}</td>
                                                <td>{lineAmount}</td>
                                                <td>{lineTax}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </TableContainer>
                            <TotemLabel>Transactions</TotemLabel>
                            <ListView<FormattedTransactionType>
                                fieldsToDisplay={TRANSACTIONS_FIELDS_TO_DISPLAY}
                                items={formattedTransactions}
                                keyExtractor={(item) => item._id}
                                linkBasePath="/transaction/"
                                hasMore={false}
                            />
                        </SectionColumn>
                    </SectionContainer>
                </ScrollableContent>
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Content = styled.div`
    flex: 1;
    overflow: hidden;
`;

const ScrollableContent = styled.div`
    padding: 15px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
`;

const TableContainer = styled.div`
    display: flex;
    flex-direction: column;

    & > :not(:first-child) {
        margin-top: 5px;
    }

    table {
        border-spacing: 0;
        border-collapse: collapse;
        color: ${({ theme }) => theme.textColor};

        th,
        td {
            text-align: left;
            border: 1px solid ${({ theme }) => theme.lightBorderColor};
            border-spacing: 1px;
        }

        th {
            padding: 10px;
        }

        td {
            padding: 5px;
        }
    }
`;

import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { GetMicrostoreSitesWithLocationsInfoQuery, MicrostoreColumnTemplateType } from 'data/__generated__';

import { ColumnPanel } from 'pages/MicrostoreColumns/MicrostoreColumnDetails/ColumnPanel';
import {
    MicrostoreColumnGroupFormValues,
    microstoreColumnSomeLocationHelper,
    MicrostoreProductsStockInfoFormValues,
} from 'pages/MicrostoreColumns/MicrostoreColumnDetails/FormHelper/DataMapper';

export const MicrostoreColumnPrintable = ({
    microstoreColumnGroupFormValuesArray,
    microstoreProductsStockInfo,
    printForFridgeOnly,
    site,
}: {
    microstoreColumnGroupFormValuesArray: MicrostoreColumnGroupFormValues[];
    microstoreProductsStockInfo: MicrostoreProductsStockInfoFormValues | null;
    printForFridgeOnly: boolean;
    site: GetMicrostoreSitesWithLocationsInfoQuery['sites'][number];
}) => {
    const theme = useContext(ThemeContext);

    return (
        <Container>
            {microstoreColumnGroupFormValuesArray.flatMap((microstoreColumnGroupFormValues) =>
                microstoreColumnGroupFormValues.columns
                    ?.filter((microstoreColumnFormValues) =>
                        printForFridgeOnly
                            ? microstoreColumnFormValues.type === MicrostoreColumnTemplateType.Fridge
                            : true,
                    )
                    ?.map((microstoreColumnFormValues) => {
                        const isColumnFridge = microstoreColumnFormValues.type === MicrostoreColumnTemplateType.Fridge;
                        const hasColumnChanged = microstoreColumnSomeLocationHelper({
                            microstoreColumnFormValues,
                            someFunction: (location) => location.productId !== location.replacementProductId,
                        });
                        const displayOnlyTomorrowForPrint = !(isColumnFridge || hasColumnChanged);
                        return (
                            <React.Fragment key={microstoreColumnFormValues._id}>
                                {!displayOnlyTomorrowForPrint ? (
                                    <MicrostoreColumnContainer>
                                        <ColumnPanel
                                            earliestDLCDateToKeepForStockCalculation={null}
                                            nextMSDeliveryDate=""
                                            nextSiteDeliveryDate=""
                                            switchColumn={null}
                                            hideTodayInfo={false}
                                            hideTomorrowInfo={true}
                                            isFormDirty={false}
                                            isToday={false}
                                            microstoreColumnGroupFormValuesArray={microstoreColumnGroupFormValuesArray}
                                            microstoreProductsStockInfo={microstoreProductsStockInfo}
                                            revertLastUpdate={() => null}
                                            selectedColumnGroup={microstoreColumnGroupFormValues}
                                            selectedColumn={microstoreColumnFormValues}
                                            selectedLocationPosition={null}
                                            selectedShelfIndex={null}
                                            setIsToday={() => null}
                                            setSelectedLocationPosition={() => null}
                                            setSelectedShelfIndex={() => null}
                                            site={site}
                                            submitColumnUpdate={() => null}
                                            updateSelectedColumn={() => null}
                                            updateSelectedLocation={() => null}
                                        />
                                        <LegendContainer>
                                            <LegendColumn>
                                                <LegendRow>
                                                    <LegendSign backgroundColor="#ff001157" />
                                                    <LegendLabel>Le produit sort du frigo</LegendLabel>
                                                </LegendRow>
                                                <LegendRow>
                                                    <LegendSign backgroundColor="#fffb00c8" />
                                                    <LegendLabel>Le produit est déplacé dans le même frigo</LegendLabel>
                                                </LegendRow>
                                            </LegendColumn>

                                            <LegendColumn>
                                                <LegendRow>
                                                    <LegendSign backgroundColor="#e181ff60" />
                                                    <LegendLabel>DLC à regarder</LegendLabel>
                                                </LegendRow>
                                                <LegendRow>
                                                    <LegendSign color={theme.errorColor}>28/02/2020</LegendSign>
                                                    <LegendLabel>
                                                        L'emplacement contient des produits périmés
                                                    </LegendLabel>
                                                </LegendRow>
                                            </LegendColumn>
                                            <LegendColumn>
                                                <LegendRow>
                                                    <LegendSign>S</LegendSign>
                                                    <LegendLabel>
                                                        Nombre théorique de produit avant réassort
                                                    </LegendLabel>
                                                </LegendRow>
                                            </LegendColumn>
                                        </LegendContainer>
                                    </MicrostoreColumnContainer>
                                ) : null}

                                <MicrostoreColumnContainer>
                                    <ColumnPanel
                                        earliestDLCDateToKeepForStockCalculation={null}
                                        nextMSDeliveryDate=""
                                        nextSiteDeliveryDate=""
                                        switchColumn={null}
                                        displayOnlyTomorrowForPrint={displayOnlyTomorrowForPrint}
                                        hideTodayInfo={true}
                                        hideTomorrowInfo={false}
                                        isFormDirty={false}
                                        isToday={false}
                                        microstoreColumnGroupFormValuesArray={microstoreColumnGroupFormValuesArray}
                                        microstoreProductsStockInfo={microstoreProductsStockInfo}
                                        revertLastUpdate={() => null}
                                        selectedColumnGroup={microstoreColumnGroupFormValues}
                                        selectedColumn={microstoreColumnFormValues}
                                        selectedLocationPosition={null}
                                        selectedShelfIndex={null}
                                        setIsToday={() => null}
                                        setSelectedLocationPosition={() => null}
                                        setSelectedShelfIndex={() => null}
                                        site={site}
                                        submitColumnUpdate={() => null}
                                        updateSelectedColumn={() => null}
                                        updateSelectedLocation={() => null}
                                    />
                                    <LegendContainer>
                                        <LegendColumn>
                                            <LegendRow>
                                                <LegendSign backgroundColor="#1bbe4f5a" />
                                                <LegendLabel>Le produit est nouveau dans le frigo</LegendLabel>
                                            </LegendRow>
                                            <LegendRow>
                                                <LegendSign backgroundColor="#fffb00c8" />
                                                <LegendLabel>Le produit est déplacé dans le même frigo</LegendLabel>
                                            </LegendRow>
                                        </LegendColumn>

                                        <LegendColumn>
                                            {displayOnlyTomorrowForPrint ? (
                                                <LegendRow>
                                                    <LegendSign backgroundColor="#e181ff60" />
                                                    <LegendLabel>DLC à regarder</LegendLabel>
                                                </LegendRow>
                                            ) : null}
                                            <LegendRow>
                                                <LegendSign borderColor={theme.successColor} />
                                                <LegendLabel>Il y a des produits a rajouter ici</LegendLabel>
                                            </LegendRow>
                                        </LegendColumn>
                                        <LegendColumn>
                                            <LegendRow>
                                                <LegendSign>ST</LegendSign>
                                                <LegendLabel>Nombre théorique de produit après réassort</LegendLabel>
                                            </LegendRow>
                                        </LegendColumn>
                                    </LegendContainer>
                                </MicrostoreColumnContainer>
                            </React.Fragment>
                        );
                    }),
            )}
        </Container>
    );
};

const Container = styled.div`
    @page {
        margin: 10mm;
    }
`;

const MicrostoreColumnContainer = styled.div`
    width: 100%;
    page-break-after: always;
`;

const LegendContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & > :not(:first-child) {
        margin-left: 20px;
    }
`;

const LegendColumn = styled.div`
    & > :not(:first-child) {
        margin-top: 5px;
    }
`;

const LegendRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;
const LegendSign = styled.div<{ backgroundColor?: string; color?: string; borderColor?: string }>`
    display: flex;
    justify-content: center;
    font-size: 11px;
    min-width: 40px;
    background-color: ${({ backgroundColor }) => backgroundColor ?? 'inherit'};
    border: 1px solid ${({ borderColor }) => borderColor ?? 'inherit'};
    color: ${({ color }) => color ?? 'inherit'};

    align-self: stretch;
`;
const LegendLabel = styled.div`
    font-size: 11px;
`;

import React from 'react';

import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import { FcQuestions } from 'react-icons/fc';

import { DeliveryState } from 'data/__generated__';
import {
    MARK_DELIVERIES_AS_ABORTED_MUTATION,
    MARK_DELIVERIES_AS_ARCHIVED_MUTATION,
    MARK_DELIVERIES_AS_DELIVERED_MUTATION,
    MARK_DELIVERIES_AS_IN_PROGRESS_MUTATION,
    MARK_DELIVERIES_AS_PACKAGING_MUTATION,
    MARK_DELIVERIES_AS_READY_MUTATION,
} from 'data/mutations/delivery';
import {
    MARK_DELIVERIES_AS_ABORTED,
    MARK_DELIVERIES_AS_ABORTEDVariables,
} from 'data/mutations/__generated__/MARK_DELIVERIES_AS_ABORTED';
import {
    MARK_DELIVERIES_AS_ARCHIVED,
    MARK_DELIVERIES_AS_ARCHIVEDVariables,
} from 'data/mutations/__generated__/MARK_DELIVERIES_AS_ARCHIVED';
import {
    MARK_DELIVERIES_AS_DELIVERED,
    MARK_DELIVERIES_AS_DELIVEREDVariables,
} from 'data/mutations/__generated__/MARK_DELIVERIES_AS_DELIVERED';
import {
    MARK_DELIVERIES_AS_IN_PROGRESS,
    MARK_DELIVERIES_AS_IN_PROGRESSVariables,
} from 'data/mutations/__generated__/MARK_DELIVERIES_AS_IN_PROGRESS';
import {
    MARK_DELIVERIES_AS_PACKAGING,
    MARK_DELIVERIES_AS_PACKAGINGVariables,
} from 'data/mutations/__generated__/MARK_DELIVERIES_AS_PACKAGING';
import {
    MARK_DELIVERIES_AS_READY,
    MARK_DELIVERIES_AS_READYVariables,
} from 'data/mutations/__generated__/MARK_DELIVERIES_AS_READY';
import { GET_DELIVERY_delivery } from 'data/queries/__generated__/GET_DELIVERY';

import { SectionColumn, SectionContainer, ColumnsSectionContainer } from 'components/DetailsView/Section';
import { StateHistory } from 'components/DetailsView/StateHistory';
import { StatusTag } from '../components/StatusTag';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { Loader, LoaderModeType } from 'components/Loader';

export const StatesSection = ({ delivery }: { delivery: GET_DELIVERY_delivery }) => {
    const { _id: deliveryId, state, stateHistory } = delivery;

    const [markDeliveriesAsAborted, { loading: markAsAbortedLoading }] = useMutation<
        MARK_DELIVERIES_AS_ABORTED,
        MARK_DELIVERIES_AS_ABORTEDVariables
    >(MARK_DELIVERIES_AS_ABORTED_MUTATION, { variables: { deliveryIds: [deliveryId] } });
    const [markDeliveriesAsArchived, { loading: markAsArchivedLoading }] = useMutation<
        MARK_DELIVERIES_AS_ARCHIVED,
        MARK_DELIVERIES_AS_ARCHIVEDVariables
    >(MARK_DELIVERIES_AS_ARCHIVED_MUTATION, { variables: { deliveryIds: [deliveryId] } });
    const [markDeliveriesAsDelivered, { loading: markAsDeliveredLoading }] = useMutation<
        MARK_DELIVERIES_AS_DELIVERED,
        MARK_DELIVERIES_AS_DELIVEREDVariables
    >(MARK_DELIVERIES_AS_DELIVERED_MUTATION, { variables: { deliveryIds: [deliveryId] } });
    const [markDeliveriesAsInProgress, { loading: markAsInProgressLoading }] = useMutation<
        MARK_DELIVERIES_AS_IN_PROGRESS,
        MARK_DELIVERIES_AS_IN_PROGRESSVariables
    >(MARK_DELIVERIES_AS_IN_PROGRESS_MUTATION, { variables: { deliveryIds: [deliveryId] } });
    const [markDeliveriesAsPackaging, { loading: markAsPackagingLoading }] = useMutation<
        MARK_DELIVERIES_AS_PACKAGING,
        MARK_DELIVERIES_AS_PACKAGINGVariables
    >(MARK_DELIVERIES_AS_PACKAGING_MUTATION, { variables: { deliveryIds: [deliveryId] } });
    const [markDeliveriesAsReady, { loading: markAsReadyLoading }] = useMutation<
        MARK_DELIVERIES_AS_READY,
        MARK_DELIVERIES_AS_READYVariables
    >(MARK_DELIVERIES_AS_READY_MUTATION, { variables: { deliveryIds: [deliveryId] } });

    const formattedStateHistory = stateHistory.map((stateRecord) => ({
        ...stateRecord,
        state: <StatusTag state={stateRecord.state} />,
    }));

    const STATE_CHANGE_ACTIONS = [
        {
            label: 'Passer en préparation',
            action: markDeliveriesAsPackaging,
            loading: markAsPackagingLoading,
            isDisabled: state !== DeliveryState.Created,
        },
        {
            label: 'Marquer comme prête',
            action: markDeliveriesAsReady,
            loading: markAsReadyLoading,
            isDisabled: state !== DeliveryState.Packaging,
        },
        {
            label: 'Passer en livraison',
            action: markDeliveriesAsInProgress,
            loading: markAsInProgressLoading,
            isDisabled: state !== DeliveryState.Ready,
        },
        {
            label: 'Marquer comme livrée',
            action: markDeliveriesAsDelivered,
            loading: markAsDeliveredLoading,
            isDisabled: ![
                DeliveryState.Packaging,
                DeliveryState.Ready,
                DeliveryState.InProgress,
                DeliveryState.NotDelivered,
            ].includes(state),
        },
        {
            label: 'Annuler la livraison',
            action: markDeliveriesAsAborted,
            loading: markAsAbortedLoading,
            isDisabled: ![
                DeliveryState.Created,
                DeliveryState.InProgress,
                DeliveryState.Packaging,
                DeliveryState.Ready,
                DeliveryState.NotDelivered,
            ].includes(state),
        },
        {
            label: 'Archiver la livraison',
            action: markDeliveriesAsArchived,
            loading: markAsArchivedLoading,
            isDisabled: ![DeliveryState.Delivered, DeliveryState.NotDelivered, DeliveryState.Aborted].includes(state),
        },
    ];

    return (
        <SectionContainer title="Historique de statuts" isInitiallyOpen>
            <ColumnsSectionContainer numberOfColumns={3}>
                <SectionColumn>
                    <StateHistory records={formattedStateHistory} />
                </SectionColumn>
                <SectionColumn>
                    <StatusTag state={state} showHeading />
                </SectionColumn>
                <SectionColumn>
                    {STATE_CHANGE_ACTIONS.map((stateChange, index) => {
                        const { label, action, isDisabled, loading } = stateChange;

                        function updateState() {
                            const hasConfirmed = window.confirm(`Êtes-vous sûr.e de vouloir ${label.toLowerCase()} ?`);
                            if (hasConfirmed) {
                                action();
                            }
                        }

                        return (
                            <TotemPrimaryButton key={index} onClick={updateState} disabled={isDisabled}>
                                {label}
                                {loading ? <Loader size="20px" mode={LoaderModeType.Spin} /> : null}
                            </TotemPrimaryButton>
                        );
                    })}
                    <Link
                        href="https://www.notion.so/thetotem/Les-livraisons-deliveries-9a8b9bf7b90c48cfae15beb97d7b4905"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FcQuestions size={24} />
                        Comprendre le cycle de vie des livraisons
                    </Link>
                </SectionColumn>
            </ColumnsSectionContainer>
        </SectionContainer>
    );
};

const Link = styled.a`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.ctaPrimaryColor};
`;

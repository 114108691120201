import { VERIFY_LOGGED_IN_verifyLoggedIn_user } from 'data/mutations/__generated__/VERIFY_LOGGED_IN';
import * as Sentry from '@sentry/react';

import { ENV_PROD } from 'constants/environment';

/**
 * This function posts to slack ONLY if you are in production, otherwise, it just logs the message into the console
 * @param {String} message The message you want to post on Slack
 * @param {String} channel The channel (start with #) you want to post to
 */
export async function postToSlack({
    message,
    channel = '_useractivity',
    user,
}: {
    message: string;
    channel: string;
    user?: VERIFY_LOGGED_IN_verifyLoggedIn_user;
}) {
    if (!user) {
        // eslint-disable-next-line import/namespace
        Sentry.withScope((scope) => {
            scope.setContext('error context', {
                message,
                channel,
            });
            // eslint-disable-next-line import/namespace
            Sentry.captureEvent({
                // eslint-disable-next-line import/namespace
                level: Sentry.Severity.Warning,
                message: "No user found, can't post to Slack.",
            });
        });
        return;
    }

    const text = `[Totadmin] ${message}`;

    if (!window.__TOTENV__.SLACK_WEBHOOK_URL) {
        throw Error('Unhandled REACT_APP_SLACK_WEBHOOK_URL');
    }

    if (process.env.ENV === ENV_PROD) {
        try {
            await fetch(window.__TOTENV__.SLACK_WEBHOOK_URL, {
                method: 'post',
                body: JSON.stringify({
                    blocks: [
                        {
                            type: 'section',
                            text: {
                                type: 'mrkdwn',
                                text,
                            },
                        },
                    ],
                    channel,
                    icon_emoji: ':sleuth_or_spy:',
                    text,
                    unfurl_links: true,
                    unfurl_media: true,
                    username: 'DetectiveTotem',
                }),
            });
        } catch (error) {
            const errorMessage = 'postToSlack: error';
            console.log(errorMessage, message, error);
            // eslint-disable-next-line import/namespace
            Sentry.captureException({
                message: errorMessage,
                contexts: { error, message },
            });
        }
    } else {
        console.log(`[POST TO SLACK - not prod] on channel ${channel}: "${text}"`);
    }
}

import { InputHTMLAttributes } from 'react';
import styled from 'styled-components';

interface CustomInputProps {
    centerText?: boolean;
}

export interface BasicInputProps extends InputHTMLAttributes<HTMLInputElement>, CustomInputProps {}

export const BasicInput = styled.input<BasicInputProps>`
    display: inline-flex;
    align-items: center;
    border-radius: ${({ theme }) => theme.borderRadius};
    outline: 0px;
    color: rgb(33, 0, 45);
    font-size: 16px;
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
    padding: 12px;
    text-align: ${({ centerText }) => (centerText ? 'center' : 'left')};
    width: ${({ width }) => width || 'auto'};

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &:disabled {
        color: ${({ theme }) => theme.textColor};
    }

    /* Firefox */
    &[type='number'] {
        -moz-appearance: textfield;
    }
`;

import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { GET_ORGANIZATION_QUERY } from 'data/queries/organization';
import {
    GET_ORGANIZATION,
    GET_ORGANIZATIONVariables,
    GET_ORGANIZATION_organization,
} from 'data/queries/__generated__/GET_ORGANIZATION';

import { Loader } from 'components/Loader';

import { OrganizationGlobalForm } from './OrganizationGlobalForm';
type ParamTypes = {
    organizationId: string;
};

export const OrganizationDetails = () => {
    const { organizationId = '' } = useParams<ParamTypes>();
    const [organizationDetails, setOrganizationDetails] = useState<GET_ORGANIZATION_organization | null>(null);

    const {
        loading: organizationLoading,
        error: organizationError,
        data: organizationData,
    } = useQuery<GET_ORGANIZATION, GET_ORGANIZATIONVariables>(GET_ORGANIZATION_QUERY, {
        variables: { organizationId },
    });

    useEffect(() => {
        if (organizationData) {
            const { organization } = organizationData;
            setOrganizationDetails(organization);
        }
    }, [organizationData]);

    if (organizationLoading || !organizationDetails) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (organizationError || !organizationData) {
        throw new Error('Une erreur est survenue lors de la récupération de cette organisation');
    }

    const organization = organizationData.organization;

    return (
        <Container>
            <OrganizationGlobalForm organization={organization} />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { toast } from 'react-toastify';

import { FaLongArrowAltRight, FaTrash } from 'react-icons/fa';
import { IoWarning } from 'react-icons/io5';
import styled from 'styled-components';

import { GET_TRANSFERABLE_PRODUCTS_QUERY } from 'data/queries/product';
import {
    GET_TRANSFERABLE_PRODUCTS,
    GET_TRANSFERABLE_PRODUCTSVariables,
    GET_TRANSFERABLE_PRODUCTS_transferableProducts,
} from 'data/queries/__generated__/GET_TRANSFERABLE_PRODUCTS';
import { colors } from 'constants/colors';

import { TotemLabel } from 'components/TotemLabel';
import { TotemInput } from 'components/TotemInput';
import { Loader, LoaderModeType } from 'components/Loader';
import { SelectedOption, TotemSelect } from 'components/TotemSelect';
import { DetailLink } from 'components/DetailsView';

import { FrenchDate } from 'helpers/dateTimes';

export const ProductsToTransferTable = ({
    originSiteId,
    destinationSiteId,
    handleProductSelect,
    productsToTransfer,
    handleQuantityToTransferUpdate,
    handleChangedAvailableQuantitiesAfterDateChange,
    handleProductDelete,
    isTransferValidated,
    transferDate,
}: {
    originSiteId: string;
    destinationSiteId: string;
    handleProductSelect: (product: GET_TRANSFERABLE_PRODUCTS_transferableProducts) => void;
    productsToTransfer: GET_TRANSFERABLE_PRODUCTS_transferableProducts[];
    handleQuantityToTransferUpdate: (
        value: string,
        productToTransfer: GET_TRANSFERABLE_PRODUCTS_transferableProducts,
    ) => void;
    handleChangedAvailableQuantitiesAfterDateChange: (
        products: GET_TRANSFERABLE_PRODUCTS_transferableProducts[],
    ) => void;
    handleProductDelete: (productToTransfer: GET_TRANSFERABLE_PRODUCTS_transferableProducts) => void;
    isTransferValidated?: boolean;
    transferDate?: FrenchDate;
}) => {
    const {
        loading: productsLoading,
        data: productsData,
        error: productsError,
    } = useQuery<GET_TRANSFERABLE_PRODUCTS, GET_TRANSFERABLE_PRODUCTSVariables>(GET_TRANSFERABLE_PRODUCTS_QUERY, {
        skip: isTransferValidated,
        variables: {
            originSiteId,
            destinationSiteId,
            transferDate,
        },
    });

    const [productsOptions, setProductsOptions] = useState<{ value: string; label: string }[]>([]);

    useEffect(() => {
        if (productsData) {
            setProductsOptions(
                productsData.transferableProducts.map((product) => ({
                    value: product.productId,
                    label: `${product.fullname} (${product.originStockAvailable} disponible(s) pour un transfert de stock)`,
                })),
            );
            handleChangedAvailableQuantitiesAfterDateChange(productsData.transferableProducts);
        }
    }, [productsData]);

    if (productsLoading) {
        return (
            <LoaderContainer>
                <Loader mode={LoaderModeType.Spin} />
            </LoaderContainer>
        );
    }

    if (!isTransferValidated && (productsError || !productsData)) {
        toast.error('Une erreur est survenue lors de la récupération des produits transférables');
        return null;
    }

    const onSelect = (option: SelectedOption<string>) => {
        if (option) {
            const product = productsData?.transferableProducts.find((product) => product.productId === option.value);
            if (product) {
                handleProductSelect(product);
            }
        }
    };

    const productsIds = productsOptions.map(({ value }) => value);
    const notTransferableProductIds = isTransferValidated
        ? []
        : productsToTransfer.map(({ productId }) => productId).filter((productId) => !productsIds.includes(productId));

    return (
        <Container>
            {!isTransferValidated ? (
                <div data-test="product-selector">
                    <TotemSelect
                        dataTest="select-product"
                        label="Ajouter un produit"
                        placeholder="Sélectionner un produit (recherche par nom ou par _id)"
                        value={null}
                        options={productsOptions}
                        onChange={(option) => onSelect(option)}
                    />
                </div>
            ) : null}
            {productsToTransfer.length ? (
                <>
                    <TotemLabel>Produits à transférer</TotemLabel>
                    {notTransferableProductIds.length ? (
                        <ErrorLabel>Des produits ne sont pas transférables (car il ne sont pas microstore)</ErrorLabel>
                    ) : null}
                    <ProductsTable>
                        <thead>
                            <tr>
                                <ProductsTableHeaderCell>Produit</ProductsTableHeaderCell>
                                <ProductsTableHeaderCell isDataCentered>
                                    {!isTransferValidated ? 'Quantité à transférer' : 'Quantité transférée'}
                                </ProductsTableHeaderCell>
                                <ProductsTableHeaderCell isDataCentered>
                                    Stock dispo à l'origine
                                </ProductsTableHeaderCell>
                                <ProductsTableHeaderCell isDataCentered>
                                    Stock dispo à destination
                                </ProductsTableHeaderCell>
                                {!isTransferValidated ? (
                                    <ProductsTableHeaderCell isDataCentered>Supprimer</ProductsTableHeaderCell>
                                ) : null}
                            </tr>
                        </thead>
                        <ProductsTableBody>
                            {productsToTransfer.map((productToTransfer) => {
                                const {
                                    productId,
                                    fullname,
                                    quantity,
                                    originStockAvailable,
                                    destinationStockAvailable,
                                } = productToTransfer;
                                const transferableProduct = productsData?.transferableProducts.find(
                                    (product) => productId === product.productId,
                                );

                                return (
                                    <tr key={productId}>
                                        <ProductsTableBodyCell>
                                            <ProductNameContainer>
                                                <DetailLink name={fullname} value={productId} path="product" />
                                                {!isTransferValidated && !transferableProduct ? (
                                                    <ErrorLabel data-test="product-not-transferable-error">
                                                        Le produit selectionné n'est pas un produit microstore
                                                    </ErrorLabel>
                                                ) : null}
                                            </ProductNameContainer>
                                        </ProductsTableBodyCell>
                                        <ProductsTableBodyCell isDataCentered>
                                            <QuantityInputOuterContainer>
                                                <QuantityInputInnerContainer>
                                                    <TotemInput
                                                        type="number"
                                                        value={quantity || ''}
                                                        disabled={isTransferValidated}
                                                        onChange={(value) =>
                                                            handleQuantityToTransferUpdate(value, productToTransfer)
                                                        }
                                                        centerText
                                                        data-test="product-quantity"
                                                    />
                                                </QuantityInputInnerContainer>
                                            </QuantityInputOuterContainer>
                                        </ProductsTableBodyCell>
                                        <ProductsTableBodyCell isDataCentered>
                                            <StockDelta>
                                                {!isTransferValidated ? (
                                                    transferableProduct ? (
                                                        <>
                                                            <span>{transferableProduct.originStockAvailable}</span>
                                                            <FaLongArrowAltRight size={25} color={colors.red} />
                                                            <span>
                                                                {transferableProduct.originStockAvailable - quantity}
                                                            </span>
                                                        </>
                                                    ) : (
                                                        <ErrorLabel>Non transférable, pas d'info</ErrorLabel>
                                                    )
                                                ) : (
                                                    <>
                                                        <span>{originStockAvailable + quantity}</span>
                                                        <FaLongArrowAltRight size={25} color={colors.red} />
                                                        <span>{originStockAvailable}</span>
                                                    </>
                                                )}
                                                {originStockAvailable - quantity < 0 ? (
                                                    <WarningIcon data-test="warning-icon" size={25} />
                                                ) : null}
                                            </StockDelta>
                                        </ProductsTableBodyCell>
                                        <ProductsTableBodyCell isDataCentered>
                                            <StockDelta>
                                                {!isTransferValidated ? (
                                                    transferableProduct ? (
                                                        <>
                                                            <span>{transferableProduct.destinationStockAvailable}</span>
                                                            <FaLongArrowAltRight size={25} color={colors.green} />
                                                            <span>
                                                                {transferableProduct.destinationStockAvailable +
                                                                    quantity}
                                                            </span>
                                                        </>
                                                    ) : (
                                                        <ErrorLabel>Non transférable, pas d'info</ErrorLabel>
                                                    )
                                                ) : (
                                                    <>
                                                        <span>{destinationStockAvailable - quantity}</span>
                                                        <FaLongArrowAltRight size={25} color={colors.green} />
                                                        <span>{destinationStockAvailable}</span>
                                                    </>
                                                )}
                                            </StockDelta>
                                        </ProductsTableBodyCell>
                                        {!isTransferValidated ? (
                                            <ProductsTableBodyCell isDataCentered>
                                                <DeleteIconContainer
                                                    onClick={() => handleProductDelete(productToTransfer)}
                                                >
                                                    <FaTrash data-test="trash-icon" size={20} color={colors.red} />
                                                </DeleteIconContainer>
                                            </ProductsTableBodyCell>
                                        ) : null}
                                    </tr>
                                );
                            })}
                        </ProductsTableBody>
                    </ProductsTable>
                </>
            ) : null}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    color: ${({ theme }) => theme.textColor};

    & > :not(:first-child) {
        margin-top: 5px;
    }
`;

const ErrorLabel = styled.div`
    font-weight: 800;
    color: ${colors.red};
    text-align: center;
`;

const ProductsTable = styled.table`
    border-radius: ${({ theme }) => theme.borderRadius};
    border-spacing: 0px;
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
`;

const ProductsTableBody = styled.thead`
    & > :not(:first-child) td {
        border-top: 1px solid ${({ theme }) => theme.lightBorderColor};
    }
`;

const ProductsTableHeaderCell = styled.th`
    padding: 10px;
    text-align: ${({ isDataCentered }: { isDataCentered?: boolean }) => (isDataCentered ? 'center' : 'left')};
    border-bottom: 1px solid ${({ theme }) => theme.lightBorderColor};
`;

const ProductsTableBodyCell = styled.td`
    padding: 10px;
    text-align: ${({ isDataCentered }: { isDataCentered?: boolean }) => (isDataCentered ? 'center' : 'left')};
`;

const ProductNameContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const QuantityInputOuterContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const QuantityInputInnerContainer = styled.div`
    width: 70px;
`;

const WarningIcon = styled(IoWarning)`
    color: ${({ theme }) => theme.warningColor};
`;

const StockDelta = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const DeleteIconContainer = styled.div`
    display: inline;
    padding: 5px;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
`;

const LoaderContainer = styled.div`
    padding: 15px;
`;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { useMutation, useQuery } from '@apollo/client';
import styled from 'styled-components';
import Switch from 'react-switch';
import { toast } from 'react-toastify';

import { PAGES } from '../../constants/pages';

import { GET_SIMPLE_MICROSTORE_SITES_QUERY } from 'data/queries/site';
import { GET_SIMPLE_MICROSTORE_SITES } from 'data/queries/__generated__/GET_SIMPLE_MICROSTORE_SITES';
import { SiteState } from 'data/__generated__';
import { colors } from 'constants/colors';
import {
    UPDATE_LOCATIONS_WITH_TARGET_STOCK,
    UPDATE_LOCATIONS_WITH_TARGET_STOCKVariables,
} from 'data/mutations/__generated__/UPDATE_LOCATIONS_WITH_TARGET_STOCK';
import { UPDATE_LOCATIONS_WITH_TARGET_STOCK_MUTATION } from 'data/mutations/microstoreLocation';

import { Header, HeaderTitle } from 'components/Header';
import { FieldToDisplay, ListView } from 'components/ListView';
import { Loader } from 'components/Loader';
import { PageTitle } from 'components/PageTitle';
import { TotemInput } from 'components/TotemInput';
import { CsvDataRow, TotemCsvInputButton } from 'components/TotemCsvInputButton';
import { getLocationIdsWithTargetStockFromData } from './Imports/locationTargetStockImport';
import { TypeTag } from 'pages/Sites/components/TypeTag';
import { StatusTag } from 'pages/Sites/components/StatusTag';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { TotemLabel } from 'components/TotemLabel';
import { LaunchRestockingFreshPopup } from './Popups/LaunchRestockingFreshPopup';
import { GrPlan } from 'react-icons/gr';

type FormattedSiteType = {
    _id: string;
    name: string;
    numberOfColumnGroup: number;
    numberOfColumn: number;
    namesOfColumn: JSX.Element;
    type: JSX.Element;
    state: JSX.Element;
    planogramLink: JSX.Element;
};

export const MicrostoreColumns = () => {
    const {
        loading: sitesLoading,
        data: sitesData,
        error: sitesError,
    } = useQuery<GET_SIMPLE_MICROSTORE_SITES>(GET_SIMPLE_MICROSTORE_SITES_QUERY);

    const [updateLocationsWithTargetStock] = useMutation<
        UPDATE_LOCATIONS_WITH_TARGET_STOCK,
        UPDATE_LOCATIONS_WITH_TARGET_STOCKVariables
    >(UPDATE_LOCATIONS_WITH_TARGET_STOCK_MUTATION);

    const [filterString, setFilterString] = useState('');
    const [shouldHideArchived, setShouldHideArchived] = useState(true);
    const [isLaunchRestockingFreshPopupOpen, setLaunchRestockingFreshPopupOpen] = useState(false);

    if (sitesLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (!sitesData || sitesError) {
        throw new Error('Une erreur est survenue lors de la récupération des sites');
    }

    const { microstoreSites } = sitesData;

    const SITES_FIELDS_TO_DISPLAY: FieldToDisplay<FormattedSiteType>[] = [
        {
            fieldName: 'name',
            label: 'Nom',
        },
        { fieldName: 'numberOfColumnGroup', label: 'Nombre de groupe' },
        { fieldName: 'numberOfColumn', label: 'Nombre de colonnes' },
        { fieldName: 'namesOfColumn', label: 'Noms de colonnes' },
        { fieldName: 'type', label: 'Type' },
        { fieldName: 'state', label: 'Statut' },
        { fieldName: 'planogramLink', label: 'Planogramme' },
    ];

    const filteredSites = microstoreSites.filter(
        ({ name, state }) =>
            name.toLowerCase().search(filterString.toLowerCase()) !== -1 &&
            (shouldHideArchived ? state !== SiteState.Archived : true),
    );

    const sortedSites = [...filteredSites].sort((microstoreColumnTemplateA, microstoreColumnTemplateB) =>
        microstoreColumnTemplateA.name.localeCompare(microstoreColumnTemplateB.name),
    );

    const formattedSites: FormattedSiteType[] = sortedSites.map(
        ({ _id, microstoreColumnGroups, name, state, type }) => {
            const flattenedColumnGroups = microstoreColumnGroups.flatMap(({ columns }) => columns);
            const allColumnNames = flattenedColumnGroups.map(({ name }) => name);

            const uniqueNamesOfColumn = Array.from(new Set(allColumnNames));

            return {
                _id,
                name,
                state: <StatusTag state={state} />,
                type: <TypeTag type={type} />,
                numberOfColumnGroup: microstoreColumnGroups.length,
                numberOfColumn: flattenedColumnGroups.length,
                namesOfColumn: (
                    <>
                        {uniqueNamesOfColumn.map((name) => (
                            <ColumnName key={name}>
                                • {allColumnNames.filter((columnName) => columnName === name).length}x {name}
                            </ColumnName>
                        ))}
                    </>
                ),
                planogramLink: (
                    <Link to={`/planogram/${_id}`}>
                        <GrPlan />
                    </Link>
                ),
            };
        },
    );

    async function handleCSVImport(csvData: CsvDataRow[]) {
        const locationIdsWithTargetStock = getLocationIdsWithTargetStockFromData(csvData);

        if (!locationIdsWithTargetStock.length) {
            return;
        }

        await updateLocationsWithTargetStock({ variables: { locationIdsWithTargetStock } });

        toast.success('Mise à jour réussie');
    }

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.microstoreColumns} />
                </HeaderTitle>
                <ButtonsContainer>
                    <TotemPrimaryButton onClick={() => setLaunchRestockingFreshPopupOpen(true)}>
                        Restocking
                    </TotemPrimaryButton>
                    <Link to={PAGES.microstoreColumnTargetsInfo.url}>
                        <TotemPrimaryButton
                            type="button"
                            title="Afficher les infos de remplissage des stores post restocking"
                        >
                            Synthèse
                        </TotemPrimaryButton>
                    </Link>
                    <TotemCsvInputButton onCsvDataUpload={handleCSVImport} label="Importer" />
                    <LabelContainer>
                        <Label>Cacher les sites archivés</Label>
                    </LabelContainer>
                    <Switch
                        onChange={() => setShouldHideArchived(!shouldHideArchived)}
                        checked={shouldHideArchived}
                        onColor={colors.green}
                        offColor={colors.pantinGrey}
                    />
                </ButtonsContainer>
            </Header>
            <Content>
                <TotemInput
                    label="Recherche"
                    onChange={setFilterString}
                    placeholder="Nom du site"
                    value={filterString}
                    autoFocus={true}
                />
                <ListView<FormattedSiteType>
                    fieldsToDisplay={SITES_FIELDS_TO_DISPLAY}
                    items={formattedSites}
                    linkBasePath="/microstoreColumns/"
                    keyExtractor={(item) => item._id}
                />
            </Content>
            <LaunchRestockingFreshPopup
                isOpen={isLaunchRestockingFreshPopupOpen}
                setIsOpen={setLaunchRestockingFreshPopupOpen}
            />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    flex: 1;
    overflow: hidden;
`;

const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    > div {
        margin-left: 5px;
    }
    > a {
        margin-left: 5px;
    }
`;

const LabelContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const Label = styled(TotemLabel)`
    margin-right: 5px;
`;

const ColumnName = styled.ul`
    margin: 0;
    padding: 0;
`;

import React from 'react';

import { SupplierOrderingTermsSummary } from './SupplierOrderingTermsSummary';
import { SupplyOrderDetailsDisplayValues } from './supplyOrderDetailsFormHelper';
import { DetailLink, DetailArrayValue, DetailValue } from 'components/DetailsView';
import { SectionColumn, SectionContainer, ColumnsSectionContainer } from 'components/DetailsView/Section';

export const SupplierInfoSection = ({
    supplyOrderDetailsDisplayValues,
}: {
    supplyOrderDetailsDisplayValues: SupplyOrderDetailsDisplayValues;
}) => {
    const { supplier } = supplyOrderDetailsDisplayValues;

    return (
        <SectionContainer title="Informations fournisseur">
            <ColumnsSectionContainer numberOfColumns={3}>
                <SectionColumn>
                    <DetailLink label="Page du fournisseur" path="supplier" value={supplier._id} name={supplier.name} />
                    <DetailValue label="ID" value={supplier._id} />
                    <DetailValue
                        label="Indice de risque"
                        sublabel="Valeur comprise entre 1 (Fiable) et 3 (Retards systématiques)"
                        value={supplier.frequency}
                    />
                    <DetailValue label="Mode de commande" value={supplier.orderMode} />
                    <DetailArrayValue label="Emails de commande" values={supplier.orderEmails} />
                </SectionColumn>
                <SectionColumn>
                    <DetailValue label="Code client" value={supplier.clientCode} />
                    <SupplierOrderingTermsSummary orderingTerms={supplier.orderingTerms} />
                </SectionColumn>
                <SectionColumn>
                    <DetailArrayValue
                        label="Délais jusqu'à réception"
                        values={[
                            `Pour une commande le Lundi (avant ${supplier.orderingTimes.monday.orderBefore}), réception
                        ${supplier.orderingTimes.monday.daysToDeliver} jour${
                            supplier.orderingTimes.monday.daysToDeliver > 1 ? 's' : ''
                        } plus tard`,
                            `Pour une commande le Mardi (avant ${supplier.orderingTimes.tuesday.orderBefore}), réception
                        ${supplier.orderingTimes.tuesday.daysToDeliver} jour${
                            supplier.orderingTimes.tuesday.daysToDeliver > 1 ? 's' : ''
                        } plus tard`,
                            `Pour une commande le Mercredi (avant ${
                                supplier.orderingTimes.wednesday.orderBefore
                            }), réception
                        ${supplier.orderingTimes.wednesday.daysToDeliver} jour${
                            supplier.orderingTimes.wednesday.daysToDeliver > 1 ? 's' : ''
                        } plus tard`,
                            `Pour une commande le Jeudi (avant ${
                                supplier.orderingTimes.thursday.orderBefore
                            }), réception
                        ${supplier.orderingTimes.thursday.daysToDeliver} jour${
                            supplier.orderingTimes.thursday.daysToDeliver > 1 ? 's' : ''
                        } plus tard`,
                            `Pour une commande le Vendredi (avant ${
                                supplier.orderingTimes.friday.orderBefore
                            }), réception
                        ${supplier.orderingTimes.friday.daysToDeliver} jour${
                            supplier.orderingTimes.friday.daysToDeliver > 1 ? 's' : ''
                        } plus tard`,
                            `Pour une commande le Samedi (avant ${
                                supplier.orderingTimes.saturday.orderBefore
                            }), réception
                        ${supplier.orderingTimes.saturday.daysToDeliver} jour${
                            supplier.orderingTimes.saturday.daysToDeliver > 1 ? 's' : ''
                        } plus tard`,
                            `Pour une commande le Dimanche (avant ${
                                supplier.orderingTimes.sunday.orderBefore
                            }), réception
                        ${supplier.orderingTimes.sunday.daysToDeliver} jour${
                            supplier.orderingTimes.sunday.daysToDeliver > 1 ? 's' : ''
                        } plus tard`,
                        ]}
                    />
                </SectionColumn>
            </ColumnsSectionContainer>
        </SectionContainer>
    );
};

import { MicrostoreColumnTemplateType } from 'data/__generated__';

export const MICROSTORE_COLUMN_TEMPLATE_TYPE_LABELS: { [key in MicrostoreColumnTemplateType]: string } = {
    [MicrostoreColumnTemplateType.Cutlery]: 'Couverts',
    [MicrostoreColumnTemplateType.Drinks]: 'Boissons',
    [MicrostoreColumnTemplateType.Fridge]: 'Frigo',
    [MicrostoreColumnTemplateType.HotDrinks]: 'Boissons Chaudes',
    [MicrostoreColumnTemplateType.Snacks]: 'Snacks',
    [MicrostoreColumnTemplateType.Microwave]: 'Micro-ondes',
    [MicrostoreColumnTemplateType.Essentials]: 'Essentiels',
    [MicrostoreColumnTemplateType.Vrac]: 'Vrac',
};

import React from 'react';
import styled from 'styled-components';

import { TotemGallery } from 'components/TotemGallery';
import { SetupInstructionFragment_PhotosInstruction } from 'data/fragments/__generated__/SetupInstructionFragment';

export const ColumnPhotosSection = ({ instruction }: { instruction: SetupInstructionFragment_PhotosInstruction }) => {
    const images = instruction.photos.flatMap(({ type, urls }) =>
        urls.map((url, index) => ({ label: `${type} ${index}`, url })),
    );

    return (
        <Container>
            <Title>Photos</Title>
            <Content>
                <TotemGallery images={images} />
            </Content>
        </Container>
    );
};

const Container = styled.div``;

const Content = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
`;

const Title = styled.div`
    font-size: 18px;
    font-weight: bold;
    text-align: center;
`;

import React, { useState } from 'react';

import styled from 'styled-components';

import { Header, HeaderTitle } from 'components/Header';
import { PageTitle } from 'components/PageTitle';
import { PAGES } from 'constants/pages';
import { Loader } from 'components/Loader';
import { TotemDatePicker } from 'components/TotemDatePicker';
import { TrelloView } from 'components/TrelloView/TrelloView';
import {
    useGetWarehouseDeliveriesQuery,
    WarehouseDeliveryFragmentFragment,
    WarehouseDeliveryState,
} from 'data/__generated__';
import { WAREHOUSE_DELIVERY_PLURAL_STATES_LABELS } from './constants/states';
import { WarehouseDeliveryTrelloItem } from './WarehouseDeliveryTrelloItem';

const STATE_COLUMNS_TO_DISPLAY = [
    WarehouseDeliveryState.Created,
    WarehouseDeliveryState.Packaging,
    WarehouseDeliveryState.Paused,
    WarehouseDeliveryState.Ready,
    WarehouseDeliveryState.InProgress,
    WarehouseDeliveryState.Delivered,
    WarehouseDeliveryState.Aborted,
    WarehouseDeliveryState.Archived,
];

const formatSearchDate = (deliverySearchDate: Date): string => {
    const tmpDate = new Date(deliverySearchDate);
    tmpDate.setMinutes(tmpDate.getMinutes() - tmpDate.getTimezoneOffset());
    return tmpDate.toISOString();
};

export const WarehouseDeliveries = () => {
    const [deliverySearchDate, setDeliverySearchDate] = useState<Date | null>(null);
    const [selectedWarehouseDeliveryIds, setSelectedWarehouseDeliveryIds] = useState<string[]>([]);

    const { data, loading, error } = useGetWarehouseDeliveriesQuery({
        variables: {
            searchDate: deliverySearchDate ? formatSearchDate(deliverySearchDate) : null,
        },
    });

    if (loading && !data) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (error || !data) {
        throw new Error('Une erreur est survenue lors de la récupération des livraisons');
    }

    const { warehouseDeliveries } = data;

    function toggleWarehouseDeliverySelection(warehouseDeliveryIdToToggle: string) {
        if (selectedWarehouseDeliveryIds.includes(warehouseDeliveryIdToToggle)) {
            setSelectedWarehouseDeliveryIds(
                selectedWarehouseDeliveryIds.filter(
                    (warehouseDeliveryId) => warehouseDeliveryId !== warehouseDeliveryIdToToggle,
                ),
            );
        } else {
            setSelectedWarehouseDeliveryIds([...selectedWarehouseDeliveryIds, warehouseDeliveryIdToToggle]);
        }
    }

    function sortByDeliveryDate(
        deliveryA: WarehouseDeliveryFragmentFragment,
        deliveryB: WarehouseDeliveryFragmentFragment,
    ) {
        if ((deliveryA.transferDate && !deliveryB.transferDate) || deliveryA.transferDate > deliveryB.transferDate) {
            return 1;
        }
        if (deliveryA.transferDate === deliveryB.transferDate) {
            return deliveryA.updatedAt > deliveryB.updatedAt ? 1 : -1;
        }
        return -1;
    }

    function getDeliveriesByState({ state }: { state: WarehouseDeliveryState }) {
        const sortedWarehouseDeliveries = warehouseDeliveries
            .filter((delivery) => delivery.state === state)
            .sort(sortByDeliveryDate);
        if (
            [
                WarehouseDeliveryState.Aborted,
                WarehouseDeliveryState.Archived,
                WarehouseDeliveryState.Delivered,
            ].includes(state)
        ) {
            sortedWarehouseDeliveries.reverse();
        }
        return sortedWarehouseDeliveries.map((warehouseDelivery) => {
            return {
                _id: warehouseDelivery._id,
                content: (
                    <WarehouseDeliveryTrelloItem
                        warehouseDelivery={warehouseDelivery}
                        isSelected={selectedWarehouseDeliveryIds.includes(warehouseDelivery._id)}
                        toggleDeliverySelection={toggleWarehouseDeliverySelection}
                    />
                ),
            };
        });
    }
    const deliveriesByState = {
        [WarehouseDeliveryState.Created]: getDeliveriesByState({ state: WarehouseDeliveryState.Created }),
        [WarehouseDeliveryState.Packaging]: getDeliveriesByState({ state: WarehouseDeliveryState.Packaging }),
        [WarehouseDeliveryState.Ready]: getDeliveriesByState({ state: WarehouseDeliveryState.Ready }),
        [WarehouseDeliveryState.InProgress]: getDeliveriesByState({ state: WarehouseDeliveryState.InProgress }),
        [WarehouseDeliveryState.Delivered]: getDeliveriesByState({ state: WarehouseDeliveryState.Delivered }),
        [WarehouseDeliveryState.Paused]: getDeliveriesByState({ state: WarehouseDeliveryState.Paused }),
        [WarehouseDeliveryState.Aborted]: getDeliveriesByState({ state: WarehouseDeliveryState.Aborted }),
        [WarehouseDeliveryState.Archived]: getDeliveriesByState({ state: WarehouseDeliveryState.Archived }),
    };

    const initialData = {
        columns: STATE_COLUMNS_TO_DISPLAY.map((state) => ({
            title: WAREHOUSE_DELIVERY_PLURAL_STATES_LABELS[state],
            items: deliveriesByState[state],
        })),
    };

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.warehouseDeliveries} />
                </HeaderTitle>

                <DateContainer>
                    <TotemDatePicker
                        label="Date de livraison"
                        selected={deliverySearchDate}
                        onChange={(date) => {
                            setDeliverySearchDate(date as Date);
                        }}
                        isClearable={true}
                    />
                </DateContainer>
            </Header>
            <Content>
                <TrelloViewContainer>
                    <TrelloView initialData={initialData} />
                </TrelloViewContainer>
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const TrelloViewContainer = styled.div`
    overflow-x: scroll;
`;

const Content = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    overflow: hidden;
`;

const DateContainer = styled.div`
    display: flex;
    min-width: 300px;
    justify-self: end;

    & > :not(:first-child) {
        margin-left: 15px;
    }
`;

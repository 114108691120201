import { gql } from '@apollo/client';

export const QUIZ_RITUAL_GAME_FRAGMENT = gql`
    fragment QuizRitualGameFragment on QuizRitualGame {
        _id
        maxAttempts
        questions {
            answerChoices
            correctAnswerIndex
            description
            explanation
            imageUrl
            timeToAnswer
            title
        }
        ritualId
        targetScore
        totalScore
        type
    }
`;
export const WANTED_RITUAL_GAME_FRAGMENT = gql`
    fragment WantedRitualGameFragment on WantedRitualGame {
        _id
        maxAttempts
        ritualId
        targetScore
        totalScore
        type
    }
`;

export const RITUAL_GAME_FRAGMENT = gql`
    fragment RitualGameFragment on RitualGame {
        ... on QuizRitualGame {
            ...QuizRitualGameFragment
        }
        ... on WantedRitualGame {
            ...WantedRitualGameFragment
        }
    }
    ${QUIZ_RITUAL_GAME_FRAGMENT}
    ${WANTED_RITUAL_GAME_FRAGMENT}
`;

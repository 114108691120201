import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { getTranslatedNotificationCategory } from 'constants/notification';
import { PAGES } from '../../constants/pages';

import { NotificationCategory } from 'data/__generated__';
import { SEND_NOTIFICATION_TO_USERS_MUTATION } from 'data/mutations/notification';
import {
    SEND_NOTIFICATION_TO_USERS,
    SEND_NOTIFICATION_TO_USERSVariables,
} from 'data/mutations/__generated__/SEND_NOTIFICATION_TO_USERS';

import { Header, HeaderTitle } from 'components/Header';
import { Loader, LoaderModeType } from 'components/Loader';
import { PageTitle } from 'components/PageTitle';
import { TotemInput } from 'components/TotemInput';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { TotemTextArea } from 'components/TotemTextArea';
import { SelectedOption, TotemSelect } from 'components/TotemSelect';
import { DeeplinkSelector } from './components/DeeplinkSelector';

export const NotificationsUser = () => {
    const [notificationCategoryOption, setNotificationCategoryOption] =
        useState<SelectedOption<NotificationCategory>>(null);
    const [deeplink, setDeeplink] = useState<string>();
    const [userIdsString, setUserIdsString] = useState<string>('');
    const [title, setTitle] = useState<string>('');
    const [body, setBody] = useState<string>('');
    const [slug, setSlug] = useState<string>('');

    const [sendNotificationToUsers, { loading: sendNotificationToUsersLoading }] = useMutation<
        SEND_NOTIFICATION_TO_USERS,
        SEND_NOTIFICATION_TO_USERSVariables
    >(SEND_NOTIFICATION_TO_USERS_MUTATION, {
        onCompleted: ({ sendNotificationToUsers }) => {
            if (sendNotificationToUsers) {
                setTitle('');
                setBody('');
                setSlug('');
                toast.success("La notification s'est bien envoyée");
            } else {
                toast.error("Une erreur est survenue pendant l'envoi de la notification");
            }
        },
    });

    function formatIdsStringToArray(idsString: string): string[] {
        return idsString
            .replace(/( |\n|\t)/gm, '')
            .split(',')
            .filter((id) => id);
    }

    function handleNotificationCategorySelect(option: SelectedOption<NotificationCategory>) {
        setNotificationCategoryOption(option);
    }

    async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        const userIdsArray = formatIdsStringToArray(userIdsString);

        if (!userIdsArray.length) {
            toast.error("Veuillez renseigner au moins un id d'utilisateur");
            return;
        }
        if (!title && !body) {
            toast.error('Veuillez renseigner un champ parmi titre et message');
            return;
        }
        if (!slug) {
            toast.error('Veuillez renseigner un identifiant (slug)');
            return;
        }

        const notificationCategory = notificationCategoryOption?.value;

        await sendNotificationToUsers({
            variables: { userIds: userIdsArray, title, body, notificationCategory, deeplink, slug },
        });
    }

    const notificationCategoriesOptions = (Object.keys(NotificationCategory) as Array<NotificationCategory>).map(
        (key) => ({
            value: key,
            label: getTranslatedNotificationCategory(key),
        }),
    );

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.notifications} />
                </HeaderTitle>

                <Link to="/notifications">
                    <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                </Link>
            </Header>
            <Form onSubmit={handleSubmit}>
                <Message>Renseignez les ids des utilisateurs pour leur envoyer une notification</Message>
                <TotemTextArea
                    label="Ids des utilisateurs"
                    value={userIdsString}
                    placeholder="Ids des utilisateurs séparés par une virgule, example : 6fc4bbfc33908a0012f85183, 6eb191db05e030001187c95a"
                    onChange={(value) => setUserIdsString(value)}
                    rows={10}
                />
                <TotemSelect
                    label="Catégorie de notification"
                    placeholder="Sélectionner une catégorie"
                    value={notificationCategoryOption}
                    options={notificationCategoriesOptions}
                    onChange={handleNotificationCategorySelect}
                />
                <TotemInput
                    label="Titre de la notif"
                    value={title}
                    placeholder="Titre"
                    onChange={(value) => setTitle(value)}
                />
                <TotemTextArea
                    label="Message de la notif"
                    value={body}
                    placeholder="Message"
                    onChange={(value) => setBody(value)}
                    rows={5}
                />
                <TotemInput
                    label="Identifiant unique (slug)"
                    value={slug}
                    placeholder="daily-delivery"
                    onChange={(value) => setSlug(value)}
                />
                <DeeplinkSelector setDeeplink={setDeeplink} />
                <SubmitButton disabled={sendNotificationToUsersLoading || !userIdsString || (!title && !body) || !slug}>
                    Envoyer une notif
                </SubmitButton>
                {sendNotificationToUsersLoading ? <Loader mode={LoaderModeType.Spin} /> : null}
            </Form>
        </Container>
    );
};

const Container = styled.form`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 30px;
    overflow-y: scroll;

    & > :not(:first-child) {
        margin-top: 20px;
    }
`;

const SubmitButton = styled(TotemPrimaryButton)`
    margin-top: 20px;
`;

const Message = styled.span`
    text-align: center;
    color: ${({ theme }) => theme.textColor};
`;

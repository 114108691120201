import React, { useEffect, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { CREATE_MICROSTORE_COLUMN_MUTATION } from 'data/mutations/microstoreColumn';
import {
    CREATE_MICROSTORE_COLUMN,
    CREATE_MICROSTORE_COLUMNVariables,
} from 'data/mutations/__generated__/CREATE_MICROSTORE_COLUMN';
import { GET_MICROSTORE_COLUMN_TEMPLATES } from 'data/queries/__generated__/GET_MICROSTORE_COLUMN_TEMPLATES';
import { GET_MICROSTORE_COLUMN_TEMPLATES_QUERY } from 'data/queries/microstoreColumnTemplate';
import { GET_MICROSTORE_SITES_microstoreSitesWithColumnGroups_microstoreColumnGroups } from 'data/queries/__generated__/GET_MICROSTORE_SITES';

import { Loader, LoaderModeType } from 'components/Loader';
import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { TotemSelect } from 'components/TotemSelect';

export function CreateMicrostoreColumnPopup({
    isOpen,
    setIsOpen,
    columnGroup,
}: {
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
    columnGroup: GET_MICROSTORE_SITES_microstoreSitesWithColumnGroups_microstoreColumnGroups;
}) {
    const [positionInColumnGroup, setPositionInColumnGroup] = useState<number>(columnGroup.columns.length);
    const [microstoreColumnTemplateId, setMicrostoreColumnTemplateId] = useState<string | null>(null);

    useEffect(() => {
        setPositionInColumnGroup(columnGroup.columns.length);
    }, [setPositionInColumnGroup, columnGroup]);

    const {
        loading: microstoreColumnTemplateLoading,
        data: microstoreColumnTemplateData,
        error: microstoreColumnTemplateError,
    } = useQuery<GET_MICROSTORE_COLUMN_TEMPLATES>(GET_MICROSTORE_COLUMN_TEMPLATES_QUERY);

    const [createMicrostoreColumn, { loading: creationLoading }] = useMutation<
        CREATE_MICROSTORE_COLUMN,
        CREATE_MICROSTORE_COLUMNVariables
    >(CREATE_MICROSTORE_COLUMN_MUTATION);

    if (microstoreColumnTemplateLoading) {
        return (
            <TotemPopup title="Ajouter une colonne" isOpen={isOpen} setIsOpen={setIsOpen} contentOverflow="visible">
                <Loader />
            </TotemPopup>
        );
    }

    if (microstoreColumnTemplateError || !microstoreColumnTemplateData) {
        throw new Error('Une erreur est survenue lors de la récupération des templates de colonne microstore');
    }

    async function handleSubmit() {
        if (!microstoreColumnTemplateId) {
            return;
        }

        const { data } = await createMicrostoreColumn({
            variables: {
                createMicrostoreColumnInput: {
                    microstoreColumnTemplateId,
                    positionInColumnGroup,
                    columnGroupId: columnGroup._id,
                },
            },
        });

        if (!data) {
            throw new Error('Une erreur est survenue lors de la creation du template de colonne');
        }

        const {
            createMicrostoreColumnMutation: { errors },
        } = data;

        if (errors.length) {
            errors.forEach((error, index) => {
                toast.error(<span key={index}>Erreur : {error}</span>, { autoClose: false });
            });

            if (errors.length > 1) {
                toast.info('Cliquez pour fermer toutes les notifications', {
                    autoClose: false,
                    onClick: () => toast.dismiss(),
                });
            }
        }

        setIsOpen(false);
    }

    const { microstoreColumnTemplatesQuery: microstoreColumnTemplates } = microstoreColumnTemplateData;

    const microstoreColumnTemplateOptions =
        microstoreColumnTemplates
            .map(({ _id: microstoreColumnTemplateId, name }) => ({
                value: microstoreColumnTemplateId,
                label: name,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)) || [];

    const positionInColumnGroupOptions = Array(columnGroup.columns.length + 1)
        .fill(undefined)
        .map((_, index) => ({
            value: index,
            label: `${index + 1}`,
        }));

    return (
        <TotemPopup title="Ajouter une colonne" isOpen={isOpen} setIsOpen={setIsOpen} contentOverflow="visible">
            <SubmitContainer>
                <TotemSelect<number>
                    label="Position de la colonne"
                    placeholder="Sélectionner la position de la colonne"
                    value={positionInColumnGroupOptions.find(
                        (positionInColumnGroupOption) => positionInColumnGroup === positionInColumnGroupOption.value,
                    )}
                    options={positionInColumnGroupOptions}
                    onChange={(option) => {
                        if (option) {
                            setPositionInColumnGroup(option.value);
                        }
                    }}
                />
                <TotemSelect
                    label="Template de colonne microstore"
                    placeholder="Sélectionner le template de colonnes"
                    value={microstoreColumnTemplateOptions.find(
                        (microstoreColumnTemplateOption) =>
                            microstoreColumnTemplateId === microstoreColumnTemplateOption.value,
                    )}
                    options={microstoreColumnTemplateOptions}
                    onChange={(option) => {
                        if (option) {
                            setMicrostoreColumnTemplateId(option.value);
                        }
                    }}
                />
                <TotemPrimaryButton onClick={handleSubmit} disabled={!microstoreColumnTemplateId}>
                    {creationLoading ? <Loader size="20px" mode={LoaderModeType.Spin} /> : 'Confirmer'}
                </TotemPrimaryButton>
            </SubmitContainer>
        </TotemPopup>
    );
}

const SubmitContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 15px;

    > :not(:first-child) {
        margin-top: 15px;
    }
`;

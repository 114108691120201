import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import styled from 'styled-components';

import { CREATE_ROUNDS_WITH_PRESETS_MUTATION } from 'data/mutations/urbantzRoundPreset';
import {
    CREATE_ROUNDS_WITH_PRESETS,
    CREATE_ROUNDS_WITH_PRESETSVariables,
} from 'data/mutations/__generated__/CREATE_ROUNDS_WITH_PRESETS';

import { Loader, LoaderModeType } from 'components/Loader';
import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { TotemDatePicker } from 'components/TotemDatePicker';

import { formatDateAsAnniversary } from 'helpers/dateTimes';

export function CreateRoundsWithPresetsPopup({
    isOpen,
    setIsOpen,
}: {
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
}) {
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());

    const [createRoundsWithPresets, { loading: creationLoading }] = useMutation<
        CREATE_ROUNDS_WITH_PRESETS,
        CREATE_ROUNDS_WITH_PRESETSVariables
    >(CREATE_ROUNDS_WITH_PRESETS_MUTATION);

    async function handleSubmit() {
        const { data } = await createRoundsWithPresets({
            variables: { birthdayDate: formatDateAsAnniversary({ dateTime: selectedDate, useNewFormat: true }) },
        });

        if (!data?.createRoundsWithPresets) {
            throw new Error('Une erreur est survenue lors de la création de la tournée prédéfinie');
        }

        setIsOpen(false);
    }

    return (
        <TotemPopup
            title="Ajouter une tournée prédéfinie"
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            contentOverflow="visible"
        >
            <TotemDatePicker
                label="Date de livraison"
                selected={selectedDate}
                onChange={(date) => {
                    if (date) {
                        setSelectedDate(date);
                    }
                }}
                isClearable={false}
            />
            <SubmitContainer>
                <ValidationButton onClick={handleSubmit}>
                    {creationLoading ? <Loader size="20px" mode={LoaderModeType.Spin} /> : 'Confirmer'}
                </ValidationButton>
            </SubmitContainer>
        </TotemPopup>
    );
}

const SubmitContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 15px;
`;

const ValidationButton = styled(TotemPrimaryButton)`
    margin-top: 15px;
`;

const DEFAULT_ERROR_MESSAGE = 'Une erreur est survenue.';

export function getErrorAsString(error: unknown, defaultMessage: string = DEFAULT_ERROR_MESSAGE): string {
    if (error instanceof Error) {
        return error.message;
    }

    if (typeof error === 'string') {
        return error;
    }

    return defaultMessage;
}

import React from 'react';
import { useFormContext } from 'react-hook-form';

import styled from 'styled-components';
import { FcQuestions } from 'react-icons/fc';

import { GET_DETAILED_ORDER_detailedOrder } from 'data/queries/__generated__/GET_DETAILED_ORDER';
import { OrderState } from 'data/__generated__';

import { ColumnsSectionContainer, SectionColumn, SectionContainer } from 'components/DetailsView/Section';
import { DetailFormDatePicker, DetailFormTextArea, StateHistory } from 'components/DetailsView';

import { StatusTag } from 'pages/Orders/components/StatusTag';

export const StatesSection = ({ order }: { order: GET_DETAILED_ORDER_detailedOrder }) => {
    const { register, control } = useFormContext();
    const today = new Date();

    const { pausedInfo, state, stateHistory } = order;
    const formattedStateHistory = stateHistory.map((stateRecord) => ({
        ...stateRecord,
        state: <StatusTag state={stateRecord.state} />,
    }));

    return (
        <SectionContainer title="Information des statuts" isInitiallyOpen>
            <ColumnsSectionContainer numberOfColumns={2}>
                <SectionColumn>
                    <StateHistory records={formattedStateHistory} />
                    <Link
                        href="https://www.notion.so/thetotem/Les-commandes-orders-orders-products-1e95003fc0ca41f1a2baa9684862a2b7"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FcQuestions size={24} />
                        Comprendre les statuts d'une commande
                    </Link>
                </SectionColumn>
                <SectionColumn>
                    <StatusTag state={state} showHeading />
                    {state === OrderState.Paused && pausedInfo ? (
                        <>
                            <DetailFormDatePicker
                                label="Date de retour"
                                placeholder="Date de retour"
                                defaultValue={pausedInfo.returnDate}
                                name="pausedInfo.returnDate"
                                control={control}
                                minDate={today}
                            />
                            <DetailFormTextArea
                                label="Motif"
                                defaultValue={pausedInfo.reason}
                                {...register('pausedInfo.reason', { required: true })}
                                width="max-content"
                            />
                        </>
                    ) : null}
                </SectionColumn>
            </ColumnsSectionContainer>
        </SectionContainer>
    );
};

const Link = styled.a`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.ctaPrimaryColor};
`;

import React from 'react';
import styled from 'styled-components';

import { useMutation } from '@apollo/client';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useForm, FormProvider } from 'react-hook-form';

import { PAGES } from 'constants/pages';

import { Header, HeaderTitle } from 'components/Header';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { PageTitle } from 'components/PageTitle';
import { DetailFormValue } from 'components/DetailsView';
import { CREATE_TOTEAM_USER_MUTATION } from 'data/mutations/user';
import { Loader, LoaderModeType } from 'components/Loader';

type FormData = {
    emailAddress: string;
    firstname: string;
    lastname: string;
};

export const CreateToteamUser = () => {
    const methods = useForm<FormData>();
    const navigate = useNavigate();
    const [createToteamUser, { loading }] = useMutation(CREATE_TOTEAM_USER_MUTATION);
    const {
        handleSubmit,
        register,
        formState: { isDirty },
    } = methods;

    const onSubmit = handleSubmit(async (fields) => {
        const { emailAddress, firstname, lastname } = fields;

        if (emailAddress.includes('+admin@totem.co')) {
            toast.error(
                'Attention - ne pas utiliser d\'adresse "+admin@totem.co" mais uniquement ajouter le role global "Toteam"',
                {
                    autoClose: false,
                },
            );
            return;
        }
        const { data } = await createToteamUser({ variables: { firstname, lastname, emailAddress } });
        if (data) {
            const {
                createToteamUserResolver: { success, error, user },
            } = data;
            if (success) {
                toast.success(`L'utilisateur ${firstname} ${lastname} a bien été créé ou mis à jour`);
                navigate(`/user/${user._id}`);
            } else {
                if (error) {
                    toast.error(error);
                } else {
                    throw Error("Une erreur inconnue s'est produite");
                }
            }
        } else {
            throw Error("Une erreur inconnue s'est produite");
        }
    });

    return (
        <Container>
            <FormProvider {...methods}>
                <Form onSubmit={onSubmit}>
                    <Header>
                        <HeaderTitle>
                            <PageTitle page={PAGES.userCreateToteam} />
                        </HeaderTitle>
                        <Link to="/users">
                            <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                        </Link>
                    </Header>
                    <Content>
                        <ScrollableContent>
                            <DetailFormValue label="Prénom" {...register('firstname', { required: true })} />
                            <DetailFormValue label="Nom" {...register('lastname', { required: true })} />
                            <DetailFormValue label="Adresse mail" {...register('emailAddress', { required: true })} />
                            <ButtonContainer>
                                <TotemPrimaryButton minWidth="140px" type="submit" disabled={!isDirty}>
                                    {loading ? (
                                        <Loader size="18px" mode={LoaderModeType.Spin} />
                                    ) : (
                                        <>Créer utilisateur Toteam</>
                                    )}
                                </TotemPrimaryButton>
                            </ButtonContainer>
                        </ScrollableContent>
                    </Content>
                </Form>
            </FormProvider>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
`;

const Content = styled.div`
    flex: 1;
    overflow: hidden;
`;
const ScrollableContent = styled.div`
    padding: 15px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 15px;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

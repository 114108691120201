import { colors } from 'constants/colors';
import React, { useState } from 'react';
import styled from 'styled-components';
import { TotemLightBox } from './TotemLightBox';

export type TotemGalleryImage = {
    url: string;
    label?: string;
};

export function TotemGallery({ images }: { images: TotemGalleryImage[] }) {
    const [isLightBoxOpen, setIsLightBoxOpen] = useState(false);
    const [initialImageIndex, setInitialImageIndex] = useState(0);

    return (
        <>
            <Container>
                {images.map(({ label, url }, index) => (
                    <ImageContainer key={index}>
                        <Image
                            src={url}
                            onClick={() => {
                                setInitialImageIndex(index);
                                setIsLightBoxOpen(true);
                            }}
                        />
                        {label ? <ImageLabel>{label}</ImageLabel> : null}
                    </ImageContainer>
                ))}
                <TotemLightBox
                    images={images}
                    isOpen={isLightBoxOpen}
                    setIsOpen={setIsLightBoxOpen}
                    initialImageIndex={initialImageIndex}
                />
            </Container>
        </>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const ImageContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 10vw;
    max-height: 40vh;
    overflow: hidden;
    margin: 2px;
`;

const ImageLabel = styled.div`
    display: none;
    position: absolute;
    bottom: 15%;
    left: 0;
    right: 0;
    text-align: center;
    color= ${colors.pureBlack};
    background-color: ${colors.pureWhite};
`;

const Image = styled.img`
    cursor: pointer;
    object-fit: contain;
    width: 100%;
    height: 100%;

    &:hover + ${ImageLabel} {
        display: block;
    }
`;

import React from 'react';

import styled from 'styled-components';

import { TotemLabel } from '../TotemLabel';

import { getExtendedFormattedDatetime } from '../../helpers/dateTimes';

type StateRecord = {
    state: JSX.Element;
    createdAt: any;
    createdBy?: string | null;
    createdByName?: string | null;
};

function getCreatorName(createdBy?: string | null, createdByName?: string | null) {
    return createdByName || createdBy || 'Inconnu';
}

export const StateHistory = ({ label, records }: { label?: string; records: StateRecord[] }) => {
    const history = [...records].reverse();
    return (
        <Container>
            {label ? <TotemLabel>{label}</TotemLabel> : null}
            <table>
                <thead>
                    <tr>
                        <th>Statut</th>
                        <th>Date de création</th>
                        <th>Créateur.ice</th>
                    </tr>
                </thead>
                <tbody>
                    {history.map(({ state, createdAt, createdBy, createdByName }, index) => {
                        return (
                            <tr key={index}>
                                <td>{state}</td>
                                <td>{getExtendedFormattedDatetime(createdAt)}</td>
                                <td>{getCreatorName(createdBy, createdByName)}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;

    & > :not(:first-child) {
        margin-top: 5px;
    }

    table {
        border-spacing: 0;
        border-collapse: collapse;
        color: ${({ theme }) => theme.textColor};

        th,
        td {
            text-align: left;
            border: 1px solid ${({ theme }) => theme.lightBorderColor};
            border-spacing: 1px;
        }

        th {
            padding: 10px;
        }

        td {
            padding: 5px;
        }
    }
`;

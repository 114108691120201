import { SetupInstructionType } from 'data/__generated__';

export const SETUP_INSTRUCTION_TYPE_LABELS: Record<SetupInstructionType, string> = {
    [SetupInstructionType.CheckTemperature]: 'Température frigo',
    [SetupInstructionType.CheckBoxTemperature]: 'Température caisse fraîche',
    [SetupInstructionType.HotDrinks]: 'Rangement machine à café',
    [SetupInstructionType.InventoryComplete]: 'Inventaire Complet',
    [SetupInstructionType.InventoryCutlery]: 'Inventaire Couverts',
    [SetupInstructionType.InventoryDry]: 'Inventaire Dry',
    [SetupInstructionType.InventorySmallExpiryDate]: 'Inventaire Petites DLCs',
    [SetupInstructionType.InventoryZero]: 'Inventaire des Zéros',
    [SetupInstructionType.Microwave]: 'Nettoyage micro-ondes',
    [SetupInstructionType.MoveProducts]: 'Changer de place les produits',
    [SetupInstructionType.Photos]: 'Photos',
    [SetupInstructionType.RemoveProducts]: 'Retirer les produits',
    [SetupInstructionType.StoreProducts]: 'Mettre les nouveaux produits',
    [SetupInstructionType.TheftNotice]: 'Écriteaux emplacements vides',
};

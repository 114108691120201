import gql from 'graphql-tag';
import { WAREHOUSE_RETURN_FRAGMENT } from 'data/fragments/warehouseReturn';

export const WAREHOUSE_RETURN_CREATED_OR_UPDATED_SUBSCRIPTION = gql`
    subscription WAREHOUSE_RETURN_CREATED_OR_UPDATED($warehouseReturnId: ID) {
        warehouseReturnCreatedOrUpdated(warehouseReturnId: $warehouseReturnId) {
            ...WarehouseReturnFragment
        }
    }
    ${WAREHOUSE_RETURN_FRAGMENT}
`;

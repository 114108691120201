import { OrderState } from 'data/__generated__';
import { colors } from 'constants/colors';

export const ORDER_STATES_LABELS = {
    [OrderState.Archived]: 'Archivée',
    [OrderState.Created]: 'Créée',
    [OrderState.Validated]: 'Validée',
    [OrderState.Paused]: 'En pause',
    [OrderState.InProgress]: 'En cours',
    [OrderState.Ready]: 'Prête',
};

export const getColorsFromState = (state: OrderState) => {
    const { yellow, blue, pantinGrey, green, pureWhite, lightGrey, black } = colors;

    switch (state) {
        case OrderState.Archived:
        case OrderState.Paused:
            return { backgroundColor: pantinGrey, labelColor: pureWhite };
        case OrderState.Created:
            return { backgroundColor: lightGrey, labelColor: black };
        case OrderState.Validated:
            return { backgroundColor: blue, labelColor: pureWhite };
        case OrderState.Ready:
            return { backgroundColor: green, labelColor: black };
        case OrderState.InProgress:
            return { backgroundColor: yellow, labelColor: black };
    }
};

export const MODIFIABLE_ORDER_STATES = [
    OrderState.Created,
    OrderState.InProgress,
    OrderState.Validated,
    OrderState.Paused,
];

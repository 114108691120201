import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { PAGES } from '../../constants/pages';

import { FieldToDisplay, ListView } from 'components/ListView';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { Header, HeaderTitle } from 'components/Header';
import { Loader } from 'components/Loader';

import { PageTitle } from 'components/PageTitle';
import { StatusTag } from 'pages/Subventions/components/StatusTag';
import { TotemInput } from 'components/TotemInput';
import { useGetSubventionsQuery } from 'data/__generated__';
import { getPeriodLabel } from './constants/states';

type FormattedSubventionType = {
    _id: string;
    name: string;
    amount: string;
    organizationName: string;
    state: JSX.Element;
};

export const Subventions = () => {
    const [filterString, setFilterString] = useState('');
    const { loading: subventionsLoading, data: subventionsData, error: subventionsError } = useGetSubventionsQuery();

    if (subventionsLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (!subventionsData || subventionsError) {
        throw new Error('Une erreur est survenue lors de la récupération des subventions');
    }

    const { subventions } = subventionsData;

    const SUBVENTIONS_FIELDS_TO_DISPLAY: FieldToDisplay<FormattedSubventionType>[] = [
        {
            fieldName: 'name',
            label: 'Nom',
        },
        { fieldName: 'amount', label: 'Montant' },
        { fieldName: 'organizationName', label: 'Organisation' },
        { fieldName: 'state', label: 'Statut' },
    ];

    const filteredSites = subventions.filter(({ name }) =>
        filterString ? name.toLowerCase().includes(filterString.toLowerCase()) : true,
    );

    const formattedSubventions: FormattedSubventionType[] = filteredSites.map(
        ({ _id, name, archivedAt, organization, reload, currentAmount }) => ({
            _id,
            name,
            amount: currentAmount
                ? `${currentAmount.toFixed(0)} € ${reload ? '/ ' + getPeriodLabel(reload) : ''}`
                : 'illimité',
            organizationName: organization?.name || 'sans organisation',
            state: <StatusTag active={!archivedAt} />,
        }),
    );

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.subventions} />
                </HeaderTitle>
                <Link to="/subvention/create">
                    <TotemPrimaryButton>Créer une subvention</TotemPrimaryButton>
                </Link>
            </Header>
            <Content>
                <TotemInput
                    label="Recherche"
                    onChange={setFilterString}
                    placeholder="Nom de la subvention"
                    value={filterString}
                />
                <ListView<FormattedSubventionType>
                    fieldsToDisplay={SUBVENTIONS_FIELDS_TO_DISPLAY}
                    items={formattedSubventions}
                    linkBasePath="/subvention/"
                    keyExtractor={(item) => item._id}
                />
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Content = styled.div`
    padding: 15px;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

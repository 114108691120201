import { gql } from '@apollo/client';

export const GET_LOCATIONS_QUERY = gql`
    query GET_LOCATIONS {
        locations {
            _id
            index
            state
            datamatrix
            productId
            productName
            zone
        }
    }
`;

export const GET_LOCATIONS_FOR_SELECTION_QUERY = gql`
    query GET_LOCATIONS_FOR_SELECTION {
        locationSelectOptions {
            _id
            productId
            datamatrix
            state
        }
    }
`;

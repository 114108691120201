import React from 'react';
import { useFormContext } from 'react-hook-form';
import validator from 'validator';

import { OrderMode } from 'data/__generated__';

import { ORDER_MODE_LABELS } from '../constants/labels';

import { SectionColumn, SectionContainer, ColumnsSectionContainer } from 'components/DetailsView/Section';
import {
    DetailFormArrayValue,
    DetailFormCheckbox,
    DetailFormSelect,
    DetailFormValue,
    DocumentationLink,
} from 'components/DetailsView';

export const OrderingMode = ({
    supplier,
}: {
    supplier: { orderMode: OrderMode; shouldOrderAutomatically: boolean; shouldCheckReceptionDayIsNotClosed: boolean };
}) => {
    const { orderMode, shouldOrderAutomatically, shouldCheckReceptionDayIsNotClosed } = supplier;
    const { register, watch } = useFormContext();
    const watchOrderMode: undefined | OrderMode = watch('orderMode', orderMode);

    const orderModeOptions = [
        {
            label: ORDER_MODE_LABELS[OrderMode.Email],
            value: OrderMode.Email,
        },
        {
            label: ORDER_MODE_LABELS[OrderMode.Platform],
            value: OrderMode.Platform,
        },
    ];

    return (
        <SectionContainer title="Mode d'achat" isInitiallyOpen>
            <ColumnsSectionContainer numberOfColumns={3}>
                <SectionColumn>
                    <DetailFormSelect<string>
                        label="Mode d'achat"
                        placeholder="Sélectionner un mode d'achat"
                        name="orderMode"
                        defaultValue={orderMode || OrderMode.Email}
                        options={orderModeOptions}
                        required={true}
                        dataTest="supplier-orderMode"
                    />
                    <DetailFormArrayValue
                        name="orderEmails"
                        label="Adresses emails de commande"
                        disabled={watchOrderMode !== OrderMode.Email}
                        data-test="supplier-orderEmails"
                        validateValueFunction={(email: string) =>
                            watchOrderMode === OrderMode.Email
                                ? validator.isEmail(email) || 'Veuillez indiquer un email valide'
                                : true
                        }
                    />
                    <DetailFormValue
                        label="Url de l'application web de l'application mobile"
                        {...register('orderPlatformUrl', {
                            required: watchOrderMode === OrderMode.Platform,
                            validate: (url) =>
                                watchOrderMode === OrderMode.Platform
                                    ? validator.isURL(url) || 'Veuillez indiquer une URL valide'
                                    : true,
                        })}
                        disabled={watchOrderMode !== OrderMode.Platform}
                        data-test="supplier-orderPlatformUrl"
                    />
                </SectionColumn>
                <SectionColumn>
                    <DetailFormValue
                        label="Indice de risque"
                        sublabel="Entre 1 (Fiable) et 3 (Retards systématiques), pris en compte dans le calcul du stock de réserve"
                        type="number"
                        step="0.1"
                        min="1"
                        max="3"
                        {...register('frequency', {
                            required: true,
                            valueAsNumber: true,
                        })}
                        data-test="supplier-riskFactor"
                    />
                    <DocumentationLink
                        src="https://www.notion.so/thetotem/Stock-de-R-serve-d2c491f605ff44a1a1a8db55305881ec"
                        name="comprendre le stock de réserve"
                    />
                    <DetailFormValue label="Code client" {...register('clientCode')} data-test="supplier-clientCode" />
                </SectionColumn>
                <SectionColumn>
                    <DetailFormCheckbox
                        name="shouldOrderAutomatically"
                        label="Envoi d'email automatique"
                        sublabel="Envoyer un email d'achat automatiquement si le franco est atteint"
                        defaultChecked={shouldOrderAutomatically}
                    />
                    <DetailFormCheckbox
                        name="shouldCheckReceptionDayIsNotClosed"
                        label="Ne peut pas être reçu un jour fermé."
                        defaultChecked={shouldCheckReceptionDayIsNotClosed}
                    />
                </SectionColumn>
            </ColumnsSectionContainer>
        </SectionContainer>
    );
};

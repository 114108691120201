import { ENV_CYPRESS, ENV_DEV, ENV_PREPROD, ENV_PROD } from '../../constants/environment';
import { logError } from '../../helpers/logger';

export function getApiUrl() {
    switch (window.__TOTENV__.ENV) {
        case ENV_PROD:
            return 'https://graphql.totem.co';
        case ENV_PREPROD:
            return 'https://graphql-test.totem.co';
        case ENV_DEV:
        case ENV_CYPRESS:
            return 'http://localhost:4000';
        default: {
            const message = 'Unhandled ENV';
            const tags = { env: window.__TOTENV__.ENV || 'Not defined' };
            logError({ message, tags });
            throw Error('Unhandled ENV');
        }
    }
}

export function getGraphQLUrl() {
    return `${getApiUrl()}/graphql`;
}

export function getGraphQLWebSocketUrl() {
    switch (window.__TOTENV__.ENV) {
        case ENV_PROD:
            return 'wss://graphql.totem.co/graphql';
        case ENV_PREPROD:
            return 'wss://graphql-test.totem.co/graphql';
        case ENV_DEV:
        case ENV_CYPRESS:
            return 'ws://localhost:4000/graphql';
        default: {
            const message = 'Unhandled ENV';
            const tags = { env: window.__TOTENV__.ENV || 'Not defined' };
            logError({ message, tags });
            throw Error('Unhandled ENV');
        }
    }
}

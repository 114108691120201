import React from 'react';

import styled from 'styled-components';

import { PAGES } from '../../constants/pages';

import { FieldToDisplay, ListView } from 'components/ListView';
import { Header, HeaderTitle } from 'components/Header';
import { Loader } from 'components/Loader';

import { PageTitle } from 'components/PageTitle';

import { BirthdayDate } from 'helpers/dateTimes';
import { useGetBakeryRecordsQuery } from 'data/__generated__';

type FormattedBakeryRecordType = {
    _id: string;
    fullname: string;
    useDate: BirthdayDate;
    quantityUsed: number;
    serialNumber: string;
};

export const BakeryRecords = () => {
    const {
        loading: bakeryRecordsLoading,
        data: bakeryRecordsData,
        error: bakeryRecordsError,
    } = useGetBakeryRecordsQuery();
    if (bakeryRecordsLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (!bakeryRecordsData || bakeryRecordsError) {
        throw new Error('Une erreur est survenue lors de la récupération des bakeryRecords');
    }

    const { bakeryRecords } = bakeryRecordsData;

    const BAKERY_RECORDS_FIELDS_TO_DISPLAY: FieldToDisplay<FormattedBakeryRecordType>[] = [
        {
            fieldName: 'fullname',
            label: 'Nom de produit',
        },
        { label: 'Date de cuisson', fieldName: 'useDate' },
        { label: 'Quantités cuites', fieldName: 'quantityUsed' },
        { label: 'Nº du carton', fieldName: 'serialNumber' },
    ];

    const formattedBakeryRecords: FormattedBakeryRecordType[] = bakeryRecords.map(
        ({ _id, fullname, useDate, quantityUsed, serialNumber }) => ({
            _id,
            fullname,
            useDate,
            quantityUsed,
            serialNumber,
        }),
    );

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.bakeryRecords} />
                </HeaderTitle>
            </Header>
            <Content>
                <ListView<FormattedBakeryRecordType>
                    fieldsToDisplay={BAKERY_RECORDS_FIELDS_TO_DISPLAY}
                    items={formattedBakeryRecords}
                    keyExtractor={(item) => item._id}
                />
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Content = styled.div`
    padding: 15px;
    flex: 1;
    overflow: hidden;
`;

import React from 'react';
import { useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { GetPolls } from 'data/queries/__generated__/GetPolls';
import { GET_POLLS_QUERY } from 'data/queries/poll';

import { FieldToDisplay, ListView } from 'components/ListView';
import { Loader } from 'components/Loader';
import { getExtendedFormattedDatetime } from 'helpers/dateTimes';

type PollTable = {
    _id: string;
    createdAt: string;
    numberOfParticipants: number;
    question: string;
};

export const PollsList = () => {
    const {
        loading: pollsLoading,
        error: pollsError,
        data: pollsData,
        fetchMore: fetchMorePolls,
    } = useQuery<GetPolls>(GET_POLLS_QUERY, {
        variables: {
            offset: 0,
        },
        fetchPolicy: 'cache-and-network',
    });

    const POLLS_FIELDS_TO_DISPLAY: FieldToDisplay<PollTable>[] = [
        { fieldName: 'createdAt', label: 'Date de création' },
        {
            fieldName: 'question',
            label: 'Question',
        },
        {
            fieldName: 'numberOfParticipants',
            label: 'Participations',
        },
    ];

    if (pollsLoading && !pollsData) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (pollsError || !pollsData) {
        toast.error('Une erreur est survenue lors de la récupération des sondages');
        return null;
    }

    const {
        polls: { hasMore, polls },
    } = pollsData;

    const sortedPolls = [...polls].sort((pollA, pollB) => pollB.createdAt - pollA.createdAt);

    const formattedPolls: PollTable[] = sortedPolls.map(({ _id, createdAt, question, numberOfParticipants }) => ({
        _id,
        question,
        numberOfParticipants,
        createdAt: getExtendedFormattedDatetime(createdAt),
    }));

    const loadMore = async () => {
        await fetchMorePolls({
            variables: {
                offset: polls.length,
            },
        });
    };

    return (
        <Container>
            {formattedPolls.length ? (
                <ListView<PollTable>
                    fieldsToDisplay={POLLS_FIELDS_TO_DISPLAY}
                    items={formattedPolls}
                    loadMore={loadMore}
                    hasMore={hasMore}
                    linkBasePath="/poll/"
                    keyExtractor={(item) => item._id}
                />
            ) : (
                "Aucun sondage n'a été trouvé"
            )}
        </Container>
    );
};

const Container = styled.div`
    flex: 1;
    overflow-y: scroll;
`;

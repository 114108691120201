import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import styled from 'styled-components';

import { PAGES } from '../../constants/pages';

import { GET_SITES } from 'data/queries/__generated__/GET_SITES';
import { GET_SITES_QUERY } from 'data/queries/site';

import { Header, HeaderTitle } from 'components/Header';
import { Loader } from 'components/Loader';
import { PageTitle } from 'components/PageTitle';
import { TotemSelect, SelectedOption } from 'components/TotemSelect';
import { ExpiryDateRecordList } from './ExpiryDateRecordList';

export const ExpiryDateRecords = () => {
    const [selectedSiteOption, setSelectedSiteOption] = useState<SelectedOption<string>>(null);

    const { loading: sitesLoading, data: sitesData, error: sitesError } = useQuery<GET_SITES>(GET_SITES_QUERY);

    if (sitesLoading && !sitesData) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (sitesError || !sitesData) {
        throw new Error('Une erreur est survenue lors de la récupération des sites');
    }

    const sitesOptions = sitesData.sites
        .map((site) => ({ value: site._id, label: site.name }))
        .sort((siteA, siteB) => siteA.label.toLowerCase().localeCompare(siteB.label.toLowerCase()));

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.expiryDateRecords} />
                </HeaderTitle>
            </Header>
            <Content>
                <TotemSelect
                    label="Site"
                    placeholder="Sélectionner un site (recherche par nom ou par _id)"
                    value={selectedSiteOption}
                    options={sitesOptions}
                    onChange={(option) => setSelectedSiteOption(option)}
                />

                {selectedSiteOption?.value ? <ExpiryDateRecordList siteId={selectedSiteOption.value} /> : null}
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    flex: 1;
    overflow: hidden;

    & > :not(:first-child) {
        margin-top: 15px;
    }
`;

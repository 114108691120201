import gql from 'graphql-tag';

export const CREATE_TERMS_RECORD_MUTATION = gql`
    mutation CREATE_TERMS_RECORD($termsOfUse: Upload!) {
        createTermsRecord(termsOfUse: $termsOfUse) {
            success
            error
            termsRecord {
                _id
                createdAt
                termsOfUseUrl
            }
        }
    }
`;

import { CategoryState } from 'data/__generated__';
import { colors } from 'constants/colors';

export const CATEGORY_STATES_LABELS = {
    [CategoryState.Archived]: 'Archivée',
    [CategoryState.Created]: 'Créée',
    [CategoryState.Regular]: 'Regular',
};

export const getColorsFromState = (state: CategoryState) => {
    const { green, pantinGrey, pureWhite, yellow, black } = colors;

    switch (state) {
        case CategoryState.Archived:
            return { backgroundColor: pantinGrey, labelColor: pureWhite };
        case CategoryState.Created:
            return { backgroundColor: yellow, labelColor: black };
        case CategoryState.Regular:
            return { backgroundColor: green, labelColor: black };
    }
};

import React, { useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { PAGES } from '../../constants/pages';
import { AiOutlineInfoCircle } from 'react-icons/ai';

import { GET_SITES_QUERY } from 'data/queries/site';
import { GET_SITES } from 'data/queries/__generated__/GET_SITES';
import {
    CREATE_MULTI_STOCK_VARIATION,
    CREATE_MULTI_STOCK_VARIATIONVariables,
} from 'data/mutations/__generated__/CREATE_MULTI_STOCK_VARIATION';
import { ProductIdAndDeltaInput, StockVariationType } from 'data/__generated__';

import { CREATE_MULTI_STOCK_VARIATION_MUTATION } from 'data/mutations/stockVariation';

import { Footer } from 'components/Footer';
import { Header, HeaderTitle } from 'components/Header';
import { Loader } from 'components/Loader';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { SelectedOption, TotemSelect } from 'components/TotemSelect';
import { TotemInput } from 'components/TotemInput';
import { PageTitle } from 'components/PageTitle';
import { ProductWithStocksSelector } from './components/ProductWithStocksSelector';
import { stockVariationTypeIsNegativeVariation, siteVariationsLabels } from './constants';

type FormType = {
    siteOption: SelectedOption<string>;
    productIdsAndDeltas: (ProductIdAndDeltaInput & { label: string })[];
    explanation: string | null;
    typeOption: SelectedOption<StockVariationType>;
};

export const StockVariationMultiCreate = () => {
    const initialFormData = {
        siteOption: null,
        productIdsAndDeltas: [],
        quantity: null,
        explanation: null,
        typeOption: null,
    };
    const [formData, setFormData] = useState<FormType>(initialFormData);
    const { loading: sitesLoading, data: sitesData, error: sitesError } = useQuery<GET_SITES>(GET_SITES_QUERY);
    const [createStockVariation] = useMutation<CREATE_MULTI_STOCK_VARIATION, CREATE_MULTI_STOCK_VARIATIONVariables>(
        CREATE_MULTI_STOCK_VARIATION_MUTATION,
    );

    if (sitesLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (sitesError || !sitesData) {
        throw new Error('Une erreur est survenue lors de la récupération des sites');
    }

    const sitesOptions = sitesData.sites
        .map((site) => ({ value: site._id, label: site.name }))
        .sort((siteA, siteB) => siteA.label.toLowerCase().localeCompare(siteB.label.toLowerCase()));

    const typeOptions = Object.keys(StockVariationType).map((s) => {
        return {
            label: `${siteVariationsLabels[s as StockVariationType]} (${s})`,
            value: s,
        };
    });

    const handleSiteSelect = (option: SelectedOption<string>) => {
        if (option) {
            const siteOption = option;
            setFormData({ ...formData, siteOption });
        }
    };

    const handleProductSelect = (option: SelectedOption<string>) => {
        if (option) {
            setFormData({
                ...formData,
                productIdsAndDeltas: [
                    ...formData.productIdsAndDeltas,
                    { delta: 0, productId: option.value, label: option.label },
                ],
            });
        }
    };

    const handleTypeSelect = (option: SelectedOption<string>) => {
        if (option) {
            const newProductIdsAndDeltas = formData.productIdsAndDeltas.map(({ delta, productId, label }) => {
                let numberValue = delta;
                const typeValue = option.value as StockVariationType;
                const isNegativeDelta = typeValue && stockVariationTypeIsNegativeVariation[typeValue];

                if ((isNegativeDelta && numberValue > 0) || (!isNegativeDelta && numberValue < 0)) {
                    numberValue = Number(-delta);
                }

                return { delta: numberValue, productId, label };
            });

            setFormData({
                ...formData,
                typeOption: option as SelectedOption<StockVariationType>,
                productIdsAndDeltas: newProductIdsAndDeltas,
            });
        }
    };

    const handleDeltaInput = (value: string, productId: string) => {
        const numberValue = Number(value);
        const isNegativeDelta =
            formData.typeOption && stockVariationTypeIsNegativeVariation[formData.typeOption?.value];

        if ((isNegativeDelta && numberValue > 0) || (!isNegativeDelta && numberValue < 0)) {
            return;
        }

        setFormData({
            ...formData,
            productIdsAndDeltas: formData.productIdsAndDeltas.map((productIdAndDelta) => ({
                ...productIdAndDelta,
                delta: productId === productIdAndDelta.productId ? numberValue : productIdAndDelta.delta,
            })),
        });
    };

    const handleExplanationInput = (value: string) => {
        if (value.trim() !== '' || value === '') {
            setFormData({
                ...formData,
                explanation: value,
            });
        }
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const { siteOption, productIdsAndDeltas, explanation, typeOption } = formData;
        const notNullProductIdsAndDeltas = productIdsAndDeltas.filter(({ delta }) => delta !== 0);
        if (!explanation || !siteOption || !notNullProductIdsAndDeltas.length || explanation === '' || !typeOption) {
            toast.error("L'un des champs n'a pas été renseigné");
            return;
        }
        const { value: siteId, label: siteLabel } = siteOption;
        const { value: type, label: typeLabel } = typeOption;
        const result = await createStockVariation({
            variables: {
                siteId,
                productIdsAndDeltas: notNullProductIdsAndDeltas.map(({ delta, productId }) => ({ delta, productId })),
                explanation,
                type,
            },
        });
        const { data } = result;
        if (data) {
            const { createMultiStockVariation } = data;
            const errors = createMultiStockVariation
                .map(({ error }) => error)
                .filter((error): error is string => !!error);
            if (!errors.length) {
                setFormData(initialFormData);
                toast.success(
                    `La variation ${typeLabel} a bien mis à jour le stock de "${notNullProductIdsAndDeltas.length}" sur le site "${siteLabel}" a bien été mis à jour !`,
                );
            } else {
                if (errors.length) {
                    throw new Error(`L'erreur suivante est survenue : ${errors.join(', ')}`);
                } else {
                    throw new Error("Une erreur inconnue s'est produite");
                }
            }
        } else {
            throw new Error("Une erreur inconnue s'est produite");
        }
    };

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.stockVariationCreate} />
                </HeaderTitle>
            </Header>
            <Content>
                <Form onSubmit={handleSubmit}>
                    <ContentScrollable>
                        <Fields>
                            <div>
                                <AiOutlineInfoCircle /> Une variation de stock est un changement particulier de stock
                                existant (ex: si le stock est de 50 et que l'on achète un coca (variation de stock = 1),
                                le nouveau stock est 49). Pour modifier le stock absolu (ex: 45 au lieu de 50):{' '}
                                <Link to="/siteInventoryStock/create">/siteInventoryStock/create</Link>
                            </div>
                            {!sitesLoading && sitesData ? (
                                <TotemSelect
                                    label="Site"
                                    placeholder="Sélectionner un site (recherche par nom ou par _id)"
                                    value={formData.siteOption}
                                    options={sitesOptions}
                                    onChange={handleSiteSelect}
                                    dataTest="site-selector"
                                />
                            ) : null}

                            <TotemSelect
                                label="Choisir le type de variation de stock"
                                placeholder="Sélectionner un type"
                                value={formData.typeOption}
                                options={typeOptions}
                                onChange={handleTypeSelect}
                                dataTest="product-selector"
                            />

                            {formData.typeOption ? (
                                <TotemInput
                                    label="Pourquoi souhaitez-vous modifier le stock ?"
                                    placeholder="Renseigner la raison de la modification de stock"
                                    type="string"
                                    value={formData.explanation ?? ''}
                                    onChange={(value) => handleExplanationInput(value)}
                                    data-test="stock-variation-explanation"
                                />
                            ) : null}

                            {formData.siteOption ? (
                                <ProductWithStocksSelector
                                    productOption={null}
                                    handleProductSelect={handleProductSelect}
                                    siteId={formData.siteOption.value}
                                />
                            ) : null}

                            {formData.productIdsAndDeltas.map(({ productId, delta, label }) => {
                                return (
                                    <Row key={productId}>
                                        <div>{label}</div>
                                        <TotemInput
                                            label="Quantité"
                                            placeholder="Renseigner le nombre d'unités du produit"
                                            type="number"
                                            value={delta ?? 0}
                                            onChange={(value) => handleDeltaInput(value, productId)}
                                            data-test="product-delta"
                                        />
                                    </Row>
                                );
                            })}
                        </Fields>
                    </ContentScrollable>
                    <Footer>
                        <TotemPrimaryButton data-test="stock-variation-create_submit-button">
                            Ajouter les variations de stock
                        </TotemPrimaryButton>
                    </Footer>
                </Form>
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Content = styled.div`
    flex: 1;
    overflow: hidden;
`;

const Form = styled.form`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ContentScrollable = styled.div`
    padding: 15px;
    width: 100%;
    flex: 1;
    overflow-y: auto;
`;

const Fields = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

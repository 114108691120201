import React from 'react';

import { GET_ALL_PRODUCTS_getAllProducts } from 'data/queries/__generated__/GET_ALL_PRODUCTS';

import { SelectedOption } from 'components/TotemCheckbox';
import { TotemSelect } from 'components/TotemSelect';

import styled from 'styled-components';

export const ProductSelector = ({
    label,
    products,
    productSelectedId,
    onProductSelection,
}: {
    label: string;
    products: GET_ALL_PRODUCTS_getAllProducts[];
    productSelectedId: string | null;
    onProductSelection: (productId: string) => void;
}) => {
    const productsOptions =
        products
            .map(({ _id, fullname }) => ({
                value: _id,
                label: fullname,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)) || [];

    function onSelect(option: SelectedOption) {
        if (!option) {
            return;
        }

        onProductSelection(option.value);
    }

    // Todo use react virtualized or react window with select. Because there are too much products
    return (
        <Container>
            <SelectContainer>
                <TotemSelect
                    label={label}
                    placeholder="Sélectionner un produit (recherche par nom ou par _id)"
                    value={productsOptions.find(({ value }) => value === productSelectedId)}
                    options={productsOptions}
                    onChange={(option: SelectedOption) => onSelect(option)}
                />
            </SelectContainer>
        </Container>
    );
};

const Container = styled.span`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
`;

const SelectContainer = styled.div`
    flex-grow: 1;
`;

import React, { useState } from 'react';

import styled from 'styled-components';

import { PAGES } from '../../constants/pages';

import { Header, HeaderTitle } from 'components/Header';
import { PageTitle } from 'components/PageTitle';

import { TotemDatePicker } from 'components/TotemDatePicker';
import { WarehouseReturnsTrelloView } from './WarehouseReturnsTrelloView';

export const WarehouseReturns = () => {
    const [warehouseReturnsDate, setWarehouseReturnsDate] = useState<Date>(new Date());

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.warehouseReturns} />
                </HeaderTitle>

                <DateContainer>
                    <TotemDatePicker
                        label="Date des retours"
                        selected={warehouseReturnsDate}
                        onChange={(date) => {
                            setWarehouseReturnsDate(date as Date);
                        }}
                    />
                </DateContainer>
            </Header>
            <WarehouseReturnsTrelloView warehouseReturnsDate={warehouseReturnsDate} />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const DateContainer = styled.div`
    min-width: 300px;
    justify-self: end;
`;

import { SUBCATEGORY_FRAGMENT } from 'data/fragments/subcategory';
import gql from 'graphql-tag';

export const GET_SUBCATEGORIES_QUERY = gql`
    query GetSubcategories {
        subcategories {
            ...Subcategory
        }
    }
    ${SUBCATEGORY_FRAGMENT}
`;

export const GET_SUBCATEGORIES_OF_CATEGORY_QUERY = gql`
    query GetSubcategoriesOfCategory($categoryId: ID!) {
        subcategoriesOfCategory(categoryId: $categoryId) {
            ...Subcategory
        }
    }
    ${SUBCATEGORY_FRAGMENT}
`;

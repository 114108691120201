import React from 'react';

import { CommonStatusTag } from 'components/CommonStatusTag';

import { colors } from 'constants/colors';

import { RitualState } from 'data/__generated__';
import { RITUALS_STATES_LABELS } from '../constants/states';

const getColorsFromState = (state: RitualState) => {
    const { green, lightGrey, black, red, yellow, pureWhite } = colors;

    switch (state) {
        case RitualState.Aborted:
            return { backgroundColor: red, labelColor: pureWhite };
        case RitualState.Created:
            return { backgroundColor: yellow, labelColor: black };
        case RitualState.Regular:
            return { backgroundColor: green, labelColor: black };
        case RitualState.Archived:
            return { backgroundColor: lightGrey, labelColor: black };
    }
};

export const StatusTag = ({
    state,
    showHeading,
    sublabel,
}: {
    state: RitualState;
    showHeading?: boolean;
    sublabel?: string;
}) => {
    const { backgroundColor, labelColor } = getColorsFromState(state);
    return (
        <CommonStatusTag
            backgroundColor={backgroundColor}
            labelColor={labelColor}
            label={RITUALS_STATES_LABELS[state]}
            showHeading={showHeading}
            sublabel={sublabel}
        />
    );
};

import { GET_STOCK_TRANSFERS_stockTransfers_stockTransfers } from 'data/queries/__generated__/GET_STOCK_TRANSFERS';

export const sortStockTransfers = (stockTransfers: GET_STOCK_TRANSFERS_stockTransfers_stockTransfers[]) => {
    const sortedStockTransfers = [...stockTransfers];
    return sortedStockTransfers.sort((stockTransferA, stockTransferB) => {
        const [dayA, monthA, yearA] = stockTransferA.transferDate.split('/');
        const [dayB, monthB, yearB] = stockTransferB.transferDate.split('/');

        const isAEarlierThanB = `${yearA}${monthA}${dayA}` < `${yearB}${monthB}${dayB}`;
        const wasAUpdatedEarlierThanB =
            `${yearA}${monthA}${dayA}` === `${yearB}${monthB}${dayB}` &&
            stockTransferA.updatedAt < stockTransferB.updatedAt;

        return isAEarlierThanB || wasAUpdatedEarlierThanB ? 1 : -1;
    });
};

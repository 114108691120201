import React from 'react';
import styled from 'styled-components';
import { useFormContext, useFieldArray } from 'react-hook-form';

import { TotemLabel } from 'components/TotemLabel';

import { MinRequirementGroup } from './MinRequirementGroup';

export const MinRequirements = ({ termIndex }: { termIndex: number }) => {
    const { control } = useFormContext();
    const { fields } = useFieldArray({
        name: `orderingTerms.${termIndex}.minRequirements`,
        control,
    });
    return (
        <>
            {fields?.map(({ id }, requirementGroupIndex) => {
                return (
                    <Container key={id}>
                        <RequirementGroupContainer>
                            <TitleRow>
                                <TotemLabel>Condition #{requirementGroupIndex + 1}</TotemLabel>
                            </TitleRow>
                            <Row>
                                <MinRequirementGroup
                                    termIndex={termIndex}
                                    requirementGroupIndex={requirementGroupIndex}
                                />
                            </Row>
                        </RequirementGroupContainer>
                        {requirementGroupIndex !== fields.length - 1 ? <JoinTotemLabel>OU</JoinTotemLabel> : null}
                    </Container>
                );
            })}
        </>
    );
};

const Container = styled.div``;

const JoinTotemLabel = styled(TotemLabel)`
    margin-left: 15px;
`;

const RequirementGroupContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: ${({ theme }) => theme.borderRadius};
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
    margin: 10px 0;
`;

const Row = styled.div`
    display: flex;
    & > :not(:first-child) {
        margin-left: 10px;
    }
`;

const TitleRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => theme.lightBorderColor};
    padding: 10px 15px;
`;

import React, { useEffect, useState } from 'react';
import { apolloClient } from 'App';
import { useMutation, useQuery } from '@apollo/client';
import { Link, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { FcCancel, FcCheckmark, FcInspection, FcMinus } from 'react-icons/fc';
import Switch from 'react-switch';

import { PAGES } from '../../constants/pages';
import { colors } from 'constants/colors';

import {
    GET_STOCK_TRANSFERS,
    GET_STOCK_TRANSFERSVariables,
    GET_STOCK_TRANSFERS_stockTransfers_stockTransfers,
} from 'data/queries/__generated__/GET_STOCK_TRANSFERS';
import { StockTransferState } from 'data/__generated__';
import {
    VALIDATE_STOCK_TRANSFERS,
    VALIDATE_STOCK_TRANSFERSVariables,
} from 'data/mutations/__generated__/VALIDATE_STOCK_TRANSFERS';
import { STOCK_TRANSFERS_UPDATED_SUBSCRIPTION } from 'data/subscriptions/stockTransfer';
import { GET_STOCK_TRANSFERS_QUERY } from 'data/queries/stockTransfer';
import {
    ABORT_STOCK_TRANSFERS,
    ABORT_STOCK_TRANSFERSVariables,
} from 'data/mutations/__generated__/ABORT_STOCK_TRANSFERS';
import { ABORT_STOCK_TRANSFERS_MUTATION, VALIDATE_STOCK_TRANSFERS_MUTATION } from 'data/mutations/stockTransfer';

import { Header, HeaderTitle } from 'components/Header';
import { PageTitle } from 'components/PageTitle';
import { TotemInput } from 'components/TotemInput';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { StockTransfersList } from './components/StockTransfersList';
import { ActionBar } from 'components/ActionBar';
import { CommonStatusTag } from 'components/CommonStatusTag';
import { Loader } from 'components/Loader';
import { TotemLabel } from 'components/TotemLabel';

import { handleUpdateActionEndToast } from 'pages/SupplyOrders/SupplyOrderDetails/utils/handleUpdateActionEndToast';
import { stockTransfersUpdatedHandler } from './utils/stockTransfersUpdatedHandler';
import { sortStockTransfers } from './utils/sortStockTransfers';
import { ValidateStockTransfersAtDatePopup } from './popups/ValidateStockTransfersAtDatePopup';

export type SiteType = 'Arrival' | 'Departure';

export const StockTransfers = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const [isValidateStockTransfersAtDatePopupOpen, setIsValidateStockTransfersAtDatePopupOpen] =
        useState<boolean>(false);

    const [shouldHideNotCreated, setShouldHideNotCreated] = useState(false);
    const [filterString, setFilterString] = useState('');
    const selectedSiteTypes = searchParams.getAll('siteType');
    const [selectedStockTransfers, setSelectedStockTransfers] = useState<
        GET_STOCK_TRANSFERS_stockTransfers_stockTransfers[]
    >([]);

    const {
        loading: stockTransfersLoading,
        data: stockTransfersData,
        error: stockTransfersError,
        fetchMore: fetchMoreStockTransfers,
    } = useQuery<GET_STOCK_TRANSFERS, GET_STOCK_TRANSFERSVariables>(GET_STOCK_TRANSFERS_QUERY, {
        variables: { offset: 0, filterString, states: shouldHideNotCreated ? [StockTransferState.Created] : null },
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        const observer = apolloClient.subscribe({
            query: STOCK_TRANSFERS_UPDATED_SUBSCRIPTION,
        });
        const subscription = observer.subscribe(({ data }) => {
            const { stockTransferUpdated } = data;
            stockTransfersUpdatedHandler(stockTransferUpdated);
        });

        return () => subscription.unsubscribe();
    });

    if (stockTransfersError || (!stockTransfersData && !stockTransfersLoading)) {
        throw new Error('Une erreur est survenue lors de la récupération des transferts de stock');
    }

    const [validateStockTransfers, { loading: validateStockTransfersLoading }] = useMutation<
        VALIDATE_STOCK_TRANSFERS,
        VALIDATE_STOCK_TRANSFERSVariables
    >(VALIDATE_STOCK_TRANSFERS_MUTATION);
    const [abortStockTransfers, { loading: abortStockTransfersLoading }] = useMutation<
        ABORT_STOCK_TRANSFERS,
        ABORT_STOCK_TRANSFERSVariables
    >(ABORT_STOCK_TRANSFERS_MUTATION);

    async function updateSearchParams(value: string) {
        setFilterString(value);
    }

    const toggleSiteSelection = (siteType: SiteType) => {
        if (selectedSiteTypes.includes(siteType)) {
            // @ts-ignore TS2554 (your editor won't show an error, but react-scripts will)
            searchParams.delete('siteType', siteType);
        } else {
            searchParams.append('siteType', siteType);
        }
        setSearchParams(searchParams);
    };

    const hasMore = stockTransfersData?.stockTransfers?.hasMore ?? false;
    const stockTransfers = stockTransfersData?.stockTransfers?.stockTransfers ?? [];

    const sortedStockTransfers = sortStockTransfers(stockTransfers).filter(({ originSiteName, destinationSiteName }) =>
        !filterString || [0, 2].includes(selectedSiteTypes.length) // all or no filters
            ? true
            : selectedSiteTypes.includes('Arrival')
              ? destinationSiteName.toLowerCase().includes(filterString.toLowerCase())
              : selectedSiteTypes.includes('Departure')
                ? originSiteName.toLowerCase().includes(filterString.toLowerCase())
                : false,
    );

    const displayedStockTransfers = sortedStockTransfers.filter(({ state }) =>
        !shouldHideNotCreated ? true : state === StockTransferState.Created,
    );

    const loadMore = async () => {
        await fetchMoreStockTransfers({
            variables: {
                offset: stockTransfers.length,
            },
        });
    };

    const actionSections = [
        {
            title: 'Actions',
            actions: [
                {
                    icon: <FcInspection size={22} />,
                    label: "Sélectionner les STs 'Crée' VISIBLES",
                    onClick: () => {
                        setSelectedStockTransfers(
                            displayedStockTransfers.filter(({ state }) => state === StockTransferState.Created),
                        );
                    },
                },
                {
                    icon: <FcCheckmark size={22} />,
                    label: 'Valider les STs à une date de transfert donnée',
                    onClick: () => {
                        setIsValidateStockTransfersAtDatePopupOpen(true);
                    },
                    loading: abortStockTransfersLoading || validateStockTransfersLoading,
                },
                {
                    icon: <FcMinus size={22} />,
                    label: 'Tout désélectionner',
                    onClick: () => {
                        setSelectedStockTransfers([]);
                    },
                    hidden: !selectedStockTransfers.length,
                },
            ],
        },
        {
            title: 'Marquer comme',
            actions: [
                {
                    icon: <FcCheckmark size={22} />,
                    label: 'Validé(s)',
                    onClick: async () => {
                        const { data } = await validateStockTransfers({
                            variables: { stockTransferIds: selectedStockTransfers.map(({ _id }) => _id) },
                        });

                        if (!data) {
                            throw new Error('Une erreur est survenue lors de la validation des transferts de stock');
                        }

                        const {
                            validateStockTransfers: { errors, stockTransfers },
                        } = data;

                        handleUpdateActionEndToast({
                            displaySuccess: !!stockTransfers.length,
                            successText: `${stockTransfers.length} transfert(s) sur ${selectedStockTransfers.length} a/ont été validé(s)`,
                            errors,
                            warnings: [],
                        });
                    },
                    hidden:
                        !selectedStockTransfers.length ||
                        selectedStockTransfers.some(({ state }) => state !== StockTransferState.Created),
                    loading: abortStockTransfersLoading || validateStockTransfersLoading,
                },
                {
                    icon: <FcCancel size={22} />,
                    label: 'Annulé(s)',
                    onClick: async () => {
                        const { data } = await abortStockTransfers({
                            variables: { stockTransferIds: selectedStockTransfers.map(({ _id }) => _id) },
                        });

                        if (!data) {
                            throw new Error("Une erreur est survenue lors de l'annulation des transferts de stock");
                        }

                        const {
                            abortStockTransfers: { errors, stockTransfers },
                        } = data;

                        handleUpdateActionEndToast({
                            displaySuccess: !!stockTransfers.length,
                            successText: `${stockTransfers.length} transfert(s) sur ${selectedStockTransfers.length} a/ont été annulé(s)`,
                            errors,
                            warnings: [],
                        });
                    },
                    hidden:
                        !selectedStockTransfers.length ||
                        selectedStockTransfers.some(({ state }) => state !== StockTransferState.Created),
                    loading: abortStockTransfersLoading || validateStockTransfersLoading,
                },
            ],
        },
    ];

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.stockTransfers} />
                </HeaderTitle>
                <ButtonsContainer>
                    <LabelContainer>
                        <Label>Afficher uniquement</Label>
                        <Label>les stocks créés</Label>
                    </LabelContainer>
                    <Switch
                        onChange={() => setShouldHideNotCreated(!shouldHideNotCreated)}
                        checked={shouldHideNotCreated}
                        onColor={colors.green}
                        offColor={colors.pantinGrey}
                    />
                    <Link to="/stockTransfer/returns">
                        <TotemPrimaryButton>Consulter retours entrepôt</TotemPrimaryButton>
                    </Link>
                    <Link to="/stockTransfer/create">
                        <TotemPrimaryButton>Créer un transfert de stock</TotemPrimaryButton>
                    </Link>
                </ButtonsContainer>
            </Header>
            <ContentContainer>
                <Content>
                    <TotemInput
                        label="Recherche"
                        onChange={updateSearchParams}
                        placeholder="Nom de site"
                        value={filterString}
                    />
                    <Filters>
                        <Title>Filtres</Title>
                        <FilterContainer
                            isSelected={selectedSiteTypes.includes('Arrival')}
                            onClick={() => toggleSiteSelection('Arrival')}
                        >
                            <CommonStatusTag
                                backgroundColor={colors.pink}
                                labelColor={colors.whiteGrey}
                                label={"Site d'arrivée"}
                            />
                        </FilterContainer>
                        <FilterContainer
                            isSelected={selectedSiteTypes.includes('Departure')}
                            onClick={() => toggleSiteSelection('Departure')}
                        >
                            <CommonStatusTag
                                backgroundColor={colors.orange}
                                labelColor={colors.whiteGrey}
                                label="Site de départ"
                            />
                        </FilterContainer>
                    </Filters>
                    <ListContainer>
                        {stockTransfersLoading ? (
                            <Loader />
                        ) : (
                            <StockTransfersList
                                hasMore={hasMore}
                                loadMore={loadMore}
                                selectedStockTransfers={selectedStockTransfers}
                                setSelectedStockTransfers={setSelectedStockTransfers}
                                stockTransfers={displayedStockTransfers}
                            />
                        )}
                    </ListContainer>
                </Content>
                <ActionBar actionSections={actionSections} isInitiallyOpen={!!selectedStockTransfers.length} />
            </ContentContainer>
            <ValidateStockTransfersAtDatePopup
                isOpen={isValidateStockTransfersAtDatePopupOpen}
                setIsOpen={setIsValidateStockTransfersAtDatePopupOpen}
            />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    ${TotemPrimaryButton} {
        margin-left: 5px;
    }
`;

const LabelContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const Label = styled(TotemLabel)`
    margin-right: 5px;
`;

const ContentContainer = styled.div`
    display: flex;
    flex: 1;
    overflow: hidden;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    flex: 1;
    overflow: hidden;
`;

const ListContainer = styled.div`
    flex: 1;
    overflow: hidden;
`;

const Filters = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const Title = styled.h3`
    margin: 0;
    font-size: 20px;
    font-weight: 800;
`;

const FilterContainer = styled.div<{ isSelected: boolean }>`
    cursor: pointer;
    border-radius: 20px;
    border: 2px solid ${({ isSelected, theme }) => (isSelected ? theme.darkBorderColor : 'transparent')};
`;

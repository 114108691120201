import React from 'react';
import { QuizQuestion } from './QuizForm';
import { TotemInput } from 'components/TotemInput';
import styled from 'styled-components';
import { TotemCheckbox } from 'components/TotemCheckbox';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { FaTrash } from 'react-icons/fa';
import { FileDragAndDrop, FileWithPreview } from 'components/FileDragAndDrop';
import type { FileError } from 'react-dropzone';
import { TotemImage } from 'components/TotemImage';

export const QuizQuestionForm = ({
    question,
    setQuestion,
}: {
    question: QuizQuestion;
    setQuestion: (question: QuizQuestion) => void;
}) => {
    async function fileValidationFunction(file: FileWithPreview) {
        return new Promise<FileError[]>((resolve, reject) => {
            const image = new Image();
            image.onload = function () {
                const widthErrors =
                    image.width >= 600 && image.height >= 400
                        ? []
                        : [
                              {
                                  message: `L'image doit avoir une dimension d'au moins 600x400px, votre image fait ${image.width}x${image.height}px`,
                                  code: 'file-invalid-dimension',
                              },
                          ];
                const fileErrors = [...widthErrors];
                resolve(fileErrors);
            };
            image.onerror = reject;
            image.src = file.preview;
        });
    }

    function setQuestionAnswer({ answer, answerIndex }: { answer: string; answerIndex: number }) {
        const newAnswers = [...question.answerOptions];
        newAnswers[answerIndex] = answer;

        setQuestion({ ...question, answerOptions: newAnswers });
    }

    function deleteAnswer({ index }: { index: number }) {
        setQuestion({
            ...question,
            answerOptions: question.answerOptions.filter((_, answerIndex) => answerIndex !== index),
        });
    }

    return (
        <>
            <DragAndDropContainer>
                <FileDragAndDrop
                    multiple={false}
                    hasPadding={false}
                    hasBackground={false}
                    fileTypes={['image/jpeg', 'image/png', 'image/svg+xml', 'image/gif']}
                    fileValidationFunction={fileValidationFunction}
                    onFileDropCallback={(files) => {
                        if (files?.[0]) {
                            setQuestion({ ...question, image: files[0] });
                        }
                    }}
                >
                    <ImageContainer>
                        <TotemImage
                            src={question.image?.preview || question.imageUrl}
                            alt={question.image?.name || 'Question'}
                            size={300}
                        />
                    </ImageContainer>
                </FileDragAndDrop>
            </DragAndDropContainer>
            <TotemInput value={question.label} onChange={(label) => setQuestion({ ...question, label })} />
            {question.answerOptions.map((answerOption, answerIndex) => (
                <AnswerContainer key={answerIndex}>
                    <TotemCheckbox
                        checked={answerIndex === question.correctAnswerIndex}
                        onChange={() => setQuestion({ ...question, correctAnswerIndex: answerIndex })}
                    />
                    <AnswerInputContainer>
                        <TotemInput
                            value={answerOption}
                            onChange={(answer) => setQuestionAnswer({ answer, answerIndex })}
                        />
                    </AnswerInputContainer>

                    <TotemPrimaryButton onClick={() => deleteAnswer({ index: answerIndex })}>
                        <FaTrash />
                    </TotemPrimaryButton>
                </AnswerContainer>
            ))}

            <ButtonContainer>
                <TotemPrimaryButton
                    onClick={() => setQuestion({ ...question, answerOptions: [...question.answerOptions, ''] })}
                >
                    Ajouter une option de réponse
                </TotemPrimaryButton>
            </ButtonContainer>
        </>
    );
};

const DragAndDropContainer = styled.div`
    align-self: flex-start;
`;

const ImageContainer = styled.div`
    cursor: pointer;
    &:hover {
        opacity: 0.4;
    }
`;

const AnswerContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    & > :not(:first-child) {
        margin-left: 10px;
    }
`;

const AnswerInputContainer = styled.div`
    flex-grow: 1;
`;

const ButtonContainer = styled.div``;

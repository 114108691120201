import React from 'react';

import styled from 'styled-components';

import { colors } from 'constants/colors';

import { GET_DELIVERY_delivery } from 'data/queries/__generated__/GET_DELIVERY';

import { SectionColumn, SectionContainer, ColumnsSectionContainer } from 'components/DetailsView/Section';
import { DetailValue } from 'components/DetailsView/DetailValue';

import { getTimeFromDatetime } from '../../../helpers/dateTimes';
import { DetailLink } from 'components/DetailsView';

export const DriverSection = ({ delivery }: { delivery: GET_DELIVERY_delivery }) => {
    const {
        roundColor,
        roundName,
        serviceTime,
        theoricServiceTime,
        times: {
            preferenceSlot: { start: startTimePreference, end: endTimePreference },
            actualSlot: { start: actualStartTime, end: actualEndTime },
        },
    } = delivery;

    const roundBackgroundColor = roundColor || colors.pantinGrey;

    return (
        <SectionContainer title="Informations de livraison" isInitiallyOpen>
            <ColumnsSectionContainer numberOfColumns={2}>
                <SectionColumn>
                    <SubsectionTitle>Livraison</SubsectionTitle>
                    <DetailValue
                        label="Tournée"
                        value={
                            <RoundContainer backgroundColor={roundBackgroundColor}>
                                {roundName || 'Pas de tournée associée'}
                            </RoundContainer>
                        }
                    />
                    <DetailValue
                        label="Créneau de livraison"
                        value={
                            startTimePreference && endTimePreference
                                ? `Entre ${getTimeFromDatetime(startTimePreference)} et ${getTimeFromDatetime(
                                      endTimePreference,
                                  )}`
                                : 'Pas de préférence'
                        }
                    />
                    <DetailValue label="Date d'arrivée" value={getTimeFromDatetime(actualStartTime)} />
                    <DetailValue label="Date de clôture" value={getTimeFromDatetime(actualEndTime)} />
                </SectionColumn>
                <SectionColumn>
                    <SubsectionTitle>Temps de service</SubsectionTitle>
                    <DetailValue label="Prévu" value={`${theoricServiceTime} min`} />
                    <DetailValue label="Effectif" value={`${serviceTime} min`} />
                    {delivery.siteSetup && delivery.siteId ? (
                        <DetailLink
                            label="Rangement Totops"
                            path="site-setup"
                            name="Lien"
                            value={`${delivery.siteId}/${delivery.siteSetup._id}`}
                        />
                    ) : null}
                </SectionColumn>
            </ColumnsSectionContainer>
        </SectionContainer>
    );
};

const SubsectionTitle = styled.span`
    font-size: 22px;
    font-weight: 800;
    color: ${({ theme }) => theme.textColor};
`;

const RoundContainer = styled.div`
    background-color: ${({ backgroundColor }: { backgroundColor: string }) => backgroundColor};
    color: ${colors.pureWhite};
    font-weight: 800;
    padding: 5px;
    border-radius: ${({ theme }) => theme.borderRadius};
    text-align: center;
`;

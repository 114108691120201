import { NotificationCategory } from 'data/__generated__';

export function getTranslatedNotificationCategory(category: NotificationCategory): string {
    switch (category) {
        case NotificationCategory.CustomRitualActivity:
            return 'Activité sur un évènement';
        case NotificationCategory.DailyDelivery:
            return 'Livraison & menu du jour';
        case NotificationCategory.Feedbacks:
            return 'Remarques';
        case NotificationCategory.NewFeatures:
            return 'Nouveautés sur votre app';
        case NotificationCategory.Recommendations:
            return 'Recommandations personnalisées';
        case NotificationCategory.Reminders:
            return 'Rappels';
        case NotificationCategory.RitualInvite:
            return 'Invitation à un évènement';
        case NotificationCategory.SiteActivity:
            return 'Activité sur votre site';
        case NotificationCategory.SpecialOffers:
            return 'Offres spéciales';
        case NotificationCategory.StartingRitual:
            return 'Un rituel ou évènement auquel vous avez participé commence';
        case NotificationCategory.WeeklySummary:
            return 'Résumé de la semaine';
    }
}

export type Deeplink = { destinationScreenLabel: string; path: string; parameters?: string[] };

export const availableDeeplinks: Deeplink[] = [
    { destinationScreenLabel: 'Préférences de notifications', path: 'deeplink/notification-preferences' },
    {
        destinationScreenLabel: 'Transaction',
        path: 'deeplink/transaction',
        parameters: ['transactionId'],
    },
    { destinationScreenLabel: 'Moyens de paiement', path: 'deeplink/payment-settings' },
    { destinationScreenLabel: 'Ajout de moyen de paiement', path: 'deeplink/payment-selection' },
    { destinationScreenLabel: 'Accueil/Home screen', path: 'deeplink/' },
    { destinationScreenLabel: 'Scan de produit', path: 'deeplink/scanner' },
    { destinationScreenLabel: 'Organisation associée', path: 'deeplink/organization' },
    { destinationScreenLabel: 'Subventions', path: 'deeplink/subventions' },
    {
        destinationScreenLabel: 'Code de vérification pour rattachement',
        path: 'deeplink/verification-code',
        parameters: ['verificationCode'],
    },
    {
        destinationScreenLabel: "Code de vérification pour vérification d'e-mail",
        path: 'deeplink/email-verification',
        parameters: ['verificationCode'],
    },
];

import gql from 'graphql-tag';

export const CREATE_RITUAL_TEMPLATE_MUTATION = gql`
    mutation CREATE_RITUAL_TEMPLATE($image: Upload!, $name: String!) {
        createRitualTemplate(image: $image, name: $name) {
            success
            errors
            ritualTemplate {
                _id
                name
                imageUrl
            }
        }
    }
`;

import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { FaSave } from 'react-icons/fa';
import { GrRevert } from 'react-icons/gr';

import { colors } from 'constants/colors';

import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { ColumnLocationSelector } from './ColumnLocationSelector';
import { MicrostoreColumnEditorInfoPopup } from './Popups/MicrostoreColumnEditorInfoPopup';
import { SynchronizePlanogramWithSenseiButton } from './SynchronizePlanogramWithSenseiButton';
import { SynchronizePlanogramWithNuButton } from './SynchronizePlanogramWithNuButton';

import {
    MicrostoreColumnGroupFormValues,
    MicrostoreColumnFormValues,
    MicrostoreLocationFormValues,
    MicrostoreProductsStockInfoFormValues,
} from './FormHelper/DataMapper';
import { TotemInput } from 'components/TotemInput';
import { formatDateAsAnniversary } from 'helpers/dateTimes';
import { GET_MICROSTORE_SITE_siteWithLocationInfo } from 'data/queries/__generated__/GET_MICROSTORE_SITE';
import { GetMicrostoreSitesWithLocationsInfoQuery, MicrostoreColumnTemplateType } from 'data/__generated__';
import { toast } from 'react-toastify';

export const ColumnPanel = ({
    canBeUpdated = true,
    earliestDLCDateToKeepForStockCalculation,
    displayOnlyTomorrowForPrint = false,
    hideTodayInfo,
    hideTomorrowInfo,
    isFormDirty,
    isToday,
    microstoreColumnGroupFormValuesArray,
    microstoreProductsStockInfo,
    getMicrostoreProductsStockInfoLoading,
    nextMSDeliveryDate,
    nextSiteDeliveryDate,
    revertLastUpdate,
    selectedColumnGroup,
    selectedColumn,
    selectedLocationPosition,
    selectedShelfIndex,
    setIsToday,
    setSelectedLocationPosition,
    setSelectedShelfIndex,
    site,
    submitColumnUpdate,
    switchColumn,
    updateSelectedColumn,
    updateSelectedLocation,
}: {
    canBeUpdated?: boolean;
    earliestDLCDateToKeepForStockCalculation: string | null;
    displayOnlyTomorrowForPrint?: boolean;
    hideTodayInfo?: boolean;
    hideTomorrowInfo?: boolean;
    isFormDirty: boolean;
    isToday: boolean;
    microstoreColumnGroupFormValuesArray: MicrostoreColumnGroupFormValues[];
    microstoreProductsStockInfo: MicrostoreProductsStockInfoFormValues | null;
    getMicrostoreProductsStockInfoLoading?: boolean;
    nextMSDeliveryDate: string;
    nextSiteDeliveryDate: string | null;
    revertLastUpdate: () => void;
    selectedColumnGroup: MicrostoreColumnGroupFormValues;
    selectedColumn: MicrostoreColumnFormValues;
    selectedLocationPosition: { column: number; row: number } | null;
    selectedShelfIndex: number | null;
    setIsToday: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedLocationPosition: React.Dispatch<React.SetStateAction<{ column: number; row: number } | null>>;
    setSelectedShelfIndex: React.Dispatch<React.SetStateAction<number | null>>;
    site: GET_MICROSTORE_SITE_siteWithLocationInfo | GetMicrostoreSitesWithLocationsInfoQuery['sites'][number];
    submitColumnUpdate: () => void;
    switchColumn: MicrostoreColumnFormValues | null;
    updateSelectedColumn: (values: Partial<MicrostoreColumnFormValues>) => void;
    updateSelectedLocation: (values: Partial<MicrostoreLocationFormValues>) => void;
}) => {
    const [isMicrostoreColumnEditorInfoPopup, setIsMicrostoreColumnEditorInfoPopup] = useState<boolean>(false);

    const todayDate = formatDateAsAnniversary({ dateTime: new Date(), useNewFormat: true });

    useEffect(() => {
        if (
            site.isSecured &&
            selectedColumn.canBeSecured &&
            !selectedColumn.senseiColumnId &&
            !selectedColumn.nuFridgeId
        ) {
            toast.warning(
                <span key="sensei">Attention: il faudra renseigner un id sensei ou nu sur cette colonne</span>,
                {
                    autoClose: false,
                },
            );
        }
    }, [selectedColumn, site]);

    function autoFillJ() {
        if (!switchColumn) {
            return;
        }

        const newShelves = selectedColumn.shelves.map((shelf, shelfIndex) => {
            const swithShelf = switchColumn.shelves?.[shelfIndex];

            if (!swithShelf) {
                return shelf;
            }

            return {
                ...shelf,
                locationTable: shelf.locationTable.map((locationRow, locationRowIndex) =>
                    locationRow.map((location, locationColIndex) => {
                        const switchLocation = swithShelf.locationTable?.[locationRowIndex]?.[locationColIndex];
                        if (!switchLocation) {
                            return location;
                        }
                        return { ...location, productId: switchLocation.productId };
                    }),
                ),
            };
        });

        updateSelectedColumn({ ...selectedColumn, shelves: newShelves });
    }

    return (
        <Container>
            <TitleContainer>
                <Title>
                    {selectedColumnGroup.name} - C{selectedColumn.positionInColumnGroup + 1} -{' '}
                    <TitleColumnName displayForPrint={false}>
                        <ColumnNameInput
                            placeholder="3e étage, cuisine etc..."
                            value={selectedColumn.name}
                            disabled={!canBeUpdated}
                            onChange={(value) => updateSelectedColumn({ ...selectedColumn, name: value })}
                        />
                    </TitleColumnName>
                    <TitleColumnName displayForPrint={true}>{selectedColumn.name}</TitleColumnName>
                    {site.isSecured && selectedColumn.canBeSecured && !selectedColumn.nuFridgeId ? (
                        <TitleColumnName displayForPrint={false}>
                            <ExternalIdInput
                                placeholder="#sensei"
                                value={selectedColumn.senseiColumnId || ''}
                                disabled={!canBeUpdated}
                                onChange={(value) =>
                                    updateSelectedColumn({ ...selectedColumn, senseiColumnId: value, nuFridgeId: null })
                                }
                                missing={!selectedColumn.senseiColumnId}
                            />
                        </TitleColumnName>
                    ) : null}
                    {site.isSecured && selectedColumn.canBeSecured && !selectedColumn.senseiColumnId ? (
                        <TitleColumnName displayForPrint={false}>
                            <NuIdInput
                                placeholder="#nu"
                                value={selectedColumn.nuFridgeId || ''}
                                disabled={!canBeUpdated}
                                onChange={(value) =>
                                    updateSelectedColumn({
                                        ...selectedColumn,
                                        senseiColumnId: null,
                                        nuFridgeId: value,
                                    })
                                }
                                missing={!selectedColumn.nuFridgeId}
                            />
                        </TitleColumnName>
                    ) : null}
                    {selectedColumn.senseiColumnId ? (
                        <TitleColumnName displayForPrint={false} style={{ paddingLeft: 15 }}>
                            <SynchronizePlanogramWithSenseiButton microstoreColumnId={selectedColumn._id} />
                        </TitleColumnName>
                    ) : null}
                    {selectedColumn.nuFridgeId ? (
                        <TitleColumnName displayForPrint={false} style={{ paddingLeft: 15 }}>
                            <SynchronizePlanogramWithNuButton microstoreColumnId={selectedColumn._id} />
                        </TitleColumnName>
                    ) : null}
                    {selectedColumn.type === MicrostoreColumnTemplateType.HotDrinks ? (
                        <TitleColumnName displayForPrint={false}>
                            <ExternalIdInput
                                placeholder="#nayax"
                                value={selectedColumn.nayaxMachineId || ''}
                                disabled={!canBeUpdated}
                                onChange={(value) => updateSelectedColumn({ ...selectedColumn, nayaxMachineId: value })}
                                missing={!selectedColumn.nayaxMachineId}
                            />
                        </TitleColumnName>
                    ) : null}
                </Title>
                <SubTitle>({site.name})</SubTitle>
                <InfoIcon size={22} onClick={() => setIsMicrostoreColumnEditorInfoPopup(true)} />
            </TitleContainer>

            <ButtonsContainer>
                {canBeUpdated ? (
                    <Button
                        type="button"
                        title="Revert modification"
                        onClick={revertLastUpdate}
                        disabled={!isFormDirty}
                    >
                        <GrRevert size={13} />
                    </Button>
                ) : null}
                {canBeUpdated && switchColumn ? (
                    <Button type="button" title="Auto fill J" onClick={autoFillJ} disabled={isFormDirty}>
                        Auto fill J
                    </Button>
                ) : null}
                <InfoContainer>
                    <DateText>
                        Prochaine date de livraison générale/ de ce store : {nextMSDeliveryDate}/{' '}
                        {nextSiteDeliveryDate ?? 'NA'}
                    </DateText>
                    <DateText>
                        Date de DLC à garder la plus proche : {earliestDLCDateToKeepForStockCalculation ?? 'NA'}
                    </DateText>
                </InfoContainer>
                <DateText
                    highlight={
                        !!selectedColumn.startingDate &&
                        selectedColumn.startingDate > todayDate &&
                        selectedColumn.startingDate <= nextMSDeliveryDate
                    }
                >
                    Début : {selectedColumn.startingDate ?? 'NA'}
                </DateText>
                <DateText highlight={!!selectedColumn.removalDate && selectedColumn.removalDate <= nextMSDeliveryDate}>
                    Retrait : {selectedColumn.removalDate ?? 'NA'}
                </DateText>
                {selectedColumn.isPaused ? <DateText highlight={true}>Colonne mise en pause</DateText> : null}
                {canBeUpdated ? (
                    <Button type="button" title="Mettre à jour" onClick={submitColumnUpdate} disabled={!isFormDirty}>
                        <FaSave size={13} />
                    </Button>
                ) : null}
            </ButtonsContainer>

            <ColumnEditorContainer>
                <ColumnLocationSelector
                    canBeUpdated={canBeUpdated}
                    displayOnlyTomorrowForPrint={displayOnlyTomorrowForPrint}
                    hideTodayInfo={hideTodayInfo}
                    hideTomorrowInfo={hideTomorrowInfo}
                    isToday={isToday}
                    microstoreColumnGroupFormValuesArray={microstoreColumnGroupFormValuesArray}
                    microstoreProductsStockInfo={microstoreProductsStockInfo}
                    getMicrostoreProductsStockInfoLoading={getMicrostoreProductsStockInfoLoading}
                    earliestDLCDateToKeepForStockCalculation={earliestDLCDateToKeepForStockCalculation}
                    nextSiteDeliveryDate={nextSiteDeliveryDate}
                    selectedColumn={selectedColumn}
                    selectedLocationPosition={selectedLocationPosition}
                    selectedShelfIndex={selectedShelfIndex}
                    setIsToday={setIsToday}
                    setSelectedLocationPosition={setSelectedLocationPosition}
                    setSelectedShelfIndex={setSelectedShelfIndex}
                    site={site}
                    switchColumn={switchColumn}
                    updateSelectedLocation={updateSelectedLocation}
                />
            </ColumnEditorContainer>
            <MicrostoreColumnEditorInfoPopup
                isOpen={isMicrostoreColumnEditorInfoPopup}
                setIsOpen={setIsMicrostoreColumnEditorInfoPopup}
            />
        </Container>
    );
};

const Container = styled.div`
    // we compute the height with vh because we want the column to be visible while taking the maximum height
    min-height: calc(100vh - 200px);
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    @media print {
        display: block;
    }
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
`;

const Title = styled.span`
    font-size: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-flex;
    align-items: center;

    @media print {
        font-size: 16px;
    }
`;

const TitleColumnName = styled.span<{ displayForPrint: boolean }>`
    display: ${({ displayForPrint }) => (displayForPrint ? 'none' : 'initial')};
    z-index: 1;
    @media print {
        display: ${({ displayForPrint }) => (displayForPrint ? 'initial' : 'none')};
    }
`;

const SubTitle = styled.span`
    margin-left: 15px;
    font-size: 20px;
    color: ${({ theme }) => theme.infoTextColor};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media print {
        font-size: 16px;
    }
`;

const ColumnNameInput = styled(TotemInput)`
    width: 300px;
`;

const ExternalIdInput = styled(TotemInput)<{ missing: boolean }>`
    width: 150px;
    margin-left: 15px;
    ${({ theme, missing }) => missing && `border: 2px solid ${theme.errorColor}; background: ${theme.warningColor}`}
`;

const NuIdInput = styled(TotemInput)<{ missing: boolean }>`
    width: 250px;
    margin-left: 15px;
    ${({ theme, missing }) => missing && `border: 2px solid ${theme.errorColor}; background: ${theme.warningColor}`}
`;

const InfoIcon = styled(AiOutlineInfoCircle)`
    margin-left: 15px;
    cursor: pointer;
    color: ${colors.blue};
    z-index: 1;

    &:hover {
        transform: scale(1.1);
    }

    @media print {
        display: none;
    }
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    & > :not(:first-child) {
        margin-left: 5px;
    }

    @media print {
        display: none;
    }
`;

const DateText = styled.div<{ highlight?: boolean }>`
    display: flex;
    flex-grow: 1;
    text-align: center;
    color: ${({ highlight }) => (highlight ? colors.red : 'inherit')};
`;

const Button = styled(TotemPrimaryButton)`
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ColumnEditorContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;

    @media print {
        display: block;
    }
`;

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 5px;
`;

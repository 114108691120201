import { RitualGameType, RitualType } from 'data/__generated__';

export const RITUAL_TYPE_LABELS: Record<RitualType, string> = {
    [RitualType.Regular]: 'Normal',
    [RitualType.Game]: 'Jeu',
    [RitualType.Custom]: 'Custom',
    [RitualType.NewProducts]: 'Nouveaux produits',
    [RitualType.DiscountsPerProduct]: 'Anti-gaspi',
};

export const RITUAL_GAME_TYPE_LABELS: Record<RitualGameType, string> = {
    [RitualGameType.Quiz]: 'Quiz',
    [RitualGameType.Wanted]: 'Wanted',
};

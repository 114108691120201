import React from 'react';

import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { MICROSTORE_COLUMN_TEMPLATE_TYPE_LABELS } from '../constants/types';

import { MicrostoreColumnTemplateFragment } from 'data/fragments/__generated__/MicrostoreColumnTemplateFragment';
import { DELETE_MICROSTORE_COLUMN_TEMPLATES_MUTATION } from 'data/mutations/microstoreColumnTemplate';
import {
    DELETE_MICROSTORE_COLUMN_TEMPLATES,
    DELETE_MICROSTORE_COLUMN_TEMPLATESVariables,
} from 'data/mutations/__generated__/DELETE_MICROSTORE_COLUMN_TEMPLATES';

import { Loader, LoaderModeType } from 'components/Loader';
import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

import { deletedMicrostoreColumnTemplatesHandler } from '../cacheHandlers/microstoreColumnTemplatesCacheHandler';

export function DeleteMicrostoreColumnTemplatesPopup({
    microstoreColumnTemplates,
    selectedMicrostoreColumnTemplateIds,
    setSelectedMicrostoreColumnTemplateIds,
    isOpen,
    setIsOpen,
}: {
    microstoreColumnTemplates: MicrostoreColumnTemplateFragment[];
    selectedMicrostoreColumnTemplateIds: string[];
    setSelectedMicrostoreColumnTemplateIds: (value: React.SetStateAction<string[]>) => void;
    isOpen: boolean;
    setIsOpen: (value: React.SetStateAction<boolean>) => void;
}) {
    const [deleteMicrostoreColumnTemplates, { loading }] = useMutation<
        DELETE_MICROSTORE_COLUMN_TEMPLATES,
        DELETE_MICROSTORE_COLUMN_TEMPLATESVariables
    >(DELETE_MICROSTORE_COLUMN_TEMPLATES_MUTATION, {
        variables: { microstoreColumnTemplateIds: selectedMicrostoreColumnTemplateIds },
    });

    const selectedMicrostoreColumnTemplates = microstoreColumnTemplates.filter((microstoreColumnTemplate) =>
        selectedMicrostoreColumnTemplateIds.includes(microstoreColumnTemplate._id),
    );

    async function handleSubmit() {
        const { data } = await deleteMicrostoreColumnTemplates();

        if (!data) {
            throw new Error('Une erreur est survenue lors de la suppression des templates de colonne');
        }

        const {
            deleteMicrostoreColumnTemplatesMutation: { deletedMicrostoreColumnTemplateIds, errors },
        } = data;

        deletedMicrostoreColumnTemplatesHandler(deletedMicrostoreColumnTemplateIds);

        if (deletedMicrostoreColumnTemplateIds.length) {
            toast.success(`Template(s) de colonne supprimé(s) : ${deletedMicrostoreColumnTemplateIds.length}`);
        }

        if (errors.length) {
            errors.forEach((error, index) => {
                toast.error(<span key={index}>Erreur : {error}</span>, { autoClose: false });
            });

            if (errors.length > 1) {
                toast.info('Cliquez pour fermer toutes les notifications', {
                    autoClose: false,
                    onClick: () => toast.dismiss(),
                });
            }
        }
        setSelectedMicrostoreColumnTemplateIds([]);
        setIsOpen(false);
    }

    return (
        <TotemPopup title="Êtes-vous sûr.e ?" isOpen={isOpen} setIsOpen={setIsOpen}>
            Vous vous apprêtez à supprimer {selectedMicrostoreColumnTemplates.length} template(s) de colonne.
            Souhaitez-vous vraiment supprimer le/les template(s) suivant(s) ?
            <MicrostoreColumnTemplatelist>
                {selectedMicrostoreColumnTemplates.map((microstoreColumnTemplate) => {
                    const { _id: microstoreColumnTemplateId, name, type } = microstoreColumnTemplate;

                    return (
                        <MicrostoreColumnTemplate key={microstoreColumnTemplateId}>
                            <MicrostoreColumnTemplateTitle>{name}</MicrostoreColumnTemplateTitle>
                            <MicrostoreColumnTemplateText>
                                Type : {MICROSTORE_COLUMN_TEMPLATE_TYPE_LABELS[type]}
                            </MicrostoreColumnTemplateText>
                        </MicrostoreColumnTemplate>
                    );
                })}
            </MicrostoreColumnTemplatelist>
            <SubmitContainer>
                <TotemPrimaryButton onClick={handleSubmit}>
                    {loading ? <Loader size="20px" mode={LoaderModeType.Spin} /> : 'Confirmer'}
                </TotemPrimaryButton>
            </SubmitContainer>
        </TotemPopup>
    );
}

const MicrostoreColumnTemplatelist = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    overflow: auto;

    & > :not(:first-child) {
        margin-top: 5px;
    }
`;

const MicrostoreColumnTemplate = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
`;

const MicrostoreColumnTemplateText = styled.div`
    margin-left: 15px;
`;

const MicrostoreColumnTemplateTitle = styled.div`
    font-weight: 800;
`;

const SubmitContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 15px;
`;

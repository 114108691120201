import React from 'react';

import styled from 'styled-components';

import { SelectedOption, TotemSelect } from 'components/TotemSelect';
import { GET_SITES_sites } from 'data/queries/__generated__/GET_SITES';

export const SitesSelector = ({
    sites,
    selectedSiteId,
    setSelectedSiteId,
}: {
    sites: GET_SITES_sites[];
    selectedSiteId: string | null;
    setSelectedSiteId: React.Dispatch<React.SetStateAction<string | null>>;
}) => {
    const sitesOptions = sites
        .map((site) => ({ value: site._id, label: site.name }))
        .sort((siteA, siteB) => siteA.label.toLowerCase().localeCompare(siteB.label.toLowerCase()));
    const selectedSiteOption = sitesOptions.find((option) => option.value === selectedSiteId);

    function handleOriginSiteSelect(originSiteOption: SelectedOption<string>) {
        if (originSiteOption) {
            const { value: siteId } = originSiteOption;
            setSelectedSiteId(siteId);
        }
    }

    return (
        <Container>
            <SiteInput>
                <TotemSelect
                    label="Site"
                    placeholder="Sélectionner un site (recherche par nom ou par _id)"
                    value={selectedSiteOption}
                    options={sitesOptions}
                    onChange={handleOriginSiteSelect}
                />
            </SiteInput>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

const SiteInput = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    & > :not(:first-child) {
        margin-top: 5px;
    }
`;

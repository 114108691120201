import React from 'react';

import { useForm, FormProvider } from 'react-hook-form';
import { FaSave } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { PAGES } from 'constants/pages';

import { Header, HeaderTitle } from 'components/Header';
import { Loader, LoaderModeType } from 'components/Loader';
import { PageTitle } from 'components/PageTitle';

import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { SubventionReloadPeriod, useCreateSubventionMutation } from 'data/__generated__';
import { GeneralInfoSection } from './SubventionDetails/GeneralInfoSection';

type FormData = {
    billToOrganizationId: string | null;
    categories: string[];
    cumulative: boolean;
    currentAmount: number | null;
    description: string;
    hoursIntervalsAvailable: string[][];
    imageUrl: string;
    name: string;
    organizationId: string | null;
    reload: SubventionReloadPeriod;
};

export const SubventionCreate = () => {
    const navigate = useNavigate();
    const [createSubvention, { loading: createSubventionLoading }] = useCreateSubventionMutation();

    const methods = useForm<FormData>({
        defaultValues: {
            billToOrganizationId: null,
            categories: [],
            cumulative: true,
            currentAmount: 5,
            description: '',
            hoursIntervalsAvailable: [['00:00', '23:59']],
            imageUrl: '',
            name: '',
            organizationId: null,
            reload: SubventionReloadPeriod.Daily,
        },
    });

    const {
        handleSubmit,
        formState: { isDirty },
    } = methods;

    const onSubmit = handleSubmit(async (fields) => {
        const { organizationId, billToOrganizationId, ...rest } = fields;
        if (!organizationId) {
            throw Error('missing organization');
        }
        const variables = {
            createSubventionInput: {
                organizationId,
                ...(billToOrganizationId !== organizationId && { billToOrganizationId }),
                ...rest,
            },
        };
        const { data } = await createSubvention({
            variables,
        });

        if (data) {
            const {
                createSubvention: { subvention, errors },
            } = data;
            if (subvention) {
                toast.success('La subvention a bien été créée !');
                navigate(`/subvention/${subvention._id}`);
            } else {
                if (errors) {
                    errors.forEach((error) => {
                        toast.error(error, { autoClose: false });
                    });

                    if (errors.length > 1) {
                        toast.info('Cliquez pour fermer toutes les notifications', {
                            autoClose: false,
                            onClick: () => toast.dismiss(),
                        });
                    }
                } else {
                    throw Error("Une erreur inconnue s'est produite");
                }
            }
        } else {
            throw Error("Une erreur inconnue s'est produite");
        }
    });

    return (
        <Container>
            <FormProvider {...methods}>
                <Form onSubmit={onSubmit}>
                    <Header>
                        <HeaderTitle>
                            <PageTitle page={PAGES.subventionCreate} />
                        </HeaderTitle>
                        <CTAsContainer>
                            <TotemPrimaryButton type="submit" minWidth="78px" disabled={!isDirty}>
                                {createSubventionLoading ? (
                                    <Loader size="18px" mode={LoaderModeType.Spin} />
                                ) : (
                                    <>
                                        <FaSave size={13} />
                                        <SaveLabel>Créer</SaveLabel>
                                    </>
                                )}
                            </TotemPrimaryButton>
                            <Link to="/subventions">
                                <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                            </Link>
                        </CTAsContainer>
                    </Header>
                    <Content>
                        <ScrollableContent>
                            <GeneralInfoSection />
                        </ScrollableContent>
                    </Content>
                </Form>
            </FormProvider>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex: 1;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
`;

const CTAsContainer = styled.div`
    display: flex;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const SaveLabel = styled.span`
    margin-left: 5px;
`;

const Content = styled.div`
    flex: 1;
    overflow: hidden;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const ScrollableContent = styled.div`
    padding: 15px;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

import gql from 'graphql-tag';
import { SITE_INVENTORY_FRAGMENT } from 'data/fragments/siteInventory';

export const GET_SETUP_SITE_INVENTORY_QUERY = gql`
    query GET_SETUP_SITE_INVENTORY($siteSetupId: ID!) {
        getSetupSiteInventory(siteSetupId: $siteSetupId) {
            ...SiteInventoryFragment
        }
    }
    ${SITE_INVENTORY_FRAGMENT}
`;

import React, { FunctionComponent } from 'react';
import { TotemPopup } from 'components/TotemPopup';
import { MobilePopupsQuery, useUpdateMobilePopupMutation } from 'data/__generated__';

import { formatFormValues, stringifyToFormValues } from './utils';
import { UpsertMobilePopupForm } from './UpsertMobilePopupForm';

type UpdateMobilePopupModalProps = {
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
    mobilePopup: MobilePopupsQuery['mobilePopups'][number];
};

export const UpdateMobilePopupModal: FunctionComponent<UpdateMobilePopupModalProps> = ({
    isOpen,
    setIsOpen,
    mobilePopup,
}) => {
    const [updateMobilePopup] = useUpdateMobilePopupMutation({
        onCompleted: (data) => {
            if (!data?.updateMobilePopup) {
                return;
            }
            setIsOpen(false);
        },
    });

    const stringifiedFormValues = stringifyToFormValues(mobilePopup);

    return (
        <TotemPopup isOpen={isOpen} title="Modifier une popup" setIsOpen={setIsOpen}>
            <UpsertMobilePopupForm
                onSubmit={(values) =>
                    updateMobilePopup({ variables: { id: mobilePopup._id, input: formatFormValues(values) } })
                }
                defaultValues={stringifiedFormValues}
                imageUrl={mobilePopup.imageUrl ?? null}
            />
        </TotemPopup>
    );
};

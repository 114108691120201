import gql from 'graphql-tag';
import { QUIZ_FRAGMENT } from '../fragments/quiz';

export const CREATE_QUIZ_MUTATION = gql`
    mutation CreateQuiz($input: QuizInput!) {
        createQuiz(input: $input) {
            success
            errors
            quiz {
                ...QuizFragment
            }
        }
    }
    ${QUIZ_FRAGMENT}
`;

export const UPDATE_QUIZ_MUTATION = gql`
    mutation UpdateQuiz($quizId: ID!, $input: QuizInput!) {
        updateQuiz(quizId: $quizId, input: $input) {
            success
            errors
            quiz {
                ...QuizFragment
            }
        }
    }
    ${QUIZ_FRAGMENT}
`;

import { gql } from '@apollo/client';

export const GET_PRODUCT_PRICE_RANGES_QUERY = gql`
    query GET_PRODUCT_PRICE_RANGES {
        productPriceRanges {
            _id
            name
        }
    }
`;

export const GET_PRODUCT_PRICE_RANGE_QUERY = gql`
    query GET_PRODUCT_PRICE_RANGE($rangeId: ID!) {
        productPriceRange(rangeId: $rangeId) {
            name
            products {
                productId
                price
                crossedPrice
                name
            }
        }
    }
`;

export const GET_PRODUCT_PRICES_QUERY = gql`
    query GET_PRODUCT_PRICES($productId: ID!) {
        productPrices(productId: $productId) {
            _id
            rangeId
            price
            crossedPrice
        }
    }
`;

export const GET_PRODUCTS_FROM_CSV_QUERY = gql`
    query GET_PRODUCTS_FROM_CSV($rangeId: ID, $csvData: [RangeCsvData!]!) {
        productsFromCsv(rangeId: $rangeId, csvData: $csvData) {
            productsForRange {
                _id
                name
                price
            }
            idsNotFound
            idsNotActive
        }
    }
`;

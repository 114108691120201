import React from 'react';

import styled from 'styled-components';

import { colors } from 'constants/colors';

import { GET_DELIVERY_delivery } from 'data/queries/__generated__/GET_DELIVERY';

import { formatDateAsAnniversary } from '../../../../helpers/dateTimes';

export const PageHeader = ({ delivery }: { delivery: GET_DELIVERY_delivery }) => {
    const { deliveryDate, organizationName, totem, orders } = delivery;

    if (!totem || orders.length === 0) {
        throw new Error('Impossible de générer un bon de livraison. Cette liaison est-elle bien liée à une commande ?');
    }

    const { address, number: totemNumber } = totem;

    // we only display the first order number if there is a punctual and a recurring order, if there is any issue we can update this
    const {
        number: orderNumber,
        user: {
            profile: { fullname: contact },
        },
    } = orders[0];

    return (
        <OuterContainer>
            <Content>
                <DetailsTable>
                    <thead>
                        <tr>
                            <HeaderCell>TOTEM SAS</HeaderCell>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <BodyCell>Société par actions simplifiée</BodyCell>
                        </tr>
                        <tr>
                            <BodyCell>Au capital de 723,10 euros</BodyCell>
                        </tr>
                        <tr>
                            <BodyCell>Siège social : 5 avenue du Général de Gaulle, 94160 Saint-Mandé</BodyCell>
                        </tr>
                        <tr>
                            <BodyCell>825 323 496 R.C.S. Créteil</BodyCell>
                        </tr>
                    </tbody>
                </DetailsTable>
                <DetailsTable>
                    <thead>
                        <tr>
                            <HeaderCell>TOTEM</HeaderCell>
                            <HeaderCell>{`${organizationName} #${totemNumber}`}</HeaderCell>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <BodyCell isBold>Date de livraison</BodyCell>
                            <BodyCell>{formatDateAsAnniversary({ dateTime: deliveryDate })}</BodyCell>
                        </tr>
                        <tr>
                            <BodyCell isBold>Commande n°</BodyCell>
                            <BodyCell>{orderNumber}</BodyCell>
                        </tr>
                        <tr>
                            <BodyCell isBold>Adresse</BodyCell>
                            <BodyCell>{address}</BodyCell>
                        </tr>
                        <tr>
                            <BodyCell isBold>Contact</BodyCell>
                            <BodyCell>{contact}</BodyCell>
                        </tr>
                    </tbody>
                </DetailsTable>
            </Content>
            <TitleContainer>
                <Title>Bon de livraison</Title>
            </TitleContainer>
        </OuterContainer>
    );
};

const OuterContainer = styled.div`
    display: table-header-group;
`;

const Content = styled.div`
    display: flex;
    justify-content: space-between;
    color: ${colors.black};
`;

const DetailsTable = styled.table`
    border: 1px solid ${colors.lightGrey};
    padding: 5px;

    & > :not(:first-child) {
        margin-top: 5px;
    }
`;

const HeaderCell = styled.th`
    font-size: 14px;
    text-align: left;
`;

const BodyCell = styled.td`
    font-size: 12px;
    font-weight: ${({ isBold }: { isBold?: boolean }) => (isBold ? 800 : 500)};
`;

const TitleContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 10px;
    font-weight: 800;
`;

const Title = styled.span`
    font-size: 32px;
    color: ${colors.green};
`;

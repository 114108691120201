import { gql } from '@apollo/client';
import { PREVIEW_CREDIT_FRAGMENT } from 'data/fragments/credit';

export const WALLET_FRAGMENT = gql`
    fragment WalletFragment on Wallet {
        credits {
            ...PreviewCreditFragment
        }
        subventions {
            _id
            name
        }
        creditCards {
            _id
            default
            expired
            paymentType
            provider
            retries
            details {
                number
                expiration
                bank
            }
        }
        mealVouchers {
            _id
            default
            expired
            paymentType
            provider
            details {
                number
                username
                expiration
                bank
            }
        }
    }
    ${PREVIEW_CREDIT_FRAGMENT}
`;

import React from 'react';

import { CommonStatusTag } from 'components/CommonStatusTag';

import { colors } from 'constants/colors';
import { InfoType } from 'data/__generated__';

function getInfoTypeLabel(infoType: InfoType) {
    switch (infoType) {
        case InfoType.Private:
            return 'Privé';
        case InfoType.IsToBeArchived:
            return 'À sortir';
        case InfoType.New:
            return 'Nouveau';
        case InfoType.IsInShortage:
            return 'Rupture fournisseur';
        case InfoType.IsBeingTested:
            return 'En test';
        case InfoType.Paused:
            return 'En pause';
        case InfoType.OutOfStock:
            return 'En rupture';
        default:
            return InfoType.IsBeingTested;
    }
}

const getColorsFromInfo = (infoType: InfoType) => {
    const { green, lightGrey, red, pureWhite, blue, orange, black } = colors;

    switch (infoType) {
        case InfoType.Private:
            return { backgroundColor: blue, labelColor: pureWhite };
        case InfoType.IsToBeArchived:
        case InfoType.OutOfStock:
            return { backgroundColor: red, labelColor: pureWhite };
        case InfoType.IsInShortage:
            return { backgroundColor: red, labelColor: black };
        case InfoType.IsBeingTested:
            return { backgroundColor: blue, labelColor: pureWhite };
        case InfoType.Paused:
            return { backgroundColor: orange, labelColor: pureWhite };
        case InfoType.New:
            return { backgroundColor: green, labelColor: black };
        default:
            return { backgroundColor: lightGrey, labelColor: black };
    }
};

export const InfoTag = ({ infoTag, marginTop }: { infoTag: InfoType; marginTop?: string | null }) => {
    const { backgroundColor, labelColor } = getColorsFromInfo(infoTag);
    return (
        <CommonStatusTag
            backgroundColor={backgroundColor}
            labelColor={labelColor}
            label={getInfoTypeLabel(infoTag)}
            showHeading={false}
            marginTop={marginTop}
        />
    );
};

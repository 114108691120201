import React from 'react';

import styled from 'styled-components';

import { GET_MICROSTORE_SITES_WITH_LOCATIONS_INFO_sites_microstoreColumnGroups_columns_shelves_locationTable } from 'data/queries/__generated__/GET_MICROSTORE_SITES_WITH_LOCATIONS_INFO';
import { GET_ALL_PRODUCTS_getAllProducts } from 'data/queries/__generated__/GET_ALL_PRODUCTS';
import { LocationPlanogramFragment } from 'data/fragments/__generated__/LocationPlanogramFragment';
import { TotemImage } from 'components/TotemImage';

export const PlanogramLocation = ({
    location,
    locationPlanogram,
    products,
}: {
    location: GET_MICROSTORE_SITES_WITH_LOCATIONS_INFO_sites_microstoreColumnGroups_columns_shelves_locationTable;
    locationPlanogram: LocationPlanogramFragment | null;
    products: GET_ALL_PRODUCTS_getAllProducts[];
}) => {
    const {
        microstoreLocationTemplate: { productArrangement, productArrangementSingleProductInfo },
    } = location;

    const locationPlanogramProduct = products.find(({ _id }) => _id === locationPlanogram?.productId);
    return (
        <Location>
            <>
                {productArrangement ? (
                    <LocationProductArrangementName>{productArrangement.name}</LocationProductArrangementName>
                ) : null}
                {productArrangementSingleProductInfo ? (
                    <LocationProductArrangementName>
                        {productArrangementSingleProductInfo.product.name}
                    </LocationProductArrangementName>
                ) : null}
                {locationPlanogramProduct ? (
                    <>
                        <LocationProductName>{locationPlanogramProduct.fullname}</LocationProductName>
                        <TotemImage
                            src={locationPlanogramProduct.imageUrl}
                            alt={locationPlanogramProduct.fullname}
                            size={50}
                        />
                    </>
                ) : null}
            </>
        </Location>
    );
};

const Location = styled.div`
    flex: 1 0 0;
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
    border-radius: ${({ theme }) => theme.borderRadius};
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    & > :not(:first-child) {
        margin-top: 5px;
    }
`;

const LocationProductArrangementName = styled.div`
    text-align: center;
    font-size: 12px;
    font-weight: bold;
`;

const LocationProductName = styled.div`
    text-align: center;
    font-size: 13px;
`;

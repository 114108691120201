import { apolloClient } from '../../../App';
import { SiteSetupFragment } from 'data/fragments/__generated__/SiteSetupFragment';
import { GET_SITE_SETUP_FOR_DATE_QUERY } from 'data/queries/siteSetup';
import {
    GET_SITE_SETUP_FOR_DATE,
    GET_SITE_SETUP_FOR_DATEVariables,
} from 'data/queries/__generated__/GET_SITE_SETUP_FOR_DATE';
import { SITE_SETUP_FRAGMENT } from 'data/fragments/siteSetup';

export const siteSetupCreatedHandler = (createdSiteSetup: SiteSetupFragment) => {
    // We can read query only if it has been run

    const result = apolloClient.readQuery<GET_SITE_SETUP_FOR_DATE, GET_SITE_SETUP_FOR_DATEVariables>({
        query: GET_SITE_SETUP_FOR_DATE_QUERY,
        variables: {
            date: createdSiteSetup.date,
        },
    });
    if (result === null) {
        return;
    }
    const currentSiteSetups = result.getSiteSetupsForDate;

    // if the current site setups already contains the created one, then we need to update it with the sent values (and keep it at the same index)
    const newSiteSetups = currentSiteSetups.find(({ _id }) => _id === createdSiteSetup._id)
        ? currentSiteSetups.map((siteSetup) => (siteSetup._id === createdSiteSetup._id ? createdSiteSetup : siteSetup))
        : [...currentSiteSetups, createdSiteSetup];

    apolloClient.writeQuery({
        query: GET_SITE_SETUP_FOR_DATE_QUERY,
        data: { getSiteSetupsForDate: newSiteSetups },
        variables: {
            date: createdSiteSetup.date,
        },
    });
};

export const siteSetupUpdatedHandler = (updatedSiteSetup: SiteSetupFragment) => {
    // We can read query only if it has been run
    const siteSetupCached = apolloClient.readFragment({
        id: `SiteSetup:${updatedSiteSetup._id}`,
        fragment: SITE_SETUP_FRAGMENT,
        fragmentName: 'SiteSetupFragment',
    });

    if (siteSetupCached) {
        apolloClient.writeFragment({
            id: `SiteSetup:${updatedSiteSetup._id}`,
            fragment: SITE_SETUP_FRAGMENT,
            fragmentName: 'SiteSetupFragment',
            data: {
                ...updatedSiteSetup,
            },
        });
    }
};

import React from 'react';

import styled from 'styled-components';
import { IconEdit } from '@tabler/icons';
import { Link } from 'react-router-dom';

import { colors } from 'constants/colors';
import { SETUP_STATE_COLORS } from '../constants/states';

import { GET_SITE_SETUP_FOR_DATE_getSiteSetupsForDate } from 'data/queries/__generated__/GET_SITE_SETUP_FOR_DATE';
import { GET_SIMPLE_MICROSTORE_SITES_microstoreSites } from 'data/queries/__generated__/GET_SIMPLE_MICROSTORE_SITES';
import { MicrostoreColumnGroupSetupState, MicrostoreColumnSetupState, SiteSetupState } from 'data/__generated__';
import { SETUP_INSTRUCTION_TYPE_LABELS } from '../constants/types';
import { SiteSetupFragment_stateHistory } from 'data/fragments/__generated__/SiteSetupFragment';
import { MicrostoreColumnGroupSetupFragment_stateHistory } from 'data/fragments/__generated__/MicrostoreColumnGroupSetupFragment';
import { MicrostoreColumnSetupFragment_stateHistory } from 'data/fragments/__generated__/MicrostoreColumnSetupFragment';
import {
    SetupInstructionFragment_CheckTemperatureInstruction_stateHistory,
    SetupInstructionFragment_CheckBoxTemperatureInstruction_stateHistory,
    SetupInstructionFragment_HotDrinksInstruction_stateHistory,
    SetupInstructionFragment_InventoryInstruction_stateHistory,
    SetupInstructionFragment_MicrowaveInstruction_stateHistory,
    SetupInstructionFragment_RemoveProductsInstruction_stateHistory,
    SetupInstructionFragment_StoreProductsInstruction_stateHistory,
    SetupInstructionFragment_TheftNoticeInstruction_stateHistory,
} from 'data/fragments/__generated__/SetupInstructionFragment';
import { GET_MICROSTORE_SITE_siteWithLocationInfo } from 'data/queries/__generated__/GET_MICROSTORE_SITE';

function computeDurationFromStateHistory({
    isNeeded,
    isFinishForced,
    stateHistory,
}: {
    isNeeded: boolean;
    isFinishForced: boolean;
    stateHistory: (
        | SiteSetupFragment_stateHistory
        | MicrostoreColumnGroupSetupFragment_stateHistory
        | MicrostoreColumnSetupFragment_stateHistory
        | SetupInstructionFragment_HotDrinksInstruction_stateHistory
        | SetupInstructionFragment_CheckTemperatureInstruction_stateHistory
        | SetupInstructionFragment_CheckBoxTemperatureInstruction_stateHistory
        | SetupInstructionFragment_InventoryInstruction_stateHistory
        | SetupInstructionFragment_MicrowaveInstruction_stateHistory
        | SetupInstructionFragment_StoreProductsInstruction_stateHistory
        | SetupInstructionFragment_RemoveProductsInstruction_stateHistory
        | SetupInstructionFragment_TheftNoticeInstruction_stateHistory
    )[];
}): string {
    if (!isNeeded) {
        return 'Pas a faire';
    }

    const startTime = stateHistory.find(({ state }) => state === SiteSetupState.InProgress)?.createdAt;
    const endTime = stateHistory.find(({ state }) => state === SiteSetupState.Finished)?.createdAt;

    if (!startTime && endTime) {
        return 'Fait à la main';
    }
    if (!startTime) {
        return 'Pas commencé';
    }
    if (!endTime) {
        return 'Pas finis';
    }

    const dateDiff = endTime - startTime;
    return `${Math.round(dateDiff / 1000 / 60)}\u00A0min\u00A0${
        isFinishForced ? '(Finition Forcée)' : ''
    }\u00A0${new Date(startTime).toLocaleTimeString()}\u00A0-\u00A0${new Date(endTime).toLocaleTimeString()}`;
}

export const SiteSetupTrelloItem = ({
    isUsedInTrelloListView = true,
    siteSetup,
    site,
}: {
    isUsedInTrelloListView?: boolean;
    siteSetup: GET_SITE_SETUP_FOR_DATE_getSiteSetupsForDate;
    site: GET_SIMPLE_MICROSTORE_SITES_microstoreSites | GET_MICROSTORE_SITE_siteWithLocationInfo;
}) => {
    return (
        <ItemContent>
            <ItemHeader backgroundColor={siteSetup.isFinishForced ? colors.red : SETUP_STATE_COLORS[siteSetup.state]}>
                {site.name} (
                {computeDurationFromStateHistory({
                    isFinishForced: siteSetup.isFinishForced,
                    isNeeded: true,
                    stateHistory: siteSetup.stateHistory,
                })}
                )
                {isUsedInTrelloListView ? (
                    <Link to={`/site-setup/${site._id}/${siteSetup._id}`}>
                        <EditIcon color={colors.pureWhite} size="15" />
                    </Link>
                ) : null}
            </ItemHeader>
            <ItemBody>
                {siteSetup.microstoreColumnGroupSetups.map((microstoreColumnGroupSetup) => {
                    const microstoreColumnGroup = site.microstoreColumnGroups.find(
                        ({ _id }) => _id === microstoreColumnGroupSetup.microstoreColumnGroupId,
                    );
                    if (!microstoreColumnGroup) {
                        return null;
                    }

                    return (
                        <MicrostoreColumnGroupDetails
                            key={microstoreColumnGroupSetup._id}
                            open={microstoreColumnGroupSetup.state === MicrostoreColumnGroupSetupState.InProgress}
                        >
                            <MicrostoreColumnGroupSummary
                                color={
                                    microstoreColumnGroupSetup.isFinishForced
                                        ? colors.red
                                        : SETUP_STATE_COLORS[microstoreColumnGroupSetup.state]
                                }
                            >
                                {microstoreColumnGroup.name} (
                                {computeDurationFromStateHistory({
                                    isFinishForced: microstoreColumnGroupSetup.isFinishForced,
                                    isNeeded: microstoreColumnGroupSetup.isNeeded,
                                    stateHistory: microstoreColumnGroupSetup.stateHistory,
                                })}
                                )
                            </MicrostoreColumnGroupSummary>
                            {microstoreColumnGroupSetup.microstoreColumnSetups.map((microstoreColumnSetup) => {
                                const microstoreColumn = microstoreColumnGroup.columns.find(
                                    ({ _id }) => _id === microstoreColumnSetup.microstoreColumnId,
                                );
                                if (!microstoreColumn) {
                                    return null;
                                }

                                return (
                                    <MicrostoreColumnDetails
                                        key={microstoreColumnSetup._id}
                                        open={microstoreColumnSetup.state === MicrostoreColumnSetupState.InProgress}
                                    >
                                        <MicrostoreColumnSummary
                                            color={
                                                microstoreColumnSetup.isFinishForced
                                                    ? colors.red
                                                    : SETUP_STATE_COLORS[microstoreColumnSetup.state]
                                            }
                                        >
                                            {microstoreColumn.name} (
                                            {computeDurationFromStateHistory({
                                                isFinishForced: microstoreColumnSetup.isFinishForced,
                                                isNeeded: microstoreColumnSetup.isNeeded,
                                                stateHistory: microstoreColumnSetup.stateHistory,
                                            })}
                                            )
                                        </MicrostoreColumnSummary>
                                        {microstoreColumnSetup.instructions.map((instruction, index) => {
                                            return (
                                                <Instruction
                                                    key={index}
                                                    color={
                                                        instruction.isFinishForced
                                                            ? colors.red
                                                            : SETUP_STATE_COLORS[instruction.state]
                                                    }
                                                >
                                                    - {SETUP_INSTRUCTION_TYPE_LABELS[instruction.type]} (
                                                    {computeDurationFromStateHistory({
                                                        isFinishForced: instruction.isFinishForced,
                                                        isNeeded: instruction.isNeeded,
                                                        stateHistory: instruction.stateHistory,
                                                    })}
                                                    )
                                                </Instruction>
                                            );
                                        })}
                                    </MicrostoreColumnDetails>
                                );
                            })}
                        </MicrostoreColumnGroupDetails>
                    );
                })}
            </ItemBody>
        </ItemContent>
    );
};

const ItemContent = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: ${({ theme }) => theme.borderRadius};
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
    overflow: hidden;
`;

const ItemHeader = styled.div<{ backgroundColor: string }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${({ backgroundColor }) => backgroundColor};
    color: ${colors.pureWhite};
    padding: 10px;
    font-weight: 800;
    text-align: center;
`;

const ItemBody = styled.div`
    padding: 10px;
`;

const EditIcon = styled(IconEdit)`
    flex-shrink: 0;
    cursor: pointer;

    &:hover {
        transform: scale(1.1);
    }
`;

const MicrostoreColumnGroupDetails = styled.details`
    &:not(:first-child) {
        margin-top: 10px;
    }
`;

const MicrostoreColumnGroupSummary = styled.summary<{ color: string }>`
    font-weight: 700;
    color: ${({ color }) => color};
    cursor: pointer;
`;

const MicrostoreColumnDetails = styled.details`
    margin-top: 5px;
`;

const MicrostoreColumnSummary = styled.summary<{ color: string }>`
    padding-left: 20px;
    color: ${({ color }) => color};
    cursor: pointer;
`;

const Instruction = styled.div<{ color: string }>`
    margin-left: 40px;
    color: ${({ color }) => color};

    &:nth-child(2) {
        margin-top: 5px;
    }
    &:nth-child(n + 3) {
        margin-top: 10px;
    }
`;

import React, { useCallback, useContext, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useQuery } from '@apollo/client';

import styled, { ThemeContext } from 'styled-components';
import { IconClipboardList } from '@tabler/icons';

import { siteVariationsColors, siteVariationsLabels } from './constants';
import { GET_SITE_PRODUCTS_WITH_LATEST_STOCKS_QUERY } from 'data/queries/product';
import { GET_SITE_QUERY } from 'data/queries/site';
import {
    GET_SITE_PRODUCTS_WITH_LATEST_STOCKS_getProductsWithLatestVariations,
    GET_SITE_PRODUCTS_WITH_LATEST_STOCKSVariables,
    GET_SITE_PRODUCTS_WITH_LATEST_STOCKS,
} from 'data/queries/__generated__/GET_SITE_PRODUCTS_WITH_LATEST_STOCKS';

import { TotemInput } from 'components/TotemInput';
import { Loader, LoaderModeType } from 'components/Loader';
import { Header, HeaderTitle } from 'components/Header';
import { DetailLink } from 'components/DetailsView';

type ParamTypes = {
    siteId: string;
};

export const SiteStocks = () => {
    const [filterString, setFilterString] = useState('');
    const theme = useContext(ThemeContext);
    const { siteId = '' } = useParams<ParamTypes>();
    const [infos, setInfos] = useState<Record<string, any> | null>(null);

    const {
        loading: siteLoading,
        data: siteData,
        error: siteError,
    } = useQuery<{ site: { name: string } }, { siteId: string }>(GET_SITE_QUERY, {
        variables: {
            siteId,
        },
    });

    const {
        loading: productsLoading,
        data: productsData,
        error: productsError,
    } = useQuery<GET_SITE_PRODUCTS_WITH_LATEST_STOCKS, GET_SITE_PRODUCTS_WITH_LATEST_STOCKSVariables>(
        GET_SITE_PRODUCTS_WITH_LATEST_STOCKS_QUERY,
        {
            variables: {
                siteId,
            },
            pollInterval: 30000,
        },
    );

    const formatVariationInfo = useCallback((json: Record<string, string>) => {
        const lineInfos = [];

        for (const i in json) {
            if (!i.match('_') && json[i]) {
                if (i === 'createdAt') {
                    lineInfos.push(
                        <div>
                            <span>Jour de création : </span>
                            <span>{new Date(json[i]).toLocaleDateString()}</span>
                        </div>,
                    );
                    lineInfos.push(
                        <div>
                            <span>Heure de création : </span>
                            <span>{new Date(json[i]).toLocaleTimeString()}</span>
                        </div>,
                    );

                    continue;
                }

                lineInfos.push(
                    <div>
                        <span>{i} : </span>
                        <span>{json[i]}</span>
                    </div>,
                );
            }
        }

        return <Info style={{ backgroundColor: json._bgColor }}>{lineInfos}</Info>;
    }, []);

    const getProductVariations = useCallback(
        (productWithVariations: GET_SITE_PRODUCTS_WITH_LATEST_STOCKS_getProductsWithLatestVariations) => {
            const { _id, fullname, stock, lastSiteInventoryStock } = productWithVariations.product;
            const availableStock = productWithVariations.availableStock;
            const variations = productWithVariations.variations;
            let value = lastSiteInventoryStock.value;

            return (
                <ProductsTableRow key={_id}>
                    <ProductsTableBodyCell>
                        <DetailLink name={fullname} value={_id} path="product" />
                    </ProductsTableBodyCell>
                    <ProductsTableBodyCell isDataCentered>
                        <QuantityInputOuterContainer>
                            <QuantityInputInnerContainer>
                                <TotemInput
                                    type="number"
                                    value={lastSiteInventoryStock.value}
                                    disabled={true}
                                    onChange={() => null}
                                    centerText
                                    data-test="product-quantity"
                                />
                            </QuantityInputInnerContainer>
                        </QuantityInputOuterContainer>
                    </ProductsTableBodyCell>
                    <ProductsTableBodyCell>
                        {variations.map((variation) => {
                            value = value + variation.delta;
                            const bgColor = siteVariationsColors[variation.type];
                            const infos = {
                                createdAt: variation.createdAt,
                                Type: siteVariationsLabels[variation.type],
                                Variation: variation.delta,
                                Valeur: value,
                                Explication: variation.explanation,
                                Référence: variation.ref,
                                _bgColor: bgColor,
                            };

                            return (
                                <QuantityVariationInnerContainer
                                    key={variation._id}
                                    onMouseEnter={() => setInfos(infos)}
                                    onMouseLeave={() => setInfos(null)}
                                >
                                    <QuantityVariationInnerValue bgColor={bgColor}>
                                        {variation.delta}
                                    </QuantityVariationInnerValue>
                                </QuantityVariationInnerContainer>
                            );
                        })}
                    </ProductsTableBodyCell>
                    <ProductsTableBodyCell isDataCentered>
                        <QuantityInputOuterContainer>
                            <QuantityInputInnerContainer>
                                <TotemInput
                                    type="number"
                                    value={stock}
                                    disabled={true}
                                    onChange={() => null}
                                    centerText
                                    data-test="product-stock-from-variation"
                                />
                            </QuantityInputInnerContainer>
                        </QuantityInputOuterContainer>
                    </ProductsTableBodyCell>
                    <ProductsTableBodyCell isDataCentered>
                        <QuantityInputOuterContainer>
                            <QuantityInputInnerContainer>
                                {!availableStock.isUnlimited ? (
                                    <TotemInput
                                        type="number"
                                        value={availableStock.value}
                                        disabled={true}
                                        onChange={() => null}
                                        centerText
                                        data-test="product-available-stock-from-variation"
                                    />
                                ) : (
                                    '∞'
                                )}
                            </QuantityInputInnerContainer>
                        </QuantityInputOuterContainer>
                    </ProductsTableBodyCell>
                    <ProductsTableBodyCell isDataCentered>
                        <Link to={`/site/${siteId}/stocks/${_id}`}>
                            <IconClipboardList size={22} color={theme.textColor} />
                        </Link>
                    </ProductsTableBodyCell>
                </ProductsTableRow>
            );
        },
        [],
    );

    if (siteLoading || productsLoading) {
        return (
            <Container>
                <Loader mode={LoaderModeType.Spin} />
            </Container>
        );
    }

    if (productsError || !productsData || siteError || !siteData) {
        toast.error('Une erreur est survenue lors de la récupération des produits transférables');
        return null;
    }

    const productsWithVariations = [...(productsData?.getProductsWithLatestVariations || [])];

    const filteredProductsWithVariations = productsWithVariations.filter(({ product }) =>
        filterString ? product.fullname.toLowerCase().includes(filterString.toLowerCase()) : true,
    );

    const site = siteData?.site;

    return (
        <Container>
            <Header>
                <HeaderTitle>Stocks du site {site.name}</HeaderTitle>
            </Header>
            <Content>
                <TotemInput
                    label="Recherche"
                    onChange={setFilterString}
                    placeholder="Nom du produit"
                    value={filterString}
                />
                {infos ? formatVariationInfo(infos) : null}
                {productsWithVariations.length ? (
                    <>
                        <ProductsTable>
                            <thead>
                                <ProductsTableRow>
                                    <ProductsTableHeaderCell>Produit</ProductsTableHeaderCell>
                                    <ProductsTableHeaderCell isDataCentered>
                                        Stock du dernier inventaire
                                    </ProductsTableHeaderCell>
                                    <ProductsTableHeaderCell isDataCentered>
                                        Variations de stock
                                    </ProductsTableHeaderCell>
                                    <ProductsTableHeaderCell isDataCentered>Stock actuel</ProductsTableHeaderCell>
                                    <ProductsTableHeaderCell isDataCentered>
                                        Stock Disponible actuel
                                    </ProductsTableHeaderCell>
                                    <ProductsTableHeaderCell isDataCentered>Détails</ProductsTableHeaderCell>
                                </ProductsTableRow>
                            </thead>
                            <ProductsTableBody>
                                {filteredProductsWithVariations
                                    .sort(
                                        (productsWithVariationsA, productsWithVariationsB) =>
                                            productsWithVariationsB.product.stock -
                                            productsWithVariationsA.product.stock,
                                    )
                                    .map(getProductVariations)}
                            </ProductsTableBody>
                        </ProductsTable>
                    </>
                ) : null}
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Content = styled.div`
    padding: 15px;
    flex: 1;
    overflow: auto;
`;

const ProductsTable = styled.table`
    margin-top: 10px;
    border-radius: ${({ theme }) => theme.borderRadius};
    border-spacing: 0px;
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
`;

const ProductsTableBody = styled.thead`
    & > :not(:first-child) td {
        border-top: 1px solid ${({ theme }) => theme.lightBorderColor};
    }
`;

const ProductsTableRow = styled.tr`
    border-bottom: 1px solid ${({ theme }) => theme.lightBorderColor};
`;

const ProductsTableHeaderCell = styled.th`
    padding: 10px;
    width: 100%;
    text-align: ${({ isDataCentered }: { isDataCentered?: boolean }) => (isDataCentered ? 'center' : 'left')};
`;

const ProductsTableBodyCell = styled.td`
    max-width: 300px;
    white-space: nowrap;
    overflow: overlay;
    padding: 10px;
    text-align: ${({ isDataCentered }: { isDataCentered?: boolean }) => (isDataCentered ? 'center' : 'left')};
    &::-webkit-scrollbar {
        width: 2px;
    }
    &::-webkit-scrollbar-track {
        background: inherit;
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${({ theme }) => theme.lightBorderColor};
        border-radius: 20px;
        border: 6px solid ${({ theme }) => theme.backgroundColor};
    }
`;

const QuantityInputOuterContainer = styled.div`
    display: flex;
    justify-content: center;
    opacity: ${({ opacity }: { opacity?: number }) => opacity ?? 1};
`;

const QuantityInputInnerContainer = styled.div`
    width: 70px;
`;

const QuantityVariationInnerContainer = styled.div`
    display: inline;
`;

const QuantityVariationInnerValue = styled.span`
    cursor: pointer;
    margin-right: 10px;
    background-color: ${({ bgColor }: { bgColor?: string }) => bgColor};
    color: ${({ bgColor }: { bgColor?: string }) => (bgColor === '#FFFFFF' ? '#111111' : '#FFFFFF')};
    border-radius: 3px;
    padding: 5px;
`;

const Info = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    margin: 10px;
    padding: 10px;
    background: ${({ backgroundColor, theme }: { backgroundColor?: string; theme: any }) =>
        backgroundColor ? backgroundColor : theme.menuBackgroundColor};
    color: ${({ theme }) => theme.menuTextColor};
    z-index: 1000;
`;

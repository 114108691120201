import gql from 'graphql-tag';

export const CONSUMPTION_VARIATION_FRAGMENT = gql`
    fragment ConsumptionVariationFragment on ConsumptionVariation {
        _id
        createdAt
        endDate
        siteId
        startDate
        updatedAt
        dessert {
            type
            value
        }
        main {
            type
            value
        }
        smallBites {
            type
            value
        }
        starter {
            type
            value
        }
    }
`;

import gql from 'graphql-tag';

import { SUPPLY_ORDER_FRAGMENT, SUPPLY_ORDER_WITH_SUPPLIER_PRODUCTS_INFO_FRAGMENT } from 'data/fragments/supplyOrder';

export const SUPPLY_ORDERS_UPDATED_OR_CREATED_SUBSCRIPTION = gql`
    subscription SUPPLY_ORDERS_UPDATED_OR_CREATED {
        supplyOrdersUpdatedOrCreated {
            ...SupplyOrderFragment
        }
    }
    ${SUPPLY_ORDER_FRAGMENT}
`;

export const SUPPLY_ORDERS_WITH_SUPPLIER_PRODUCTS_INFO_UPDATED_OR_CREATED_SUBSCRIPTION = gql`
    subscription SUPPLY_ORDERS_WITH_SUPPLIER_PRODUCTS_INFO_UPDATED_OR_CREATED {
        supplyOrdersUpdatedOrCreated {
            ...SupplyOrderWithSupplierProductsInfoFragment
        }
    }
    ${SUPPLY_ORDER_WITH_SUPPLIER_PRODUCTS_INFO_FRAGMENT}
`;

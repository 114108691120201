import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { UNAVAILABLE_PRODUCT_STATES } from 'pages/Products/constants/states';

import { DefaultProductType } from 'pages/Products/constants/defaultProduct';

import { SectionColumn, SectionContainer, ColumnsSectionContainer } from 'components/DetailsView/Section';
import { DetailFormCheckbox, DetailFormValue } from 'components/DetailsView';
import { DetailDisplayValue } from 'components/DetailsView/DetailDisplayValue';

import { round } from 'helpers/price';
import { GetDetailedProductQuery } from 'data/__generated__';

export const DimensionsSection = ({
    product,
    isIncomingFromCreation = false,
}: {
    product: NonNullable<GetDetailedProductQuery['detailedProduct']> | DefaultProductType;
    isIncomingFromCreation?: boolean;
}) => {
    const { displayKg, height, length, state, toleranceMax, toleranceMin, weight, weightWithoutPackaging, width } =
        product;

    const { register, control } = useFormContext();
    const productHasRequiredFields = !UNAVAILABLE_PRODUCT_STATES.includes(state);
    const minValue = productHasRequiredFields ? 0.01 : 0;

    const updatedVolumeValues: number[] = useWatch({
        control,
        name: ['height', 'width', 'length'],
        defaultValue: [height || 0, width || 0, length || 0],
    });

    const watchedDisplayKg: boolean = useWatch({
        control,
        name: 'displayKg',
        defaultValue: displayKg,
    });

    return (
        <SectionContainer title="Dimensions" isInitiallyOpen={isIncomingFromCreation}>
            <ColumnsSectionContainer numberOfColumns={3}>
                <SectionColumn>
                    <DetailFormValue
                        label="Hauteur (en cm)"
                        type="number"
                        step=".01"
                        defaultValue={height}
                        {...register('height', {
                            min: { value: minValue, message: '0.01' },
                            valueAsNumber: true,
                            required: productHasRequiredFields,
                        })}
                    />
                    <DetailFormValue
                        label="Largeur (en cm)"
                        type="number"
                        step=".01"
                        defaultValue={width}
                        {...register('width', {
                            min: { value: minValue, message: '0.01' },
                            valueAsNumber: true,
                            required: productHasRequiredFields,
                        })}
                    />
                    <DetailFormValue
                        label="Longueur (en cm)"
                        type="number"
                        step=".01"
                        defaultValue={length}
                        {...register('length', {
                            min: { value: minValue, message: '0.01' },
                            valueAsNumber: true,
                            required: productHasRequiredFields,
                        })}
                    />
                    <DetailDisplayValue
                        label="Volume"
                        sublabel="(calculé - cm3)"
                        value={round(
                            (updatedVolumeValues?.[0] || 0) *
                                (updatedVolumeValues?.[1] || 0) *
                                (updatedVolumeValues?.[2] || 0),
                            2,
                        )}
                    />
                </SectionColumn>
                <SectionColumn>
                    <DetailFormValue
                        label="Poids avec emballage (en kg)"
                        defaultValue={weight}
                        {...register('weight', {
                            min: 0,
                            valueAsNumber: true,
                            required: productHasRequiredFields,
                        })}
                    />
                    <DetailFormValue
                        label="Poids sans emballage (en kg)"
                        defaultValue={weightWithoutPackaging}
                        {...register('weightWithoutPackaging', {
                            min: 0,
                            valueAsNumber: true,
                            required: watchedDisplayKg && productHasRequiredFields,
                        })}
                    />
                    <DetailFormValue
                        label="Écart toléré en dessous du poids (en g)"
                        defaultValue={toleranceMin}
                        {...register('toleranceMin', {
                            min: 0,
                            valueAsNumber: true,
                            required: productHasRequiredFields,
                        })}
                    />
                    <DetailFormValue
                        label="Écart toléré au dessus du poids (en g)"
                        defaultValue={toleranceMax}
                        {...register('toleranceMax', {
                            min: 0,
                            valueAsNumber: true,
                            required: productHasRequiredFields,
                        })}
                    />
                </SectionColumn>
                <SectionColumn>
                    <DetailFormCheckbox
                        name="displayKg"
                        label="Afficher les quantités en kg"
                        defaultChecked={watchedDisplayKg}
                    />
                </SectionColumn>
            </ColumnsSectionContainer>
        </SectionContainer>
    );
};

import React from 'react';
import { useQuery } from '@apollo/client';
import { toast } from 'react-toastify';

import { GET_ALL_PRODUCTS } from 'data/queries/__generated__/GET_ALL_PRODUCTS';
import { GET_ALL_PRODUCTS_QUERY } from 'data/queries/product';

import { SelectedOption } from 'components/TotemCheckbox';
import { Loader, LoaderModeType } from 'components/Loader';
import { TotemSelect } from 'components/TotemSelect';

import styled from 'styled-components';
import { MicrostoreLocationTemplateFormValues } from './FormHelper/DataMapper';
import { ProductArrangementReassortmentType } from 'data/__generated__';
import { PRODUCT_ARRANGEMENT_REASSORTMENT_TYPE_LABELS } from 'pages/ProductArrangements/ProductArrangementDetails/constants/types';
import { TotemInput } from 'components/TotemInput';

export const TemplateProductSelector = ({
    selectedLocationTemplate,
    updateLocation,
}: {
    selectedLocationTemplate: MicrostoreLocationTemplateFormValues;
    updateLocation: (newLocationValues: Partial<MicrostoreLocationTemplateFormValues>) => void;
}) => {
    const {
        loading: productsLoading,
        data: productsData,
        error: productsError,
    } = useQuery<GET_ALL_PRODUCTS>(GET_ALL_PRODUCTS_QUERY);

    const { productArrangementSingleProductInfo } = selectedLocationTemplate;

    if (productsLoading) {
        return <Loader mode={LoaderModeType.Spin} />;
    }

    if (productsError || !productsData) {
        toast.error('Une erreur est survenue lors de la récupération des produits microstore ');
        return null;
    }

    const { getAllProducts } = productsData;

    const productsOptions =
        getAllProducts
            .map(({ _id, name, brand, volume }) => ({
                value: _id,
                label: `${name} - ${brand} - ${volume}`,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)) || [];

    function onProductSelect(productId: string) {
        const product = getAllProducts.find(({ _id }) => _id === productId) ?? null;
        if (!product) {
            return;
        }

        updateLocation({
            productArrangement: null,
            productArrangementSingleProductInfo: {
                product,
                reassortmentType:
                    productArrangementSingleProductInfo?.reassortmentType ?? ProductArrangementReassortmentType.Target,
                stockMaxUnsecured: productArrangementSingleProductInfo?.stockMaxUnsecured ?? 1,
                stockMaxSensei: productArrangementSingleProductInfo?.stockMaxSensei ?? 1,
            },
        });
    }

    const reassortmentTypeOptions = Object.keys(ProductArrangementReassortmentType)
        .map((type) => ({
            value: type as ProductArrangementReassortmentType,
            label: PRODUCT_ARRANGEMENT_REASSORTMENT_TYPE_LABELS[type as ProductArrangementReassortmentType],
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    // Todo use react virtualized or react window with select. Because there are too much products
    return (
        <Container>
            <InputContainer>
                <TotemSelect
                    label="Choisir un seul produit"
                    placeholder="Sélectionner un produit (recherche par nom ou par _id)"
                    value={
                        productsOptions.find(
                            (productOption) => productOption.value === productArrangementSingleProductInfo?.product._id,
                        ) ?? null
                    }
                    options={productsOptions}
                    onChange={(option: SelectedOption) => {
                        if (option) {
                            onProductSelect(option.value);
                        }
                    }}
                    menuPlacement="auto"
                />
            </InputContainer>

            {productArrangementSingleProductInfo?.product._id ? (
                <ProductInfoContainer>
                    <InputContainer>
                        <TotemSelect
                            label="Type de réassort"
                            placeholder="Sélectionner un type de réassort"
                            value={reassortmentTypeOptions.find(
                                (reassortmentTypeOption) =>
                                    reassortmentTypeOption.value ===
                                    productArrangementSingleProductInfo.reassortmentType,
                            )}
                            options={reassortmentTypeOptions}
                            onChange={(option) => {
                                if (option) {
                                    updateLocation({
                                        productArrangementSingleProductInfo: {
                                            ...productArrangementSingleProductInfo,
                                            reassortmentType: option.value,
                                        },
                                    });
                                }
                            }}
                        />
                    </InputContainer>
                    <InputContainer>
                        <TotemInput
                            label="StockMax confiance"
                            placeholder="0"
                            type="number"
                            centerText={true}
                            step="1"
                            min="0"
                            onChange={(newStockMax) =>
                                updateLocation({
                                    productArrangementSingleProductInfo: {
                                        ...productArrangementSingleProductInfo,
                                        stockMaxUnsecured: newStockMax ? parseInt(newStockMax) : 0,
                                    },
                                })
                            }
                            value={
                                productArrangementSingleProductInfo
                                    ? productArrangementSingleProductInfo.stockMaxUnsecured.toString() ?? ''
                                    : ''
                            }
                            required
                        />
                    </InputContainer>
                    <InputContainer>
                        <TotemInput
                            label="StockMax sensei"
                            placeholder="0"
                            type="number"
                            centerText={true}
                            step="1"
                            min="0"
                            onChange={(newStockMax) =>
                                updateLocation({
                                    productArrangementSingleProductInfo: {
                                        ...productArrangementSingleProductInfo,
                                        stockMaxSensei: newStockMax ? parseInt(newStockMax) : 0,
                                    },
                                })
                            }
                            value={
                                productArrangementSingleProductInfo
                                    ? productArrangementSingleProductInfo.stockMaxSensei.toString() ?? ''
                                    : ''
                            }
                            required
                        />
                    </InputContainer>
                </ProductInfoContainer>
            ) : null}
        </Container>
    );
};

const Container = styled.span`
    width: 100%;
`;

const InputContainer = styled.div`
    flex-grow: 1;
`;

const ProductInfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;

    & > :not(:first-child) {
        margin-left: 15px;
    }
`;

import React from 'react';

import { useQuery } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { PAGES } from 'constants/pages';

import {
    GET_HOT_DRINKS_ARRANGEMENT,
    GET_HOT_DRINKS_ARRANGEMENTVariables,
} from 'data/queries/__generated__/GET_HOT_DRINKS_ARRANGEMENT';
import { GET_HOT_DRINKS_ARRANGEMENT_QUERY } from 'data/queries/hotDrinksArrangement';

import { Loader } from 'components/Loader';
import { HotDrinksArrangementDetailsContent } from './HotDrinksArrangementDetailsContent';
import { Header, HeaderTitle } from 'components/Header';
import { PageTitle } from 'components/PageTitle';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { GET_NAYAX_PRODUCTS_QUERY } from 'data/queries/product';
import { GET_NAYAX_PRODUCTS } from 'data/queries/__generated__/GET_NAYAX_PRODUCTS';

type ParamTypes = {
    hotDrinksArrangementId: string;
};

export const HotDrinksArrangementDetails = () => {
    const { hotDrinksArrangementId = '' } = useParams<ParamTypes>();

    const {
        loading: hotDrinksArrangementLoading,
        data: hotDrinksArrangementData,
        error: hotDrinksArrangementError,
    } = useQuery<GET_HOT_DRINKS_ARRANGEMENT, GET_HOT_DRINKS_ARRANGEMENTVariables>(GET_HOT_DRINKS_ARRANGEMENT_QUERY, {
        variables: { hotDrinksArrangementId },
    });

    const {
        loading: productsLoading,
        data: productsData,
        error: productsError,
    } = useQuery<GET_NAYAX_PRODUCTS>(GET_NAYAX_PRODUCTS_QUERY);

    if (hotDrinksArrangementLoading || productsLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (hotDrinksArrangementError || !hotDrinksArrangementData || productsError || !productsData) {
        throw new Error(`L'erreur suivante est survenue : ${hotDrinksArrangementError || hotDrinksArrangementError}`);
    }

    const { getHotDrinksArrangement: hotDrinksArrangement } = hotDrinksArrangementData;
    const { getNayaxProducts: products } = productsData;

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.hotDrinksArrangementDetails} />
                </HeaderTitle>
                <CTAsContainer>
                    <Link to="/hot-drinks-arrangements">
                        <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                    </Link>
                </CTAsContainer>
            </Header>
            <HotDrinksArrangementDetailsContent hotDrinksArrangement={hotDrinksArrangement} products={products} />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const CTAsContainer = styled.div`
    display: flex;
    align-items: center;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

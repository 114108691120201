export const HOT_DRINKS_CATEGORY_ID = 'DxDpLm7gFZxBT3d4B';
export const FRUITS_CATEGORY_ID = 'zpfBvWe7A6NYXF6gZ';

export const TOTEM_INSTALLATION_CATEGORY_ID = 'QY4qSgsjwuYYBAEeh';
export const SMALL_BITES_CATEGORY_ID = 'b76d366d-99d6-4364-b91a-07474920cfd8';
export const BEER_CATEGORY_ID = 'DPogLHpv8CfAnheZ2';
export const BEER_EQUIPMENT_CATEGORY_ID = '7CpWgSsvLrC5pgqDE';
export const INDIVIDUAL_SNACKS_CATEGORY_ID = 'rT9FzJZAJQ6pe83t5';
export const SNACKS_IN_BULK_CATEGORY_ID = 'X5A978gGcnXzFfphi';
export const COLD_DRINKS_CATEGORY_ID = 'hRp2WC7tCSEE6DT7R';
export const DESSERTS_CATEGORY_ID = 'pPNNmDyijRkhyBq6n';
export const MEALS_CATEGORY_ID = 'HNJaaoCKYQvr5B5W8';
export const STARTERS_CATEGORY_ID = 'yatqdfxAyKEuuF7vK';
export const BAKERY_CATEGORY_ID = 'xcsRXJ4Jd8jpoXyo3';

export const FOOD_CATEGORIES = [
    HOT_DRINKS_CATEGORY_ID,
    FRUITS_CATEGORY_ID,
    BEER_CATEGORY_ID,
    INDIVIDUAL_SNACKS_CATEGORY_ID,
    SNACKS_IN_BULK_CATEGORY_ID,
    COLD_DRINKS_CATEGORY_ID,
    DESSERTS_CATEGORY_ID,
    MEALS_CATEGORY_ID,
    STARTERS_CATEGORY_ID,
    BAKERY_CATEGORY_ID,
];

import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';

import { formatDateAsAnniversary } from 'helpers/dateTimes';

import { GENERATE_MENUS_SUPPLY_ORDERS_MUTATION } from 'data/mutations/menus';
import {
    GENERATE_MENUS_SUPPLY_ORDERS,
    GENERATE_MENUS_SUPPLY_ORDERSVariables,
} from 'data/mutations/__generated__/GENERATE_MENUS_SUPPLY_ORDERS';

import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

export function GenerateMenusSupplyOrdersButton({ date }: { date: Date }) {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [generateMenusSupplyOrders, { loading }] = useMutation<
        GENERATE_MENUS_SUPPLY_ORDERS,
        GENERATE_MENUS_SUPPLY_ORDERSVariables
    >(GENERATE_MENUS_SUPPLY_ORDERS_MUTATION);

    async function handleClick() {
        try {
            await generateMenusSupplyOrders({
                variables: {
                    date: formatDateAsAnniversary({ dateTime: date, useNewFormat: true }),
                },
                onCompleted: ({ generateMenusSupplyOrders }) => {
                    if (generateMenusSupplyOrders?.success) {
                        toast.success('Les commandes fournisseurs ont bien été créées');
                    } else {
                        toast.error(
                            `Une erreur s'est produite : ${generateMenusSupplyOrders?.error || 'erreur inconnue'}`,
                        );
                    }
                },
                onError: (error) => {
                    toast.error(`Impossible de créer les commandes fournisseur : ${error}`);
                },
            });

            // update client with updated SiteMenusFromPrediction
        } catch (error) {
            toast.error(`Impossible de créer les commandes fournisseur : ${error}`);
            throw Error('Impossible de créer les commandes fournisseur');
        }
    }

    return (
        <>
            <TotemPopup
                title="Confirmer la création des achats"
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                contentOverflow="visible"
            >
                <InfoText>
                    En confirmant vous supprimerez les commandes fournisseurs 'Created' existantes pour la semaine du{' '}
                    {formatDateAsAnniversary({ dateTime: date })}
                </InfoText>
                <TotemPrimaryButton disabled={loading} onClick={handleClick}>
                    confirmer
                </TotemPrimaryButton>
            </TotemPopup>
            <TotemPrimaryButton disabled={loading} onClick={() => setIsOpen(true)}>
                Achats
            </TotemPrimaryButton>
        </>
    );
}

const InfoText = styled.div`
    padding: 20px 0px;
`;

import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import styled from 'styled-components';

import { UPDATE_PRODUCT_SWITCH_MUTATION } from 'data/mutations/productSwitch';
import { productSwitchesUpdatedOrCreatedHandler } from './cacheHandlers/productSwitchesCacheHandler';
import { ProductSwitchFragment } from 'data/fragments/__generated__/ProductSwitchFragment';
import {
    UPDATE_PRODUCT_SWITCH,
    UPDATE_PRODUCT_SWITCHVariables,
} from 'data/mutations/__generated__/UPDATE_PRODUCT_SWITCH';
import { GET_ALL_PRODUCTS_getAllProducts } from 'data/queries/__generated__/GET_ALL_PRODUCTS';
import { SupplyOrderProductStockVariationFragment } from 'data/fragments/__generated__/SupplyOrderProductStockVariationFragment';

import { Loader, LoaderModeType } from 'components/Loader';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { TotemInput } from 'components/TotemInput';
import { TotemLabel } from 'components/TotemLabel';
import { TotemSwitch } from 'components/TotemSwitch';
import { ProductsInfoStockVariation } from 'pages/SupplyOrders/SupplyOrderDetails/ProductsInfoStockVariation';

import { formatDateAsAnniversary } from 'helpers/dateTimes';

function computeProductAVariationAfterSwitch({
    productAVariation,
    ratioToOrderOfA,
    switchFreefood,
    switchStore,
}: {
    productAVariation: SupplyOrderProductStockVariationFragment | null;
    ratioToOrderOfA: number;
    switchFreefood: boolean;
    switchStore: boolean;
}): SupplyOrderProductStockVariationFragment | null {
    if (!productAVariation) {
        return null;
    }

    const newProductSwitchVariations = productAVariation.productSwitchDetails.variations.map((_, index) => {
        const freefoodDailyVariation = switchFreefood
            ? productAVariation.outboundFreefoodFloatStockVariation[index]
            : 0;
        const storeDailyVariation = switchStore ? productAVariation.outboundStoreFloatStockVariation[index] : 0;
        const dailyVariation = freefoodDailyVariation + storeDailyVariation;

        return Math.round((ratioToOrderOfA - 1) * dailyVariation);
    });

    const { newVariationAcc: newStockVariation } = productAVariation.stockVariation.reduce(
        ({ newVariationAcc, totalDiff }, dailyVariation, index) => {
            const productSwitchDailyVariation = productAVariation.productSwitchDetails.variations?.[index] ?? 0;
            const newProductSwitchDailyVariation = newProductSwitchVariations?.[index] ?? 0;
            const dailyDiff = productSwitchDailyVariation - newProductSwitchDailyVariation;
            const newDailyVariation = dailyVariation - (totalDiff + dailyDiff);
            newVariationAcc[index] = newDailyVariation;

            return {
                newVariationAcc,
                totalDiff: totalDiff + dailyDiff,
            };
        },
        { newVariationAcc: [...productAVariation.stockVariation], totalDiff: 0 },
    );

    return {
        ...productAVariation,
        productSwitchDetails: { ...productAVariation.productSwitchDetails, variations: newProductSwitchVariations },
        stockVariation: newStockVariation,
    };
}

function computeProductBVariationAfterSwitch({
    productAVariation,
    productBVariation,
    ratioOfAToOrderForB,
    switchFreefood,
    switchStore,
}: {
    productAVariation: SupplyOrderProductStockVariationFragment | null;
    productBVariation: SupplyOrderProductStockVariationFragment | null;
    ratioOfAToOrderForB: number;
    switchFreefood: boolean;
    switchStore: boolean;
}): SupplyOrderProductStockVariationFragment | null {
    if (!productBVariation) {
        return null;
    }

    if (!productAVariation) {
        return productBVariation;
    }

    const variationsDateDiffBetweenAandB =
        (new Date(productAVariation.createdAt).getDay() - new Date(productBVariation.createdAt).getDay() + 7) % 7;

    const newProductSwitchVariations = productBVariation.outboundStockVariation.map((_, index) => {
        const productAIndex =
            index - variationsDateDiffBetweenAandB > 0
                ? index - variationsDateDiffBetweenAandB
                : (index - variationsDateDiffBetweenAandB + 7) % 7;

        const freefoodDailyVariation = switchFreefood
            ? productAVariation.outboundFreefoodFloatStockVariation?.[productAIndex] ?? 0
            : 0;

        const storeDailyVariation = switchStore
            ? productAVariation.outboundStoreFloatStockVariation?.[productAIndex] ?? 0
            : 0;
        const dailyVariation = freefoodDailyVariation + storeDailyVariation;

        return Math.round(ratioOfAToOrderForB * dailyVariation);
    });

    const { newVariationAcc: newStockVariation } = productBVariation.stockVariation.reduce(
        ({ newVariationAcc, totalDiff }, dailyVariation, index) => {
            const productSwitchDailyVariation = productAVariation.productSwitchDetails.variations?.[index] ?? 0;
            const newProductSwitchDailyVariation = newProductSwitchVariations?.[index] ?? 0;
            const dailyDiff = productSwitchDailyVariation - newProductSwitchDailyVariation;
            const newDailyVariation = dailyVariation - (totalDiff + dailyDiff);
            newVariationAcc[index] = newDailyVariation;

            return {
                newVariationAcc,
                totalDiff: totalDiff + dailyDiff,
            };
        },
        { newVariationAcc: [...productBVariation.stockVariation], totalDiff: 0 },
    );

    return {
        ...productBVariation,
        productSwitchDetails: { ...productBVariation.productSwitchDetails, variations: newProductSwitchVariations },
        stockVariation: newStockVariation,
    };
}

export function ProductSwitchForm({
    products,
    productSwitch,
    setProductSwitchToArchive,
}: {
    products: GET_ALL_PRODUCTS_getAllProducts[];
    productSwitch: ProductSwitchFragment;
    setProductSwitchToArchive: React.Dispatch<React.SetStateAction<ProductSwitchFragment | null>>;
}) {
    const [productSwitchForm, setProductSwitchForm] = useState<ProductSwitchFragment>(productSwitch);

    const productA = products.find(({ _id }) => _id === productSwitch.productAId);
    const productB = products.find(({ _id }) => _id === productSwitch.productBId);

    const hasChanged = JSON.stringify(productSwitchForm) !== JSON.stringify(productSwitch);

    const [updateProductSwitch, { loading: updateLoading }] = useMutation<
        UPDATE_PRODUCT_SWITCH,
        UPDATE_PRODUCT_SWITCHVariables
    >(UPDATE_PRODUCT_SWITCH_MUTATION);

    async function update() {
        const { _id, ratioOfAToOrderForB, ratioToOrderOfA, switchFreefood, switchStore } = productSwitchForm;
        const { data } = await updateProductSwitch({
            variables: {
                productSwitchUpdateInput: { _id, ratioOfAToOrderForB, ratioToOrderOfA, switchFreefood, switchStore },
            },
        });

        if (!data) {
            throw new Error('Une erreur est survenue lors de la création du switch produit');
        }

        const { updateProductSwitch: newProductSwitch } = data;

        productSwitchesUpdatedOrCreatedHandler([newProductSwitch]);
    }

    const productAVariationAfterSwitch = computeProductAVariationAfterSwitch({
        productAVariation: productSwitchForm.productAVariation,
        ratioToOrderOfA: productSwitchForm.ratioToOrderOfA,
        switchFreefood: productSwitchForm.switchFreefood,
        switchStore: productSwitchForm.switchStore,
    });

    const productBVariationAfterSwitch = computeProductBVariationAfterSwitch({
        productAVariation: productSwitchForm.productAVariation,
        productBVariation: productSwitchForm.productBVariation,
        ratioOfAToOrderForB: productSwitchForm.ratioOfAToOrderForB,
        switchFreefood: productSwitchForm.switchFreefood,
        switchStore: productSwitchForm.switchStore,
    });

    return (
        <SubmitContainer>
            <Table>
                <tr>
                    <TableCell>
                        <TotemLabel>Produit A {productA?.fullname ?? 'Erreur'}</TotemLabel>
                    </TableCell>
                    <TableCell>
                        <TotemLabel>Produit B {productB?.fullname ?? 'Erreur'}</TotemLabel>
                    </TableCell>
                </tr>
                <tr>
                    <TableCell>
                        <TotemLabel>Début {productSwitch.startDate}</TotemLabel>
                    </TableCell>
                    <TableCell>
                        <TotemLabel>Fin {productSwitch.endDate}</TotemLabel>
                    </TableCell>
                </tr>
                <tr>
                    <TableCell>
                        <TotemSwitch
                            label="Changement des variations freefood"
                            checked={productSwitchForm.switchFreefood}
                            onChange={() => {
                                setProductSwitchForm({
                                    ...productSwitchForm,
                                    switchFreefood: !productSwitchForm.switchFreefood,
                                });
                            }}
                        />
                    </TableCell>
                </tr>
                <tr>
                    <TableCell>
                        <TotemSwitch
                            label="Changement des variations store"
                            checked={productSwitchForm.switchStore}
                            onChange={() => {
                                setProductSwitchForm({
                                    ...productSwitchForm,
                                    switchStore: !productSwitchForm.switchStore,
                                });
                            }}
                        />
                    </TableCell>
                </tr>
                <tr>
                    <TableCell>
                        <TotemInput
                            label="Ratio de commande du produit A"
                            sublabel="0.1 = on commande 10% de ce qu'on aurait du commander."
                            type="number"
                            min="0"
                            step="0.01"
                            value={productSwitchForm.ratioToOrderOfA}
                            onChange={(value) =>
                                setProductSwitchForm({ ...productSwitchForm, ratioToOrderOfA: parseFloat(value) })
                            }
                        />
                    </TableCell>
                    <TableCell>
                        <TotemInput
                            label="Ratio de commande du produit B par rapport au produit A"
                            sublabel="0.1 = on commande 10% de B de ce qu'on aurait du commander pour A"
                            type="number"
                            min="0"
                            step="0.01"
                            value={productSwitchForm.ratioOfAToOrderForB}
                            onChange={(value) =>
                                setProductSwitchForm({ ...productSwitchForm, ratioOfAToOrderForB: parseFloat(value) })
                            }
                        />
                    </TableCell>
                </tr>
                <tr>
                    {[productSwitch.productAVariation, productSwitch.productBVariation].map(
                        (productVariation, index) => (
                            <TableCell key={index}>
                                <TotemLabel>
                                    Dernières variations enregistrées :{' '}
                                    {productVariation
                                        ? formatDateAsAnniversary({
                                              dateTime: productVariation.createdAt,
                                          })
                                        : ''}
                                </TotemLabel>
                            </TableCell>
                        ),
                    )}
                </tr>
                <tr>
                    {[
                        { productVariation: productSwitch.productAVariation, product: productA },
                        { productVariation: productSwitch.productBVariation, product: productB },
                    ].map(({ productVariation, product }, index) => (
                        <TableCell key={index}>
                            {productVariation && product ? (
                                <ProductsInfoStockVariation
                                    isComputed={false}
                                    productStockVariation={productVariation}
                                    productInfo={product}
                                    stock={productVariation.initialStock ?? 0}
                                    startDate={formatDateAsAnniversary({
                                        dateTime: productVariation.createdAt,
                                    })}
                                    shouldDisplayTooltip={index < 3}
                                />
                            ) : (
                                'Pas de variation'
                            )}
                        </TableCell>
                    ))}
                </tr>
                <tr>
                    <TableCell>
                        <TotemLabel>Estimation des variations avec le switch :</TotemLabel>
                    </TableCell>
                    <TableCell>
                        <TotemLabel>Estimation des variations avec le switch :</TotemLabel>
                    </TableCell>
                </tr>
                <tr>
                    {[
                        { productVariation: productAVariationAfterSwitch, product: productA },
                        { productVariation: productBVariationAfterSwitch, product: productB },
                    ].map(({ productVariation, product }, index) => (
                        <TableCell key={index}>
                            {productVariation && product ? (
                                <ProductsInfoStockVariation
                                    isComputed={false}
                                    productStockVariation={productVariation}
                                    productInfo={product}
                                    stock={productVariation.initialStock ?? 0}
                                    startDate={formatDateAsAnniversary({
                                        dateTime: productVariation.createdAt,
                                    })}
                                    shouldDisplayTooltip={index < 3}
                                />
                            ) : (
                                'Pas de variation'
                            )}
                        </TableCell>
                    ))}
                </tr>
                <tr>
                    <FooterTableCell colSpan={2}>
                        <FooterContainer>
                            <TotemPrimaryButton disabled={!hasChanged} onClick={update}>
                                {updateLoading ? <Loader size="20px" mode={LoaderModeType.Spin} /> : 'Mettre à jour'}
                            </TotemPrimaryButton>
                            Statut : {productSwitch.state}
                            <TotemPrimaryButton onClick={() => setProductSwitchToArchive(productSwitch)}>
                                {updateLoading ? <Loader size="20px" mode={LoaderModeType.Spin} /> : 'Archiver'}
                            </TotemPrimaryButton>
                        </FooterContainer>
                    </FooterTableCell>
                </tr>
            </Table>
        </SubmitContainer>
    );
}

const SubmitContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 15px;
`;

const Table = styled.table`
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;

    background-color: ${({ theme }) => theme.cardBackgroundColor};
    border-radius: ${({ theme }) => theme.borderRadius};
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
`;

const TableCell = styled.td`
    width: 50%;
    overflow: auto;
    padding: 5px;
    border-left: 1px solid ${({ theme }) => theme.lightBorderColor};
    border-right: 1px solid ${({ theme }) => theme.lightBorderColor};
`;

const FooterTableCell = styled.td`
    overflow: auto;
    padding: 10px;
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
`;

const FooterContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
`;

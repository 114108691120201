import React from 'react';

import styled from 'styled-components';

import { TotemLabel } from '../TotemLabel';

type Item = {
    label: string;
    value: string | number | null;
};

export const DetailObjectValue = ({ label, items }: { label?: string; items: Item[] }) => {
    return (
        <Container>
            <TotemLabel>{label}</TotemLabel>
            <List>
                <tbody>
                    {items.map((item, index) => (
                        <tr key={index}>
                            <Cell isBold>{item.label}</Cell>
                            <Cell>{item.value ?? '⚠️ Valeur manquante'}</Cell>
                        </tr>
                    ))}
                </tbody>
            </List>
        </Container>
    );
};

const Container = styled.div`
    display: inline-flex;
    flex-direction: column;

    & > :not(:first-child) {
        margin-top: 5px;
    }
`;

const List = styled.table`
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
    min-width: 200px;

    border-spacing: 0;
    border-collapse: collapse;
`;

const Cell = styled.td`
    text-align: left;
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
    border-spacing: 1px;
    padding: 10px;
    font-weight: ${({ isBold }: { isBold?: boolean }) => (isBold ? 800 : 500)};
    color: ${({ theme }) => theme.textColor};
`;

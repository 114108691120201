export const ENV_PROD = 'production';
export const ENV_PREPROD = 'preprod';
export const ENV_DEV = 'development';
export const ENV_CYPRESS = 'cypress';

export function getEnvDomain(): string {
    switch (process.env.ENV) {
        case ENV_PROD:
            return 'admin.totem.co';
        case ENV_PREPROD:
            return 'admin-preprod.totem.co';
        case ENV_DEV:
        default:
            return 'localhost:3000';
    }
}

import React from 'react';

import styled from 'styled-components';

import { ColumnEditor } from './ColumnEditor';
import { LocationEditor } from './LocationEditor';
import { ShelfEditor } from './ShelfEditor';

import { MicrostoreColumnTemplateFormValues } from './FormHelper/DataMapper';

export const ColumnShelfLocationDetailedEditor = ({
    microstoreColumnTemplateFormValues,
    setMicrostoreColumnTemplateFormValuesAndIsFormDirty,
    selectedShelfTemplateIndex,
    setSelectedShelfTemplateIndex,
    selectedLocationTemplatePosition,
    setSelectedLocationTemplatePosition,
}: {
    microstoreColumnTemplateFormValues: MicrostoreColumnTemplateFormValues;
    setMicrostoreColumnTemplateFormValuesAndIsFormDirty: ({
        newMicrostoreColumnTemplateFormValues,
        newIsDirty,
    }: {
        newMicrostoreColumnTemplateFormValues: MicrostoreColumnTemplateFormValues;
        newIsDirty?: boolean | undefined;
    }) => void;
    selectedShelfTemplateIndex: number | null;
    setSelectedShelfTemplateIndex: (value: React.SetStateAction<number | null>) => void;
    selectedLocationTemplatePosition: {
        column: number;
        row: number;
    } | null;
    setSelectedLocationTemplatePosition: (
        value: React.SetStateAction<{
            column: number;
            row: number;
        } | null>,
    ) => void;
}) => {
    return (
        <Container>
            <LocationSelectorContainer>
                <ColumnEditorContainer>
                    <ColumnEditor
                        microstoreColumnTemplateFormValues={microstoreColumnTemplateFormValues}
                        selectedShelfTemplateIndex={selectedShelfTemplateIndex}
                        setSelectedShelfTemplateIndex={setSelectedShelfTemplateIndex}
                        setSelectedLocationTemplatePosition={setSelectedLocationTemplatePosition}
                        setMicrostoreColumnTemplateFormValuesAndIsFormDirty={
                            setMicrostoreColumnTemplateFormValuesAndIsFormDirty
                        }
                    />
                </ColumnEditorContainer>
                <ShelfEditorContainer>
                    <ShelfEditor
                        microstoreColumnTemplateFormValues={microstoreColumnTemplateFormValues}
                        selectedShelfTemplateIndex={selectedShelfTemplateIndex}
                        selectedLocationTemplatePosition={selectedLocationTemplatePosition}
                        setSelectedLocationTemplatePosition={setSelectedLocationTemplatePosition}
                        setMicrostoreColumnTemplateFormValuesAndIsFormDirty={
                            setMicrostoreColumnTemplateFormValuesAndIsFormDirty
                        }
                    />
                </ShelfEditorContainer>
            </LocationSelectorContainer>
            <ProductArrangementSelectorContainer>
                <LocationEditorContainer>
                    <LocationEditor
                        microstoreColumnTemplateFormValues={microstoreColumnTemplateFormValues}
                        selectedShelfTemplateIndex={selectedShelfTemplateIndex}
                        selectedLocationTemplatePosition={selectedLocationTemplatePosition}
                        setMicrostoreColumnTemplateFormValuesAndIsFormDirty={
                            setMicrostoreColumnTemplateFormValuesAndIsFormDirty
                        }
                    />
                </LocationEditorContainer>
            </ProductArrangementSelectorContainer>
        </Container>
    );
};

// we compute the height with vh because we want the column to be visible while taking the maximum height
const Container = styled.div`
    height: calc(100vh - 200px);
    flex-grow: 1;
    display: flex;
    flex-direction: row;
`;

const LocationSelectorContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex: 1 0 0;
`;

const ColumnEditorContainer = styled.div`
    width: 100%;
    height: 70%;
`;

const ShelfEditorContainer = styled.div`
    margin-top: 15px;
    width: 100%;
    height: 30%;
`;

const ProductArrangementSelectorContainer = styled.div`
    flex: 1 0 0;
    margin-left: 15px;
`;

const LocationEditorContainer = styled.div`
    width: 100%;
    height: 100%;
`;

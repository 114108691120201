import React from 'react';

import { useMutation } from '@apollo/client';
import { FcCalendar } from 'react-icons/fc';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { GET_SUPPLY_ORDERS_supplyOrders } from 'data/queries/__generated__/GET_SUPPLY_ORDERS';
import { MARK_SUPPLY_ORDERS_AS_ORDERED_MUTATION } from 'data/mutations/supplyOrder';
import {
    MARK_SUPPLY_ORDERS_AS_ORDERED,
    MARK_SUPPLY_ORDERS_AS_ORDEREDVariables,
} from 'data/mutations/__generated__/MARK_SUPPLY_ORDERS_AS_ORDERED';

import { Loader, LoaderModeType } from 'components/Loader';
import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

export function MarkAsOrderedPopup({
    supplyOrders,
    selectedSupplyOrderIds,
    setSelectedSupplyOrderIds,
    isOpen,
    setIsOpen,
}: {
    supplyOrders: GET_SUPPLY_ORDERS_supplyOrders[];
    selectedSupplyOrderIds: string[];
    setSelectedSupplyOrderIds: (supplyOrderIds: string[]) => void;
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
}) {
    const [markSupplyOrdersAsOrdered, { loading }] = useMutation<
        MARK_SUPPLY_ORDERS_AS_ORDERED,
        MARK_SUPPLY_ORDERS_AS_ORDEREDVariables
    >(MARK_SUPPLY_ORDERS_AS_ORDERED_MUTATION);

    const selectedSupplyOrders = supplyOrders.filter((supplyOrder) => selectedSupplyOrderIds.includes(supplyOrder._id));

    async function handleSubmit({ shouldSendMailToSupplier }: { shouldSendMailToSupplier: boolean }) {
        const { data } = await markSupplyOrdersAsOrdered({
            variables: { supplyOrderIds: selectedSupplyOrderIds, shouldSendMailToSupplier },
        });

        if (!data) {
            throw new Error("Une erreur est survenue lors du passage des achats en 'commandés'");
        }

        const {
            markSupplyOrdersAsOrdered: { updatedSupplyOrders, errors, warnings },
        } = data;

        if (updatedSupplyOrders.length) {
            toast.success(`Achat(s) mis en 'commandé(s)' : ${updatedSupplyOrders.length}`);
        }

        if (errors.length) {
            errors.forEach((error, index) => {
                toast.error(<span key={index}>Erreur : {error}</span>, { autoClose: false });
            });
        }

        if (warnings?.length) {
            warnings.forEach((warning, index) => {
                toast.warning(<span key={index}>Warning : {warning}</span>, { autoClose: false });
            });
        }

        if (errors.length + (warnings?.length ?? 0) > 1) {
            toast.info('Cliquez pour fermer toutes les notifications', {
                autoClose: false,
                onClick: () => toast.dismiss(),
            });
        }

        setSelectedSupplyOrderIds([]);
        setIsOpen(false);
    }

    return (
        <TotemPopup title="Êtes-vous sûr.e ?" isOpen={isOpen} setIsOpen={setIsOpen}>
            <PopupContentContainer>
                <div>
                    Vous vous apprêtez à passer à 'commandé(s)' {selectedSupplyOrders.length} achat(s). Souhaitez-vous
                    vraiment modifier le statut du/des achat(s) suivant(s) ?
                </div>
                <SupplyOrderlist>
                    {selectedSupplyOrders.map((supplyOrder) => {
                        const { _id: supplyOrderId, orderDate, dateDeliveryScheduled, number, supplier } = supplyOrder;

                        return (
                            <SupplyOrder key={supplyOrderId}>
                                <SupplyOrderTitle>
                                    {supplier.name} #{number}
                                </SupplyOrderTitle>
                                <SupplyOrderDate>
                                    <FcCalendar size={26} />
                                    Date de commande : {orderDate ?? 'N/A'}
                                </SupplyOrderDate>
                                <SupplyOrderDate>
                                    <FcCalendar size={26} />
                                    Date de livraison prévue : {dateDeliveryScheduled ?? 'N/A'}
                                </SupplyOrderDate>
                            </SupplyOrder>
                        );
                    })}
                </SupplyOrderlist>
                <div>
                    Vous pouvez choisir d'envoyer automatiquement un mail au fournisseur en utilisant{' '}
                    <a
                        href="https://drive.google.com/drive/folders/15xLcDS3pRCQ3ZPk84Q-VfreVZPfLy644"
                        target="_blank"
                        rel="noreferrer"
                    >
                        les bons de commandes générés automatiquement.
                    </a>
                </div>
                <SubmitContainer>
                    <TotemPrimaryButton onClick={() => handleSubmit({ shouldSendMailToSupplier: true })}>
                        {loading ? (
                            <Loader size="20px" mode={LoaderModeType.Spin} />
                        ) : (
                            'Envoyer le mail aux fournisseurs'
                        )}
                    </TotemPrimaryButton>
                    <TotemPrimaryButton onClick={() => handleSubmit({ shouldSendMailToSupplier: false })}>
                        {loading ? (
                            <Loader size="20px" mode={LoaderModeType.Spin} />
                        ) : (
                            'Ne pas envoyer le mail aux fournisseurs'
                        )}
                    </TotemPrimaryButton>
                </SubmitContainer>
            </PopupContentContainer>
        </TotemPopup>
    );
}

const PopupContentContainer = styled.div`
    & > :not(:first-child) {
        margin-top: 20px;
    }
`;

const SupplyOrderlist = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 50px;
    overflow: auto;

    & > :not(:first-child) {
        margin-top: 5px;
    }
`;

const SupplyOrder = styled.span`
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
`;

const SupplyOrderDate = styled.span`
    display: inline-flex;
    align-items: center;
    margin-left: 15px;
`;

const SupplyOrderTitle = styled.span`
    display: inline-flex;
    align-items: center;
    font-weight: 800;
    flex-grow: 1;
`;

const SubmitContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
`;

export const colors = {
    pureBlack: '#000000',
    primaryYellow: '#ffe12c',
    black: '#111111',
    hoveredBlack: '#333333',
    yellow: '#ffeb00',
    greenLighter: '#66F4AC',
    green: '#00ED76',
    greenDarker: '#29DD82',
    orange: '#ffa500', // not the correct color of the palette but color palette not good for warning color (because close to red)
    pink: '#ff00A8',
    blue: '#093AFB',
    blandBlue: '#809AFF',
    emeraldBlue: '#00b6a8',
    blueOutline: '#2684FF', // not in the color palette,
    violet: '#8600FC',
    pureWhite: '#FFFFFF',
    whiteGrey: '#F9F9F9', // white element is hovered
    culturedGrey: '#f2f0f1', // backgroud
    lightGrey: '#E0E0E0', // shadows OR [disabled] background + border
    borderGrey: '#c6c6c6', // border
    pantinGrey: '#939393', // secondary text, informative, placeholder
    darkGrey: '#404040', // dark element is hovered + darker border
    red: '#f46a6a', // // not in the color palette, for errors
};

import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { CREATE_PRODUCT_ARRANGEMENT_MUTATION } from 'data/mutations/productArrangement';
import {
    CREATE_PRODUCT_ARRANGEMENT,
    CREATE_PRODUCT_ARRANGEMENTVariables,
} from 'data/mutations/__generated__/CREATE_PRODUCT_ARRANGEMENT';

import { Loader, LoaderModeType } from 'components/Loader';
import { TotemInput } from 'components/TotemInput';
import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

import { productArrangementsUpdatedOrCreatedHandler } from '../cacheHandlers/productArrangementsCacheHandler';

export function CreateProductArrangementPopup({
    isOpen,
    setIsOpen,
}: {
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
}) {
    const [productArrangementName, setProductArrangementName] = useState<string>('');

    const [createProductArrangement, { loading: creationLoading }] = useMutation<
        CREATE_PRODUCT_ARRANGEMENT,
        CREATE_PRODUCT_ARRANGEMENTVariables
    >(CREATE_PRODUCT_ARRANGEMENT_MUTATION, { variables: { name: productArrangementName } });

    async function handleSubmit() {
        const { data } = await createProductArrangement();

        if (!data) {
            throw new Error('Une erreur est survenue lors de la creation de la gamme de produit');
        }

        const {
            createProductArrangementMutation: { newProductArrangement, errors },
        } = data;

        if (errors.length) {
            errors.forEach((error, index) => {
                toast.error(<span key={index}>Erreur : {error}</span>, { autoClose: false });
            });

            if (errors.length > 1) {
                toast.info('Cliquez pour fermer toutes les notifications', {
                    autoClose: false,
                    onClick: () => toast.dismiss(),
                });
            }
        }

        if (newProductArrangement) {
            productArrangementsUpdatedOrCreatedHandler([newProductArrangement]);

            const newWindow = window.open(
                `productArrangement/${newProductArrangement._id}`,
                '_blank',
                'noopener,noreferrer',
            );
            if (newWindow) {
                newWindow.opener = null;
            }
        }

        setIsOpen(false);
    }

    return (
        <TotemPopup title="Créer une gamme de produit" isOpen={isOpen} setIsOpen={setIsOpen} contentOverflow="visible">
            Veuillez donner un nom unique à votre gamme de produit
            <SubmitContainer>
                <TotemInput
                    label="Nom de la gamme de produit"
                    onChange={setProductArrangementName}
                    placeholder="Dessert Bergam laitier"
                    value={productArrangementName}
                    autoFocus={true}
                />
                <ValidationButton onClick={handleSubmit}>
                    {creationLoading ? <Loader size="20px" mode={LoaderModeType.Spin} /> : 'Confirmer'}
                </ValidationButton>
            </SubmitContainer>
        </TotemPopup>
    );
}

const SubmitContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 15px;
`;

const ValidationButton = styled(TotemPrimaryButton)`
    margin-top: 15px;
`;

import { gql } from '@apollo/client';

export const TAG_QUERY_FRAGMENT = gql`
    fragment TagQueryFragment on Tag {
        _id
        displayIndex
        name
        type
        state
    }
`;

export const TAG_MUTATION_FRAGMENT = gql`
    fragment TagMutationFragment on Tag {
        _id
        createdAt
        displayIndex
        name
        type
        state
        stateHistory {
            state
            createdAt
        }
        updatedAt
    }
`;

import gql from 'graphql-tag';

import { CONSUMPTION_VARIATION_FRAGMENT } from '../fragments/consumptionVariation';

export const GET_SITE_CONSUMPTION_VARIATIONS_QUERY = gql`
    query GET_SITE_CONSUMPTION_VARIATIONS($siteId: ID!) {
        siteConsumptionVariations(siteId: $siteId) {
            ...ConsumptionVariationFragment
        }
    }
    ${CONSUMPTION_VARIATION_FRAGMENT}
`;

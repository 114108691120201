import React from 'react';

import { useQuery } from '@apollo/client';
import styled from 'styled-components';

import { FieldToDisplay, ListView } from 'components/ListView';
import { Loader } from 'components/Loader';

import { getExtendedFormattedDatetime } from '../../../helpers/dateTimes';
import { GET_USERS_QUERY } from 'data/queries/user';
import { GET_USERS, GET_USERSVariables } from 'data/queries/__generated__/GET_USERS';

type FormattedUserType = {
    _id: string;
    fullname: string;
    lastSeenAt: string;
    state: string;
    username: string;
};

export const UserList = ({ filterString }: { filterString: string }) => {
    const {
        loading: usersLoading,
        data: usersData,
        error: usersError,
        fetchMore: fetchMoreUsers,
    } = useQuery<GET_USERS, GET_USERSVariables>(GET_USERS_QUERY, {
        variables: { offset: 0, filterString },
        fetchPolicy: 'cache-and-network',
    });

    if (usersLoading && !usersData) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (usersError || !usersData) {
        throw new Error('Une erreur est survenue lors de la récupération des users');
    }

    const {
        users: { hasMore, users },
    } = usersData;

    const loadMore = async () => {
        await fetchMoreUsers({
            variables: {
                offset: users.length,
            },
        });
    };

    const USER_FIELDS_TO_DISPLAY: FieldToDisplay<FormattedUserType>[] = [
        {
            fieldName: 'fullname',
            label: 'Nom',
        },
        {
            fieldName: 'username',
            label: 'Username',
        },
        {
            fieldName: '_id',
            label: 'ID',
        },
        {
            fieldName: 'lastSeenAt',
            label: 'Dernière connexion',
        },
        {
            fieldName: 'state',
            label: 'Statut',
        },
    ];

    const sortedUsers = [...users].sort((userA, userB) => (userA.createdAt > userB.createdAt ? -1 : 1));

    const formattedusers: FormattedUserType[] = sortedUsers.map(
        ({ _id, username, lastSeenAt, profile: { fullname, state } }) => ({
            _id,
            username,
            fullname: fullname || 'non renseigné',
            state: state || 'inconnu',
            lastSeenAt: lastSeenAt ? getExtendedFormattedDatetime(lastSeenAt) : 'jamais',
        }),
    );

    return (
        <ListView<FormattedUserType>
            fieldsToDisplay={USER_FIELDS_TO_DISPLAY}
            items={formattedusers}
            keyExtractor={(item) => item._id}
            loadMore={loadMore}
            linkBasePath="/user/"
            hasMore={hasMore}
        />
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

import { GET_SITE_RITUALS_siteRituals } from 'data/queries/__generated__/GET_SITE_RITUALS';

import { isFirstTimeLowerThanSecond } from './dateTimes';

export function getRitualCalendarExtremeHours(rituals: GET_SITE_RITUALS_siteRituals[]): {
    minHour: string;
    maxHour: string;
} {
    let minHour = rituals[0].timeWindow.start;
    let maxHour = rituals[0].timeWindow.end;

    rituals.forEach((ritual) => {
        const {
            timeWindow: { start, end },
        } = ritual;
        if (isFirstTimeLowerThanSecond(start, minHour)) {
            minHour = start;
        }
        if (isFirstTimeLowerThanSecond(maxHour, end)) {
            maxHour = end;
        }
    });

    return { minHour, maxHour };
}

import React from 'react';
import styled from 'styled-components';

import { colors } from 'constants/colors';

import { GET_MICROSTORE_PRODUCTS_getMicrostoreProducts } from 'data/queries/__generated__/GET_MICROSTORE_PRODUCTS';
import { SetupInstructionFragment_RemoveProductsInstruction_removedProducts } from 'data/fragments/__generated__/SetupInstructionFragment';

import { dateFromString } from 'helpers/dateTimes';

export const RemovedProductsSection = ({
    removedProducts,
    inventoryDate,
    products,
}: {
    removedProducts: SetupInstructionFragment_RemoveProductsInstruction_removedProducts[];
    inventoryDate: string;
    products: GET_MICROSTORE_PRODUCTS_getMicrostoreProducts[];
}) => {
    const startOfInventoryDay = dateFromString(inventoryDate, true);
    startOfInventoryDay.setHours(0, 0, 0, 0);

    const removedProductIds = removedProducts.map(({ productId }) => productId);
    const removedProductsFromCatalog = products.filter((product) => removedProductIds.includes(product._id));

    return (
        <ProductsContainer>
            {removedProductsFromCatalog.map((removedProductFromCatalog) => {
                const associatedRemovedProducts = removedProducts.filter(
                    ({ productId }) => productId === removedProductFromCatalog._id,
                );
                return (
                    <ProductContainer key={removedProductFromCatalog._id}>
                        <ProductName>{removedProductFromCatalog.name}</ProductName>
                        {/* Todo: in a future developement, the removed products will be associated to a location */}
                        <LocationsContainer>
                            {associatedRemovedProducts.map((removedProduct, index) => {
                                return (
                                    <LocationContainer key={index}>
                                        <QuantitiesContainer>
                                            {!removedProduct.quantityDetails.length ? (
                                                <QuantityContainer>Vide</QuantityContainer>
                                            ) : null}
                                            {removedProduct.quantityDetails.map(({ expiryDate, quantity }) => {
                                                const isExpired = !!(
                                                    expiryDate && dateFromString(expiryDate) < startOfInventoryDay
                                                );
                                                return (
                                                    <QuantityContainer isExpired={isExpired}>
                                                        Qty: {quantity} ; DLC: {expiryDate ?? 'Non Rensigné'}
                                                    </QuantityContainer>
                                                );
                                            })}
                                        </QuantitiesContainer>
                                    </LocationContainer>
                                );
                            })}
                        </LocationsContainer>
                    </ProductContainer>
                );
            })}
        </ProductsContainer>
    );
};

const ProductsContainer = styled.div`
    flex-grow: 1;
    & > :not(:first-child) {
        border-top: 1px solid ${colors.lightGrey};
        margin-top: 5px;
    }
`;
const ProductContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;
const ProductName = styled.div`
    font-weight: bold;
    width: 50%;
`;
const LocationsContainer = styled.div`
    margin-left: 10px;
    width: 50%;
`;
const LocationContainer = styled.div`
    display: flex;
`;
const QuantitiesContainer = styled.div``;
const QuantityContainer = styled.div<{ isExpired?: boolean }>`
    margin-left: 10px;
    color: ${({ isExpired }) => (isExpired ? colors.red : colors.pureBlack)};
`;

import React from 'react';

import { useMutation } from '@apollo/client';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { FaSave, FaUndoAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';

import { OpsParameterFragment } from 'data/fragments/__generated__/OpsParameterFragment';
import {
    UPDATE_NUMERICAL_OPS_PARAMETER,
    UPDATE_NUMERICAL_OPS_PARAMETERVariables,
} from 'data/mutations/__generated__/UPDATE_NUMERICAL_OPS_PARAMETER';
import {
    UPDATE_BOOLEAN_OPS_PARAMETER_MUTATION,
    UPDATE_NUMERICAL_OPS_PARAMETER_MUTATION,
} from 'data/mutations/opsParameter';
import {
    UPDATE_BOOLEAN_OPS_PARAMETER,
    UPDATE_BOOLEAN_OPS_PARAMETERVariables,
} from 'data/mutations/__generated__/UPDATE_BOOLEAN_OPS_PARAMETER';

import { DetailFormCheckbox, DetailFormValue } from 'components/DetailsView';
import { Loader, LoaderModeType } from 'components/Loader';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { unstable_usePrompt } from 'react-router-dom';

type FormData = {
    value: number | boolean;
};

export const OpsParameterForm = ({ opsParameter }: { opsParameter: OpsParameterFragment }) => {
    const [updateNumericalOpsParameter, { loading: updateNumericalLoading }] = useMutation<
        UPDATE_NUMERICAL_OPS_PARAMETER,
        UPDATE_NUMERICAL_OPS_PARAMETERVariables
    >(UPDATE_NUMERICAL_OPS_PARAMETER_MUTATION);
    const [updateBooleanOpsParameter, { loading: updateBooleanLoading }] = useMutation<
        UPDATE_BOOLEAN_OPS_PARAMETER,
        UPDATE_BOOLEAN_OPS_PARAMETERVariables
    >(UPDATE_BOOLEAN_OPS_PARAMETER_MUTATION);

    const updateLoading = updateNumericalLoading || updateBooleanLoading;

    const methods = useForm<FormData>();
    const {
        formState: { isDirty },
        handleSubmit,
        register,
        reset,
    } = methods;

    unstable_usePrompt({
        message: 'Êtes-vous sûr.e de vouloir quitter cette page sans enregistrer ?',
        when: ({ currentLocation, nextLocation }) => isDirty && currentLocation.pathname !== nextLocation.pathname,
    });

    const handleFormSubmit = handleSubmit(async ({ value }) => {
        if (typeof value === 'number') {
            const { data } = await updateNumericalOpsParameter({
                variables: {
                    opsParameterId: opsParameter._id,
                    value,
                },
            });

            if (data) {
                const { updateNumericalOpsParameter } = data;
                toast.success(`Le paramètre "${opsParameter.name}" a bien été modifié`);
                reset({
                    value:
                        'numericalValue' in updateNumericalOpsParameter
                            ? updateNumericalOpsParameter.numericalValue
                            : undefined,
                });
            } else {
                throw Error("Une erreur inconnue s'est produite");
            }
        } else {
            const { data } = await updateBooleanOpsParameter({
                variables: {
                    opsParameterId: opsParameter._id,
                    value,
                },
            });

            if (data) {
                const { updateBooleanOpsParameter } = data;
                toast.success(`Le paramètre "${opsParameter.name}" a bien été modifié`);
                reset({
                    value:
                        'booleanValue' in updateBooleanOpsParameter
                            ? updateBooleanOpsParameter.booleanValue
                            : undefined,
                });
            } else {
                throw Error("Une erreur inconnue s'est produite");
            }
        }
    });

    return (
        <>
            <FormProvider {...methods}>
                <Form onSubmit={handleFormSubmit}>
                    <Content>
                        {'numericalValue' in opsParameter ? (
                            <DetailFormValue
                                defaultValue={opsParameter.numericalValue}
                                type="number"
                                step="0.00001"
                                {...register('value', {
                                    required: true,
                                    valueAsNumber: true,
                                })}
                            />
                        ) : null}
                        {'booleanValue' in opsParameter ? (
                            <DetailFormCheckbox name="value" defaultChecked={opsParameter.booleanValue} />
                        ) : null}
                        <TotemPrimaryButton type="submit" disabled={!isDirty}>
                            {updateLoading ? <Loader size="18px" mode={LoaderModeType.Spin} /> : <FaSave size={13} />}
                        </TotemPrimaryButton>
                        <TotemPrimaryButton isSecondaryStyle type="button" disabled={!isDirty} onClick={() => reset()}>
                            {updateLoading ? (
                                <Loader size="18px" mode={LoaderModeType.Spin} />
                            ) : (
                                <FaUndoAlt size={13} />
                            )}
                        </TotemPrimaryButton>
                    </Content>
                </Form>
            </FormProvider>
        </>
    );
};

const Form = styled.form`
    display: flex;
    flex: 1;
`;

const Content = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

import { gql } from '@apollo/client';
import { COMMENT_FRAGMENT } from 'data/fragments/commentFragment';

export const GET_COMMENTS_QUERY = gql`
    query Comments($offset: Int!, $limit: Int!) {
        comments(offset: $offset, limit: $limit) {
            comments {
                ...CommentFragment
                answers {
                    ...CommentFragment
                }
            }
            hasMore
        }
    }
    ${COMMENT_FRAGMENT}
`;

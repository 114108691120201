import { SupplyOrderProductReceivedDetailWithoutTypeName } from '../SupplyOrderDetails/supplyOrderDetailsFormHelper';

export function getSupplyOrderProductReceivedTotal(
    productReceivedDetails: SupplyOrderProductReceivedDetailWithoutTypeName[],
): {
    brokenItemsReceived: number | null;
    colisReceived: number | null;
    conditionningReceived: number | null;
    quantityReceived: number | null;
} {
    const {
        brokenItemsReceived = null,
        colisReceived = null,
        quantityReceived = null,
    } = productReceivedDetails.length
        ? productReceivedDetails.reduce(
              (
                  {
                      brokenItemsReceived,
                      colisReceived,
                      quantityReceived,
                  }: {
                      brokenItemsReceived: number;
                      colisReceived: number;
                      quantityReceived: number;
                  },
                  { brokenItems, colis, quantity },
              ) => ({
                  brokenItemsReceived: brokenItemsReceived + brokenItems,
                  colisReceived: colisReceived + colis,
                  quantityReceived: quantityReceived + quantity,
              }),
              {
                  brokenItemsReceived: 0,
                  colisReceived: 0,
                  quantityReceived: 0,
              },
          )
        : {};

    const conditionningReceived = productReceivedDetails.length ? productReceivedDetails[0].conditionning : null;

    return {
        brokenItemsReceived,
        colisReceived,
        conditionningReceived,
        quantityReceived,
    };
}

import gql from 'graphql-tag';
import { SUPPLY_ORDER_PRODUCT_STOCK_VARIATION_FRAGMENT } from './supplyOrder';

export const PRODUCT_SWITCH_FRAGMENT = gql`
    fragment ProductSwitchFragment on ProductSwitch {
        _id
        createdAt
        endDate
        productAId
        productAVariation {
            ...SupplyOrderProductStockVariationFragment
        }
        productBId
        productBVariation {
            ...SupplyOrderProductStockVariationFragment
        }
        ratioToOrderOfA
        ratioOfAToOrderForB
        startDate
        state
        switchFreefood
        switchStore
        updatedAt
    }
    ${SUPPLY_ORDER_PRODUCT_STOCK_VARIATION_FRAGMENT}
`;

import { gql } from '@apollo/client';

export const DETAILED_ORDER_FRAGMENT = gql`
    fragment DetailedOrderFragment on Order {
        _id
        appreciation
        badService
        delivery {
            _id
            stateHistory {
                state
            }
        }
        deliveryDate
        deliveryTimeWindows {
            start
            stop
        }
        productsWithQuantityPicked {
            _id
            name
            quantity
            quantityPicked
            unitPrice
        }
        discount {
            amount
            description
            type
        }
        feedback
        frequency
        hasOnsiteSetup
        isPunctual
        number
        organization {
            _id
            hasSubscription
            name
        }
        pausedInfo {
            reason
            returnDate
        }
        paymentId
        previous_orderId
        prices {
            discount
            discount055
            discount10
            discount20
            price
            price055
            price10
            price20
            priceDelivery
            priceHT
            priceHT055
            priceHT10
            priceHT20
            priceTTC
            priceTTC055
            priceTTC10
            priceTTC20
            tva
            tva055
            tva10
            tva20
        }
        productsRequestedButNotFullyDelivered {
            _id
            name
            unitPrice
            quantityRequested
        }
        state
        stateHistory {
            state
            createdAt
            createdBy
            createdByName
        }
        totem {
            _id
            name
            number
        }
        user {
            _id
            profile {
                firstname
                lastname
            }
        }
        weekday
    }
`;

import React, { useState } from 'react';

import CSVReader from 'react-csv-reader';
import styled, { css } from 'styled-components';

export type CsvDataRow = Array<string>;

export function TotemCsvInputButton({
    onCsvDataUpload,
    disabled,
    label,
}: {
    onCsvDataUpload: (data: Array<CsvDataRow>) => void;
    disabled?: boolean;
    label?: string;
}) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const handleCsvUpload = (data: Array<CsvDataRow>) => {
        setIsLoading(true);
        if (data?.length) {
            onCsvDataUpload(data);
        }
        setIsLoading(false);
    };

    const usedLabel = label ?? 'Sélectionner un fichier CSV';

    // With the CsvReader, we cannot add the same file twice. Therefore we did a hack :
    // we use the isLoading props to unmount the CSVReader component so it's reinitialise
    return (
        <ReaderContainer disabled={disabled}>
            {isLoading ? (
                <div>
                    <label>{usedLabel}</label>
                </div>
            ) : (
                <CSVReader label={usedLabel} onFileLoaded={handleCsvUpload} disabled={isLoading} />
            )}
        </ReaderContainer>
    );
}

const ReaderContainer = styled.div`
    margin-right: 5px;
    > div {
        display: flex;
        border: 1px solid ${({ theme }) => theme.ctaPrimaryColor};
        border-radius: ${({ theme }) => theme.borderRadius};

        > label {
            display: inline-block;
            text-align: center;
            padding: 16px 32px 18px 32px;
            color: ${({ theme }) => theme.ctaPrimaryTextColor};
            background-color: ${({ theme }) => theme.ctaPrimaryColor};
            font-size: 15px;
            font-weight: 800;
            text-transform: uppercase;
            letter-spacing: 2px;
            border: 0px;
            cursor: pointer;

            &:hover {
                background: ${({ theme }) => theme.ctaPrimaryHoveredColor};
            }
            ${({ disabled }: { disabled?: boolean }) =>
                disabled &&
                css`
                    background-color: ${({ theme }) => theme.disabledColor};
                    cursor: default;
                    pointer-events: none;
                `}
        }
        > input {
            display: none;
        }
    }
`;

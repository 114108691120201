type CsvDataRow = Array<string>;
type CsvCheckReturn = {
    success: boolean;
    error?: string;
};

export function checkStockCsvImport({ csvData }: { csvData: Array<CsvDataRow> }): CsvCheckReturn {
    const [header, ...data] = csvData;
    const productIdIndex = header.findIndex((elem) => elem === 'productId');
    const quantityIndex = header.findIndex((elem) => elem === 'quantity');

    const arrayLength = header.length;

    if (!data.length) {
        return {
            success: false,
            error: 'Veuillez vérifier le fichier CSV. Il semble être vide',
        };
    }

    //if extra line in 2 column file, corrups all data, last line - ['']
    if (data[data.length - 1].length === 1 && data[data.length - 1][0] === '') {
        return {
            success: false,
            error: "Fichier CSV mal formaté. Veuillez vérifier que vous n'avez pas laissé de lignes ou de colonnes vides",
        };
    }

    if (productIdIndex === -1 || quantityIndex === -1) {
        return {
            success: false,
            error: "Veuillez vérifier les champs CSV. Il devrait y avoir 'productId' et 'quantity'",
        };
    }

    let error;
    data.forEach((productArrayFromCSV: Array<string>, rowIndex: number): void => {
        if (productArrayFromCSV.length !== arrayLength) {
            error = `Veuillez vérifier le fichier CSV. Mauvais nombre de colonnes sur la ligne ${rowIndex + 2}`;
        }
    });

    data.forEach((productArrayFromCSV: Array<string>, rowIndex: number): void => {
        productArrayFromCSV.forEach((field: string, fieldIndex: number) => {
            if (!field) {
                error = `Veuillez vérifier le fichier CSV. Problème de champs sur la ligne ${rowIndex + 2}, colonne ${
                    fieldIndex + 1
                }`;
            }
        });
    });

    data.forEach((productArrayFromCSV: Array<string>, rowIndex: number): void => {
        const quantity = Number(productArrayFromCSV[quantityIndex].replace(',', '.'));
        if (Number.isNaN(quantity) || !Number.isInteger(quantity)) {
            error = `Veuillez vérifier le fichier CSV. Quantité non valide sur la ligne ${rowIndex + 2}`;
        }
    });

    return { success: !error, error };
}

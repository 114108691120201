import gql from 'graphql-tag';
import { STOCK_TRANSFER_FRAGMENT } from '../fragments/stockTransfer';

export const GET_STOCK_TRANSFERS_QUERY = gql`
    query GET_STOCK_TRANSFERS($offset: Int!, $filterString: String, $states: [String]) {
        stockTransfers(offset: $offset, filterString: $filterString, states: $states) {
            hasMore
            stockTransfers {
                _id
                transferDate
                state
                destinationSiteName
                originSiteName
                quantityOfProducts
                numberOfRefs
                updatedAt
            }
        }
    }
`;

export const GET_RETURNED_STOCK_TRANSFERS_QUERY = gql`
    query GET_RETURNED_STOCK_TRANSFERS($transferDate: String!) {
        returnedStockTransfers(transferDate: $transferDate) {
            _id
            state
            originSiteName
            products {
                productId
                fullname
                originStockAvailable
                destinationStockAvailable
                quantity
            }
            updatedAt
        }
    }
`;

export const GET_STOCK_TRANSFER_QUERY = gql`
    query GET_STOCK_TRANSFER($stockTransferId: ID!) {
        stockTransfer(stockTransferId: $stockTransferId) {
            ...StockTransferFragment
        }
    }
    ${STOCK_TRANSFER_FRAGMENT}
`;

import { useQuery } from '@apollo/client';
import React from 'react';

import styled from 'styled-components';

import { GET_SITES_QUERY } from 'data/queries/site';
import { GET_SITES } from 'data/queries/__generated__/GET_SITES';

import { Loader } from 'components/Loader';

import { SectionContainer } from 'components/DetailsView/Section';
import { formatDateAsAnniversary } from 'helpers/dateTimes';
import { GET_STOCK_VARIATIONS_FOR_DATE_QUERY } from 'data/queries/stockVariation';
import {
    GET_STOCK_VARIATIONS_FOR_DATE,
    GET_STOCK_VARIATIONS_FOR_DATEVariables,
} from 'data/queries/__generated__/GET_STOCK_VARIATIONS_FOR_DATE';
import { StockVariationType } from 'data/__generated__';
import { StockVariationFragment } from 'data/fragments/__generated__/StockVariationFragment';
import { StockVariationList } from '../StockVariationList';
import { siteVariationsLabels } from '../constants';
import { GET_ALL_PRODUCTS_QUERY } from 'data/queries/product';
import { GET_ALL_PRODUCTS } from 'data/queries/__generated__/GET_ALL_PRODUCTS';

export const SiteVariationsList = ({ date }: { date: Date }) => {
    const {
        data: stockVariationsData,
        error: stockVariationsError,
        loading: stockVariationsLoading,
    } = useQuery<GET_STOCK_VARIATIONS_FOR_DATE, GET_STOCK_VARIATIONS_FOR_DATEVariables>(
        GET_STOCK_VARIATIONS_FOR_DATE_QUERY,
        {
            fetchPolicy: 'cache-and-network',
            variables: { date: formatDateAsAnniversary({ dateTime: date, useNewFormat: true }) },
        },
    );

    const {
        loading: productsLoading,
        data: productsData,
        error: productsError,
    } = useQuery<GET_ALL_PRODUCTS>(GET_ALL_PRODUCTS_QUERY);

    const { loading: sitesLoading, data: sitesData, error: sitesError } = useQuery<GET_SITES>(GET_SITES_QUERY);

    if (
        (stockVariationsLoading && !stockVariationsData) ||
        (sitesLoading && !sitesData) ||
        (productsLoading && !productsData)
    ) {
        return (
            <Content>
                <Loader />
            </Content>
        );
    }

    if (stockVariationsError || !stockVariationsData || sitesError || !sitesData || productsError || !productsData) {
        throw new Error('Une erreur est survenue lors de la récupération des variations');
    }

    function mapVariationsByProductId(variations: StockVariationFragment[]): Map<string, StockVariationFragment[]> {
        const variationsByProductId: Map<string, StockVariationFragment[]> = new Map();
        variations.forEach((variation) =>
            variationsByProductId.set(variation.productId, [
                ...(variationsByProductId.get(variation.productId) || []),
                variation,
            ]),
        );

        return variationsByProductId;
    }

    function displayVariationsByProduct(variations: StockVariationFragment[]) {
        const variationsByProductId = mapVariationsByProductId(variations);
        return Array.from(variationsByProductId.keys()).map((productId) => {
            const product = products.find(({ _id }) => _id === productId);
            const productVariations = variationsByProductId.get(productId) || [];
            return (
                <ProductVariationContainer key={productId}>
                    <ProductName>
                        {product ? `${product.name} - ${product.brand} - ${product.volume}` : productId}
                    </ProductName>
                    <StockVariationList variations={productVariations} />
                </ProductVariationContainer>
            );
        });
    }

    const { sites } = sitesData;
    const { getStockVariationsForDate: stockVariations } = stockVariationsData;
    const { getAllProducts: products } = productsData;

    return (
        <Content>
            {sites.map((site) => {
                const siteStockVariations = stockVariations.filter(({ siteId }) => siteId === site._id);
                return (
                    <SectionContainer
                        title={`${site.name} (${siteStockVariations.length} variations)`}
                        isInitiallyOpen={false}
                        key={site._id}
                    >
                        <>
                            <VariationsDetails>
                                <VariationsSummary>Toutes les variations du jour</VariationsSummary>
                                {displayVariationsByProduct(siteStockVariations)}
                            </VariationsDetails>
                            {Object.values(StockVariationType).map((stockVariationType: StockVariationType) => {
                                const typeVariations = siteStockVariations.filter(
                                    ({ type }) => type === stockVariationType,
                                );

                                if (!typeVariations.length) {
                                    return null;
                                }
                                return (
                                    <VariationsDetails key={stockVariationType}>
                                        <VariationsSummary>
                                            {siteVariationsLabels[stockVariationType]}
                                        </VariationsSummary>
                                        {displayVariationsByProduct(typeVariations)}
                                    </VariationsDetails>
                                );
                            })}
                        </>
                    </SectionContainer>
                );
            })}
        </Content>
    );
};

const Content = styled.div`
    padding: 15px;
    flex: 1;
    overflow: auto;

    & > :not(:first-child) {
        margin-top: 15px;
    }
`;

const VariationsDetails = styled.details`
    margin-top: 5px;
`;

const VariationsSummary = styled.summary`
    cursor: pointer;
    font-weight: bold;
`;

const ProductVariationContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    align-items: center;
`;
const ProductName = styled.div`
    font-weight: bold;
    width: 600px;
    overflow: hidden;
`;

import React from 'react';
import { FiCameraOff } from 'react-icons/fi';
import styled from 'styled-components';
import { colors } from 'constants/colors';

export function TotemImage({ src, alt, size }: { src: string | null; alt: string; size: number }) {
    return (
        <ImageContainer size={size}>
            {src ? <Image src={src} alt={alt} /> : <FiCameraOff color={colors.pantinGrey} />}
        </ImageContainer>
    );
}

const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: ${({ size }: { size: number }) => size}px;
    width: ${({ size }: { size: number }) => size}px;
    height: ${({ size }: { size: number }) => size}px;
    background-color: ${({ theme }) => theme.pictureBackgroundColor};
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
    border-radius: ${({ theme }) => theme.borderRadius};
    overflow: hidden;
`;

const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`;

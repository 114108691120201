import React, { useState } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';
import { useNavigate, Link } from 'react-router-dom';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { FaTrash } from 'react-icons/fa';

import { GET_PRODUCTS_FROM_CSV_QUERY } from 'data/queries/price';
import {
    GET_PRODUCTS_FROM_CSV,
    GET_PRODUCTS_FROM_CSVVariables,
} from 'data/queries/__generated__/GET_PRODUCTS_FROM_CSV';
import { CREATE_PRICE_RANGE_MUTATION } from 'data/mutations/price';
import { CREATE_PRICE_RANGE, CREATE_PRICE_RANGEVariables } from 'data/mutations/__generated__/CREATE_PRICE_RANGE';

import { colors } from 'constants/colors';

import { ProductsPriceTable } from './ProductsPriceTable';
import { PriceRangeProductSelector } from './PriceRangeProductSelector';

import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { Header, HeaderTitle } from 'components/Header';
import { Loader, LoaderModeType } from 'components/Loader';
import { DetailFormValue } from 'components/DetailsView/DetailFormValue';
import { TotemCsvInputButton, CsvDataRow } from 'components/TotemCsvInputButton';

import { checkProductRangeCsvImport } from '../../helpers/CsvCheckers/checkProductRangeCsvImport';

export type ProductEntry = {
    productId: string;
    price: number;
    crossedPrice: number | null;
    name: string;
};

export type SelectedProduct = {
    productId: string;
    name: string;
};

export type ProductPriceRangeType = {
    rangeName: string;
    products: ProductEntry[];
};

export const ProductPriceRangeCreate = () => {
    const navigate = useNavigate();
    const [selectedProducts, setSelectedProducts] = useState<SelectedProduct[]>([]);
    const [csvData, setCsvData] = useState<Array<CsvDataRow>>([]);
    const [getProductsFromCsv, { loading: loadingCsv }] = useLazyQuery<
        GET_PRODUCTS_FROM_CSV,
        GET_PRODUCTS_FROM_CSVVariables
    >(GET_PRODUCTS_FROM_CSV_QUERY, {
        fetchPolicy: 'cache-and-network',
        onCompleted({ productsFromCsv }) {
            const { productsForRange, idsNotFound, idsNotActive } = productsFromCsv;
            const productsToAddToSelection = [];
            const existingProductValues = getValues('products');

            for (const product of productsForRange) {
                const { _id, name, price } = product;
                if (price) {
                    if (selectedProducts.some(({ productId }) => productId === _id)) {
                        const index = existingProductValues.map(({ productId }) => productId).indexOf(_id);
                        setValue(`products.${index}.price` as 'products.0.price', price);
                    } else {
                        productsToAddToSelection.push({ productId: _id, name });
                        append({ productId: _id, price } as ProductEntry);
                    }
                }
            }
            setSelectedProducts([...selectedProducts, ...productsToAddToSelection]);

            if (idsNotFound.length) {
                let message = 'Ids des produits pas trouvés: ';
                message += idsNotFound.join(', ');
                toast.warn(message, { closeOnClick: false, autoClose: false });
            }

            if (idsNotActive.length) {
                let message = 'Ids des produits en état non-actif: ';
                message += idsNotActive.join(', ');
                toast.warn(message, { closeOnClick: false, autoClose: false });
            }
        },
    });

    const [createPriceRange, { loading: createPriceRangeLoading }] = useMutation<
        CREATE_PRICE_RANGE,
        CREATE_PRICE_RANGEVariables
    >(CREATE_PRICE_RANGE_MUTATION);

    const methods = useForm<ProductPriceRangeType>({ shouldUnregister: false });
    const {
        getValues,
        handleSubmit,
        formState: { isDirty },
        control,
        register,
        reset,
        setValue,
    } = methods;
    const { fields, append, remove } = useFieldArray({ control, name: 'products' });

    if (createPriceRangeLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    const handleCSVImport = async () => {
        const { success, error } = checkProductRangeCsvImport({ csvData });
        if (!success) {
            setCsvData([]);
            toast.error(error, { autoClose: false });
            return;
        }

        const [header, ...data] = csvData;
        const productIdIndex = header.findIndex((elem) => elem === 'productId');
        const priceIndex = header.findIndex((elem) => elem === 'price');

        const csvDataFormatted = data.map((rowArray) => {
            const price = Number(rowArray[priceIndex].replace(',', '.'));
            return { productId: rowArray[productIdIndex], price };
        });

        await getProductsFromCsv({
            variables: {
                csvData: csvDataFormatted,
            },
        });
    };

    function removeProduct(index: number): void {
        remove(index);
        setSelectedProducts(selectedProducts.filter((_, selectedProductIndex) => selectedProductIndex !== index));
    }

    const onSubmit = handleSubmit(async (formData) => {
        const { products = [], rangeName } = formData;

        if (!products?.length) {
            toast.error('Une gamme doit contenir au moins 1 produit');
            return null;
        }

        reset({ products: formData?.products });

        const { data } = await createPriceRange({
            variables: {
                rangeName,
                products,
            },
        });

        if (data) {
            const { success, error, rangeId } = data.createPriceRange;
            if (success && rangeId) {
                toast.success(`La gamme "${rangeName}" a bien été créée !`);
                navigate('/productPriceRange/' + rangeId);
            }
            toast.error(error);
        } else {
            toast.error('Une erreur est survenue lors de la création de la gamme');
        }
    });

    return (
        <Container>
            <Header>
                <HeaderTitle>Créer une gamme de prix</HeaderTitle>
                <ButtonsContainer>
                    {csvData.length ? (
                        <ButtonsContainer>
                            <TotemPrimaryButton onClick={() => setCsvData([])}>
                                <FaTrash data-test="trash-icon" size={12} color={colors.pureWhite} />
                            </TotemPrimaryButton>
                            <TotemPrimaryButton minWidth="105px" onClick={handleCSVImport}>
                                {loadingCsv ? <Loader size="18px" mode={LoaderModeType.Spin} /> : 'Import CSV'}
                            </TotemPrimaryButton>
                        </ButtonsContainer>
                    ) : (
                        <TotemCsvInputButton onCsvDataUpload={setCsvData} />
                    )}

                    <Link to="/productPriceRanges">
                        <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                    </Link>
                </ButtonsContainer>
            </Header>
            <Content>
                <FormProvider {...methods}>
                    <Form onSubmit={onSubmit}>
                        <Fields>
                            <DetailFormValue
                                label="Nom de la gamme"
                                placeholder="Gamme A, prix Pantin, prix Murex ..."
                                width="100%"
                                {...register('rangeName' as const, { required: true })}
                            />
                            <PriceRangeProductSelector
                                append={append}
                                selectedProducts={selectedProducts}
                                setSelectedProducts={setSelectedProducts}
                            />
                        </Fields>
                        {fields.length ? (
                            <ProductsPriceTable
                                selectedProducts={selectedProducts}
                                fields={fields}
                                removeProduct={removeProduct}
                            />
                        ) : null}
                        <RangeSubmitButton type="submit" disabled={!isDirty}>
                            Créer la gamme
                        </RangeSubmitButton>
                    </Form>
                </FormProvider>
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background: ${({ theme }) => theme.backgroundColor};
`;

const ButtonsContainer = styled.div`
    display: flex;
    width: max-content;
    align-items: center;

    ${TotemPrimaryButton} {
        margin-left: 5px;
    }
`;

const Content = styled.div`
    display: flex;
    position: relative;
    flex: 1;
    padding: 15px;
    overflow-y: hidden;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    align-items: center;
`;

const Fields = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

const RangeSubmitButton = styled(TotemPrimaryButton)`
    margin-top: 10px;
`;

import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { WarehouseDeliveryFragmentFragment } from 'data/__generated__';
import styled from 'styled-components';
import { Header, HeaderTitle } from 'components/Header';
import { PageTitle } from 'components/PageTitle';
import { Link } from 'react-router-dom';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { PAGES } from 'constants/pages';
import { GeneralInfoSection } from './GeneralInfoSection';
import { StatesSection } from './StatesSection';
import { ProductsSection } from './ProductsSection';

export const WarehouseDeliveryFormContent = ({
    warehouseDelivery,
}: {
    warehouseDelivery: WarehouseDeliveryFragmentFragment;
}) => {
    const methods = useForm<WarehouseDeliveryFragmentFragment>({});

    // if we want to allow updates later on
    const {
        handleSubmit,
        // formState: { isDirty, isSubmitSuccessful },
        // reset,
    } = methods;

    const onSubmit = handleSubmit(async (fields) => console.log(fields));

    return (
        <FormProvider {...methods}>
            <Form onSubmit={onSubmit}>
                <Header>
                    <HeaderTitle>
                        <PageTitle page={PAGES.warehouseDeliveryDetails} />
                    </HeaderTitle>
                    <CTAsContainer>
                        <Link to={PAGES.warehouseDeliveries.url}>
                            <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                        </Link>
                    </CTAsContainer>
                </Header>
                <Content>
                    <ScrollableContent>
                        <GeneralInfoSection warehouseDelivery={warehouseDelivery} />
                        <StatesSection warehouseDelivery={warehouseDelivery} />
                        <ProductsSection warehouseDelivery={warehouseDelivery} />
                    </ScrollableContent>
                </Content>
            </Form>
        </FormProvider>
    );
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
`;

const CTAsContainer = styled.div`
    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const Content = styled.div`
    flex: 1;
    overflow: hidden;
`;

const ScrollableContent = styled.div`
    padding: 15px;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

import React from 'react';
import { useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import type { FieldArrayMethodProps } from 'react-hook-form';

import { GET_NON_ARCHIVED_PRODUCTS_QUERY } from 'data/queries/product';
import { GET_NON_ARCHIVED_PRODUCTS } from 'data/queries/__generated__/GET_NON_ARCHIVED_PRODUCTS';

import { ProductEntry, SelectedProduct } from './ProductPriceRangeCreate';

import { SelectedOption, TotemSelect } from 'components/TotemSelect';
import { Loader, LoaderModeType } from 'components/Loader';
import { getProductFullName } from 'pages/Products/utils';

export const PriceRangeProductSelector = ({
    append,
    selectedProducts,
    setSelectedProducts,
    shouldDisplaySelect = true,
}: {
    append: (value: ProductEntry | ProductEntry[], options?: FieldArrayMethodProps | undefined) => void;
    selectedProducts: SelectedProduct[];
    setSelectedProducts: React.Dispatch<React.SetStateAction<SelectedProduct[]>>;
    shouldDisplaySelect?: boolean;
}) => {
    const {
        loading: productsLoading,
        data: productsData,
        error: productsError,
    } = useQuery<GET_NON_ARCHIVED_PRODUCTS>(GET_NON_ARCHIVED_PRODUCTS_QUERY);

    const onSelect = (option: SelectedOption<string>) => {
        if (option && !selectedProducts.some(({ productId }) => productId === option.value)) {
            setSelectedProducts([...selectedProducts, { productId: option.value, name: option.label }]);
            append({ productId: option.value } as ProductEntry);
        }
    };

    if (productsLoading) {
        return <Loader mode={LoaderModeType.Spin} />;
    }

    if (productsError || !productsData) {
        toast.error('Une erreur est survenue lors de la récupération des produits non-archivés ');
        return null;
    }

    const { nonArchivedProducts } = productsData;

    const selectedProductIds = selectedProducts.map(({ productId }) => productId);

    const productsOptions =
        nonArchivedProducts
            .filter(({ _id }) => !selectedProductIds.includes(_id))
            .map(({ _id, name, brand, volume }) => ({
                value: _id,
                label: getProductFullName({ name, brand, volume }),
            })) || [];

    return shouldDisplaySelect ? (
        <TotemSelect
            dataTest="select-product"
            label="Ajouter un produit"
            placeholder="Sélectionner un produit (recherche par nom ou par _id)"
            value={null}
            options={productsOptions}
            onChange={(option) => onSelect(option)}
        />
    ) : (
        <></>
    );
};

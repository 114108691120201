import React from 'react';

import styled from 'styled-components';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import type { FileError } from 'react-dropzone';
import { FaPlus, FaTrash } from 'react-icons/fa';

import { getExtendedFormattedDatetime } from 'helpers/dateTimes';

import { GET_ACTIVE_CATEGORIES_activeCategories } from 'data/queries/__generated__/GET_ACTIVE_CATEGORIES';
import { BarcodeType, GetDetailedProductQuery } from 'data/__generated__';
import { colors } from 'constants/colors';

import { DefaultProductType } from 'pages/Products/constants/defaultProduct';
import { InfoTags } from 'pages/Products/components/InfoTags';

import { SectionColumn, SectionContainer, ColumnsSectionContainer } from 'components/DetailsView/Section';
import { DetailFormValue } from 'components/DetailsView/DetailFormValue';
import { DetailDisplayValue } from 'components/DetailsView/DetailDisplayValue';
import { DetailFormSelect, DetailFormTextArea, DetailValue, DetailFormImage } from 'components/DetailsView';
import { Rating } from 'components/Rating';
import { FileWithPreview } from 'components/FileDragAndDrop';
import { StatusTag } from '../components/StatusTag';
import { CopyValue } from 'components/CopyValue';
import { TotemLabel } from 'components/TotemLabel';

import { getProductFullName } from '../utils';
import { SubcategorySelector } from './components/SubcategorySelector';
import { ProductForm } from './types';
import { TotemSelect } from 'components/TotemSelect';

export const GeneralInfoSection = ({
    product,
    categories,
    productForm,
    setProductForm,
}: {
    product: NonNullable<GetDetailedProductQuery['detailedProduct']> | DefaultProductType;
    categories: Array<GET_ACTIVE_CATEGORIES_activeCategories>;
    productForm: ProductForm;
    setProductForm: (productForm: ProductForm) => void;
}) => {
    const {
        externalProductId,
        access,
        brand,
        description,
        comment,
        imageUrl,
        isBeingTested,
        isPaused,
        isToBeArchived,
        name,
        nbRatings,
        newUntil,
        packagingImageUrl,
        rating,
        state,
        volume,
        searchKeywords,
    } = product;

    const { register, control } = useFormContext();
    const { fields: barcodeFields, append, remove } = useFieldArray({ name: 'barcodes' });

    const updatedTitleValues: string[] = useWatch({
        control,
        name: ['name', 'brand', 'volume'],
        defaultValue: [name, brand, volume],
    });

    const categoryOptions = categories.map(({ _id, title }: { _id: string; title: string }) => ({
        label: title,
        value: _id,
    }));

    const selectedCategoryOption = categoryOptions.find((option) => option.value === productForm.categoryId);

    async function fileValidationFunction(file: FileWithPreview) {
        return new Promise<FileError[]>((resolve, reject) => {
            const image = new Image();
            image.onload = function () {
                const widthErrors =
                    image.width >= 1200 && image.height >= 1200
                        ? []
                        : [
                              {
                                  message: `L'image doit avoir une dimension de au moins 1200x1200px, votre image fait ${image.width}x${image.height}px`,
                                  code: 'file-invalid-dimension',
                              },
                          ];
                const ratioErrors =
                    (image.width / image.height).toFixed(2) === (1).toFixed(2)
                        ? []
                        : [
                              {
                                  message: `L'image doit avoir un ratio de 1/1, votre image a un ratio de ${(
                                      image.width / image.height
                                  ).toFixed(2)}`,
                                  code: 'file-invalid-dimension',
                              },
                          ];
                const fileErrors = [...widthErrors, ...ratioErrors];
                resolve(fileErrors);
            };
            image.onerror = reject;
            image.src = file.preview;
        });
    }

    const barcodeOptions = [
        { value: BarcodeType.Product, label: 'Produit' },
        { value: BarcodeType.Packaging, label: 'Colis' },
    ];

    return (
        <SectionContainer title="Informations générales" isInitiallyOpen>
            <ColumnsSectionContainer numberOfColumns={3}>
                <SectionColumn>
                    {'_id' in product ? (
                        <Row>
                            <CopyValue value={product._id} />
                        </Row>
                    ) : null}
                    <DetailFormValue
                        label="Référence"
                        defaultValue={externalProductId}
                        {...register('externalProductId', { required: false })}
                        width="200px"
                        data-test="product-externalProductId"
                    />
                    <Row>
                        <DetailFormImage
                            name="image"
                            label="Image"
                            sublabel="Image du produit (webapp + mobile)"
                            defaultValue={imageUrl}
                            fileValidationFunction={fileValidationFunction}
                            size={175}
                        />
                        <DetailFormImage
                            name="packagingImage"
                            label="Image de l'emballage"
                            sublabel="Miniature de l'image du produit dans le frigo (mobile)"
                            defaultValue={packagingImageUrl}
                            size={175}
                        />
                    </Row>
                    <DetailValue label="Note" value={<Rating rating={rating} numberOfRatings={nbRatings} />} />
                    <TotemLabel>Codes-barres</TotemLabel>
                    <List>
                        {barcodeFields.map((field, index) => {
                            return (
                                <Entry key={field.id} hasBorderTop={index > 0}>
                                    <DetailFormValue
                                        placeholder="Code barre"
                                        {...register(`barcodes.${index}.barcode` as const, { required: true })}
                                    />
                                    <DetailFormSelect
                                        placeholder="Type de code barre"
                                        defaultValue={`barcodes.${index}.type` as BarcodeType}
                                        name={`barcodes.${index}.type`}
                                        options={barcodeOptions}
                                        required={true}
                                        width="30%"
                                    />
                                    <IconContainer
                                        onClick={() => {
                                            remove(index);
                                        }}
                                    >
                                        <TrashIcon />
                                    </IconContainer>
                                </Entry>
                            );
                        })}
                        <Entry hasBorderTop={barcodeFields.length > 0}>
                            <IconContainer onClick={() => append({ barcode: '', type: '' })}>
                                <PlusIcon color={colors.pureWhite} />
                            </IconContainer>
                        </Entry>
                    </List>
                </SectionColumn>
                <SectionColumn>
                    <TotemSelect<string>
                        label="Catégorie"
                        placeholder="Sélectionner une catégorie"
                        value={selectedCategoryOption}
                        name="categoryId"
                        options={categoryOptions}
                        onChange={(option) => {
                            if (option) {
                                setProductForm({
                                    ...productForm,
                                    categoryId: option.value,
                                    ...(option.value !== productForm.categoryId && { subcategoryId: null }),
                                });
                            }
                        }}
                        dataTest="product-category"
                    />
                    {productForm.categoryId ? (
                        <SubcategorySelector
                            categoryId={productForm.categoryId}
                            subcategoryId={productForm.subcategoryId}
                            onChange={(subcategoryId: string | null) =>
                                setProductForm({ ...productForm, subcategoryId })
                            }
                        />
                    ) : null}
                    <DetailFormValue
                        label="Nom"
                        defaultValue={name}
                        {...register('name', { required: true })}
                        width="100%"
                        data-test="product-name"
                    />
                    <DetailFormValue
                        label="Marque"
                        defaultValue={brand}
                        {...register('brand', { required: true })}
                        data-test="product-brand"
                    />
                    <DetailFormValue
                        label="Contenance"
                        sublabel="(poids avec emballage, en g ou mL, affiché aux clients)"
                        defaultValue={volume}
                        {...register('volume', { required: true })}
                        data-test="product-volume"
                    />
                    <DetailDisplayValue
                        label="Nom complet"
                        sublabel="(calculé)"
                        value={getProductFullName({
                            name: updatedTitleValues[0] || name,
                            brand: updatedTitleValues[1] || brand,
                            volume: updatedTitleValues[2] || volume,
                        })}
                    />
                </SectionColumn>
                <SectionColumn>
                    <DetailFormTextArea
                        label="Description"
                        defaultValue={description}
                        {...register('description', { required: true })}
                        width="100%"
                        height="130px"
                        data-test="product-description"
                    />
                    {'isOutOfStock' in product ? (
                        <ModificationInfo>
                            <StatusTag
                                state={state}
                                showHeading
                                sublabel="Modifiable dans la section 'Gestion des statuts'"
                            />
                            <InfoTags
                                access={access}
                                isOutOfStock={product.isOutOfStock}
                                isToBeArchived={isToBeArchived}
                                isBeingTested={isBeingTested}
                                newUntil={newUntil ?? null}
                                isPaused={isPaused}
                                isInShortage={product.supplierShortageInfo.inShortage}
                            />
                        </ModificationInfo>
                    ) : null}
                    {'createdAt' in product ? (
                        <Row>
                            <DetailValue
                                label="Dernière mise à jour"
                                value={getExtendedFormattedDatetime(product.updatedAt)}
                            />
                            <DetailValue
                                label="Date de création"
                                value={getExtendedFormattedDatetime(product.createdAt)}
                            />
                        </Row>
                    ) : null}
                    <DetailFormTextArea
                        label="Commentaires"
                        defaultValue={comment}
                        sublabel="Utilisé uniquement en interne pour prendre des notes/ faire des remarques. Pour noter le(s) client(s) pour lequel(s) ce produit est commandé, veuillez utiliser 'Commandé par x (nom d'entreprise)'"
                        {...register('comment')}
                        width="100%"
                        height="130px"
                    />
                    <DetailFormTextArea
                        label="Mots clés de recherche"
                        defaultValue={searchKeywords?.join('\n')}
                        sublabel="Utilisés pour la recherche de produits dans l'application. Séparés par un retour à la ligne."
                        {...register('searchKeywords')}
                        width="100%"
                        height="130px"
                    />
                </SectionColumn>
            </ColumnsSectionContainer>
        </SectionContainer>
    );
};

const Row = styled.div`
    display: flex;

    & > :not(:first-child) {
        margin-left: 15px;
    }
`;

const ModificationInfo = styled.div`
    display: flex;
    flex-direction: column;

    & > :not(:first-child) {
        margin-top: 15px;
    }
`;

const List = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
    min-width: 200px;
`;

const Entry = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-top: ${({ hasBorderTop }: { hasBorderTop: boolean }) => (hasBorderTop ? 1 : 0)}px solid
        ${({ theme }) => theme.lightBorderColor};

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const IconContainer = styled.div<{ disabled?: boolean }>`
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background-color: ${({ disabled, theme }) => (disabled ? theme.disabledColor : theme.ctaPrimaryColor)};
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
    cursor: pointer;
`;

const TrashIcon = styled(FaTrash)`
    color: ${({ theme }) => theme.menuTextColor};
`;

const PlusIcon = styled(FaPlus)`
    color: ${({ theme }) => theme.menuTextColor};
`;

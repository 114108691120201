import React from 'react';

import { CommonStatusTag } from 'components/CommonStatusTag';

import { colors } from 'constants/colors';

import { SiteType } from 'data/__generated__';
import { SITE_TYPES_LABELS } from 'pages/Sites/constants/types';

const getColorsFromType = (type: SiteType) => {
    const { pureWhite, black, emeraldBlue, blandBlue, orange } = colors;

    switch (type) {
        case SiteType.Microstore:
            return { backgroundColor: emeraldBlue, labelColor: pureWhite };
        case SiteType.Warehouse:
            return { backgroundColor: blandBlue, labelColor: pureWhite };
        case SiteType.BrokenProductsWarehouse:
            return { backgroundColor: orange, labelColor: pureWhite };
        case SiteType.ExpiredProductsWarehouse:
            return { backgroundColor: orange, labelColor: black };
    }
};

export const TypeTag = ({ type }: { type: SiteType }) => {
    const { backgroundColor, labelColor } = getColorsFromType(type);
    return (
        <CommonStatusTag backgroundColor={backgroundColor} labelColor={labelColor} label={SITE_TYPES_LABELS[type]} />
    );
};

import React from 'react';

export type Theme = {
    backgroundColor: string;
    borderRadius: string;
    boxShadow: string;
    cardBackgroundColor: string;
    ctaPrimaryBorderColor: string;
    ctaPrimaryColor: string;
    ctaPrimaryHoveredColor: string;
    ctaPrimaryTextColor: string;
    ctaSecondaryBorderColor: string;
    ctaSecondaryColor: string;
    ctaSecondaryHoveredColor: string;
    ctaSecondaryTextColor: string;
    darkBackgroundColor: string;
    darkBorderColor: string;
    disabledColor: string;
    disabledTextColor: string;
    errorColor: string;
    infoTextColor: string;
    lightBorderColor: string;
    menuBackgroundColor: string;
    menuTextColor: string;
    overlayColor: string;
    overlayTextColor: string;
    pictureBackgroundColor: string;
    popupOverlayColor: string;
    successColor: string;
    textColor: string;
    warningColor: string;
};

type ThemeContextType = {
    setTheme: (Theme: Theme) => void;
};

export const UpdateThemeContext = React.createContext<ThemeContextType>({ setTheme: () => null });

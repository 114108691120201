import { apolloClient } from 'App';
import { ConsumptionVariationFragment } from 'data/fragments/__generated__/ConsumptionVariationFragment';
import { GET_SITE_CONSUMPTION_VARIATIONS_QUERY } from 'data/queries/consumptionVariation';
import {
    GET_SITE_CONSUMPTION_VARIATIONS,
    GET_SITE_CONSUMPTION_VARIATIONS_siteConsumptionVariations,
} from 'data/queries/__generated__/GET_SITE_CONSUMPTION_VARIATIONS';

export const consumptionVariationsUpdatedOrCreatedHandler = (
    consumptionVariationsUpdatedOrCreated: ConsumptionVariationFragment[],
) => {
    let currentConsumptionVariations = [] as GET_SITE_CONSUMPTION_VARIATIONS_siteConsumptionVariations[];

    const result = apolloClient.readQuery<GET_SITE_CONSUMPTION_VARIATIONS>({
        query: GET_SITE_CONSUMPTION_VARIATIONS_QUERY,
        variables: { siteId: consumptionVariationsUpdatedOrCreated[0]?.siteId },
    });
    if (!result) {
        return;
    }
    currentConsumptionVariations = result.siteConsumptionVariations;

    const updatedOrCreatedConsumptionVariationIds = consumptionVariationsUpdatedOrCreated.map(({ _id }) => _id);
    const nonUpdatedOrCreatedConsumptionVariations = currentConsumptionVariations.filter(
        ({ _id }) => !updatedOrCreatedConsumptionVariationIds.includes(_id),
    );
    const newConsumptionVariations = [
        ...consumptionVariationsUpdatedOrCreated,
        ...nonUpdatedOrCreatedConsumptionVariations,
    ];

    apolloClient.writeQuery({
        query: GET_SITE_CONSUMPTION_VARIATIONS_QUERY,
        data: { siteConsumptionVariations: newConsumptionVariations },
        variables: { siteId: consumptionVariationsUpdatedOrCreated[0]?.siteId },
    });
};

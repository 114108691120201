import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import { PageType } from 'constants/pages';

export const PageTitle = ({ page }: { page: PageType }) => {
    return (
        <>
            <Helmet title={`${page.label} · TOTADMIN`} />
            <PageTitleLabel>{page.label}</PageTitleLabel>
        </>
    );
};

const PageTitleLabel = styled.h2`
    font-size: 16px;
    margin: 10px 0;
`;

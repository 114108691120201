import React from 'react';

import { toast } from 'react-toastify';

export async function handleUpdateActionEndToast({
    displaySuccess,
    successText,
    errors,
    warnings,
}: {
    displaySuccess: boolean;
    successText: string;
    errors: ReadonlyArray<string>;
    warnings?: ReadonlyArray<string> | null;
}) {
    if (displaySuccess) {
        toast.success(successText);
    }

    if (errors.length) {
        errors.forEach((error, index) => {
            toast.error(<span key={index}>Erreur : {error}</span>, { autoClose: false });
        });
    }

    if (warnings?.length) {
        warnings.forEach((warning, index) => {
            toast.warning(<span key={index}>Warning : {warning}</span>, { autoClose: false });
        });
    }

    if (errors.length + (warnings?.length || 0) > 1) {
        toast.info('Cliquez pour fermer toutes les notifications', {
            autoClose: false,
            onClick: () => toast.dismiss(),
        });
    }
}

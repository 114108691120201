import gql from 'graphql-tag';

export const LOGGED_IN_USER_FRAGMENT = gql`
    fragment LoggedInUserFragment on User {
        _id
        username
        profile {
            firstname
            imageUrl
            lastname
        }
    }
`;

export const USER_NOTIFICATION_PREFERENCES = gql`
    fragment NotificationPreferences on NotificationPreferences {
        customRitualActivity
        dailyDelivery
        feedbacks
        newFeatures
        recommendations
        reminders
        ritualInvite
        siteActivity
        specialOffers
        startingRitual
        weeklySummary
    }
`;

import { apolloClient } from '../../../App';
import { GET_ADVERTISEMENT_SCREEN_CONFIG_QUERY } from 'data/queries/advertisementScreenConfig';
import { AdvertisementScreenConfigFragmentFragment, GetAdvertisementScreenConfigQueryResult } from 'data/__generated__';

export const advertisementScreenConfigUpdatedOrCreatedHandler = (
    advertisementScreenConfigUpdatedOrCreated: AdvertisementScreenConfigFragmentFragment,
) => {
    const result = apolloClient.readQuery<GetAdvertisementScreenConfigQueryResult>({
        query: GET_ADVERTISEMENT_SCREEN_CONFIG_QUERY,
    });
    if (!result) {
        return;
    }

    apolloClient.writeQuery({
        query: GET_ADVERTISEMENT_SCREEN_CONFIG_QUERY,
        data: { getAdvertisementScreenConfig: advertisementScreenConfigUpdatedOrCreated },
    });
};

import gql from 'graphql-tag';
import { MICROSTORE_COLUMN_GROUP_WITH_COLUMNS_FRAGMENT } from 'data/fragments/microstoreColumnGroup';
import { SITE_FRAGMENT } from '../fragments/site';

export const CREATE_MICROSTORE_COLUMN_GROUP_MUTATION = gql`
    mutation CREATE_MICROSTORE_COLUMN_GROUP($createMicrostoreColumnGroupInput: CreateMicrostoreColumnGroupInput!) {
        createMicrostoreColumnGroupMutation(createMicrostoreColumnGroupInput: $createMicrostoreColumnGroupInput) {
            errors
            newSite {
                ...SiteFragment
                microstoreColumnGroups {
                    ...MicrostoreColumnGroupWithColumnsFragment
                }
            }
        }
    }
    ${SITE_FRAGMENT}
    ${MICROSTORE_COLUMN_GROUP_WITH_COLUMNS_FRAGMENT}
`;

export const UPDATE_MICROSTORE_COLUMN_GROUP_NAME_MUTATION = gql`
    mutation UPDATE_MICROSTORE_COLUMN_GROUP_NAME($columnGroupId: ID!, $name: String!) {
        updateMicrostoreColumnGroupNameMutation(columnGroupId: $columnGroupId, name: $name) {
            errors
            newSite {
                ...SiteFragment
                microstoreColumnGroups {
                    ...MicrostoreColumnGroupWithColumnsFragment
                }
            }
        }
    }
    ${SITE_FRAGMENT}
    ${MICROSTORE_COLUMN_GROUP_WITH_COLUMNS_FRAGMENT}
`;

export const DELETE_MICROSTORE_COLUMN_GROUP_MUTATION = gql`
    mutation DELETE_MICROSTORE_COLUMN_GROUP($microstoreColumnGroupId: ID!) {
        deleteMicrostoreColumnGroupMutation(microstoreColumnGroupId: $microstoreColumnGroupId) {
            errors
            newSite {
                ...SiteFragment
                microstoreColumnGroups {
                    ...MicrostoreColumnGroupWithColumnsFragment
                }
            }
        }
    }
    ${SITE_FRAGMENT}
    ${MICROSTORE_COLUMN_GROUP_WITH_COLUMNS_FRAGMENT}
`;

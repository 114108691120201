import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useForm, FormProvider } from 'react-hook-form';
import { FaSave } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';

import { PAGES } from 'constants/pages';

import { Header, HeaderTitle } from 'components/Header';
import { Loader, LoaderModeType } from 'components/Loader';
import { PageTitle } from 'components/PageTitle';

import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { GeneralInfoSection } from './GeneralInfoSection';
import { ContactsSection } from './ContactsSection';
import { PaymentInfoSection } from './PaymentInfoSection';
import { OrderingTerms } from './OrderingTerms';
import { OrderingMode } from './OrderingMode';
import { OrderingTimes } from './OrderingTimes';

import { OrderMode, PaymentTimeType, SupplierFormInput, SupplierPaymentType } from 'data/__generated__';
import { CREATE_SUPPLIER_MUTATION } from 'data/mutations/supplier';
import { CREATE_SUPPLIER, CREATE_SUPPLIERVariables } from 'data/mutations/__generated__/CREATE_SUPPLIER';

import { formatFormArray } from 'pages/Products/utils';

const DEFAULT_SUPPLIER_DELIVERY_CONDITION = {
    orderBefore: '10:00',
    daysToDeliver: 7,
    deliveryHours: {
        start: '5:00',
        end: '19:00',
    },
    canBeOrdered: true,
};

export const SupplierCreate = () => {
    const navigate = useNavigate();
    const initialSupplier: SupplierFormInput = {
        name: '',
        paymentType: SupplierPaymentType.SepaDirectDebit,
        paymentTimeType: PaymentTimeType.NumberOfDay,
        paymentTimeDays: 10,
        orderMode: OrderMode.Email,
        orderEmails: [],
        closingDatesRanges: [],
        contacts: [],
        orderingProductTerms: [],
        orderingTerms: [
            {
                deliveryPrice: null,
                minRequirements: [
                    [
                        { unit: null, value: null },
                        { unit: null, value: null },
                    ],
                    [
                        { unit: null, value: null },
                        { unit: null, value: null },
                    ],
                ],
            },
            {
                deliveryPrice: null,
                minRequirements: [
                    [
                        { unit: null, value: null },
                        { unit: null, value: null },
                    ],
                    [
                        { unit: null, value: null },
                        { unit: null, value: null },
                    ],
                ],
            },
        ],
        orderingTimes: {
            monday: DEFAULT_SUPPLIER_DELIVERY_CONDITION,
            tuesday: DEFAULT_SUPPLIER_DELIVERY_CONDITION,
            wednesday: DEFAULT_SUPPLIER_DELIVERY_CONDITION,
            thursday: DEFAULT_SUPPLIER_DELIVERY_CONDITION,
            friday: DEFAULT_SUPPLIER_DELIVERY_CONDITION,
            saturday: {
                ...DEFAULT_SUPPLIER_DELIVERY_CONDITION,
                canBeOrdered: false,
                daysToDeliver: 9,
            },
            sunday: {
                ...DEFAULT_SUPPLIER_DELIVERY_CONDITION,
                canBeOrdered: false,
                daysToDeliver: 8,
            },
        },
        shouldCheckReceptionDayIsNotClosed: true,
        shouldOrderAutomatically: false,
        frequency: 1,
    };

    const [supplierFormState, setSupplierFormState] = useState<SupplierFormInput>(initialSupplier);

    const [createSupplier, { loading: creationLoading }] = useMutation<CREATE_SUPPLIER, CREATE_SUPPLIERVariables>(
        CREATE_SUPPLIER_MUTATION,
    );

    type FormData = Omit<SupplierFormInput, 'orderEmails'> & {
        orderEmails: { value: string }[];
    };

    const methods = useForm<FormData>({
        defaultValues: {
            ...initialSupplier,
            orderEmails: initialSupplier.orderEmails.map((barcode) => {
                return { value: barcode };
            }),
        },
    });
    const {
        handleSubmit,
        formState: { isDirty },
    } = methods;

    const onSubmit = handleSubmit(async (fields) => {
        const formattedOrderEmails = formatFormArray(fields.orderEmails);

        const { data } = await createSupplier({
            variables: {
                fields: {
                    ...fields,
                    orderEmails: formattedOrderEmails,
                    orderingProductTerms: supplierFormState.orderingProductTerms,
                },
            },
        });
        if (data) {
            const {
                createSupplier: { errors, formErrors, createdSupplier },
            } = data;
            if (createdSupplier) {
                toast.success(`Le fournisseur "${createdSupplier.name}" a bien été crée !`);
                navigate(`/supplier/${createdSupplier._id}`);
            } else if (formErrors) {
                formErrors.forEach(({ sectionName, sectionErrors }, index) => {
                    toast.error(
                        <span key={index}>
                            Erreur dans la section "{sectionName}" :
                            {sectionErrors.map(({ fieldName, fieldError }, index) => (
                                <span key={index}>
                                    <br />- {fieldName} : "{fieldError}"
                                </span>
                            ))}
                        </span>,
                        { autoClose: false },
                    );
                });

                if (formErrors.length > 1) {
                    toast.info('Cliquez pour fermer toutes les notifications', {
                        autoClose: false,
                        onClick: () => toast.dismiss(),
                    });
                }
            } else if (errors) {
                errors.forEach((error) => {
                    toast.error(error);
                });
                if (errors.length > 1) {
                    toast.info('Cliquez pour fermer toutes les notifications', {
                        autoClose: false,
                        onClick: () => toast.dismiss(),
                    });
                }
            } else {
                throw Error("Une erreur inconnue s'est produite");
            }
        }
    });

    const hasChanged = isDirty || JSON.stringify(initialSupplier) !== JSON.stringify(supplierFormState);

    return (
        <Container>
            <FormProvider {...methods}>
                <Form onSubmit={onSubmit}>
                    <Header>
                        <HeaderTitle>
                            <PageTitle page={PAGES.supplierCreate} />
                        </HeaderTitle>
                        <CTAsContainer>
                            <TotemPrimaryButton
                                data-test="create-button"
                                type="submit"
                                minWidth="78px"
                                disabled={!hasChanged}
                            >
                                {creationLoading ? (
                                    <Loader size="18px" mode={LoaderModeType.Spin} />
                                ) : (
                                    <>
                                        <FaSave size={13} />
                                        <SaveLabel>Créer</SaveLabel>
                                    </>
                                )}
                            </TotemPrimaryButton>
                            <Link to="/suppliers">
                                <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                            </Link>
                        </CTAsContainer>
                    </Header>
                    <Content>
                        <ScrollableContent>
                            <GeneralInfoSection />
                            <ContactsSection />
                            <PaymentInfoSection supplier={initialSupplier} />
                            <OrderingTerms
                                supplierId={null}
                                supplierFormState={supplierFormState}
                                setSupplierFormState={setSupplierFormState}
                            />
                            <OrderingMode supplier={initialSupplier} />
                            <OrderingTimes
                                orderingTimes={initialSupplier.orderingTimes}
                                supplierFormState={supplierFormState}
                                setSupplierFormState={setSupplierFormState}
                            />
                        </ScrollableContent>
                    </Content>
                </Form>
            </FormProvider>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex: 1;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
`;

const CTAsContainer = styled.div`
    display: flex;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const SaveLabel = styled.span`
    margin-left: 5px;
`;

const Content = styled.div`
    flex: 1;
    overflow: hidden;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const ScrollableContent = styled.div`
    padding: 15px;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

import React from 'react';

import { FcHighPriority } from 'react-icons/fc';
import styled from 'styled-components';

import { GET_DELIVERY_delivery_boxes_products } from 'data/queries/__generated__/GET_DELIVERY';

import { Theme } from '../../../contexts/theme';

export const BoxProductTile = ({ boxProduct }: { boxProduct: GET_DELIVERY_delivery_boxes_products }) => {
    const { photoUrl, location, productLabel, quantity, quantityPicked } = boxProduct;

    return (
        <ProductContainer>
            <ProductImageContainer>
                <ProductImage src={photoUrl} alt={productLabel} />
                <QuantityBadge isQuantityPickedCorrect={quantity === quantityPicked}>{`${
                    quantityPicked ?? 0
                }/${quantity}`}</QuantityBadge>
            </ProductImageContainer>
            <ProductDetails>
                <ProductName>{productLabel}</ProductName>
                <ProductLocation>{location}</ProductLocation>
                {quantityPicked !== quantity ? (
                    <WarningBadge>
                        <FcHighPriority />
                        <WarningLabel>Mauvaises quantités</WarningLabel>
                    </WarningBadge>
                ) : null}
            </ProductDetails>
            <QuantityToPick>
                <QuantityLabel>Quantité</QuantityLabel>
                <QuantityValue>{quantity}</QuantityValue>
            </QuantityToPick>
        </ProductContainer>
    );
};

const numberOfColumns = 4;

const ProductContainer = styled.div`
    display: flex;
    border-radius: ${({ theme }) => theme.borderRadius};
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
    overflow: hidden;

    @media screen {
        width: calc(
            (100% / ${numberOfColumns}) - ${(10 * (numberOfColumns - 1)) / numberOfColumns}px
        ); // There are (4 - 1) margin-lefts to spread over 4 columns
    }

    @media print {
        width: 100%;
    }
`;

const ProductImageContainer = styled.div`
    position: relative;
`;

const ProductImage = styled.img`
    width: 100px;
    height: 100px;
    object-fit: contain;

    @media print {
        display: block;
    }

    @media print {
        display: none;
    }
`;

const QuantityBadge = styled.div`
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: ${({ isQuantityPickedCorrect, theme }: { isQuantityPickedCorrect: boolean; theme: Theme }) =>
        isQuantityPickedCorrect ? theme.successColor : theme.warningColor};
    border-radius: ${({ theme }) => theme.borderRadius};
    padding: 2px 4px;
    font-size: 14px;
    color: ${({ theme }) => theme.menuTextColor};
`;

const ProductDetails = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-left: 1px solid ${({ theme }) => theme.lightBorderColor};
    color: ${({ theme }) => theme.textColor};
`;

const ProductName = styled.span`
    font-size: 18px;
`;

const ProductLocation = styled.span``;

const WarningBadge = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    margin-top: 5px;
    border-radius: ${({ theme }) => theme.borderRadius};
    background-color: ${({ theme }) => theme.warningColor};
    color: ${({ theme }) => theme.menuTextColor};

    @media print {
        display: none;
    }
`;

const WarningLabel = styled.span`
    margin-left: 5px;
`;

const QuantityToPick = styled.div`
    flex-direction: column;
    align-items: center;
    padding: 5px;

    @media screen {
        display: none;
    }

    @media print {
        display: flex;
    }
`;

const QuantityLabel = styled.span``;

const QuantityValue = styled.span`
    font-size: 36px;
`;

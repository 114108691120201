import React from 'react';

import styled from 'styled-components';
import { TotemPrimaryButton } from './TotemPrimaryButton';

type Action = {
    icon?: JSX.Element;
    label: string;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void>;
    hidden?: boolean;
    disabled?: boolean;
};

type ActionSection = {
    title?: string;
    actions: ReadonlyArray<Action>;
};

export const ActionButtons = ({ actionSections }: { actionSections: ActionSection[] }) => {
    return (
        <Container>
            {actionSections.map((actionSection, index) => {
                const { title, actions } = actionSection;

                return (
                    <Section key={index}>
                        {title ? <Title>{title}</Title> : null}
                        <ButtonsContainer>
                            {actions
                                .filter(({ hidden }) => !hidden)
                                .map(({ disabled, icon, label, onClick }, index) => (
                                    <ActionButton type="button" disabled={disabled} key={index} onClick={onClick}>
                                        {icon && icon}
                                        <ActionLabel>{label}</ActionLabel>
                                    </ActionButton>
                                ))}
                        </ButtonsContainer>
                    </Section>
                );
            })}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const Section = styled.div`
    display: flex;
    flex-direction: column;

    &:not(:first-child) {
        margin-top: 20px;
    }
`;

const Title = styled.div`
    font-size: 20px;
    font-weight: 800;
    color: ${({ theme }) => theme.ctaPrimaryColor};
`;
const ButtonsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-right: -20px;
    margin-top: -5px;
`;

const ActionButton = styled(TotemPrimaryButton)`
    display: flex;
    align-items: center;
    justify-content: center;

    margin-right: 20px;
    margin-top: 20px;
`;

const ActionLabel = styled.div`
    flex-shrink: 0;
    margin-left: 10px;
`;

import { createClient } from 'graphql-ws';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { onError } from '@apollo/client/link/error';
import { ApolloClient, ApolloLink, from, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
// eslint-disable-next-line import/no-unresolved
import { createUploadLink } from 'apollo-upload-client';

import { toast } from 'react-toastify';

import { TOTEM_BEARER_TOKEN } from '../../constants/token';

import { getGraphQLUrl, getGraphQLWebSocketUrl } from './graphql-utils';
import { cache } from './cache';

const graphQLWebSockerUrl = getGraphQLWebSocketUrl();

const wsLink = new GraphQLWsLink(
    createClient({
        url: graphQLWebSockerUrl,
        shouldRetry: () => true, // client should not throw if server restarts
        connectionParams: () => ({
            token: window.localStorage.getItem(TOTEM_BEARER_TOKEN),
        }),
    }),
);

const graphQLUrl = getGraphQLUrl();

const uploadLink = createUploadLink({ uri: graphQLUrl });

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const authorization = window.localStorage.getItem(TOTEM_BEARER_TOKEN) || '';

    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            'apollo-require-preflight': true, // https://www.apollographql.com/docs/apollo-server/security/cors/
            authorization,
        },
    };
});

// we don't need httpLink because uploadLink already integrates it.
// Moreover, we can only use on terminating Link, and uploadLink is considered as a terminating Link (because of its httpLink)
export function createApolloClient() {
    const errorLink = onError(({ graphQLErrors }) => {
        if (graphQLErrors) {
            graphQLErrors.map((error) => {
                const { extensions, message, locations, path } = error;

                // TODO: We need to handle errors better, the error message is lost
                // @ts-expect-error to fix
                const errorMessage = extensions?.exception?.message || message;

                toast.error(errorMessage, { autoClose: false });
                return console.error(
                    [
                        '[GraphQLError]',
                        `Message: ${message}`,
                        `Location: ${JSON.stringify(locations)}`,
                        `Path: ${path}`,
                    ].join('\n'),
                );
            });
        }
    });
    return new ApolloClient({
        cache,
        link: from([
            errorLink,
            authLink,
            split(
                ({ query }) => {
                    const definition = getMainDefinition(query);
                    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
                },
                wsLink,
                // TS issue : apollo-upload-client incorrect typing (https://github.com/DefinitelyTyped/DefinitelyTyped/issues/47369)
                uploadLink as unknown as ApolloLink,
            ),
        ]),
    });
}

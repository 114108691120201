import React from 'react';
import { useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { GetQuizzes } from 'data/queries/__generated__/GetQuizzes';
import { GET_QUIZZES_QUERY } from 'data/queries/quiz';

import { FieldToDisplay, ListView } from 'components/ListView';
import { Loader } from 'components/Loader';
import { getExtendedFormattedDatetime } from 'helpers/dateTimes';

type QuizTable = {
    _id: string;
    createdAt: string;
    title: string;
};

export const QuizzesList = () => {
    const {
        loading: quizzesLoading,
        error: quizzesError,
        data: quizzesData,
        fetchMore: fetchMoreQuizzes,
    } = useQuery<GetQuizzes>(GET_QUIZZES_QUERY, {
        variables: {
            offset: 0,
        },
        fetchPolicy: 'cache-and-network',
    });

    const QUIZZES_FIELDS_TO_DISPLAY: FieldToDisplay<QuizTable>[] = [
        { fieldName: 'createdAt', label: 'Date de création' },
        {
            fieldName: 'title',
            label: 'Question',
        },
    ];

    if (quizzesLoading && !quizzesData) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (quizzesError || !quizzesData) {
        toast.error('Une erreur est survenue lors de la récupération des quiz');
        return null;
    }

    const {
        quizzes: { hasMore, quizzes },
    } = quizzesData;

    const sortedQuizzes = [...quizzes].sort((quizA, quizB) => quizB.createdAt - quizA.createdAt);

    const formattedQuizzes: QuizTable[] = sortedQuizzes.map(({ _id, createdAt, title }) => ({
        _id,
        title,
        createdAt: getExtendedFormattedDatetime(createdAt),
    }));

    const loadMore = async () => {
        await fetchMoreQuizzes({
            variables: {
                offset: quizzes.length,
            },
        });
    };

    return (
        <Container>
            {formattedQuizzes.length ? (
                <ListView<QuizTable>
                    fieldsToDisplay={QUIZZES_FIELDS_TO_DISPLAY}
                    items={formattedQuizzes}
                    loadMore={loadMore}
                    hasMore={hasMore}
                    linkBasePath="/quiz/"
                    keyExtractor={(item) => item._id}
                />
            ) : (
                "Aucun quiz n'a été trouvé"
            )}
        </Container>
    );
};

const Container = styled.div`
    flex: 1;
    overflow-y: scroll;
`;

import gql from 'graphql-tag';

export const PRODUCT_GROUP_FRAGMENT = gql`
    fragment ProductGroupFragment on ProductGroup {
        _id
        composition {
            productId
            quantity
        }
        createdAt
        productId
        updatedAt
    }
`;

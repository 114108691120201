import { WarehouseDeliveryFragmentFragment } from 'data/__generated__';

export function getWarehouseDeliveryLabel(warehouseDelivery: WarehouseDeliveryFragmentFragment): string {
    const {
        transferDate,
        destinationSite: { name },
    } = warehouseDelivery;
    const [year, month, day] = transferDate.split('-');
    const frenchDate = [day, month, year].join('/');
    return `${frenchDate} - ${name}`;
}

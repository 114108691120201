import React from 'react';
import styled from 'styled-components';

import { SiteSetupFragment } from 'data/fragments/__generated__/SiteSetupFragment';
import { GET_MICROSTORE_SITE_siteWithLocationInfo } from 'data/queries/__generated__/GET_MICROSTORE_SITE';

import { SectionContainer } from 'components/DetailsView/Section';
import { SiteSetupTrelloItem } from '../components/SiteSetupTrelloItem';
import { colors } from 'constants/colors';

export const TimingSection = ({
    site,
    siteSetup,
}: {
    site: GET_MICROSTORE_SITE_siteWithLocationInfo;
    siteSetup: SiteSetupFragment;
}) => {
    return (
        <SectionContainer title="Timing du rangement" isInitiallyOpen>
            <>
                <SiteSetupTrelloItem isUsedInTrelloListView={false} site={site} siteSetup={siteSetup} />
                <LegendsContainer>
                    <Legend>
                        <LegendColor color={colors.pantinGrey} />
                        <LegendText>Non commencé</LegendText>
                    </Legend>
                    <Legend>
                        <LegendColor color={colors.orange} />
                        <LegendText>En cours</LegendText>
                    </Legend>
                    <Legend>
                        <LegendColor color={colors.green} />
                        <LegendText>Fini</LegendText>
                    </Legend>
                    <Legend>
                        <LegendColor color={colors.red} />
                        <LegendText>Fini mais forcé</LegendText>
                    </Legend>
                </LegendsContainer>
            </>
        </SectionContainer>
    );
};

const LegendsContainer = styled.div`
    display: flex;
    margin-top: 20px;
`;
const Legend = styled.div`
    display: flex;
    margin-left: 10px;
`;
const LegendColor = styled.div<{ color: string }>`
    background-color: ${({ color }) => color};
    width: 20px;
    height: 20px;
`;
const LegendText = styled.div`
    margin-left: 5px;
`;

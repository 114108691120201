import gql from 'graphql-tag';

export const SUBCATEGORY_FRAGMENT = gql`
    fragment Subcategory on Subcategory {
        _id
        categoryId
        displayIndex
        name
        state
    }
`;

import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useGetWarehouseDeliveryQuery } from 'data/__generated__';
import { Loader } from 'components/Loader';
import { WarehouseDeliveryFormContent } from './WarehouseDeliveryFormContent';

type ParamTypes = {
    warehouseDeliveryId: string;
};
export function WarehouseDeliveryDetails() {
    const { warehouseDeliveryId = '' } = useParams<ParamTypes>();
    const {
        data: warehouseDeliveryData,
        loading: warehouseDeliveryLoading,
        error: warehouseDeliveryError,
    } = useGetWarehouseDeliveryQuery({ variables: { warehouseDeliveryId } });

    if (warehouseDeliveryLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (warehouseDeliveryError || !warehouseDeliveryData) {
        throw new Error('Une erreur est survenue lors de la récupération de la préparation');
    }

    const { warehouseDelivery } = warehouseDeliveryData;

    console.log(warehouseDelivery);

    return (
        <Container>
            <WarehouseDeliveryFormContent warehouseDelivery={warehouseDelivery} />
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex: 1;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

import gql from 'graphql-tag';
import { PRODUCT_GROUP_FRAGMENT } from 'data/fragments/productGroup';

export const GET_PRODUCT_GROUP_QUERY = gql`
    query GET_PRODUCT_GROUP($productGroupId: ID!) {
        getProductGroup(productGroupId: $productGroupId) {
            ...ProductGroupFragment
        }
    }
    ${PRODUCT_GROUP_FRAGMENT}
`;

export const GET_PRODUCT_GROUPS_CONTAINING_PRODUCTS_QUERY = gql`
    query GET_PRODUCT_GROUPS_CONTAINING_PRODUCTS($productIds: [ID!]!) {
        getProductGroupsContainingProducts(productIds: $productIds) {
            ...ProductGroupFragment
            product {
                _id
                brand
                isToBeArchived
                name
                supplierShortageInfo {
                    inShortage
                    returnDate
                }
                stock
                volume
            }
        }
    }
    ${PRODUCT_GROUP_FRAGMENT}
`;

import React from 'react';
import { useQuery } from '@apollo/client';
import { Link, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import type { Options } from 'react-select';

import { InfoType, ProductState } from 'data/__generated__';
import { GET_CATEGORIES_QUERY } from 'data/queries/category';
import { GET_CATEGORIES } from 'data/queries/__generated__/GET_CATEGORIES';
import { GET_SUPPLIERS_QUERY } from 'data/queries/supplier';
import { GET_SUPPLIERS } from 'data/queries/__generated__/GET_SUPPLIERS';

import { Header, HeaderTitle } from 'components/Header';
import { PageTitle } from 'components/PageTitle';
import { TotemInput } from 'components/TotemInput';
import { Option, TotemSelect } from 'components/TotemSelect';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { ProductsList } from './components/ProductsList';
import { StatusTag } from './components/StatusTag';

import { PAGES } from 'constants/pages';
import { GET_TAGS_QUERY } from 'data/queries/tag';
import { GET_TAGS } from 'data/queries/__generated__/GET_TAGS';
import { InfoTag } from 'pages/Products/components/InfoTag';
import { GetSubcategories } from 'data/queries/__generated__/GetSubcategories';
import { GET_SUBCATEGORIES_QUERY } from 'data/queries/subcategory';

type ParamTypes = {
    category?: string;
    filterString?: string;
    info?: InfoType[];
    state?: ProductState;
    subcategory?: string;
    supplier?: string;
    tag?: string;
};

type UrlParams<T> = { [P in keyof T]?: string[] };

function parseUrlSearchParams<T>(searchParams: URLSearchParams): UrlParams<T> {
    const params: UrlParams<T> = {};
    // @ts-ignore URLSearchParams is not templated, key is string even with Location<T>
    searchParams.forEach((value: string, key: keyof T) => {
        if (!params[key]) {
            params[key] = [value];
        } else {
            (params[key] as string[]).push(value);
        }
    });
    return params;
}

function buildUrlSearchParams<T>(params: UrlParams<T>): URLSearchParams {
    return Object.entries(params).reduce((urlSearchParams, [key, values]) => {
        (values as string[]).forEach((value) => urlSearchParams.append(key, value));
        return urlSearchParams;
    }, new URLSearchParams());
}

const defaultStateFilter = [ProductState.Regular, ProductState.Created];

export const Products = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const params = parseUrlSearchParams<ParamTypes>(searchParams);

    const {
        filterString = [],
        state: stateFilter = defaultStateFilter,
        info: infoFilters = [],
        category: categoryFilter,
        subcategory: subcategoryFilter,
        supplier: supplierFilter,
        tag: tagFilter,
    } = params;

    const { data: categoriesData } = useQuery<GET_CATEGORIES>(GET_CATEGORIES_QUERY);
    const {
        data: subcategoriesData,
        loading: subcategoriesLoading,
        error: subcategoriesError,
    } = useQuery<GetSubcategories>(GET_SUBCATEGORIES_QUERY);
    const { loading: tagsLoading, data: tagsData, error: tagsError } = useQuery<GET_TAGS>(GET_TAGS_QUERY);

    const {
        loading: suppliersLoading,
        data: suppliersData,
        error: suppliersError,
    } = useQuery<GET_SUPPLIERS>(GET_SUPPLIERS_QUERY);

    function updateFilterString(value: string) {
        const searchParams = buildUrlSearchParams({ ...params, filterString: [value] });
        setSearchParams(searchParams);
    }

    function updateStateFilter(state: ProductState) {
        if (stateFilter === defaultStateFilter) {
            const newState = defaultStateFilter.includes(state)
                ? defaultStateFilter.filter((currentState) => currentState !== state)
                : [state];
            const updatedParams = {
                ...params,
                state: newState,
            };
            const searchParams = buildUrlSearchParams(updatedParams);
            setSearchParams(searchParams);
            return;
        }
        if (params?.state?.includes(state)) {
            const updatedParams = { ...params, state: params.state.filter((currentState) => currentState !== state) };
            const searchParams = buildUrlSearchParams(updatedParams);
            setSearchParams(searchParams);
        } else {
            const updatedParams = { ...params, state: [...(params?.state || []), state] };
            const searchParams = buildUrlSearchParams(updatedParams);
            setSearchParams(searchParams);
        }
    }

    function updateInfoFilters(infoTag: InfoType) {
        if (params?.info?.includes(infoTag)) {
            const updatedParams = { ...params, info: params.info.filter((currentInfo) => currentInfo !== infoTag) };
            const searchParams = buildUrlSearchParams(updatedParams);
            setSearchParams(searchParams);
        } else {
            const updatedParams = { ...params, info: [...(params?.info || []), infoTag] };
            const searchParams = buildUrlSearchParams(updatedParams);
            setSearchParams(searchParams);
        }
    }

    function updateCategoryFilter(categories: Options<Option<string>> | null | undefined) {
        if (categories && categories?.length > 0) {
            const searchParams = buildUrlSearchParams({
                ...params,
                category: categories.map((category) => category.value),
            });
            setSearchParams(searchParams);
        } else {
            const searchParams = buildUrlSearchParams({ ...params, category: [] });
            setSearchParams(searchParams);
        }
    }

    const categoryOptions = (categoriesData?.categories || []).map((category) => ({
        label: category.title,
        value: category._id,
    }));

    const subcategoryOptions = (subcategoriesData?.subcategories || [])
        .filter(
            (subcategory) =>
                !categoryFilter || categoryFilter.length === 0 || categoryFilter.includes(subcategory.categoryId),
        )
        .map((subcategory) => ({
            label: subcategory.name,
            value: subcategory._id,
        }));

    function updateSubcategoryFilter(subcategories: Options<Option<string>> | null | undefined) {
        if (subcategories && subcategories?.length > 0) {
            const searchParams = buildUrlSearchParams({
                ...params,
                subcategory: subcategories.map((subcategory) => subcategory.value),
            });
            setSearchParams(searchParams);
        } else {
            const searchParams = buildUrlSearchParams({ ...params, subcategory: [] });
            setSearchParams(searchParams);
        }
    }

    const tagOptions = (tagsData?.tags || []).map((tag) => ({
        label: tag.name,
        value: tag._id,
    }));

    function updateTagFilter(tags: Options<Option<string>> | null | undefined) {
        if (tags && tags?.length > 0) {
            const searchParams = buildUrlSearchParams({
                ...params,
                tag: tags.map((tag) => tag.value),
            });
            setSearchParams(searchParams);
        } else {
            const searchParams = buildUrlSearchParams({ ...params, tag: [] });
            setSearchParams(searchParams);
        }
    }

    const supplierOptions = suppliersData?.suppliers
        .map((supplier) => ({ value: supplier._id, label: supplier.name }))
        .sort((supplierOptionA, supplierOptionB) =>
            supplierOptionA.label.toLowerCase().localeCompare(supplierOptionB.label.toLowerCase()),
        );

    const supplierOptionSelected =
        supplierOptions && supplierFilter?.length
            ? supplierOptions.find(({ value }) => value === supplierFilter[0])
            : null;

    function updateSupplierFilter(supplier: Option<string> | null | undefined) {
        if (!supplier) {
            const { supplier, ...updatedParams } = params;
            const searchParams = buildUrlSearchParams({
                ...updatedParams,
            });
            setSearchParams(searchParams);
        } else {
            const searchParams = buildUrlSearchParams({
                ...params,
                supplier: [supplier.value],
            });
            setSearchParams(searchParams);
        }
    }

    const selectedCategoryOptions = categoryOptions.filter(({ value }) => categoryFilter?.includes(value));
    const selectedSubcategoryOptions = subcategoryOptions.filter(({ value }) => subcategoryFilter?.includes(value));
    const selectedTagOptions = tagOptions.filter(({ value }) => tagFilter?.includes(value));

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.products} />
                </HeaderTitle>
                <ButtonsContainer>
                    <Link to="/product/imports">
                        <TotemPrimaryButton isSecondaryStyle>Imports CSV</TotemPrimaryButton>
                    </Link>
                    <Link to="/product/create">
                        <TotemPrimaryButton data-test="create-product-button">Créer un produit</TotemPrimaryButton>
                    </Link>
                </ButtonsContainer>
            </Header>
            <Content>
                <TotemInput
                    label="Recherche"
                    onChange={updateFilterString}
                    placeholder="Nom ou marque du produit"
                    value={filterString[0]}
                    autoFocus={true}
                />
                <Filters>
                    <Title>Filtres</Title>
                    {(Object.keys(ProductState) as ProductState[]).map((state) => (
                        <FilterContainer
                            key={state}
                            isSelected={stateFilter.includes(state)}
                            onClick={() => updateStateFilter(state)}
                        >
                            <StatusTag state={state} />
                        </FilterContainer>
                    ))}
                    <SelectContainer>
                        <TotemSelect<string, true>
                            isMulti
                            placeholder="Catégories"
                            value={selectedCategoryOptions}
                            options={categoryOptions}
                            onChange={updateCategoryFilter}
                        />
                    </SelectContainer>
                    {!subcategoriesLoading && !subcategoriesError ? (
                        <SelectContainer>
                            <TotemSelect<string, true>
                                isMulti
                                placeholder="Sous-catégories"
                                value={selectedSubcategoryOptions}
                                options={subcategoryOptions}
                                onChange={updateSubcategoryFilter}
                            />
                        </SelectContainer>
                    ) : null}
                    {!tagsLoading && !tagsError ? (
                        <SelectContainer>
                            <TotemSelect<string, true>
                                isMulti
                                placeholder="Tags"
                                value={selectedTagOptions}
                                options={tagOptions}
                                onChange={updateTagFilter}
                            />
                        </SelectContainer>
                    ) : null}
                    {!suppliersLoading && !suppliersError ? (
                        <SelectContainer>
                            <TotemSelect
                                isClearable
                                placeholder="Fournisseur (nom ou _id)"
                                value={supplierOptionSelected}
                                options={supplierOptions}
                                onChange={updateSupplierFilter}
                            />
                        </SelectContainer>
                    ) : null}
                </Filters>
                <Filters>
                    <Title>Filtres Info</Title>
                    {(Object.keys(InfoType) as InfoType[]).map((infoTag) => (
                        <FilterContainer
                            key={infoTag}
                            isSelected={infoFilters.includes(infoTag)}
                            onClick={() => updateInfoFilters(infoTag)}
                        >
                            <InfoTag infoTag={infoTag} />
                        </FilterContainer>
                    ))}
                </Filters>
                <ListContainer>
                    <ProductsList
                        infoFilters={infoFilters as InfoType[]}
                        filterString={filterString[0]}
                        stateFilter={stateFilter as ProductState[]}
                        categoryFilter={categoryFilter}
                        subcategoryFilter={subcategoryFilter}
                        supplierFilter={supplierFilter}
                        tagFilter={tagFilter}
                    />
                </ListContainer>
            </Content>
        </Container>
    );
};

const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    ${TotemPrimaryButton} {
        margin-left: 5px;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    flex: 1;
    overflow: hidden;
`;

const ListContainer = styled.div`
    flex: 1;
    overflow: hidden;
`;

const Filters = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const SelectContainer = styled.div`
    width: 250px;
`;

const Title = styled.h3`
    margin: 0;
    font-size: 20px;
    font-weight: 800;
`;

const FilterContainer = styled.div<{ isSelected: boolean }>`
    cursor: pointer;
    border-radius: 20px;
    border: 2px solid ${({ isSelected, theme }) => (isSelected ? theme.darkBorderColor : 'transparent')};
`;

import gql from 'graphql-tag';
import { TOTEM_CLOSED_DAY_FRAGMENT } from 'data/fragments/totemClosedDay';

export const GET_ALL_REGULAR_TOTEM_CLOSED_DAY_QUERY = gql`
    query GET_ALL_REGULAR_TOTEM_CLOSED_DAY {
        getAllRegularTotemClosedDay {
            ...TotemClosedDayFragment
        }
    }
    ${TOTEM_CLOSED_DAY_FRAGMENT}
`;

import React from 'react';
import { Route, Routes, createBrowserRouter } from 'react-router-dom';

import { PAGES } from 'constants/pages';

import { SupplyOrderConfirmation } from './SupplyOrders/SupplyOrderConfirmation';
import { AdvertisementScreenConfigLoggedOut } from './AdvertisementScreenConfig/AdvertisementScreenConfigLoggedOut';
import { Login } from './Login';

function PublicRoot() {
    return (
        <Routes>
            <Route path={PAGES.supplyOrderConfirmation.url} element={<SupplyOrderConfirmation />} />
            <Route
                path={PAGES.advertisementScreenConfigLoggedOut.url}
                element={<AdvertisementScreenConfigLoggedOut />}
            />
            <Route path="/*" element={<Login />} />
        </Routes>
    );
}

export const publicRouter = createBrowserRouter([{ path: '*', Component: PublicRoot }]);

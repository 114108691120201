import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { PAGES } from '../../constants/pages';

import { Header, HeaderTitle } from 'components/Header';
import { TotemDatePicker } from 'components/TotemDatePicker';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { PageTitle } from 'components/PageTitle';

import { StockTransferReturnsProducts } from 'pages/StockTransfers/components/StockTransferReturnsProducts';

export const StockTransferReturns = () => {
    const [transferDate, setTransferDate] = useState<Date>(new Date());

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.stockTransferReturns} />
                </HeaderTitle>
                <Link to="/stockTransfers">
                    <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                </Link>
            </Header>
            <Content>
                <TotemDatePicker
                    label="Date du retour"
                    selected={transferDate}
                    onChange={(date) => setTransferDate(date as Date)}
                />
                <StockTransferReturnsProducts transferDate={transferDate} />
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    flex: 1;
    overflow: hidden;
`;

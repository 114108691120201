import React, { useState } from 'react';

import { useQuery } from '@apollo/client';

import { GET_EXPIRY_DATE_RECORDS_FOR_SITE_QUERY } from 'data/queries/expiryDateRecord';
import {
    GET_EXPIRY_DATE_RECORDS_FOR_SITE,
    GET_EXPIRY_DATE_RECORDS_FOR_SITEVariables,
} from 'data/queries/__generated__/GET_EXPIRY_DATE_RECORDS_FOR_SITE';
import { ExpiryDateRecordState } from 'data/__generated__';

import { FieldToDisplay, ListView } from 'components/ListView';
import { Loader } from 'components/Loader';
import { TotemInput } from 'components/TotemInput';
import { TotemSelect } from 'components/TotemSelect';
import { StatusTag } from 'pages/ExpiryDateRecords/components/StatusTag';
import { EXPIRY_DATE_RECORD_STATES_LABELS } from 'pages/ExpiryDateRecords/constants/states';

import { dateFromString } from 'helpers/dateTimes';
import styled from 'styled-components';

type FormattedExpiryDateRecordType = {
    _id: string;
    createdAt: string;
    expiryDate: string;
    productName: string;
    quantityAtInventory: number;
    state: string;
    stateComponent: JSX.Element;
    updatedAt: string;
};

export const ExpiryDateRecordList = ({ siteId }: { siteId: string }) => {
    const [searchedProductName, setSearchedProductName] = useState('');
    const [searchedState, setSearchedState] = useState<ExpiryDateRecordState | null>(ExpiryDateRecordState.Regular);

    const {
        loading: expiryDateRecordsLoading,
        data: expiryDateRecordsData,
        error: expiryDateRecordsError,
    } = useQuery<GET_EXPIRY_DATE_RECORDS_FOR_SITE, GET_EXPIRY_DATE_RECORDS_FOR_SITEVariables>(
        GET_EXPIRY_DATE_RECORDS_FOR_SITE_QUERY,
        { variables: { siteId } },
    );

    if (expiryDateRecordsLoading && !expiryDateRecordsData) {
        return <Loader />;
    }

    if (expiryDateRecordsError || !expiryDateRecordsData) {
        throw new Error('Une erreur est survenue lors de la récupération des DLC');
    }

    const { getExpiryDateRecordsForSiteQuery: expiryDateRecords } = expiryDateRecordsData;

    const EXPIRY_DATE_RECORD_FIELDS_TO_DISPLAY: FieldToDisplay<FormattedExpiryDateRecordType>[] = [
        {
            fieldName: 'productName',
            label: 'Nom du produit',
            sortFunction: (expiryDateRecordA, expiryDateRecordB) =>
                (expiryDateRecordA.productName as string).localeCompare(expiryDateRecordB.productName as string),
        },
        {
            fieldName: 'expiryDate',
            label: 'DLC',
            sortFunction: (expiryDateRecordA, expiryDateRecordB) =>
                dateFromString(expiryDateRecordA.expiryDate as string) <
                dateFromString(expiryDateRecordB.expiryDate as string)
                    ? -1
                    : 1,
        },
        {
            fieldName: 'quantityAtInventory',
            label: 'Qt dernier inventaire',
        },
        {
            fieldName: 'stateComponent',
            label: 'Statut',
            sortFunction: (expiryDateRecordA, expiryDateRecordB) =>
                (expiryDateRecordA.state as string).localeCompare(expiryDateRecordB.state as string),
        },
        {
            fieldName: 'updatedAt',
            label: 'Date de mise à jour',
            sortFunction: (expiryDateRecordA, expiryDateRecordB) =>
                new Date(expiryDateRecordA.updatedAt as string) < new Date(expiryDateRecordB.updatedAt as string)
                    ? -1
                    : 1,
        },
        {
            fieldName: 'createdAt',
            label: 'Date de création',
            sortFunction: (expiryDateRecordA, expiryDateRecordB) =>
                new Date(expiryDateRecordA.createdAt as string) < new Date(expiryDateRecordB.createdAt as string)
                    ? -1
                    : 1,
        },
    ];

    const filteredExpiryDateRecords = expiryDateRecords.filter(
        ({ product: { brand, name, volume }, state }) =>
            `${name} - ${brand} - ${volume}`.toLowerCase().search(searchedProductName.toLowerCase()) !== -1 &&
            (!searchedState || searchedState === state),
    );

    const sortedExpiryDateRecords = [...filteredExpiryDateRecords].sort((expiryDateRecordA, expiryDateRecordB) =>
        expiryDateRecordA.updatedAt < expiryDateRecordB.updatedAt ? 1 : -1,
    );

    const formattedExpiryDateRecords: FormattedExpiryDateRecordType[] = sortedExpiryDateRecords.map(
        ({ _id, createdAt, expiryDate, product: { brand, name, volume }, quantityAtInventory, state, updatedAt }) => {
            return {
                _id,
                createdAt: new Date(createdAt).toLocaleString(),
                expiryDate,
                productName: `${brand} - ${name} - ${volume}`,
                quantityAtInventory,
                state,
                stateComponent: <StatusTag state={state} />,
                updatedAt: new Date(updatedAt).toLocaleString(),
            };
        },
    );

    const stateOptions = Object.values(ExpiryDateRecordState).map((state) => ({
        value: state,
        label: EXPIRY_DATE_RECORD_STATES_LABELS[state],
    }));

    return (
        <>
            <FilterContainer>
                <TotemInput
                    label="Nom du produit"
                    onChange={setSearchedProductName}
                    placeholder="Nom du produit"
                    value={searchedProductName}
                    autoFocus={true}
                />

                <TotemSelect<ExpiryDateRecordState>
                    label="Statut"
                    value={stateOptions.find((option) => option.value === searchedState)}
                    options={stateOptions}
                    placeholder="Statut"
                    isClearable={true}
                    onChange={async (option) => {
                        setSearchedState(option?.value ?? null);
                    }}
                />
            </FilterContainer>
            <ListView<FormattedExpiryDateRecordType>
                fieldsToDisplay={EXPIRY_DATE_RECORD_FIELDS_TO_DISPLAY}
                items={formattedExpiryDateRecords}
                keyExtractor={(item) => item._id}
            />
        </>
    );
};

const FilterContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    & > * {
        flex-grow: 1;
    }

    & > :not(:first-child) {
        margin-left: 20px;
    }
`;

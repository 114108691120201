import React, { PropsWithChildren, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { UpdateThemeContext } from './contexts';
import { Theme } from './contexts/theme';

export function ThemeContextProvider({ defaultTheme, children }: PropsWithChildren<{ defaultTheme: Theme }>) {
    const [theme, setTheme] = useState(defaultTheme);

    return (
        <UpdateThemeContext.Provider value={{ setTheme }}>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </UpdateThemeContext.Provider>
    );
}

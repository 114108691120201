import React from 'react';

import { useMutation } from '@apollo/client';
import { FcCalendar } from 'react-icons/fc';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { GET_SUPPLY_ORDERS_supplyOrders } from 'data/queries/__generated__/GET_SUPPLY_ORDERS';
import { MARK_SUPPLY_ORDERS_AS_CONFIRMED_MUTATION } from 'data/mutations/supplyOrder';
import {
    MARK_SUPPLY_ORDERS_AS_CONFIRMED,
    MARK_SUPPLY_ORDERS_AS_CONFIRMEDVariables,
} from 'data/mutations/__generated__/MARK_SUPPLY_ORDERS_AS_CONFIRMED';

import { Loader, LoaderModeType } from 'components/Loader';
import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

export function MarkAsConfirmedPopup({
    supplyOrders,
    selectedSupplyOrderIds,
    setSelectedSupplyOrderIds,
    isOpen,
    setIsOpen,
}: {
    supplyOrders: GET_SUPPLY_ORDERS_supplyOrders[];
    selectedSupplyOrderIds: string[];
    setSelectedSupplyOrderIds: (supplyOrderIds: string[]) => void;
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
}) {
    const [markSupplyOrdersAsConfirmed, { loading }] = useMutation<
        MARK_SUPPLY_ORDERS_AS_CONFIRMED,
        MARK_SUPPLY_ORDERS_AS_CONFIRMEDVariables
    >(MARK_SUPPLY_ORDERS_AS_CONFIRMED_MUTATION, { variables: { supplyOrderIds: selectedSupplyOrderIds } });

    const selectedSupplyOrders = supplyOrders.filter((supplyOrder) => selectedSupplyOrderIds.includes(supplyOrder._id));

    async function handleSubmit() {
        const { data } = await markSupplyOrdersAsConfirmed();

        if (!data) {
            throw new Error("Une erreur est survenue lors du passage des achats en 'confirmé(s)'");
        }

        const {
            markSupplyOrdersAsConfirmed: { updatedSupplyOrders, errors },
        } = data;

        if (updatedSupplyOrders.length) {
            toast.success(`Achat(s) mis en 'confirmé(s)' : ${updatedSupplyOrders.length}`);
        }

        if (errors.length) {
            errors.forEach((error, index) => {
                toast.error(<span key={index}>Erreur : {error}</span>, { autoClose: false });
            });

            if (errors.length > 1) {
                toast.info('Cliquez pour fermer toutes les notifications', {
                    autoClose: false,
                    onClick: () => toast.dismiss(),
                });
            }
        }
        setSelectedSupplyOrderIds([]);
        setIsOpen(false);
    }

    return (
        <TotemPopup title="Êtes-vous sûr.e ?" isOpen={isOpen} setIsOpen={setIsOpen}>
            Vous vous apprêtez à passer à 'confirmé(s)' {selectedSupplyOrders.length} achat(s). Souhaitez-vous vraiment
            modifier le statut du/des achat(s) suivant(s) ?
            <SupplyOrderlist>
                {selectedSupplyOrders.map((supplyOrder) => {
                    const { _id: supplyOrderId, orderDate, dateDeliveryScheduled, number, supplier } = supplyOrder;

                    return (
                        <SupplyOrder key={supplyOrderId}>
                            <SupplyOrderTitle>
                                {supplier.name} #{number}
                            </SupplyOrderTitle>
                            <SupplyOrderDate>
                                <FcCalendar size={26} />
                                Date de commande : {orderDate ?? 'N/A'}
                            </SupplyOrderDate>
                            <SupplyOrderDate>
                                <FcCalendar size={26} />
                                Date de livraison prévue : {dateDeliveryScheduled ?? 'N/A'}
                            </SupplyOrderDate>
                        </SupplyOrder>
                    );
                })}
            </SupplyOrderlist>
            <SubmitContainer>
                <TotemPrimaryButton onClick={handleSubmit}>
                    {loading ? <Loader size="20px" mode={LoaderModeType.Spin} /> : 'Confirmer'}
                </TotemPrimaryButton>
            </SubmitContainer>
        </TotemPopup>
    );
}

const SupplyOrderlist = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    padding-left: 50px;
    overflow: auto;

    & > :not(:first-child) {
        margin-top: 5px;
    }
`;

const SupplyOrder = styled.span`
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
`;

const SupplyOrderDate = styled.span`
    display: inline-flex;
    align-items: center;
    margin-left: 15px;
`;

const SupplyOrderTitle = styled.span`
    display: inline-flex;
    align-items: center;
    font-weight: 800;
    flex-grow: 1;
`;

const SubmitContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 15px;
`;

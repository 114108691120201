import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import { FaSave } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { PAGES } from 'constants/pages';

import { CreatePollVariables, CreatePoll } from 'data/mutations/__generated__/CreatePoll';
import { CREATE_POLL_MUTATION } from 'data/mutations/poll';

import { Header, HeaderTitle } from 'components/Header';
import { Loader, LoaderModeType } from 'components/Loader';
import { PageTitle } from 'components/PageTitle';
import { PollForm, PollFormType } from './components/PollForm';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

import { formatDateAsAnniversary } from 'helpers/dateTimes';

export const PollCreate = () => {
    const navigate = useNavigate();

    const [createPoll, { loading: creationLoading }] = useMutation<CreatePoll, CreatePollVariables>(
        CREATE_POLL_MUTATION,
    );

    const initialForm = {
        audience: { organizationIds: [], siteIds: [], userIds: [] },
        answerOptions: [],
        dateWindow: { startDate: null, endDate: null },
        image: null,
        imageUrl: null,
        question: null,
        sectionTitle: null,
        title: null,
    };
    const [pollForm, setPollForm] = useState<PollFormType>(initialForm);

    async function onSubmit() {
        if (!pollForm.question) {
            toast.error('Question non renseignée.');
            return;
        }
        if (!pollForm.dateWindow.startDate || !pollForm.dateWindow.endDate) {
            toast.error('Date non renseignée.');
            return;
        }
        if (!pollForm.title) {
            toast.error('Titre non renseigné.');
            return;
        }
        const { data } = await createPoll({
            variables: {
                input: {
                    audience: pollForm.audience,
                    answerOptions: pollForm.answerOptions,
                    dateWindow: {
                        startDate: formatDateAsAnniversary({
                            dateTime: pollForm.dateWindow.startDate,
                            useNewFormat: true,
                        }),
                        endDate: formatDateAsAnniversary({ dateTime: pollForm.dateWindow.endDate, useNewFormat: true }),
                    },
                    image: pollForm.image,
                    imageUrl: pollForm.imageUrl,
                    question: pollForm.question,
                    title: pollForm.title,
                },
            },
        });

        if (data) {
            const {
                createPoll: { poll, errors },
            } = data;
            if (poll) {
                toast.success('Le sondage a bien été créé !');
                navigate(`/poll/${poll._id}`);
            } else {
                if (errors) {
                    errors.forEach((error) => {
                        toast.error(error, { autoClose: false });
                    });

                    if (errors.length > 1) {
                        toast.info('Cliquez pour fermer toutes les notifications', {
                            autoClose: false,
                            onClick: () => toast.dismiss(),
                        });
                    }
                } else {
                    throw Error("Une erreur inconnue s'est produite");
                }
            }
        } else {
            throw Error("Une erreur inconnue s'est produite");
        }
    }

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.pollCreate} />
                </HeaderTitle>
                <CTAsContainer>
                    <TotemPrimaryButton data-test="create-button" onClick={onSubmit} minWidth="78px">
                        {creationLoading ? (
                            <Loader size="18px" mode={LoaderModeType.Spin} />
                        ) : (
                            <>
                                <FaSave size={13} />
                                <SaveLabel>Créer</SaveLabel>
                            </>
                        )}
                    </TotemPrimaryButton>
                    <Link to="/polls">
                        <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                    </Link>
                </CTAsContainer>
            </Header>
            <Content>
                <ScrollableContent>
                    <PollForm pollForm={pollForm} setPollForm={setPollForm} />
                </ScrollableContent>
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const CTAsContainer = styled.div`
    display: flex;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const SaveLabel = styled.span`
    margin-left: 5px;
`;

const Content = styled.div`
    flex: 1;
    overflow: hidden;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const ScrollableContent = styled.div`
    padding: 15px;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

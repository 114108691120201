import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';

import styled from 'styled-components';

import { EXTERNAL_CONFIRM_SUPPLY_ORDER_MUTATION } from 'data/mutations/supplyOrder';
import { Spinner } from 'components/Spinner';

const EMAIL_HEADER = 'https://totems3.s3.eu-central-1.amazonaws.com/assets/header-black.png';
const EMAIL_FOOTER = 'https://totems3.s3.eu-central-1.amazonaws.com/assets/yellow-footer.png';

export const SupplyOrderConfirmation = () => {
    const { token } = useParams<{ token: string }>();
    const [displayMessage, setDisplayMessage] = useState<string | null>(null);

    const [confirmSupplyOrder] = useMutation(EXTERNAL_CONFIRM_SUPPLY_ORDER_MUTATION);

    useEffect(() => {
        const verifyToken = async () => {
            const result = await confirmSupplyOrder({ variables: { token } });

            if (!result) {
                setDisplayMessage("Une erreur est survenue. La commande n'a pas pu être validée.");
                return;
            }

            const {
                data: {
                    externalConfirmSupplyOrder: { success, error },
                },
            } = result;

            if (error) {
                setDisplayMessage(error);
                return;
            }

            if (success) {
                setDisplayMessage('La commande a bien été validée. ✅');
            }
        };

        if (!displayMessage) {
            verifyToken();
        }
    }, []);

    return (
        <Container>
            <ImageContainer height={250}>
                <Image src={EMAIL_HEADER} alt="Totem Header" />
            </ImageContainer>
            <TextContainer>{displayMessage ? <Label>{displayMessage}</Label> : <Spinner size={64} />}</TextContainer>
            <ImageContainer height={150}>
                <Image src={EMAIL_FOOTER} alt="Totem Footer" />
            </ImageContainer>
        </Container>
    );
};
const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
`;

const ImageContainer = styled.div<{ height: number }>`
    width: 100%;
    height: ${({ height }) => `${height}px`};
`;

const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const TextContainer = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
`;

const Label = styled.div`
    font-size: 22px;
    text-align: center;
`;

export function renderAsPrice(price: number) {
    const eurosPart = Math.floor(price);
    const centsPart = Math.round((price - eurosPart) * 100);
    return `${eurosPart},${centsPart}${centsPart === 0 ? '0' : ''} €`;
}

export function round(number: number, numberOfDecimals = 0): number {
    const multiplier = Math.pow(10, numberOfDecimals);
    return Math.round(number * multiplier) / multiplier;
}

export function roundPrice(number: number): number {
    return round(number, 2);
}

import React, { ReactNode, useEffect, useState } from 'react';
import { ImEnlarge, ImShrink } from 'react-icons/im';
// @ts-ignore
import Split from 'react-split';

import styled from 'styled-components';

function getInitialSizesWithLocalStorage({ initialSizes, name }: { initialSizes: number[]; name?: string }) {
    try {
        const localStorageSizes = name ? window.localStorage.getItem(`split-${name}-sizes`) : null;
        return localStorageSizes ? JSON.parse(localStorageSizes) : initialSizes;
    } catch {
        return initialSizes;
    }
}

export function SplitPanels({
    children,
    displayEnlarge,
    displayShrink,
    haveMargins,
    initialSizes,
    isVertical = false,
    name,
}: {
    children: ReactNode;
    displayEnlarge: boolean[];
    displayShrink: boolean[];
    haveMargins?: boolean[];
    initialSizes: number[];
    isVertical?: boolean;
    name?: string;
}) {
    const [currentSizes, setCurrentSizes] = useState<number[]>(getInitialSizesWithLocalStorage({ initialSizes, name }));

    useEffect(() => {
        setCurrentSizes(getInitialSizesWithLocalStorage({ initialSizes, name }));
    }, [initialSizes, setCurrentSizes, name]);

    function updateCurrentSizes(sizes: number[]) {
        if (name) {
            window.localStorage.setItem(`split-${name}-sizes`, JSON.stringify(sizes));
        }
        setCurrentSizes(sizes);
    }

    function enlargeIndex(index: number) {
        const newSizes = new Array(currentSizes.length);
        newSizes.fill(0);
        newSizes[index] = 100;

        updateCurrentSizes(newSizes);
    }

    function shrinkIndex(index: number) {
        const sizes = [...currentSizes];
        sizes[index] = 0;

        const totalSize = sizes.reduce<number>((acc, size) => acc + size, 0);

        const newSizes = sizes.map((size, sizeIndex) =>
            totalSize ? (size * 100) / totalSize : index === sizeIndex ? 0 : 100 / (sizes.length - 1),
        );

        updateCurrentSizes(newSizes);
    }

    if (!children) {
        return null;
    }

    return (
        <Container
            isVertical={isVertical}
            className="split"
            sizes={currentSizes}
            minSize={40}
            snapOffset={0}
            gutterSize={10}
            direction={isVertical ? 'vertical' : 'horizontal'}
            onDragEnd={(newSizes: number[]) => updateCurrentSizes(newSizes)}
        >
            {(Array.isArray(children) ? children : [children]).map((child, index) => (
                <PanelContainer key={index}>
                    <ButtonsContainer>
                        {displayEnlarge[index] ? <EnlargeButton size={20} onClick={() => enlargeIndex(index)} /> : null}
                        {displayShrink[index] ? <ShrinkButton size={20} onClick={() => shrinkIndex(index)} /> : null}
                    </ButtonsContainer>
                    <ChildContainer hasMargin={haveMargins ? haveMargins[index] : true}>{child}</ChildContainer>
                </PanelContainer>
            ))}
        </Container>
    );
}

const Container = styled(Split)<{ isVertical: boolean }>`
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: ${({ isVertical }) => (isVertical ? 'column' : 'row')};
    height: 100%;
    width: 100%;

    .gutter {
        background-color: #eee;
        background-repeat: no-repeat;
        background-position: 50%;
    }

    .gutter.gutter-horizontal {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
        cursor: col-resize;
    }

    .gutter.gutter-vertical {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=');
        cursor: row-resize;
    }
`;

const PanelContainer = styled.div`
    position: relative;
    transition:
        width 0.1s ease,
        height 0.1s ease;
`;

const ChildContainer = styled.div<{ hasMargin: boolean }>`
    height: calc(100% - (2 * ${({ hasMargin }) => (hasMargin ? '30px' : '0px')}));
    margin: ${({ hasMargin }) => (hasMargin ? 30 : 0)}px;
    overflow: hidden;
`;

const ButtonsContainer = styled.div`
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const EnlargeButton = styled(ImEnlarge)`
    cursor: pointer;
`;

const ShrinkButton = styled(ImShrink)`
    cursor: pointer;
`;

import React from 'react';
import styled from 'styled-components';

import { DetailValue } from 'components/DetailsView';
import { ColumnsSectionContainer, SectionColumn } from 'components/DetailsView/Section';

import {
    SetupInstructionFragment_CheckBoxTemperatureInstruction,
    SetupInstructionFragment_CheckTemperatureInstruction,
} from 'data/fragments/__generated__/SetupInstructionFragment';
import { SETUP_INSTRUCTION_TYPE_LABELS } from 'pages/SiteSetups/constants/types';

export const ColumnTemperatureSection = ({
    instruction,
}: {
    instruction:
        | SetupInstructionFragment_CheckTemperatureInstruction
        | SetupInstructionFragment_CheckBoxTemperatureInstruction;
}) => {
    return (
        <Container>
            <Title>{SETUP_INSTRUCTION_TYPE_LABELS[instruction.type]}</Title>
            <Content>
                <ColumnsSectionContainer numberOfColumns={2}>
                    <SectionColumn>
                        <DetailValue label="Température" value={instruction.temperature} />
                    </SectionColumn>
                    <SectionColumn>
                        <DetailValue label="Explication" value={instruction.noTemperatureComment || 'NA'} />
                    </SectionColumn>
                </ColumnsSectionContainer>
            </Content>
        </Container>
    );
};

const Container = styled.div``;

const Content = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
`;

const Title = styled.div`
    font-size: 18px;
    font-weight: bold;
    text-align: center;
`;

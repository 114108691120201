import React, { useState } from 'react';

import { FcCalendar } from 'react-icons/fc';
import styled from 'styled-components';

import { colors } from 'constants/colors';

import { GET_DELIVERY_delivery } from 'data/queries/__generated__/GET_DELIVERY';
import { DeliveryState } from 'data/__generated__';

import { DetailValue } from 'components/DetailsView/DetailValue';
import { SectionColumn, SectionContainer, ColumnsSectionContainer } from 'components/DetailsView/Section';
import { StatusTag } from '../components/StatusTag';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { CopyValue } from 'components/CopyValue';
import { UpdateDeliveryDatePopup } from './UpdateDeliveryDatePopup';

import { formatDateAsAnniversary, getExtendedFormattedDatetime } from '../../../helpers/dateTimes';
import { DetailLink } from 'components/DetailsView';

export const GeneralInfoSection = ({ delivery }: { delivery: GET_DELIVERY_delivery }) => {
    const { _id: deliveryId, createdAt, deliveryDate, state, trackingUrl, updatedAt, label, orders, siteId } = delivery;
    const [isUpdateDatePopupOpen, setIsUpdateDatePopupOpen] = useState(false);

    return (
        <SectionContainer title="Informations générales" isInitiallyOpen>
            <ColumnsSectionContainer numberOfColumns={3}>
                <SectionColumn>
                    <CopyValue value={deliveryId} />
                    <DetailValue label="Détail" value={label} />
                    <DetailValue
                        label="Date de livraison"
                        value={
                            <DeliveryDateContainer>
                                <FcCalendar size={22} />
                                <DeliveryDate>
                                    {formatDateAsAnniversary({ dateTime: deliveryDate, displayDay: true })}
                                </DeliveryDate>
                                {[
                                    DeliveryState.Created,
                                    DeliveryState.Packaging,
                                    DeliveryState.Ready,
                                    DeliveryState.NotDelivered,
                                ].includes(state) ? (
                                    <UpdateDateButton onClick={() => setIsUpdateDatePopupOpen(true)}>
                                        Modifier
                                    </UpdateDateButton>
                                ) : null}
                            </DeliveryDateContainer>
                        }
                    />
                    <UpdateDeliveryDatePopup
                        deliveryId={deliveryId}
                        isOpen={isUpdateDatePopupOpen}
                        setIsOpen={setIsUpdateDatePopupOpen}
                    />
                    {trackingUrl ? (
                        <DetailValue
                            label="Lien de suivi"
                            value={
                                <UrbantzLink target="_blank" rel="noopener noreferrer" href={trackingUrl}>
                                    {trackingUrl}
                                </UrbantzLink>
                            }
                        />
                    ) : null}
                </SectionColumn>
                <SectionColumn>
                    <DetailValue label="Date de création" value={getExtendedFormattedDatetime(createdAt)} />
                    <DetailValue label="Dernière mise à jour" value={getExtendedFormattedDatetime(updatedAt)} />
                    <StatusTag state={state} showHeading />
                </SectionColumn>
                <SectionColumn>
                    {orders.map((order) => (
                        <DetailLink key={order._id} path="order" label="Order ID" value={order._id} />
                    ))}
                    <DetailLink path="site" label="Site ID" value={siteId} />
                </SectionColumn>
            </ColumnsSectionContainer>
        </SectionContainer>
    );
};

const UrbantzLink = styled.a`
    color: ${colors.green};
`;

const DeliveryDateContainer = styled.div`
    display: flex;
    align-items: center;
`;

const DeliveryDate = styled.span`
    margin-left: 5px;
`;

const UpdateDateButton = styled(TotemPrimaryButton)`
    margin-left: 15px;
`;

import React, { useEffect } from 'react';

import { useLazyQuery, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
    GET_PRODUCTS_TO_ORDER_ONCE_FOR_SUPPLY_ORDER_QUERY,
    GET_SUPPLY_ORDER_SUPPLIER_PRODUCTS_STOCK_VARIATION_QUERY,
    GET_SUPPLY_ORDER_WITH_SUPPLIER_PRODUCTS_INFO_QUERY,
} from 'data/queries/supplyOrder';
import {
    GET_SUPPLY_ORDER_WITH_SUPPLIER_PRODUCTS_INFO,
    GET_SUPPLY_ORDER_WITH_SUPPLIER_PRODUCTS_INFOVariables,
} from 'data/queries/__generated__/GET_SUPPLY_ORDER_WITH_SUPPLIER_PRODUCTS_INFO';
import {
    GET_SUPPLY_ORDER_SUPPLIER_PRODUCTS_STOCK_VARIATION,
    GET_SUPPLY_ORDER_SUPPLIER_PRODUCTS_STOCK_VARIATIONVariables,
} from 'data/queries/__generated__/GET_SUPPLY_ORDER_SUPPLIER_PRODUCTS_STOCK_VARIATION';

import { Loader } from 'components/Loader';
import { SupplyOrderDetailsFormPage } from 'pages/SupplyOrders/SupplyOrderDetails/SupplyOrderDetailsFormPage';
import {
    GET_PRODUCTS_TO_ORDER_ONCE_FOR_SUPPLY_ORDER,
    GET_PRODUCTS_TO_ORDER_ONCE_FOR_SUPPLY_ORDERVariables,
} from 'data/queries/__generated__/GET_PRODUCTS_TO_ORDER_ONCE_FOR_SUPPLY_ORDER';

type ParamTypes = {
    supplyOrderId: string;
};

export const SupplyOrderDetails = () => {
    const { supplyOrderId = '' } = useParams<ParamTypes>();

    const {
        loading: supplyOrderLoading,
        data: supplyOrderData,
        error: supplyOrderError,
    } = useQuery<GET_SUPPLY_ORDER_WITH_SUPPLIER_PRODUCTS_INFO, GET_SUPPLY_ORDER_WITH_SUPPLIER_PRODUCTS_INFOVariables>(
        GET_SUPPLY_ORDER_WITH_SUPPLIER_PRODUCTS_INFO_QUERY,
        {
            variables: { supplyOrderId },
            onCompleted: () => null,
        },
    );

    const {
        loading: productsToOrderOnceForSupplyOrderLoading,
        data: productsToOrderOnceForSupplyOrderData,
        error: productsToOrderOnceForSupplyOrderError,
    } = useQuery<GET_PRODUCTS_TO_ORDER_ONCE_FOR_SUPPLY_ORDER, GET_PRODUCTS_TO_ORDER_ONCE_FOR_SUPPLY_ORDERVariables>(
        GET_PRODUCTS_TO_ORDER_ONCE_FOR_SUPPLY_ORDER_QUERY,
        {
            variables: { supplyOrderId },
        },
    );

    const [getSupplyOrderSupplierProductsStockVariation, productsStockVariationQueryState] = useLazyQuery<
        GET_SUPPLY_ORDER_SUPPLIER_PRODUCTS_STOCK_VARIATION,
        GET_SUPPLY_ORDER_SUPPLIER_PRODUCTS_STOCK_VARIATIONVariables
    >(GET_SUPPLY_ORDER_SUPPLIER_PRODUCTS_STOCK_VARIATION_QUERY, { fetchPolicy: 'network-only' });

    useEffect(() => {
        getSupplyOrderSupplierProductsStockVariation({
            variables: { supplyOrderId },
        });
    }, [supplyOrderId, supplyOrderData, getSupplyOrderSupplierProductsStockVariation]);

    if (supplyOrderLoading || productsToOrderOnceForSupplyOrderLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (
        supplyOrderError ||
        !supplyOrderData ||
        productsToOrderOnceForSupplyOrderError ||
        !productsToOrderOnceForSupplyOrderData
    ) {
        throw new Error("Une erreur est survenue lors de la récupération de l'achat");
    }

    const { supplyOrderWithSupplierProductsInfo: supplyOrder } = supplyOrderData;
    const { productsToOrderOnceForSupplyOrder: productsToOrderOnce } = productsToOrderOnceForSupplyOrderData;

    if (!supplyOrder) {
        throw new Error(`Aucun achat n'a été trouvé avec l'id "${supplyOrderId}"`);
    }

    return (
        <SupplyOrderDetailsFormPage
            supplyOrder={supplyOrder}
            productsStockVariationQueryState={productsStockVariationQueryState}
            productsToOrderOnce={productsToOrderOnce}
        />
    );
};

const Container = styled.div`
    display: flex;
    flex: 1;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

import React from 'react';

import { InfoType } from 'data/__generated__';

import { InfoTag } from 'pages/Products/components/InfoTag';
import { TotemLabel } from 'components/TotemLabel';

function getProductInfoTags({
    access,
    isBeingTested,
    isInShortage,
    isOutOfStock,
    isPaused,
    isToBeArchived,
    newUntil,
}: {
    access: string[];
    isBeingTested: boolean;
    isInShortage: boolean;
    isOutOfStock: boolean;
    isPaused: boolean;
    isToBeArchived: boolean;
    newUntil: string | null;
}) {
    const infoTags: InfoType[] = [];

    if (access.length) {
        infoTags.push(InfoType.Private);
    }

    if (isInShortage) {
        infoTags.push(InfoType.IsInShortage);
    }

    if (isToBeArchived) {
        infoTags.push(InfoType.IsToBeArchived);
    }

    if (isBeingTested) {
        infoTags.push(InfoType.IsBeingTested);
    }

    if (newUntil) {
        infoTags.push(InfoType.New);
    }

    if (isPaused) {
        infoTags.push(InfoType.Paused);
    }

    if (isOutOfStock) {
        infoTags.push(InfoType.OutOfStock);
    }

    return infoTags;
}

export const InfoTags = ({
    access,
    isBeingTested,
    isInShortage,
    isOutOfStock,
    isToBeArchived,
    newUntil,
    isPaused,
    shouldAddMargin = false,
    showHeader = true,
}: {
    access: string[];
    isBeingTested: boolean;
    isInShortage: boolean;
    isOutOfStock: boolean;
    isPaused: boolean;
    isToBeArchived: boolean;
    newUntil: string | null;
    shouldAddMargin?: boolean;
    showHeader?: boolean;
}) => {
    const productInfoTags = getProductInfoTags({
        access,
        isToBeArchived,
        isInShortage,
        isBeingTested,
        newUntil,
        isOutOfStock,
        isPaused,
    });

    return (
        <>
            {showHeader && !!productInfoTags.length ? <TotemLabel>Info</TotemLabel> : null}
            {productInfoTags.map((infoTag, index) => {
                return <InfoTag key={index} infoTag={infoTag} marginTop={shouldAddMargin && index ? '2px' : null} />;
            })}
        </>
    );
};

import React from 'react';
import styled from 'styled-components';

import { colors } from 'constants/colors';

import { MicrostoreColumnInventoryFragment_checkedLocations } from 'data/fragments/__generated__/MicrostoreColumnInventoryFragment';
import { GET_MICROSTORE_PRODUCTS_getMicrostoreProducts } from 'data/queries/__generated__/GET_MICROSTORE_PRODUCTS';

import { dateFromString } from 'helpers/dateTimes';

export const InventoryProductsSection = ({
    checkedLocations,
    products,
    setSelectedProductId,
    selectedProductId,
    weekdateBeforeNextSetup,
}: {
    checkedLocations: MicrostoreColumnInventoryFragment_checkedLocations[];
    products: GET_MICROSTORE_PRODUCTS_getMicrostoreProducts[];
    setSelectedProductId: React.Dispatch<React.SetStateAction<string | null>>;
    selectedProductId: string | null;
    weekdateBeforeNextSetup: Date;
}) => {
    const checkedProductIds = checkedLocations.map(({ productId }) => productId);
    const checkedProducts = products.filter((product) => checkedProductIds.includes(product._id));

    return (
        <ProductsContainer>
            {checkedProducts.map((checkedProduct) => {
                const associatedCheckedLocations = checkedLocations.filter(
                    ({ productId }) => productId === checkedProduct._id,
                );
                const isSelected = selectedProductId === checkedProduct._id;
                return (
                    <ProductContainer key={checkedProduct._id}>
                        <ProductName
                            onClick={() => setSelectedProductId(checkedProduct._id ?? null)}
                            isSelected={isSelected}
                        >
                            {checkedProduct.name}
                        </ProductName>
                        <LocationsContainer>
                            {associatedCheckedLocations.map((checkedLocation, index) => {
                                return (
                                    <LocationContainer key={checkedLocation.microstoreLocationId}>
                                        <LocationName>Emplacement {index + 1}</LocationName>
                                        <QuantitiesContainer>
                                            {!checkedLocation.quantityDetails.length ? (
                                                <QuantityContainer>Vide</QuantityContainer>
                                            ) : null}
                                            {checkedLocation.quantityDetails.map(({ expiryDate, quantity }) => {
                                                const isExpired = !!(
                                                    expiryDate && dateFromString(expiryDate) < weekdateBeforeNextSetup
                                                );
                                                return (
                                                    <QuantityContainer key={expiryDate} isExpired={isExpired}>
                                                        Qty: {quantity} ; DLC: {expiryDate ?? 'Non Rensigné'}
                                                    </QuantityContainer>
                                                );
                                            })}
                                        </QuantitiesContainer>
                                    </LocationContainer>
                                );
                            })}
                        </LocationsContainer>
                    </ProductContainer>
                );
            })}
        </ProductsContainer>
    );
};

const ProductsContainer = styled.div`
    flex-grow: 1;
    & > :not(:first-child) {
        border-top: 1px solid ${colors.lightGrey};
        margin-top: 5px;
    }
`;
const ProductContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;
const ProductName = styled.div<{ isSelected: boolean }>`
    cursor: pointer;
    font-weight: bold;
    width: 50%;
    color: ${({ isSelected }) => (isSelected ? colors.red : colors.pureBlack)};
`;
const LocationsContainer = styled.div`
    margin-left: 10px;
    width: 50%;
`;
const LocationContainer = styled.div`
    display: flex;
`;
const LocationName = styled.div`
    font-style: italic;
`;
const QuantitiesContainer = styled.div``;
const QuantityContainer = styled.div<{ isExpired?: boolean }>`
    margin-left: 10px;
    color: ${({ isExpired }) => (isExpired ? colors.red : colors.pureBlack)};
`;

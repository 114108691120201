import gql from 'graphql-tag';

import {
    PRODUCT_ARRANGEMENT_FRAGMENT,
    PRODUCT_ARRANGEMENT_WITH_PRODUCTS_FRAGMENT,
} from '../fragments/productArrangement';

export const GET_PRODUCT_ARRANGEMENTS_QUERY = gql`
    query GET_PRODUCT_ARRANGEMENTS {
        productArrangementsQuery {
            ...ProductArrangementFragment
        }
    }
    ${PRODUCT_ARRANGEMENT_FRAGMENT}
`;

export const GET_PRODUCT_ARRANGEMENT_QUERY = gql`
    query GET_PRODUCT_ARRANGEMENT($productArrangementId: String!) {
        productArrangementQuery(productArrangementId: $productArrangementId) {
            ...ProductArrangementWithProductsFragment
        }
    }
    ${PRODUCT_ARRANGEMENT_WITH_PRODUCTS_FRAGMENT}
`;

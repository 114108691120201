import React, { useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import styled from 'styled-components';

import { PAGES } from '../../constants/pages';
import { getTranslatedNotificationCategory } from 'constants/notification';

import { SEND_NOTIFICATION_TO_ORGANIZATION_MUTATION } from 'data/mutations/notification';
import { GET_ORGANIZATIONS_QUERY } from 'data/queries/organization';
import {
    SEND_NOTIFICATION_TO_ORGANIZATION,
    SEND_NOTIFICATION_TO_ORGANIZATIONVariables,
} from 'data/mutations/__generated__/SEND_NOTIFICATION_TO_ORGANIZATION';
import { GET_ORGANIZATIONS } from 'data/queries/__generated__/GET_ORGANIZATIONS';
import { NotificationCategory } from 'data/__generated__';

import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { Header, HeaderTitle } from 'components/Header';
import { PageTitle } from 'components/PageTitle';
import { TotemInput } from 'components/TotemInput';
import { TotemTextArea } from 'components/TotemTextArea';
import { Loader, LoaderModeType } from 'components/Loader';
import { SelectedOption, TotemSelect } from 'components/TotemSelect';
import { DeeplinkSelector } from './components/DeeplinkSelector';

export const NotificationsOrganization = () => {
    const [organizationOption, setOrganizationOption] = useState<SelectedOption<string>>(null);
    const [notificationCategoryOption, setNotificationCategoryOption] =
        useState<SelectedOption<NotificationCategory>>(null);
    const [deeplink, setDeeplink] = useState<string>();
    const [title, setTitle] = useState<string>('');
    const [body, setBody] = useState<string>('');
    const [slug, setSlug] = useState<string>('');

    const {
        loading: organizationsLoading,
        data: organizationsData,
        error: organizationsError,
    } = useQuery<GET_ORGANIZATIONS>(GET_ORGANIZATIONS_QUERY);

    const [sendNotificationToOrganization, { loading: sendNotificationToOrganizationLoading }] = useMutation<
        SEND_NOTIFICATION_TO_ORGANIZATION,
        SEND_NOTIFICATION_TO_ORGANIZATIONVariables
    >(SEND_NOTIFICATION_TO_ORGANIZATION_MUTATION, {
        onCompleted: ({ sendNotificationToOrganization }) => {
            if (sendNotificationToOrganization) {
                setTitle('');
                setBody('');
                setSlug('');
                toast.success('Les notifications se sont bien envoyées');
            } else {
                toast.error("Une erreur est survenue pendant l'envoi des notifications");
            }
        },
    });

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!organizationOption) {
            toast.error("Veuillez renseigner l'organisation");
            return;
        }
        if (!title && !body) {
            toast.error('Veuillez renseigner un champ parmi titre et message');
            return;
        }
        if (!slug) {
            toast.error('Veuillez renseigner un identifiant (slug)');
            return;
        }

        const notificationCategory = notificationCategoryOption?.value;

        await sendNotificationToOrganization({
            variables: { organizationId: organizationOption.value, title, body, notificationCategory, deeplink, slug },
        });
    };

    const handleOrganizationSelect = (option: SelectedOption<string>) => {
        if (option) {
            setOrganizationOption(option);
        }
    };

    function handleNotificationCategorySelect(option: SelectedOption<NotificationCategory>) {
        setNotificationCategoryOption(option);
    }

    if (organizationsLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (organizationsError || !organizationsData) {
        throw new Error('Une erreur est survenue lors de la récupération des organisations');
    }

    const organizationsOptions = organizationsData.organizations
        .map((organization) => ({ value: organization._id, label: organization.name }))
        .sort((organizationA, organizationB) =>
            organizationA.label.toLowerCase().localeCompare(organizationB.label.toLowerCase()),
        );

    const notificationCategoriesOptions = (Object.keys(NotificationCategory) as Array<NotificationCategory>).map(
        (key) => ({
            value: key,
            label: getTranslatedNotificationCategory(key),
        }),
    );

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.notifications} />
                </HeaderTitle>

                <Link to="/notifications">
                    <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                </Link>
            </Header>
            <Form onSubmit={handleSubmit}>
                <Message>Choisissez une organisation pour envoyer une notification à tous ses membres</Message>
                {!organizationsLoading && organizationsData ? (
                    <TotemSelect
                        label="Organisation"
                        placeholder="Sélectionner une organisation (recherche par nom ou par _id)"
                        value={organizationOption}
                        options={organizationsOptions}
                        onChange={handleOrganizationSelect}
                    />
                ) : null}
                <TotemSelect
                    label="Catégorie de notification"
                    placeholder="Sélectionner une catégorie"
                    value={notificationCategoryOption}
                    options={notificationCategoriesOptions}
                    onChange={handleNotificationCategorySelect}
                />
                <TotemInput
                    label="Titre de la notif"
                    value={title}
                    placeholder="Titre"
                    onChange={(value) => setTitle(value)}
                />
                <TotemTextArea
                    label="Message de la notif"
                    value={body}
                    placeholder="Message"
                    onChange={(value) => setBody(value)}
                    rows={5}
                />
                <TotemInput
                    label="Identifiant unique (slug)"
                    value={slug}
                    placeholder="daily-delivery"
                    onChange={(value) => setSlug(value)}
                />
                <DeeplinkSelector setDeeplink={setDeeplink} />
                <SubmitButton
                    disabled={
                        sendNotificationToOrganizationLoading || !organizationOption || (!title && !body) || !slug
                    }
                >
                    Envoyer une notif
                </SubmitButton>
                {sendNotificationToOrganizationLoading ? <Loader mode={LoaderModeType.Spin} /> : null}
            </Form>
        </Container>
    );
};

const Container = styled.form`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 30px;
    overflow-y: scroll;

    & > :not(:first-child) {
        margin-top: 20px;
    }
`;

const SubmitButton = styled(TotemPrimaryButton)`
    margin-top: 20px;
`;

const Message = styled.span`
    text-align: center;
    color: ${({ theme }) => theme.textColor};
`;

import React from 'react';
import styled from 'styled-components';

import { TotemLabel } from '../TotemLabel';
import { BasicInput } from '../BasicInput';
import { Controller } from 'react-hook-form';
import type { Control } from 'react-hook-form';

import fr from 'date-fns/locale/fr';
import DatePicker, { registerLocale } from 'react-datepicker';
import type { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

registerLocale('fr', fr);

const isRangeDate = (date: Date | Date[] | null) => {
    return Array.isArray(date);
};

type DetailFormDatePickerProps = Omit<ReactDatePickerProps, 'onChange'> & {
    label?: string;
    sublabel?: string;
    defaultValue?: any;
    placeholder?: string;
    minDate?: Date | null;
    name: string;
    isDisabled?: boolean;
    error?: string;
    control: Control;
    transformValueToDate?: (value: any) => Date | null;
    transformDateToValue?: (date: Date | null) => any;
    dateFormat?: string;
};

export const DetailFormDatePicker = ({
    label,
    sublabel,
    placeholder,
    defaultValue,
    name,
    isDisabled,
    error,
    control,
    minDate = null,
    transformValueToDate,
    transformDateToValue,
    dateFormat = 'dd/MM/yyyy',
    ...props
}: DetailFormDatePickerProps) => {
    return (
        <Container>
            <LabelContainer>
                {label ? <TotemLabel>{label}</TotemLabel> : null}
                {sublabel ? <Sublabel>{sublabel}</Sublabel> : null}
            </LabelContainer>
            <ValueContainer>
                <Controller
                    control={control}
                    name={name}
                    defaultValue={defaultValue}
                    render={({ field: { onChange, value } }) => (
                        <DatePicker
                            selected={transformValueToDate ? transformValueToDate(value) : value}
                            onChange={(date) => {
                                if (isRangeDate(date)) {
                                    return;
                                }
                                transformDateToValue
                                    ? onChange(transformDateToValue(date as Date | null))
                                    : onChange(date);
                            }}
                            placeholderText={placeholder}
                            locale="fr"
                            disabled={isDisabled}
                            dateFormat={dateFormat}
                            customInput={<BasicInput />}
                            minDate={minDate}
                            wrapperClassName={`totem-date-picker-${name}`}
                            {...props}
                        />
                    )}
                />
                {error ? <Error>{error}</Error> : null}
            </ValueContainer>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    color: ${({ theme }) => theme.textColor};
`;

const LabelContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const Sublabel = styled.span`
    color: ${({ theme }) => theme.infoTextColor};
    font-size: 13px;
`;

const ValueContainer = styled.div`
    position: relative;
    margin-top: 5px;
`;

const Error = styled.div`
    position: absolute;
    right: -2px;
    top: -16px;
    font-size: 14px;
    color: ${({ theme }) => theme.errorColor};
    width: max-content;
`;

import React from 'react';
import styled from 'styled-components';
import type { FieldArrayMethodProps, FieldArrayWithId } from 'react-hook-form';

import { GET_EQUIPMENTS_adminEquipments } from 'data/queries/__generated__/GET_EQUIPMENTS';

import { SelectedOption } from 'components/TotemCheckbox';
import { TotemSelect } from 'components/TotemSelect';

type EquipmentType = { equipmentId: string; quantity: number; comment: string };
type EquipmentFields = FieldArrayWithId<
    {
        equipments: EquipmentType[];
    },
    'equipments',
    'id'
>[];

export const EquipmentSelector = ({
    append,
    equipmentFields,
    equipments,
}: {
    append: (
        value: { equipmentId: string; quantity: number; comment: string },
        options?: FieldArrayMethodProps | undefined,
    ) => void;
    equipmentFields: EquipmentFields;
    equipments: GET_EQUIPMENTS_adminEquipments[];
}) => {
    const onSelect = (option: SelectedOption) => {
        if (option && !equipmentFields.some(({ equipmentId }) => equipmentId === option.value)) {
            append({ equipmentId: option.value, quantity: 0, comment: '' });
        }
    };

    const equipmentOptions = equipments.map(({ _id, fullname }: { _id: string; fullname: string }) => ({
        value: _id,
        label: fullname,
    }));

    return (
        <SelectorContainer>
            <TotemSelect
                label="Ajouter un equipment"
                placeholder="Recherche par nom ou par _id"
                value={null}
                options={equipmentOptions}
                onChange={(option) => onSelect(option)}
                styles={customStyles}
            />
        </SelectorContainer>
    );
};

const SelectorContainer = styled.div`
    width: 100%;
    font-size: 15px;
`;

const customStyles = {
    menuList: (provided: any) => ({
        ...provided,
        maxHeight: '110px',
    }),
    option: (provided: any) => ({
        ...provided,
        height: '30px',
    }),
};

import gql from 'graphql-tag';
import { DETAILED_TRANSACTION_FRAGMENT } from '../fragments/transaction';

export const REFUND_TRANSACTION_MUTATION = gql`
    mutation REFUND_TRANSACTION($transactionId: ID!, $refundReason: String, $shouldUpdateProductStock: Boolean) {
        refundTransaction(
            transactionId: $transactionId
            refundReason: $refundReason
            shouldUpdateProductStock: $shouldUpdateProductStock
        ) {
            ...DetailedTransactionFragment
        }
    }
    ${DETAILED_TRANSACTION_FRAGMENT}
`;

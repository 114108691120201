import gql from 'graphql-tag';

import { QUIZ_FRAGMENT } from 'data/fragments/quiz';

export const GET_QUIZZES_QUERY = gql`
    query GetQuizzes($offset: Int!) {
        quizzes(offset: $offset) {
            hasMore
            quizzes {
                _id
                createdAt
                title
            }
        }
    }
`;

export const GET_QUIZ_QUERY = gql`
    query GetQuiz($quizId: ID!) {
        quiz(quizId: $quizId) {
            ...QuizFragment
        }
    }
    ${QUIZ_FRAGMENT}
`;

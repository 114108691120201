import styled from 'styled-components';

export const SectionColumn = styled.div`
    display: flex;
    flex-direction: column;
    & > :not(:first-child) {
        margin-top: 15px;
    }

    @media print {
        padding: 40px;
    }

    @page {
        @bottom-left {
            content: 'Page ' counter(page) ' sur ' counter(pages);
        }
    }
`;

import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import styled from 'styled-components';

import { CREATE_CONSUMPTION_VARIATION_MUTATION } from 'data/mutations/consumptionVariation';
import {
    CREATE_CONSUMPTION_VARIATION,
    CREATE_CONSUMPTION_VARIATIONVariables,
} from 'data/mutations/__generated__/CREATE_CONSUMPTION_VARIATION';

import { Loader, LoaderModeType } from 'components/Loader';
import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

import { consumptionVariationsUpdatedOrCreatedHandler } from '../cacheHandlers/consumptionVariationsCacheHandler';
import { ConsumptionVariationCreateInput } from 'data/__generated__';
import { dateFromString, formatDateAsAnniversary } from 'helpers/dateTimes';
import { ColumnsSectionContainer, SectionColumn } from 'components/DetailsView/Section';
import { TotemDatePicker } from 'components/TotemDatePicker';

export function CreateConsumptionVariationPopup({
    siteId,
    isOpen,
    setIsOpen,
}: {
    siteId: string;
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
}) {
    const [consumptionVariationForm, setConsumptionVariationForm] = useState<ConsumptionVariationCreateInput>({
        endDate: formatDateAsAnniversary({ dateTime: new Date(), useNewFormat: true }),
        siteId,
        startDate: formatDateAsAnniversary({ dateTime: new Date(), useNewFormat: true }),
    });

    const [createConsumptionVariation, { loading: creationLoading }] = useMutation<
        CREATE_CONSUMPTION_VARIATION,
        CREATE_CONSUMPTION_VARIATIONVariables
    >(CREATE_CONSUMPTION_VARIATION_MUTATION, {
        variables: { consumptionVariationCreateInput: consumptionVariationForm },
    });

    async function handleSubmit() {
        const { data } = await createConsumptionVariation();

        if (!data) {
            throw new Error('Une erreur est survenue lors de la création de la variation de consommation');
        }

        const { createConsumptionVariation: newConsumptionVariation } = data;

        consumptionVariationsUpdatedOrCreatedHandler([newConsumptionVariation]);

        setIsOpen(false);
    }

    return (
        <TotemPopup
            title="Ajouter une variation de consommation"
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            contentOverflow="visible"
        >
            <ColumnsSectionContainer numberOfColumns={2}>
                <SectionColumn>
                    <TotemDatePicker
                        label="Date de début"
                        selected={dateFromString(consumptionVariationForm.startDate, true)}
                        onChange={(date) => {
                            if (!date) {
                                return;
                            }
                            setConsumptionVariationForm({
                                ...consumptionVariationForm,
                                startDate: formatDateAsAnniversary({ dateTime: date, useNewFormat: true }),
                            });
                        }}
                    />
                </SectionColumn>
                <SectionColumn>
                    <TotemDatePicker
                        label="Date de fin"
                        selected={dateFromString(consumptionVariationForm.endDate, true)}
                        onChange={(date) => {
                            if (!date) {
                                return;
                            }
                            setConsumptionVariationForm({
                                ...consumptionVariationForm,
                                endDate: formatDateAsAnniversary({ dateTime: date, useNewFormat: true }),
                            });
                        }}
                    />
                </SectionColumn>
            </ColumnsSectionContainer>

            <SubmitContainer>
                <ValidationButton
                    onClick={handleSubmit}
                    disabled={consumptionVariationForm.endDate < consumptionVariationForm.startDate}
                >
                    {creationLoading ? <Loader size="20px" mode={LoaderModeType.Spin} /> : 'Confirmer'}
                </ValidationButton>
            </SubmitContainer>
        </TotemPopup>
    );
}

const SubmitContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 15px;
`;

const ValidationButton = styled(TotemPrimaryButton)`
    margin-top: 15px;
`;

import React, { PropsWithChildren } from 'react';
import { toast } from 'react-toastify';
import Lottie from 'react-lottie';
import styled from 'styled-components';
import dogAnimationData from './animationData/error-dog.json';
import coffeeSpilledAnimationData from './animationData/error-coffee-spilled.json';

type Props = PropsWithChildren<Record<string, unknown>>;

type State = {
    hasError: boolean;
};

export class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error: any) {
        this.setState({ hasError: true });
        toast.error(error.message, { autoClose: false });
    }

    render() {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: Math.random() > 0.25 ? dogAnimationData : coffeeSpilledAnimationData,
        };
        if (this.state.hasError) {
            return (
                <Container>
                    <Title>Une erreur inopinée est survenue</Title>
                    <Lottie options={defaultOptions} height={400} width={400} />
                </Container>
            );
        }
        return this.props.children;
    }
}

const Container = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 30px;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Title = styled.span`
    font-size: 42px;
    font-weight: 800;
    color: ${({ theme }) => theme.textColor};
`;

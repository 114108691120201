import gql from 'graphql-tag';

export const THERMOMETER_FRAGMENT = gql`
    fragment ThermometerFragment on Thermometer {
        _id
        associatedUserId
        isOperational
        number
    }
`;

import gql from 'graphql-tag';

import { HOT_DRINKS_ARRANGEMENT_FRAGMENT } from '../fragments/hotDrinksArrangement';

export const GET_HOT_DRINKS_ARRANGEMENTS_QUERY = gql`
    query GET_HOT_DRINKS_ARRANGEMENTS {
        getHotDrinksArrangements {
            ...HotDrinksArrangementFragment
        }
    }
    ${HOT_DRINKS_ARRANGEMENT_FRAGMENT}
`;

export const GET_HOT_DRINKS_ARRANGEMENT_QUERY = gql`
    query GET_HOT_DRINKS_ARRANGEMENT($hotDrinksArrangementId: ID!) {
        getHotDrinksArrangement(hotDrinksArrangementId: $hotDrinksArrangementId) {
            ...HotDrinksArrangementFragment
        }
    }
    ${HOT_DRINKS_ARRANGEMENT_FRAGMENT}
`;

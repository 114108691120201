import { apolloClient } from 'App';

import { CREATE_RITUAL_TEMPLATE_createRitualTemplate_ritualTemplate } from 'data/mutations/__generated__/CREATE_RITUAL_TEMPLATE';
import { GET_RITUAL_TEMPLATES_QUERY } from 'data/queries/ritualTemplates';
import { GET_RITUAL_TEMPLATES } from 'data/queries/__generated__/GET_RITUAL_TEMPLATES';

export const ritualTemplatesUpdatedHandler = (
    newRitualTemplate: CREATE_RITUAL_TEMPLATE_createRitualTemplate_ritualTemplate,
) => {
    const result = apolloClient.readQuery<GET_RITUAL_TEMPLATES>({ query: GET_RITUAL_TEMPLATES_QUERY });
    if (!result) {
        return;
    }
    const { ritualTemplates: currentRitualTemplates } = result;

    const ritualTemplateIds = currentRitualTemplates.map(({ _id }) => _id);

    if (ritualTemplateIds.includes(newRitualTemplate._id)) {
        // If this is a ritualTemplate existing in the cache
        const data = currentRitualTemplates.map((ritualTemplate) => {
            if (ritualTemplate._id === newRitualTemplate._id) {
                return newRitualTemplate;
            }
            return ritualTemplate;
        });
        apolloClient.writeQuery({
            query: GET_RITUAL_TEMPLATES_QUERY,
            data,
        });
    } else {
        // If this is a new ritualTemplate, not present in the cache
        const data = {
            ritualTemplates: [...currentRitualTemplates, newRitualTemplate],
        };
        apolloClient.writeQuery({
            query: GET_RITUAL_TEMPLATES_QUERY,
            data,
        });
    }
};

import React, { useState } from 'react';
import styled from 'styled-components';

import { TagType, TagState } from 'data/__generated__';
import { TotemInput } from 'components/TotemInput';

import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { SelectedOption, TotemSelect } from 'components/TotemSelect';
import { CopyValue } from 'components/CopyValue';

export const TagForm = <
    T extends {
        _id?: string | null;
        displayIndex: number;
        name: string;
        type: SelectedOption<TagType> | null;
        state: SelectedOption<TagState> | null;
    },
>({
    handleSubmit,
    canISubmit,
    initialFormData,
    submitMessage,
}: {
    initialFormData: T;
    handleSubmit: (e: React.FormEvent<HTMLFormElement>, formData: T) => void;
    canISubmit: (formData: T) => boolean;
    submitMessage: string;
}) => {
    const [formData, setFormData] = useState<T>(initialFormData);
    const typesOptions = [
        { value: TagType.Jow, label: TagType.Jow },
        { value: TagType.Principal, label: TagType.Principal },
    ];

    const statesOptions = [
        { value: TagState.Regular, label: TagState.Regular },
        { value: TagState.Archived, label: TagState.Archived },
    ];

    return (
        <Form onSubmit={(e) => handleSubmit(e, formData)}>
            <Fields>
                {initialFormData._id ? <CopyValue value={initialFormData._id} /> : null}
                <TotemInput
                    label="Nom du tag"
                    placeholder="Chips"
                    value={formData.name}
                    onChange={(value) => setFormData({ ...formData, name: value })}
                    dataTest="tag-create_name"
                />
                <TotemSelect<TagType>
                    label="Type"
                    placeholder="Sélectionner un type"
                    value={formData.type}
                    options={typesOptions}
                    onChange={(option) => setFormData({ ...formData, type: option })}
                    dataTest="tag-create_type"
                />
                <TotemSelect<TagState>
                    label="State"
                    placeholder="Sélectionner un état"
                    value={formData.state}
                    options={statesOptions}
                    onChange={(option) => setFormData({ ...formData, state: option })}
                    dataTest="tag-create_state"
                />
                <Row>
                    <TotemInput
                        label="Index d'affichage"
                        placeholder=""
                        value={formData.displayIndex}
                        onChange={(value) => setFormData({ ...formData, displayIndex: value })}
                        type="number"
                    />
                </Row>
            </Fields>
            <TotemPrimaryButton disabled={!canISubmit(formData)} data-test="tag-create_submit-button">
                {submitMessage}
            </TotemPrimaryButton>
        </Form>
    );
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

const Fields = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

const Row = styled.div`
    display: flex;

    & > :not(:first-child) {
        margin-left: 15px;
    }
`;

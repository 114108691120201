import React, { useCallback, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { GET_SITE_MENUS } from 'data/queries/__generated__/GET_SITE_MENUS';
import { GET_SITE_COLUMNS_QUERY, GET_SITE_MENUS_QUERY } from 'data/queries/menus';
import { GET_CATALOG_PRODUCTS_catalogProducts_products } from 'data/queries/__generated__/GET_CATALOG_PRODUCTS';
import { GET_SITE_COLUMNS } from 'data/queries/__generated__/GET_SITE_COLUMNS';
import { MicrostoreColumnTemplateType } from 'data/__generated__';
import { GET_PRODUCT_ARRANGEMENT_productArrangementQuery_productsWithStockMax_product } from 'data/queries/__generated__/GET_PRODUCT_ARRANGEMENT';

import { PAGES } from 'constants/pages';
import { formatDateAsAnniversary, getNextMondayDate } from 'helpers/dateTimes';
import { LocationsToUpdate, ProductReplacementPopupInfo } from './types';

import { Header, HeaderTitle } from 'components/Header';
import { PageTitle } from 'components/PageTitle';
import { Loader } from 'components/Loader';
import { NotFound } from 'components/NotFound';
import { TotemDatePicker } from 'components/TotemDatePicker';

import { GenerateQuantitiesButton } from './GenerateQuantitiesButton';
import { GenerateMenusSupplyOrdersButton } from './GenerateMenusSupplyOrdersButton';
import { GenerateSiteMenusButton } from './GenerateSiteMenusButton';
import { ProductReplacementPopup } from './ProductReplacementPopup';
import { UpdateSiteMenusButton } from './UpdateSiteMenusButton';
import { SiteMenus } from './SiteMenus';

const DAYS = ['L', 'M', 'M', 'J', 'V'];

type ParamTypes = {
    siteId: string;
    selectedDate: string;
};

export const MicrostoreMenusDetails = () => {
    const navigate = useNavigate();
    const { siteId = '', selectedDate: selectedDateFromUrl } = useParams<ParamTypes>();
    const [selectedDays, setSelectedDays] = useState<boolean[]>([true, false, true, false, false]);
    const [hideDate, setHideDate] = useState<boolean>(false);

    const [locationsToUpdate, setLocationsToUpdate] = useState<LocationsToUpdate>({});
    const [productReplacementPopupInfo, setProductReplacementPopupInfo] = useState<ProductReplacementPopupInfo | null>(
        null,
    );

    const selectedDate = selectedDateFromUrl ? new Date(selectedDateFromUrl) : getNextMondayDate();

    const setSelectedDate = useCallback(
        (selectedDate: Date) => {
            navigate(
                `/menusDetails/${siteId}/${formatDateAsAnniversary({
                    dateTime: selectedDate,
                    useNewFormat: true,
                })}`,
            );
        },
        [selectedDate],
    );

    const {
        data: menusData,
        loading: menusLoading,
        error: menusError,
    } = useQuery<GET_SITE_MENUS>(GET_SITE_MENUS_QUERY, {
        variables: {
            siteId,
            date: formatDateAsAnniversary({ dateTime: selectedDate, useNewFormat: true }),
        },
    });

    const {
        data: columnData,
        loading: columnLoading,
        error: columnError,
    } = useQuery<GET_SITE_COLUMNS>(GET_SITE_COLUMNS_QUERY, {
        variables: {
            siteId,
        },
    });

    // We could add 'date' later if we want to be more specific
    // for now we only allow for product replacement in menu, not updated rotations
    const updateLocationsToUpdate = useCallback(
        ({
            locationId,
            currentProductId,
            newProduct,
            dayIndex,
        }: {
            locationId: string;
            currentProductId: string | null;
            newProduct:
                | GET_PRODUCT_ARRANGEMENT_productArrangementQuery_productsWithStockMax_product
                | GET_CATALOG_PRODUCTS_catalogProducts_products
                | null;
            dayIndex: number;
        }) => {
            const newLocationsToUpdate = { ...locationsToUpdate };
            if (!newLocationsToUpdate[locationId]) {
                newLocationsToUpdate[locationId] = {};
            }
            newLocationsToUpdate[locationId][dayIndex] = { currentProductId: currentProductId || null, newProduct };
            // reset empty location or same product
            if ((!newProduct && !currentProductId) || currentProductId === newProduct?._id) {
                delete newLocationsToUpdate[locationId][dayIndex];
            }
            setLocationsToUpdate(newLocationsToUpdate);
        },
        [locationsToUpdate],
    );

    function resetLocationsToUpdate() {
        setLocationsToUpdate({});
    }

    if (menusLoading || columnLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (!columnData || !menusData || menusError || columnError) {
        toast.error('Impossible de récupérer les informations du site');
        console.log({ menusError, columnError });
    }

    const fridgeColumnId = menusData?.siteMenus.columns[0]?.columnId;

    const firstFridgeColumn = columnData?.siteWithLocationTemplates?.microstoreColumnGroups[0].columns.find(
        ({ type, _id }) => type === MicrostoreColumnTemplateType.Fridge && _id === fridgeColumnId,
    );

    if (!firstFridgeColumn) {
        toast.error('Impossible de récupérer de colonne frigo sur ce site');
    }

    const menusForFirstFridge =
        firstFridgeColumn && menusData?.siteMenus.columns.find(({ columnId }) => columnId === firstFridgeColumn._id);

    if (!menusForFirstFridge) {
        toast.error('Impossible de récupérer de menus pour cette colonne');
    }

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.menusDetails} />
                </HeaderTitle>
                <Filters>
                    <GenerateSiteMenusButton date={selectedDate} />
                    <GenerateQuantitiesButton date={selectedDate} />
                    <GenerateMenusSupplyOrdersButton date={selectedDate} />
                    {firstFridgeColumn ? (
                        // this only needs newProduct._id
                        <UpdateSiteMenusButton
                            columnId={firstFridgeColumn._id}
                            date={selectedDate}
                            locationsToUpdate={locationsToUpdate}
                            resetLocationsToUpdate={resetLocationsToUpdate}
                            siteId={siteId}
                        />
                    ) : null}
                    <TotemDatePicker
                        selected={selectedDate}
                        onChange={(date) => {
                            setSelectedDate(date || getNextMondayDate());
                            resetLocationsToUpdate();
                        }}
                    />
                    <SelectedDays>
                        {selectedDays.map((selected, dayIndex) => (
                            <SelectedDay key={dayIndex}>
                                <Day>{DAYS[dayIndex]}</Day>
                                <Selected
                                    checked={selected}
                                    onChange={() => {
                                        const newSelectedDays = [...selectedDays];
                                        newSelectedDays[dayIndex] = !newSelectedDays[dayIndex];
                                        setSelectedDays(newSelectedDays);
                                    }}
                                />
                            </SelectedDay>
                        ))}
                    </SelectedDays>
                    <SelectedDay>
                        <Day>Dates</Day>
                        <Selected checked={!hideDate} onChange={() => setHideDate(!hideDate)} />
                    </SelectedDay>
                </Filters>
            </Header>
            <ProductReplacementPopup
                isOpen={!!productReplacementPopupInfo}
                locationsToUpdate={locationsToUpdate}
                productReplacementPopupInfo={productReplacementPopupInfo}
                setIsOpen={() => setProductReplacementPopupInfo(null)}
                updateLocationsToUpdate={updateLocationsToUpdate}
            />
            {firstFridgeColumn && menusForFirstFridge ? (
                <SiteMenus
                    column={firstFridgeColumn}
                    dailyMenus={menusForFirstFridge.dailyMenus}
                    hideDate={hideDate}
                    locationsToUpdate={locationsToUpdate}
                    selectedDays={selectedDays}
                    setProductReplacementPopupInfo={setProductReplacementPopupInfo}
                />
            ) : (
                <NotFound />
            )}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
`;

const Filters = styled.div`
    display: flex;
    align-items: center;

    & > :not(:first-child) {
        margin-left: 20px;
    }
`;

const SelectedDays = styled.div`
    display: flex;
`;

const SelectedDay = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Day = styled.div``;

const Selected = styled.input.attrs({ type: 'checkbox' })`
    cursor: pointer;
`;

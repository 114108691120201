import React from 'react';

import styled from 'styled-components';

import { TotemLabel } from '../TotemLabel';

export const DetailArrayValue = ({ label, values }: { label?: string; values: (string | number | null)[] | null }) => {
    return (
        <Container>
            <ArrayLabel>{label}</ArrayLabel>
            {values?.length ? (
                <List>
                    {values.map((value, index) => (
                        <Entry key={index} hasBorderTop={index > 0}>
                            {value ?? '⚠️ Valeur manquante'}
                        </Entry>
                    ))}
                </List>
            ) : (
                <Entry hasBorderTop={false}>Aucune valeur</Entry>
            )}
        </Container>
    );
};

const Container = styled.div`
    display: inline-flex;
    flex-direction: column;
    & > :not(:first-child) {
        margin-top: 5px;
    }
`;

const List = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
    min-width: 200px;
`;

const Entry = styled.div`
    padding: 10px;
    border-top: ${({ hasBorderTop }: { hasBorderTop: boolean }) => (hasBorderTop ? 1 : 0)}px solid
        ${({ theme }) => theme.lightBorderColor};
    color: ${({ theme }) => theme.textColor};
`;

const ArrayLabel = styled(TotemLabel)`
    margin: 5px 0;
`;

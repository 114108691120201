import React from 'react';
import { getExtendedFormattedDatetime } from 'helpers/dateTimes';

import { GET_USER_userFromAdmin_wallet_credits } from 'data/queries/__generated__/GET_USER';
import { FieldToDisplay, ListView } from 'components/ListView';
import { CREDIT_TYPE_LABELS } from 'pages/Credits/constants';

type FormattedCreditType = {
    _id: string;
    createdAt: string;
    updatedAt: string;
    createdBy: string;
    name: string;
    initialAmount: number;
    currentAmount: number;
    expirationDate: string;
    startDate: string;
    type: string;
};

const CREDIT_FIELDS_TO_DISPLAY: FieldToDisplay<FormattedCreditType>[] = [
    {
        fieldName: 'createdAt',
        label: 'Date de création',
    },
    {
        fieldName: 'updatedAt',
        label: 'Dernière mise à jour',
    },
    {
        fieldName: 'createdBy',
        label: 'Créé par',
    },
    {
        fieldName: 'type',
        label: 'Type',
    },
    {
        fieldName: 'name',
        label: 'Nom du crédit',
    },
    {
        fieldName: 'initialAmount',
        label: 'Montant initial',
    },
    {
        fieldName: 'currentAmount',
        label: 'Montant restant',
    },
    {
        fieldName: 'startDate',
        label: 'Date de début de validité',
    },
    {
        fieldName: 'expirationDate',
        label: "Date d'expiration",
    },
];

export function Credits({ credits }: { credits: GET_USER_userFromAdmin_wallet_credits[] }) {
    const formattedCredits: FormattedCreditType[] = credits.map((credit) => {
        return {
            _id: credit._id || '',
            createdAt: getExtendedFormattedDatetime(credit.createdAt),
            updatedAt: getExtendedFormattedDatetime(credit.updatedAt),
            createdBy: credit?.createdBy?.profile?.fullname || '',
            name: credit.name || '',
            initialAmount: credit.initialAmount || 0,
            currentAmount: credit.currentAmount || 0,
            expirationDate: credit.expirationDate ? getExtendedFormattedDatetime(credit.expirationDate) : '',
            startDate: credit.startDate ? getExtendedFormattedDatetime(credit.startDate) : '',
            type: CREDIT_TYPE_LABELS[credit.type],
        };
    });
    return (
        <ListView<FormattedCreditType>
            fieldsToDisplay={CREDIT_FIELDS_TO_DISPLAY}
            items={formattedCredits}
            keyExtractor={(item) => item._id}
        />
    );
}

import { DETAILED_ORDER_FRAGMENT } from 'data/fragments/order';
import gql from 'graphql-tag';

export const GET_DETAILED_ORDER_QUERY = gql`
    query GET_DETAILED_ORDER($orderId: ID!) {
        detailedOrder(orderId: $orderId) {
            ...DetailedOrderFragment
        }
    }
    ${DETAILED_ORDER_FRAGMENT}
`;

export const GET_ORDERS_FROM_ORGANIZATION_QUERY = gql`
    query GET_ORDERS_FROM_ORGANIZATION($organizationId: ID!) {
        activeOrdersFromOrganization(organizationId: $organizationId) {
            _id
            deliveryDate
            isPunctual
            number
            totem {
                _id
                name
                number
            }
        }
    }
`;

export const GET_ORDERS_OF_ORGANIZATION_QUERY = gql`
    query GET_ORDERS_OF_ORGANIZATION($organizationId: ID!, $windowStart: String, $windowEnd: String) {
        ordersOfOrganization(organizationId: $organizationId, windowStart: $windowStart, windowEnd: $windowEnd) {
            _id
            deliveryDate
            isPunctual
            number
            totem {
                _id
                name
                number
            }
        }
    }
`;

export const GET_ACTIVE_OR_FOR_DATE_ORDERS_QUERY = gql`
    query GET_ACTIVE_OR_FOR_DATE_ORDERS($deliveryDate: String) {
        activeOrForDateOrders(deliveryDate: $deliveryDate) {
            _id
            date_delivery
            isPunctual
            number
            state
            first_order
            totem {
                _id
                name
                number
                state
            }
            organization {
                name
            }
            pausedInfo {
                returnDate
            }
            prices {
                priceTTC
                priceHT
            }
            weekday
        }
    }
`;

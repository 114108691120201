import { SITE_FRAGMENT } from 'data/fragments/site';
import gql from 'graphql-tag';

export const SITES_UPDATED_SUBSCRIPTION = gql`
    subscription SITES_UPDATED($siteId: ID) {
        siteUpdated(siteId: $siteId) {
            ...SiteFragment
        }
    }
    ${SITE_FRAGMENT}
`;

import React from 'react';
import { FcQuestions } from 'react-icons/fc';
import styled from 'styled-components';

import { TotemLabel } from '../TotemLabel';

export const DocumentationLink = ({
    label,
    sublabel,
    name,
    src,
    fontSize = '16px',
}: {
    label?: string;
    sublabel?: string;
    name?: string;
    src: string;
    fontSize?: string;
}) => {
    return (
        <Container>
            {label ? <TotemLabel>{label}</TotemLabel> : null}
            {sublabel ? <Sublabel>{sublabel}</Sublabel> : null}
            <StyledLink fontSize={fontSize} href={src} target="_blank" rel="noopener noreferrer">
                <FcQuestions size={24} />
                {name || src}
            </StyledLink>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    overflow: hidden;
    flex-direction: column;
    color: ${({ theme }) => theme.textColor};
`;

const Sublabel = styled.span`
    color: ${({ theme }) => theme.infoTextColor};
    font-size: 13px;
`;

const StyledLink = styled.a`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.ctaPrimaryHoveredColor};
    text-decoration: none;
    font-size: ${({ fontSize }: { fontSize: string }) => fontSize};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &:hover {
        text-decoration: underline;
    }
`;

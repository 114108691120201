import { colors } from 'constants/colors';
import { WarehouseReturnState } from 'data/__generated__';

export const WAREHOUSE_RETURN_STATES_LABELS = {
    [WarehouseReturnState.Created]: 'Créé',
    [WarehouseReturnState.InProgress]: 'Sur la route',
    [WarehouseReturnState.Delivered]: 'Livré en entrepôt',
    [WarehouseReturnState.Stored]: 'Rangé',
};

export const WAREHOUSE_RETURN_PLURAL_STATES_LABELS = {
    [WarehouseReturnState.Created]: 'Créés',
    [WarehouseReturnState.InProgress]: 'Sur la route',
    [WarehouseReturnState.Delivered]: 'Livrés en entrepôt',
    [WarehouseReturnState.Stored]: 'Rangés',
};

export const WAREHOUSE_RETURN_STATE_COLORS = {
    [WarehouseReturnState.Created]: colors.pantinGrey,
    [WarehouseReturnState.InProgress]: colors.orange,
    [WarehouseReturnState.Delivered]: colors.blue,
    [WarehouseReturnState.Stored]: colors.green,
};

import React from 'react';
import styled from 'styled-components';
import { colors } from 'constants/colors';
import ReactTooltip from 'react-tooltip';

import { GET_SUPPLY_ORDERS_supplyOrders_supplier_orderingTimes } from 'data/queries/__generated__/GET_SUPPLY_ORDERS';
import { SupplyOrderProductStockVariationFragment } from 'data/fragments/__generated__/SupplyOrderProductStockVariationFragment';

import { getDayLabels } from './utils';

export const ProductsInfoStockVariationTable = ({
    isComputed,
    orderingTimes,
    productStockVariation,
    startDate,
    precalculatedDayLabels,
    shouldDisplayTooltip, // if this component is being mapped, too many tooltips (should only show tooltip for 1-3 components) will freeze the browser tab
}: {
    isComputed: boolean;
    orderingTimes?: GET_SUPPLY_ORDERS_supplyOrders_supplier_orderingTimes;
    productStockVariation: SupplyOrderProductStockVariationFragment;
    startDate: string | null;
    precalculatedDayLabels?: { label: string; orderDayLabels: string[] }[];
    shouldDisplayTooltip: boolean;
}) => {
    const {
        outboundStockVariation,
        productId,
        productSwitchDetails,
        missingStockBetweenDeliveries,
        stockVariation,
        stockVariationFromSupplyOrders,
        forecasts: { forecastsStoreDry },
    } = productStockVariation;

    const dayLabels =
        precalculatedDayLabels ??
        getDayLabels({
            isComputed: isComputed ?? false,
            startDate,
            timeWindow: forecastsStoreDry.length,
            orderingTimes,
        });

    return (
        <Container>
            <tbody>
                <tr>
                    {dayLabels.map(({ label, orderDayLabels }, index: number) => {
                        return (
                            <ProductsInfoStockVariationTableCell
                                key={index}
                                canBeDelivered={!!orderDayLabels.length}
                                data-for={`stock-variation-${productId}-${index}`}
                                data-tip={`Livré ce jour-là si commandé: ${orderDayLabels.join(' ou ')}`}
                            >
                                {label}
                                {shouldDisplayTooltip ? (
                                    <ReactTooltip
                                        id={`stock-variation-${productId}-${index}`}
                                        disable={!orderDayLabels.length}
                                    />
                                ) : null}
                            </ProductsInfoStockVariationTableCell>
                        );
                    })}
                </tr>
                <tr>
                    {outboundStockVariation.map((stockDelta: number, index: number) => {
                        return (
                            <ProductsInfoStockDeltaTableCell key={index} color={colors.red}>
                                {stockDelta ? stockDelta : ''}
                            </ProductsInfoStockDeltaTableCell>
                        );
                    })}
                </tr>
                <tr>
                    {productSwitchDetails.variations.map((stockDelta: number, index: number) => {
                        return (
                            <ProductsInfoStockDeltaTableCell key={index} color={colors.violet}>
                                {stockDelta ? stockDelta : ''}
                            </ProductsInfoStockDeltaTableCell>
                        );
                    })}
                </tr>
                <tr>
                    <ProductsInfoStockDeltaTableCell color={colors.orange}>
                        {missingStockBetweenDeliveries ? `-${missingStockBetweenDeliveries}` : ''}
                    </ProductsInfoStockDeltaTableCell>
                </tr>
                <tr>
                    {stockVariationFromSupplyOrders.map((stockDelta: number, index: number) => {
                        return (
                            <ProductsInfoStockDeltaTableCell key={index} color={colors.blue}>
                                {stockDelta ? `+${stockDelta}` : ''}
                            </ProductsInfoStockDeltaTableCell>
                        );
                    })}
                </tr>
                <tr>
                    {stockVariation.map((stockVariationValue: number, index: number) => {
                        return (
                            <ProductsInfoStockVariationTableCell key={index}>
                                {stockVariationValue}
                            </ProductsInfoStockVariationTableCell>
                        );
                    })}
                </tr>
            </tbody>
        </Container>
    );
};

const Container = styled.table`
    border-collapse: collapse;
`;

const ProductsInfoStockVariationTableCell = styled.td<{ canBeDelivered?: boolean }>`
    font-size: 12px;
    text-align: center;
    padding: 0 5px;
    color: ${({ theme, canBeDelivered }) => (canBeDelivered ? theme.textColor : theme.infoTextColor)};
`;

const ProductsInfoStockDeltaTableCell = styled.td`
    text-align: center;
    font-size: 10px;
    color: ${({ color }) => color};
`;

import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { FaSave } from 'react-icons/fa';
import { toast } from 'react-toastify';

import styled from 'styled-components';

import { PAGES } from 'constants/pages';

import { useGetDetailedCategoryQuery, useUpdateCategoryMutation } from 'data/__generated__';

import { GeneralInfoSection } from 'pages/Categories/CategoryDetails/GeneralInfoSection';
import { StatesSection } from 'pages/Categories/CategoryDetails/StatesSection';

import { Loader, LoaderModeType } from 'components/Loader';
import { Header, HeaderTitle } from 'components/Header';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { PageTitle } from 'components/PageTitle';
import { SubcategoriesSection } from './SubcategoriesSection';

type FormData = {
    image: File | null;
    title: string;
    indexDisplay: number;
    isPunctual: boolean;
    isFreefood: boolean;
    isMicrostore: boolean;
    shouldShowSubcategories: boolean;
};

export const CategoryDetails = () => {
    const { categoryId = '' }: { categoryId?: string } = useParams();

    const methods = useForm<FormData>();
    const {
        formState: { isDirty },
        handleSubmit,
        reset,
    } = methods;

    const {
        data: categoryData,
        loading: categoryLoading,
        error: categoryError,
    } = useGetDetailedCategoryQuery({
        variables: {
            categoryId,
        },
    });

    const [updateCategory, { loading: updateLoading }] = useUpdateCategoryMutation();

    if (categoryLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (categoryError || !categoryData) {
        throw new Error('Une erreur est survenue lors de la récupération de la catégorie');
    }

    const { detailedCategory } = categoryData;

    if (!detailedCategory) {
        throw new Error(`Aucun produit avec l'id ${categoryId} n'a été trouvé`);
    }

    const handleFormSubmit = handleSubmit(async (fields) => {
        const { data } = await updateCategory({
            variables: {
                categoryId,
                fields,
            },
        });

        if (data) {
            const {
                updateCategory: { success, error },
            } = data;
            if (success) {
                toast.success(`La catégorie "${fields.title}" a bien été modifiée`);
                reset(fields);
            } else {
                if (error) {
                    toast.error(error);
                } else {
                    throw Error("Une erreur inconnue s'est produite");
                }
            }
        } else {
            throw Error("Une erreur inconnue s'est produite");
        }
    });

    return (
        <Container>
            <FormProvider {...methods}>
                <Form onSubmit={handleFormSubmit}>
                    <Header>
                        <HeaderTitle>
                            <PageTitle page={PAGES.categoryDetails} />
                        </HeaderTitle>
                        <CTAsContainer>
                            <TotemPrimaryButton minWidth="140px" type="submit" disabled={!isDirty}>
                                {updateLoading ? (
                                    <Loader size="18px" mode={LoaderModeType.Spin} />
                                ) : (
                                    <>
                                        <FaSave size={13} />
                                        <SaveLabel>Mettre à jour</SaveLabel>
                                    </>
                                )}
                            </TotemPrimaryButton>
                            <ButtonLink to="/categories">
                                <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                            </ButtonLink>
                        </CTAsContainer>
                    </Header>
                    <Content>
                        <ScrollableContent>
                            <GeneralInfoSection category={detailedCategory} />
                            <StatesSection category={detailedCategory} isDirty={isDirty} />
                            <SubcategoriesSection categoryId={categoryId} />
                        </ScrollableContent>
                    </Content>
                </Form>
            </FormProvider>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
`;

const Content = styled.div`
    flex: 1;
    overflow: hidden;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const ScrollableContent = styled.div`
    padding: 15px;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

const ButtonLink = styled(Link)`
    margin-left: 5px;
`;

const CTAsContainer = styled.div`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.textColor};

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const SaveLabel = styled.span`
    margin-left: 5px;
`;

import { SUPPLIER_PARTNERSHIP_FRAGMENT } from 'data/fragments/supplierPartnership';
import gql from 'graphql-tag';

export const CREATE_SUPPLIER_PARTNERSHIP_MUTATION = gql`
    mutation CREATE_SUPPLIER_PARTNERSHIP($supplierPartnershipCreateInput: SupplierPartnershipCreateInput!) {
        createSupplierPartnership(supplierPartnershipCreateInput: $supplierPartnershipCreateInput) {
            ...SupplierPartnershipFragment
        }
    }
    ${SUPPLIER_PARTNERSHIP_FRAGMENT}
`;

export const UPDATE_SUPPLIER_PARTNERSHIP_MUTATION = gql`
    mutation UPDATE_SUPPLIER_PARTNERSHIP($supplierPartnershipUpdateInput: SupplierPartnershipUpdateInput!) {
        updateSupplierPartnership(supplierPartnershipUpdateInput: $supplierPartnershipUpdateInput) {
            ...SupplierPartnershipFragment
        }
    }
    ${SUPPLIER_PARTNERSHIP_FRAGMENT}
`;

import React, { useState } from 'react';
import styled from 'styled-components';
import { FaSave } from 'react-icons/fa';
import { toast } from 'react-toastify';

import { SubcategoryFragment, SubcategoryState, useUpdateSubcategoryMutation } from 'data/__generated__';

import { TotemInput } from 'components/TotemInput';
import { TotemSelect } from 'components/TotemSelect';

export const SubcategoryDetails = ({ subcategory }: { subcategory: SubcategoryFragment }) => {
    const { _id: subcategoryId, displayIndex, name, state } = subcategory;

    const [subcategoryForm, setSubcategoryForm] = useState({ displayIndex, name, state });

    const [updateSubcategoryMutation] = useUpdateSubcategoryMutation();

    const stateOptions = [
        {
            value: SubcategoryState.Regular,
            label: 'Regular',
        },
        {
            value: SubcategoryState.Archived,
            label: 'Archivée',
        },
    ];

    const selectedStateOption = stateOptions.find((option) => option.value === subcategoryForm.state);

    const isArchived = subcategoryForm.state === SubcategoryState.Archived;

    async function updateSubcategory() {
        const { data } = await updateSubcategoryMutation({
            variables: { subcategoryId, updateInput: subcategoryForm },
        });

        if (data) {
            const {
                updateSubcategory: { errors, subcategory },
            } = data;

            if (subcategory) {
                toast.success(`La sous-catégorie "${subcategory.name}" a bien été modifiée`);
            }

            if (errors) {
                errors.forEach((error) => toast.error(error));
            }
        }
    }

    return (
        <TableRow>
            <TableCell isGreyed={isArchived}>{subcategoryId}</TableCell>
            <TableCell isGreyed={isArchived}>
                <TotemInput
                    value={subcategoryForm.name}
                    onChange={(value) => setSubcategoryForm({ ...subcategoryForm, name: value })}
                />
            </TableCell>
            <TableCell isGreyed={isArchived}>
                <TotemInput
                    value={subcategoryForm.displayIndex}
                    onChange={(value) => setSubcategoryForm({ ...subcategoryForm, displayIndex: Number(value) })}
                />
            </TableCell>
            <td>
                <TotemSelect
                    value={selectedStateOption}
                    options={stateOptions}
                    onChange={(option) => {
                        if (option) {
                            setSubcategoryForm({ ...subcategoryForm, state: option.value });
                        }
                    }}
                />
            </td>
            <TableCell isCentered>
                <Button onClick={updateSubcategory}>
                    <FaSave />
                </Button>
            </TableCell>
        </TableRow>
    );
};

const TableRow = styled.tr`
    color: ${({ theme }) => theme.textColor};
`;

const TableCell = styled.td<{ isGreyed?: boolean; isCentered?: boolean }>`
    opacity: ${({ isGreyed }) => (isGreyed ? 0.5 : 1)};
    text-align: ${({ isCentered }) => (isCentered ? 'center' : 'left')};
`;

const Button = styled.div`
    cursor: pointer;
`;

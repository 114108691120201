import gql from 'graphql-tag';

export const PRODUCT_ARRANGEMENT_PRODUCT_FRAGMENT = gql`
    fragment ProductArrangementProductFragment on Product {
        _id
        brand
        fullname
        height
        imageUrl
        isMicrostore
        length
        name
        state
        volume
        weight
        width
    }
`;

export const PRODUCT_ARRANGEMENT_FRAGMENT = gql`
    fragment ProductArrangementFragment on ProductArrangement {
        _id
        name
        productIdsWithStockMax {
            productId
            stockMaxUnsecured
            stockMaxSensei
        }
        reassortmentType
        defaultStockMaxValues {
            stockMaxUnsecured
            stockMaxSensei
        }
    }
`;

export const PRODUCT_ARRANGEMENT_WITH_PRODUCTS_FRAGMENT = gql`
    fragment ProductArrangementWithProductsFragment on ProductArrangement {
        _id
        name
        productsWithStockMax {
            product {
                ...ProductArrangementProductFragment
            }
            stockMaxUnsecured
            stockMaxSensei
        }
        reassortmentType
    }
    ${PRODUCT_ARRANGEMENT_PRODUCT_FRAGMENT}
`;

import React, { useCallback, useState } from 'react';

import styled from 'styled-components';

import { siteVariationsColors, siteVariationsLabels } from './constants';
import { GET_SITE_PRODUCTS_WITH_LATEST_STOCKS_getProductsWithLatestVariations_variations } from 'data/queries/__generated__/GET_SITE_PRODUCTS_WITH_LATEST_STOCKS';
import { StockVariationFragment } from 'data/fragments/__generated__/StockVariationFragment';

export const StockVariationList = ({
    lastSiteInventoryStock,
    variations,
}: {
    lastSiteInventoryStock?: number;
    variations: (
        | GET_SITE_PRODUCTS_WITH_LATEST_STOCKS_getProductsWithLatestVariations_variations
        | StockVariationFragment
    )[];
}) => {
    const [infos, setInfos] = useState<Record<string, any> | null>(null);

    const formatVariationInfo = useCallback((json: Record<string, string>) => {
        const lineInfos = [];

        for (const i in json) {
            if (!i.match('_') && json[i]) {
                if (i === 'createdAt') {
                    lineInfos.push(
                        <div>
                            <span>Jour de création : </span>
                            <span>{new Date(json[i]).toLocaleDateString()}</span>
                        </div>,
                    );
                    lineInfos.push(
                        <div>
                            <span>Heure de création : </span>
                            <span>{new Date(json[i]).toLocaleTimeString()}</span>
                        </div>,
                    );

                    continue;
                }

                lineInfos.push(
                    <div>
                        <span>{i} : </span>
                        <span>{json[i]}</span>
                    </div>,
                );
            }
        }

        return <Info style={{ backgroundColor: json._bgColor }}>{lineInfos}</Info>;
    }, []);

    let value = lastSiteInventoryStock || 0;

    return (
        <Container>
            {infos ? formatVariationInfo(infos) : null}
            {variations.map((variation) => {
                value = value + variation.delta;
                const bgColor = siteVariationsColors[variation.type];
                const infos = {
                    createdAt: variation.createdAt,
                    Type: siteVariationsLabels[variation.type],
                    Variation: variation.delta,
                    ...(lastSiteInventoryStock && { Valeur: value }),
                    Explication: variation.explanation,
                    Référence: variation.ref,
                    _bgColor: bgColor,
                };

                return (
                    <QuantityVariationInnerContainer
                        key={variation._id}
                        onMouseEnter={() => setInfos(infos)}
                        onMouseLeave={() => setInfos(null)}
                    >
                        <QuantityVariationInnerValue bgColor={bgColor}>{variation.delta}</QuantityVariationInnerValue>
                    </QuantityVariationInnerContainer>
                );
            })}
        </Container>
    );
};

const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: row;
    flex: 1;
`;

const QuantityVariationInnerContainer = styled.div``;

const QuantityVariationInnerValue = styled.div`
    cursor: pointer;
    margin-left: 10px;
    background-color: ${({ bgColor }: { bgColor?: string }) => bgColor};
    color: ${({ bgColor }: { bgColor?: string }) => (bgColor === '#FFFFFF' ? '#111111' : '#FFFFFF')};
    border-radius: 3px;
    padding: 5px;
`;

const Info = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    margin: 10px;
    padding: 10px;
    background: ${({ backgroundColor, theme }: { backgroundColor?: string; theme: any }) =>
        backgroundColor ? backgroundColor : theme.menuBackgroundColor};
    color: ${({ theme }) => theme.menuTextColor};
    z-index: 1000;
`;

import React, { useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { CREATE_MICROSTORE_COLUMN_GROUP_MUTATION } from 'data/mutations/microstoreColumnGroup';
import {
    CREATE_MICROSTORE_COLUMN_GROUP,
    CREATE_MICROSTORE_COLUMN_GROUPVariables,
} from 'data/mutations/__generated__/CREATE_MICROSTORE_COLUMN_GROUP';

import { Loader, LoaderModeType } from 'components/Loader';
import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { TotemSelect } from 'components/TotemSelect';
import { GET_MICROSTORE_SITES_microstoreSitesWithColumnGroups } from 'data/queries/__generated__/GET_MICROSTORE_SITES';
import { TotemInput } from 'components/TotemInput';

export function CreateMicrostoreColumnGroupPopup({
    isOpen,
    setIsOpen,
    site,
}: {
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
    site: GET_MICROSTORE_SITES_microstoreSitesWithColumnGroups;
}) {
    const [positionInSite, setPositionInSite] = useState<number>(site.microstoreColumnGroups.length);
    const [groupName, setGroupName] = useState<string>('');

    useEffect(() => {
        setPositionInSite(site.microstoreColumnGroups.length);
    }, [setPositionInSite, site]);

    const [createMicrostoreColumnGroup, { loading: creationLoading }] = useMutation<
        CREATE_MICROSTORE_COLUMN_GROUP,
        CREATE_MICROSTORE_COLUMN_GROUPVariables
    >(CREATE_MICROSTORE_COLUMN_GROUP_MUTATION);

    async function handleSubmit() {
        if (!groupName) {
            return;
        }

        const { data } = await createMicrostoreColumnGroup({
            variables: {
                createMicrostoreColumnGroupInput: {
                    name: groupName,
                    positionInSite,
                    siteId: site._id,
                },
            },
        });

        if (!data) {
            throw new Error('Une erreur est survenue lors de la creation du template de colonne');
        }

        const {
            createMicrostoreColumnGroupMutation: { errors },
        } = data;

        if (errors.length) {
            errors.forEach((error, index) => {
                toast.error(<span key={index}>Erreur : {error}</span>, { autoClose: false });
            });

            if (errors.length > 1) {
                toast.info('Cliquez pour fermer toutes les notifications', {
                    autoClose: false,
                    onClick: () => toast.dismiss(),
                });
            }
        }

        setIsOpen(false);
    }

    const positionInSiteOptions = Array(site.microstoreColumnGroups.length + 1)
        .fill(undefined)
        .map((_, index) => ({
            value: index,
            label: `${index + 1}`,
        }));

    return (
        <TotemPopup
            title="Ajouter un groupement de colonne"
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            contentOverflow="visible"
        >
            <SubmitContainer>
                <TotemSelect<number>
                    label="Position du groupe"
                    placeholder="Sélectionner la position du groupe"
                    value={positionInSiteOptions.find(
                        (positionInSiteOption) => positionInSite === positionInSiteOption.value,
                    )}
                    options={positionInSiteOptions}
                    onChange={(option) => {
                        if (option) {
                            setPositionInSite(option.value);
                        }
                    }}
                />
                <TotemInput
                    label="Nom du groupe"
                    placeholder="3e étage, cuisine etc..."
                    value={groupName}
                    onChange={(value) => setGroupName(value)}
                />
                <TotemPrimaryButton onClick={handleSubmit} disabled={!groupName}>
                    {creationLoading ? <Loader size="20px" mode={LoaderModeType.Spin} /> : 'Confirmer'}
                </TotemPrimaryButton>
            </SubmitContainer>
        </TotemPopup>
    );
}

const SubmitContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 15px;

    > :not(:first-child) {
        margin-top: 15px;
    }
`;

import { GET_DETAILED_SUPPLIER_detailedSupplier_orderingTimes } from 'data/queries/__generated__/GET_DETAILED_SUPPLIER';
import { SupplierOrderingTimesInput } from 'data/__generated__';

export function getOrderingTimesDay({
    orderingTimes,
    name,
}: {
    orderingTimes: GET_DETAILED_SUPPLIER_detailedSupplier_orderingTimes | SupplierOrderingTimesInput;
    name: string;
}) {
    switch (name) {
        case 'monday':
            return orderingTimes.monday;
        case 'tuesday':
            return orderingTimes.tuesday;
        case 'wednesday':
            return orderingTimes.wednesday;
        case 'thursday':
            return orderingTimes.thursday;
        case 'friday':
            return orderingTimes.friday;
        case 'saturday':
            return orderingTimes.saturday;
        case 'sunday':
            return orderingTimes.sunday;
        default:
            throw Error(`Pas de heure de commande pour jour ${name}`);
    }
}

import React, { ReactNode, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import type { FileWithPath, FileError } from 'react-dropzone';

import styled from 'styled-components';

export type FileWithPreview = FileWithPath & { preview: string };

/**
 * Drag and drop files component
 *
 * @param multiple - Allow drag 'n' drop (or selection from the file dialog) of multiple files
 * @param fileTypes - Set accepted file types (has to be MIME type, all listed here : https://www.iana.org/assignments/media-types/media-types.xhtml)
 *
 * @author [Benjamin Vaysse](https://github.com/benjamin-vaysse)
 */
export const FileDragAndDrop = ({
    multiple,
    fileTypes,
    hasPadding = true,
    hasBackground = true,
    onFileDropCallback,
    fileValidationFunction,
    children,
}: {
    multiple: boolean;
    fileTypes: string[];
    hasBackground?: boolean;
    hasPadding?: boolean;
    onFileDropCallback: (files: FileWithPreview[] | null) => void;
    fileValidationFunction?: (file: FileWithPreview) => Promise<FileError[]>;
    children?: ReactNode;
}) => {
    const [acceptedFiles, setAcceptedFiles] = useState<null | FileWithPath[]>(null);
    const [rejectedFiles, setRejectedFiles] = useState<null | { file: FileWithPath; errors: FileError[] }[]>(null);

    const onDrop = useCallback(
        async (acceptedFiles: FileWithPath[], rejectedFiles: { file: FileWithPath; errors: FileError[] }[]) => {
            const acceptedFilesWithPreview = acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                }),
            );
            const acceptedFilesWithValidationErrors = [];
            for (const acceptedFileWithPreview of acceptedFilesWithPreview) {
                acceptedFilesWithValidationErrors.push({
                    file: acceptedFileWithPreview,
                    errors: fileValidationFunction ? await fileValidationFunction(acceptedFileWithPreview) : [],
                });
            }

            setAcceptedFiles(
                acceptedFilesWithValidationErrors.filter(({ errors }) => !errors.length).map(({ file }) => file),
            );

            const nonValidAcceptedFiles = acceptedFilesWithValidationErrors.filter(({ errors }) => errors.length);
            setRejectedFiles([...rejectedFiles, ...nonValidAcceptedFiles]);

            onFileDropCallback(acceptedFilesWithPreview.length > 0 ? acceptedFilesWithPreview : null);
        },
        [fileValidationFunction, onFileDropCallback],
    );
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: multiple,
        accept: fileTypes,
    });
    return (
        <>
            <DragAndDropContainer {...getRootProps()} hasPadding={hasPadding} hasBackground={hasBackground}>
                <input {...getInputProps()} />
                {children ? (
                    children
                ) : (
                    <>
                        {isDragActive ? (
                            <p>Glisser le/les fichiers ici</p>
                        ) : (
                            <p>Glisser le/les fichiers ici, ou cliquez pour sélectionner un/des fichiers.</p>
                        )}
                        <em>
                            (Seuls les fichiers de type <strong>{fileTypes.join(', ')}</strong> seront acceptés)
                        </em>
                    </>
                )}
            </DragAndDropContainer>
            <Feedback>
                {acceptedFiles && acceptedFiles.length > 0 ? (
                    <>
                        <h4>Fichier(s) prêt(s) à être uploadé(s)</h4>
                        <ul>
                            {acceptedFiles.map((file) => (
                                <li key={file.path}>
                                    ✅ {file.path} - {file.size} bytes
                                </li>
                            ))}
                        </ul>
                    </>
                ) : null}
                {rejectedFiles && rejectedFiles.length > 0 ? (
                    <>
                        <h4>Fichier(s) refusé(s)</h4>
                        <ul>
                            {rejectedFiles.map(({ file, errors }: { file: FileWithPath; errors: Array<FileError> }) => (
                                <li key={file.path}>
                                    ❌ {file.path} - {file.size} bytes
                                    <ul>
                                        {errors.map((e) => (
                                            <li key={e.code}>{e.message}</li>
                                        ))}
                                    </ul>
                                </li>
                            ))}
                        </ul>
                    </>
                ) : null}
            </Feedback>
        </>
    );
};

const DragAndDropContainer = styled.div<{ hasPadding?: boolean; hasBackground: boolean }>`
    display: flex;
    flex-direction: column;
    padding: ${({ hasPadding }) => (hasPadding ? '10px 20px 20px 20px' : 0)};
    border: ${({ hasPadding, theme }) => (hasPadding ? `2px dashed ${theme.lightBorderColor}` : 0)};
    outline: none;
    transition: border 0.24s ease-in-out;
    background-color: ${({ hasBackground }) => (hasBackground ? '#fafafa' : 'transparent')};
    color: #bdbdbd;
    &:focus {
        border-color: #2196f3;
    }
`;

const Feedback = styled.aside`
    color: ${({ theme }) => theme.textColor};
`;

import React from 'react';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { GET_PRODUCT_PRICE_RANGES } from 'data/queries/__generated__/GET_PRODUCT_PRICE_RANGES';
import { GET_PRODUCT_PRICE_RANGES_QUERY } from 'data/queries/price';
import { PAGES } from '../../constants/pages';

import { PageTitle } from 'components/PageTitle';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { Header, HeaderTitle } from 'components/Header';
import { FieldToDisplay, ListView } from 'components/ListView';
import { Loader } from 'components/Loader';

type ProductPriceRangeTable = {
    _id: string;
    name: string;
};

export const ProductPriceRanges = () => {
    const {
        loading: productRangesLoading,
        error: productRangesError,
        data: productRangesData,
    } = useQuery<GET_PRODUCT_PRICE_RANGES>(GET_PRODUCT_PRICE_RANGES_QUERY, { pollInterval: 5000 });

    const RANGES_FIELDS_TO_DISPLAY: FieldToDisplay<ProductPriceRangeTable>[] = [
        {
            fieldName: 'name',
            label: 'Nom',
        },
    ];

    if (productRangesLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (productRangesError) {
        toast.error('Une erreur est survenue lors de la récupération des produits non-archivés ');
        return null;
    }

    const priceRanges = productRangesData?.productPriceRanges;

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.productPriceRanges} />
                </HeaderTitle>
                <Link to="/productPriceRange/create">
                    <TotemPrimaryButton>Créer une gamme</TotemPrimaryButton>
                </Link>
            </Header>
            <Content>
                {priceRanges?.length ? (
                    <ListView<ProductPriceRangeTable>
                        fieldsToDisplay={RANGES_FIELDS_TO_DISPLAY}
                        items={priceRanges}
                        linkBasePath="/productPriceRange/"
                        keyExtractor={(item) => item._id}
                    />
                ) : (
                    "Aucune gamme n'a encore été rajoutée"
                )}
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
`;

const Content = styled.div`
    padding: 15px;
    flex: 1;
    overflow: hidden;
    background: ${({ theme }) => theme.backgroundColor};
`;

import React from 'react';

import styled from 'styled-components';

import { SelectedOption, TotemSelect } from 'components/TotemSelect';
import { LocationEditor } from './LocationEditor';

import { MicrostoreColumnTemplateFormValues, MicrostoreLocationTemplateFormValues } from './FormHelper/DataMapper';
import { computeColorFromName } from 'helpers/computeColorFromName';

const SHELF_BACKGROUND_COLOR = ['#fff7ef', '#f3ffef', '#eff6ff'];

export const ColumnShelfLocationSimpleEditor = ({
    microstoreColumnTemplateFormValues,
    setMicrostoreColumnTemplateFormValuesAndIsFormDirty,
    selectedShelfTemplateIndex,
    setSelectedShelfTemplateIndex,
    selectedLocationTemplatePosition,
    setSelectedLocationTemplatePosition,
}: {
    microstoreColumnTemplateFormValues: MicrostoreColumnTemplateFormValues;
    setMicrostoreColumnTemplateFormValuesAndIsFormDirty: ({
        newMicrostoreColumnTemplateFormValues,
        newIsDirty,
    }: {
        newMicrostoreColumnTemplateFormValues: MicrostoreColumnTemplateFormValues;
        newIsDirty?: boolean | undefined;
    }) => void;
    selectedShelfTemplateIndex: number | null;
    setSelectedShelfTemplateIndex: (value: React.SetStateAction<number | null>) => void;
    selectedLocationTemplatePosition: {
        column: number;
        row: number;
    } | null;
    setSelectedLocationTemplatePosition: (
        value: React.SetStateAction<{
            column: number;
            row: number;
        } | null>,
    ) => void;
}) => {
    function setShelvesOfColumn(numberOfShelves: number) {
        setSelectedShelfTemplateIndex(null);
        setSelectedLocationTemplatePosition(null);
        const newShelfTemplates = [];
        for (let i = 0; i < numberOfShelves; i++) {
            newShelfTemplates.push({ _id: null, locationTemplateTable: [] });
        }
        setMicrostoreColumnTemplateFormValuesAndIsFormDirty({
            newMicrostoreColumnTemplateFormValues: {
                ...microstoreColumnTemplateFormValues,
                shelfTemplates: newShelfTemplates,
            },
        });
    }

    function setLocationsOfShelf(shelfIndex: number, numberOfColumn: number, numberOfRow: number) {
        setSelectedLocationTemplatePosition(null);
        const newLocationTemplateTable: MicrostoreLocationTemplateFormValues[][] = [];

        for (let rowIndex = 0; rowIndex < numberOfRow; rowIndex++) {
            const newLocationTemplateRow: MicrostoreLocationTemplateFormValues[] = [];
            for (let columnIndex = 0; columnIndex < numberOfColumn; columnIndex++) {
                newLocationTemplateRow.push({
                    _id: null,
                    allowProductRotation: false,
                    productArrangement: null,
                    productArrangementSingleProductInfo: null,
                    shouldOrderProductIfNotInSite: false,
                    shouldSmoothProductTransition: true,
                });
            }
            newLocationTemplateTable.push(newLocationTemplateRow);
        }

        const newShelfTemplates = [...microstoreColumnTemplateFormValues.shelfTemplates];
        newShelfTemplates[shelfIndex] = {
            ...selectedShelfTemplate,
            locationTemplateTable: newLocationTemplateTable,
        };
        setMicrostoreColumnTemplateFormValuesAndIsFormDirty({
            newMicrostoreColumnTemplateFormValues: {
                ...microstoreColumnTemplateFormValues,
                shelfTemplates: newShelfTemplates,
            },
        });
    }

    const numberOfShelvesOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8].map((numberOfShelves) => ({
        value: numberOfShelves,
        label: `${numberOfShelves} étagère${numberOfShelves > 1 ? 's' : ''}`,
    }));

    type ShelfConfigurationOptionValue = { numberOfColumn: number; numberOfRow: number };

    const shelfConfigurationOptions = [1, 2, 3, 4, 5, 10].flatMap((numberOfColumn) =>
        [1, 2, 3, 4].map((numberOfRow) => ({
            value: {
                numberOfColumn,
                numberOfRow,
            },
            label: `${numberOfColumn} colonne${numberOfColumn > 1 ? 's' : ''} * ${numberOfRow} ligne${
                numberOfRow > 1 ? 's' : ''
            }`,
        })),
    );

    const selectedShelfTemplate =
        selectedShelfTemplateIndex !== null
            ? microstoreColumnTemplateFormValues.shelfTemplates[selectedShelfTemplateIndex]
            : null;

    const selectedShelfConfigurationOption =
        shelfConfigurationOptions.find(
            (shelfConfigurationOption) =>
                shelfConfigurationOption.value.numberOfRow === selectedShelfTemplate?.locationTemplateTable.length &&
                selectedShelfTemplate?.locationTemplateTable.every(
                    (locationTemplateRow) =>
                        shelfConfigurationOption.value.numberOfColumn === locationTemplateRow.length,
                ),
        ) ?? null;

    return (
        <Container>
            <ColumnShelfSimpleEditorContainer>
                <TotemSelect<number>
                    placeholder="Nombre d'étagères"
                    value={
                        numberOfShelvesOptions.find(
                            (numberOfShelvesOption) =>
                                numberOfShelvesOption.value ===
                                microstoreColumnTemplateFormValues.shelfTemplates.length,
                        ) ?? null
                    }
                    options={numberOfShelvesOptions}
                    onChange={(option: SelectedOption<number>) => {
                        if (option) {
                            setShelvesOfColumn(option.value);
                        }
                    }}
                />
                <Column>
                    {microstoreColumnTemplateFormValues.shelfTemplates.map((shelfTemplate, shelfIndex) => {
                        return (
                            <Shelf
                                key={shelfIndex}
                                isSelected={
                                    selectedShelfTemplateIndex === shelfIndex &&
                                    selectedLocationTemplatePosition === null
                                }
                                color={SHELF_BACKGROUND_COLOR[shelfIndex % SHELF_BACKGROUND_COLOR.length]}
                            >
                                <ShelfSelectionZone
                                    isSelected={
                                        selectedShelfTemplateIndex === shelfIndex &&
                                        selectedLocationTemplatePosition === null
                                    }
                                    onClick={() => {
                                        setSelectedShelfTemplateIndex(shelfIndex);
                                        setSelectedLocationTemplatePosition(null);
                                    }}
                                />
                                {shelfTemplate.locationTemplateTable.map((locationTemplateRow, locationRowIndex) => {
                                    return (
                                        <LocationRow key={locationRowIndex}>
                                            {locationTemplateRow.map((locationTemplate, locationColumnIndex) => {
                                                const { productArrangement, productArrangementSingleProductInfo } =
                                                    locationTemplate;
                                                return (
                                                    <Location
                                                        key={locationColumnIndex}
                                                        isSelected={
                                                            shelfIndex === selectedShelfTemplateIndex &&
                                                            locationColumnIndex ===
                                                                selectedLocationTemplatePosition?.column &&
                                                            locationRowIndex === selectedLocationTemplatePosition?.row
                                                        }
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            setSelectedShelfTemplateIndex(shelfIndex);
                                                            setSelectedLocationTemplatePosition({
                                                                column: locationColumnIndex,
                                                                row: locationRowIndex,
                                                            });
                                                        }}
                                                    >
                                                        {productArrangement ? (
                                                            <>
                                                                <LocationProductArrangementColor
                                                                    color={computeColorFromName(
                                                                        productArrangement.name,
                                                                    )}
                                                                />
                                                                <LocationProductArrangementName>
                                                                    {productArrangement.name}
                                                                </LocationProductArrangementName>
                                                                <LocationNumberOfProduct>
                                                                    {productArrangement.productsWithStockMax.length}{' '}
                                                                    produit(s)
                                                                </LocationNumberOfProduct>
                                                            </>
                                                        ) : null}

                                                        {productArrangementSingleProductInfo ? (
                                                            <>
                                                                <LocationProductArrangementColor
                                                                    color={computeColorFromName(
                                                                        productArrangementSingleProductInfo.product
                                                                            .name,
                                                                    )}
                                                                />
                                                                <LocationProductArrangementName>
                                                                    {`${productArrangementSingleProductInfo.product.name} - ${productArrangementSingleProductInfo.product.brand} - ${productArrangementSingleProductInfo.product.volume}`}
                                                                </LocationProductArrangementName>
                                                                <LocationNumberOfProduct>
                                                                    Produit seul
                                                                </LocationNumberOfProduct>
                                                            </>
                                                        ) : null}
                                                    </Location>
                                                );
                                            })}
                                        </LocationRow>
                                    );
                                })}
                            </Shelf>
                        );
                    })}
                </Column>
                <BottomSelectorContainer>
                    {selectedShelfTemplateIndex === null ? (
                        <SelectShelfOrLocationLabel>
                            Sélectionner une étagère ou un emplacement.
                        </SelectShelfOrLocationLabel>
                    ) : selectedLocationTemplatePosition === null ? (
                        <ShelfSelectContainer>
                            {/* @ts-expect-error */}
                            <TotemSelect<ShelfConfigurationOptionValue>
                                menuPlacement="auto"
                                label="Choisir une configuration d'étagère"
                                placeholder="Configuration de l'étagère"
                                value={selectedShelfConfigurationOption}
                                options={shelfConfigurationOptions}
                                onChange={(option: SelectedOption<ShelfConfigurationOptionValue>) => {
                                    if (option) {
                                        setLocationsOfShelf(
                                            selectedShelfTemplateIndex,
                                            option.value.numberOfColumn,
                                            option.value.numberOfRow,
                                        );
                                    }
                                }}
                            />
                        </ShelfSelectContainer>
                    ) : (
                        <LocationEditor
                            displayProductArrangement={false}
                            microstoreColumnTemplateFormValues={microstoreColumnTemplateFormValues}
                            selectedShelfTemplateIndex={selectedShelfTemplateIndex}
                            selectedLocationTemplatePosition={selectedLocationTemplatePosition}
                            setMicrostoreColumnTemplateFormValuesAndIsFormDirty={
                                setMicrostoreColumnTemplateFormValuesAndIsFormDirty
                            }
                        />
                    )}
                </BottomSelectorContainer>
            </ColumnShelfSimpleEditorContainer>
        </Container>
    );
};

const Container = styled.div`
    // we compute the height with vh because we want the column to be visible while taking the maximum height
    min-height: calc(100vh - 200px);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
`;

const ColumnShelfSimpleEditorContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    flex-grow: 1;
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
    border-radius: ${({ theme }) => theme.borderRadius};
    padding: 10px;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

const Shelf = styled.div<{ isSelected: boolean; color: string }>`
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    border: 1px solid ${({ theme, isSelected }) => (isSelected ? theme.ctaPrimaryColor : theme.darkBorderColor)};
    box-shadow: ${({ theme, isSelected }) => (isSelected ? theme.boxShadow : 'none')};
    border-radius: ${({ theme }) => theme.borderRadius};
    padding: 5px 40px 5px 5px;
    background-color: ${({ color }) => color};

    & > :not(:first-child) {
        margin-top: 5px;
    }
`;

const ShelfSelectionZone = styled.div<{ isSelected: boolean }>`
    position: absolute;
    cursor: pointer;
    top: 0;
    bottom: 0;
    right: 0;
    width: 30px;
    background-color: ${({ theme, isSelected }) => (isSelected ? theme.ctaPrimaryColor : theme.lightBorderColor)};
`;

const LocationRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 1;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const Location = styled.div<{ isSelected: boolean }>`
    cursor: pointer;
    flex-grow: 1;
    border: 1px solid ${({ theme, isSelected }) => (isSelected ? theme.ctaPrimaryColor : theme.lightBorderColor)};
    box-shadow: ${({ theme, isSelected }) => (isSelected ? theme.boxShadow : 'none')};
    border-radius: ${({ theme }) => theme.borderRadius};
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const LocationProductArrangementName = styled.div`
    text-align: center;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const LocationProductArrangementColor = styled.div`
    align-self: flex-start;
    width: 10px;
    height: 10px;
    background-color: ${({ theme, color }) => color ?? theme.textColor};
`;

const LocationNumberOfProduct = styled.div`
    align-self: flex-end;
    font-size: 12px;
`;

const BottomSelectorContainer = styled.div`
    margin-top: 15px;
`;

const SelectShelfOrLocationLabel = styled.div`
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
    padding: 15px;
    text-align: center;
    font-weight: 800;
`;

const ShelfSelectContainer = styled.div`
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
    padding: 15px;
`;

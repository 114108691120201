import gql from 'graphql-tag';
import { SUPPLIER_FRAGMENT } from 'data/fragments/supplier';

export const SUPPLIER_UPDATED_SUBSCRIPTION = gql`
    subscription SUPPLIER_UPDATED {
        supplierUpdated {
            ...SupplierFragment
        }
    }
    ${SUPPLIER_FRAGMENT},
`;

import { BirthdayDate, transformFrenchToBirthDayDate } from 'helpers/dateTimes';
import {
    MicrostoreColumnFormValues,
    MicrostoreLocationFormValues,
    MicrostoreProductsStockInfoFormValues,
} from './DataMapper';

// to distribute evenly the product stock between the different location, each location will have at least the quotient of the euclidian division
// then we distribute the remainder to the first locations.
// Ex : if I have 8 products in stock, and 3 locations. Then each location will have at least 2 (8/3) product.
//      the remainder is 2, then the 2 first location will have one more product.
//      the stocks in the locations will be : 3, 3, 2
export function getRepartitionedLocationStock({
    locationId,
    stockToRepartition,
    locationsContainingProduct,
}: {
    locationId: string;
    stockToRepartition: number;
    locationsContainingProduct: MicrostoreLocationFormValues[];
}) {
    const locationIndex = locationsContainingProduct.findIndex(({ _id }) => _id === locationId);

    return (
        Math.floor(stockToRepartition / (locationsContainingProduct.length || 1)) +
        (locationIndex < stockToRepartition % locationsContainingProduct.length ? 1 : 0)
    );
}

export function getProductStockInLocation({
    locationId,
    allSiteLocations,
    microstoreProductsStockInfo,
    productId,
}: {
    allSiteLocations: MicrostoreLocationFormValues[];
    locationId: string;
    microstoreProductsStockInfo: MicrostoreProductsStockInfoFormValues | null;
    productId: string | null;
}) {
    // We use Math.max because on some rare occasion, the stock on site can be negative.
    // If it's negative we say it's 0.
    const productStockInSite = Math.max(
        0,
        microstoreProductsStockInfo?.find(({ _id }) => _id === productId)?.stockOnSite ?? 0,
    );
    const locationsWithSameProduct = allSiteLocations.filter((location) => productId === location.productId);

    return getRepartitionedLocationStock({
        locationId,
        stockToRepartition: productStockInSite,
        locationsContainingProduct: locationsWithSameProduct,
    });
}

export function getReplacementProductStockInLocation({
    locationId,
    allSiteLocations,
    microstoreProductsStockInfo,
    replacementProductId,
}: {
    allSiteLocations: MicrostoreLocationFormValues[];
    locationId: string;
    microstoreProductsStockInfo: MicrostoreProductsStockInfoFormValues | null;
    replacementProductId: string | null;
}) {
    // We use Math.max because on some rare occasion, the stock on site can be negative.
    // If it's negative we say it's 0.
    const replacementProductStockInSite = Math.max(
        0,
        microstoreProductsStockInfo?.find(({ _id }) => _id === replacementProductId)?.stockOnSite ?? 0,
    );
    const locationsWithSameReplacementProduct = allSiteLocations.filter(
        (location) => replacementProductId === location.replacementProductId,
    );

    return getRepartitionedLocationStock({
        locationId,
        stockToRepartition: replacementProductStockInSite,
        locationsContainingProduct: locationsWithSameReplacementProduct,
    });
}

export function getStockToRemoveInLocation({
    allSiteLocations,
    locationId,
    replacementProductId,
    microstoreProductsStockInfo,
    earliestDLCDateToKeepForStockCalculation,
}: {
    allSiteLocations: MicrostoreLocationFormValues[];
    locationId: string;
    replacementProductId: string | null;
    microstoreProductsStockInfo: MicrostoreProductsStockInfoFormValues | null;
    earliestDLCDateToKeepForStockCalculation: BirthdayDate | null;
}) {
    // if product changes location, it's expiryDates are in today's location and not kept for tomorrow's location, so we need to use those
    const todayProductLocation = allSiteLocations.find(({ productId }) => productId === replacementProductId);

    const stockOnSite = Math.max(
        0,
        microstoreProductsStockInfo?.find(({ _id }) => _id === replacementProductId)?.stockOnSite ?? 0,
    );

    // we currently incorrectly stock combined dlcs for product in every product location, so getting data for one location is enough
    let stockToRemove = 0;
    if (todayProductLocation) {
        const stockToKeep = todayProductLocation.expiryDates.reduce(
            (stockToKeep, { expiryDate, quantity }) =>
                stockToKeep +
                (!!earliestDLCDateToKeepForStockCalculation &&
                transformFrenchToBirthDayDate(expiryDate) >= earliestDLCDateToKeepForStockCalculation
                    ? quantity
                    : 0),
            0,
        );

        const quantitiesWithDLC = todayProductLocation.expiryDates.reduce((stock, { quantity }) => stock + quantity, 0);
        const quantitiesMissingInDLC = Math.max(stockOnSite - quantitiesWithDLC, 0);

        stockToRemove = Math.max(stockOnSite - stockToKeep - quantitiesMissingInDLC, 0);
    }

    const tomorrowProductLocations = allSiteLocations.filter(
        (location) => location.replacementProductId === replacementProductId,
    );

    return getRepartitionedLocationStock({
        locationId,
        stockToRepartition: stockToRemove,
        locationsContainingProduct: tomorrowProductLocations,
    });
}

export function getNoStockInLocationWarnings({
    allSiteLocations,
    microstoreColumnFormValue,
    microstoreProductsStockInfo,
}: {
    allSiteLocations: MicrostoreLocationFormValues[];
    microstoreColumnFormValue: MicrostoreColumnFormValues;
    microstoreProductsStockInfo: MicrostoreProductsStockInfoFormValues | null;
}): string[] {
    const warnings: string[] = [];

    microstoreColumnFormValue.shelves.forEach((shelf) =>
        shelf.locationTable.forEach((locationRow) =>
            locationRow.forEach((location) => {
                const stockAfterReassortment =
                    getReplacementProductStockInLocation({
                        allSiteLocations,
                        locationId: location._id,
                        microstoreProductsStockInfo,
                        replacementProductId: location.replacementProductId || null,
                    }) + location.stockToAdd;
                if (location.replacementProductId && stockAfterReassortment <= 0) {
                    warnings.push(
                        `L'emplacement à la position : étagère-${shelf.positionInColumn + 1} rangée-${
                            location.positionInShelf.row + 1
                        } colonne-${location.positionInShelf.column + 1} n'aura pas de stock après le réassort`,
                    );
                }
            }),
        ),
    );

    return warnings;
}

import React, { InputHTMLAttributes } from 'react';

import styled from 'styled-components';

import { TotemLabel } from '../TotemLabel';
import { BasicInput } from '../BasicInput';

type DetailFormValueProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'defaultValue'> & {
    label?: string;
    sublabel?: string;
    error?: string;
    defaultValue?: string | number | null;
};

export const DetailDisplayValue = ({ label, sublabel, style, value, placeholder, error }: DetailFormValueProps) => {
    return (
        <Container style={style}>
            <LabelContainer hasMarginBottom={!!label || !!sublabel}>
                {label ? <TotemLabel>{label}</TotemLabel> : null}
                {sublabel ? <Sublabel>{sublabel}</Sublabel> : null}
            </LabelContainer>
            <ValueContainer>
                <BasicInput placeholder={placeholder || 'Aucune valeur'} width="100%" value={value} disabled />
                {error ? <Error>{error}</Error> : null}
            </ValueContainer>
        </Container>
    );
};

const Error = styled.div`
    position: absolute;
    right: -2px;
    top: -16px;
    font-size: 14px;
    color: ${({ theme }) => theme.errorColor};
    width: max-content;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    color: ${({ theme }) => theme.textColor};
`;

const LabelContainer = styled.div<{ hasMarginBottom: boolean }>`
    display: flex;
    flex-direction: column;
    margin-top: ${({ hasMarginBottom }) => (hasMarginBottom ? 8 : 0)}px;
`;

const Sublabel = styled.span`
    color: ${({ theme }) => theme.infoTextColor};
    font-size: 13px;
`;

const ValueContainer = styled.div`
    position: relative;
    margin-top: 5px;
    min-width: 70px;
`;

import React from 'react';
import { useQuery } from '@apollo/client';
import { TotemSelect } from 'components/TotemSelect';
import { GET_ACTIVE_ORGANIZATIONS } from 'data/queries/__generated__/GET_ACTIVE_ORGANIZATIONS';
import { GET_SITES } from 'data/queries/__generated__/GET_SITES';
import { GET_ACTIVE_ORGANIZATIONS_QUERY } from 'data/queries/organization';
import { GET_SITES_QUERY } from 'data/queries/site';
import { SiteState, SiteType } from 'data/__generated__';

import { TotemTextArea } from 'components/TotemTextArea';
import { QuizAudience } from './QuizForm';

export const QuizAudienceSelector = ({
    audience,
    setAudience,
}: {
    audience: QuizAudience;
    setAudience: (audience: QuizAudience) => void;
}) => {
    const { data: organizationsData } = useQuery<GET_ACTIVE_ORGANIZATIONS>(GET_ACTIVE_ORGANIZATIONS_QUERY);

    const { data: sitesData } = useQuery<GET_SITES>(GET_SITES_QUERY);

    if (!sitesData) {
        return null;
    }

    if (!organizationsData) {
        return null;
    }

    const { activeOrganizations } = organizationsData;

    const organizationOptions = activeOrganizations.map(({ _id, name }) => ({ label: name, value: _id }));

    const { sites } = sitesData;

    const siteOptions = sites
        .filter((site) => site.state !== SiteState.Archived && site.type === SiteType.Microstore)
        .map(({ _id, name }) => ({ label: name, value: _id }));

    const selectedOrganizationOptions = organizationOptions.filter(({ value }) =>
        audience.organizationIds.includes(value),
    );
    const selectedSiteOptions = siteOptions.filter(({ value }) => audience.siteIds.includes(value));

    return (
        <>
            <TotemSelect
                label="Organisations cibles"
                value={selectedOrganizationOptions}
                options={organizationOptions}
                isMulti
                onChange={(options) => {
                    if (options) {
                        setAudience({ ...audience, organizationIds: options.map(({ value }) => value) });
                    }
                }}
            />
            <TotemSelect
                label="Sites cibles"
                value={selectedSiteOptions}
                options={siteOptions}
                isMulti
                onChange={(options) => {
                    if (options) {
                        setAudience({ ...audience, siteIds: options.map(({ value }) => value) });
                    }
                }}
            />
            <TotemTextArea
                label="Utilisateurs cibles"
                placeholder="Renseignez les identifiants des utilisateurs, séparés par des virgules"
                defaultValue={audience.userIds.join(',')}
                onChange={(value) =>
                    setAudience({
                        ...audience,
                        userIds: value.split(',').map((userId) => userId.replace(' ', '').trim()),
                    })
                }
            />
        </>
    );
};

import React from 'react';

import { CommonStatusTag } from 'components/CommonStatusTag';

import { colors } from 'constants/colors';

import { DeliveryState } from 'data/__generated__';
import { DELIVERY_STATES_LABELS } from '../constants/states';

const getColorsFromState = (state: DeliveryState) => {
    const { blue, green, pantinGrey, lightGrey, black, yellow, red, pureWhite } = colors;

    switch (state) {
        case DeliveryState.Aborted:
            return { backgroundColor: red, labelColor: pureWhite };
        case DeliveryState.Archived:
            return { backgroundColor: pantinGrey, labelColor: pureWhite };
        case DeliveryState.Created:
            return { backgroundColor: lightGrey, labelColor: black };
        case DeliveryState.Delivered:
            return { backgroundColor: green, labelColor: black };
        case DeliveryState.InProgress:
            return { backgroundColor: blue, labelColor: pureWhite };
        case DeliveryState.NotDelivered:
            return { backgroundColor: pantinGrey, labelColor: pureWhite };
        case DeliveryState.Packaging:
            return { backgroundColor: black, labelColor: pureWhite };
        case DeliveryState.Ready:
            return { backgroundColor: yellow, labelColor: black };
    }
};

export const StatusTag = ({ state, showHeading }: { state: DeliveryState; showHeading?: boolean }) => {
    const { backgroundColor, labelColor } = getColorsFromState(state);
    return (
        <CommonStatusTag
            backgroundColor={backgroundColor}
            labelColor={labelColor}
            label={DELIVERY_STATES_LABELS[state]}
            showHeading={showHeading}
        />
    );
};

import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';

import { toast } from 'react-toastify';
import styled from 'styled-components';
import { FaSave, FaUndoAlt } from 'react-icons/fa';

import { PAGES } from 'constants/pages';
import { MICROSTORE_COLUMN_TEMPLATE_TYPE_LABELS } from '../constants/types';

import { MicrostoreColumnTemplateType } from 'data/__generated__';
import { MicrostoreColumnTemplateWithAllInfo } from 'data/fragments/__generated__/MicrostoreColumnTemplateWithAllInfo';
import {
    UPDATE_MICROSTORE_COLUMN_TEMPLATE,
    UPDATE_MICROSTORE_COLUMN_TEMPLATEVariables,
} from 'data/mutations/__generated__/UPDATE_MICROSTORE_COLUMN_TEMPLATE';
import { UPDATE_MICROSTORE_COLUMN_TEMPLATE_MUTATION } from 'data/mutations/microstoreColumnTemplate';

import { ColumnsSectionContainer, SectionColumn, SectionContainer } from 'components/DetailsView/Section';
import { FullPageLoader } from 'components/FullPageLoader';
import { Header, HeaderTitle } from 'components/Header';
import { PageTitle } from 'components/PageTitle';
import { SelectedOption } from 'components/TotemCheckbox';
import { TotemInput } from 'components/TotemInput';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { TotemSelect } from 'components/TotemSelect';
import { ColumnShelfLocationDetailedEditor } from './ColumnShelfLocationDetailedEditor';
import { ColumnShelfLocationSimpleEditor } from './ColumnShelfLocationSimpleEditor';

import {
    mapMicrostoreColumnTemplateFormValuesToMicrostoreColumnTemplateUpdateInput,
    mapMicrostoreColumnTemplateToMicrostoreColumnTemplateFormValues,
    MicrostoreColumnTemplateFormValues,
} from './FormHelper/DataMapper';

export const MicrostoreColumnTemplateDetailsForm = ({
    microstoreColumnTemplate,
}: {
    microstoreColumnTemplate: MicrostoreColumnTemplateWithAllInfo;
}) => {
    const [microstoreColumnTemplateFormValues, setMicrostoreColumnTemplateFormValues] =
        useState<MicrostoreColumnTemplateFormValues>(
            mapMicrostoreColumnTemplateToMicrostoreColumnTemplateFormValues({ microstoreColumnTemplate }),
        );
    const [isFormDirty, setIsFormDirty] = useState<boolean>(false);

    const [selectedShelfTemplateIndex, setSelectedShelfTemplateIndex] = useState<number | null>(null);
    const [selectedLocationTemplatePosition, setSelectedLocationTemplatePosition] = useState<{
        column: number;
        row: number;
    } | null>(null);

    const [updateMicrostoreColumnTemplate, { loading: updateLoading }] = useMutation<
        UPDATE_MICROSTORE_COLUMN_TEMPLATE,
        UPDATE_MICROSTORE_COLUMN_TEMPLATEVariables
    >(UPDATE_MICROSTORE_COLUMN_TEMPLATE_MUTATION);

    function setMicrostoreColumnTemplateFormValuesAndIsFormDirty({
        newMicrostoreColumnTemplateFormValues,
        newIsDirty = true,
    }: {
        newMicrostoreColumnTemplateFormValues: MicrostoreColumnTemplateFormValues;
        newIsDirty?: boolean;
    }) {
        setMicrostoreColumnTemplateFormValues(newMicrostoreColumnTemplateFormValues);
        setIsFormDirty(newIsDirty);
    }

    function resetFormValues() {
        setMicrostoreColumnTemplateFormValuesAndIsFormDirty({
            newMicrostoreColumnTemplateFormValues: mapMicrostoreColumnTemplateToMicrostoreColumnTemplateFormValues({
                microstoreColumnTemplate,
            }),
            newIsDirty: false,
        });
    }

    async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        const microstoreColumnTemplateUpdateInput =
            mapMicrostoreColumnTemplateFormValuesToMicrostoreColumnTemplateUpdateInput({
                microstoreColumnTemplateFormValues,
            });

        const { data } = await updateMicrostoreColumnTemplate({
            variables: { microstoreColumnTemplate: microstoreColumnTemplateUpdateInput },
        });

        if (!data) {
            throw new Error('Une erreur est survenue lors de la modification du template de colonne');
        }

        const {
            updateMicrostoreColumnTemplateMutation: { newMicrostoreColumnTemplate, errors },
        } = data;

        if (errors.length) {
            errors.forEach((error, index) => {
                toast.error(<span key={index}>Erreur : {error}</span>, { autoClose: false });
            });

            if (errors.length > 1) {
                toast.info('Cliquez pour fermer toutes les notifications', {
                    autoClose: false,
                    onClick: () => toast.dismiss(),
                });
            }
        }

        if (newMicrostoreColumnTemplate) {
            toast.success(`Le template de colonne ${newMicrostoreColumnTemplate.name} a bien été modifié !`);
            setMicrostoreColumnTemplateFormValuesAndIsFormDirty({
                newMicrostoreColumnTemplateFormValues: mapMicrostoreColumnTemplateToMicrostoreColumnTemplateFormValues({
                    microstoreColumnTemplate: newMicrostoreColumnTemplate,
                }),
                newIsDirty: false,
            });
        }
    }

    const typeOptions =
        Object.keys(MicrostoreColumnTemplateType)
            .map((type) => ({
                value: type as MicrostoreColumnTemplateType,
                label: MICROSTORE_COLUMN_TEMPLATE_TYPE_LABELS[type as MicrostoreColumnTemplateType],
            }))
            .sort((a, b) => a.label.localeCompare(b.label)) || [];

    return (
        <>
            <Form onSubmit={onSubmit}>
                <Header>
                    <HeaderTitle>
                        <PageTitle page={PAGES.microstoreColumnTemplateDetails} />
                    </HeaderTitle>
                    <ButtonsContainer>
                        <TotemPrimaryButton
                            isSecondaryStyle
                            type="button"
                            onClick={resetFormValues}
                            disabled={!isFormDirty}
                        >
                            <FaUndoAlt size={13} />
                            <ButtonLabel>Réinitialiser</ButtonLabel>
                        </TotemPrimaryButton>
                        <TotemPrimaryButton type="submit" disabled={!isFormDirty}>
                            <FaSave size="13" />
                            <ButtonLabel>Mettre à jour</ButtonLabel>
                        </TotemPrimaryButton>
                        <Link to="/microstoreColumnTemplates">
                            <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                        </Link>
                    </ButtonsContainer>
                </Header>
                <Content>
                    <SectionContainer title="Informations générales" isInitiallyOpen>
                        <ColumnsSectionContainer numberOfColumns={2}>
                            <SectionColumn>
                                <TotemInput
                                    label="Nom du template de colonne"
                                    placeholder="Frigo basique"
                                    onChange={(newName) =>
                                        setMicrostoreColumnTemplateFormValuesAndIsFormDirty({
                                            newMicrostoreColumnTemplateFormValues: {
                                                ...microstoreColumnTemplateFormValues,
                                                name: newName,
                                            },
                                        })
                                    }
                                    value={microstoreColumnTemplateFormValues.name}
                                />
                            </SectionColumn>
                            <SectionColumn>
                                <TotemSelect<MicrostoreColumnTemplateType>
                                    label="Type de colonne"
                                    placeholder="Type de colonne"
                                    value={typeOptions.find(
                                        (typeOption) => typeOption.value === microstoreColumnTemplateFormValues.type,
                                    )}
                                    options={typeOptions}
                                    onChange={(option: SelectedOption<MicrostoreColumnTemplateType>) => {
                                        if (option) {
                                            setMicrostoreColumnTemplateFormValuesAndIsFormDirty({
                                                newMicrostoreColumnTemplateFormValues: {
                                                    ...microstoreColumnTemplateFormValues,
                                                    type: option.value,
                                                },
                                            });
                                        }
                                    }}
                                />
                            </SectionColumn>
                        </ColumnsSectionContainer>
                    </SectionContainer>

                    <SectionContainer title="Création assistée" isInitiallyOpen>
                        <ColumnShelfLocationSimpleEditor
                            microstoreColumnTemplateFormValues={microstoreColumnTemplateFormValues}
                            setMicrostoreColumnTemplateFormValuesAndIsFormDirty={
                                setMicrostoreColumnTemplateFormValuesAndIsFormDirty
                            }
                            selectedShelfTemplateIndex={selectedShelfTemplateIndex}
                            setSelectedShelfTemplateIndex={setSelectedShelfTemplateIndex}
                            selectedLocationTemplatePosition={selectedLocationTemplatePosition}
                            setSelectedLocationTemplatePosition={setSelectedLocationTemplatePosition}
                        />
                    </SectionContainer>

                    <SectionContainer title="Création détaillée" isInitiallyOpen={false}>
                        <ColumnShelfLocationDetailedEditor
                            microstoreColumnTemplateFormValues={microstoreColumnTemplateFormValues}
                            setMicrostoreColumnTemplateFormValuesAndIsFormDirty={
                                setMicrostoreColumnTemplateFormValuesAndIsFormDirty
                            }
                            selectedShelfTemplateIndex={selectedShelfTemplateIndex}
                            setSelectedShelfTemplateIndex={setSelectedShelfTemplateIndex}
                            selectedLocationTemplatePosition={selectedLocationTemplatePosition}
                            setSelectedLocationTemplatePosition={setSelectedLocationTemplatePosition}
                        />
                    </SectionContainer>
                </Content>
            </Form>

            {updateLoading ? <FullPageLoader /> : null}
        </>
    );
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
`;

const ButtonsContainer = styled.div`
    display: flex;
    width: max-content;
    align-items: center;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const ButtonLabel = styled.span`
    margin-left: 5px;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 15px;
    overflow: scroll;

    & > :not(:first-child) {
        margin-top: 15px;
    }
`;

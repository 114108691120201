import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { GetDetailedProductQuery, ProductState } from 'data/__generated__';

import { DetailFormCheckbox, DetailFormDatePicker } from 'components/DetailsView';
import { dateFromString, formatDateAsAnniversary, getExtendedFormattedDatetime } from 'helpers/dateTimes';
import { TotemLabel } from 'components/TotemLabel';

export const StatusSection = ({ product }: { product: NonNullable<GetDetailedProductQuery['detailedProduct']> }) => {
    const { control, setValue } = useFormContext();
    const { state, isBeingTested, isPaused, isToBeArchived, toBeArchivedHistory, newUntil, useInMenusFrom } = product;

    useEffect(() => {
        setValue('isBeingTested', isBeingTested);
        setValue('isPaused', isPaused);
        setValue('isToBeArchived', isToBeArchived);
        setValue('newUntil', newUntil);
        setValue('useInMenusFrom', useInMenusFrom);
    }, [setValue, isBeingTested, isPaused, isToBeArchived, newUntil, useInMenusFrom]);

    const today = new Date();

    return (
        <>
            {state === ProductState.Created ? (
                <DetailFormCheckbox name="isBeingTested" label="En test" defaultChecked={isBeingTested} />
            ) : state === ProductState.Regular ? (
                <>
                    <DetailFormDatePicker
                        label="Nouveau jusqu'à"
                        sublabel="Date de suppression automatique de label 'Nouveau'. Pas de label si champs vide."
                        placeholder="Date suppression label"
                        defaultValue={newUntil}
                        transformValueToDate={(value) => (value ? dateFromString(value) : null)}
                        transformDateToValue={(date) => (date ? formatDateAsAnniversary({ dateTime: date }) : null)}
                        name="newUntil"
                        control={control}
                        isClearable
                        minDate={today}
                    />
                    <DetailFormDatePicker
                        label="Présent en store le"
                        sublabel="Ce produit doit être choisi par l'algo de génération des menus à partir de cette date"
                        placeholder="Date d'arrivée en store"
                        defaultValue={useInMenusFrom}
                        transformValueToDate={(value) => (value ? dateFromString(value, true) : null)}
                        transformDateToValue={(date) =>
                            date ? formatDateAsAnniversary({ dateTime: date, useNewFormat: true }) : null
                        }
                        name="useInMenusFrom"
                        control={control}
                        isClearable
                        minDate={today}
                    />
                    <DetailFormCheckbox
                        name="isToBeArchived"
                        label="À sortir"
                        sublabel="Produit ne pourra plus être commandé et passera automatiquement en 'Archivé' dès qu'il n'y a plus de stock"
                        defaultChecked={isToBeArchived}
                    />
                    {toBeArchivedHistory.length ? (
                        <TableContainer>
                            <TotemLabel>Historique de À sortir</TotemLabel>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Commencé le</th>
                                        <th>Fini le</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {[...toBeArchivedHistory].reverse().map(({ createdAt, endedAt }, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{getExtendedFormattedDatetime(createdAt)}</td>
                                                <td>
                                                    {endedAt
                                                        ? getExtendedFormattedDatetime(endedAt)
                                                        : 'Pas encore fini'}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </TableContainer>
                    ) : null}
                </>
            ) : (
                <DetailFormCheckbox name="isPaused" label="En pause" defaultChecked={isPaused} />
            )}
        </>
    );
};

const TableContainer = styled.div`
    display: flex;
    flex-direction: column;

    & > :not(:first-child) {
        margin-top: 5px;
    }

    table {
        border-spacing: 0;
        border-collapse: collapse;
        color: ${({ theme }) => theme.textColor};

        th,
        td {
            text-align: left;
            border: 1px solid ${({ theme }) => theme.lightBorderColor};
            border-spacing: 1px;
        }

        th {
            padding: 10px;
        }

        td {
            padding: 5px 10px;
        }
    }
`;

import React, { useState } from 'react';

import { toast } from 'react-toastify';
import styled from 'styled-components';

import { PAGES } from '../../constants/pages';

import { useBatchCreditsMutation, useGetOrganizationsEligibleToCreditsQuery } from 'data/__generated__';

import { Footer } from 'components/Footer';
import { Header, HeaderTitle } from 'components/Header';
import { Loader, LoaderModeType } from 'components/Loader';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { TotemInput } from 'components/TotemInput';
import { TotemLabel } from 'components/TotemLabel';
import { SelectedOption, TotemSelect } from 'components/TotemSelect';
import { PageTitle } from 'components/PageTitle';
import { ColumnsSectionContainer, SectionColumn } from 'components/DetailsView/Section';
import { formatDateAsAnniversary } from 'helpers/dateTimes';
import { DetailValue } from 'components/DetailsView';

type FormType = {
    initialAmount: number;
};

type FormFilter = {
    organization: {
        selected: boolean;
        optionSelected: {
            label: string;
            value: string;
        };
    };
    users: {
        selected: boolean;
        optionSelected: {
            value: string[];
        };
    };
};

export const CreditsForm = () => {
    const oneYearFromNow = new Date();
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

    const initialFormData = {
        initialAmount: 0,
    };

    const initialFilterData = {
        organization: {
            selected: false,
            optionSelected: {
                label: '',
                value: '',
            },
        },
        users: {
            selected: false,
            optionSelected: {
                value: [],
            },
        },
    };

    const [formData, setFormData] = useState<FormType>(initialFormData);

    const [formFilterData, setFormFilterData] = useState<FormFilter>(initialFilterData);
    const {
        loading: organizationsLoading,
        data: organizationsData,
        error: organizationsError,
    } = useGetOrganizationsEligibleToCreditsQuery();

    const [batchCredits, { loading: createCreditsLoading }] = useBatchCreditsMutation();

    if (organizationsLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (organizationsError || !organizationsData) {
        throw new Error('Une erreur est survenue lors de la récupération des organisations');
    }

    const { organizationsEligibleToCredits } = organizationsData;

    const organizationsOptions =
        organizationsEligibleToCredits
            .map((organization) => ({ value: organization._id, label: organization.name }))
            .sort((orgA, orgB) => orgA.label.toLowerCase().localeCompare(orgB.label.toLowerCase())) || [];

    const handleSelectOrganization = (option: SelectedOption<string>) => {
        if (option) {
            const filter = { organization: { optionSelected: option, selected: formFilterData.organization.selected } };
            setFormFilterData({ ...formFilterData, ...filter });
        }
    };

    const handleUser = (option: string[]) => {
        if (option) {
            const filter = { users: { optionSelected: { value: option }, selected: formFilterData.users.selected } };
            setFormFilterData({ ...formFilterData, ...filter });
        }
    };

    const isSubmitDisabled =
        !(formFilterData.users.selected || formFilterData.organization.selected) ||
        (formFilterData.users.selected && !formFilterData.users.optionSelected.value.length) ||
        (formFilterData.organization.selected && !formFilterData.organization.optionSelected.value);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const { initialAmount } = formData;
        const { organization, users } = formFilterData;
        const where = {
            userIds: users.selected ? users.optionSelected.value : null,
            organizationId: organization.selected ? organization.optionSelected.value : null,
        };

        // if no option selected, or selected option doesn't have a value
        if (isSubmitDisabled) {
            return;
        }

        if (!initialAmount) {
            toast.error("L'un des champs n'a pas été renseigné");
            return;
        }

        const result = await batchCredits({
            variables: {
                credit: {
                    initialAmount,
                },
                where,
            },
        });

        const { data } = result;
        if (data) {
            const {
                batchCredits: { success },
            } = data;
            if (success) {
                toast.success('Le crédit a été créé');
                setFormData(initialFormData);
            } else {
                const {
                    batchCredits: { error },
                } = data;
                if (error) {
                    toast.error(`L'erreur suivante est survenue : ${error}`);
                } else {
                    throw Error("Une erreur inconnue s'est produite");
                }
            }
        } else {
            throw Error("Une erreur inconnue s'est produite");
        }
    };

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.creditCreate} />
                </HeaderTitle>
            </Header>
            <Content>
                <Form onSubmit={handleSubmit}>
                    <ContentScrollable>
                        <ColumnsSectionContainer numberOfColumns={3}>
                            <SectionColumn>
                                <DetailValue
                                    label="Nom du crédit - affiché dans l'app et sur la notification"
                                    value={
                                        formData.initialAmount +
                                        ' € - ' +
                                        formatDateAsAnniversary({ dateTime: new Date() })
                                    }
                                    data-test="credit-name"
                                />
                                <TotemInput
                                    label="Montant du crédit"
                                    placeholder="Le montant de votre crédit en euro"
                                    name="initialAmount"
                                    autoFocus
                                    type="number"
                                    step="0.01"
                                    data-test="credit-amount"
                                    onChange={(value) => setFormData({ ...formData, initialAmount: parseFloat(value) })}
                                />
                            </SectionColumn>
                        </ColumnsSectionContainer>

                        <br />
                        <TotemLabel color="white">
                            <span>Envoyer à : </span>
                        </TotemLabel>
                        <div>
                            <br />
                            <div>
                                <input
                                    type="radio"
                                    id="organization"
                                    name="filter"
                                    onChange={() => {
                                        setFormFilterData({
                                            organization: {
                                                selected: true,
                                                optionSelected: {
                                                    label: '',
                                                    value: '',
                                                },
                                            },
                                            users: initialFilterData.users,
                                        });
                                    }}
                                />
                                <label htmlFor="organization">une organisation</label>
                                <br />
                                {!organizationsLoading && organizationsData && formFilterData.organization.selected ? (
                                    <TotemSelect
                                        placeholder="Sélectionner votre organisation"
                                        value={formFilterData.organization.optionSelected}
                                        options={organizationsOptions}
                                        onChange={handleSelectOrganization}
                                        dataTest="select-organization-filter"
                                    />
                                ) : null}
                            </div>
                            <br />
                            <div>
                                <input
                                    type="radio"
                                    id="user"
                                    name="filter"
                                    onChange={() => {
                                        setFormFilterData({
                                            organization: initialFilterData.organization,
                                            users: {
                                                selected: true,
                                                optionSelected: {
                                                    value: [],
                                                },
                                            },
                                        });
                                    }}
                                />
                                <label htmlFor="users">des utilisateurs</label>
                                <br />
                                <br />
                                {formFilterData.users.selected ? (
                                    <TotemInput
                                        placeholder="Liste des utilisateurs sans espace (ex : id_utilisateur_1,id_utilisateur_2,id_utilisateur_3)"
                                        name="users"
                                        autoFocus
                                        data-test="credit-users"
                                        onChange={(value) => handleUser(value.split(','))}
                                    />
                                ) : null}
                            </div>
                        </div>
                    </ContentScrollable>
                    <Footer>
                        <TotemPrimaryButton
                            data-test="stock-transfer-create_submit-button"
                            disabled={createCreditsLoading || isSubmitDisabled}
                        >
                            {createCreditsLoading ? (
                                <Loader size="20px" mode={LoaderModeType.Spin} />
                            ) : (
                                'Créer le crédit'
                            )}
                        </TotemPrimaryButton>
                    </Footer>
                </Form>
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
`;

const Content = styled.div`
    flex: 1;
    overflow: hidden;
`;

const Form = styled.form`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ContentScrollable = styled.div`
    padding: 15px;
    width: 100%;
    flex: 1;
    overflow-y: auto;
`;

import React from 'react';

import { useQuery, useSubscription } from '@apollo/client';
import styled from 'styled-components';

import { WAREHOUSE_RETURN_PLURAL_STATES_LABELS } from './constants/states';

import { GET_WAREHOUSE_RETURN_FOR_DATE_QUERY } from 'data/queries/warehouseReturn';
import { GET_SIMPLE_MICROSTORE_SITES_QUERY } from 'data/queries/site';
import { WarehouseReturnState } from 'data/__generated__';
import {
    GET_WAREHOUSE_RETURN_FOR_DATE,
    GET_WAREHOUSE_RETURN_FOR_DATEVariables,
} from 'data/queries/__generated__/GET_WAREHOUSE_RETURN_FOR_DATE';
import {
    GET_SIMPLE_MICROSTORE_SITES,
    GET_SIMPLE_MICROSTORE_SITES_microstoreSites,
} from 'data/queries/__generated__/GET_SIMPLE_MICROSTORE_SITES';
import { WAREHOUSE_RETURN_CREATED_OR_UPDATED_SUBSCRIPTION } from 'data/subscriptions/warehouseReturn';
import { WAREHOUSE_RETURN_CREATED_OR_UPDATED } from 'data/subscriptions/__generated__/WAREHOUSE_RETURN_CREATED_OR_UPDATED';

import { WarehouseReturnTrelloItem } from './components/WarehouseReturnTrelloItem';
import { Loader } from 'components/Loader';
import { TrelloView } from 'components/TrelloView/TrelloView';

import { warehouseReturnCreatedOrUpdatedHandler } from './cacheHandlers/warehouseReturnsCacheHandler';
import { formatDateAsAnniversary } from 'helpers/dateTimes';

export const WarehouseReturnsTrelloView = ({ warehouseReturnsDate }: { warehouseReturnsDate: Date }) => {
    const {
        data: warehouseReturnsData,
        error: warehouseReturnsError,
        loading: warehouseReturnLoading,
    } = useQuery<GET_WAREHOUSE_RETURN_FOR_DATE, GET_WAREHOUSE_RETURN_FOR_DATEVariables>(
        GET_WAREHOUSE_RETURN_FOR_DATE_QUERY,
        {
            fetchPolicy: 'cache-and-network',
            variables: { date: formatDateAsAnniversary({ dateTime: warehouseReturnsDate, useNewFormat: true }) },
        },
    );
    const {
        data: sitesData,
        loading: sitesLoading,
        error: sitesError,
    } = useQuery<GET_SIMPLE_MICROSTORE_SITES>(GET_SIMPLE_MICROSTORE_SITES_QUERY, {
        fetchPolicy: 'cache-and-network',
    });

    useSubscription<WAREHOUSE_RETURN_CREATED_OR_UPDATED>(WAREHOUSE_RETURN_CREATED_OR_UPDATED_SUBSCRIPTION, {
        onSubscriptionData({ subscriptionData: { data } }) {
            if (!data) {
                return;
            }
            const { warehouseReturnCreatedOrUpdated } = data;
            warehouseReturnCreatedOrUpdatedHandler(warehouseReturnCreatedOrUpdated);
        },
    });

    if ((warehouseReturnLoading && !warehouseReturnsData) || (sitesLoading && !sitesData)) {
        return (
            <Content>
                <Loader />
            </Content>
        );
    }

    if (warehouseReturnsError || !warehouseReturnsData || sitesError || !sitesData) {
        throw new Error('Une erreur est survenue lors de la récupération des rangements');
    }

    const { getWarehouseReturnsForDate: warehouseReturns } = warehouseReturnsData;
    const { microstoreSites: sites } = sitesData;

    function getWarehouseReturnsByState({ state }: { state: WarehouseReturnState }) {
        return warehouseReturns
            .filter((warehouseReturn) => warehouseReturn.state === state)
            .map((warehouseReturn) => {
                const originSite = sites.find(
                    ({ _id }) => _id === warehouseReturn.originSiteId,
                ) as GET_SIMPLE_MICROSTORE_SITES_microstoreSites;
                return {
                    _id: warehouseReturn._id,
                    content: <WarehouseReturnTrelloItem warehouseReturn={warehouseReturn} originSite={originSite} />,
                };
            });
    }

    const warehouseReturnsByState = {
        [WarehouseReturnState.Created]: getWarehouseReturnsByState({ state: WarehouseReturnState.Created }),
        [WarehouseReturnState.InProgress]: getWarehouseReturnsByState({ state: WarehouseReturnState.InProgress }),
        [WarehouseReturnState.Delivered]: getWarehouseReturnsByState({ state: WarehouseReturnState.Delivered }),
        [WarehouseReturnState.Stored]: getWarehouseReturnsByState({ state: WarehouseReturnState.Stored }),
    };

    const STATE_COLUMNS_TO_DISPLAY = [
        WarehouseReturnState.Created,
        WarehouseReturnState.InProgress,
        WarehouseReturnState.Delivered,
        WarehouseReturnState.Stored,
    ];

    const initialData = {
        columns: [
            ...STATE_COLUMNS_TO_DISPLAY.map((state) => ({
                title: WAREHOUSE_RETURN_PLURAL_STATES_LABELS[state],
                items: warehouseReturnsByState[state],
            })),
        ],
    };

    return (
        <Content>
            <TrelloViewContainer>
                <TrelloView columnWidth="300px" initialData={initialData} />
            </TrelloViewContainer>
        </Content>
    );
};

const TrelloViewContainer = styled.div`
    overflow-x: scroll;
`;

const Content = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    overflow: hidden;
`;

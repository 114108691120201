import React, { useState } from 'react';
import { useQuery, useSubscription } from '@apollo/client';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Loader } from 'components/Loader';

import { SupplierGlobalForm } from './SupplierGlobalForm';

import { GET_DETAILED_SUPPLIER_QUERY } from 'data/queries/supplier';
import { SUPPLIER_UPDATED_SUBSCRIPTION } from 'data/subscriptions/supplier';
import { GET_DETAILED_SUPPLIER } from 'data/queries/__generated__/GET_DETAILED_SUPPLIER';
import { SUPPLIER_UPDATED } from 'data/subscriptions/__generated__/SUPPLIER_UPDATED';
import { SupplierFragment } from 'data/fragments/__generated__/SupplierFragment';

type ParamTypes = {
    supplierId: string;
};

export const SupplierDetails = () => {
    const { supplierId } = useParams<ParamTypes>();
    const [supplierUpdated, setSupplierUpdated] = useState<SupplierFragment | null>(null);
    const {
        loading: supplierLoading,
        data: supplierData,
        error: supplierError,
    } = useQuery<GET_DETAILED_SUPPLIER>(GET_DETAILED_SUPPLIER_QUERY, {
        variables: { supplierId },
    });
    useSubscription<SUPPLIER_UPDATED>(SUPPLIER_UPDATED_SUBSCRIPTION, {
        onSubscriptionData({ subscriptionData: { data } }) {
            if (!data) {
                return;
            }
            const { supplierUpdated } = data;
            if (supplierUpdated?._id === supplierId) {
                setSupplierUpdated(supplierUpdated);
            }
        },
    });

    if (supplierLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (supplierError || !supplierData) {
        throw new Error('Une erreur est survenue lors de la récupération du fournisseur');
    }

    const { detailedSupplier } = supplierData;

    if (!detailedSupplier && !supplierUpdated) {
        throw new Error(`Aucun fournisseur avec l'id ${supplierId} n'a été trouvé`);
    }

    return (
        <Container>
            <SupplierGlobalForm detailedSupplier={supplierUpdated || detailedSupplier} />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex: 1;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

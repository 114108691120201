import { gql } from '@apollo/client';

export const CREATE_PRICE_RANGE_MUTATION = gql`
    mutation CREATE_PRICE_RANGE($rangeName: String!, $products: [ProductPriceInput!]!) {
        createPriceRange(rangeName: $rangeName, products: $products) {
            rangeId
            success
            error
        }
    }
`;

export const UPDATE_PRICE_RANGE_MUTATION = gql`
    mutation UPDATE_PRICE_RANGE($rangeName: String!, $rangeId: ID!, $products: [ProductPriceInput!]!) {
        updatePriceRange(rangeName: $rangeName, rangeId: $rangeId, products: $products) {
            rangeId
            success
            error
        }
    }
`;

import gql from 'graphql-tag';

export const SITE_MENUS_FRAGMENT = gql`
    fragment SiteMenusFragment on SiteMenus {
        siteId
        columns {
            columnId
            dailyMenus {
                date
                productLocations {
                    product {
                        _id
                        fullname
                    }
                    location {
                        _id
                    }
                }
            }
        }
    }
`;

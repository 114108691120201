import React, { FunctionComponent } from 'react';
import { TotemPopup } from 'components/TotemPopup';
import { SupplierDiscountsQuery, useCreateSupplierDiscountMutation } from 'data/__generated__';

import { GET_SUPPLIER_DISCOUNTS_QUERY } from 'data/queries/supplierDiscounts';
import { formatFormValues } from './utils';
import { UpsertSupplierDiscountForm } from './UpsertSupplierDiscountForm';

type CreateSupplierDiscountModalProps = {
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
};

export const CreateSupplierDiscountModal: FunctionComponent<CreateSupplierDiscountModalProps> = ({
    isOpen,
    setIsOpen,
}) => {
    const [createSupplierDiscount] = useCreateSupplierDiscountMutation({
        onCompleted: (data) => {
            if (!data?.createSupplierDiscount) {
                return;
            }
            setIsOpen(false);
        },
        update: (cache, { data }) => {
            const query = cache.readQuery<SupplierDiscountsQuery>({ query: GET_SUPPLIER_DISCOUNTS_QUERY });
            if (!query || !data?.createSupplierDiscount) {
                return;
            }
            cache.writeQuery<SupplierDiscountsQuery>({
                query: GET_SUPPLIER_DISCOUNTS_QUERY,
                data: {
                    supplierDiscounts: [...query.supplierDiscounts, data.createSupplierDiscount],
                },
            });
        },
    });

    return (
        <TotemPopup isOpen={isOpen} title="Créer une remise fournisseur" setIsOpen={setIsOpen}>
            <UpsertSupplierDiscountForm
                onSubmit={(values) => createSupplierDiscount({ variables: { input: formatFormValues(values) } })}
                imageUrl={null}
            />
        </TotemPopup>
    );
};

import {
    GetAllRegularTotemClosedReceptionDayDocument,
    GetAllRegularTotemClosedReceptionDayQuery,
} from 'data/__generated__';
import { apolloClient } from '../../../App';

export const totemClosedReceptionDayCreatedHandler = (
    createdTotemClosedReceptionDay: GetAllRegularTotemClosedReceptionDayQuery['getAllRegularTotemClosedReceptionDay'][number],
) => {
    const result = apolloClient.readQuery<GetAllRegularTotemClosedReceptionDayQuery>({
        query: GetAllRegularTotemClosedReceptionDayDocument,
    });
    if (result === null) {
        return;
    }
    const currentTotemClosedReceptionDays = result.getAllRegularTotemClosedReceptionDay;

    // if the current totem closed days already contains the created one, then we need to update it with the sent values (and keep it at the same index)
    const newTotemClosedReceptionDays = currentTotemClosedReceptionDays.find(
        ({ _id }) => _id === createdTotemClosedReceptionDay._id,
    )
        ? currentTotemClosedReceptionDays.map((totemClosedReceptionDay) =>
              totemClosedReceptionDay._id === createdTotemClosedReceptionDay._id
                  ? createdTotemClosedReceptionDay
                  : totemClosedReceptionDay,
          )
        : [...currentTotemClosedReceptionDays, createdTotemClosedReceptionDay];

    apolloClient.writeQuery<GetAllRegularTotemClosedReceptionDayQuery>({
        query: GetAllRegularTotemClosedReceptionDayDocument,
        data: { getAllRegularTotemClosedReceptionDay: newTotemClosedReceptionDays },
    });
};

export const totemClosedReceptionDayRemovedHandler = (removedTotemClosedReceptionDayId: string) => {
    const result = apolloClient.readQuery<GetAllRegularTotemClosedReceptionDayQuery>({
        query: GetAllRegularTotemClosedReceptionDayDocument,
    });
    if (result === null) {
        return;
    }
    const currentTotemClosedReceptionDays = result.getAllRegularTotemClosedReceptionDay;

    // if the current totem closed days already contains the created one, then we need to update it with the sent values (and keep it at the same index)
    const newTotemClosedReceptionDays = currentTotemClosedReceptionDays.filter(
        (totemClosedReceptionDay) => totemClosedReceptionDay._id !== removedTotemClosedReceptionDayId,
    );

    apolloClient.writeQuery<GetAllRegularTotemClosedReceptionDayQuery>({
        query: GetAllRegularTotemClosedReceptionDayDocument,
        data: { getAllRegularTotemClosedReceptionDay: newTotemClosedReceptionDays },
    });
};

import gql from 'graphql-tag';

export const GET_RITUAL_TEMPLATES_QUERY = gql`
    query GET_RITUAL_TEMPLATES {
        ritualTemplates {
            _id
            imageUrl
            name
        }
    }
`;

import React from 'react';

import { Link, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { PAGES } from '../../constants/pages';

import { CreditType, useGetCreditsQuery } from 'data/__generated__';

import { Header, HeaderTitle } from 'components/Header';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { Loader } from 'components/Loader';

import { ListView, FieldToDisplay } from 'components/ListView';
import { PageTitle } from 'components/PageTitle';
import { getExtendedFormattedDatetime } from '../../helpers/dateTimes';
import { CREDIT_TYPE_LABELS } from 'pages/Credits/constants';

type FormattedCreditType = {
    _id: string;
    createdAt: string;
    updatedAt: string;
    refundedAt: string;
    createdBy: string;
    name: string;
    initialAmount: number;
    currentAmount: number;
    organizationName: string;
    expirationDate: string;
    owner: string;
    startDate: string;
    type: string;
};

const CREDIT_FIELDS_TO_DISPLAY: FieldToDisplay<FormattedCreditType>[] = [
    {
        fieldName: 'createdAt',
        label: 'Date de création',
    },
    {
        fieldName: 'updatedAt',
        label: 'Dernière mise à jour',
    },
    {
        fieldName: 'createdBy',
        label: 'Créé par',
    },
    {
        fieldName: 'type',
        label: 'Type',
    },
    {
        fieldName: 'organizationName',
        label: 'Lot pour l‘organisation',
    },
    {
        fieldName: 'name',
        label: 'Nom du crédit',
    },
    {
        fieldName: 'owner',
        label: 'Utilisateur',
    },
    {
        fieldName: 'initialAmount',
        label: 'Montant initial',
    },
    {
        fieldName: 'currentAmount',
        label: 'Montant restant',
    },
    {
        fieldName: 'refundedAt',
        label: 'Remboursé le',
    },
    {
        fieldName: 'startDate',
        label: 'Date de début de validité',
    },
    {
        fieldName: 'expirationDate',
        label: "Date d'expiration",
    },
];

export const CreditsList = () => {
    const [searchParams] = useSearchParams();
    const userId = searchParams.get('userId');
    const types = searchParams.getAll('types') as CreditType[];

    const {
        loading: creditsLoading,
        data: creditsData,
        error: creditsError,
        fetchMore: fetchMoreCredits,
    } = useGetCreditsQuery({
        variables: { offset: 0, userId, types },
    });

    if (creditsError) {
        throw new Error('Une erreur est survenue lors du chargement des crédits');
    }

    if (creditsLoading || !creditsData) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    const {
        credits: { credits, hasMore },
    } = creditsData;

    const sortedCredits = [...credits].sort((creditA, creditB) => creditB.createdAt - creditA.createdAt);

    const formattedCredits: FormattedCreditType[] = sortedCredits.map((credit) => {
        return {
            _id: credit._id || '',
            createdAt: getExtendedFormattedDatetime(credit.createdAt),
            updatedAt: getExtendedFormattedDatetime(credit.updatedAt),
            refundedAt: credit.refundedAt ? getExtendedFormattedDatetime(credit.refundedAt) : '',
            createdBy: credit?.createdBy?.profile?.fullname || '',
            name: credit.name || '',
            initialAmount: credit.initialAmount || 0,
            currentAmount: credit.currentAmount || 0,
            organizationName: credit.organizationBatched?.name || '',
            expirationDate: getExtendedFormattedDatetime(credit.expirationDate),
            owner: credit?.owner?.profile?.fullname || '',
            startDate: getExtendedFormattedDatetime(credit.startDate),
            type: CREDIT_TYPE_LABELS[credit.type],
        };
    });

    const loadMore = async () => {
        await fetchMoreCredits({
            variables: {
                offset: credits.length,
            },
        });
    };

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.credits} />
                </HeaderTitle>
                <Link to="/credit/create">
                    <TotemPrimaryButton>Créer un crédit</TotemPrimaryButton>
                </Link>
            </Header>
            <Content>
                <ListView<FormattedCreditType>
                    fieldsToDisplay={CREDIT_FIELDS_TO_DISPLAY}
                    items={formattedCredits}
                    loadMore={loadMore}
                    hasMore={hasMore}
                    keyExtractor={(item) => item._id}
                />
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Content = styled.div`
    padding: 15px;
    flex: 1;
    overflow: hidden;
`;

import React from 'react';

import { useMutation } from '@apollo/client';
import type { FileError } from 'react-dropzone';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { CREATE_RITUAL_TEMPLATE_MUTATION } from 'data/mutations/ritualTemplate';
import {
    CREATE_RITUAL_TEMPLATE,
    CREATE_RITUAL_TEMPLATEVariables,
} from 'data/mutations/__generated__/CREATE_RITUAL_TEMPLATE';

import { DetailFormImage, DetailFormValue } from 'components/DetailsView';
import { FileWithPreview } from 'components/FileDragAndDrop';
import { Loader, LoaderModeType } from 'components/Loader';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { ritualTemplatesUpdatedHandler } from './utils/ritualTemplatesUpdatedHandler';

export const AddRitualTemplateModal = ({ onClose }: { onClose: () => void }) => {
    const [createRitualTemplate, { loading: createRitualTemplateLoading }] = useMutation<
        CREATE_RITUAL_TEMPLATE,
        CREATE_RITUAL_TEMPLATEVariables
    >(CREATE_RITUAL_TEMPLATE_MUTATION);

    async function fileValidationFunction(file: FileWithPreview) {
        return new Promise<FileError[]>((resolve, reject) => {
            const image = new Image();
            image.onload = function () {
                const widthErrors =
                    image.width >= 600 && image.height >= 400
                        ? []
                        : [
                              {
                                  message: `L'image doit avoir une dimension d'au moins 600x400px, votre image fait ${image.width}x${image.height}px`,
                                  code: 'file-invalid-dimension',
                              },
                          ];
                const fileErrors = [...widthErrors];
                resolve(fileErrors);
            };
            image.onerror = reject;
            image.src = file.preview;
        });
    }

    type FormData = {
        image: string;
        name: string;
    };

    const methods = useForm<FormData>({
        defaultValues: {},
    });

    const {
        handleSubmit,
        formState: { isDirty },
        register,
    } = methods;

    const onSubmit = handleSubmit(async (variables) => {
        const { data } = await createRitualTemplate({
            variables,
        });
        if (data) {
            const {
                createRitualTemplate: { success, errors, ritualTemplate },
            } = data;

            errors?.forEach((error, index) => {
                toast.error(<span key={index}>Erreur : {error}</span>, { autoClose: false });
            });

            if (success) {
                if (ritualTemplate) {
                    ritualTemplatesUpdatedHandler(ritualTemplate);
                }
                toast.success('Le modèle de rituel a bien été créé !');
                onClose();
            }
        } else {
            throw Error("Une erreur inconnue s'est produite");
        }
    });

    return (
        <ModalContainer onClick={onClose}>
            <Modal
                onClick={(e) => {
                    // only close the modal when I click on background
                    e.stopPropagation();
                }}
            >
                <FormProvider {...methods}>
                    <Form onSubmit={onSubmit}>
                        <FormFields>
                            <DetailFormImage
                                name="image"
                                label="Image"
                                sublabel="Image du rituel"
                                defaultValue={null}
                                fileValidationFunction={fileValidationFunction}
                                size={175}
                            />
                            <DetailFormValue
                                label="Nom"
                                sublabel="Le nom du rituel tel qu'il sera affiché sur l'app mobile"
                                defaultValue={"Le goûter, c'est sacré !"}
                                {...register('name', { required: true })}
                            />
                        </FormFields>
                        <TotemPrimaryButton
                            data-test="submit-button"
                            minWidth="140px"
                            type="submit"
                            disabled={!isDirty}
                        >
                            {createRitualTemplateLoading ? (
                                <Loader size="18px" mode={LoaderModeType.Spin} />
                            ) : (
                                'Créer le modèle de rituel'
                            )}
                        </TotemPrimaryButton>
                    </Form>
                </FormProvider>
            </Modal>
        </ModalContainer>
    );
};

const ModalContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #1116;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
`;

const Modal = styled.div`
    border-top: 5px solid #ff6c25;
    background-color: ${({ theme }) => theme.cardBackgroundColor};
    padding: 40px;
    width: 50%;
    height: auto;
    line-height: 20px;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;

    & > :not(:first-child) {
        margin-top: 15px;
    }
`;

const FormFields = styled.form`
    display: flex;
    flex: 1;

    & > :not(:first-child) {
        margin-left: 15px;
    }
`;

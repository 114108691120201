import gql from 'graphql-tag';
import { MICROSTORE_COLUMN_INVENTORY_FRAGMENT } from './microstoreColumnInventory.fragments';

export const SITE_INVENTORY_FRAGMENT = gql`
    fragment SiteInventoryFragment on SiteInventory {
        _id
        createdAt
        date
        microstoreColumnInventories {
            ...MicrostoreColumnInventoryFragment
        }
        siteId
        state
        updatedAt
    }
    ${MICROSTORE_COLUMN_INVENTORY_FRAGMENT}
`;

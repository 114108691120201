import {
    BOOLEAN_FIELDS,
    CONDITIONAL_FIELDS,
    ID,
    NUMERIC_FIELDS,
    NUMERIC_FIELDS_THAT_CAN_BE_NULL,
    OPTIONAL_CREATION_FIELDS,
    OPTIONAL_UPDATE_FIELDS,
    REQUIRED_CREATION_FIELDS,
    REQUIRED_UPDATE_FIELDS,
} from 'pages/Products/constants/csvFields';
import { defaultProduct } from 'pages/Products/constants/defaultProduct';
import { FLOWS } from 'pages/Products/constants/flows';
import { TVAs } from 'pages/Products/constants/tva';

import { ImportType } from '../CsvImport';

import { formatToNumber, isBoolean, isIdField, isTrue } from 'pages/Products/ProductImports/helpers/utils';
import { getErrorAsString } from 'helpers/getErrorAsString';

type CsvDataRow = Array<string>;
type CsvCheckReturn = {
    success: boolean;
    error?: string;
};

// TODO use TS enums instead
const allergenOptions = Object.keys(defaultProduct.allergen);
const deliveryDaysOptions = Object.keys(defaultProduct.deliveryDays);

export function checkProductCsvImport({
    csvData,
    importType,
}: {
    csvData: Array<CsvDataRow>;
    importType: ImportType;
}): CsvCheckReturn {
    try {
        const [initialHeader, ...data] = csvData;

        if (!data?.length || !initialHeader?.length) {
            throw Error('Veuillez vérifier le fichier CSV. Il semble être vide');
        }

        const requiredImportFields =
            importType === ImportType.CREATION ? REQUIRED_CREATION_FIELDS : REQUIRED_UPDATE_FIELDS;

        const productFields = (
            importType === ImportType.CREATION
                ? [...REQUIRED_CREATION_FIELDS, ...CONDITIONAL_FIELDS, ...OPTIONAL_CREATION_FIELDS]
                : [...REQUIRED_UPDATE_FIELDS, ...CONDITIONAL_FIELDS, ...OPTIONAL_UPDATE_FIELDS]
        ).map(({ name }: { name: string }) => name.toLowerCase());

        const arrayLength = initialHeader.length;

        //if extra empty line in 2 column file, last line becomes - [''] - only one element, breaking the file format
        if (data[data.length - 1].length === 1 && data[data.length - 1][0] === '') {
            throw Error(
                "Fichier CSV mal formaté. Veuillez vérifier que vous n'avez pas laissé de lignes ou de colonnes vides",
            );
        }

        data.forEach((productArrayFromCSV: CsvDataRow, rowIndex: number): void => {
            if (productArrayFromCSV.length !== arrayLength) {
                throw Error(
                    `Veuillez vérifier le fichier CSV. Mauvais nombre de colonnes sur la ligne ${rowIndex + 2}`,
                );
            }
        });

        //header fields validity check
        const nonValidFields: string[] = [];
        initialHeader.forEach((headerField, index) => {
            const fieldIndex = productFields.findIndex((elem) => elem === headerField.toLowerCase().trim());
            if (fieldIndex === -1) {
                if (importType === ImportType.UPDATE && isIdField(headerField)) {
                    initialHeader[index] = ID;
                    return;
                }
                nonValidFields.push(headerField);
            }
            if (index === initialHeader.length - 1 && nonValidFields.length) {
                throw Error(`Veuillez vérifier le fichier CSV. Champs ${nonValidFields.join(', ')} non reconnus`);
            }
        });

        //CSV header values are often sent with random capitalised letters
        const header = initialHeader.map((value) => value.toLowerCase().trim());
        const requiredFieldIndexes: number[] = [];

        //required field check
        for (const field of requiredImportFields) {
            const { name } = field;
            const index = header.findIndex((elem) => elem === name.toLowerCase());
            if (index === -1) {
                throw Error(`Veuillez vérifier le fichier CSV. Le champ ${name} est requis`);
            }
            requiredFieldIndexes.push(index);
        }

        //required field value check
        data.forEach((productArrayFromCSV: CsvDataRow, rowIndex: number): void => {
            requiredFieldIndexes.forEach((fieldIndex: number) => {
                const field = productArrayFromCSV[fieldIndex];
                if (!field) {
                    throw Error(
                        `Veuillez vérifier le fichier CSV. Champ obligatoire vide sur la ligne ${
                            rowIndex + 2
                        }, colonne ${fieldIndex + 1}`,
                    );
                }
            });
        });

        //non empty field check for update
        if (importType === ImportType.UPDATE) {
            for (const field of REQUIRED_CREATION_FIELDS) {
                const index = header.findIndex((elem) => elem === field.name.toLowerCase());
                if (index !== -1) {
                    data.forEach((productArrayFromCSV: CsvDataRow, rowIndex: number): void => {
                        const fieldValue = productArrayFromCSV[index];
                        if (!fieldValue) {
                            throw Error(
                                `Veuillez vérifier le fichier CSV. Champ ${
                                    field.name
                                } défini mais la valeur est vide sur la ligne ${rowIndex + 2}, colonne ${index + 1}`,
                            );
                        }
                    });
                }
            }
        }

        //conditional required field check, checked in api for update
        if (importType === ImportType.CREATION) {
            const displayKgIndex = header.findIndex((elem) => elem === 'displaykg');

            if (displayKgIndex !== -1) {
                const weightWithoutPackagingIndex = header.findIndex((elem) => elem === 'weightwithoutpackaging');
                data.forEach((productArrayFromCSV: CsvDataRow, rowIndex: number): void => {
                    if (
                        isTrue(productArrayFromCSV[displayKgIndex]) &&
                        (weightWithoutPackagingIndex === -1 || !productArrayFromCSV[weightWithoutPackagingIndex])
                    ) {
                        throw Error(
                            `Veuillez vérifier le fichier CSV. DisplayKg est true mais weightWithoutPackaging est vide sur la ligne ${
                                rowIndex + 2
                            }`,
                        );
                    }
                });
            }

            const isFreefoodIndex = header.findIndex((elem) => elem === 'isfreefood');
            const isPunctualIndex = header.findIndex((elem) => elem === 'ispunctual');

            if (isFreefoodIndex !== -1 || isPunctualIndex !== -1) {
                const priceB2BIndex = header.findIndex((elem) => elem === 'priceb2b');
                const crossedPriceB2BIndex = header.findIndex((elem) => elem === 'crossedpriceb2b');
                data.forEach((productArrayFromCSV: CsvDataRow, rowIndex: number): void => {
                    if (
                        (isTrue(productArrayFromCSV[isFreefoodIndex]) ||
                            isTrue(productArrayFromCSV[isPunctualIndex])) &&
                        (priceB2BIndex === -1 || !productArrayFromCSV[priceB2BIndex])
                    ) {
                        throw Error(
                            `Veuillez vérifier le fichier CSV. isFreefood ou is Punctual est true mais le priceB2B est vide sur la ligne ${
                                rowIndex + 2
                            }`,
                        );
                    }

                    if (
                        (isTrue(productArrayFromCSV[isFreefoodIndex]) ||
                            isTrue(productArrayFromCSV[isPunctualIndex])) &&
                        (priceB2BIndex === -1 || !productArrayFromCSV[priceB2BIndex]) &&
                        (crossedPriceB2BIndex !== -1 || productArrayFromCSV[crossedPriceB2BIndex])
                    ) {
                        throw Error(
                            'Veuillez vérifier le fichier CSV. le priceB2B est vide mais le prix barré est défini',
                        );
                    }
                });
            }

            const isMicrostore = header.findIndex((elem) => elem === 'ismicrostore');

            if (isMicrostore !== -1) {
                const priceB2CIndex = header.findIndex((elem) => elem === 'priceb2c');
                const crossedPriceB2CIndex = header.findIndex((elem) => elem === 'crossedpriceb2c');
                data.forEach((productArrayFromCSV: CsvDataRow, rowIndex: number): void => {
                    if (
                        isTrue(productArrayFromCSV[isMicrostore]) &&
                        (priceB2CIndex === -1 || !productArrayFromCSV[priceB2CIndex])
                    ) {
                        throw Error(
                            `Veuillez vérifier le fichier CSV. isMicrostore est true mais le priceB2C est vide sur la ligne ${
                                rowIndex + 2
                            }`,
                        );
                    }

                    if (
                        isTrue(productArrayFromCSV[isMicrostore]) &&
                        (priceB2CIndex === -1 || !productArrayFromCSV[priceB2CIndex]) &&
                        (crossedPriceB2CIndex !== -1 || productArrayFromCSV[crossedPriceB2CIndex])
                    ) {
                        throw Error(
                            'Veuillez vérifier le fichier CSV. le priceB2C est vide mais le prix barré est défini',
                        );
                    }
                });
            }
        }

        //type check
        BOOLEAN_FIELDS.forEach((field) => {
            const booleanFieldIndex = header.findIndex((elem) => elem === field.toLowerCase());
            if (booleanFieldIndex !== -1) {
                data.forEach((productArrayFromCSV: CsvDataRow, rowIndex: number): void => {
                    //don't check if field is empty as boolean field should not be empty
                    if (!isBoolean(productArrayFromCSV[booleanFieldIndex])) {
                        throw Error(
                            `Veuillez vérifier le fichier CSV. Champ ${field} devrait être true/false sur la ligne ${
                                rowIndex + 2
                            }`,
                        );
                    }
                });
            }
        });

        NUMERIC_FIELDS.forEach((field) => {
            const numericFieldIndex = header.findIndex((elem) => elem === field.toLowerCase());
            if (numericFieldIndex !== -1) {
                data.forEach((productArrayFromCSV: CsvDataRow, rowIndex: number): void => {
                    //check if field is empty
                    if (
                        productArrayFromCSV[numericFieldIndex] !== '' ||
                        (productArrayFromCSV[numericFieldIndex] === null &&
                            !NUMERIC_FIELDS_THAT_CAN_BE_NULL.includes(field))
                    ) {
                        const numericValue = formatToNumber(productArrayFromCSV[numericFieldIndex]);
                        if (
                            Number.isNaN(numericValue) ||
                            (Number(numericValue) <= 0 && !field.startsWith('nutrition'))
                        ) {
                            throw Error(
                                `Veuillez vérifier le fichier CSV. Champ ${field} devrait être un chiffre positif sur la ligne ${
                                    rowIndex + 2
                                }`,
                            );
                        }
                    }
                });
            }
        });

        header.forEach((headerField, index) => {
            if (
                ![
                    ...BOOLEAN_FIELDS.map((field) => field.toLowerCase()),
                    ...NUMERIC_FIELDS.map((field) => field.toLowerCase()),
                ].includes(headerField)
            ) {
                data.forEach((productArrayFromCSV: CsvDataRow, rowIndex: number): void => {
                    const dataField = productArrayFromCSV[index];

                    if (isBoolean(dataField)) {
                        throw Error(
                            `Veuillez vérifier le fichier CSV. Champ ${headerField} ne devrait pas être true/false sur la ligne ${
                                rowIndex + 2
                            }, colonne ${index + 1}`,
                        );
                    }
                });
            }
        });

        const flowIndex = header.findIndex((elem) => elem === 'flow');
        const tvaIndex = header.findIndex((elem) => elem === 'tva');
        const heightIndex = header.findIndex((elem) => elem === 'height');
        const widthIndex = header.findIndex((elem) => elem === 'width');
        const lengthIndex = header.findIndex((elem) => elem === 'length');
        const weightIndex = header.findIndex((elem) => elem === 'weight');
        const conditionningTotemIndex = header.findIndex((elem) => elem === 'conditionningtotem');
        const portionIndex = header.findIndex((elem) => elem === 'portion');
        const deliveryDaysIndex = header.findIndex((elem) => elem === 'deliverydays');
        const allergensIndex = header.findIndex((elem) => elem === 'allergens');

        data.forEach((productArrayFromCSV: CsvDataRow, rowIndex: number): void => {
            if (weightIndex !== -1) {
                const weight = formatToNumber(productArrayFromCSV[weightIndex]);

                if (weight > 10) {
                    throw Error(
                        `Un produit ne peut pas avoir un poids total supérieur à 10kg. Veuillez vérifier le champ 'weight', sur la ligne ${
                            rowIndex + 2
                        }`,
                    );
                }
            }

            if (heightIndex !== -1 && widthIndex !== -1 && lengthIndex !== -1) {
                const height = formatToNumber(productArrayFromCSV[heightIndex]);
                const width = formatToNumber(productArrayFromCSV[widthIndex]);
                const length = formatToNumber(productArrayFromCSV[lengthIndex]);

                if (height * width * length > 30000) {
                    throw Error(
                        `Un produit ne peut pas avoir un volume total supérieur à 30000cm3, vérifiez les champs PTL sur la ligne ${
                            rowIndex + 2
                        }`,
                    );
                }
            }

            if (conditionningTotemIndex !== -1 && portionIndex !== -1) {
                const conditioningTotem = formatToNumber(productArrayFromCSV[conditionningTotemIndex]);
                const portion = formatToNumber(productArrayFromCSV[portionIndex]);

                if (!Number.isInteger(conditioningTotem / portion)) {
                    throw Error(
                        `Le colisage TOTEM doit être un multiple du nombre de portion dans une pièce. Veuillez vérifier les champs 'conditionningTotem' et 'portion', sur la ligne ${
                            rowIndex + 2
                        }`,
                    );
                }
            }

            if (flowIndex !== -1) {
                if (productArrayFromCSV[flowIndex] && !FLOWS.includes(productArrayFromCSV[flowIndex].toLowerCase())) {
                    throw Error(
                        `Veuillez vérifier le fichier CSV. Champ 'flow' devrait être 'dry', 'fresh', 'bakery' ou 'fruit'  sur la ligne ${
                            rowIndex + 2
                        }`,
                    );
                }
            }

            if (tvaIndex !== -1) {
                if (
                    productArrayFromCSV[tvaIndex] &&
                    !TVAs.includes(formatToNumber(productArrayFromCSV[tvaIndex]) * 100)
                ) {
                    throw Error(
                        `Veuillez vérifier le fichier CSV. Champ 'tva' devrait être '0.055', '0.1' ou '0.2' sur la ligne ${
                            rowIndex + 2
                        }`,
                    );
                }
            }

            if (allergensIndex !== -1) {
                const allergens = productArrayFromCSV[allergensIndex];
                if (allergens) {
                    const valuesArray = allergens.split(',');
                    const allergensArray = valuesArray.map((value) => value.toLowerCase().trim());
                    allergensArray.forEach((allergen) => {
                        if (allergen && !allergenOptions.includes(allergen)) {
                            throw Error(
                                `Veuillez vérifier le fichier CSV. Variable ${allergen} non reconnu dans le champ 'allergens', ligne ${
                                    rowIndex + 2
                                }`,
                            );
                        }
                    });
                }
            }

            if (deliveryDaysIndex !== -1) {
                const deliveryDays = productArrayFromCSV[deliveryDaysIndex];
                if (deliveryDays) {
                    const valuesArray = deliveryDays.split(',');
                    const definedValuesArray = valuesArray.filter((value) => value);

                    if (definedValuesArray.length >= 5) {
                        throw Error(
                            `Veuillez vérifier le fichier CSV. Renseigner au moins un jour de livraison sur le champ 'deliveryDays', ligne ${
                                rowIndex + 2
                            }`,
                        );
                    }

                    const deliveryDaysArray = definedValuesArray.map((value) => value.toLowerCase().trim());
                    deliveryDaysArray.forEach((day) => {
                        if (!deliveryDaysOptions.includes(day)) {
                            throw Error(
                                `Veuillez vérifier le fichier CSV. Variable ${day} non reconnu dans le champ 'deliveryDays', ligne ${
                                    rowIndex + 2
                                }`,
                            );
                        }
                    });
                }
            }
        });

        return { success: true };
    } catch (error) {
        return { success: false, error: getErrorAsString(error) };
    }
}

import React from 'react';

import { CommonStatusTag } from 'components/CommonStatusTag';

import { colors } from 'constants/colors';

import { ProductState } from 'data/__generated__';
import { PRODUCTS_STATES_LABELS } from '../constants/states';

const getColorsFromState = (state: ProductState) => {
    const { green, lightGrey, yellow, black } = colors;

    switch (state) {
        case ProductState.Created:
            return { backgroundColor: yellow, labelColor: black };
        case ProductState.Regular:
            return { backgroundColor: green, labelColor: black };
        case ProductState.Archived:
            return { backgroundColor: lightGrey, labelColor: black };
    }
};

export const StatusTag = ({
    state,
    showHeading,
    sublabel,
}: {
    state: ProductState;
    showHeading?: boolean;
    sublabel?: string;
}) => {
    const { backgroundColor, labelColor } = getColorsFromState(state);
    return (
        <CommonStatusTag
            backgroundColor={backgroundColor}
            labelColor={labelColor}
            label={PRODUCTS_STATES_LABELS[state]}
            showHeading={showHeading}
            sublabel={sublabel}
        />
    );
};

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import styled from 'styled-components';

import { PAGES } from '../../constants/pages';

import { Header, HeaderTitle } from 'components/Header';
import { PageTitle } from 'components/PageTitle';
import { TotemDatePicker } from 'components/TotemDatePicker';
import { MicrostoreSnapshotView } from './MicrostoreSnapshotView';

type ParamTypes = {
    siteId: string;
};

export const MicrostoreSnapshot = () => {
    const { siteId = '' } = useParams<ParamTypes>();

    const [snapshotDate, setSnapshotDate] = useState<Date>(new Date());

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.microstoreSnapshot} />
                </HeaderTitle>

                <DateContainer>
                    <TotemDatePicker
                        label="Date"
                        selected={snapshotDate}
                        onChange={(date) => {
                            setSnapshotDate(date as Date);
                        }}
                    />
                </DateContainer>
            </Header>
            <MicrostoreSnapshotView siteId={siteId} snapshotDate={snapshotDate} />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const DateContainer = styled.div`
    min-width: 300px;
    justify-self: end;
`;

import React from 'react';

import { useMutation } from '@apollo/client';
import styled from 'styled-components';

import { CREATE_THERMOMETER_MUTATION } from 'data/mutations/thermometer';
import { CREATE_THERMOMETER, CREATE_THERMOMETERVariables } from 'data/mutations/__generated__/CREATE_THERMOMETER';

import { Loader, LoaderModeType } from 'components/Loader';
import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

import { thermometersUpdatedOrCreatedHandler } from '../cacheHandlers/thermometersCacheHandler';

export function CreateThermometerPopup({
    isOpen,
    setIsOpen,
    nextThermometerNumber,
}: {
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
    nextThermometerNumber: number;
}) {
    const [createThermometer, { loading: creationLoading }] = useMutation<
        CREATE_THERMOMETER,
        CREATE_THERMOMETERVariables
    >(CREATE_THERMOMETER_MUTATION, { variables: { number: nextThermometerNumber } });

    async function handleSubmit() {
        const { data } = await createThermometer();

        if (!data) {
            throw new Error('Une erreur est survenue lors de la mise à jour du thermomètre');
        }

        const { createThermometer: newThermometer } = data;

        thermometersUpdatedOrCreatedHandler([newThermometer]);

        setIsOpen(false);
    }

    return (
        <TotemPopup title="Ajouter un thermomètre" isOpen={isOpen} setIsOpen={setIsOpen} contentOverflow="visible">
            Voulez-vous ajouter le thermomètre numéro {nextThermometerNumber}
            <SubmitContainer>
                <ValidationButton onClick={handleSubmit}>
                    {creationLoading ? <Loader size="20px" mode={LoaderModeType.Spin} /> : 'Confirmer'}
                </ValidationButton>
            </SubmitContainer>
        </TotemPopup>
    );
}

const SubmitContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 15px;
`;

const ValidationButton = styled(TotemPrimaryButton)`
    margin-top: 15px;
`;

import { gql } from '@apollo/client';

import { OPS_PARAMETER_FRAGMENT } from '../fragments/opsParameter';

export const UPDATE_NUMERICAL_OPS_PARAMETER_MUTATION = gql`
    mutation UPDATE_NUMERICAL_OPS_PARAMETER($opsParameterId: String!, $value: Float!) {
        updateNumericalOpsParameter(opsParameterId: $opsParameterId, value: $value) {
            ...OpsParameterFragment
        }
    }
    ${OPS_PARAMETER_FRAGMENT}
`;

export const UPDATE_BOOLEAN_OPS_PARAMETER_MUTATION = gql`
    mutation UPDATE_BOOLEAN_OPS_PARAMETER($opsParameterId: String!, $value: Boolean!) {
        updateBooleanOpsParameter(opsParameterId: $opsParameterId, value: $value) {
            ...OpsParameterFragment
        }
    }
    ${OPS_PARAMETER_FRAGMENT}
`;

import React from 'react';
import styled from 'styled-components';
import { useFormContext, useFieldArray } from 'react-hook-form';

import { SupplierFormInput } from 'data/__generated__';

import { SectionColumn, SectionContainer, ColumnsSectionContainer } from 'components/DetailsView/Section';
import { DetailFormValue } from 'components/DetailsView/DetailFormValue';
import { TotemLabel } from 'components/TotemLabel';
import { MinRequirements } from '../components/MinRequirements';
import { OrderingProductTerms } from './OrderingProductTerms';

export const OrderingTerms = ({
    supplierId,
    supplierFormState,
    setSupplierFormState,
}: {
    supplierId: string | null;
    supplierFormState: SupplierFormInput;
    setSupplierFormState: React.Dispatch<React.SetStateAction<SupplierFormInput>>;
}) => {
    const { register } = useFormContext();

    const { fields } = useFieldArray({
        name: 'orderingTerms' as const,
    });

    return (
        <SectionContainer title="Coûts de livraison et conditions d'achat" isInitiallyOpen>
            <>
                <ColumnsSectionContainer numberOfColumns={2}>
                    {fields?.map(({ id }, termIndex) => {
                        return (
                            <SectionColumn key={id}>
                                <OrderingTerm>
                                    <TitleRow>
                                        <BigTotemLabel>Coût de livraison conditionné #{termIndex + 1}</BigTotemLabel>
                                    </TitleRow>
                                    <DetailFormValue
                                        label="Coût de la livraison (€)"
                                        sublabel="Est égal à 0 si vous définissez un franco, toute autre valeur définit un minimum de commande"
                                        {...register(`orderingTerms.${termIndex}.deliveryPrice`, {
                                            required: false,
                                            setValueAs: (value) => (!(value || value === 0) ? null : Number(value)),
                                        })}
                                        width="150px"
                                        type="number"
                                        step="0.01"
                                        min="0"
                                        data-test={`supplier-orderingTerms-${termIndex}-deliveryPrice`}
                                    />
                                    <SmallTitle>Conditions d'achat</SmallTitle>
                                    <Sublabel>
                                        Une de ces conditions doit être remplie pour qu'un achat puisse s'effectuer avec
                                        le coût de livraison renseigné ci-dessus.
                                    </Sublabel>
                                    <MinRequirements termIndex={termIndex} />
                                </OrderingTerm>
                            </SectionColumn>
                        );
                    })}
                </ColumnsSectionContainer>
                {supplierId ? (
                    <OrderingProductTerms
                        supplierId={supplierId}
                        setSupplierFormState={setSupplierFormState}
                        supplierFormState={supplierFormState}
                    />
                ) : null}
            </>
        </SectionContainer>
    );
};

const TitleRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const SmallTitle = styled(TotemLabel)`
    margin-top: 15px;
`;

const OrderingTerm = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: ${({ theme }) => theme.borderRadius};
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
    margin: 10px;
    padding: 10px;
`;

const BigTotemLabel = styled(TotemLabel)`
    font-size: 1.2em;
`;

const Sublabel = styled.span`
    color: ${({ theme }) => theme.infoTextColor};
    font-size: 13px;
`;

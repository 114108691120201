import gql from 'graphql-tag';

import { DELIVERY_FRAGMENT, UPDATE_STATE_RESULT_FRAGMENT } from '../fragments/delivery';

export const MARK_DELIVERIES_AS_DELIVERED_MUTATION = gql`
    mutation MARK_DELIVERIES_AS_DELIVERED($deliveryIds: [ID!]!) {
        markDeliveriesAsDelivered(deliveryIds: $deliveryIds) {
            ...UpdateStateResultFragment
        }
    }
    ${UPDATE_STATE_RESULT_FRAGMENT}
`;

export const MARK_DELIVERIES_AS_ABORTED_MUTATION = gql`
    mutation MARK_DELIVERIES_AS_ABORTED($deliveryIds: [ID!]!) {
        markDeliveriesAsAborted(deliveryIds: $deliveryIds) {
            ...UpdateStateResultFragment
        }
    }
    ${UPDATE_STATE_RESULT_FRAGMENT}
`;

export const MARK_DELIVERIES_AS_ARCHIVED_MUTATION = gql`
    mutation MARK_DELIVERIES_AS_ARCHIVED($deliveryIds: [ID!]!) {
        markDeliveriesAsArchived(deliveryIds: $deliveryIds) {
            ...UpdateStateResultFragment
        }
    }
    ${UPDATE_STATE_RESULT_FRAGMENT}
`;

export const MARK_DELIVERIES_AS_IN_PROGRESS_MUTATION = gql`
    mutation MARK_DELIVERIES_AS_IN_PROGRESS($deliveryIds: [ID!]!) {
        markDeliveriesAsInProgress(deliveryIds: $deliveryIds) {
            ...UpdateStateResultFragment
        }
    }
    ${UPDATE_STATE_RESULT_FRAGMENT}
`;

export const MARK_DELIVERIES_AS_PACKAGING_MUTATION = gql`
    mutation MARK_DELIVERIES_AS_PACKAGING($deliveryIds: [ID!]!) {
        markDeliveriesAsPackaging(deliveryIds: $deliveryIds) {
            ...UpdateStateResultFragment
        }
    }
    ${UPDATE_STATE_RESULT_FRAGMENT}
`;

export const MARK_DELIVERIES_AS_READY_MUTATION = gql`
    mutation MARK_DELIVERIES_AS_READY($deliveryIds: [ID!]!) {
        markDeliveriesAsReady(deliveryIds: $deliveryIds) {
            ...UpdateStateResultFragment
        }
    }
    ${UPDATE_STATE_RESULT_FRAGMENT}
`;

export const CREATE_DELIVERY_FOR_ORDER_MUTATION = gql`
    mutation CREATE_DELIVERY_FOR_ORDER($orderId: ID!) {
        createDeliveryForOrder(orderId: $orderId) {
            ...DeliveryFragment
        }
    }
    ${DELIVERY_FRAGMENT}
`;

export const UPDATE_DELIVERY_DATE_MUTATION = gql`
    mutation UPDATE_DELIVERY_DATE($deliveryId: ID!, $deliveryDate: String!) {
        updateDeliveryDate(deliveryId: $deliveryId, deliveryDate: $deliveryDate) {
            updatedDelivery {
                ...DeliveryFragment
            }
            errors
        }
    }
    ${DELIVERY_FRAGMENT}
`;

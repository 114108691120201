import React from 'react';
import { toast } from 'react-toastify';

import { GET_ALL_PRODUCTS_getAllProducts } from 'data/queries/__generated__/GET_ALL_PRODUCTS';

import { SelectedOption } from 'components/TotemCheckbox';
import { TotemSelect } from 'components/TotemSelect';
import { TotemCsvInputButton, CsvDataRow } from 'components/TotemCsvInputButton';

import { ProductArrangementFormValues, ProductArrangementProductWithStockMaxFormValues } from './FormHelper/DataMapper';
import styled from 'styled-components';

export const ProductArrangementProductSelector = ({
    productArrangementFormValues,
    setProductArrangementFormValuesAndIsFormDirty,
    products,
}: {
    productArrangementFormValues: ProductArrangementFormValues;
    setProductArrangementFormValuesAndIsFormDirty: ({
        newProductArrangementFormValues,
        newIsDirty,
    }: {
        newProductArrangementFormValues: ProductArrangementFormValues;
        newIsDirty?: boolean | undefined;
    }) => void;
    products: GET_ALL_PRODUCTS_getAllProducts[];
}) => {
    const productsOptions =
        products
            .map(({ _id, name, brand, volume }) => ({
                value: _id,
                label: `${name} - ${brand} - ${volume}`,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)) || [];

    function getProductArrangementFormValuesProductWithStockMaxFromId(
        productId: string,
        stockMaxUnsecured?: number,
        stockMaxSensei?: number,
    ): ProductArrangementProductWithStockMaxFormValues | null {
        const productForProductArrangement = products.find(({ _id }) => _id === productId);
        if (!productForProductArrangement) {
            return null;
        }

        const { _id, brand, fullname, height, imageUrl, isMicrostore, length, name, state, volume, weight, width } =
            productForProductArrangement;
        return {
            product: {
                _id,
                brand,
                fullname,
                height,
                imageUrl,
                isMicrostore,
                length,
                name,
                state,
                volume,
                weight,
                width,
            },
            stockMaxUnsecured: stockMaxUnsecured ?? null,
            stockMaxSensei: stockMaxSensei ?? null,
        };
    }

    function onSelect(option: SelectedOption) {
        if (!option) {
            return;
        }
        const newProductWithStockMax = getProductArrangementFormValuesProductWithStockMaxFromId(option.value);
        if (
            newProductWithStockMax &&
            !productArrangementFormValues.productsWithStockMax.some(
                ({ product }) => product._id === newProductWithStockMax.product._id,
            )
        ) {
            setProductArrangementFormValuesAndIsFormDirty({
                newProductArrangementFormValues: {
                    ...productArrangementFormValues,
                    productsWithStockMax: [
                        newProductWithStockMax,
                        ...productArrangementFormValues.productsWithStockMax,
                    ],
                },
            });
        }
    }

    function handleCSVImport(csvData: CsvDataRow[]) {
        const newProductsWithStockMax: ProductArrangementProductWithStockMaxFormValues[] = [];

        const [header, ...data] = csvData;
        const productIdIndex = header.findIndex((elem) => elem === 'productId');
        const stockMaxUnsecuredIndex = header.findIndex((elem) => elem === 'stockMaxUnsecured');
        const stockMaxSenseiIndex = header.findIndex((elem) => elem === 'stockMaxSensei');

        const numberOfFields = header.length;

        if (!data.length) {
            toast.error('Veuillez vérifier le fichier CSV. Il semble être vide');
            return;
        }

        //if extra line in 2 column file, corrups all data, last line - ['']
        if (data[data.length - 1].length === 1 && data[data.length - 1][0] === '') {
            toast.error(
                "Fichier CSV mal formaté. Veuillez vérifier que vous n'avez pas laissé de lignes ou de colonnes vides",
            );
            return;
        }

        if (productIdIndex === -1 || stockMaxUnsecuredIndex === -1 || stockMaxSenseiIndex === -1) {
            toast.error(
                "Veuillez vérifier les champs CSV. Il devrait y avoir 'productId' et 'stockMaxUnsecured' et 'stockMaxSensei'",
            );
            return;
        }

        data.forEach((productArrayFromCSV: Array<string>, rowIndex: number): void => {
            if (productArrayFromCSV.length !== numberOfFields) {
                toast.error(
                    `Veuillez vérifier le fichier CSV. Mauvais nombre de colonnes sur la ligne ${rowIndex + 2}`,
                );
                return;
            }
            productArrayFromCSV.forEach((field: string, fieldIndex: number) => {
                if (!field) {
                    toast.error(
                        `Veuillez vérifier le fichier CSV. Problème de champs sur la ligne ${rowIndex + 2}, colonne ${
                            fieldIndex + 1
                        }`,
                    );
                    return;
                }
            });
            const productId = productArrayFromCSV[productIdIndex];
            const stockMaxUnsecured = Number(productArrayFromCSV[stockMaxUnsecuredIndex]);
            const stockMaxSensei = Number(productArrayFromCSV[stockMaxSenseiIndex]);
            if (Number.isNaN(stockMaxUnsecured) || !Number.isInteger(stockMaxUnsecured)) {
                toast.error(
                    `Veuillez vérifier le fichier CSV. Quantité unsecured non valide sur la ligne ${rowIndex + 2}`,
                );
                return;
            }
            if (Number.isNaN(stockMaxSensei) || !Number.isInteger(stockMaxSensei)) {
                toast.error(
                    `Veuillez vérifier le fichier CSV. Quantité sensei non valide sur la ligne ${rowIndex + 2}`,
                );
                return;
            }
            if (
                newProductsWithStockMax.find(
                    ({ product: alreadyAddedProduct }) => productId === alreadyAddedProduct._id,
                )
            ) {
                toast.error(
                    `Vous avez rajouté plusieurs fois le même id de produit ${productId} sur la ligne ${rowIndex + 2}`,
                );
                return;
            }

            const newProductWithStockMax = getProductArrangementFormValuesProductWithStockMaxFromId(
                productId,
                stockMaxUnsecured,
                stockMaxSensei,
            );
            if (newProductWithStockMax) {
                newProductsWithStockMax.push(newProductWithStockMax);
            }
        });

        setProductArrangementFormValuesAndIsFormDirty({
            newProductArrangementFormValues: {
                ...productArrangementFormValues,
                productsWithStockMax: newProductsWithStockMax,
            },
        });

        toast.success(`Import réussi de ${newProductsWithStockMax.length} produits`);
    }

    // Todo use react virtualized or react window with select. Because there are too much products
    return (
        <Container>
            <SelectContainer>
                <TotemSelect
                    label="Ajouter un produit"
                    placeholder="Sélectionner un produit (recherche par nom ou par _id)"
                    value={null}
                    options={productsOptions}
                    onChange={(option: SelectedOption) => onSelect(option)}
                />
            </SelectContainer>

            <ButtonContainer>
                <TotemCsvInputButton onCsvDataUpload={handleCSVImport} />
            </ButtonContainer>
        </Container>
    );
};

const Container = styled.span`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
`;

const SelectContainer = styled.div`
    flex-grow: 1;
`;

const ButtonContainer = styled.div`
    margin-left: 20px;
`;

import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';

import { toast } from 'react-toastify';
import styled from 'styled-components';
import { FaSave, FaTrash } from 'react-icons/fa';

import { colors } from 'constants/colors';
import {
    GET_PRODUCTS_FROM_CSV,
    GET_PRODUCTS_FROM_CSVVariables,
} from 'data/queries/__generated__/GET_PRODUCTS_FROM_CSV';
import { GET_PRODUCTS_FROM_CSV_QUERY, GET_PRODUCT_PRICE_RANGE_QUERY } from 'data/queries/price';
import {
    GET_PRODUCT_PRICE_RANGE,
    GET_PRODUCT_PRICE_RANGEVariables,
} from 'data/queries/__generated__/GET_PRODUCT_PRICE_RANGE';
import { UPDATE_PRICE_RANGE_MUTATION } from 'data/mutations/price';
import { UPDATE_PRICE_RANGE, UPDATE_PRICE_RANGEVariables } from 'data/mutations/__generated__/UPDATE_PRICE_RANGE';

import { apolloClient } from '../../App';
import { ProductEntry, ProductPriceRangeType, SelectedProduct } from './ProductPriceRangeCreate';
import { ProductsPriceTable } from './ProductsPriceTable';
import { PriceRangeProductSelector } from './PriceRangeProductSelector';

import { DetailFormValue } from 'components/DetailsView/DetailFormValue';
import { Header, HeaderTitle } from 'components/Header';
import { Loader, LoaderModeType } from 'components/Loader';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { TotemCsvInputButton, CsvDataRow } from 'components/TotemCsvInputButton';

import { checkProductRangeCsvImport } from '../../helpers/CsvCheckers/checkProductRangeCsvImport';
import { DEFAULT_RANGE_IDS } from 'constants/prices';
type ParamTypes = {
    rangeId: string;
};

export const ProductPriceRangeDetails = () => {
    const { rangeId = '' } = useParams<ParamTypes>();
    const [csvData, setCsvData] = useState<Array<CsvDataRow>>([]);
    const [selectedProducts, setSelectedProducts] = useState<SelectedProduct[]>([]);
    const {
        loading: priceRangeLoading,
        data: priceRangeData,
        error: priceRangeError,
    } = useQuery<GET_PRODUCT_PRICE_RANGE, GET_PRODUCT_PRICE_RANGEVariables>(GET_PRODUCT_PRICE_RANGE_QUERY, {
        variables: {
            rangeId,
        },
    });
    const [getProductsFromCsv, { loading: loadingCsv }] = useLazyQuery<
        GET_PRODUCTS_FROM_CSV,
        GET_PRODUCTS_FROM_CSVVariables
    >(GET_PRODUCTS_FROM_CSV_QUERY, {
        fetchPolicy: 'cache-and-network',
        onCompleted({ productsFromCsv }) {
            const { productsForRange, idsNotFound, idsNotActive } = productsFromCsv;
            const productsToAddToSelection = [];
            const existingProductValues = getValues('products');

            for (const product of productsForRange) {
                const { _id, name, price } = product;
                if (price) {
                    if (selectedProducts.some(({ productId }) => productId === _id)) {
                        const index = existingProductValues.map(({ productId }) => productId).indexOf(_id);
                        setValue(`products.${index}.price` as 'products.0.price', price, { shouldDirty: true });
                    } else {
                        productsToAddToSelection.push({ productId: _id, name });
                        append({ productId: _id, price } as ProductEntry);
                    }
                }
            }
            setSelectedProducts([...selectedProducts, ...productsToAddToSelection]);

            if (idsNotFound.length) {
                let message = 'Ids des produits pas trouvés: ';
                message += idsNotFound.join(', ');
                toast.warn(message, { closeOnClick: false, autoClose: false });
            }

            if (idsNotActive.length) {
                let message = 'Ids des produits en état non-actif: ';
                message += idsNotActive.join(', ');
                toast.warn(message, { closeOnClick: false, autoClose: false });
            }
        },
    });

    const [updatePriceRange, { loading: updatePriceRangeLoading }] = useMutation<
        UPDATE_PRICE_RANGE,
        UPDATE_PRICE_RANGEVariables
    >(UPDATE_PRICE_RANGE_MUTATION);

    const methods = useForm<ProductPriceRangeType>({ shouldUnregister: false });
    const {
        control,
        getValues,
        formState: { isDirty },
        handleSubmit,
        register,
        reset,
        setValue,
    } = methods;
    const { fields, append, remove } = useFieldArray({ control, name: 'products' });

    useEffect(() => {
        if (priceRangeData?.productPriceRange) {
            const { products } = priceRangeData.productPriceRange;
            const previouslySelectedProducts = products.map(({ productId, name }) => ({ productId, name }));
            setSelectedProducts(previouslySelectedProducts);
            const selectedPriceProducts = products.map(({ productId, price, crossedPrice }) => ({
                productId,
                price,
                crossedPrice,
            }));
            reset({ products: selectedPriceProducts });
        }
    }, [priceRangeData, append, reset]);

    const handleCSVImport = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        const { success, error } = checkProductRangeCsvImport({ csvData });
        if (!success) {
            setCsvData([]);
            toast.error(error, { autoClose: false });
            return;
        }

        const [header, ...data] = csvData;
        const productIdIndex = header.findIndex((elem) => elem === 'productId');
        const priceIndex = header.findIndex((elem) => elem === 'price');

        const csvDataFormatted = data.map((rowArray) => {
            const price = Number(rowArray[priceIndex].replace(',', '.'));
            return { productId: rowArray[productIdIndex], price };
        });

        await getProductsFromCsv({
            variables: {
                csvData: csvDataFormatted,
            },
        });
    };

    function removeProduct(index: number): void {
        remove(index);
        setSelectedProducts(selectedProducts.filter((_, selectedProductIndex) => selectedProductIndex !== index));
    }

    const onSubmit = handleSubmit(async (formData) => {
        try {
            const { rangeName, products } = formData;

            if (!products?.length) {
                toast.error('Une gamme doit contenir au moins 1 produit');
                return null;
            }

            reset({ products });

            const result = await updatePriceRange({
                variables: { rangeName, rangeId, products },
            });

            const priceRangeResult = result?.data?.updatePriceRange;

            if (priceRangeResult) {
                const { success, error } = priceRangeResult;
                if (!success && error) {
                    toast.error(error);
                } else {
                    const productsInCache = products.map(({ productId, price }) => {
                        const productName = fields.find((field) => field.productId === productId)?.name;
                        return { productId, price, name: productName };
                    });

                    const data = {
                        productPriceRange: {
                            name: rangeName,
                            products: productsInCache,
                        },
                    };

                    apolloClient.writeQuery({
                        query: GET_PRODUCT_PRICE_RANGE_QUERY,
                        variables: {
                            rangeId,
                        },
                        data,
                    });

                    toast.success('La gamme a été modifiée !');
                }
            }
        } catch (error) {
            toast.error('Une erreur est survenue.');
        }
    });

    if (priceRangeLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (priceRangeError || !priceRangeData) {
        toast.error('Une erreur est survenue lors de la récupération de la gamme');
        return null;
    }

    const { productPriceRange } = priceRangeData;
    const isDefaultRange = DEFAULT_RANGE_IDS.includes(rangeId);

    return (
        <FormProvider {...methods}>
            <Form onSubmit={onSubmit}>
                <Header>
                    <HeaderTitle>{productPriceRange?.name}</HeaderTitle>
                    <ButtonsContainer>
                        {!isDefaultRange ? (
                            <>
                                {csvData.length ? (
                                    <ButtonsContainer>
                                        <TotemPrimaryButton onClick={() => setCsvData([])}>
                                            <FaTrash data-test="trash-icon" size={12} color={colors.pureWhite} />
                                        </TotemPrimaryButton>
                                        <TotemPrimaryButton minWidth="105px" onClick={(e) => handleCSVImport(e)}>
                                            {loadingCsv || updatePriceRangeLoading ? (
                                                <Loader size="18px" mode={LoaderModeType.Spin} />
                                            ) : (
                                                'Import CSV'
                                            )}
                                        </TotemPrimaryButton>
                                    </ButtonsContainer>
                                ) : (
                                    <TotemCsvInputButton onCsvDataUpload={setCsvData} />
                                )}
                                <TotemPrimaryButton type="submit" disabled={!isDirty}>
                                    {loadingCsv || updatePriceRangeLoading ? (
                                        <Loader size="18px" mode={LoaderModeType.Spin} />
                                    ) : (
                                        <>
                                            <FaSave size="13" />
                                            <SaveLabel>Mettre à jour</SaveLabel>
                                        </>
                                    )}
                                </TotemPrimaryButton>
                            </>
                        ) : null}
                        <Link to="/productPriceRanges">
                            <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                        </Link>
                    </ButtonsContainer>
                </Header>
                <Content>
                    <Fields>
                        <DetailFormValue
                            label="Nom de la gamme"
                            placeholder="Gamme A, prix Pantin, prix Murex ..."
                            defaultValue={productPriceRange?.name}
                            disabled={isDefaultRange}
                            width="100%"
                            {...register('rangeName' as const, { required: true })}
                        />
                        {isDefaultRange ? (
                            <div>
                                <b>NB:</b> Les champs dans les gammes par défaut ne sont pas modifiables. Veuillez
                                mettre les prix des produits à jour soit individuellement sur leur propres pages
                                produit, soit par
                                <StyledLink to="/product/imports">import csv</StyledLink>
                                pour plusieurs produits (champs priceB2B, crossedPriceB2B ou priceB2C, crossedPriceB2C).
                            </div>
                        ) : null}
                        <PriceRangeProductSelector
                            append={append}
                            selectedProducts={selectedProducts}
                            setSelectedProducts={setSelectedProducts}
                            shouldDisplaySelect={!isDefaultRange}
                        />
                    </Fields>
                    {fields.length ? (
                        <ProductsPriceTable
                            selectedProducts={selectedProducts}
                            fields={fields}
                            removeProduct={removeProduct}
                            rangeId={rangeId}
                        />
                    ) : null}
                </Content>
            </Form>
        </FormProvider>
    );
};

const SaveLabel = styled.span`
    margin-left: 5px;
`;

const ButtonsContainer = styled.div`
    display: flex;
    width: max-content;
    align-items: center;

    ${TotemPrimaryButton} {
        margin-left: 5px;
    }
`;

const Container = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    background: ${({ theme }) => theme.backgroundColor};
`;

const Content = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    flex: 1;
    padding: 15px;
    overflow-y: hidden;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    background: ${({ theme }) => theme.backgroundColor};
`;

const Fields = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

const StyledLink = styled(Link)`
    color: ${({ theme }) => theme.ctaPrimaryHoveredColor};
    margin: 0 5px;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

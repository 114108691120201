import { TransactionState, PaymentIntentState } from 'data/__generated__';

export const TRANSACTIONS_STATES_LABELS = {
    [TransactionState.Pending]: 'En attente (Pending)',
    [TransactionState.Processing]: 'En traitement (Processing)',
    [TransactionState.Paid]: 'Payée (Paid)',
    [TransactionState.Failed]: 'Echouée (Failed)',
    [TransactionState.Refunded]: 'Remboursée (Refunded)',
    [TransactionState.Canceled]: 'Annulée (Canceled)',
};

export const PAYMENT_METHOD_STATUS_LABELS = {
    [PaymentIntentState.Canceled]: 'Annulé (Canceled)',
    [PaymentIntentState.Captured]: 'Payé (Captured)',
    [PaymentIntentState.Failed]: 'Refusé (Failed)',
    [PaymentIntentState.Processing]: 'Paiement en cours (Processing)',
    [PaymentIntentState.Refunded]: 'Remboursé (Refunded)',
    [PaymentIntentState.Waiting]: 'En attente (Waiting)',
};

import React from 'react';

import styled from 'styled-components';

import { colors } from 'constants/colors';

import { TotemPopup } from 'components/TotemPopup';
import { DetailValue } from 'components/DetailsView';
import { ColumnsSectionContainer, SectionColumn } from 'components/DetailsView/Section';
import { ProductWithStockVariations_availableStock_details } from 'data/fragments/__generated__/ProductWithStockVariations';

export function AvailableStocksDetailsPopup({
    isOpen,
    setIsOpen,
    details,
}: {
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
    details: ProductWithStockVariations_availableStock_details | null;
}) {
    if (!details) {
        return (
            <TotemPopup
                title="Détails des variations de stock"
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                contentOverflow="hidden"
            >
                <NoDetailsMessage>Pas de détail pour ce stock disponible</NoDetailsMessage>{' '}
            </TotemPopup>
        );
    }

    const {
        dailyStocks,
        orderedStocks,
        predictionPunctualOrders,
        predictionRecurringOrders,
        predictionStoreTarget,
        preparationDate,
        preparationDateUsedIndex,
        realPunctualOrders,
        realRecurringOrders,
        realStoreTargetNotPrepared,
        realStoreTargetPrepared,
        soonestRestockedDate,
        soonestRestockedDateUsedIndex,
        storeMenu,
        usedPunctualOrders,
        usedRecurringOrders,
        usedStoreMenu,
        usedStoreTarget,
    } = details;

    return (
        <TotemPopup
            title="Détails des variations de stock"
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            contentOverflow="hidden"
        >
            <ScrollContainer>
                <ColumnsSectionContainer numberOfColumns={3}>
                    <SectionColumn>
                        <DetailValue label="Jour de prépa" value={preparationDate} />
                        <DetailValue label="Jour de prépa index" value={preparationDateUsedIndex} />
                    </SectionColumn>
                    <SectionColumn>
                        <DetailValue label="Prochaine livraison possible" value={soonestRestockedDate ?? 'NA'} />
                        <DetailValue label="Prochaine livraison possible index" value={soonestRestockedDateUsedIndex} />
                    </SectionColumn>
                </ColumnsSectionContainer>
                <TableContainer>
                    <Table>
                        <TableRow>
                            <b>
                                <RowTitle>Commandes récurrentes</RowTitle>
                            </b>
                        </TableRow>
                        <TableRow>
                            <i>
                                <RowTitle>prévisions</RowTitle>
                            </i>
                            {predictionRecurringOrders.map((stockDelta: number, index: number) => {
                                return (
                                    <ProductsInfoStockDeltaTableCell key={index} color={colors.red}>
                                        {stockDelta ? '-' + stockDelta.toFixed(1) : ''}
                                    </ProductsInfoStockDeltaTableCell>
                                );
                            })}
                        </TableRow>
                        <TableRow>
                            <i>
                                <RowTitle>existantes</RowTitle>
                            </i>
                            {realRecurringOrders.map((stockDelta, index: number) => {
                                return (
                                    <ProductsInfoStockDeltaTableCell key={index} color={colors.red}>
                                        {stockDelta ? '-' + stockDelta.toFixed(1) : ''}
                                    </ProductsInfoStockDeltaTableCell>
                                );
                            })}
                        </TableRow>
                        <TableRow>
                            <RowTitle>pris en compte dans le calcul</RowTitle>
                            {usedRecurringOrders.map((stockDelta, index: number) => {
                                return (
                                    <ProductsInfoStockDeltaTableCell key={index} color={colors.blue}>
                                        {'-' + stockDelta.toFixed(1)}
                                    </ProductsInfoStockDeltaTableCell>
                                );
                            })}
                        </TableRow>
                        <Separator />
                        <TableRow>
                            <b>
                                <RowTitle>Commandes ponctuelles</RowTitle>
                            </b>
                        </TableRow>
                        <TableRow>
                            <i>
                                <RowTitle>prévisions</RowTitle>
                            </i>
                            {predictionPunctualOrders.map((stockDelta, index: number) => {
                                return (
                                    <ProductsInfoStockDeltaTableCell key={index} color={colors.red}>
                                        {stockDelta ? '-' + stockDelta.toFixed(1) : ''}
                                    </ProductsInfoStockDeltaTableCell>
                                );
                            })}
                        </TableRow>
                        <TableRow>
                            <i>
                                <RowTitle>existantes</RowTitle>
                            </i>
                            {realPunctualOrders.map((stockDelta, index: number) => {
                                return (
                                    <ProductsInfoStockDeltaTableCell key={index} color={colors.red}>
                                        {stockDelta ? '-' + stockDelta.toFixed(1) : ''}
                                    </ProductsInfoStockDeltaTableCell>
                                );
                            })}
                        </TableRow>
                        <TableRow>
                            <RowTitle>pris en compte dans le calcul</RowTitle>
                            {usedPunctualOrders.map((stockDelta, index: number) => {
                                return (
                                    <ProductsInfoStockDeltaTableCell key={index} color={colors.blue}>
                                        {'-' + stockDelta.toFixed(1)}
                                    </ProductsInfoStockDeltaTableCell>
                                );
                            })}
                        </TableRow>
                        <Separator />
                        <TableRow>
                            <b>
                                <RowTitle>Store target</RowTitle>
                            </b>
                        </TableRow>
                        <TableRow>
                            <i>
                                <RowTitle>prévisions Store Dry</RowTitle>
                            </i>
                            {predictionStoreTarget.map((stockDelta: number, index: number) => {
                                return (
                                    <ProductsInfoStockDeltaTableCell key={index} color={colors.red}>
                                        {stockDelta ? '-' + stockDelta.toFixed(1) : ''}
                                    </ProductsInfoStockDeltaTableCell>
                                );
                            })}
                        </TableRow>
                        <TableRow>
                            <i>
                                <RowTitle>validés non Préparés</RowTitle>
                            </i>
                            {realStoreTargetNotPrepared.map((stockDelta: number, index: number) => {
                                return (
                                    <ProductsInfoStockDeltaTableCell key={index} color={colors.red}>
                                        {stockDelta ? '-' + stockDelta.toFixed(1) : ''}
                                    </ProductsInfoStockDeltaTableCell>
                                );
                            })}
                        </TableRow>
                        <TableRow>
                            <i>
                                <RowTitle>validés Préparés</RowTitle>
                            </i>
                            {realStoreTargetPrepared.map((stockDelta: number, index: number) => {
                                return (
                                    <ProductsInfoStockDeltaTableCell key={index} color={colors.red}>
                                        {stockDelta ? '-' + stockDelta.toFixed(1) : ''}
                                    </ProductsInfoStockDeltaTableCell>
                                );
                            })}
                        </TableRow>
                        <TableRow>
                            <RowTitle>pris en compte dans le calcul</RowTitle>
                            {usedStoreTarget.map((stockDelta: number, index: number) => {
                                return (
                                    <ProductsInfoStockDeltaTableCell key={index} color={colors.blue}>
                                        {'-' + stockDelta.toFixed(1)}
                                    </ProductsInfoStockDeltaTableCell>
                                );
                            })}
                        </TableRow>
                        <Separator />
                        <TableRow>
                            <b>
                                <RowTitle>Store menu</RowTitle>
                            </b>
                        </TableRow>
                        <TableRow>
                            <i>
                                <RowTitle>prévisions Store Menu</RowTitle>
                            </i>
                            {storeMenu.map((stockDelta: number, index: number) => {
                                return (
                                    <ProductsInfoStockDeltaTableCell key={index} color={colors.red}>
                                        {stockDelta ? '-' + stockDelta.toFixed(1) : ''}
                                    </ProductsInfoStockDeltaTableCell>
                                );
                            })}
                        </TableRow>
                        <TableRow>
                            <RowTitle>pris en compte dans le calcul</RowTitle>
                            {usedStoreMenu.map((stockDelta: number, index: number) => {
                                return (
                                    <ProductsInfoStockDeltaTableCell key={index} color={colors.blue}>
                                        {'-' + stockDelta.toFixed(1)}
                                    </ProductsInfoStockDeltaTableCell>
                                );
                            })}
                        </TableRow>
                        <Separator />
                        <Separator />
                        <TableRow>
                            <b>
                                <RowTitle>Com. Fournisseurs</RowTitle>
                            </b>
                            {orderedStocks.map((stockDelta: number, index: number) => {
                                return (
                                    <ProductsInfoStockDeltaTableCell key={index} color={colors.greenDarker}>
                                        {stockDelta ? `+${stockDelta}` : ''}
                                    </ProductsInfoStockDeltaTableCell>
                                );
                            })}
                        </TableRow>
                        <Separator />
                        <TableRow>
                            <b>
                                <RowTitle>Stock en fin de journée</RowTitle>
                            </b>
                            {dailyStocks.map((dailyStock: number, index: number) => {
                                return (
                                    <ProductsInfoStockVariationTableCell key={index}>
                                        {index === preparationDateUsedIndex && '['} {dailyStock}{' '}
                                        {index === soonestRestockedDateUsedIndex - 1 && ']'}
                                    </ProductsInfoStockVariationTableCell>
                                );
                            })}
                        </TableRow>
                    </Table>
                </TableContainer>
            </ScrollContainer>
        </TotemPopup>
    );
}

const ScrollContainer = styled.div`
    overflow: auto;
`;

const TableContainer = styled.div`
    margin: 15px 0;
`;

const Table = styled.tbody`
    display: table;
    width: 100%;
`;

const TableRow = styled.tr`
    height: 25px;
`;

const RowTitle = styled.td`
    color: ${({ theme }) => theme.textColor};
`;

const ProductsInfoStockDeltaTableCell = styled.td`
    text-align: center;
    padding: 0 10px;
    color: ${({ theme, color }) => color || theme.textColor};
`;

const ProductsInfoStockVariationTableCell = styled.td`
    text-align: center;
    padding: 0 10px;
`;

const Separator = styled.tr`
    width: 100%;
    height: 5px;
    border-top: 1px solid ${({ theme }) => theme.textColor};
`;

const NoDetailsMessage = styled.div`
    color: ${({ theme }) => theme.errorColor};
`;

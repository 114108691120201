import { Header, HeaderTitle } from 'components/Header';
import { PageTitle } from 'components/PageTitle';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { PAGES } from 'constants/pages';
import {
    SupplierDiscountsQuery,
    SupplierDiscountState,
    useSupplierDiscountsQuery,
    useUpdateSupplierDiscountStateMutation,
} from 'data/__generated__';
import { formatDateAsAnniversary } from 'helpers/dateTimes';
import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';

import { formatPrice } from '@totem/constants';
import { CreateSupplierDiscountModal } from './components/CreateSupplierDiscountModal';
import { UpdateSupplierDiscountModal } from './components/UpdateSupplierDiscountModal';
import { colors } from 'constants/colors';
import { round } from 'helpers/price';
import { DeleteConfirmationModal } from './components/DeleteConfirmationModal';
import { TotemImage } from 'components/TotemImage';

const PRICE_FLEX_VALUE = 0.4;

const { greenDarker, darkGrey } = colors;

export const SupplierDiscountsPage: FunctionComponent = () => {
    const { data } = useSupplierDiscountsQuery();

    const supplierDiscounts = [...(data?.supplierDiscounts ?? [])].sort((a, b) =>
        a.salesStartDate > b.salesStartDate ? 1 : -1,
    );

    const [isCreateSupplierDiscountModalOpen, setIsCreateSupplierDiscountModalOpen] = useState(false);
    const [isUpdateSupplierDiscountModalOpen, setIsUpdateSupplierDiscountModalOpen] = useState(false);
    const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);

    const [selectedSupplierDiscount, setSelectedSupplierDiscount] = useState<
        SupplierDiscountsQuery['supplierDiscounts'][number] | null
    >(null);

    const handleUpdateSupplierDiscount = (supplierDiscount: SupplierDiscountsQuery['supplierDiscounts'][number]) => {
        setSelectedSupplierDiscount(supplierDiscount);
        setIsUpdateSupplierDiscountModalOpen(true);
    };

    const handleDeleteSupplierDiscount = (supplierDiscount: SupplierDiscountsQuery['supplierDiscounts'][number]) => {
        setSelectedSupplierDiscount(supplierDiscount);
        setIsDeleteConfirmationModalOpen(true);
    };

    const [updateSupplierDiscountState] = useUpdateSupplierDiscountStateMutation();

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.supplierDiscounts} />
                </HeaderTitle>
                <TotemPrimaryButton onClick={() => setIsCreateSupplierDiscountModalOpen(true)}>
                    Ajouter une remise fournisseur
                </TotemPrimaryButton>
            </Header>
            <Content>
                <TableContainer>
                    <TableRow style={{ padding: '0px 8px' }}>
                        <TableCell flex={2} bold>
                            Nom
                        </TableCell>
                        <TableCell bold>Période d'achat</TableCell>
                        <TableCell bold>Période de vente</TableCell>
                        <TableCell flex={0.5} bold>
                            Remise
                        </TableCell>
                        <TableCell flex={1} bold>
                            Créé le
                        </TableCell>
                        <TableCell bold />
                    </TableRow>
                    {supplierDiscounts.map((supplierDiscount) => {
                        const { discountedProductPrices } = supplierDiscount;

                        return (
                            <TableElement key={supplierDiscount._id}>
                                <TableRow key={supplierDiscount._id}>
                                    <TableCell flex={2} style={{ fontWeight: 'bold', gap: 8 }}>
                                        {supplierDiscount.name}
                                        <StatusBadge state={supplierDiscount.state}>
                                            {supplierDiscount.state === SupplierDiscountState.Regular
                                                ? 'Activé'
                                                : 'Désactivé'}
                                        </StatusBadge>
                                    </TableCell>
                                    <TableCell bold>
                                        {`${formatDateAsAnniversary({ dateTime: new Date(supplierDiscount.purchaseStartDate) })} → ${formatDateAsAnniversary({ dateTime: new Date(supplierDiscount.purchaseEndDate) })}`}
                                    </TableCell>
                                    <TableCell bold>
                                        {`${formatDateAsAnniversary({ dateTime: new Date(supplierDiscount.salesStartDate) })} → ${formatDateAsAnniversary({ dateTime: new Date(supplierDiscount.salesEndDate) })}`}
                                    </TableCell>
                                    <TableCell flex={0.5} bold>{`${round(supplierDiscount.rate * 100, 2)}%`}</TableCell>
                                    <TableCell flex={1}>
                                        {new Date(supplierDiscount.createdAt).toLocaleString('fr-FR')}
                                    </TableCell>
                                    <TableCell style={{ gap: 4 }}>
                                        <button onClick={() => handleUpdateSupplierDiscount(supplierDiscount)}>
                                            Modifier
                                        </button>
                                        <button
                                            onClick={() => {
                                                updateSupplierDiscountState({
                                                    variables: {
                                                        id: supplierDiscount._id,
                                                        state:
                                                            supplierDiscount.state === SupplierDiscountState.Regular
                                                                ? SupplierDiscountState.Archived
                                                                : SupplierDiscountState.Regular,
                                                    },
                                                });
                                            }}
                                        >
                                            {supplierDiscount.state === SupplierDiscountState.Regular
                                                ? 'Désactiver'
                                                : 'Activer'}
                                        </button>
                                        <button
                                            onClick={() => {
                                                handleDeleteSupplierDiscount(supplierDiscount);
                                            }}
                                        >
                                            Supp.
                                        </button>
                                    </TableCell>
                                </TableRow>
                                <ProductsContainer style={{ textAlign: 'left', color: 'grey', fontSize: 13 }}>
                                    <TableRow>
                                        <TableCell bold flex={1}>
                                            ID
                                        </TableCell>
                                        <TableCell bold flex={2}>
                                            Nom
                                        </TableCell>
                                        <TableCell bold flex={1}>
                                            Catégorie
                                        </TableCell>
                                        <TableCell bold flex={1}>
                                            Sous-catégorie
                                        </TableCell>
                                        <TableCell bold flex={1}>
                                            Fournisseur
                                        </TableCell>
                                        <TableCell bold flex={PRICE_FLEX_VALUE}>
                                            PA
                                        </TableCell>
                                        <TableCell bold flex={PRICE_FLEX_VALUE}>
                                            PA Rem.
                                        </TableCell>
                                        <TableCell bold flex={PRICE_FLEX_VALUE}>
                                            PV
                                        </TableCell>
                                        <TableCell bold flex={PRICE_FLEX_VALUE}>
                                            PV Rem.
                                        </TableCell>
                                        <TableCell bold flex={PRICE_FLEX_VALUE}>
                                            % Rem
                                        </TableCell>
                                        <TableCell bold flex={PRICE_FLEX_VALUE}>
                                            Δ
                                        </TableCell>
                                    </TableRow>
                                    {[...discountedProductPrices]
                                        .sort((a, b) => (a.product.fullname < b.product.fullname ? -1 : 1))
                                        .map((discountedProductPrice) => (
                                            <TableRow key={discountedProductPrice.product._id}>
                                                <TableCell flex={1}>{discountedProductPrice.product._id}</TableCell>
                                                <TableCell flex={2}>
                                                    {discountedProductPrice.product.fullname}
                                                </TableCell>
                                                <TableCell flex={1}>
                                                    {discountedProductPrice.product.category.title}
                                                </TableCell>
                                                <TableCell flex={1}>
                                                    {discountedProductPrice.product.subcategory?.name}
                                                </TableCell>
                                                <TableCell flex={1}>
                                                    {discountedProductPrice.product.suppliers[0]?.name}
                                                </TableCell>
                                                <TableCell flex={PRICE_FLEX_VALUE}>
                                                    {formatPrice(discountedProductPrice.purchasePrice)}
                                                </TableCell>
                                                <TableCell flex={PRICE_FLEX_VALUE}>
                                                    {formatPrice(discountedProductPrice.purchaseDiscountedPrice)}
                                                </TableCell>
                                                <TableCell flex={PRICE_FLEX_VALUE}>
                                                    {formatPrice(discountedProductPrice.salesPrice)}
                                                </TableCell>
                                                <TableCell flex={PRICE_FLEX_VALUE}>
                                                    {formatPrice(discountedProductPrice.salesDiscountedPrice)}
                                                </TableCell>
                                                <TableCell flex={PRICE_FLEX_VALUE}>
                                                    {`${round(discountedProductPrice.salesDiscountRate * 100, 2)}%`}
                                                </TableCell>
                                                <TableCell flex={PRICE_FLEX_VALUE}>
                                                    {formatPrice(discountedProductPrice.salesDiscountAmount)}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </ProductsContainer>
                                {supplierDiscount.imageUrl && (
                                    <TotemImage
                                        src={supplierDiscount.imageUrl ?? null}
                                        alt={supplierDiscount.name}
                                        size={100}
                                    />
                                )}
                            </TableElement>
                        );
                    })}
                </TableContainer>
            </Content>
            <CreateSupplierDiscountModal
                isOpen={isCreateSupplierDiscountModalOpen}
                setIsOpen={(arg) => setIsCreateSupplierDiscountModalOpen(arg)}
            />
            {!!selectedSupplierDiscount && (
                <UpdateSupplierDiscountModal
                    isOpen={isUpdateSupplierDiscountModalOpen}
                    setIsOpen={(arg) => setIsUpdateSupplierDiscountModalOpen(arg)}
                    supplierDiscount={selectedSupplierDiscount}
                />
            )}
            {!!selectedSupplierDiscount && (
                <DeleteConfirmationModal
                    isOpen={isDeleteConfirmationModalOpen}
                    setIsOpen={(arg) => setIsDeleteConfirmationModalOpen(arg)}
                    supplierDiscountId={selectedSupplierDiscount._id}
                />
            )}
        </Container>
    );
};

const StatusBadge = styled.div<{ state?: SupplierDiscountState }>`
    background-color: ${({ state }) => (state === SupplierDiscountState.Regular ? greenDarker : darkGrey)};
    color: white;
    padding: 4px 8px;
    border-radius: 8px;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Content = styled.div`
    display: flex;
    padding: 20px;
    flex-direction: column;
    flex: 1;
    overflow: auto;
    background-color: #f9f9f9;
    border-radius: 8px;
`;

const TableContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    border-collapse: collapse;
`;

const TableRow = styled.div`
    display: flex;
    flex-direction: row;
`;

const ProductsContainer = styled.div`
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 8px 0px;
    border-top: 1px solid #e5e5e5;
`;

const TableElement = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid #cecece;
    border-radius: 8px;
    padding: 8px;
    background-color: white;
`;

const TableCell = styled.div<{ bold?: boolean; flex?: number }>`
    display: flex;
    align-items: center;
    padding: 8px;
    flex: ${({ flex }) => flex ?? 1};
    font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
`;

import gql from 'graphql-tag';
import { GET_SITES, GET_SITES_sites } from 'data/queries/__generated__/GET_SITES';
import { apolloClient } from '../../../App';

import { GET_MICROSTORE_SITE_QUERY, GET_SITES_QUERY } from 'data/queries/site';
import { SITES_UPDATED_siteUpdated } from 'data/subscriptions/__generated__/SITES_UPDATED';
import { GET_MICROSTORE_SITE } from 'data/queries/__generated__/GET_MICROSTORE_SITE';
import { UPDATE_MICROSTORES_REASSORTMENT_FOR_DRY_FLOW_updateMicrostoresReassortmentForDryFlowMutation_newSites } from 'data/mutations/__generated__/UPDATE_MICROSTORES_REASSORTMENT_FOR_DRY_FLOW';

export const sitesUpdatedHandler = (siteUpdated: SITES_UPDATED_siteUpdated) => {
    let currentSites = [] as GET_SITES_sites[];
    // We can read query only if it has been run
    try {
        const result = apolloClient.readQuery<GET_SITES>({ query: GET_SITES_QUERY });
        if (!result) {
            throw Error('An error occurred when reading GET_SITES_QUERY');
        }
        currentSites = result.sites;
    } catch {
        // do nothing
    }
    const siteIds = currentSites.map(({ _id }) => _id);

    if (siteIds.includes(siteUpdated._id)) {
        // If this is a site existing in the cache
        const data = currentSites.map((site) => {
            if (site._id === siteUpdated._id) {
                return siteUpdated;
            }
            return site;
        });
        apolloClient.writeQuery({
            query: GET_SITES_QUERY,
            data,
        });
    } else {
        // If this is a new site, not present in the cache
        const data = {
            sites: [...currentSites, siteUpdated],
        };
        apolloClient.writeQuery({
            query: GET_SITES_QUERY,
            data,
        });
    }
};

export const siteUpdatedHandler = (siteUpdated: SITES_UPDATED_siteUpdated, siteId: string) => {
    const data = {
        site: siteUpdated,
    };
    apolloClient.writeFragment({
        id: siteId,
        fragment: gql`
            fragment SitePartialFragment on Site {
                name
                state
            }
        `,
        data,
    });
};

export function microstoreUpdatedHandler({
    siteId,
    updatedSites,
}: {
    siteId: string;
    updatedSites: UPDATE_MICROSTORES_REASSORTMENT_FOR_DRY_FLOW_updateMicrostoresReassortmentForDryFlowMutation_newSites[];
}) {
    // We can read query only if it has been run
    try {
        const result = apolloClient.readQuery<GET_MICROSTORE_SITE>({
            query: GET_MICROSTORE_SITE_QUERY,
            variables: { siteId },
        });
        if (!result) {
            throw Error('An error occurred when reading GET_MICROSTORE_SITE_QUERY');
        }
        const currentMicrostore = result.siteWithLocationInfo;

        const updatedMicrostore = updatedSites.find(({ _id }) => _id === siteId);
        if (!updatedMicrostore) {
            return;
        }

        const data = { siteWithLocationInfo: { ...currentMicrostore, ...updatedMicrostore } };

        apolloClient.writeQuery({
            query: GET_MICROSTORE_SITE_QUERY,
            data,
        });
    } catch {
        // do nothing
    }
}

import React from 'react';
import styled from 'styled-components';

import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useForm, FormProvider } from 'react-hook-form';

import { useCreateOrganizationMutation } from 'data/__generated__';
import { PAGES } from 'constants/pages';

import { Header, HeaderTitle } from 'components/Header';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { PageTitle } from 'components/PageTitle';
import { DetailFormValue } from 'components/DetailsView';
import { Loader, LoaderModeType } from 'components/Loader';

type FormData = {
    adminUserId: string;
    name: string;
    domain?: string;
};

export const OrganizationCreate = () => {
    const methods = useForm<FormData>();
    const navigate = useNavigate();
    const [createOrganization, { loading }] = useCreateOrganizationMutation();
    const {
        handleSubmit,
        register,
        formState: { isDirty },
    } = methods;

    const onSubmit = handleSubmit(async (fields) => {
        const { adminUserId, name, domain } = fields;

        const { data } = await createOrganization({ variables: { adminUserId, name, domain } });
        if (data) {
            const {
                createOrganization: { error, createdOrganization },
            } = data;
            if (createdOrganization) {
                toast.success(`L'organisation ${name} a bien été créé`);
                navigate(`/organization/${createdOrganization._id}`);
            } else {
                if (error) {
                    toast.error(error);
                } else {
                    throw Error("Une erreur inconnue s'est produite");
                }
            }
        } else {
            throw Error("Une erreur inconnue s'est produite");
        }
    });

    return (
        <Container>
            <FormProvider {...methods}>
                <Form onSubmit={onSubmit}>
                    <Header>
                        <HeaderTitle>
                            <PageTitle page={PAGES.organizationCreate} />
                        </HeaderTitle>
                        <Link to="/organizations">
                            <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                        </Link>
                    </Header>
                    <Content>
                        <ScrollableContent>
                            <DetailFormValue label="Nom de l'organisation" {...register('name', { required: true })} />
                            <DetailFormValue
                                label="id de l'administrateur"
                                {...register('adminUserId', { required: true })}
                            />
                            <DetailFormValue
                                label="domaine de l'organisation"
                                {...register('domain', { required: false })}
                            />
                            <ButtonContainer>
                                <TotemPrimaryButton minWidth="140px" type="submit" disabled={!isDirty}>
                                    {loading ? (
                                        <Loader size="18px" mode={LoaderModeType.Spin} />
                                    ) : (
                                        <>Créer une organisation</>
                                    )}
                                </TotemPrimaryButton>
                            </ButtonContainer>
                        </ScrollableContent>
                    </Content>
                </Form>
            </FormProvider>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
`;

const Content = styled.div`
    flex: 1;
    overflow: hidden;
`;
const ScrollableContent = styled.div`
    padding: 15px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 15px;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

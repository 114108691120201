import React, { useState, useContext } from 'react';

import styled, { ThemeContext } from 'styled-components';

import { GET_DETAILED_TOTEMS_OF_ORGANIZATION_totemsOfOrganization_recentOrders } from 'data/queries/__generated__/GET_DETAILED_TOTEMS_OF_ORGANIZATION';

import { IconClipboardList } from '@tabler/icons';
import { Link } from 'react-router-dom';
import { StatusTag as OrderStatusTag } from 'pages/Orders/components/StatusTag';
import { StatusTag as DeliveryStatusTag } from 'pages/Deliveries/components/StatusTag';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { OrdersCalendarPopin } from 'pages/Organizations/OrganizationDetails/TotemsDetails/OrdersCalendarPopin';

export const RecentOrders = ({
    organizationId,
    totemId,
    orders,
}: {
    organizationId: string;
    totemId: string;
    orders: GET_DETAILED_TOTEMS_OF_ORGANIZATION_totemsOfOrganization_recentOrders[];
}) => {
    const theme = useContext(ThemeContext);
    const [isPopinOpen, setIsPopinOpen] = useState(false);

    function togglePopinOpen() {
        setIsPopinOpen(!isPopinOpen);
    }

    return (
        <Container>
            {isPopinOpen ? (
                <OrdersCalendarPopin
                    togglePopinOpen={togglePopinOpen}
                    organizationId={organizationId}
                    totemId={totemId}
                />
            ) : null}
            <Header>
                <Title>Commandes récentes</Title>
                <TotemPrimaryButton onClick={togglePopinOpen}>Voir plus</TotemPrimaryButton>
            </Header>
            <OrdersTable>
                <thead>
                    <tr>
                        <th>Date livraison</th>
                        <th>Commande</th>
                        <th>Livraison</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {orders.map((order) => {
                        const { _id, deliveryDate, deliveryState, state } = order;
                        return (
                            <tr key={_id}>
                                <TableCell>{deliveryDate}</TableCell>
                                <TableCell>
                                    <OrderStatusTag state={state} />
                                </TableCell>
                                <TableCell>
                                    {deliveryState ? <DeliveryStatusTag state={deliveryState} /> : 'Ø'}
                                </TableCell>
                                <TableCell>
                                    <Link to={`/order/${_id}`}>
                                        <DetailsIconContainer>
                                            <IconClipboardList size={22} color={theme.textColor} />
                                        </DetailsIconContainer>
                                    </Link>
                                </TableCell>
                            </tr>
                        );
                    })}
                </tbody>
            </OrdersTable>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    border-radius: ${({ theme }) => theme.borderRadius};
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
    color: ${({ theme }) => theme.textColor};
    padding: 10px;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const OrdersTable = styled.table`
    margin-top: 5px;
`;

const Title = styled.span`
    font-size: 18px;
    font-weight: 800;
`;

const TableCell = styled.td`
    text-align: center;
`;

const DetailsIconContainer = styled.div`
    display: flex;
    align-items: center;
`;

import React from 'react';

import { useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { FaTrash } from 'react-icons/fa';
import { IoWarning } from 'react-icons/io5';
import styled from 'styled-components';

import { colors } from 'constants/colors';

import {
    GET_TRANSFERABLE_PRODUCTS,
    GET_TRANSFERABLE_PRODUCTSVariables,
    GET_TRANSFERABLE_PRODUCTS_transferableProducts,
} from 'data/queries/__generated__/GET_TRANSFERABLE_PRODUCTS';
import { GET_TRANSFERABLE_PRODUCTS_QUERY } from 'data/queries/product';

import { Loader, LoaderModeType } from 'components/Loader';
import { TotemInput } from 'components/TotemInput';
import { TotemLabel } from 'components/TotemLabel';
import { SelectedOption, TotemSelect } from 'components/TotemSelect';

export const ProductsToRemoveTable = ({
    siteId,
    selectedProductsToRemove,
    setSelectedProductsToRemove,
}: {
    siteId: string;
    selectedProductsToRemove: GET_TRANSFERABLE_PRODUCTS_transferableProducts[];
    setSelectedProductsToRemove: React.Dispatch<React.SetStateAction<GET_TRANSFERABLE_PRODUCTS_transferableProducts[]>>;
}) => {
    const {
        loading: productsLoading,
        data: productsData,
        error: productsError,
    } = useQuery<GET_TRANSFERABLE_PRODUCTS, GET_TRANSFERABLE_PRODUCTSVariables>(GET_TRANSFERABLE_PRODUCTS_QUERY, {
        variables: {
            originSiteId: siteId,
            destinationSiteId: siteId, // this is a trick to re-use the same query as in stock transfer.
        },
        fetchPolicy: 'no-cache',
    });

    if (productsLoading) {
        return (
            <LoaderContainer>
                <Loader mode={LoaderModeType.Spin} />
            </LoaderContainer>
        );
    }

    if (productsError || !productsData) {
        toast.error('Une erreur est survenue lors de la récupération des produits transférables');
        return null;
    }

    function onProductSelect(option: SelectedOption<string>) {
        if (option) {
            const transferableProduct = transferableProducts.find((product) => product.productId === option.value);
            if (transferableProduct) {
                if (!selectedProductsToRemove.find((product) => product.productId === option.value)) {
                    setSelectedProductsToRemove([...selectedProductsToRemove, { ...transferableProduct, quantity: 0 }]);
                }
            }
        }
    }

    function updateProductQuantity({
        quantity,
        product,
    }: {
        quantity: number;
        product: GET_TRANSFERABLE_PRODUCTS_transferableProducts;
    }) {
        if (quantity > product.originStockAvailable) {
            return;
        }
        setSelectedProductsToRemove(
            selectedProductsToRemove.map((productToTransfer) => {
                if (productToTransfer.productId !== product.productId) {
                    return productToTransfer;
                }
                return {
                    ...productToTransfer,
                    quantity,
                };
            }),
        );
    }

    function onDeltaInputChange(value: string, product: GET_TRANSFERABLE_PRODUCTS_transferableProducts) {
        // Check that the string only contains digits
        if (/^(\d+)?$/.test(value)) {
            updateProductQuantity({ product, quantity: Number(value) });
        }
    }

    function onInventoryInputChange(value: string, product: GET_TRANSFERABLE_PRODUCTS_transferableProducts) {
        // Check that the string only contains digits
        if (/^(\d+)?$/.test(value)) {
            const quantity = product.originStockAvailable - Number(value);
            updateProductQuantity({ product, quantity });
        }
    }

    function onProductDelete(productId: string) {
        setSelectedProductsToRemove(
            selectedProductsToRemove.filter((productToTransfer) => productToTransfer.productId !== productId),
        );
    }

    const { transferableProducts } = productsData;

    const productsOptions = transferableProducts.map((product) => ({
        value: product.productId,
        label: `${product.fullname} (${product.originStockAvailable} en stock)`,
    }));

    return (
        <Container>
            <TotemSelect
                label="Ajouter un produit"
                placeholder="Sélectionner un produit (recherche par nom ou par _id)"
                value={null}
                options={productsOptions}
                onChange={(option) => onProductSelect(option)}
            />

            <TotemLabel>
                Si vous voyez l'icône <WarningIcon size={25} /> c'est que vous avez plus de produits que prévu.
            </TotemLabel>
            <TotemLabel>Produits</TotemLabel>
            <ProductsTable>
                <thead>
                    <tr>
                        <ProductsTableHeaderCell>Produit</ProductsTableHeaderCell>
                        <ProductsTableHeaderCell isDataCentered>Quantité totale avant</ProductsTableHeaderCell>
                        <ProductsTableHeaderCell isDataCentered>Nouvelle quantité totale</ProductsTableHeaderCell>
                        <ProductsTableHeaderCell isDataCentered>Quantité à retirer</ProductsTableHeaderCell>
                        <ProductsTableHeaderCell isDataCentered>Supprimer</ProductsTableHeaderCell>
                    </tr>
                </thead>
                <ProductsTableBody>
                    {selectedProductsToRemove.map(
                        (productToTransfer: GET_TRANSFERABLE_PRODUCTS_transferableProducts) => {
                            const { productId, fullname, quantity, originStockAvailable } = productToTransfer;

                            return (
                                <tr key={productId}>
                                    <ProductsTableBodyCell>
                                        <ProductNameContainer>{fullname}</ProductNameContainer>
                                    </ProductsTableBodyCell>
                                    <ProductsTableBodyCell isDataCentered>{originStockAvailable}</ProductsTableBodyCell>
                                    <ProductsTableBodyCell isDataCentered>
                                        <QuantityInputOuterContainer>
                                            <QuantityInputInnerContainer>
                                                <TotemInput
                                                    type="number"
                                                    value={originStockAvailable - quantity}
                                                    onChange={(value) =>
                                                        onInventoryInputChange(value, productToTransfer)
                                                    }
                                                    centerText
                                                    min={0}
                                                />
                                            </QuantityInputInnerContainer>
                                        </QuantityInputOuterContainer>
                                    </ProductsTableBodyCell>
                                    <ProductsTableBodyCell isDataCentered>
                                        <QuantityInputOuterContainer>
                                            {quantity < 0 ? <WarningIcon size={25} /> : null}
                                            <QuantityInputInnerContainer>
                                                <TotemInput
                                                    type="number"
                                                    value={quantity}
                                                    onChange={(value) => onDeltaInputChange(value, productToTransfer)}
                                                    centerText
                                                    max={originStockAvailable}
                                                />
                                            </QuantityInputInnerContainer>
                                            {quantity < 0 ? <WarningIcon size={25} /> : null}
                                        </QuantityInputOuterContainer>
                                    </ProductsTableBodyCell>
                                    <ProductsTableBodyCell isDataCentered>
                                        <DeleteIconContainer onClick={() => onProductDelete(productId)}>
                                            <FaTrash size={20} color={colors.red} />
                                        </DeleteIconContainer>
                                    </ProductsTableBodyCell>
                                </tr>
                            );
                        },
                    )}
                </ProductsTableBody>
            </ProductsTable>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    color: ${({ theme }) => theme.textColor};

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

const ProductsTable = styled.table`
    border-radius: ${({ theme }) => theme.borderRadius};
    border-spacing: 0px;
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
`;

const ProductsTableBody = styled.thead`
    & > :not(:first-child) td {
        border-top: 1px solid ${({ theme }) => theme.lightBorderColor};
    }
`;

const ProductsTableHeaderCell = styled.th`
    padding: 10px;
    text-align: ${({ isDataCentered }: { isDataCentered?: boolean }) => (isDataCentered ? 'center' : 'left')};
    border-bottom: 1px solid ${({ theme }) => theme.lightBorderColor};
`;

const ProductsTableBodyCell = styled.td`
    padding: 10px;
    text-align: ${({ isDataCentered }: { isDataCentered?: boolean }) => (isDataCentered ? 'center' : 'left')};
`;

const ProductNameContainer = styled.div`
    font-weight: bold;
`;

const QuantityInputOuterContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const QuantityInputInnerContainer = styled.div`
    width: 70px;
`;

const DeleteIconContainer = styled.div`
    display: inline;
    padding: 5px;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
`;

const LoaderContainer = styled.div`
    padding: 15px;
`;

const WarningIcon = styled(IoWarning)`
    color: ${({ theme }) => theme.warningColor};
`;

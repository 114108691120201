import React from 'react';

import { SiteSetupFragment } from 'data/fragments/__generated__/SiteSetupFragment';
import { GET_MICROSTORE_SITE_siteWithLocationInfo } from 'data/queries/__generated__/GET_MICROSTORE_SITE';

import { DetailValue } from 'components/DetailsView/DetailValue';
import { SectionColumn, SectionContainer, ColumnsSectionContainer } from 'components/DetailsView/Section';

export const GeneralInfoSection = ({
    site,
    siteSetup,
}: {
    site: GET_MICROSTORE_SITE_siteWithLocationInfo;
    siteSetup: SiteSetupFragment;
}) => {
    return (
        <SectionContainer title="Informations générales" isInitiallyOpen>
            <ColumnsSectionContainer numberOfColumns={2}>
                <SectionColumn>
                    <DetailValue label="ID" value={siteSetup._id} />
                    <DetailValue label="Date" value={siteSetup.date} />
                </SectionColumn>
                <SectionColumn>
                    <DetailValue label="Site ID" value={site._id} />
                    <DetailValue label="Site Name" value={site.name} />
                </SectionColumn>
            </ColumnsSectionContainer>
        </SectionContainer>
    );
};

import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from '@apollo/client';
import styled from 'styled-components';

import { formatDateAsAnniversary } from 'helpers/dateTimes';

import { GENERATE_QUANTITIES_MUTATION } from 'data/mutations/menus';
import { GENERATE_QUANTITIES, GENERATE_QUANTITIESVariables } from 'data/mutations/__generated__/GENERATE_QUANTITIES';
import { QUANTITIES_GENERATION_STATUS_QUERY } from 'data/queries/menus';
import { QUANTITIES_GENERATION_STATUS } from 'data/queries/__generated__/QUANTITIES_GENERATION_STATUS';

import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { PredictionServiceStatus } from 'data/__generated__';

export function GenerateQuantitiesButton({ date }: { date: Date }) {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [polling, setPolling] = useState<boolean>(false);
    const {
        data,
        loading: statusLoading,
        error,
        startPolling,
        stopPolling,
    } = useQuery<QUANTITIES_GENERATION_STATUS>(QUANTITIES_GENERATION_STATUS_QUERY);
    const [generateQuantities, { loading }] = useMutation<GENERATE_QUANTITIES, GENERATE_QUANTITIESVariables>(
        GENERATE_QUANTITIES_MUTATION,
    );

    const quantitiesGenerationStatus = data?.quantitiesGenerationStatus;

    async function handleClick() {
        try {
            await generateQuantities({
                variables: {
                    date: formatDateAsAnniversary({ dateTime: date, useNewFormat: true }),
                },
                onCompleted: ({ generateQuantities }) => {
                    if (generateQuantities?.success) {
                        toast.success('La génération des quantités a bien été relancée. Patientez');
                        startPolling(10000);
                        setPolling(true);
                    } else {
                        toast.error(`Une erreur s'est produite : ${generateQuantities?.error || 'erreur inconnue'}`);
                    }
                },
                onError: (error) => {
                    toast.error(`Impossible de générer les quantités : ${error}`);
                },
            });
        } catch (error) {
            toast.error(`Impossible de générer les quantités : ${error}`);
            throw Error('Impossible de générer les quantités');
        }
    }

    useEffect(() => {
        if (!statusLoading && quantitiesGenerationStatus !== PredictionServiceStatus.Loading) {
            stopPolling();
            setPolling(false);
        }
    }, [quantitiesGenerationStatus, statusLoading]);

    return (
        <>
            <TotemPopup
                title="Confirmer la re-génération des quantités"
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                contentOverflow="visible"
            >
                <InfoText>
                    En confirmant vous remplacerez les quantités existantes pour la semaine du{' '}
                    {formatDateAsAnniversary({ dateTime: date })}.
                </InfoText>
                <InfoText>Cette opération peut prendre un certain temps, ne la relancez pas en parallèle ;)</InfoText>
                <TotemPrimaryButton disabled={loading} onClick={handleClick}>
                    confirmer
                </TotemPrimaryButton>
            </TotemPopup>
            <TotemPrimaryButton
                disabled={
                    loading ||
                    statusLoading ||
                    polling ||
                    !!error ||
                    quantitiesGenerationStatus === PredictionServiceStatus.Loading ||
                    quantitiesGenerationStatus === PredictionServiceStatus.Error
                }
                onClick={() => setIsOpen(true)}
            >
                Quantités
            </TotemPrimaryButton>
        </>
    );
}

const InfoText = styled.div`
    padding: 20px 0px;
`;

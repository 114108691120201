import React, { useContext, useState } from 'react';

import { toast } from 'react-toastify';
import styled, { ThemeContext } from 'styled-components';

import { PAGES } from '../../constants/pages';
import { ENV_DEV, ENV_PREPROD } from 'constants/environment';

import {
    useBundleUpdatedSubscription,
    BundleState,
    useGetBundlesQuery,
    useGenerateSignatureMutation,
    useUpdateBundleStateMutation,
    BundleFragmentFragment,
} from 'data/__generated__';

import { Header, HeaderTitle } from 'components/Header';
import { FieldToDisplay, ListView } from 'components/ListView';
import { Loader } from 'components/Loader';
import { PageTitle } from 'components/PageTitle';
import { SelectedOption, TotemSelect } from 'components/TotemSelect';
import { StatusTag } from 'pages/Bundles/components/StatusTag';
import { BundleTypeTag } from 'pages/Bundles/components/TypeTag';
import { BoxToPrint, PrintBoxTags } from 'pages/Bundles/components/PrintBoxTags';
import { BUNDLE_STATES_LABELS } from 'pages/Bundles/constants/states';
import { TotemInput } from 'components/TotemInput';
import { DetailLink } from 'components/DetailsView';

import { getExtendedFormattedDatetime } from 'helpers/dateTimes';
import { bundleUpdatedHandler } from './utils/bundleUpdatedHandler';
import { transformForComparison } from 'helpers/textHelper';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

type FormattedBundleType = {
    _id: string;
    boxes: JSX.Element;
    createdAt: string;
    insertSignatureButton: JSX.Element | string;
    isSelected: JSX.Element;
    number: number;
    numberOfReferences: number;
    numberOfUnits: number;
    operatorName: string;
    preparationInfo: JSX.Element;
    state: JSX.Element;
    type: JSX.Element;
};

export const Bundles = () => {
    const theme = useContext(ThemeContext);
    const [filterString, setFilterString] = useState('');

    const [updateBundleState] = useUpdateBundleStateMutation();

    // only for testing
    const [insertSignatureMutation] = useGenerateSignatureMutation();

    const { loading: bundlesLoading, error: bundlesError, data: bundlesData } = useGetBundlesQuery();

    const [selectedBundleIds, setSelectedBundleIds] = useState<string[]>([]);

    useBundleUpdatedSubscription({
        onSubscriptionData({ subscriptionData: { data } }) {
            if (!data) {
                return;
            }
            const { bundleUpdated } = data;
            bundleUpdatedHandler(bundleUpdated);
        },
    });

    if (bundlesLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (!bundlesData || bundlesError) {
        throw new Error('Une erreur est survenue lors de la récupération des bundles');
    }

    function toggleBundleSelection(bundleId: string) {
        if (selectedBundleIds.includes(bundleId)) {
            setSelectedBundleIds(selectedBundleIds.filter((selectedBundleId) => selectedBundleId !== bundleId));
        } else {
            setSelectedBundleIds([...selectedBundleIds, bundleId]);
        }
    }

    async function insertSignatureButton(bundleId: string) {
        await insertSignatureMutation({ variables: { bundleId } });
    }

    function formatBundle(bundle: BundleFragmentFragment) {
        const {
            _id: bundleId,
            boxes,
            createdAt,
            number,
            numberOfReferences,
            numberOfUnits,
            operatorName,
            preparationDates: { start: preparationStart, end: preparationEnd },
            preparationDuration,
            state,
            stateHistory,
            type,
        } = bundle;

        const preparationDurationString = preparationDuration
            ? `${Math.floor(preparationDuration / 60)}h
              ${(preparationDuration % 60).toString().padStart(2, '0')}`
            : '-';

        const hasBeenPaused = !!stateHistory.find(({ state }) => state === BundleState.Paused);

        const stateOptions = [
            { value: BundleState.Created, label: BUNDLE_STATES_LABELS.Created },
            { value: BundleState.Started, label: BUNDLE_STATES_LABELS.Started },
            { value: BundleState.InProgress, label: BUNDLE_STATES_LABELS.InProgress },
            { value: BundleState.Completed, label: BUNDLE_STATES_LABELS.Completed },
            { value: BundleState.Paused, label: BUNDLE_STATES_LABELS.Paused },
            { value: BundleState.Aborted, label: BUNDLE_STATES_LABELS.Aborted },
            { value: BundleState.Archived, label: BUNDLE_STATES_LABELS.Archived },
        ];

        return {
            _id: bundleId,
            boxes: (
                <Boxes>
                    {[...boxes]
                        .sort((boxA, boxB) => (boxA.position || 0) - (boxB.position || 0))
                        .map(({ _id, label, deliveryId }) => (
                            <DetailLink
                                key={_id}
                                path="delivery"
                                value={deliveryId}
                                name={label}
                                color={theme.textColor}
                                shouldWrap={true}
                            />
                        ))}
                </Boxes>
            ),
            createdAt: getExtendedFormattedDatetime(createdAt),
            isSelected: (
                <input
                    type="checkbox"
                    checked={selectedBundleIds.includes(bundleId)}
                    onClick={() => toggleBundleSelection(bundleId)}
                />
            ),
            number,
            numberOfReferences,
            numberOfUnits,
            operatorName,
            preparationInfo: (
                <PreparationInfo>
                    <PreparationInterval>
                        {preparationStart || '?'}&nbsp;&rarr;&nbsp;{preparationEnd || '?'}
                    </PreparationInterval>
                    <PreparationDuration>{preparationDurationString}</PreparationDuration>
                    {hasBeenPaused ? <PausedWarning>A été "paused"</PausedWarning> : null}
                </PreparationInfo>
            ),
            insertSignatureButton:
                process.env.ENV && [ENV_DEV, ENV_PREPROD].includes(process.env.ENV) ? (
                    <TotemPrimaryButton onClick={() => insertSignatureButton(bundleId)}>
                        Insert signature
                    </TotemPrimaryButton>
                ) : (
                    ''
                ),
            state: (
                <StatusCell>
                    <StatusTag state={state} />
                    <SelectContainer>
                        <TotemSelect<BundleState>
                            options={stateOptions.filter((option) => option.value !== state)}
                            placeholder="Mettre à jour"
                            onChange={async (option: SelectedOption<BundleState>) => {
                                if (option) {
                                    const { data } = await updateBundleState({
                                        variables: { bundleId, state: option.value },
                                    });

                                    if (!data || data.updateBundleState.error) {
                                        toast.error(`Erreur: ${data?.updateBundleState.error}`);
                                    } else {
                                        toast.success('Le statut du bundle a bien été mis à jour');
                                    }
                                }
                            }}
                        />
                    </SelectContainer>
                </StatusCell>
            ),
            type: <BundleTypeTag type={type} />,
        };
    }

    const { recentBundles } = bundlesData;

    const todayBundles = recentBundles.filter(
        (bundle) => new Date(bundle.createdAt).getDate() === new Date().getDate(),
    );

    const previousBundles = recentBundles.filter(
        (bundle) => new Date(bundle.createdAt).getDate() !== new Date().getDate(),
    );

    const BUNDLES_FIELDS_TO_DISPLAY: FieldToDisplay<FormattedBundleType>[] = [
        { fieldName: 'isSelected', label: '' },
        { fieldName: 'createdAt', label: 'Date de création' },
        { fieldName: 'number', label: 'N°' },
        { fieldName: 'operatorName', label: 'Opérateur.ice' },
        { fieldName: 'preparationInfo', label: 'Préparation' },
        { fieldName: 'type', label: 'Type' },
        { fieldName: 'boxes', label: 'Caisses' },
        { fieldName: 'numberOfReferences', label: 'Réfs' },
        { fieldName: 'numberOfUnits', label: 'Unités' },
        { fieldName: 'state', label: 'Statut' },
        { fieldName: 'insertSignatureButton', label: '' },
    ];

    const formattedTodayBundles: FormattedBundleType[] = todayBundles
        .filter(({ operatorName }) =>
            transformForComparison(operatorName).includes(transformForComparison(filterString)),
        )
        .map(formatBundle);

    const formattedPreviousBundles: FormattedBundleType[] = previousBundles
        .filter(({ operatorName }) =>
            transformForComparison(operatorName).includes(transformForComparison(filterString)),
        )
        .map(formatBundle);

    const boxesToPrint = selectedBundleIds.reduce<BoxToPrint[]>((acc, bundleId) => {
        const { boxes, number: bundleNumber } = recentBundles.find(
            ({ _id }) => _id === bundleId,
        ) as BundleFragmentFragment; // will be there

        acc.push(
            ...boxes.map(({ label, hasOnsiteSetup, orderId, roundColor, roundName }) => ({
                label,
                hasOnsiteSetup,
                isFreefood: !!orderId,
                bundleNumber,
                roundColor,
                roundName,
            })),
        );

        return acc;
    }, []);

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.bundles} />
                </HeaderTitle>
                <PrintBoxTags boxesToPrint={boxesToPrint} />
            </Header>
            <Content>
                <TotemInput
                    label="Recherche du préparateur"
                    onChange={setFilterString}
                    placeholder="Nom du préparateur"
                    value={filterString}
                />
                <BundlesSection>
                    <Title>Bundles du jour</Title>
                    <ListView<FormattedBundleType>
                        fieldsToDisplay={BUNDLES_FIELDS_TO_DISPLAY}
                        items={formattedTodayBundles}
                        keyExtractor={(item) => item._id}
                    />
                </BundlesSection>
                <BundlesSection>
                    <Title>Anciens bundles</Title>
                    <ListView<FormattedBundleType>
                        fieldsToDisplay={BUNDLES_FIELDS_TO_DISPLAY}
                        items={formattedPreviousBundles}
                        keyExtractor={(item) => item._id}
                    />
                </BundlesSection>
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Content = styled.div`
    padding: 15px;
    flex: 1;
    overflow: scroll;
`;

const BundlesSection = styled.div`
    flex: 1;
`;

const Title = styled.h3`
    color: ${({ theme }) => theme.textColor};
`;

const Boxes = styled.div`
    display: flex;
    flex-direction: column;
`;

const PreparationInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const PreparationInterval = styled.div`
    font-size: 16px;
`;

const PreparationDuration = styled.div`
    font-size: 14px;
`;

const PausedWarning = styled.div`
    font-size: 14px;
    color: ${({ theme }) => theme.warningColor};
`;

const StatusCell = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
`;

const SelectContainer = styled.div`
    margin-left: 20px;
    min-width: 150px;
`;

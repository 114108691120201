import React from 'react';
import styled from 'styled-components';

import { SectionColumn, SectionContainer, ColumnsSectionContainer } from 'components/DetailsView/Section';
import { DetailLink } from 'components/DetailsView';
import { useMutation } from '@apollo/client';
import { CREATE_PRODUCT_GROUP_MUTATION } from 'data/mutations/productGroup';
import { CREATE_PRODUCT_GROUP, CREATE_PRODUCT_GROUPVariables } from 'data/mutations/__generated__/CREATE_PRODUCT_GROUP';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { GetDetailedProductQuery } from 'data/__generated__';

export const ProductGroupSection = ({
    product,
}: {
    product: NonNullable<GetDetailedProductQuery['detailedProduct']>;
}) => {
    const { _id: productId, productGroup, productGroupsUsedIn } = product;

    const [createProductGroup, { loading: createLoading }] = useMutation<
        CREATE_PRODUCT_GROUP,
        CREATE_PRODUCT_GROUPVariables
    >(CREATE_PRODUCT_GROUP_MUTATION, { variables: { productId } });

    return (
        <SectionContainer title="Groupe de produits">
            <>
                {productGroup ? (
                    <>
                        <Title>Ce produit est un regroupement de plusieurs produits</Title>
                        <Subtitle>
                            Pour le moment on vous affiche que le product id. Si jamais c'est pas mal utilisé, on vous
                            mettra le nom du produit directement
                        </Subtitle>
                        <ColumnsSectionContainer numberOfColumns={2}>
                            <SectionColumn>
                                <DetailLink
                                    path="product-group"
                                    name="Voir le regroupement de produits"
                                    value={productGroup._id}
                                />
                            </SectionColumn>
                            <SectionColumn>
                                {productGroup.composition.map((composition) => (
                                    <CompositionEntry key={composition.productId}>
                                        <DetailLink
                                            path="product"
                                            name={`Produit : ${composition.productId}`}
                                            value={composition.productId}
                                        />
                                        <ProductQuantity>Quantité : {composition.quantity}</ProductQuantity>
                                    </CompositionEntry>
                                ))}
                            </SectionColumn>
                        </ColumnsSectionContainer>
                    </>
                ) : !productGroupsUsedIn.length ? (
                    <Flex>
                        <Title>Paramétrer ce produit comme étant un regroupement de produits ?</Title>
                        <TotemPrimaryButton disabled={createLoading} onClick={() => createProductGroup()}>
                            Créer un regroupement de produit
                        </TotemPrimaryButton>
                    </Flex>
                ) : null}
                {productGroupsUsedIn.length ? (
                    <>
                        <Title>Ce produit est utilisé dans un/des regroupement(s) de produits</Title>
                        <Subtitle>
                            Pour le moment on vous affiche que le product id. Si jamais c'est pas mal utilisé, on vous
                            mettra le nom du produit directement
                        </Subtitle>
                        {productGroupsUsedIn.map((productGroupUsedIn) => (
                            <ColumnsSectionContainer numberOfColumns={2} key={productGroupUsedIn.productId}>
                                <SectionColumn>
                                    <DetailLink
                                        path="product-group"
                                        name="Voir le regroupement de produits"
                                        value={productGroupUsedIn._id}
                                    />
                                </SectionColumn>
                                <SectionColumn>
                                    {productGroupUsedIn.composition.map((composition) => (
                                        <CompositionEntry key={composition.productId}>
                                            <DetailLink
                                                path="product"
                                                name={`Produit : ${composition.productId}`}
                                                value={composition.productId}
                                            />
                                            <ProductQuantity>Quantité : {composition.quantity}</ProductQuantity>
                                        </CompositionEntry>
                                    ))}
                                </SectionColumn>
                            </ColumnsSectionContainer>
                        ))}
                    </>
                ) : null}
            </>
        </SectionContainer>
    );
};

const Title = styled.div`
    font-weight: bold;
    font-size: 20px;
`;

const Subtitle = styled.div`
    margin-top: 5px;
`;

const Flex = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
`;

const CompositionEntry = styled.div`
    display: flex;
    align-items: center;
`;

const ProductQuantity = styled.div`
    margin-left: 10px;
`;

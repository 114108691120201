import React from 'react';
import { CommonStatusTag } from 'components/CommonStatusTag';

import { OrderState } from 'data/__generated__';
import { getColorsFromState, ORDER_STATES_LABELS } from '../constants/states';

export const StatusTag = ({
    state,
    showHeading,
    sublabel,
}: {
    state: OrderState;
    showHeading?: boolean;
    sublabel?: string;
}) => {
    const { backgroundColor, labelColor } = getColorsFromState(state);
    return (
        <CommonStatusTag
            backgroundColor={backgroundColor}
            labelColor={labelColor}
            label={ORDER_STATES_LABELS[state]}
            showHeading={showHeading}
            sublabel={sublabel}
        />
    );
};

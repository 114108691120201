import gql from 'graphql-tag';

export const MOBILE_POPUP_FRAGMENT = gql`
    fragment MobilePopupFragment on MobilePopup {
        _id
        startsAt
        endsAt
        imageUrl
        title
        body
        siteIds
        sites {
            _id
            name
        }
        createdAt
    }
`;

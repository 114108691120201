import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import styled from 'styled-components';

import { GET_PRODUCT_ARRANGEMENT_QUERY } from 'data/queries/productArrangement';
import {
    GET_PRODUCT_ARRANGEMENT,
    GET_PRODUCT_ARRANGEMENTVariables,
} from 'data/queries/__generated__/GET_PRODUCT_ARRANGEMENT';

import { Loader } from 'components/Loader';
import { ProductArrangementDetailsForm } from './ProductArrangementDetailsForm';
import { GET_ALL_PRODUCTS } from 'data/queries/__generated__/GET_ALL_PRODUCTS';
import { GET_ALL_PRODUCTS_QUERY } from 'data/queries/product';

type ParamTypes = {
    productArrangementId: string;
};

export const ProductArrangementDetails = () => {
    const { productArrangementId = '' } = useParams<ParamTypes>();

    const {
        loading: productArrangementLoading,
        data: productArrangementData,
        error: productArrangementError,
    } = useQuery<GET_PRODUCT_ARRANGEMENT, GET_PRODUCT_ARRANGEMENTVariables>(GET_PRODUCT_ARRANGEMENT_QUERY, {
        variables: {
            productArrangementId,
        },
    });

    const {
        loading: productsLoading,
        data: productsData,
        error: productsError,
    } = useQuery<GET_ALL_PRODUCTS>(GET_ALL_PRODUCTS_QUERY);

    if (productArrangementLoading || productsLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (productArrangementError || !productArrangementData || productsError || !productsData) {
        throw new Error('Une erreur est survenue lors de la récupération de le gamme de produits');
    }

    const { productArrangementQuery: productArrangement } = productArrangementData;
    const { getAllProducts: products } = productsData;

    return (
        <Container>
            <ProductArrangementDetailsForm productArrangement={productArrangement} products={products} />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex: 1;
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
`;

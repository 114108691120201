import gql from 'graphql-tag';

import { URBANTZ_ROUND_PRESET_FRAGMENT } from '../fragments/urbantzRoundPreset';

export const GET_URBANTZ_ROUND_PRESETS_QUERY = gql`
    query GET_URBANTZ_ROUND_PRESETS {
        getUrbantzRoundPresets {
            ...UrbantzRoundPresetFragment
        }
    }
    ${URBANTZ_ROUND_PRESET_FRAGMENT}
`;

import React, { useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { SubcategoryState, useCreateSubcategoryMutation, useGetSubcategoriesOfCategoryQuery } from 'data/__generated__';

import { SectionContainer } from 'components/DetailsView/Section';
import { Loader, LoaderModeType } from 'components/Loader';
import { SubcategoryDetails } from './SubcategoryDetails';
import { TotemInput } from 'components/TotemInput';
import { TotemSelect } from 'components/TotemSelect';
import { subcategoryAddedHandler } from './utils/subcategoryHandlers';

export const SubcategoriesSection = ({ categoryId }: { categoryId: string }) => {
    const initialSubcategoryForm = {
        displayIndex: 0,
        name: '',
        state: SubcategoryState.Regular,
    };
    const [subcategoryForm, setSubcategoryForm] = useState(initialSubcategoryForm);

    const [createSubcategoryMutation] = useCreateSubcategoryMutation();

    const { data: subcategoriesData } = useGetSubcategoriesOfCategoryQuery({ variables: { categoryId } });

    const stateOptions = [
        {
            value: SubcategoryState.Regular,
            label: 'Regular',
        },
        {
            value: SubcategoryState.Archived,
            label: 'Archivée',
        },
    ];

    const selectedStateOption = stateOptions.find((option) => option.value === subcategoryForm.state);

    async function createSubcategory() {
        const { data } = await createSubcategoryMutation({
            variables: { categoryId, createInput: subcategoryForm },
        });

        if (data) {
            const {
                createSubcategory: { errors, subcategory },
            } = data;

            if (subcategory) {
                toast.success(`La sous-catégorie "${subcategory.name}" a bien été ajoutée.`);
                subcategoryAddedHandler({ categoryId, addedSubcategory: subcategory });
                setSubcategoryForm(initialSubcategoryForm);
            }

            if (errors) {
                errors.forEach((error) => toast.error(error));
            }
        }
    }

    return (
        <SectionContainer title="Sous-catégories" isInitiallyOpen>
            {!subcategoriesData ? (
                <LoaderContainer>
                    <Loader mode={LoaderModeType.Spin} />
                </LoaderContainer>
            ) : (
                <Table>
                    <thead>
                        <TableHeader>Identifiant</TableHeader>
                        <TableHeader>Nom</TableHeader>
                        <TableHeader>Indice d'affichage</TableHeader>
                        <TableHeader>Statut</TableHeader>
                        <TableHeader />
                    </thead>
                    <tbody>
                        {[...subcategoriesData.subcategoriesOfCategory]
                            .sort((subcategoryA, subcategoryB) => subcategoryA.displayIndex - subcategoryB.displayIndex)
                            .sort((subcategoryA, subcategoryB) => {
                                const statePriorities = {
                                    [SubcategoryState.Regular]: 0,
                                    [SubcategoryState.Archived]: 1,
                                };

                                return statePriorities[subcategoryA.state] - statePriorities[subcategoryB.state];
                            })
                            .map((subcategory) => (
                                <SubcategoryDetails key={subcategory._id} subcategory={subcategory} />
                            ))}
                        <tr />
                        <tr>
                            <td>Ajouter une sous-catégorie</td>
                            <TableCell>
                                <TotemInput
                                    placeholder="Nom de la sous-catégorie"
                                    value={subcategoryForm.name}
                                    onChange={(value) => setSubcategoryForm({ ...subcategoryForm, name: value })}
                                />
                            </TableCell>
                            <TableCell>
                                <TotemInput
                                    value={subcategoryForm.displayIndex}
                                    onChange={(value) =>
                                        setSubcategoryForm({ ...subcategoryForm, displayIndex: Number(value) })
                                    }
                                />
                            </TableCell>
                            <td>
                                <TotemSelect
                                    value={selectedStateOption}
                                    options={stateOptions}
                                    onChange={(option) => {
                                        if (option) {
                                            setSubcategoryForm({ ...subcategoryForm, state: option.value });
                                        }
                                    }}
                                />
                            </td>
                            <TableCell isCentered>
                                <Button onClick={createSubcategory}>
                                    <FaPlus />
                                </Button>
                            </TableCell>
                        </tr>
                    </tbody>
                </Table>
            )}
        </SectionContainer>
    );
};

const LoaderContainer = styled.div`
    flex: 1;
    justify-content: center;
`;

const Table = styled.table`
    width: 100%;
    color: ${({ theme }) => theme.textColor};
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
    border-collapse: collapse;

    th,
    td {
        border: 1px solid ${({ theme }) => theme.lightBorderColor};
        border-collapse: collapse;
        padding: 10px 5px;
    }
`;

const TableHeader = styled.th`
    text-align: left;
`;

const TableCell = styled.td<{ isCentered?: boolean }>`
    text-align: ${({ isCentered }) => (isCentered ? 'center' : 'left')};
`;

const Button = styled.div`
    cursor: pointer;
`;

import React from 'react';

import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
    GET_DETAILED_TOTEMS_OF_ORGANIZATION,
    GET_DETAILED_TOTEMS_OF_ORGANIZATIONVariables,
} from 'data/queries/__generated__/GET_DETAILED_TOTEMS_OF_ORGANIZATION';
import { GET_DETAILED_TOTEMS_OF_ORGANIZATION_QUERY } from 'data/queries/totem';
import { GET_EQUIPMENTS } from 'data/queries/__generated__/GET_EQUIPMENTS';
import { GET_EQUIPMENTS_QUERY } from 'data/queries/equipment';

import { Loader } from 'components/Loader';
import { TotemDetails } from './TotemDetails';
import { Option } from 'components/TotemSelect';

type ParamTypes = {
    organizationId: string;
};

export const TotemsDetails = ({ mainUserOptions }: { mainUserOptions: Option<string>[] }) => {
    const { organizationId = '' } = useParams<ParamTypes>();

    const {
        loading: totemsLoading,
        error: totemsError,
        data: totemsData,
    } = useQuery<GET_DETAILED_TOTEMS_OF_ORGANIZATION, GET_DETAILED_TOTEMS_OF_ORGANIZATIONVariables>(
        GET_DETAILED_TOTEMS_OF_ORGANIZATION_QUERY,
        { variables: { organizationId } },
    );

    const {
        data: equipmentData,
        loading: equipmentLoading,
        error: equipmentError,
    } = useQuery<GET_EQUIPMENTS>(GET_EQUIPMENTS_QUERY);

    if (totemsLoading || equipmentLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (totemsError || !totemsData || equipmentError || !equipmentData) {
        throw new Error('Une erreur est survenue lors de la récupération des totems');
    }

    const totems = totemsData.totemsOfOrganization;
    const { adminEquipments } = equipmentData;

    return (
        <Container>
            <Totems>
                {totems.map((totem) => {
                    return (
                        <TotemDetails
                            key={totem._id}
                            totem={totem}
                            mainUserOptions={mainUserOptions}
                            equipments={adminEquipments}
                        />
                    );
                })}
            </Totems>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

const Totems = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: 5px;
    overflow-x: scroll;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

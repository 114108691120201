import React from 'react';
import styled from 'styled-components';

import { LocationsToUpdate, ProductReplacementPopupInfo } from './types';

import { GET_SITE_COLUMNS_siteWithLocationTemplates_microstoreColumnGroups_columns } from 'data/queries/__generated__/GET_SITE_COLUMNS';
import { GET_SITE_MENUS_siteMenus_columns_dailyMenus } from 'data/queries/__generated__/GET_SITE_MENUS';

export const SiteMenus = ({
    column,
    dailyMenus,
    hideDate,
    locationsToUpdate,
    selectedDays,
    setProductReplacementPopupInfo,
}: {
    column: GET_SITE_COLUMNS_siteWithLocationTemplates_microstoreColumnGroups_columns;
    dailyMenus: GET_SITE_MENUS_siteMenus_columns_dailyMenus[];
    hideDate: boolean;
    locationsToUpdate: LocationsToUpdate;
    selectedDays: boolean[];
    setProductReplacementPopupInfo: (arg: ProductReplacementPopupInfo) => void;
}) => (
    <Content>
        <Column>
            {column.shelves.map((shelf) => (
                <Shelf key={shelf._id}>
                    {shelf.locationTable.map((locationRow, rowIndex) => (
                        <LocationRow key={rowIndex}>
                            {locationRow.map((locationInColumn) => (
                                <Location key={locationInColumn._id}>
                                    {dailyMenus.map(({ date, productLocations }, dayIndex) => {
                                        if (!selectedDays[dayIndex]) return null;

                                        const productLocation = productLocations.find(
                                            ({ location }) => location._id === locationInColumn._id,
                                        );
                                        const replacementProduct =
                                            locationsToUpdate[locationInColumn._id]?.[dayIndex]?.newProduct;
                                        return (
                                            <ProductForDay
                                                key={`${locationInColumn._id}-${date}`}
                                                onClick={() => {
                                                    setProductReplacementPopupInfo({
                                                        productLocation,
                                                        locationInColumn,
                                                        dayIndex,
                                                    });
                                                }}
                                                replaced={
                                                    replacementProduct?._id !== productLocation?.product?._id &&
                                                    (replacementProduct === null || !!replacementProduct)
                                                }
                                            >
                                                <DateField hideDate={hideDate}>{date}</DateField>
                                                <NameField>
                                                    {replacementProduct === null
                                                        ? 'Produit retiré'
                                                        : // if we pick a product outside of the product arrangment we don't have the fullname in the fragment
                                                          // @ts-ignore
                                                          replacementProduct?.fullname ||
                                                          replacementProduct?.name ||
                                                          productLocation?.product?.fullname}
                                                </NameField>
                                            </ProductForDay>
                                        );
                                    })}
                                </Location>
                            ))}
                        </LocationRow>
                    ))}
                </Shelf>
            ))}
        </Column>
    </Content>
);

const Content = styled.div`
    flex: 1;
    overflow: scroll;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    padding: 5px;
    width: 100%;
`;

const Shelf = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    margin: auto;
`;

const LocationRow = styled.div`
    display: flex;
    flex: 1;
    margin: 2px;
    border: 1px solid ${({ theme }) => theme.darkBorderColor};
`;

const Location = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-basis: 0;
    flex-grow: 1;
    margin: 2px;
    border: 1px solid ${({ theme }) => theme.darkBorderColor};

    & > :not(:first-child) {
        border-top: 1px solid ${({ theme }) => theme.darkBorderColor};
    }
`;

const ProductForDay = styled.div<{ replaced: boolean }>`
    display: flex;
    flex-basis: 0;
    flex-grow: 1;
    width: 100%;
    flex-direction: column;
    padding: 3px;
    background-color: ${({ replaced, theme }) => (replaced ? theme.warningColor : theme.backgroundColor)};

    &:hover {
        background-color: ${({ theme }) => theme.overlayColor};
        color: ${({ theme }) => theme.overlayTextColor};
    }
`;

const DateField = styled.div<{ hideDate: boolean }>`
    ${({ hideDate }) => hideDate && 'display: none'};
    font-size: 13px;
    margin-bottom: 4px;
`;

const NameField = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

import { SubventionReloadPeriod } from 'data/__generated__';

export const SUBVENTIONS_STATES_LABELS = {
    0: 'Archivé',
    1: 'Regular',
};

export function getPeriodLabel(reload: SubventionReloadPeriod): string {
    switch (reload) {
        case SubventionReloadPeriod.Daily:
            return 'jour';
        case SubventionReloadPeriod.Weekly:
            return 'semaine';
        case SubventionReloadPeriod.Monthly:
            return 'mois';
        default:
            return 'inconnu';
    }
}

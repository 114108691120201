import React from 'react';
import { RouterProvider } from 'react-router-dom';

import { VERIFY_LOGGED_IN_verifyLoggedIn_user } from 'data/mutations/__generated__/VERIFY_LOGGED_IN';

import { UserContext } from '../../../src/contexts';
import { privateRouter } from './privateRouter';

export function Privatize({ user }: { user: VERIFY_LOGGED_IN_verifyLoggedIn_user }) {
    return (
        <UserContext.Provider value={user}>
            <RouterProvider router={privateRouter} />
        </UserContext.Provider>
    );
}

import gql from 'graphql-tag';

import { MESSAGES_SET_FRAGMENT } from '../fragments/messagesSet';

export const CREATE_MESSAGES_SET_MUTATION = gql`
    mutation CREATE_MESSAGES_SET($messagesSetInput: CreateMessagesSetInput!) {
        createMessagesSet(messagesSetInput: $messagesSetInput) {
            success
            errors
            createdMessagesSet {
                ...MessagesSetFragment
            }
        }
    }
    ${MESSAGES_SET_FRAGMENT}
`;

export const UPDATE_MESSAGES_SET_MUTATION = gql`
    mutation UPDATE_MESSAGES_SET($messagesSetId: ID!, $updateInput: UpdateMessagesSetInput!) {
        updateMessagesSet(messagesSetId: $messagesSetId, updateInput: $updateInput) {
            success
            errors
            updatedMessagesSet {
                ...MessagesSetFragment
            }
        }
    }
    ${MESSAGES_SET_FRAGMENT}
`;

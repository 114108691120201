import React from 'react';

import { CommonStatusTag } from 'components/CommonStatusTag';

import { colors } from 'constants/colors';

import { BundleState } from 'data/__generated__';
import { BUNDLE_STATES_LABELS } from '../constants/states';

const getColorsFromState = (state: BundleState) => {
    const { blue, green, lightGrey, pantinGrey, yellow, red, pureWhite, black } = colors;

    switch (state) {
        case BundleState.Created:
            return { backgroundColor: yellow, labelColor: black };
        case BundleState.Started:
            return { backgroundColor: blue, labelColor: pureWhite };
        case BundleState.InProgress:
            return { backgroundColor: black, labelColor: pureWhite };
        case BundleState.Completed:
            return { backgroundColor: green, labelColor: black };
        case BundleState.Paused:
            return { backgroundColor: lightGrey, labelColor: pureWhite };
        case BundleState.Aborted:
            return { backgroundColor: red, labelColor: pureWhite };
        case BundleState.Archived:
            return { backgroundColor: pantinGrey, labelColor: pureWhite };
    }
};

export const StatusTag = ({ state }: { state: BundleState }) => {
    const { backgroundColor, labelColor } = getColorsFromState(state);
    return (
        <CommonStatusTag
            backgroundColor={backgroundColor}
            labelColor={labelColor}
            label={BUNDLE_STATES_LABELS[state]}
        />
    );
};

import React from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Link, useParams, unstable_usePrompt } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { useForm, FormProvider } from 'react-hook-form';
import { FaSave } from 'react-icons/fa';

import { PAGES } from 'constants/pages';

import { getApiUrl } from 'data/connection/graphql-utils';
import { GET_SITES_QUERY } from 'data/queries/site';
import { GET_USER_QUERY } from 'data/queries/user';
import { GET_ACTIVE_ORGANIZATIONS } from 'data/queries/__generated__/GET_ACTIVE_ORGANIZATIONS';
import { GET_USER, GET_USERVariables } from 'data/queries/__generated__/GET_USER';
import { GET_SITES } from 'data/queries/__generated__/GET_SITES';
import { useGetTotemCashHistoryQuery } from 'data/__generated__';
import { GET_ACTIVE_ORGANIZATIONS_QUERY } from 'data/queries/organization';
import { UPDATE_ADMIN_USER_MUTATION } from 'data/mutations/user';
import { ATTACH_LAST_STRIPE_PAYMENT_METHOD_MUTATION } from 'data/mutations/paymentMethod';

import { Header, HeaderTitle } from 'components/Header';
import { Loader, LoaderModeType } from 'components/Loader';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { SectionColumn, SectionContainer } from 'components/DetailsView/Section';
import { PageTitle } from 'components/PageTitle';
import { PanelContainer, RightPanel } from 'components/DetailsView/Panel';
import { DetailsPanel, LastTransactions, PaymentMethods } from '../components';
import { CopyValue } from 'components/CopyValue';
import { TotemLabel } from 'components/TotemLabel';

type ParamTypes = {
    userId: string;
};

export const UserDetails = () => {
    // react-router-v6 considers params to be optionnal now, even if par of the route. We need to handle undefined cases that will never happen
    const { userId = '' } = useParams<ParamTypes>();

    const methods = useForm();
    const {
        handleSubmit,
        formState: { isDirty },
    } = methods;

    const [updateUser, { loading: updateUserLoading }] = useMutation(UPDATE_ADMIN_USER_MUTATION);
    const {
        loading: userLoading,
        data: userData,
        error: userError,
    } = useQuery<GET_USER, GET_USERVariables>(GET_USER_QUERY, {
        variables: { userId },
        skip: !userId,
    });

    const {
        loading: organizationsLoading,
        data: organizationsData,
        error: organizationsError,
    } = useQuery<GET_ACTIVE_ORGANIZATIONS>(GET_ACTIVE_ORGANIZATIONS_QUERY);

    const getTotemCashHistoryResult = useGetTotemCashHistoryQuery({ variables: { userId, offset: 0, limit: 5 } });
    const {
        loading: totemCashHistoryLoading,
        data: totemCashHistoryData,
        error: totemCashHistoryError,
    } = getTotemCashHistoryResult;

    const { loading: sitesLoading, data: sitesData, error: sitesError } = useQuery<GET_SITES>(GET_SITES_QUERY);

    const [attachLastStripePaymentMethod, { loading: attachLastStripePaymentMethodLoading }] = useMutation(
        ATTACH_LAST_STRIPE_PAYMENT_METHOD_MUTATION,
    );

    unstable_usePrompt({
        message: 'Êtes-vous sûr.e de vouloir quitter cette page sans enregistrer ?',
        when: ({ currentLocation, nextLocation }) => isDirty && currentLocation.pathname !== nextLocation.pathname,
    });

    if (organizationsLoading || sitesLoading || userLoading || totemCashHistoryLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (
        userError ||
        !userData ||
        !userData.userFromAdmin ||
        organizationsError ||
        !organizationsData ||
        sitesError ||
        !sitesData ||
        totemCashHistoryError ||
        !totemCashHistoryData
    ) {
        throw new Error("Une erreur est survenue lors de la récupération de l'utilisateur");
    }

    const { userFromAdmin } = userData;
    const { activeOrganizations: organizations } = organizationsData;
    const { sites } = sitesData;

    const { _id, getLastTransactions: lastTransactions, wallet } = userFromAdmin;

    const {
        profile: { firstname, lastname },
    } = userFromAdmin;

    const retrieveLastStripePaymentMethod = async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault();
        const {
            data: {
                attachLastStripePaymentMethod: { success, error },
            },
        } = await attachLastStripePaymentMethod({ variables: { userId } });
        if (error || !success) {
            toast.error(error || 'Erreur inconnue');
        }
    };

    const onSubmit = handleSubmit(async (fieldsToUpdate) => {
        const { data } = await updateUser({ variables: { fieldsToUpdate, userId } });

        if (data) {
            const {
                updateAdminUser: { success, error },
            } = data;
            if (success) {
                toast.success(`L'utilisateur ${firstname} ${lastname} a bien été modifiée`);
            } else {
                if (error) {
                    toast.error(error);
                } else {
                    throw Error("Une erreur inconnue s'est produite");
                }
            }
        } else {
            throw Error("Une erreur inconnue s'est produite");
        }
    });

    return (
        <Container>
            <FormProvider {...methods}>
                <Form onSubmit={onSubmit}>
                    <Header>
                        <HeaderTitle>
                            <PageTitle page={PAGES.userDetails} />
                        </HeaderTitle>
                        <CTAsContainer>
                            <TotemPrimaryButton
                                data-test="submit-button"
                                minWidth="140px"
                                type="submit"
                                disabled={!isDirty}
                            >
                                {updateUserLoading ? (
                                    <Loader size="18px" mode={LoaderModeType.Spin} />
                                ) : (
                                    <>
                                        <FaSave size={13} />
                                        <SaveLabel>Mettre à jour</SaveLabel>
                                    </>
                                )}
                            </TotemPrimaryButton>
                            <Link to="/users">
                                <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                            </Link>
                        </CTAsContainer>
                    </Header>
                    <Content>
                        <ScrollableContent>
                            <PanelContainer>
                                <DetailsPanel user={userFromAdmin} organizations={organizations} sites={sites} />
                                <RightPanel>
                                    <SectionContainer
                                        linkToMore={`/transactions?userId=${_id}`}
                                        title="Dernières transactions"
                                        isInitiallyOpen
                                    >
                                        <SectionColumn>
                                            <LastTransactions lastTransactions={lastTransactions} />
                                        </SectionColumn>
                                    </SectionContainer>
                                    <SectionContainer title="Moyens de paiement" isInitiallyOpen>
                                        <SectionColumn>
                                            <PaymentMethods
                                                userId={userId}
                                                wallet={wallet}
                                                getTotemCashHistoryResult={getTotemCashHistoryResult}
                                            />
                                            <TotemLabel>Lien d'ajout de CB</TotemLabel>
                                            <CopyValue value={`${getApiUrl()}/stripe/add/${_id}`} />
                                            <TotemPrimaryButton
                                                data-test="submit-button"
                                                minWidth="140px"
                                                type="submit"
                                                disabled={attachLastStripePaymentMethodLoading}
                                                onClick={retrieveLastStripePaymentMethod}
                                            >
                                                {attachLastStripePaymentMethodLoading ? (
                                                    <Loader size="18px" mode={LoaderModeType.Spin} />
                                                ) : (
                                                    'récupérer le dernier moyen de paiement sur Stripe'
                                                )}
                                            </TotemPrimaryButton>
                                        </SectionColumn>
                                    </SectionContainer>
                                </RightPanel>
                            </PanelContainer>
                        </ScrollableContent>
                    </Content>
                </Form>
            </FormProvider>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
`;

const SaveLabel = styled.span`
    margin-left: 5px;
`;

const CTAsContainer = styled.div`
    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const Content = styled.div`
    flex: 1;
    overflow: hidden;
`;

const ScrollableContent = styled.div`
    padding: 15px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
`;

import React from 'react';
import { useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { GET_MESSAGES_SETS_PAGINATED } from 'data/queries/__generated__/GET_MESSAGES_SETS_PAGINATED';
import { GET_MESSAGES_SETS_PAGINATED_QUERY } from 'data/queries/messagesSet';

import { FieldToDisplay, ListView } from 'components/ListView';
import { Loader } from 'components/Loader';
import { MessagesSetState } from 'data/__generated__';
import { getExtendedFormattedDatetime } from 'helpers/dateTimes';
import { CommonStatusTag } from 'components/CommonStatusTag';
import { colors } from 'constants/colors';

type Message = {
    title: string;
    body: string;
};

type MessagesSet = {
    _id: string;
    createdAt: string;
    name: string;
    messages: Message[];
    state: MessagesSetState.Regular;
};

export const MessagesSetsList = () => {
    const {
        loading: messagesSetLoading,
        error: messagesSetError,
        data: messagesSetData,
        fetchMore: fetchMoreMessagesSet,
    } = useQuery<GET_MESSAGES_SETS_PAGINATED>(GET_MESSAGES_SETS_PAGINATED_QUERY, {
        variables: {
            offset: 0,
            limit: 30,
        },
        fetchPolicy: 'cache-and-network',
    });

    const MESSAGES_SETS_FIELDS_TO_DISPLAY: FieldToDisplay<MessagesSet>[] = [
        { fieldName: 'name', label: 'Nom de la série' },
        { fieldName: 'createdAt', label: 'Date de création' },
        { fieldName: 'messages', label: 'Nombre de messages' },
        { fieldName: 'state', label: 'Statut' },
    ];

    if (messagesSetLoading && !messagesSetData) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (messagesSetError || !messagesSetData) {
        toast.error('Une erreur est survenue lors de la récupération des messages');
        return null;
    }

    const {
        messagesSetsPaginated: { hasMore, messagesSets },
    } = messagesSetData;

    const sortedMessagesSets = [...messagesSets].sort((setA, setB) => setB.createdAt - setA.createdAt);
    const { black, green } = colors;

    const formattedMessagesSets = sortedMessagesSets.map(({ _id, createdAt, name, messages, state }) => ({
        _id,
        state: <CommonStatusTag label={state} backgroundColor={green} labelColor={black} />,
        name,
        messages: messages.length,
        createdAt: getExtendedFormattedDatetime(createdAt),
    }));

    const loadMore = async () => {
        await fetchMoreMessagesSet({
            variables: {
                offset: messagesSets.length,
            },
        });
    };

    return (
        <Container>
            {formattedMessagesSets.length ? (
                <ListView<MessagesSet>
                    fieldsToDisplay={MESSAGES_SETS_FIELDS_TO_DISPLAY}
                    items={formattedMessagesSets}
                    loadMore={loadMore}
                    hasMore={hasMore}
                    linkBasePath="/messagesset/"
                    keyExtractor={(item) => item._id}
                />
            ) : (
                "Aucun message n'a été trouvé"
            )}
        </Container>
    );
};

const Container = styled.div`
    flex: 1;
    overflow-y: scroll;
`;

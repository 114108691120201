import { toast } from 'react-toastify';

export function displayErrors(errors: string[]) {
    if (errors.length) {
        errors.forEach((error) => toast.error(error, { autoClose: false }));
        toast.info('Cliquez pour fermer toutes les notifications', {
            autoClose: false,
            onClick: () => toast.dismiss(),
        });
    }
}

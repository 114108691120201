import React, { ReactNode } from 'react';
import { IoClose } from 'react-icons/io5';

import styled from 'styled-components';

export function TotemPopup({
    title,
    children,
    isOpen,
    setIsOpen,
    contentOverflow,
    position,
}: {
    title: string;
    children: ReactNode;
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
    contentOverflow?: string;
    position?: string;
}) {
    return (
        <OuterContainer isOpen={isOpen} position={position} onClick={() => setIsOpen(false)}>
            <InnerContainer onClick={(event) => event.stopPropagation()}>
                <Header>
                    <Title>{title}</Title>
                    <CloseIconContainer onClick={() => setIsOpen(false)}>
                        <IoClose size={22} />
                    </CloseIconContainer>
                </Header>
                <Content contentOverflow={contentOverflow}>{children}</Content>
            </InnerContainer>
        </OuterContainer>
    );
}

const OuterContainer = styled.div<{ isOpen: boolean; position?: string }>`
    position: ${({ position }) => position ?? 'absolute'};
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 10;
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
`;

const InnerContainer = styled.div`
    display: inline-flex;
    flex-direction: column;
    border-radius: ${({ theme }) => theme.borderRadius};
    margin: auto;
    padding: 15px;
    background-color: ${({ theme }) => theme.backgroundColor};
    max-height: 95%;
    max-width: 95%;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Title = styled.span`
    color: ${({ theme }) => theme.textColor};
    font-size: 26px;
    font-weight: 800;
`;

const CloseIconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 50px;
    padding: 5px;
    color: ${({ theme }) => theme.textColor};
    cursor: pointer;

    &:hover {
        color: ${({ theme }) => theme.ctaPrimaryColor};
    }
`;

const Content = styled.div<{ contentOverflow?: string }>`
    display: flex;
    flex-direction: column;
    color: ${({ theme }) => theme.textColor};
    margin-top: 10px;
    overflow: ${({ contentOverflow }) => contentOverflow ?? 'hidden'};
`;

import React, { InputHTMLAttributes } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import Switch from 'react-switch';
import styled from 'styled-components';

import { colors } from 'constants/colors';

import { TotemLabel } from '../TotemLabel';

type DetailFormCheckboxProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'defaultValue'> & {
    label?: string;
    sublabel?: string;
    name: string;
    defaultChecked?: boolean;
    disabled?: boolean;
};

export const DetailFormCheckbox = ({
    label,
    sublabel,
    name,
    defaultChecked = false,
    disabled = false,
}: DetailFormCheckboxProps) => {
    const { control } = useFormContext();

    return (
        <Container>
            <LabelContainer>
                <TotemLabel>{label}</TotemLabel>
                {sublabel ? <Sublabel>{sublabel}</Sublabel> : null}
            </LabelContainer>
            <ValueContainer>
                <Controller
                    name={name}
                    render={({ field: { onChange, value } }) => (
                        <Switch
                            onChange={onChange}
                            checked={value}
                            onColor={colors.green}
                            offColor={colors.pantinGrey}
                            disabled={disabled}
                        />
                    )}
                    control={control}
                    defaultValue={defaultChecked}
                />
            </ValueContainer>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    color: ${({ theme }) => theme.textColor};
`;

const LabelContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const Sublabel = styled.span`
    color: ${({ theme }) => theme.infoTextColor};
    font-size: 13px;
`;

const ValueContainer = styled.div`
    margin-top: 5px;
`;

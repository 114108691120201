import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import { FaSave } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { PAGES } from 'constants/pages';

import { CreateQuizVariables, CreateQuiz } from 'data/mutations/__generated__/CreateQuiz';
import { CREATE_QUIZ_MUTATION } from 'data/mutations/quiz';

import { Header, HeaderTitle } from 'components/Header';
import { Loader, LoaderModeType } from 'components/Loader';
import { PageTitle } from 'components/PageTitle';
import { QuizForm, QuizFormType } from './components/QuizForm';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

import { formatDateAsAnniversary } from 'helpers/dateTimes';

export const QuizCreate = () => {
    const navigate = useNavigate();

    const [createQuiz, { loading: creationLoading }] = useMutation<CreateQuiz, CreateQuizVariables>(
        CREATE_QUIZ_MUTATION,
    );

    const initialForm: QuizFormType = {
        audience: { organizationIds: [], siteIds: [], userIds: [] },
        date: null,
        description: null,
        image: null,
        imageUrl: null,
        questions: [],
        title: '',
    };
    const [quizForm, setQuizForm] = useState<QuizFormType>(initialForm);

    async function onSubmit() {
        if (!quizForm.questions.length) {
            toast.error('Veuillez renseigner au moins une question');
            return;
        }
        if (!quizForm.date) {
            toast.error('Date du quiz non renseignée.');
            return;
        }
        if (!quizForm.description) {
            toast.error('Description non renseignée.');
            return;
        }
        if (!quizForm.title) {
            toast.error('Titre du quiz non renseigné.');
            return;
        }
        if (!quizForm.image && !quizForm.imageUrl) {
            toast.error('Image du quiz non renseignée.');
            return;
        }
        let isQuizQuestionsWrong = false;
        quizForm.questions.forEach(({ answerOptions, correctAnswerIndex, label, image, imageUrl }, questionIndex) => {
            if (
                !label ||
                !answerOptions.length ||
                answerOptions.length <= correctAnswerIndex ||
                (!image && !imageUrl)
            ) {
                isQuizQuestionsWrong = true;
                if (!label) toast.error(`Question ${questionIndex + 1} : veuillez renseigner le texte de la question.`);
                if (!answerOptions.length)
                    toast.error(
                        `Question ${questionIndex + 1} : veuillez renseigner au moins une réponse à la question.`,
                    );
                if (answerOptions.length <= correctAnswerIndex)
                    toast.error(`Question ${questionIndex + 1} : veuillez cocher la réponse correcte.`);
                if (!image && !imageUrl) toast.error(`Question ${questionIndex + 1} : veuillez renseigner une image.`);
            }
        });
        if (isQuizQuestionsWrong) {
            return;
        }
        const { data } = await createQuiz({
            variables: {
                input: {
                    audience: quizForm.audience,
                    date: formatDateAsAnniversary({ dateTime: quizForm.date, useNewFormat: true }),
                    description: quizForm.description,
                    image: quizForm.image,
                    imageUrl: quizForm.imageUrl,
                    questions: quizForm.questions,
                    title: quizForm.title,
                },
            },
        });

        if (data) {
            const {
                createQuiz: { quiz, errors },
            } = data;
            if (quiz) {
                toast.success('Le quiz a bien été créé !');
                navigate(`/quiz/${quiz._id}`);
            } else {
                if (errors) {
                    errors.forEach((error) => {
                        toast.error(error, { autoClose: false });
                    });

                    if (errors.length > 1) {
                        toast.info('Cliquez pour fermer toutes les notifications', {
                            autoClose: false,
                            onClick: () => toast.dismiss(),
                        });
                    }
                } else {
                    throw Error("Une erreur inconnue s'est produite");
                }
            }
        } else {
            throw Error("Une erreur inconnue s'est produite");
        }
    }

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.quizCreate} />
                </HeaderTitle>
                <CTAsContainer>
                    <TotemPrimaryButton data-test="create-button" onClick={onSubmit} minWidth="78px">
                        {creationLoading ? (
                            <Loader size="18px" mode={LoaderModeType.Spin} />
                        ) : (
                            <>
                                <FaSave size={13} />
                                <SaveLabel>Créer</SaveLabel>
                            </>
                        )}
                    </TotemPrimaryButton>
                    <Link to="/quizzes">
                        <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                    </Link>
                </CTAsContainer>
            </Header>
            <Content>
                <ScrollableContent>
                    <QuizForm quizForm={quizForm} setQuizForm={setQuizForm} />
                </ScrollableContent>
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const CTAsContainer = styled.div`
    display: flex;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const SaveLabel = styled.span`
    margin-left: 5px;
`;

const Content = styled.div`
    flex: 1;
    overflow: hidden;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const ScrollableContent = styled.div`
    padding: 15px;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

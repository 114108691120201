import gql from 'graphql-tag';

export const CREATE_SITE_INVENTORY_STOCK_MUTATION = gql`
    mutation CREATE_SITE_INVENTORY_STOCK($siteId: ID!, $productId: ID!, $quantity: Int!, $explanation: String!) {
        createSiteInventoryStock(
            siteId: $siteId
            productId: $productId
            quantity: $quantity
            explanation: $explanation
        ) {
            success
            error
        }
    }
`;

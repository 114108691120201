import React from 'react';

import styled from 'styled-components';

import { Loader, LoaderModeType } from './Loader';

export const FullPageLoader = ({
    size = '55px',
    mode = LoaderModeType.Bounce,
}: {
    size?: string;
    mode?: LoaderModeType;
}) => {
    return (
        <Container>
            <Loader size={size} mode={mode} displayText={false} />
        </Container>
    );
};

const Container = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
`;

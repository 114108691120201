import React from 'react';

import styled from 'styled-components';

import { GET_SIMPLE_MICROSTORE_SITES_microstoreSites } from 'data/queries/__generated__/GET_SIMPLE_MICROSTORE_SITES';

export const SiteWithoutSetupTrelloItem = ({ site }: { site: GET_SIMPLE_MICROSTORE_SITES_microstoreSites }) => {
    return (
        <ItemContent>
            <ItemBody>
                <Title>{site.name}</Title>
            </ItemBody>
        </ItemContent>
    );
};

const ItemContent = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: ${({ theme }) => theme.borderRadius};
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
    overflow: hidden;
`;

const ItemBody = styled.div`
    padding: 10px;
`;

const Title = styled.span`
    font-size: 20px;
    font-weight: 800;
`;

import { useMutation } from '@apollo/client';
import React from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { Loader, LoaderModeType } from 'components/Loader';
import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

import {
    ABORT_TOTEM_CLOSED_DAY,
    ABORT_TOTEM_CLOSED_DAYVariables,
} from 'data/mutations/__generated__/ABORT_TOTEM_CLOSED_DAY';
import { ABORT_TOTEM_CLOSED_DAY_MUTATION } from 'data/mutations/totemClosedDay';

import { totemClosedDayRemovedHandler } from '../cacheHandlers/totemClosedDayCacheHandler';
import { TotemClosedDayFragment } from 'data/fragments/__generated__/TotemClosedDayFragment';

export function AbortTotemClosedDayPopup({
    isOpen,
    setIsOpen,
    totemClosedDay,
}: {
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
    totemClosedDay: TotemClosedDayFragment;
}) {
    const [abortTotemClosedDay, { loading }] = useMutation<ABORT_TOTEM_CLOSED_DAY, ABORT_TOTEM_CLOSED_DAYVariables>(
        ABORT_TOTEM_CLOSED_DAY_MUTATION,
    );

    async function handleSubmit() {
        const { data } = await abortTotemClosedDay({
            variables: {
                totemClosedDayId: totemClosedDay._id,
            },
        });

        if (!data) {
            throw new Error('Une erreur est survenue lors de la création du jour de fermeture');
        }

        const { abortTotemClosedDay: abortedTotemClosedDay } = data;

        if (abortedTotemClosedDay) {
            totemClosedDayRemovedHandler(abortedTotemClosedDay._id);
            toast.success('Jour de fermeture annulé');
            setIsOpen(false);
        } else {
            toast.error('Une erreur est survenue');
        }
    }

    return (
        <TotemPopup
            title="Annuler un jour de fermeture"
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            contentOverflow="visible"
        >
            <WarningMessage>
                Attention, supprimer un jour férié des jours de fermetures Totem ne le retire pas automatiquement des
                sites. Il faut le faire à la main ou faire un tech support.
            </WarningMessage>
            <SubmitContainer>
                <TotemPrimaryButton onClick={handleSubmit}>
                    {loading ? <Loader size="20px" mode={LoaderModeType.Spin} /> : 'Annuler le jour de fermeture'}
                </TotemPrimaryButton>
            </SubmitContainer>
        </TotemPopup>
    );
}

const SubmitContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 15px;
`;

const WarningMessage = styled.div`
    color: ${({ theme }) => theme.warningColor};
`;

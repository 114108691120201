import gql from 'graphql-tag';

export const QUIZ_FRAGMENT = gql`
    fragment QuizFragment on Quiz {
        _id
        audience {
            organizationIds
            siteIds
            userIds
        }
        createdAt
        date
        description
        imageUrl
        questions {
            label
            answerOptions
            correctAnswerIndex
            imageUrl
        }
        state
        title
        updatedAt
    }
`;

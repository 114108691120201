import React from 'react';

import styled from 'styled-components';
import Select from 'react-select';
import type { Props as SelectProps, OnChangeValue, ActionMeta } from 'react-select';
import { TotemLabel } from './TotemLabel';
import { colors } from 'constants/colors';

export type OptionValue = string | number | null;

export type Option<T extends OptionValue> = {
    label: string;
    value: T;
    isDisabled?: boolean;
};

export type SelectedOption<T extends OptionValue> = Option<T> | null | undefined;

interface TotemSelectProps<T extends OptionValue, IsMulti extends boolean> extends SelectProps<Option<T>, IsMulti> {
    label?: string;
    dataTest?: string;
    onChange: (option: OnChangeValue<Option<T>, IsMulti> | null | undefined, action: ActionMeta<Option<T>>) => void;
    width?: string;
}

export function TotemSelect<T extends OptionValue, IsMulti extends boolean = false>({
    label,
    onChange,
    dataTest,
    width,
    ...props
}: TotemSelectProps<T, IsMulti>) {
    const onSafeChange = (option: OnChangeValue<Option<T>, IsMulti>, action: ActionMeta<Option<T>>) => {
        const safeOption = option as OnChangeValue<Option<T>, IsMulti>;
        return onChange && onChange(safeOption, action);
    };

    return (
        <Container data-test={dataTest} width={width}>
            {label ? <TotemLabel>{label}</TotemLabel> : null}
            <Select classNamePrefix={dataTest} onChange={onSafeChange} closeMenuOnSelect={!props.isMulti} {...props} />
        </Container>
    );
}

const Container = styled.div<{ width?: string }>`
    display: flex;
    flex-direction: column;
    color: ${colors.black};
    width: ${({ width }) => width || '100%'};
    gap: 5px;
`;

import React, { useEffect, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { FaSave } from 'react-icons/fa';
import { Link, unstable_usePrompt } from 'react-router-dom';

import { PAGES } from 'constants/pages';

import { Header, HeaderTitle } from 'components/Header';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { Loader, LoaderModeType } from 'components/Loader';
import { PageTitle } from 'components/PageTitle';
import { GeneralInfoSection } from './GeneralInfoSection';
import { StatesSection } from './StatesSection';
import { ContactsSection } from './ContactsSection';
import { PaymentInfoSection } from './PaymentInfoSection';
import { OrderingTerms } from './OrderingTerms';
import { OrderingMode } from './OrderingMode';
import { OrderingTimes } from './OrderingTimes';

import { SupplierFormInput } from 'data/__generated__';
import { GET_DETAILED_SUPPLIER_detailedSupplier } from 'data/queries/__generated__/GET_DETAILED_SUPPLIER';
import { UPDATE_SUPPLIER, UPDATE_SUPPLIERVariables } from 'data/mutations/__generated__/UPDATE_SUPPLIER';
import { UPDATE_SUPPLIER_MUTATION } from 'data/mutations/supplier';
import { SupplierFragment } from 'data/fragments/__generated__/SupplierFragment';

import { formatFormArray } from 'pages/Products/utils';
import { removeFieldNameFromOrderingTimeForDay } from '../utils/removeFieldNameFromOrderingTimeForDay';

function getValuesFromObject(supplier: SupplierFragment) {
    const {
        name,
        description,
        phone,
        address,
        zipcode,
        website,
        contacts,
        clientCode,
        frequency,
        paymentTimeType,
        paymentTimeDays,
        orderingTerms,
        orderMode,
        orderEmails,
        orderingTimes,
        orderPlatformUrl,
    } = supplier;

    return {
        name,
        description,
        phone,
        address,
        zipcode,
        website,
        contacts,
        clientCode,
        frequency,
        paymentTimeType,
        paymentTimeDays,
        orderingTerms,
        orderMode,
        // need to transform string to object because useFieldArray doesn't support strings in arrays
        orderEmails: orderEmails.map((email) => {
            return { value: email };
        }),
        orderingTimes,
        orderPlatformUrl,
    };
}

export const SupplierGlobalForm = ({
    detailedSupplier,
}: {
    detailedSupplier: GET_DETAILED_SUPPLIER_detailedSupplier;
}) => {
    type FormData = Omit<GET_DETAILED_SUPPLIER_detailedSupplier, '__typename' | 'orderEmails'> & {
        orderEmails: { value: string }[];
    };

    const [supplierFormState, setSupplierFormState] = useState<SupplierFormInput>(detailedSupplier);

    const previousSupplier = useRef<SupplierFragment | null>(null);

    const methods = useForm<FormData>({
        defaultValues: getValuesFromObject(detailedSupplier),
    });

    const {
        handleSubmit,
        formState: { isDirty, isSubmitted, isSubmitting },
        reset,
    } = methods;

    useEffect(() => {
        setSupplierFormState(detailedSupplier);
    }, [detailedSupplier]);

    useEffect(() => {
        if (isSubmitted) {
            previousSupplier.current = null;
            return reset({}, { keepValues: true });
        }
        if (
            !isSubmitting &&
            previousSupplier.current &&
            detailedSupplier.updatedAt !== previousSupplier.current.updatedAt
        ) {
            toast.warning("Quelqu'un vient de modifier le fournisseur, la vue a été mise à jour !");
            reset(getValuesFromObject(detailedSupplier));
        }
        previousSupplier.current = detailedSupplier;
    }, [isSubmitting, isSubmitted, reset, detailedSupplier]);

    const [updateSupplier, { loading: updateLoading }] = useMutation<UPDATE_SUPPLIER, UPDATE_SUPPLIERVariables>(
        UPDATE_SUPPLIER_MUTATION,
    );

    unstable_usePrompt({
        message: 'Êtes-vous sûr.e de vouloir quitter cette page sans enregistrer ?',
        when: ({ currentLocation, nextLocation }) => hasChanged && currentLocation.pathname !== nextLocation.pathname,
    });

    const onSubmit = handleSubmit(async (fields) => {
        const cleanedContacts = fields.contacts.map(({ name, surname, phone, email, description }) => ({
            name,
            surname,
            phone,
            email,
            description,
        }));
        const cleanedOrderingTerms = fields.orderingTerms.map(({ deliveryPrice, minRequirements }) => ({
            deliveryPrice,
            minRequirements: minRequirements.map((minRequirementGroup) =>
                minRequirementGroup.map((minRequirement) => ({
                    unit: minRequirement.unit,
                    value: minRequirement.value,
                })),
            ),
        }));
        const cleanedOrderingTimes = {
            monday: removeFieldNameFromOrderingTimeForDay(fields.orderingTimes.monday),
            tuesday: removeFieldNameFromOrderingTimeForDay(fields.orderingTimes.tuesday),
            wednesday: removeFieldNameFromOrderingTimeForDay(fields.orderingTimes.wednesday),
            thursday: removeFieldNameFromOrderingTimeForDay(fields.orderingTimes.thursday),
            friday: removeFieldNameFromOrderingTimeForDay(fields.orderingTimes.friday),
            saturday: removeFieldNameFromOrderingTimeForDay(fields.orderingTimes.saturday),
            sunday: removeFieldNameFromOrderingTimeForDay(fields.orderingTimes.sunday),
        };

        const formattedOrderEmails = formatFormArray(fields.orderEmails);

        const { data } = await updateSupplier({
            variables: {
                supplierId: detailedSupplier._id,
                fields: {
                    ...fields,
                    // shouldn't have to specify frequency because the field is required (but TS is dumb here)
                    frequency: fields.frequency || 1,
                    contacts: cleanedContacts,
                    orderEmails: formattedOrderEmails,
                    orderingProductTerms: supplierFormState.orderingProductTerms.map(({ minQuantity, productId }) => ({
                        minQuantity,
                        productId,
                    })), // to remove __typename ...
                    closingDatesRanges: supplierFormState.closingDatesRanges.map(({ closingDate, openingDate }) => ({
                        closingDate,
                        openingDate,
                    })), // to remove __typename ...
                    orderingTerms: cleanedOrderingTerms,
                    orderingTimes: cleanedOrderingTimes,
                },
            },
        });
        if (data) {
            const {
                updateSupplier: { formErrors, updatedSupplier, error },
            } = data;
            if (updatedSupplier) {
                toast.success(`Le fournisseur "${updatedSupplier.name}" a bien été modifié !`);
            } else {
                if (error) {
                    toast.error(error);
                }
                if (formErrors) {
                    formErrors.forEach(({ sectionName, sectionErrors }, index) => {
                        toast.error(
                            <span key={index}>
                                Erreur dans la section "{sectionName}" :
                                {sectionErrors.map(({ fieldName, fieldError }, index) => (
                                    <span key={index}>
                                        <br />- {fieldName} : "{fieldError}"
                                    </span>
                                ))}
                            </span>,
                            { autoClose: false },
                        );
                    });

                    if (formErrors.length > 1) {
                        toast.info('Cliquez pour fermer toutes les notifications', {
                            autoClose: false,
                            onClick: () => toast.dismiss(),
                        });
                    }
                } else {
                    throw Error("Une erreur inconnue s'est produite");
                }
            }
        } else {
            throw Error("Une erreur inconnue s'est produite");
        }
    });

    const hasChanged = isDirty || JSON.stringify(detailedSupplier) !== JSON.stringify(supplierFormState);

    return (
        <>
            <FormProvider {...methods}>
                <Form onSubmit={onSubmit}>
                    <Header>
                        <HeaderTitle>
                            <PageTitle page={PAGES.supplierDetails} />
                        </HeaderTitle>
                        <CTAsContainer>
                            <TotemPrimaryButton
                                data-test="submit-button"
                                minWidth="140px"
                                type="submit"
                                disabled={!hasChanged}
                            >
                                {updateLoading ? (
                                    <Loader size="18px" mode={LoaderModeType.Spin} />
                                ) : (
                                    <>
                                        <FaSave size={13} />
                                        <SaveLabel>Enregistrer</SaveLabel>
                                    </>
                                )}
                            </TotemPrimaryButton>
                            <Link to="/suppliers">
                                <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                            </Link>
                        </CTAsContainer>
                    </Header>
                    <Content>
                        <ScrollableContent>
                            <GeneralInfoSection supplier={detailedSupplier} />
                            <StatesSection supplier={detailedSupplier} />
                            <ContactsSection />
                            <PaymentInfoSection supplier={detailedSupplier} />
                            <OrderingTerms
                                supplierId={detailedSupplier._id}
                                supplierFormState={supplierFormState}
                                setSupplierFormState={setSupplierFormState}
                            />
                            <OrderingMode supplier={detailedSupplier} />
                            <OrderingTimes
                                orderingTimes={detailedSupplier.orderingTimes}
                                supplierFormState={supplierFormState}
                                setSupplierFormState={setSupplierFormState}
                            />
                        </ScrollableContent>
                    </Content>
                </Form>
            </FormProvider>
        </>
    );
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
`;

const CTAsContainer = styled.div`
    display: flex;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const SaveLabel = styled.span`
    margin-left: 5px;
`;

const Content = styled.div`
    flex: 1;
    overflow: hidden;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const ScrollableContent = styled.div`
    padding: 15px;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

import gql from 'graphql-tag';
import { MICROSTORE_COLUMN_SETUP_FRAGMENT } from './microstoreColumnSetup';
import { SETUP_INSTRUCTION_FRAGMENT } from './setupInstruction';

export const MICROSTORE_COLUMN_GROUP_SETUP_FRAGMENT = gql`
    fragment MicrostoreColumnGroupSetupFragment on MicrostoreColumnGroupSetup {
        _id
        instructionsAfterColumns {
            ...SetupInstructionFragment
        }
        instructionsBeforeColumns {
            ...SetupInstructionFragment
        }
        isFinishForced
        isNeeded
        microstoreColumnGroupId
        microstoreColumnSetups {
            ...MicrostoreColumnSetupFragment
        }
        positionInSiteSetup
        siteSetupId
        state
        stateHistory {
            createdAt
            state
        }
        updatedAt
    }
    ${MICROSTORE_COLUMN_SETUP_FRAGMENT}
    ${SETUP_INSTRUCTION_FRAGMENT}
`;

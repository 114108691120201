import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import { useGetAdvertisementScreenConfigQuery } from 'data/__generated__';

export const AdvertisementScreenConfigLoggedOut = () => {
    const [displayedIndex, setDisplayedIndex] = useState<number>(0);
    const [savedTimeoutId, setSavedTimeoutId] = useState<ReturnType<typeof setTimeout> | null>(null);

    const {
        loading: advertisementScreenConfigLoading,
        data: advertisementScreenConfigData,
        error: advertisementScreenConfigError,
    } = useGetAdvertisementScreenConfigQuery({ pollInterval: 10000 });

    function changeImage() {
        const imageUrls = advertisementScreenConfigData?.getAdvertisementScreenConfig?.imageUrls;
        if (!imageUrls) {
            return;
        }
        const newDisplayedIndex = displayedIndex + 1;
        if (newDisplayedIndex >= imageUrls.length) {
            setDisplayedIndex(0);
        } else {
            setDisplayedIndex(newDisplayedIndex);
        }
    }

    useEffect(() => {
        if (savedTimeoutId) {
            clearTimeout(savedTimeoutId);
        }
        const timeoutId = setTimeout(changeImage, 3000);
        setSavedTimeoutId(timeoutId);
    }, [advertisementScreenConfigData, displayedIndex]);

    if (advertisementScreenConfigLoading && !advertisementScreenConfigData) {
        return <Container>Loading</Container>;
    }

    if (advertisementScreenConfigError || !advertisementScreenConfigData) {
        throw new Error('Une erreur est survenue lors de la récupération des publicités');
    }

    const { getAdvertisementScreenConfig: advertisementScreenConfig } = advertisementScreenConfigData;

    return (
        <Container>
            <Image
                src={advertisementScreenConfig.imageUrls[displayedIndex]}
                alt={advertisementScreenConfig.imageUrls[displayedIndex]}
            />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
`;

const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: fill;
`;

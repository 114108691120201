import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { FaPlus, FaTrash } from 'react-icons/fa';

import { FieldToDisplay, ListView } from 'components/ListView';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { TotemDatePicker } from 'components/TotemDatePicker';
import { TotemInput } from 'components/TotemInput';
import { TotemCheckbox } from 'components/TotemCheckbox';

import {
    MicrostoreLocationExpiryDateFormValues,
    MicrostoreLocationFormValues,
    MicrostoreProductsStockInfoFormValues,
} from './FormHelper/DataMapper';
import { dateFromString, formatDateAsAnniversary } from 'helpers/dateTimes';
import { Loader } from 'components/Loader';

type FormattedProductType = {
    deleteComponent: JSX.Element;
    expiryDateComponent: JSX.Element;
    quantityComponent: JSX.Element;
    shouldRemoveFromSiteComponent: JSX.Element;
};

function sortExpiryDate(
    expiryDates: MicrostoreLocationExpiryDateFormValues[],
): MicrostoreLocationExpiryDateFormValues[] {
    const sortedExpiryDates = [...expiryDates].sort((expiryDateA, expiryDateB) =>
        dateFromString(expiryDateA.expiryDate as string) < dateFromString(expiryDateB.expiryDate as string) ? -1 : 1,
    );

    return sortedExpiryDates;
}

function getExpiryDateColor(expiryDate: string, theme: any) {
    const today = new Date();
    if (formatDateAsAnniversary({ dateTime: today }) === expiryDate) {
        return theme.warningColor;
    }

    if (today > dateFromString(expiryDate)) {
        return theme.errorColor;
    }

    return 'inherit';
}

export const ExpiryDateEditor = ({
    isToday,
    microstoreProductsStockInfo,
    selectedLocation,
    updateSelectedLocation,
}: {
    isToday: boolean;
    microstoreProductsStockInfo: MicrostoreProductsStockInfoFormValues | null;
    selectedLocation: MicrostoreLocationFormValues;
    updateSelectedLocation: (values: Partial<MicrostoreLocationFormValues>) => void;
}) => {
    const theme = useContext(ThemeContext);

    if (!selectedLocation) {
        return <Container>Sélectionnez un emplacement</Container>;
    }

    if (!microstoreProductsStockInfo) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    function addExpiryDate() {
        if (isToday) {
            updateSelectedLocation({
                ...selectedLocation,
                expiryDates: [
                    ...selectedLocation.expiryDates,
                    {
                        expiryDate: formatDateAsAnniversary({ dateTime: new Date() }),
                        quantity: 0,
                        shouldRemoveFromSite: false,
                    },
                ],
            });
        }
    }

    function deleteExpiryDate(expiryDateArray: MicrostoreLocationExpiryDateFormValues[], index: number) {
        if (isToday) {
            updateSelectedLocation({
                ...selectedLocation,
                expiryDates: [...expiryDateArray.slice(0, index), ...expiryDateArray.slice(index + 1)],
            });
        }
    }

    function updateExpiryDate(
        expiryDateArray: MicrostoreLocationExpiryDateFormValues[],
        newExpiryDate: MicrostoreLocationExpiryDateFormValues,
        index: number,
    ) {
        if (isToday) {
            updateSelectedLocation({
                ...selectedLocation,
                expiryDates: [...expiryDateArray.slice(0, index), newExpiryDate, ...expiryDateArray.slice(index + 1)],
            });
        }
    }

    const EXPIRY_DATES_FIELDS_TO_DISPLAY: FieldToDisplay<FormattedProductType>[] = [
        {
            fieldName: 'expiryDateComponent',
            label: 'Date',
        },
        {
            fieldName: 'quantityComponent',
            label: 'Stock Théorique',
            maxWidth: '70px',
        },
    ];

    if (isToday) {
        EXPIRY_DATES_FIELDS_TO_DISPLAY.unshift({
            fieldName: 'deleteComponent',
            label: '',
        });
    }

    const expiryDates = isToday
        ? selectedLocation.expiryDates
        : microstoreProductsStockInfo
              .find(({ _id }) => _id === selectedLocation.replacementProductId)
              ?.expiryDateRecordsWarehouse?.map(({ expiryDate, quantityAtInventory }) => ({
                  expiryDate,
                  quantity: quantityAtInventory,
                  shouldRemoveFromSite: false,
              })) ?? [];

    const sortedExpiryDates = sortExpiryDate(expiryDates);

    const formattedExpiryDates: FormattedProductType[] = sortedExpiryDates.map(
        ({ expiryDate, quantity, shouldRemoveFromSite }, index, expiryDateArray) => ({
            deleteComponent: (
                <Button
                    type="button"
                    title="Supprimer une DLC"
                    onClick={() => deleteExpiryDate(expiryDateArray, index)}
                >
                    <FaTrash size={13} />
                </Button>
            ),
            expiryDateComponent: isToday ? (
                <ExpiryDateInputContainer color={getExpiryDateColor(expiryDate, theme)}>
                    <TotemDatePicker
                        selected={expiryDate ? dateFromString(expiryDate) : null}
                        onChange={(date: Date | null) => {
                            if (!date) {
                                return;
                            }
                            const newExpiryDate = formatDateAsAnniversary({ dateTime: date });
                            updateExpiryDate(
                                expiryDateArray,
                                { expiryDate: newExpiryDate, quantity, shouldRemoveFromSite },
                                index,
                            );
                        }}
                        className="expiry-date-input"
                    />
                </ExpiryDateInputContainer>
            ) : (
                <ExpiryDate color={getExpiryDateColor(expiryDate, theme)}>{expiryDate}</ExpiryDate>
            ),
            quantityComponent: isToday ? (
                <ExpiryDateInputContainer color={getExpiryDateColor(expiryDate, theme)}>
                    <TotemInput
                        type="number"
                        value={quantity}
                        onChange={(value) => {
                            const newQuantity = parseInt(value) || 0;
                            updateExpiryDate(
                                expiryDateArray,
                                { expiryDate, quantity: newQuantity, shouldRemoveFromSite },
                                index,
                            );
                        }}
                        width="100%"
                        step="1"
                        min="0"
                        required={true}
                        className="expiry-date-input"
                    />
                </ExpiryDateInputContainer>
            ) : (
                <div>{quantity}</div>
            ),
            shouldRemoveFromSiteComponent: (
                <ExpiryDateInputContainer color={getExpiryDateColor(expiryDate, theme)}>
                    <TotemCheckbox
                        checked={shouldRemoveFromSite}
                        onChange={() => {
                            updateExpiryDate(
                                expiryDateArray,
                                { expiryDate, quantity, shouldRemoveFromSite: !shouldRemoveFromSite },
                                index,
                            );
                        }}
                    />
                </ExpiryDateInputContainer>
            ),
        }),
    );

    return (
        <Container>
            <TitleContainer>
                <Title>{isToday ? 'DLC Site' : 'DLC Entrepôt'}</Title>
                {isToday ? (
                    <ButtonsContainer>
                        <Button type="button" title="Ajouter une DLC" onClick={() => addExpiryDate()}>
                            <FaPlus size={10} />
                        </Button>
                    </ButtonsContainer>
                ) : null}
            </TitleContainer>

            <ProductListContainer>
                <ListView<FormattedProductType>
                    fieldsToDisplay={EXPIRY_DATES_FIELDS_TO_DISPLAY}
                    isSmallDisplay={true}
                    items={formattedExpiryDates}
                />
            </ProductListContainer>
        </Container>
    );
};

const Container = styled.div`
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const Title = styled.span`
    font-size: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const ButtonsContainer = styled.div`
    margin-left: 10px;
`;

const Button = styled(TotemPrimaryButton)`
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ProductListContainer = styled.div`
    margin-top: 10px;
    overflow: hidden;
`;

const ExpiryDate = styled.span`
    color: ${({ color }) => color ?? 'inherit'};
`;

const ExpiryDateInputContainer = styled.div`
    & .expiry-date-input {
        color: ${({ color }) => color ?? 'inherit'};
        padding: 5px;
        max-width: 100px;
    }
`;

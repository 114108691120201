import gql from 'graphql-tag';
import { SITE_SETUP_FRAGMENT } from 'data/fragments/siteSetup';

export const GET_SITE_SETUP_FOR_DATE_QUERY = gql`
    query GET_SITE_SETUP_FOR_DATE($date: String!) {
        getSiteSetupsForDate(date: $date) {
            ...SiteSetupFragment
        }
    }
    ${SITE_SETUP_FRAGMENT}
`;

export const GET_SITE_SETUP_QUERY = gql`
    query GET_SITE_SETUP($siteSetupId: ID!) {
        getSiteSetup(siteSetupId: $siteSetupId) {
            ...SiteSetupFragment
        }
    }
    ${SITE_SETUP_FRAGMENT}
`;

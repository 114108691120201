import React, { useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import styled from 'styled-components';

import {
    UPDATE_SUPPLIER_PARTNERSHIP,
    UPDATE_SUPPLIER_PARTNERSHIPVariables,
} from 'data/mutations/__generated__/UPDATE_SUPPLIER_PARTNERSHIP';
import { UPDATE_SUPPLIER_PARTNERSHIP_MUTATION } from 'data/mutations/supplierPartnership';
import { SupplierPartnershipUpdateInput } from 'data/__generated__';

import { supplierPartnershipsUpdatedOrCreatedHandler } from './cacheHandlers/supplierPartnershipsCacheHandler';

import { Loader, LoaderModeType } from 'components/Loader';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { SupplierPartnershipFragment } from 'data/fragments/__generated__/SupplierPartnershipFragment';
import { TotemInput } from 'components/TotemInput';
import { TotemDatePicker } from 'components/TotemDatePicker';
import { dateFromString, formatDateAsAnniversary } from 'helpers/dateTimes';
import { DetailValue } from 'components/DetailsView';
import { ColumnsSectionContainer, SectionColumn } from 'components/DetailsView/Section';
import { GET_ALL_PRODUCTS_getAllProducts } from 'data/queries/__generated__/GET_ALL_PRODUCTS';
import { SelectedOption, TotemSelect } from 'components/TotemSelect';
import { toast } from 'react-toastify';
import { FaTrash } from 'react-icons/fa';
import { colors } from 'constants/colors';

export const SupplierPartnershipDetails = ({
    supplierPartnership,
    products,
}: {
    supplierPartnership: SupplierPartnershipFragment;
    products: GET_ALL_PRODUCTS_getAllProducts[];
}) => {
    const [supplierPartnershipForm, setSupplierPartnershipForm] =
        useState<SupplierPartnershipUpdateInput>(supplierPartnership);

    const hasChanged = JSON.stringify(supplierPartnershipForm) !== JSON.stringify(supplierPartnership);

    useEffect(() => {
        setSupplierPartnershipForm(supplierPartnership);
    }, [supplierPartnership, setSupplierPartnershipForm]);

    const [updateSupplierPartnership, { loading: updateLoading }] = useMutation<
        UPDATE_SUPPLIER_PARTNERSHIP,
        UPDATE_SUPPLIER_PARTNERSHIPVariables
    >(UPDATE_SUPPLIER_PARTNERSHIP_MUTATION);

    async function update() {
        const { _id, budget, endDate, name, productIds, startDate } = supplierPartnershipForm;
        const { data } = await updateSupplierPartnership({
            variables: { supplierPartnershipUpdateInput: { _id, budget, endDate, name, productIds, startDate } },
        });

        if (!data) {
            throw new Error('Une erreur est survenue lors de la mise à jour du trade marketing');
        }

        const { updateSupplierPartnership: newSupplierPartnership } = data;

        supplierPartnershipsUpdatedOrCreatedHandler([newSupplierPartnership]);
    }

    function addProduct(newProductId: string) {
        if (supplierPartnershipForm.productIds.some((productId) => newProductId === productId)) {
            toast.info('Le produit est déjà dans la liste');
            return;
        }

        setSupplierPartnershipForm({
            ...supplierPartnershipForm,
            productIds: [...supplierPartnershipForm.productIds, newProductId],
        });
    }

    function removeProduct(productIdToRemove: string) {
        const newProductIds = supplierPartnershipForm.productIds.filter((productId) => {
            return productId !== productIdToRemove;
        });

        setSupplierPartnershipForm({ ...supplierPartnershipForm, productIds: [...newProductIds] });
    }

    const productsOptions =
        products
            .filter(({ _id }) => !supplierPartnershipForm.productIds.some((productId) => _id === productId))
            .map(({ _id, fullname }) => ({
                value: _id,
                label: fullname,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)) || [];

    return (
        <Container>
            <TotemInput
                label="Nom"
                value={supplierPartnershipForm.name}
                onChange={(value) => setSupplierPartnershipForm({ ...supplierPartnershipForm, name: value })}
            />
            <TotemInput
                label="Budget"
                type="number"
                min="0"
                step="1"
                value={supplierPartnershipForm.budget}
                onChange={(value) =>
                    setSupplierPartnershipForm({ ...supplierPartnershipForm, budget: parseInt(value) })
                }
            />

            <ColumnsSectionContainer numberOfColumns={2}>
                <SectionColumn>
                    <TotemDatePicker
                        label="Date de début"
                        selected={dateFromString(supplierPartnershipForm.startDate, true)}
                        onChange={(date) => {
                            if (!date) {
                                return;
                            }
                            setSupplierPartnershipForm({
                                ...supplierPartnershipForm,
                                startDate: formatDateAsAnniversary({ dateTime: date, useNewFormat: true }),
                            });
                        }}
                    />
                    <DetailValue label="Créé le" value={new Date(supplierPartnership.createdAt).toLocaleString()} />
                </SectionColumn>
                <SectionColumn>
                    <TotemDatePicker
                        label="Date de fin"
                        selected={dateFromString(supplierPartnershipForm.endDate, true)}
                        onChange={(date) => {
                            if (!date) {
                                return;
                            }
                            setSupplierPartnershipForm({
                                ...supplierPartnershipForm,
                                endDate: formatDateAsAnniversary({ dateTime: date, useNewFormat: true }),
                            });
                        }}
                    />
                    <DetailValue
                        label="Mis à jour le"
                        value={new Date(supplierPartnership.updatedAt).toLocaleString()}
                    />
                </SectionColumn>
            </ColumnsSectionContainer>
            <TotemSelect
                placeholder="Sélectionner un produit"
                options={productsOptions}
                onChange={(option: SelectedOption<string>) => {
                    if (option) {
                        addProduct(option.value);
                    }
                }}
            />
            <ProductsContainer>
                {supplierPartnershipForm.productIds.map((productId) => {
                    const product = products.find(({ _id }) => productId === _id);
                    return (
                        <ProductContainer key={productId}>
                            <SmallButton type="button" onClick={() => removeProduct(productId)}>
                                <FaTrash size={15} />
                            </SmallButton>
                            <ProductName>{product?.fullname ?? productId}</ProductName>
                            {!product ? (
                                <ErrorMessage>Le produit n'est pas trouvable en base de données</ErrorMessage>
                            ) : null}
                        </ProductContainer>
                    );
                })}
            </ProductsContainer>
            <TotemPrimaryButton disabled={!hasChanged} onClick={update}>
                {updateLoading ? <Loader size="20px" mode={LoaderModeType.Spin} /> : 'Mettre à jour'}
            </TotemPrimaryButton>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 10px;
    margin-top: 20px;
    background-color: ${({ theme }) => theme.cardBackgroundColor};
    border-radius: ${({ theme }) => theme.borderRadius};
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
`;

const ProductsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const ProductContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
const ProductName = styled.div`
    font-weight: bold;
    margin-left: 15px;
    flex-grow: 1;
`;

const ErrorMessage = styled.div`
    color: ${colors.red};
`;

const SmallButton = styled(TotemPrimaryButton)`
    padding: 10px;
`;

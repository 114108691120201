import React, { InputHTMLAttributes } from 'react';
import { useFormContext, get } from 'react-hook-form';
import styled from 'styled-components';

import { getFormError } from 'constants/formErrors';

import { TotemLabel } from '../TotemLabel';
import { BasicInput } from '../BasicInput';

type DetailFormValueProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'defaultValue'> & {
    label?: string;
    sublabel?: string;
    name?: string;
    centerText?: boolean;
    defaultValue?: string | number | null;
    customError?: string;
};

export const DetailFormValue = React.forwardRef<HTMLInputElement, DetailFormValueProps>(
    ({ label, sublabel, name, defaultValue, customError, placeholder, style, value, ...props }, ref) => {
        const {
            formState: { errors },
        } = useFormContext();
        const errorObject = get(errors, name || '');
        const error = customError || (errorObject ? getFormError(errorObject.type, errorObject.message) : '');

        return (
            <Container style={style}>
                <LabelContainer hasMarginBottom={!!label || !!sublabel}>
                    {label ? <TotemLabel>{label}</TotemLabel> : null}
                    {sublabel ? <Sublabel>{sublabel}</Sublabel> : null}
                </LabelContainer>
                <ValueContainer>
                    <BasicInput
                        name={name}
                        placeholder={placeholder || 'Aucune valeur'}
                        defaultValue={defaultValue ?? ''}
                        width="100%"
                        ref={ref}
                        value={value}
                        {...props}
                    />
                    {error ? <Error>{error}</Error> : null}
                </ValueContainer>
            </Container>
        );
    },
);

const Error = styled.div`
    position: absolute;
    right: -2px;
    top: -16px;
    font-size: 14px;
    color: ${({ theme }) => theme.errorColor};
    width: max-content;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    color: ${({ theme }) => theme.textColor};
`;

const LabelContainer = styled.div<{ hasMarginBottom: boolean }>`
    display: flex;
    flex-direction: column;
    margin-bottom: ${({ hasMarginBottom }) => (hasMarginBottom ? 5 : 0)}px;
`;

const Sublabel = styled.span`
    color: ${({ theme }) => theme.infoTextColor};
    font-size: 13px;
`;

const ValueContainer = styled.div`
    position: relative;
    min-width: 70px;
`;

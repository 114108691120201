import React, { useState } from 'react';
import styled from 'styled-components';

import { RITUAL_TYPE_LABELS } from '../constants/types';

import { RitualGameFragment } from 'data/fragments/__generated__/RitualGameFragment';

import { ColumnsSectionContainer, SectionColumn } from 'components/DetailsView/Section';
import { DetailValue } from 'components/DetailsView';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { ChangeTypePopup } from './ChangeTypePopup';
import { GetDetailedRitualQuery } from 'data/__generated__';

export const TypeSection = ({
    ritual,
    ritualGame,
    isDirty,
}: {
    ritual: NonNullable<GetDetailedRitualQuery['detailedRitual']>;
    ritualGame: RitualGameFragment | null;
    isDirty: boolean;
}) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    return (
        <>
            <SeparatorContainer>
                <ColumnsSectionContainer numberOfColumns={3}>
                    <DetailValue label="Type de rituel" value={RITUAL_TYPE_LABELS[ritual.type]} />
                    <SectionColumn />
                    <SectionColumn>
                        {isDirty ? (
                            <WarningText>
                                ⚠️ Veuillez vous assurer d'avoir sauvegardé ou annulé vos ajustements avant de changer
                                le type du rituel
                            </WarningText>
                        ) : null}
                        <TotemPrimaryButton type="button" onClick={() => setIsPopupOpen(true)} disabled={isDirty}>
                            Changer de type
                        </TotemPrimaryButton>
                    </SectionColumn>
                </ColumnsSectionContainer>
            </SeparatorContainer>
            <ChangeTypePopup isOpen={isPopupOpen} ritual={ritual} ritualGame={ritualGame} setIsOpen={setIsPopupOpen} />
        </>
    );
};

const SeparatorContainer = styled.div`
    margin-top: 30px;
    padding-top: 15px;
    border-top: ${({ theme }) => `1px solid ${theme.lightBorderColor}`};
`;

const WarningText = styled.div`
    color: ${({ theme }) => theme.textColor};
    font-weight: 800;
`;

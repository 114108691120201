import React from 'react';

import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { RELAUNCH_PASS_SUPPLY_ORDERS_TO_ORDERED } from 'data/mutations/__generated__/RELAUNCH_PASS_SUPPLY_ORDERS_TO_ORDERED';
import { RELAUNCH_PASS_SUPPLY_ORDERS_TO_ORDERED_MUTATION } from 'data/mutations/supplyOrder';

import { Loader, LoaderModeType } from 'components/Loader';
import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

export const RelaunchPassSupplyOrdersToOrderedPopup = ({
    isOpen,
    setIsOpen,
}: {
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
}) => {
    const [relaunchPassSupplyOrdersToOrdered, { loading }] = useMutation<RELAUNCH_PASS_SUPPLY_ORDERS_TO_ORDERED>(
        RELAUNCH_PASS_SUPPLY_ORDERS_TO_ORDERED_MUTATION,
    );

    async function handleSubmit() {
        const { data } = await relaunchPassSupplyOrdersToOrdered();

        const errorMessage = "Une erreur est survenue lors de passage automatique des commandes en 'Commandé'";
        if (!data) {
            toast.error(errorMessage);
            return;
        }

        const {
            relaunchPassSupplyOrdersToOrdered: { updatedSupplyOrders, errors },
        } = data;

        if (updatedSupplyOrders?.length) {
            toast.success(`Achat(s) commandé(s) : ${updatedSupplyOrders.length}`);
        } else if (!updatedSupplyOrders?.length && !errors?.length) {
            toast.warning('Algorithme executé sans erreur, aucun achat commandé');
        }

        if (errors?.length) {
            errors.forEach((error, index) => {
                toast.error(<span key={index}>Erreur : {error}</span>, { autoClose: false });
            });

            if (errors.length > 1) {
                toast.info('Cliquez pour fermer toutes les notifications', {
                    autoClose: false,
                    onClick: () => toast.dismiss(),
                });
            }
        }

        setIsOpen(false);
    }

    return (
        <TotemPopup title="Êtes-vous sûr.e ?" isOpen={isOpen} setIsOpen={setIsOpen}>
            Relancer le passage automatique en 'Commandé' des commandes du jour ? (Cela est normalement fait
            automatiquement tous les jours à 9h30).
            <SubmitContainer>
                <TotemPrimaryButton onClick={handleSubmit}>
                    {loading ? <Loader size="20px" mode={LoaderModeType.Spin} /> : 'Confirmer'}
                </TotemPrimaryButton>
            </SubmitContainer>
        </TotemPopup>
    );
};

const SubmitContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 15px;
`;

import React from 'react';

import styled from 'styled-components';

import { SiteFragmentFragment } from 'data/__generated__';

type Metadata = Omit<SiteFragmentFragment['urbantzMetadata'], '__typename'>;

export const UrbantzMetadataForm = ({
    metadata,
    updateMetadata,
}: {
    metadata: SiteFragmentFragment['urbantzMetadata'];
    updateMetadata: (metadata: SiteFragmentFragment['urbantzMetadata']) => void;
}) => {
    const {
        needsCleaningMachineNECTA,
        needsInventoryBoissons,
        needsInventoryNonAlimentaire,
        needsInventorySnack,
        needsSmallExpiryDateInventory,
        replaceCompleteInventoryByZeroInventory,
    } = metadata;

    const metadataKeys: { name: keyof Metadata; activeWeekdays: { evenWeek: boolean; oddWeek: boolean }[] }[] = [
        { name: 'needsInventoryBoissons', activeWeekdays: needsInventoryBoissons },
        { name: 'needsInventoryNonAlimentaire', activeWeekdays: needsInventoryNonAlimentaire },
        { name: 'needsInventorySnack', activeWeekdays: needsInventorySnack },
        { name: 'needsCleaningMachineNECTA', activeWeekdays: needsCleaningMachineNECTA },
        { name: 'needsSmallExpiryDateInventory', activeWeekdays: needsSmallExpiryDateInventory },
        { name: 'replaceCompleteInventoryByZeroInventory', activeWeekdays: replaceCompleteInventoryByZeroInventory },
    ];

    function toggleDay({
        dayIndex,
        name,
        isEvenWeekCheckbox,
    }: {
        dayIndex: number;
        name: keyof Metadata;
        isEvenWeekCheckbox: boolean;
    }) {
        updateMetadata({
            ...metadata,
            [name]: metadata[name].map((value: { evenWeek: boolean; oddWeek: boolean }, index: number) =>
                index === dayIndex
                    ? {
                          evenWeek: isEvenWeekCheckbox ? !value.evenWeek : value.evenWeek,
                          oddWeek: isEvenWeekCheckbox ? value.oddWeek : !value.oddWeek,
                      }
                    : value,
            ),
        });
    }

    return (
        <Container>
            Séparation par semaines paires / semaines impaires
            <MetadataTable>
                <thead>
                    <tr>
                        <th>Nom</th>
                        <th>Lundi</th>
                        <th>Mardi</th>
                        <th>Mercredi</th>
                        <th>Jeudi</th>
                        <th>Vendredi</th>
                    </tr>
                </thead>
                <tbody>
                    {metadataKeys.map((metadataKey) => {
                        const { name, activeWeekdays } = metadataKey;

                        return (
                            <MetadataKey key={name}>
                                <MetadataCell>{name}</MetadataCell>
                                {activeWeekdays.map((weekday, dayIndex) => {
                                    return (
                                        <MetadataCell key={dayIndex} center>
                                            <MetadataCheckbox
                                                type="checkbox"
                                                checked={weekday.evenWeek}
                                                onChange={() => toggleDay({ dayIndex, name, isEvenWeekCheckbox: true })}
                                            />
                                            <MetadataCheckbox
                                                type="checkbox"
                                                checked={weekday.oddWeek}
                                                onChange={() =>
                                                    toggleDay({ dayIndex, name, isEvenWeekCheckbox: false })
                                                }
                                            />
                                        </MetadataCell>
                                    );
                                })}
                            </MetadataKey>
                        );
                    })}
                </tbody>
            </MetadataTable>
        </Container>
    );
};

const Container = styled.div``;

const MetadataTable = styled.table`
    width: 100%;
    color: ${({ theme }) => theme.textColor};
`;

const MetadataKey = styled.tr``;

const MetadataCell = styled.td<{ center?: boolean }>`
    text-align: ${({ center }) => (center ? 'center' : 'left')};
`;

const MetadataCheckbox = styled.input`
    width: 26px;
    height: 26px;
`;

import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Link, unstable_usePrompt } from 'react-router-dom';
import { FaSave } from 'react-icons/fa';

import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { UPDATE_ORGANIZATION, UPDATE_ORGANIZATIONVariables } from 'data/mutations/__generated__/UPDATE_ORGANIZATION';
import { GET_ORGANIZATION_organization } from 'data/queries/__generated__/GET_ORGANIZATION';
import { UPDATE_ORGANIZATION_MUTATION } from 'data/mutations/organization';
import { OrganizationPaymentType } from 'data/__generated__';

import { Header, HeaderTitle } from 'components/Header';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { GeneralInfoSection } from './GeneralInfoSection';
import { PaymentInfoSection } from './PaymentInfoSection';
import { StatesSection } from './StatesSection';
import { AdditionalInfoSection } from './AdditionalInfoSection';
import { TotemsDetails } from './TotemsDetails';

type FormData = {
    automaticInvoicing: boolean;
    B2BPriceRange: string | null;
    domains: { value: string }[];
    hasFreefoodIncluded: boolean;
    hasSubscription: boolean;
    maxUsers: number;
    paymentType: OrganizationPaymentType;
    hasPrepaidOrganizationCards: boolean;
    hasPrepaidSubventions: boolean;
    salesId: string | null;
    segregatedInvoicing: boolean;
    totalCoworkers: number;
    zuoraAccountId: string | null;
};

export const OrganizationGlobalForm = ({ organization }: { organization: GET_ORGANIZATION_organization }) => {
    const { _id: organizationId, name, domains } = organization;
    const [updateOrganization] = useMutation<UPDATE_ORGANIZATION, UPDATE_ORGANIZATIONVariables>(
        UPDATE_ORGANIZATION_MUTATION,
    );

    const methods = useForm<FormData>({
        defaultValues: {
            // need to do this because useFieldArray doesn't support strings, only objects
            domains: domains?.map((domain) => {
                return { value: domain };
            }),
        },
    });

    const {
        handleSubmit,
        formState: { isDirty, isSubmitSuccessful },
        reset,
    } = methods;

    unstable_usePrompt({
        message: 'Êtes-vous sûr.e de vouloir quitter cette page sans enregistrer ?',
        when: ({ currentLocation, nextLocation }) => isDirty && currentLocation.pathname !== nextLocation.pathname,
    });

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset({}, { keepValues: true });
        }
    }, [isSubmitSuccessful, reset]);

    const onSubmit = handleSubmit(async (fields) => {
        const formattedDomains = fields.domains.map((domain) => {
            return domain.value;
        });
        if (fields.hasSubscription !== organization.hasSubscription) {
            alert(
                "Attention : vous avez modifié l'abonnement livraisons illimitées, vous devez également le mettre à jour sur Stripe manuellement",
            );
            const newWindow = window.open(
                `https://dashboard.stripe.com/customers/${organization.stripeId}`,
                '_blank',
                'noopener,noreferrer',
            );
            if (newWindow) {
                newWindow.opener = null;
            }
        }
        const { data } = await updateOrganization({
            variables: { organizationId, fields: { ...fields, domains: formattedDomains } },
        });

        if (data) {
            const {
                updateOrganization: { error, formErrors, updatedOrganization },
            } = data;
            if (updatedOrganization) {
                toast.success(`L'organisation "${name}" a bien été modifiée !`);
            } else if (formErrors) {
                formErrors.forEach(({ sectionName, sectionErrors }, index) => {
                    toast.error(
                        <span key={index}>
                            Erreur dans la section "{sectionName}" :
                            {sectionErrors.map(({ fieldName, fieldError }, index) => (
                                <span key={index}>
                                    <br />- {fieldName} : "{fieldError}"
                                </span>
                            ))}
                        </span>,
                        { autoClose: false },
                    );
                });

                if (formErrors.length > 1) {
                    toast.info('Cliquez pour fermer toutes les notifications', {
                        autoClose: false,
                        onClick: () => toast.dismiss(),
                    });
                }
            } else if (error) {
                toast.error(error);
            } else {
                throw Error("Une erreur inconnue s'est produite");
            }
        }
    });

    const { admins } = organization;

    const mainUserOptions = admins.map(({ _id, fullname }) => ({ value: _id, label: fullname }));

    return (
        <>
            <Header>
                <HeaderTitle>{name}</HeaderTitle>
                <ButtonsContainer>
                    <Link to="/organizations">
                        <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                    </Link>
                </ButtonsContainer>
            </Header>
            <Content>
                <LeftPanel>
                    <FormProvider {...methods}>
                        <Form onSubmit={onSubmit}>
                            <Details>
                                <GeneralInfoSection organization={organization} />
                                <PaymentInfoSection organization={organization} />
                                <StatesSection organization={organization} />
                                <AdditionalInfoSection organization={organization} />
                            </Details>
                            <PanelFooter>
                                <TotemPrimaryButton type="submit" disabled={!isDirty}>
                                    <FaSave size="13" />
                                    <SaveLabel>Mettre à jour</SaveLabel>
                                </TotemPrimaryButton>
                            </PanelFooter>
                        </Form>
                    </FormProvider>
                </LeftPanel>
                <RightPanel>
                    <TotemsDetails mainUserOptions={mainUserOptions} />
                </RightPanel>
            </Content>
        </>
    );
};

const Content = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: 10px;
    overflow: hidden;
    color: ${({ theme }) => theme.textColor};
`;

const SaveLabel = styled.span`
    margin-left: 5px;
`;

const LeftPanel = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    min-width: 300px;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex: 1;
    background-color: ${({ theme }) => theme.cardBackgroundColor};
    border-radius: ${({ theme }) => theme.borderRadius};
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
`;

const Details = styled.div`
    flex: 1;
    overflow-y: scroll;
    padding: 5px;

    & > :not(:first-child) {
        margin-top: 5px;
    }
`;

const PanelFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-top: 1px solid ${({ theme }) => theme.lightBorderColor};
`;

const RightPanel = styled.div`
    flex: 3;
    background-color: ${({ theme }) => theme.cardBackgroundColor};
    border-radius: ${({ theme }) => theme.borderRadius};
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
    margin-left: 10px;
    overflow: hidden;
`;

const ButtonsContainer = styled.div`
    display: flex;
    width: max-content;
    align-items: center;

    ${TotemPrimaryButton} {
        margin-left: 5px;
    }
`;

import styled from 'styled-components';

export const Footer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 15px;
    background-color: ${({ theme }) => theme.menuBackgroundColor};
`;

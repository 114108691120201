import React from 'react';

import styled from 'styled-components';

import { ColumnsSectionContainer, SectionColumn, SectionContainer } from 'components/DetailsView/Section';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { TotemLabel } from 'components/TotemLabel';
import { TotemInput } from 'components/TotemInput';

export type FormValue = {
    name: string;
    messages: { body: string; redirectUrl: string | null }[];
};

export const MessagesSetFormContent = ({
    formValue,
    setFormValue,
}: {
    formValue: FormValue;
    setFormValue: (value: FormValue) => void;
}) => {
    const { messages } = formValue;

    function appendMessage() {
        setFormValue({ ...formValue, messages: [...formValue.messages, { body: '', redirectUrl: null }] });
    }

    function removeMessageAtIndex(indexToRemove: number) {
        setFormValue({
            ...formValue,
            messages: formValue.messages.filter((message, index) => index !== indexToRemove),
        });
    }

    function updateMessageBodyAtIndex(indexToUpdate: number, newValue: string) {
        setFormValue({
            ...formValue,
            messages: formValue.messages.map((message, index) =>
                index === indexToUpdate ? { ...message, body: newValue } : message,
            ),
        });
    }

    function updateMessageUrlAtIndex(indexToUpdate: number, newValue: string) {
        setFormValue({
            ...formValue,
            messages: formValue.messages.map((message, index) =>
                index === indexToUpdate ? { ...message, redirectUrl: newValue } : message,
            ),
        });
    }

    return (
        <Container>
            <TotemInput
                label="Nom de la série"
                value={formValue.name}
                onChange={(value) => setFormValue({ ...formValue, name: value })}
            />
            <SectionContainer title="Messages" isInitiallyOpen>
                <ColumnsSectionContainer numberOfColumns={4}>
                    {messages.map((message, index) => {
                        return (
                            <SectionColumn key={index}>
                                <TotemInput
                                    label="Message"
                                    maxLength={100}
                                    required
                                    width="100%"
                                    value={message.body}
                                    onChange={(value) => updateMessageBodyAtIndex(index, value)}
                                    data-test={`messages-${index}`}
                                />
                                <TotemInput
                                    label="Url de redirection"
                                    sublabel="(optionnelle)"
                                    width="100%"
                                    value={message.redirectUrl || ''}
                                    onChange={(value) => updateMessageUrlAtIndex(index, value)}
                                    data-test={`messages-${index}`}
                                />
                                <TotemPrimaryButton
                                    isSecondaryStyle
                                    type="button"
                                    onClick={() => removeMessageAtIndex(index)}
                                >
                                    Supprimer le message #{index + 1}
                                </TotemPrimaryButton>
                            </SectionColumn>
                        );
                    })}
                    <SectionColumn>
                        {!messages.length ? <TotemLabel>Aucun message ajouté</TotemLabel> : null}
                        {messages.length < 3 ? (
                            <TotemPrimaryButton type="button" onClick={() => appendMessage()}>
                                Ajouter un {messages.length ? 'nouveau' : null} message
                            </TotemPrimaryButton>
                        ) : null}
                    </SectionColumn>
                </ColumnsSectionContainer>
            </SectionContainer>
        </Container>
    );
};

const Container = styled.div`
    margin-top: 10px;
    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

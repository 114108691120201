import gql from 'graphql-tag';

export const CREATE_BUNDLES_MUTATION = gql`
    mutation CREATE_BUNDLES($flow: ProductFlow!, $deliveryType: DeliveryDestinationType!, $deliveryDate: String!) {
        createBundles(flow: $flow, deliveryType: $deliveryType, deliveryDate: $deliveryDate) {
            success
            errors
        }
    }
`;

export const UPDATE_BUNDLE_STATE_MUTATION = gql`
    mutation UPDATE_BUNDLE_STATE($bundleId: String!, $state: BundleState!) {
        updateBundleState(bundleId: $bundleId, state: $state) {
            success
            error
            bundle {
                _id
                state
            }
        }
    }
`;

export const GENERATE_SIGNATURE_MUTATION = gql`
    mutation GENERATE_SIGNATURE($bundleId: String!) {
        generateSignatureBundle(bundleId: $bundleId) {
            _id
        }
    }
`;

import React from 'react';
import styled from 'styled-components';

import { SiteSetupFragment } from 'data/fragments/__generated__/SiteSetupFragment';

import { SectionContainer } from 'components/DetailsView/Section';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { SiteSetupState } from 'data/__generated__';
import { useMutation } from '@apollo/client';
import {
    FORCE_FINISH_SITE_SETUP,
    FORCE_FINISH_SITE_SETUPVariables,
} from 'data/mutations/__generated__/FORCE_FINISH_SITE_SETUP';
import {
    FINISH_SITE_SETUP_WITH_MANUAL_GENERATION_MUTATION,
    FORCE_FINISH_SITE_SETUP_MUTATION,
} from 'data/mutations/siteSetup';
import {
    FINISH_SITE_SETUP_WITH_MANUAL_GENERATION,
    FINISH_SITE_SETUP_WITH_MANUAL_GENERATIONVariables,
} from 'data/mutations/__generated__/FINISH_SITE_SETUP_WITH_MANUAL_GENERATION';
import { Loader, LoaderModeType } from 'components/Loader';
import { toast } from 'react-toastify';

export const ActionSection = ({ siteSetup }: { siteSetup: SiteSetupFragment }) => {
    const [forceFinishSiteSetup, { loading: forceFinishSiteSetupLoading }] = useMutation<
        FORCE_FINISH_SITE_SETUP,
        FORCE_FINISH_SITE_SETUPVariables
    >(FORCE_FINISH_SITE_SETUP_MUTATION, { variables: { siteSetupId: siteSetup._id } });
    const [finishSiteSetupWithManualGeneration, { loading: finishSiteSetupWithManualGenerationLoading }] = useMutation<
        FINISH_SITE_SETUP_WITH_MANUAL_GENERATION,
        FINISH_SITE_SETUP_WITH_MANUAL_GENERATIONVariables
    >(FINISH_SITE_SETUP_WITH_MANUAL_GENERATION_MUTATION, { variables: { siteSetupId: siteSetup._id } });

    function displayToast({ success, errorMessage }: { success: boolean; errorMessage: string }) {
        if (success) {
            toast.success('Le rangement à bien été validé !');
        } else {
            toast.error(errorMessage);
        }
    }

    async function forceFinish() {
        const { data, errors } = await forceFinishSiteSetup();

        displayToast({
            success: !!data?.forceFinishSiteSetup,
            errorMessage: "Une erreur inconnue s'est produite : " + errors?.join(', '),
        });
    }

    async function manualFinish() {
        const { data, errors } = await finishSiteSetupWithManualGeneration();

        displayToast({
            success: !!data?.finishSiteSetupWithManualGeneration,
            errorMessage: "Une erreur inconnue s'est produite : " + errors?.join(', '),
        });
    }

    return (
        <SectionContainer title="Actions" isInitiallyOpen>
            <ButtonContainer>
                <TotemPrimaryButton
                    onClick={manualFinish}
                    disabled={finishSiteSetupWithManualGenerationLoading || siteSetup.state !== SiteSetupState.Created}
                >
                    Valider le rangement (fait à la main, pas de Totops)
                    {finishSiteSetupWithManualGenerationLoading ? (
                        <Loader mode={LoaderModeType.Spin} size="22px" />
                    ) : null}
                </TotemPrimaryButton>
                <TotemPrimaryButton
                    onClick={forceFinish}
                    disabled={forceFinishSiteSetupLoading || siteSetup.state !== SiteSetupState.InProgress}
                >
                    Valider le rangement (en forçant si besoin)
                    {forceFinishSiteSetupLoading ? <Loader mode={LoaderModeType.Spin} size="22px" /> : null}
                </TotemPrimaryButton>
            </ButtonContainer>
        </SectionContainer>
    );
};

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-around;

    & > :not(:first-child) {
        margin-left: 20px;
    }
`;

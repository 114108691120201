import React, { useState } from 'react';

import styled from 'styled-components';

import { Header, HeaderTitle } from 'components/Header';

import { PageTitle } from 'components/PageTitle';
import { PAGES } from 'constants/pages';
import { TotemDatePicker } from 'components/TotemDatePicker';
import { SiteVariationsList } from './SiteVariationsList';

export const StockVariationsByDay = () => {
    const [date, setDate] = useState<Date>(new Date());

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.stockVariationsByDay} />
                </HeaderTitle>

                <DateContainer>
                    <TotemDatePicker
                        label="Date des variations"
                        selected={date}
                        onChange={(date) => {
                            setDate(date as Date);
                        }}
                    />
                </DateContainer>
            </Header>
            <SiteVariationsList date={date} />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const DateContainer = styled.div`
    min-width: 300px;
    justify-self: end;
`;

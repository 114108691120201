import { apolloClient } from '../../../App';
import { ProductArrangementFragment } from 'data/fragments/__generated__/ProductArrangementFragment';
import { GET_PRODUCT_ARRANGEMENTS_QUERY } from 'data/queries/productArrangement';
import {
    GET_PRODUCT_ARRANGEMENTS,
    GET_PRODUCT_ARRANGEMENTS_productArrangementsQuery,
} from 'data/queries/__generated__/GET_PRODUCT_ARRANGEMENTS';

export const deletedProductArrangementsHandler = (deletedProductArrangementIds: string[]) => {
    let currentProductArrangements = [] as GET_PRODUCT_ARRANGEMENTS_productArrangementsQuery[];
    // We can read query only if it has been run
    try {
        const result = apolloClient.readQuery<GET_PRODUCT_ARRANGEMENTS>({ query: GET_PRODUCT_ARRANGEMENTS_QUERY });
        if (!result) {
            throw Error('An error occurred when reading GET_SUPPLY_ORDERS_QUERY');
        }
        currentProductArrangements = result.productArrangementsQuery;
    } catch {
        // do nothing
    }

    const newProductArrangements = currentProductArrangements.filter(
        (productArrangement) => !deletedProductArrangementIds.includes(productArrangement._id),
    );

    apolloClient.writeQuery({
        query: GET_PRODUCT_ARRANGEMENTS_QUERY,
        data: { productArrangementsQuery: newProductArrangements },
    });
};

export const productArrangementsUpdatedOrCreatedHandler = (
    productArrangementsUpdatedOrCreated: ProductArrangementFragment[],
) => {
    let currentProductArrangements = [] as GET_PRODUCT_ARRANGEMENTS_productArrangementsQuery[];
    // We can read query only if it has been run
    try {
        const result = apolloClient.readQuery<GET_PRODUCT_ARRANGEMENTS>({ query: GET_PRODUCT_ARRANGEMENTS_QUERY });
        if (!result) {
            throw Error('An error occurred when reading GET_SUPPLY_ORDERS_QUERY');
        }
        currentProductArrangements = result.productArrangementsQuery;
    } catch {
        // do nothing
    }

    const updatedOrCreatedProductArrangementIds = productArrangementsUpdatedOrCreated.map(({ _id }) => _id);
    const nonUpdatedOrCreatedProductArrangements = currentProductArrangements.filter(
        ({ _id }) => !updatedOrCreatedProductArrangementIds.includes(_id),
    );
    const newProductArrangements = [...productArrangementsUpdatedOrCreated, ...nonUpdatedOrCreatedProductArrangements];

    apolloClient.writeQuery({
        query: GET_PRODUCT_ARRANGEMENTS_QUERY,
        data: { productArrangementsQuery: newProductArrangements },
    });
};

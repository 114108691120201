import React, { useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import styled from 'styled-components';

import {
    UPDATE_CONSUMPTION_VARIATION,
    UPDATE_CONSUMPTION_VARIATIONVariables,
} from 'data/mutations/__generated__/UPDATE_CONSUMPTION_VARIATION';
import { UPDATE_CONSUMPTION_VARIATION_MUTATION } from 'data/mutations/consumptionVariation';
import { ConsumptionVariationUpdateInput } from 'data/__generated__';

import { consumptionVariationsUpdatedOrCreatedHandler } from './cacheHandlers/consumptionVariationsCacheHandler';

import { Loader, LoaderModeType } from 'components/Loader';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { ConsumptionVariationFragment } from 'data/fragments/__generated__/ConsumptionVariationFragment';
import { TotemDatePicker } from 'components/TotemDatePicker';
import { dateFromString, formatDateAsAnniversary } from 'helpers/dateTimes';
import { DetailValue } from 'components/DetailsView';
import { ColumnsSectionContainer, SectionColumn } from 'components/DetailsView/Section';
import { QuantityVariationDetails } from './QuantityVariationDetails';

export const ConsumptionVariationDetails = ({
    consumptionVariation,
}: {
    consumptionVariation: ConsumptionVariationFragment;
}) => {
    const [consumptionVariationForm, setConsumptionVariationForm] =
        useState<ConsumptionVariationUpdateInput>(consumptionVariation);

    const hasChanged = JSON.stringify(consumptionVariationForm) !== JSON.stringify(consumptionVariation);

    useEffect(() => {
        setConsumptionVariationForm(consumptionVariation);
    }, [consumptionVariation, setConsumptionVariationForm]);

    const [updateConsumptionVariation, { loading: updateLoading }] = useMutation<
        UPDATE_CONSUMPTION_VARIATION,
        UPDATE_CONSUMPTION_VARIATIONVariables
    >(UPDATE_CONSUMPTION_VARIATION_MUTATION);

    async function update() {
        const { _id, endDate, startDate } = consumptionVariationForm;
        const dessert = { type: consumptionVariationForm.dessert.type, value: consumptionVariationForm.dessert.value }; // remove fucking typename
        const main = { type: consumptionVariationForm.main.type, value: consumptionVariationForm.main.value }; // remove fucking typename
        const smallBites = {
            type: consumptionVariationForm.smallBites.type,
            value: consumptionVariationForm.smallBites.value,
        }; // remove fucking typename
        const starter = { type: consumptionVariationForm.starter.type, value: consumptionVariationForm.starter.value }; // remove fucking typename
        const { data } = await updateConsumptionVariation({
            variables: {
                consumptionVariationUpdateInput: { _id, endDate, startDate, dessert, main, smallBites, starter },
            },
        });

        if (!data) {
            throw new Error('Une erreur est survenue lors de la mise à jour de la variation de consommation');
        }

        const { updateConsumptionVariation: newConsumptionVariation } = data;

        consumptionVariationsUpdatedOrCreatedHandler([newConsumptionVariation]);
    }

    return (
        <Container>
            <ColumnsSectionContainer numberOfColumns={2}>
                <SectionColumn>
                    <TotemDatePicker
                        label="Date de début"
                        selected={dateFromString(consumptionVariationForm.startDate, true)}
                        onChange={(date) => {
                            if (!date) {
                                return;
                            }
                            setConsumptionVariationForm({
                                ...consumptionVariationForm,
                                startDate: formatDateAsAnniversary({ dateTime: date, useNewFormat: true }),
                            });
                        }}
                    />
                    <DetailValue label="Créé le" value={new Date(consumptionVariation.createdAt).toLocaleString()} />
                </SectionColumn>
                <SectionColumn>
                    <TotemDatePicker
                        label="Date de fin"
                        selected={dateFromString(consumptionVariationForm.endDate, true)}
                        onChange={(date) => {
                            if (!date) {
                                return;
                            }
                            setConsumptionVariationForm({
                                ...consumptionVariationForm,
                                endDate: formatDateAsAnniversary({ dateTime: date, useNewFormat: true }),
                            });
                        }}
                    />
                    <DetailValue
                        label="Mis à jour le"
                        value={new Date(consumptionVariation.updatedAt).toLocaleString()}
                    />
                </SectionColumn>
            </ColumnsSectionContainer>
            <QuantityVariationDetailsContainer>
                <QuantityVariationDetails
                    title="Entrée"
                    quantityVariation={consumptionVariationForm.starter}
                    updateQuantityVariation={(newValue) =>
                        setConsumptionVariationForm({
                            ...consumptionVariationForm,
                            starter: newValue,
                        })
                    }
                />
                <QuantityVariationDetails
                    title="Petite faim"
                    quantityVariation={consumptionVariationForm.smallBites}
                    updateQuantityVariation={(newValue) =>
                        setConsumptionVariationForm({
                            ...consumptionVariationForm,
                            smallBites: newValue,
                        })
                    }
                />
                <QuantityVariationDetails
                    title="Plat"
                    quantityVariation={consumptionVariationForm.main}
                    updateQuantityVariation={(newValue) =>
                        setConsumptionVariationForm({
                            ...consumptionVariationForm,
                            main: newValue,
                        })
                    }
                />
                <QuantityVariationDetails
                    title="Dessert"
                    quantityVariation={consumptionVariationForm.dessert}
                    updateQuantityVariation={(newValue) =>
                        setConsumptionVariationForm({
                            ...consumptionVariationForm,
                            dessert: newValue,
                        })
                    }
                />
            </QuantityVariationDetailsContainer>
            <TotemPrimaryButton disabled={!hasChanged} onClick={update}>
                {updateLoading ? <Loader size="20px" mode={LoaderModeType.Spin} /> : 'Mettre à jour'}
            </TotemPrimaryButton>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 10px;
    margin-top: 20px;
    background-color: ${({ theme }) => theme.cardBackgroundColor};
    border-radius: ${({ theme }) => theme.borderRadius};
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
`;

const QuantityVariationDetailsContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 5px;
`;

import React, { FunctionComponent, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { TotemLabel } from 'components/TotemLabel';
import { ImagePicker } from './form-components/ImagePicker';

export type UpsertSupplierDiscountFormValues = {
    name: string;
    rate: string;
    productIds: string;
    purchaseStartDate: string;
    purchaseEndDate: string;
    salesStartDate: string;
    salesEndDate: string;
    image?: File | null;
};

type UpsertSupplierDiscountFormProps = {
    imageUrl: string | null;
    onSubmit: (values: UpsertSupplierDiscountFormValues) => void;
    defaultValues?: UpsertSupplierDiscountFormValues;
};

export const UpsertSupplierDiscountForm: FunctionComponent<UpsertSupplierDiscountFormProps> = ({
    onSubmit,
    defaultValues,
    imageUrl,
}) => {
    const { register, handleSubmit, formState, reset, control } = useForm<UpsertSupplierDiscountFormValues>({
        defaultValues,
    });

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues, reset]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column' }}>
            <InputContainer>
                <TotemLabel>Nom de l'opération</TotemLabel>
                <Input type="text" placeholder="Enter name" {...register('name', { required: true })} />
            </InputContainer>
            <InputContainer>
                <TotemLabel>Taux de remise (%)</TotemLabel>
                <Input
                    type="number"
                    step="0.01"
                    placeholder="Enter rate"
                    {...register('rate', { required: true, min: 0, max: 100 })}
                />
            </InputContainer>
            <InputContainer>
                <TotemLabel>IDs des produits concernés (1 par ligne)</TotemLabel>
                <MultilineInput placeholder="Un par ligne" {...register('productIds', { required: false })} />
            </InputContainer>
            <InputContainer>
                <TotemLabel>Période d'achat</TotemLabel>
                <DateInputContainer>
                    <Input
                        type="date"
                        placeholder="Enter rate"
                        style={{ flex: 1 }}
                        {...register('purchaseStartDate', { required: true, min: 0, max: 100 })}
                    />
                    <Input
                        type="date"
                        placeholder="Enter rate"
                        style={{ flex: 1 }}
                        {...register('purchaseEndDate', { required: true, min: 0, max: 100 })}
                    />
                </DateInputContainer>
            </InputContainer>
            <InputContainer>
                <TotemLabel>Période de vente</TotemLabel>
                <DateInputContainer>
                    <Input
                        type="date"
                        placeholder="Enter rate"
                        style={{ flex: 1 }}
                        {...register('salesStartDate', { required: true, min: 0, max: 100 })}
                    />
                    <Input
                        type="date"
                        placeholder="Enter rate"
                        style={{ flex: 1 }}
                        {...register('salesEndDate', { required: true, min: 0, max: 100 })}
                    />
                </DateInputContainer>
            </InputContainer>
            <ImagePicker control={control} defaultValue={imageUrl} errors={formState.errors} name="image" />
            <TotemPrimaryButton type="submit" disabled={!formState.isValid}>
                OK
            </TotemPrimaryButton>
        </Form>
    );
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const Input = styled.input`
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    outline: none;
    &:focus {
        border-color: #333;
    }
`;

const DateInputContainer = styled.div`
    display: flex;
    gap: 4px;
`;

const MultilineInput = styled.textarea`
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    outline: none;
    resize: vertical;
    &:focus {
        border-color: #333;
    }
`;

import { SupplyOrderProductIssueType } from 'data/__generated__';

export const SUPPLY_ORDER_ISSUE_LABELS: Record<SupplyOrderProductIssueType, string> = {
    [SupplyOrderProductIssueType.NotReceived]: '❌ Pas reçus',
    [SupplyOrderProductIssueType.BadExpiryDate]: '❄️ Mauvaise DLC',
    [SupplyOrderProductIssueType.DifferentQuantity]: '🤷 Mauvaise quantité',
    [SupplyOrderProductIssueType.DifferentPackaging]: '📦 Mauvais colisage',
    [SupplyOrderProductIssueType.BrokenQuantity]: '💔 Cassés',
    [SupplyOrderProductIssueType.NewBarcode]: '💻 Nouveau code barre',
    [SupplyOrderProductIssueType.WrongProduct]: '➕ Pas attendus dans cet achat',
    [SupplyOrderProductIssueType.UnknownProduct]: '➕ Pas dans notre catalog',
};

import styled from 'styled-components';

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.cardBackgroundColor};
    width: 100%;
    padding: 10px 15px;
    box-shadow: ${({ theme }) => theme.boxShadow};
    z-index: 1; // necessary for the shadows
`;

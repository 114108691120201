import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import Switch from 'react-switch';

import { PAGES } from 'constants/pages';
import { colors } from 'constants/colors';

import { GET_EQUIPMENTS_QUERY } from 'data/queries/equipment';
import { GET_SUPPLIERS_QUERY } from 'data/queries/supplier';
import { GET_EQUIPMENTS } from 'data/queries/__generated__/GET_EQUIPMENTS';
import { GET_SUPPLIERS } from 'data/queries/__generated__/GET_SUPPLIERS';
import { EquipmentState } from 'data/__generated__';
import { GET_TOTEMS_WITH_EQUIPMENTS_QUERY } from 'data/queries/totem';
import { GET_TOTEMS_WITH_EQUIPMENTS } from 'data/queries/__generated__/GET_TOTEMS_WITH_EQUIPMENTS';

import { Header, HeaderTitle } from 'components/Header';
import { FieldToDisplay, ListView } from 'components/ListView';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { PageTitle } from 'components/PageTitle';
import { Loader } from 'components/Loader';
import { TotemImage } from 'components/TotemImage';
import { TotemLabel } from 'components/TotemLabel';
import { DetailLink } from 'components/DetailsView';

import { StatusTag } from 'pages/Equipments/components/StatusTag';

type FormattedEquipmentType = {
    _id: string;
    fullname: string;
    image: JSX.Element;
    state: JSX.Element;
    supplierName: JSX.Element;
    totemsList: JSX.Element;
};
export const Equipments = () => {
    const [shouldHideNotRegular, setShouldHideNotRegular] = useState(true);
    const {
        data: equipmentData,
        loading: equipmentLoading,
        error: equipmentError,
    } = useQuery<GET_EQUIPMENTS>(GET_EQUIPMENTS_QUERY);
    const {
        loading: suppliersLoading,
        data: suppliersData,
        error: suppliersError,
    } = useQuery<GET_SUPPLIERS>(GET_SUPPLIERS_QUERY);

    const {
        loading: totemsWithEquipmentsLoading,
        data: totemsWithEquipmentsData,
        error: totemsWithEquipmentsError,
    } = useQuery<GET_TOTEMS_WITH_EQUIPMENTS>(GET_TOTEMS_WITH_EQUIPMENTS_QUERY);

    if (equipmentLoading || suppliersLoading || totemsWithEquipmentsLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (
        equipmentError ||
        !equipmentData ||
        suppliersError ||
        !suppliersData ||
        totemsWithEquipmentsError ||
        !totemsWithEquipmentsData
    ) {
        throw new Error('Une erreur est survenue lors de la récupération des équipements');
    }

    const { adminEquipments: equipments } = equipmentData;
    const { suppliers: dbSuppliers } = suppliersData;
    const { totemsWithEquipments } = totemsWithEquipmentsData;

    const EQUIPMENT_FIELDS_TO_DISPLAY: FieldToDisplay<FormattedEquipmentType>[] = [
        { fieldName: 'image', label: 'Photo' },
        {
            fieldName: 'fullname',
            label: 'Nom de produit',
        },
        {
            fieldName: 'state',
            label: 'Statut',
        },
        {
            fieldName: 'supplierName',
            label: 'Fournisseur',
        },
        {
            fieldName: 'totemsList',
            label: "Totems ayant l'équipement",
        },
    ];

    const filteredEquipments = equipments.filter(({ state }) =>
        shouldHideNotRegular ? state === EquipmentState.Regular : true,
    );

    const formattedEquipments: FormattedEquipmentType[] = filteredEquipments.map(
        ({ _id, fullname, imageUrl, state, suppliers }) => {
            const supplier = dbSuppliers.find((supplier) => supplier._id === suppliers?.[0]?._id);
            const totemsWithEquipment = totemsWithEquipments.filter(({ equipments }) =>
                equipments.some(({ equipmentId }) => equipmentId === _id),
            );

            return {
                _id,
                image: <TotemImage src={imageUrl} alt="Pas d'image" size={100} />,
                fullname,
                state: <StatusTag state={state} />,
                supplierName: (
                    <DetailLink
                        path="supplier"
                        name={supplier?.name}
                        value={suppliers?.[0]?._id ?? 'Pas de fournisseur'}
                    />
                ),
                totemsList: (
                    <>
                        {totemsWithEquipment.map((totem) => {
                            const {
                                _id: totemId,
                                organization: { name },
                                number,
                            } = totem;
                            const totemEquipment = totem.equipments.find(({ equipmentId }) => equipmentId === _id);
                            return (
                                <TotemName key={totemId}>
                                    • {name} - Totem #{number}. Quantité: {totemEquipment?.quantity ?? -1}
                                </TotemName>
                            );
                        })}
                    </>
                ),
            };
        },
    );

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.equipments} />
                </HeaderTitle>
                <ButtonsContainer>
                    <TotemLabel>Afficher uniquement en statut 'Régulier'</TotemLabel>
                    <Switch
                        onChange={() => setShouldHideNotRegular(!shouldHideNotRegular)}
                        checked={shouldHideNotRegular}
                        onColor={colors.green}
                        offColor={colors.pantinGrey}
                    />
                </ButtonsContainer>
            </Header>
            <Content>
                <ListContainer>
                    <ListView<FormattedEquipmentType>
                        fieldsToDisplay={EQUIPMENT_FIELDS_TO_DISPLAY}
                        items={formattedEquipments}
                        keyExtractor={(item) => item._id}
                        linkBasePath="/equipment/"
                    />
                </ListContainer>
            </Content>
        </Container>
    );
};

const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    ${TotemPrimaryButton} {
        margin-left: 5px;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    flex: 1;
    overflow: hidden;
`;

const ListContainer = styled.div`
    flex: 1;
    overflow: hidden;
`;

const TotemName = styled.ul`
    margin: 0;
    padding: 0;
`;

import React, { useState } from 'react';
import styled from 'styled-components';
import validator from 'validator';

import { TotemInput } from 'components/TotemInput';
import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { SectionColumn } from 'components/DetailsView/Section';

export const SynchronizePlanogramWithSenseiConfirmationPopup = ({
    isOpen,
    confirm,
    setIsOpen,
}: {
    isOpen: boolean;
    confirm: (arg: string | null) => void;
    setIsOpen: (arg: boolean) => void;
}) => {
    const [columnPhotoUrl, setColumnPhotoUrl] = useState<string | null>(null);
    const isValid = !columnPhotoUrl || validator.isURL(columnPhotoUrl);

    return (
        <TotemPopup title="Confirmation" isOpen={isOpen} setIsOpen={setIsOpen} contentOverflow="auto" position="fixed">
            <SectionColumn>
                <TotemInput
                    label="URL de la photo de la colonne après le réassort"
                    sublabel="Optionel, pourra être demandée par Sensei"
                    placeholder="www.whatever.com"
                    value={columnPhotoUrl || ''}
                    onChange={(value) => setColumnPhotoUrl(value)}
                />
                {!isValid ? <WarningText>⚠️ url invalide</WarningText> : null}
                <TotemPrimaryButton
                    disabled={!isValid}
                    onClick={() => {
                        confirm(columnPhotoUrl);
                        setIsOpen(false);
                    }}
                >
                    Envoyer le planogramme
                </TotemPrimaryButton>
            </SectionColumn>
        </TotemPopup>
    );
};

const WarningText = styled.div`
    color: ${({ theme }) => theme.textColor};
    font-weight: 500;
`;

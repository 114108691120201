import { Theme } from 'contexts/theme';
import { colors } from './colors';

export const lightTheme: Theme = {
    backgroundColor: colors.culturedGrey,
    darkBackgroundColor: '#f2f2f2',
    textColor: colors.black,
    infoTextColor: colors.pantinGrey,
    menuBackgroundColor: colors.black,
    menuTextColor: colors.pureWhite,
    cardBackgroundColor: colors.pureWhite,
    pictureBackgroundColor: colors.culturedGrey,
    ctaPrimaryColor: colors.green,
    ctaPrimaryTextColor: colors.black,
    ctaPrimaryBorderColor: colors.greenDarker,
    ctaPrimaryHoveredColor: colors.greenDarker,
    ctaSecondaryColor: colors.black,
    ctaSecondaryTextColor: colors.pureWhite,
    ctaSecondaryBorderColor: colors.pureBlack,
    ctaSecondaryHoveredColor: colors.hoveredBlack,
    lightBorderColor: colors.borderGrey,
    darkBorderColor: colors.darkGrey,
    disabledColor: colors.lightGrey,
    disabledTextColor: colors.black,
    errorColor: colors.red,
    successColor: colors.green,
    warningColor: colors.orange,
    popupOverlayColor: 'rgba(0, 0, 0, 0.7)',
    overlayColor: colors.hoveredBlack,
    overlayTextColor: colors.pureWhite,
    boxShadow:
        '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
};

export const darkTheme: Theme = {
    backgroundColor: colors.black,
    darkBackgroundColor: colors.pureBlack,
    textColor: colors.pureWhite,
    infoTextColor: colors.pantinGrey,
    menuBackgroundColor: colors.black,
    menuTextColor: colors.pureWhite,
    cardBackgroundColor: colors.pureBlack,
    pictureBackgroundColor: colors.culturedGrey,
    ctaPrimaryColor: colors.green,
    ctaPrimaryTextColor: colors.black,
    ctaPrimaryBorderColor: colors.greenDarker,
    ctaPrimaryHoveredColor: colors.greenDarker,
    ctaSecondaryColor: colors.lightGrey,
    ctaSecondaryTextColor: colors.black,
    ctaSecondaryBorderColor: colors.pureBlack,
    ctaSecondaryHoveredColor: colors.pureWhite,
    lightBorderColor: colors.borderGrey,
    darkBorderColor: colors.greenDarker,
    disabledColor: colors.black,
    disabledTextColor: colors.lightGrey,
    errorColor: colors.red,
    successColor: colors.green,
    warningColor: colors.orange,
    popupOverlayColor: 'rgba(0, 0, 0, 0.7)',
    overlayColor: colors.hoveredBlack,
    overlayTextColor: colors.pureWhite,
    boxShadow: `0px 0px 1px 0px ${colors.greenDarker}`,
    borderRadius: '4px',
};

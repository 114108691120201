import React, { TextareaHTMLAttributes } from 'react';

import styled from 'styled-components';

import { BasicTextArea } from './BasicTextArea';
import { TotemLabel } from './TotemLabel';

type TotemTextAreaProps = Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'onChange'> & {
    onChange: (value: string) => void;
    label?: string;
    dataTest?: string;
    centerText?: boolean;
    autoFocus?: boolean;
};

export const TotemTextArea = ({ label, onChange, dataTest, centerText, autoFocus, ...props }: TotemTextAreaProps) => {
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const {
            target: { value },
        } = e;
        onChange(value);
    };

    return (
        <TextAreaContainer>
            {label ? <TotemLabel>{label}</TotemLabel> : null}
            <BasicTextArea
                centerText={centerText}
                onChange={handleChange}
                data-test={dataTest}
                autoFocus={autoFocus}
                {...props}
            />
        </TextAreaContainer>
    );
};

const TextAreaContainer = styled.div`
    display: flex;
    flex-direction: column;

    & > :not(:first-child) {
        margin-top: 5px;
    }
`;

import React from 'react';

import Switch from 'react-switch';

import { NotificationPreferenceChannel } from 'data/__generated__';
import { colors } from 'constants/colors';
import { useMutation } from '@apollo/client';
import { TOGGLE_USER_NOTIFICATION_PREFERENCE_FROM_ADMIN } from 'data/mutations/user';
import {
    toggleUserNotificationPreferenceFromAdmin,
    toggleUserNotificationPreferenceFromAdminVariables,
} from 'data/mutations/__generated__/toggleUserNotificationPreferenceFromAdmin';
import { notificationPreferencesUpdatedHandler } from '../utils/userUpdatedHandlers';

export const notificationPreferencesInfo: Record<
    string,
    { channel: NotificationPreferenceChannel; isEditable: boolean }[]
> = {
    specialOffers: [
        { channel: NotificationPreferenceChannel.Email, isEditable: true },
        { channel: NotificationPreferenceChannel.Push, isEditable: true },
        { channel: NotificationPreferenceChannel.InApp, isEditable: false },
    ],
    recommendations: [
        { channel: NotificationPreferenceChannel.Email, isEditable: true },
        { channel: NotificationPreferenceChannel.Push, isEditable: true },
        { channel: NotificationPreferenceChannel.InApp, isEditable: false },
    ],
    weeklySummary: [
        { channel: NotificationPreferenceChannel.Email, isEditable: true },
        { channel: NotificationPreferenceChannel.InApp, isEditable: false },
    ],
    newFeatures: [
        { channel: NotificationPreferenceChannel.Email, isEditable: true },
        { channel: NotificationPreferenceChannel.Push, isEditable: true },
        { channel: NotificationPreferenceChannel.InApp, isEditable: false },
    ],
    feedbacks: [
        { channel: NotificationPreferenceChannel.Email, isEditable: true },
        { channel: NotificationPreferenceChannel.Push, isEditable: true },
        { channel: NotificationPreferenceChannel.InApp, isEditable: false },
    ],
    dailyDelivery: [
        { channel: NotificationPreferenceChannel.Email, isEditable: true },
        { channel: NotificationPreferenceChannel.Push, isEditable: true },
        { channel: NotificationPreferenceChannel.InApp, isEditable: false },
    ],
    startingRitual: [
        { channel: NotificationPreferenceChannel.Push, isEditable: true },
        { channel: NotificationPreferenceChannel.InApp, isEditable: false },
    ],
    siteActivity: [
        { channel: NotificationPreferenceChannel.Push, isEditable: true },
        { channel: NotificationPreferenceChannel.InApp, isEditable: true },
    ],
    ritualInvite: [
        { channel: NotificationPreferenceChannel.Email, isEditable: true },
        { channel: NotificationPreferenceChannel.Push, isEditable: true },
        { channel: NotificationPreferenceChannel.InApp, isEditable: false },
    ],
    customRitualActivity: [
        { channel: NotificationPreferenceChannel.Email, isEditable: true },
        { channel: NotificationPreferenceChannel.Push, isEditable: true },
        { channel: NotificationPreferenceChannel.InApp, isEditable: false },
    ],
    reminders: [{ channel: NotificationPreferenceChannel.Email, isEditable: false }],
};

export const NotificationPreferenceRow = ({
    category,
    channels,
    userId,
}: {
    category: string;
    channels: NotificationPreferenceChannel[];
    userId: string;
}) => {
    const [toggleNotificationPreference] = useMutation<
        toggleUserNotificationPreferenceFromAdmin,
        toggleUserNotificationPreferenceFromAdminVariables
    >(TOGGLE_USER_NOTIFICATION_PREFERENCE_FROM_ADMIN);

    function getToggleChannelCallback(channel: NotificationPreferenceChannel) {
        return async function () {
            const { data } = await toggleNotificationPreference({
                variables: { channel, notificationPreference: category, userId },
            });

            if (data) {
                const { toggleUserNotificationPreferenceFromAdmin: notificationPreferences } = data;

                notificationPreferencesUpdatedHandler({ notificationPreferences, userId });
            }
        };
    }

    const categoryChannelsInfo = notificationPreferencesInfo[category];

    if (!categoryChannelsInfo) {
        return null;
    }

    return (
        <tr>
            <td>{category}</td>
            <td>
                {categoryChannelsInfo.some(
                    (channelInfo) => channelInfo.channel === NotificationPreferenceChannel.Email,
                ) ? (
                    <Switch
                        onChange={getToggleChannelCallback(NotificationPreferenceChannel.Email)}
                        checked={channels.includes(NotificationPreferenceChannel.Email)}
                        onColor={colors.green}
                        offColor={colors.pantinGrey}
                        disabled={
                            !categoryChannelsInfo.some(
                                (channelInfo) =>
                                    channelInfo.channel === NotificationPreferenceChannel.Email &&
                                    channelInfo.isEditable,
                            )
                        }
                    />
                ) : null}
            </td>
            <td>
                {categoryChannelsInfo.some(
                    (channelInfo) => channelInfo.channel === NotificationPreferenceChannel.Push,
                ) ? (
                    <Switch
                        onChange={getToggleChannelCallback(NotificationPreferenceChannel.Push)}
                        checked={channels.includes(NotificationPreferenceChannel.Push)}
                        onColor={colors.green}
                        offColor={colors.pantinGrey}
                        disabled={
                            !categoryChannelsInfo.some(
                                (channelInfo) =>
                                    channelInfo.channel === NotificationPreferenceChannel.Push &&
                                    channelInfo.isEditable,
                            )
                        }
                    />
                ) : null}
            </td>
            <td>
                {categoryChannelsInfo.some(
                    (channelInfo) => channelInfo.channel === NotificationPreferenceChannel.InApp,
                ) ? (
                    <Switch
                        onChange={getToggleChannelCallback(NotificationPreferenceChannel.InApp)}
                        checked={channels.includes(NotificationPreferenceChannel.InApp)}
                        onColor={colors.green}
                        offColor={colors.pantinGrey}
                        disabled={
                            !categoryChannelsInfo.some(
                                (channelInfo) =>
                                    channelInfo.channel === NotificationPreferenceChannel.InApp &&
                                    channelInfo.isEditable,
                            )
                        }
                    />
                ) : null}
            </td>
        </tr>
    );
};

import type { LazyQueryResult } from '@apollo/client';
import React from 'react';

import {
    GET_SUPPLY_ORDER_SUPPLIER_PRODUCTS_STOCK_VARIATION,
    GET_SUPPLY_ORDER_SUPPLIER_PRODUCTS_STOCK_VARIATIONVariables,
} from 'data/queries/__generated__/GET_SUPPLY_ORDER_SUPPLIER_PRODUCTS_STOCK_VARIATION';
import { GET_SUPPLY_ORDERS_supplyOrders_supplier_orderingTimes } from 'data/queries/__generated__/GET_SUPPLY_ORDERS';
import { GET_PRODUCTS_TO_ORDER_ONCE_FOR_SUPPLY_ORDER_productsToOrderOnceForSupplyOrder } from 'data/queries/__generated__/GET_PRODUCTS_TO_ORDER_ONCE_FOR_SUPPLY_ORDER';

import { DetailValue } from 'components/DetailsView/DetailValue';
import { SectionColumn, SectionContainer, ColumnsSectionContainer } from 'components/DetailsView/Section';
import { ProductsInfoTable } from './ProductsInfoTable';

import { SupplyOrderDetailsDisplayValues } from './supplyOrderDetailsFormHelper';

export const ProductsInfoSection = ({
    supplyOrderDetailsDisplayValues,
    productsStockVariationQueryState,
    productsToOrderOnce,
    orderingTimes,
}: {
    supplyOrderDetailsDisplayValues: SupplyOrderDetailsDisplayValues;
    productsStockVariationQueryState: LazyQueryResult<
        GET_SUPPLY_ORDER_SUPPLIER_PRODUCTS_STOCK_VARIATION,
        GET_SUPPLY_ORDER_SUPPLIER_PRODUCTS_STOCK_VARIATIONVariables
    >;
    productsToOrderOnce: GET_PRODUCTS_TO_ORDER_ONCE_FOR_SUPPLY_ORDER_productsToOrderOnceForSupplyOrder[];
    orderingTimes: GET_SUPPLY_ORDERS_supplyOrders_supplier_orderingTimes;
}) => {
    const {
        totalVolume,
        totalWeight,
        orderingTermsValidity: { isValid, deliveryPrice },
    } = supplyOrderDetailsDisplayValues;

    return (
        <SectionContainer title="Informations produits" isInitiallyOpen>
            <>
                <ColumnsSectionContainer numberOfColumns={3}>
                    <SectionColumn>
                        <DetailValue
                            isValid={isValid}
                            label="Coût de la livraison"
                            value={isValid ? `${deliveryPrice} €` : 'Conditions de commande non remplies'}
                        />
                    </SectionColumn>
                    <SectionColumn>
                        <DetailValue label="Poids total des produits demandés" value={`${totalWeight} g`} />
                    </SectionColumn>
                    <SectionColumn>
                        <DetailValue label="Volume total des produits demandés" value={`${totalVolume} cm3`} />
                    </SectionColumn>
                </ColumnsSectionContainer>
                <ProductsInfoTable
                    supplyOrderDetailsDisplayValues={supplyOrderDetailsDisplayValues}
                    productsStockVariationQueryState={productsStockVariationQueryState}
                    orderingTimes={orderingTimes}
                    productsToOrderOnce={productsToOrderOnce}
                />
            </>
        </SectionContainer>
    );
};

import {
    GET_DETAILED_SUPPLIER_detailedSupplier_orderingTimes_monday,
    GET_DETAILED_SUPPLIER_detailedSupplier_orderingTimes_tuesday,
    GET_DETAILED_SUPPLIER_detailedSupplier_orderingTimes_wednesday,
    GET_DETAILED_SUPPLIER_detailedSupplier_orderingTimes_thursday,
    GET_DETAILED_SUPPLIER_detailedSupplier_orderingTimes_friday,
    GET_DETAILED_SUPPLIER_detailedSupplier_orderingTimes_saturday,
    GET_DETAILED_SUPPLIER_detailedSupplier_orderingTimes_sunday,
} from 'data/queries/__generated__/GET_DETAILED_SUPPLIER';

export function removeFieldNameFromOrderingTimeForDay(
    orderingDay:
        | GET_DETAILED_SUPPLIER_detailedSupplier_orderingTimes_monday
        | GET_DETAILED_SUPPLIER_detailedSupplier_orderingTimes_tuesday
        | GET_DETAILED_SUPPLIER_detailedSupplier_orderingTimes_wednesday
        | GET_DETAILED_SUPPLIER_detailedSupplier_orderingTimes_thursday
        | GET_DETAILED_SUPPLIER_detailedSupplier_orderingTimes_friday
        | GET_DETAILED_SUPPLIER_detailedSupplier_orderingTimes_saturday
        | GET_DETAILED_SUPPLIER_detailedSupplier_orderingTimes_sunday,
) {
    return {
        orderBefore: orderingDay.orderBefore,
        daysToDeliver: orderingDay.daysToDeliver,
        deliveryHours: {
            start: orderingDay.deliveryHours.start,
            end: orderingDay.deliveryHours.end,
        },
        canBeOrdered: orderingDay.canBeOrdered,
    };
}

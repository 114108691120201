import React from 'react';

import { useMutation } from '@apollo/client';
import { Link, useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';
import styled from 'styled-components';

import { PAGES } from '../../constants/pages';
import { CREATE_BADGE_MUTATION } from 'data/mutations/badge';
import { CREATE_BADGE, CREATE_BADGEVariables } from 'data/mutations/__generated__/CREATE_BADGE';

import { Header, HeaderTitle } from 'components/Header';
import { PageTitle } from 'components/PageTitle';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

import { BadgeForm } from './components/BadgeForm';
import { SelectedOption } from 'components/TotemSelect';
import { BadgeType } from 'data/__generated__';
import { BADGE_TYPES } from './constants/types';

type FormType = {
    _id: string | null;
    name: string;
    typeOption: SelectedOption<BadgeType>;
    image: File | null;
    imageUrl: string | null;
};

export function BadgeCreate() {
    const navigate = useNavigate();
    const preferenceTypeOption = { value: BadgeType.Preference, label: BADGE_TYPES.preference };
    const initialFormData = {
        _id: null,
        name: '',
        typeOption: preferenceTypeOption,
        image: null,
        imageUrl: null,
    };

    const [createBadge] = useMutation<CREATE_BADGE, CREATE_BADGEVariables>(CREATE_BADGE_MUTATION);
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>, formData: FormType) => {
        e.preventDefault();
        const { name, typeOption, image } = formData;
        if (!typeOption) {
            toast.error("L'un des champs n'a pas été renseigné");
            return;
        }

        const { value: type } = typeOption;
        const result = await createBadge({ variables: { badge: { name, type, image } } });
        const { data } = result;
        if (data) {
            const {
                createBadge: { success },
            } = data;
            if (success) {
                toast.success(`Le badge "${formData.name}" a bien été créé !`);
                navigate('/badges');
            } else {
                const {
                    createBadge: { error },
                } = data;
                if (error) {
                    toast.error(`L'erreur suivante est survenue : ${error}`);
                } else {
                    throw Error("Une erreur inconnue s'est produite");
                }
            }
        } else {
            throw Error("Une erreur inconnue s'est produite");
        }
    };

    function canISubmit(submitData: FormType): boolean {
        return submitData.name !== '' && submitData.image !== null;
    }

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.badgeCreate} />
                </HeaderTitle>
                <Link to="/badges">
                    <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                </Link>
            </Header>
            <Content>
                <BadgeForm<FormType>
                    handleSubmit={handleSubmit}
                    initialFormData={initialFormData}
                    canISubmit={canISubmit}
                    submitMessage="Créer un badge"
                />
            </Content>
        </Container>
    );
}

const Container = styled.div`
    flex-direction: column;
    flex: 1;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Content = styled.div`
    padding: 15px;
`;

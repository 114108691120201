import React from 'react';

import { useFormContext, useWatch } from 'react-hook-form';
import styled from 'styled-components';

import { ColumnsSectionContainer, SectionColumn } from 'components/DetailsView/Section';
import { GET_DETAILED_EQUIPMENT_detailedEquipment } from 'data/queries/__generated__/GET_DETAILED_EQUIPMENT';
import { TotemImage } from 'components/TotemImage';
import { DetailFormCheckbox, DetailFormTextArea, DetailFormValue, DetailValue } from 'components/DetailsView';
import { DetailDisplayValue } from 'components/DetailsView/DetailDisplayValue';
import { getProductFullName } from 'pages/Products/utils';
import { getExtendedFormattedDatetime } from 'helpers/dateTimes';
import { CopyValue } from 'components/CopyValue';

export const EquipmentFormContent = ({ equipment }: { equipment: GET_DETAILED_EQUIPMENT_detailedEquipment }) => {
    const {
        _id: equipmentId,
        createdAt,
        updatedAt,
        imageUrl,
        name,
        brand,
        volume,
        price,
        description,
        hidden,
        height,
        width,
        length,
        weight,
    } = equipment;

    const { control, register } = useFormContext();
    const updatedTitleValues: string[] = useWatch({
        control,
        name: ['name', 'brand', 'volume'],
        defaultValue: [name, brand, volume],
    });

    return (
        <Container>
            <ColumnsSectionContainer numberOfColumns={3}>
                <SectionColumn>
                    <CopyValue value={equipmentId} label="Id" />
                    <Row>
                        <TotemImage src={imageUrl} size={200} alt={name} />
                        <Column>
                            <DetailValue label="Date de création" value={getExtendedFormattedDatetime(createdAt)} />
                            <DetailValue label="Dernière mise à jour" value={getExtendedFormattedDatetime(updatedAt)} />
                            <DetailFormCheckbox
                                label="Caché"
                                sublabel="Équipement ne sera visible sur l'app si caché"
                                name="hidden"
                                defaultChecked={hidden}
                            />
                        </Column>
                    </Row>
                    <DetailFormValue
                        label="Prix"
                        sublabel="En €"
                        type="number"
                        defaultValue={price}
                        {...register('price', { valueAsNumber: true })}
                    />
                </SectionColumn>
                <SectionColumn>
                    <DetailFormValue
                        label="Nom"
                        defaultValue={name}
                        {...register('name', { required: true })}
                        width="100%"
                    />
                    <DetailFormValue label="Marque" defaultValue={brand} {...register('brand')} />
                    <DetailFormValue
                        label="Capacité"
                        sublabel="(capacité utilisateurs, affiché aux clients)"
                        defaultValue={volume}
                        {...register('volume')}
                    />
                    <DetailDisplayValue
                        label="Nom complet"
                        sublabel="(calculé)"
                        value={getProductFullName({
                            name: updatedTitleValues[0] || name,
                            brand: updatedTitleValues[1] || brand,
                            volume: updatedTitleValues[2] || volume,
                        })}
                    />
                </SectionColumn>
                <SectionColumn>
                    <DetailFormTextArea
                        label="Description"
                        defaultValue={description}
                        {...register('description', { required: true })}
                        width="100%"
                        height="130px"
                    />
                    <DetailFormValue
                        label="Poids"
                        type="number"
                        step=".01"
                        defaultValue={weight}
                        {...register('weight', { valueAsNumber: true })}
                    />
                    <DetailFormValue
                        label="Hauteur"
                        type="number"
                        step=".01"
                        defaultValue={height}
                        {...register('height', { valueAsNumber: true })}
                    />
                    <DetailFormValue
                        label="Largeur"
                        type="number"
                        step=".01"
                        defaultValue={width}
                        {...register('width', { valueAsNumber: true })}
                    />
                    <DetailFormValue
                        label="Longueur"
                        type="number"
                        step=".01"
                        defaultValue={length ?? 0}
                        {...register('length', { valueAsNumber: true })}
                    />
                </SectionColumn>
            </ColumnsSectionContainer>
        </Container>
    );
};

const Container = styled.div`
    position: relative;
    width: 100%;
    margin-top: 10px;
    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;

    & > :not(:first-child) {
        margin-left: 10px;
    }
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;

    & > :not(:first-child) {
        margin-top: 15px;
    }
`;

import React from 'react';

import { useFormContext } from 'react-hook-form';

import { SectionColumn, SectionContainer, ColumnsSectionContainer } from 'components/DetailsView/Section';
import { DetailFormValue } from 'components/DetailsView/DetailFormValue';
import { DetailFormTextArea } from 'components/DetailsView/DetailFormTextArea';
import { DetailValue } from 'components/DetailsView';
import { CopyValue } from 'components/CopyValue';

import { getExtendedFormattedDatetime } from 'helpers/dateTimes';
import { StatusTag } from '../components/StatusTag';

import { GET_DETAILED_SUPPLIER_detailedSupplier } from 'data/queries/__generated__/GET_DETAILED_SUPPLIER';

export const GeneralInfoSection = ({ supplier }: { supplier?: GET_DETAILED_SUPPLIER_detailedSupplier }) => {
    const { register } = useFormContext();

    return (
        <SectionContainer
            title="Informations générales"
            isInitiallyOpen
            linkToMore={supplier && `/products?supplier=${supplier._id}`}
            linkToMoreLabel="voir les produits"
        >
            <ColumnsSectionContainer numberOfColumns={3}>
                <SectionColumn>
                    {supplier?._id ? <CopyValue value={supplier._id} /> : null}
                    <DetailFormValue
                        label="Nom"
                        {...register('name', { required: true })}
                        width="100%"
                        data-test="supplier-name"
                    />
                    <DetailFormTextArea
                        label="Description"
                        {...register('description', { required: false })}
                        width="100%"
                        height="130px"
                        data-test="supplier-description"
                    />
                </SectionColumn>
                <SectionColumn>
                    <DetailFormValue
                        label="Numéro de téléphone"
                        {...register('phone', { required: false })}
                        width="100%"
                        data-test="supplier-phone"
                    />
                    <DetailFormValue
                        label="Adresse"
                        {...register('address', { required: false })}
                        width="100%"
                        data-test="supplier-address"
                    />
                    <DetailFormValue
                        label="Code postal"
                        {...register('zipcode', { required: false })}
                        width="100%"
                        data-test="supplier-zipcode"
                    />
                    <DetailFormValue
                        label="Site web"
                        {...register('website', { required: false })}
                        width="100%"
                        data-test="supplier-website"
                    />
                </SectionColumn>
                <SectionColumn>
                    {supplier?.createdAt ? (
                        <DetailValue
                            label="Date de création"
                            value={getExtendedFormattedDatetime(supplier.createdAt)}
                        />
                    ) : null}
                    {supplier?.updatedAt ? (
                        <DetailValue
                            label="Dernière mise à jour"
                            value={getExtendedFormattedDatetime(supplier.updatedAt)}
                        />
                    ) : null}
                    {supplier?.state ? <StatusTag state={supplier.state} /> : null}
                </SectionColumn>
            </ColumnsSectionContainer>
        </SectionContainer>
    );
};

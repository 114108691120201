import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { Loader, LoaderModeType } from 'components/Loader';
import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { SelectedOption, TotemSelect } from 'components/TotemSelect';

import { TotemDatePicker } from 'components/TotemDatePicker';
import { CREATE_BUNDLES_MUTATION } from 'data/mutations/bundle';
import { CREATE_BUNDLES, CREATE_BUNDLESVariables } from 'data/mutations/__generated__/CREATE_BUNDLES';
import { DeliveryDestinationType, ProductFlow } from 'data/__generated__';
import { formatDateAsAnniversary } from 'helpers/dateTimes';

const FLOW_OPTIONS = [
    { label: 'sec', value: ProductFlow.Dry },
    {
        label: 'frais',
        value: ProductFlow.Fresh,
    },
    {
        label: 'fruits',
        value: ProductFlow.Fruit,
    },
    {
        label: 'boulangerie',
        value: ProductFlow.Bakery,
    },
];
const DELIVERY_TYPES = [
    {
        label: 'Store',
        value: DeliveryDestinationType.Store,
    },
    {
        label: 'E-Store',
        value: DeliveryDestinationType.Estore,
    },
];

export function CreateBundlesPopup({ isOpen, setIsOpen }: { isOpen: boolean; setIsOpen: (arg: boolean) => void }) {
    const [selectedFlow, setSelectedFlow] = useState<SelectedOption<ProductFlow>>(null);
    const [selectedType, setSelectedType] = useState<SelectedOption<DeliveryDestinationType>>(null);
    const [deliveryDate, setDeliveryDate] = useState<Date | null>(null);

    const [createBundles, { loading }] = useMutation<CREATE_BUNDLES, CREATE_BUNDLESVariables>(CREATE_BUNDLES_MUTATION);

    async function handleSubmit() {
        if (deliveryDate && selectedFlow && selectedType) {
            const { data } = await createBundles({
                variables: {
                    flow: selectedFlow.value,
                    deliveryType: selectedType.value,
                    deliveryDate: formatDateAsAnniversary({ dateTime: deliveryDate }),
                },
            });

            if (!data) {
                throw new Error('Une erreur est survenue lors de la création de la livraison');
            }

            const { createBundles: result } = data;

            if (result.success) {
                toast.success('Bundles créés !');
                setIsOpen(false);
            } else {
                result?.errors?.forEach((error, index) => {
                    toast.error(<span key={index}>Erreur : {error}</span>, { autoClose: false });
                });

                if (result?.errors?.length && result?.errors?.length > 1) {
                    toast.info('Cliquez pour fermer toutes les notifications', {
                        autoClose: false,
                        onClick: () => toast.dismiss(),
                    });
                }
            }
        }
    }

    return (
        <TotemPopup title="Créer des bundles" isOpen={isOpen} setIsOpen={setIsOpen} contentOverflow="visible">
            <SelectorContainer>
                <TotemSelect
                    label="Flux"
                    placeholder="Sélectionner un flux"
                    value={selectedFlow}
                    options={FLOW_OPTIONS}
                    onChange={setSelectedFlow}
                />
            </SelectorContainer>
            {selectedFlow ? (
                <SelectorContainer>
                    <TotemSelect
                        label="Type"
                        placeholder="Sélectionner store / estore"
                        value={selectedType}
                        options={DELIVERY_TYPES}
                        onChange={setSelectedType}
                    />
                </SelectorContainer>
            ) : null}
            {selectedFlow && selectedType ? (
                <SelectorContainer>
                    <TotemDatePicker
                        label="Date de livraison"
                        selected={deliveryDate || new Date()}
                        onChange={(date) => {
                            setDeliveryDate(date as Date);
                        }}
                    />
                </SelectorContainer>
            ) : null}
            <SubmitContainer>
                <TotemPrimaryButton onClick={handleSubmit} disabled={!deliveryDate}>
                    {loading ? <Loader size="20px" mode={LoaderModeType.Spin} /> : 'Créer les bundles'}
                </TotemPrimaryButton>
            </SubmitContainer>
        </TotemPopup>
    );
}

const SelectorContainer = styled.div`
    margin: 10px 0px;
`;

const SubmitContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 15px;
`;

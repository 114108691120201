import React from 'react';

import { CommonStatusTag } from 'components/CommonStatusTag';

import { colors } from 'constants/colors';

import { PaymentIntentState } from 'data/__generated__';
import { PAYMENT_METHOD_STATUS_LABELS } from '../constants/states';

function getColorsFromStatus(status: PaymentIntentState) {
    switch (status) {
        case PaymentIntentState.Canceled:
            return { backgroundColor: colors.darkGrey, labelColor: colors.pureWhite };
        case PaymentIntentState.Captured:
            return { backgroundColor: colors.green, labelColor: colors.black };
        case PaymentIntentState.Failed:
            return { backgroundColor: colors.red, labelColor: colors.pureWhite };
        case PaymentIntentState.Processing:
            return { backgroundColor: colors.orange, labelColor: colors.black };
        case PaymentIntentState.Refunded:
            return { backgroundColor: colors.blue, labelColor: colors.pureWhite };
        case PaymentIntentState.Waiting:
            return { backgroundColor: colors.yellow, labelColor: colors.black };
    }
}

export const PaymentMethodStatusTag = ({ status }: { status: PaymentIntentState }) => {
    const { backgroundColor, labelColor } = getColorsFromStatus(status);
    return (
        <CommonStatusTag
            backgroundColor={backgroundColor}
            labelColor={labelColor}
            label={PAYMENT_METHOD_STATUS_LABELS[status]}
        />
    );
};

import { StockVariationType } from 'data/__generated__';

export const siteVariationsColors: Record<StockVariationType, string> = {
    [StockVariationType.Donated]: '#05fdc3',
    [StockVariationType.KnownLossesBroken]: '#FD6205',
    [StockVariationType.KnownLossesDlc]: '#FD6205',
    [StockVariationType.Transaction]: '#00ED76',
    [StockVariationType.KnownSales]: '#00ED76',
    [StockVariationType.MicrostoreReassort]: '#FF4444',
    [StockVariationType.MicrostoreWarehouseReturn]: '#FD6205',
    [StockVariationType.NotDelivered]: '#e805fd',
    [StockVariationType.NotPrepared]: '#e805fd',
    [StockVariationType.Reappearing]: '#FD6205',
    [StockVariationType.Refund]: '#5C7CFB',
    [StockVariationType.PreparedProductReset]: '#CCCC00',
    [StockVariationType.StockTransfert]: '#ABCABC',
    [StockVariationType.SupplierRefund]: '#5C7CFB',
    [StockVariationType.UnknownLosses]: '#FF11A8',
    [StockVariationType.UnknownLossesSolved]: '#FF11A8',
    [StockVariationType.UnknownSales]: '#990000',
    [StockVariationType.UnknownSalesSolved]: '#990000',
    [StockVariationType.WarehouseAdditionalDeliveryInRack]: '#FF4444',
    [StockVariationType.WarehouseInBox]: '#00AAAA',
    [StockVariationType.WarehouseInRack]: '#FF4444',
    [StockVariationType.WarehouseBundleAborted]: '#CCCC00',
    [StockVariationType.Prepared]: '#0f23d6',
};

export const siteVariationsLabels: Record<StockVariationType, string> = {
    [StockVariationType.Donated]: 'Produit(s) donné(s)',
    [StockVariationType.KnownLossesBroken]: 'Produit(s) cassé(s)',
    [StockVariationType.KnownLossesDlc]: 'DLC dépassé(s)',
    [StockVariationType.Transaction]: 'Transaction',
    [StockVariationType.KnownSales]: 'Vente(s) connue(s)',
    [StockVariationType.MicrostoreReassort]: 'Réassortissement du microstore',
    [StockVariationType.MicrostoreWarehouseReturn]: 'Retour entrepôt',
    [StockVariationType.NotDelivered]: 'Non délivré (erreur de prep)',
    [StockVariationType.NotPrepared]: 'Non préparé (erreur de prep)',
    [StockVariationType.PreparedProductReset]: 'Annulation de produit preparé',
    [StockVariationType.Reappearing]: 'Produit(s) retrouvé(s)',
    [StockVariationType.Refund]: 'Remboursement',
    [StockVariationType.StockTransfert]: 'Transfert de stock',
    [StockVariationType.SupplierRefund]: 'Remboursement fournisseur',
    [StockVariationType.UnknownLosses]: 'Démarque(s) inconnue(s)',
    [StockVariationType.UnknownLossesSolved]: 'Problème de démarques(s) inconnue(s) résolue',
    [StockVariationType.UnknownSalesSolved]: 'Problème de surmarques(s) inconnue(s) résolue',
    [StockVariationType.UnknownSales]: 'Vente(s) inconnues(s)',
    [StockVariationType.WarehouseAdditionalDeliveryInRack]: "Livraison additionnelle d'une carte achat",
    [StockVariationType.WarehouseInBox]: "Placé dans une box prête de l'entrepôt",
    [StockVariationType.WarehouseInRack]: "Rangé dans l'entrêpot",
    [StockVariationType.Prepared]: 'Préparé',
    [StockVariationType.WarehouseBundleAborted]: 'Bundle annulé',
};

export const stockVariationTypeIsNegativeVariation: Record<StockVariationType, boolean> = {
    [StockVariationType.Donated]: true,
    [StockVariationType.KnownLossesBroken]: true,
    [StockVariationType.KnownLossesDlc]: true,
    [StockVariationType.KnownSales]: true,
    [StockVariationType.MicrostoreReassort]: false,
    [StockVariationType.MicrostoreWarehouseReturn]: true,
    [StockVariationType.NotDelivered]: true,
    [StockVariationType.NotPrepared]: false,
    [StockVariationType.PreparedProductReset]: false,
    [StockVariationType.Reappearing]: false,
    [StockVariationType.Refund]: false,
    [StockVariationType.StockTransfert]: false,
    [StockVariationType.SupplierRefund]: true,
    [StockVariationType.Transaction]: true,
    [StockVariationType.UnknownLosses]: true,
    [StockVariationType.UnknownLossesSolved]: false,
    [StockVariationType.UnknownSales]: false,
    [StockVariationType.UnknownSalesSolved]: true,
    [StockVariationType.WarehouseAdditionalDeliveryInRack]: false,
    [StockVariationType.WarehouseBundleAborted]: false,
    [StockVariationType.WarehouseInBox]: true,
    [StockVariationType.WarehouseInRack]: false,
    [StockVariationType.Prepared]: true,
};

export const DEFAULT_PANTIN_WAREHOUSE_SITE_ID = '4b6e4d6c-6050-4856-80a7-262e6e12112c';

import gql from 'graphql-tag';

export const MICROSTORE_COLUMN_INVENTORY_FRAGMENT = gql`
    fragment MicrostoreColumnInventoryFragment on MicrostoreColumnInventory {
        _id
        checkedLocations {
            microstoreLocationId
            productId
            quantityDetails {
                expiryDate
                quantity
            }
        }
        createdAt
        microstoreColumnId
        siteInventoryId
        state
        updatedAt
    }
`;

import gql from 'graphql-tag';
import { WAREHOUSE_RETURN_FRAGMENT } from 'data/fragments/warehouseReturn';

export const PASS_WAREHOUSE_RETURN_TO_IN_PROGRESS_MUTATION = gql`
    mutation PASS_WAREHOUSE_RETURN_TO_IN_PROGRESS($warehouseReturnId: ID!) {
        passWarehouseReturnToInProgress(warehouseReturnId: $warehouseReturnId) {
            ...WarehouseReturnFragment
        }
    }
    ${WAREHOUSE_RETURN_FRAGMENT}
`;

export const PASS_WAREHOUSE_RETURN_TO_DELIVERED_MUTATION = gql`
    mutation PASS_WAREHOUSE_RETURN_TO_DELIVERED($warehouseReturnId: ID!) {
        passWarehouseReturnToDelivered(warehouseReturnId: $warehouseReturnId) {
            ...WarehouseReturnFragment
        }
    }
    ${WAREHOUSE_RETURN_FRAGMENT}
`;

export const PASS_WAREHOUSE_RETURN_TO_STORED_MUTATION = gql`
    mutation PASS_WAREHOUSE_RETURN_TO_STORED($warehouseReturnId: ID!) {
        passWarehouseReturnToStored(warehouseReturnId: $warehouseReturnId) {
            ...WarehouseReturnFragment
        }
    }
    ${WAREHOUSE_RETURN_FRAGMENT}
`;

import { gql } from '@apollo/client';
import { EQUIPMENT_FRAGMENT } from 'data/fragments/equipment';

export const UPDATE_EQUIPMENT_MUTATION = gql`
    mutation UPDATE_EQUIPMENT($equipmentId: ID!, $updateFields: EquipmentUpdateFields!) {
        updateEquipment(equipmentId: $equipmentId, updateFields: $updateFields) {
            equipment {
                ...Equipment
            }
            error
            success
        }
    }
    ${EQUIPMENT_FRAGMENT}
`;

export const UPDATE_EQUIPMENT_STATE_MUTATION = gql`
    mutation UPDATE_EQUIPMENT_STATE($equipmentId: ID!, $stateToSet: EquipmentState!) {
        updateEquipmentState(equipmentId: $equipmentId, stateToSet: $stateToSet) {
            success
            error
            equipment {
                ...Equipment
            }
        }
    }
    ${EQUIPMENT_FRAGMENT}
`;

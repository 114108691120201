import React from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { PAGES } from '../../constants/pages';
import { TransactionState, useGetSitesQuery } from 'data/__generated__';

import { Header, HeaderTitle } from 'components/Header';
import { PageTitle } from 'components/PageTitle';
import { TotemInput } from 'components/TotemInput';
import { Option, TotemSelect } from 'components/TotemSelect';
import { TransactionsList } from './components/TransactionsList';
import { StatusTag } from './components/StatusTag';
import { PAYMENT_PROVIDERS, ProviderTag } from './components/ProviderTag';

export const Transactions = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const userId = searchParams.get('userId');
    const filterString = searchParams.get('filterString') || '';
    const siteFilter = searchParams.get('siteFilter') || '';
    const selectedStates = searchParams.getAll('state') as TransactionState[];
    const selectedProviders = searchParams.getAll('provider');

    const { loading: sitesLoading, error: sitesError, data: sitesData } = useGetSitesQuery();

    function updateSearchParams(value: string) {
        // clear userId on search
        searchParams.delete('userId');
        if (value) {
            searchParams.set('filterString', value);
        } else {
            searchParams.delete('filterString');
        }
        setSearchParams(searchParams);
    }

    const siteOptions = sitesData?.sites
        .map((site) => ({ value: site._id, label: site.name }))
        .sort((siteA, siteB) => siteA.label.toLowerCase().localeCompare(siteB.label.toLowerCase()));

    const siteOptionSelected =
        siteOptions && siteFilter?.length ? siteOptions.find(({ value }) => value === siteFilter) : null;

    function updateSiteFilter(site: Option<string> | null | undefined) {
        if (site) {
            searchParams.set('siteFilter', site.value);
        } else {
            searchParams.delete('siteFilter');
        }
        setSearchParams(searchParams);
    }

    function toggleStateSelection(state: TransactionState) {
        return () => {
            if (selectedStates.includes(state)) {
                // @ts-ignore TS2554 (your editor won't show an error, but react-scripts will)
                searchParams.delete('state', state);
            } else {
                searchParams.append('state', state);
            }
            setSearchParams(searchParams);
        };
    }

    function toggleProviderSelection(provider: string) {
        return () => {
            if (selectedProviders.includes(provider)) {
                // @ts-ignore TS2554 (your editor won't show an error, but react-scripts will)
                searchParams.delete('provider', provider);
            } else {
                searchParams.append('provider', provider);
            }
            setSearchParams(searchParams);
        };
    }

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.transactions} />
                </HeaderTitle>
            </Header>
            <Content>
                <TotemInput
                    label="Recherche"
                    onChange={updateSearchParams}
                    placeholder="Nom de l'utilisateur"
                    value={filterString}
                />
                <Filters>
                    <Title>Filtres</Title>
                    {!sitesLoading && !sitesError ? (
                        <SelectContainer>
                            <TotemSelect
                                isClearable
                                placeholder="site"
                                value={siteOptionSelected}
                                options={siteOptions}
                                onChange={updateSiteFilter}
                            />
                        </SelectContainer>
                    ) : null}
                    {PAYMENT_PROVIDERS.map((provider) => (
                        <FilterContainer
                            key={provider}
                            isSelected={selectedProviders.includes(provider)}
                            onClick={toggleProviderSelection(provider)}
                        >
                            <ProviderTag provider={provider} />
                        </FilterContainer>
                    ))}
                </Filters>
                <Filters>
                    <Title>Filtres</Title>
                    {(Object.keys(TransactionState) as TransactionState[]).map((state) => (
                        <FilterContainer
                            key={state}
                            isSelected={selectedStates.includes(state)}
                            onClick={toggleStateSelection(state)}
                        >
                            <StatusTag state={state} />
                        </FilterContainer>
                    ))}
                </Filters>
                <ListContainer>
                    <TransactionsList
                        filterString={filterString}
                        stateFilter={selectedStates}
                        providerFilter={selectedProviders}
                        siteFilter={siteFilter ? [siteFilter] : []}
                        userId={userId}
                    />
                </ListContainer>
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    flex: 1;
    overflow: hidden;
`;

const SelectContainer = styled.div`
    width: 250px;
`;

const ListContainer = styled.div`
    flex: 1;
    overflow: hidden;
`;

const Filters = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const Title = styled.h3`
    margin: 0;
    font-size: 20px;
    font-weight: 800;
`;

const FilterContainer = styled.div<{ isSelected: boolean }>`
    cursor: pointer;
    border-radius: 20px;
    border: 2px solid ${({ isSelected, theme }) => (isSelected ? theme.darkBorderColor : 'transparent')};
`;

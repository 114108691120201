import {
    SetupInstructionFragment,
    SetupInstructionFragment_CheckTemperatureInstruction,
    SetupInstructionFragment_CheckBoxTemperatureInstruction,
    SetupInstructionFragment_InventoryInstruction,
    SetupInstructionFragment_MoveProductsInstruction,
    SetupInstructionFragment_PhotosInstruction,
    SetupInstructionFragment_RemoveProductsInstruction,
} from '../../../data/fragments/__generated__/SetupInstructionFragment';
import { SetupInstructionFragmentFragment, SetupInstructionType } from '../../../data/__generated__';

export function isCheckTemperatureInstruction(
    instruction: SetupInstructionFragment,
): instruction is SetupInstructionFragment_CheckTemperatureInstruction {
    return instruction.type === SetupInstructionType.CheckTemperature;
}

export function isCheckBoxTemperatureInstruction(
    instruction: SetupInstructionFragment,
): instruction is SetupInstructionFragment_CheckBoxTemperatureInstruction {
    return instruction.type === SetupInstructionType.CheckBoxTemperature;
}

export function isInventoryInstruction(
    instruction: SetupInstructionFragment,
): instruction is SetupInstructionFragment_InventoryInstruction {
    return (
        instruction.type === SetupInstructionType.InventoryComplete ||
        instruction.type === SetupInstructionType.InventoryCutlery ||
        instruction.type === SetupInstructionType.InventoryDry ||
        instruction.type === SetupInstructionType.InventorySmallExpiryDate ||
        instruction.type === SetupInstructionType.InventoryZero
    );
}

export function isMoveProductsInstruction(
    instruction: SetupInstructionFragment,
): instruction is SetupInstructionFragment_MoveProductsInstruction {
    return instruction.type === SetupInstructionType.MoveProducts;
}

export function isPhotosInstruction(
    instruction: SetupInstructionFragmentFragment,
): instruction is SetupInstructionFragment_PhotosInstruction {
    return instruction.type === SetupInstructionType.Photos;
}

export function isRemoveProductsInstruction(
    instruction: SetupInstructionFragment,
): instruction is SetupInstructionFragment_RemoveProductsInstruction {
    return instruction.type === SetupInstructionType.RemoveProducts;
}

export function isStoreProductsInstruction(
    instruction: SetupInstructionFragment,
): instruction is SetupInstructionFragment_RemoveProductsInstruction {
    return instruction.type === SetupInstructionType.StoreProducts;
}

import React from 'react';
import styled from 'styled-components';

import { colors } from 'constants/colors';

import { MicrostoreColumnSetupFragment } from 'data/fragments/__generated__/MicrostoreColumnSetupFragment';
import { MicrostoreColumnWithShelvesFragment } from 'data/fragments/__generated__/MicrostoreColumnWithShelvesFragment';
import { MicrostoreColumnInventoryFragment } from 'data/fragments/__generated__/MicrostoreColumnInventoryFragment';
import { GET_MICROSTORE_PRODUCTS_getMicrostoreProducts } from 'data/queries/__generated__/GET_MICROSTORE_PRODUCTS';

import { ColumnInventorySection } from './inventory/ColumnInventorySection';
import { ColumnTemperatureSection } from './temperature/ColumnTemperatureSection';
import { ColumnRemoveProductsSection } from './removeProducts/ColumnRemoveProductsSection';
import { ColumnPhotosSection } from './photos/ColumnPhotosSection';
import { SetupInstructionType } from 'data/__generated__';
import {
    isCheckBoxTemperatureInstruction,
    isCheckTemperatureInstruction,
    isPhotosInstruction,
    isRemoveProductsInstruction,
} from 'pages/SiteSetups/utils/typePredicates';
import { GET_MICROSTORE_SITE_siteWithLocationInfo } from 'data/queries/__generated__/GET_MICROSTORE_SITE';

export const ColumnDetails = ({
    inventoryDate,
    products,
    selectedColumn,
    selectedColumnInventory,
    selectedColumnSetup,
    site,
}: {
    inventoryDate: string;
    products: GET_MICROSTORE_PRODUCTS_getMicrostoreProducts[];
    selectedColumn: MicrostoreColumnWithShelvesFragment;
    selectedColumnInventory: MicrostoreColumnInventoryFragment;
    selectedColumnSetup: MicrostoreColumnSetupFragment;
    site: GET_MICROSTORE_SITE_siteWithLocationInfo;
}) => {
    const temperatureInstruction = selectedColumnSetup.instructions.find(
        ({ type }) => type === SetupInstructionType.CheckTemperature,
    );
    const boxTemperatureInstruction = selectedColumnSetup.instructions.find(
        ({ type }) => type === SetupInstructionType.CheckBoxTemperature,
    );

    const removedProductsIntruction = selectedColumnSetup.instructions.find(
        ({ type }) => type === SetupInstructionType.RemoveProducts,
    );
    const photosInstruction = selectedColumnSetup.instructions.find(({ type }) => type === SetupInstructionType.Photos);

    return (
        <Container>
            {boxTemperatureInstruction && isCheckBoxTemperatureInstruction(boxTemperatureInstruction) ? (
                <ColumnTemperatureSection instruction={boxTemperatureInstruction} />
            ) : null}
            {temperatureInstruction && isCheckTemperatureInstruction(temperatureInstruction) ? (
                <ColumnTemperatureSection instruction={temperatureInstruction} />
            ) : null}
            {removedProductsIntruction && isRemoveProductsInstruction(removedProductsIntruction) ? (
                <ColumnRemoveProductsSection
                    instruction={removedProductsIntruction}
                    inventoryDate={inventoryDate}
                    products={products}
                />
            ) : null}
            <ColumnInventorySection
                column={selectedColumn}
                columnSetup={selectedColumnSetup}
                inventoryDate={inventoryDate}
                products={products}
                columnInventory={selectedColumnInventory}
                site={site}
            />
            {photosInstruction && isPhotosInstruction(photosInstruction) ? (
                <ColumnPhotosSection instruction={photosInstruction} />
            ) : null}
        </Container>
    );
};

const Container = styled.div`
    border: 1px solid ${colors.pureBlack};
    border-top: 0px;
    border-radius: 0 0 10px 10px;
    padding: 10px;

    & > :not(:first-child) {
        margin-top: 20px;
        border-top: 1px solid ${colors.borderGrey};
    }
`;

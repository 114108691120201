import type { LazyQueryResult } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import styled, { css } from 'styled-components';
import { FaEdit } from 'react-icons/fa';
import { AiOutlineInfoCircle } from 'react-icons/ai';

import { colors } from 'constants/colors';
import { GET_PRODUCT_GROUPS_CONTAINING_PRODUCTS_QUERY } from 'data/queries/productGroup';
import {
    GET_PRODUCT_GROUPS_CONTAINING_PRODUCTS,
    GET_PRODUCT_GROUPS_CONTAINING_PRODUCTSVariables,
} from 'data/queries/__generated__/GET_PRODUCT_GROUPS_CONTAINING_PRODUCTS';
import {
    GET_SUPPLY_ORDER_SUPPLIER_PRODUCTS_STOCK_VARIATION,
    GET_SUPPLY_ORDER_SUPPLIER_PRODUCTS_STOCK_VARIATIONVariables,
} from 'data/queries/__generated__/GET_SUPPLY_ORDER_SUPPLIER_PRODUCTS_STOCK_VARIATION';
import { SupplyOrderState } from 'data/__generated__';
import { GET_SUPPLY_ORDERS_supplyOrders_supplier_orderingTimes } from 'data/queries/__generated__/GET_SUPPLY_ORDERS';
import { SupplyOrderWithSupplierProductsInfoFragment_products_confirmedQuantityHistory } from 'data/fragments/__generated__/SupplyOrderWithSupplierProductsInfoFragment';
import { GET_PRODUCTS_TO_ORDER_ONCE_FOR_SUPPLY_ORDER_productsToOrderOnceForSupplyOrder } from 'data/queries/__generated__/GET_PRODUCTS_TO_ORDER_ONCE_FOR_SUPPLY_ORDER';

import { BasicInput } from 'components/BasicInput';
import { Loader, LoaderModeType } from 'components/Loader';
import { ProductsInfoTableFooter } from 'pages/SupplyOrders/SupplyOrderDetails/ProductsInfoTableFooter';
import { SupplyOrderProductInfoReceivedDetailsPopup } from 'pages/SupplyOrders/SupplyOrderDetails/Popups/SupplyOrderProductInfoReceivedDetailsPopup';
import { ProductsInfoStockVariation } from './ProductsInfoStockVariation';
import { ConfirmedQuantityHistoryPopup } from 'pages/SupplyOrders/SupplyOrderDetails/Popups/ConfirmedQuantityHistoryPopup';

import { ProductWithInfoType, SupplyOrderDetailsDisplayValues } from './supplyOrderDetailsFormHelper';
import { getSupplyOrderProductReceivedTotal } from '../helper/getSupplyOrderProductReceivedTotal';
import { formatDateAsAnniversary } from 'helpers/dateTimes';

export const ProductsInfoTable = ({
    supplyOrderDetailsDisplayValues,
    productsStockVariationQueryState,
    orderingTimes,
    productsToOrderOnce,
}: {
    supplyOrderDetailsDisplayValues: SupplyOrderDetailsDisplayValues;
    productsStockVariationQueryState: LazyQueryResult<
        GET_SUPPLY_ORDER_SUPPLIER_PRODUCTS_STOCK_VARIATION,
        GET_SUPPLY_ORDER_SUPPLIER_PRODUCTS_STOCK_VARIATIONVariables
    >;
    orderingTimes: GET_SUPPLY_ORDERS_supplyOrders_supplier_orderingTimes;
    productsToOrderOnce: GET_PRODUCTS_TO_ORDER_ONCE_FOR_SUPPLY_ORDER_productsToOrderOnceForSupplyOrder[];
}) => {
    const [selectedProductIndex, setSelectedProductIndex] = useState<number | null>(null);
    const { productsWithInfo, state, supplier } = supplyOrderDetailsDisplayValues;

    const { data: productGroupsData } = useQuery<
        GET_PRODUCT_GROUPS_CONTAINING_PRODUCTS,
        GET_PRODUCT_GROUPS_CONTAINING_PRODUCTSVariables
    >(GET_PRODUCT_GROUPS_CONTAINING_PRODUCTS_QUERY, {
        variables: { productIds: productsWithInfo.map(({ _id }) => _id) },
        onCompleted: () => null,
    });

    const [quantityHistoryValuesToDisplay, setQuantityHistoryValuesToDisplay] = useState<
        SupplyOrderWithSupplierProductsInfoFragment_products_confirmedQuantityHistory[] | null
    >(null);

    const {
        loading: productsStockVariationLoading,
        data: productsStockVariationData,
        error: productsStockVariationError,
    } = productsStockVariationQueryState;

    const supplyOrderStockVariations =
        productsStockVariationData?.supplyOrderSupplierProductsStockVariation?.supplyOrderStockVariations;

    const productGroupStockVariations =
        productsStockVariationData?.supplyOrderSupplierProductsStockVariation?.productGroupStockVariations;

    const { control } = useFormContext();

    // Multiple conditions were commented because Coco wants to modify the quantity at each state.
    // We need to check with her why and create an other tool for her needs.
    // We prefer to have cleaner data with more conditions

    const isSupplyOrderReceived = [SupplyOrderState.Received, SupplyOrderState.InRack].includes(state);

    return (
        <>
            <SupplierProductInfoTableContainer>
                <SupplierProductInfoTable>
                    <thead>
                        <SupplierProductInfoHeaderRow>
                            <SupplierProductInfoHeaderCell>Nom du produit</SupplierProductInfoHeaderCell>
                            <SupplierProductInfoHeaderCellSmall>Stock</SupplierProductInfoHeaderCellSmall>
                            <SupplierProductInfoHeaderCellSmall>Stock Dispo</SupplierProductInfoHeaderCellSmall>
                            <SupplierProductInfoHeaderCell>Variation Stock</SupplierProductInfoHeaderCell>
                            <SupplierProductInfoHeaderCellSmall>Colisage</SupplierProductInfoHeaderCellSmall>
                            <SupplierProductInfoHeaderCellSmall>Vendu par</SupplierProductInfoHeaderCellSmall>
                            <SupplierProductInfoHeaderCellSmall>Nb. Colis demandés</SupplierProductInfoHeaderCellSmall>
                            <SupplierProductInfoHeaderCellSmall>Quantité demandée</SupplierProductInfoHeaderCellSmall>
                            <SupplierProductInfoHeaderCellSmall>
                                Quantité confirmée par fournisseur
                            </SupplierProductInfoHeaderCellSmall>
                            <SupplierProductInfoHeaderCellSmall>
                                Quantité pour prédictions
                            </SupplierProductInfoHeaderCellSmall>
                            <SupplierProductInfoHeaderCellSmall>
                                Quantité pour les menus
                            </SupplierProductInfoHeaderCellSmall>
                            {isSupplyOrderReceived ? (
                                <SupplierProductInfoHeaderCellSmall>Colisage reçu</SupplierProductInfoHeaderCellSmall>
                            ) : null}
                            {isSupplyOrderReceived ? (
                                <SupplierProductInfoHeaderCellSmall>Nb. Colis reçus</SupplierProductInfoHeaderCellSmall>
                            ) : null}
                            {isSupplyOrderReceived ? (
                                <SupplierProductInfoHeaderCellSmall>Quantité reçue</SupplierProductInfoHeaderCellSmall>
                            ) : null}
                            {isSupplyOrderReceived ? (
                                <SupplierProductInfoHeaderCellSmall>
                                    Quantité reçue cassée
                                </SupplierProductInfoHeaderCellSmall>
                            ) : null}
                            <SupplierProductInfoHeaderCellSmall>Prix unitaire HT</SupplierProductInfoHeaderCellSmall>
                            <SupplierProductInfoHeaderCellSmall>
                                Prix fournisseur unitaire HT
                            </SupplierProductInfoHeaderCellSmall>
                            <SupplierProductInfoHeaderCellSmall>Total HT à recevoir</SupplierProductInfoHeaderCellSmall>
                            {isSupplyOrderReceived ? (
                                <SupplierProductInfoHeaderCellSmall>Total HT Reçu</SupplierProductInfoHeaderCellSmall>
                            ) : null}
                        </SupplierProductInfoHeaderRow>
                    </thead>
                    <tbody>
                        {productsWithInfo.map((productInfo, index) => {
                            return (
                                <Controller
                                    key={productInfo._id}
                                    control={control}
                                    name={`productsWithInfo.${index}`}
                                    defaultValue={productInfo}
                                    render={({
                                        field: { onChange, value },
                                    }: {
                                        field: {
                                            value: ProductWithInfoType;
                                            onChange: (value: ProductWithInfoType) => void;
                                        };
                                    }) => {
                                        const {
                                            _id: productId,
                                            brand,
                                            conditionning,
                                            confirmedQuantity,
                                            confirmedQuantityHistory,
                                            colis,
                                            packagesPerBatch,
                                            supplierShortageInfo,
                                            isAtSoldoutRisk,
                                            isIncorrectSupplier,
                                            isToBeArchived,
                                            name,
                                            price,
                                            productReceivedDetails,
                                            quantity,
                                            quantityGenerated,
                                            quantityGeneratedForMenus,
                                            stock,
                                            availableStock,
                                            unitPrice,
                                            unitPriceSupplier,
                                            volume,
                                        } = value;

                                        const {
                                            brokenItemsReceived,
                                            colisReceived,
                                            conditionningReceived,
                                            quantityReceived,
                                        } = getSupplyOrderProductReceivedTotal(productReceivedDetails);

                                        const isQuantityLessThanRequested =
                                            quantity < quantityGeneratedForMenus + quantityGenerated;

                                        const productStockVariation = supplyOrderStockVariations?.find(
                                            ({ productId: _id }) => _id === productId,
                                        );

                                        const confirmedQuantityMatchesPackaging =
                                            confirmedQuantity === null ||
                                            Number.isInteger(Number(confirmedQuantity) / conditionning);

                                        const confirmedQuantityMatchesPackagesPerBatch =
                                            confirmedQuantity === null ||
                                            Number.isInteger(
                                                Number(confirmedQuantity) / (conditionning * packagesPerBatch),
                                            );

                                        const isRequestedBySupplyBU = productsToOrderOnce.find(
                                            ({ _id }) => _id === productId,
                                        );

                                        const orderingProductTerm = supplier.orderingProductTerms.find(
                                            (orderingProductTerm) => orderingProductTerm.productId === productId,
                                        );

                                        return (
                                            <>
                                                <SupplierProductInfoRow
                                                    highlighted={!!colis}
                                                    isAtSoldoutRisk={isAtSoldoutRisk}
                                                >
                                                    <SupplierProductInfoCell isBold={true}>
                                                        <ProductNameLink to={`/product/${productId}`}>
                                                            {`${name} - ${brand} - ${volume}${
                                                                isIncorrectSupplier
                                                                    ? " - Ce produit n'aurait pas du être commandé avec ce fournisseur"
                                                                    : ''
                                                            }`}
                                                        </ProductNameLink>
                                                        <Warning>
                                                            {isToBeArchived &&
                                                                "⚠️ Ce produit est marqué comme 'À sortir' et ne devrait plus être commandé"}
                                                        </Warning>
                                                        <Warning>
                                                            {supplierShortageInfo.inShortage &&
                                                                `⚠️ Ce produit est marqué comme 'En rupture' ${
                                                                    supplierShortageInfo.returnDate
                                                                        ? `jusqu'à ${formatDateAsAnniversary({
                                                                              dateTime: supplierShortageInfo.returnDate,
                                                                          })} `
                                                                        : ''
                                                                }et ne devrait pas être commandé`}
                                                        </Warning>
                                                        <Warning>
                                                            {isQuantityLessThanRequested &&
                                                                `❗️Quantité commandée ne sera pas suffisante pour les demandes (${quantityGeneratedForMenus}) menus calculée par data et prédictions (${quantityGenerated})`}
                                                        </Warning>
                                                        <Warning>
                                                            {!confirmedQuantityMatchesPackaging &&
                                                                `❗️Quantité confirmée par fournisseur n'a pas le bon colisage (${conditionning}), ce qui fait ${
                                                                    Math.round(
                                                                        (Number(confirmedQuantity) / conditionning) *
                                                                            100,
                                                                    ) / 100
                                                                } colis`}
                                                        </Warning>
                                                        <Warning>
                                                            {confirmedQuantityMatchesPackaging &&
                                                                !confirmedQuantityMatchesPackagesPerBatch &&
                                                                `❗️Quantité confirmée par fournisseur n'a pas le bon 'vendu par' colisage (${
                                                                    conditionning * packagesPerBatch
                                                                }), ce qui fait ${
                                                                    Math.round(
                                                                        (Number(confirmedQuantity) /
                                                                            (packagesPerBatch * conditionning)) *
                                                                            100,
                                                                    ) / 100
                                                                } colisage`}
                                                        </Warning>
                                                        <Warning>
                                                            {colis % packagesPerBatch
                                                                ? `❗️Le nombre de colis renseigné ne peut pas être commandé car vendu par ${packagesPerBatch} (${colis} / ${packagesPerBatch} = ${
                                                                      colis / packagesPerBatch
                                                                  }).`
                                                                : null}
                                                        </Warning>
                                                        <Warning color={colors.blue}>
                                                            {isRequestedBySupplyBU
                                                                ? 'Ce produit a été demandé par Supply et cet achat devrait avoir au moins une unité commandée'
                                                                : null}
                                                        </Warning>
                                                    </SupplierProductInfoCell>
                                                    <SupplierProductInfoCellSmall>{stock}</SupplierProductInfoCellSmall>
                                                    <SupplierProductInfoCellSmall>
                                                        {availableStock}
                                                    </SupplierProductInfoCellSmall>
                                                    <SupplierProductInfoCell>
                                                        {productsStockVariationLoading ? (
                                                            <Loader size="22px" mode={LoaderModeType.Spin} />
                                                        ) : null}
                                                        {!productsStockVariationLoading &&
                                                            (productsStockVariationError ||
                                                            !productsStockVariationData ||
                                                            !productStockVariation ? (
                                                                <div>
                                                                    Erreur lors du chargement de la variation de stock
                                                                </div>
                                                            ) : (
                                                                <ProductsInfoStockVariation
                                                                    stock={stock}
                                                                    productStockVariation={productStockVariation}
                                                                    isComputed={productStockVariation.isComputed}
                                                                    orderingTimes={orderingTimes}
                                                                    productInfo={productInfo}
                                                                    shouldDisplayTooltip={index < 3}
                                                                    startDate={
                                                                        supplyOrderDetailsDisplayValues.orderDate
                                                                    }
                                                                />
                                                            ))}
                                                    </SupplierProductInfoCell>
                                                    <SupplierProductInfoCellSmall>
                                                        {conditionning}
                                                    </SupplierProductInfoCellSmall>
                                                    <SupplierProductInfoCellSmall>
                                                        {packagesPerBatch} colis
                                                    </SupplierProductInfoCellSmall>
                                                    <SupplierProductInfoCellSmallForInput>
                                                        <SupplierProductInfoInput
                                                            placeholder="0"
                                                            value={colis}
                                                            onChange={(e) => {
                                                                const newColis = parseInt(e.target.value) ?? 0;
                                                                const newQuantity =
                                                                    conditionning && newColis
                                                                        ? conditionning * newColis
                                                                        : 0;
                                                                onChange({
                                                                    ...value,
                                                                    colis: newColis,
                                                                    quantity: newQuantity,
                                                                    price:
                                                                        (confirmedQuantity ?? newQuantity) *
                                                                        (unitPrice ?? unitPriceSupplier),
                                                                });
                                                            }}
                                                            centerText
                                                            width="100%"
                                                            type="number"
                                                            step="1"
                                                            min="0"
                                                        />
                                                    </SupplierProductInfoCellSmallForInput>
                                                    <SupplierProductInfoCellSmall isBold={true}>
                                                        {quantity}
                                                        {orderingProductTerm ? (
                                                            <Warning
                                                                color={
                                                                    quantity &&
                                                                    orderingProductTerm.minQuantity &&
                                                                    orderingProductTerm.minQuantity > quantity
                                                                        ? colors.red
                                                                        : colors.green
                                                                }
                                                            >
                                                                Min {orderingProductTerm.minQuantity}
                                                            </Warning>
                                                        ) : null}
                                                    </SupplierProductInfoCellSmall>
                                                    <SupplierProductInfoCellSmallForInput>
                                                        <InputWithIconContainer>
                                                            {confirmedQuantityHistory.length ? (
                                                                <InfoIcon
                                                                    size={20}
                                                                    onClick={() =>
                                                                        setQuantityHistoryValuesToDisplay(
                                                                            confirmedQuantityHistory,
                                                                        )
                                                                    }
                                                                />
                                                            ) : null}
                                                            <SupplierProductInfoInput
                                                                value={confirmedQuantity ?? ''}
                                                                onChange={(e) => {
                                                                    const newConfirmedQuantity =
                                                                        e.target.value === ''
                                                                            ? null
                                                                            : parseInt(e.target.value);
                                                                    onChange({
                                                                        ...value,
                                                                        confirmedQuantity: newConfirmedQuantity,
                                                                        price:
                                                                            (newConfirmedQuantity ?? quantity) *
                                                                            (unitPrice ?? unitPriceSupplier),
                                                                    });
                                                                }}
                                                                centerText
                                                                width="100%"
                                                                type="number"
                                                                step="1"
                                                            />
                                                        </InputWithIconContainer>
                                                    </SupplierProductInfoCellSmallForInput>
                                                    <SupplierProductInfoCellSmall>
                                                        {quantityGenerated}
                                                    </SupplierProductInfoCellSmall>
                                                    <SupplierProductInfoCellSmall>
                                                        {quantityGeneratedForMenus}
                                                    </SupplierProductInfoCellSmall>
                                                    {isSupplyOrderReceived ? (
                                                        <>
                                                            <SupplierProductInfoCellSmallForInput>
                                                                {conditionningReceived ?? 'N/A'}
                                                            </SupplierProductInfoCellSmallForInput>

                                                            <SupplierProductInfoCellSmallForInput>
                                                                {colisReceived !== null
                                                                    ? `${colisReceived} (+${
                                                                          colisReceived
                                                                              ? (quantityReceived ?? 0) % colisReceived
                                                                              : 0
                                                                      }p)`
                                                                    : 'N/A'}
                                                            </SupplierProductInfoCellSmallForInput>

                                                            <SupplierProductInfoCellSmallForInput>
                                                                {quantityReceived ?? 'N/A'}
                                                                <EditIcon
                                                                    size="13"
                                                                    onClick={() => setSelectedProductIndex(index)}
                                                                />
                                                            </SupplierProductInfoCellSmallForInput>

                                                            <SupplierProductInfoCellSmallForInput>
                                                                {brokenItemsReceived ?? 'N/A'}
                                                            </SupplierProductInfoCellSmallForInput>
                                                        </>
                                                    ) : null}
                                                    <SupplierProductInfoCellSmallForInput>
                                                        <SupplierProductInfoInput
                                                            placeholder="0"
                                                            value={unitPrice ?? ''}
                                                            onChange={(e) => {
                                                                const newUnitPrice = parseFloat(e.target.value) || null;
                                                                onChange({
                                                                    ...value,
                                                                    unitPrice: newUnitPrice,
                                                                    price:
                                                                        quantity * (newUnitPrice ?? unitPriceSupplier),
                                                                });
                                                            }}
                                                            centerText
                                                            width="100%"
                                                            type="number"
                                                            step="0.000000000000001"
                                                            min="0"
                                                            required={!!colis}
                                                        />
                                                    </SupplierProductInfoCellSmallForInput>
                                                    <SupplierProductInfoCellSmall>
                                                        {unitPriceSupplier}
                                                    </SupplierProductInfoCellSmall>
                                                    <SupplierProductInfoCellSmall>
                                                        {price?.toFixed(2)}
                                                    </SupplierProductInfoCellSmall>
                                                    {isSupplyOrderReceived ? (
                                                        <SupplierProductInfoCellSmall>
                                                            {quantityReceived
                                                                ? (
                                                                      quantityReceived *
                                                                      (unitPrice ?? unitPriceSupplier)
                                                                  ).toFixed(2)
                                                                : null}
                                                        </SupplierProductInfoCellSmall>
                                                    ) : null}
                                                </SupplierProductInfoRow>
                                            </>
                                        );
                                    }}
                                />
                            );
                        })}
                    </tbody>
                    <ProductsInfoTableFooter
                        supplyOrderDetailsDisplayValues={supplyOrderDetailsDisplayValues}
                        isSupplyOrderReceived={isSupplyOrderReceived}
                    />
                </SupplierProductInfoTable>
            </SupplierProductInfoTableContainer>
            <Title>Regroupements de produit lié à ces produits</Title>
            {productGroupsData?.getProductGroupsContainingProducts ? (
                <SupplierProductInfoTableContainer>
                    <SupplierProductInfoTable>
                        <thead>
                            <SupplierProductInfoHeaderRow>
                                <SupplierProductInfoHeaderCell>Nom du produit</SupplierProductInfoHeaderCell>
                                <SupplierProductInfoHeaderCell>Variation Stock</SupplierProductInfoHeaderCell>
                                <SupplierProductInfoHeaderCell>Proportion pour cet achat</SupplierProductInfoHeaderCell>
                            </SupplierProductInfoHeaderRow>
                        </thead>
                        <tbody>
                            {productGroupsData?.getProductGroupsContainingProducts?.map((productGroup, index) => {
                                const {
                                    _id: productId,
                                    brand,
                                    isToBeArchived,
                                    name,
                                    supplierShortageInfo,
                                    stock,
                                    volume,
                                } = productGroup.product;

                                const productStockVariation = productGroupStockVariations?.find(
                                    ({ productId: _id }) => _id === productId,
                                );

                                return (
                                    <>
                                        <SupplierProductInfoRow>
                                            <SupplierProductInfoCell isBold={true}>
                                                <ProductNameLink to={`/product/${productId}`}>
                                                    {`${name} - ${brand} - ${volume}`}
                                                </ProductNameLink>
                                                <Warning>
                                                    {isToBeArchived &&
                                                        "⚠️ Ce produit est marqué comme 'À sortir' et ne devrait plus être commandé"}
                                                </Warning>
                                                <Warning>
                                                    {supplierShortageInfo.inShortage &&
                                                        `⚠️ Ce produit est marqué comme 'En rupture' ${
                                                            supplierShortageInfo.returnDate
                                                                ? `jusqu'à ${formatDateAsAnniversary({
                                                                      dateTime: supplierShortageInfo.returnDate,
                                                                  })} `
                                                                : ''
                                                        }et ne devrait pas être commandé`}
                                                </Warning>
                                            </SupplierProductInfoCell>
                                            <SupplierProductInfoCell>
                                                {productsStockVariationLoading ? (
                                                    <Loader size="22px" mode={LoaderModeType.Spin} />
                                                ) : null}
                                                {!productsStockVariationLoading &&
                                                    (productsStockVariationError ||
                                                    !productsStockVariationData ||
                                                    !productStockVariation ? (
                                                        <div>Erreur lors du chargement de la variation de stock</div>
                                                    ) : (
                                                        <ProductsInfoStockVariation
                                                            isComputed={productStockVariation.isComputed}
                                                            stock={stock}
                                                            productStockVariation={productStockVariation}
                                                            orderingTimes={orderingTimes}
                                                            productInfo={productGroup.product}
                                                            shouldDisplayTooltip={index < 3}
                                                            startDate={supplyOrderDetailsDisplayValues.orderDate}
                                                        />
                                                    ))}
                                            </SupplierProductInfoCell>
                                            <SupplierProductInfoCell>
                                                {productGroup.composition.map((compositionEntry) => {
                                                    const productInfo = productsWithInfo.find(
                                                        ({ _id }) => _id === compositionEntry.productId,
                                                    );
                                                    if (!productInfo) {
                                                        return null;
                                                    }

                                                    const { brand, name, volume } = productInfo;

                                                    return (
                                                        <ProductNameLink
                                                            to={`/product/${compositionEntry.productId}`}
                                                            key={compositionEntry.productId}
                                                        >
                                                            {`${name} - ${brand} - ${volume}, quantité : ${compositionEntry.quantity}`}
                                                            <br />
                                                        </ProductNameLink>
                                                    );
                                                })}
                                            </SupplierProductInfoCell>
                                        </SupplierProductInfoRow>
                                    </>
                                );
                            })}
                        </tbody>
                    </SupplierProductInfoTable>
                </SupplierProductInfoTableContainer>
            ) : null}
            <SupplyOrderProductInfoReceivedDetailsPopup
                isOpen={selectedProductIndex !== null}
                setIsOpen={(isOpen: boolean) => {
                    if (!isOpen) {
                        setSelectedProductIndex(null);
                    }
                }}
                productIndex={selectedProductIndex}
                supplyOrderDetailsDisplayValues={supplyOrderDetailsDisplayValues}
            />
            <ConfirmedQuantityHistoryPopup
                quantityHistoryValuesToDisplay={quantityHistoryValuesToDisplay}
                setQuantityHistoryValuesToDisplay={setQuantityHistoryValuesToDisplay}
            />
        </>
    );
};

const SupplierProductInfoTableContainer = styled.div`
    // we compute the height with vh because we want the column to be visible while taking the maximum height
    width: 100%;
    max-height: calc(100vh - 200px);
    overflow: scroll;
    margin-top: 20px;

    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
        height: 7px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-box-shadow: 0 0 2px rgba(255, 255, 255, 0.5);
    }
`;

const SupplierProductInfoTable = styled.table`
    border-collapse: collapse;
`;

// Table Header
const SupplierProductInfoHeaderRow = styled.tr`
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
`;

const SupplierProductInfoHeaderCell = styled.th`
    position: sticky;
    top: 0;
    z-index: 1;
    padding: 10px;
    min-width: 140px;
    font-weight: 800;
    color: ${({ theme }) => theme.ctaPrimaryColor};
    text-align: left;
    background-color: ${({ theme }) => theme.cardBackgroundColor};
`;

const SupplierProductInfoHeaderCellSmall = styled(SupplierProductInfoHeaderCell)`
    max-width: 95px;
    min-width: 90px;
    text-align: center;
`;

// Table Row
const SupplierProductInfoRow = styled.tr<{ highlighted?: boolean; isAtSoldoutRisk?: boolean }>`
    border-top: ${({ theme }) => `1px solid ${theme.lightBorderColor}`};
    border-right: ${({ theme, highlighted }) =>
        `5px solid ${highlighted ? theme.ctaPrimaryColor : theme.cardBackgroundColor}`};
    border-left: ${({ theme, highlighted }) =>
        `5px solid ${highlighted ? theme.ctaPrimaryColor : theme.cardBackgroundColor}`};
    ${({ isAtSoldoutRisk }) =>
        isAtSoldoutRisk &&
        css`
            border-top: 2px solid red;
            border-bottom: 2px solid red;
        `}
`;

const ProductNameLink = styled(Link)`
    color: ${({ theme }) => theme.textColor};
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

const SupplierProductInfoCell = styled.td<{ isBold?: boolean }>`
    padding: 10px;
    color: ${({ theme }) => theme.textColor};
    font-weight: ${({ isBold }) => (isBold ? 800 : 500)};
    min-width: 160px;
`;

const SupplierProductInfoCellSmall = styled(SupplierProductInfoCell)`
    max-width: 85px;
    min-width: 85px;
    text-align: center;
`;

const SupplierProductInfoCellSmallForInput = styled(SupplierProductInfoCellSmall)`
    padding: 10px;
`;

const SupplierProductInfoInput = styled(BasicInput)`
    padding: 15px 5px;
`;

const EditIcon = styled(FaEdit)`
    flex-shrink: 0;
    cursor: pointer;
    color: ${({ theme }) => theme.ctaPrimaryColor};
    margin-left: 5px;

    &:hover {
        transform: scale(1.1);
    }
`;

const Warning = styled.div<{ color?: string }>`
    color: ${({ theme, color }) => color ?? theme.errorColor};
    font-weight: 500;
    font-size: 14px;
`;

const Title = styled.div`
    font-size: 22px;
    font-weight: bold;
    margin-top: 20px;
`;

const InputWithIconContainer = styled.div`
    display: flex;
    position: relative;
`;

const InfoIcon = styled(AiOutlineInfoCircle)`
    top: -10px;
    right: -10px;
    position: absolute;
    cursor: pointer;
    color: ${colors.blue};
    &:hover {
        transform: scale(1.1);
    }
`;

import { BundleFragment } from 'data/fragments/bundle';
import gql from 'graphql-tag';

export const GET_BUNDLES_QUERY = gql`
    query GET_BUNDLES {
        recentBundles {
            ...BundleFragment
        }
    }
    ${BundleFragment}
`;

import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import React from 'react';
import { FaArrowUp, FaTrash } from 'react-icons/fa';

import styled from 'styled-components';

import { MicrostoreColumnTemplateFormValues } from './FormHelper/DataMapper';

export const ColumnEditor = ({
    microstoreColumnTemplateFormValues,
    selectedShelfTemplateIndex,
    setSelectedShelfTemplateIndex,
    setSelectedLocationTemplatePosition,
    setMicrostoreColumnTemplateFormValuesAndIsFormDirty,
}: {
    microstoreColumnTemplateFormValues: MicrostoreColumnTemplateFormValues;
    selectedShelfTemplateIndex: number | null;
    setSelectedShelfTemplateIndex: (value: React.SetStateAction<number | null>) => void;
    setSelectedLocationTemplatePosition: (
        value: React.SetStateAction<{
            column: number;
            row: number;
        } | null>,
    ) => void;
    setMicrostoreColumnTemplateFormValuesAndIsFormDirty: ({
        newMicrostoreColumnTemplateFormValues,
        newIsDirty,
    }: {
        newMicrostoreColumnTemplateFormValues: MicrostoreColumnTemplateFormValues;
        newIsDirty?: boolean | undefined;
    }) => void;
}) => {
    function addShelf() {
        setSelectedShelfTemplateIndex(null);
        setSelectedLocationTemplatePosition(null);
        const newShelfTemplate = { _id: null, locationTemplateTable: [] };
        setMicrostoreColumnTemplateFormValuesAndIsFormDirty({
            newMicrostoreColumnTemplateFormValues: {
                ...microstoreColumnTemplateFormValues,
                shelfTemplates: [...microstoreColumnTemplateFormValues.shelfTemplates, newShelfTemplate],
            },
        });
    }

    function removeShelf(indexToRemove: number) {
        setSelectedShelfTemplateIndex(null);
        setSelectedLocationTemplatePosition(null);
        setMicrostoreColumnTemplateFormValuesAndIsFormDirty({
            newMicrostoreColumnTemplateFormValues: {
                ...microstoreColumnTemplateFormValues,
                shelfTemplates: [
                    ...microstoreColumnTemplateFormValues.shelfTemplates.filter(
                        (_shelfTemplate, index) => index !== indexToRemove,
                    ),
                ],
            },
        });
    }

    function moveShelfUp(indexToMoveUp: number) {
        setSelectedShelfTemplateIndex(null);
        setSelectedLocationTemplatePosition(null);
        const newShelfTemplates = [
            ...microstoreColumnTemplateFormValues.shelfTemplates.slice(0, indexToMoveUp - 1),
            ...microstoreColumnTemplateFormValues.shelfTemplates.slice(indexToMoveUp, indexToMoveUp + 1),
            ...microstoreColumnTemplateFormValues.shelfTemplates.slice(indexToMoveUp - 1, indexToMoveUp),
            ...microstoreColumnTemplateFormValues.shelfTemplates.slice(indexToMoveUp + 1),
        ];
        setMicrostoreColumnTemplateFormValuesAndIsFormDirty({
            newMicrostoreColumnTemplateFormValues: {
                ...microstoreColumnTemplateFormValues,
                shelfTemplates: newShelfTemplates,
            },
        });
    }

    return (
        <ColumnDetails>
            {microstoreColumnTemplateFormValues.shelfTemplates.map((shelfTemplate, index) => {
                const { locationTemplateTable } = shelfTemplate;
                return (
                    <ShelfInColumnContainer key={index}>
                        <ShelfInColumn
                            onClick={() => {
                                setSelectedShelfTemplateIndex(index);
                                setSelectedLocationTemplatePosition(null);
                            }}
                            isSelected={index === selectedShelfTemplateIndex}
                        >
                            {!locationTemplateTable.length ? 'Vide' : 'Pas Vide'}
                        </ShelfInColumn>

                        <ShelfInColumnButtonsContainer>
                            <SmallButton
                                type="button"
                                onClick={() => {
                                    moveShelfUp(index);
                                }}
                            >
                                <FaArrowUp size={15} />
                            </SmallButton>
                            <SmallButton
                                type="button"
                                onClick={() => {
                                    removeShelf(index);
                                }}
                            >
                                <FaTrash size={15} />
                            </SmallButton>
                        </ShelfInColumnButtonsContainer>
                    </ShelfInColumnContainer>
                );
            })}

            <TotemPrimaryButton type="button" onClick={addShelf}>
                Ajouter une étagère
            </TotemPrimaryButton>
        </ColumnDetails>
    );
};

const SmallButton = styled(TotemPrimaryButton)`
    padding: 5px;
    font-size: 10px;
`;

const ColumnDetails = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
    border-radius: ${({ theme }) => theme.borderRadius};
    height: 100%;
    background-color: ${({ theme }) => theme.cardBackgroundColor};
`;

const ShelfInColumnContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    height: 10%;
`;

const ShelfInColumn = styled.div<{ isSelected: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: ${({ theme, isSelected }) =>
        isSelected ? `2px solid ${theme.ctaPrimaryColor}` : `1px solid ${theme.darkBorderColor}`};
    flex-grow: 1;
    height: 100%;
    cursor: pointer;
`;

const ShelfInColumnButtonsContainer = styled.div`
    margin-left: 5px;
    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

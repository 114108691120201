import React from 'react';

import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { SupplyOrderState } from 'data/__generated__';

import { DetailFormDatePicker } from 'components/DetailsView/DetailFormDatePicker';
import { DetailValue } from 'components/DetailsView/DetailValue';
import { SectionColumn, SectionContainer, ColumnsSectionContainer } from 'components/DetailsView/Section';
import { StatusTag } from '../components/StatusTag';
import { DetailFormTextArea, DetailLink } from 'components/DetailsView';

import { dateFromString, formatDateAsAnniversary, getExtendedFormattedDatetime } from '../../../helpers/dateTimes';
import { SupplyOrderDetailsDisplayValues } from './supplyOrderDetailsFormHelper';
import { TotemLabel } from 'components/TotemLabel';

export const GeneralInfoSection = ({
    supplyOrderDetailsDisplayValues,
}: {
    supplyOrderDetailsDisplayValues: SupplyOrderDetailsDisplayValues;
}) => {
    const {
        _id: supplyOrderId,
        comment,
        createdAt,
        updatedAt,
        scheduledDeliveryDateHistory,
        number,
        spreadsheetId,
        spreadsheetName,
        state,
        supplier,
        orderDate,
        dateDeliveryScheduled,
        scheduledDeliveryDateValidity,
        weeklyMenusStartDate,
    } = supplyOrderDetailsDisplayValues;

    const isSupplyOrderNotOrdered = [SupplyOrderState.Created, SupplyOrderState.Ready].includes(state);
    const isSupplyOrderInRack = state === SupplyOrderState.InRack;

    const { control, register } = useFormContext();

    return (
        <SectionContainer title="Informations générales" isInitiallyOpen>
            <ColumnsSectionContainer numberOfColumns={2}>
                <SectionColumn>
                    <DetailLink label="Fournisseur" name={supplier.name} value={supplier._id} path="supplier" />
                    <DetailValue label="Numéro de commande" value={number} />
                    <DetailFormDatePicker
                        label="Commandé le"
                        placeholder="Date de commande"
                        defaultValue={orderDate}
                        name="orderDate"
                        control={control}
                        transformValueToDate={(value) => (value ? dateFromString(value) : null)}
                        transformDateToValue={(date) => (date ? formatDateAsAnniversary({ dateTime: date }) : null)}
                        isClearable={isSupplyOrderNotOrdered}
                        isDisabled={!isSupplyOrderNotOrdered}
                    />
                    <DetailFormDatePicker
                        label="Livraison prévue le"
                        placeholder="Date de livraison prévue"
                        defaultValue={dateDeliveryScheduled}
                        name="dateDeliveryScheduled"
                        control={control}
                        transformValueToDate={(value) => (value ? dateFromString(value) : null)}
                        transformDateToValue={(date) => (date ? formatDateAsAnniversary({ dateTime: date }) : null)}
                        isClearable={!isSupplyOrderInRack}
                        isDisabled={isSupplyOrderInRack}
                    />
                    {!scheduledDeliveryDateValidity ? (
                        <InvalidDeliveryDate>
                            La date de livraison prévue est incompatible avec la date de commande
                        </InvalidDeliveryDate>
                    ) : null}
                    {scheduledDeliveryDateHistory.length ? (
                        <TableContainer>
                            <TotemLabel>Historique des dates de livraison prévues</TotemLabel>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Mis à jour à</th>
                                        <th>Date de livraison prévue</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {[...scheduledDeliveryDateHistory]
                                        .reverse()
                                        .map(({ createdAt, scheduledDeliveryDate }, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{getExtendedFormattedDatetime(createdAt)}</td>
                                                    <td>{scheduledDeliveryDate}</td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        </TableContainer>
                    ) : null}
                    <DetailValue
                        label="Bon de commande"
                        value={
                            <OrderFormLink
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`https://docs.google.com/spreadsheets/d/${spreadsheetId}`}
                            >
                                {spreadsheetName}
                            </OrderFormLink>
                        }
                    />
                </SectionColumn>
                <SectionColumn>
                    <DetailValue label="ID" value={supplyOrderId} />
                    <DetailValue label="Date de création" value={getExtendedFormattedDatetime(createdAt)} />
                    <DetailValue label="Dernière mise à jour" value={getExtendedFormattedDatetime(updatedAt)} />
                    <DetailValue label="Date de début des menus hebdomadaires" value={weeklyMenusStartDate ?? 'N/A'} />
                    <StatusTag state={state} showHeading />
                    <DetailFormTextArea
                        label="Commentaires (usage interne)"
                        defaultValue={comment}
                        {...register('comment')}
                    />
                </SectionColumn>
            </ColumnsSectionContainer>
        </SectionContainer>
    );
};

const OrderFormLink = styled.a`
    color: ${({ theme }) => theme.ctaPrimaryColor};
`;

const InvalidDeliveryDate = styled.div`
    color: ${({ theme }) => theme.errorColor};
`;

const TableContainer = styled.div`
    display: flex;
    flex-direction: column;

    & > :not(:first-child) {
        margin-top: 5px;
    }

    table {
        border-spacing: 0;
        border-collapse: collapse;
        color: ${({ theme }) => theme.textColor};

        th,
        td {
            text-align: left;
            border: 1px solid ${({ theme }) => theme.lightBorderColor};
            border-spacing: 1px;
        }

        th {
            padding: 10px;
        }

        td {
            padding: 5px 10px;
        }
    }
`;

import { apolloClient } from '../../../App';
import { UrbantzRoundPresetFragment } from 'data/fragments/__generated__/UrbantzRoundPresetFragment';
import { GET_URBANTZ_ROUND_PRESETS_QUERY } from 'data/queries/urbantzRoundPreset';
import { GET_URBANTZ_ROUND_PRESETS } from 'data/queries/__generated__/GET_URBANTZ_ROUND_PRESETS';

export const urbantzRoundPresetsUpdatedOrCreatedHandler = (
    urbantzRoundPresetsUpdatedOrCreated: UrbantzRoundPresetFragment[],
) => {
    const result = apolloClient.readQuery<GET_URBANTZ_ROUND_PRESETS>({ query: GET_URBANTZ_ROUND_PRESETS_QUERY });
    if (!result) {
        return;
    }
    const currentUrbantzRoundPresets = result.getUrbantzRoundPresets;

    const updatedOrCreatedUrbantzRoundPresetIds = urbantzRoundPresetsUpdatedOrCreated.map(({ _id }) => _id);
    const nonUpdatedOrCreatedUrbantzRoundPresets = currentUrbantzRoundPresets.filter(
        ({ _id }) => !updatedOrCreatedUrbantzRoundPresetIds.includes(_id),
    );
    const newUrbantzRoundPresets = [...urbantzRoundPresetsUpdatedOrCreated, ...nonUpdatedOrCreatedUrbantzRoundPresets];

    apolloClient.writeQuery({
        query: GET_URBANTZ_ROUND_PRESETS_QUERY,
        data: { getUrbantzRoundPresets: newUrbantzRoundPresets },
    });
};

export const urbantzRoundPresetsDeletedHandler = (deletedUrbantzRoundPresetIds: string[]) => {
    const result = apolloClient.readQuery<GET_URBANTZ_ROUND_PRESETS>({ query: GET_URBANTZ_ROUND_PRESETS_QUERY });
    if (!result) {
        return;
    }
    const currentUrbantzRoundPresets = result.getUrbantzRoundPresets;

    const nonDeletedUrbantzRoundPresets = currentUrbantzRoundPresets.filter(
        ({ _id }) => !deletedUrbantzRoundPresetIds.includes(_id),
    );

    apolloClient.writeQuery({
        query: GET_URBANTZ_ROUND_PRESETS_QUERY,
        data: { getUrbantzRoundPresets: nonDeletedUrbantzRoundPresets },
    });
};

import React, { useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { PAGES } from '../../constants/pages';
import { AiOutlineInfoCircle } from 'react-icons/ai';

import { GET_SITES_QUERY } from 'data/queries/site';
import { GET_SITES } from 'data/queries/__generated__/GET_SITES';
import {
    CREATE_SITE_INVENTORY_STOCK,
    CREATE_SITE_INVENTORY_STOCKVariables,
} from 'data/mutations/__generated__/CREATE_SITE_INVENTORY_STOCK';
import { CREATE_SITE_INVENTORY_STOCK_MUTATION } from 'data/mutations/siteInventoryStock';

import { Footer } from 'components/Footer';
import { Header, HeaderTitle } from 'components/Header';
import { Loader } from 'components/Loader';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { SelectedOption, TotemSelect } from 'components/TotemSelect';
import { TotemInput } from 'components/TotemInput';
import { PageTitle } from 'components/PageTitle';
import { ProductWithStocksSelector } from './components/ProductWithStocksSelector';

type FormType = {
    siteOption: SelectedOption<string>;
    productOption: SelectedOption<string>;
    quantity: number | null;
    explanation: string | null;
};

export const SiteInventoryStockCreate = () => {
    const initialFormData = {
        siteOption: null,
        productOption: null,
        quantity: null,
        explanation: null,
    };
    const [formData, setFormData] = useState<FormType>(initialFormData);
    const { loading: sitesLoading, data: sitesData, error: sitesError } = useQuery<GET_SITES>(GET_SITES_QUERY);
    const [createSiteInventoryStock] = useMutation<CREATE_SITE_INVENTORY_STOCK, CREATE_SITE_INVENTORY_STOCKVariables>(
        CREATE_SITE_INVENTORY_STOCK_MUTATION,
    );

    if (sitesLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (sitesError || !sitesData) {
        throw new Error('Une erreur est survenue lors de la récupération des sites');
    }

    const sitesOptions = sitesData.sites
        .map((site) => ({ value: site._id, label: site.name }))
        .sort((siteA, siteB) => siteA.label.toLowerCase().localeCompare(siteB.label.toLowerCase()));

    const handleSiteSelect = (option: SelectedOption<string>) => {
        if (option) {
            const siteOption = option;
            setFormData({ ...formData, siteOption });
        }
    };

    const handleProductSelect = (option: SelectedOption<string>) => {
        if (option) {
            const productOption = option;
            setFormData({
                ...formData,
                productOption,
            });
        }
    };

    const handleQuantityInput = (value: string) => {
        if (/^(\d+)?$/.test(value)) {
            setFormData({
                ...formData,
                quantity: value ? Number(value) : null,
            });
        }
    };

    const handleExplanationInput = (value: string) => {
        if (value.trim() !== '' || value === '') {
            setFormData({
                ...formData,
                explanation: value,
            });
        }
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const { siteOption, productOption, quantity, explanation } = formData;
        if (!explanation || !siteOption || !productOption || quantity === null || explanation === '') {
            toast.error("L'un des champs n'a pas été renseigné");
            return;
        }
        const { value: siteId, label: siteLabel } = siteOption;
        const { value: productId, label: productLabel } = productOption;
        const result = await createSiteInventoryStock({
            variables: {
                siteId,
                productId,
                quantity,
                explanation,
            },
        });
        const { data } = result;
        if (data) {
            const {
                createSiteInventoryStock: { success },
            } = data;
            if (success) {
                toast.success(
                    `Le stock du produit "${productLabel}" sur le site "${siteLabel}" a bien été mis à jour !`,
                );
            } else {
                const {
                    createSiteInventoryStock: { error },
                } = data;
                if (error) {
                    throw new Error(`L'erreur suivante est survenue : ${error}`);
                } else {
                    throw new Error("Une erreur inconnue s'est produite");
                }
            }
        } else {
            throw new Error("Une erreur inconnue s'est produite");
        }
    };

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.siteInventoryStockCreate} />
                </HeaderTitle>
            </Header>
            <Content>
                <Form onSubmit={handleSubmit}>
                    <ContentScrollable>
                        <Fields>
                            <div>
                                <AiOutlineInfoCircle /> Un inventaire de stock est un changement complet de stock
                                existant (ex: 45 au lieu de 50). Pour créer une variation de stock unique (ex: achat
                                d'une coca): <Link to="/siteInventoryStock/create">/stockVariation/create</Link>
                            </div>
                            {!sitesLoading && sitesData ? (
                                <TotemSelect
                                    label="Site"
                                    placeholder="Sélectionner un site (recherche par nom ou par _id)"
                                    value={formData.siteOption}
                                    options={sitesOptions}
                                    onChange={handleSiteSelect}
                                    dataTest="site-selector"
                                />
                            ) : null}
                            {formData.siteOption ? (
                                <ProductWithStocksSelector
                                    productOption={formData.productOption}
                                    handleProductSelect={handleProductSelect}
                                    siteId={formData.siteOption.value}
                                />
                            ) : null}

                            <TotemInput
                                label="Quantité"
                                placeholder="Renseigner le nombre d'unités du produit"
                                type="number"
                                value={formData.quantity ?? ''}
                                onChange={(value) => handleQuantityInput(value)}
                                data-test="product-quantity"
                            />
                            <TotemInput
                                label="Pourquoi souhaitez-vous modifier le stock ?"
                                placeholder="Renseigner la raison de la modification de stock"
                                type="string"
                                value={formData.explanation ?? ''}
                                onChange={(value) => handleExplanationInput(value)}
                                data-test="stock-record-explanation"
                            />
                        </Fields>
                    </ContentScrollable>
                    <Footer>
                        <TotemPrimaryButton data-test="stock-record-create_submit-button">
                            Mettre le stock à jour
                        </TotemPrimaryButton>
                    </Footer>
                </Form>
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Content = styled.div`
    flex: 1;
    overflow: hidden;
`;

const Form = styled.form`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ContentScrollable = styled.div`
    padding: 15px;
    width: 100%;
    flex: 1;
    overflow-y: auto;
`;

const Fields = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

import { colors } from 'constants/colors';
import { SiteSetupState } from 'data/__generated__';

export const SETUP_STATES_LABELS = {
    [SiteSetupState.Created]: 'Créé',
    [SiteSetupState.InProgress]: 'En rangement',
    [SiteSetupState.Finished]: 'Fini',
};

export const SETUP_PLURAL_STATES_LABELS = {
    [SiteSetupState.Created]: 'Créés',
    [SiteSetupState.InProgress]: 'En rangement',
    [SiteSetupState.Finished]: 'Finis',
};

export const SETUP_STATE_COLORS = {
    [SiteSetupState.Created]: colors.pantinGrey,
    [SiteSetupState.InProgress]: colors.orange,
    [SiteSetupState.Finished]: colors.green,
};

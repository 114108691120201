import gql from 'graphql-tag';
import { SETUP_INSTRUCTION_FRAGMENT } from './setupInstruction';

export const MICROSTORE_COLUMN_SETUP_FRAGMENT = gql`
    fragment MicrostoreColumnSetupFragment on MicrostoreColumnSetup {
        _id
        createdAt
        instructions {
            ...SetupInstructionFragment
        }
        isFinishForced
        isNeeded
        microstoreColumnId
        microstoreColumnInventoryId
        microstoreColumnGroupSetupId
        positionInColumnGroupSetup
        state
        stateHistory {
            createdAt
            state
        }
        updatedAt
    }
    ${SETUP_INSTRUCTION_FRAGMENT}
`;

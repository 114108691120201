import gql from 'graphql-tag';

export const SUPPLIER_ORDERING_TIME_FOR_DAY_FRAGMENT = gql`
    fragment OrderingTimeForDayFragment on OrderingTimeForDay {
        orderBefore
        daysToDeliver
        deliveryHours {
            start
            end
        }
        canBeOrdered
    }
`;

export const SUPPLIER_FRAGMENT = gql`
    fragment SupplierFragment on Supplier {
        _id
        name
        closingDatesRanges {
            closingDate
            openingDate
        }
        description
        phone
        address
        zipcode
        website
        createdAt
        updatedAt
        shouldCheckReceptionDayIsNotClosed
        shouldOrderAutomatically
        state
        stateHistory {
            state
            createdAt
            createdBy
            createdByName
        }
        contacts {
            name
            surname
            phone
            email
            description
        }
        paymentTimeType
        paymentTimeDays
        paymentType
        orderingProductTerms {
            minQuantity
            productId
        }
        orderingTerms {
            deliveryPrice
            minRequirements {
                unit
                value
            }
        }
        orderingTimes {
            monday {
                ...OrderingTimeForDayFragment
            }
            tuesday {
                ...OrderingTimeForDayFragment
            }
            wednesday {
                ...OrderingTimeForDayFragment
            }
            thursday {
                ...OrderingTimeForDayFragment
            }
            friday {
                ...OrderingTimeForDayFragment
            }
            saturday {
                ...OrderingTimeForDayFragment
            }
            sunday {
                ...OrderingTimeForDayFragment
            }
        }
        orderMode
        orderEmails
        orderPlatformUrl
        clientCode
        frequency
        updatedAt
    }
    ${SUPPLIER_ORDERING_TIME_FOR_DAY_FRAGMENT}
`;

export const FORM_ERROR_FRAGMENT = gql`
    fragment FormErrorFragment on FormError {
        sectionName
        sectionErrors {
            fieldName
            fieldError
        }
    }
`;

import gql from 'graphql-tag';

import { PRODUCT_SWITCH_FRAGMENT } from '../fragments/productSwitch';

export const GET_PRODUCT_SWITCHES_QUERY = gql`
    query GET_PRODUCT_SWITCHES {
        getProductSwitches {
            ...ProductSwitchFragment
        }
    }
    ${PRODUCT_SWITCH_FRAGMENT}
`;

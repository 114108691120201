import gql from 'graphql-tag';

export const POLL_FRAGMENT = gql`
    fragment PollFragment on Poll {
        _id
        answerOptions
        answersRepartition
        audience {
            organizationIds
            siteIds
            userIds
        }
        createdAt
        dateWindow {
            startDate
            endDate
        }
        imageUrl
        numberOfParticipants
        question
        sectionTitle
        title
    }
`;

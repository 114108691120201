import { gql } from '@apollo/client';

import { BOX_PARAMETER_FRAGMENT, OPS_PARAMETER_FRAGMENT } from '../fragments/opsParameter';

export const GET_OPS_PARAMETERS_QUERY = gql`
    query GET_OPS_PARAMETERS {
        getOpsParameters {
            ...OpsParameterFragment
        }
    }
    ${OPS_PARAMETER_FRAGMENT}
`;

export const GET_BOX_OPS_PARAMETERS_QUERY = gql`
    query GET_BOX_OPS_PARAMETERS {
        boxOpsParameters {
            volume {
                ...BoxParameterFragment
            }
            weight {
                ...BoxParameterFragment
            }
        }
    }
    ${BOX_PARAMETER_FRAGMENT}
`;

import gql from 'graphql-tag';
import { PRODUCT_ARRANGEMENT_PRODUCT_FRAGMENT, PRODUCT_ARRANGEMENT_WITH_PRODUCTS_FRAGMENT } from './productArrangement';

export const MICROSTORE_COLUMN_TEMPLATE_FRAGMENT = gql`
    fragment MicrostoreColumnTemplateFragment on MicrostoreColumnTemplate {
        _id
        name
        type
    }
`;

export const MICROSTORE_COLUMN_TEMPLATE_WITH_ALL_INFO = gql`
    fragment MicrostoreColumnTemplateWithAllInfo on MicrostoreColumnTemplate {
        _id
        name
        type
        shelfTemplates {
            _id
            columnTemplateId
            locationTemplateTable {
                _id
                positionInShelf {
                    column
                    row
                }
                productArrangement {
                    ...ProductArrangementWithProductsFragment
                }
                alternativeProductArrangementIds
                productArrangementSingleProductInfo {
                    product {
                        ...ProductArrangementProductFragment
                    }
                    stockMaxUnsecured
                    stockMaxSensei
                    reassortmentType
                }
                shelfTemplateId
                shouldOrderProductIfNotInSite
                shouldSmoothProductTransition
                allowProductRotation
            }
            positionInColumn
        }
    }
    ${PRODUCT_ARRANGEMENT_WITH_PRODUCTS_FRAGMENT}
    ${PRODUCT_ARRANGEMENT_PRODUCT_FRAGMENT}
`;

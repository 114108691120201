import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';

import { MARK_SUPPLY_ORDER_AS_ABORTED_MUTATION } from 'data/mutations/supplyOrder';
import {
    MARK_SUPPLY_ORDER_AS_ABORTED,
    MARK_SUPPLY_ORDER_AS_ABORTEDVariables,
} from 'data/mutations/__generated__/MARK_SUPPLY_ORDER_AS_ABORTED';

import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { SelectedOption, TotemSelect } from 'components/TotemSelect';
import { Loader, LoaderModeType } from 'components/Loader';

import { handleUpdateActionEndToast } from '../utils/handleUpdateActionEndToast';
import { TotemInput } from 'components/TotemInput';

export const ABORT_WITHOUT_REASON_LABEL = 'no-reason-specified';

export const STATE_CHANGE_REASONS = [
    { value: 'unreachable-franco', label: 'Franco difficile à atteindre' },
    { value: 'overstock-risk', label: 'Risque de surstock' },
    {
        value: 'too-early',
        label: "Date d'achat prématurée (le delai de livraison fournisseur permet d'attendre encore un peu sans tomber en rupture de stock).",
    },
    { value: 'already-ordered', label: 'Commande déjà passée' },
    { value: 'human-error', label: 'Erreur humaine (achat créé avec le mauvais fournisseur, etc..)' },
    { value: 'supplier-out-of-stock', label: 'Rupture chez le fournisseur' },
    {
        value: 'other',
        label: 'Autre',
    },
];

export function AbortSupplyOrderPopup({
    isOpen,
    setIsOpen,
    supplyOrderId,
}: {
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
    supplyOrderId: string;
}) {
    const [selectedAbortingReasonOption, setSelectedAbortingReasonOption] = useState<SelectedOption<string>>(null);
    const [otherAbortingReason, setOtherAbortingReason] = useState<string | undefined>(undefined);
    const [markSupplyOrderAsAborted, { loading: markSupplyOrderAsAbortedLoading }] = useMutation<
        MARK_SUPPLY_ORDER_AS_ABORTED,
        MARK_SUPPLY_ORDER_AS_ABORTEDVariables
    >(MARK_SUPPLY_ORDER_AS_ABORTED_MUTATION, {
        variables: {
            supplyOrderIdsWithReasons: [
                {
                    supplyOrderId,
                    stateChangeReasons:
                        selectedAbortingReasonOption?.value === 'other'
                            ? [otherAbortingReason || ABORT_WITHOUT_REASON_LABEL]
                            : [selectedAbortingReasonOption?.value || ABORT_WITHOUT_REASON_LABEL],
                },
            ],
        },
    });

    async function handleSubmit() {
        const { data } = await markSupplyOrderAsAborted();

        if (!data) {
            throw new Error("Une erreur est survenue lors de l'annulation de l'achat");
        }

        const {
            markSupplyOrdersAsAborted: { updatedSupplyOrders, errors, warnings },
        } = data;
        handleUpdateActionEndToast({
            displaySuccess: !!updatedSupplyOrders.length,
            successText: "L'achat a bien été annulé",
            errors,
            warnings,
        });
        setIsOpen(false);
    }

    return (
        <TotemPopup
            title="Souhaitez-vous vraiment annuler l'achat ?"
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            contentOverflow="visible"
        >
            <Container>
                <SubmitContainer>
                    {selectedAbortingReasonOption?.value === 'other' ? (
                        <>
                            <TotemInput
                                label="Autre raison"
                                onChange={setOtherAbortingReason}
                                placeholder="Autre raison de l'annulation de l'achat"
                                value={otherAbortingReason}
                                autoFocus={true}
                            />
                            <ValidationButton onClick={() => setSelectedAbortingReasonOption(null)}>
                                Choisir une raison de la liste
                            </ValidationButton>
                        </>
                    ) : (
                        <TotemSelect
                            label="Raison de l'annulation"
                            placeholder="Indiquer la raison de l'annulation de l'achat"
                            value={selectedAbortingReasonOption}
                            options={STATE_CHANGE_REASONS}
                            onChange={setSelectedAbortingReasonOption}
                        />
                    )}

                    <ValidationButton
                        disabled={
                            !selectedAbortingReasonOption ||
                            (selectedAbortingReasonOption?.value === 'other' && !otherAbortingReason)
                        }
                        onClick={handleSubmit}
                    >
                        {markSupplyOrderAsAbortedLoading ? (
                            <Loader size="20px" mode={LoaderModeType.Spin} />
                        ) : (
                            "Valider l'annulation"
                        )}
                    </ValidationButton>
                </SubmitContainer>
            </Container>
        </TotemPopup>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    & > :not(:first-child) {
        margin-top: 15px;
    }
`;

const SubmitContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 15px;
    width: 100%;
`;

const ValidationButton = styled(TotemPrimaryButton)`
    margin-top: 15px;
`;

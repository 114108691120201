import gql from 'graphql-tag';

export const LOCATION_PLANOGRAM_FRAGMENT = gql`
    fragment LocationPlanogramFragment on LocationPlanogram {
        _id
        createdAt
        locationId
        productId
        startDate
        updatedAt
    }
`;

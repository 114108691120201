import { apolloClient } from '../../../App';
import { ThermometerFragment } from 'data/fragments/__generated__/ThermometerFragment';
import { GET_THERMOMETERS_QUERY } from 'data/queries/thermometer';
import { GET_THERMOMETERS, GET_THERMOMETERS_getThermometers } from 'data/queries/__generated__/GET_THERMOMETERS';

export const thermometersUpdatedOrCreatedHandler = (thermometersUpdatedOrCreated: ThermometerFragment[]) => {
    let currentThermometers = [] as GET_THERMOMETERS_getThermometers[];

    const result = apolloClient.readQuery<GET_THERMOMETERS>({ query: GET_THERMOMETERS_QUERY });
    if (!result) {
        return;
    }
    currentThermometers = result.getThermometers;

    const updatedOrCreatedThermometerIds = thermometersUpdatedOrCreated.map(({ _id }) => _id);
    const nonUpdatedOrCreatedThermometers = currentThermometers.filter(
        ({ _id }) => !updatedOrCreatedThermometerIds.includes(_id),
    );
    const newThermometers = [...thermometersUpdatedOrCreated, ...nonUpdatedOrCreatedThermometers];

    apolloClient.writeQuery({
        query: GET_THERMOMETERS_QUERY,
        data: { getThermometers: newThermometers },
    });
};

import React, { useState } from 'react';
import styled from 'styled-components';

import { SETUP_INSTRUCTION_TYPE_LABELS } from 'pages/SiteSetups/constants/types';

import { MicrostoreColumnWithShelvesFragment } from 'data/fragments/__generated__/MicrostoreColumnWithShelvesFragment';
import { MicrostoreColumnInventoryFragment } from 'data/fragments/__generated__/MicrostoreColumnInventoryFragment';
import { GET_MICROSTORE_PRODUCTS_getMicrostoreProducts } from 'data/queries/__generated__/GET_MICROSTORE_PRODUCTS';
import { MicrostoreColumnSetupFragment } from 'data/fragments/__generated__/MicrostoreColumnSetupFragment';
import { SetupInstructionType } from 'data/__generated__';

import { InventoryColumnDisplay } from './InventoryColumnDisplay';
import { InventoryProductsSection } from './InventoryProductsSection';
import { GET_MICROSTORE_SITE_siteWithLocationInfo } from 'data/queries/__generated__/GET_MICROSTORE_SITE';
import { getWeekdateBeforeNextSetup } from 'pages/SiteSetups/utils/getWeekdateBeforeNextSetup';
import { dateFromString } from 'helpers/dateTimes';

export const ColumnInventorySection = ({
    column,
    columnInventory,
    columnSetup,
    inventoryDate,
    products,
    site,
}: {
    column: MicrostoreColumnWithShelvesFragment;
    columnInventory: MicrostoreColumnInventoryFragment;
    columnSetup: MicrostoreColumnSetupFragment;
    inventoryDate: string;
    products: GET_MICROSTORE_PRODUCTS_getMicrostoreProducts[];
    site: GET_MICROSTORE_SITE_siteWithLocationInfo;
}) => {
    const [selectedProductId, setSelectedProductId] = useState<string | null>(null);

    const weekdateBeforeNextSetup = getWeekdateBeforeNextSetup({
        deliveryDays: site.deliveryDays,
        startDate: dateFromString(inventoryDate, true),
    });

    const inventoryInstructionTypes = columnSetup.instructions
        .filter(({ type }) =>
            [
                SetupInstructionType.InventoryComplete,
                SetupInstructionType.InventoryCutlery,
                SetupInstructionType.InventoryDry,
                SetupInstructionType.InventorySmallExpiryDate,
                SetupInstructionType.InventoryZero,
            ].includes(type),
        )
        .map(({ type }) => type);

    if (!inventoryInstructionTypes.length) {
        return null;
    }

    return (
        <Container>
            <Title>
                {inventoryInstructionTypes.reduce(
                    (acc, inventoryType, index) =>
                        acc + (index !== 0 ? ' + ' : '') + SETUP_INSTRUCTION_TYPE_LABELS[inventoryType],
                    '',
                )}
            </Title>
            <Content>
                <ColumnInventoryContainer>
                    <InventoryColumnDisplay
                        column={column}
                        columnInventory={columnInventory}
                        setSelectedProductId={setSelectedProductId}
                        selectedProductId={selectedProductId}
                        weekdateBeforeNextSetup={weekdateBeforeNextSetup}
                    />
                </ColumnInventoryContainer>
                <InventoryProductsSection
                    checkedLocations={columnInventory.checkedLocations}
                    products={products}
                    setSelectedProductId={setSelectedProductId}
                    selectedProductId={selectedProductId}
                    weekdateBeforeNextSetup={weekdateBeforeNextSetup}
                />
            </Content>
        </Container>
    );
};

const Container = styled.div``;

const Content = styled.div`
    display: flex;
    margin-top: 20px;
`;

const Title = styled.div`
    font-size: 18px;
    font-weight: bold;
    text-align: center;
`;

const ColumnInventoryContainer = styled.div`
    width: 30%;
`;

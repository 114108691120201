import React from 'react';

import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { GET_MESSAGES_SET_QUERY } from 'data/queries/messagesSet';

import { Loader } from 'components/Loader';

import { GET_MESSAGES_SET } from 'data/queries/__generated__/GET_MESSAGES_SET';
import { MessagesSetForm } from './components/MessagesSetForm';

type ParamTypes = {
    messagesSetId: string;
};

export const MessagesSetDetails = () => {
    const { messagesSetId = '' } = useParams<ParamTypes>();

    const {
        loading: messagesSetLoading,
        data: messagesSetData,
        error: messagesSetError,
    } = useQuery<GET_MESSAGES_SET>(GET_MESSAGES_SET_QUERY, {
        variables: { messagesSetId },
    });

    if (messagesSetLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (!messagesSetData || messagesSetError) {
        throw new Error('Une erreur est survenue lors de la récupération de la série');
    }

    const { messagesSet } = messagesSetData;

    if (!messagesSet) {
        throw new Error('Une erreur est survenue lors de la récupération de la série');
    }

    return (
        <Container>
            <MessagesSetForm messagesSet={messagesSet} />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex: 1;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

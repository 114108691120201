import React from 'react';

import Lottie from 'react-lottie';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import notFoundData from '../assets/lottie/not-found.json';

export const NotFound = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: notFoundData,
    };

    return (
        <Container>
            <Helmet title="404... OUPS · TOTADMIN" />
            <LottieContainer>
                <Lottie options={defaultOptions} height="100%" width="100%" />
            </LottieContainer>
            <Title>Oups... On a bien cherché, mais la page demandée est introuvable</Title>
        </Container>
    );
};

const Container = styled.div`
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
`;

const LottieContainer = styled.div`
    width: 70%;
    height: 70%;
`;

const Title = styled.span`
    text-align: center;
    font-size: 40px;
`;

import { CsvDataRow } from 'components/TotemCsvInputButton';
import { MicrostoreLocationIdWithTargetStockInput } from 'data/__generated__';
import { toast } from 'react-toastify';

export function getLocationIdsWithTargetStockFromData(
    csvData: CsvDataRow[],
): MicrostoreLocationIdWithTargetStockInput[] {
    const locationIdsWithTargetStock: MicrostoreLocationIdWithTargetStockInput[] = [];

    const [header, ...data] = csvData;
    const locationIdIndex = header.findIndex((elem) => elem === 'locationId');
    const targetStockIndex = header.findIndex((elem) => elem === 'targetStock');

    const numberOfFields = header.length;

    if (!data.length) {
        toast.error('Veuillez vérifier le fichier CSV. Il semble être vide');
        return [];
    }

    //if extra line in 2 column file, corrups all data, last line - ['']
    if (data[data.length - 1].length === 1 && data[data.length - 1][0] === '') {
        toast.error(
            "Fichier CSV mal formaté. Veuillez vérifier que vous n'avez pas laissé de lignes ou de colonnes vides",
        );
        return [];
    }

    if (locationIdIndex === -1 || targetStockIndex === -1) {
        toast.error("Veuillez vérifier les champs CSV. Il devrait y avoir 'locationId' et 'targetStock'");
        return [];
    }

    data.forEach((locationArrayFromCSV: Array<string>, rowIndex: number): void => {
        if (locationArrayFromCSV.length !== numberOfFields) {
            toast.error(`Veuillez vérifier le fichier CSV. Mauvais nombre de colonnes sur la ligne ${rowIndex + 2}`);
            return;
        }
        locationArrayFromCSV.forEach((field: string, fieldIndex: number) => {
            if (!field) {
                toast.error(
                    `Veuillez vérifier le fichier CSV. Problème de champs sur la ligne ${rowIndex + 2}, colonne ${
                        fieldIndex + 1
                    }`,
                );
                return [];
            }
        });
        const locationId = locationArrayFromCSV[locationIdIndex];
        const targetStock =
            locationArrayFromCSV[targetStockIndex] === 'null' ? null : Number(locationArrayFromCSV[targetStockIndex]);
        if (targetStock !== null && (Number.isNaN(targetStock) || !Number.isInteger(targetStock))) {
            toast.error(
                `Veuillez vérifier le fichier CSV. Stock cible (targetStock) non valide sur la ligne ${rowIndex + 2}`,
            );
            return;
        }

        locationIdsWithTargetStock.push({ locationId, targetStock });
    });

    return locationIdsWithTargetStock;
}

import React from 'react';

import { IconBuildingWarehouse, IconBook, IconUsers } from '@tabler/icons';

import { colors } from 'constants/colors';

import { BiCoffeeTogo } from 'react-icons/bi';
import { CgScreenWide } from 'react-icons/cg';
import { FaAppleAlt, FaCoffee, FaHandshake, FaThermometerHalf, FaTruckLoading } from 'react-icons/fa';
import {
    FcAreaChart,
    FcBarChart,
    FcCalendar,
    FcCancel,
    FcConferenceCall,
    FcCurrencyExchange,
    FcDataConfiguration,
    FcDataRecovery,
    FcDeployment,
    FcDonate,
    FcExpired,
    FcFilledFilter,
    FcGrid,
    FcInTransit,
    FcInvite,
    FcList,
    FcMoneyTransfer,
    FcNeutralTrading,
    FcNews,
    FcOrganization,
    FcPaid,
    FcPlanner,
    FcSettings,
    FcShop,
    FcSms,
    FcStackOfPhotos,
} from 'react-icons/fc';
import { BsFillQuestionDiamondFill } from 'react-icons/bs';
import { HiOutlineSwitchHorizontal } from 'react-icons/hi';
import { RiExchangeBoxLine, RiFridgeFill } from 'react-icons/ri';
import { IoBicycleOutline } from 'react-icons/io5';
import { GrPlan } from 'react-icons/gr';

enum PageName {
    advertisementScreenConfig = 'advertisementScreenConfig',
    advertisementScreenConfigLoggedOut = 'advertisementScreenConfigLoggedOut',
    badges = 'badges',
    badgeCreate = 'badgeCreate',
    badgeUpdate = 'badgeUpdate',
    bakeryRecords = 'bakeryRecords',
    bundles = 'bundles',
    categories = 'categories',
    categoryCreate = 'categoryCreate',
    categoryDetails = 'categoryDetails',
    credits = 'credits',
    creditCreate = 'creditCreate',
    comments = 'comments',
    deliveries = 'deliveries',
    deliveriesDailyPhoto = 'deliveriesDailyPhoto',
    deliveryDetails = 'deliveryDetails',
    expiredFutureStockVariations = 'expiredFutureStockVariations',
    expiryDateRecords = 'expiryDateRecords',
    equipments = 'equipments',
    equipmentDetails = 'equipmentDetails',
    futureStockVariations = 'futureStockVariations',
    hotDrinksArrangements = 'hotDrinksArrangements',
    hotDrinksArrangementDetails = 'hotDrinksArrangementDetails',
    locations = 'locations',
    supplierPartnerships = 'supplierPartnerships',
    menus = 'menus',
    menusDetails = 'menusDetails',
    messagesSets = 'messagesSets',
    messagesSetsCreate = 'messagesSetsCreate',
    messagesSetDetails = 'messagesSetDetails',
    microstoreColumns = 'microstoreColumns',
    microstoreColumnDetails = 'microstoreColumnDetails',
    microstoreColumnTargetsInfo = 'microstoreColumnTargetsInfo',
    microstoreColumnTemplates = 'microstoreColumnTemplates',
    microstoreColumnTemplateDetails = 'microstoreColumnTemplateDetails',
    microstorePlanogram = 'microstorePlanogram',
    microstoreSnapshot = 'microstoreSnapshot',
    mobilePopups = 'mobilePopups',
    notifications = 'notifications',
    notificationsOrganization = 'notificationsOrganization',
    notificationsSite = 'notificationsSite',
    notificationsUser = 'notificationsUser',
    opsParameters = 'opsParameters',
    orders = 'orders',
    orderDetails = 'orderDetails',
    organizations = 'organizations',
    organizationDetails = 'organizationDetails',
    organizationCreate = 'organizationCreate',
    payments = 'payments',
    paymentDetails = 'paymentDetails',
    polls = 'polls',
    pollCreate = 'pollCreate',
    pollDetails = 'pollDetails',
    products = 'products',
    productCreate = 'productCreate',
    productArrangements = 'productArrangements',
    productGroups = 'productGroups',
    productGroupDetails = 'productGroupDetails',
    productArrangementDetails = 'productArrangementDetails',
    productDetails = 'productDetails',
    productImport = 'productImport',
    productPriceRangeCreate = 'productPriceRangeCreate',
    productPriceRangeDetails = 'productPriceRangeDetails',
    productPriceRanges = 'productPriceRanges',
    productSwitches = 'productSwitches',
    quizzes = 'quizzes',
    quizCreate = 'quizCreate',
    quizDetails = 'quizDetails',
    ritualCreate = 'ritualCreate',
    ritualDetails = 'ritualDetails',
    rituals = 'rituals',
    ritualTemplates = 'ritualTemplates',
    settings = 'settings',
    sites = 'sites',
    siteCreate = 'siteCreate',
    siteDetails = 'siteDetails',
    siteExternalInventory = 'siteExternalInventory',
    siteProductStocks = 'siteProductStocks',
    siteSetups = 'siteSetups',
    siteSetupDetails = 'siteSetupDetails',
    siteStocks = 'siteStocks',
    stockTransfers = 'stockTransfers',
    stockTransferCreate = 'stockTransferCreate',
    stockTransferDetails = 'stockTransferDetails',
    stockTransferReturns = 'stockTransferReturns',
    stocks = 'stocks',
    siteInventoryStockCreate = 'siteInventoryStockCreate',
    stockVariationCreate = 'stockVariationCreate',
    stockVariationMultiCreate = 'stockVariationMultiCreate',
    stockVariationsByDay = 'stockVariationsByDay',
    subventions = 'subventions',
    subventionCreate = 'subventionCreate',
    subventionDetails = 'subventionDetails',
    suppliers = 'suppliers',
    supplierCreate = 'supplierCreate',
    supplierDetails = 'supplierDetails',
    supplyOrders = 'supplyOrders',
    supplyOrderDetails = 'supplyOrderDetails',
    supplyOrderConfirmation = 'supplyOrderConfirmation',
    supplierDiscounts = 'supplierDiscounts',
    tags = 'tags',
    tagCreate = 'tagCreate',
    tagUpdate = 'tagUpdate',
    thermometers = 'thermometers',
    transactions = 'transactions',
    transactionCreate = 'transactionCreate',
    transactionDetails = 'transactionDetails',
    termsRecords = 'termsRecords',
    termsRecordCreate = 'termsRecordCreate',
    totemClosedDays = 'totemClosedDays',
    totemClosedReceptionDays = 'totemClosedReceptionDays',
    urbantzRoundPresets = 'urbantzRoundPresets',
    users = 'users',
    userCreateToteam = 'userCreateToteam',
    userDetails = 'userDetails',
    senseiBasket = 'senseiBasket',
    warehouseDeliveries = 'warehouseDeliveries',
    warehouseDeliveryDetails = 'warehouseDeliveryDetails',
    warehouseReturns = 'warehouseReturns',
    warehouseReturnDetails = 'warehouseReturnDetails',
}

export enum NavSection {
    WarehouseManagementSystem = 'WarehouseManagementSystem',
    CatalogManagementSystem = 'CatalogManagementSystem',
    CustomerManagementSystem = 'CustomerManagementSystem',
}

export const NAV_SECTION_LABELS = {
    [NavSection.WarehouseManagementSystem]: 'Warehouse Management System',
    [NavSection.CatalogManagementSystem]: 'Catalog Management System',
    [NavSection.CustomerManagementSystem]: 'Customer Management System',
};

export const NAV_SECTION_ICONS = {
    [NavSection.WarehouseManagementSystem]: <IconBuildingWarehouse />,
    [NavSection.CatalogManagementSystem]: <IconBook />,
    [NavSection.CustomerManagementSystem]: <IconUsers />,
};

export type PageType = {
    icon?: JSX.Element;
    label: string;
    section: PageName;
    shortLabel?: string;
    url: string;
    navSection?: NavSection;
};

export const PAGES: Record<PageName, PageType> = {
    advertisementScreenConfig: {
        icon: <CgScreenWide />,
        label: 'Écran publicitaire',
        section: PageName.advertisementScreenConfig,
        url: '/advertisement-screen-config',
        navSection: NavSection.CustomerManagementSystem,
    },
    advertisementScreenConfigLoggedOut: {
        label: 'Écran publicitaire',
        section: PageName.advertisementScreenConfig,
        url: '/advertisement-screen-config/logged-out',
    },
    badges: {
        icon: <FcFilledFilter />,
        label: 'Badges',
        section: PageName.badges,
        url: '/badges',
        navSection: NavSection.CatalogManagementSystem,
    },
    badgeCreate: {
        icon: <FcFilledFilter />,
        label: 'Badges',
        section: PageName.badges,
        url: '/badge/create',
    },
    badgeUpdate: {
        icon: <FcFilledFilter />,
        label: 'Badges',
        section: PageName.badges,
        url: '/badge/:badgeId',
    },
    bakeryRecords: {
        icon: <>🥐</>,
        label: 'Suivi de cuisson viennoiseries',
        section: PageName.bakeryRecords,
        url: '/bakeryRecords',
        navSection: NavSection.WarehouseManagementSystem,
    },
    bundles: {
        icon: <FcDeployment />,
        label: 'Bundles',
        section: PageName.bundles,
        url: '/bundles',
        navSection: NavSection.WarehouseManagementSystem,
    },
    categories: {
        icon: <FcList />,
        label: 'Catégories',
        section: PageName.categories,
        url: '/categories',
        navSection: NavSection.CatalogManagementSystem,
    },
    categoryCreate: {
        icon: <FcList />,
        label: 'Créer une catégorie',
        section: PageName.categories,
        url: '/category/create',
    },
    categoryDetails: {
        icon: <FcList />,
        label: "Détails d'une catégorie",
        section: PageName.categories,
        url: '/category/:categoryId',
    },
    credits: {
        icon: <FcDonate />,
        label: 'Crédits',
        section: PageName.credits,
        url: '/credits',
        navSection: NavSection.CustomerManagementSystem,
    },
    creditCreate: {
        icon: <FcDonate />,
        label: 'Créer un crédit',
        section: PageName.credits,
        url: '/credit/create',
    },
    comments: {
        icon: <FcConferenceCall />,
        label: 'Commentaires',
        section: PageName.comments,
        url: '/comments',
        navSection: NavSection.CustomerManagementSystem,
    },
    deliveries: {
        icon: <FcInTransit />,
        label: 'Livraisons',
        section: PageName.deliveries,
        url: '/deliveries',
        navSection: NavSection.WarehouseManagementSystem,
    },
    deliveriesDailyPhoto: {
        icon: <FcInTransit />,
        label: 'Photos des Livraisons',
        section: PageName.deliveries,
        url: '/deliveries/photos',
    },
    deliveryDetails: {
        icon: <FcInTransit />,
        label: "Détails d'une livraison",
        section: PageName.deliveries,
        url: '/delivery/:deliveryId',
    },
    equipments: {
        icon: <FaCoffee color={colors.blueOutline} />,
        label: 'Équipements',
        section: PageName.equipments,
        url: '/equipments',
        navSection: NavSection.CatalogManagementSystem,
    },
    equipmentDetails: {
        label: "Détails d'un équipement",
        section: PageName.equipments,
        url: '/equipment/:equipmentId',
    },
    expiredFutureStockVariations: {
        label: 'Variations de stock à venir expirées',
        section: PageName.stocks,
        url: '/stockVariation/expiredFutureStockVariations',
    },
    expiryDateRecords: {
        icon: <FcExpired />,
        label: 'Date limite de consommation',
        section: PageName.expiryDateRecords,
        shortLabel: 'DLC',
        url: '/expiry-date-records',
        navSection: NavSection.WarehouseManagementSystem,
    },
    futureStockVariations: {
        icon: <FcAreaChart />,
        label: 'Variations de stock à venir',
        section: PageName.stocks,
        url: '/stockVariation/futureStockVariations',
    },
    hotDrinksArrangements: {
        icon: <BiCoffeeTogo color={colors.orange} />,
        label: 'Gammes Machine à Café',
        shortLabel: 'Gammes Machine à Café',
        section: PageName.hotDrinksArrangements,
        url: '/hot-drinks-arrangements',
        navSection: NavSection.CatalogManagementSystem,
    },
    hotDrinksArrangementDetails: {
        icon: <BiCoffeeTogo color={colors.orange} />,
        label: 'Gamme Machine à Café',
        section: PageName.hotDrinksArrangements,
        url: '/hot-drinks-arrangement/:hotDrinksArrangementId',
    },
    locations: {
        icon: <FcGrid />,
        label: 'Emplacements entrepôt',
        section: PageName.locations,
        shortLabel: 'Emplacements',
        url: '/locations',
        navSection: NavSection.WarehouseManagementSystem,
    },
    supplierPartnerships: {
        icon: <RiExchangeBoxLine />,
        label: 'Trades Marketing',
        section: PageName.supplierPartnerships,
        url: '/supplier-partnerships',
        navSection: NavSection.CustomerManagementSystem,
    },
    menus: {
        icon: <FcCalendar />,
        label: 'Menus store',
        section: PageName.menus,
        shortLabel: 'Menus',
        url: '/menus',
        navSection: NavSection.CatalogManagementSystem,
    },
    menusDetails: {
        icon: <FcCalendar />,
        label: 'Détail menus store',
        section: PageName.menus,
        url: '/menusDetails/:siteId/:selectedDate',
    },
    messagesSets: {
        icon: <FcSms />,
        label: 'Séries de messages',
        section: PageName.messagesSets,
        shortLabel: 'Messages Rituels',
        url: '/messagesSets',
    },
    messagesSetsCreate: {
        icon: <FcSms />,
        label: 'Créer une série de messages',
        section: PageName.messagesSets,
        shortLabel: 'Messages Rituels',
        url: '/messagesSets/create',
    },
    messagesSetDetails: {
        icon: <FcSms />,
        label: 'Détails de la série',
        section: PageName.messagesSetDetails,
        shortLabel: 'Détails Messages',
        url: '/messagesSet/:messagesSetId',
    },
    microstoreColumns: {
        icon: <FcDataRecovery />,
        label: 'Colonnes microstore',
        section: PageName.microstoreColumns,
        shortLabel: 'MS Colonnes',
        url: '/microstoreColumns',
        navSection: NavSection.WarehouseManagementSystem,
    },
    microstoreColumnDetails: {
        icon: <FcDataRecovery />,
        label: "Détails des colonnes d'un microstore",
        section: PageName.microstoreColumns,
        url: '/microstoreColumns/:siteId',
    },
    microstoreColumnTargetsInfo: {
        icon: <FcDataRecovery />,
        label: 'Remplissage des stocks cibles des microstores',
        section: PageName.microstoreColumns,
        url: '/microstoreColumns/targets-info',
    },
    microstoreColumnTemplates: {
        icon: <FcDataConfiguration />,
        label: 'Templates de colonne microstore',
        section: PageName.microstoreColumnTemplates,
        shortLabel: 'MS Cl. Templates',
        url: '/microstoreColumnTemplates',
        navSection: NavSection.CatalogManagementSystem,
    },
    microstoreColumnTemplateDetails: {
        icon: <FcDataConfiguration />,
        label: "Détails d'un template de colonne microstore",
        section: PageName.microstoreColumnTemplates,
        url: '/microstoreColumnTemplate/:microstoreColumnTemplateId',
    },
    microstorePlanogram: {
        icon: <GrPlan />,
        label: 'Planogramme',
        section: PageName.microstoreColumns,
        url: '/planogram/:siteId',
    },
    microstoreSnapshot: {
        icon: <FcDataRecovery />,
        label: 'Snapshot Microstore',
        section: PageName.microstoreSnapshot,
        url: '/microstore-snapshot/:siteId',
    },
    mobilePopups: {
        icon: <FcDataRecovery />,
        label: 'Popups mobile',
        section: PageName.mobilePopups,
        url: '/mobilePopups',
        navSection: NavSection.CustomerManagementSystem,
    },
    notifications: {
        icon: <FcInvite />,
        label: 'Notifications',
        section: PageName.notifications,
        url: '/notifications',
        navSection: NavSection.CustomerManagementSystem,
    },
    notificationsOrganization: {
        icon: <FcInvite />,
        label: 'Notifier une organisation',
        section: PageName.notifications,
        url: '/notifications/organization',
    },
    notificationsSite: {
        icon: <FcInvite />,
        label: 'Notifier un site',
        section: PageName.notifications,
        url: '/notifications/site',
    },
    notificationsUser: {
        icon: <FcInvite />,
        label: 'Notifier un utilisateur',
        section: PageName.notifications,
        url: '/notifications/user',
    },
    opsParameters: {
        icon: <FcSettings />,
        label: 'Paramètres Ops',
        section: PageName.opsParameters,
        url: '/ops-parameters',
        navSection: NavSection.WarehouseManagementSystem,
    },
    orders: {
        icon: <FcPaid />,
        label: 'Commandes',
        section: PageName.orders,
        url: '/orders',
        navSection: NavSection.CustomerManagementSystem,
    },
    orderDetails: {
        icon: <FcPaid />,
        label: "Détails d'une commande",
        section: PageName.orders,
        url: '/order/:orderId',
    },
    organizations: {
        icon: <FcOrganization />,
        label: 'Organisations',
        section: PageName.organizations,
        url: '/organizations',
        navSection: NavSection.CustomerManagementSystem,
    },
    organizationCreate: {
        icon: <FcOrganization />,
        label: 'Créer une organisation',
        section: PageName.organizations,
        url: '/organization/create',
    },
    organizationDetails: {
        icon: <FcOrganization />,
        label: "Détails d'une organisation",
        section: PageName.organizations,
        url: '/organization/:organizationId',
    },
    payments: {
        icon: <FcMoneyTransfer />,
        label: 'Paiements',
        section: PageName.payments,
        url: '/payments',
        navSection: NavSection.CustomerManagementSystem,
    },
    paymentDetails: {
        icon: <FcMoneyTransfer />,
        label: "Détails d'un paiement",
        section: PageName.payments,
        url: '/payment/:paymentId',
    },
    polls: {
        icon: <FcBarChart />,
        label: 'Sondages',
        section: PageName.polls,
        url: '/polls',
        navSection: NavSection.CustomerManagementSystem,
    },
    pollCreate: {
        icon: <FcBarChart />,
        label: 'Créer un sondage',
        section: PageName.polls,
        url: '/poll/create',
    },
    pollDetails: {
        icon: <FcBarChart />,
        label: "Détails d'un sondage",
        section: PageName.polls,
        url: '/poll/:pollId',
    },
    products: {
        icon: <FaAppleAlt color={colors.red} />,
        label: 'Produits',
        section: PageName.products,
        url: '/products',
        navSection: NavSection.CatalogManagementSystem,
    },
    productCreate: {
        icon: <FaAppleAlt color={colors.red} />,
        label: 'Créer un produit',
        section: PageName.products,
        url: '/product/create',
    },
    productDetails: {
        icon: <FaAppleAlt color={colors.red} />,
        label: "Détails d'un produit",
        section: PageName.products,
        url: '/product/:productId',
    },
    productImport: {
        icon: <FaAppleAlt color={colors.red} />,
        label: 'Imports CSV de produit',
        section: PageName.products,
        url: '/product/imports',
    },
    productGroups: {
        icon: <FaAppleAlt color={colors.red} />,
        label: 'Regroupements de produits',
        section: PageName.productGroups,
        url: '/product-groups',
    },
    productGroupDetails: {
        icon: <FaAppleAlt color={colors.red} />,
        label: "Détails d'un regroupement de produits",
        section: PageName.productGroups,
        url: '/product-group/:productGroupId',
    },
    productArrangements: {
        icon: <FaAppleAlt color={colors.red} />,
        label: 'Gamme de produit',
        section: PageName.productArrangements,
        url: '/productArrangements',
        navSection: NavSection.CatalogManagementSystem,
    },
    productArrangementDetails: {
        icon: <FaAppleAlt color={colors.red} />,
        label: "Détails d'une gamme de produit",
        section: PageName.productArrangements,
        url: '/productArrangement/:productArrangementId',
    },
    productPriceRanges: {
        icon: <FcMoneyTransfer />,
        label: 'Gammes de prix',
        section: PageName.productPriceRanges,
        url: '/productPriceRanges',
        navSection: NavSection.CatalogManagementSystem,
    },
    productPriceRangeCreate: {
        icon: <FcMoneyTransfer />,
        label: 'Créer une gamme de prix',
        section: PageName.productPriceRanges,
        url: '/productPriceRange/create',
    },
    productPriceRangeDetails: {
        icon: <FcMoneyTransfer />,
        label: "Détails d'une gamme de prix",
        section: PageName.productPriceRanges,
        url: '/productPriceRange/:rangeId',
    },
    productSwitches: {
        icon: <HiOutlineSwitchHorizontal />,
        label: 'Switch de produit (béta)',
        section: PageName.productSwitches,
        url: '/product-switches',
        navSection: NavSection.WarehouseManagementSystem,
    },
    quizzes: {
        icon: <BsFillQuestionDiamondFill />,
        label: 'Quiz',
        section: PageName.quizzes,
        url: '/quizzes',
        navSection: NavSection.CustomerManagementSystem,
    },
    quizCreate: {
        icon: <BsFillQuestionDiamondFill />,
        label: 'Créer un quiz',
        section: PageName.quizzes,
        url: '/quiz/create',
    },
    quizDetails: {
        icon: <BsFillQuestionDiamondFill />,
        label: "Détails d'un quiz",
        section: PageName.quizzes,
        url: '/quiz/:quizId',
    },
    ritualCreate: {
        icon: <FcPlanner />,
        label: 'Créer un rituel',
        section: PageName.rituals,
        url: '/ritual/create',
    },
    ritualDetails: {
        icon: <FcPlanner />,
        label: "Détails d'un rituel",
        section: PageName.rituals,
        url: '/ritual/:ritualId',
    },
    rituals: {
        icon: <FcPlanner />,
        label: 'Rituels',
        section: PageName.rituals,
        url: '/rituals',
        navSection: NavSection.CustomerManagementSystem,
    },
    ritualTemplates: {
        icon: <FcStackOfPhotos />,
        label: 'Rituels (Modèles)',
        section: PageName.ritualTemplates,
        url: '/ritualTemplates',
        navSection: NavSection.CustomerManagementSystem,
    },
    settings: {
        icon: <FcSettings />,
        label: 'Paramètres',
        section: PageName.settings,
        url: '/settings',
    },
    sites: {
        icon: <FcShop />,
        label: 'Sites',
        section: PageName.sites,
        url: '/sites',
        navSection: NavSection.CustomerManagementSystem,
    },
    siteCreate: {
        icon: <FcShop />,
        label: 'Créer un site',
        section: PageName.sites,
        url: '/site/create',
    },
    siteDetails: {
        icon: <FcShop />,
        label: "Détails d'un site",
        section: PageName.sites,
        url: '/site/:siteId',
    },
    siteExternalInventory: {
        icon: <FcShop />,
        label: 'Ventes Connues Site',
        section: PageName.siteExternalInventory,
        url: '/site-known-sales-inventory',
        navSection: NavSection.WarehouseManagementSystem,
    },
    siteSetups: {
        icon: <RiFridgeFill />,
        label: 'Rangement des sites',
        shortLabel: 'Rangement Sites',
        section: PageName.siteSetups,
        url: '/site-setups',
        navSection: NavSection.WarehouseManagementSystem,
    },
    siteSetupDetails: {
        icon: <RiFridgeFill />,
        label: 'Rangement du site',
        section: PageName.siteSetups,
        url: '/site-setup/:siteId/:siteSetupId',
    },
    subventions: {
        icon: <FaHandshake color={colors.yellow} />,
        label: 'Subventions',
        section: PageName.subventions,
        url: '/subventions',
        navSection: NavSection.CustomerManagementSystem,
    },
    subventionCreate: {
        icon: <FaHandshake color={colors.yellow} />,
        label: 'Créer une subvention',
        section: PageName.subventions,
        url: '/subvention/create',
    },
    subventionDetails: {
        icon: <FaHandshake color={colors.yellow} />,
        label: "Détails d'une subvention",
        section: PageName.subventions,
        url: '/subvention/:subventionId',
    },
    suppliers: {
        icon: <FaHandshake color={colors.yellow} />,
        label: 'Fournisseurs',
        section: PageName.suppliers,
        url: '/suppliers',
        navSection: NavSection.CatalogManagementSystem,
    },
    supplierCreate: {
        icon: <FaHandshake color={colors.yellow} />,
        label: 'Créer un fournisseur',
        section: PageName.suppliers,
        url: '/supplier/create',
    },
    supplierDetails: {
        icon: <FaHandshake color={colors.yellow} />,
        label: "Détail d'un fournisseur",
        section: PageName.suppliers,
        url: '/supplier/:supplierId',
    },
    supplyOrders: {
        icon: <FaTruckLoading color={colors.blue} />,
        label: 'Achats',
        section: PageName.supplyOrders,
        url: '/supplyOrders',
        navSection: NavSection.WarehouseManagementSystem,
    },
    supplyOrderDetails: {
        icon: <FaTruckLoading color={colors.blue} />,
        label: "Détails d'un achat",
        section: PageName.supplyOrders,
        url: '/supplyOrder/:supplyOrderId',
    },
    supplyOrderConfirmation: {
        icon: <FaHandshake color={colors.blue} />,
        label: "Confirmation de commande d'achat",
        section: PageName.supplyOrderConfirmation,
        url: '/supplyOrderConfirmation/:token',
    },
    supplierDiscounts: {
        icon: <FaHandshake color={colors.yellow} />,
        label: 'Remises fournisseurs',
        section: PageName.supplierDiscounts,
        url: '/supplierDiscounts',
        navSection: NavSection.CatalogManagementSystem,
    },
    stockTransfers: {
        icon: <FcNeutralTrading />,
        label: 'Transferts de stock',
        section: PageName.stockTransfers,
        url: '/stockTransfers',
        navSection: NavSection.WarehouseManagementSystem,
    },
    stockTransferCreate: {
        icon: <FcNeutralTrading />,
        label: 'Créer un transfert de stock',
        section: PageName.stockTransfers,
        url: '/stockTransfer/create',
    },
    stockTransferDetails: {
        icon: <FcNeutralTrading />,
        label: "Détais d'un transfert de stock",
        section: PageName.stockTransfers,
        url: '/stockTransfer/:stockTransferId',
    },
    stockTransferReturns: {
        icon: <FcNeutralTrading />,
        label: "Consulter les retours à l'entrepôt",
        section: PageName.stockTransfers,
        url: '/stockTransfer/returns',
    },
    stocks: {
        icon: <FcAreaChart />,
        label: 'Stocks',
        section: PageName.stocks,
        url: '/stocks',
        navSection: NavSection.WarehouseManagementSystem,
    },
    stockVariationsByDay: {
        icon: <FcAreaChart />,
        label: 'Variations de stock par jour',
        section: PageName.stockVariationsByDay,
        url: '/stock-variations-by-day',
    },
    siteProductStocks: {
        icon: <FcAreaChart />,
        label: 'Historique de stock du produit dans site',
        section: PageName.stocks,
        url: '/site/:siteId/stocks/:productId',
    },
    siteStocks: {
        icon: <FcAreaChart />,
        label: 'Site Stocks',
        section: PageName.stocks,
        url: '/site/:siteId/stocks',
    },
    siteInventoryStockCreate: {
        icon: <FcAreaChart />,
        label: 'Inventorier un produit',
        section: PageName.stocks,
        url: '/siteInventoryStock/create',
    },
    stockVariationCreate: {
        icon: <FcAreaChart />,
        label: 'Créer une variation de stock',
        section: PageName.stocks,
        url: '/stockVariation/create',
    },
    stockVariationMultiCreate: {
        icon: <FcAreaChart />,
        label: 'Créer des variations de stock',
        section: PageName.stocks,
        url: '/stockVariation/create/multi',
    },
    tags: {
        icon: <FcDonate />,
        label: 'Tags',
        section: PageName.tags,
        url: '/tags',
        navSection: NavSection.CatalogManagementSystem,
    },
    tagCreate: {
        icon: <FcDonate />,
        label: 'Tag',
        section: PageName.tags,
        url: '/tag/create',
    },
    tagUpdate: {
        icon: <FcDonate />,
        label: 'Tag',
        section: PageName.tags,
        url: '/tag/:tagId',
    },
    termsRecords: {
        icon: <FcNews />,
        label: 'CGV',
        section: PageName.termsRecords,
        url: '/termsRecords',
        navSection: NavSection.CustomerManagementSystem,
    },
    termsRecordCreate: {
        icon: <FcNews />,
        label: 'Créer une nouvelle version des CGV',
        section: PageName.termsRecords,
        url: '/termsRecord/create',
    },
    thermometers: {
        icon: <FaThermometerHalf />,
        label: 'Thermomètres',
        section: PageName.thermometers,
        url: '/thermometers',
        navSection: NavSection.WarehouseManagementSystem,
    },
    transactions: {
        icon: <FcCurrencyExchange />,
        label: 'Transactions',
        section: PageName.transactions,
        url: '/transactions',
        navSection: NavSection.CustomerManagementSystem,
    },
    transactionCreate: {
        icon: <FcCurrencyExchange />,
        label: 'Créer une transaction',
        section: PageName.transactions,
        url: '/transaction/create',
    },
    transactionDetails: {
        icon: <FcCurrencyExchange />,
        label: "Détails d'une transaction",
        section: PageName.transactions,
        url: '/transaction/:transactionId',
    },
    totemClosedDays: {
        icon: <FcCancel />,
        label: 'Jours de fermeture Totem',
        section: PageName.totemClosedDays,
        url: '/totem-closed-days',
        navSection: NavSection.CustomerManagementSystem,
    },
    totemClosedReceptionDays: {
        icon: <FcCancel />,
        label: 'Jours de non-réception fresh Totem',
        section: PageName.totemClosedReceptionDays,
        url: '/totem-closed-reception-days',
        navSection: NavSection.WarehouseManagementSystem,
    },
    urbantzRoundPresets: {
        icon: <IoBicycleOutline />,
        label: 'Tournées prédéfinies',
        section: PageName.urbantzRoundPresets,
        url: '/urbantz-round-presets',
        navSection: NavSection.WarehouseManagementSystem,
    },
    users: {
        icon: <FcConferenceCall />,
        label: 'Utilisateurs',
        section: PageName.users,
        url: '/users',
        navSection: NavSection.CustomerManagementSystem,
    },
    userCreateToteam: {
        icon: <FcConferenceCall />,
        label: 'Créer un utilisateur Toteam',
        section: PageName.users,
        url: '/user/create-toteam',
    },
    userDetails: {
        icon: <FcConferenceCall />,
        label: "Détails d'un utilisateur",
        section: PageName.users,
        url: '/user/:userId',
    },
    senseiBasket: {
        icon: <FcConferenceCall />,
        label: 'Panier Sensei',
        section: PageName.users,
        url: '/sensei-basket/:basketId',
    },
    warehouseDeliveries: {
        icon: <FcInTransit />,
        label: 'Livraisons-entrepot',
        section: PageName.warehouseDeliveries,
        url: '/warehouseDeliveries',
        navSection: NavSection.WarehouseManagementSystem,
    },
    warehouseDeliveryDetails: {
        icon: <FcInTransit />,
        label: 'Livraisons-entrepot',
        section: PageName.warehouseDeliveries,
        url: '/WarehouseDelivery/:warehouseDeliveryId',
    },
    warehouseReturns: {
        icon: <RiFridgeFill />,
        label: 'Retours Entrepôt',
        section: PageName.warehouseReturns,
        url: '/warehouse-returns',
        navSection: NavSection.WarehouseManagementSystem,
    },
    warehouseReturnDetails: {
        icon: <RiFridgeFill />,
        label: 'Retour Entrepôt',
        section: PageName.warehouseReturns,
        url: '/warehouse-return/:warehouseReturnId',
    },
};

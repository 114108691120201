import React from 'react';
import styled from 'styled-components';

import { SETUP_INSTRUCTION_TYPE_LABELS } from 'pages/SiteSetups/constants/types';

import { GET_MICROSTORE_PRODUCTS_getMicrostoreProducts } from 'data/queries/__generated__/GET_MICROSTORE_PRODUCTS';

import { RemovedProductsSection } from './RemovedProductsSection';

import { SetupInstructionFragment_RemoveProductsInstruction } from 'data/fragments/__generated__/SetupInstructionFragment';

export const ColumnRemoveProductsSection = ({
    instruction,
    inventoryDate,
    products,
}: {
    instruction: SetupInstructionFragment_RemoveProductsInstruction;
    inventoryDate: string;
    products: GET_MICROSTORE_PRODUCTS_getMicrostoreProducts[];
}) => {
    return (
        <Container>
            <Title>{SETUP_INSTRUCTION_TYPE_LABELS[instruction.type]}</Title>
            <Content>
                <ColumnInventoryContainer />
                <RemovedProductsSection
                    removedProducts={instruction.removedProducts}
                    inventoryDate={inventoryDate}
                    products={products}
                />
            </Content>
        </Container>
    );
};

const Container = styled.div``;

const Content = styled.div`
    display: flex;
    margin-top: 20px;
`;

const Title = styled.div`
    font-size: 18px;
    font-weight: bold;
    text-align: center;
`;

const ColumnInventoryContainer = styled.div`
    width: 30%;
`;

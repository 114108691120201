import React from 'react';

import { CommonStatusTag } from 'components/CommonStatusTag';

import { colors } from 'constants/colors';
import { EquipmentState } from 'data/__generated__';

export const EQUIPMENT_STATES_LABELS = {
    Archived: 'Archivé',
    Regular: 'Régulier',
    Testing: 'En Test',
};

const getColorsFromState = (state: EquipmentState) => {
    const { green, pantinGrey, pureWhite, black } = colors;

    switch (state) {
        case EquipmentState.Testing:
            return { backgroundColor: black, labelColor: pureWhite };
        case EquipmentState.Regular:
            return { backgroundColor: green, labelColor: pureWhite };
        case EquipmentState.Archived:
            return { backgroundColor: pantinGrey, labelColor: pureWhite };
    }
};

export const StatusTag = ({ state, showHeading }: { state: EquipmentState; showHeading?: boolean }) => {
    const { backgroundColor, labelColor } = getColorsFromState(state);
    return (
        <CommonStatusTag
            backgroundColor={backgroundColor}
            labelColor={labelColor}
            label={EQUIPMENT_STATES_LABELS[state]}
            showHeading={showHeading}
        />
    );
};

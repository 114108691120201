import gql from 'graphql-tag';
import { SUPPLIER_ORDERING_TIME_FOR_DAY_FRAGMENT } from './supplier';

const BAD_EXPIRY_DATE_ISSUE_FRAGMENT = gql`
    fragment BadExpiryDateIssueFragment on BadExpiryDateIssue {
        expiryDate
        minSupplierExpiryDays
        productId
        quantity
        type
    }
`;

const BROKEN_QUANTITY_ISSUE_FRAGMENT = gql`
    fragment BrokenQuantityIssueFragment on BrokenQuantityIssue {
        brokenQuantity
        productId
        type
    }
`;

const DIFFERENT_PACKAGING_ISSUE_FRAGMENT = gql`
    fragment DifferentPackagingIssueFragment on DifferentPackagingIssue {
        expectedPackaging
        productId
        receivedPackaging
        type
    }
`;

const DIFFERENT_QUANTITY_ISSUE_FRAGMENT = gql`
    fragment DifferentQuantityIssueFragment on DifferentQuantityIssue {
        expectedQuantity
        productId
        receivedQuantity
        type
    }
`;

const NEW_BARCODE_ISSUE_FRAGMENT = gql`
    fragment NewBarcodeIssueFragment on NewBarcodeIssue {
        barcode
        productId
        type
    }
`;

const NOT_RECEIVED_ISSUE_FRAGMENT = gql`
    fragment NotReceivedIssueFragment on NotReceivedIssue {
        productId
        type
    }
`;

const UNKNOWN_PRODUCT_ISSUE_FRAGMENT = gql`
    fragment UnknownProductIssueFragment on UnknownProductIssue {
        barcode
        description
        type
    }
`;

const WRONG_PRODUCT_ISSUE_FRAGMENT = gql`
    fragment WrongProductIssueFragment on WrongProductIssue {
        barcode
        productId
        type
    }
`;

export const SUPPLY_ORDER_PRODUCT_ISSUE_FRAGMENT = gql`
    fragment SupplyOrderProductIssueFragment on SupplyOrderProductIssue {
        ... on BadExpiryDateIssue {
            ...BadExpiryDateIssueFragment
        }
        ... on BrokenQuantityIssue {
            ...BrokenQuantityIssueFragment
        }
        ... on DifferentPackagingIssue {
            ...DifferentPackagingIssueFragment
        }
        ... on DifferentQuantityIssue {
            ...DifferentQuantityIssueFragment
        }
        ... on NewBarcodeIssue {
            ...NewBarcodeIssueFragment
        }
        ... on NotReceivedIssue {
            ...NotReceivedIssueFragment
        }
        ... on UnknownProductIssue {
            ...UnknownProductIssueFragment
        }
        ... on WrongProductIssue {
            ...WrongProductIssueFragment
        }
    }
    ${BAD_EXPIRY_DATE_ISSUE_FRAGMENT}
    ${BROKEN_QUANTITY_ISSUE_FRAGMENT}
    ${DIFFERENT_PACKAGING_ISSUE_FRAGMENT}
    ${DIFFERENT_QUANTITY_ISSUE_FRAGMENT}
    ${NEW_BARCODE_ISSUE_FRAGMENT}
    ${NOT_RECEIVED_ISSUE_FRAGMENT}
    ${UNKNOWN_PRODUCT_ISSUE_FRAGMENT}
    ${WRONG_PRODUCT_ISSUE_FRAGMENT}
`;

export const SUPPLY_ORDER_FRAGMENT = gql`
    fragment SupplyOrderFragment on SupplyOrder {
        _id
        state
        createdAt
        orderDate
        orderingTermsValidity {
            isValid
            deliveryPrice
        }
        dateDeliveryScheduled
        dateDelivery
        scheduledDeliveryDateValidity
        number
        priceHT
        priceTTC
        supplier {
            _id
            name
            orderingTimes {
                monday {
                    ...OrderingTimeForDayFragment
                }
                tuesday {
                    ...OrderingTimeForDayFragment
                }
                wednesday {
                    ...OrderingTimeForDayFragment
                }
                thursday {
                    ...OrderingTimeForDayFragment
                }
                friday {
                    ...OrderingTimeForDayFragment
                }
                saturday {
                    ...OrderingTimeForDayFragment
                }
                sunday {
                    ...OrderingTimeForDayFragment
                }
            }
            orderingProductTerms {
                minQuantity
                productId
            }
            orderingTerms {
                deliveryPrice
                minRequirements {
                    unit
                    value
                }
            }
        }
        weeklyMenusStartDate
    }
    ${SUPPLIER_ORDERING_TIME_FOR_DAY_FRAGMENT}
`;

export const SUPPLY_ORDER_WITH_SUPPLIER_PRODUCTS_INFO_FRAGMENT = gql`
    fragment SupplyOrderWithSupplierProductsInfoFragment on SupplyOrder {
        _id
        comment
        createdAt
        dateDelivery
        dateDeliveryScheduled
        description
        hourDelivery
        issue
        issues {
            ...SupplyOrderProductIssueFragment
        }
        justInTime
        number
        orderDate
        orderingTermsValidity {
            isValid
            deliveryPrice
        }
        scheduledDeliveryDateHistory {
            scheduledDeliveryDate
            createdAt
        }
        scheduledDeliveryDateValidity
        priceHT
        priceHTInvoice
        priceTTCInvoice
        products {
            _id
            brand
            colis
            confirmedQuantity
            confirmedQuantityHistory {
                createdAt
                quantity
            }
            conditionning
            isToBeArchived
            name
            price
            productReceivedDetails {
                brokenItems
                colis
                conditionning
                quantity
                expiryDate
            }
            quantity
            quantityGenerated
            quantityGeneratedForMenus
            supplierShortageInfo {
                inShortage
                returnDate
            }
            stock
            storedAt
            supplyOrderId
            availableStock
            tva
            unitPrice
            volume
        }
        soldOutRiskProductIds
        spreadsheetId
        spreadsheetName
        state
        stateHistory {
            createdAt
            state
            createdBy
            createdByName
        }
        supplier {
            _id
            clientCode
            frequency
            name
            orderMode
            orderEmails
            productsInfoWithStock {
                _id
                availableStock
                brand
                conditionning
                fullname
                imageUrl
                isToBeArchived
                name
                packagesPerBatch
                stock
                supplierShortageInfo {
                    inShortage
                    returnDate
                }
                tva
                unitPrice
                volume
            }
            orderingTimes {
                monday {
                    ...OrderingTimeForDayFragment
                }
                tuesday {
                    ...OrderingTimeForDayFragment
                }
                wednesday {
                    ...OrderingTimeForDayFragment
                }
                thursday {
                    ...OrderingTimeForDayFragment
                }
                friday {
                    ...OrderingTimeForDayFragment
                }
                saturday {
                    ...OrderingTimeForDayFragment
                }
                sunday {
                    ...OrderingTimeForDayFragment
                }
            }
            orderingProductTerms {
                minQuantity
                productId
            }
            orderingTerms {
                deliveryPrice
                minRequirements {
                    unit
                    value
                }
            }
        }
        temperature
        totalQuantity
        totalQuantityReceived
        totalVolume
        totalWeight
        updatedAt
        weeklyMenusStartDate
    }
    ${SUPPLY_ORDER_PRODUCT_ISSUE_FRAGMENT}
    ${SUPPLIER_ORDERING_TIME_FOR_DAY_FRAGMENT}
`;

export const SUPPLY_ORDER_PRODUCT_STOCK_VARIATION_FRAGMENT = gql`
    fragment SupplyOrderProductStockVariationFragment on ProductStockVariation {
        computedStockThreshold
        createdAt
        forecasts {
            forecastsDaysToIgnoreForOrders
            forecastsFreefoodPunctual
            forecastsFreefoodRecurring
            forecastsStoreDry
            lastUpdateDateFreefoodPunctual
            lastUpdateDateFreefoodRecurring
            lastUpdateDateStoreDry
        }
        initialStock
        microstoreDetails {
            missingStockByDay
            targetStockByDay
            targetStockDetails {
                locationTarget
                planogramDate
                siteName
                usedLocationTarget
            }
            totalStock
        }
        missingStockBetweenDeliveries
        operations {
            punctualOrdersNotPrepared
            recurrentOrdersNotPrepared
            validatedStockTransferPrepared
            validatedStockTransferNotPrepared
        }
        outboundFreefoodFloatStockVariation
        outboundStoreFloatStockVariation
        outboundStockVariation
        productId
        productSwitchDetails {
            variations
        }
        stockUpdateDayIndexIfOrderedOnNextOrderingDay
        stockUpdateDayIndexIfOrderedToday
        stockVariation
        stockVariationFromSupplyOrders
        supplyOrderId
    }
`;

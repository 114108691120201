import React from 'react';

import { useQuery } from '@apollo/client';
import styled from 'styled-components';

import { GET_PAGINATED_FILTERED_SUPPLIERS_QUERY } from 'data/queries/supplier';

import { FieldToDisplay, ListView } from 'components/ListView';
import { Loader } from 'components/Loader';
import {
    GET_PAGINATED_FILTERED_SUPPLIERS,
    GET_PAGINATED_FILTERED_SUPPLIERSVariables,
} from 'data/queries/__generated__/GET_PAGINATED_FILTERED_SUPPLIERS';

import { SupplierState } from 'data/__generated__';

type FormattedSupplierType = {
    _id: string;
    name: string;
};

export const SuppliersList = ({
    filterString,
    shouldHideArchived,
}: {
    filterString: string;
    shouldHideArchived: boolean;
}) => {
    const {
        loading: suppliersLoading,
        data: suppliersData,
        error: suppliersError,
        fetchMore: fetchMoreSuppliers,
    } = useQuery<GET_PAGINATED_FILTERED_SUPPLIERS, GET_PAGINATED_FILTERED_SUPPLIERSVariables>(
        GET_PAGINATED_FILTERED_SUPPLIERS_QUERY,
        {
            variables: { offset: 0, filterString, shouldHideArchived },
            fetchPolicy: 'cache-and-network',
        },
    );

    if (suppliersLoading && !suppliersData) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (suppliersError || !suppliersData) {
        throw new Error('Une erreur est survenue lors de la récupération des fournisseurs');
    }

    const {
        paginatedFilteredSuppliers: { hasMore, suppliers },
    } = suppliersData;

    const sortedSuppliers = [...suppliers]
        .sort((supplierA, supplierB) => (supplierA.name < supplierB.name ? -1 : 1))
        .filter((supplier) => (shouldHideArchived ? supplier.state !== SupplierState.Archived : true));

    const loadMore = async () => {
        await fetchMoreSuppliers({
            variables: {
                offset: suppliers.length,
                filterString,
                shouldHideArchived,
            },
        });
    };

    const SUPPLIER_FIELDS_TO_DISPLAY: FieldToDisplay<FormattedSupplierType>[] = [
        {
            fieldName: 'name',
            label: 'Nom',
        },
        {
            fieldName: '_id',
            label: 'ID',
        },
    ];

    return (
        <ListView<FormattedSupplierType>
            fieldsToDisplay={SUPPLIER_FIELDS_TO_DISPLAY}
            items={sortedSuppliers}
            keyExtractor={(item) => item._id}
            loadMore={loadMore}
            hasMore={hasMore}
            linkBasePath="/supplier/"
        />
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

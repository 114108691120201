import React, { Dispatch, SetStateAction } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import {
    GET_OFF_DATA_OFFProductData_productNutritionData,
    GET_OFF_DATA_OFFProductData_productNutritionData_nutrition,
} from 'data/queries/__generated__/GET_OFF_DATA';
import { ALLERGENS } from 'pages/Products/constants/nutrition';

import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

type OFFData = {
    [key: string]: any;
};

export const OFFNutritionConfirmationPopup = ({
    fetchedOFFData,
    setFetchedOFFData,
}: {
    fetchedOFFData: OFFData;
    setFetchedOFFData: Dispatch<SetStateAction<GET_OFF_DATA_OFFProductData_productNutritionData | null>>;
}) => {
    const { setValue } = useFormContext();
    const generateNutrientsList = (nutritionInfo: GET_OFF_DATA_OFFProductData_productNutritionData_nutrition) => {
        function getNutritionInfo(nutrientName: string) {
            switch (nutrientName) {
                case 'carbohydrates':
                    return nutritionInfo.carbohydrates;
                case 'energyKcal':
                    return nutritionInfo.energyKcal;
                case 'fat':
                    return nutritionInfo.fat;
                case 'fiber':
                    return nutritionInfo.fiber;
                case 'proteins':
                    return nutritionInfo.proteins;
                case 'saturatedFat':
                    return nutritionInfo.saturatedFat;
                case 'sodium':
                    return nutritionInfo.sodium;
                case 'sugars':
                    return nutritionInfo.sugars;
                default:
                    return '';
            }
        }

        return (
            <ItemList>
                {Object.keys(nutritionInfo)?.map((key) => {
                    if (key !== '__typename') {
                        return (
                            <li key={key}>
                                <Key>{key}:</Key> {getNutritionInfo(key)} g
                            </li>
                        );
                    }
                    return null;
                })}
            </ItemList>
        );
    };

    function getNutritionDBFields(OFFNutrientName: string) {
        switch (OFFNutrientName) {
            case 'energyKcal':
                return 'caloriesKCal';
            case 'fat':
                return 'totalFat';
            case 'fiber':
                return 'fibers';
            case 'proteins':
                return 'protein';
            case 'saturatedFat':
                return 'sturatedFat';
            default:
                return OFFNutrientName;
        }
    }

    const applyNutritionData = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        Object.keys(fetchedOFFData)?.forEach((key: string) => {
            if (key !== '__typename') {
                if (['allergens', 'traces'].includes(key)) {
                    if (key === 'allergens') {
                        const allergens = fetchedOFFData[key];
                        ALLERGENS.forEach(({ name }: { name: string }) => {
                            setValue(`allergen.${name}`, allergens.includes(name), { shouldDirty: true });
                        });
                    } else {
                        fetchedOFFData[key] &&
                            setValue('allergen.canContain', fetchedOFFData[key], { shouldDirty: true });
                    }
                } else if (['ingredients', 'nutriscore'].includes(key)) {
                    fetchedOFFData[key] && setValue(key, fetchedOFFData[key]);
                } else if (key === 'nutrition') {
                    const nutrition = fetchedOFFData[key];
                    Object.keys(nutrition)?.forEach((nutrient) => {
                        setValue(`nutrition.${getNutritionDBFields(nutrient)}`, nutrition[nutrient], {
                            shouldDirty: true,
                        });
                    });
                }
            }
        });
        setFetchedOFFData(null);
    };

    return (
        <Container>
            <Content>
                <Title>Données nutritionnelles trouvées</Title>
                <DataDisplay>
                    {Object.keys(fetchedOFFData)?.map((key: string) => {
                        if (key !== '__typename') {
                            let value;
                            if (['allergens', 'traces'].includes(key)) {
                                const arrayValues: string[] = fetchedOFFData[key];
                                value = arrayValues.toString();
                            } else if (['ingredients', 'nutriscore'].includes(key)) {
                                value = fetchedOFFData[key];
                            } else if (key === 'nutrition') {
                                return (
                                    <>
                                        <Key>{key}:</Key>
                                        {generateNutrientsList(fetchedOFFData[key])}
                                    </>
                                );
                            }
                            return (
                                <DataRow>
                                    <Key>{key}:</Key> {value || '-'}
                                </DataRow>
                            );
                        }
                        return null;
                    })}
                </DataDisplay>
                <Row>
                    <ConfirmationButtons onClick={() => setFetchedOFFData(null)}>Annuler</ConfirmationButtons>
                    <ConfirmationButtons onClick={applyNutritionData}>Appliquer ces données</ConfirmationButtons>
                </Row>
            </Content>
        </Container>
    );
};

const ItemList = styled.ul`
    margin: 0 0 0 15px;
`;

const DataDisplay = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const Key = styled.span`
    font-weight: 800;
    text-transform: capitalize;
    margin-right: 5px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
`;

const DataRow = styled(Row)`
    margin: 5px;
`;

const ConfirmationButtons = styled(TotemPrimaryButton)`
    margin: 10px 0 0 10px;
`;

const Title = styled.div`
    font-size: 22px;
    font-weight: 800;
`;

const Content = styled.div`
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    width: 500px;
    border-radius: ${({ theme }) => theme.borderRadius};
`;

const Container = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
`;

import gql from 'graphql-tag';

import { DETAILED_RITUAL_FRAGMENT } from './../fragments/ritual';

export const CREATE_RITUAL_MUTATION = gql`
    mutation CREATE_RITUAL($createRitualInput: CreateRitualInput!) {
        createRitual(createRitualInput: $createRitualInput) {
            success
            errors
            createdRitual {
                ...DetailedRitualFragment
            }
        }
    }
    ${DETAILED_RITUAL_FRAGMENT}
`;

export const UPDATE_RITUAL_MUTATION = gql`
    mutation UPDATE_RITUAL($ritualId: ID!, $updateInput: UpdateRitualInput!) {
        updateRitual(ritualId: $ritualId, updateInput: $updateInput) {
            success
            errors
            updatedRitual {
                ...DetailedRitualFragment
            }
        }
    }
    ${DETAILED_RITUAL_FRAGMENT}
`;

export const UPDATE_RITUAL_STATE_MUTATION = gql`
    mutation UPDATE_RITUAL_STATE($ritualId: ID!, $stateToSet: RitualState!) {
        updateRitualState(ritualId: $ritualId, stateToSet: $stateToSet) {
            success
            errors
            updatedRitual {
                ...DetailedRitualFragment
            }
        }
    }
    ${DETAILED_RITUAL_FRAGMENT}
`;

import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { useForm, FormProvider } from 'react-hook-form';

import { toast } from 'react-toastify';
import { FaSave } from 'react-icons/fa';
import styled from 'styled-components';

import { PAGES } from 'constants/pages';
import { GET_DETAILED_EQUIPMENT_QUERY } from 'data/queries/equipment';
import { GET_DETAILED_EQUIPMENT } from 'data/queries/__generated__/GET_DETAILED_EQUIPMENT';
import { UPDATE_EQUIPMENT_MUTATION } from 'data/mutations/equipment';
import { UPDATE_EQUIPMENT, UPDATE_EQUIPMENTVariables } from 'data/mutations/__generated__/UPDATE_EQUIPMENT';

import { Header, HeaderTitle } from 'components/Header';
import { Loader, LoaderModeType } from 'components/Loader';
import { PageTitle } from 'components/PageTitle';

import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { EquipmentFormContent } from './components/EquipmentFormContent';
import { StatesSection } from './components/StatesSection';

type FormData = {
    name: string;
    brand: string;
    volume: string;
    price: number;
    description: string;
    hidden: boolean;
    height: number;
    width: number;
    length: number;
    weight: number;
};

export const EquipmentDetails = () => {
    const { equipmentId = '' } = useParams<{
        equipmentId: string;
    }>();

    const {
        loading: equipmentLoading,
        data: equipmentData,
        error: equipmentError,
    } = useQuery<GET_DETAILED_EQUIPMENT>(GET_DETAILED_EQUIPMENT_QUERY, {
        variables: { equipmentId },
    });

    const [updateEquipment, { loading: updateEquipmentLoading }] = useMutation<
        UPDATE_EQUIPMENT,
        UPDATE_EQUIPMENTVariables
    >(UPDATE_EQUIPMENT_MUTATION);

    const methods = useForm<FormData>();
    const {
        handleSubmit,
        formState: { isDirty },
        reset,
    } = methods;

    if (equipmentLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (!equipmentData || equipmentError) {
        throw new Error('Une erreur est survenue lors de la récupération du rituel');
    }

    const onSubmit = handleSubmit(async (fields) => {
        const { data } = await updateEquipment({
            variables: {
                equipmentId,
                updateFields: fields,
            },
        });
        if (data) {
            const {
                updateEquipment: { equipment, success, error },
            } = data;
            if (success && equipment) {
                toast.success('Le rituel a bien été mis à jour !');
                reset(fields);
            } else {
                if (error) {
                    toast.error(error, { autoClose: false });
                } else {
                    throw Error("Une erreur inconnue s'est produite");
                }
            }
        } else {
            throw Error("Une erreur inconnue s'est produite");
        }
    });

    const { detailedEquipment: equipment } = equipmentData;

    if (!equipment) {
        throw new Error("Une erreur est survenue lors de la récupération d'équipement");
    }

    return (
        <Container>
            <FormProvider {...methods}>
                <Form onSubmit={onSubmit}>
                    <Header>
                        <HeaderTitle>
                            <PageTitle page={PAGES.equipmentDetails} />
                        </HeaderTitle>
                        <CTAsContainer>
                            <TotemPrimaryButton type="submit" minWidth="78px" disabled={!isDirty}>
                                {updateEquipmentLoading ? (
                                    <Loader size="18px" mode={LoaderModeType.Spin} />
                                ) : (
                                    <>
                                        <FaSave size={13} />
                                        <SaveLabel>Mettre à jour</SaveLabel>
                                    </>
                                )}
                            </TotemPrimaryButton>
                            <Link to="/equipments">
                                <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                            </Link>
                        </CTAsContainer>
                    </Header>
                    <Content>
                        <ScrollableContent>
                            <EquipmentFormContent equipment={equipment} />
                            <StatesSection equipment={equipment} isDirty={isDirty} />
                        </ScrollableContent>
                    </Content>
                </Form>
            </FormProvider>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex: 1;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
`;

const CTAsContainer = styled.div`
    display: flex;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const SaveLabel = styled.span`
    margin-left: 5px;
`;

const Content = styled.div`
    flex: 1;
    overflow: hidden;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const ScrollableContent = styled.div`
    padding: 15px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
`;

import React from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { PAGES } from '../../constants/pages';

import { PageTitle } from 'components/PageTitle';
import { Header, HeaderTitle } from 'components/Header';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { MessagesSetsList } from 'pages/MessagesSets/MessagesSetsList';

export const MessagesSet = () => {
    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.messagesSets} />
                </HeaderTitle>
                <Link to={PAGES.messagesSetsCreate.url}>
                    <TotemPrimaryButton>Créer une série</TotemPrimaryButton>
                </Link>
            </Header>
            <Content>
                <MessagesSetsList />
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    flex: 1;
    overflow: hidden;
`;

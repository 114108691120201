import { gql } from '@apollo/client';
import { SUPPLIER_DISCOUNT_FRAGMENT } from 'data/fragments/supplierDiscount';

export const GET_SUPPLIER_DISCOUNTS_QUERY = gql`
    query SupplierDiscounts {
        supplierDiscounts {
            ...SupplierDiscountFragment
        }
    }
    ${SUPPLIER_DISCOUNT_FRAGMENT}
`;

import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import { FaSave } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { PAGES } from 'constants/pages';

import { Header, HeaderTitle } from 'components/Header';
import { FormValue, MessagesSetFormContent } from './components/MessagesSetFormContent';
import { Loader, LoaderModeType } from 'components/Loader';
import { PageTitle } from 'components/PageTitle';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

import { MessagesSetState } from 'data/__generated__';
import { CREATE_MESSAGES_SET, CREATE_MESSAGES_SETVariables } from 'data/mutations/__generated__/CREATE_MESSAGES_SET';
import { CREATE_MESSAGES_SET_MUTATION } from 'data/mutations/messagesSet';

export const MessagesSetCreate = () => {
    const navigate = useNavigate();
    const [createMessagesSet, { loading: createMessagesSetLoading }] = useMutation<
        CREATE_MESSAGES_SET,
        CREATE_MESSAGES_SETVariables
    >(CREATE_MESSAGES_SET_MUTATION);

    const [formValue, setFormValue] = useState<FormValue>({
        name: '',
        messages: [],
    });

    async function onSubmit() {
        const variables = {
            messagesSetInput: {
                ...formValue,
                messages: formValue.messages.map((message) => {
                    return { ...message, redirectUrl: message.redirectUrl || null };
                }),
                state: MessagesSetState.Regular,
            },
        };
        const { data } = await createMessagesSet({ variables });

        if (data) {
            const {
                createMessagesSet: { createdMessagesSet, success, errors },
            } = data;
            if (success && createdMessagesSet) {
                toast.success('La série a bien été créée !');
                navigate(`/messagesSet/${createdMessagesSet._id}`);
            }
            if (errors) {
                errors.forEach((error) => {
                    toast.error(error, { autoClose: false });
                });

                if (errors.length > 1) {
                    toast.info('Cliquez pour fermer toutes les notifications', {
                        autoClose: false,
                        onClick: () => toast.dismiss(),
                    });
                }
            }
        } else {
            throw Error("Une erreur inconnue s'est produite");
        }
    }

    return (
        <Container>
            <Form>
                <Header>
                    <HeaderTitle>
                        <PageTitle page={PAGES.messagesSetsCreate} />
                    </HeaderTitle>
                    <CTAsContainer>
                        <TotemPrimaryButton onClick={onSubmit} minWidth="78px">
                            {createMessagesSetLoading ? (
                                <Loader size="18px" mode={LoaderModeType.Spin} />
                            ) : (
                                <>
                                    <FaSave size={13} />
                                    <SaveLabel>Créer</SaveLabel>
                                </>
                            )}
                        </TotemPrimaryButton>
                        <Link to="/messagesSets">
                            <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                        </Link>
                    </CTAsContainer>
                </Header>
                <Content>
                    <ScrollableContent>
                        <MessagesSetFormContent formValue={formValue} setFormValue={setFormValue} />
                    </ScrollableContent>
                </Content>
            </Form>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex: 1;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Form = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
`;

const CTAsContainer = styled.div`
    display: flex;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const SaveLabel = styled.span`
    margin-left: 5px;
`;

const Content = styled.div`
    flex: 1;
    overflow: hidden;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const ScrollableContent = styled.div`
    padding: 15px;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

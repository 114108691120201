import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import styled from 'styled-components';

import { GET_ALL_PRODUCTS_getAllProducts } from 'data/queries/__generated__/GET_ALL_PRODUCTS';
import { CREATE_PRODUCT_SWITCH_MUTATION } from 'data/mutations/productSwitch';
import {
    CREATE_PRODUCT_SWITCH,
    CREATE_PRODUCT_SWITCHVariables,
} from 'data/mutations/__generated__/CREATE_PRODUCT_SWITCH';

import { productSwitchesUpdatedOrCreatedHandler } from '../cacheHandlers/productSwitchesCacheHandler';

import { Loader, LoaderModeType } from 'components/Loader';
import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { TotemDatePicker } from 'components/TotemDatePicker';
import { ProductSelector } from '../ProductSelector';

import { dateFromString, formatDateAsAnniversary } from 'helpers/dateTimes';
import { TotemSwitch } from 'components/TotemSwitch';
import { ProductSwitchCreateInput } from 'data/__generated__';
import { TotemInput } from 'components/TotemInput';

type ProductSwitchCreateType = Omit<ProductSwitchCreateInput, 'endDate' | 'productAId' | 'productBId' | 'startDate'> & {
    endDate: null | string;
    productAId: null | string;
    productBId: null | string;
    startDate: null | string;
};

export function CreateProductSwitchPopup({
    isOpen,
    setIsOpen,
    products,
}: {
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
    products: GET_ALL_PRODUCTS_getAllProducts[];
}) {
    const [productSwitchForm, setProductSwitchForm] = useState<ProductSwitchCreateType>({
        endDate: null,
        productAId: null,
        productBId: null,
        ratioOfAToOrderForB: 1,
        ratioToOrderOfA: 0,
        startDate: null,
        switchFreefood: false,
        switchStore: true,
    });

    const [createProductSwitch, { loading: creationLoading }] = useMutation<
        CREATE_PRODUCT_SWITCH,
        CREATE_PRODUCT_SWITCHVariables
    >(CREATE_PRODUCT_SWITCH_MUTATION);

    async function handleSubmit() {
        const {
            endDate,
            productAId,
            productBId,
            ratioOfAToOrderForB,
            ratioToOrderOfA,
            startDate,
            switchFreefood,
            switchStore,
        } = productSwitchForm;
        if (!productAId || !productBId || !startDate || !endDate) {
            return;
        }
        const { data } = await createProductSwitch({
            variables: {
                productSwitchCreateInput: {
                    endDate,
                    productAId,
                    productBId,
                    ratioOfAToOrderForB,
                    ratioToOrderOfA,
                    startDate,
                    switchFreefood,
                    switchStore,
                },
            },
        });

        if (!data) {
            throw new Error('Une erreur est survenue lors de la création du switch de produit');
        }

        const { createProductSwitch: newProductSwitch } = data;

        productSwitchesUpdatedOrCreatedHandler([newProductSwitch]);

        setIsOpen(false);
    }

    return (
        <TotemPopup
            title="Ajouter un switch de produit"
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            contentOverflow="visible"
        >
            <SubmitContainer>
                <ProductSelector
                    label="Produit A"
                    onProductSelection={(productAId) => setProductSwitchForm({ ...productSwitchForm, productAId })}
                    productSelectedId={productSwitchForm.productAId}
                    products={products}
                />
                <ProductSelector
                    label="Produit B"
                    onProductSelection={(productBId) => setProductSwitchForm({ ...productSwitchForm, productBId })}
                    productSelectedId={productSwitchForm.productBId}
                    products={products}
                />
                <TotemDatePicker
                    label="Date de début"
                    selected={productSwitchForm.startDate ? dateFromString(productSwitchForm.startDate, true) : null}
                    onChange={(startDate) => {
                        setProductSwitchForm({
                            ...productSwitchForm,
                            startDate: startDate
                                ? formatDateAsAnniversary({ dateTime: startDate, useNewFormat: true })
                                : null,
                        });
                    }}
                />
                <TotemDatePicker
                    label="Date de fin"
                    selected={productSwitchForm.endDate ? dateFromString(productSwitchForm.endDate, true) : null}
                    onChange={(endDate) => {
                        setProductSwitchForm({
                            ...productSwitchForm,
                            endDate: endDate
                                ? formatDateAsAnniversary({ dateTime: endDate, useNewFormat: true })
                                : null,
                        });
                    }}
                />
                <TotemSwitch
                    label="Changement des variations freefood"
                    checked={productSwitchForm.switchFreefood}
                    onChange={() => {
                        setProductSwitchForm({
                            ...productSwitchForm,
                            switchFreefood: !productSwitchForm.switchFreefood,
                        });
                    }}
                />
                <TotemSwitch
                    label="Changement des variations store"
                    checked={productSwitchForm.switchStore}
                    onChange={() => {
                        setProductSwitchForm({
                            ...productSwitchForm,
                            switchStore: !productSwitchForm.switchStore,
                        });
                    }}
                />
                <TotemInput
                    label="Ratio de commande du produit A"
                    sublabel="0.1 = on commande 10% de ce qu'on aurait du commander."
                    type="number"
                    min="0"
                    step="0.01"
                    value={productSwitchForm.ratioToOrderOfA}
                    onChange={(value) =>
                        setProductSwitchForm({ ...productSwitchForm, ratioToOrderOfA: parseFloat(value) })
                    }
                />
                <TotemInput
                    label="Ratio de commande du produit B par rapport au produit A"
                    sublabel="0.1 = on commande 10% de B de ce qu'on aurait du commander pour A"
                    type="number"
                    min="0"
                    step="0.01"
                    value={productSwitchForm.ratioOfAToOrderForB}
                    onChange={(value) =>
                        setProductSwitchForm({ ...productSwitchForm, ratioOfAToOrderForB: parseFloat(value) })
                    }
                />
                <ValidationButton
                    onClick={handleSubmit}
                    disabled={
                        !productSwitchForm.endDate ||
                        !productSwitchForm.startDate ||
                        !productSwitchForm.productAId ||
                        !productSwitchForm.productBId
                    }
                >
                    {creationLoading ? <Loader size="20px" mode={LoaderModeType.Spin} /> : 'Confirmer'}
                </ValidationButton>
            </SubmitContainer>
        </TotemPopup>
    );
}

const SubmitContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 15px;
`;

const ValidationButton = styled(TotemPrimaryButton)`
    margin-top: 15px;
`;

import { GET_SUPPLY_ORDERS_supplyOrders_supplier_orderingTimes } from 'data/queries/__generated__/GET_SUPPLY_ORDERS';
import { addDaysToDate, dateFromString, removeDaysFromDate } from 'helpers/dateTimes';

enum Day {
    sunday = 'sunday',
    monday = 'monday',
    tuesday = 'tuesday',
    wednesday = 'wednesday',
    thursday = 'thursday',
    friday = 'friday',
    saturday = 'saturday',
}

const dayShortLabelByDayIndex = ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'];
const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

function getArrivalDayInfo(orderingTimes: GET_SUPPLY_ORDERS_supplyOrders_supplier_orderingTimes) {
    const arrivalDayInfo: Record<number, number[]> = {};

    days.forEach((day, index) => {
        const orderingTime = orderingTimes[day as Day];

        if (orderingTime?.canBeOrdered) {
            const arrivalDayIndex = (index + orderingTime.daysToDeliver) % 7;

            if (!arrivalDayInfo[arrivalDayIndex]) {
                arrivalDayInfo[arrivalDayIndex] = [];
            }

            arrivalDayInfo[arrivalDayIndex].push(orderingTime.daysToDeliver);
        }
    });

    return arrivalDayInfo;
}

function getDateLabel(date: Date) {
    const month = date.getMonth() + 1; //months from 1-12
    const day = date.getDate();

    return `${day}/${month}`;
}

export function getDayLabels({
    isComputed,
    startDate,
    timeWindow,
    orderingTimes,
}: {
    isComputed: boolean;
    startDate: string | null;
    timeWindow: number;
    orderingTimes?: GET_SUPPLY_ORDERS_supplyOrders_supplier_orderingTimes;
}): { label: string; orderDayLabels: string[] }[] {
    const arrivalDayInfo = orderingTimes ? getArrivalDayInfo(orderingTimes) : [];

    // if !isComputed -> manually created supply order
    const today = !isComputed && startDate ? dateFromString(startDate) : new Date();
    return Array.from(Array(timeWindow), (_, index) => {
        const dayIndex = (today.getDay() + index) % 7;

        const dayDate = addDaysToDate({ date: today, daysToAdd: index });

        const orderDayLabels: string[] = [];

        if (arrivalDayInfo[dayIndex]) {
            const daysToDeliver = arrivalDayInfo[dayIndex];

            daysToDeliver.forEach((daysEntry) => {
                const orderDate = removeDaysFromDate({ date: dayDate, daysToRemove: daysEntry });
                orderDayLabels.push(getDateLabel(orderDate));
            });
        }

        return {
            label: `${dayShortLabelByDayIndex[dayIndex]} ${getDateLabel(dayDate)}`,
            orderDayLabels,
        };
    });
}

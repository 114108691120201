import React from 'react';
import styled from 'styled-components';

import { CopyValue } from 'components/CopyValue';
import { ColumnsSectionContainer, SectionColumn, SectionContainer } from 'components/DetailsView/Section';
import { WarehouseDeliveryFragmentFragment } from 'data/__generated__';
import { getWarehouseDeliveryLabel } from '../constants/utils';
import { DetailLink, DetailValue } from 'components/DetailsView';
import { FcCalendar } from 'react-icons/fc';
import { formatDateAsAnniversary, getExtendedFormattedDatetime } from 'helpers/dateTimes';
import { StatusTag } from './StatusTag';

export const GeneralInfoSection = ({ warehouseDelivery }: { warehouseDelivery: WarehouseDeliveryFragmentFragment }) => {
    const {
        _id: warehouseDeliveryId,
        createdAt,
        updatedAt,
        destinationSiteId,
        destinationSite: { name: destinationSiteName },
        transferDate,
        originSiteId,
        originSite: { name: originSiteName },
        operatorName,
        stockTransferId,
        state,
    } = warehouseDelivery;

    return (
        <SectionContainer title="Informations générales" isInitiallyOpen>
            <ColumnsSectionContainer numberOfColumns={3}>
                <SectionColumn>
                    <CopyValue value={warehouseDeliveryId} />
                    <DetailLink
                        path="stockTransfer"
                        name={getWarehouseDeliveryLabel(warehouseDelivery)}
                        label="Transfert de stock"
                        value={stockTransferId}
                    />
                    <DetailLink value={originSiteId} path="site" label="Site d'origine" name={originSiteName} />
                    <DetailLink
                        path="site"
                        label="Site de destination"
                        name={destinationSiteName}
                        value={destinationSiteId}
                    />
                </SectionColumn>
                <SectionColumn>
                    <DetailValue
                        label="Date de livraison"
                        value={
                            <DeliveryDateContainer>
                                <FcCalendar size={22} />
                                <DeliveryDate>
                                    {formatDateAsAnniversary({ dateTime: transferDate, displayDay: true })}
                                </DeliveryDate>
                            </DeliveryDateContainer>
                        }
                    />
                    <DetailValue label="Operateur" value={operatorName} />
                    <StatusTag state={state} showHeading />
                </SectionColumn>
                <SectionColumn>
                    <DetailValue label="Date de création" value={getExtendedFormattedDatetime(createdAt)} />
                    <DetailValue label="Dernière mise à jour" value={getExtendedFormattedDatetime(updatedAt)} />
                </SectionColumn>
            </ColumnsSectionContainer>
        </SectionContainer>
    );
};

const DeliveryDateContainer = styled.div`
    display: flex;
    align-items: center;
`;

const DeliveryDate = styled.span`
    margin-left: 5px;
`;

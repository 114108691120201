import React from 'react';

import styled from 'styled-components';

import { GET_USER_userFromAdmin_notificationPreferences } from 'data/queries/__generated__/GET_USER';

import { TotemLabel } from 'components/TotemLabel';
import { NotificationPreferenceRow } from './NotificationPreferenceRow';

export const NotificationPreferencesDetails = ({
    notificationPreferences,
    userId,
}: {
    notificationPreferences: GET_USER_userFromAdmin_notificationPreferences;
    userId: string;
}) => {
    return (
        <Container>
            <TotemLabel>Préférences de notifications</TotemLabel>
            <table>
                <thead>
                    <tr>
                        <th>Catégorie</th>
                        <th>Mail</th>
                        <th>Push</th>
                        <th>InApp</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(notificationPreferences)
                        .filter(([key]) => key !== '__typename')
                        .map(([category, channels]) => (
                            <NotificationPreferenceRow
                                key={category}
                                category={category}
                                channels={channels}
                                userId={userId}
                            />
                        ))}
                </tbody>
            </table>
        </Container>
    );
};

const Container = styled.div`
    color: ${({ theme }) => theme.textColor};
`;

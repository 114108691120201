import gql from 'graphql-tag';
import { TOTEM_CLOSED_DAY_FRAGMENT } from '../fragments/totemClosedDay';

export const CREATE_TOTEM_CLOSED_DAY_MUTATION = gql`
    mutation CREATE_TOTEM_CLOSED_DAY($createTotemClosedDayInput: CreateTotemClosedDayInput!) {
        createTotemClosedDay(createTotemClosedDayInput: $createTotemClosedDayInput) {
            ...TotemClosedDayFragment
        }
    }
    ${TOTEM_CLOSED_DAY_FRAGMENT}
`;

export const ABORT_TOTEM_CLOSED_DAY_MUTATION = gql`
    mutation ABORT_TOTEM_CLOSED_DAY($totemClosedDayId: ID!) {
        abortTotemClosedDay(totemClosedDayId: $totemClosedDayId) {
            ...TotemClosedDayFragment
        }
    }
    ${TOTEM_CLOSED_DAY_FRAGMENT}
`;

export const ARCHIVE_TOTEM_CLOSED_DAY_MUTATION = gql`
    mutation ARCHIVE_TOTEM_CLOSED_DAY($totemClosedDayId: ID!) {
        archiveTotemClosedDay(totemClosedDayId: $totemClosedDayId) {
            ...TotemClosedDayFragment
        }
    }
    ${TOTEM_CLOSED_DAY_FRAGMENT}
`;

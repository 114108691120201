import { colors } from 'constants/colors';
import React, { useEffect, useState } from 'react';
import { ImArrowLeft2, ImArrowRight2 } from 'react-icons/im';
import { IoCloseSharp } from 'react-icons/io5';
import styled from 'styled-components';

export type TotemLightboxImage = {
    url: string;
    label?: string;
};

export function TotemLightBox({
    images,
    initialImageIndex,
    isOpen,
    setIsOpen,
}: {
    images: TotemLightboxImage[];
    initialImageIndex?: number;
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
}) {
    const [displayIndex, setDisplayIndex] = useState(initialImageIndex ?? 0);

    useEffect(() => {
        setDisplayIndex(initialImageIndex ?? 0);
    }, [initialImageIndex, images]);

    useEffect(() => {
        const keydownFunction = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                setIsOpen(false);
            }
            if (event.key === 'ArrowLeft') {
                displayPreviousImage();
            }
            if (event.key === 'ArrowRight') {
                displayNextImage();
            }
        };
        window.addEventListener('keydown', keydownFunction);
        return () => window.removeEventListener('keydown', keydownFunction);
    }, [displayPreviousImage, displayNextImage, setIsOpen]);

    if (!images.length || !images?.[displayIndex] || !isOpen) {
        return null;
    }

    const { label, url } = images[displayIndex];

    function displayNextImage() {
        setDisplayIndex((displayIndex + 1) % images.length);
    }

    function displayPreviousImage() {
        setDisplayIndex((displayIndex - 1 + images.length) % images.length);
    }

    return (
        <Container>
            <Content>
                <ImArrowLeft2 color={colors.pureWhite} size={80} onClick={displayPreviousImage} />
                <ImageContainer>
                    <Image src={url} />
                </ImageContainer>
                <ImArrowRight2 color={colors.pureWhite} size={80} onClick={displayNextImage} />
            </Content>
            <CloseIcon color={colors.pureWhite} size={45} onClick={() => setIsOpen(false)} />
            <NumberDisplay>
                {displayIndex + 1} / {images.length}
            </NumberDisplay>
            {label ? <ImageLabel>{label}</ImageLabel> : null}
        </Container>
    );
}

const Container = styled.div`
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: ${colors.black}cc;
`;

const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 50px;
    background-color: ${colors.black}55;
`;

const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow: hidden;
    margin: 0 20px;
`;

const Image = styled.img`
    object-fit: contain;
    width: 100%;
    height: 100%;
`;

const CloseIcon = styled(IoCloseSharp)`
    position: absolute;
    top: 50px;
    right: 50px;
`;

const NumberDisplay = styled.div`
    position: absolute;
    top: 50px;
    left: 50px;
    color: ${colors.pureWhite};
    font-size: 25px;
`;

const ImageLabel = styled.div`
    position: absolute;
    top: 5px;
    width: 100%;
    text-align: center;
    color: ${colors.pureWhite};
    font-size: 25px;
`;

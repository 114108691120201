import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import { FaArrowUp, FaTrash } from 'react-icons/fa';

import { NEW_DAY_LABELS } from 'constants/dateAndTime';

import {
    UPDATE_URBANTZ_ROUND_PRESET,
    UPDATE_URBANTZ_ROUND_PRESETVariables,
} from 'data/mutations/__generated__/UPDATE_URBANTZ_ROUND_PRESET';
import {
    DELETE_URBANTZ_ROUND_PRESET_MUTATION,
    UPDATE_URBANTZ_ROUND_PRESET_MUTATION,
} from 'data/mutations/urbantzRoundPreset';
import { UrbantzRoundPresetFragment } from 'data/fragments/__generated__/UrbantzRoundPresetFragment';

import { Loader, LoaderModeType } from 'components/Loader';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { Option, TotemSelect } from 'components/TotemSelect';

import {
    urbantzRoundPresetsDeletedHandler,
    urbantzRoundPresetsUpdatedOrCreatedHandler,
} from './cacheHandlers/urbantzRoundPresetsCacheHandler';
import {
    DELETE_URBANTZ_ROUND_PRESET,
    DELETE_URBANTZ_ROUND_PRESETVariables,
} from 'data/mutations/__generated__/DELETE_URBANTZ_ROUND_PRESET';

export function UrbantzRoundPresetForm({
    totemOptions,
    urbantzRoundPreset,
}: {
    totemOptions: Option<string>[];
    urbantzRoundPreset: UrbantzRoundPresetFragment;
}) {
    const [totemIds, setTotemIds] = useState<string[]>(urbantzRoundPreset.totemIds);

    const [updateUrbantzRoundPreset, { loading: updateLoading }] = useMutation<
        UPDATE_URBANTZ_ROUND_PRESET,
        UPDATE_URBANTZ_ROUND_PRESETVariables
    >(UPDATE_URBANTZ_ROUND_PRESET_MUTATION);

    const [deleteUrbantzRoundPreset, { loading: deleteLoading }] = useMutation<
        DELETE_URBANTZ_ROUND_PRESET,
        DELETE_URBANTZ_ROUND_PRESETVariables
    >(DELETE_URBANTZ_ROUND_PRESET_MUTATION);

    async function update() {
        const { data } = await updateUrbantzRoundPreset({
            variables: { urbantzRoundPresetUpdateInput: { _id: urbantzRoundPreset._id, totemIds } },
        });

        if (!data?.updateUrbantzRoundPreset) {
            throw new Error('Une erreur est survenue lors de la mise à jour de la tournée');
        }

        const { updateUrbantzRoundPreset: updatedUrbantzRoundPreset } = data;

        urbantzRoundPresetsUpdatedOrCreatedHandler([updatedUrbantzRoundPreset]);
    }

    async function deleteRound() {
        const hasConfirmed = window.confirm('Êtes-vous sûr.e de vouloir supprimer la tournée ?');
        if (!hasConfirmed) {
            return;
        }

        const { data } = await deleteUrbantzRoundPreset({
            variables: { urbantzRoundPresetId: urbantzRoundPreset._id },
        });

        if (!data?.deleteUrbantzRoundPreset) {
            throw new Error('Une erreur est survenue lors de la suppression de la tournée');
        }

        urbantzRoundPresetsDeletedHandler([urbantzRoundPreset._id]);
    }

    function moveTotemUp(index: number) {
        if (!index) {
            return;
        }

        const newTotemIds = [...totemIds];
        const temp = newTotemIds[index - 1];
        newTotemIds[index - 1] = newTotemIds[index];
        newTotemIds[index] = temp;
        setTotemIds(newTotemIds);
    }

    function deleteTotem(index: number) {
        const newTotemIds = [...totemIds];
        newTotemIds.splice(index, 1);
        setTotemIds(newTotemIds);
    }

    const hasChanged = urbantzRoundPreset.totemIds.toString() !== totemIds.toString();

    return (
        <Container>
            <DayContent>{NEW_DAY_LABELS[urbantzRoundPreset.dayIndex]}</DayContent>
            {totemIds.map((totemId, index) => {
                const totemOption = totemOptions.find(({ value }) => value === totemId);
                return (
                    <TotemContainer key={`${totemId}-${index}`}>
                        <TotemLabel>
                            {index + 1}. {totemOption?.label ?? 'Erreur'}
                        </TotemLabel>
                        {index ? (
                            <SmallButton onClick={() => moveTotemUp(index)}>
                                <FaArrowUp size={15} />
                            </SmallButton>
                        ) : null}
                        <SmallButton onClick={() => deleteTotem(index)}>
                            <FaTrash size={15} />
                        </SmallButton>
                    </TotemContainer>
                );
            })}
            <SelectContainer>
                <TotemSelect
                    placeholder="Sélectionner un totem"
                    isDisabled={updateLoading}
                    value={null}
                    options={totemOptions}
                    onChange={(option) => {
                        if (option) {
                            setTotemIds([...totemIds, option.value]);
                        }
                    }}
                />
            </SelectContainer>
            <ButtonContainer>
                <TotemPrimaryButton disabled={!hasChanged} onClick={update}>
                    {updateLoading || deleteLoading ? (
                        <Loader size="20px" mode={LoaderModeType.Spin} />
                    ) : (
                        'Mettre à jour'
                    )}
                </TotemPrimaryButton>
                <TotemPrimaryButton disabled={hasChanged} onClick={deleteRound}>
                    {updateLoading || deleteLoading ? <Loader size="20px" mode={LoaderModeType.Spin} /> : 'Supprimer'}
                </TotemPrimaryButton>
            </ButtonContainer>
        </Container>
    );
}

const Container = styled.div`
    margin-top: 20px;
    padding: 10px;
    background-color: ${({ theme }) => theme.cardBackgroundColor};
    box-shadow: ${({ theme }) => theme.boxShadow};
    border-radius: 10px;
`;

const DayContent = styled.div`
    font-weight: bold;
`;

const ButtonContainer = styled.div`
    margin-top: 10px;

    > :not(:first-child) {
        margin-left: 10px;
    }
`;

const SelectContainer = styled.div`
    margin-top: 10px;
`;

const TotemContainer = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: row;
`;
const TotemLabel = styled.div`
    margin-top: 10px;
    flex-grow: 1;
`;

const SmallButton = styled(TotemPrimaryButton)`
    padding: 10px;
    margin-left: 5px;
`;

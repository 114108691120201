import { gql } from '@apollo/client';

export const FUTURE_STOCK_VARIATION_FRAGMENT = gql`
    fragment FutureStockVariationFragment on FutureStockVariation {
        _id
        createdAt
        delta
        siteId
        productId
        ref
        processInfo {
            boxInfo {
                boxId
                isPrepared
                stockVariationRef
            }
            supplyOrderStockVariationRef
        }
        issues {
            explanation
            type
        }
        state
        type
        updatedAt
        useDate
    }
`;

import React from 'react';

import { CommonStatusTag } from 'components/CommonStatusTag';

import { colors } from 'constants/colors';

import { OrganizationPaymentType } from 'data/__generated__';
import { ORGANIZATION_PAYMENT_TYPE_LABELS } from '../constants/payment-types';

const getColorsFromState = (state: OrganizationPaymentType | null) => {
    switch (state) {
        case OrganizationPaymentType.CreditCard:
            return { backgroundColor: colors.green, labelColor: colors.black };
        case OrganizationPaymentType.SepaDirectDebit:
            return { backgroundColor: colors.blue, labelColor: colors.pureWhite };
        case OrganizationPaymentType.BankTransfer:
            return { backgroundColor: colors.pink, labelColor: colors.pureWhite };
        case OrganizationPaymentType.MonthlySepaDirectDebit:
            return { backgroundColor: colors.blue, labelColor: colors.pureWhite };
        default:
            return { backgroundColor: colors.pantinGrey, labelColor: colors.pureWhite };
    }
};

export const PaymentType = ({ paymentType }: { paymentType: OrganizationPaymentType | null }) => {
    const { backgroundColor, labelColor } = getColorsFromState(paymentType);
    return (
        <CommonStatusTag
            backgroundColor={backgroundColor}
            labelColor={labelColor}
            label={paymentType ? ORGANIZATION_PAYMENT_TYPE_LABELS[paymentType] : 'Ø'}
        />
    );
};

import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { PAGES } from 'constants/pages';
import { DEFAULT_PANTIN_WAREHOUSE_SITE_ID } from 'pages/Stocks/constants';

import { Header, HeaderTitle } from 'components/Header';
import { FutureStockVariationsList } from 'pages/Stocks/components/FutureStockVariationsList';
import { PageTitle } from 'components/PageTitle';
import { ProductWithStocksSelector } from 'pages/Stocks/components/ProductWithStocksSelector';
import { SelectedOption } from 'components/TotemSelect';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

export const FutureStockVariations = () => {
    const [selectedProduct, setSelectedProduct] = useState<SelectedOption<string> | null>(null);

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.futureStockVariations} />
                </HeaderTitle>
                <NavButtons>
                    <Link to="/stockVariation/expiredFutureStockVariations">
                        <TotemPrimaryButton>Variations de stock à venir expirées</TotemPrimaryButton>
                    </Link>
                    <Link to="/stocks">
                        <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                    </Link>
                </NavButtons>
            </Header>
            <Content>
                <ProductWithStocksSelector
                    productOption={selectedProduct}
                    handleProductSelect={(selection) => setSelectedProduct(selection || null)}
                    siteId={DEFAULT_PANTIN_WAREHOUSE_SITE_ID}
                    label="Sélectionner un produit"
                />
                {selectedProduct ? <FutureStockVariationsList productId={selectedProduct.value} /> : null}
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Content = styled.div`
    padding: 15px;
    flex: 1;
    overflow: auto;
`;

const NavButtons = styled.div`
    display: flex;
    align-items: center;
    ${TotemPrimaryButton} {
        margin-left: 15px;
    }
`;

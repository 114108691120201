import React from 'react';
import { useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { FaFileInvoice } from 'react-icons/fa';

import { GET_PAYMENTS } from 'data/queries/__generated__/GET_PAYMENTS';
import { GET_PAYMENTS_QUERY } from 'data/queries/payment';

import { FieldToDisplay, ListView } from 'components/ListView';
import { Loader } from 'components/Loader';
import { StatusTag } from './components/StatusTag';
import { getExtendedFormattedDatetime } from 'helpers/dateTimes';
import { TotemImage } from 'components/TotemImage';

function getOctobatLink(invoiceId: string | null): string {
    return 'https://app.octobat.com/invoices/' + invoiceId;
}

type PaymentTable = {
    _id: string;
    createdAt: string;
    octobat: JSX.Element;
    organizationName: string;
    state: JSX.Element;
    totalAmountDisplay: string;
};

export const PaymentsList = ({
    stateFilter,
    organizationIdFilter,
}: {
    stateFilter?: string[];
    organizationIdFilter?: string;
}) => {
    const {
        loading: paymentsLoading,
        error: paymentsError,
        data: paymentsData,
        fetchMore: fetchMorePayments,
    } = useQuery<GET_PAYMENTS>(GET_PAYMENTS_QUERY, {
        variables: {
            offset: 0,
            stateFilter,
            organizationIdFilter,
        },
        fetchPolicy: 'cache-and-network',
    });

    const PAYMENTS_FIELDS_TO_DISPLAY: FieldToDisplay<PaymentTable>[] = [
        { fieldName: 'createdAt', label: 'Date de création' },
        {
            fieldName: 'organizationName',
            label: 'Organisation',
        },
        {
            fieldName: 'state',
            label: 'Statut',
        },
        {
            fieldName: 'totalAmountDisplay',
            label: 'Montant total',
        },
        {
            fieldName: 'octobat',
            label: 'Octobat',
        },
    ];

    if (paymentsLoading && !paymentsData) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (paymentsError || !paymentsData) {
        toast.error('Une erreur est survenue lors de la récupération des paiements');
        return null;
    }

    const {
        payments: { hasMore, payments },
    } = paymentsData;

    const sortedPayments = [...payments].sort((paymentA, paymentB) => paymentB.createdAt - paymentA.createdAt);

    const formattedPayments: PaymentTable[] = sortedPayments.map(
        ({ _id, createdAt, invoiceId, invoicePdfUrl, organizationName, state, totalAmountDisplay }) => ({
            _id,
            organizationName,
            state: <StatusTag state={state} />,
            totalAmountDisplay,
            createdAt: getExtendedFormattedDatetime(createdAt),
            octobat: (
                <OctobatLinks>
                    <ExternalResourceLink
                        href={getOctobatLink(invoiceId)}
                        target="_blank"
                        rel="noopener noreferrer"
                        disabled={!invoiceId}
                    >
                        <TotemImage src="https://app.octobat.com/favicon-32x32.png" alt="Logo Octobat" size={30} />
                    </ExternalResourceLink>
                    {invoicePdfUrl ? (
                        <ExternalResourceLink href={invoicePdfUrl} target="_blank" rel="noopener noreferrer">
                            <FaFileInvoice size={30} />
                        </ExternalResourceLink>
                    ) : null}
                </OctobatLinks>
            ),
        }),
    );

    const loadMore = async () => {
        await fetchMorePayments({
            variables: {
                offset: payments.length,
            },
        });
    };

    return (
        <Container>
            {formattedPayments.length ? (
                <ListView<PaymentTable>
                    fieldsToDisplay={PAYMENTS_FIELDS_TO_DISPLAY}
                    items={formattedPayments}
                    loadMore={loadMore}
                    hasMore={hasMore}
                    linkBasePath="/payment/"
                    keyExtractor={(item) => item._id}
                />
            ) : (
                "Aucun paiement n'a été trouvé"
            )}
        </Container>
    );
};

const Container = styled.div`
    flex: 1;
    overflow-y: scroll;
`;

const OctobatLinks = styled.div`
    display: flex;
`;

const ExternalResourceLink = styled.a<{ disabled?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;

    color: ${({ theme }) => theme.ctaPrimaryColor};
    text-decoration: none;
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'default')};
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

    &:hover {
        text-decoration: underline;
    }

    & > :not(:first-child) {
        margin-left: 10px;
    }
`;

import React, { useState } from 'react';

import { AiFillFileAdd } from 'react-icons/ai';
import styled from 'styled-components';

import { PAGES } from '../../constants/pages';
import { colors } from 'constants/colors';

import { useGetAdvertisementScreenConfigQuery, useUpdateAdvertisementScreenConfigMutation } from 'data/__generated__';
import { advertisementScreenConfigUpdatedOrCreatedHandler } from './cacheHandlers/advertisementScreenConfigCacheHandler';

import { Header, HeaderTitle } from 'components/Header';
import { Loader, LoaderModeType } from 'components/Loader';
import { PageTitle } from 'components/PageTitle';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { FileDragAndDrop, FileWithPreview } from 'components/FileDragAndDrop';
import { TotemImage } from 'components/TotemImage';

export const AdvertisementScreenConfig = () => {
    const [images, setImages] = useState<FileWithPreview[]>([]);

    const {
        loading: advertisementScreenConfigLoading,
        data: advertisementScreenConfigData,
        error: advertisementScreenConfigError,
    } = useGetAdvertisementScreenConfigQuery();

    const [updateAdvertisementScreenConfig, { loading: updateLoading }] = useUpdateAdvertisementScreenConfigMutation();

    if (advertisementScreenConfigLoading && !advertisementScreenConfigData) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (advertisementScreenConfigError || !advertisementScreenConfigData) {
        throw new Error("Une erreur est survenue lors de la récupération des configs d'écran publicitaire");
    }

    const { getAdvertisementScreenConfig: advertisementScreenConfig } = advertisementScreenConfigData;

    async function update() {
        const { data } = await updateAdvertisementScreenConfig({
            variables: { advertisementScreenConfigUpdateInput: { _id: advertisementScreenConfig._id, images } },
        });

        if (!data) {
            throw new Error("Une erreur est survenue lors de la création de la config d'écran publicitaire");
        }

        const { updateAdvertisementScreenConfig: newAdvertisementScreenConfig } = data;

        if (newAdvertisementScreenConfig) {
            advertisementScreenConfigUpdatedOrCreatedHandler(newAdvertisementScreenConfig);
        }
    }

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.advertisementScreenConfig} />
                </HeaderTitle>
                <ActionContainer>
                    <TotemPrimaryButton onClick={() => update()}>
                        {updateLoading ? <Loader size="22px" mode={LoaderModeType.Spin} /> : 'Mettre à jour'}
                    </TotemPrimaryButton>
                </ActionContainer>
            </Header>
            <Content>
                <SectionTitle>Liste d'images actuelle</SectionTitle>

                {advertisementScreenConfig.imageUrls.map((imageUrl) => (
                    <ImageContainer key={imageUrl}>
                        <TotemImage src={imageUrl} alt={imageUrl} size={200} />
                    </ImageContainer>
                ))}

                <SectionTitle>Changer la liste</SectionTitle>

                {images.map((image, index) => (
                    <ImageContainer key={index}>
                        <FileDragAndDrop
                            multiple={false}
                            hasPadding={false}
                            fileTypes={['image/jpeg', 'image/png', 'image/svg+xml', 'image/gif']}
                            onFileDropCallback={(files) => {
                                if (files?.[0]) {
                                    const newImages = [...images];
                                    newImages[index] = files[0];
                                    setImages(newImages);
                                }
                            }}
                        >
                            <ImageHoverContainer>
                                <TotemImage src={image.preview} alt={image.name} size={200} />
                            </ImageHoverContainer>
                        </FileDragAndDrop>
                    </ImageContainer>
                ))}

                <ImageContainer>
                    <FileDragAndDrop
                        multiple={false}
                        hasPadding={false}
                        fileTypes={['image/jpeg', 'image/png', 'image/svg+xml', 'image/gif']}
                        onFileDropCallback={(files) => {
                            if (files?.[0]) {
                                const newImages = [...images];
                                newImages.push(files[0]);
                                setImages(newImages);
                            }
                        }}
                    >
                        <ImageHoverContainer>
                            <NewImageZone>
                                <AiFillFileAdd />
                            </NewImageZone>
                        </ImageHoverContainer>
                    </FileDragAndDrop>
                </ImageContainer>
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
    overflow: auto;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    flex: 1;
    overflow: auto;
`;

const ActionContainer = styled.div`
    display: flex;

    & > :not(:first-child) {
        margin-left: 10px;
    }
`;

const SectionTitle = styled.div`
    font-size: 22px;
    margin-top: 30px;
    text-decoration: underline;
    font-weight: bold;
`;

const ImageContainer = styled.div`
    margin-top: 20px;
    display: flex;
    flex-shrink: 0;
`;

const ImageHoverContainer = styled.div`
    cursor: pointer;
    &:hover {
        opacity: 0.4;
    }
    flex-shrink: 0;
`;

const NewImageZone = styled.div`
    height: 50px;
    width: 50px;
    background-color: ${colors.lightGrey};
    display: flex;
    align-items: center;
    justify-content: center;
`;

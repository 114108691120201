import { gql } from '@apollo/client';

export const UPDATE_LOCATIONS_STATE_MUTATION = gql`
    mutation UPDATE_LOCATIONS_STATE($locationIds: [String!]!, $stateToSet: LocationState!) {
        updateLocationsState(locationIds: $locationIds, stateToSet: $stateToSet) {
            locations {
                _id
                datamatrix
                state
            }
            error
            warnings
        }
    }
`;

export const SWAP_PRODUCT_LOCATIONS_MUTATION = gql`
    mutation SWAP_PRODUCT_LOCATIONS($locationIds: [String!]!) {
        swapProductLocations(locationIds: $locationIds) {
            locations {
                _id
                productId
                productName
            }
            error
            warnings
        }
    }
`;

import React from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { ProductArrangementWithProductsFragment } from 'data/fragments/__generated__/ProductArrangementWithProductsFragment';
import { GET_PRODUCT_ARRANGEMENTS_QUERY, GET_PRODUCT_ARRANGEMENT_QUERY } from 'data/queries/productArrangement';
import {
    GET_PRODUCT_ARRANGEMENT,
    GET_PRODUCT_ARRANGEMENTVariables,
} from 'data/queries/__generated__/GET_PRODUCT_ARRANGEMENT';
import { GET_PRODUCT_ARRANGEMENTS } from 'data/queries/__generated__/GET_PRODUCT_ARRANGEMENTS';

import { Loader, LoaderModeType } from 'components/Loader';
import { SelectedOption, TotemSelect } from 'components/TotemSelect';
import { ProductsArrangement } from './ProductsArrangement';

import { MicrostoreColumnTemplateFormValues, MicrostoreLocationTemplateFormValues } from './FormHelper/DataMapper';
import { TemplateProductSelector } from './TemplateProductSelector';
import { TotemSwitch } from 'components/TotemSwitch';
import { ProductArrangementReassortmentType } from 'data/__generated__';

export const LocationEditor = ({
    displayProductArrangement = true,
    microstoreColumnTemplateFormValues,
    selectedShelfTemplateIndex,
    selectedLocationTemplatePosition,
    setMicrostoreColumnTemplateFormValuesAndIsFormDirty,
}: {
    displayProductArrangement?: boolean;
    microstoreColumnTemplateFormValues: MicrostoreColumnTemplateFormValues;
    selectedShelfTemplateIndex: number | null;
    selectedLocationTemplatePosition: {
        column: number;
        row: number;
    } | null;
    setMicrostoreColumnTemplateFormValuesAndIsFormDirty: ({
        newMicrostoreColumnTemplateFormValues,
        newIsDirty,
    }: {
        newMicrostoreColumnTemplateFormValues: MicrostoreColumnTemplateFormValues;
        newIsDirty?: boolean | undefined;
    }) => void;
}) => {
    const {
        loading: productArrangementsLoading,
        data: productArrangementsData,
        error: productArrangementsError,
    } = useQuery<GET_PRODUCT_ARRANGEMENTS>(GET_PRODUCT_ARRANGEMENTS_QUERY);

    const [getProductArrangementAndAddToLocation, { loading: productArrangementLoading }] = useLazyQuery<
        GET_PRODUCT_ARRANGEMENT,
        GET_PRODUCT_ARRANGEMENTVariables
    >(GET_PRODUCT_ARRANGEMENT_QUERY, {
        fetchPolicy: 'cache-and-network', // remove this when apollo fix it's bug : https://github.com/apollographql/react-apollo/issues/2177
        onCompleted: ({ productArrangementQuery }) => {
            if (productArrangementQuery) {
                addProductArrangementToLocation(productArrangementQuery);
            }
        },
        onError: (error) => {
            toast.error(`Une erreur inattendue s'est produite : ${error.message}`);
        },
    });

    if (productArrangementsLoading && !productArrangementsData) {
        return (
            <LocationDetails>
                <Loader mode={LoaderModeType.Spin} />
            </LocationDetails>
        );
    }

    if (productArrangementsError || !productArrangementsData) {
        throw new Error('Une erreur est survenue lors de la récupération des gammes de produits');
    }

    const { productArrangementsQuery: productArrangements } = productArrangementsData;

    const selectedShelfTemplate =
        selectedShelfTemplateIndex !== null
            ? microstoreColumnTemplateFormValues.shelfTemplates[selectedShelfTemplateIndex]
            : null;

    const selectedLocationTemplate =
        selectedShelfTemplate !== null && selectedLocationTemplatePosition !== null
            ? selectedShelfTemplate.locationTemplateTable[selectedLocationTemplatePosition.row][
                  selectedLocationTemplatePosition.column
              ]
            : null;

    if (!selectedLocationTemplate) {
        return <LocationDetails>Sélectionnez un emplacement</LocationDetails>;
    }

    function updateLocation(newLocationValues: Partial<MicrostoreLocationTemplateFormValues>) {
        if (
            selectedShelfTemplateIndex === null ||
            !selectedShelfTemplate ||
            selectedLocationTemplatePosition === null ||
            !selectedLocationTemplate
        ) {
            return;
        }

        const locationTemplateTable = selectedShelfTemplate.locationTemplateTable;
        const locationRow = locationTemplateTable[selectedLocationTemplatePosition.row];
        const location = locationRow[selectedLocationTemplatePosition.column];

        const newLocationRow = [...locationRow];
        newLocationRow[selectedLocationTemplatePosition.column] = { ...location, ...newLocationValues };

        const newLocationTemplateTable = [...locationTemplateTable];
        newLocationTemplateTable[selectedLocationTemplatePosition.row] = [...newLocationRow];

        const newShelfTemplates = [...microstoreColumnTemplateFormValues.shelfTemplates];
        newShelfTemplates[selectedShelfTemplateIndex] = {
            ...selectedShelfTemplate,
            locationTemplateTable: newLocationTemplateTable,
        };
        setMicrostoreColumnTemplateFormValuesAndIsFormDirty({
            newMicrostoreColumnTemplateFormValues: {
                ...microstoreColumnTemplateFormValues,
                shelfTemplates: newShelfTemplates,
            },
        });
    }

    function addProductArrangementToLocation(
        productArrangement: ProductArrangementWithProductsFragment,
        alternativeProductArrangementIds?: string[] | null,
    ) {
        updateLocation({
            productArrangement,
            alternativeProductArrangementIds,
            productArrangementSingleProductInfo: null,
        });
    }

    const productArrangementOptions =
        productArrangements
            .map(({ _id: productArrangementId, name }) => ({
                value: productArrangementId,
                label: name,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)) || [];

    const productArrangementOptionsWithManualReassortment =
        productArrangements
            .filter(({ reassortmentType }) => reassortmentType === ProductArrangementReassortmentType.Manual)
            .map(({ _id: productArrangementId, name }) => ({
                value: productArrangementId,
                label: name,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)) || [];

    const displayAlternativeProductArrangementIds =
        selectedLocationTemplate?.productArrangement?.reassortmentType === ProductArrangementReassortmentType.Manual;

    return (
        <LocationDetails>
            <TotemSelect<string>
                menuPlacement="auto"
                label="Choisir une gamme de produit"
                placeholder="Sélectionner une gamme de produit"
                value={
                    productArrangementOptions.find(
                        (productArrangementOption) =>
                            productArrangementOption.value === selectedLocationTemplate?.productArrangement?._id,
                    ) ?? null
                }
                options={productArrangementOptions}
                onChange={(option: SelectedOption<string>) => {
                    if (option) {
                        getProductArrangementAndAddToLocation({
                            variables: {
                                productArrangementId: option.value,
                            },
                        });
                    }
                }}
            />
            {displayAlternativeProductArrangementIds && (
                <TotemSelect
                    isMulti
                    menuPlacement="auto"
                    label="Choisir des gammes de produit alternatives"
                    placeholder="Sélectionner des gammes de produit"
                    value={
                        productArrangementOptionsWithManualReassortment.filter((productArrangementOption) =>
                            selectedLocationTemplate?.alternativeProductArrangementIds?.includes(
                                productArrangementOption.value,
                            ),
                        ) ?? []
                    }
                    options={productArrangementOptionsWithManualReassortment}
                    onChange={(options) => {
                        if (options) {
                            const alternativeProductArrangementIds = options.map((option) => option.value);
                            updateLocation({ alternativeProductArrangementIds });
                        }
                    }}
                />
            )}
            {displayProductArrangement &&
                (productArrangementLoading ? (
                    <Loader mode={LoaderModeType.Spin} />
                ) : selectedLocationTemplate.productArrangement ? (
                    <ProductsArrangement productArrangement={selectedLocationTemplate.productArrangement} />
                ) : null)}
            <TemplateProductSelector
                selectedLocationTemplate={selectedLocationTemplate}
                updateLocation={updateLocation}
            />

            <TotemSwitch
                label="Attendre que le produit précédent soit épuisé avant de mettre le nouveau produit"
                checked={selectedLocationTemplate.shouldSmoothProductTransition}
                onChange={() => {
                    updateLocation({
                        shouldOrderProductIfNotInSite: !selectedLocationTemplate.shouldSmoothProductTransition
                            ? selectedLocationTemplate.shouldOrderProductIfNotInSite
                            : true,
                        shouldSmoothProductTransition: !selectedLocationTemplate.shouldSmoothProductTransition,
                    });
                }}
            />
            <TotemSwitch
                label="Commander le nouveau produit pour cet emplacement même si l'ancien produit n'est pas écoulé"
                checked={selectedLocationTemplate.shouldOrderProductIfNotInSite}
                disabled={!selectedLocationTemplate.shouldSmoothProductTransition}
                onChange={() => {
                    updateLocation({
                        shouldOrderProductIfNotInSite: !selectedLocationTemplate.shouldOrderProductIfNotInSite,
                    });
                }}
            />
            <TotemSwitch
                label="Autoriser la rotation de ce produit en cours de semaine (remplacement par un autre produit de la gamme de produit lors de la création des menus)"
                checked={selectedLocationTemplate.allowProductRotation}
                onChange={() => {
                    updateLocation({
                        allowProductRotation: !selectedLocationTemplate.allowProductRotation,
                    });
                }}
            />
        </LocationDetails>
    );
};

const LocationDetails = styled.div`
    padding: 15px;
    height: 100%;
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
    border-radius: ${({ theme }) => theme.borderRadius};
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.cardBackgroundColor};

    & > :not(:first-child) {
        margin-top: 15px;
    }
`;

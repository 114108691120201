import { apolloClient } from 'App';

import { GET_BUNDLES_QUERY } from 'data/queries/bundle';
import { GET_BUNDLES, GET_BUNDLES_recentBundles } from 'data/queries/__generated__/GET_BUNDLES';
import { BundleFragmentFragment } from 'data/__generated__';

export const bundleUpdatedHandler = (updatedBundle: BundleFragmentFragment) => {
    let currentBundles = [] as GET_BUNDLES_recentBundles[];
    // We can read query only if it has been run
    try {
        const result = apolloClient.readQuery<GET_BUNDLES>({ query: GET_BUNDLES_QUERY });
        if (!result) {
            throw Error('An error occurred when reading GET_BUNDLES_QUERY');
        }
        currentBundles = result.recentBundles;
    } catch {
        // do nothing
    }
    const bundleIds = currentBundles.map(({ _id }) => _id);

    if (bundleIds.includes(updatedBundle._id)) {
        // If this is a bundle existing in the cache
        const data = currentBundles.map((bundle) => {
            if (bundle._id === updatedBundle._id) {
                return updatedBundle;
            }
            return bundle;
        });
        apolloClient.writeQuery({
            query: GET_BUNDLES_QUERY,
            data,
        });
    } else {
        // If this is a new bundle, not present in the cache
        const data = {
            recentBundles: [...currentBundles, updatedBundle],
        };
        apolloClient.writeQuery({
            query: GET_BUNDLES_QUERY,
            data,
        });
    }
};

import gql from 'graphql-tag';

import { POLL_FRAGMENT } from 'data/fragments/poll';

export const GET_POLLS_QUERY = gql`
    query GetPolls($offset: Int!) {
        polls(offset: $offset) {
            hasMore
            polls {
                _id
                createdAt
                numberOfParticipants
                question
            }
        }
    }
`;

export const GET_POLL_QUERY = gql`
    query GetPoll($pollId: ID!) {
        poll(pollId: $pollId) {
            ...PollFragment
        }
    }
    ${POLL_FRAGMENT}
`;

import React from 'react';
import { StockVariationType } from 'data/__generated__';
import { CommonStatusTag } from 'components/CommonStatusTag';
import { colors } from 'constants/colors';
import { siteVariationsColors } from '../constants';
export const TypeTag = ({ type }: { type: StockVariationType }) => {
    const getColorsFromType = (type: StockVariationType) => {
        return {
            backgroundColor: siteVariationsColors[type],
            labelColor: colors.pureWhite,
        };
    };

    const { backgroundColor, labelColor } = getColorsFromType(type);

    return <CommonStatusTag backgroundColor={backgroundColor} labelColor={labelColor} label={type} />;
};

import React, { useContext, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { IconMoon, IconSun } from '@tabler/icons';
import QRCode from 'qrcode.react';

import Switch from 'react-switch';
import styled from 'styled-components';

import { Header, HeaderTitle } from 'components/Header';
import { PageTitle } from 'components/PageTitle';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

import { GET_TEST_SENSEI_GATE_TOKEN_QUERY } from 'data/queries/user';
import { TOTEM_BEARER_TOKEN } from 'constants/token';
import { PAGES } from 'constants/pages';
import { colors } from 'constants/colors';
import { darkTheme, lightTheme } from 'constants/theme';

import { UpdateThemeContext, InitialUserContext } from '../../contexts';
import { CopyValue } from 'components/CopyValue';
import { TotemLabel } from 'components/TotemLabel';
import { TotemInput } from 'components/TotemInput';
import { TotemCheckbox } from 'components/TotemCheckbox';

export const Settings = () => {
    const { setUser } = useContext(InitialUserContext);
    const { setTheme } = useContext(UpdateThemeContext);
    const [token, setToken] = useState<string | null>(null);
    const themeFromStorage = window.localStorage.getItem('theme');

    const [isStaff, setIsStaff] = useState<boolean>(false);
    const [senseiUserId, setSenseiUserId] = useState<string>('');

    const handleLogOut = () => {
        window.localStorage.removeItem(TOTEM_BEARER_TOKEN);
        setUser(null);
    };

    function updateTheme() {
        if (themeFromStorage === 'dark') {
            window.localStorage.setItem('theme', 'light');
            setTheme(lightTheme);
        } else {
            window.localStorage.setItem('theme', 'dark');
            setTheme(darkTheme);
        }
    }

    const [getGateToken] = useLazyQuery(GET_TEST_SENSEI_GATE_TOKEN_QUERY);

    async function handleGetToken() {
        const response = await getGateToken({ variables: { isStaff, senseiUserId } });

        if (response?.data?.testSenseiGateToken) {
            setToken(response?.data?.testSenseiGateToken);
        }
    }

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.settings} />
                </HeaderTitle>
            </Header>
            <Content>
                <ScrollableContent>
                    <Switch
                        onChange={updateTheme}
                        checked={themeFromStorage !== 'dark'}
                        onColor={colors.green}
                        offColor={colors.pantinGrey}
                        onHandleColor={colors.pureWhite}
                        offHandleColor="#282c34"
                        checkedIcon={false}
                        uncheckedIcon={false}
                        checkedHandleIcon={
                            <IconContainer>
                                <IconSun color={colors.black} />
                            </IconContainer>
                        }
                        uncheckedHandleIcon={
                            <IconContainer>
                                <IconMoon color={colors.lightGrey} />
                            </IconContainer>
                        }
                    />
                    <TotemPrimaryButton isSecondaryStyle onClick={handleLogOut}>
                        Se déconnecter
                    </TotemPrimaryButton>
                    <div>Sensei</div>
                    <div>Victor Id : 5fc4bbfc33908a0012f85183</div>
                    <div>Linda Id : 5eb191ce05e030001187c94a</div>
                    <div>Theeb Id : 5eb191db05e030001187c95a</div>
                    <TotemInput
                        label="Id utilisateur pour sensei"
                        value={senseiUserId}
                        placeholder="UserId"
                        onChange={(value) => setSenseiUserId(value)}
                    />
                    <TotemCheckbox
                        checked={isStaff}
                        onChange={() => setIsStaff(!isStaff)}
                        label="QR code pour staff ?"
                    />
                    <TotemPrimaryButton isSecondaryStyle onClick={handleGetToken}>
                        Get gate token
                    </TotemPrimaryButton>
                    {token && (
                        <>
                            <CopyValue label="Token" value={token} /> <TotemLabel>QR Code</TotemLabel>
                            <QRCodeContainer>
                                <QRCode value={token} renderAs="canvas" size={200} />
                            </QRCodeContainer>
                        </>
                    )}
                </ScrollableContent>
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Content = styled.div`
    flex: 1;
    overflow: hidden;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const ScrollableContent = styled.div`
    padding: 15px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    max-width: 400px;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

const IconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

const QRCodeContainer = styled.div`
    padding: 10px;
    background-color: white;
    height: 220px;
    width: 220px;
`;

import React from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { DetailFormValue } from 'components/DetailsView/DetailFormValue';

import { TotemLabel } from '../TotemLabel';

type Item = {
    label: string;
    name: string;
    value?: number | null;
};

export const DetailInputTable = ({
    label,
    tableName,
    subtext,
    items,
}: {
    label?: string;
    tableName: string;
    subtext?: string;
    items: Item[];
}) => {
    const { register } = useFormContext();
    return (
        <Container>
            <TotemLabel>{label}</TotemLabel>
            <List hasLabel={!!label}>
                <tbody>
                    {items.map((item, index) => (
                        <tr key={index}>
                            <Cell>{item.label}</Cell>
                            <Cell>
                                {(
                                    <DetailFormValue
                                        defaultValue={item.value}
                                        {...register(`${tableName}.${item.name}`, { valueAsNumber: true })}
                                    />
                                ) ?? '⚠️ Valeur manquante'}
                            </Cell>
                        </tr>
                    ))}
                </tbody>
            </List>
            {subtext ? <SubText>{subtext}</SubText> : null}
        </Container>
    );
};

const Container = styled.div`
    display: inline-flex;
    flex-direction: column;
`;

const List = styled.table<{ hasLabel: boolean }>`
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
    border-spacing: 0;
    border-collapse: collapse;
    margin-top: ${({ hasLabel }: { hasLabel: boolean }) => (hasLabel ? '10px' : 0)};
`;

const Cell = styled.td`
    text-align: left;
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
    border-spacing: 1px;
    padding: 0 0 0 10px;
    font-weight: ${({ isBold }: { isBold?: boolean }) => (isBold ? 800 : 500)};
    color: ${({ theme }) => theme.textColor};
`;

const SubText = styled.div`
    margin-top: 5px;
    font-size: 14px;
    color: ${({ theme }) => theme.textColor};
`;

import React from 'react';

import { useFormContext, useFieldArray } from 'react-hook-form';

import { SectionColumn, SectionContainer, ColumnsSectionContainer } from 'components/DetailsView/Section';
import { DetailFormValue } from 'components/DetailsView/DetailFormValue';
import { TotemLabel } from 'components/TotemLabel';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

export const ContactsSection = () => {
    const { register } = useFormContext();

    const { fields, append, remove } = useFieldArray({
        name: 'contacts',
    });

    return (
        <SectionContainer title="Contacts" isInitiallyOpen>
            <ColumnsSectionContainer numberOfColumns={4}>
                {fields.map((field, index) => {
                    return (
                        <SectionColumn key={field.id}>
                            <DetailFormValue
                                label="Prénom"
                                {...register(`contacts.${index}.name` as const, { required: false })}
                                width="100%"
                                data-test={`supplier-contact-${index}-name`}
                            />
                            <DetailFormValue
                                label="Nom"
                                {...register(`contacts.${index}.surname` as const, { required: false })}
                                width="100%"
                                data-test={`supplier-contact-${index}-surname`}
                            />
                            <DetailFormValue
                                label="Numéro de téléphone"
                                {...register(`contacts.${index}.phone` as const, { required: false })}
                                width="100%"
                                data-test={`supplier-contact-${index}-phone`}
                            />
                            <DetailFormValue
                                label="Adresse email"
                                {...register(`contacts.${index}.email` as const, { required: false })}
                                width="100%"
                                data-test={`supplier-contact-${index}-email`}
                            />
                            <DetailFormValue
                                label="Description"
                                {...register(`contacts.${index}.description` as const, {
                                    required: false,
                                })}
                                width="100%"
                                data-test={`supplier-contact-${index}-description`}
                            />
                            <TotemPrimaryButton isSecondaryStyle type="button" onClick={() => remove(index)}>
                                Supprimer le contact #{index + 1}
                            </TotemPrimaryButton>
                        </SectionColumn>
                    );
                })}
                <SectionColumn>
                    {!fields.length ? <TotemLabel>Aucun contact renseigné</TotemLabel> : null}
                    <TotemPrimaryButton
                        type="button"
                        onClick={() => append({ name: '', surname: '', phone: '', email: '', description: '' })}
                    >
                        Ajouter un {!!fields.length && 'nouveau'} contact
                    </TotemPrimaryButton>
                </SectionColumn>
            </ColumnsSectionContainer>
        </SectionContainer>
    );
};

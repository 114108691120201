import { STOCK_VARIATION_FRAGMENT } from 'data/fragments/stockVariation';
import gql from 'graphql-tag';

export const CREATE_STOCK_VARIATION_MUTATION = gql`
    mutation CREATE_STOCK_VARIATION(
        $siteId: ID!
        $productId: ID!
        $delta: Int!
        $type: StockVariationType!
        $explanation: String!
    ) {
        createStockVariation(
            siteId: $siteId
            productId: $productId
            delta: $delta
            type: $type
            explanation: $explanation
        ) {
            error
            product {
                _id
            }
        }
    }
`;

export const CREATE_MULTI_STOCK_VARIATION_MUTATION = gql`
    mutation CREATE_MULTI_STOCK_VARIATION(
        $siteId: ID!
        $productIdsAndDeltas: [ProductIdAndDeltaInput!]!
        $type: StockVariationType!
        $explanation: String!
    ) {
        createMultiStockVariation(
            siteId: $siteId
            productIdsAndDeltas: $productIdsAndDeltas
            type: $type
            explanation: $explanation
        ) {
            error
            product {
                _id
            }
        }
    }
`;

export const SPLIT_STOCK_VARIATION_MUTATION = gql`
    mutation SPLIT_STOCK_VARIATION($stockVariationId: ID!, $newStockVariationInput: StockVariationInput!) {
        splitStockVariation(stockVariationId: $stockVariationId, newStockVariationInput: $newStockVariationInput) {
            error
            variations {
                ...StockVariationFragment
            }
        }
    }
    ${STOCK_VARIATION_FRAGMENT}
`;

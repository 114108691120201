import gql from 'graphql-tag';

export const EXPIRY_DATE_RECORD_FRAGMENT = gql`
    fragment ExpiryDateRecordFragment on ExpiryDateRecord {
        _id
        createdAt
        expiryDate
        initialQuantity
        quantityAtInventory
        productId
        product {
            brand
            name
            volume
        }
        siteId
        state
        updatedAt
    }
`;

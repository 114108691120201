import React from 'react';

import { CommonStatusTag } from 'components/CommonStatusTag';

import { colors } from 'constants/colors';

import { WarehouseDeliveryState } from 'data/__generated__';
import { WAREHOUSE_DELIVERY_STATES_LABELS } from '../constants/states';

const getColorsFromState = (state: WarehouseDeliveryState) => {
    const { blue, green, pantinGrey, lightGrey, black, yellow, red, pureWhite } = colors;

    switch (state) {
        case WarehouseDeliveryState.Aborted:
            return { backgroundColor: red, labelColor: pureWhite };
        case WarehouseDeliveryState.Archived:
            return { backgroundColor: pantinGrey, labelColor: pureWhite };
        case WarehouseDeliveryState.Created:
            return { backgroundColor: lightGrey, labelColor: black };
        case WarehouseDeliveryState.Delivered:
            return { backgroundColor: green, labelColor: black };
        case WarehouseDeliveryState.InProgress:
            return { backgroundColor: blue, labelColor: pureWhite };
        case WarehouseDeliveryState.Paused:
            return { backgroundColor: pantinGrey, labelColor: pureWhite };
        case WarehouseDeliveryState.Packaging:
            return { backgroundColor: black, labelColor: pureWhite };
        case WarehouseDeliveryState.Ready:
            return { backgroundColor: yellow, labelColor: black };
    }
};

export const StatusTag = ({ state, showHeading }: { state: WarehouseDeliveryState; showHeading?: boolean }) => {
    const { backgroundColor, labelColor } = getColorsFromState(state);
    return (
        <CommonStatusTag
            backgroundColor={backgroundColor}
            labelColor={labelColor}
            label={WAREHOUSE_DELIVERY_STATES_LABELS[state]}
            showHeading={showHeading}
        />
    );
};

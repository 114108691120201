import React from 'react';

import styled from 'styled-components';

import { LocationColumnType } from 'pages/Locations';
import { Column, ColumnType } from './Column';

export type TrelloViewDataType = {
    columns: ColumnType[] | LocationColumnType[];
};

export const TrelloView = ({
    initialData,
    selectedItemIds,
    setSelectedItemIds,
    columnWidth,
    padding = '15px',
}: {
    initialData: TrelloViewDataType;
    selectedItemIds?: string[];
    setSelectedItemIds?: (value: React.SetStateAction<string[]>) => void;
    columnWidth?: string;
    padding?: string;
}) => {
    return (
        <Container padding={padding}>
            {/*
            // @ts-ignore */}
            {initialData.columns.map((column, index) => {
                return (
                    <Column
                        key={index}
                        column={column}
                        selectedItemIds={selectedItemIds}
                        setSelectedItemIds={setSelectedItemIds}
                        columnWidth={columnWidth}
                    />
                );
            })}
        </Container>
    );
};

const Container = styled.div`
    display: inline-flex;
    flex: 1;
    height: 100%;
    padding: ${({ padding }: { padding: string }) => padding};

    & > :not(:first-child) {
        margin-left: 10px;
    }
`;

import React from 'react';

import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { DELETE_MICROSTORE_COLUMN_MUTATION } from 'data/mutations/microstoreColumn';
import {
    DELETE_MICROSTORE_COLUMN,
    DELETE_MICROSTORE_COLUMNVariables,
} from 'data/mutations/__generated__/DELETE_MICROSTORE_COLUMN';
import { GET_MICROSTORE_SITES_microstoreSitesWithColumnGroups_microstoreColumnGroups_columns } from 'data/queries/__generated__/GET_MICROSTORE_SITES';

import { Loader, LoaderModeType } from 'components/Loader';
import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

export function DeleteMicrostoreColumnPopup({
    isOpen,
    microstoreColumnToDelete,
    setIsOpen,
}: {
    isOpen: boolean;
    microstoreColumnToDelete: GET_MICROSTORE_SITES_microstoreSitesWithColumnGroups_microstoreColumnGroups_columns | null;
    setIsOpen: (arg: boolean) => void;
}) {
    const [deleteMicrostoreColumn, { loading: creationLoading }] = useMutation<
        DELETE_MICROSTORE_COLUMN,
        DELETE_MICROSTORE_COLUMNVariables
    >(DELETE_MICROSTORE_COLUMN_MUTATION);

    if (!microstoreColumnToDelete) {
        return (
            <TotemPopup
                title="VOULEZ-VOUS VRAIMENT ARCHIVER CETTE COLONNE ?"
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                contentOverflow="visible"
            >
                <SubmitContainer>
                    <div>Il y a eu une erreur</div>
                </SubmitContainer>
            </TotemPopup>
        );
    }

    async function handleSubmit() {
        if (!microstoreColumnToDelete) {
            return;
        }

        const { data } = await deleteMicrostoreColumn({
            variables: {
                microstoreColumnId: microstoreColumnToDelete._id,
            },
        });

        if (!data) {
            throw new Error('Une erreur est survenue lors de la suppression de la colonne microstore');
        }

        const {
            deleteMicrostoreColumnMutation: { errors },
        } = data;

        if (errors.length) {
            errors.forEach((error, index) => {
                toast.error(<span key={index}>Erreur : {error}</span>, { autoClose: false });
            });

            if (errors.length > 1) {
                toast.info('Cliquez pour fermer toutes les notifications', {
                    autoClose: false,
                    onClick: () => toast.dismiss(),
                });
            }
        }

        setIsOpen(false);
    }

    return (
        <TotemPopup
            title="VOULEZ-VOUS VRAIMENT ARCHIVER CETTE COLONNE ?"
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            contentOverflow="visible"
        >
            <SubmitContainer>
                <div>Attention, une fois archivée, vous ne pourrez plus consulter son contenu.</div>
                <ButtonsContainer>
                    <TotemPrimaryButton onClick={() => setIsOpen(false)}>
                        {creationLoading ? <Loader size="20px" mode={LoaderModeType.Spin} /> : 'Annuler'}
                    </TotemPrimaryButton>
                    <TotemPrimaryButton onClick={handleSubmit}>
                        {creationLoading ? <Loader size="20px" mode={LoaderModeType.Spin} /> : 'Confirmer'}
                    </TotemPrimaryButton>
                </ButtonsContainer>
            </SubmitContainer>
        </TotemPopup>
    );
}

const SubmitContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    color: ${({ theme }) => theme.infoTextColor};

    > :not(:first-child) {
        margin-top: 15px;
    }
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    > :not(:first-child) {
        margin-left: 50px;
    }
`;

import React, { useRef } from 'react';

import ReactToPrint from 'react-to-print';
import { FcPrint } from 'react-icons/fc';
import styled from 'styled-components';

import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

export type BoxToPrint = {
    bundleNumber: number;
    hasOnsiteSetup: boolean;
    isFreefood: boolean;
    roundColor: string;
    roundName: string;
    label: string;
};

function getFlowEmoji(flow: string): string | null {
    switch (flow) {
        case 'bakery':
            return '🥐';
        case 'dry':
            return '🥜';
        case 'fresh':
            return '❄️';
        case 'fruit':
            return '🍌';
        default:
            return null;
    }
}

export const PrintBoxTags = ({ boxesToPrint }: { boxesToPrint: BoxToPrint[] }) => {
    const componentRef = useRef(null);

    return (
        <Container>
            <ReactToPrint
                trigger={() => {
                    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                    // to the root node of the returned component as it will be overwritten.
                    return (
                        <span>
                            <TotemPrimaryButton disabled={!boxesToPrint.length}>
                                <PrintButtonContent>
                                    <FcPrint size={20} />
                                    <PrintLabel>Imprimer les étiquettes</PrintLabel>
                                </PrintButtonContent>
                            </TotemPrimaryButton>
                        </span>
                    );
                }}
                content={() => componentRef.current}
            />
            <HiddenComponent>
                <Printable ref={componentRef}>
                    <BoxTags>
                        {boxesToPrint.map((box, index) => {
                            const { bundleNumber, hasOnsiteSetup, isFreefood, roundColor, roundName, label } = box;

                            const [tagLabel, , deliveryDate, boxType, boxNumber, flow] = label.split(' • ');

                            const tagSubLabel = [deliveryDate, boxType, boxNumber].join(' • ');

                            return (
                                <BoxTagContainer key={index} hasBackground={isFreefood}>
                                    <TagHeader>
                                        <span>{bundleNumber}</span>
                                        {hasOnsiteSetup ? null : <span>🛍️</span>}
                                        <span>{getFlowEmoji(flow)}</span>
                                    </TagHeader>
                                    <TagContent>
                                        <Label isTextLong={tagLabel.length > 23} hasOnsiteSetup={hasOnsiteSetup}>
                                            {tagLabel}
                                        </Label>
                                        <SubLabel>{tagSubLabel}</SubLabel>
                                    </TagContent>
                                    <TagFooter>
                                        <Round roundColor={roundColor}>{roundName}</Round>
                                    </TagFooter>
                                </BoxTagContainer>
                            );
                        })}
                    </BoxTags>
                </Printable>
            </HiddenComponent>
        </Container>
    );
};

const Container = styled.div`
    color: black;
`;

const PrintButtonContent = styled.div`
    display: flex;
    align-items: center;
`;

const PrintLabel = styled.span`
    margin-left: 5px;
`;

const HiddenComponent = styled.div`
    display: none;
`;

const Printable = styled.div`
    @page {
        size: landscape;
        margin: 12mm 15mm;
    }
`;

const BoxTags = styled.div`
    display: table;
    width: 100%;
`;

const BoxTagContainer = styled.div<{ hasBackground: boolean }>`
    position: relative;
    display: inline-flex;
    flex-direction: column;
    align-items: space-between;
    width: 33%;
    padding: 30px 10px;
    border: 1px solid black;
    min-height: 350px;
    break-inside: avoid;
    background-color: ${({ hasBackground }) => (hasBackground ? 'lightblue' : 'transparent')};
`;

const TagHeader = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 36px;
    color: black;
`;

const Round = styled.div<{ roundColor: string }>`
    display: flex;
    align-items: center;
    font-size: 30px;
    font-weight: 800;
    border-radius: ${({ theme }) => theme.borderRadius};
    background-color: ${({ roundColor }) => roundColor};
    color: white;
    padding: 5px 10px;
`;

const TagContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    margin-top: 5px;
`;

const Label = styled.span<{ hasOnsiteSetup: boolean; isTextLong: boolean }>`
    font-size: ${({ isTextLong }) => (isTextLong ? 34 : 44)}px;
    background-color: ${({ hasOnsiteSetup }) => (hasOnsiteSetup ? 'transparent' : 'palevioletred')};
    font-weight: 800;
    color: black;
    text-align: center;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
`;

const SubLabel = styled.span`
    font-size: 24px;
    color: black;
    text-align: center;
`;

const TagFooter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
`;

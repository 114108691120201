import React from 'react';

import styled from 'styled-components';

import { GET_MICROSTORE_SITES_WITH_REASSORTMENT_STOCK_microstoreSitesWithReassortmentInfo_freshReassortmentInfos_stockByCategories } from 'data/queries/__generated__/GET_MICROSTORE_SITES_WITH_REASSORTMENT_STOCK';
import { PercentageBar } from 'components/PercentageBar';

export const ReassortmentStockByCategoriesProgressDisplay = ({
    reassortmentStockByCategories,
    reassortmentStockTargetByCategories,
}: {
    reassortmentStockByCategories: Omit<
        GET_MICROSTORE_SITES_WITH_REASSORTMENT_STOCK_microstoreSitesWithReassortmentInfo_freshReassortmentInfos_stockByCategories,
        '__typename'
    >;
    reassortmentStockTargetByCategories: Omit<
        GET_MICROSTORE_SITES_WITH_REASSORTMENT_STOCK_microstoreSitesWithReassortmentInfo_freshReassortmentInfos_stockByCategories,
        '__typename'
    >;
}) => {
    return (
        <Container>
            <PercentageBar
                label={`Entrées : ${reassortmentStockByCategories.starter}/${reassortmentStockTargetByCategories.starter}`}
                percent={
                    reassortmentStockTargetByCategories.starter
                        ? (100 * reassortmentStockByCategories.starter) / reassortmentStockTargetByCategories.starter
                        : 100
                }
            />
            <PercentageBar
                label={`Petites faims : ${reassortmentStockByCategories.smallBites}/${reassortmentStockTargetByCategories.smallBites}`}
                percent={
                    reassortmentStockTargetByCategories.smallBites
                        ? (100 * reassortmentStockByCategories.smallBites) /
                          reassortmentStockTargetByCategories.smallBites
                        : 100
                }
            />
            <PercentageBar
                label={`Plats : ${reassortmentStockByCategories.main}/${reassortmentStockTargetByCategories.main}`}
                percent={
                    reassortmentStockTargetByCategories.main
                        ? (100 * reassortmentStockByCategories.main) / reassortmentStockTargetByCategories.main
                        : 100
                }
            />
            <PercentageBar
                label={`Desserts : ${reassortmentStockByCategories.dessert}/${reassortmentStockTargetByCategories.dessert}`}
                percent={
                    reassortmentStockTargetByCategories.dessert
                        ? (100 * reassortmentStockByCategories.dessert) / reassortmentStockTargetByCategories.dessert
                        : 100
                }
            />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & > :not(:first-child) {
        margin-left: 15px;
    }
`;

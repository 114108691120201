import React, { useState } from 'react';
import Switch from 'react-switch';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { PAGES } from 'constants/pages';

import { CategoryState, useGetCategoriesQuery } from 'data/__generated__';

import { CopyValue } from 'components/CopyValue';
import { FieldToDisplay, ListView } from 'components/ListView';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { Header, HeaderTitle } from 'components/Header';
import { Loader } from 'components/Loader';

import { PageTitle } from 'components/PageTitle';
import { TotemImage } from 'components/TotemImage';
import { StatusTag } from 'pages/Categories/components/StatusTag';
import { TotemLabel } from 'components/TotemLabel';
import { colors } from 'constants/colors';
import { CommonStatusTag } from 'components/CommonStatusTag';

type FormattedCategoryType = {
    _id: string;
    image: JSX.Element;
    indexDisplay: number;
    shouldShowSubcategories: JSX.Element;
    state: JSX.Element;
    title: string;
    uniqueId: JSX.Element;
};

export const Categories = () => {
    const [shouldHideArchived, setShouldHideArchived] = useState(true);
    const { loading: categoriesLoading, data: categoriesData, error: categoriesError } = useGetCategoriesQuery();
    if (categoriesLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (!categoriesData || categoriesError) {
        throw new Error('Une erreur est survenue lors de la récupération des catégories');
    }

    const { categories } = categoriesData;

    const CATEGORY_FIELDS_TO_DISPLAY: FieldToDisplay<FormattedCategoryType>[] = [
        {
            fieldName: 'image',
            label: 'Icone',
        },
        {
            fieldName: 'title',
            label: 'Nom',
        },
        {
            fieldName: 'uniqueId',
            label: 'Identifiant unique',
        },
        {
            fieldName: 'state',
            label: 'Statut',
        },
        {
            fieldName: 'indexDisplay',
            label: "Index d'affichage",
        },
        {
            fieldName: 'shouldShowSubcategories',
            label: 'Afficher sous-catégories',
        },
    ];

    const filteredCategories = categories.filter(({ state }) =>
        shouldHideArchived ? state !== CategoryState.Archived : true,
    );

    const formattedCategories: FormattedCategoryType[] = filteredCategories.map(
        ({ _id, title, imageUrl = '', indexDisplay, state, shouldShowSubcategories }) => ({
            _id,
            uniqueId: <CopyValue value={_id} />,
            title,
            image: <TotemImage src={imageUrl} alt={title} size={50} />,
            state: <StatusTag state={state} />,
            indexDisplay,
            shouldShowSubcategories: (
                <CommonStatusTag
                    backgroundColor={shouldShowSubcategories ? colors.green : colors.red}
                    labelColor={colors.pureWhite}
                    label={shouldShowSubcategories ? 'Oui' : 'Non'}
                />
            ),
        }),
    );

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.categories} />
                </HeaderTitle>
                <ButtonsContainer>
                    <LabelContainer>
                        <Label>Cacher les catégories archivées</Label>
                    </LabelContainer>
                    <Switch
                        onChange={() => setShouldHideArchived(!shouldHideArchived)}
                        checked={shouldHideArchived}
                        onColor={colors.green}
                        offColor={colors.pantinGrey}
                        data-test="hide-archived-products-switch"
                    />
                    <Link to="/category/create">
                        <TotemPrimaryButton>Créer une catégorie</TotemPrimaryButton>
                    </Link>
                </ButtonsContainer>
            </Header>
            <Content>
                <ListView<FormattedCategoryType>
                    fieldsToDisplay={CATEGORY_FIELDS_TO_DISPLAY}
                    items={formattedCategories}
                    linkBasePath="/category/"
                    keyExtractor={(item) => item._id}
                />
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    ${TotemPrimaryButton} {
        margin-left: 5px;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    flex: 1;
    overflow: hidden;
`;

const LabelContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const Label = styled(TotemLabel)`
    margin-right: 5px;
`;

import { CreateMobilePopupInput } from 'data/__generated__';

import { UpsertMobilePopupFormValues } from './UpsertMobilePopupForm';

export const formatFormValues = (values: UpsertMobilePopupFormValues): CreateMobilePopupInput => {
    return {
        ...values,
        siteIds: values.siteIds.split('\n').filter((siteId) => siteId.trim() !== ''),
        startsAt: new Date(values.startsAt).getTime(),
        endsAt: new Date(values.endsAt).getTime(),
        image: values.image ?? undefined,
    };
};

export const stringifyToFormValues = (values: CreateMobilePopupInput): UpsertMobilePopupFormValues => {
    return {
        title: values.title ?? '',
        body: values.body ?? '',
        siteIds: (values.siteIds ?? []).join('\n'),
        startsAt: new Date(values.startsAt).toISOString().split('T')[0],
        endsAt: new Date(values.endsAt).toISOString().split('T')[0],
    };
};

import gql from 'graphql-tag';
import { STOCK_TRANSFER_FRAGMENT } from '../fragments/stockTransfer';

export const CREATE_STOCK_TRANSFER_MUTATION = gql`
    mutation CREATE_STOCK_TRANSFER(
        $transferDate: String!
        $originSiteId: ID!
        $destinationSiteId: ID!
        $products: [StockTransferProductInput!]!
    ) {
        createStockTransfer(
            transferDate: $transferDate
            originSiteId: $originSiteId
            destinationSiteId: $destinationSiteId
            products: $products
        ) {
            success
            error
            stockTransfer {
                _id
            }
        }
    }
`;

export const UPDATE_STOCK_TRANSFER_MUTATION = gql`
    mutation UPDATE_STOCK_TRANSFER(
        $stockTransferId: ID!
        $transferDate: String!
        $originSiteId: ID!
        $destinationSiteId: ID!
        $products: [StockTransferProductInput!]!
        $shouldUpdateStockImmediately: Boolean!
    ) {
        updateStockTransfer(
            stockTransferId: $stockTransferId
            transferDate: $transferDate
            originSiteId: $originSiteId
            destinationSiteId: $destinationSiteId
            products: $products
            shouldUpdateStockImmediately: $shouldUpdateStockImmediately
        ) {
            success
            error
            stockTransfer {
                ...StockTransferFragment
            }
        }
    }
    ${STOCK_TRANSFER_FRAGMENT}
`;

export const VALIDATE_STOCK_TRANSFERS_MUTATION = gql`
    mutation VALIDATE_STOCK_TRANSFERS($stockTransferIds: [ID!]!) {
        validateStockTransfers(stockTransferIds: $stockTransferIds) {
            errors
            stockTransfers {
                ...StockTransferFragment
            }
        }
    }
    ${STOCK_TRANSFER_FRAGMENT}
`;

export const VALIDATE_STOCK_TRANSFERS_AT_DATE_MUTATION = gql`
    mutation VALIDATE_STOCK_TRANSFERS_AT_DATE($date: String!) {
        validateStockTransfersAtDate(date: $date) {
            errors
            stockTransfers {
                ...StockTransferFragment
            }
        }
    }
    ${STOCK_TRANSFER_FRAGMENT}
`;

export const ABORT_STOCK_TRANSFERS_MUTATION = gql`
    mutation ABORT_STOCK_TRANSFERS($stockTransferIds: [ID!]!) {
        abortStockTransfers(stockTransferIds: $stockTransferIds) {
            errors
            stockTransfers {
                ...StockTransferFragment
            }
        }
    }
    ${STOCK_TRANSFER_FRAGMENT}
`;

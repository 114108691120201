import gql from 'graphql-tag';

export const GET_TERMS_RECORDS_QUERY = gql`
    query GET_TERMS_RECORDS {
        termsRecords {
            _id
            createdAt
            termsOfUseUrl
        }
    }
`;

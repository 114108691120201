import React from 'react';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';

import { LAUNCH_FRESH_RESTOCKING_MUTATION } from 'data/mutations/microstoreColumn';
import { LAUNCH_FRESH_RESTOCKING } from 'data/mutations/__generated__/LAUNCH_FRESH_RESTOCKING';

import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { Loader, LoaderModeType } from 'components/Loader';

export function LaunchRestockingFreshPopup({
    isOpen,
    setIsOpen,
}: {
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
}) {
    const [launchFreshRestocking, { loading }] = useMutation<LAUNCH_FRESH_RESTOCKING>(LAUNCH_FRESH_RESTOCKING_MUTATION);

    async function handleClick() {
        try {
            const { data } = await launchFreshRestocking({
                onError: (error) => {
                    toast.error(`Impossible de lancer le restocking fresh: ${error}`);
                },
            });

            if (!data) {
                throw new Error('Une erreur est survenue lors de la création du restocking');
            }

            const { launchFreshRestockingMutation: result } = data;
            if (result.success) {
                toast.success('Le restocking fresh a été lancé, un message sera posté sur slack quand il sera terminé');
                setIsOpen(false);
            } else {
                result?.errors?.forEach((error, index) => {
                    toast.error(<span key={index}>Erreur : {error}</span>, { autoClose: false });
                });

                if (result?.errors?.length && result?.errors?.length > 1) {
                    toast.info('Cliquez pour fermer toutes les notifications', {
                        autoClose: false,
                        onClick: () => toast.dismiss(),
                    });
                }
            }
        } catch (error) {
            toast.error(`Impossible de lancer le restocking fresh: ${error}`);
        }
    }

    return (
        <TotemPopup title="Lancer le restocking fresh" isOpen={isOpen} setIsOpen={setIsOpen} contentOverflow="visible">
            <SubmitContainer>
                <div>
                    <b>Attention,</b> il ne faut pas relancer le restocking si il a déjà été lancé. En moyenne, cela
                    prend 15 minutes.
                </div>
                <div>
                    Lorsque le restocking est terminé vous recevrez un message sur le channel slack{' '}
                    <b>#restocking-fresh-report</b>
                </div>
                <ButtonsContainer>
                    <TotemPrimaryButton onClick={() => setIsOpen(false)}>Annuler</TotemPrimaryButton>
                    <TotemPrimaryButton disabled={loading} onClick={handleClick}>
                        {loading ? <Loader size="20px" mode={LoaderModeType.Spin} /> : 'Lancer le restocking fresh'}
                    </TotemPrimaryButton>
                </ButtonsContainer>
            </SubmitContainer>
        </TotemPopup>
    );
}
const SubmitContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    color: ${({ theme }) => theme.infoTextColor};

    > :not(:first-child) {
        margin-top: 15px;
    }
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    > :not(:first-child) {
        margin-left: 50px;
    }
`;

import { ReassortmentStockTargetRatiosFormInput } from 'pages/MicrostoreColumns/MicrostoreColumnDetails/SiteUpdateForm';
import { formatToNumber } from 'pages/Products/ProductImports/helpers/utils';

export function getNumericValue(value: string | number): number {
    return typeof value === 'string' ? formatToNumber(value) : value;
}

export function stockTargetRatiosCheck(
    reassortmentStockTargetRatios: ReassortmentStockTargetRatiosFormInput,
): string | undefined {
    const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];

    for (const day of days) {
        // @ts-ignore
        const ratioValue = reassortmentStockTargetRatios[day] as string | number;

        if (typeof ratioValue === 'string') {
            const value = formatToNumber(ratioValue);

            if (isNaN(value)) {
                return `Une valeur de ratio de consommation devrait être un chiffre, reçu ${ratioValue}`;
            }

            if (value < 0 || value > 1) {
                return `Une valeur de ratio de consommation devrait être entre 0 et 1, reçu ${ratioValue}`;
            }
        }
    }
}

import React from 'react';

import styled from 'styled-components';

import { TodayShelf } from './TodayShelf';
import { TomorrowShelf } from './TomorrowShelf';

import {
    MicrostoreColumnGroupFormValues,
    MicrostoreColumnFormValues,
    MicrostoreLocationFormValues,
    MicrostoreProductsStockInfoFormValues,
} from './FormHelper/DataMapper';
import { BirthdayDate } from 'helpers/dateTimes';
import { GET_MICROSTORE_SITE_siteWithLocationInfo } from 'data/queries/__generated__/GET_MICROSTORE_SITE';
import { GetMicrostoreSitesWithLocationsInfoQuery } from 'data/__generated__';

export const ColumnLocationSelector = ({
    canBeUpdated = true,
    displayOnlyTomorrowForPrint,
    hideTodayInfo,
    hideTomorrowInfo,
    isToday,
    microstoreColumnGroupFormValuesArray,
    microstoreProductsStockInfo,
    getMicrostoreProductsStockInfoLoading,
    nextSiteDeliveryDate,
    earliestDLCDateToKeepForStockCalculation,
    selectedColumn,
    selectedLocationPosition,
    selectedShelfIndex,
    setIsToday,
    setSelectedLocationPosition,
    setSelectedShelfIndex,
    site,
    switchColumn,
    updateSelectedLocation,
}: {
    canBeUpdated?: boolean;
    displayOnlyTomorrowForPrint: boolean;
    hideTodayInfo?: boolean;
    hideTomorrowInfo?: boolean;
    isToday: boolean;
    microstoreColumnGroupFormValuesArray: MicrostoreColumnGroupFormValues[];
    microstoreProductsStockInfo: MicrostoreProductsStockInfoFormValues | null;
    getMicrostoreProductsStockInfoLoading?: boolean;
    nextSiteDeliveryDate: BirthdayDate | null;
    earliestDLCDateToKeepForStockCalculation: BirthdayDate | null;
    selectedColumn: MicrostoreColumnFormValues;
    selectedLocationPosition: { column: number; row: number } | null;
    selectedShelfIndex: number | null;
    setIsToday: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedLocationPosition: React.Dispatch<React.SetStateAction<{ column: number; row: number } | null>>;
    setSelectedShelfIndex: React.Dispatch<React.SetStateAction<number | null>>;
    site: GET_MICROSTORE_SITE_siteWithLocationInfo | GetMicrostoreSitesWithLocationsInfoQuery['sites'][number];
    switchColumn: MicrostoreColumnFormValues | null;
    updateSelectedLocation: (values: Partial<MicrostoreLocationFormValues>) => void;
}) => {
    const allSiteLocations = microstoreColumnGroupFormValuesArray.flatMap((columnGroup) =>
        columnGroup.columns.flatMap((column) =>
            column.shelves.flatMap((shelf) => shelf.locationTable.flatMap((locationRow) => locationRow)),
        ),
    );

    return (
        <Column>
            {selectedColumn.shelves.map((shelf, shelfIndex) => {
                return (
                    <ShelfContainer key={shelfIndex}>
                        <TodayShelf
                            allSiteLocations={allSiteLocations}
                            canBeUpdated={canBeUpdated}
                            hideTodayInfo={hideTodayInfo}
                            isToday={isToday}
                            microstoreProductsStockInfo={microstoreProductsStockInfo}
                            getMicrostoreProductsStockInfoLoading={getMicrostoreProductsStockInfoLoading}
                            selectedColumn={selectedColumn}
                            selectedLocationPosition={selectedLocationPosition}
                            selectedShelfIndex={selectedShelfIndex}
                            setIsToday={setIsToday}
                            setSelectedLocationPosition={setSelectedLocationPosition}
                            setSelectedShelfIndex={setSelectedShelfIndex}
                            shelf={shelf}
                            shelfIndex={shelfIndex}
                            switchColumn={switchColumn}
                        />
                        <TomorrowShelf
                            allSiteLocations={allSiteLocations}
                            canBeUpdated={canBeUpdated}
                            displayOnlyTomorrowForPrint={displayOnlyTomorrowForPrint}
                            hideTomorrowInfo={hideTomorrowInfo}
                            isToday={isToday}
                            microstoreProductsStockInfo={microstoreProductsStockInfo}
                            nextSiteDeliveryDate={nextSiteDeliveryDate}
                            earliestDLCDateToKeepForStockCalculation={earliestDLCDateToKeepForStockCalculation}
                            selectedColumn={selectedColumn}
                            selectedLocationPosition={selectedLocationPosition}
                            selectedShelfIndex={selectedShelfIndex}
                            setIsToday={setIsToday}
                            setSelectedLocationPosition={setSelectedLocationPosition}
                            setSelectedShelfIndex={setSelectedShelfIndex}
                            shelf={shelf}
                            shelfIndex={shelfIndex}
                            site={site}
                            switchColumn={switchColumn}
                            updateSelectedLocation={updateSelectedLocation}
                        />
                    </ShelfContainer>
                );
            })}
        </Column>
    );
};

const Column = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    flex: 1 0 0;
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
    border-radius: ${({ theme }) => theme.borderRadius};

    & > :not(:first-child) {
        border-top: 2px solid ${({ theme }) => theme.darkBorderColor};
        margin-top: 5px;
        padding-top: 5px;
    }

    @media print {
        display: block;

        & > :not(:first-child) {
            border-top: 4px solid ${({ theme }) => theme.darkBorderColor};
        }
    }
`;

const ShelfContainer = styled.div`
    flex: 1 0 0;
    display: flex;
    flex-direction: column;
    break-inside: avoid;
`;

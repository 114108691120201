import { TextareaHTMLAttributes } from 'react';
import styled from 'styled-components';

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    name: string;
}

interface CustomTextAreaProps {
    centerText?: boolean;
    width?: string;
    height?: string;
}

interface BasicTextAreaProps extends TextAreaProps, CustomTextAreaProps {}

export const BasicTextArea = styled.textarea<BasicTextAreaProps | CustomTextAreaProps>`
    display: inline-flex;
    align-items: center;
    border-radius: ${({ theme }) => theme.borderRadius};
    outline: 0px;
    color: rgb(33, 0, 45);
    font-size: 16px;
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
    padding: 12px;
    text-align: ${({ centerText }) => (centerText ? 'center' : 'left')};
    width: ${({ width }) => width || '100%'};
    height: ${({ height }) => height || 'auto'};
    font-family: inherit;
    font-size: inherit;
    resize: none;
    overflow-y: scroll;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    &[type='number'] {
        -moz-appearance: textfield;
    }
`;

import React, { useState } from 'react';

import { useForm, FormProvider } from 'react-hook-form';
import { FaSave } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { PAGES } from 'constants/pages';

import { Header, HeaderTitle } from 'components/Header';
import { Loader, LoaderModeType } from 'components/Loader';
import { PageTitle } from 'components/PageTitle';

import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { RitualFormContent } from './components/RitualFormContent';
import { RitualRepetition, useCreateRitualMutation } from 'data/__generated__';
import { RitualPopup } from 'pages/Rituals/components/RitualPopup';

type FormData = {
    activeDate: string;
    activeWeekdays: boolean[];
    associatedDiscount: number;
    expiringProductsOnly: boolean;
    image: File;
    messagesSetId: string | null;
    name: string;
    repetition: RitualRepetition;
    requiresParticipation: boolean;
    restrictedBadgeIds: string[];
    restrictedCategoryIds: string[];
    restrictedOrganizationIds: string[];
    restrictedProductIds: string[];
    restrictedSiteIds: string[];
    restrictedSubcategoryIds: string[];
    restrictedTagIds: string[];
    restrictedUserIds: string;
    timeWindow: {
        start: string;
        end: string;
    };
    supplierPartnershipId: string | null;
};

export const RitualCreate = () => {
    const navigate = useNavigate();
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [chosenTemplate, setChosenTemplate] = useState<{ name: string; imageUrl: string } | null>(null);
    const [createRitual, { loading: createRitualLoading }] = useCreateRitualMutation();

    const methods = useForm<FormData>({
        defaultValues: {
            repetition: RitualRepetition.Weekly,
            restrictedBadgeIds: [],
            restrictedCategoryIds: [],
            restrictedOrganizationIds: [],
            restrictedProductIds: [],
            restrictedSiteIds: [],
            restrictedSubcategoryIds: [],
            restrictedTagIds: [],
            restrictedUserIds: '',
            supplierPartnershipId: null,
        },
    });

    const {
        handleSubmit,
        formState: { isDirty },
    } = methods;

    const onSubmit = handleSubmit(async (fields) => {
        const { activeDate, activeWeekdays, repetition } = fields;
        const variables = {
            createRitualInput: {
                ...fields,
                activeDate: repetition === RitualRepetition.Punctual ? activeDate : null,
                activeWeekdays: repetition === RitualRepetition.Weekly ? activeWeekdays : null,
                restrictedUserIds: fields.restrictedUserIds ? fields.restrictedUserIds.trim().split(',') : [],
                imageUrl: chosenTemplate?.imageUrl,
            },
        };
        const { data } = await createRitual({
            variables,
        });

        if (data) {
            const {
                createRitual: { createdRitual, success, errors },
            } = data;
            if (success && createdRitual) {
                toast.success('Le rituel a bien été créé !');
                navigate(`/ritual/${createdRitual._id}`);
            } else {
                if (errors) {
                    errors.forEach((error) => {
                        toast.error(error, { autoClose: false });
                    });

                    if (errors.length > 1) {
                        toast.info('Cliquez pour fermer toutes les notifications', {
                            autoClose: false,
                            onClick: () => toast.dismiss(),
                        });
                    }
                } else {
                    throw Error("Une erreur inconnue s'est produite");
                }
            }
        } else {
            throw Error("Une erreur inconnue s'est produite");
        }
    });

    return (
        <Container>
            <FormProvider {...methods}>
                <Form onSubmit={onSubmit}>
                    <Header>
                        <HeaderTitle>
                            <PageTitle page={PAGES.ritualCreate} />
                        </HeaderTitle>
                        <CTAsContainer>
                            <TotemPrimaryButton type="button" onClick={() => setIsPopupOpen(true)}>
                                Modèle
                            </TotemPrimaryButton>
                            <TotemPrimaryButton type="submit" minWidth="78px" disabled={!isDirty}>
                                {createRitualLoading ? (
                                    <Loader size="18px" mode={LoaderModeType.Spin} />
                                ) : (
                                    <>
                                        <FaSave size={13} />
                                        <SaveLabel>Créer</SaveLabel>
                                    </>
                                )}
                            </TotemPrimaryButton>
                            <Link to="/rituals">
                                <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                            </Link>
                        </CTAsContainer>
                    </Header>
                    <Content>
                        <ScrollableContent>
                            <RitualFormContent chosenTemplate={chosenTemplate} />
                        </ScrollableContent>
                    </Content>
                </Form>
            </FormProvider>
            <RitualPopup isOpen={isPopupOpen} setIsOpen={setIsPopupOpen} setChosenTemplate={setChosenTemplate} />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex: 1;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
`;

const CTAsContainer = styled.div`
    display: flex;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const SaveLabel = styled.span`
    margin-left: 5px;
`;

const Content = styled.div`
    flex: 1;
    overflow: hidden;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const ScrollableContent = styled.div`
    padding: 15px;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

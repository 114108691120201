import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import { generatePath, Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { PAGES } from 'constants/pages';

import { GetMicrostoreSiteQuery, ReassortmentFlow } from 'data/__generated__';
import {
    VALIDATE_MICROSTORES_REASSORTMENT,
    VALIDATE_MICROSTORES_REASSORTMENTVariables,
} from 'data/mutations/__generated__/VALIDATE_MICROSTORES_REASSORTMENT';
import { VALIDATE_MICROSTORES_REASSORTMENT_MUTATION } from 'data/mutations/microstoreColumn';
import { GET_MICROSTORE_SITES_microstoreSitesWithColumnGroups } from 'data/queries/__generated__/GET_MICROSTORE_SITES';

import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { Loader, LoaderModeType } from 'components/Loader';
import { StatusTag as StockTransferStatusTag } from 'pages/StockTransfers/components/StatusTag';
import { StatusTag as DeliveryStatusTag } from 'pages/Deliveries/components/StatusTag';
import { TotemCheckbox } from 'components/TotemCheckbox';
import { TotemLabel } from 'components/TotemLabel';
import { dateFromString, formatDateAsAnniversary } from 'helpers/dateTimes';

export function TransferInfoPopup({
    isOpen,
    microstoreSites,
    nextMSDeliveryDate,
    setIsOpen,
    site,
}: {
    isOpen: boolean;
    microstoreSites: GET_MICROSTORE_SITES_microstoreSitesWithColumnGroups[] | null;
    nextMSDeliveryDate: string;
    setIsOpen: (arg: boolean) => void;
    site: GetMicrostoreSiteQuery['siteWithLocationInfo'];
}) {
    const nextMSDeliveryDayIndex = (dateFromString(nextMSDeliveryDate, true).getDay() + 6) % 7;
    const openAndActiveSiteIdsForNextDayDelivery =
        microstoreSites
            ?.filter(
                ({ deliveryDays, launchDate }) =>
                    deliveryDays[nextMSDeliveryDayIndex] &&
                    launchDate &&
                    nextMSDeliveryDate >=
                        formatDateAsAnniversary({ dateTime: dateFromString(launchDate), useNewFormat: true }),
            )
            .map(({ _id }) => _id) || [];

    const currentSiteDeliveryDays = microstoreSites?.find((msSite) => msSite._id === site._id)?.deliveryDays || [];

    const [selectedSiteIds, setSelectedSiteIds] = useState<string[]>([]);

    const [validateMicrostoresReassortmentMutation, { loading: validateMicrostoreReassortmentLoading }] = useMutation<
        VALIDATE_MICROSTORES_REASSORTMENT,
        VALIDATE_MICROSTORES_REASSORTMENTVariables
    >(VALIDATE_MICROSTORES_REASSORTMENT_MUTATION);

    function toggleSiteSelection(siteIdToToggle: string) {
        if (selectedSiteIds.includes(siteIdToToggle)) {
            setSelectedSiteIds(selectedSiteIds.filter((siteId) => siteId !== siteIdToToggle));
        } else {
            setSelectedSiteIds([...selectedSiteIds, siteIdToToggle]);
        }
    }

    async function validateMicrostoreReassortment({
        reassortmentFlow,
        siteIds,
    }: {
        reassortmentFlow: ReassortmentFlow;
        siteIds: string[];
    }) {
        const hasConfirmed = window.confirm('Vous allez créer ou updater un transfert de stock, êtes vous sûr ?');
        if (!hasConfirmed) {
            return;
        }

        const { data } = await validateMicrostoresReassortmentMutation({
            variables: {
                reassortmentFlow,
                siteIds,
            },
        });

        if (!data) {
            throw new Error('Une erreur est survenue lors de la génération du transfert de stock');
        }

        const {
            validateMicrostoresReassortmentMutation: { errors, newSites },
        } = data;

        if (errors.length) {
            errors.forEach((error, index) => {
                toast.error(<span key={index}>Erreur : {error}</span>, { autoClose: false });
            });

            if (errors.length > 1) {
                toast.info('Cliquez pour fermer toutes les notifications', {
                    autoClose: false,
                    onClick: () => toast.dismiss(),
                });
            }
        }

        if (newSites) {
            toast.success(
                `Le(s) transfert(s) de stock a/ont bien été créé(s) / modifié(s) ! (Nb : ${newSites.length})`,
            );
        }
    }

    return (
        <TotemPopup
            title="Transfert de stocks"
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            contentOverflow="auto"
            position="fixed"
        >
            <TotemLabel>Site grisé - pas de livraison le prochain jour de livraison de sites</TotemLabel>
            <Content>
                <Section>
                    <SectionTitle>Information pour : {site.name}</SectionTitle>
                    <ColumnsContainer>
                        {[ReassortmentFlow.DryAndFruit, ReassortmentFlow.Fresh].map((reassortmentFlow) => {
                            const stockTransferForReassortmentType = site.microstoreReassortmentStockTransfers.find(
                                (microstoreReassortmentStockTransfer) =>
                                    microstoreReassortmentStockTransfer.reassortmentFlow === reassortmentFlow,
                            );

                            const flowLabel = `Réassort ${reassortmentFlow}`;
                            return (
                                <ColumnContainer key={reassortmentFlow}>
                                    <span>Transfert de stock pour : {flowLabel}</span>
                                    {!stockTransferForReassortmentType ? (
                                        <>
                                            <span>Transfert de Stock</span>
                                            <span>Aucun</span>
                                        </>
                                    ) : (
                                        <>
                                            <StockTransferAndDeliveryLink
                                                to={generatePath(PAGES.stockTransferDetails.url, {
                                                    stockTransferId: stockTransferForReassortmentType._id,
                                                })}
                                                target="_blank"
                                            >
                                                Transfert de Stock
                                            </StockTransferAndDeliveryLink>
                                            <span>{stockTransferForReassortmentType.transferDate}</span>
                                            <StockTransferStatusTag state={stockTransferForReassortmentType.state} />
                                            {stockTransferForReassortmentType.delivery ? (
                                                <>
                                                    <StockTransferAndDeliveryLink
                                                        to={generatePath(PAGES.deliveryDetails.url, {
                                                            deliveryId: stockTransferForReassortmentType.delivery._id,
                                                        })}
                                                        target="_blank"
                                                    >
                                                        Livraison
                                                    </StockTransferAndDeliveryLink>
                                                    <DeliveryStatusTag
                                                        state={stockTransferForReassortmentType.delivery.state}
                                                    />
                                                </>
                                            ) : null}
                                        </>
                                    )}

                                    <TotemPrimaryButton
                                        type="button"
                                        disabled={
                                            validateMicrostoreReassortmentLoading ||
                                            !currentSiteDeliveryDays[nextMSDeliveryDayIndex]
                                        }
                                        onClick={() =>
                                            validateMicrostoreReassortment({
                                                reassortmentFlow,
                                                siteIds: [site._id],
                                            })
                                        }
                                    >
                                        {validateMicrostoreReassortmentLoading ? (
                                            <Loader size="18px" mode={LoaderModeType.Spin} />
                                        ) : (
                                            <span>
                                                Créer ou modifier pour {flowLabel} de {site.name}
                                            </span>
                                        )}
                                    </TotemPrimaryButton>
                                </ColumnContainer>
                            );
                        })}
                    </ColumnsContainer>
                </Section>
                <Section>
                    <SectionTitle>Génération des transferts de stocks</SectionTitle>
                    {microstoreSites ? (
                        <>
                            <TotemCheckbox
                                checked={openAndActiveSiteIdsForNextDayDelivery.every((siteId) =>
                                    selectedSiteIds.includes(siteId),
                                )}
                                onChange={() => {
                                    if (selectedSiteIds.length !== openAndActiveSiteIdsForNextDayDelivery.length) {
                                        setSelectedSiteIds(openAndActiveSiteIdsForNextDayDelivery);
                                    } else {
                                        setSelectedSiteIds([]);
                                    }
                                }}
                                label="Sélectionner tous les sites ouverts"
                            />
                            <CheckboxesContainer>
                                {microstoreSites.map((site) => (
                                    <CheckboxContainer key={site._id}>
                                        <TotemCheckbox
                                            checked={selectedSiteIds.some((siteId) => siteId === site._id)}
                                            onChange={() => toggleSiteSelection(site._id)}
                                            label={`${site.name}${
                                                openAndActiveSiteIdsForNextDayDelivery.includes(site._id)
                                                    ? ''
                                                    : ' (fermé)'
                                            }`}
                                            disabled={!site.deliveryDays[nextMSDeliveryDayIndex]}
                                        />
                                    </CheckboxContainer>
                                ))}
                            </CheckboxesContainer>
                            <ColumnsContainer>
                                {[ReassortmentFlow.DryAndFruit, ReassortmentFlow.Fresh].map((reassortmentFlow) => {
                                    const flowLabel = `Réassort ${reassortmentFlow}`;
                                    return (
                                        <ColumnContainer key={reassortmentFlow}>
                                            <span>Transfert de stock pour : {flowLabel}</span>
                                            <TotemPrimaryButton
                                                type="button"
                                                title="Créer ou modifier les transferts de stock sélectionnés"
                                                disabled={validateMicrostoreReassortmentLoading}
                                                onClick={() =>
                                                    validateMicrostoreReassortment({
                                                        reassortmentFlow,
                                                        siteIds: selectedSiteIds,
                                                    })
                                                }
                                            >
                                                {validateMicrostoreReassortmentLoading ? (
                                                    <Loader size="18px" mode={LoaderModeType.Spin} />
                                                ) : (
                                                    <span>
                                                        Créer ou modifier pour {flowLabel} du/des site(s) sélectionné(s)
                                                    </span>
                                                )}
                                            </TotemPrimaryButton>
                                        </ColumnContainer>
                                    );
                                })}
                            </ColumnsContainer>
                        </>
                    ) : (
                        <Loader mode={LoaderModeType.Spin} />
                    )}
                </Section>
            </Content>
        </TotemPopup>
    );
}

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    & > :not(:first-child) {
        margin-top: 10px;
        border-top: 1px solid ${({ theme }) => theme.darkBorderColor};
    }
`;

const Section = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

const SectionTitle = styled.div`
    text-align: center;
    font-size: 20px;
    font-weight: 800;
`;

const StockTransferAndDeliveryLink = styled(Link)`
    font-weight: 800;
    color: ${({ theme }) => theme.ctaPrimaryColor};
    text-decoration: none;

    &:hover {
        color: ${({ theme }) => theme.textColor};
    }
`;

const CheckboxesContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const CheckboxContainer = styled.div`
    flex-basis: 25%;
`;

const ColumnsContainer = styled.div`
    display: flex;
    flex-direction: row;

    & > :not(:first-child) {
        margin-left: 20px;
    }
`;

const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

import React, { FunctionComponent } from 'react';

import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { MobilePopupsQuery, useDeleteMobilePopupMutation } from 'data/__generated__';
import { MOBILE_POPUPS_QUERY } from 'data/queries/mobilePopup';

type DeleteConfirmationModalProps = {
    setIsOpen: (arg: boolean) => void;
    isOpen: boolean;
    mobilePopupId: string;
};
export const DeleteConfirmationModal: FunctionComponent<DeleteConfirmationModalProps> = ({
    setIsOpen,
    isOpen,
    mobilePopupId,
}) => {
    const [deleteMobilePopup] = useDeleteMobilePopupMutation({
        update: (cache, { data }, { variables }) => {
            if (!data?.deleteMobilePopup) return;

            const mobilePopupsQuery = cache.readQuery<MobilePopupsQuery>({
                query: MOBILE_POPUPS_QUERY,
            });

            if (!mobilePopupsQuery) return;

            cache.writeQuery<MobilePopupsQuery>({
                query: MOBILE_POPUPS_QUERY,
                data: {
                    mobilePopups: mobilePopupsQuery.mobilePopups.filter(
                        (mobilePopup) => mobilePopup._id !== variables?.id,
                    ),
                },
            });
        },
        onCompleted: (data) => {
            if (!data?.deleteMobilePopup) return;

            setIsOpen(false);
        },
    });

    return (
        <TotemPopup isOpen={isOpen} title="Supprimer la popup" setIsOpen={setIsOpen}>
            <p>Êtes-vous sûr de vouloir supprimer cette popup ?</p>
            <TotemPrimaryButton
                onClick={() => {
                    deleteMobilePopup({ variables: { id: mobilePopupId } });
                }}
            >
                Oui
            </TotemPrimaryButton>
        </TotemPopup>
    );
};

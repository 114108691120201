import React from 'react';
import { useFormContext } from 'react-hook-form';
import type { FieldArrayWithId } from 'react-hook-form';

import styled from 'styled-components';
import { FaArrowUp, FaTrash } from 'react-icons/fa';

import { colors } from 'constants/colors';

import { PricesArray } from 'pages/Products/ProductDetails/components/PricesArray';
import { SupplierType } from 'pages/Products/constants/defaultProduct';

import { DetailFormValue } from 'components/DetailsView';
import { TotemLabel } from 'components/TotemLabel';

type SupplierItem = FieldArrayWithId<
    {
        suppliers: SupplierType[];
    },
    'suppliers',
    'id'
>;

export const SupplierInfo = ({
    index,
    isArchived,
    supplierItem,
    supplierName,
    remove,
    move,
}: {
    index: number;
    isArchived: boolean;
    supplierItem: SupplierItem;
    supplierName?: string;
    remove: (index: number) => void;
    move: (index: number, targetPosition: number) => void;
}) => {
    const { register } = useFormContext();
    const { _id, code, name } = supplierItem;

    return (
        <SupplierContainer>
            <input {...register(`suppliers.${index}._id` as const)} type="hidden" defaultValue={_id} />
            <SupplierHeader>
                <SupplierLabel>{`Fournisseur Nᵒ${index + 1}`}</SupplierLabel>
                <IconContainer onClick={() => move(index, 0)}>
                    <FaArrowUp size={16} color={colors.pureWhite} />
                </IconContainer>
                <IconContainer onClick={() => remove(index)}>
                    <FaTrash data-test="trash-icon" size={16} color={colors.pureWhite} />
                </IconContainer>
            </SupplierHeader>
            {isArchived ? <WarningText>Le fournisseur est archivé</WarningText> : null}
            <InfoSection>
                <DetailFormValue label="Nom" defaultValue={supplierName || name} disabled />
                <DetailFormValue
                    label="Code fournisseur"
                    placeholder="Code fournisseur"
                    defaultValue={code || ''}
                    width="100%"
                    {...register(`suppliers.${index}.code` as const)}
                />
            </InfoSection>
            <ItemContainer key={supplierItem.id}>
                <PricesArray supplierIndex={index} supplierName={supplierName || name || 'Nom inconnu'} />
            </ItemContainer>
        </SupplierContainer>
    );
};

const SupplierLabel = styled(TotemLabel)`
    margin-bottom: 15px;
    flex: 1;
`;

const InfoSection = styled.div`
    display: flex;
    flex: 1;
    margin-top: 5px;

    & > :not(:first-child) {
        margin-left: 15px;
    }
`;

const ItemContainer = styled.div`
    position: relative;
    display: flex;
`;

const SupplierContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

const SupplierHeader = styled.div`
    display: flex;
`;

const WarningText = styled.div`
    color: ${({ theme }) => theme.errorColor};
`;

const IconContainer = styled.div`
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    min-width: 35px;
    height: 35px;
    border-radius: 35px;
    background-color: ${({ theme }) => theme.ctaPrimaryColor};
    cursor: pointer;
    margin-left: 2px;
`;

import React from 'react';

import styled from 'styled-components';

import { ProductFlow } from 'data/__generated__';
import { BUNDLE_TYPES_LABELS } from 'pages/Bundles/constants/types';
import { colors } from 'constants/colors';

const getColorsFromType = (type: ProductFlow | null) => {
    const { pantinGrey, green, orange, primaryYellow, pureWhite, black, emeraldBlue } = colors;

    switch (type) {
        case ProductFlow.Fresh:
            return { backgroundColor: emeraldBlue, borderColor: emeraldBlue, labelColor: pureWhite };
        case ProductFlow.Dry:
            return { backgroundColor: green, borderColor: green, labelColor: pureWhite };
        case ProductFlow.Bakery:
            return { backgroundColor: orange, borderColor: orange, labelColor: black };
        case ProductFlow.Fruit:
            return { backgroundColor: primaryYellow, borderColor: primaryYellow, labelColor: black };
        default:
            return { backgroundColor: pureWhite, borderColor: pantinGrey, labelColor: black };
    }
};

export const BundleTypeTag = ({
    type,
    label = '',
    customMargin,
}: {
    type: ProductFlow | null;
    label?: string;
    customMargin?: string;
}) => {
    const { backgroundColor, labelColor, borderColor } = getColorsFromType(type);
    return (
        <TagContainer color={backgroundColor} borderColor={borderColor} customMargin={customMargin}>
            <TagLabel color={labelColor}>{type === null ? label : BUNDLE_TYPES_LABELS[type]}</TagLabel>
        </TagContainer>
    );
};

const TagContainer = styled.div<{ color: string; borderColor: string; customMargin?: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 1px;
    background-color: ${({ color }: { color: string }) => color}dd;
    padding: 5px 15px;
    border-radius: 20px;
    ${({ customMargin }) => customMargin && `margin: ${customMargin}`};
`;

const TagLabel = styled.div`
    color: ${({ color }: { color: string }) => color};
    font-size: 14px;
`;

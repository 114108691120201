import styled from 'styled-components';

export const TotemPrimaryButton = styled.button<{ isSecondaryStyle?: boolean; minWidth?: string }>`
    display: inline-block;
    text-align: center;
    padding: 16px 32px 18px 32px;
    border: 1px solid
        ${({ theme, isSecondaryStyle }) =>
            isSecondaryStyle ? theme.ctaSecondaryBorderColor : theme.ctaPrimaryBorderColor};
    color: ${({ theme, isSecondaryStyle }) =>
        isSecondaryStyle ? theme.ctaSecondaryTextColor : theme.ctaPrimaryTextColor};
    background-color: ${({ theme, isSecondaryStyle }) =>
        isSecondaryStyle ? theme.ctaSecondaryColor : theme.ctaPrimaryColor};
    ${({ minWidth }) => minWidth && `min-width: ${minWidth}`};
    font-size: 15px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 2px;
    border-radius: ${({ theme }) => theme.borderRadius};
    cursor: pointer;

    &[disabled] {
        color: ${({ theme }) => theme.disabledTextColor};
        background-color: ${({ theme }) => theme.disabledColor};
        border: 1px solid ${({ theme }) => theme.disabledColor};
        cursor: default;
        &:hover {
            background: ${({ theme }) => theme.disabledColor};
        }
    }
    &:hover {
        background: ${({ isSecondaryStyle, theme }) =>
            isSecondaryStyle ? theme.ctaSecondaryHoveredColor : theme.ctaPrimaryHoveredColor};
    }
`;

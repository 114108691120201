import React from 'react';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { GET_DETAILED_EQUIPMENT_detailedEquipment } from 'data/queries/__generated__/GET_DETAILED_EQUIPMENT';
import { UPDATE_EQUIPMENT_STATE_MUTATION } from 'data/mutations/equipment';
import { EquipmentState } from 'data/__generated__';

import { ColumnsSectionContainer, SectionColumn } from 'components/DetailsView/Section';
import { StateHistory } from 'components/DetailsView';
import { StatusTag } from 'pages/Equipments/components/StatusTag';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

export const StatesSection = ({
    equipment,
    isDirty,
}: {
    equipment: GET_DETAILED_EQUIPMENT_detailedEquipment;
    isDirty: boolean;
}) => {
    const { _id: equipmentId, stateHistory, state } = equipment;

    const [updateEquipmentState] = useMutation(UPDATE_EQUIPMENT_STATE_MUTATION);

    const formattedStateHistory = stateHistory.map((stateRecord) => ({
        ...stateRecord,
        state: <StatusTag state={stateRecord.state} />,
    }));

    const STATE_CHANGE_ACTIONS = [
        {
            label: 'Passer équipment en statut régulier',
            stateToSet: EquipmentState.Regular,
            isDisabled: state === EquipmentState.Regular,
        },
        {
            label: "Archiver l'équipment",
            stateToSet: EquipmentState.Archived,
            isDisabled: state === EquipmentState.Archived,
        },
    ];

    const handleStateUpdate = async (stateToSet: EquipmentState) => {
        const { data } = await updateEquipmentState({
            variables: {
                stateToSet,
                equipmentId,
            },
        });

        if (!data) {
            throw Error("Une erreur inconnue s'est produite");
        }

        const {
            updateEquipmentState: { success, errors, equipment: updatedEquipment },
        } = data;

        if (success && updatedEquipment) {
            toast.success('Le statut du produit a bien été modifié !');
            return;
        }

        if (errors) {
            errors.forEach((error: string) => {
                toast.error(error, { autoClose: false });
                return;
            });
        }

        throw Error("Une erreur inconnue s'est produite");
    };

    const updateState = (e: React.MouseEvent<HTMLElement, MouseEvent>, label: string, stateToSet: EquipmentState) => {
        e.preventDefault();
        const hasConfirmed = window.confirm(`Êtes-vous sûr.e de vouloir ${label.toLowerCase()} ?`);
        if (hasConfirmed) {
            handleStateUpdate(stateToSet);
        }
    };

    return (
        <SeparatorContainer>
            <ColumnsSectionContainer numberOfColumns={3}>
                <StateHistory label="Historique de statuts" records={formattedStateHistory} />
                <StatusTag state={state} showHeading={true} />

                <SectionColumn>
                    {isDirty ? (
                        <WarningText>
                            ⚠️ Veuillez vous assurer d'avoir sauvegardé ou annulé vos ajustements avant de mettre à jour
                            le statut
                        </WarningText>
                    ) : null}
                    <OverlaySectionColumn isDirty={isDirty}>
                        {STATE_CHANGE_ACTIONS.map((stateChange, index) => {
                            const { stateToSet, label, isDisabled } = stateChange;

                            return (
                                <TotemPrimaryButton
                                    key={index}
                                    type="button"
                                    onClick={(event) => updateState(event, label, stateToSet)}
                                    disabled={isDisabled || isDirty}
                                >
                                    {label}
                                </TotemPrimaryButton>
                            );
                        })}
                    </OverlaySectionColumn>
                </SectionColumn>
            </ColumnsSectionContainer>
        </SeparatorContainer>
    );
};

const SeparatorContainer = styled.div`
    margin-top: 30px;
    padding-top: 15px;
    border-top: ${({ theme }) => `1px solid ${theme.lightBorderColor}`};
`;

const WarningText = styled.div`
    color: ${({ theme }) => theme.textColor};
    font-weight: 800;
`;

const OverlaySectionColumn = styled(SectionColumn)`
    opacity: ${({ isDirty }: { isDirty: boolean }) => (isDirty ? 0.4 : 1)};
`;

import React from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';
import styled from 'styled-components';

import { PAGES } from '../../constants/pages';

import { Header, HeaderTitle } from 'components/Header';
import { PageTitle } from 'components/PageTitle';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

import { BadgeForm } from './components/BadgeForm';
import { SelectedOption } from 'components/TotemSelect';
import { BadgeType, useGetBadgeQuery, useUpdateBadgeMutation } from 'data/__generated__';
import { BADGE_TYPES } from './constants/types';

type FormType = {
    _id: string;
    name: string;
    typeOption: SelectedOption<BadgeType>;
    image: File | null;
    imageUrl: string | null;
};

type ParamTypes = {
    badgeId: string;
};

export function BadgeUpdate() {
    const { badgeId = '' } = useParams<ParamTypes>();
    const navigate = useNavigate();

    const {
        loading: badgeLoading,
        data: badgeData,
        error: badgeError,
    } = useGetBadgeQuery({
        variables: { badgeId },
    });

    const [updateBadge] = useUpdateBadgeMutation();
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>, formData: FormType) => {
        e.preventDefault();
        const { name, typeOption, image, _id } = formData;
        if (!typeOption) {
            toast.error("L'un des champs n'a pas été renseigné");
            return;
        }

        const { value: type } = typeOption;
        const result = await updateBadge({ variables: { badge: { _id, name, type, image } } });
        const { data } = result;
        if (data) {
            const {
                updateBadge: { success },
            } = data;
            if (success) {
                toast.success(`Le badge "${formData.name}" a bien été créé !`);
                navigate('/badges');
            } else {
                const {
                    updateBadge: { error },
                } = data;
                if (error) {
                    toast.error(`L'erreur suivante est survenue : ${error}`);
                } else {
                    throw Error("Une erreur inconnue s'est produite");
                }
            }
        } else {
            throw Error("Une erreur inconnue s'est produite");
        }
    };

    function canISubmit(submitData: FormType): boolean {
        return submitData.name !== '';
    }

    if (badgeLoading || badgeError || !badgeData) {
        return null;
    }

    const initialFormData = {
        _id: badgeData.badge._id,
        name: badgeData.badge.name,
        typeOption: { value: badgeData.badge.type, label: BADGE_TYPES[badgeData.badge.type] },
        image: null,
        imageUrl: `${window.__TOTENV__.BASE_CLOUDFRONT_IMAGE_URL}${badgeData?.badge.imageUrl}`,
    };

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.badgeCreate} />
                </HeaderTitle>
                <Link to="/badges">
                    <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                </Link>
            </Header>
            <Content>
                <BadgeForm<FormType>
                    handleSubmit={handleSubmit}
                    initialFormData={initialFormData}
                    canISubmit={canISubmit}
                    submitMessage="Mettre à jour le badge"
                />
            </Content>
        </Container>
    );
}

const Container = styled.div`
    flex-direction: column;
    flex: 1;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Content = styled.div`
    padding: 15px;
`;

import React from 'react';
import styled from 'styled-components';
import { FaLongArrowAltDown } from 'react-icons/fa';

import { GET_MICROSTORE_SITES_WITH_LOCATIONS_INFO_sites } from 'data/queries/__generated__/GET_MICROSTORE_SITES_WITH_LOCATIONS_INFO';

export const ColumnSelector = ({
    setSelectedColumnId,
    selectedColumnId,
    site,
}: {
    setSelectedColumnId: React.Dispatch<React.SetStateAction<string | null>>;
    selectedColumnId: string | null;
    site: GET_MICROSTORE_SITES_WITH_LOCATIONS_INFO_sites;
}) => {
    return (
        <Container>
            <ContentContainer>
                <ColumnsContainer>
                    {site.microstoreColumnGroups.map((microstoreColumnGroup) => {
                        return (
                            <ColumnGroupContainer key={microstoreColumnGroup._id}>
                                <ColumnGroupName>{microstoreColumnGroup.name}</ColumnGroupName>
                                {microstoreColumnGroup.columns
                                    .filter(({ columnIdToReplace }) => !columnIdToReplace)
                                    .map((microstoreColumn) => {
                                        const isSelected = microstoreColumn._id === selectedColumnId;
                                        const replacementColumn = microstoreColumnGroup.columns.find(
                                            ({ columnIdToReplace }) => columnIdToReplace === microstoreColumn._id,
                                        );
                                        const isReplacementSelected = replacementColumn?._id === selectedColumnId;

                                        return (
                                            <ColumnContainer key={microstoreColumn._id}>
                                                <ColumnSelectorDisplay
                                                    onClick={() => {
                                                        setSelectedColumnId(microstoreColumn._id);
                                                    }}
                                                    isSelected={isSelected}
                                                >
                                                    <ColumnNumber isSelected={isSelected}>
                                                        {microstoreColumn.positionInColumnGroup + 1}
                                                    </ColumnNumber>
                                                    <ColumnName>{microstoreColumn.name}</ColumnName>
                                                </ColumnSelectorDisplay>
                                                {replacementColumn ? (
                                                    <>
                                                        <FaLongArrowAltDown />
                                                        <ColumnSelectorDisplay
                                                            key={replacementColumn._id}
                                                            onClick={() => {
                                                                setSelectedColumnId(replacementColumn._id);
                                                            }}
                                                            isSelected={isReplacementSelected}
                                                        >
                                                            <ColumnNumber isSelected={isReplacementSelected}>
                                                                {microstoreColumn.positionInColumnGroup + 1}
                                                            </ColumnNumber>
                                                            <ColumnName>{replacementColumn.name}</ColumnName>
                                                        </ColumnSelectorDisplay>
                                                    </>
                                                ) : null}
                                            </ColumnContainer>
                                        );
                                    })}
                            </ColumnGroupContainer>
                        );
                    })}
                </ColumnsContainer>
            </ContentContainer>
        </Container>
    );
};

const Container = styled.div`
    color: ${({ theme }) => theme.textColor};
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    overflow: auto;
`;

const ContentContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 5px;
    width: 100%;

    &:first-child {
        flex-grow: 1;
    }

    &:not(:first-child) {
        border-top: 1px solid ${({ theme }) => theme.darkBorderColor};
    }
`;

const ColumnsContainer = styled.div`
    flex-grow: 1;
    margin-top: 15px;
    border-top: 1px solid ${({ theme }) => theme.darkBorderColor};
`;

const ColumnGroupContainer = styled.div`
    padding: 10px;
    border-bottom: 1px solid ${({ theme }) => theme.lightBorderColor};
`;

const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    border: black dashed 1px;
    border-radius: 10px;
`;

const ColumnSelectorDisplay = styled.div<{ isSelected: boolean }>`
    margin-top: 15px;
    cursor: pointer;
    color: ${({ theme, isSelected }) => (isSelected ? theme.ctaPrimaryColor : 'inherit')};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
`;

const ColumnNumber = styled.div<{ isSelected: boolean }>`
    height: 40px;
    width: 40px;
    border-radius: 100%;
    border: 2px solid ${({ theme, isSelected }) => (isSelected ? theme.ctaPrimaryColor : theme.lightBorderColor)};
    box-shadow: ${({ theme, isSelected }) => (isSelected ? theme.boxShadow : 'none')};
    background-color: ${({ theme }) => theme.cardBackgroundColor};
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
`;

const ColumnGroupName = styled.div`
    font-weight: 600;
    text-align: center;
`;

const ColumnName = styled.div`
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
`;

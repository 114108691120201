import React from 'react';
import styled from 'styled-components';

import { formatPrice } from '@totem/constants';

import { DetailedTransactionFragmentFragment, PaymentMethodName } from 'data/__generated__';
import { PaymentMethodStatusTag } from './PaymentMethodStatusTag';

function getPaymentMethodType(paymentMethodName: string | null): string {
    switch (paymentMethodName) {
        case PaymentMethodName.CreditCardPaymentMethod:
            return 'Carte bancaire';
        case PaymentMethodName.CreditPaymentMethod:
            return 'Crédits';
        case PaymentMethodName.MealVoucherPaymentMethod:
            return 'Titre restaurant';
        case PaymentMethodName.SubventionPaymentMethod:
            return 'Subvention';
        case PaymentMethodName.MealPlanPaymentMethod:
            return 'Réduction formule';
        case PaymentMethodName.RitualPaymentMethod:
            return 'Rituel';
        case PaymentMethodName.ThirdPartyPaymentMethod:
            return 'Paiement externe';
        default:
            return 'Moyen de paiement inconnu';
    }
}

export const PaymentMethods = ({
    paymentMethods,
}: {
    paymentMethods: DetailedTransactionFragmentFragment['paymentMethods'];
}) => {
    return (
        <>
            {paymentMethods?.map(
                ({ name, paymentMethodName, provider, type, amountUsed, status, explanation = '' }, index) => (
                    <PaymentMethodRow key={index}>
                        <PaymentMethodStatusTag status={status} />
                        <DetailsContainer>
                            {formatPrice(amountUsed || 0)} {getPaymentMethodType(paymentMethodName)}
                            {provider && `, fournisseur : ${provider}`}
                            {type && `, type : ${type}`}
                            {name && `, nom : ${name}`}
                            <strong>{explanation && ` : ${explanation}`}</strong>
                        </DetailsContainer>
                    </PaymentMethodRow>
                ),
            )}
        </>
    );
};

const DetailsContainer = styled.span`
    margin-left: 5px;
`;

const PaymentMethodRow = styled.div`
    display: flex;
    align-items: baseline;

    &:not(:first-child) {
        margin-top: 5px;
    }
`;

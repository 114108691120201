import { gql } from '@apollo/client';
import { TAG_MUTATION_FRAGMENT } from 'data/fragments/tag';

export const CREATE_TAG_MUTATION = gql`
    mutation CREATE_TAG($tag: CreateTagInput!) {
        createTag(tag: $tag) {
            tag {
                ...TagMutationFragment
            }
            success
            error
        }
    }
    ${TAG_MUTATION_FRAGMENT}
`;

export const UPDATE_TAG_MUTATION = gql`
    mutation UPDATE_TAG($tag: UpdateTagInput!) {
        updateTag(tag: $tag) {
            tag {
                ...TagMutationFragment
            }
            success
            error
        }
    }
    ${TAG_MUTATION_FRAGMENT}
`;

import React from 'react';
import styled from 'styled-components';

export const Spinner = ({ size = 20 }: { size?: number }) => {
    return (
        <Loader size={size}>
            <SpinnerAnimation size={size} />
        </Loader>
    );
};

const Loader = styled.div<{ size?: number }>`
    position: relative;
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    border: 4px solid ${({ theme }) => theme.lightBorderColor};
    border-radius: 50%;
`;

const SpinnerAnimation = styled.div<{ size?: number }>`
    position: absolute;
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    border: 4px solid #fff;
    margin: -4px;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    border-color: ${({ theme }) => theme.pictureBackgroundColor} transparent transparent transparent;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

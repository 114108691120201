import gql from 'graphql-tag';

export const GET_TOTEMS_OF_ORGANIZATION_QUERY = gql`
    query GET_TOTEMS_OF_ORGANIZATION($organizationId: String!) {
        totemsOfOrganization(organizationId: $organizationId) {
            _id
            name
            number
        }
    }
`;

export const GET_DETAILED_TOTEMS_OF_ORGANIZATION_QUERY = gql`
    query GET_DETAILED_TOTEMS_OF_ORGANIZATION($organizationId: String!) {
        totemsOfOrganization(organizationId: $organizationId) {
            _id
            accessTime
            address
            address_details
            city
            country
            deliveryInstructions
            equipments {
                equipmentId
                quantity
                comment
            }
            latitude
            longitude
            mainUser {
                _id
                fullName
            }
            microstore {
                _id
                name
            }
            minimumOrderAmountInfo {
                minimumBakeryAmount
                minimumDryAmount
                minimumFreshAmount
                minimumFruitAmount
                minimumTotalAmount
            }
            name
            needSignature
            number
            organizationId
            primaryTotemId
            recentOrders {
                _id
                deliveryDate
                deliveryState
                state
            }
            salesforceId
            state
            stateHistory {
                createdAt
                createdBy
                createdByName
                state
            }
            type
            zipcode
        }
    }
`;

export const GET_TOTEMS_WITH_EQUIPMENTS_QUERY = gql`
    query GET_TOTEMS_WITH_EQUIPMENTS {
        totemsWithEquipments {
            _id
            number
            equipments {
                equipmentId
                quantity
            }
            organization {
                _id
                name
            }
        }
    }
`;
export const GET_ACTIVE_TOTEMS_QUERY = gql`
    query GET_ACTIVE_TOTEMS {
        activeTotems {
            _id
            address
            city
            country
            name
            number
            organizationId
            zipcode
        }
    }
`;

import React, { useState, useContext } from 'react';
import { IconClipboardList } from '@tabler/icons';
import { Link } from 'react-router-dom';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';

import styled, { ThemeContext } from 'styled-components';

import { Loader, LoaderModeType } from './Loader';
import { TotemCheckbox } from './TotemCheckbox';

export type AcceptedFieldElement = JSX.Element | string | number | JSX.Element[];

type SortFunction<T> = (itemA: FormattedField<T>, itemB: FormattedField<T>) => number;

export type FieldToDisplay<T> = {
    fieldName: keyof T;
    label: string;
    sortFunction?: SortFunction<T>;
    maxWidth?: string;
    minWidth?: string;
    sublabel?: string;
};

type FormattedField<T> = Record<keyof T, AcceptedFieldElement>;

export function ListView<T>({
    fieldsToDisplay,
    isSmallDisplay,
    items,
    linkBasePath,
    linkSuffixPath,
    keyExtractor,
    loadMore,
    hasMore,
    onItemClick,
    highlightedKeys,
    selectedKeys,
    setSelectedKeys,
}: {
    fieldsToDisplay: FieldToDisplay<T>[];
    isSmallDisplay?: boolean;
    items: FormattedField<T>[];
    linkBasePath?: string;
    linkSuffixPath?: string;
    keyExtractor?: (item: FormattedField<T>) => AcceptedFieldElement;
    loadMore?: () => void;
    hasMore?: boolean;
    onItemClick?: (item: FormattedField<T>) => void;
    highlightedKeys?: string[];
    selectedKeys?: string[];
    setSelectedKeys?: (value: string[]) => void;
}) {
    const theme = useContext(ThemeContext);
    const [isFetchingMore, setIsFetchingMore] = useState(false);
    const [selectedSort, setSelectedSort] = useState<SortFunction<T> | null>(null);
    const [isSortReverse, setIsSortReverse] = useState<boolean>(false);

    linkSuffixPath = linkSuffixPath || '';

    const keyExtractorClosure = (index: number, item: FormattedField<T>, areKeysUnique: boolean) => {
        const key = keyExtractor && areKeysUnique ? keyExtractor(item) : index.toString();
        if (typeof key === 'string') return key;
        return index.toString();
    };

    const handleScroll = async (e: React.UIEvent<HTMLElement>) => {
        const element = e.currentTarget;
        const scrollThreshold = 0.8;
        if (
            !isFetchingMore &&
            hasMore &&
            loadMore &&
            element.scrollTop > scrollThreshold * (element.scrollHeight - element.clientHeight)
        ) {
            setIsFetchingMore(true);
            await loadMore();
            setIsFetchingMore(false);
        }
    };

    function toggleSelection(uniqueKey: string) {
        if (!selectedKeys || !setSelectedKeys) {
            return;
        }
        if (selectedKeys.includes(uniqueKey)) {
            setSelectedKeys(selectedKeys.filter((selectedKey) => selectedKey !== uniqueKey));
        } else {
            setSelectedKeys([...selectedKeys, uniqueKey]);
        }
    }

    // if we have the same key twice, the table does weird stuff. This is a check to prevent them.
    const areKeysUnique = items.every(
        (value, valueIndex, array) =>
            array.findIndex(
                (otherValue, otherValueIndex) =>
                    keyExtractorClosure(valueIndex, value, true) ===
                    keyExtractorClosure(otherValueIndex, otherValue, true),
            ) === valueIndex,
    );

    const sortedItemsAsc = selectedSort ? [...items].sort(selectedSort) : [...items];
    const sortedItems = isSortReverse ? sortedItemsAsc.reverse() : sortedItemsAsc;

    return (
        <TableContainer onScroll={handleScroll}>
            {!areKeysUnique ? (
                <ErrorMessage>
                    La liste contient deux items similaires, cela n'est pas normal, contactez un dev
                </ErrorMessage>
            ) : null}
            <ItemsTable>
                <TableHeader>
                    <tr>
                        {selectedKeys && setSelectedKeys ? <HeaderCell /> : null}
                        {fieldsToDisplay.map(({ label, sortFunction, maxWidth, minWidth, sublabel }, index) => (
                            <HeaderCell
                                key={index}
                                isSmallDisplay={isSmallDisplay}
                                maxWidth={maxWidth}
                                minWidth={minWidth}
                                title={label}
                            >
                                {sortFunction ? (
                                    <SortButton
                                        type="button"
                                        isSelected={selectedSort === sortFunction}
                                        onClick={() => {
                                            if (selectedSort === sortFunction) {
                                                if (isSortReverse) {
                                                    setSelectedSort(null);
                                                    setIsSortReverse(false);
                                                } else {
                                                    setIsSortReverse(true);
                                                }
                                            } else {
                                                setSelectedSort(() => sortFunction);
                                                setIsSortReverse(false);
                                            }
                                        }}
                                    >
                                        {selectedSort !== sortFunction ? (
                                            <FaSort />
                                        ) : isSortReverse ? (
                                            <FaSortDown />
                                        ) : (
                                            <FaSortUp />
                                        )}
                                    </SortButton>
                                ) : null}
                                {label}
                                {sublabel ? <Sublabel isSmallDisplay={isSmallDisplay}>{sublabel}</Sublabel> : null}
                            </HeaderCell>
                        ))}
                        {linkBasePath ? (
                            <HeaderCell>
                                <b>Détails</b>
                            </HeaderCell>
                        ) : null}
                    </tr>
                </TableHeader>
                <tbody>
                    {sortedItems.map((item, index) => {
                        const uniqueKey = keyExtractorClosure(index, item, areKeysUnique);
                        const detailsLink = `${linkBasePath}${uniqueKey}${linkSuffixPath}`;
                        const isSelected = selectedKeys?.includes(uniqueKey) ?? false;
                        return (
                            <Row
                                key={uniqueKey}
                                isClickable={!!onItemClick}
                                onClick={() => {
                                    if (onItemClick) {
                                        onItemClick(item);
                                    }
                                }}
                                isHighlighted={!!highlightedKeys?.includes(uniqueKey)}
                            >
                                {selectedKeys && setSelectedKeys ? (
                                    <Cell isSmallDisplay={isSmallDisplay}>
                                        <TotemCheckbox
                                            checked={isSelected}
                                            onChange={() => toggleSelection(uniqueKey)}
                                        />
                                    </Cell>
                                ) : null}
                                {fieldsToDisplay.map(({ fieldName, maxWidth, minWidth }, index) => (
                                    <Cell
                                        key={index}
                                        isSmallDisplay={isSmallDisplay}
                                        maxWidth={maxWidth}
                                        minWidth={minWidth}
                                    >
                                        {item[fieldName]}
                                    </Cell>
                                ))}
                                {linkBasePath ? (
                                    <Cell isSmallDisplay={isSmallDisplay}>
                                        <Link to={detailsLink} data-test="more-details">
                                            <DetailsIconContainer>
                                                <IconClipboardList size={22} color={theme.textColor} />
                                            </DetailsIconContainer>
                                        </Link>
                                    </Cell>
                                ) : null}
                            </Row>
                        );
                    })}
                </tbody>
            </ItemsTable>
            {isFetchingMore ? (
                <div>
                    <Loader mode={LoaderModeType.Spin} />
                </div>
            ) : null}
        </TableContainer>
    );
}

const TableContainer = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: auto;
    z-index: 0;
`;

const ErrorMessage = styled.div`
    color: ${({ theme }) => theme.errorColor};
`;

const ItemsTable = styled.table`
    width: 100%;
    font-size: 17px;
    border-spacing: 0px;
`;

const TableHeader = styled.thead`
    position: relative;
`;

const HeaderCell = styled.th<{ maxWidth?: string; minWidth?: string; isSmallDisplay?: boolean }>`
    position: sticky;
    top: 0;
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
    text-align: left;
    border-bottom: 2px solid ${({ theme }) => theme.darkBorderColor};
    max-width: ${({ maxWidth }) => maxWidth ?? 'initial'};
    min-width: ${({ minWidth }) => minWidth ?? 'initial'};
    font-size: ${({ isSmallDisplay }) => (isSmallDisplay ? 13 : 'initial')}px;
    padding: ${({ isSmallDisplay }) => (isSmallDisplay ? 4 : 10)}px;
    white-space: ${({ isSmallDisplay }) => (isSmallDisplay ? 'nowrap' : 'initial')};
    overflow: ${({ isSmallDisplay }) => (isSmallDisplay ? 'hidden' : 'initial')};
    text-overflow: ${({ isSmallDisplay }) => (isSmallDisplay ? 'ellipsis' : 'initial')};
`;

const Row = styled.tr<{ isClickable: boolean; isHighlighted: boolean }>`
    cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'initial')};
    color: ${({ theme, isHighlighted }) => (isHighlighted ? theme.ctaPrimaryColor : theme.textColor)};
`;

const Cell = styled.td<{ maxWidth?: string; minWidth?: string; isSmallDisplay?: boolean }>`
    padding: ${({ isSmallDisplay }) => (isSmallDisplay ? 4 : 10)}px;
    font-size: ${({ isSmallDisplay }) => (isSmallDisplay ? 15 : 'initial')}px;
    border-bottom: 1px solid ${({ theme }) => theme.lightBorderColor};
    max-width: ${({ maxWidth }) => maxWidth ?? 'initial'};
    min-width: ${({ minWidth }) => minWidth ?? 'initial'};
    white-space: pre-wrap;
`;

const DetailsIconContainer = styled.div`
    display: flex;
    align-items: center;
`;

const SortButton = styled.button<{ isSelected: boolean }>`
    cursor: pointer;
    text-align: center;
    padding: 0;
    margin: 0;
    color: ${({ theme, isSelected }) => (isSelected ? theme.ctaPrimaryColor : theme.textColor)};
    background: none;
    border: none;
    outline: none;
    outline: none;
`;

const Sublabel = styled.div<{ isSmallDisplay?: boolean }>`
    position: absolute;
    left: ${({ isSmallDisplay }) => (isSmallDisplay ? 4 : 10)}px;
    bottom: 1px;
    color: ${({ theme }) => theme.infoTextColor};
    font-size: 9px;
`;

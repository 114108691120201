import React, { useState } from 'react';

import { useQuery } from '@apollo/client';

import { GET_SITE_CONSUMPTION_VARIATIONS_QUERY } from 'data/queries/consumptionVariation';
import { GET_SITE_CONSUMPTION_VARIATIONS } from 'data/queries/__generated__/GET_SITE_CONSUMPTION_VARIATIONS';

import { Loader } from 'components/Loader';
import { CreateConsumptionVariationPopup } from './Popups/CreateConsumptionVariationPopup';
import { ConsumptionVariationDetails } from './ConsumptionVariationDetails';
import { ColumnsSectionContainer, SectionColumn, SectionContainer } from 'components/DetailsView/Section';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

export const ConsumptionVariations = ({ siteId }: { siteId: string }) => {
    const [isCreateConsumptionVariationPopupOpen, setIsCreateConsumptionVariationPopupOpen] = useState<boolean>(false);

    const {
        loading: consumptionVariationsLoading,
        data: consumptionVariationsData,
        error: consumptionVariationsError,
    } = useQuery<GET_SITE_CONSUMPTION_VARIATIONS>(GET_SITE_CONSUMPTION_VARIATIONS_QUERY, { variables: { siteId } });

    if (consumptionVariationsLoading && !consumptionVariationsData) {
        return (
            <SectionContainer title="Variation de la consommation" isInitiallyOpen>
                <Loader />
            </SectionContainer>
        );
    }

    if (consumptionVariationsError || !consumptionVariationsData) {
        throw new Error('Une erreur est survenue lors de la récupération des variations de consommation');
    }

    const { siteConsumptionVariations } = consumptionVariationsData;

    const sortedConsumptionVariations = [...siteConsumptionVariations]
        .sort((consumptionVariationA, consumptionVariationB) =>
            consumptionVariationB.endDate.localeCompare(consumptionVariationA.endDate),
        )
        .sort((consumptionVariationA, consumptionVariationB) =>
            consumptionVariationB.startDate.localeCompare(consumptionVariationA.startDate),
        );

    return (
        <>
            <SectionContainer title="Variation de la consommation" isInitiallyOpen>
                <ColumnsSectionContainer numberOfColumns={1}>
                    <SectionColumn>
                        <TotemPrimaryButton
                            onClick={() => {
                                setIsCreateConsumptionVariationPopupOpen(true);
                            }}
                        >
                            Ajouter une variation de la consommation
                        </TotemPrimaryButton>
                        {sortedConsumptionVariations.map((consumptionVariation) => (
                            <ConsumptionVariationDetails
                                key={consumptionVariation._id}
                                consumptionVariation={consumptionVariation}
                            />
                        ))}
                    </SectionColumn>
                </ColumnsSectionContainer>
            </SectionContainer>

            <CreateConsumptionVariationPopup
                siteId={siteId}
                isOpen={isCreateConsumptionVariationPopupOpen}
                setIsOpen={setIsCreateConsumptionVariationPopupOpen}
            />
        </>
    );
};

import React, { useEffect } from 'react';

import { useQuery } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { PAGES } from '../../../constants/pages';

import { GET_DELIVERY_QUERY } from 'data/queries/delivery';
import { GET_DELIVERY } from 'data/queries/__generated__/GET_DELIVERY';
import { DELIVERIES_UPDATED_SUBSCRIPTION } from 'data/subscriptions/delivery';

import { Header, HeaderTitle } from 'components/Header';
import { Loader } from 'components/Loader';
import { PageTitle } from 'components/PageTitle';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { BoxesSection } from './BoxesSection';
import { DriverSection } from './DriverSection';
import { GeneralInfoSection } from './GeneralInfoSection';
import { PhotosSectionContent } from './PhotosSectionContent';
import { PhotosSection as SiteSetupPhotoSection } from '../../SiteSetups/details/PhotosSection';
import { StatesSection } from './StatesSection';

import { apolloClient } from '../../../App';
import { deliveryUpdatedHandler } from '../utils/deliverySubscriptionsHandlers';
import { SectionContainer } from 'components/DetailsView/Section';

type ParamTypes = {
    deliveryId: string;
};

export const DeliveryDetails = () => {
    const { deliveryId } = useParams<ParamTypes>();
    const {
        loading: deliveryLoading,
        data: deliveryData,
        error: deliveryError,
    } = useQuery<GET_DELIVERY>(GET_DELIVERY_QUERY, {
        fetchPolicy: 'cache-and-network',
        variables: { deliveryId },
    });

    useEffect(() => {
        const observer = apolloClient.subscribe({
            query: DELIVERIES_UPDATED_SUBSCRIPTION,
        });

        const subscription = observer.subscribe(({ data }) => {
            const { deliveriesUpdated } = data;

            if (delivery) {
                deliveryUpdatedHandler(delivery, deliveriesUpdated);
            }
        });

        return () => subscription.unsubscribe();
    });

    if (deliveryLoading && !deliveryData) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (deliveryError || !deliveryData) {
        throw new Error('Une erreur est survenue lors de la récupération de la livraison');
    }

    const { delivery } = deliveryData;

    if (!delivery) {
        throw new Error(`Aucune livraison n'a été trouvée avec l'id "${deliveryId}"`);
    }

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.deliveryDetails} />
                </HeaderTitle>
                <CTAsContainer>
                    <Link to={PAGES.deliveries.url}>
                        <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                    </Link>
                </CTAsContainer>
            </Header>
            <Content>
                <ScrollableContent>
                    <GeneralInfoSection delivery={delivery} />
                    <StatesSection delivery={delivery} />
                    <DriverSection delivery={delivery} />
                    <SectionContainer title="Photos" isInitiallyOpen>
                        <PhotosSectionContent delivery={delivery} />
                    </SectionContainer>
                    {delivery.siteSetup ? <SiteSetupPhotoSection siteSetup={delivery.siteSetup} /> : null}
                    <BoxesSection delivery={delivery} />
                </ScrollableContent>
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const CTAsContainer = styled.div`
    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const Content = styled.div`
    flex: 1;
    overflow: hidden;
`;

const ScrollableContent = styled.div`
    padding: 15px;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import styled from 'styled-components';

import { GET_MICROSTORE_COLUMN_TEMPLATE_QUERY } from 'data/queries/microstoreColumnTemplate';
import {
    GET_MICROSTORE_COLUMN_TEMPLATE,
    GET_MICROSTORE_COLUMN_TEMPLATEVariables,
} from 'data/queries/__generated__/GET_MICROSTORE_COLUMN_TEMPLATE';

import { Loader } from 'components/Loader';
import { MicrostoreColumnTemplateDetailsForm } from './MicrostoreColumnTemplateDetailsForm';

type ParamTypes = {
    microstoreColumnTemplateId: string;
};

export const MicrostoreColumnTemplateDetails = () => {
    const { microstoreColumnTemplateId = '' } = useParams<ParamTypes>();

    const {
        loading: microstoreColumnTemplateLoading,
        data: microstoreColumnTemplateData,
        error: microstoreColumnTemplateError,
    } = useQuery<GET_MICROSTORE_COLUMN_TEMPLATE, GET_MICROSTORE_COLUMN_TEMPLATEVariables>(
        GET_MICROSTORE_COLUMN_TEMPLATE_QUERY,
        {
            variables: {
                microstoreColumnTemplateId,
            },
        },
    );

    if (microstoreColumnTemplateLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (microstoreColumnTemplateError || !microstoreColumnTemplateData) {
        throw new Error('Une erreur est survenue lors de la récupération du template de colonne');
    }

    const { microstoreColumnTemplateQuery: microstoreColumnTemplate } = microstoreColumnTemplateData;

    return (
        <Container>
            <MicrostoreColumnTemplateDetailsForm microstoreColumnTemplate={microstoreColumnTemplate} />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex: 1;
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
`;

import React, { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { FaSave } from 'react-icons/fa';

import { PAGES } from '../../constants/pages';
import { DEFAULT_B2B_RANGE_ID, DEFAULT_B2C_RANGE_ID } from '../../constants/prices';

import { GET_PRODUCT_PRICE_RANGES } from 'data/queries/__generated__/GET_PRODUCT_PRICE_RANGES';
import { GET_PRODUCT_PRICE_RANGES_QUERY } from 'data/queries/price';
import { SITES_UPDATED_SUBSCRIPTION } from 'data/subscriptions/site';
import { SiteFragmentFragment, useGetSiteQuery, useUpdateSiteMutation } from 'data/__generated__';

import { Header, HeaderTitle } from 'components/Header';
import { Loader, LoaderModeType } from 'components/Loader';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { TotemSelect } from 'components/TotemSelect';
import { ColumnsSectionContainer, SectionContainer } from 'components/DetailsView/Section';
import { PageTitle } from 'components/PageTitle';

import { UrbantzMetadataForm } from './components/UrbantzMetadataForm';
import { GeneralInfoSection } from 'pages/Sites/SiteDetails/GeneralInfoSection';
import { DeliveryInfoSection } from 'pages/Sites/SiteDetails/DeliveryInfoSection';

import { SiteRituals } from './components/SiteRituals';
import { siteUpdatedHandler } from './utils/sitesUpdatedHandler';
import { apolloClient } from '../../App';
import { getNumericValue, stockTargetRatiosCheck } from 'pages/MicrostoreColumns/MicrostoreColumnDetails/utils';
import { SiteWantedProducts } from './components/SiteWantedProducts';
import { ConsumptionVariations } from './ConsumptionVariation/ConsumptionVariations';

type ParamTypes = {
    siteId: string;
};

export const SiteDetails = () => {
    const { siteId = '' } = useParams<ParamTypes>();

    const [updatedSiteDetails, setUpdatedSiteDetails] = useState<SiteFragmentFragment | null>(null);

    const {
        loading: siteLoading,
        data: siteData,
        error: siteError,
    } = useGetSiteQuery({
        variables: { siteId },
    });
    const {
        loading: priceRangesLoading,
        data: priceRangesData,
        error: priceRangesError,
    } = useQuery<GET_PRODUCT_PRICE_RANGES>(GET_PRODUCT_PRICE_RANGES_QUERY);
    const [updateSite, { loading: updateLoading }] = useUpdateSiteMutation();

    useEffect(() => {
        const observer = apolloClient.subscribe({
            query: SITES_UPDATED_SUBSCRIPTION,
            variables: { siteId },
        });
        const subscription = observer.subscribe(({ data }) => {
            const { siteUpdated } = data;
            siteUpdatedHandler(siteUpdated, siteId);
            setUpdatedSiteDetails(null);
            toast.warn("Le site vient d'être mis à jour");
        });

        return () => subscription.unsubscribe();
    });

    if (siteLoading || priceRangesLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (siteError || !siteData) {
        throw new Error('Une erreur est survenue lors de la récupération des sites');
    }

    if (priceRangesError || !priceRangesData) {
        throw new Error('Une erreur est survenue lors de la récupération des gammes');
    }

    const { productPriceRanges } = priceRangesData;
    const rangesWithoutDefault = productPriceRanges.filter(({ _id }) => DEFAULT_B2C_RANGE_ID !== _id);
    const rangeOptions = rangesWithoutDefault.map(({ _id, name }) => ({ label: name, value: _id }));

    const { site: apolloSiteDetails } = siteData;
    const siteDetails = updatedSiteDetails || apolloSiteDetails;

    if (siteDetails.B2CPriceRange === DEFAULT_B2B_RANGE_ID) {
        toast.warning(
            'ATTENTION : le prix B2B par défaut correspond au prix freefood et sera utilisé sur un store (ex Datadog)',
            {
                autoClose: false,
            },
        );
    }

    const { B2CPriceRange, urbantzMetadata } = siteDetails;

    const handleModify = async () => {
        const {
            _id: siteId,
            accessTime,
            removalDate,
            B2CPriceRange,
            unknownShrinkageBillingThreshold,
            closingDatesRanges,
            deliveryDays,
            deliveryTimeWindow: { start: deliveryWindowStart, stop: deliveryWindowStop },
            estimatedUserCount,
            hasExternalSetupProcess,
            isSecured,
            hasCoffee,
            launchDate,
            name,
            prepareBySupplierPackage,
            reassortmentStockTargets: { __typename, ...stockTargets },
            reassortmentOnboardingStockTargets: { __typename: onboardingTypename, ...onboardingStockTargets },
            reassortmentStockTargetRatios,
            shouldAutomaticallyCreateTargetReassortment,
            shouldAutomaticallyCreateManualReassortment,
            shouldHaveTheftNotice,
            shouldModulateForExpired,
            shouldModulateForUnknownLosses,
            state,
            unwantedProductIds,
            urbantzMetadata,
            useNayaxCardForCoffee,
            wantedProductIds,
        } = siteDetails;

        const stockTargetRatiosError = stockTargetRatiosCheck(reassortmentStockTargetRatios);
        if (stockTargetRatiosError) {
            toast.warning(stockTargetRatiosError);
            return;
        }

        const startHour = deliveryWindowStart.replace(':', '');
        const stopHour = deliveryWindowStop.replace(':', '');
        if (Number(startHour) >= Number(stopHour)) {
            toast.error('Fenêtre de livraison invalide');
            return;
        }

        const { monday, tuesday, wednesday, thursday, friday } = reassortmentStockTargetRatios;

        const mutationVariables = {
            accessTime,
            removalDate,
            B2CPriceRange,
            unknownShrinkageBillingThreshold,
            closingDatesRanges: closingDatesRanges.map(({ start, end }) => ({ start, end })), // This is to remove __typename from object
            deliveryDays,
            deliveryWindowStart,
            deliveryWindowStop,
            estimatedUserCount,
            hasExternalSetupProcess,
            isSecured,
            hasCoffee,
            launchDate,
            name,
            prepareBySupplierPackage,
            reassortmentStockTargets: stockTargets,
            reassortmentOnboardingStockTargets: onboardingStockTargets,
            reassortmentStockTargetRatios: {
                monday: getNumericValue(monday),
                tuesday: getNumericValue(tuesday),
                wednesday: getNumericValue(wednesday),
                thursday: getNumericValue(thursday),
                friday: getNumericValue(friday),
            },
            shouldAutomaticallyCreateTargetReassortment,
            shouldAutomaticallyCreateManualReassortment,
            shouldHaveTheftNotice,
            shouldModulateForExpired,
            shouldModulateForUnknownLosses,
            siteId,
            state,
            useNayaxCardForCoffee,
            unwantedProductIds,
            urbantzMetadata: {
                needsCleaningMachineNECTA: urbantzMetadata.needsCleaningMachineNECTA.map(({ evenWeek, oddWeek }) => ({
                    evenWeek,
                    oddWeek,
                })),
                needsInventoryBoissons: urbantzMetadata.needsInventoryBoissons.map(({ evenWeek, oddWeek }) => ({
                    evenWeek,
                    oddWeek,
                })),
                needsInventoryNonAlimentaire: urbantzMetadata.needsInventoryNonAlimentaire.map(
                    ({ evenWeek, oddWeek }) => ({
                        evenWeek,
                        oddWeek,
                    }),
                ),
                needsInventorySnack: urbantzMetadata.needsInventorySnack.map(({ evenWeek, oddWeek }) => ({
                    evenWeek,
                    oddWeek,
                })),
                needsSmallExpiryDateInventory: urbantzMetadata.needsSmallExpiryDateInventory.map(
                    ({ evenWeek, oddWeek }) => ({
                        evenWeek,
                        oddWeek,
                    }),
                ),
                replaceCompleteInventoryByZeroInventory: urbantzMetadata.replaceCompleteInventoryByZeroInventory.map(
                    ({ evenWeek, oddWeek }) => ({
                        evenWeek,
                        oddWeek,
                    }),
                ),
            },
            wantedProductIds,
        };

        const { data } = await updateSite({ variables: mutationVariables });
        if (!data) {
            toast.error('Une erreur inconnue est survenue lors de la modification du site');
        } else if (!data.updateSite.success) {
            toast.error(`L'erreur suivante est survenue : "${data.updateSite.error}"`);
        } else {
            setUpdatedSiteDetails(null);
            toast.success('Le site a bien été modifié');
        }
    };

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.siteDetails} />
                </HeaderTitle>
                <CTAsContainer>
                    <Link to={`/site/${siteId}/stocks`}>
                        <TotemPrimaryButton>Consulter les stocks</TotemPrimaryButton>
                    </Link>
                    <TotemPrimaryButton
                        onClick={handleModify}
                        disabled={!updatedSiteDetails || updateLoading}
                        data-test="site-details_submit-button"
                    >
                        {updateLoading ? (
                            <Loader size="20px" mode={LoaderModeType.Spin} />
                        ) : (
                            <>
                                <FaSave size="13" />
                                <SaveLabel>Mettre à jour</SaveLabel>
                            </>
                        )}
                    </TotemPrimaryButton>
                    <Link to="/sites">
                        <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                    </Link>
                </CTAsContainer>
            </Header>
            <Content>
                <ScrollableContent>
                    <GeneralInfoSection siteDetails={siteDetails} setUpdatedSiteDetails={setUpdatedSiteDetails} />
                    <DeliveryInfoSection siteDetails={siteDetails} setUpdatedSiteDetails={setUpdatedSiteDetails} />
                    <SectionContainer title="Gamme de prix" isInitiallyOpen>
                        <TotemSelect
                            label="Gamme de prix"
                            placeholder="Sélectionner une gamme de prix"
                            isClearable={true}
                            defaultValue={rangeOptions.find(({ value }) => value === B2CPriceRange)}
                            options={rangeOptions}
                            onChange={(option) =>
                                setUpdatedSiteDetails({ ...siteDetails, B2CPriceRange: option?.value || '' })
                            }
                        />
                    </SectionContainer>
                    <SectionContainer title="Métadonnées Urbantz" isInitiallyOpen>
                        <ColumnsSectionContainer numberOfColumns={1}>
                            <UrbantzMetadataForm
                                metadata={urbantzMetadata}
                                updateMetadata={(urbantzMetadata: SiteFragmentFragment['urbantzMetadata']) =>
                                    setUpdatedSiteDetails({ ...siteDetails, urbantzMetadata })
                                }
                            />
                        </ColumnsSectionContainer>
                    </SectionContainer>
                    <SectionContainer title="Produits pour plano dry" isInitiallyOpen>
                        <SiteWantedProducts siteDetails={siteDetails} setUpdatedSiteDetails={setUpdatedSiteDetails} />
                    </SectionContainer>
                    <ConsumptionVariations siteId={siteId} />
                    <SectionContainer title="Rituels du site" isInitiallyOpen={false}>
                        <ColumnsSectionContainer numberOfColumns={1}>
                            <SiteRituals siteId={siteId} />
                        </ColumnsSectionContainer>
                    </SectionContainer>
                </ScrollableContent>
            </Content>
        </Container>
    );
};

const SaveLabel = styled.span`
    margin-left: 5px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const CTAsContainer = styled.div`
    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const ScrollableContent = styled.div`
    padding: 15px;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    & > :not(:first-child) {
        margin-top: 15px;
    }
`;

const Content = styled.div`
    flex: 1;
    overflow: hidden;
`;

import React, { useState } from 'react';
import { toast } from 'react-toastify';
import ReactDatePicker from 'react-datepicker';
import { useMutation } from '@apollo/client';

import styled from 'styled-components';
import { FaTrash } from 'react-icons/fa';

import { colors } from 'constants/colors';
import { ADD_ADDITIONAL_SUPPLY_ORDER_RECEIVED_PRODUCT_DETAILS_MUTATION } from 'data/mutations/supplyOrder';
import {
    ADD_ADDITIONAL_SUPPLY_ORDER_RECEIVED_PRODUCT_DETAILS,
    ADD_ADDITIONAL_SUPPLY_ORDER_RECEIVED_PRODUCT_DETAILSVariables,
} from 'data/mutations/__generated__/ADD_ADDITIONAL_SUPPLY_ORDER_RECEIVED_PRODUCT_DETAILS';
import { GET_SUPPLY_ORDER_WITH_SUPPLIER_PRODUCTS_INFO_supplyOrderWithSupplierProductsInfo_supplier_productsInfoWithStock } from 'data/queries/__generated__/GET_SUPPLY_ORDER_WITH_SUPPLIER_PRODUCTS_INFO';
import { SupplyOrderProductReceivedDetailWithoutTypeName } from 'pages/SupplyOrders/SupplyOrderDetails/supplyOrderDetailsFormHelper';

import { TotemPopup } from 'components/TotemPopup';
import { BasicInput } from 'components/BasicInput';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { SelectedOption, TotemSelect } from 'components/TotemSelect';
import { TotemLabel } from 'components/TotemLabel';
import { Loader, LoaderModeType } from 'components/Loader';

import { getSupplyOrderProductReceivedTotal } from 'pages/SupplyOrders/helper/getSupplyOrderProductReceivedTotal';
import { dateFromString, formatDateAsAnniversary } from 'helpers/dateTimes';
import { getProductFullName } from 'pages/Products/utils';
import { areExpiryDatesUnique } from 'pages/SupplyOrders/SupplyOrderDetails/Popups/SupplyOrderProductInfoReceivedDetailsPopup';

export const AdditionalDeliveryPopup = ({
    isOpen,
    setIsOpen,
    supplyOrderId,
    supplierProducts,
}: {
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
    supplyOrderId: string;
    supplierProducts: GET_SUPPLY_ORDER_WITH_SUPPLIER_PRODUCTS_INFO_supplyOrderWithSupplierProductsInfo_supplier_productsInfoWithStock[];
}) => {
    const [productsWithAdditionalQuantities, setProductsWithAdditionalQuantities] = useState<
        {
            productId: string;
            fullname: string;
            productReceivedDetails: SupplyOrderProductReceivedDetailWithoutTypeName[];
        }[]
    >([]);

    const [addAdditionalReceivedProductDetails, { loading }] = useMutation<
        ADD_ADDITIONAL_SUPPLY_ORDER_RECEIVED_PRODUCT_DETAILS,
        ADD_ADDITIONAL_SUPPLY_ORDER_RECEIVED_PRODUCT_DETAILSVariables
    >(ADD_ADDITIONAL_SUPPLY_ORDER_RECEIVED_PRODUCT_DETAILS_MUTATION);

    function onClose() {
        setProductsWithAdditionalQuantities([]);
        setIsOpen(false);
    }

    async function handleProductDetailsUpdate() {
        if (
            productsWithAdditionalQuantities.some(
                ({ productReceivedDetails }) =>
                    !areExpiryDatesUnique(productReceivedDetails) ||
                    productReceivedDetails.some(({ quantity, brokenItems }) => quantity === 0 && brokenItems === 0),
            )
        )
            return;

        const productDetails = productsWithAdditionalQuantities.map(({ productId, productReceivedDetails }) => ({
            productId,
            productReceivedDetails,
        }));

        const { data } = await addAdditionalReceivedProductDetails({
            variables: { supplyOrderId, productDetails },
        });

        if (!data?.addAdditionalSupplyOrderReceivedProductDetails) {
            throw Error("Une erreur inconnue s'est produite");
        } else {
            toast.success('Les quantités ont bien été mises à jour !');
        }
        onClose();
    }

    function onProductSelect(option: SelectedOption<string>) {
        if (option?.value && !productsWithAdditionalQuantities.some(({ productId }) => productId === option.value)) {
            const supplierProduct = supplierProducts.find(({ _id }) => _id === option.value);

            if (!supplierProduct) return;

            setProductsWithAdditionalQuantities([
                ...productsWithAdditionalQuantities,
                {
                    productId: option.value,
                    fullname: option.label,
                    productReceivedDetails: [
                        {
                            brokenItems: 0,
                            colis: 0,
                            conditionning: supplierProduct.conditionning,
                            expiryDate: null,
                            quantity: 0,
                        },
                    ],
                },
            ]);
        }
    }

    function onProductRemove(productToRemoveId: string) {
        setProductsWithAdditionalQuantities(
            productsWithAdditionalQuantities.filter(({ productId }) => productId !== productToRemoveId),
        );
    }

    function onDetailChange({
        productIdToUpdate,
        productDetailIndex,
        productDetail,
    }: {
        productIdToUpdate: string;
        productDetailIndex: number;
        productDetail: SupplyOrderProductReceivedDetailWithoutTypeName;
    }) {
        const productWithAdditionalQuantities = productsWithAdditionalQuantities.find(
            ({ productId }) => productId === productIdToUpdate,
        );
        if (!productWithAdditionalQuantities) return;

        const updatedProductReceivedDetails = [...productWithAdditionalQuantities.productReceivedDetails];

        updatedProductReceivedDetails[productDetailIndex] = {
            ...productDetail,
            colis: Math.floor(
                productDetail.conditionning
                    ? productDetail.quantity / productDetail.conditionning
                    : productDetail.quantity,
            ),
        };

        if (!areExpiryDatesUnique(updatedProductReceivedDetails)) {
            toast.error('Vous ne pouvez avoir deux DLC identiques');
            return;
        }

        setProductsWithAdditionalQuantities(
            productsWithAdditionalQuantities.map((product) => {
                if (product.productId !== productIdToUpdate) return product;

                return {
                    ...product,
                    productReceivedDetails: updatedProductReceivedDetails,
                };
            }),
        );
    }

    function addDetail(productIdToUpdate: string) {
        const supplierProduct = supplierProducts.find(({ _id }) => _id === productIdToUpdate);
        const productWithAdditionalQuantities = productsWithAdditionalQuantities.find(
            ({ productId }) => productId === productIdToUpdate,
        );

        if (!supplierProduct || !productWithAdditionalQuantities) return;

        setProductsWithAdditionalQuantities(
            productsWithAdditionalQuantities.map((product) => {
                if (product.productId !== productIdToUpdate) return product;

                const productReceivedDetails = [
                    ...product.productReceivedDetails,
                    {
                        brokenItems: 0,
                        colis: 0,
                        conditionning: supplierProduct.conditionning,
                        expiryDate: null,
                        quantity: 0,
                    },
                ];

                if (!areExpiryDatesUnique(productReceivedDetails)) {
                    toast.error('Vous ne pouvez avoir deux DLC identiques');
                }

                return {
                    ...product,
                    productReceivedDetails: productReceivedDetails,
                };
            }),
        );
    }

    function removeDetail({
        productIdToUpdate,
        productDetailIndex,
    }: {
        productIdToUpdate: string;
        productDetailIndex: number;
    }) {
        const productWithAdditionalQuantities = productsWithAdditionalQuantities.find(
            ({ productId }) => productId === productIdToUpdate,
        );

        if (!productWithAdditionalQuantities) return;

        setProductsWithAdditionalQuantities(
            productsWithAdditionalQuantities.map((product) => {
                if (product.productId !== productIdToUpdate) return product;

                const updatedProductReceivedDetails = [
                    ...product.productReceivedDetails.slice(0, productDetailIndex),
                    ...product.productReceivedDetails.slice(productDetailIndex + 1),
                ];

                return {
                    ...product,
                    productReceivedDetails: updatedProductReceivedDetails,
                };
            }),
        );
    }

    const productsOptions =
        supplierProducts.map(({ _id, name, brand, volume }) => ({
            value: _id,
            label: getProductFullName({ name, brand, volume }),
        })) || [];

    const shouldPreventUpdate = productsWithAdditionalQuantities.some(
        ({ productReceivedDetails }) =>
            !areExpiryDatesUnique(productReceivedDetails) ||
            productReceivedDetails.some(({ quantity, brokenItems }) => quantity === 0 && brokenItems === 0),
    );

    return (
        <TotemPopup
            title="Déclarer quantités additionnelles livrées"
            isOpen={isOpen}
            setIsOpen={onClose}
            contentOverflow={productsWithAdditionalQuantities.length ? 'scroll' : 'overflow'}
        >
            <Container>
                <TotemSelect
                    dataTest="select-product"
                    label="Ajouter un produit"
                    placeholder="Sélectionner un produit (recherche par nom ou par _id)"
                    value={null}
                    options={productsOptions}
                    onChange={(option) => onProductSelect(option)}
                />
                <>
                    {productsWithAdditionalQuantities.map(({ productId, fullname, productReceivedDetails }) => {
                        const { brokenItemsReceived, colisReceived, conditionningReceived, quantityReceived } =
                            getSupplyOrderProductReceivedTotal(productReceivedDetails);

                        return (
                            <ProductContainer key={productId}>
                                <TitleRow>
                                    <TotemLabel>{fullname}</TotemLabel>

                                    <IconContainer onClick={() => onProductRemove(productId)}>
                                        <FaTrash data-test="trash-icon" size={16} color={colors.pureWhite} />
                                    </IconContainer>
                                </TitleRow>
                                {productReceivedDetails.length ? (
                                    <ReceivedDetailsTable>
                                        <thead>
                                            <tr>
                                                <ReceivedDetailsHeaderCell>Quantité reçue</ReceivedDetailsHeaderCell>
                                                <ReceivedDetailsHeaderCell>
                                                    Quantité reçue cassée
                                                </ReceivedDetailsHeaderCell>
                                                <ReceivedDetailsHeaderCell>Nb. Colis reçus</ReceivedDetailsHeaderCell>
                                                <ReceivedDetailsHeaderCell>DLC</ReceivedDetailsHeaderCell>
                                                <ReceivedDetailsHeaderCell>Colisage reçu</ReceivedDetailsHeaderCell>
                                                <ReceivedDetailsHeaderCell />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {productReceivedDetails.map(
                                                (
                                                    productDetail: SupplyOrderProductReceivedDetailWithoutTypeName,
                                                    productDetailIndex: number,
                                                ) => {
                                                    const { brokenItems, colis, conditionning, expiryDate, quantity } =
                                                        productDetail;
                                                    return (
                                                        <ReceivedDetailsRow key={productDetailIndex}>
                                                            <ReceivedDetailsCell>
                                                                <BasicInput
                                                                    value={quantity}
                                                                    onChange={({ target }) => {
                                                                        const newQuantity = parseInt(target.value) || 0;

                                                                        onDetailChange({
                                                                            productIdToUpdate: productId,
                                                                            productDetailIndex,
                                                                            productDetail: {
                                                                                ...productDetail,
                                                                                quantity: newQuantity,
                                                                            },
                                                                        });
                                                                    }}
                                                                    centerText
                                                                    width="100%"
                                                                    step="1"
                                                                    min="0"
                                                                    required
                                                                />
                                                            </ReceivedDetailsCell>
                                                            <ReceivedDetailsCell>
                                                                <BasicInput
                                                                    value={brokenItems}
                                                                    onChange={({ target }) => {
                                                                        const newBrokenItems =
                                                                            parseInt(target.value) || 0;

                                                                        onDetailChange({
                                                                            productIdToUpdate: productId,
                                                                            productDetailIndex,
                                                                            productDetail: {
                                                                                ...productDetail,
                                                                                brokenItems: newBrokenItems,
                                                                            },
                                                                        });
                                                                    }}
                                                                    centerText
                                                                    width="100%"
                                                                    step="1"
                                                                    min="0"
                                                                    required
                                                                />
                                                            </ReceivedDetailsCell>
                                                            <ReceivedDetailsCell>{`${colis} (+${
                                                                conditionning ? quantity % conditionning : 0
                                                            }p)`}</ReceivedDetailsCell>
                                                            <ReceivedDetailsCell>
                                                                <ReactDatePicker
                                                                    selected={
                                                                        expiryDate ? dateFromString(expiryDate) : null
                                                                    }
                                                                    onChange={(date) => {
                                                                        if (Array.isArray(date)) return;

                                                                        const newExpiryDate = date
                                                                            ? formatDateAsAnniversary({
                                                                                  dateTime: date,
                                                                              })
                                                                            : null;

                                                                        onDetailChange({
                                                                            productIdToUpdate: productId,
                                                                            productDetailIndex,
                                                                            productDetail: {
                                                                                ...productDetail,
                                                                                expiryDate: newExpiryDate,
                                                                            },
                                                                        });
                                                                    }}
                                                                    placeholderText="DLC"
                                                                    locale="fr"
                                                                    isClearable
                                                                    dateFormat="dd/MM/yyyy"
                                                                    customInput={<BasicInput />}
                                                                />
                                                            </ReceivedDetailsCell>
                                                            <ReceivedDetailsCell>
                                                                <BasicInput
                                                                    value={conditionning}
                                                                    onChange={({ target }) => {
                                                                        const newConditionning =
                                                                            parseInt(target.value) || 1;

                                                                        onDetailChange({
                                                                            productIdToUpdate: productId,
                                                                            productDetailIndex,
                                                                            productDetail: {
                                                                                ...productDetail,
                                                                                conditionning: newConditionning,
                                                                            },
                                                                        });
                                                                    }}
                                                                    centerText
                                                                    width="100%"
                                                                    step="1"
                                                                    min="1"
                                                                    required
                                                                />
                                                            </ReceivedDetailsCell>
                                                            <ReceivedDetailsCell>
                                                                <ThinButton
                                                                    onClick={() =>
                                                                        removeDetail({
                                                                            productIdToUpdate: productId,
                                                                            productDetailIndex,
                                                                        })
                                                                    }
                                                                >
                                                                    <FaTrash size={13} />
                                                                </ThinButton>
                                                            </ReceivedDetailsCell>
                                                        </ReceivedDetailsRow>
                                                    );
                                                },
                                            )}
                                        </tbody>
                                        <tfoot>
                                            <ReceivedDetailsFooterRow>
                                                <ReceivedDetailsHeaderCell>
                                                    {quantityReceived}
                                                </ReceivedDetailsHeaderCell>
                                                <ReceivedDetailsHeaderCell>
                                                    {brokenItemsReceived}
                                                </ReceivedDetailsHeaderCell>
                                                <ReceivedDetailsHeaderCell>{colisReceived}</ReceivedDetailsHeaderCell>
                                                <ReceivedDetailsHeaderCell />
                                                <ReceivedDetailsHeaderCell>
                                                    {conditionningReceived}
                                                </ReceivedDetailsHeaderCell>
                                                <ReceivedDetailsHeaderCell />
                                            </ReceivedDetailsFooterRow>
                                        </tfoot>
                                    </ReceivedDetailsTable>
                                ) : null}
                                <ButtonRow>
                                    <ThinButton onClick={() => addDetail(productId)}>Ajouter une DLC</ThinButton>
                                </ButtonRow>
                            </ProductContainer>
                        );
                    })}
                </>
                {productsWithAdditionalQuantities.length ? (
                    <>
                        <TextContainer>
                            NB: Après l'enregistrement des quantités additionnelles, elles vont être comptées dans le
                            stock de produit dans l'entrepôt
                            <br />
                            et utilisées dans les calculs de stock disponible. Veuillez donc les ranger sans délai, afin
                            d'éviter des soldouts.
                        </TextContainer>
                        <TotemPrimaryButton
                            type="button"
                            onClick={handleProductDetailsUpdate}
                            disabled={shouldPreventUpdate || loading}
                            minWidth="335px"
                        >
                            {loading ? <Loader size="20px" mode={LoaderModeType.Spin} /> : 'Enregistrer les quantités'}
                        </TotemPrimaryButton>
                    </>
                ) : null}
            </Container>
        </TotemPopup>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    & > :not(:first-child) {
        margin-top: 15px;
    }
`;

const ReceivedDetailsTable = styled.table`
    margin-top: 10px;
    border-collapse: collapse;
    background-color: ${({ theme }) => theme.cardBackgroundColor};
    border: ${({ theme }) => `1px solid ${theme.lightBorderColor}`};
`;

const ProductContainer = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    flex: 1;
    padding: 5px 10px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid grey;
`;

const TitleRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const ButtonRow = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 5px;
`;

const ThinButton = styled(TotemPrimaryButton)`
    padding: 8px;
`;

const IconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 35px;
    height: 35px;
    border-radius: 35px;
    background-color: ${({ theme }) => theme.ctaPrimaryColor};
    cursor: pointer;
`;

const ReceivedDetailsHeaderCell = styled.th`
    padding: 10px;
    font-weight: 800;
    color: ${({ theme }) => theme.ctaPrimaryColor};
    text-align: center;
`;

const ReceivedDetailsRow = styled.tr`
    border-top: ${({ theme }) => `1px solid ${theme.lightBorderColor}`};
`;

const ReceivedDetailsCell = styled.td`
    padding: 15px;
    color: ${({ theme }) => theme.textColor};
`;

const ReceivedDetailsFooterRow = styled.tr`
    border-top: ${({ theme }) => `1px solid ${theme.lightBorderColor}`};
`;

const TextContainer = styled.div`
    text-align: center;
`;

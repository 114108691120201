import gql from 'graphql-tag';
import { SUPPLY_ORDER_FRAGMENT, SUPPLY_ORDER_WITH_SUPPLIER_PRODUCTS_INFO_FRAGMENT } from '../fragments/supplyOrder';

export const UPDATE_SUPPLY_ORDER_MUTATION = gql`
    mutation UPDATE_SUPPLY_ORDER($supplyOrderId: ID!, $fields: SupplyOrderUpdateInput!) {
        updateSupplyOrderMutation(supplyOrderId: $supplyOrderId, fields: $fields) {
            success
            formErrors {
                sectionName
                sectionErrors {
                    fieldName
                    fieldError
                }
            }
            errors
            warnings
            updatedSupplyOrder {
                ...SupplyOrderWithSupplierProductsInfoFragment
            }
        }
    }
    ${SUPPLY_ORDER_WITH_SUPPLIER_PRODUCTS_INFO_FRAGMENT}
`;

export const CREATE_SUPPLY_ORDER_MUTATION = gql`
    mutation CREATE_SUPPLY_ORDER($supplierId: ID!) {
        createSupplyOrderMutation(supplierId: $supplierId) {
            createdSupplyOrder {
                ...SupplyOrderFragment
            }
            errors
            warnings
        }
    }
    ${SUPPLY_ORDER_FRAGMENT}
`;

export const GENERATE_SUPPLY_ORDERS_NEEDED_MUTATION = gql`
    mutation GENERATE_SUPPLY_ORDERS_NEEDED {
        generateSupplyOrdersNeededMutation {
            createdSupplyOrders {
                ...SupplyOrderFragment
            }
            mergedSupplyOrders {
                ...SupplyOrderFragment
            }
            errors
        }
    }
    ${SUPPLY_ORDER_FRAGMENT}
`;

export const RELAUNCH_PASS_SUPPLY_ORDERS_TO_ORDERED_MUTATION = gql`
    mutation RELAUNCH_PASS_SUPPLY_ORDERS_TO_ORDERED {
        relaunchPassSupplyOrdersToOrdered {
            updatedSupplyOrders {
                ...SupplyOrderFragment
            }
            errors
        }
    }
    ${SUPPLY_ORDER_FRAGMENT}
`;

export const MARK_SUPPLY_ORDERS_AS_ABORTED_MUTATION = gql`
    mutation MARK_SUPPLY_ORDERS_AS_ABORTED($supplyOrderIdsWithReasons: [SupplyOrderIDWithReasonsForAborting!]!) {
        markSupplyOrdersAsAborted(supplyOrderIdsWithReasons: $supplyOrderIdsWithReasons) {
            updatedSupplyOrders {
                ...SupplyOrderWithSupplierProductsInfoFragment
            }
            errors
            warnings
        }
    }
    ${SUPPLY_ORDER_WITH_SUPPLIER_PRODUCTS_INFO_FRAGMENT}
`;

export const MARK_SUPPLY_ORDER_AS_ABORTED_MUTATION = gql`
    mutation MARK_SUPPLY_ORDER_AS_ABORTED($supplyOrderIdsWithReasons: [SupplyOrderIDWithReasonsForAborting!]!) {
        markSupplyOrdersAsAborted(supplyOrderIdsWithReasons: $supplyOrderIdsWithReasons) {
            updatedSupplyOrders {
                ...SupplyOrderWithSupplierProductsInfoFragment
            }
            errors
            warnings
        }
    }
    ${SUPPLY_ORDER_WITH_SUPPLIER_PRODUCTS_INFO_FRAGMENT}
`;

export const MARK_SUPPLY_ORDER_AS_CHECKED_MUTATION = gql`
    mutation MARK_SUPPLY_ORDER_AS_CHECKED($supplyOrderIds: [ID!]!) {
        markSupplyOrdersAsChecked(supplyOrderIds: $supplyOrderIds) {
            updatedSupplyOrders {
                ...SupplyOrderWithSupplierProductsInfoFragment
            }
            errors
            warnings
        }
    }
    ${SUPPLY_ORDER_WITH_SUPPLIER_PRODUCTS_INFO_FRAGMENT}
`;

export const MARK_SUPPLY_ORDER_AS_CONFIRMED_MUTATION = gql`
    mutation MARK_SUPPLY_ORDER_AS_CONFIRMED($supplyOrderIds: [ID!]!) {
        markSupplyOrdersAsConfirmed(supplyOrderIds: $supplyOrderIds) {
            updatedSupplyOrders {
                ...SupplyOrderWithSupplierProductsInfoFragment
            }
            errors
            warnings
        }
    }
    ${SUPPLY_ORDER_WITH_SUPPLIER_PRODUCTS_INFO_FRAGMENT}
`;

export const MARK_SUPPLY_ORDERS_AS_CONFIRMED_MUTATION = gql`
    mutation MARK_SUPPLY_ORDERS_AS_CONFIRMED($supplyOrderIds: [ID!]!) {
        markSupplyOrdersAsConfirmed(supplyOrderIds: $supplyOrderIds) {
            updatedSupplyOrders {
                _id
                state
            }
            errors
            warnings
        }
    }
`;

export const MARK_SUPPLY_ORDER_AS_INRACK_MUTATION = gql`
    mutation MARK_SUPPLY_ORDER_AS_INRACK($supplyOrderIds: [ID!]!) {
        markSupplyOrdersAsInRack(supplyOrderIds: $supplyOrderIds) {
            updatedSupplyOrders {
                ...SupplyOrderWithSupplierProductsInfoFragment
            }
            errors
            warnings
        }
    }
    ${SUPPLY_ORDER_WITH_SUPPLIER_PRODUCTS_INFO_FRAGMENT}
`;

export const MARK_SUPPLY_ORDER_AS_ON_HOLD_MUTATION = gql`
    mutation MARK_SUPPLY_ORDER_AS_ON_HOLD($supplyOrderId: ID!) {
        markSupplyOrderAsOnHold(supplyOrderId: $supplyOrderId) {
            ...SupplyOrderWithSupplierProductsInfoFragment
        }
    }
    ${SUPPLY_ORDER_WITH_SUPPLIER_PRODUCTS_INFO_FRAGMENT}
`;

export const MARK_SUPPLY_ORDER_AS_ORDERED_MUTATION = gql`
    mutation MARK_SUPPLY_ORDER_AS_ORDERED($supplyOrderIds: [ID!]!, $shouldSendMailToSupplier: Boolean) {
        markSupplyOrdersAsOrdered(
            supplyOrderIds: $supplyOrderIds
            shouldSendMailToSupplier: $shouldSendMailToSupplier
        ) {
            updatedSupplyOrders {
                ...SupplyOrderWithSupplierProductsInfoFragment
            }
            errors
            warnings
        }
    }
    ${SUPPLY_ORDER_WITH_SUPPLIER_PRODUCTS_INFO_FRAGMENT}
`;

export const MARK_SUPPLY_ORDERS_AS_ORDERED_MUTATION = gql`
    mutation MARK_SUPPLY_ORDERS_AS_ORDERED($supplyOrderIds: [ID!]!, $shouldSendMailToSupplier: Boolean) {
        markSupplyOrdersAsOrdered(
            supplyOrderIds: $supplyOrderIds
            shouldSendMailToSupplier: $shouldSendMailToSupplier
        ) {
            updatedSupplyOrders {
                _id
                state
            }
            errors
            warnings
        }
    }
`;

export const MARK_SUPPLY_ORDER_AS_READY_MUTATION = gql`
    mutation MARK_SUPPLY_ORDER_AS_READY($supplyOrderIds: [ID!]!) {
        markSupplyOrdersAsReady(supplyOrderIds: $supplyOrderIds) {
            updatedSupplyOrders {
                ...SupplyOrderWithSupplierProductsInfoFragment
            }
            errors
            warnings
        }
    }
    ${SUPPLY_ORDER_WITH_SUPPLIER_PRODUCTS_INFO_FRAGMENT}
`;

export const MARK_SUPPLY_ORDERS_AS_READY_MUTATION = gql`
    mutation MARK_SUPPLY_ORDERS_AS_READY($supplyOrderIds: [ID!]!) {
        markSupplyOrdersAsReady(supplyOrderIds: $supplyOrderIds) {
            updatedSupplyOrders {
                _id
                dateDeliveryScheduled
                orderDate
                state
            }
            errors
            warnings
        }
    }
`;

export const MARK_SUPPLY_ORDER_AS_RECEIVED_MUTATION = gql`
    mutation MARK_SUPPLY_ORDER_AS_RECEIVED($supplyOrderIds: [ID!]!, $hourDeliveryReceivedAt: String!) {
        markSupplyOrdersAsReceived(supplyOrderIds: $supplyOrderIds, hourDeliveryReceivedAt: $hourDeliveryReceivedAt) {
            updatedSupplyOrders {
                ...SupplyOrderWithSupplierProductsInfoFragment
            }
            errors
            warnings
        }
    }
    ${SUPPLY_ORDER_WITH_SUPPLIER_PRODUCTS_INFO_FRAGMENT}
`;

export const MARK_SUPPLY_ORDERS_AS_RECEIVED_MUTATION = gql`
    mutation MARK_SUPPLY_ORDERS_AS_RECEIVED($supplyOrderIds: [ID!]!, $hourDeliveryReceivedAt: String!) {
        markSupplyOrdersAsReceived(supplyOrderIds: $supplyOrderIds, hourDeliveryReceivedAt: $hourDeliveryReceivedAt) {
            updatedSupplyOrders {
                _id
                state
            }
            errors
            warnings
        }
    }
`;

export const EXTERNAL_CONFIRM_SUPPLY_ORDER_MUTATION = gql`
    mutation EXTERNAL_CONFIRM_SUPPLY_ORDER($token: String!) {
        externalConfirmSupplyOrder(token: $token) {
            success
            error
        }
    }
`;

export const ADD_ADDITIONAL_SUPPLY_ORDER_RECEIVED_PRODUCT_DETAILS_MUTATION = gql`
    mutation ADD_ADDITIONAL_SUPPLY_ORDER_RECEIVED_PRODUCT_DETAILS(
        $supplyOrderId: String!
        $productDetails: [SupplyOrderReceivedProductDetails!]!
    ) {
        addAdditionalSupplyOrderReceivedProductDetails(supplyOrderId: $supplyOrderId, productDetails: $productDetails) {
            ...SupplyOrderWithSupplierProductsInfoFragment
        }
    }
    ${SUPPLY_ORDER_WITH_SUPPLIER_PRODUCTS_INFO_FRAGMENT}
`;

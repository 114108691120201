import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { PAGES } from '../../constants/pages';

import { GET_SITES_QUERY } from 'data/queries/site';
import { GET_SITES } from 'data/queries/__generated__/GET_SITES';
import { SITES_UPDATED_SUBSCRIPTION } from 'data/subscriptions/site';

import { FieldToDisplay, ListView } from 'components/ListView';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { Header, HeaderTitle } from 'components/Header';
import { Loader } from 'components/Loader';

import { sitesUpdatedHandler } from './utils/sitesUpdatedHandler';
import { apolloClient } from '../../App';
import { PageTitle } from 'components/PageTitle';
import { StatusTag } from 'pages/Sites/components/StatusTag';
import { TotemInput } from 'components/TotemInput';
import { TypeTag } from 'pages/Sites/components/TypeTag';

type FormattedSiteType = {
    _id: string;
    name: string;
    type: JSX.Element;
    state: JSX.Element;
};

export const Sites = () => {
    const [filterString, setFilterString] = useState('');
    const { loading: sitesLoading, data: sitesData, error: sitesError } = useQuery<GET_SITES>(GET_SITES_QUERY);
    useEffect(() => {
        const observer = apolloClient.subscribe({
            query: SITES_UPDATED_SUBSCRIPTION,
        });
        const subscription = observer.subscribe(({ data }) => {
            const { siteUpdated } = data;
            sitesUpdatedHandler(siteUpdated);
        });

        return () => subscription.unsubscribe();
    });

    if (sitesLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (!sitesData || sitesError) {
        throw new Error('Une erreur est survenue lors de la récupération des sites');
    }

    const { sites } = sitesData;

    const SITES_FIELDS_TO_DISPLAY: FieldToDisplay<FormattedSiteType>[] = [
        {
            fieldName: 'name',
            label: 'Nom',
        },
        { fieldName: 'type', label: 'Type' },
        { fieldName: 'state', label: 'Statut' },
    ];

    const filteredSites = sites.filter(({ name }) =>
        filterString ? name.toLowerCase().includes(filterString.toLowerCase()) : true,
    );

    const formattedSites: FormattedSiteType[] = filteredSites.map(({ _id, name, state, type }) => ({
        _id,
        name,
        state: <StatusTag state={state} />,
        type: <TypeTag type={type} />,
    }));

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.sites} />
                </HeaderTitle>
                <Link to="/site/create">
                    <TotemPrimaryButton>Créer un site</TotemPrimaryButton>
                </Link>
            </Header>
            <Content>
                <TotemInput
                    label="Recherche"
                    onChange={setFilterString}
                    placeholder="Nom du site"
                    value={filterString}
                />
                <ListView<FormattedSiteType>
                    fieldsToDisplay={SITES_FIELDS_TO_DISPLAY}
                    items={formattedSites}
                    linkBasePath="/site/"
                    keyExtractor={(item) => item._id}
                />
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Content = styled.div`
    padding: 15px;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import Switch from 'react-switch';
import styled from 'styled-components';

import { PAGES } from '../../constants/pages';
import { colors } from 'constants/colors';

import { GET_TAGS_QUERY } from 'data/queries/tag';
import { GET_TAGS } from 'data/queries/__generated__/GET_TAGS';
import { TagState } from 'data/__generated__';

import { FieldToDisplay, ListView } from 'components/ListView';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { Header, HeaderTitle } from 'components/Header';
import { Loader } from 'components/Loader';

import { PageTitle } from 'components/PageTitle';
import { TotemLabel } from 'components/TotemLabel';
import { TotemInput } from 'components/TotemInput';

type FormattedTagsType = {
    _id: string;
    displayIndex: number;
    name: string;
    state: string;
    type: string;
};

export const Tags = () => {
    const [shouldHideArchived, setShouldHideArchived] = useState(true);
    const [filterString, setFilterString] = useState('');
    const { loading: badgesLoading, data: tagsData, error: bagdesError } = useQuery<GET_TAGS>(GET_TAGS_QUERY);
    if (badgesLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (!tagsData || bagdesError) {
        throw new Error('Une erreur est survenue lors de la récupération des badges');
    }

    const { tags } = tagsData;

    const BADGES_FIELDS_TO_DISPLAY: FieldToDisplay<FormattedTagsType>[] = [
        {
            fieldName: 'name',
            label: 'Nom',
        },
        {
            fieldName: '_id',
            label: 'ID',
        },
        { fieldName: 'displayIndex', label: "Index d'affichage" },
        { fieldName: 'type', label: 'Type' },
        { fieldName: 'state', label: 'State' },
    ];

    const formattedTags: FormattedTagsType[] = tags
        .filter((tag) => (shouldHideArchived ? tag.state !== TagState.Archived : tag))
        .filter((tag) => (filterString ? tag.name.toLowerCase().includes(filterString.toLowerCase()) : true))
        .sort((tag1, tag2) => tag1.displayIndex - tag2.displayIndex)
        .map(({ _id, displayIndex, name, type, state }) => ({
            _id,
            displayIndex,
            name,
            type,
            state,
        }));

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.tags} />
                </HeaderTitle>
                <ButtonsContainer>
                    <LabelContainer>
                        <Label>Cacher les tags archivés</Label>
                    </LabelContainer>
                    <Switch
                        onChange={() => setShouldHideArchived(!shouldHideArchived)}
                        checked={shouldHideArchived}
                        onColor={colors.green}
                        offColor={colors.pantinGrey}
                        data-test="hide-archived-products-switch"
                    />
                    <Link to="/tag/create">
                        <TotemPrimaryButton>Créer un tag</TotemPrimaryButton>
                    </Link>
                </ButtonsContainer>
            </Header>
            <Content>
                <TotemInput
                    label="Recherche"
                    onChange={setFilterString}
                    placeholder="Nom du tag"
                    value={filterString}
                />
                <ListView<FormattedTagsType>
                    fieldsToDisplay={BADGES_FIELDS_TO_DISPLAY}
                    items={formattedTags}
                    linkBasePath="/tag/"
                    keyExtractor={(item) => item._id}
                />
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    ${TotemPrimaryButton} {
        margin-left: 5px;
    }
`;

const LabelContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const Label = styled(TotemLabel)`
    margin-right: 5px;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    flex: 1;
    overflow: hidden;
`;

import { apolloClient } from 'App';

import { GetSubcategoriesOfCategory } from 'data/queries/__generated__/GetSubcategoriesOfCategory';
import { GET_SUBCATEGORIES_OF_CATEGORY_QUERY } from 'data/queries/subcategory';
import { SubcategoryFragment } from 'data/__generated__';

export const subcategoryAddedHandler = ({
    categoryId,
    addedSubcategory,
}: {
    categoryId: string;
    addedSubcategory: SubcategoryFragment;
}) => {
    const result = apolloClient.readQuery<GetSubcategoriesOfCategory>({
        query: GET_SUBCATEGORIES_OF_CATEGORY_QUERY,
        variables: { categoryId },
    });

    if (!result) {
        return;
    }

    const { subcategoriesOfCategory } = result;

    const data = {
        subcategoriesOfCategory: [...subcategoriesOfCategory, addedSubcategory],
    };

    apolloClient.writeQuery({
        query: GET_SUBCATEGORIES_OF_CATEGORY_QUERY,
        variables: { categoryId },
        data,
    });
};

import React from 'react';

import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { GENERATE_SUPPLY_ORDERS_NEEDED_MUTATION } from 'data/mutations/supplyOrder';

import { Loader, LoaderModeType } from 'components/Loader';
import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { GENERATE_SUPPLY_ORDERS_NEEDED } from 'data/mutations/__generated__/GENERATE_SUPPLY_ORDERS_NEEDED';

export function GenerateSupplyOrdersNeededPopup({
    isOpen,
    setIsOpen,
}: {
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
}) {
    const [generateSupplyOrdersNeeded, { loading }] = useMutation<GENERATE_SUPPLY_ORDERS_NEEDED>(
        GENERATE_SUPPLY_ORDERS_NEEDED_MUTATION,
    );

    async function handleSubmit() {
        const { data } = await generateSupplyOrdersNeeded();

        if (!data) {
            throw new Error("Une erreur est survenue lors de l'annulation des achats");
        }

        const {
            generateSupplyOrdersNeededMutation: { createdSupplyOrders, mergedSupplyOrders, errors },
        } = data;

        if (createdSupplyOrders.length) {
            toast.success(`Achat(s) créé(s) : ${createdSupplyOrders.length}`);
        }

        if (mergedSupplyOrders.length) {
            toast.success(`Achat(s) fusionné(s) : ${mergedSupplyOrders.length}`);
        }

        if (!createdSupplyOrders.length && !errors?.length) {
            toast.warning('Algorithme executé sans erreur, aucun achat créé/fusionéé');
        }

        if (errors.length) {
            errors.forEach((error, index) => {
                toast.error(<span key={index}>Erreur : {error}</span>, { autoClose: false });
            });

            if (errors.length > 1) {
                toast.info('Cliquez pour fermer toutes les notifications', {
                    autoClose: false,
                    onClick: () => toast.dismiss(),
                });
            }
        }
        setIsOpen(false);
    }

    return (
        <TotemPopup title="Êtes-vous sûr.e ?" isOpen={isOpen} setIsOpen={setIsOpen}>
            Commencer la génération automatique d'achats ? (Cela est normalement fait automatiquement tous les jours à
            4h du matin).
            <SubmitContainer>
                <TotemPrimaryButton onClick={handleSubmit}>
                    {loading ? <Loader size="20px" mode={LoaderModeType.Spin} /> : 'Confirmer'}
                </TotemPrimaryButton>
            </SubmitContainer>
        </TotemPopup>
    );
}

const SubmitContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 15px;
`;

import React from 'react';

import { SectionColumn, SectionContainer, ColumnsSectionContainer } from 'components/DetailsView/Section';
import { StateHistory } from 'components/DetailsView/StateHistory';
import { StatusTag } from '../components/StatusTag';

import { SupplyOrderDetailsDisplayValues } from './supplyOrderDetailsFormHelper';

export const StatesSection = ({
    supplyOrderDetailsDisplayValues,
}: {
    supplyOrderDetailsDisplayValues: SupplyOrderDetailsDisplayValues;
}) => {
    const { state, stateHistory } = supplyOrderDetailsDisplayValues;

    const formattedStateHistory = stateHistory.map((stateRecord) => ({
        ...stateRecord,
        state: <StatusTag state={stateRecord.state} />,
    }));

    return (
        <SectionContainer title="Historique de statuts">
            <ColumnsSectionContainer numberOfColumns={2}>
                <SectionColumn>
                    <StateHistory records={formattedStateHistory} />
                </SectionColumn>
                <SectionColumn>
                    <StatusTag state={state} showHeading />
                </SectionColumn>
            </ColumnsSectionContainer>
        </SectionContainer>
    );
};

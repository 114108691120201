import React from 'react';

import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { ProductArrangementFragment } from 'data/fragments/__generated__/ProductArrangementFragment';
import { ARCHIVE_PRODUCT_ARRANGEMENTS_MUTATION } from 'data/mutations/productArrangement';
import {
    ARCHIVE_PRODUCT_ARRANGEMENTS,
    ARCHIVE_PRODUCT_ARRANGEMENTSVariables,
} from 'data/mutations/__generated__/ARCHIVE_PRODUCT_ARRANGEMENTS';

import { Loader, LoaderModeType } from 'components/Loader';
import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

import { deletedProductArrangementsHandler } from '../cacheHandlers/productArrangementsCacheHandler';

export function ArchiveProductArrangementsPopup({
    productArrangements,
    selectedProductArrangementIds,
    setSelectedProductArrangementIds,
    isOpen,
    setIsOpen,
}: {
    productArrangements: ProductArrangementFragment[];
    selectedProductArrangementIds: string[];
    setSelectedProductArrangementIds: (value: React.SetStateAction<string[]>) => void;
    isOpen: boolean;
    setIsOpen: (value: React.SetStateAction<boolean>) => void;
}) {
    const [archiveProductArrangements, { loading }] = useMutation<
        ARCHIVE_PRODUCT_ARRANGEMENTS,
        ARCHIVE_PRODUCT_ARRANGEMENTSVariables
    >(ARCHIVE_PRODUCT_ARRANGEMENTS_MUTATION, { variables: { productArrangementIds: selectedProductArrangementIds } });

    const selectedProductArrangements = productArrangements.filter((productArrangement) =>
        selectedProductArrangementIds.includes(productArrangement._id),
    );

    async function handleSubmit() {
        const { data } = await archiveProductArrangements();

        if (!data) {
            throw new Error('Une erreur est survenue lors de la suppression des gammes de produits');
        }

        const {
            archiveProductArrangementsMutation: { archivedProductArrangementIds, errors },
        } = data;

        deletedProductArrangementsHandler(archivedProductArrangementIds);

        if (archivedProductArrangementIds.length) {
            toast.success(`Gamme(s) de produits archivée(s) : ${archivedProductArrangementIds.length}`);
        }

        if (errors.length) {
            errors.forEach((error, index) => {
                toast.error(<span key={index}>Erreur : {error}</span>, { autoClose: false });
            });

            if (errors.length > 1) {
                toast.info('Cliquez pour fermer toutes les notifications', {
                    autoClose: false,
                    onClick: () => toast.dismiss(),
                });
            }
        }
        setSelectedProductArrangementIds([]);
        setIsOpen(false);
    }

    return (
        <TotemPopup title="Êtes-vous sûr.e ?" isOpen={isOpen} setIsOpen={setIsOpen}>
            Vous vous apprêtez à archiver {selectedProductArrangements.length} gamme
            {selectedProductArrangements.length > 1 ? 's' : ''} de produits. Souhaitez-vous vraiment archiver{' '}
            {selectedProductArrangements.length > 1 ? 'les gammes suivantes' : 'la gamme suivante'} ?
            <ProductArrangementlist>
                {selectedProductArrangements.map((productArrangement) => {
                    const { _id: productArrangementId, name, productIdsWithStockMax } = productArrangement;

                    return (
                        <ProductArrangement key={productArrangementId}>
                            <ProductArrangementTitle>{name}</ProductArrangementTitle>
                            <ProductArrangementText>
                                Nombre de produit : {productIdsWithStockMax.length}
                            </ProductArrangementText>
                        </ProductArrangement>
                    );
                })}
            </ProductArrangementlist>
            <SubmitContainer>
                <TotemPrimaryButton onClick={handleSubmit}>
                    {loading ? <Loader size="20px" mode={LoaderModeType.Spin} /> : 'Confirmer'}
                </TotemPrimaryButton>
            </SubmitContainer>
        </TotemPopup>
    );
}

const ProductArrangementlist = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    overflow: auto;

    & > :not(:first-child) {
        margin-top: 5px;
    }
`;

const ProductArrangement = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
`;

const ProductArrangementText = styled.div`
    margin-left: 15px;
`;

const ProductArrangementTitle = styled.div`
    font-weight: 800;
`;

const SubmitContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 15px;
`;

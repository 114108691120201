import React from 'react';
import { Route, Routes, createBrowserRouter } from 'react-router-dom';
import styled from 'styled-components';

import { PAGES } from '../../constants/pages';

import { AdvertisementScreenConfig } from 'pages/AdvertisementScreenConfig';
import { BadgeCreate } from '../Badges/BadgeCreate';
import { BadgeUpdate } from '../Badges/BadgeUpdate';
import { Badges } from '../Badges';
import { Bundles } from 'pages/Bundles';
import { Categories } from 'pages/Categories';
import { CreateToteamUser } from 'pages/Users/CreateToteamUser';
import { CreateCategory } from 'pages/Categories/CreateCategory';
import { CategoryDetails } from 'pages/Categories/CategoryDetails';
import { CreditsList, CreditsForm } from '../Credits';
import { Deliveries } from '../Deliveries';
import { DeliveryDetails } from '../Deliveries/DeliveryDetails';
import { ExpiredFutureStockVariations } from 'pages/Stocks/ExpiredFutureStockVariations';
import { ExpiryDateRecords } from 'pages/ExpiryDateRecords';
import { FutureStockVariations } from 'pages/Stocks/FutureStockVariations';
import { Home } from '../Home';
import { Locations } from 'pages/Locations';
import { MessagesSet } from 'pages/MessagesSets/index';
import { MessagesSetCreate } from 'pages/MessagesSets/MessagesSetCreate';
import { MessagesSetDetails } from 'pages/MessagesSets/MessagesSetDetails';
import { MicrostoreColumnDetails } from 'pages/MicrostoreColumns/MicrostoreColumnDetails';
import { MicrostoreColumnTemplateDetails } from 'pages/MicrostoreColumnTemplates/MicrostoreColumnTemplateDetails';
import { MicrostoreColumnTemplates } from 'pages/MicrostoreColumnTemplates';
import { MicrostoreColumns } from 'pages/MicrostoreColumns';
import { MicrostoreColumnsTargetsInfo } from 'pages/MicrostoreColumns/TargetsInfo';
import { MicrostoreMenus } from 'pages/MicrostoreMenus';
import { MicrostoreMenusDetails } from 'pages/MicrostoreMenus/MicrostoreMenusDetails';
import { Notifications } from '../Notifications';
import { NotificationsOrganization } from '../Notifications/NotificationsOrganization';
import { NotificationsSite } from 'pages/Notifications/NotificationsSite';
import { NotificationsUser } from '../Notifications/NotificationsUser';
import { Orders } from 'pages/Orders';
import { OrderDetails } from 'pages/Orders/OrderDetails';
import { OrganizationDetails } from '../Organizations/OrganizationDetails';
import { OrganizationCreate } from '../Organizations/OrganizationCreate';
import { Organizations } from '../Organizations';
import { PaymentDetails } from 'pages/Payments/PaymentDetails';
import { Payments } from 'pages/Payments';
import { ProductArrangementDetails } from 'pages/ProductArrangements/ProductArrangementDetails';
import { ProductArrangements } from 'pages/ProductArrangements';
import { ProductCreate } from 'pages/Products/ProductCreate';
import { ProductCsvImport } from '../Products/ProductImports/index';
import { ProductDetails } from '../Products/ProductDetails';
import { ProductPriceRangeCreate } from '../ProductPriceRanges/ProductPriceRangeCreate';
import { ProductPriceRangeDetails } from '../ProductPriceRanges/ProductPriceRangeDetails';
import { ProductPriceRanges } from '../ProductPriceRanges';
import { Products } from '../Products';
import { RitualCreate } from 'pages/Rituals/RitualCreate';
import { RitualDetails } from 'pages/Rituals/RitualDetails';
import { Rituals } from 'pages/Rituals';
import { RitualTemplates } from 'pages/RitualTemplates';
import { Settings } from '../Settings';
import { SiteCreate } from '../Sites/SiteCreate';
import { SiteDetails } from '../Sites/SiteDetails';
import { Sites } from '../Sites';
import { SiteProductStocks } from 'pages/Stocks/SiteProductStocks';
import { SiteStocks } from '../Stocks/SiteStocks';
import { SiteInventoryStockCreate } from '../Stocks/SiteInventoryStockCreate';
import { Stocks } from '../Stocks';
import { StockVariationCreate } from '../Stocks/StockVariationCreate';
import { StockTransferCreate } from '../StockTransfers/StockTransferCreate';
import { StockTransferDetails } from '../StockTransfers/StockTransferDetails';
import { StockTransferReturns } from 'pages/StockTransfers/StockTransferReturns';
import { StockTransfers } from '../StockTransfers';
import { Suppliers } from '../Suppliers';
import { SupplierDetails } from '../Suppliers/SupplierDetails';
import { SupplyOrderDetails } from '../SupplyOrders/SupplyOrderDetails';
import { SupplyOrders } from '../SupplyOrders';
import { TagCreate } from '../Tags/TagCreate';
import { TagUpdate } from '../Tags/TagUpdate';
import { Tags } from '../Tags';
import { TermsRecordCreate } from '../TermsRecords/TermsRecordCreate';
import { TermsRecords } from '../TermsRecords';
import { TransactionDetails } from '../Transactions/TransactionDetails';
import { Transactions } from '../Transactions';
import { UserDetails, Users } from '../Users';
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import { NavBar } from './NavBar';
import { NotFound } from 'components/NotFound';
import { OpsParameters } from 'pages/OpsParameters';
import { SupplierCreate } from 'pages/Suppliers/SupplierDetails/SupplierCreate';
import { SiteSetups } from 'pages/SiteSetups';
import { DeliveriesDailyPhotos } from 'pages/Deliveries/DeliveriesDailyPhotos';
import { SiteExternalInventory } from 'pages/SiteExternalInventory';
import { WarehouseReturns } from 'pages/WarehouseReturn';
import { WarehouseReturnDetails } from 'pages/WarehouseReturn/details/WarehouseReturnDetails';
import { StockVariationsByDay } from 'pages/Stocks/variationsByDay';
import { ProductGroupDetails } from 'pages/ProductGroup/details/index.ts';
import { SiteSetupDetails } from 'pages/SiteSetups/details';
import { HotDrinksArrangements } from 'pages/HotDrinksArrangement';
import { HotDrinksArrangementDetails } from 'pages/HotDrinksArrangement/details';
import { TotemClosedDays } from 'pages/TotemClosedDays';
import { TotemClosedReceptionDays } from 'pages/TotemClosedReceptionDays';
import { Thermometers } from 'pages/Thermometers';
import { ProductSwitches } from 'pages/ProductSwitch';
import { BakeryRecords } from 'pages/BakeryRecords';
import { MicrostoreSnapshot } from 'pages/MicrostoreSnapshot';
import { Equipments } from 'pages/Equipments';
import { EquipmentDetails } from 'pages/Equipments/EquipmentDetails';
import { UrbantzRoundPresets } from 'pages/UrbantzRoundPresets';
import { Polls } from 'pages/Polls';
import { PollDetails } from 'pages/Polls/PollDetails';
import { PollCreate } from 'pages/Polls/PollCreate';
import { MicrostorePlanogram } from 'pages/MicrostorePlanogram';
import { Quizzes } from 'pages/Quizzes';
import { QuizCreate } from 'pages/Quizzes/QuizCreate';
import { QuizDetails } from 'pages/Quizzes/QuizDetails';
import { SenseiBasket } from 'pages/SenseiBasket';
import { SupplierPartnerships } from 'pages/SupplierPartnership';
import { StockVariationMultiCreate } from 'pages/Stocks/StockVariationMultiCreate';
import { SupplierDiscountsPage } from 'pages/SupplierDiscounts';
import { CommentsPage } from 'pages/Comments';
import { MobilePopupsPage } from 'pages/MobilePopups';
import { Subventions } from 'pages/Subventions';
import { SubventionDetails } from 'pages/Subventions/SubventionDetails';
import { SubventionCreate } from 'pages/Subventions/SubventionCreate';
import { WarehouseDeliveries } from 'pages/WarehouseDeliveries';
import { WarehouseDeliveryDetails } from 'pages/WarehouseDeliveries/WarehouseDeliveryDetails';

function PrivateRoot() {
    return (
        <AppContainer>
            <NavBar />
            <ErrorBoundary>
                <Routes>
                    {/* <Route path="/" element={<Outlet />}> */}
                    <Route path={PAGES.advertisementScreenConfig.url} element={<AdvertisementScreenConfig />} />
                    <Route path={PAGES.badges.url} element={<Badges />} />
                    <Route path={PAGES.badgeCreate.url} element={<BadgeCreate />} />
                    <Route path={PAGES.badgeUpdate.url} element={<BadgeUpdate />} />
                    <Route path={PAGES.bakeryRecords.url} element={<BakeryRecords />} />
                    <Route path={PAGES.bundles.url} element={<Bundles />} />
                    <Route path={PAGES.categories.url} element={<Categories />} />
                    <Route path={PAGES.comments.url} element={<CommentsPage />} />
                    <Route path={PAGES.categoryCreate.url} element={<CreateCategory />} />
                    <Route path={PAGES.categoryDetails.url} element={<CategoryDetails />} />
                    <Route path={PAGES.credits.url} element={<CreditsList />} />
                    <Route path={PAGES.creditCreate.url} element={<CreditsForm />} />
                    <Route path={PAGES.deliveries.url} element={<Deliveries />} />
                    <Route path={PAGES.deliveriesDailyPhoto.url} element={<DeliveriesDailyPhotos />} />
                    <Route path={PAGES.deliveryDetails.url} element={<DeliveryDetails />} />
                    <Route path={PAGES.expiryDateRecords.url} element={<ExpiryDateRecords />} />
                    <Route path={PAGES.equipments.url} element={<Equipments />} />
                    <Route path={PAGES.equipmentDetails.url} element={<EquipmentDetails />} />
                    <Route path={PAGES.hotDrinksArrangements.url} element={<HotDrinksArrangements />} />
                    <Route path={PAGES.hotDrinksArrangementDetails.url} element={<HotDrinksArrangementDetails />} />
                    <Route path={PAGES.locations.url} element={<Locations />} />
                    <Route path={PAGES.supplierPartnerships.url} element={<SupplierPartnerships />} />
                    <Route path={PAGES.menus.url} element={<MicrostoreMenus />} />
                    <Route path={PAGES.menusDetails.url} element={<MicrostoreMenusDetails />} />
                    <Route path={PAGES.messagesSets.url} element={<MessagesSet />} />
                    <Route path={PAGES.messagesSetsCreate.url} element={<MessagesSetCreate />} />
                    <Route path={PAGES.messagesSetDetails.url} element={<MessagesSetDetails />} />
                    <Route path={PAGES.microstoreColumns.url} element={<MicrostoreColumns />} />
                    <Route path={PAGES.microstoreColumnTargetsInfo.url} element={<MicrostoreColumnsTargetsInfo />} />
                    <Route path={PAGES.microstoreColumnDetails.url} element={<MicrostoreColumnDetails />} />
                    <Route path={PAGES.microstorePlanogram.url} element={<MicrostorePlanogram />} />
                    <Route path={PAGES.microstoreColumnTemplates.url} element={<MicrostoreColumnTemplates />} />
                    <Route
                        path={PAGES.microstoreColumnTemplateDetails.url}
                        element={<MicrostoreColumnTemplateDetails />}
                    />
                    <Route path={PAGES.mobilePopups.url} element={<MobilePopupsPage />} />
                    <Route path={PAGES.microstoreSnapshot.url} element={<MicrostoreSnapshot />} />
                    <Route path={PAGES.notifications.url} element={<Notifications />} />
                    <Route path={PAGES.notificationsOrganization.url} element={<NotificationsOrganization />} />
                    <Route path={PAGES.notificationsSite.url} element={<NotificationsSite />} />
                    <Route path={PAGES.notificationsUser.url} element={<NotificationsUser />} />
                    <Route path={PAGES.opsParameters.url} element={<OpsParameters />} />
                    <Route path={PAGES.orders.url} element={<Orders />} />
                    <Route path={PAGES.orderDetails.url} element={<OrderDetails />} />
                    <Route path={PAGES.organizations.url} element={<Organizations />} />
                    <Route path={PAGES.organizationCreate.url} element={<OrganizationCreate />} />
                    <Route path={PAGES.organizationDetails.url} element={<OrganizationDetails />} />
                    <Route path={PAGES.payments.url} element={<Payments />} />
                    <Route path={PAGES.paymentDetails.url} element={<PaymentDetails />} />
                    <Route path={PAGES.polls.url} element={<Polls />} />
                    <Route path={PAGES.pollCreate.url} element={<PollCreate />} />
                    <Route path={PAGES.pollDetails.url} element={<PollDetails />} />
                    <Route path={PAGES.productPriceRanges.url} element={<ProductPriceRanges />} />
                    <Route path={PAGES.productPriceRangeCreate.url} element={<ProductPriceRangeCreate />} />
                    <Route path={PAGES.productPriceRangeDetails.url} element={<ProductPriceRangeDetails />} />
                    <Route path={PAGES.productPriceRanges.url} element={<ProductPriceRanges />} />
                    <Route path={PAGES.productPriceRangeCreate.url} element={<ProductPriceRangeCreate />} />
                    <Route path={PAGES.productPriceRangeDetails.url} element={<ProductPriceRangeDetails />} />
                    <Route path={PAGES.products.url} element={<Products />} />
                    <Route path={PAGES.productCreate.url} element={<ProductCreate />} />
                    <Route path={PAGES.productImport.url} element={<ProductCsvImport />} />
                    <Route path={PAGES.productDetails.url} element={<ProductDetails />} />
                    <Route path={PAGES.productGroupDetails.url} element={<ProductGroupDetails />} />
                    <Route path={PAGES.productArrangements.url} element={<ProductArrangements />} />
                    <Route path={PAGES.productArrangementDetails.url} element={<ProductArrangementDetails />} />
                    <Route path={PAGES.productSwitches.url} element={<ProductSwitches />} />
                    <Route path={PAGES.quizzes.url} element={<Quizzes />} />
                    <Route path={PAGES.quizCreate.url} element={<QuizCreate />} />
                    <Route path={PAGES.quizDetails.url} element={<QuizDetails />} />
                    <Route path={PAGES.ritualCreate.url} element={<RitualCreate />} />
                    <Route path={PAGES.ritualDetails.url} element={<RitualDetails />} />
                    <Route path={PAGES.rituals.url} element={<Rituals />} />
                    <Route path={PAGES.ritualTemplates.url} element={<RitualTemplates />} />
                    <Route path={PAGES.settings.url} element={<Settings />} />
                    <Route path={PAGES.sites.url} element={<Sites />} />
                    <Route path={PAGES.siteCreate.url} element={<SiteCreate />} />
                    <Route path={PAGES.siteDetails.url} element={<SiteDetails />} />
                    <Route path={PAGES.siteInventoryStockCreate.url} element={<SiteInventoryStockCreate />} />
                    <Route path={PAGES.siteProductStocks.url} element={<SiteProductStocks />} />
                    <Route path={PAGES.siteSetups.url} element={<SiteSetups />} />
                    <Route path={PAGES.siteSetupDetails.url} element={<SiteSetupDetails />} />
                    <Route path={PAGES.siteStocks.url} element={<SiteStocks />} />
                    <Route path={PAGES.stockTransfers.url} element={<StockTransfers />} />
                    <Route path={PAGES.siteExternalInventory.url} element={<SiteExternalInventory />} />
                    <Route path={PAGES.stockTransferCreate.url} element={<StockTransferCreate />} />
                    <Route path={PAGES.stockTransferReturns.url} element={<StockTransferReturns />} />
                    <Route path={PAGES.stockTransferDetails.url} element={<StockTransferDetails />} />
                    <Route path={PAGES.expiredFutureStockVariations.url} element={<ExpiredFutureStockVariations />} />
                    <Route path={PAGES.futureStockVariations.url} element={<FutureStockVariations />} />
                    <Route path={PAGES.stocks.url} element={<Stocks />} />
                    <Route path={PAGES.stockVariationCreate.url} element={<StockVariationCreate />} />
                    <Route path={PAGES.stockVariationMultiCreate.url} element={<StockVariationMultiCreate />} />
                    <Route path={PAGES.stockVariationsByDay.url} element={<StockVariationsByDay />} />
                    <Route path={PAGES.subventions.url} element={<Subventions />} />
                    <Route path={PAGES.subventionCreate.url} element={<SubventionCreate />} />
                    <Route path={PAGES.subventionDetails.url} element={<SubventionDetails />} />
                    <Route path={PAGES.suppliers.url} element={<Suppliers />} />
                    <Route path={PAGES.supplierCreate.url} element={<SupplierCreate />} />
                    <Route path={PAGES.supplierDetails.url} element={<SupplierDetails />} />
                    <Route path={PAGES.supplyOrders.url} element={<SupplyOrders />} />
                    <Route path={PAGES.supplyOrderDetails.url} element={<SupplyOrderDetails />} />
                    <Route path={PAGES.supplierDiscounts.url} element={<SupplierDiscountsPage />} />
                    <Route path={PAGES.tags.url} element={<Tags />} />
                    <Route path={PAGES.tagCreate.url} element={<TagCreate />} />
                    <Route path={PAGES.tagUpdate.url} element={<TagUpdate />} />
                    <Route path={PAGES.termsRecords.url} element={<TermsRecords />} />
                    <Route path={PAGES.termsRecordCreate.url} element={<TermsRecordCreate />} />
                    <Route path={PAGES.thermometers.url} element={<Thermometers />} />
                    <Route path={PAGES.transactions.url} element={<Transactions />} />
                    <Route path={PAGES.transactionDetails.url} element={<TransactionDetails />} />
                    <Route path={PAGES.totemClosedDays.url} element={<TotemClosedDays />} />
                    <Route path={PAGES.totemClosedReceptionDays.url} element={<TotemClosedReceptionDays />} />
                    <Route path={PAGES.urbantzRoundPresets.url} element={<UrbantzRoundPresets />} />
                    <Route path={PAGES.users.url} element={<Users />} />
                    <Route path={PAGES.userCreateToteam.url} element={<CreateToteamUser />} />
                    <Route path={PAGES.userDetails.url} element={<UserDetails />} />
                    <Route path={PAGES.senseiBasket.url} element={<SenseiBasket />} />
                    <Route path={PAGES.warehouseDeliveries.url} element={<WarehouseDeliveries />} />
                    <Route path={PAGES.warehouseDeliveryDetails.url} element={<WarehouseDeliveryDetails />} />
                    <Route path={PAGES.warehouseReturns.url} element={<WarehouseReturns />} />
                    <Route path={PAGES.warehouseReturnDetails.url} element={<WarehouseReturnDetails />} />
                    <Route path="/" element={<Home />} />
                    <Route element={<NotFound />} />
                    {/* </Route> */}
                </Routes>
            </ErrorBoundary>
        </AppContainer>
    );
}

export const privateRouter = createBrowserRouter([{ path: '*', Component: PrivateRoot }]);

const AppContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
`;

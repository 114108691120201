import React from 'react';
import { FutureStockVariationState } from 'data/__generated__';
import { CommonStatusTag } from 'components/CommonStatusTag';
import { colors } from 'constants/colors';

export const StatusTag = ({ state }: { state: FutureStockVariationState }) => {
    const getColorsFromState = (state: FutureStockVariationState) => {
        const { pantinGrey, green, pureWhite, black } = colors;

        switch (state) {
            case FutureStockVariationState.Ongoing:
                return { backgroundColor: green, labelColor: black };
            case FutureStockVariationState.Completed:
                return { backgroundColor: pantinGrey, labelColor: pureWhite };
            case FutureStockVariationState.Aborted:
                return { backgroundColor: pantinGrey, labelColor: pureWhite };
        }
    };

    const { backgroundColor, labelColor } = getColorsFromState(state);

    return <CommonStatusTag backgroundColor={backgroundColor} labelColor={labelColor} label={state} />;
};

import React from 'react';

import styled from 'styled-components';
import { useFieldArray, useFormContext, get } from 'react-hook-form';
import type { Validate } from 'react-hook-form';
import { FaTrash, FaPlus } from 'react-icons/fa';

import { colors } from 'constants/colors';
import { getFormError } from 'constants/formErrors';

import { BasicInput } from '../BasicInput';
import { TotemLabel } from '../TotemLabel';

export const DetailFormArrayValue = ({
    disabled,
    name,
    label,
    validateValueFunction,
}: {
    disabled?: boolean;
    name: string;
    label?: string;
    validateValueFunction?: Validate<string, any>;
}) => {
    const {
        register,
        formState: { errors },
    } = useFormContext();
    const { fields, append, remove } = useFieldArray({ name });

    const errorObject = get(errors, name || '');
    const error = errorObject ? getFormError(errorObject.type, errorObject.message) : '';

    return (
        <Container>
            <ArrayLabel>{label}</ArrayLabel>
            {error ? <Error>{error}</Error> : null}
            <List>
                {fields.map((item, index) => {
                    const itemErrorObject = get(errors, `${name}.${index}.value`);
                    const itemError = itemErrorObject
                        ? getFormError(itemErrorObject.type, itemErrorObject.message)
                        : '';

                    return (
                        <Entry key={item.id} hasBorderTop={index > 0}>
                            <BasicInput
                                disabled={disabled}
                                {...register(`${name}.${index}.value`, {
                                    validate: validateValueFunction
                                        ? {
                                              value: validateValueFunction,
                                          }
                                        : undefined,
                                })}
                            />
                            {itemError ? <Error>{itemError}</Error> : null}
                            <IconContainer
                                disabled={disabled}
                                onClick={() => {
                                    remove(index);
                                }}
                            >
                                <TrashIcon color={colors.pureWhite} />
                            </IconContainer>
                        </Entry>
                    );
                })}
                <Entry hasBorderTop={fields.length > 0}>
                    <IconContainer disabled={disabled} onClick={() => append({})}>
                        <PlusIcon color={colors.pureWhite} />
                    </IconContainer>
                </Entry>
            </List>
        </Container>
    );
};

const Container = styled.div`
    display: inline-flex;
    flex-direction: column;

    & > :not(:first-child) {
        margin-top: 5px;
    }
`;

const List = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
    min-width: 200px;
`;

const Entry = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-top: ${({ hasBorderTop }: { hasBorderTop: boolean }) => (hasBorderTop ? 1 : 0)}px solid
        ${({ theme }) => theme.lightBorderColor};

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const IconContainer = styled.div<{ disabled?: boolean }>`
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background-color: ${({ disabled, theme }) => (disabled ? theme.disabledColor : theme.ctaPrimaryColor)};
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
    cursor: pointer;
`;

const TrashIcon = styled(FaTrash)`
    color: ${({ theme }) => theme.menuTextColor};
`;

const PlusIcon = styled(FaPlus)`
    color: ${({ theme }) => theme.menuTextColor};
`;

const ArrayLabel = styled(TotemLabel)`
    margin: 5px 0;
`;

const Error = styled.div`
    font-size: 14px;
    color: ${({ theme }) => theme.errorColor};
    width: max-content;
`;

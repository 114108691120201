import React, { FunctionComponent } from 'react';
import { TotemPopup } from 'components/TotemPopup';
import { SupplierDiscountsQuery, useUpdateSupplierDiscountMutation } from 'data/__generated__';

import { formatFormValues, stringifyToFormValues } from './utils';
import { UpsertSupplierDiscountForm } from './UpsertSupplierDiscountForm';

type UpdateSupplierDiscountModalProps = {
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
    supplierDiscount: SupplierDiscountsQuery['supplierDiscounts'][number];
};

export const UpdateSupplierDiscountModal: FunctionComponent<UpdateSupplierDiscountModalProps> = ({
    isOpen,
    setIsOpen,
    supplierDiscount,
}) => {
    const [updateSupplierDiscount] = useUpdateSupplierDiscountMutation({
        onCompleted: (data) => {
            if (!data?.updateSupplierDiscount) {
                return;
            }
            setIsOpen(false);
        },
    });

    const stringifiedFormValues = stringifyToFormValues(supplierDiscount);

    return (
        <TotemPopup isOpen={isOpen} title="Modifier une remise fournisseur" setIsOpen={setIsOpen}>
            <UpsertSupplierDiscountForm
                onSubmit={(values) =>
                    updateSupplierDiscount({ variables: { id: supplierDiscount._id, input: formatFormValues(values) } })
                }
                defaultValues={stringifiedFormValues}
                imageUrl={supplierDiscount.imageUrl ?? null}
            />
        </TotemPopup>
    );
};

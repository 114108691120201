import gql from 'graphql-tag';

import { THERMOMETER_FRAGMENT } from '../fragments/thermometer';

export const GET_THERMOMETERS_QUERY = gql`
    query GET_THERMOMETERS {
        getThermometers {
            ...ThermometerFragment
        }
    }
    ${THERMOMETER_FRAGMENT}
`;

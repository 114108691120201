import { gql } from '@apollo/client';

export const CREATE_BADGE_MUTATION = gql`
    mutation CREATE_BADGE($badge: CreateBadgeInput!) {
        createBadge(badge: $badge) {
            badge {
                name
                type
                imageUrl
            }
            success
            error
        }
    }
`;

export const UPDATE_BADGE_MUTATION = gql`
    mutation UPDATE_BADGE($badge: UpdateBadgeInput!) {
        updateBadge(badge: $badge) {
            badge {
                name
                type
                imageUrl
            }
            success
            error
        }
    }
`;

import React from 'react';

import styled from 'styled-components';

export type Option<T = string> = {
    label: string;
    value: T;
    isDisabled?: boolean;
};

export type SelectedOption<T = string> = Option<T> | null | undefined;

type TotemCheckboxProps = {
    disabled?: boolean;
    label?: string;
    checked: boolean;
    onChange: () => void;
};

export function TotemCheckbox({ label, checked, onChange, disabled }: TotemCheckboxProps) {
    return (
        <Container>
            <CheckboxInput type="checkbox" checked={checked} onChange={onChange} disabled={disabled} />
            {label ? <Text isDisabled={disabled}>{label}</Text> : null}
        </Container>
    );
}

const Container = styled.label`
    display: inline-flex;
    align-items: center;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const CheckboxInput = styled.input`
    margin: 0;
`;

const Text = styled.span<{ isDisabled?: boolean }>`
    font-size: 18px;
    color: ${({ theme, isDisabled }) => (isDisabled ? theme.disabledColor : theme.textColor)};
`;

import gql from 'graphql-tag';

import { MESSAGES_SET_FRAGMENT } from 'data/fragments/messagesSet';

export const GET_MESSAGES_SETS_PAGINATED_QUERY = gql`
    query GET_MESSAGES_SETS_PAGINATED($offset: Int!, $limit: Int!) {
        messagesSetsPaginated(offset: $offset, limit: $limit) {
            hasMore
            messagesSets {
                ...MessagesSetFragment
            }
        }
    }
    ${MESSAGES_SET_FRAGMENT}
`;

export const GET_MESSAGES_SETS_QUERY = gql`
    query GET_MESSAGES_SETS {
        messagesSets {
            ...MessagesSetFragment
        }
    }
    ${MESSAGES_SET_FRAGMENT}
`;

export const GET_MESSAGES_SET_QUERY = gql`
    query GET_MESSAGES_SET($messagesSetId: ID!) {
        messagesSet(messagesSetId: $messagesSetId) {
            ...MessagesSetFragment
        }
    }
    ${MESSAGES_SET_FRAGMENT}
`;

import React from 'react';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';

import { GET_DETAILED_SUPPLIER_detailedSupplier_orderingTimes } from 'data/queries/__generated__/GET_DETAILED_SUPPLIER';
import { SupplierFormInput, SupplierOrderingTimesInput } from 'data/__generated__';
import { DAYS_OF_THE_WEEK } from 'constants/dateAndTime';

import { DetailFormDatePicker } from 'components/DetailsView/DetailFormDatePicker';
import { SectionContainer } from 'components/DetailsView/Section';
import { DetailFormCheckbox, DetailFormSelect } from 'components/DetailsView';

import { computeHourDeliveryFromDate, dateFromHourString } from 'helpers/dateTimes';
import { getOrderingTimesDay } from 'pages/Suppliers/utils';
import { SupplierClosingDates } from '../components/SupplierClosingDates';

const DAYS = DAYS_OF_THE_WEEK.map(({ label }) => label);

function createDeliveryDaysOptionsForOrderingDay(day: number): { value: number; label: string }[] {
    return new Array(21).fill(0).map((_, index) => {
        return {
            value: index,
            label: `${DAYS[(index + day) % 7]}${
                index === 0 ? ' (le jour même)' : ` (+${index} jour${index === 1 ? '' : 's'})`
            }`,
        };
    });
}

export const OrderingTimes = ({
    orderingTimes,
    supplierFormState,
    setSupplierFormState,
}: {
    orderingTimes: GET_DETAILED_SUPPLIER_detailedSupplier_orderingTimes | SupplierOrderingTimesInput;
    supplierFormState: SupplierFormInput;
    setSupplierFormState: React.Dispatch<React.SetStateAction<SupplierFormInput>>;
}) => {
    const { control } = useFormContext();
    return (
        <SectionContainer title="Dates et heures de livraison et de commande" isInitiallyOpen>
            <>
                <MetadataTable>
                    <thead>
                        <tr>
                            <th>Jour de commande</th>
                            <th>Peut être commandé</th>
                            <th>Heure limite de commande</th>
                            <th>Jour de livraison</th>
                            <th>Livré à partir de</th>
                            <th>Jusqu'à</th>
                        </tr>
                    </thead>
                    <tbody>
                        {DAYS_OF_THE_WEEK.map(({ label, name }, dayIndex) => (
                            <tr key={name}>
                                <td>{label}</td>
                                <td>
                                    <DetailFormCheckbox
                                        name={`orderingTimes.${name}.canBeOrdered`}
                                        defaultChecked={getOrderingTimesDay({ orderingTimes, name }).canBeOrdered}
                                        disabled={['saturday', 'sunday'].includes(name)}
                                    />
                                </td>
                                <td>
                                    <DetailFormDatePicker
                                        placeholder="Heure de livraison"
                                        name={`orderingTimes.${name}.orderBefore`}
                                        control={control}
                                        transformDateToValue={(date) =>
                                            date ? computeHourDeliveryFromDate(date, ':') : null
                                        }
                                        transformValueToDate={(value) =>
                                            value ? dateFromHourString(value, ':') : null
                                        }
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={30}
                                        timeCaption="Heure"
                                        dateFormat="HH:mm"
                                    />
                                </td>
                                <td>
                                    <DetailFormSelect<number>
                                        placeholder="Sélectionner un jour de livraison"
                                        name={`orderingTimes.${name}.daysToDeliver`}
                                        options={createDeliveryDaysOptionsForOrderingDay(dayIndex)}
                                        dataTest={`orderingTimes.${name}.daysToDeliver`}
                                    />
                                </td>
                                <td>
                                    <DetailFormDatePicker
                                        placeholder="Heure de livraison"
                                        name={`orderingTimes.${name}.deliveryHours.start`}
                                        control={control}
                                        transformDateToValue={(date) =>
                                            date ? computeHourDeliveryFromDate(date, ':') : null
                                        }
                                        transformValueToDate={(value) =>
                                            value ? dateFromHourString(value, ':') : null
                                        }
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={30}
                                        timeCaption="Heure"
                                        dateFormat="HH:mm"
                                    />
                                </td>
                                <td>
                                    <DetailFormDatePicker
                                        placeholder="Heure de livraison"
                                        name={`orderingTimes.${name}.deliveryHours.end`}
                                        control={control}
                                        transformDateToValue={(date) =>
                                            date ? computeHourDeliveryFromDate(date, ':') : null
                                        }
                                        transformValueToDate={(value) =>
                                            value ? dateFromHourString(value, ':') : null
                                        }
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={30}
                                        timeCaption="Heure"
                                        dateFormat="HH:mm"
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </MetadataTable>
                <SupplierClosingDates
                    closingDatesRanges={supplierFormState.closingDatesRanges}
                    setClosingDatesRanges={(closingDatesRanges) =>
                        setSupplierFormState({ ...supplierFormState, closingDatesRanges })
                    }
                />
            </>
        </SectionContainer>
    );
};

const MetadataTable = styled.table`
    width: 100%;
    color: ${({ theme }) => theme.textColor};
    th {
        text-align: left;
    }
`;

import type { FieldArrayMethodProps, FieldArrayWithId } from 'react-hook-form';
import { SelectedOption } from 'components/TotemCheckbox';
import { TotemSelect } from 'components/TotemSelect';
import { GET_SUPPLIERS_suppliers } from 'data/queries/__generated__/GET_SUPPLIERS';
import React from 'react';
import styled from 'styled-components';
import { customStyles } from './PriceRangeSelector';
import { SupplierType } from 'pages/Products/constants/defaultProduct';

type SupplierFields = FieldArrayWithId<
    {
        suppliers: SupplierType[];
    },
    'suppliers',
    'id'
>[];

export const SupplierSelector = ({
    prepend,
    supplierFields,
    suppliers,
}: {
    prepend: (value: SupplierType | SupplierType[], options?: FieldArrayMethodProps | undefined) => void;
    supplierFields: SupplierFields;
    suppliers: GET_SUPPLIERS_suppliers[];
}) => {
    const onSelect = (option: SelectedOption) => {
        if (option && !supplierFields.some(({ _id }) => _id === option.value)) {
            prepend({ _id: option.value, code: '', prices: [] });
        }
    };

    const supplierOptions = suppliers.map(({ _id, name }: { _id: string; name: string }) => ({
        value: _id,
        label: name,
    }));

    return (
        <SelectorContainer>
            <TotemSelect
                label="Ajouter un fournisseur "
                placeholder="Recherche par nom ou par _id"
                value={null}
                options={supplierOptions}
                onChange={(option) => onSelect(option)}
                styles={customStyles}
                dataTest="product-supplier"
            />
        </SelectorContainer>
    );
};

const SelectorContainer = styled.div`
    width: 100%;
    font-size: 15px;
`;

import gql from 'graphql-tag';

export const WAREHOUSE_RETURN_FRAGMENT = gql`
    fragment WarehouseReturnFragment on WarehouseReturn {
        _id
        brokenProducts {
            productId
            quantity
            deliveredQuantity
            storedQuantity
        }
        createdAt
        date
        expiredProducts {
            productId
            quantity
            deliveredQuantity
            storedQuantity
        }
        originSiteId
        returnedProducts {
            productId
            quantity
            deliveredQuantity
            storedQuantity
        }
        returnedWithNoStockUpdateProducts {
            productId
            quantity
            deliveredQuantity
            storedQuantity
        }
        state
        stateHistory {
            createdAt
            state
        }
        updatedAt
    }
`;

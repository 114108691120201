import React, { useState } from 'react';

import styled from 'styled-components';

import { PAGES } from '../../constants/pages';

import { FieldToDisplay, ListView } from 'components/ListView';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { Header, HeaderTitle } from 'components/Header';
import { Loader } from 'components/Loader';
import { PageTitle } from 'components/PageTitle';
import { CreateTotemClosedReceptionDayPopup } from './components/CreateTotemClosedReceptionDayPopup';
import { AbortTotemClosedReceptionDayPopup } from './components/AbortTotemClosedReceptionDayPopup';

import { totemClosedReceptionDayRemovedHandler } from './cacheHandlers/totemClosedReceptionDayCacheHandler';
import { toast } from 'react-toastify';
import { formatDateAsAnniversary } from 'helpers/dateTimes';
import {
    TotemClosedReceptionDayFragmentFragment,
    useArchiveTotemClosedReceptionDayMutation,
    useGetAllRegularTotemClosedReceptionDayQuery,
} from 'data/__generated__';

type FormattedTotemClosedReceptionDayType = {
    _id: string;
    date: string;
    abortButton: JSX.Element;
    archiveButton: JSX.Element;
};

export const TotemClosedReceptionDays = () => {
    const [isCreatePopupOpen, setIsCreatePopupOpen] = useState<boolean>(false);
    const [isAbortPopupOpen, setIsAbortPopupOpen] = useState<boolean>(false);
    const [totemClosedReceptionDayToAbort, setTotemClosedReceptionDayToAbort] =
        useState<TotemClosedReceptionDayFragmentFragment | null>(null);

    const {
        loading: totemClosedReceptionDaysLoading,
        data: totemClosedReceptionDaysData,
        error: totemClosedReceptionDaysError,
    } = useGetAllRegularTotemClosedReceptionDayQuery();

    const [archiveTotemClosedReceptionDay, { loading }] = useArchiveTotemClosedReceptionDayMutation();

    if (totemClosedReceptionDaysLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (!totemClosedReceptionDaysData || totemClosedReceptionDaysError) {
        throw new Error('Une erreur est survenue lors de la récupération des jours de fermeture');
    }

    const todayDateFormatted = formatDateAsAnniversary({ dateTime: new Date(), useNewFormat: true });

    async function archive(totemClosedReceptionDayId: string) {
        if (window.confirm('Voulez-vous archiver ce jour de fermeture ?')) {
            const { data } = await archiveTotemClosedReceptionDay({ variables: { totemClosedReceptionDayId } });

            if (!data) {
                throw new Error('Une erreur est survenue lors de la création du jour de fermeture');
            }

            const { archiveTotemClosedReceptionDay: archivedTotemClosedReceptionDay } = data;

            if (archivedTotemClosedReceptionDay) {
                totemClosedReceptionDayRemovedHandler(archivedTotemClosedReceptionDay._id);
                toast.success('Jour de fermeture archivé');
            } else {
                toast.error('Une erreur est survenue');
            }
        }
    }

    const { getAllRegularTotemClosedReceptionDay: totemClosedReceptionDays } = totemClosedReceptionDaysData;

    const SITES_FIELDS_TO_DISPLAY: FieldToDisplay<FormattedTotemClosedReceptionDayType>[] = [
        {
            fieldName: 'date',
            label: 'Date',
        },
        { fieldName: 'abortButton', label: 'Annuler' },
        { fieldName: 'archiveButton', label: 'Archiver' },
    ];

    const formattedTotemClosedReceptionDays: FormattedTotemClosedReceptionDayType[] = totemClosedReceptionDays.map(
        (totemClosedReceptionDay) => {
            const { _id, date } = totemClosedReceptionDay;
            return {
                _id,
                date,
                abortButton: (
                    <TotemPrimaryButton
                        onClick={() => {
                            setTotemClosedReceptionDayToAbort(totemClosedReceptionDay);
                            setIsAbortPopupOpen(true);
                        }}
                        disabled={loading || totemClosedReceptionDay.date < todayDateFormatted}
                    >
                        Annuler
                    </TotemPrimaryButton>
                ),
                archiveButton: (
                    <TotemPrimaryButton
                        onClick={() => {
                            archive(totemClosedReceptionDay._id);
                        }}
                        disabled={loading || totemClosedReceptionDay.date > todayDateFormatted}
                    >
                        Archiver
                    </TotemPrimaryButton>
                ),
            };
        },
    );

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.totemClosedReceptionDays} />
                </HeaderTitle>
                <TotemPrimaryButton onClick={() => setIsCreatePopupOpen(true)}>
                    Créer un jour de fermeture pour totem
                </TotemPrimaryButton>
            </Header>
            <Content>
                <ListView<FormattedTotemClosedReceptionDayType>
                    fieldsToDisplay={SITES_FIELDS_TO_DISPLAY}
                    items={formattedTotemClosedReceptionDays}
                    keyExtractor={(item) => item._id}
                />
            </Content>
            <CreateTotemClosedReceptionDayPopup isOpen={isCreatePopupOpen} setIsOpen={setIsCreatePopupOpen} />
            {totemClosedReceptionDayToAbort ? (
                <AbortTotemClosedReceptionDayPopup
                    totemClosedReceptionDay={totemClosedReceptionDayToAbort}
                    isOpen={isAbortPopupOpen}
                    setIsOpen={setIsAbortPopupOpen}
                />
            ) : null}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Content = styled.div`
    padding: 15px;
    flex: 1;
    overflow: hidden;
`;

import React from 'react';
import styled from 'styled-components';

import { colors } from 'constants/colors';

import { ColumnPhotosSection } from './photos/ColumnPhotosSection';
import { SetupInstructionType } from 'data/__generated__';
import { isPhotosInstruction } from 'pages/SiteSetups/utils/typePredicates';
import { MicrostoreColumnGroupSetupFragment } from 'data/fragments/__generated__/MicrostoreColumnGroupSetupFragment';

export const ColumnGroupDetails = ({
    selectedColumnGroupSetup,
}: {
    selectedColumnGroupSetup: MicrostoreColumnGroupSetupFragment;
}) => {
    const photosInstruction = selectedColumnGroupSetup.instructionsAfterColumns.find(
        ({ type }) => type === SetupInstructionType.Photos,
    );

    return (
        <Container>
            {photosInstruction && isPhotosInstruction(photosInstruction) ? (
                <ColumnPhotosSection instruction={photosInstruction} />
            ) : null}
        </Container>
    );
};

const Container = styled.div`
    border: 1px solid ${colors.pureBlack};
    border-radius: 10px;
    padding: 10px;

    & > :not(:first-child) {
        margin-top: 20px;
        border-top: 1px solid ${colors.borderGrey};
    }
`;

import React, { useEffect, useState, Fragment, useContext } from 'react';

import { Link, useLocation, matchPath } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { IconChevronDown, IconChevronUp, IconChevronsLeft, IconChevronsRight } from '@tabler/icons';

import { NavSection, NAV_SECTION_LABELS, NAV_SECTION_ICONS, PAGES, PageType } from 'constants/pages';

import { NavBarPageTitle } from 'components/NavBarPageTitle';

import { TOTEM_LOGO_LIGHT } from 'constants/logos';
import { UserContext } from 'contexts';

function getInitialIsOpenWithLocalStorage() {
    try {
        const localStorageIsOpen = window.localStorage.getItem('is-nav-bar-open');
        return localStorageIsOpen ? JSON.parse(localStorageIsOpen) : true;
    } catch {
        return true;
    }
}

export const NavBar = () => {
    const location = useLocation();
    const user = useContext(UserContext);

    const [isOpen, setIsOpen] = useState<boolean>(getInitialIsOpenWithLocalStorage());
    const [openedNavBarSection, setOpenedNavBarSection] = useState<NavSection | string | null>(
        window.localStorage.getItem('preferedNavBarSection') || NavSection.WarehouseManagementSystem,
    );

    useEffect(() => {
        if (openedNavBarSection) {
            window.localStorage.setItem('preferedNavBarSection', openedNavBarSection);
        }
    }, [openedNavBarSection]);

    useEffect(() => {
        setIsOpen(getInitialIsOpenWithLocalStorage());
    }, [setIsOpen]);

    function updateIsOpen(isOpen: boolean) {
        window.localStorage.setItem('is-nav-bar-open', JSON.stringify(isOpen));
        setIsOpen(isOpen);
    }

    const activePage = Object.values(PAGES).find((page: PageType) => matchPath(location.pathname, page.url));

    const {
        profile: { firstname, imageUrl, lastname },
    } = user;

    return (
        <Container isOpen={isOpen}>
            <Content>
                <NavBarHeader>
                    {isOpen ? (
                        <IconChevronsLeft onClick={() => updateIsOpen(!isOpen)} />
                    ) : (
                        <IconChevronsRight onClick={() => updateIsOpen(!isOpen)} />
                    )}
                    <Link to="/">
                        <LogoContainer>
                            <img src={TOTEM_LOGO_LIGHT} alt="totem-logo" />
                        </LogoContainer>
                    </Link>
                </NavBarHeader>
                <LinksContainer>
                    {Object.values(NavSection).map((section, index) => {
                        return (
                            <Fragment key={index}>
                                <NavBarSectionTitleContainer
                                    isOpen={isOpen}
                                    onClick={() =>
                                        setOpenedNavBarSection(section === openedNavBarSection ? null : section)
                                    }
                                >
                                    {isOpen ? (
                                        <NavBarSectionTitle>{NAV_SECTION_LABELS[section]}</NavBarSectionTitle>
                                    ) : (
                                        NAV_SECTION_ICONS[section]
                                    )}
                                    {openedNavBarSection === section ? (
                                        <IconChevronUp size={20} />
                                    ) : (
                                        <IconChevronDown size={20} />
                                    )}
                                </NavBarSectionTitleContainer>
                                {openedNavBarSection === section &&
                                    Object.values(PAGES)
                                        .filter((page) => page.navSection === section)
                                        .sort((pageA, pageB) =>
                                            (pageA.shortLabel ?? pageA.label).localeCompare(
                                                pageB.shortLabel ?? pageB.label,
                                            ),
                                        )
                                        .map((page, index) => (
                                            <NavLink
                                                key={index}
                                                $isActive={activePage?.section === page.section}
                                                to={page.url}
                                                style={{
                                                    animationDuration: '300ms',
                                                    animationDelay: index * 10 + 'ms',
                                                }}
                                            >
                                                <NavBarPageTitle page={page} shouldDisplayShortLabel />
                                            </NavLink>
                                        ))}
                            </Fragment>
                        );
                    })}
                </LinksContainer>
                <Footer isOpen={isOpen}>
                    <SettingsLink data-test="link-to-settings" to="/settings">
                        <UserImageContainer>
                            {imageUrl ? (
                                <UserImage src={imageUrl} />
                            ) : (
                                <Initials>
                                    {firstname?.charAt(0)}
                                    {lastname?.charAt(0)}
                                </Initials>
                            )}
                        </UserImageContainer>
                        <ProfileContainer>
                            <Label>Mon profil</Label>
                        </ProfileContainer>
                    </SettingsLink>
                </Footer>
            </Content>
        </Container>
    );
};

const OPEN_NAV_BAR_WIDTH = '210px';
const CLOSED_NAV_BAR_WIDTH = '45px';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const NavBarSectionTitleContainer = styled.div<{ isOpen: boolean }>`
    display: flex;
    flex-direction: column;
    gap: 5px;
    cursor: pointer;
    margin: 10px 0 10px ${({ isOpen }) => (isOpen ? '24px' : '4px')};
    transition: all 0.6s ease-in-out;
`;

const NavBarSectionTitle = styled.h3`
    margin: 0;
`;

const Container = styled.div<{ isOpen: boolean }>`
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    background-color: ${({ theme }) => theme.menuBackgroundColor};
    color: ${({ theme }) => theme.menuTextColor};
    border-right: 1px solid ${({ theme }) => theme.darkBorderColor};
    width: ${({ isOpen }) => (isOpen ? OPEN_NAV_BAR_WIDTH : CLOSED_NAV_BAR_WIDTH)};
    transition: width 0.6s ease-in-out;
    overflow: hidden;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    width: ${OPEN_NAV_BAR_WIDTH};
    padding: 20px 10px;
    overflow: auto;
`;

const NavBarHeader = styled.div`
    font-size: 26px;
    color: ${({ theme }) => theme.ctaPrimaryColor};
    cursor: pointer;
    display: flex;
    align-items: center;
`;

const LinksContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
`;

const NavLink = styled(Link)<{ $isActive: boolean }>`
    font-size: 19px;
    font-weight: ${({ $isActive }) => ($isActive ? 800 : 500)};
    color: ${({ theme, $isActive }) => ($isActive ? theme.ctaPrimaryColor : theme.menuTextColor)};
    text-decoration: none;
    padding: 3px 4px;
    border-radius: ${({ theme }) => theme.borderRadius};
    @media (prefers-reduced-motion: no-preference) {
        animation-name: ${fadeIn};
        animation-fill-mode: backwards;
    }
    &:hover {
        background-color: ${({ theme }) => theme.overlayColor};
    }
`;

const Footer = styled.div<{ isOpen: boolean }>`
    display: flex;
    flex-direction: column;
    margin-top: auto;
    transform: translateX(-${({ isOpen }) => (isOpen ? '0px' : OPEN_NAV_BAR_WIDTH)});
    transition: transform 0.6s ease-in-out;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

const SettingsLink = styled(Link)`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.menuTextColor};
    text-decoration: none;
    padding: 5px;
    border-radius: ${({ theme }) => theme.borderRadius};

    &:hover {
        background-color: ${({ theme }) => theme.overlayColor};
    }
`;

const UserImageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    overflow: hidden;
    border: 2px solid ${({ theme }) => theme.lightBorderColor};
`;

const UserImage = styled.img`
    width: 50px;
    height: 50px;
    object-fit: contain;
`;

const LogoContainer = styled.div`
    margin-left: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    img {
        height: 20px;
        object-fit: contain;
    }
`;

const Initials = styled.span`
    font-size: 20px;
`;

const ProfileContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 10px;
`;

const Label = styled.span`
    font-size: 18px;
    font-weight: 800;
    color: ${({ theme }) => theme.ctaPrimaryColor};
`;

import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import styled from 'styled-components';

import { PAGES } from '../../constants/pages';

import { GET_SUPPLIER_PARTNERSHIPS_QUERY } from 'data/queries/supplierPartnership';
import { GET_SUPPLIER_PARTNERSHIPS } from 'data/queries/__generated__/GET_SUPPLIER_PARTNERSHIPS';

import { Header, HeaderTitle } from 'components/Header';
import { Loader } from 'components/Loader';
import { PageTitle } from 'components/PageTitle';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { CreateSupplierPartnershipPopup } from './Popups/CreateSupplierPartnershipPopup';
import { SupplierPartnershipDetails } from './SupplierPartnershipDetails';
import { GET_ALL_PRODUCTS_QUERY } from 'data/queries/product';
import { GET_ALL_PRODUCTS } from 'data/queries/__generated__/GET_ALL_PRODUCTS';

export const SupplierPartnerships = () => {
    const [isCreateSupplierPartnershipPopupOpen, setIsCreateSupplierPartnershipPopupOpen] = useState<boolean>(false);

    const {
        loading: supplierPartnershipsLoading,
        data: supplierPartnershipsData,
        error: supplierPartnershipsError,
    } = useQuery<GET_SUPPLIER_PARTNERSHIPS>(GET_SUPPLIER_PARTNERSHIPS_QUERY);

    const {
        data: productsData,
        loading: productsLoading,
        error: productsError,
    } = useQuery<GET_ALL_PRODUCTS>(GET_ALL_PRODUCTS_QUERY);

    if ((supplierPartnershipsLoading && !supplierPartnershipsData) || (productsLoading && !productsData)) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (supplierPartnershipsError || !supplierPartnershipsData || productsError || !productsData) {
        throw new Error('Une erreur est survenue lors de la récupération des trade marketings ou des produits');
    }

    const { supplierPartnerships } = supplierPartnershipsData;
    const { getAllProducts: products } = productsData;

    const sortedSupplierPartnerships = [...supplierPartnerships]
        .sort((supplierPartnershipA, supplierPartnershipB) =>
            supplierPartnershipB.endDate.localeCompare(supplierPartnershipA.endDate),
        )
        .sort((supplierPartnershipA, supplierPartnershipB) =>
            supplierPartnershipB.startDate.localeCompare(supplierPartnershipA.startDate),
        );

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.supplierPartnerships} />
                </HeaderTitle>
                <ActionContainer>
                    <TotemPrimaryButton
                        onClick={() => {
                            setIsCreateSupplierPartnershipPopupOpen(true);
                        }}
                    >
                        Ajouter un trade marketing
                    </TotemPrimaryButton>
                </ActionContainer>
            </Header>
            <Content>
                {sortedSupplierPartnerships.map((supplierPartnership) => (
                    <SupplierPartnershipDetails
                        key={supplierPartnership._id}
                        supplierPartnership={supplierPartnership}
                        products={products}
                    />
                ))}
            </Content>
            <CreateSupplierPartnershipPopup
                isOpen={isCreateSupplierPartnershipPopupOpen}
                setIsOpen={setIsCreateSupplierPartnershipPopupOpen}
            />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    flex: 1;
    overflow: auto;
`;

const ActionContainer = styled.div`
    display: flex;
    gap: 10px;
`;

import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import styled from 'styled-components';

import { CREATE_SUPPLIER_PARTNERSHIP_MUTATION } from 'data/mutations/supplierPartnership';
import {
    CREATE_SUPPLIER_PARTNERSHIP,
    CREATE_SUPPLIER_PARTNERSHIPVariables,
} from 'data/mutations/__generated__/CREATE_SUPPLIER_PARTNERSHIP';

import { Loader, LoaderModeType } from 'components/Loader';
import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

import { supplierPartnershipsUpdatedOrCreatedHandler } from '../cacheHandlers/supplierPartnershipsCacheHandler';
import { TotemInput } from 'components/TotemInput';
import { SupplierPartnershipCreateInput } from 'data/__generated__';
import { dateFromString, formatDateAsAnniversary } from 'helpers/dateTimes';
import { ColumnsSectionContainer, SectionColumn } from 'components/DetailsView/Section';
import { TotemDatePicker } from 'components/TotemDatePicker';

export function CreateSupplierPartnershipPopup({
    isOpen,
    setIsOpen,
}: {
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
}) {
    const [supplierPartnershipForm, setSupplierPartnershipForm] = useState<SupplierPartnershipCreateInput>({
        budget: 0,
        endDate: formatDateAsAnniversary({ dateTime: new Date(), useNewFormat: true }),
        name: '',
        startDate: formatDateAsAnniversary({ dateTime: new Date(), useNewFormat: true }),
    });

    const [createSupplierPartnership, { loading: creationLoading }] = useMutation<
        CREATE_SUPPLIER_PARTNERSHIP,
        CREATE_SUPPLIER_PARTNERSHIPVariables
    >(CREATE_SUPPLIER_PARTNERSHIP_MUTATION, { variables: { supplierPartnershipCreateInput: supplierPartnershipForm } });

    async function handleSubmit() {
        const { data } = await createSupplierPartnership();

        if (!data) {
            throw new Error('Une erreur est survenue lors creation du trade marketing');
        }

        const { createSupplierPartnership: newSupplierPartnership } = data;

        supplierPartnershipsUpdatedOrCreatedHandler([newSupplierPartnership]);

        setIsOpen(false);
    }

    return (
        <TotemPopup title="Ajouter un trade marketing" isOpen={isOpen} setIsOpen={setIsOpen} contentOverflow="visible">
            <TotemInput
                label="Nom"
                value={supplierPartnershipForm.name}
                onChange={(value) => setSupplierPartnershipForm({ ...supplierPartnershipForm, name: value })}
            />
            <TotemInput
                label="Budget"
                type="number"
                min="0"
                step="1"
                value={supplierPartnershipForm.budget}
                onChange={(value) =>
                    setSupplierPartnershipForm({ ...supplierPartnershipForm, budget: parseInt(value) })
                }
            />

            <ColumnsSectionContainer numberOfColumns={2}>
                <SectionColumn>
                    <TotemDatePicker
                        label="Date de début"
                        selected={dateFromString(supplierPartnershipForm.startDate, true)}
                        onChange={(date) => {
                            if (!date) {
                                return;
                            }
                            setSupplierPartnershipForm({
                                ...supplierPartnershipForm,
                                startDate: formatDateAsAnniversary({ dateTime: date, useNewFormat: true }),
                            });
                        }}
                    />
                </SectionColumn>
                <SectionColumn>
                    <TotemDatePicker
                        label="Date de fin"
                        selected={dateFromString(supplierPartnershipForm.endDate, true)}
                        onChange={(date) => {
                            if (!date) {
                                return;
                            }
                            setSupplierPartnershipForm({
                                ...supplierPartnershipForm,
                                endDate: formatDateAsAnniversary({ dateTime: date, useNewFormat: true }),
                            });
                        }}
                    />
                </SectionColumn>
            </ColumnsSectionContainer>

            <SubmitContainer>
                <ValidationButton onClick={handleSubmit}>
                    {creationLoading ? <Loader size="20px" mode={LoaderModeType.Spin} /> : 'Confirmer'}
                </ValidationButton>
            </SubmitContainer>
        </TotemPopup>
    );
}

const SubmitContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 15px;
`;

const ValidationButton = styled(TotemPrimaryButton)`
    margin-top: 15px;
`;

import React from 'react';
import styled from 'styled-components';

import { TotemPopup } from 'components/TotemPopup';
import { colors } from 'constants/colors';

export const TrelloCardInfoPopup = ({ isOpen, setIsOpen }: { isOpen: boolean; setIsOpen: (arg: boolean) => void }) => {
    return (
        <TotemPopup title="Explications des visuels des cartes achat" isOpen={isOpen} setIsOpen={setIsOpen}>
            <ExplanationContainer>
                • <b>Couleur de la carte :</b> Dans la section 'Créés' :{' '}
                <ColorSpan color={colors.violet}>en violet</ColorSpan> : cartes achat fresh créées automatiquement par
                l'algo data, <ColorSpan color={colors.emeraldBlue}>en bleu canard</ColorSpan> : carte achat fresh créée
                automatiquement fusionnée avec carte achat dry, sinon cartes grises. Autres sections/ autres couleurs
                n'ont pas de sens particulier
                <br />• <b>Couleur de bord de la carte :</b> Carte 'Créée' ou 'Prête' générée automatiquement :{' '}
                <ColorSpan color={colors.pink}>en rose</ColorSpan> - a des produits en risque de soldout, carte
                'Commandée' ou 'Confirmée' : <ColorSpan color={colors.red}>en rouge</ColorSpan> - date de livrason
                prévue dans le passé, <ColorSpan color={colors.orange}>en orange</ColorSpan> - date de livraison prévue
                aujourd'hui,
                <br />• <b>Couleur de la livraison prévue :</b> <ColorSpan color={colors.green}>date valide</ColorSpan>{' '}
                (peut toujours être reçue si commandée à la date de 'Commandée'),{' '}
                <ColorSpan color={colors.orange}>date non valide</ColorSpan>
                <br />• <b>Couleur de la franco :</b> <ColorSpan color={colors.green}>franco valide</ColorSpan>,{' '}
                <ColorSpan color={colors.orange}>franco pas valide</ColorSpan>
                <br />• ❗️ : Produits réceptionnés ont au moins 1 problème
            </ExplanationContainer>
        </TotemPopup>
    );
};

const ExplanationContainer = styled.div`
    font-style: normal;
    display: inline-block;
    max-width: 800px;
`;
const ColorSpan = styled.span`
    color: ${({ color }) => color};
    margin: 0 2px;
`;

import React from 'react';
import styled from 'styled-components';

import { PAGES } from '../../constants/pages';

import { PageTitle } from 'components/PageTitle';
import { Header, HeaderTitle } from 'components/Header';
import { QuizzesList } from './QuizzesList';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { Link } from 'react-router-dom';

export const Quizzes = () => {
    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.quizzes} />
                </HeaderTitle>
                <ButtonsContainer>
                    <Link to="/quiz/create">
                        <TotemPrimaryButton>Créer un quiz</TotemPrimaryButton>
                    </Link>
                </ButtonsContainer>
            </Header>
            <Content>
                <QuizzesList />
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
`;

const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    ${TotemPrimaryButton} {
        margin-left: 5px;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    flex: 1;
    overflow: hidden;
`;

import gql from 'graphql-tag';

export const CATEGORY_FRAGMENT = gql`
    fragment CategoryFragment on Category {
        _id
        createdAt
        updatedAt
        indexDisplay
        isFreefood
        isMicrostore
        isPunctual
        imageUrl
        shouldShowSubcategories
        state
        stateHistory {
            state
            createdAt
            createdBy
            createdByName
        }
        title
    }
`;

import gql from 'graphql-tag';
import { WAREHOUSE_RETURN_FRAGMENT } from 'data/fragments/warehouseReturn';

export const GET_WAREHOUSE_RETURN_FOR_DATE_QUERY = gql`
    query GET_WAREHOUSE_RETURN_FOR_DATE($date: String) {
        getWarehouseReturnsForDate(date: $date) {
            ...WarehouseReturnFragment
        }
    }
    ${WAREHOUSE_RETURN_FRAGMENT}
`;

export const GET_WAREHOUSE_RETURN_QUERY = gql`
    query GET_WAREHOUSE_RETURN($warehouseReturnId: ID!) {
        getWarehouseReturn(warehouseReturnId: $warehouseReturnId) {
            ...WarehouseReturnFragment
        }
    }
    ${WAREHOUSE_RETURN_FRAGMENT}
`;

import gql from 'graphql-tag';

import { CATALOG_PRODUCT_FRAGMENT, PRODUCT_FRAGMENT } from '../fragments/product';

export const UPDATED_PRODUCT_SUBSCRIPTION = gql`
    subscription UPDATED_PRODUCT($productId: ID!) {
        updatedProduct(productId: $productId) {
            ...ProductFragment
        }
    }
    ${PRODUCT_FRAGMENT}
`;

export const UPDATED_PRODUCTS_SUBSCRIPTION = gql`
    subscription UPDATED_PRODUCTS {
        updatedProducts {
            ...CatalogProductFragment
        }
    }
    ${CATALOG_PRODUCT_FRAGMENT}
`;

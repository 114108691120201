import React from 'react';

import { GET_DELIVERY_delivery } from 'data/queries/__generated__/GET_DELIVERY';

import styled from 'styled-components';
import { SetupInstructionFragment_PhotosInstruction } from 'data/fragments/__generated__/SetupInstructionFragment';
import { isPhotosInstruction } from 'pages/SiteSetups/utils/typePredicates';
import { TotemGallery } from 'components/TotemGallery';
import { DeliveryFragmentFragment, SiteSetupFragmentFragment } from 'data/__generated__';

export const PhotosSectionContent = ({
    delivery,
}: {
    delivery:
        | GET_DELIVERY_delivery
        | (DeliveryFragmentFragment & {
              photos: Array<{ __typename?: 'DeliveryPhoto'; type: string; urls: string[] }>;
              siteSetup?: SiteSetupFragmentFragment | null;
          });
}) => {
    const { photos: urbantzPhotos } = delivery;

    // TODO: Change this as soon as we implement the urbantz hook on totadmin
    function getTranslatedType(photoKey: string) {
        switch (photoKey) {
            case 'takeGlobal':
                return 'Totem Photo Globale';
            case 'fridge':
                return 'Boissons Fraîches';
            case 'snacks':
                return 'Snacks en Vrac';
            case 'individualSnacks':
                return 'Snacks Individuels';
            case 'fruits':
                return 'Fruits BIO';
            case 'coffee':
                return 'Café & Thé';
            case 'fresh':
                return 'Desserts & Co';
            case 'meal':
                return 'Plats';
            case 'starter':
                return 'Entrées';
            case 'bakery':
                return 'Boulangerie';
            case 'sample':
                return 'Echantillons';
            case 'b2c':
                return 'Livraison B2C';
            case 'frigoRestaurationInventaireSorties':
                return 'Frigo Restauration Inventaire Sorties';
            case 'frigoRestaurationRemplissage':
                return 'Frigo Restauration Remplissage';
            case 'frigoRestaurationRangement':
                return 'Frigo Restauration Rangement';
            case 'frigoBoissonsSorties':
                return 'Frigo Boissons Sorties';
            case 'frigoBoissons':
                return 'Frigo Boissons';
            case 'frigoBoissonsInventaire':
                return 'Frigo Boissons Inventaire';
            case 'colonneSnackSorties':
                return 'Colonne Snack Sorties';
            case 'colonneSnack':
                return 'Colonne Snack';
            case 'colonneSnackInventaire':
                return 'Colonne Snack Inventaire';
            case 'colonneNonAlimentaireSorties':
                return 'Colonne Non Alimentaire Sorties';
            case 'colonneNonAlimentaire':
                return 'Colonne Non Alimentaire';
            case 'colonneNonAlimentaireInventaire':
                return 'Colonne Non Alimentaire Inventaire';
            case 'colonneVracSorties':
                return 'Colonne Vrac Sorties';
            case 'colonneVrac':
                return 'Colonne Vrac';
            case 'colonneBoissonsChaudes':
                return 'Colonne Boissons Chaudes';
            case 'needsFillingMachineNECTA':
                return 'Remplissage Machine à café';
            case 'needsInventoryStockCafe':
                return 'Inventaire Stock Machine à café';
            case 'needsCleaningMachineNECTA':
                return 'Nettoyage Machine à café';
            case 'colonneMicroOndes':
                return 'Colonne Micro-Ondes';
            default:
                return 'Inconnu';
        }
    }

    // todo, use the same component as in site setup page once urbantz photo are removed.
    const storePhotosInstructions =
        (delivery.siteSetup?.microstoreColumnGroupSetups
            ?.flatMap(({ instructionsAfterColumns, instructionsBeforeColumns, microstoreColumnSetups }) => [
                ...instructionsBeforeColumns,
                ...microstoreColumnSetups.flatMap(({ instructions }) => instructions),
                ...instructionsAfterColumns,
            ])
            ?.filter((instruction) =>
                isPhotosInstruction(instruction),
            ) as SetupInstructionFragment_PhotosInstruction[]) ?? [];

    const freefoodPhotosInstructions =
        // @ts-ignore
        (delivery.siteSetup?.freefoodSetup?.instructions?.filter((instruction) =>
            isPhotosInstruction(instruction),
        ) as SetupInstructionFragment_PhotosInstruction[]) ?? [];

    const storePhotos = storePhotosInstructions.flatMap(({ photos }) =>
        photos.flatMap(({ type, urls }) => urls.map((url, index) => ({ label: `${type} ${index}`, url }))),
    );
    const freefoodPhotos = freefoodPhotosInstructions.flatMap(({ photos }) =>
        photos.flatMap(({ type, urls }) => urls.map((url, index) => ({ label: `${type} ${index}`, url }))),
    );

    urbantzPhotos.forEach(({ type, urls }) => {
        const translatedCategory = getTranslatedType(type);
        urls.forEach((url, index) => freefoodPhotos.push({ label: `${translatedCategory} ${index}`, url }));
    });

    return (
        <>
            <Title>Photos Freefood</Title>
            <TotemGallery images={freefoodPhotos} />
            <Title>Photos Store</Title>
            <TotemGallery images={storePhotos} />
        </>
    );
};

const Title = styled.div`
    margin-top: 15px;
    font-weight: bold;
    font-size: 18px;
`;

import React, { useState } from 'react';

import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';

import { FcInspection, FcSynchronize } from 'react-icons/fc';
import { FaArrowRight } from 'react-icons/fa';

import { SWAP_PRODUCT_LOCATIONS_MUTATION, UPDATE_LOCATIONS_STATE_MUTATION } from 'data/mutations/location';
import {
    UPDATE_LOCATIONS_STATE,
    UPDATE_LOCATIONS_STATEVariables,
} from 'data/mutations/__generated__/UPDATE_LOCATIONS_STATE';
import {
    SWAP_PRODUCT_LOCATIONS,
    SWAP_PRODUCT_LOCATIONSVariables,
} from 'data/mutations/__generated__/SWAP_PRODUCT_LOCATIONS';
import { LocationState } from 'data/__generated__';

import { ActionBar } from 'components/ActionBar';
import { colors } from 'constants/colors';
import { Loader, LoaderModeType } from 'components/Loader';

import { handleUpdateActionEndToast } from 'pages/SupplyOrders/SupplyOrderDetails/utils/handleUpdateActionEndToast';

export const LocationActions = ({
    selectedLocationIds,
    setSelectedLocationIds,
}: {
    selectedLocationIds: string[];
    setSelectedLocationIds: (locationIds: string[]) => void;
}) => {
    const [stateMutationLoading, setStateMutationLoading] = useState<LocationState | null>(null);
    const [updateLocationsState] = useMutation<UPDATE_LOCATIONS_STATE, UPDATE_LOCATIONS_STATEVariables>(
        UPDATE_LOCATIONS_STATE_MUTATION,
    );
    const [swapProductLocations, { loading: swapLoading }] = useMutation<
        SWAP_PRODUCT_LOCATIONS,
        SWAP_PRODUCT_LOCATIONSVariables
    >(SWAP_PRODUCT_LOCATIONS_MUTATION);

    const handleStateUpdate = async (stateToSet: LocationState) => {
        setStateMutationLoading(stateToSet);
        const { data } = await updateLocationsState({
            variables: {
                locationIds: selectedLocationIds,
                stateToSet,
            },
        });

        setSelectedLocationIds([]);
        if (data) {
            const {
                updateLocationsState: { error, warnings },
            } = data;

            setStateMutationLoading(null);
            handleUpdateActionEndToast({
                errors: error ? [error] : [],
                warnings: warnings || [],
                successText: 'Les statuts ont été mis à jour !',
                displaySuccess: !error && warnings?.length !== selectedLocationIds.length,
            });
        } else {
            toast.error('Une erreur inconnue est survenue');
        }
    };

    const handleProductSwap = async () => {
        if (selectedLocationIds.length !== 2) {
            toast.info('Veuillez sélectionner exactement 2 emplacements');
            return;
        }

        const { data } = await swapProductLocations({
            variables: { locationIds: selectedLocationIds },
        });

        setSelectedLocationIds([]);
        if (data) {
            const {
                swapProductLocations: { warnings, error },
            } = data;

            handleUpdateActionEndToast({
                errors: error ? [error] : [],
                warnings: warnings || [],
                successText:
                    "Les emplacements des produits ont été échangés ! N'oubliez pas d'échanger le stock physique",
                displaySuccess: !(error || warnings?.length),
            });
        } else {
            toast.error('Une erreur inconnue est survenue');
        }
    };

    const actionSections = [
        {
            actions: [
                {
                    icon: <FcInspection size={22} />,
                    label: 'Tout désélectionner',
                    onClick: () => {
                        setSelectedLocationIds([]);
                    },
                    hidden: !selectedLocationIds.length,
                },
                {
                    icon:
                        stateMutationLoading === LocationState.Regular ? (
                            <Loader mode={LoaderModeType.Spin} size="22px" />
                        ) : (
                            <FaArrowRight size={20} color={colors.green} />
                        ),
                    label: 'Passer en Regular',
                    onClick: () => handleStateUpdate(LocationState.Regular),
                    hidden: stateMutationLoading === LocationState.Hidden || !selectedLocationIds.length,
                },
                {
                    icon:
                        stateMutationLoading === LocationState.Hidden ? (
                            <Loader mode={LoaderModeType.Spin} size="22px" />
                        ) : (
                            <FaArrowRight size={20} color={colors.pantinGrey} />
                        ),
                    label: 'Passer en Caché',
                    onClick: () => handleStateUpdate(LocationState.Hidden),
                    hidden: stateMutationLoading === LocationState.Regular || !selectedLocationIds.length,
                },
                {
                    icon: swapLoading ? <Loader mode={LoaderModeType.Spin} size="22px" /> : <FcSynchronize size={22} />,
                    label: 'Échanger produits',
                    onClick: handleProductSwap,
                    hidden: !selectedLocationIds.length,
                },
            ],
        },
    ];

    return <ActionBar actionSections={actionSections} />;
};

import React from 'react';
import { useMutation } from '@apollo/client';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { CategoryFragmentFragment, CategoryState } from 'data/__generated__';
import {
    UPDATE_CATEGORY_STATE,
    UPDATE_CATEGORY_STATEVariables,
} from 'data/mutations/__generated__/UPDATE_CATEGORY_STATE';

import { UPDATE_CATEGORY_STATE_MUTATION } from 'data/mutations/category';

import { SectionColumn, SectionContainer, ColumnsSectionContainer } from 'components/DetailsView/Section';
import { StateHistory } from 'components/DetailsView/StateHistory';
import { StatusTag } from '../components/StatusTag';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { Loader, LoaderModeType } from 'components/Loader';

export const StatesSection = ({ category, isDirty }: { category: CategoryFragmentFragment; isDirty: boolean }) => {
    const { _id: categoryId, state, stateHistory } = category;
    const [updateCategoryState, { loading }] = useMutation<UPDATE_CATEGORY_STATE, UPDATE_CATEGORY_STATEVariables>(
        UPDATE_CATEGORY_STATE_MUTATION,
    );

    const { reset, getValues } = useFormContext();

    const formattedStateHistory = stateHistory.map((stateRecord) => ({
        ...stateRecord,
        state: <StatusTag state={stateRecord.state} />,
    }));

    const STATE_CHANGE_ACTIONS = [
        {
            label: 'Passer catégorie en statut régulier',
            stateToSet: CategoryState.Regular,
            isDisabled: state === CategoryState.Regular,
        },
        {
            label: 'Archiver la catégorie',
            stateToSet: CategoryState.Archived,
            isDisabled: state === CategoryState.Archived,
        },
    ];

    const handleStateUpdate = async (stateToSet: CategoryState) => {
        const { data } = await updateCategoryState({
            variables: {
                stateToSet,
                categoryId,
            },
        });
        if (data) {
            const {
                updateCategoryState: { success, error },
            } = data;

            if (success) {
                reset({
                    ...getValues(),
                    state: stateToSet,
                });
                toast.success('Le statut de la catégorie a bien été modifié !');
            } else if (error) {
                toast.error(error, { autoClose: false });
            } else {
                throw Error("Une erreur inconnue s'est produite");
            }
        } else {
            throw Error("Une erreur inconnue s'est produite");
        }
    };

    return (
        <SectionContainer title="Gestion des statuts" isInitiallyOpen>
            <ColumnsSectionContainer numberOfColumns={3}>
                <SectionColumn>
                    <StateHistory records={formattedStateHistory} />
                </SectionColumn>
                {loading ? (
                    <LoaderContainer>
                        <Loader mode={LoaderModeType.Spin} />
                    </LoaderContainer>
                ) : (
                    <SectionColumn>
                        {isDirty ? (
                            <WarningText>
                                ⚠️ Veuillez vous assurer d'avoir sauvegardé ou annulé vos ajustements avant de mettre à
                                jour le statut de la catégorie
                            </WarningText>
                        ) : null}
                        <OverlaySectionColumn isDirty={isDirty}>
                            {STATE_CHANGE_ACTIONS.map((stateChange, index) => {
                                const { label, isDisabled, stateToSet } = stateChange;

                                function updateState(e: React.MouseEvent<HTMLElement, MouseEvent>) {
                                    e.preventDefault();
                                    const hasConfirmed = window.confirm(
                                        `Êtes-vous sûr.e de vouloir ${label.toLowerCase()} ?`,
                                    );

                                    if (hasConfirmed) {
                                        handleStateUpdate(stateToSet);
                                    }
                                }

                                return (
                                    <TotemPrimaryButton
                                        key={index}
                                        onClick={updateState}
                                        disabled={isDisabled || isDirty}
                                    >
                                        {label}
                                    </TotemPrimaryButton>
                                );
                            })}
                        </OverlaySectionColumn>
                    </SectionColumn>
                )}
            </ColumnsSectionContainer>
        </SectionContainer>
    );
};

const WarningText = styled.div`
    color: ${({ theme }) => theme.textColor};
    font-weight: 800;
`;

const OverlaySectionColumn = styled(SectionColumn)`
    opacity: ${({ isDirty }: { isDirty: boolean }) => (isDirty ? 0.4 : 1)};
`;

const LoaderContainer = styled.div`
    flex: 1;
    justify-content: center;
`;

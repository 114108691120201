import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';

import { toast } from 'react-toastify';
import styled from 'styled-components';
import { FaSave, FaUndoAlt } from 'react-icons/fa';

import { PAGES } from 'constants/pages';
import { PRODUCT_ARRANGEMENT_REASSORTMENT_TYPE_LABELS } from './constants/types';

import { ProductArrangementReassortmentType } from 'data/__generated__';
import { ProductArrangementWithProductsFragment } from 'data/fragments/__generated__/ProductArrangementWithProductsFragment';
import {
    UPDATE_PRODUCT_ARRANGEMENT,
    UPDATE_PRODUCT_ARRANGEMENTVariables,
} from 'data/mutations/__generated__/UPDATE_PRODUCT_ARRANGEMENT';
import { UPDATE_PRODUCT_ARRANGEMENT_MUTATION } from 'data/mutations/productArrangement';

import { FullPageLoader } from 'components/FullPageLoader';
import { Header, HeaderTitle } from 'components/Header';
import { PageTitle } from 'components/PageTitle';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { TotemInput } from 'components/TotemInput';
import { TotemSelect } from 'components/TotemSelect';
import { ProductArrangementProductSelector } from './ProductArrangementProductSelector';
import { ProductsArrangementProductsTable } from './ProductsArrangementProductsTable';

import {
    mapProductArrangementFormValuesToProductArrangementUpdateInput,
    mapProductArrangementToProductArrangementFormValues,
    ProductArrangementFormValues,
} from './FormHelper/DataMapper';
import { GET_ALL_PRODUCTS_getAllProducts } from 'data/queries/__generated__/GET_ALL_PRODUCTS';

export const ProductArrangementDetailsForm = ({
    productArrangement,
    products,
}: {
    productArrangement: ProductArrangementWithProductsFragment;
    products: GET_ALL_PRODUCTS_getAllProducts[];
}) => {
    const [productArrangementFormValues, setProductArrangementFormValues] = useState<ProductArrangementFormValues>(
        mapProductArrangementToProductArrangementFormValues({ productArrangement }),
    );
    const [isFormDirty, setIsFormDirty] = useState<boolean>(false);

    const [updateProductArrangement, { loading: updateLoading }] = useMutation<
        UPDATE_PRODUCT_ARRANGEMENT,
        UPDATE_PRODUCT_ARRANGEMENTVariables
    >(UPDATE_PRODUCT_ARRANGEMENT_MUTATION);

    function setProductArrangementFormValuesAndIsFormDirty({
        newProductArrangementFormValues,
        newIsDirty = true,
    }: {
        newProductArrangementFormValues: ProductArrangementFormValues;
        newIsDirty?: boolean;
    }) {
        setProductArrangementFormValues(newProductArrangementFormValues);
        setIsFormDirty(newIsDirty);
    }

    function resetFormValues() {
        setProductArrangementFormValuesAndIsFormDirty({
            newProductArrangementFormValues: mapProductArrangementToProductArrangementFormValues({
                productArrangement,
            }),
            newIsDirty: false,
        });
    }

    async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        const productArrangementUpdateInput = mapProductArrangementFormValuesToProductArrangementUpdateInput({
            productArrangementFormValues,
        });
        const { data } = await updateProductArrangement({
            variables: { productArrangement: productArrangementUpdateInput },
        });

        if (!data) {
            throw new Error('Une erreur est survenue lors de la modification de la gamme de produit');
        }

        const {
            updateProductArrangementMutation: { newProductArrangement, errors },
        } = data;

        if (errors.length) {
            errors.forEach((error, index) => {
                toast.error(<span key={index}>Erreur : {error}</span>, { autoClose: false });
            });

            if (errors.length > 1) {
                toast.info('Cliquez pour fermer toutes les notifications', {
                    autoClose: false,
                    onClick: () => toast.dismiss(),
                });
            }
        }

        if (newProductArrangement) {
            toast.success(`La gamme de produit ${newProductArrangement.name} a bien été modifié !`);
            setProductArrangementFormValuesAndIsFormDirty({
                newProductArrangementFormValues: mapProductArrangementToProductArrangementFormValues({
                    productArrangement: newProductArrangement,
                }),
                newIsDirty: false,
            });
        }
    }

    const reassortmentTypeOptions = Object.keys(ProductArrangementReassortmentType)
        .map((type) => ({
            value: type as ProductArrangementReassortmentType,
            label: PRODUCT_ARRANGEMENT_REASSORTMENT_TYPE_LABELS[type as ProductArrangementReassortmentType],
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    return (
        <>
            <Form onSubmit={onSubmit}>
                <Header>
                    <HeaderTitle>
                        <PageTitle page={PAGES.productArrangementDetails} />
                    </HeaderTitle>
                    <ButtonsContainer>
                        <TotemPrimaryButton
                            isSecondaryStyle
                            type="button"
                            onClick={resetFormValues}
                            disabled={!isFormDirty}
                        >
                            <FaUndoAlt size={13} />
                            <ButtonLabel>Réinitialiser</ButtonLabel>
                        </TotemPrimaryButton>
                        <TotemPrimaryButton
                            type="submit"
                            disabled={
                                !isFormDirty ||
                                productArrangementFormValues.productsWithStockMax.some(
                                    ({ stockMaxUnsecured, stockMaxSensei }) =>
                                        !Number.isInteger(stockMaxUnsecured) || !Number.isInteger(stockMaxSensei),
                                )
                            }
                        >
                            <FaSave size="13" />
                            <ButtonLabel>Mettre à jour</ButtonLabel>
                        </TotemPrimaryButton>
                        <Link to="/productArrangements">
                            <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                        </Link>
                    </ButtonsContainer>
                </Header>
                <Content>
                    <TotemInput
                        label="Nom de la gamme de produit"
                        placeholder="Dessert Bergam laitier"
                        onChange={(newName) =>
                            setProductArrangementFormValuesAndIsFormDirty({
                                newProductArrangementFormValues: { ...productArrangementFormValues, name: newName },
                            })
                        }
                        value={productArrangementFormValues.name}
                    />
                    <TotemSelect
                        placeholder="Sélectionner un type de réassort"
                        value={reassortmentTypeOptions.find(
                            (reassortmentTypeOption) =>
                                reassortmentTypeOption.value === productArrangementFormValues.reassortmentType,
                        )}
                        options={reassortmentTypeOptions}
                        onChange={(option) => {
                            if (option) {
                                setProductArrangementFormValuesAndIsFormDirty({
                                    newProductArrangementFormValues: {
                                        ...productArrangementFormValues,
                                        reassortmentType: option.value,
                                    },
                                });
                            }
                        }}
                    />
                    <ProductArrangementProductSelector
                        productArrangementFormValues={productArrangementFormValues}
                        setProductArrangementFormValuesAndIsFormDirty={setProductArrangementFormValuesAndIsFormDirty}
                        products={products}
                    />
                    <ProductsArrangementProductsTable
                        productArrangementFormValues={productArrangementFormValues}
                        setProductArrangementFormValuesAndIsFormDirty={setProductArrangementFormValuesAndIsFormDirty}
                        products={products}
                    />
                </Content>
            </Form>

            {updateLoading ? <FullPageLoader /> : null}
        </>
    );
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
`;

const ButtonsContainer = styled.div`
    display: flex;
    width: max-content;
    align-items: center;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const ButtonLabel = styled.span`
    margin-left: 5px;
`;

const Content = styled.div`
    width: 100%;
    flex-grow: 1;
    padding: 15px;
    overflow: scroll;

    & > :not(:first-child) {
        margin-top: 15px;
    }
`;

import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { PAGES } from 'constants/pages';

import { CsvImport } from './CsvImport';

import { Header, HeaderTitle } from 'components/Header';
import { PageTitle } from 'components/PageTitle';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

export const ProductCsvImport = () => {
    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.productImport} />
                </HeaderTitle>
                <Link to="/products">
                    <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                </Link>
            </Header>
            <CsvImport />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
`;

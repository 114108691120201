import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import type { FileWithPath } from 'react-dropzone';

import { PAGES } from '../../constants/pages';

import { CREATE_TERMS_RECORD, CREATE_TERMS_RECORDVariables } from 'data/mutations/__generated__/CREATE_TERMS_RECORD';
import { CREATE_TERMS_RECORD_MUTATION } from 'data/mutations/termsRecord';

import { FileDragAndDrop } from 'components/FileDragAndDrop';
import { Footer } from 'components/Footer';
import { Header, HeaderTitle } from 'components/Header';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { PageTitle } from 'components/PageTitle';

export const TermsRecordCreate = () => {
    const navigate = useNavigate();
    const [termsRecordPDF, setTermsRecordPDF] = useState<FileWithPath | null>(null);
    const [termsRecord, { loading }] = useMutation<CREATE_TERMS_RECORD, CREATE_TERMS_RECORDVariables>(
        CREATE_TERMS_RECORD_MUTATION,
    );

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const result = await termsRecord({
            variables: {
                termsOfUse: termsRecordPDF,
            },
        });
        const { data } = result;
        if (data) {
            const {
                createTermsRecord: { success, error },
            } = data;
            if (success) {
                toast.success('Les CGV ont bien été créées !');
                navigate(PAGES.termsRecords.url);
            } else {
                if (error) {
                    toast.error(`L'erreur suivante est survenue : ${error}`);
                } else {
                    throw Error("Une erreur inconnue s'est produite");
                }
            }
        } else {
            throw Error("Une erreur inconnue s'est produite");
        }
    };

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.termsRecordCreate} />
                </HeaderTitle>
                <Link to={PAGES.termsRecords.url}>
                    <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                </Link>
            </Header>
            <Content>
                <Form onSubmit={handleSubmit}>
                    <ContentScrollable>
                        <FileDragAndDrop
                            multiple={false}
                            fileTypes={['application/pdf']}
                            onFileDropCallback={(files) => setTermsRecordPDF(files ? files[0] : null)}
                        />
                        <p>
                            Notes :{' '}
                            <ul>
                                <li>
                                    Le fichier sera automatiquement renommé sous la forme{' '}
                                    <code>CGV-TOTEM-YYYY-MM-DD.pdf</code>.
                                </li>
                                <li>
                                    Si vous créez 2 versions des CGV le même jour, la dernière version écrasera la
                                    précédente.
                                </li>
                            </ul>
                        </p>
                    </ContentScrollable>
                    <Footer>
                        <TotemPrimaryButton data-test="terms-record-create-terms" disabled={!termsRecordPDF || loading}>
                            Créer les CGV
                        </TotemPrimaryButton>
                    </Footer>
                </Form>
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Content = styled.div`
    flex: 1;
    overflow: hidden;
`;

const Form = styled.form`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ContentScrollable = styled.div`
    padding: 15px;
    width: 100%;
    flex: 1;
    overflow-y: auto;
    color: ${({ theme }) => theme.textColor};
`;

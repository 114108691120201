import React, { FunctionComponent, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { TotemLabel } from 'components/TotemLabel';
import { ImagePicker } from './form-components/ImagePicker';

export type UpsertMobilePopupFormValues = {
    title: string;
    body: string;
    siteIds: string;
    startsAt: string;
    endsAt: string;
    image?: File | null;
};

type UpsertMobilePopupFormProps = {
    imageUrl: string | null;
    onSubmit: (values: UpsertMobilePopupFormValues) => void;
    defaultValues?: UpsertMobilePopupFormValues;
};

export const UpsertMobilePopupForm: FunctionComponent<UpsertMobilePopupFormProps> = ({
    onSubmit,
    defaultValues,
    imageUrl,
}) => {
    const { register, handleSubmit, formState, reset, control } = useForm<UpsertMobilePopupFormValues>({
        defaultValues,
    });

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues, reset]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column' }}>
            <InputContainer>
                <TotemLabel>Titre</TotemLabel>
                <Input type="text" placeholder="Enter name" {...register('title', { required: false })} />
            </InputContainer>
            <InputContainer>
                <TotemLabel>Contenu</TotemLabel>
                <Input type="text" placeholder="Enter name" {...register('body', { required: false })} />
            </InputContainer>
            <InputContainer>
                <TotemLabel>Id de sites</TotemLabel>
                <MultilineInput placeholder="Un par ligne" {...register('siteIds', { required: false })} />
            </InputContainer>
            <InputContainer>
                <TotemLabel>Période</TotemLabel>
                <DateInputContainer>
                    <Input
                        type="date"
                        style={{ flex: 1 }}
                        {...register('startsAt', { required: true, min: 0, max: 100 })}
                    />
                    <Input
                        type="date"
                        style={{ flex: 1 }}
                        {...register('endsAt', { required: true, min: 0, max: 100 })}
                    />
                </DateInputContainer>
            </InputContainer>
            <ImagePicker control={control} defaultValue={imageUrl} errors={formState.errors} name="image" />
            <TotemPrimaryButton type="submit" disabled={!formState.isValid || formState.isSubmitting}>
                OK
            </TotemPrimaryButton>
        </Form>
    );
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const Input = styled.input`
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    outline: none;
    &:focus {
        border-color: #333;
    }
`;

const DateInputContainer = styled.div`
    display: flex;
    gap: 4px;
`;

const MultilineInput = styled.textarea`
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    outline: none;
    resize: vertical;
    &:focus {
        border-color: #333;
    }
`;

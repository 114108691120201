import React from 'react';

import { CategoryState } from 'data/__generated__';
import { CATEGORY_STATES_LABELS, getColorsFromState } from 'pages/Categories/components/constants/states';

import { CommonStatusTag } from 'components/CommonStatusTag';

export const StatusTag = ({
    state,
    showHeading,
    sublabel,
}: {
    state: CategoryState;
    showHeading?: boolean;
    sublabel?: string;
}) => {
    const { backgroundColor, labelColor } = getColorsFromState(state);
    return (
        <CommonStatusTag
            backgroundColor={backgroundColor}
            labelColor={labelColor}
            label={CATEGORY_STATES_LABELS[state]}
            showHeading={showHeading}
            sublabel={sublabel}
        />
    );
};

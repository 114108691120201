import React from 'react';
import styled from 'styled-components';

import { WarehouseDeliveryFragmentFragment } from 'data/__generated__';

import { DetailLink } from 'components/DetailsView';
import { ColumnsSectionContainer, SectionContainer } from 'components/DetailsView/Section';
import { TotemInput } from 'components/TotemInput';

export const ProductsSection = ({ warehouseDelivery }: { warehouseDelivery: WarehouseDeliveryFragmentFragment }) => {
    const { products } = warehouseDelivery;

    return (
        <SectionContainer title="Produits transférés" isInitiallyOpen>
            <ColumnsSectionContainer numberOfColumns={1}>
                <ProductsTable>
                    <thead>
                        <tr>
                            <ProductsTableHeaderCell>Produit</ProductsTableHeaderCell>
                            <ProductsTableHeaderCell isDataCentered>Colis prévus</ProductsTableHeaderCell>
                            <ProductsTableHeaderCell isDataCentered>Quantité prévue</ProductsTableHeaderCell>
                            <ProductsTableHeaderCell isDataCentered>Colis préparés</ProductsTableHeaderCell>
                            <ProductsTableHeaderCell isDataCentered>Quantité préparée</ProductsTableHeaderCell>
                        </tr>
                    </thead>
                    <ProductsTableBody>
                        {products.map((product) => {
                            const { productId, productLabel, packages, packagesPicked, conditionning } = product;

                            const quantity = packages * conditionning;
                            const quantityPicked =
                                packagesPicked !== null && packagesPicked !== undefined
                                    ? packagesPicked * conditionning
                                    : null;

                            return (
                                <tr key={productId}>
                                    <ProductsTableBodyCell>
                                        <ProductNameContainer>
                                            <DetailLink name={productLabel} value={productId} path="product" />
                                        </ProductNameContainer>
                                    </ProductsTableBodyCell>
                                    <ProductsTableBodyCell isDataCentered>
                                        <QuantityInputOuterContainer>
                                            <QuantityInputInnerContainer>
                                                <TotemInput
                                                    type="number"
                                                    value={packages || ''}
                                                    disabled // update if we want this to be editable
                                                    centerText
                                                    data-test="product-packages"
                                                    onChange={() => null}
                                                />
                                            </QuantityInputInnerContainer>
                                        </QuantityInputOuterContainer>
                                    </ProductsTableBodyCell>
                                    <ProductsTableBodyCell isDataCentered>
                                        <QuantityInputOuterContainer>
                                            <QuantityInputInnerContainer>
                                                <TotemInput
                                                    type="number"
                                                    value={quantity || ''}
                                                    disabled // update if we want this to be editable
                                                    centerText
                                                    data-test="product-quantity"
                                                    onChange={() => null}
                                                />
                                            </QuantityInputInnerContainer>
                                        </QuantityInputOuterContainer>
                                    </ProductsTableBodyCell>
                                    <ProductsTableBodyCell isDataCentered>
                                        <QuantityInputOuterContainer>
                                            <QuantityInputInnerContainer>
                                                <TotemInput
                                                    type="number"
                                                    value={packagesPicked || ''}
                                                    disabled // update if we want this to be editable
                                                    centerText
                                                    data-test="product-packages-picked"
                                                    onChange={() => null}
                                                />
                                            </QuantityInputInnerContainer>
                                        </QuantityInputOuterContainer>
                                    </ProductsTableBodyCell>
                                    <ProductsTableBodyCell>
                                        <QuantityInputOuterContainer>
                                            <QuantityInputInnerContainer>
                                                <TotemInput
                                                    type="number"
                                                    value={quantityPicked || ''}
                                                    disabled // update if we want this to be editable
                                                    centerText
                                                    data-test="product-quantity-picked"
                                                    onChange={() => null}
                                                />
                                            </QuantityInputInnerContainer>
                                        </QuantityInputOuterContainer>
                                    </ProductsTableBodyCell>
                                </tr>
                            );
                        })}
                    </ProductsTableBody>
                </ProductsTable>
            </ColumnsSectionContainer>
        </SectionContainer>
    );
};

const ProductsTable = styled.table`
    border-radius: ${({ theme }) => theme.borderRadius};
    border-spacing: 0px;
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
`;

const ProductsTableBody = styled.thead`
    & > :not(:first-child) td {
        border-top: 1px solid ${({ theme }) => theme.lightBorderColor};
    }
`;

const ProductsTableHeaderCell = styled.th`
    padding: 10px;
    text-align: ${({ isDataCentered }: { isDataCentered?: boolean }) => (isDataCentered ? 'center' : 'left')};
    border-bottom: 1px solid ${({ theme }) => theme.lightBorderColor};
`;

const ProductsTableBodyCell = styled.td`
    padding: 10px;
    text-align: ${({ isDataCentered }: { isDataCentered?: boolean }) => (isDataCentered ? 'center' : 'left')};
`;

const ProductNameContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const QuantityInputOuterContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const QuantityInputInnerContainer = styled.div`
    width: 70px;
`;

import React, { FunctionComponent } from 'react';
import { TotemPopup } from 'components/TotemPopup';
import { MobilePopupsQuery, useCreateMobilePopupMutation } from 'data/__generated__';

import { MOBILE_POPUPS_QUERY } from 'data/queries/mobilePopup';
import { formatFormValues } from './utils';
import { UpsertMobilePopupForm } from './UpsertMobilePopupForm';

type CreateMobilePopupModalProps = {
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
};

export const CreateMobilePopupModal: FunctionComponent<CreateMobilePopupModalProps> = ({ isOpen, setIsOpen }) => {
    const [createMobilePopup] = useCreateMobilePopupMutation({
        onCompleted: (data) => {
            if (!data?.createMobilePopup) {
                return;
            }
            setIsOpen(false);
        },
        update: (cache, { data }) => {
            const query = cache.readQuery<MobilePopupsQuery>({ query: MOBILE_POPUPS_QUERY });
            if (!query || !data?.createMobilePopup) {
                return;
            }
            cache.writeQuery<MobilePopupsQuery>({
                query: MOBILE_POPUPS_QUERY,
                data: {
                    mobilePopups: [...query.mobilePopups, data.createMobilePopup],
                },
            });
        },
    });

    return (
        <TotemPopup isOpen={isOpen} title="Créer une popup mobile" setIsOpen={setIsOpen}>
            <UpsertMobilePopupForm
                onSubmit={(values) => createMobilePopup({ variables: { input: formatFormValues(values) } })}
                imageUrl={null}
            />
        </TotemPopup>
    );
};

import React from 'react';

import styled, { css } from 'styled-components';

import { useGetToteamMessagesQuery } from 'data/__generated__';

const ICON_URL = 'https://totems3.s3.eu-central-1.amazonaws.com/totems/totemCardAll.svg';

export enum LoaderModeType {
    Bounce = 'bounce',
    Spin = 'spin',
}

export const Loader = ({
    size = '55px',
    mode = LoaderModeType.Bounce,
    displayText = true,
}: {
    size?: string;
    mode?: LoaderModeType;
    displayText?: boolean;
}) => {
    const defaultMessage = {
        message: "Va te chercher un café, tu l'as bien mérité",
        userFullName: 'Totem',
    };
    const { data: messagesData = { toteamMessages: [] }, loading: messagesLoading } = useGetToteamMessagesQuery();
    const randomMessage =
        messagesData.toteamMessages[Math.floor(Math.random() * messagesData.toteamMessages.length)] || defaultMessage;
    return (
        <Container>
            <Square className={mode} size={size} />
            {displayText && mode === LoaderModeType.Bounce && !messagesLoading ? (
                <>
                    <Message>{randomMessage.message}</Message>
                    <MessageUserFullName>{randomMessage.userFullName}</MessageUserFullName>
                </>
            ) : null}
        </Container>
    );
};

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .bounce {
        animation-name: bounce;
        animation-timing-function: linear;
    }

    @keyframes bounce {
        10% {
            transform: scale(1, 1) translateY(0);
        }
        25% {
            transform: scale(0.75, 1.25) translateY(25px);
        }
        28% {
            transform: scale(0.75, 1.25) translateY(25px);
        }
        42% {
            transform: scale(1.4, 0.6) translateY(-300px);
        }
        68% {
            transform: scale(0.95, 1.05) translateY(0);
        }
        78% {
            transform: scale(1.02, 0.98) translateY(-10px);
        }
        96% {
            transform: scale(1, 1) translateY(0);
        }
    }

    // unused animation code if we ever want to use randomly selected loaders that move differently
    .spin {
        animation-name: spin;
        animation-timing-function: linear;
    }

    @keyframes spin {
        20% {
            transform: scale(1, 1);
        }
        25% {
            transform: rotate(90deg);
        }
        45% {
            transform: rotate(90deg);
        }
        50% {
            transform: rotate(180deg);
        }
        70% {
            transform: rotate(180deg);
        }
        75% {
            transform: rotate(270deg);
        }
        95% {
            transform: rotate(270deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

const Square = styled.div`
    width: ${({ size }: { size: string }) => size};
    height: ${({ size }: { size: string }) => size};
    border-radius: ${({ theme }) => theme.borderRadius};
    background: url('${ICON_URL}');
    background-size: ${({ size }: { size: string }) => css`
        ${size} ${size}
    `};
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
`;

const Message = styled.div`
    text-align: center;
    padding: 0 20px;
    margin-top: 50px;
    font-size: 20px;
    color: ${({ theme }) => theme.textColor};
`;

const MessageUserFullName = styled.div`
    margin-top: 10px;
    font-size: 20px;
    font-weight: 800;
    color: ${({ theme }) => theme.textColor};
`;

import { BOOLEAN_FIELDS, ID, NUMERIC_FIELDS } from 'pages/Products/constants/csvFields';

type FormattedFieldType = string | number | boolean | null;

export const isTrue = (value: string): boolean => {
    return value?.toLowerCase() === 'true' || value?.toLowerCase() === 'vrai';
};

export const isFalse = (value: string): boolean => {
    return value?.toLowerCase() === 'false' || value?.toLowerCase() === 'faux';
};

export const isBoolean = (value: string): boolean => {
    return isTrue(value) || isFalse(value);
};

export const formatToNumber = (value: string): number => {
    return Number(value?.replace(',', '.'));
};

export const formatProductField = (fieldName: string, fieldValue: string): FormattedFieldType => {
    if (fieldValue === '') {
        return null;
    }
    if (NUMERIC_FIELDS.includes(fieldName)) {
        return formatToNumber(fieldValue);
    }
    if (BOOLEAN_FIELDS.includes(fieldName)) {
        return isTrue(fieldValue);
    }
    if (fieldName === 'flow') {
        return fieldValue.toLowerCase();
    }
    return fieldValue;
};

export const splitStringIntoArray = (fieldValue: FormattedFieldType): string[] => {
    const valuesArray = (typeof fieldValue === 'string' && fieldValue !== '' && fieldValue.split(',')) || [];
    return valuesArray.map((value) => value.toLowerCase().trim());
};

//same as splitStringIntoArray, only without toLowerCase, to keep ids/barcodes in their original format
export const splitStringIntoArrayKeepCase = (fieldValue: FormattedFieldType): string[] => {
    const valuesArray = (typeof fieldValue === 'string' && fieldValue !== '' && fieldValue.split(',')) || [];
    return valuesArray.map((value) => value.trim());
};

export const isIdField = (value: string): boolean => {
    const idLowerCase = value?.toLowerCase();
    return ID === idLowerCase || ID === '_' + idLowerCase;
};

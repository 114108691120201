import { apolloClient } from '../../../App';
import { ProductSwitchFragment } from 'data/fragments/__generated__/ProductSwitchFragment';
import { GET_PRODUCT_SWITCHES_QUERY } from 'data/queries/productSwitch';
import {
    GET_PRODUCT_SWITCHES,
    GET_PRODUCT_SWITCHES_getProductSwitches,
} from 'data/queries/__generated__/GET_PRODUCT_SWITCHES';

export const productSwitchesUpdatedOrCreatedHandler = (productSwitchesUpdatedOrCreated: ProductSwitchFragment[]) => {
    let currentProductSwitches = [] as GET_PRODUCT_SWITCHES_getProductSwitches[];

    const result = apolloClient.readQuery<GET_PRODUCT_SWITCHES>({ query: GET_PRODUCT_SWITCHES_QUERY });
    if (!result) {
        return;
    }
    currentProductSwitches = result.getProductSwitches;

    const updatedOrCreatedProductSwitchIds = productSwitchesUpdatedOrCreated.map(({ _id }) => _id);
    const nonUpdatedOrCreatedProductSwitches = currentProductSwitches.filter(
        ({ _id }) => !updatedOrCreatedProductSwitchIds.includes(_id),
    );
    const newProductSwitches = [...productSwitchesUpdatedOrCreated, ...nonUpdatedOrCreatedProductSwitches];

    apolloClient.writeQuery<GET_PRODUCT_SWITCHES>({
        query: GET_PRODUCT_SWITCHES_QUERY,
        data: { getProductSwitches: newProductSwitches },
    });
};

export const productSwitchArchivedHandler = (archivedProductSwitch: ProductSwitchFragment) => {
    let currentProductSwitches = [] as GET_PRODUCT_SWITCHES_getProductSwitches[];

    const result = apolloClient.readQuery<GET_PRODUCT_SWITCHES>({ query: GET_PRODUCT_SWITCHES_QUERY });
    if (!result) {
        return;
    }
    currentProductSwitches = result.getProductSwitches;

    const newProductSwitches = currentProductSwitches.filter(({ _id }) => _id !== archivedProductSwitch._id);

    apolloClient.writeQuery<GET_PRODUCT_SWITCHES>({
        query: GET_PRODUCT_SWITCHES_QUERY,
        data: { getProductSwitches: newProductSwitches },
    });
};

import {
    MICROSTORE_COLUMN_TEMPLATE_FRAGMENT,
    MICROSTORE_COLUMN_TEMPLATE_WITH_ALL_INFO,
} from 'data/fragments/microstoreColumnTemplate';
import gql from 'graphql-tag';

export const CREATE_MICROSTORE_COLUMN_TEMPLATE_MUTATION = gql`
    mutation CREATE_MICROSTORE_COLUMN_TEMPLATE($microstoreColumnTemplate: MicrostoreColumnTemplateCreateInput!) {
        createMicrostoreColumnTemplateMutation(microstoreColumnTemplate: $microstoreColumnTemplate) {
            newMicrostoreColumnTemplate {
                ...MicrostoreColumnTemplateFragment
            }
            errors
        }
    }
    ${MICROSTORE_COLUMN_TEMPLATE_FRAGMENT}
`;

export const DELETE_MICROSTORE_COLUMN_TEMPLATES_MUTATION = gql`
    mutation DELETE_MICROSTORE_COLUMN_TEMPLATES($microstoreColumnTemplateIds: [String!]!) {
        deleteMicrostoreColumnTemplatesMutation(microstoreColumnTemplateIds: $microstoreColumnTemplateIds) {
            deletedMicrostoreColumnTemplateIds
            errors
        }
    }
`;

export const UPDATE_MICROSTORE_COLUMN_TEMPLATE_MUTATION = gql`
    mutation UPDATE_MICROSTORE_COLUMN_TEMPLATE($microstoreColumnTemplate: MicrostoreColumnTemplateUpdateInput!) {
        updateMicrostoreColumnTemplateMutation(microstoreColumnTemplate: $microstoreColumnTemplate) {
            newMicrostoreColumnTemplate {
                ...MicrostoreColumnTemplateWithAllInfo
            }
            errors
        }
    }
    ${MICROSTORE_COLUMN_TEMPLATE_WITH_ALL_INFO}
`;

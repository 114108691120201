import gql from 'graphql-tag';

export const STOCK_TRANSFERS_UPDATED_SUBSCRIPTION = gql`
    subscription STOCK_TRANSFERS_UPDATED($stockTransferId: ID) {
        stockTransferUpdated(stockTransferId: $stockTransferId) {
            _id
            createdAt
            deliveryId
            destinationSiteId
            destinationSiteName
            numberOfRefs
            originSiteId
            originSiteName
            products {
                productId
                fullname
                originStockAvailable
                destinationStockAvailable
                quantity
            }
            quantityOfProducts
            state
            stateHistory {
                state
                createdAt
                createdBy
            }
            transferDate
            updatedAt
        }
    }
`;

import React, { useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { REPLACE_MICROSTORE_COLUMN_MUTATION } from 'data/mutations/microstoreColumn';
import {
    REPLACE_MICROSTORE_COLUMN,
    REPLACE_MICROSTORE_COLUMNVariables,
} from 'data/mutations/__generated__/REPLACE_MICROSTORE_COLUMN';
import { GET_MICROSTORE_COLUMN_TEMPLATES } from 'data/queries/__generated__/GET_MICROSTORE_COLUMN_TEMPLATES';
import { GET_MICROSTORE_COLUMN_TEMPLATES_QUERY } from 'data/queries/microstoreColumnTemplate';
import { GET_MICROSTORE_SITES_microstoreSitesWithColumnGroups_microstoreColumnGroups_columns } from 'data/queries/__generated__/GET_MICROSTORE_SITES';
import { MicrostoreColumnTemplateType } from 'data/__generated__';

import { Loader, LoaderModeType } from 'components/Loader';
import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { TotemSelect } from 'components/TotemSelect';
import { TotemDatePicker } from 'components/TotemDatePicker';
import { dateFromString, formatDateAsAnniversary } from 'helpers/dateTimes';

export function ReplaceMicrostoreColumnPopup({
    isOpen,
    setMicrostoreColumnToReplace,
    column,
}: {
    isOpen: boolean;
    setMicrostoreColumnToReplace: (
        column: GET_MICROSTORE_SITES_microstoreSitesWithColumnGroups_microstoreColumnGroups_columns | null,
    ) => void;
    column: GET_MICROSTORE_SITES_microstoreSitesWithColumnGroups_microstoreColumnGroups_columns;
}) {
    const [replacementDate, setReplacementDate] = useState<Date | null>(
        column.removalDate ? dateFromString(column.removalDate, true) : null,
    );
    const [microstoreColumnTemplateId, setMicrostoreColumnTemplateId] = useState<string | null>(null);

    const {
        loading: microstoreColumnTemplateLoading,
        data: microstoreColumnTemplateData,
        error: microstoreColumnTemplateError,
    } = useQuery<GET_MICROSTORE_COLUMN_TEMPLATES>(GET_MICROSTORE_COLUMN_TEMPLATES_QUERY);

    const [replaceMicrostoreColumn, { loading: replacementLoading }] = useMutation<
        REPLACE_MICROSTORE_COLUMN,
        REPLACE_MICROSTORE_COLUMNVariables
    >(REPLACE_MICROSTORE_COLUMN_MUTATION);

    // only display warnings for columns with fresh products
    const shouldDisplayDelaysWarning = column.type === MicrostoreColumnTemplateType.Fridge;

    const mondayInThreeWeeks = new Date();
    mondayInThreeWeeks.setDate(mondayInThreeWeeks.getDate() + 21 - ((mondayInThreeWeeks.getDay() + 6) % 7));
    mondayInThreeWeeks.setHours(0, 0, 0, 0);

    if (microstoreColumnTemplateLoading) {
        return (
            <TotemPopup
                title="Ajouter une colonne"
                isOpen={isOpen}
                setIsOpen={() => setMicrostoreColumnToReplace(null)}
                contentOverflow="visible"
            >
                <Loader />
            </TotemPopup>
        );
    }

    if (microstoreColumnTemplateError || !microstoreColumnTemplateData) {
        throw new Error('Une erreur est survenue lors de la récupération des templates de colonne microstore');
    }

    async function handleSubmit() {
        if (!microstoreColumnTemplateId || !replacementDate) {
            return;
        }
        if (column.startingDate && replacementDate <= dateFromString(column.startingDate, true)) {
            toast.error(
                <span>Vous ne pouvez pas mettre une date de retrait avant la date de début de la colonne</span>,
                {
                    autoClose: false,
                },
            );
            return;
        }

        if (shouldDisplayDelaysWarning && replacementDate && mondayInThreeWeeks > replacementDate) {
            toast.error(
                <span>
                    "Non respect des délais nécessaires pour les achats en vue du lancement - le restocking ne sera pas
                    automatique"
                </span>,
                {
                    autoClose: false,
                },
            );
        }

        const { data } = await replaceMicrostoreColumn({
            variables: {
                replaceMicrostoreColumnInput: {
                    columnId: column._id,
                    microstoreColumnTemplateId,
                    replacementDate: formatDateAsAnniversary({ dateTime: replacementDate, useNewFormat: true }),
                },
            },
        });

        if (!data) {
            throw new Error('Une erreur est survenue lors du replacement du template de colonne');
        }

        const {
            replaceMicrostoreColumnMutation: { errors },
        } = data;

        if (errors.length) {
            errors.forEach((error, index) => {
                toast.error(<span key={index}>Erreur : {error}</span>, { autoClose: false });
            });

            if (errors.length > 1) {
                toast.info('Cliquez pour fermer toutes les notifications', {
                    autoClose: false,
                    onClick: () => toast.dismiss(),
                });
            }
        }

        setMicrostoreColumnToReplace(null);
    }

    const { microstoreColumnTemplatesQuery: microstoreColumnTemplates } = microstoreColumnTemplateData;

    const microstoreColumnTemplateOptions =
        microstoreColumnTemplates
            .map(({ _id: microstoreColumnTemplateId, name }) => ({
                value: microstoreColumnTemplateId,
                label: name,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)) || [];

    return (
        <TotemPopup
            title="Remplacer une colonne"
            isOpen={isOpen}
            setIsOpen={() => setMicrostoreColumnToReplace(null)}
            contentOverflow="visible"
        >
            <SubmitContainer>
                <TotemDatePicker
                    label="Date de remplacement"
                    selected={replacementDate}
                    isClearable={true}
                    onChange={(date) => {
                        if (shouldDisplayDelaysWarning && date && mondayInThreeWeeks > date) {
                            window.alert(
                                "Non respect de la règle :\n\nLa date de switch d'une colonne doit être renseignée strictement avant le lundi de la semaine en J-14 du switch pour permettre les achats.\n\nVeuillez valider avec data & supply sinon il n'y aura pas de restocking",
                            );
                        }
                        setReplacementDate(date);
                    }}
                />
                <TotemSelect
                    label="Template de colonne microstore"
                    placeholder="Sélectionner le template de colonnes"
                    value={microstoreColumnTemplateOptions.find(
                        (microstoreColumnTemplateOption) =>
                            microstoreColumnTemplateId === microstoreColumnTemplateOption.value,
                    )}
                    options={microstoreColumnTemplateOptions}
                    onChange={(option) => {
                        if (option) {
                            setMicrostoreColumnTemplateId(option.value);
                        }
                    }}
                />
                <TotemPrimaryButton onClick={handleSubmit} disabled={!microstoreColumnTemplateId || !replacementDate}>
                    {replacementLoading ? <Loader size="20px" mode={LoaderModeType.Spin} /> : 'Confirmer'}
                </TotemPrimaryButton>
            </SubmitContainer>
        </TotemPopup>
    );
}

const SubmitContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 15px;

    > :not(:first-child) {
        margin-top: 15px;
    }
`;

import React from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';

import { ColumnsSectionContainer } from 'components/DetailsView/Section';

import { SupplierFormInput } from 'data/__generated__';
import { GET_SUPPLIER_PRODUCTS_QUERY } from 'data/queries/product';
import {
    GET_SUPPLIER_PRODUCTS,
    GET_SUPPLIER_PRODUCTSVariables,
} from 'data/queries/__generated__/GET_SUPPLIER_PRODUCTS';
import { Loader } from 'components/Loader';
import { SelectedOption, TotemSelect } from 'components/TotemSelect';
import { TotemInput } from 'components/TotemInput';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { FaTrash } from 'react-icons/fa';
import { colors } from 'constants/colors';

export const OrderingProductTerms = ({
    supplierId,
    supplierFormState,
    setSupplierFormState,
}: {
    supplierId: string;
    supplierFormState: SupplierFormInput;
    setSupplierFormState: React.Dispatch<React.SetStateAction<SupplierFormInput>>;
}) => {
    const {
        data: productsData,
        loading: productsLoading,
        error: productsError,
    } = useQuery<GET_SUPPLIER_PRODUCTS, GET_SUPPLIER_PRODUCTSVariables>(GET_SUPPLIER_PRODUCTS_QUERY, {
        variables: { supplierId },
    });

    if (productsLoading && !productsData) {
        return (
            <ColumnsSectionContainer numberOfColumns={1}>
                <Loader />
            </ColumnsSectionContainer>
        );
    }

    if (productsError || !productsData) {
        throw new Error('Une erreur est survenue lors de la récupération des produits du fournisseur');
    }

    const { getSupplierProducts: products } = productsData;

    function addProduct(productId: string) {
        if (
            supplierFormState.orderingProductTerms.some(
                (orderingProductTerm) => orderingProductTerm.productId === productId,
            )
        ) {
            toast.info('Le produit est déjà dans la liste');
            return;
        }

        setSupplierFormState({
            ...supplierFormState,
            orderingProductTerms: [...supplierFormState.orderingProductTerms, { productId, minQuantity: 0 }],
        });
    }

    function updateProduct({ productId, minQuantity }: { productId: string; minQuantity: number }) {
        const newOrderingProductTerms = supplierFormState.orderingProductTerms.map((orderingProductTerm) => {
            if (orderingProductTerm.productId === productId) {
                return { productId, minQuantity };
            }
            return orderingProductTerm;
        });

        setSupplierFormState({
            ...supplierFormState,
            orderingProductTerms: [...newOrderingProductTerms],
        });
    }

    function removeProduct(productId: string) {
        const newOrderingProductTerms = supplierFormState.orderingProductTerms.filter((orderingProductTerm) => {
            return orderingProductTerm.productId !== productId;
        });

        setSupplierFormState({
            ...supplierFormState,
            orderingProductTerms: [...newOrderingProductTerms],
        });
    }

    const productsOptions =
        products
            .filter(({ _id }) => !supplierFormState.orderingProductTerms.some(({ productId }) => _id === productId))
            .map(({ _id, fullname }) => ({
                value: _id,
                label: fullname,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)) || [];

    return (
        <ColumnsSectionContainer numberOfColumns={1}>
            <Title>Minimum de commande par produit</Title>
            <TotemSelect
                placeholder="Sélectionner un produit"
                options={productsOptions}
                onChange={(option: SelectedOption<string>) => {
                    if (option) {
                        addProduct(option.value);
                    }
                }}
            />
            {supplierFormState.orderingProductTerms.map(({ minQuantity, productId }) => {
                const product = products.find(({ _id }) => productId === _id);
                return (
                    <ProductContainer key={productId}>
                        <Quantity>
                            <TotemInput
                                placeholder="0"
                                value={minQuantity}
                                onChange={(value) => {
                                    const newMinQuantity = parseInt(value) || 0;
                                    updateProduct({
                                        minQuantity: newMinQuantity,
                                        productId,
                                    });
                                }}
                                centerText
                                width="100%"
                                type="number"
                                step="1"
                                min="0"
                            />
                        </Quantity>
                        <ProductName>{product?.fullname ?? productId}</ProductName>
                        {!product ? (
                            <ErrorMessage>Le produit n'est pas vendu principalement par ce fournisseur</ErrorMessage>
                        ) : null}

                        <TotemPrimaryButton type="button" onClick={() => removeProduct(productId)}>
                            <FaTrash size={20} />
                        </TotemPrimaryButton>
                    </ProductContainer>
                );
            })}
        </ColumnsSectionContainer>
    );
};

const Title = styled.div`
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
`;
const ProductContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
`;
const ProductName = styled.div`
    font-weight: bold;
    margin-left: 15px;
    flex-grow: 1;
`;
const Quantity = styled.div``;

const ErrorMessage = styled.div`
    color: ${colors.red};
`;

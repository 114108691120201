import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import styled from 'styled-components';

import { PAGES } from '../../constants/pages';

import { Header, HeaderTitle } from 'components/Header';
import { PageTitle } from 'components/PageTitle';
import { Loader } from 'components/Loader';

import { GET_SITES } from 'data/queries/__generated__/GET_SITES';
import { GET_SITES_QUERY } from 'data/queries/site';
import { SitesSelector } from './components/SitesSelector';
import { ProductsToRemoveTable } from './components/ProductsToRemoveTable';
import { GET_TRANSFERABLE_PRODUCTS_transferableProducts } from 'data/queries/__generated__/GET_TRANSFERABLE_PRODUCTS';
import { DECLARE_SITE_EXTERNAL_INVENTORY_MUTATION } from 'data/mutations/site';
import {
    DECLARE_SITE_EXTERNAL_INVENTORY,
    DECLARE_SITE_EXTERNAL_INVENTORYVariables,
} from 'data/mutations/__generated__/DECLARE_SITE_EXTERNAL_INVENTORY';
import { toast } from 'react-toastify';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { FaSave } from 'react-icons/fa';

export const SiteExternalInventory = () => {
    const [selectedSiteId, setSelectedSiteId] = useState<string | null>(null);
    const [selectedProductsToRemove, setSelectedProductsToRemove] = useState<
        GET_TRANSFERABLE_PRODUCTS_transferableProducts[]
    >([]);

    const { loading: sitesLoading, data: sitesData, error: sitesError } = useQuery<GET_SITES>(GET_SITES_QUERY);

    const [declareSiteExternalInventory] = useMutation<
        DECLARE_SITE_EXTERNAL_INVENTORY,
        DECLARE_SITE_EXTERNAL_INVENTORYVariables
    >(DECLARE_SITE_EXTERNAL_INVENTORY_MUTATION);

    useEffect(() => {
        setSelectedProductsToRemove([]);
    }, [selectedSiteId, setSelectedProductsToRemove]);

    if (sitesLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (sitesError || !sitesData) {
        throw new Error('Une erreur est survenue lors de la récupération des sites');
    }

    const { sites } = sitesData;

    const handleDeclare = async () => {
        if (!selectedSiteId) {
            return;
        }

        const { data } = await declareSiteExternalInventory({
            variables: {
                siteId: selectedSiteId,
                productsInventoryInfo: selectedProductsToRemove.map(
                    ({ productId, originStockAvailable, quantity }) => ({
                        productId,
                        stock: originStockAvailable - quantity,
                    }),
                ),
            },
        });
        if (!data) {
            toast.error("Une erreur inconnue est survenue lors de l'enregistrement de l'inventaire");
            return;
        }

        setSelectedSiteId(null);
        toast.success("L'inventaire a bien été enregistré");
    };

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.siteExternalInventory} />
                </HeaderTitle>

                <CTAsContainer>
                    <TotemPrimaryButton
                        onClick={handleDeclare}
                        disabled={!selectedSiteId || !selectedProductsToRemove.length}
                    >
                        <FaSave size="13" />
                        <SaveLabel>Mettre à jour</SaveLabel>
                    </TotemPrimaryButton>
                </CTAsContainer>
            </Header>
            <ContentContainer>
                <Content>
                    <SitesSelector
                        selectedSiteId={selectedSiteId}
                        setSelectedSiteId={setSelectedSiteId}
                        sites={sites}
                    />
                    {selectedSiteId ? (
                        <ProductsToRemoveTable
                            selectedProductsToRemove={selectedProductsToRemove}
                            setSelectedProductsToRemove={setSelectedProductsToRemove}
                            siteId={selectedSiteId}
                        />
                    ) : null}
                </Content>
            </ContentContainer>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const CTAsContainer = styled.div`
    display: flex;
    align-items: center;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const SaveLabel = styled.span`
    margin-left: 5px;
`;

const ContentContainer = styled.div`
    display: flex;
    flex: 1;
    overflow: hidden;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    flex: 1;
    overflow: auto;

    & > :not(:first-child) {
        margin-top: 15px;
    }
`;

import React, { useEffect, useState } from 'react';

import { useLazyQuery, useQuery, useSubscription } from '@apollo/client';
import styled from 'styled-components';

import { PAGES } from '../../constants/pages';
import { SETUP_PLURAL_STATES_LABELS } from './constants/states';

import { GET_SITE_SETUP_FOR_DATE_QUERY } from 'data/queries/siteSetup';
import { GET_SIMPLE_MICROSTORE_SITES_QUERY } from 'data/queries/site';
import { SiteSetupState, SiteType } from 'data/__generated__';
import {
    GET_SITE_SETUP_FOR_DATE,
    GET_SITE_SETUP_FOR_DATEVariables,
} from 'data/queries/__generated__/GET_SITE_SETUP_FOR_DATE';
import {
    GET_SIMPLE_MICROSTORE_SITES,
    GET_SIMPLE_MICROSTORE_SITES_microstoreSites,
} from 'data/queries/__generated__/GET_SIMPLE_MICROSTORE_SITES';
import { SITE_SETUP_CREATED_SUBSCRIPTION, SITE_SETUP_UPDATED_SUBSCRIPTION } from 'data/subscriptions/siteSetup';
import { SITE_SETUP_UPDATED } from 'data/subscriptions/__generated__/SITE_SETUP_UPDATED';
import { SITE_SETUP_CREATED } from 'data/subscriptions/__generated__/SITE_SETUP_CREATED';

import { SiteSetupTrelloItem } from './components/SiteSetupTrelloItem';
import { Header, HeaderTitle } from 'components/Header';
import { Loader } from 'components/Loader';
import { PageTitle } from 'components/PageTitle';
import { TrelloView } from 'components/TrelloView/TrelloView';
import { SiteWithoutSetupTrelloItem } from './components/SiteWithoutSetupTrelloItem';

import { siteSetupCreatedHandler, siteSetupUpdatedHandler } from './cacheHandlers/siteSetupsCacheHandler';
import { TotemDatePicker } from 'components/TotemDatePicker';
import { formatDateAsAnniversary } from 'helpers/dateTimes';

// yoyoyo
export const SiteSetups = () => {
    const [setupsDate, setSetupsDate] = useState<Date>(new Date());
    const [isSiteSetupsLoading, setIsSiteSetupsLoading] = useState(true);

    const [getSiteSetupsForDate, { data: siteSetupsData, error: siteSetupsError }] = useLazyQuery<
        GET_SITE_SETUP_FOR_DATE,
        GET_SITE_SETUP_FOR_DATEVariables
    >(GET_SITE_SETUP_FOR_DATE_QUERY, {
        fetchPolicy: 'cache-and-network',
    });
    const {
        data: sitesData,
        loading: sitesLoading,
        error: sitesError,
    } = useQuery<GET_SIMPLE_MICROSTORE_SITES>(GET_SIMPLE_MICROSTORE_SITES_QUERY, {
        fetchPolicy: 'cache-and-network',
    });

    useEffect(() => {
        setIsSiteSetupsLoading(true);
        getSiteSetupsForDate({
            variables: { date: formatDateAsAnniversary({ dateTime: setupsDate, useNewFormat: true }) },
        }).then(() => setIsSiteSetupsLoading(false));
    }, [setIsSiteSetupsLoading, getSiteSetupsForDate, setupsDate]);

    useSubscription<SITE_SETUP_CREATED>(SITE_SETUP_CREATED_SUBSCRIPTION, {
        onSubscriptionData({ subscriptionData: { data } }) {
            if (!data) {
                return;
            }
            const { siteSetupCreated } = data;
            siteSetupCreatedHandler(siteSetupCreated);
        },
    });

    useSubscription<SITE_SETUP_UPDATED>(SITE_SETUP_UPDATED_SUBSCRIPTION, {
        onSubscriptionData({ subscriptionData: { data } }) {
            if (!data) {
                return;
            }
            const { siteSetupUpdated } = data;
            siteSetupUpdatedHandler(siteSetupUpdated);
        },
    });

    if ((isSiteSetupsLoading && !siteSetupsData) || (sitesLoading && !sitesData)) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (siteSetupsError || !siteSetupsData || sitesError || !sitesData) {
        throw new Error('Une erreur est survenue lors de la récupération des rangements');
    }

    const { getSiteSetupsForDate: siteSetups } = siteSetupsData;
    const { microstoreSites: sites } = sitesData;

    function getSiteSetupsByState({ state }: { state: SiteSetupState }) {
        return siteSetups
            .filter((siteSetup) => siteSetup.state === state)
            .map((siteSetup) => {
                const site = sites.find(
                    ({ _id }) => _id === siteSetup.siteId,
                ) as GET_SIMPLE_MICROSTORE_SITES_microstoreSites;
                return {
                    _id: siteSetup._id,
                    content: <SiteSetupTrelloItem siteSetup={siteSetup} site={site} />,
                };
            });
    }

    const siteSetupsByState = {
        [SiteSetupState.Created]: getSiteSetupsByState({ state: SiteSetupState.Created }),
        [SiteSetupState.InProgress]: getSiteSetupsByState({ state: SiteSetupState.InProgress }),
        [SiteSetupState.Finished]: getSiteSetupsByState({ state: SiteSetupState.Finished }),
    };

    const siteWithoutSetupItems = sites
        .filter((site) => site.type !== SiteType.Warehouse && !siteSetups.find(({ siteId }) => siteId === site._id))
        .sort((siteA, siteB) => siteA.name.localeCompare(siteB.name))
        .map((site) => {
            return {
                _id: site._id,
                content: <SiteWithoutSetupTrelloItem site={site} />,
            };
        });

    const STATE_COLUMNS_TO_DISPLAY = [SiteSetupState.Created, SiteSetupState.InProgress, SiteSetupState.Finished];

    const initialData = {
        columns: [
            {
                title: 'Site sans rangement',
                items: siteWithoutSetupItems,
            },
            ...STATE_COLUMNS_TO_DISPLAY.map((state) => ({
                title: SETUP_PLURAL_STATES_LABELS[state],
                items: siteSetupsByState[state],
            })),
        ],
    };

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.siteSetups} />
                </HeaderTitle>

                <DateContainer>
                    <TotemDatePicker
                        label="Date des rangements"
                        selected={setupsDate}
                        onChange={(date) => {
                            setSetupsDate(date as Date);
                        }}
                    />
                </DateContainer>
            </Header>
            <Content>
                <TrelloViewContainer>
                    <TrelloView columnWidth="300px" initialData={initialData} />
                </TrelloViewContainer>
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const DateContainer = styled.div`
    min-width: 300px;
    justify-self: end;
`;

const TrelloViewContainer = styled.div`
    overflow-x: scroll;
`;

const Content = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    overflow: hidden;
`;

import React from 'react';

import { useQuery } from '@apollo/client';
import styled from 'styled-components';

import { colors } from 'constants/colors';

import { GET_SNAPSHOT_MICROSTORE_FOR_DATE_QUERY } from 'data/queries/snapshotMicrostore';
import {
    GET_SNAPSHOT_MICROSTORE_FOR_DATE,
    GET_SNAPSHOT_MICROSTORE_FOR_DATEVariables,
} from 'data/queries/__generated__/GET_SNAPSHOT_MICROSTORE_FOR_DATE';
import { GET_ALL_PRODUCTS } from 'data/queries/__generated__/GET_ALL_PRODUCTS';
import { GET_ALL_PRODUCTS_QUERY } from 'data/queries/product';

import { Loader } from 'components/Loader';

import { formatDateAsAnniversary } from 'helpers/dateTimes';

export const MicrostoreSnapshotView = ({ siteId, snapshotDate }: { siteId: string; snapshotDate: Date }) => {
    const {
        data: snapshotMicrostoreData,
        error: snapshotMicrostoreError,
        loading: snapshotMicrostoreLoading,
    } = useQuery<GET_SNAPSHOT_MICROSTORE_FOR_DATE, GET_SNAPSHOT_MICROSTORE_FOR_DATEVariables>(
        GET_SNAPSHOT_MICROSTORE_FOR_DATE_QUERY,
        {
            fetchPolicy: 'cache-and-network',
            variables: {
                siteId,
                snapshotDate: formatDateAsAnniversary({ dateTime: snapshotDate, useNewFormat: true }),
            },
        },
    );

    const {
        loading: productsLoading,
        data: productsData,
        error: productsError,
    } = useQuery<GET_ALL_PRODUCTS>(GET_ALL_PRODUCTS_QUERY);

    if ((snapshotMicrostoreLoading && !snapshotMicrostoreData) || (productsLoading && !productsData)) {
        return (
            <Content>
                <Loader />
            </Content>
        );
    }

    if (snapshotMicrostoreError || !snapshotMicrostoreData || productsError || !productsData) {
        throw new Error('Une erreur est survenue lors de la récupération des rangements');
    }

    const { getSnapshotMicrostoreForDate: snapshotMicrostore } = snapshotMicrostoreData;
    const { getAllProducts: products } = productsData;

    if (!snapshotMicrostore) {
        return null;
    }

    return (
        <Content>
            {snapshotMicrostore.columnGroups.map(({ columnGroupId, columns }) => (
                <ColumnGroup key={columnGroupId}>
                    <ColumnGroupTitle>Regroupement de colonne : {columnGroupId}</ColumnGroupTitle>
                    {columns.map(({ columnId, shelves }) => (
                        <React.Fragment key={columnId}>
                            <ColumnTitle>Colonne : {columnId}</ColumnTitle>
                            <Column>
                                {shelves.map(({ locationTable, shelfId }) => (
                                    <Shelf key={shelfId}>
                                        {locationTable.map((locationRow, rowIndex) => (
                                            <LocationRow key={rowIndex}>
                                                {locationRow.map(({ locationId, productId }) => {
                                                    const product = products.find(({ _id }) => _id === productId);
                                                    return (
                                                        <Location key={locationId}>
                                                            {product?.fullname ?? productId}
                                                        </Location>
                                                    );
                                                })}
                                            </LocationRow>
                                        ))}
                                    </Shelf>
                                ))}
                            </Column>
                        </React.Fragment>
                    ))}
                </ColumnGroup>
            ))}
        </Content>
    );
};

const Content = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    overflow: auto;
    padding: 20px;
    margin-bottom: 40px;
`;

const ColumnGroup = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    border: 1px solid ${colors.lightGrey};
`;

const ColumnGroupTitle = styled.div`
    text-align: center;
    font-size: 22px;
    font-weight: bold;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid ${colors.lightGrey};
    margin-top: 10px;
    background-color: ${colors.pureWhite};
`;

const ColumnTitle = styled.div`
    margin-top: 30px;
    text-align: center;
    font-size: 18px;
    text-decoration: underline;
`;

const Shelf = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid ${colors.lightGrey};

    &:not(:first) {
        margin-top: 10px;
    }
`;
const LocationRow = styled.div`
    display: flex;
    flex-direction: row;
    margin: 5px;
    border: 1px solid ${colors.lightGrey};
`;
const Location = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 2px;
    border: 1px solid ${colors.lightGrey};
    min-height: 40px;
    padding: 3px;
`;

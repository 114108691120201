import { gql } from '@apollo/client';
import { EQUIPMENT_FRAGMENT } from 'data/fragments/equipment';

export const GET_EQUIPMENTS_QUERY = gql`
    query GET_EQUIPMENTS {
        adminEquipments {
            _id
            fullname
            suppliers {
                _id
                name
            }
            imageUrl
            state
        }
    }
`;

export const GET_DETAILED_EQUIPMENT_QUERY = gql`
    query GET_DETAILED_EQUIPMENT($equipmentId: ID!) {
        detailedEquipment(equipmentId: $equipmentId) {
            ...Equipment
        }
    }
    ${EQUIPMENT_FRAGMENT}
`;

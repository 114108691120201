import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import styled from 'styled-components';

import {
    REMOVE_PRODUCT_IN_ORDERS,
    REMOVE_PRODUCT_IN_ORDERSVariables,
} from 'data/mutations/__generated__/REMOVE_PRODUCT_IN_ORDERS';
import {
    REPLACE_PRODUCT_IN_ORDERS,
    REPLACE_PRODUCT_IN_ORDERSVariables,
} from 'data/mutations/__generated__/REPLACE_PRODUCT_IN_ORDERS';
import { REMOVE_PRODUCT_IN_ORDERS_MUTATION, REPLACE_PRODUCT_IN_ORDERS_MUTATION } from 'data/mutations/product';
import { GET_PRODUCTS_BY_CATEGORY } from 'data/queries/__generated__/GET_PRODUCTS_BY_CATEGORY';
import { GET_PRODUCTS_BY_CATEGORY_QUERY } from 'data/queries/product';
import { REPLACEABLE_PRODUCT_ORDERS_AND_FUTURE_STOCK_VARIATIONS_QUERY } from 'data/queries/futureStockVariation';
import {
    REPLACEABLE_PRODUCT_ORDERS_AND_FUTURE_STOCK_VARIATIONS,
    REPLACEABLE_PRODUCT_ORDERS_AND_FUTURE_STOCK_VARIATIONSVariables,
} from 'data/queries/__generated__/REPLACEABLE_PRODUCT_ORDERS_AND_FUTURE_STOCK_VARIATIONS';

import { TotemSelect } from 'components/TotemSelect';
import { toast } from 'react-toastify';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { Loader, LoaderModeType } from 'components/Loader';

export const ProductModal = ({
    productId,
    displayKg,
    name,
    categoryId,
    portion,
    setIsOpen,
}: {
    productId: string;
    displayKg: boolean;
    name: string;
    categoryId: string;
    portion: number;
    setIsOpen: (isOpen: boolean) => void;
}) => {
    const [replacementProduct, setReplacementproduct] = useState<{
        _id: string;
        name: string;
        portion: number;
    } | null>();

    const {
        loading: productFromSameCategoryLoading,
        data: productFromSameCategory,
        error: productFromSameCategoryError,
    } = useQuery<GET_PRODUCTS_BY_CATEGORY>(GET_PRODUCTS_BY_CATEGORY_QUERY, {
        variables: { categoryId },
    });

    const {
        loading: futureStockVariationsAndOrdersLoading,
        data: futureStockVariationsAndOrdersData,
        error: futureStockVariationsAndOrdersError,
    } = useQuery<
        REPLACEABLE_PRODUCT_ORDERS_AND_FUTURE_STOCK_VARIATIONS,
        REPLACEABLE_PRODUCT_ORDERS_AND_FUTURE_STOCK_VARIATIONSVariables
    >(REPLACEABLE_PRODUCT_ORDERS_AND_FUTURE_STOCK_VARIATIONS_QUERY, {
        variables: {
            productId,
        },
    });

    const [removeProductInOrders, { loading: removeProductInOrdersLoading }] = useMutation<
        REMOVE_PRODUCT_IN_ORDERS,
        REMOVE_PRODUCT_IN_ORDERSVariables
    >(REMOVE_PRODUCT_IN_ORDERS_MUTATION);

    const [replaceProductInOrders, { loading: replaceProductInOrdersLoading }] = useMutation<
        REPLACE_PRODUCT_IN_ORDERS,
        REPLACE_PRODUCT_IN_ORDERSVariables
    >(REPLACE_PRODUCT_IN_ORDERS_MUTATION);

    if (productFromSameCategoryLoading || futureStockVariationsAndOrdersLoading) {
        return (
            <ModalContainer>
                <Modal>
                    <Loader />
                </Modal>
            </ModalContainer>
        );
    }

    if (
        productFromSameCategoryError ||
        !productFromSameCategory ||
        futureStockVariationsAndOrdersError ||
        !futureStockVariationsAndOrdersData
    ) {
        throw new Error('Une erreur est survenue lors de la récupération des produits');
    }

    const closeModal = () => {
        setIsOpen(false);
    };

    const replaceProductInOrdersHandler = async () => {
        if (!replacementProduct) {
            return;
        }

        const { data } = await replaceProductInOrders({
            variables: {
                productToRemoveId: productId,
                productToAddId: replacementProduct._id,
            },
        });

        const errors = data?.replaceProductInOrders.errors;

        if (errors?.length) {
            errors.forEach((error, index) => {
                toast.error(<span key={index}>{error}</span>, { autoClose: false });
            });

            return;
        }

        toast.success(`Le produit "${name}" a bien été remplacé dans les commandes par "${replacementProduct.name}"!`);
        closeModal();
    };

    const removeProductInOrdersHandler = async () => {
        const { data } = await removeProductInOrders({
            variables: {
                productToRemoveId: productId,
            },
        });

        const errors = data?.removeProductInOrders.errors;

        if (errors?.length) {
            errors.forEach((error, index) => {
                toast.error(<span key={index}>{error}</span>, { autoClose: false });
            });

            return;
        }

        toast.success(`Le produit "${name}" a bien été supprimé des commandes!`);
        closeModal();
    };

    const productsOptions =
        productFromSameCategory?.getProductsByCategory?.map((product: any) => {
            return {
                value: product._id,
                label: `${product.name}, stock non reservé: ${product.availableStock} ${displayKg ? 'kg' : 'unités'}`,
            };
        }) || [];

    const {
        replaceableProductOrdersAndFutureStockVariations: { orders, futureStockVariations },
    } = futureStockVariationsAndOrdersData;

    const modifiableOrderFutureQuantities = futureStockVariations.reduce((acc, { delta }) => (acc += delta), 0);
    const sortedPreparationDates = futureStockVariations.map(({ useDate }) => useDate).sort();
    const closestDateQuantityToPrepare = futureStockVariations
        .filter(({ useDate }) => useDate === sortedPreparationDates[0])
        .reduce((acc, { delta }) => (acc += Math.abs(delta)), 0);

    return (
        <ModalContainer onClick={closeModal}>
            <Modal
                onClick={(e) => {
                    // only close the modal when I click on background
                    e.stopPropagation();
                }}
            >
                <ModalTitle>Souhaitez-vous remplacer ou supprimer ?</ModalTitle>
                <ModalText>
                    Vous pouvez choisir de remplacer ce produit par un autre produit de la même catégorie, ou bien de
                    supprimer ce produit des commandes, les portions ne seront pas réallouées.
                </ModalText>
                <ModalText>Ce choix impactera toutes les commandes modifiables ci-dessous :</ModalText>
                <OrdersContainer>
                    {orders.map(({ _id: orderId, organization, totem, number, products }) => {
                        const product = products.find(({ _id }) => _id === productId);
                        return (
                            <li key={orderId}>
                                {organization.name} - Totem N°{totem.number}: Commande N°{number}, quantité:{' '}
                                {product?.quantity ?? 0}
                            </li>
                        );
                    })}
                </OrdersContainer>
                <ActionsContainer>
                    <ModalText>
                        💡 Il y a <b>{Math.abs(modifiableOrderFutureQuantities || 0)}</b>
                        {displayKg ? ' kg ' : ' unités '} de produit confirmés (qui devraient être preparés) en total.
                        Parmi eux <b>{closestDateQuantityToPrepare}</b>
                        {displayKg ? ' kg ' : ' unités '} devrait être préparés le {sortedPreparationDates[0]}. Veuillez
                        vous assurer que les quantités suffisantes de produit vont être commandées/ livrées avant cette
                        date si possible afin d'éviter un soldout
                    </ModalText>
                    <TotemSelect
                        label={`Remplacer ${name} par :`}
                        placeholder="Sélectionner le produit de remplacement"
                        options={productsOptions}
                        onChange={(option) => {
                            const product = productFromSameCategory?.getProductsByCategory.find(
                                ({ _id }) => _id === option?.value,
                            );
                            setReplacementproduct(
                                product
                                    ? {
                                          _id: product._id,
                                          name: product.name,
                                          portion: product.portion,
                                      }
                                    : null,
                            );
                        }}
                    />
                    {replacementProduct ? (
                        <ModalText>
                            {`Vous allez remplacer avec le ratio suivant : ${portion} produits (${name}) donneront ${replacementProduct.portion} produits (${replacementProduct.name})`}
                        </ModalText>
                    ) : null}
                    <br />
                    <TotemPrimaryButton
                        disabled={!replacementProduct?._id}
                        type="button"
                        onClick={replaceProductInOrdersHandler}
                    >
                        {replaceProductInOrdersLoading ? (
                            <Loader size="18px" mode={LoaderModeType.Spin} />
                        ) : (
                            <>
                                <SaveLabel>Remplacer dans toutes les commandes </SaveLabel>
                            </>
                        )}
                    </TotemPrimaryButton>
                    <ModalText>OU</ModalText>
                    <TotemPrimaryButton
                        onClick={async (e) => {
                            e.preventDefault();
                            await removeProductInOrdersHandler();
                        }}
                        type="button"
                    >
                        {removeProductInOrdersLoading ? (
                            <Loader size="18px" mode={LoaderModeType.Spin} />
                        ) : (
                            <>
                                <SaveLabel>Supprimer de toutes les commandes</SaveLabel>
                            </>
                        )}
                    </TotemPrimaryButton>
                </ActionsContainer>
            </Modal>
        </ModalContainer>
    );
};

const ModalContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #1116;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
`;

const Modal = styled.div`
    border-top: 5px solid #ff6c25;
    background-color: ${({ theme }) => theme.backgroundColor};
    padding: 40px;
    width: 50%;
    height: auto;
    line-height: 20px;
`;

const ModalTitle = styled.div`
    padding: 10px 0;
    color: ${({ theme }) => theme.textColor};
    font-weight: bold;
    font-size: 1.8em;
`;

const ModalText = styled.div`
    padding: 10px 0;
`;

const SaveLabel = styled.span`
    margin-left: 5px;
`;

const OrdersContainer = styled.div`
    max-height: 300px;
    overflow-y: scroll;

    ::-webkit-scrollbar {
        width: 5px;
        height: 0;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: ${({ theme }) => theme.borderRadius};
        background-color: ${({ theme }) => theme.ctaPrimaryColor};
    }
`;

const ActionsContainer = styled.div`
    margin-top: 5px;
`;

import gql from 'graphql-tag';
import { FREEFOOD_SETUP_FRAGMENT } from './freefoodSetup';
import { MICROSTORE_COLUMN_GROUP_SETUP_FRAGMENT } from './microstoreColumnGroupSetup';

export const SITE_SETUP_FRAGMENT = gql`
    fragment SiteSetupFragment on SiteSetup {
        _id
        createdAt
        date
        freefoodSetup {
            ...FreefoodSetupFragment
        }
        isFinishForced
        issues {
            productsIssues {
                comment
                productId
                brokenDuringDeliveryQuantity
                brokenOnSiteAfterInventoryQuantity
                brokenOnSiteBeforeInventoryQuantity
                expiredDeliveredQuantity
                expiredOnSiteAfterInventoryQuantity
                expiredOnSiteBeforeInventoryQuantity
                notEnoughPlaceQuantity
                otherBeforeInventoryQuantity
                otherAfterInventoryOrDuringDeliveryQuantity
                unexpectedOnSiteQuantity
                wrongQuantityDelivered
            }
        }
        microstoreColumnGroupSetups {
            ...MicrostoreColumnGroupSetupFragment
        }
        siteId
        state
        stateHistory {
            createdAt
            state
        }
        updatedAt
    }
    ${FREEFOOD_SETUP_FRAGMENT}
    ${MICROSTORE_COLUMN_GROUP_SETUP_FRAGMENT}
`;

import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import styled from 'styled-components';

import { PAGES } from '../../constants/pages';

import { GET_PRODUCT_ARRANGEMENTS_QUERY } from 'data/queries/productArrangement';
import { GET_PRODUCT_ARRANGEMENTS } from 'data/queries/__generated__/GET_PRODUCT_ARRANGEMENTS';

import { Header, HeaderTitle } from 'components/Header';
import { FieldToDisplay, ListView } from 'components/ListView';
import { Loader } from 'components/Loader';
import { PageTitle } from 'components/PageTitle';
import { TotemInput } from 'components/TotemInput';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { CreateProductArrangementPopup } from './Popups/CreateProductArrangementPopup';
import { ArchiveProductArrangementsPopup } from './Popups/ArchiveProductArrangementsPopup';

type FormattedProductArrangementType = {
    _id: string;
    name: string;
    numberOfProducts: number;
};

export const ProductArrangements = () => {
    const [selectedProductArrangementIds, setSelectedProductArrangementIds] = useState<string[]>([]);

    const [isCreateProductArrangementPopupOpen, setIsCreateProductArrangementPopupOpen] = useState<boolean>(false);
    const [isArchiveProductArrangementsPopupOpen, setIsArchiveProductArrangementsPopupOpen] = useState<boolean>(false);

    const [filterString, setFilterString] = useState('');

    const {
        loading: productArrangementsLoading,
        data: productArrangementsData,
        error: productArrangementsError,
    } = useQuery<GET_PRODUCT_ARRANGEMENTS>(GET_PRODUCT_ARRANGEMENTS_QUERY);

    if (productArrangementsLoading && !productArrangementsData) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (productArrangementsError || !productArrangementsData) {
        throw new Error('Une erreur est survenue lors de la récupération des gammes de produits');
    }

    const { productArrangementsQuery: productArrangements } = productArrangementsData;

    const PRODUCT_ARRANGEMENT_FIELDS_TO_DISPLAY: FieldToDisplay<FormattedProductArrangementType>[] = [
        {
            fieldName: 'name',
            label: 'Nom',
        },
        {
            fieldName: 'numberOfProducts',
            label: 'Nombre de produits',
        },
    ];

    const filteredProductArrangements = productArrangements.filter(
        ({ name }) => name.toLowerCase().search(filterString.toLowerCase()) !== -1,
    );

    const sortedProductArrangements = [...filteredProductArrangements].sort(
        (productArrangementA, productArrangementB) => productArrangementA.name.localeCompare(productArrangementB.name),
    );

    const formattedProductArrangements: FormattedProductArrangementType[] = sortedProductArrangements.map(
        ({ _id, name, productIdsWithStockMax }) => {
            return {
                _id,
                name,
                numberOfProducts: productIdsWithStockMax.length,
            };
        },
    );

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.productArrangements} />
                </HeaderTitle>
                <ActionContainer>
                    {selectedProductArrangementIds.length ? (
                        <TotemPrimaryButton
                            onClick={() => {
                                setIsArchiveProductArrangementsPopupOpen(true);
                            }}
                        >
                            Archiver{' '}
                            {selectedProductArrangementIds.length > 1
                                ? 'ces gammes de produit'
                                : 'cette gamme de produit'}
                        </TotemPrimaryButton>
                    ) : null}
                    <TotemPrimaryButton
                        onClick={() => {
                            setIsCreateProductArrangementPopupOpen(true);
                        }}
                    >
                        Créer une gamme de produit
                    </TotemPrimaryButton>
                </ActionContainer>
            </Header>
            <Content>
                <TotemInput
                    label="Recherche"
                    onChange={setFilterString}
                    placeholder="Nom de la gamme de produits"
                    value={filterString}
                    autoFocus={true}
                />
                <ListView<FormattedProductArrangementType>
                    fieldsToDisplay={PRODUCT_ARRANGEMENT_FIELDS_TO_DISPLAY}
                    items={formattedProductArrangements}
                    keyExtractor={(item) => item._id}
                    linkBasePath="/productArrangement/"
                    selectedKeys={selectedProductArrangementIds}
                    setSelectedKeys={setSelectedProductArrangementIds}
                />
            </Content>
            <CreateProductArrangementPopup
                isOpen={isCreateProductArrangementPopupOpen}
                setIsOpen={setIsCreateProductArrangementPopupOpen}
            />
            <ArchiveProductArrangementsPopup
                productArrangements={productArrangements}
                selectedProductArrangementIds={selectedProductArrangementIds}
                setSelectedProductArrangementIds={setSelectedProductArrangementIds}
                isOpen={isArchiveProductArrangementsPopupOpen}
                setIsOpen={setIsArchiveProductArrangementsPopupOpen}
            />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    flex: 1;
    overflow: hidden;
`;

const ActionContainer = styled.div`
    display: flex;

    & > :not(:first-child) {
        margin-left: 10px;
    }
`;

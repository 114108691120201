import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { TOTEM_CLOSED_DAY_TYPE_LABELS } from '../constants/types';

import { Loader, LoaderModeType } from 'components/Loader';
import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { TotemSelect } from 'components/TotemSelect';
import { TotemDatePicker } from 'components/TotemDatePicker';

import {
    CREATE_TOTEM_CLOSED_DAY,
    CREATE_TOTEM_CLOSED_DAYVariables,
} from 'data/mutations/__generated__/CREATE_TOTEM_CLOSED_DAY';
import { TotemClosedDayType } from 'data/__generated__';
import { CREATE_TOTEM_CLOSED_DAY_MUTATION } from 'data/mutations/totemClosedDay';

import { formatDateAsAnniversary } from 'helpers/dateTimes';
import { totemClosedDayCreatedHandler } from '../cacheHandlers/totemClosedDayCacheHandler';

const TYPE_OPTIONS = Object.values(TotemClosedDayType).map((type: TotemClosedDayType) => ({
    label: TOTEM_CLOSED_DAY_TYPE_LABELS[type],
    value: type,
}));

export function CreateTotemClosedDayPopup({
    isOpen,
    setIsOpen,
}: {
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
}) {
    const [type, setType] = useState<TotemClosedDayType>(TotemClosedDayType.BankHoliday);
    const [date, setDate] = useState<Date | null>(null);

    const [createTotemClosedDay, { loading }] = useMutation<CREATE_TOTEM_CLOSED_DAY, CREATE_TOTEM_CLOSED_DAYVariables>(
        CREATE_TOTEM_CLOSED_DAY_MUTATION,
    );

    async function handleSubmit() {
        if (!date || !type) {
            return;
        }

        const { data } = await createTotemClosedDay({
            variables: {
                createTotemClosedDayInput: {
                    date: formatDateAsAnniversary({ dateTime: date, useNewFormat: true }),
                    type,
                },
            },
        });

        if (!data) {
            throw new Error('Une erreur est survenue lors de la création du jour de fermeture');
        }

        const { createTotemClosedDay: newTotemClosedDay } = data;

        if (newTotemClosedDay) {
            totemClosedDayCreatedHandler(newTotemClosedDay);
            toast.success('Jour de fermeture créé !');
            setIsOpen(false);
        } else {
            toast.error('Une erreur est survenue');
        }
    }

    return (
        <TotemPopup title="Créer un jour de fermeture" isOpen={isOpen} setIsOpen={setIsOpen} contentOverflow="visible">
            <WarningMessage>
                Si le jour de fermeture est un jour férié, alors celui-ci sera automatiquement ajouté comme date de
                fermeture à chacun des sites.
                <br />
                Tout autre type de jour de fermeture n'est pour le moment pas rajouté automatiquement aux sites.
                <br />
                <br />
                Attention, supprimer un jour férié des jours de fermetures Totem ne le retire pas automatiquement des
                sites. Il faut le faire à la main ou faire un tech support.
            </WarningMessage>
            <SelectorContainer>
                <TotemSelect
                    label="Type de fermeture"
                    placeholder="Sélectionner un type"
                    value={TYPE_OPTIONS.find(({ value }) => value === type)}
                    options={TYPE_OPTIONS}
                    onChange={(selectedOption) => {
                        if (selectedOption) {
                            setType(selectedOption.value);
                        }
                    }}
                />
            </SelectorContainer>
            <SelectorContainer>
                <TotemDatePicker
                    label="Date"
                    selected={date}
                    onChange={(date) => {
                        setDate(date as Date);
                    }}
                />
            </SelectorContainer>

            <SubmitContainer>
                <TotemPrimaryButton onClick={handleSubmit} disabled={!date || !type}>
                    {loading ? <Loader size="20px" mode={LoaderModeType.Spin} /> : 'Créer le jour de fermeture'}
                </TotemPrimaryButton>
            </SubmitContainer>
        </TotemPopup>
    );
}

const SelectorContainer = styled.div`
    margin: 10px 0px;
`;

const SubmitContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 15px;
`;

const WarningMessage = styled.div`
    color: ${({ theme }) => theme.warningColor};
`;

import { OrderFrequency } from 'data/__generated__';

// order types
export const RECURRING = 'Récurrente';
export const PUNCTUAL = 'Ponctuelle';

// previous orderIds
export const FIRST_ORDER = 'firstOrder';
export const PUNCTUAL_ORDER = 'punctualOrder';

// bad service labels
export function getServiceLabel(service: string) {
    switch (service) {
        case 'delivery':
            return 'Livraison';
        case 'equipments':
            return 'Équipements';
        case 'products':
            return 'Qualité des produits';
        case 'quantity':
            return 'Quantité';
        case 'productsMissing':
            return 'Produits manquants';
        case 'webapp':
            return 'Espace client';
        default:
            return '';
    }
}

export function getFrequencyLabel(frequency: OrderFrequency) {
    switch (frequency) {
        case 'Weekly':
            return 'Hebdomadaire';
        case 'BiWeekly':
            return 'Bi-hebdomadaire';
        case 'Monthly':
            return 'Mensuelle';
        case 'Quarterly':
            return 'Trimestrielle';
        case 'BiAnnually':
            return 'Semestrielle';
        default:
            return '';
    }
}

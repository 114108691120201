import React from 'react';

import { AiFillStar } from 'react-icons/ai';
import styled from 'styled-components';

export const Rating = ({ rating, numberOfRatings }: { rating: number; numberOfRatings: number }) => {
    const formattedRating = rating.toString().replace('.', ',');

    return (
        <Container>
            <AiFillStar size={19} />
            <RatingLabel>{numberOfRatings ? `${formattedRating} (${numberOfRatings} notes)` : '-'}</RatingLabel>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${({ theme }) => theme.textColor};
`;

const RatingLabel = styled.span`
    margin-left: 5px;
`;

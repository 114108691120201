import React from 'react';
import Switch from 'react-switch';
import styled from 'styled-components';

import { deliveryWindowOptions } from 'pages/Sites/constants/deliveryHours';
import { FRENCH_DAYS } from 'constants/dateAndTime';
import { colors } from 'constants/colors';

import { SiteFragmentFragment } from 'data/__generated__';

import { ColumnsSectionContainer, SectionColumn, SectionContainer } from 'components/DetailsView/Section';
import { ClosingDates } from 'pages/Sites/components/ClosingDates';
import { TotemInput } from 'components/TotemInput';
import { TotemSelect } from 'components/TotemSelect';
import { TotemLabel } from 'components/TotemLabel';

export const DeliveryInfoSection = ({
    siteDetails,
    setUpdatedSiteDetails,
}: {
    siteDetails: SiteFragmentFragment;
    setUpdatedSiteDetails: (site: SiteFragmentFragment) => void;
}) => {
    const { accessTime, closingDatesRanges, deliveryDays, deliveryTimeWindow } = siteDetails;

    const selectedStartHourOption = deliveryWindowOptions.find((option) => option.value === deliveryTimeWindow.start);
    const selectedStopHourOption = deliveryWindowOptions.find((option) => option.value === deliveryTimeWindow.stop);

    const updateDeliveryDay = (value: boolean, index: number) => {
        const updatedDeliveryDays = [...deliveryDays];
        updatedDeliveryDays[index] = value;

        setUpdatedSiteDetails({ ...siteDetails, deliveryDays: updatedDeliveryDays });
    };

    return (
        <SectionContainer title="Informations de livraison" isInitiallyOpen>
            <ColumnsSectionContainer numberOfColumns={2}>
                <SectionColumn>
                    <TotemLabel>Jours de livraison</TotemLabel>
                    <List>
                        <tbody>
                            {FRENCH_DAYS.map((day, index) => (
                                <tr key={index}>
                                    <Cell isBold>{day}</Cell>
                                    <Cell>
                                        <Switch
                                            onChange={(value) => updateDeliveryDay(value, index)}
                                            checked={deliveryDays[index]}
                                            onColor={colors.green}
                                            offColor={colors.pantinGrey}
                                        />
                                    </Cell>
                                </tr>
                            ))}
                        </tbody>
                    </List>
                </SectionColumn>
                <SectionColumn>
                    <TotemSelect
                        label="Début de la fenêtre de livraison"
                        placeholder="Sélectionner un horaire"
                        value={selectedStartHourOption}
                        options={deliveryWindowOptions}
                        onChange={(option) => {
                            if (option) {
                                setUpdatedSiteDetails({
                                    ...siteDetails,
                                    deliveryTimeWindow: {
                                        ...siteDetails.deliveryTimeWindow,
                                        start: option.value,
                                    },
                                });
                            }
                        }}
                        dataTest="site-details_window-start"
                    />
                    <TotemSelect
                        label="Fin de la fenêtre de livraison"
                        placeholder="Sélectionner un horaire"
                        value={selectedStopHourOption}
                        options={deliveryWindowOptions}
                        onChange={(option) => {
                            if (option) {
                                setUpdatedSiteDetails({
                                    ...siteDetails,
                                    deliveryTimeWindow: {
                                        ...siteDetails.deliveryTimeWindow,
                                        stop: option.value,
                                    },
                                });
                            }
                        }}
                        dataTest="site-details_window-stop"
                    />
                    <TotemInput
                        label="Temps d'accès au site"
                        value={accessTime}
                        placeholder="Ex : 5"
                        type="number"
                        step="1"
                        onChange={(value: string) => {
                            setUpdatedSiteDetails({
                                ...siteDetails,
                                accessTime: Number(value),
                            });
                        }}
                        data-test="site-details_access-time"
                    />
                    <ClosingDates
                        closingDatesRanges={closingDatesRanges}
                        setClosingDatesRanges={(closingDatesRanges: SiteFragmentFragment['closingDatesRanges']) => {
                            setUpdatedSiteDetails({
                                ...siteDetails,
                                closingDatesRanges,
                            });
                        }}
                    />
                </SectionColumn>
            </ColumnsSectionContainer>
        </SectionContainer>
    );
};

const List = styled.table`
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
    min-width: 200px;

    border-spacing: 0;
    border-collapse: collapse;
`;

const Cell = styled.td`
    text-align: left;
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
    border-spacing: 1px;
    padding-left: 10px;
    font-weight: ${({ isBold }: { isBold?: boolean }) => (isBold ? 800 : 500)};
    color: ${({ theme }) => theme.textColor};
`;

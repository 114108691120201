import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';

import { SPLIT_STOCK_VARIATION_MUTATION } from 'data/mutations/stockVariation';
import {
    SPLIT_STOCK_VARIATION,
    SPLIT_STOCK_VARIATIONVariables,
} from 'data/mutations/__generated__/SPLIT_STOCK_VARIATION';
import { StockVariationType } from 'data/__generated__';
import { GET_SITE_PRODUCT_WITH_STOCKS_getProductWithVariations_variations } from 'data/queries/__generated__/GET_SITE_PRODUCT_WITH_STOCKS';

import { TotemPopup } from 'components/TotemPopup';
import { BasicInput } from 'components/BasicInput';
import { TotemLabel } from 'components/TotemLabel';
import { getExtendedFormattedDatetime } from 'helpers/dateTimes';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

export const StockVariationSplitPopup = ({
    selectedStockVariation,
    setSelectedStockVariation,
}: {
    selectedStockVariation: GET_SITE_PRODUCT_WITH_STOCKS_getProductWithVariations_variations;
    setSelectedStockVariation: (param: null) => void;
}) => {
    const [newDelta, setNewDelta] = useState(0);

    const [splitStockVariation] = useMutation<SPLIT_STOCK_VARIATION, SPLIT_STOCK_VARIATIONVariables>(
        SPLIT_STOCK_VARIATION_MUTATION,
    );

    const handleStockVariationSplit = async () => {
        if (newDelta === 0 || !selectedStockVariation) {
            return;
        }

        const { data } = await splitStockVariation({
            variables: {
                stockVariationId: selectedStockVariation._id,
                newStockVariationInput: {
                    delta: -newDelta,
                    type: StockVariationType.SupplierRefund,
                    explanation: `Créé le ${getExtendedFormattedDatetime(Date.now())}`,
                },
            },
        });

        if (!data || data.splitStockVariation.error) {
            toast.error(
                `Une erreur est survenue${data?.splitStockVariation.error ? `: ${data.splitStockVariation.error}` : ''}`,
            );
        } else {
            toast.success('Les variations de stock ont été modifiées');
        }

        setSelectedStockVariation(null);
    };

    return (
        <TotemPopup
            isOpen={!!selectedStockVariation}
            setIsOpen={() => setSelectedStockVariation(null)}
            title="Repartition de DC"
        >
            <TotemLabel>DC Total: {Math.abs(selectedStockVariation.delta)}</TotemLabel>
            <InputContainer>
                <Column>
                    <TotemLabel>Sans explication</TotemLabel>
                    <NumericInput disabled value={Math.abs(selectedStockVariation.delta) - newDelta} />
                </Column>
                <ButtonsContainer>
                    <QuantityButton onClick={() => setNewDelta(newDelta - 1)} disabled={newDelta === 0}>
                        -
                    </QuantityButton>
                    <QuantityButton
                        onClick={() => setNewDelta(newDelta + 1)}
                        disabled={newDelta >= Math.abs(selectedStockVariation.delta)}
                    >
                        +
                    </QuantityButton>
                </ButtonsContainer>
                <Column>
                    <TotemLabel>Retards d'achat</TotemLabel>
                    <NumericInput disabled value={newDelta} />
                </Column>
            </InputContainer>
            <ButtonsContainer>
                <TotemPrimaryButton onClick={handleStockVariationSplit} disabled={newDelta === 0}>
                    Repartir les quantités
                </TotemPrimaryButton>
            </ButtonsContainer>
        </TotemPopup>
    );
};

const NumericInput = styled(BasicInput)`
    max-width: 75px;
    text-align: center;
    margin-top: 5px;
`;

const InputContainer = styled.div`
    display: flex;
    margin-top: 15px;
    align-items: flex-end;
    justify-content: space-between;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ButtonsContainer = styled.div`
    margin-top: 10px;
    display: flex;
    justify-content: center;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const QuantityButton = styled(TotemPrimaryButton)`
    padding: 10px 15px;
`;

import React, { useState } from 'react';

import styled from 'styled-components';

import { RitualState, useGetRitualsQuery } from 'data/__generated__';

import { DAYS_OF_THE_WEEK } from 'constants/dateAndTime';

import { FieldToDisplay, ListView } from 'components/ListView';
import { Loader } from 'components/Loader';
import { TotemImage } from 'components/TotemImage';
import { StatusTag } from './components/StatusTag';
import { TotemInput } from 'components/TotemInput';

type FormattedRitualType = {
    _id: string;
    image: JSX.Element;
    name: string;
    recurrence: string;
    state: JSX.Element;
    ritualState: RitualState;
    timeWindow: string;
};

const defaultFilterState = [RitualState.Regular, RitualState.Created];

export const RitualsList = () => {
    const [filterString, setFilterString] = useState('');
    const [filterState, setFilterState] = useState(defaultFilterState);

    const {
        loading: ritualsLoading,
        data: ritualsData,
        error: ritualsError,
    } = useGetRitualsQuery({ fetchPolicy: 'cache-and-network' });

    function updateStateFilter(stateToUpdate: RitualState) {
        if (filterState?.includes(stateToUpdate)) {
            setFilterState(filterState.filter((state) => state !== stateToUpdate));
        } else {
            setFilterState([...filterState, stateToUpdate]);
        }
    }

    if (ritualsLoading && !ritualsData) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (ritualsError || !ritualsData) {
        throw new Error('Une erreur est survenue lors de la récupération des rituels');
    }

    const { rituals } = ritualsData;

    const RITUAL_FIELDS_TO_DISPLAY: FieldToDisplay<FormattedRitualType>[] = [
        { fieldName: 'image', label: 'Photo' },
        {
            fieldName: 'name',
            label: 'Nom',
        },
        {
            fieldName: 'timeWindow',
            label: 'Horaires',
        },
        {
            fieldName: 'recurrence',
            label: 'Récurrence',
        },
        {
            fieldName: 'state',
            label: 'Statut',
        },
    ];

    const formattedRituals: FormattedRitualType[] = rituals.map(
        ({ _id, activeDate, activeWeekdays, imageUrl, name, state, timeWindow }) => {
            return {
                _id,
                image: <TotemImage src={imageUrl} alt={name} size={100} />,
                name,
                ritualState: state,
                state: <StatusTag state={state} />,
                recurrence:
                    activeDate ||
                    activeWeekdays
                        ?.slice(0, 5)
                        .map((day, index) => (day ? DAYS_OF_THE_WEEK[index].label[0] : '-'))
                        .join(' | ') ||
                    '',
                timeWindow: `${timeWindow.start} - ${timeWindow.end}`,
            };
        },
    );

    const filteredRituals = formattedRituals.filter(({ name, ritualState }) => {
        if (filterString) {
            return name.toLowerCase().includes(filterString.toLowerCase()) && filterState.includes(ritualState);
        }
        return filterState.includes(ritualState);
    });

    return (
        <>
            <TotemInput label="Recherche" onChange={setFilterString} placeholder="Nom du rituel" value={filterString} />
            <Filters>
                <Title>Filtres</Title>
                {(Object.keys(RitualState) as RitualState[]).map((state) => (
                    <FilterContainer
                        key={state}
                        isSelected={filterState.includes(state)}
                        onClick={() => updateStateFilter(state)}
                    >
                        <StatusTag state={state} />
                    </FilterContainer>
                ))}
            </Filters>
            <ListView<FormattedRitualType>
                fieldsToDisplay={RITUAL_FIELDS_TO_DISPLAY}
                items={filteredRituals}
                keyExtractor={(item) => item._id}
                linkBasePath="/ritual/"
            />
        </>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const Title = styled.h3`
    margin: 0;
    font-size: 20px;
    font-weight: 800;
`;

const Filters = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const FilterContainer = styled.div<{ isSelected: boolean }>`
    cursor: pointer;
    border-radius: 20px;
    border: 2px solid ${({ isSelected, theme }) => (isSelected ? theme.darkBorderColor : 'transparent')};
`;

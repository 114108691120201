import React, { useEffect } from 'react';

import { useQuery } from '@apollo/client';
import type { FileError } from 'react-dropzone';
import { useFormContext, useWatch } from 'react-hook-form';
import styled from 'styled-components';

import { ritualWindowOptions } from '../constants/ritualHours';
import { DAYS_OF_THE_WEEK } from 'constants/dateAndTime';

import {
    GetDetailedRitualQuery,
    RitualRepetition,
    SiteState,
    SiteType,
    TagState,
    useGetSupplierPartnershipsQuery,
} from 'data/__generated__';
import { GET_ACTIVE_ORGANIZATIONS } from 'data/queries/__generated__/GET_ACTIVE_ORGANIZATIONS';
import { GET_ACTIVE_ORGANIZATIONS_QUERY } from 'data/queries/organization';
import { GET_ACTIVE_CATEGORIES } from 'data/queries/__generated__/GET_ACTIVE_CATEGORIES';
import { GET_ACTIVE_CATEGORIES_QUERY } from 'data/queries/category';
import { GET_BADGES } from 'data/queries/__generated__/GET_BADGES';
import { GET_BADGES_QUERY } from 'data/queries/badge';
import { GET_MESSAGES_SETS } from 'data/queries/__generated__/GET_MESSAGES_SETS';
import { GET_MESSAGES_SETS_QUERY } from 'data/queries/messagesSet';
import { GET_SITES } from 'data/queries/__generated__/GET_SITES';
import { GET_SITES_QUERY } from 'data/queries/site';
import { GET_TAGS } from 'data/queries/__generated__/GET_TAGS';
import { GET_TAGS_QUERY } from 'data/queries/tag';

import {
    DetailFormCheckbox,
    DetailFormDatePicker,
    DetailFormImage,
    DetailFormSelect,
    DetailFormTextArea,
    DetailFormValue,
} from 'components/DetailsView';
import { FileWithPreview } from 'components/FileDragAndDrop';
import { Loader } from 'components/Loader';
import { TotemLabel } from 'components/TotemLabel';
import { CopyValue } from 'components/CopyValue';

import { dateFromString, formatDateAsAnniversary } from 'helpers/dateTimes';
import { ColumnsSectionContainer, SectionColumn } from 'components/DetailsView/Section';
import { GET_AVAILABLE_FOR_MICROSTORE_QUERY } from 'data/queries/product';
import { GET_AVAILABLE_FOR_MICROSTORE } from 'data/queries/__generated__/GET_AVAILABLE_FOR_MICROSTORE';
import { GET_SUBCATEGORIES_QUERY } from 'data/queries/subcategory';
import { GetSubcategories } from 'data/queries/__generated__/GetSubcategories';

const defaultActiveWeekdays = [true, true, true, true, true, false, false];

const defaultRitual = {
    _id: null,
    activeDate: null,
    activeWeekdays: defaultActiveWeekdays,
    associatedDiscount: null,
    expiringProductsOnly: false,
    imageUrl: null,
    messagesSetId: null,
    name: null,
    repetition: RitualRepetition.Weekly,
    requiresParticipation: false,
    restrictedBadgeIds: [],
    restrictedCategoryIds: [],
    restrictedOrganizationIds: [],
    restrictedProductIds: [],
    restrictedSiteIds: [],
    restrictedSubcategoryIds: [],
    restrictedTagIds: [],
    restrictedUserIds: [],
    timeWindow: { start: '10:00', end: '12:00' },
    supplierPartnershipId: null,
};

export const RitualFormContent = ({
    ritual,
    chosenTemplate,
}: {
    ritual?: GetDetailedRitualQuery['detailedRitual'] | null;
    chosenTemplate?: { name: string; imageUrl: string } | null;
}) => {
    const {
        _id,
        activeDate,
        activeWeekdays,
        associatedDiscount,
        expiringProductsOnly,
        imageUrl,
        messagesSetId,
        name,
        repetition,
        requiresParticipation,
        restrictedBadgeIds,
        restrictedCategoryIds,
        restrictedOrganizationIds,
        restrictedProductIds,
        restrictedSiteIds,
        restrictedSubcategoryIds,
        restrictedTagIds,
        restrictedUserIds,
        timeWindow,
        supplierPartnershipId,
    } = ritual ?? defaultRitual;

    async function fileValidationFunction(file: FileWithPreview) {
        return new Promise<FileError[]>((resolve, reject) => {
            const image = new Image();
            image.onload = function () {
                const widthErrors =
                    image.width >= 600 && image.height >= 400
                        ? []
                        : [
                              {
                                  message: `L'image doit avoir une dimension d'au moins 600x400px, votre image fait ${image.width}x${image.height}px`,
                                  code: 'file-invalid-dimension',
                              },
                          ];
                const fileErrors = [...widthErrors];
                resolve(fileErrors);
            };
            image.onerror = reject;
            image.src = file.preview;
        });
    }

    const { control, register, setValue } = useFormContext();

    useEffect(() => {
        if (chosenTemplate) {
            setValue('name', chosenTemplate.name, { shouldDirty: true });
        }
    }, [chosenTemplate, setValue]);

    const repetitionWatch = useWatch({
        control,
        name: 'repetition',
        defaultValue: repetition,
    });

    const {
        loading: organizationsLoading,
        data: organizationsData,
        error: organizationsError,
    } = useQuery<GET_ACTIVE_ORGANIZATIONS>(GET_ACTIVE_ORGANIZATIONS_QUERY);

    const {
        loading: activeCategoriesLoading,
        data: activeCategoriesData,
        error: activeCategoriesError,
    } = useQuery<GET_ACTIVE_CATEGORIES>(GET_ACTIVE_CATEGORIES_QUERY);

    const {
        loading: activeSubcategoriesLoading,
        data: activeSubcategoriesData,
        error: activeSubcategoriesError,
    } = useQuery<GetSubcategories>(GET_SUBCATEGORIES_QUERY);

    const {
        loading: productsLoading,
        data: productsData,
        error: productsError,
    } = useQuery<GET_AVAILABLE_FOR_MICROSTORE>(GET_AVAILABLE_FOR_MICROSTORE_QUERY);

    const { loading: badgesLoading, data: badgesData, error: badgesError } = useQuery<GET_BADGES>(GET_BADGES_QUERY);

    const {
        loading: messagesSetsLoading,
        data: messagesSetsData,
        error: messagesSetsError,
    } = useQuery<GET_MESSAGES_SETS>(GET_MESSAGES_SETS_QUERY);

    const { loading: tagsLoading, data: tagsData, error: tagsError } = useQuery<GET_TAGS>(GET_TAGS_QUERY);

    const { loading: sitesLoading, data: sitesData, error: sitesError } = useQuery<GET_SITES>(GET_SITES_QUERY);

    const {
        loading: supplierPartnershipsLoading,
        data: supplierPartnershipsData,
        error: supplierPartnershipsError,
    } = useGetSupplierPartnershipsQuery();

    if (
        organizationsLoading ||
        sitesLoading ||
        productsLoading ||
        activeCategoriesLoading ||
        activeSubcategoriesLoading ||
        badgesLoading ||
        tagsLoading ||
        messagesSetsLoading ||
        supplierPartnershipsLoading
    ) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (productsError || !productsData) {
        throw new Error('Une erreur est survenue lors de la récupération des produits');
    }

    if (activeCategoriesError || !activeCategoriesData) {
        throw new Error('Une erreur est survenue lors de la récupération des catégories actives');
    }

    if (activeSubcategoriesError || !activeSubcategoriesData) {
        throw new Error('Une erreur est survenue lors de la récupération des sous-catégories actives');
    }

    if (badgesError || !badgesData) {
        throw new Error('Une erreur est survenue lors de la récupération des badges');
    }

    if (messagesSetsError || !messagesSetsData) {
        throw new Error('Une erreur est survenue lors de la récupération des séries de messages');
    }

    if (tagsError || !tagsData) {
        throw new Error('Une erreur est survenue lors de la récupération des tags');
    }

    if (organizationsError || !organizationsData) {
        throw new Error('Une erreur est survenue lors de la récupération des organisations');
    }

    if (sitesError || !sitesData) {
        throw new Error('Une erreur est survenue lors de la récupération des sites');
    }

    if (supplierPartnershipsError || !supplierPartnershipsData) {
        throw new Error('Une erreur est survenue lors de la récupération des partenariats fournisseurs');
    }

    const { activeOrganizations: organizations } = organizationsData;
    const { sites } = sitesData;
    const { availableForMicrostore: products } = productsData;
    const { activeCategories } = activeCategoriesData;
    const { subcategories } = activeSubcategoriesData;
    const { badges } = badgesData;
    const { messagesSets } = messagesSetsData;
    const { tags } = tagsData;
    const { supplierPartnerships } = supplierPartnershipsData;

    const organizationOptions = organizations.map(({ _id, name }) => ({
        label: name,
        value: _id,
    }));

    const siteOptions = sites
        .filter((site) => site.state !== SiteState.Archived && site.type === SiteType.Microstore)
        .map(({ _id, name }) => ({
            label: name,
            value: _id,
        }));

    const repetitionOptions = [
        { label: 'Ponctuel', value: RitualRepetition.Punctual },
        { label: 'Hebdomadaire', value: RitualRepetition.Weekly },
    ];

    const productOptions = products.map((product) => {
        return { value: product?._id, label: product?.fullname };
    });

    const categoryOptions = activeCategories.map((category) => {
        return { value: category?._id, label: category?.title };
    });

    const subcategoryOptions = subcategories.map((subcategory) => {
        return { value: subcategory._id, label: subcategory.name };
    });

    const badgeOptions = badges.map((badge) => {
        return { value: badge?._id, label: badge?.name };
    });

    const tagOptions = tags
        // TODO: only fetch active tags
        .filter((tag) => tag.state === TagState.Regular)
        .map(({ _id, name }) => {
            return { value: _id, label: name };
        });

    const messagesSetsOptions = messagesSets.map((messagesSet) => ({
        label: messagesSet.name,
        value: messagesSet._id,
    }));

    const supplierPartnershipsOptions = supplierPartnerships.map((supplierPartnership) => ({
        label: supplierPartnership.name,
        value: supplierPartnership._id,
    }));

    const today = new Date();

    return (
        <Container>
            <ColumnsSectionContainer numberOfColumns={2}>
                <SectionColumn>
                    <Row>
                        <DetailFormImage
                            name="image"
                            label="Image"
                            defaultValue={imageUrl || chosenTemplate?.imageUrl}
                            fileValidationFunction={fileValidationFunction}
                            size={250}
                            required={!imageUrl && !chosenTemplate?.imageUrl}
                        />
                        <Column>
                            <DetailFormValue
                                label="Nom"
                                defaultValue={name}
                                {...register('name', { required: true })}
                            />
                            <DetailFormValue
                                label="Réduction associée"
                                sublabel="En €. Laissez ce champ vide si le rituel n'est pas lié à une réduction."
                                placeholder="Réduction en €"
                                defaultValue={associatedDiscount}
                                type="number"
                                width="100%"
                                step=".01"
                                {...register('associatedDiscount', {
                                    min: 0.01,
                                    valueAsNumber: true,
                                })}
                            />
                            <DetailFormCheckbox
                                label="Ce rituel requiert une participation pour accéder au crédit"
                                name="requiresParticipation"
                                defaultChecked={requiresParticipation}
                            />
                        </Column>
                    </Row>
                </SectionColumn>
                <SectionColumn>
                    {_id ? <CopyValue value={_id} /> : null}
                    <DetailFormSelect<string>
                        label="Récurrence"
                        placeholder="Sélectionnez un mode de récurrence"
                        defaultValue={repetition}
                        name="repetition"
                        width="100%"
                        options={repetitionOptions}
                    />
                    <div>
                        {repetitionWatch === RitualRepetition.Weekly ? (
                            (activeWeekdays || defaultActiveWeekdays).map((weekday, index) => (
                                <>
                                    <TotemLabel>{DAYS_OF_THE_WEEK.map((day) => day.label)[index][0]}</TotemLabel>
                                    <input
                                        type="checkbox"
                                        defaultChecked={weekday}
                                        {...register(`activeWeekdays.${index}`)}
                                    />
                                </>
                            ))
                        ) : (
                            <DetailFormDatePicker
                                label="Date d'activation du rituel"
                                placeholder="Choisir une date"
                                defaultValue={activeDate}
                                name="activeDate"
                                control={control}
                                transformValueToDate={(value) => (value ? dateFromString(value) : null)}
                                transformDateToValue={(date) =>
                                    date ? formatDateAsAnniversary({ dateTime: date }) : null
                                }
                                minDate={today}
                            />
                        )}
                    </div>
                </SectionColumn>
            </ColumnsSectionContainer>
            <ColumnsSectionContainer numberOfColumns={3}>
                <SectionColumn>
                    <DetailFormSelect<string>
                        label="Début de l'horaire du rituel"
                        placeholder="Sélectionnez une heure"
                        defaultValue={timeWindow.start}
                        name="timeWindow.start"
                        options={ritualWindowOptions}
                    />
                    <DetailFormSelect<string>
                        label="Fin de l'horaire du rituel"
                        placeholder="Sélectionnez une heure"
                        defaultValue={timeWindow.end}
                        name="timeWindow.end"
                        options={ritualWindowOptions}
                    />
                    <DetailFormSelect<string>
                        label="Série de messages"
                        isClearable
                        placeholder="Sélectionnez une série de messages"
                        name="messagesSetId"
                        defaultValue={messagesSetId ?? undefined}
                        options={messagesSetsOptions}
                    />
                </SectionColumn>
                <SectionColumn>
                    <DetailFormSelect<string>
                        label="Produits"
                        placeholder="Sélectionnez un ou plusieurs produit(s)"
                        defaultValue={restrictedProductIds}
                        isMulti
                        isClearable
                        name="restrictedProductIds"
                        options={productOptions}
                    />
                    <DetailFormSelect<string>
                        label="Catégories"
                        placeholder="Sélectionnez une ou plusieurs catégories(s)"
                        defaultValue={restrictedCategoryIds}
                        isMulti
                        isClearable
                        name="restrictedCategoryIds"
                        options={categoryOptions}
                    />
                    <DetailFormSelect<string>
                        label="Sous-catégories"
                        placeholder="Sélectionnez une ou plusieurs sous-catégories(s)"
                        defaultValue={restrictedSubcategoryIds}
                        isMulti
                        isClearable
                        name="restrictedSubcategoryIds"
                        options={subcategoryOptions}
                    />
                    <DetailFormSelect<string>
                        label="Badges"
                        placeholder="Sélectionnez un ou plusieurs badge(s)"
                        defaultValue={restrictedBadgeIds}
                        isMulti
                        isClearable
                        name="restrictedBadgeIds"
                        options={badgeOptions}
                    />
                    <DetailFormSelect<string>
                        label="Tags"
                        placeholder="Sélectionnez un ou plusieurs tag(s)"
                        defaultValue={restrictedTagIds}
                        isMulti
                        isClearable
                        name="restrictedTagIds"
                        options={tagOptions}
                    />
                    <DetailFormCheckbox
                        label="Anti-gaspi"
                        sublabel="Uniquement pour les produits qui expirent le jour de rituel"
                        name="expiringProductsOnly"
                        defaultChecked={expiringProductsOnly}
                    />
                </SectionColumn>
                <SectionColumn>
                    <DetailFormSelect<string>
                        label="Site(s) concerné(s)"
                        placeholder="Sélectionnez un ou plusieurs site(s)"
                        defaultValue={restrictedSiteIds}
                        isMulti
                        isClearable
                        name="restrictedSiteIds"
                        options={siteOptions}
                    />
                    <DetailFormSelect<string>
                        label="Organisation(s) concernée(s)"
                        placeholder="Sélectionnez une ou plusieurs organisation(s)"
                        defaultValue={restrictedOrganizationIds}
                        isMulti
                        isClearable
                        name="restrictedOrganizationIds"
                        options={organizationOptions}
                    />
                    <DetailFormTextArea
                        label="Utilisateur(s) concerné(s)"
                        placeholder="Renseignez les identifiants des utilisateurs, séparés par des virgules"
                        defaultValue={restrictedUserIds.join(',')}
                        {...register('restrictedUserIds')}
                    />
                    <DetailFormSelect<string>
                        label="Trade marketing"
                        placeholder="Sélectionnez un trade marketing"
                        sublabel="Lorsque les crédits créés par tous les rituels liés atteignent le budget du trade marketing, les rituels liés seront automatiquement archivés."
                        defaultValue={supplierPartnershipId ?? undefined}
                        name="supplierPartnershipId"
                        options={supplierPartnershipsOptions}
                        isClearable
                    />
                </SectionColumn>
            </ColumnsSectionContainer>
        </Container>
    );
};

const Container = styled.div`
    margin-top: 10px;
    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;

    & > :not(:first-child) {
        margin-left: 10px;
    }
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

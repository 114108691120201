import React, { useEffect, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { FaSave, FaTrash } from 'react-icons/fa';

import { PAGES } from '../../../constants/pages';
import { colors } from 'constants/colors';

import { UPDATE_PRODUCT_GROUP, UPDATE_PRODUCT_GROUPVariables } from 'data/mutations/__generated__/UPDATE_PRODUCT_GROUP';
import { UPDATE_PRODUCT_GROUP_MUTATION } from 'data/mutations/productGroup';
import { GET_ALL_PRODUCTS } from 'data/queries/__generated__/GET_ALL_PRODUCTS';
import { GET_ALL_PRODUCTS_QUERY } from 'data/queries/product';
import { GET_PRODUCT_GROUP, GET_PRODUCT_GROUPVariables } from 'data/queries/__generated__/GET_PRODUCT_GROUP';
import { GET_PRODUCT_GROUP_QUERY } from 'data/queries/productGroup';
import { ProductGroupFragment_composition } from 'data/fragments/__generated__/ProductGroupFragment';

import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { Header, HeaderTitle } from 'components/Header';
import { Loader } from 'components/Loader';
import { SectionColumn } from 'components/DetailsView/Section';
import { PageTitle } from 'components/PageTitle';
import { DetailLink } from 'components/DetailsView/DetailLink';
import { SelectedOption, TotemSelect } from 'components/TotemSelect';
import { TotemInput } from 'components/TotemInput';

type ParamTypes = {
    productGroupId: string;
};

export const ProductGroupDetails = () => {
    const { productGroupId = '' } = useParams<ParamTypes>();

    const [productGroupComposition, setProductGroupComposition] = useState<
        Omit<ProductGroupFragment_composition, '__typename'>[]
    >([]);

    const [updateProductGroup] = useMutation<UPDATE_PRODUCT_GROUP, UPDATE_PRODUCT_GROUPVariables>(
        UPDATE_PRODUCT_GROUP_MUTATION,
    );
    const {
        loading: productGroupLoading,
        data: productGroupData,
        error: productGroupError,
    } = useQuery<GET_PRODUCT_GROUP, GET_PRODUCT_GROUPVariables>(GET_PRODUCT_GROUP_QUERY, {
        variables: { productGroupId },
    });

    const {
        loading: productsLoading,
        data: productsData,
        error: productsError,
    } = useQuery<GET_ALL_PRODUCTS>(GET_ALL_PRODUCTS_QUERY);

    useEffect(() => {
        if (productGroupData?.getProductGroup.composition) {
            setProductGroupComposition(
                productGroupData.getProductGroup.composition.map(({ productId, quantity }) => ({
                    productId,
                    quantity,
                })),
            );
        }
    }, [productGroupData, setProductGroupComposition]);

    if (productsLoading || productGroupLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (productsError || !productsData || productGroupError || !productGroupData) {
        throw new Error('Une erreur est survenue lors de la récupération des produits');
    }

    const { getAllProducts: products } = productsData;
    const { getProductGroup: productGroup } = productGroupData;

    const handleProductSelect = (option: SelectedOption<string>) => {
        if (option) {
            const product = products.find(({ _id }) => _id === option.value);
            if (product) {
                if (!productGroupComposition.some(({ productId }) => product._id === productId)) {
                    setProductGroupComposition([...productGroupComposition, { productId: product._id, quantity: 1 }]);
                }
            }
        }
    };

    const handleQuantityUpdate = ({ productId, value }: { productId: string; value: string }) => {
        // Check that the string only contains digits
        if (/^(\d+)?$/.test(value)) {
            setProductGroupComposition(
                productGroupComposition.map((compositionEntry) => {
                    if (compositionEntry.productId !== productId) {
                        return compositionEntry;
                    }
                    return {
                        productId,
                        quantity: Number(value),
                    };
                }),
            );
        }
    };

    const handleProductDelete = (productId: string) => {
        setProductGroupComposition(
            productGroupComposition.filter((compositionEntry) => compositionEntry.productId !== productId),
        );
    };

    const handleSave = async () => {
        const { data } = await updateProductGroup({
            variables: {
                productGroupId: productGroup._id,
                composition: productGroupComposition,
            },
        });
        if (!data) {
            toast.error('Une erreur inconnue est survenue lors de la modification du regroupement de produits');
            return;
        }

        toast.success('Le regroupement de produits a bien été modifié');
    };

    const productGroupProduct = products.find(({ _id }) => _id === productGroup.productId);

    const productsOptions = products.map((product) => ({
        value: product._id,
        label: product.fullname,
    }));

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.productGroupDetails} />
                </HeaderTitle>
                <CTAsContainer>
                    <TotemPrimaryButton onClick={handleSave}>
                        <FaSave size="13" />
                        <SaveLabel>Mettre à jour</SaveLabel>
                    </TotemPrimaryButton>
                </CTAsContainer>
            </Header>
            <Content>
                <ScrollableContent>
                    <SectionColumn>
                        <DetailLink
                            label={productGroupProduct?.fullname ?? 'Produit inexistant'}
                            path="product"
                            value={productGroup.productId}
                        />

                        <TotemSelect
                            label="Ajouter un produit"
                            placeholder="Sélectionner un produit (recherche par nom ou par _id)"
                            value={null}
                            options={productsOptions}
                            onChange={(option) => handleProductSelect(option)}
                        />
                        <ProductsTable>
                            <thead>
                                <tr>
                                    <ProductsTableHeaderCell>Produit</ProductsTableHeaderCell>
                                    <ProductsTableHeaderCell isDataCentered>Quantité</ProductsTableHeaderCell>

                                    <ProductsTableHeaderCell isDataCentered>Supprimer</ProductsTableHeaderCell>
                                </tr>
                            </thead>
                            <ProductsTableBody>
                                {productGroupComposition.map(({ productId, quantity }) => {
                                    const product = products.find(({ _id }) => _id === productId);

                                    return (
                                        <tr key={productId}>
                                            <ProductsTableBodyCell>
                                                <ProductNameContainer>
                                                    <DetailLink
                                                        name={product?.fullname ?? 'No product found, weird'}
                                                        value={productId}
                                                        path="product"
                                                    />
                                                </ProductNameContainer>
                                            </ProductsTableBodyCell>
                                            <ProductsTableBodyCell isDataCentered>
                                                <QuantityInputOuterContainer>
                                                    <QuantityInputInnerContainer>
                                                        <TotemInput
                                                            type="number"
                                                            value={quantity || ''}
                                                            onChange={(value) =>
                                                                handleQuantityUpdate({ productId, value })
                                                            }
                                                            centerText
                                                            data-test="product-quantity"
                                                        />
                                                    </QuantityInputInnerContainer>
                                                </QuantityInputOuterContainer>
                                            </ProductsTableBodyCell>

                                            <ProductsTableBodyCell isDataCentered>
                                                <DeleteIconContainer onClick={() => handleProductDelete(productId)}>
                                                    <FaTrash data-test="trash-icon" size={20} color={colors.red} />
                                                </DeleteIconContainer>
                                            </ProductsTableBodyCell>
                                        </tr>
                                    );
                                })}
                            </ProductsTableBody>
                        </ProductsTable>
                    </SectionColumn>
                </ScrollableContent>
            </Content>
        </Container>
    );
};

const SaveLabel = styled.span`
    margin-left: 5px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const CTAsContainer = styled.div`
    display: flex;
    align-items: center;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const Content = styled.div`
    flex: 1;
    overflow: hidden;
`;

const ScrollableContent = styled.div`
    padding: 15px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
`;

const ProductsTable = styled.table`
    border-radius: ${({ theme }) => theme.borderRadius};
    border-spacing: 0px;
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
`;

const ProductsTableBody = styled.thead`
    & > :not(:first-child) td {
        border-top: 1px solid ${({ theme }) => theme.lightBorderColor};
    }
`;

const ProductsTableHeaderCell = styled.th`
    padding: 10px;
    text-align: ${({ isDataCentered }: { isDataCentered?: boolean }) => (isDataCentered ? 'center' : 'left')};
    border-bottom: 1px solid ${({ theme }) => theme.lightBorderColor};
`;

const ProductsTableBodyCell = styled.td`
    padding: 10px;
    text-align: ${({ isDataCentered }: { isDataCentered?: boolean }) => (isDataCentered ? 'center' : 'left')};
`;

const ProductNameContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const QuantityInputOuterContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const QuantityInputInnerContainer = styled.div`
    width: 70px;
`;

const DeleteIconContainer = styled.div`
    display: inline;
    padding: 5px;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
`;

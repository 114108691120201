import React, { useContext } from 'react';
import { AiFillCloseCircle, AiFillWarning } from 'react-icons/ai';
import styled, { ThemeContext } from 'styled-components';

function getBarStyle(percent: number, theme: any) {
    const defaultStyle = {
        barColor: theme.successColor,
        containerBorderColor: theme.lightBorderColor,
        containerBorderSize: '1px',
        labelIcon: null,
    };

    if (percent < 50) {
        return {
            ...defaultStyle,
            barColor: theme.errorColor,
        };
    }
    if (percent < 75) {
        return {
            ...defaultStyle,
            barColor: theme.warningColor,
        };
    }

    if (percent > 120) {
        return {
            ...defaultStyle,
            containerBorderColor: theme.errorColor,
            containerBorderSize: '2px',
            labelIcon: <AiFillCloseCircle size={15} color={theme.errorColor} />,
        };
    }

    if (percent > 100) {
        return {
            ...defaultStyle,
            containerBorderColor: theme.warningColor,
            containerBorderSize: '2px',
            labelIcon: <AiFillWarning size={15} color={theme.warningColor} />,
        };
    }

    return defaultStyle;
}

export const PercentageBar = ({ label, percent }: { label: string; percent: number }) => {
    const theme = useContext(ThemeContext);

    const sanitizedPercent = Math.max(0, Math.min(100, percent));
    const barStyle = getBarStyle(percent, theme);

    return (
        <Container borderColor={barStyle.containerBorderColor} borderSize={barStyle.containerBorderSize}>
            <Bar color={barStyle.barColor} percent={sanitizedPercent} />
            <LabelContainer>
                {barStyle.labelIcon}
                <span>{label}</span>
            </LabelContainer>
        </Container>
    );
};

const Container = styled.div<{ borderColor: string; borderSize: string }>`
    width: 100%;
    height: 30px;
    position: relative;
    border: ${({ borderSize }) => borderSize} solid ${({ borderColor }) => borderColor};
    border-radius: ${({ theme }) => theme.borderRadius};
`;

const Bar = styled.div<{ color: string; percent: number }>`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: ${({ percent }) => `${percent}%`};
    background-color: ${({ color }) => color};
    border-radius: ${({ theme }) => theme.borderRadius};
`;

const LabelContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.textColor};

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

import * as Sentry from '@sentry/react';
import { postToSlack } from './postToSlack';

interface Metadata {
    [key: string]: string;
}

type ContextType = Record<string, unknown>;

function formatTags(tags = {}) {
    return Object.entries(tags).reduce((acc, [key, val]) => {
        acc += `${key}: ${val}\n`;
        return acc;
    }, '');
}

function formatContexts(contexts = {}) {
    return Object.entries(contexts).reduce((acc, [key, val]) => {
        try {
            acc += `${key}: ${JSON.stringify(val)}\n`;
        } catch (_) {
            acc += `${key}: unable to serialize value\n`;
        }
        return acc;
    }, '');
}

function logEventToConsole({
    message,
    tags = {},
    contexts = {},
}: {
    message: string;
    tags: Metadata;
    contexts: ContextType;
}) {
    const output = `${message}
${formatTags(tags)}---
${formatContexts(contexts)}---`;
    console.log(output);
}

function logEvent({
    message,
    tags = {},
    contexts = {},
    // eslint-disable-next-line import/namespace
    level = Sentry.Severity.Error,
    slackChannel,
}: {
    message: string;
    tags: Metadata;
    contexts: ContextType;
    level: Sentry.Severity;
    slackChannel?: string;
}) {
    const formattedMessage = `${level}: ${message}`;

    logEventToConsole({
        message: formattedMessage,
        tags,
        contexts,
    });

    // eslint-disable-next-line import/namespace
    Sentry.withScope((scope) => {
        scope.setContext('error context', {
            ...contexts,
        });
        // eslint-disable-next-line import/namespace
        Sentry.captureEvent({
            level,
            message,
            tags,
        });
    });

    if (slackChannel) {
        postToSlack({ message, channel: slackChannel });
    }
}

export function logError({
    message,
    tags = {},
    contexts = {},
    slackChannel,
}: {
    message: string;
    tags?: Metadata;
    contexts?: ContextType;
    slackChannel?: string;
}) {
    // eslint-disable-next-line import/namespace
    return logEvent({ message, tags, contexts, slackChannel, level: Sentry.Severity.Error });
}

export function logWarning({
    message,
    tags = {},
    contexts = {},
    slackChannel,
}: {
    message: string;
    tags?: Metadata;
    contexts?: ContextType;
    slackChannel?: string;
}) {
    // eslint-disable-next-line import/namespace
    return logEvent({ message, tags, contexts, slackChannel, level: Sentry.Severity.Warning });
}

export function logInfo({
    message,
    tags = {},
    contexts = {},
    slackChannel,
}: {
    message: string;
    tags?: Metadata;
    contexts?: ContextType;
    slackChannel?: string;
}) {
    // eslint-disable-next-line import/namespace
    return logEvent({ message, tags, contexts, slackChannel, level: Sentry.Severity.Info });
}

import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useFormContext, useWatch } from 'react-hook-form';
import { toast } from 'react-toastify';

import { SHORT_FIELD_WIDTH } from 'constants/detailsView';
import { ALLERGENS, NUTRISCORE, NUTRITION } from 'pages/Products/constants/nutrition';
import { GET_OFF_DATA_QUERY } from 'data/queries/product';
import {
    GET_OFF_DATA,
    GET_OFF_DATAVariables,
    GET_OFF_DATA_OFFProductData_productNutritionData,
} from 'data/queries/__generated__/GET_OFF_DATA';

import { DefaultProductType } from 'pages/Products/constants/defaultProduct';

import { OFFNutritionConfirmationPopup } from './components/OFFNutritionConfirmationPopup';

import { DetailSwitchTable, DetailFormSelect, DetailFormTextArea, DetailFormValue } from 'components/DetailsView';
import { SectionColumn, SectionContainer, ColumnsSectionContainer } from 'components/DetailsView/Section';
import { Loader, LoaderModeType } from 'components/Loader';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { DetailInputTable } from 'components/DetailsView/DetailInputTable';
import { GetDetailedProductQuery } from 'data/__generated__';

export const NutritionalInfoSection = ({
    product,
    isIncomingFromCreation = false,
}: {
    product: NonNullable<GetDetailedProductQuery['detailedProduct']> | DefaultProductType;
    isIncomingFromCreation?: boolean;
}) => {
    const { control, register } = useFormContext();
    const [fetchedOFFData, setFetchedOFFData] = useState<GET_OFF_DATA_OFFProductData_productNutritionData | null>(null);
    const [OFFData, { loading: loadingOffData }] = useLazyQuery<GET_OFF_DATA, GET_OFF_DATAVariables>(
        GET_OFF_DATA_QUERY,
        {
            onCompleted({ OFFProductData }) {
                if (!OFFProductData) {
                    toast.error('Une erreur est survenue lors de la récupération des données O.F.F');
                    return;
                }

                const { success, error, productNutritionData } = OFFProductData;

                if (!success) {
                    toast.error(error);
                } else {
                    setFetchedOFFData(productNutritionData);
                }
            },
            fetchPolicy: 'network-only',
        },
    );

    const { allergen, barcodes, ingredients, nutriscore, nutrition } = product;

    function getAllergenByName(allergenName: string) {
        if (!allergen) {
            return false;
        }

        switch (allergenName) {
            case 'gluten':
                return allergen.gluten;
            case 'crustaceans':
                return allergen.crustaceans;
            case 'eggs':
                return allergen.eggs;
            case 'fish':
                return allergen.fish;
            case 'peanuts':
                return allergen.peanuts;
            case 'soybeans':
                return allergen.soybeans;
            case 'milk':
                return allergen.milk;
            case 'nuts':
                return allergen.nuts;
            case 'celery':
                return allergen.celery;
            case 'mustard':
                return allergen.mustard;
            case 'sesame':
                return allergen.sesame;
            case 'sulphites':
                return allergen.sulphites;
            case 'lupin':
                return allergen.lupin;
            case 'molluscs':
                return allergen.molluscs;
            default:
                return false;
        }
    }

    function getNutrientByName(nutrientName: string) {
        switch (nutrientName) {
            case 'caloriesKCal':
                return nutrition.caloriesKCal;
            case 'totalFat':
                return nutrition.totalFat;
            case 'sturatedFat':
                return nutrition.sturatedFat;
            case 'sodium':
                return nutrition.sodium;
            case 'carbohydrates':
                return nutrition.carbohydrates;
            case 'sugars':
                return nutrition.sugars;
            case 'fibers':
                return nutrition.fibers;
            case 'protein':
                return nutrition.protein;
            default:
                return 0;
        }
    }

    const formattedAllergens = ALLERGENS.map((item) => ({ ...item, value: getAllergenByName(item.name) }));
    const formattedNutritionalFacts = NUTRITION.map((item) => ({ ...item, value: getNutrientByName(item.name) }));

    const updatedBarcodes: {
        value: string;
    }[] = useWatch({
        control,
        name: 'barcodes',
        defaultValue: barcodes.map((barcode) => {
            return { value: barcode };
        }),
    });

    const handleOnClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        await OFFData({
            variables: {
                barcodes: updatedBarcodes.map((barcode) => {
                    return barcode.value;
                }),
            },
        });
    };

    return (
        <>
            <SectionContainer title="Nutrition, allergènes et ingrédients" isInitiallyOpen={isIncomingFromCreation}>
                <ColumnsSectionContainer numberOfColumns={3}>
                    <SectionColumn>
                        <TotemPrimaryButton onClick={(e) => handleOnClick(e)} disabled={!updatedBarcodes?.length}>
                            {loadingOffData ? (
                                <Loader size="18px" mode={LoaderModeType.Spin} />
                            ) : (
                                'Obtenir des données nutritionnelles O.F.F'
                            )}
                        </TotemPrimaryButton>
                        <DetailFormSelect<string | null>
                            label="Nutriscore"
                            placeholder="Nutriscore"
                            defaultValue={nutriscore}
                            name="nutriscore"
                            options={NUTRISCORE}
                            width={SHORT_FIELD_WIDTH}
                            isClearable
                        />
                        <DetailFormTextArea
                            label="Ingrédients"
                            defaultValue={ingredients}
                            {...register('ingredients')}
                            height="200px"
                        />
                    </SectionColumn>
                    <SectionColumn>
                        <DetailInputTable
                            label="Valeurs nutritionnelles (pour 100g)"
                            tableName="nutrition"
                            items={formattedNutritionalFacts}
                            subtext="NB: Le champ de fibres n'est pas affiché au client s'il est égal à zéro"
                        />
                    </SectionColumn>
                    <SectionColumn>
                        <DetailSwitchTable tableName="allergen" label="Allergènes" items={formattedAllergens} />
                        <DetailFormValue
                            label="Peut contenir des traces de..."
                            defaultValue={allergen?.canContain}
                            width="100%"
                            {...register('allergen.canContain')}
                        />
                    </SectionColumn>
                </ColumnsSectionContainer>
            </SectionContainer>
            {fetchedOFFData ? (
                <OFFNutritionConfirmationPopup fetchedOFFData={fetchedOFFData} setFetchedOFFData={setFetchedOFFData} />
            ) : null}
        </>
    );
};

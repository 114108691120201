import gql from 'graphql-tag';
import { SITE_SETUP_FRAGMENT } from '../fragments/siteSetup';

export const FORCE_FINISH_SITE_SETUP_MUTATION = gql`
    mutation FORCE_FINISH_SITE_SETUP($siteSetupId: ID!) {
        forceFinishSiteSetup(siteSetupId: $siteSetupId) {
            ...SiteSetupFragment
        }
    }
    ${SITE_SETUP_FRAGMENT}
`;

export const FINISH_SITE_SETUP_WITH_MANUAL_GENERATION_MUTATION = gql`
    mutation FINISH_SITE_SETUP_WITH_MANUAL_GENERATION($siteSetupId: ID!) {
        finishSiteSetupWithManualGeneration(siteSetupId: $siteSetupId) {
            ...SiteSetupFragment
        }
    }
    ${SITE_SETUP_FRAGMENT}
`;

import React from 'react';

import { CommonStatusTag } from 'components/CommonStatusTag';

import { colors } from 'constants/colors';

import { PaymentState } from 'data/__generated__';
import { PAYMENTS_STATES_LABELS } from '../constants/states';

const getColorsFromState = (state: PaymentState) => {
    switch (state) {
        case PaymentState.Draft:
            return { backgroundColor: colors.yellow, labelColor: colors.black };
        case PaymentState.Paid:
            return { backgroundColor: colors.green, labelColor: colors.black };
        case PaymentState.Pending:
            return { backgroundColor: colors.blandBlue, labelColor: colors.black };
        case PaymentState.Declined:
            return { backgroundColor: colors.red, labelColor: colors.pureWhite };
        case PaymentState.Refunded:
            return { backgroundColor: colors.violet, labelColor: colors.pureWhite };
    }
};

export const StatusTag = ({ state, showHeading }: { state: PaymentState; showHeading?: boolean }) => {
    const { backgroundColor, labelColor } = getColorsFromState(state);
    return (
        <CommonStatusTag
            backgroundColor={backgroundColor}
            labelColor={labelColor}
            label={PAYMENTS_STATES_LABELS[state]}
            showHeading={showHeading}
        />
    );
};

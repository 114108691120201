import React, { useState } from 'react';
import styled from 'styled-components';

import {
    SubventionFragmentFragment,
    SubventionReloadPeriod,
    useGetActiveCategoriesQuery,
    useGetOrganizationsQuery,
} from 'data/__generated__';

import { ColumnsSectionContainer, SectionColumn, SectionContainer } from 'components/DetailsView/Section';
import { DetailValue } from 'components/DetailsView/DetailValue';
import { CopyValue } from 'components/CopyValue';
import { getExtendedFormattedDatetime } from 'helpers/dateTimes';
import { DetailFormCheckbox, DetailFormSelect, DetailFormValue, DetailLink } from 'components/DetailsView';
import { TotemSwitch } from 'components/TotemSwitch';
import { Loader } from 'components/Loader';
import { useFormContext } from 'react-hook-form';
import { subventionWindowOptions } from 'pages/Subventions/constants/subventionHours';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

const dailyReloadOption = { value: SubventionReloadPeriod.Daily, label: 'jour' };
const weeklyReloadTypeOption = { value: SubventionReloadPeriod.Weekly, label: 'semaine' };
const monthlyReloadTypeOption = { value: SubventionReloadPeriod.Monthly, label: 'mois' };
const RELOAD_OPTIONS = [dailyReloadOption, weeklyReloadTypeOption, monthlyReloadTypeOption];

const createdAt = new Date().valueOf();

const defaultSubvention = {
    _id: null,
    archivedAt: null,
    createdAt,
    billToOrganizationId: null,
    categories: [],
    cumulative: true,
    currentAmount: 2,
    description: '',
    hoursIntervalsAvailable: [['00:00', '23:59']],
    imageUrl: '',
    name: '',
    organizationId: null,
    reload: SubventionReloadPeriod.Daily,
    updatedAt: createdAt,
};

export const GeneralInfoSection = ({ subventionDetails }: { subventionDetails?: SubventionFragmentFragment }) => {
    const { register, setValue } = useFormContext();
    const {
        loading: activeCategoriesLoading,
        data: activeCategoriesData,
        error: activeCategoriesError,
    } = useGetActiveCategoriesQuery();

    const {
        loading: organizationsLoading,
        data: organizationsData,
        error: organizationsError,
    } = useGetOrganizationsQuery();

    const {
        _id: subventionId,
        archivedAt,
        billToOrganizationId,
        categories,
        createdAt,
        cumulative,
        currentAmount,
        description,
        hoursIntervalsAvailable,
        name,
        organizationId,
        reload,
        updatedAt,
    } = subventionDetails || defaultSubvention;

    const [unlimited, setUnlimited] = useState<boolean>(!currentAmount);
    if (activeCategoriesLoading || organizationsLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }
    if (activeCategoriesError || !activeCategoriesData) {
        throw new Error('Une erreur est survenue lors de la récupération des catégories actives');
    }
    if (organizationsError || !organizationsData) {
        throw new Error('Une erreur est survenue lors de la récupération des organizations');
    }
    const { activeCategories } = activeCategoriesData;
    const categoryOptions = activeCategories.map((category) => {
        return { value: category?._id, label: category?.title };
    });

    const { organizations } = organizationsData;
    const organizationOptions = organizations.map(({ _id, name }) => {
        return { value: _id, label: name };
    });

    const { name: organizationName } = organizations.find(({ _id }) => _id === organizationId) || {
        name: '',
    };

    return (
        <SectionContainer title="Informations Générales" isInitiallyOpen>
            <>
                <ColumnsSectionContainer numberOfColumns={2}>
                    <SectionColumn>
                        {subventionId ? <CopyValue value={subventionId} /> : null}
                        <DetailFormValue
                            label="Nom pour les clients"
                            defaultValue={name}
                            {...register('name', { required: true })}
                        />
                        <DetailFormValue
                            label="Description"
                            defaultValue={description}
                            {...register('description', { required: true })}
                        />
                        <TotemSwitch
                            label="Illimitée"
                            checked={unlimited}
                            onChange={() => {
                                setValue('currentAmount', !unlimited ? null : 2, { shouldTouch: true });
                                setValue('reload', !unlimited ? null : SubventionReloadPeriod.Daily, {
                                    shouldTouch: true,
                                    shouldValidate: true,
                                });
                                setUnlimited(!unlimited);
                            }}
                        />
                        {!unlimited ? (
                            <>
                                <DetailFormValue
                                    label="Montant"
                                    sublabel="En €. Laissez ce champ vide pour une subvention illimitée"
                                    placeholder="Réduction en €"
                                    defaultValue={currentAmount}
                                    type="number"
                                    width="100%"
                                    step=".01"
                                    {...register('currentAmount', {
                                        min: 0.01,
                                        valueAsNumber: true,
                                    })}
                                />
                                <DetailFormSelect<SubventionReloadPeriod>
                                    label="Période"
                                    placeholder="Sélectionnez une période de rechargement"
                                    defaultValue={reload as SubventionReloadPeriod}
                                    name="reload"
                                    width="100%"
                                    options={RELOAD_OPTIONS}
                                />
                            </>
                        ) : null}
                        <DetailFormCheckbox
                            label="Cumulative"
                            sublabel="Cette subvention peut être utilisée sur plusieurs produits pendant la période de validité"
                            name="cumulative"
                            defaultChecked={!!cumulative}
                        />
                        <DetailFormSelect<string>
                            label="Début de l'horaire de validité"
                            placeholder="Sélectionnez une heure"
                            defaultValue={hoursIntervalsAvailable[0][0]}
                            name="hoursIntervalsAvailable.0.0"
                            options={subventionWindowOptions}
                        />
                        <DetailFormSelect<string>
                            label="Fin de l'horaire de validité"
                            placeholder="Sélectionnez une heure"
                            defaultValue={hoursIntervalsAvailable[0][1]}
                            name="hoursIntervalsAvailable.0.1"
                            options={subventionWindowOptions}
                        />
                        <DetailFormSelect<string>
                            label="Catégories"
                            placeholder="Sélectionnez une ou plusieurs catégories(s)"
                            defaultValue={categories}
                            isMulti
                            isClearable
                            name="categories"
                            options={categoryOptions}
                        />
                    </SectionColumn>
                    <SectionColumn>
                        {subventionId ? (
                            <DetailLink
                                label="Organization"
                                name={organizationName}
                                value={organizationId}
                                path="organization"
                            />
                        ) : (
                            <DetailFormSelect<string>
                                label="Organization"
                                placeholder="Sélectionnez une organisation"
                                defaultValue={organizationId || undefined}
                                name="organizationId"
                                options={organizationOptions}
                            />
                        )}
                        <DetailFormSelect<string>
                            label="Organization pour la facturation (si différente)"
                            placeholder="Sélectionnez une organisation"
                            defaultValue={billToOrganizationId || undefined}
                            name="billToOrganizationId"
                            options={organizationOptions}
                        />
                        <DetailValue label="Date de création" value={getExtendedFormattedDatetime(createdAt)} />
                        <DetailValue label="Dernière mise à jour" value={getExtendedFormattedDatetime(updatedAt)} />
                        {archivedAt ? (
                            <DetailValue label="Date d'archivage" value={getExtendedFormattedDatetime(archivedAt)} />
                        ) : subventionId ? (
                            <TotemPrimaryButton
                                onClick={() => {
                                    setValue('archivedAt', new Date(), { shouldTouch: true });
                                }}
                            >
                                Archiver la subvention
                            </TotemPrimaryButton>
                        ) : null}
                    </SectionColumn>
                </ColumnsSectionContainer>
            </>
        </SectionContainer>
    );
};

const Container = styled.div`
    margin-top: 10px;
    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

import React from 'react';

import { useQuery } from '@apollo/client';
import styled from 'styled-components';

import { PAGES } from '../../constants/pages';

import { GET_OPS_PARAMETERS } from 'data/queries/__generated__/GET_OPS_PARAMETERS';
import { GET_OPS_PARAMETERS_QUERY } from 'data/queries/opsParameter';

import { Header, HeaderTitle } from 'components/Header';
import { FieldToDisplay, ListView } from 'components/ListView';
import { Loader } from 'components/Loader';
import { PageTitle } from 'components/PageTitle';
import { OpsParameterFragment } from 'data/fragments/__generated__/OpsParameterFragment';
import { OpsParameterForm } from './components/OpsParameterForm';

type FormattedOpsParameterType = {
    _id: string;
    name: string;
    value: JSX.Element;
};

export const OpsParameters = () => {
    const {
        loading: opsParametersLoading,
        error: opsParametersError,
        data: opsParametersData,
    } = useQuery<GET_OPS_PARAMETERS>(GET_OPS_PARAMETERS_QUERY);

    if (opsParametersLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (!opsParametersData || opsParametersError) {
        throw new Error('Une erreur est survenue lors de la récupération des opsParameters');
    }

    function formatOpsParameter(opsParameter: OpsParameterFragment) {
        const { _id, name } = opsParameter;

        return {
            _id,
            name,
            value: <OpsParameterForm opsParameter={opsParameter} />,
        };
    }

    const { getOpsParameters } = opsParametersData;

    const sortedOpsParameters = [...getOpsParameters].sort((opsParameterA, opsParameterB) =>
        opsParameterA.name.localeCompare(opsParameterB.name),
    );

    const OPS_PARAMETERS_FIELDS_TO_DISPLAY: FieldToDisplay<FormattedOpsParameterType>[] = [
        { fieldName: 'name', label: 'Nom' },
        { fieldName: 'value', label: 'Valeur' },
    ];

    const formattedOpsParameters: FormattedOpsParameterType[] = sortedOpsParameters.map(formatOpsParameter);

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.opsParameters} />
                </HeaderTitle>
            </Header>
            <Content>
                <ListView<FormattedOpsParameterType>
                    fieldsToDisplay={OPS_PARAMETERS_FIELDS_TO_DISPLAY}
                    items={formattedOpsParameters}
                    keyExtractor={(item) => item._id}
                />
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Content = styled.div`
    padding: 15px;
    flex: 1;
    overflow: scroll;
`;

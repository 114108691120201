import React from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { PAGES } from '../../constants/pages';

import { PageTitle } from 'components/PageTitle';
import { Header, HeaderTitle } from 'components/Header';
import { StatusTag } from './components/StatusTag';
import { PaymentState } from 'data/__generated__';
import { PaymentsList } from 'pages/Payments/PaymentsList';

export const Payments = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const selectedStates = searchParams.getAll('state');
    const organizationIdFilter = searchParams.get('organizationId') || '';

    function toggleStateSelection(state: PaymentState) {
        return () => {
            if (selectedStates.includes(state)) {
                // @ts-ignore TS2554 (your editor won't show an error, but react-scripts will)
                searchParams.delete('state', state);
                setSearchParams(searchParams);
            } else {
                searchParams.append('state', state);
                setSearchParams(searchParams);
            }
        };
    }

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.payments} />
                </HeaderTitle>
            </Header>
            <Content>
                <Filters>
                    <Title>Filtres</Title>
                    {(Object.keys(PaymentState) as PaymentState[]).map((state) => (
                        <FilterContainer
                            key={state}
                            isSelected={selectedStates.includes(state)}
                            onClick={toggleStateSelection(state)}
                        >
                            <StatusTag state={state} />
                        </FilterContainer>
                    ))}
                </Filters>
                <PaymentsList stateFilter={selectedStates} organizationIdFilter={organizationIdFilter} />
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    flex: 1;
    overflow: hidden;
`;

const Filters = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const Title = styled.h3`
    margin: 0;
    font-size: 20px;
    font-weight: 800;
`;

const FilterContainer = styled.div<{ isSelected: boolean }>`
    cursor: pointer;
    border-radius: 20px;
    border: 2px solid ${({ isSelected, theme }) => (isSelected ? theme.darkBorderColor : 'transparent')};
`;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';

import { GET_LOCATIONS_QUERY } from 'data/queries/locations';
import { GET_LOCATIONS, GET_LOCATIONS_locations } from 'data/queries/__generated__/GET_LOCATIONS';
import { LocationState, LocationZone } from 'data/__generated__';

import { PAGES } from 'constants/pages';
import { colors } from 'constants/colors';

import { LocationActions } from 'pages/Locations/components/LocationActions';

import { LocationTrelloItem } from 'pages/Locations/components/LocationTrelloItem';
import { ItemType } from 'components/TrelloView/Column';
import { TrelloView } from 'components/TrelloView/TrelloView';
import { StatusTag } from 'pages/Locations/components/StatusTag';
import { CommonStatusTag } from 'components/CommonStatusTag';
import { TotemInput } from 'components/TotemInput';

import { Header, HeaderTitle } from 'components/Header';
import { PageTitle } from 'components/PageTitle';
import { Loader, LoaderModeType } from 'components/Loader';

type SortedLocations = {
    dry: GET_LOCATIONS_locations[];
    fresh: GET_LOCATIONS_locations[];
    bakery: GET_LOCATIONS_locations[];
    fruit: GET_LOCATIONS_locations[];
};

export type LocationColumnType = {
    title: string;
    items: (ItemType & {
        state: LocationState;
        productName: string | null;
        datamatrix: string;
    })[];
};

export const LOCATION_COLUMN_LABELS = {
    dry: 'Secs 🥜',
    fresh: 'Frais ❄️',
    fruit: 'Fruits 🍌',
    bakery: 'Boulangerie 🥐',
};

// TODO node 20 FIX THIS
export const Locations = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [hasProductIdEmpty, setHasProductIdEmpty] = useState<boolean>(false);
    const [selectedLocationIds, setSelectedLocationIds] = useState<string[]>([]);
    const [sortedLocations, setSortedLocations] = useState<SortedLocations | null>(null);
    const filterString = searchParams.get('filterString') || '';
    const selectedStates = searchParams.getAll('state') as LocationState[];

    const {
        data: locationsData,
        loading: locationsLoading,
        error: locationsError,
    } = useQuery<GET_LOCATIONS>(GET_LOCATIONS_QUERY);

    function toggleLocationSelection(locationIdToToggle: string) {
        if (selectedLocationIds.includes(locationIdToToggle)) {
            // searchParams.append(locationIdToToggle);
            setSelectedLocationIds(selectedLocationIds.filter((locationId) => locationId !== locationIdToToggle));
        } else {
            setSelectedLocationIds([...selectedLocationIds, locationIdToToggle]);
        }
    }

    function mapLocationsToTrelloItems(locations: GET_LOCATIONS_locations[]) {
        return locations.map((location) => {
            const { _id, state, productName, datamatrix } = location;
            return {
                _id,
                content: (
                    <LocationTrelloItem
                        location={location}
                        isSelected={selectedLocationIds.includes(location._id)}
                        toggleSelection={() => toggleLocationSelection(location._id)}
                    />
                ),
                state,
                productName,
                datamatrix,
            };
        });
    }

    useEffect(() => {
        if (locationsData) {
            const { locations } = locationsData;

            const sortedLocations = [...locations].sort((locationA, locationB) =>
                locationA.index > locationB.index ? 1 : -1,
            );

            setSortedLocations(
                sortedLocations.reduce<SortedLocations>(
                    (acc, location) => {
                        switch (location.zone) {
                            case LocationZone.Dry:
                                acc.dry.push(location);
                                break;
                            case LocationZone.Fre:
                                acc.fresh.push(location);
                                break;
                            case LocationZone.Fru:
                                acc.fruit.push(location);
                                break;
                            case LocationZone.Bak:
                                acc.bakery.push(location);
                                break;
                        }
                        return acc;
                    },
                    {
                        dry: [],
                        fresh: [],
                        bakery: [],
                        fruit: [],
                    },
                ),
            );
        }
    }, [locationsData]);

    if (locationsLoading && !locationsData) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (locationsError || !locationsData) {
        throw new Error('Une erreur est survenue lors de la récupération des emplacements');
    }

    if (!sortedLocations) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    const trelloData = {
        columns: [
            {
                title: LOCATION_COLUMN_LABELS.dry,
                items: mapLocationsToTrelloItems(sortedLocations.dry),
            },
            {
                title: LOCATION_COLUMN_LABELS.fruit,
                items: mapLocationsToTrelloItems(sortedLocations.fruit),
            },
            {
                title: LOCATION_COLUMN_LABELS.fresh,
                items: mapLocationsToTrelloItems(sortedLocations.fresh),
            },
            {
                title: LOCATION_COLUMN_LABELS.bakery,
                items: mapLocationsToTrelloItems(sortedLocations.bakery),
            },
        ],
    };

    function toggleStateSelection(state: LocationState) {
        return () => {
            if (selectedStates.includes(state)) {
                // @ts-ignore TS2554 (your editor won't show an error, but react-scripts will)
                searchParams.delete('state', state);
                setSearchParams(searchParams);
            } else {
                searchParams.append('state', state);
                setSearchParams(searchParams);
            }
        };
    }

    function updateSearchParams(value: string): void {
        if (value?.length > 2) {
            searchParams.set('filterString', value);
        } else {
            searchParams.delete('filterString');
        }
        setSearchParams(searchParams);
    }

    function filterColumns(dataColumns: LocationColumnType[]) {
        return dataColumns.map((column) => {
            const filteredStatesItems = column.items.filter(
                ({ state }) => !selectedStates.length || selectedStates.includes(state),
            );

            const filteredNameItems = filteredStatesItems.filter(
                ({ productName, datamatrix }) =>
                    !filterString ||
                    productName?.toLowerCase().includes(filterString.toLowerCase()) ||
                    datamatrix?.toLowerCase().includes(filterString.toLowerCase()),
            );

            const filteredItems = filteredNameItems.filter(({ productName }) => !hasProductIdEmpty || !productName);
            return { ...column, items: filteredItems };
        });
    }

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.locations} />
                </HeaderTitle>
            </Header>
            <Content>
                <InnerContent>
                    <SearchSection>
                        <TotemInput
                            label="Recherche"
                            onChange={(value) => updateSearchParams(value)}
                            placeholder="Chercher par datamatrix ou nom de produit (au moins 3 caractères)"
                            value={filterString}
                        />
                        <Filters>
                            <Title>Filtres</Title>
                            {(Object.keys(LocationState) as LocationState[]).map((state) => (
                                <FilterContainer
                                    key={state}
                                    isSelected={selectedStates.includes(state)}
                                    onClick={toggleStateSelection(state)}
                                >
                                    <StatusTag state={state} />
                                </FilterContainer>
                            ))}
                            <FilterContainer
                                isSelected={hasProductIdEmpty}
                                onClick={() => setHasProductIdEmpty(!hasProductIdEmpty)}
                            >
                                <CommonStatusTag
                                    backgroundColor={colors.blue}
                                    labelColor={colors.pureWhite}
                                    label="Emplacement libre"
                                />
                            </FilterContainer>
                        </Filters>
                    </SearchSection>
                    <TrelloViewContainer>
                        {trelloData ? (
                            <TrelloView
                                initialData={
                                    selectedStates.length || filterString.length > 2 || hasProductIdEmpty
                                        ? { columns: filterColumns(trelloData.columns) }
                                        : trelloData
                                }
                                columnWidth="280px"
                            />
                        ) : (
                            <Container>
                                <Loader mode={LoaderModeType.Spin} />
                            </Container>
                        )}
                    </TrelloViewContainer>
                </InnerContent>
                <LocationActions
                    selectedLocationIds={selectedLocationIds}
                    setSelectedLocationIds={setSelectedLocationIds}
                />
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Content = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    overflow: hidden;
`;

const InnerContent = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

const SearchSection = styled.div`
    padding: 15px 15px 0;
`;
const TrelloViewContainer = styled.div`
    display: flex;
    flex: 1;
    overflow-x: scroll;
`;

const Filters = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const Title = styled.h3`
    margin: 0;
    color: ${({ theme }) => theme.textColor};
    font-size: 20px;
    font-weight: 800;
`;

const FilterContainer = styled.div<{ isSelected: boolean }>`
    cursor: pointer;
    border-radius: 20px;
    border: 2px solid ${({ isSelected, theme }) => (isSelected ? theme.darkBorderColor : 'transparent')};
`;

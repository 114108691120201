import { THERMOMETER_FRAGMENT } from 'data/fragments/thermometer';
import gql from 'graphql-tag';

export const CREATE_THERMOMETER_MUTATION = gql`
    mutation CREATE_THERMOMETER($number: Int!) {
        createThermometer(number: $number) {
            ...ThermometerFragment
        }
    }
    ${THERMOMETER_FRAGMENT}
`;

export const UPDATE_THERMOMETER_MUTATION = gql`
    mutation UPDATE_THERMOMETER($thermometerUpdateInput: ThermometerUpdateInput!) {
        updateThermometer(thermometerUpdateInput: $thermometerUpdateInput) {
            ...ThermometerFragment
        }
    }
    ${THERMOMETER_FRAGMENT}
`;

import React from 'react';
import styled from 'styled-components';
import { PAYMENT_TYPE } from '@totem/constants';

import { colors } from 'constants/colors';

import { FcCheckmark } from 'react-icons/fc';

import { FieldToDisplay, ListView } from 'components/ListView';
import { PaymentMethodType } from './types';

import { capitalize } from 'pages/Users/utils';

type FormattedCardAndMealVoucherPaymentMethodType = {
    _id: string;
    expiration: JSX.Element;
    identifier: string;
    isDefault: JSX.Element;
    provider: string;
    retriesLabel: string;
};

function getProvider({ paymentType, provider, bank }: { paymentType: string; provider: string; bank: string | null }) {
    if (paymentType === PAYMENT_TYPE.CB) {
        return `CB - ${capitalize(provider)}`;
    }
    if (paymentType === PAYMENT_TYPE.SWILE) {
        return 'Swile';
    }
    if (paymentType === PAYMENT_TYPE.EDENRED) {
        return 'TR - Edenred';
    }
    if (paymentType === PAYMENT_TYPE.RESTOFLASH) {
        return 'Restoflash - Paygreen';
    }
    if (paymentType === PAYMENT_TYPE.PASSRESTO) {
        return 'Passresto - Paygreen';
    }
    if (PAYMENT_TYPE.TRD === paymentType) {
        if (bank?.match('Natixis')) {
            return 'Apetiz - Paygreen';
        }
        if (bank?.match('UP')) {
            return 'UP - Paygreen';
        }
        if (bank?.match('Sodexo')) {
            return 'Sodexo - Paygreen';
        }

        return 'Inconnu - Paygreen';
    }

    return 'autre';
}

export function CardAndMealVoucherPaymentMethods({ paymentMethods }: { paymentMethods: PaymentMethodType[] }) {
    const PAYMENT_METHOD_FIELDS_TO_DISPLAY: FieldToDisplay<FormattedCardAndMealVoucherPaymentMethodType>[] = [
        {
            fieldName: 'provider',
            label: 'Fournisseur',
        },
        {
            fieldName: 'identifier',
            label: 'Numéro / compte',
        },
        {
            fieldName: 'expiration',
            label: 'Exp.',
        },
        {
            fieldName: 'retriesLabel',
            label: 'Echecs',
        },
        {
            fieldName: 'isDefault',
            label: 'Défaut',
        },
    ];

    const formattedPaymentMethods = paymentMethods.map((paymentMethod) => {
        const {
            _id,
            default: isDefault,
            details: { number, username, expiration, bank },
            expired,
            paymentType,
            provider,
            retries,
        } = paymentMethod;
        const cardDigits = (number?.length && '**' + number.substr(number.length - 4)) || '**xxxx';
        const formattedExpiration = expiration ? expiration.slice(0, 2) + '/' + expiration.slice(2) : '';
        return {
            _id,
            expiration: (
                <Expiration>
                    {formattedExpiration}
                    {expired ? <Expired /> : null}
                </Expiration>
            ),
            identifier: (number && cardDigits) || username || 'inconnu',
            isDefault: isDefault ? <FcCheckmark /> : <div />,
            provider: getProvider({ paymentType, provider, bank }),
            retriesLabel: retries === undefined ? 'NA' : retries.toString(),
        };
    });
    return (
        <ListView<FormattedCardAndMealVoucherPaymentMethodType>
            fieldsToDisplay={PAYMENT_METHOD_FIELDS_TO_DISPLAY}
            items={formattedPaymentMethods}
            keyExtractor={(item) => item._id}
        />
    );
}

const Expiration = styled.div`
    display: flex;
    align-items: center;
`;

const Expired = styled.div`
    background-color: ${colors.red};
    width: 10px;
    height: 10px;
    border-radius: ${({ theme }) => theme.borderRadius};
    margin-left: 5px;
`;

import React, { useContext, useState } from 'react';

import { useQuery } from '@apollo/client';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import styled, { ThemeContext } from 'styled-components';

import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import fr from 'date-fns/locale/fr';

import { colors } from 'constants/colors';

import { GET_ORDERS_OF_ORGANIZATION_QUERY } from 'data/queries/order';
import {
    GET_ORDERS_OF_ORGANIZATION,
    GET_ORDERS_OF_ORGANIZATIONVariables,
} from 'data/queries/__generated__/GET_ORDERS_OF_ORGANIZATION';

import { Loader } from 'components/Loader';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import './OrdersCalendarPopin.styles.css';
import { Link } from 'react-router-dom';

const locales = {
    fr,
};

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
});

type Event = { title: string; start: Date; end: Date; allDay: boolean; backgroundColor: string; orderId: string };

const TOTEM_COLORS = [
    colors.green,
    colors.green,
    colors.blue,
    colors.pantinGrey,
    colors.blue,
    colors.pink,
    colors.culturedGrey,
    colors.black,
];

export const OrdersCalendarPopin = ({
    togglePopinOpen,
    organizationId,
    totemId = null,
}: {
    togglePopinOpen: () => void;
    organizationId: string;
    totemId?: string | null;
}) => {
    const [windowStart, setWindowStart] = useState<Date | string | null>(null);
    const [windowEnd, setWindowEnd] = useState<Date | string | null>(null);
    const [totemIdFilter, setTotemIdFilter] = useState<string | null>(totemId);
    const theme = useContext(ThemeContext);

    const {
        loading: ordersOfOrganizationLoading,
        error: ordersOfOrganizationError,
        data: ordersOfOrganizationData,
    } = useQuery<GET_ORDERS_OF_ORGANIZATION, GET_ORDERS_OF_ORGANIZATIONVariables>(GET_ORDERS_OF_ORGANIZATION_QUERY, {
        variables: {
            organizationId,
            windowStart: windowStart ? windowStart.toString() : null,
            windowEnd: windowEnd ? windowEnd.toString() : null,
        },
    });

    if (ordersOfOrganizationLoading && !ordersOfOrganizationData) {
        return (
            <Container>
                <Content>
                    <Header>
                        <Title>Commandes</Title>
                        <TotemPrimaryButton onClick={togglePopinOpen}>Fermer</TotemPrimaryButton>
                    </Header>
                    <Loader />
                </Content>
            </Container>
        );
    }

    if (ordersOfOrganizationError || !ordersOfOrganizationData) {
        throw new Error('Impossible de récupérer les commandes liées à cette organisation');
    }

    const { ordersOfOrganization } = ordersOfOrganizationData;

    const totems = Array.from(new Set(ordersOfOrganization.map((order) => order.totem)))
        .sort((totemA, totemB) => totemA.number - totemB.number)
        .map((totem, index) => ({
            ...totem,
            color: TOTEM_COLORS[index],
            name: totem.name || `TOTEM n°${totem.number}`,
        }));

    const components = {
        event: ({ event }: { event: Event }) => (
            <Link to={`/order/${event.orderId}`}>
                <OrderEventButton>{event.title}</OrderEventButton>
            </Link>
        ),
    };

    const orders = ordersOfOrganization.filter((order) => !totemIdFilter || order.totem._id === totemIdFilter);

    const events = orders.map((order) => {
        const {
            deliveryDate,
            isPunctual,
            number: orderNumber,
            totem: { _id: totemId },
        } = order;

        const totem = totems.find((totemItem) => totemItem._id === totemId);

        const dateParts = deliveryDate.split('/');

        // month is 0-based, that's why we need dataParts[1] - 1
        const date = new Date(+dateParts[2], parseInt(dateParts[1]) - 1, +dateParts[0]);

        return {
            title: `Commande n°${orderNumber} ${isPunctual ? '⏱ Ponctuelle' : ''}`,
            orderId: order._id,
            start: date,
            end: date,
            allDay: true,
            backgroundColor: totem?.color || colors.red,
        };
    });

    function onRangeChange(
        dateRange:
            | Date[]
            | {
                  start: Date | string;
                  end: Date | string;
              },
    ) {
        return function () {
            if ('end' in dateRange) {
                const { start, end } = dateRange;
                setWindowStart(start);
                setWindowEnd(end);
            }
        };
    }

    function toggleTotemIdFilter(totemId: string) {
        return function () {
            if (totemId === totemIdFilter) {
                setTotemIdFilter(null);
            } else {
                setTotemIdFilter(totemId);
            }
        };
    }

    return (
        <Container>
            <Content>
                <Header>
                    <Title>Commandes</Title>
                    <TotemPrimaryButton onClick={togglePopinOpen}>Fermer</TotemPrimaryButton>
                </Header>
                <TotemCaptions>
                    {totems.map((totem) => {
                        const { _id: totemId, name, color } = totem;

                        return (
                            <TotemCaption
                                key={totemId}
                                onClick={toggleTotemIdFilter(totemId)}
                                isSelected={totemIdFilter === totemId}
                            >
                                <TotemColor color={color} />
                                <TotemName>{name}</TotemName>
                            </TotemCaption>
                        );
                    })}
                </TotemCaptions>
                <CalendarContainer>
                    <Calendar
                        localizer={localizer}
                        events={events}
                        eventPropGetter={(event: Event) => ({
                            style: { backgroundColor: event.backgroundColor },
                        })}
                        dayPropGetter={(date) => {
                            const today = new Date();
                            const isToday =
                                date.getDate() === today.getDate() &&
                                date.getMonth() === today.getMonth() &&
                                date.getFullYear() === today.getFullYear();
                            return {
                                style: { backgroundColor: isToday ? theme.backgroundColor : theme.cardBackgroundColor },
                            };
                        }}
                        startAccessor="start"
                        endAccessor="end"
                        views={['month']}
                        components={components}
                        onRangeChange={onRangeChange}
                        style={{ height: '100%', width: '100%' }}
                        culture="fr"
                        messages={{
                            previous: 'Précedent',
                            next: 'Suivant',
                            today: "Aujourd'hui",
                            showMore: (total) => `+${total} de plus`,
                        }}
                    />
                </CalendarContainer>
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: ${({ theme }) => theme.popupOverlayColor};
    z-index: 1;
`;

const Content = styled.div`
    width: 95%;
    height: 95%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: ${({ theme }) => theme.cardBackgroundColor};
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
`;

const TotemCaptions = styled.div`
    display: flex;

    & > :not(:first-child) {
        margin-left: 10px;
    }
`;

const TotemCaption = styled.div<{ isSelected: boolean }>`
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: ${({ theme }) => theme.borderRadius};
    border: 1px solid ${({ isSelected, theme }) => (isSelected ? theme.lightBorderColor : 'transparent')};
    padding: 2px;
`;

const TotemColor = styled.div<{ color: string }>`
    width: 15px;
    height: 15px;
    background-color: ${({ color }) => color};
    border-radius: 2px;
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
`;

const TotemName = styled.div`
    margin-left: 5px;
`;

const CalendarContainer = styled.div`
    flex-grow: 1;
    margin-top: 5px;
`;

const Title = styled.span`
    font-size: 18px;
    font-weight: 800;
`;

const OrderEventButton = styled.button`
    padding: 3px;
    width: 100%;
    color: ${({ theme }) => theme.menuTextColor};
    font-size: 10px;
    background: transparent;
    text-align: start;
    border: none;
    cursor: pointer;
`;

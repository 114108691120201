import * as jose from 'jose';

export async function generateMetabaseDashboard({
    siteName,
    shouldShowLosses = true,
}: {
    siteName: string;
    shouldShowLosses?: boolean;
}) {
    const METABASE_SITE_URL = 'https://metabase.totem.co';
    // This is not so much of a secret, because you can't do much with it
    // Nonetheless, it wouldn't be a bad idea to add this to secrethub, and fetch the metabase dashboard url from the api
    // if you need to generate a new key, check : https://metabase.totem.co/admin/settings/embedding-in-other-applications/standalone
    const METABASE_SECRET_KEY = 'a8ed824bf13c8f21392de1d076bd2fc9f74e3d3d1144ee523fab99c28052ab34';
    const payload = {
        resource: { dashboard: shouldShowLosses ? 277 : 472 },
        params: { site: [siteName] },
        exp: Math.round(Date.now() / 1000) + 60 * 60 * 24 * 30 * 12 * 10, // 10 years
    };
    const encodedPayload = new jose.SignJWT(payload).setProtectedHeader({ alg: 'HS256' }).setExpirationTime('10y');
    const token = await encodedPayload.sign(new TextEncoder().encode(METABASE_SECRET_KEY));
    return METABASE_SITE_URL + '/embed/dashboard/' + token + '#bordered=true&titled=true';
}

import gql from 'graphql-tag';
import { ADVERTISEMENT_SCREEN_CONFIG_FRAGMENT } from 'data/fragments/advertisementScreenConfig';

export const GET_ADVERTISEMENT_SCREEN_CONFIG_QUERY = gql`
    query GET_ADVERTISEMENT_SCREEN_CONFIG {
        getAdvertisementScreenConfig {
            ...AdvertisementScreenConfigFragment
        }
    }
    ${ADVERTISEMENT_SCREEN_CONFIG_FRAGMENT}
`;

import { PRODUCT_SWITCH_FRAGMENT } from 'data/fragments/productSwitch';
import gql from 'graphql-tag';

export const CREATE_PRODUCT_SWITCH_MUTATION = gql`
    mutation CREATE_PRODUCT_SWITCH($productSwitchCreateInput: ProductSwitchCreateInput!) {
        createProductSwitch(productSwitchCreateInput: $productSwitchCreateInput) {
            ...ProductSwitchFragment
        }
    }
    ${PRODUCT_SWITCH_FRAGMENT}
`;

export const ARCHIVE_PRODUCT_SWITCH_MUTATION = gql`
    mutation ARCHIVE_PRODUCT_SWITCH($productSwitchId: ID!) {
        archiveProductSwitch(productSwitchId: $productSwitchId) {
            ...ProductSwitchFragment
        }
    }
    ${PRODUCT_SWITCH_FRAGMENT}
`;

export const UPDATE_PRODUCT_SWITCH_MUTATION = gql`
    mutation UPDATE_PRODUCT_SWITCH($productSwitchUpdateInput: ProductSwitchUpdateInput!) {
        updateProductSwitch(productSwitchUpdateInput: $productSwitchUpdateInput) {
            ...ProductSwitchFragment
        }
    }
    ${PRODUCT_SWITCH_FRAGMENT}
`;

import { gql } from '@apollo/client';
import { DETAILED_ORDER_FRAGMENT } from 'data/fragments/order';

export const UPDATE_ORDER_INFO_MUTATION = gql`
    mutation UPDATE_ORDER_INFO($orderId: ID!, $fieldsToUpdate: OrderUpdateInput!) {
        updateOrderInfo(orderId: $orderId, fieldsToUpdate: $fieldsToUpdate) {
            success
            error
            order {
                ...DetailedOrderFragment
            }
        }
    }
    ${DETAILED_ORDER_FRAGMENT}
`;

export const CHARGE_ORDER_MUTATION = gql`
    mutation CHARGE_ORDER($orderId: ID!) {
        chargeOrder(orderId: $orderId) {
            _id
            paymentId
        }
    }
`;

export const DUPLICATE_ORDER_MUTATION = gql`
    mutation DUPLICATE_ORDER(
        $orderToDuplicateId: ID!
        $destinationTotemId: ID!
        $deliveryDate: String!
        $deliveryTimeWindow: DeliveryTimeWindowInput!
        $isOrderPunctual: Boolean!
    ) {
        duplicateOrder(
            orderToDuplicateId: $orderToDuplicateId
            destinationTotemId: $destinationTotemId
            deliveryDate: $deliveryDate
            deliveryTimeWindow: $deliveryTimeWindow
            isOrderPunctual: $isOrderPunctual
        ) {
            _id
        }
    }
`;

import React from 'react';

import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { DELETE_MICROSTORE_COLUMN_GROUP_MUTATION } from 'data/mutations/microstoreColumnGroup';
import {
    DELETE_MICROSTORE_COLUMN_GROUP,
    DELETE_MICROSTORE_COLUMN_GROUPVariables,
} from 'data/mutations/__generated__/DELETE_MICROSTORE_COLUMN_GROUP';
import { GET_MICROSTORE_SITES_microstoreSitesWithColumnGroups_microstoreColumnGroups } from 'data/queries/__generated__/GET_MICROSTORE_SITES';

import { Loader, LoaderModeType } from 'components/Loader';
import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

export function DeleteMicrostoreColumnGroupPopup({
    isOpen,
    microstoreColumnGroupToDelete,
    setIsOpen,
}: {
    isOpen: boolean;
    microstoreColumnGroupToDelete: GET_MICROSTORE_SITES_microstoreSitesWithColumnGroups_microstoreColumnGroups;
    setIsOpen: (arg: boolean) => void;
}) {
    const [deleteMicrostoreColumnGroup, { loading: creationLoading }] = useMutation<
        DELETE_MICROSTORE_COLUMN_GROUP,
        DELETE_MICROSTORE_COLUMN_GROUPVariables
    >(DELETE_MICROSTORE_COLUMN_GROUP_MUTATION);

    async function handleSubmit() {
        if (!microstoreColumnGroupToDelete) {
            return;
        }

        const { data } = await deleteMicrostoreColumnGroup({
            variables: {
                microstoreColumnGroupId: microstoreColumnGroupToDelete._id,
            },
        });

        if (!data) {
            throw new Error('Une erreur est survenue lors de la suppression de la colonne microstore');
        }

        const {
            deleteMicrostoreColumnGroupMutation: { errors },
        } = data;

        if (errors.length) {
            errors.forEach((error, index) => {
                toast.error(<span key={index}>Erreur : {error}</span>, { autoClose: false });
            });

            if (errors.length > 1) {
                toast.info('Cliquez pour fermer toutes les notifications', {
                    autoClose: false,
                    onClick: () => toast.dismiss(),
                });
            }
        }

        setIsOpen(false);
    }

    return (
        <TotemPopup
            title="VOULEZ-VOUS VRAIMENT SUPPRIMER CE REGROUPEMENT DE COLONNE ?"
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            contentOverflow="visible"
        >
            <SubmitContainer>
                <div>Attention, une fois supprimée, vous ne pourrez plus consulter son contenu.</div>
                <ButtonsContainer>
                    <TotemPrimaryButton onClick={() => setIsOpen(false)}>
                        {creationLoading ? <Loader size="20px" mode={LoaderModeType.Spin} /> : 'Annuler'}
                    </TotemPrimaryButton>
                    <TotemPrimaryButton onClick={handleSubmit}>
                        {creationLoading ? <Loader size="20px" mode={LoaderModeType.Spin} /> : 'Confirmer'}
                    </TotemPrimaryButton>
                </ButtonsContainer>
            </SubmitContainer>
        </TotemPopup>
    );
}

const SubmitContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    color: ${({ theme }) => theme.infoTextColor};

    > :not(:first-child) {
        margin-top: 15px;
    }
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    > :not(:first-child) {
        margin-left: 50px;
    }
`;

import { gql } from '@apollo/client';
import { MOBILE_POPUP_FRAGMENT } from 'data/fragments/mobilePopup';

export const MOBILE_POPUPS_QUERY = gql`
    query MobilePopups {
        mobilePopups {
            ...MobilePopupFragment
        }
    }
    ${MOBILE_POPUP_FRAGMENT}
`;

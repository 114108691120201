import { apolloClient } from '../../../App';
import { MicrostoreColumnTemplateFragment } from 'data/fragments/__generated__/MicrostoreColumnTemplateFragment';
import { GET_MICROSTORE_COLUMN_TEMPLATES_QUERY } from 'data/queries/microstoreColumnTemplate';
import {
    GET_MICROSTORE_COLUMN_TEMPLATES,
    GET_MICROSTORE_COLUMN_TEMPLATES_microstoreColumnTemplatesQuery,
} from 'data/queries/__generated__/GET_MICROSTORE_COLUMN_TEMPLATES';

export const deletedMicrostoreColumnTemplatesHandler = (deletedMicrostoreColumnTemplateIds: string[]) => {
    let currentMicrostoreColumnTemplates = [] as GET_MICROSTORE_COLUMN_TEMPLATES_microstoreColumnTemplatesQuery[];
    // We can read query only if it has been run
    try {
        const result = apolloClient.readQuery<GET_MICROSTORE_COLUMN_TEMPLATES>({
            query: GET_MICROSTORE_COLUMN_TEMPLATES_QUERY,
        });
        if (!result) {
            throw Error('An error occurred when reading GET_MICROSTORE_COLUMN_TEMPLATES_QUERY');
        }
        currentMicrostoreColumnTemplates = result.microstoreColumnTemplatesQuery;
    } catch {
        // do nothing
    }

    const newMicrostoreColumnTemplates = currentMicrostoreColumnTemplates.filter(
        (microstoreColumnTemplate) => !deletedMicrostoreColumnTemplateIds.includes(microstoreColumnTemplate._id),
    );

    apolloClient.writeQuery({
        query: GET_MICROSTORE_COLUMN_TEMPLATES_QUERY,
        data: { microstoreColumnTemplatesQuery: newMicrostoreColumnTemplates },
    });
};

export const microstoreColumnTemplatesUpdatedOrCreatedHandler = (
    microstoreColumnTemplatesUpdatedOrCreated: MicrostoreColumnTemplateFragment[],
) => {
    let currentMicrostoreColumnTemplates = [] as GET_MICROSTORE_COLUMN_TEMPLATES_microstoreColumnTemplatesQuery[];
    // We can read query only if it has been run
    try {
        const result = apolloClient.readQuery<GET_MICROSTORE_COLUMN_TEMPLATES>({
            query: GET_MICROSTORE_COLUMN_TEMPLATES_QUERY,
        });
        if (!result) {
            throw Error('An error occurred when reading GET_MICROSTORE_COLUMN_TEMPLATES_QUERY');
        }
        currentMicrostoreColumnTemplates = result.microstoreColumnTemplatesQuery;
    } catch {
        // do nothing
    }

    const updatedOrCreatedMicrostoreColumnTemplateIds = microstoreColumnTemplatesUpdatedOrCreated.map(({ _id }) => _id);
    const nonUpdatedOrCreatedMicrostoreColumnTemplates = currentMicrostoreColumnTemplates.filter(
        ({ _id }) => !updatedOrCreatedMicrostoreColumnTemplateIds.includes(_id),
    );
    const newMicrostoreColumnTemplates = [
        ...microstoreColumnTemplatesUpdatedOrCreated,
        ...nonUpdatedOrCreatedMicrostoreColumnTemplates,
    ];

    apolloClient.writeQuery({
        query: GET_MICROSTORE_COLUMN_TEMPLATES_QUERY,
        data: { microstoreColumnTemplatesQuery: newMicrostoreColumnTemplates },
    });
};

import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import styled from 'styled-components';

import { DAY_SELECT_OPTIONS } from 'constants/dateAndTime';

import { CREATE_URBANTZ_ROUND_PRESET_MUTATION } from 'data/mutations/urbantzRoundPreset';
import {
    CREATE_URBANTZ_ROUND_PRESET,
    CREATE_URBANTZ_ROUND_PRESETVariables,
} from 'data/mutations/__generated__/CREATE_URBANTZ_ROUND_PRESET';

import { Loader, LoaderModeType } from 'components/Loader';
import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { Option, TotemSelect } from 'components/TotemSelect';

import { urbantzRoundPresetsUpdatedOrCreatedHandler } from '../cacheHandlers/urbantzRoundPresetsCacheHandler';

export function CreateUrbantzRoundPresetPopup({
    isOpen,
    setIsOpen,
}: {
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
}) {
    const [selectedDay, setSelectedDay] = useState<Option<number> | null>(null);

    const [createUrbantzRoundPreset, { loading: creationLoading }] = useMutation<
        CREATE_URBANTZ_ROUND_PRESET,
        CREATE_URBANTZ_ROUND_PRESETVariables
    >(CREATE_URBANTZ_ROUND_PRESET_MUTATION);

    async function handleSubmit() {
        if (!selectedDay) {
            return;
        }
        const { data } = await createUrbantzRoundPreset({ variables: { dayIndex: selectedDay.value } });

        if (!data?.createUrbantzRoundPreset) {
            throw new Error('Une erreur est survenue lors de la création de la tournée prédéfinie');
        }

        const { createUrbantzRoundPreset: newUrbantzRoundPreset } = data;

        urbantzRoundPresetsUpdatedOrCreatedHandler([newUrbantzRoundPreset]);

        setIsOpen(false);
    }

    return (
        <TotemPopup
            title="Ajouter une tournée prédéfinie"
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            contentOverflow="visible"
        >
            <TotemSelect
                placeholder="Sélectionner un jour"
                isDisabled={creationLoading}
                value={selectedDay}
                options={DAY_SELECT_OPTIONS}
                onChange={(option) => {
                    if (option) {
                        setSelectedDay(option);
                    }
                }}
            />
            <SubmitContainer>
                <ValidationButton onClick={handleSubmit}>
                    {creationLoading ? <Loader size="20px" mode={LoaderModeType.Spin} /> : 'Confirmer'}
                </ValidationButton>
            </SubmitContainer>
        </TotemPopup>
    );
}

const SubmitContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 15px;
`;

const ValidationButton = styled(TotemPrimaryButton)`
    margin-top: 15px;
`;

import React, { useEffect, useState } from 'react';
import { IconChevronsRight, IconChevronsLeft } from '@tabler/icons';
import styled, { css } from 'styled-components';
import { Loader, LoaderModeType } from './Loader';

type Action = {
    icon?: JSX.Element;
    label: string;
    onClick: (...args: any[]) => void;
    hidden?: boolean;
    loading?: boolean;
};

type ActionSection = {
    title?: string;
    actions: Action[];
};

export const ActionBar = ({
    actionSections,
    isInitiallyOpen = false,
}: {
    actionSections: ActionSection[];
    isInitiallyOpen?: boolean;
}) => {
    const [isHidden, setIsHidden] = useState(true);

    useEffect(() => {
        setIsHidden(!isInitiallyOpen);
    }, [setIsHidden, isInitiallyOpen]);

    return (
        <OuterContainer isHidden={isHidden}>
            <ExpandIconContainer onClick={() => setIsHidden(!isHidden)}>
                {isHidden ? <IconChevronsLeft /> : <IconChevronsRight />}
            </ExpandIconContainer>
            <Container>
                {actionSections.map((actionSection, index) => {
                    const { title, actions } = actionSection;

                    return (
                        <Section key={index}>
                            {title ? <Title>{title}</Title> : null}
                            {actions.map(({ icon, label, loading, onClick, hidden }, index) => (
                                <ActionContainer disabled={hidden || loading} key={index} onClick={onClick}>
                                    {loading ? (
                                        <LoaderContainer>
                                            <Loader size="22px" mode={LoaderModeType.Spin} />
                                        </LoaderContainer>
                                    ) : null}
                                    {icon ? <ActionIcon>{icon}</ActionIcon> : null}
                                    <ActionLabel>{label}</ActionLabel>
                                </ActionContainer>
                            ))}
                        </Section>
                    );
                })}
            </Container>
        </OuterContainer>
    );
};
const COLLAPSED_BAR_WIDTH = 36;
const BAR_WIDTH = 200;

const OuterContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    height: 100%;
    margin-left: auto;
    width: ${({ isHidden }: { isHidden: boolean }) =>
        isHidden ? COLLAPSED_BAR_WIDTH : COLLAPSED_BAR_WIDTH + BAR_WIDTH}px;
    transition: width 0.6s ease-in-out;
    border-left: 1px solid ${({ theme }) => theme.lightBorderColor};
    background-color: ${({ theme }) => theme.cardBackgroundColor};
    overflow: hidden;
`;

const ExpandIconContainer = styled.div`
    padding: 5px;
    font-size: 26px;
    color: ${({ theme }) => theme.ctaPrimaryColor};
    cursor: pointer;
`;

const Container = styled.div`
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    padding: 5px;
    width: ${BAR_WIDTH}px;
`;

const Section = styled.div`
    display: flex;
    flex-direction: column;
`;

const Title = styled.div`
    display: flex;
    flex: 1;
    font-size: 22px;
    font-weight: 800;
    color: ${({ theme }) => theme.ctaPrimaryColor};
    margin-left: 31px;
`;

const ActionContainer = styled.div<{ disabled?: boolean }>`
    display: flex;
    flex: 1;
    align-items: center;
    border-radius: ${({ theme }) => theme.borderRadius};
    padding: 5px;
    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.overlayColor};
    }

    ${({ disabled }: { disabled?: boolean }) =>
        disabled &&
        css`
            opacity: 0.4;
            cursor: default;
            pointer-events: none;
        `}
`;

const LoaderContainer = styled.div`
    height: 22px;
    width: 22px;
`;

const ActionIcon = styled.div`
    flex-shrink: 0;
    height: 22px;
`;

const ActionLabel = styled.div`
    font-size: 18px;
    color: ${({ theme }) => theme.ctaPrimaryColor};
    margin-left: 5px;
`;

import gql from 'graphql-tag';
import { MICROSTORE_LOCATION_FRAGMENT } from 'data/fragments/microstoreLocation';

export const UPDATE_LOCATIONS_WITH_TARGET_STOCK_MUTATION = gql`
    mutation UPDATE_LOCATIONS_WITH_TARGET_STOCK(
        $locationIdsWithTargetStock: [MicrostoreLocationIdWithTargetStockInput!]!
    ) {
        updateLocationsWithTargetStock(locationIdsWithTargetStock: $locationIdsWithTargetStock) {
            ...MicrostoreLocationFragment
        }
    }
    ${MICROSTORE_LOCATION_FRAGMENT}
`;

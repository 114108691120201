import React from 'react';

import { GET_ORGANIZATION_organization } from 'data/queries/__generated__/GET_ORGANIZATION';

import { SectionColumn, SectionContainer } from 'components/DetailsView/Section';
import { StateHistory } from 'components/DetailsView';

import { StatusTag } from 'pages/Organizations/components/StatusTag';

export const StatesSection = ({ organization }: { organization: GET_ORGANIZATION_organization }) => {
    const { state, stateHistory } = organization;
    const formattedStateHistory = stateHistory.map((stateRecord) => ({
        ...stateRecord,
        state: <StatusTag state={stateRecord.state} />,
    }));

    return (
        <SectionContainer title="Statuts et historique" isInitiallyOpen>
            <SectionColumn>
                <StatusTag state={state} showHeading />
                <StateHistory records={formattedStateHistory} />
            </SectionColumn>
        </SectionContainer>
    );
};

import React, { useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';

import { FaTrash } from 'react-icons/fa';

import {
    CONDITIONAL_FIELDS,
    CONDITIONAL_MODIFICATION_FIELDS,
    FieldType,
    OPTIONAL_CREATION_FIELDS,
    OPTIONAL_UPDATE_FIELDS,
    REQUIRED_ACTIVE_PRODUCT_FIELDS,
    REQUIRED_CREATION_FIELDS,
    REQUIRED_UPDATE_FIELDS,
} from '../constants/csvFields';
import { colors } from 'constants/colors';
import { CREATE_PRODUCTS_FROM_IMPORT_MUTATION, UPDATE_PRODUCTS_FROM_IMPORT_MUTATION } from 'data/mutations/product';
import {
    CREATE_PRODUCTS_FROM_IMPORT,
    CREATE_PRODUCTS_FROM_IMPORTVariables,
} from 'data/mutations/__generated__/CREATE_PRODUCTS_FROM_IMPORT';
import {
    UPDATE_PRODUCTS_FROM_IMPORT,
    UPDATE_PRODUCTS_FROM_IMPORTVariables,
} from 'data/mutations/__generated__/UPDATE_PRODUCTS_FROM_IMPORT';

import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { TotemLabel } from 'components/TotemLabel';
import { CsvDataRow, TotemCsvInputButton } from 'components/TotemCsvInputButton';
import { Loader, LoaderModeType } from 'components/Loader';

import { checkProductCsvImport } from './helpers/checkProductCsvImport';
import { formatProductCsvData } from './helpers/formatProductCsvData';

export enum ImportType {
    CREATION = 'Création',
    UPDATE = 'Modification',
}

export const CsvImport = () => {
    const [importType, setImportType] = useState<ImportType | null>();
    const [csvData, setCsvData] = useState<Array<CsvDataRow>>([]);
    const [createProducts, { loading: loadingCreationCsv }] = useMutation<
        CREATE_PRODUCTS_FROM_IMPORT,
        CREATE_PRODUCTS_FROM_IMPORTVariables
    >(CREATE_PRODUCTS_FROM_IMPORT_MUTATION);
    const [updateProducts, { loading: loadingUpdateCsv }] = useMutation<
        UPDATE_PRODUCTS_FROM_IMPORT,
        UPDATE_PRODUCTS_FROM_IMPORTVariables
    >(UPDATE_PRODUCTS_FROM_IMPORT_MUTATION);

    const loadingCsv = loadingCreationCsv || loadingUpdateCsv;

    const handleCSVImport = async () => {
        if (importType) {
            const { success, error } = checkProductCsvImport({ csvData, importType });
            if (!success) {
                setCsvData([]);
                toast.error(error, { autoClose: false });
                return;
            }

            const formattedData = formatProductCsvData({ csvData, importType });

            if (importType === ImportType.CREATION) {
                const { data } = await createProducts({
                    variables: {
                        productsToCreate: formattedData,
                    },
                });

                if (data) {
                    const {
                        createProductsFromImport: { success, error, products },
                    } = data;
                    if (success && products) {
                        const productIds = products.map(({ _id }) => _id).join(', ');
                        toast.success(`Les produits ont bien été crées ! Ids: ${productIds}`, { autoClose: false });
                    } else {
                        if (error) {
                            toast.error(error);
                        } else {
                            throw Error("Une erreur inconnue s'est produite");
                        }
                    }
                } else {
                    throw Error("Une erreur inconnue s'est produite");
                }
            } else {
                const { data } = await updateProducts({
                    variables: {
                        productsToUpdate: formattedData,
                    },
                });

                if (data) {
                    const {
                        updateProductsFromImport: { success, error, products },
                    } = data;
                    if (success && products) {
                        toast.success('Les produits ont bien été mis à jour !');
                    } else {
                        if (error) {
                            toast.error(error, { autoClose: false });
                        } else {
                            throw Error("Une erreur inconnue s'est produite");
                        }
                    }
                } else {
                    throw Error("Une erreur inconnue s'est produite");
                }
            }
        }
    };

    const requiredFields = importType === ImportType.CREATION ? REQUIRED_CREATION_FIELDS : REQUIRED_UPDATE_FIELDS;
    const optionalFields = importType === ImportType.CREATION ? OPTIONAL_CREATION_FIELDS : OPTIONAL_UPDATE_FIELDS;

    return (
        <Content>
            <SelectionSection>
                <SelectionContainer>
                    <TotemLabel>Sélectionnez le type d'import</TotemLabel>
                    <OptionsContainer>
                        <RadioOption>
                            <RadioButton name="import" onChange={() => setImportType(ImportType.CREATION)} /> Création
                            des produits
                        </RadioOption>
                        <RadioOption>
                            <RadioButton name="import" onChange={() => setImportType(ImportType.UPDATE)} /> Modification
                            des produits
                        </RadioOption>
                    </OptionsContainer>
                    {csvData.length ? (
                        <ButtonContainer>
                            <TotemPrimaryButton onClick={() => setCsvData([])}>
                                <FaTrash data-test="trash-icon" size={12} color={colors.pureWhite} />
                            </TotemPrimaryButton>
                            <TotemPrimaryButton minWidth="105px" onClick={handleCSVImport}>
                                {loadingCsv ? <Loader size="18px" mode={LoaderModeType.Spin} /> : 'Import CSV'}
                            </TotemPrimaryButton>
                        </ButtonContainer>
                    ) : (
                        <TotemCsvInputButton onCsvDataUpload={setCsvData} disabled={!importType} />
                    )}
                    {importType === ImportType.UPDATE ? (
                        <Warning>
                            Attention : un csv avec une cellule vide, impliquera la suppression de la donnée actuelle.
                            Si vous ne voulez pas mettre à jour un champ, ne l'incluez simplement pas dans votre csv,
                            seul '_id' est requis
                        </Warning>
                    ) : null}
                </SelectionContainer>
                {importType ? (
                    <ColumnContainer>
                        <TotemLabel>Champs requis pour la {importType}:</TotemLabel>
                        {requiredFields.map((field: FieldType, index) => {
                            const { name, displayInfo = '' } = field;
                            return (
                                <div key={index}>
                                    • <b>{name}</b> {displayInfo}
                                </div>
                            );
                        })}
                        {importType === ImportType.UPDATE ? (
                            <>
                                <NonEmptyLabel>
                                    Champs qui, si définis, ne peuvent pas avoir de valeurs vides:
                                </NonEmptyLabel>
                                {REQUIRED_CREATION_FIELDS.map((field: FieldType, index) => {
                                    return <div key={index}>• {field.name}</div>;
                                })}
                                <NonEmptyLabel>
                                    Champs qui, si définis, et produit en état actif, ne peuvent pas avoir de valeurs
                                    vides:
                                </NonEmptyLabel>
                                {REQUIRED_ACTIVE_PRODUCT_FIELDS.map((field: string, index) => {
                                    return <div key={index}>• {field}</div>;
                                })}
                            </>
                        ) : null}
                    </ColumnContainer>
                ) : null}
                {importType ? (
                    <ColumnContainer>
                        <TotemLabel>Champs optionnels pour la {importType}:</TotemLabel>
                        {optionalFields.map((field: FieldType, index) => {
                            const { name, displayInfo = '' } = field;
                            return (
                                <div key={index}>
                                    • <b>{name}</b> {displayInfo}
                                </div>
                            );
                        })}
                    </ColumnContainer>
                ) : null}
                {importType ? (
                    <ColumnContainer>
                        <TotemLabel>Champs conditionnels pour la {importType}:</TotemLabel>
                        {CONDITIONAL_FIELDS.map((field: FieldType, index) => {
                            const { name, displayInfo = '' } = field;
                            return (
                                <div key={index}>
                                    • <b>{name}</b> {displayInfo}
                                </div>
                            );
                        })}
                        {importType === ImportType.UPDATE &&
                            CONDITIONAL_MODIFICATION_FIELDS.map((field: FieldType, index) => {
                                const { name, displayInfo = '' } = field;
                                return (
                                    <div key={index}>
                                        • <b>{name}</b> {displayInfo}
                                    </div>
                                );
                            })}
                    </ColumnContainer>
                ) : null}
            </SelectionSection>
        </Content>
    );
};

const ButtonContainer = styled.div`
    display: flex;
    width: max-content;

    ${TotemPrimaryButton} {
        margin-left: 5px;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    flex: 1;
    overflow: scroll;
`;

const SelectionSection = styled.div`
    display: flex;
    height: 100%;
    overflow: hidden;
`;

const SelectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 20%;
`;

const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    ::-webkit-scrollbar {
        width: 5px;
        height: 0;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: ${({ theme }) => theme.borderRadius};
        background-color: ${({ theme }) => theme.ctaPrimaryColor};
    }
    margin-left: 10px;
    font-size: 14px;
    width: 30%;
`;

const OptionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 10px 0 20px;
`;

const RadioOption = styled.div`
    display: flex;
    font-size: 18px;
    margin-bottom: 5px;
`;

const Warning = styled.div`
    display: flex;
    margin-top: 20px;
`;

const RadioButton = styled.input.attrs({ type: 'radio' })`
    margin-right: 5px;
`;

const NonEmptyLabel = styled(TotemLabel)`
    margin-top: 20px;
    font-size: 12px;
`;

import React, { Dispatch, SetStateAction } from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';

import { GET_RITUAL_TEMPLATES } from 'data/queries/__generated__/GET_RITUAL_TEMPLATES';
import { GET_RITUAL_TEMPLATES_QUERY } from 'data/queries/ritualTemplates';

import { TotemPopup } from 'components/TotemPopup';
import { Loader, LoaderModeType } from 'components/Loader';
import { colors } from 'constants/colors';

export const RitualPopup = ({
    isOpen,
    setIsOpen,
    setChosenTemplate,
}: {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    setChosenTemplate: Dispatch<SetStateAction<{ name: string; imageUrl: string } | null>>;
}) => {
    const {
        data: ritualTemplatesData,
        loading: ritualTemplatesLoading,
        error: ritualTemplatesError,
    } = useQuery<GET_RITUAL_TEMPLATES>(GET_RITUAL_TEMPLATES_QUERY);

    if (ritualTemplatesLoading) {
        return null;
    }

    if (ritualTemplatesError || !ritualTemplatesData) {
        throw new Error('An error occurred');
    }

    const { ritualTemplates } = ritualTemplatesData;

    return (
        <TotemPopup
            title="Créer un rituel à partir d'un modèle"
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            contentOverflow="scroll"
        >
            {ritualTemplatesLoading ? (
                <Loader mode={LoaderModeType.Spin} />
            ) : (
                <TilesList>
                    {ritualTemplates.map((ritualTemplate) => {
                        const { _id, imageUrl, name } = ritualTemplate;
                        return (
                            <RitualTemplateTile
                                key={_id}
                                onClick={() => {
                                    setChosenTemplate({
                                        name,
                                        imageUrl,
                                    });
                                    setIsOpen(false);
                                }}
                            >
                                <ImageContainer>
                                    <img
                                        style={{ objectFit: 'cover' }}
                                        src={imageUrl}
                                        alt={name}
                                        width="150px"
                                        height="150px"
                                    />
                                    <Gradient />
                                </ImageContainer>
                                <TileFooter>
                                    <NameContainer>
                                        <Name>{name}</Name>
                                    </NameContainer>
                                </TileFooter>
                            </RitualTemplateTile>
                        );
                    })}
                </TilesList>
            )}
        </TotemPopup>
    );
};

const TilesList = styled.div`
    display: flex;
    justify-content: stretch;
    align-items: flex-start;
    flex-wrap: wrap;
    flex: 1;
    max-width: 850px;
`;

const ImageContainer = styled.div`
    position: relative;
    width: 150px;
`;

const Gradient = styled.div`
    position: absolute;
    width: 150px;
    height: 33%;
    bottom: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 62.5%, #ffffff 100%);
`;

const RitualTemplateTile = styled.div`
    display: flex;
    flex-direction: column;
    align-self: stretch;
    width: 150px;
    overflow: hidden;
    border-radius: 5px;
    margin-top: 10px;
    margin-left: 10px;
    cursor: pointer;
`;

const TileFooter = styled.div`
    padding: 0px 15px;
    flex: 1;
    background-color: ${colors.pureWhite};
    width: 150px;
`;

const NameContainer = styled.div`
    margin-top: -10px;
    flex: 1;
    transform: rotate(-3deg);
`;

const Name = styled.span`
    font-family: Totem;
    font-size: 26px;
    line-height: 25px;
    color: ${colors.pink};
`;

import React from 'react';

import { SiteSetupFragment } from 'data/fragments/__generated__/SiteSetupFragment';

import { SectionContainer } from 'components/DetailsView/Section';
import { isPhotosInstruction } from '../utils/typePredicates';
import { SetupInstructionFragment_PhotosInstruction } from 'data/fragments/__generated__/SetupInstructionFragment';
import { TotemGallery } from 'components/TotemGallery';
import styled from 'styled-components';

export const PhotosSection = ({ siteSetup }: { siteSetup: SiteSetupFragment }) => {
    const storePhotosInstructions = siteSetup.microstoreColumnGroupSetups
        .flatMap(({ instructionsAfterColumns, instructionsBeforeColumns, microstoreColumnSetups }) => [
            ...instructionsBeforeColumns,
            ...microstoreColumnSetups.flatMap(({ instructions }) => instructions),
            ...instructionsAfterColumns,
        ])
        .filter((instruction) => isPhotosInstruction(instruction)) as SetupInstructionFragment_PhotosInstruction[];

    const freefoodPhotosInstructions =
        (siteSetup.freefoodSetup?.instructions?.filter((instruction) =>
            isPhotosInstruction(instruction),
        ) as SetupInstructionFragment_PhotosInstruction[]) ?? [];

    const storePhotos = storePhotosInstructions.flatMap(({ photos }) =>
        photos.flatMap(({ type, urls }) => urls.map((url, index) => ({ label: `${type} ${index}`, url }))),
    );
    const freefoodPhotos = freefoodPhotosInstructions.flatMap(({ photos }) =>
        photos.flatMap(({ type, urls }) => urls.map((url, index) => ({ label: `${type} ${index}`, url }))),
    );

    return (
        <SectionContainer title="Toutes les Photos" isInitiallyOpen>
            <>
                <Title>Photos Freefood</Title>
                <TotemGallery images={freefoodPhotos} />
                <Title>Photos Store</Title>
                <TotemGallery images={storePhotos} />
            </>
        </SectionContainer>
    );
};

const Title = styled.div`
    margin-top: 15px;
    font-weight: bold;
    font-size: 18px;
`;

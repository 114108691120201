import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import styled from 'styled-components';

import { PAGES } from '../../constants/pages';

import { GET_MICROSTORE_COLUMN_TEMPLATES_QUERY } from 'data/queries/microstoreColumnTemplate';
import { GET_MICROSTORE_COLUMN_TEMPLATES } from 'data/queries/__generated__/GET_MICROSTORE_COLUMN_TEMPLATES';

import { Header, HeaderTitle } from 'components/Header';
import { FieldToDisplay, ListView } from 'components/ListView';
import { Loader } from 'components/Loader';
import { PageTitle } from 'components/PageTitle';
import { TotemInput } from 'components/TotemInput';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { CreateMicrostoreColumnTemplatePopup } from './Popups/CreateMicrostoreColumnTemplatePopup';
import { DeleteMicrostoreColumnTemplatesPopup } from './Popups/DeleteMicrostoreColumnTemplatesPopup';
import { MICROSTORE_COLUMN_TEMPLATE_TYPE_LABELS } from './constants/types';

type FormattedMicrostoreColumnTemplateType = {
    _id: string;
    name: string;
    type: string;
};

export const MicrostoreColumnTemplates = () => {
    const [selectedMicrostoreColumnTemplateIds, setSelectedMicrostoreColumnTemplateIds] = useState<string[]>([]);

    const [isCreateMicrostoreColumnTemplatePopupOpen, setIsCreateMicrostoreColumnTemplatePopupOpen] =
        useState<boolean>(false);
    const [isDeleteMicrostoreColumnTemplatesPopupOpen, setIsDeleteMicrostoreColumnTemplatesPopupOpen] =
        useState<boolean>(false);

    const [filterString, setFilterString] = useState('');

    const {
        loading: microstoreColumnTemplatesLoading,
        data: microstoreColumnTemplatesData,
        error: microstoreColumnTemplatesError,
    } = useQuery<GET_MICROSTORE_COLUMN_TEMPLATES>(GET_MICROSTORE_COLUMN_TEMPLATES_QUERY);

    if (microstoreColumnTemplatesLoading && !microstoreColumnTemplatesData) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (microstoreColumnTemplatesError || !microstoreColumnTemplatesData) {
        throw new Error('Une erreur est survenue lors de la récupération des templates de colonne');
    }

    const { microstoreColumnTemplatesQuery: microstoreColumnTemplates } = microstoreColumnTemplatesData;

    const MICROSTORE_COLUMN_TEMPLATE_FIELDS_TO_DISPLAY: FieldToDisplay<FormattedMicrostoreColumnTemplateType>[] = [
        {
            fieldName: 'name',
            label: 'Nom',
        },
        {
            fieldName: 'type',
            label: 'Type de colonne',
        },
    ];

    const filteredMicrostoreColumnTemplates = microstoreColumnTemplates.filter(
        ({ name }) => name.toLowerCase().search(filterString.toLowerCase()) !== -1,
    );

    const sortedMicrostoreColumnTemplates = [...filteredMicrostoreColumnTemplates].sort(
        (microstoreColumnTemplateA, microstoreColumnTemplateB) =>
            microstoreColumnTemplateA.name.localeCompare(microstoreColumnTemplateB.name),
    );

    const formattedMicrostoreColumnTemplates: FormattedMicrostoreColumnTemplateType[] =
        sortedMicrostoreColumnTemplates.map(({ _id, name, type }) => {
            return {
                _id,
                name,
                type: MICROSTORE_COLUMN_TEMPLATE_TYPE_LABELS[type],
            };
        });

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.microstoreColumnTemplates} />
                </HeaderTitle>
                <ActionContainer>
                    {selectedMicrostoreColumnTemplateIds.length ? (
                        <TotemPrimaryButton
                            onClick={() => {
                                setIsDeleteMicrostoreColumnTemplatesPopupOpen(true);
                            }}
                        >
                            Supprimer les templates de colonne
                        </TotemPrimaryButton>
                    ) : null}
                    <TotemPrimaryButton
                        onClick={() => {
                            setIsCreateMicrostoreColumnTemplatePopupOpen(true);
                        }}
                    >
                        Créer un template de colonne
                    </TotemPrimaryButton>
                </ActionContainer>
            </Header>
            <Content>
                <TotemInput
                    label="Recherche"
                    onChange={setFilterString}
                    placeholder="Nom de la colonne"
                    value={filterString}
                    autoFocus={true}
                />
                <ListView<FormattedMicrostoreColumnTemplateType>
                    fieldsToDisplay={MICROSTORE_COLUMN_TEMPLATE_FIELDS_TO_DISPLAY}
                    items={formattedMicrostoreColumnTemplates}
                    keyExtractor={(item) => item._id}
                    linkBasePath="/microstoreColumnTemplate/"
                    selectedKeys={selectedMicrostoreColumnTemplateIds}
                    setSelectedKeys={setSelectedMicrostoreColumnTemplateIds}
                />
            </Content>
            <CreateMicrostoreColumnTemplatePopup
                isOpen={isCreateMicrostoreColumnTemplatePopupOpen}
                setIsOpen={setIsCreateMicrostoreColumnTemplatePopupOpen}
            />
            <DeleteMicrostoreColumnTemplatesPopup
                microstoreColumnTemplates={microstoreColumnTemplates}
                selectedMicrostoreColumnTemplateIds={selectedMicrostoreColumnTemplateIds}
                setSelectedMicrostoreColumnTemplateIds={setSelectedMicrostoreColumnTemplateIds}
                isOpen={isDeleteMicrostoreColumnTemplatesPopupOpen}
                setIsOpen={setIsDeleteMicrostoreColumnTemplatesPopupOpen}
            />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    flex: 1;
    overflow: hidden;
`;

const ActionContainer = styled.div`
    display: flex;

    & > :not(:first-child) {
        margin-left: 10px;
    }
`;

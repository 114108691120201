import { gql } from '@apollo/client';

export const SUPPLIER_DISCOUNT_FRAGMENT = gql`
    fragment SupplierDiscountFragment on SupplierDiscount {
        _id
        name
        createdAt
        updatedAt
        state
        rate
        productIds
        purchaseStartDate
        purchaseEndDate
        salesStartDate
        salesEndDate
        imageUrl
        discountedProductPrices {
            _id
            purchasePrice
            purchaseDiscountedPrice
            purchaseDiscountAmount
            purchaseDiscountRate
            salesPrice
            salesDiscountedPrice
            salesDiscountAmount
            salesDiscountRate
            product {
                _id
                fullname
                category {
                    _id
                    title
                }
                subcategory {
                    _id
                    name
                }
                suppliers {
                    _id
                    name
                }
            }
        }
    }
`;

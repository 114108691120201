import gql from 'graphql-tag';

import { LOCATION_PLANOGRAM_FRAGMENT } from 'data/fragments/locationPlanogram';

export const GET_SITE_LOCATION_PLANOGRAMS_QUERY = gql`
    query GET_SITE_LOCATION_PLANOGRAMS($siteId: ID!) {
        getSiteLocationPlanograms(siteId: $siteId) {
            ...LocationPlanogramFragment
        }
    }
    ${LOCATION_PLANOGRAM_FRAGMENT}
`;

import React from 'react';

import styled from 'styled-components';

import { GET_DETAILED_ORDER_detailedOrder } from 'data/queries/__generated__/GET_DETAILED_ORDER';

import { SectionColumn, SectionContainer } from 'components/DetailsView/Section';

import { renderAsPrice } from 'helpers/price';

export const ProductsSection = ({ order }: { order: GET_DETAILED_ORDER_detailedOrder }) => {
    const { productsWithQuantityPicked, productsRequestedButNotFullyDelivered } = order;

    const productIds = productsWithQuantityPicked.map(({ _id }) => _id);
    const productsRemovedFromOrder = productsRequestedButNotFullyDelivered.filter(
        ({ _id }) => !productIds.includes(_id),
    );

    return (
        <SectionContainer title="Détails des produits" isInitiallyOpen>
            <SectionColumn>
                <Table>
                    <thead>
                        <tr>
                            <HeaderCell>Nom</HeaderCell>
                            <HeaderCell>Prix (unité)</HeaderCell>
                            <HeaderCell>Qté commandée</HeaderCell>
                            <HeaderCell>Prix commandé</HeaderCell>
                            <HeaderCell>Qté livrée</HeaderCell>
                            <HeaderCell>Prix livré</HeaderCell>
                        </tr>
                    </thead>
                    <tbody>
                        {[...productsWithQuantityPicked]
                            .sort((productA, productB) => productA.name.localeCompare(productB.name))
                            .map((product) => {
                                const { _id, name, quantity, quantityPicked, unitPrice } = product;

                                return (
                                    <tr key={_id}>
                                        <td>{name}</td>
                                        <td>{renderAsPrice(unitPrice)}</td>
                                        <td>{quantity}</td>
                                        <td>{renderAsPrice(unitPrice * (quantity || 0))}</td>
                                        <td>{quantityPicked}</td>
                                        <td>{quantityPicked ? renderAsPrice(unitPrice * quantityPicked) : '-'}</td>
                                    </tr>
                                );
                            })}
                    </tbody>
                    <TableFooter>
                        <tr>
                            <td>TOTAL ({productsWithQuantityPicked.length} produits)</td>
                            <td />
                            <td>
                                {productsWithQuantityPicked.reduce((acc, product) => acc + (product?.quantity || 0), 0)}
                            </td>
                            <td>
                                {renderAsPrice(
                                    productsWithQuantityPicked.reduce(
                                        (acc, product) => acc + (product?.quantity || 0) * product.unitPrice,
                                        0,
                                    ),
                                )}
                            </td>
                            <td>
                                {productsWithQuantityPicked.reduce(
                                    (acc, product) => acc + (product.quantityPicked ?? 0),
                                    0,
                                )}
                            </td>
                            <td>
                                {renderAsPrice(
                                    productsWithQuantityPicked.reduce(
                                        (acc, product) => acc + (product.quantityPicked ?? 0) * product.unitPrice,
                                        0,
                                    ),
                                )}
                            </td>
                        </tr>
                    </TableFooter>
                </Table>
                {productsRemovedFromOrder.length ? (
                    <>
                        <Subtitle>Produits supprimés de la commande pendant le passage en 'Prête'</Subtitle>
                        <Table>
                            <thead>
                                <tr>
                                    <HeaderCell>Nom</HeaderCell>
                                    <HeaderCell>Prix (unité)</HeaderCell>
                                    <HeaderCell>Qté commandée</HeaderCell>
                                    <HeaderCell>Prix commandé</HeaderCell>
                                </tr>
                            </thead>
                            <tbody>
                                {[...productsRemovedFromOrder]
                                    .sort((productA, productB) => productA.name.localeCompare(productB.name))
                                    .map((product) => {
                                        const { _id, name, quantityRequested, unitPrice } = product;

                                        return (
                                            <tr key={_id}>
                                                <td>{name}</td>
                                                <td>{renderAsPrice(unitPrice)}</td>
                                                <td>{quantityRequested}</td>
                                                <td>{renderAsPrice(unitPrice * (quantityRequested || 0))}</td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </Table>
                    </>
                ) : null}
            </SectionColumn>
        </SectionContainer>
    );
};

const Table = styled.table`
    border-spacing: 0px;
`;

const HeaderCell = styled.th`
    text-align: left;
`;

const TableFooter = styled.tfoot`
    & td {
        font-weight: 800;
        border-top: 1px solid ${({ theme }) => theme.lightBorderColor};
    }
`;

const Subtitle = styled.div`
    color: ${({ theme }) => theme.errorColor};
    font-weight: 600;
`;

import gql from 'graphql-tag';

export const STOCK_TRANSFER_FRAGMENT = gql`
    fragment StockTransferFragment on StockTransfer {
        _id
        createdAt
        updatedAt
        transferDate
        destinationSiteId
        destinationSiteName
        originSiteId
        originSiteName
        products {
            productId
            fullname
            originStockAvailable
            destinationStockAvailable
            quantity
        }
        deliveryId
        delivery {
            state
        }
        shouldUpdateStockImmediately
        state
        stateHistory {
            state
            createdAt
            createdBy
            createdByName
        }
    }
`;

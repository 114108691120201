import React, { useState } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import Switch from 'react-switch';

import { GET_ACTIVE_OR_FOR_DATE_ORDERS_QUERY } from 'data/queries/order';
import {
    GET_ACTIVE_OR_FOR_DATE_ORDERS,
    GET_ACTIVE_OR_FOR_DATE_ORDERS_activeOrForDateOrders,
} from 'data/queries/__generated__/GET_ACTIVE_OR_FOR_DATE_ORDERS';
import { OrderState } from 'data/__generated__';
import { PAGES } from 'constants/pages';
import { colors } from 'constants/colors';

import { Header, HeaderTitle } from 'components/Header';
import { Loader } from 'components/Loader';
import { PageTitle } from 'components/PageTitle';
import { TrelloView } from 'components/TrelloView/TrelloView';
import { OrderTrelloItem } from 'pages/Orders/components/OrderTrelloItem';
import { TotemLabel } from 'components/TotemLabel';

import { dateFromString, formatDateAsAnniversary } from 'helpers/dateTimes';
import { TotemDatePicker } from 'components/TotemDatePicker';

export const ORDER_COLUMN_LABELS = {
    archived: 'Archivées',
    created: 'Créées',
    validated: 'Validées',
    readyRecurring: 'Prêtes récurrentes',
    readyPunctual: 'Prêtes ponctuelles',
    onboarding: 'Première commande',
    pausedToReactivate: 'En pause à reactiver',
    paused: 'En pause',
    draftNProspects: 'Prospect',
    inProgress: 'En cours',
};

export const Orders = () => {
    const [selectedOrderIds, setSelectedOrderIds] = useState<string[]>([]);
    const [deliverySearchDate, setDeliverySearchDate] = useState<Date | null>(null);
    const [sortByName, setSortByName] = useState(true);
    const {
        data: ordersData,
        loading: ordersLoading,
        error: ordersError,
    } = useQuery<GET_ACTIVE_OR_FOR_DATE_ORDERS>(GET_ACTIVE_OR_FOR_DATE_ORDERS_QUERY, {
        fetchPolicy: 'cache-and-network',
        variables: {
            deliveryDate: deliverySearchDate ? formatDateAsAnniversary({ dateTime: deliverySearchDate }) : null,
        },
    });

    if (ordersLoading && !ordersData) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (ordersError || !ordersData) {
        throw new Error('Une erreur est survenue lors de la récupération des commandes');
    }

    const { activeOrForDateOrders: unsortedOrders } = ordersData;

    const orders = sortByName
        ? [...unsortedOrders].sort((orderA, orderB) => (orderA.organization.name > orderB.organization.name ? 1 : -1))
        : [...unsortedOrders].sort((orderA, orderB) =>
              !orderB.date_delivery ||
              (orderA.date_delivery && dateFromString(orderA.date_delivery) > dateFromString(orderB.date_delivery))
                  ? -1
                  : 1,
          );

    const today = new Date();
    const todayNumeric = today.setHours(0, 0, 0, 0);

    const {
        archived,
        readyRecurring,
        readyPunctual,
        validated,
        onboarding,
        pausedToReactivate,
        paused,
        draftNProspects,
        inProgress,
        created,
    } = orders.reduce<{
        archived: GET_ACTIVE_OR_FOR_DATE_ORDERS_activeOrForDateOrders[];
        readyRecurring: GET_ACTIVE_OR_FOR_DATE_ORDERS_activeOrForDateOrders[];
        readyPunctual: GET_ACTIVE_OR_FOR_DATE_ORDERS_activeOrForDateOrders[];
        validated: GET_ACTIVE_OR_FOR_DATE_ORDERS_activeOrForDateOrders[];
        onboarding: GET_ACTIVE_OR_FOR_DATE_ORDERS_activeOrForDateOrders[];
        pausedToReactivate: GET_ACTIVE_OR_FOR_DATE_ORDERS_activeOrForDateOrders[];
        paused: GET_ACTIVE_OR_FOR_DATE_ORDERS_activeOrForDateOrders[];
        draftNProspects: GET_ACTIVE_OR_FOR_DATE_ORDERS_activeOrForDateOrders[];
        inProgress: GET_ACTIVE_OR_FOR_DATE_ORDERS_activeOrForDateOrders[];
        created: GET_ACTIVE_OR_FOR_DATE_ORDERS_activeOrForDateOrders[];
    }>(
        (acc, order) => {
            if (order.state === OrderState.Archived) {
                acc.archived.push(order);
            } else if (order.state === OrderState.Ready) {
                order.isPunctual ? acc.readyPunctual.push(order) : acc.readyRecurring.push(order);
            } else if (order.state === OrderState.Validated) {
                order.first_order ? acc.onboarding.push(order) : acc.validated.push(order);
            } else if (order.state === OrderState.Paused) {
                if (order?.pausedInfo?.returnDate) {
                    order?.pausedInfo?.returnDate > todayNumeric
                        ? acc.paused.push(order)
                        : acc.pausedToReactivate.push(order);
                } else {
                    acc.paused.push(order);
                }
            } else if ([OrderState.Created, OrderState.InProgress].includes(order.state)) {
                if (order.isPunctual || order.first_order) {
                    acc.draftNProspects.push(order);
                } else {
                    order.state === OrderState.InProgress ? acc.inProgress.push(order) : acc.created.push(order);
                }
            }

            return acc;
        },
        {
            archived: [],
            readyRecurring: [],
            readyPunctual: [],
            validated: [],
            onboarding: [],
            pausedToReactivate: [],
            paused: [],
            draftNProspects: [],
            inProgress: [],
            created: [],
        },
    );

    function toggleOrderSelection(orderIdToToggle: string) {
        if (selectedOrderIds.includes(orderIdToToggle)) {
            setSelectedOrderIds(selectedOrderIds.filter((OrderId) => OrderId !== orderIdToToggle));
        } else {
            setSelectedOrderIds([...selectedOrderIds, orderIdToToggle]);
        }
    }

    function mapOrdersToTrelloItems(
        ordersToMap: GET_ACTIVE_OR_FOR_DATE_ORDERS_activeOrForDateOrders[],
        shouldShowState = false,
    ) {
        return ordersToMap.map((order) => ({
            _id: order._id,
            content: (
                <OrderTrelloItem
                    order={order}
                    isSelected={selectedOrderIds.includes(order._id)}
                    toggleSelection={() => toggleOrderSelection(order._id)}
                    shouldShowState={shouldShowState}
                />
            ),
        }));
    }

    const initialData = {
        columns: [
            {
                title: ORDER_COLUMN_LABELS.readyRecurring,
                items: mapOrdersToTrelloItems(readyRecurring),
            },

            {
                title: ORDER_COLUMN_LABELS.readyPunctual,
                items: mapOrdersToTrelloItems(readyPunctual),
            },
            {
                title: ORDER_COLUMN_LABELS.validated,
                items: mapOrdersToTrelloItems(validated),
            },
            {
                title: ORDER_COLUMN_LABELS.onboarding,
                items: mapOrdersToTrelloItems(onboarding),
            },
            {
                title: ORDER_COLUMN_LABELS.inProgress,
                items: mapOrdersToTrelloItems(inProgress),
            },
            {
                title: ORDER_COLUMN_LABELS.pausedToReactivate,
                items: mapOrdersToTrelloItems(pausedToReactivate),
            },
            {
                title: ORDER_COLUMN_LABELS.created,
                items: mapOrdersToTrelloItems(created),
            },
            {
                title: ORDER_COLUMN_LABELS.paused,
                items: mapOrdersToTrelloItems(paused),
            },
            {
                title: ORDER_COLUMN_LABELS.draftNProspects,
                items: mapOrdersToTrelloItems(draftNProspects),
            },
            {
                title: ORDER_COLUMN_LABELS.archived,
                items: mapOrdersToTrelloItems(archived),
            },
        ],
    };

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.orders} />
                </HeaderTitle>
                <OptionContainer>
                    <TotemDatePicker
                        label="Date de livraison"
                        selected={deliverySearchDate}
                        onChange={(date) => {
                            setDeliverySearchDate(date as Date);
                        }}
                        isClearable={true}
                    />
                    <TotemLabel>Trier par date livraison</TotemLabel>
                    <Switch
                        onChange={() => setSortByName(!sortByName)}
                        checked={sortByName}
                        onColor={colors.green}
                        offColor={colors.pantinGrey}
                        checkedIcon={false}
                        uncheckedIcon={false}
                    />
                    <TotemLabel>Trier par nom</TotemLabel>
                </OptionContainer>
            </Header>
            <Content>
                <TrelloViewContainer>
                    <TrelloView
                        initialData={initialData}
                        selectedItemIds={selectedOrderIds}
                        setSelectedItemIds={setSelectedOrderIds}
                        columnWidth="250px"
                    />
                </TrelloViewContainer>
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const OptionContainer = styled.div`
    display: flex;
    align-items: center;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const TrelloViewContainer = styled.div`
    overflow-x: scroll;
    flex: 1;
`;

const Content = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    overflow: hidden;
`;

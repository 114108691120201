import React, { useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import ReactToPrint from 'react-to-print';

import { GET_PRODUCTS_WITH_STOCK_BY_SITES_QUERY } from 'data/queries/product';
import {
    GET_PRODUCTS_WITH_STOCK_BY_SITES,
    GET_PRODUCTS_WITH_STOCK_BY_SITESVariables,
} from 'data/queries/__generated__/GET_PRODUCTS_WITH_STOCK_BY_SITES';

import { Loader, LoaderModeType } from 'components/Loader';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { MicrostoreColumnPrintable } from './MicrostoreColumnPrintable';

import {
    mapMicrostoreColumnGroupToMicrostoreColumnGroupFormValues,
    MicrostoreProductsStockInfoFormValues,
} from 'pages/MicrostoreColumns/MicrostoreColumnDetails/FormHelper/DataMapper';
import { TotemCheckbox } from 'components/TotemCheckbox';
import { useGetMicrostoreSitesWithLocationsInfoQuery } from 'data/__generated__';

export const PrintComponent = ({ siteIds }: { siteIds: string[] }) => {
    const componentRef = useRef(null);

    const [printForFridgeOnly, setPrintForFridgeOnly] = useState<boolean>(false);

    const {
        loading: microstoreSitesLoading,
        data: microstoreSitesData,
        error: microstoreSitesError,
    } = useGetMicrostoreSitesWithLocationsInfoQuery({
        variables: {
            siteIds,
        },
        fetchPolicy: 'no-cache',
    });

    const {
        loading: getProductsWithStockBySitesLoading,
        data: getProductsWithStockBySitesData,
        error: getProductsWithStockBySitesError,
    } = useQuery<GET_PRODUCTS_WITH_STOCK_BY_SITES, GET_PRODUCTS_WITH_STOCK_BY_SITESVariables>(
        GET_PRODUCTS_WITH_STOCK_BY_SITES_QUERY,
        {
            variables: {
                siteIds,
            },
            fetchPolicy: 'no-cache',
        },
    );

    if (microstoreSitesLoading || getProductsWithStockBySitesLoading) {
        return (
            <Container>
                <Loader mode={LoaderModeType.Spin} />
            </Container>
        );
    }

    if (
        microstoreSitesError ||
        !microstoreSitesData ||
        getProductsWithStockBySitesError ||
        !getProductsWithStockBySitesData
    ) {
        throw new Error('Une erreur est survenue lors de la récupération des informations pour impression');
    }

    const { sites } = microstoreSitesData;
    const sortedSites = [...sites].sort((siteA, siteB) => siteA.name.localeCompare(siteB.name));
    const { productsWithStockBySites } = getProductsWithStockBySitesData;

    return (
        <Container>
            <PrintContainer>
                <TotemCheckbox
                    label="N'imprimer que pour les frigos"
                    checked={printForFridgeOnly}
                    onChange={() => {
                        setPrintForFridgeOnly(!printForFridgeOnly);
                    }}
                />
                <ReactToPrint
                    trigger={() => {
                        // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                        // to the root node of the returned component as it will be overwritten.
                        return <TotemPrimaryButton type="button">Imprimer</TotemPrimaryButton>;
                    }}
                    content={() => componentRef.current}
                />
                <HiddenComponent>
                    <div ref={componentRef}>
                        {sortedSites.map((site) => {
                            const microstoreColumnGroupFormValuesArray = site.microstoreColumnGroups.map(
                                (microstoreColumnGroup) =>
                                    mapMicrostoreColumnGroupToMicrostoreColumnGroupFormValues({
                                        microstoreColumnGroup,
                                    }),
                            );

                            // we construct productsStockInfo because we don't need some values while printing. We only need stock on site.
                            const microstoreProductsStockInfo: MicrostoreProductsStockInfoFormValues =
                                productsWithStockBySites.map((product) => {
                                    const stockBySite = product.stockBySites.find(({ siteId }) => siteId === site._id);
                                    return {
                                        ...product,
                                        __typename: 'ProductWithMicrostoreNeededInfo',
                                        stockOnSite: stockBySite?.stock ?? 0,
                                        stockAvailableOnSite: stockBySite?.stockAvailable ?? 0,
                                        stockWarehouse: 0,
                                        stockAvailableWarehouse: 0,
                                        stockUsedInOtherMicrostores: 0,
                                        stockExpectedInSupplyOrders: 0,
                                        transactionsCountForToday: 0,
                                        stockUsedInMicrostore: 0,
                                        stockToRemove: 0,
                                        isPresentTomorrow: true,
                                    };
                                });

                            return (
                                <MicrostoreColumnPrintable
                                    key={site._id}
                                    microstoreColumnGroupFormValuesArray={microstoreColumnGroupFormValuesArray}
                                    microstoreProductsStockInfo={microstoreProductsStockInfo}
                                    printForFridgeOnly={printForFridgeOnly}
                                    site={site}
                                />
                            );
                        })}
                    </div>
                </HiddenComponent>
            </PrintContainer>
        </Container>
    );
};

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const PrintContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    padding: 10px 0;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

const HiddenComponent = styled.div`
    display: none;
`;

import gql from 'graphql-tag';

export const SETUP_INSTRUCTION_FRAGMENT = gql`
    fragment SetupInstructionFragment on SetupInstruction {
        ... on CheckTemperatureInstruction {
            createdAt
            isFinishForced
            isNeeded
            noTemperatureComment
            state
            stateHistory {
                createdAt
                state
            }
            temperature
            type
            updatedAt
        }
        ... on CheckBoxTemperatureInstruction {
            createdAt
            isFinishForced
            isNeeded
            noTemperatureComment
            state
            stateHistory {
                createdAt
                state
            }
            temperature
            thermometerNumber
            type
            updatedAt
        }
        ... on HotDrinksInstruction {
            createdAt
            hasCleanedCollectingTray
            hasCleanedMachine
            hasReplenishedConsumables
            hasReplenishedTanks
            hasRemovedCoffeeGround
            hasRinsedMachine
            isMachineWorking
            isFinishForced
            isNeeded
            needsCleaningMachine
            state
            stateHistory {
                createdAt
                state
            }
            type
            updatedAt
        }
        ... on InventoryInstruction {
            createdAt
            isFinishForced
            isNeeded
            state
            stateHistory {
                createdAt
                state
            }
            type
            updatedAt
        }
        ... on MicrowaveInstruction {
            createdAt
            hasCleanedMicrowaves
            isFinishForced
            isNeeded
            state
            stateHistory {
                createdAt
                state
            }
            type
            updatedAt
        }
        ... on MoveProductsInstruction {
            createdAt
            isFinishForced
            isNeeded
            state
            stateHistory {
                createdAt
                state
            }
            type
            updatedAt
        }
        ... on PhotosInstruction {
            _id
            createdAt
            isFinishForced
            isNeeded
            photos {
                isMandatory
                type
                urls
            }
            state
            stateHistory {
                createdAt
                state
            }
            type
            updatedAt
        }
        ... on RemoveProductsInstruction {
            createdAt
            isFinishForced
            isNeeded
            removedProducts {
                productId
                quantityDetails {
                    expiryDate
                    quantity
                }
            }
            state
            stateHistory {
                createdAt
                state
            }
            type
            updatedAt
        }
        ... on StoreProductsInstruction {
            createdAt
            isFinishForced
            isNeeded
            state
            stateHistory {
                createdAt
                state
            }
            type
            updatedAt
        }
        ... on TheftNoticeInstruction {
            createdAt
            isFinishForced
            isNeeded
            needMoreNotices
            state
            stateHistory {
                createdAt
                state
            }
            type
            updatedAt
        }
    }
`;

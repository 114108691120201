import React from 'react';
import styled from 'styled-components';

import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { PAGES } from '../../constants/pages';

import { formatDateAsAnniversary } from '../../helpers/dateTimes';

import { GET_TERMS_RECORDS_QUERY } from 'data/queries/termsRecord';
import { GET_TERMS_RECORDS, GET_TERMS_RECORDS_termsRecords } from 'data/queries/__generated__/GET_TERMS_RECORDS';

import { Header, HeaderTitle } from 'components/Header';
import { FieldToDisplay, ListView } from 'components/ListView';
import { Loader } from 'components/Loader';
import { PageTitle } from 'components/PageTitle';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

const TERMS_RECORD_FIELDS_TO_DISPLAY: FieldToDisplay<GET_TERMS_RECORDS_termsRecords>[] = [
    {
        fieldName: '_id',
        label: 'Id',
    },
    {
        fieldName: 'createdAt',
        label: 'Date de création',
    },
    {
        fieldName: 'termsOfUseUrl',
        label: 'Lien des CGV',
    },
];

export const TermsRecords = () => {
    const {
        loading: termsLoading,
        data: termsData,
        error: termsError,
    } = useQuery<GET_TERMS_RECORDS>(GET_TERMS_RECORDS_QUERY, { fetchPolicy: 'network-only' });
    if (termsError) {
        throw new Error('Une erreur est survenue lors du chargement des CGV');
    }
    if (termsLoading || !termsData) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    const { termsRecords } = termsData;

    const formattedTermsRecords = termsRecords.map((termsRecord) => {
        return {
            ...termsRecord,
            createdAt: formatDateAsAnniversary({ dateTime: termsRecord.createdAt }),
            termsOfUseUrl: (
                <TermsLink href={termsRecord.termsOfUseUrl} target="_blank" rel="noopener noreferrer">
                    {termsRecord.termsOfUseUrl}
                </TermsLink>
            ),
        };
    });
    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.termsRecords} />
                </HeaderTitle>
                <Link to={PAGES.termsRecordCreate.url}>
                    <TotemPrimaryButton>Créer de nouvelles CGV</TotemPrimaryButton>
                </Link>
            </Header>
            <Content>
                <ListView<GET_TERMS_RECORDS_termsRecords>
                    fieldsToDisplay={TERMS_RECORD_FIELDS_TO_DISPLAY}
                    items={formattedTermsRecords}
                    keyExtractor={(item) => item._id}
                />
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Content = styled.div`
    padding: 15px;
    flex: 1;
`;

const TermsLink = styled.a`
    color: ${({ theme }) => theme.ctaPrimaryColor};
`;

import gql from 'graphql-tag';
import {
    SUPPLY_ORDER_FRAGMENT,
    SUPPLY_ORDER_PRODUCT_ISSUE_FRAGMENT,
    SUPPLY_ORDER_PRODUCT_STOCK_VARIATION_FRAGMENT,
    SUPPLY_ORDER_WITH_SUPPLIER_PRODUCTS_INFO_FRAGMENT,
} from '../fragments/supplyOrder';

export const GET_SUPPLY_ORDERS_QUERY = gql`
    query GET_SUPPLY_ORDERS($displayLimitDateFormatted: String!) {
        supplyOrders(displayLimitDateFormatted: $displayLimitDateFormatted) {
            ...SupplyOrderFragment
            description
            isAtSoldOutRisk
            isMerged
            issues {
                ...SupplyOrderProductIssueFragment
            }
            products {
                _id
                quantity
                supplyOrderId
            }
        }
    }
    ${SUPPLY_ORDER_FRAGMENT}
    ${SUPPLY_ORDER_PRODUCT_ISSUE_FRAGMENT}
`;

export const GET_SUPPLY_ORDER_WITH_SUPPLIER_PRODUCTS_INFO_QUERY = gql`
    query GET_SUPPLY_ORDER_WITH_SUPPLIER_PRODUCTS_INFO($supplyOrderId: String!) {
        supplyOrderWithSupplierProductsInfo(supplyOrderId: $supplyOrderId) {
            ...SupplyOrderWithSupplierProductsInfoFragment
        }
    }
    ${SUPPLY_ORDER_WITH_SUPPLIER_PRODUCTS_INFO_FRAGMENT}
`;

export const GET_SUPPLY_ORDER_SUPPLIER_PRODUCTS_STOCK_VARIATION_QUERY = gql`
    query GET_SUPPLY_ORDER_SUPPLIER_PRODUCTS_STOCK_VARIATION($supplyOrderId: String!) {
        supplyOrderSupplierProductsStockVariation(supplyOrderId: $supplyOrderId) {
            productGroupStockVariations {
                ...SupplyOrderProductStockVariationFragment
                isComputed
            }
            supplyOrderStockVariations {
                ...SupplyOrderProductStockVariationFragment
                isComputed
            }
        }
    }
    ${SUPPLY_ORDER_PRODUCT_STOCK_VARIATION_FRAGMENT}
`;

export const GET_PRODUCTS_TO_ORDER_ONCE_FOR_SUPPLY_ORDER_QUERY = gql`
    query GET_PRODUCTS_TO_ORDER_ONCE_FOR_SUPPLY_ORDER($supplyOrderId: String!) {
        productsToOrderOnceForSupplyOrder(supplyOrderId: $supplyOrderId) {
            _id
            suppliers {
                prices {
                    packagesPerBatch
                }
            }
        }
    }
`;

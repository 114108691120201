import React, { useState } from 'react';

import Collapsible from 'react-collapsible';
import { BsCaretDownFill, BsCaretUpFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const SectionContainer = ({
    children,
    title,
    isInitiallyOpen = false,
    keepOpen = false,
    linkToMore,
    linkToMoreLabel = 'voir plus',
}: {
    children: JSX.Element;
    title: JSX.Element | string;
    isInitiallyOpen?: boolean;
    keepOpen?: boolean;
    linkToMore?: string;
    linkToMoreLabel?: string;
}) => {
    const [isOpen, setIsOpen] = useState(keepOpen || isInitiallyOpen);

    return (
        <CollapsibleContainer>
            <Collapsible
                trigger={
                    <CollapsibleHeader
                        onClick={() => {
                            if (keepOpen) return;

                            setIsOpen(!isOpen);
                        }}
                    >
                        <SectionContainerTitle>
                            {title}
                            {linkToMore ? <SeeMoreLink to={linkToMore}>{linkToMoreLabel}</SeeMoreLink> : null}
                        </SectionContainerTitle>
                        {!keepOpen ? (
                            <IconContainer>
                                {isOpen ? <BsCaretUpFill size="16" /> : <BsCaretDownFill size="16" />}
                            </IconContainer>
                        ) : null}
                    </CollapsibleHeader>
                }
                transitionTime={200}
                triggerDisabled={keepOpen}
                open={isOpen}
                overflowWhenOpen="visible"
                openedClassName="collapsible-opened"
                triggerClassName="collapsible-trigger-closed"
                triggerOpenedClassName="collapsible-trigger-opened"
                contentInnerClassName="collapsible-content-inner"
            >
                {children}
            </Collapsible>
        </CollapsibleContainer>
    );
};

const SectionContainerTitle = styled.h2`
    margin: 0;
    font-size: 20px;
`;

const CollapsibleContainer = styled.div`
    background-color: ${({ theme }) => theme.cardBackgroundColor};
    border-radius: ${({ theme }) => theme.borderRadius};
    border: 1px solid ${({ theme }) => theme.lightBorderColor};

    .Collapsible {
        &__contentInner {
            border-top: 1px solid ${({ theme }) => theme.lightBorderColor};
            padding: 15px;
        }
    }
`;

const CollapsibleHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0 15px 15px;
    font-size: 24px;
    color: ${({ theme }) => theme.textColor};
    cursor: pointer;
`;

const IconContainer = styled.div`
    padding: 0 15px;
`;

const SeeMoreLink = styled(Link)`
    margin-left: 15px;
    font-size: 18px;
    color: ${({ theme }) => theme.textColor};
`;

import gql from 'graphql-tag';

export const OPS_PARAMETER_FRAGMENT = gql`
    fragment OpsParameterFragment on OpsParameter {
        ... on BooleanOpsParameter {
            _id
            key
            name
            booleanValue: value
        }
        ... on NumericalOpsParameter {
            _id
            key
            name
            numericalValue: value
        }
    }
`;

export const BOX_PARAMETER_FRAGMENT = gql`
    fragment BoxParameterFragment on BoxParameter {
        bakery {
            ...OpsParameterFragment
        }
        dry {
            ...OpsParameterFragment
        }
        fresh {
            ...OpsParameterFragment
        }
        fruit {
            ...OpsParameterFragment
        }
    }
    ${OPS_PARAMETER_FRAGMENT}
`;

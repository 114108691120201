import React from 'react';

import fr from 'date-fns/locale/fr';
import DatePicker, { registerLocale } from 'react-datepicker';
import styled from 'styled-components';

import 'react-datepicker/dist/react-datepicker.css';
import { BasicInput } from './BasicInput';
import { TotemLabel } from './TotemLabel';
registerLocale('fr', fr);

type TotemDatePickerType = {
    className?: string;
    label?: string;
    sublabel?: string;
    selected: Date | null;
    showTimeInput?: boolean;
    isClearable?: boolean;
    isDisabled?: boolean;
    shouldAllowPastDates?: boolean;
    onChange: (date: Date | null) => void;
};

export const TotemDatePicker = ({
    className,
    label,
    sublabel,
    selected,
    isClearable = false,
    isDisabled,
    onChange,
    showTimeInput = false,
    shouldAllowPastDates = true,
}: TotemDatePickerType) => {
    const handleDateChange = (date: Date | Date[] | null) => {
        if (Array.isArray(date)) {
            return;
        }
        onChange(date);
    };
    const dateFormat = showTimeInput ? 'dd/MM/yyyy - HH:mm:ss' : 'dd/MM/yyyy';

    const today = new Date();
    const isDateAfterToday = (date: Date) => {
        return date > today;
    };

    return (
        <Container>
            {label ? <TotemLabel>{label}</TotemLabel> : null}
            {sublabel ? <Sublabel>{sublabel}</Sublabel> : null}
            <div>
                <DatePicker
                    selected={selected}
                    onChange={(date) => handleDateChange(date)}
                    locale="fr"
                    disabled={isDisabled}
                    dateFormat={dateFormat}
                    className={className}
                    customInput={<BasicInput />}
                    wrapperClassName="totem-date-picker"
                    showTimeInput={showTimeInput}
                    timeInputLabel={showTimeInput ? 'Heure : ' : undefined}
                    isClearable={isClearable}
                    filterDate={(date) => shouldAllowPastDates || isDateAfterToday(date)}
                />
            </div>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    & > :not(:first-child) {
        margin-top: 5px;
    }
`;

const Sublabel = styled.span`
    color: ${({ theme }) => theme.infoTextColor};
    font-size: 13px;
`;

import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import styled from 'styled-components';

import { PAGES } from '../../constants/pages';

import { GET_PRODUCT_SWITCHES_QUERY } from 'data/queries/productSwitch';
import { GET_PRODUCT_SWITCHES } from 'data/queries/__generated__/GET_PRODUCT_SWITCHES';
import { GET_ALL_PRODUCTS_QUERY } from 'data/queries/product';
import { GET_ALL_PRODUCTS } from 'data/queries/__generated__/GET_ALL_PRODUCTS';

import { Header, HeaderTitle } from 'components/Header';
import { Loader } from 'components/Loader';
import { PageTitle } from 'components/PageTitle';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { CreateProductSwitchPopup } from './Popups/CreateProductSwitchPopup';
import { ArchiveProductSwitchPopup } from './Popups/ArchiveProductSwitchPopup';
import { ProductSwitchForm } from './ProductSwitchForm';
import { ProductSwitchFragment } from 'data/fragments/__generated__/ProductSwitchFragment';

export const ProductSwitches = () => {
    const [isCreateProductSwitchPopupOpen, setIsCreateProductSwitchPopupOpen] = useState<boolean>(false);
    const [productSwitchToArchive, setProductSwitchToArchive] = useState<ProductSwitchFragment | null>(null);

    const {
        loading: productSwitchesLoading,
        data: productSwitchesData,
        error: productSwitchesError,
    } = useQuery<GET_PRODUCT_SWITCHES>(GET_PRODUCT_SWITCHES_QUERY);

    const {
        loading: productsLoading,
        data: productsData,
        error: productsError,
    } = useQuery<GET_ALL_PRODUCTS>(GET_ALL_PRODUCTS_QUERY);

    if ((productSwitchesLoading && !productSwitchesData) || (productsLoading && !productsData)) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (productSwitchesError || !productSwitchesData || productsError || !productsData) {
        throw new Error('Une erreur est survenue lors de la récupération des switch de produit');
    }

    const { getProductSwitches: productSwitches } = productSwitchesData;
    const { getAllProducts: products } = productsData;

    const sortedProductSwitches = [...productSwitches].sort(
        (productSwitchA, productSwitchB) => productSwitchA.createdAt - productSwitchB.createdAt,
    );

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.productSwitches} />
                </HeaderTitle>
                <ActionContainer>
                    <TotemPrimaryButton
                        onClick={() => {
                            setIsCreateProductSwitchPopupOpen(true);
                        }}
                    >
                        Ajouter un switch produit
                    </TotemPrimaryButton>
                </ActionContainer>
            </Header>
            <Content>
                {sortedProductSwitches.map((productSwitch) => (
                    <ProductSwitchForm
                        key={productSwitch._id}
                        productSwitch={productSwitch}
                        products={products}
                        setProductSwitchToArchive={setProductSwitchToArchive}
                    />
                ))}
            </Content>
            <CreateProductSwitchPopup
                products={products}
                isOpen={isCreateProductSwitchPopupOpen}
                setIsOpen={setIsCreateProductSwitchPopupOpen}
            />
            {productSwitchToArchive ? (
                <ArchiveProductSwitchPopup
                    products={products}
                    productSwitch={productSwitchToArchive}
                    isOpen={!!productSwitchToArchive}
                    setIsOpen={(isOpen) => {
                        if (!isOpen) {
                            setProductSwitchToArchive(null);
                        }
                    }}
                />
            ) : null}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    flex: 1;
    overflow: auto;
`;

const ActionContainer = styled.div`
    display: flex;

    & > :not(:first-child) {
        margin-left: 10px;
    }
`;

import styled from 'styled-components';

export const PanelContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

export const LeftPanel = styled.div`
    display: flex;
    flex-direction: column;
    width: 375px;
    min-width: 375px;
    padding-right: 15px;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

export const RightPanel = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

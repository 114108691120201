import React from 'react';

import Switch, { ReactSwitchProps } from 'react-switch';
import styled from 'styled-components';

import { colors } from 'constants/colors';

import { TotemLabel } from './TotemLabel';

type TotemSwitchProps = ReactSwitchProps & {
    label?: string;
    sublabel?: string;
    onChange: () => void;
    checked?: boolean;
};

export const TotemSwitch = ({ label, sublabel, onChange, checked = false, ...props }: TotemSwitchProps) => {
    return (
        <Container>
            <LabelContainer>
                <TotemLabel>{label}</TotemLabel>
                {sublabel ? <Sublabel>{sublabel}</Sublabel> : null}
            </LabelContainer>
            <ValueContainer>
                <Switch onChange={onChange} checked={checked} onColor={colors.green} offColor={colors.red} {...props} />
            </ValueContainer>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    color: ${({ theme }) => theme.textColor};
`;

const LabelContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const Sublabel = styled.span`
    color: ${({ theme }) => theme.infoTextColor};
    font-size: 13px;
`;

const ValueContainer = styled.div`
    margin-top: 5px;
`;

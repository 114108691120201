import React, { useEffect, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Link, unstable_usePrompt, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { FaSave } from 'react-icons/fa';

import { apolloClient } from '../../App';

import { PAGES } from '../../constants/pages';

import { StockTransferState } from 'data/__generated__';
import {
    GET_STOCK_TRANSFER,
    GET_STOCK_TRANSFER_stockTransfer,
    GET_STOCK_TRANSFER_stockTransfer_products,
} from 'data/queries/__generated__/GET_STOCK_TRANSFER';
import { GET_SITES } from 'data/queries/__generated__/GET_SITES';
import { GET_SITES_QUERY } from 'data/queries/site';
import { GET_TRANSFERABLE_PRODUCTS_transferableProducts } from 'data/queries/__generated__/GET_TRANSFERABLE_PRODUCTS';
import {
    UPDATE_STOCK_TRANSFER,
    UPDATE_STOCK_TRANSFERVariables,
} from 'data/mutations/__generated__/UPDATE_STOCK_TRANSFER';
import { UPDATE_STOCK_TRANSFER_MUTATION } from 'data/mutations/stockTransfer';
import { STOCK_TRANSFERS_UPDATED_SUBSCRIPTION } from 'data/subscriptions/stockTransfer';
import { GET_STOCK_TRANSFER_QUERY } from 'data/queries/stockTransfer';

import { StatesSection } from 'pages/StockTransfers/StatesSection';

import { SitesSelector } from './components/SitesSelector';
import { ProductsToTransferTable } from './components/ProductsToTransferTable';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { Header, HeaderTitle } from 'components/Header';
import { Loader, LoaderModeType } from 'components/Loader';
import { SelectedOption } from 'components/TotemSelect';
import { DetailValue } from 'components/DetailsView/DetailValue';
import { SectionColumn } from 'components/DetailsView/Section';
import { TotemDatePicker } from 'components/TotemDatePicker';
import { PageTitle } from 'components/PageTitle';
import { DetailLink } from 'components/DetailsView/DetailLink';

import { stockTransferUpdatedHandler } from './utils/stockTransfersUpdatedHandler';
import { dateFromString, formatDateAsAnniversary, getExtendedFormattedDatetime } from '../../helpers/dateTimes';
import { colors } from 'constants/colors';
import { TotemCheckbox } from 'components/TotemCheckbox';

type ParamTypes = {
    stockTransferId: string;
};

export const StockTransferDetails = () => {
    const { stockTransferId = '' } = useParams<ParamTypes>();
    const [updatedStockTransferDetails, setUpdatedStockTransferDetails] =
        useState<GET_STOCK_TRANSFER_stockTransfer | null>(null);

    const {
        loading: stockTransferLoading,
        data: stockTransferData,
        error: stockTransferError,
    } = useQuery<GET_STOCK_TRANSFER>(GET_STOCK_TRANSFER_QUERY, {
        variables: { stockTransferId },
    });
    const { loading: sitesLoading, data: sitesData, error: sitesError } = useQuery<GET_SITES>(GET_SITES_QUERY);

    const [updateStockTransfer, { loading: updateLoading }] = useMutation<
        UPDATE_STOCK_TRANSFER,
        UPDATE_STOCK_TRANSFERVariables
    >(UPDATE_STOCK_TRANSFER_MUTATION);

    useEffect(() => {
        const observer = apolloClient.subscribe({
            query: STOCK_TRANSFERS_UPDATED_SUBSCRIPTION,
            variables: { stockTransferId },
        });
        const subscription = observer.subscribe(({ data }) => {
            const { stockTransferUpdated } = data;
            stockTransferUpdatedHandler(stockTransferUpdated, stockTransferId);
            setUpdatedStockTransferDetails(null);
            toast.warn("Le transfert de stock vient d'être mis à jour");
        });

        return () => subscription.unsubscribe();
    });

    unstable_usePrompt({
        message: 'Êtes-vous sûr de vouloir quitter la page sans sauvegarder ?',
        when: ({ currentLocation, nextLocation }) =>
            !!updatedStockTransferDetails && currentLocation.pathname !== nextLocation.pathname,
    });

    if (sitesLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (sitesError || !sitesData) {
        throw new Error('Une erreur est survenue lors de la récupération des sites');
    }

    if (stockTransferLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (stockTransferError || !stockTransferData) {
        throw new Error(`L'erreur suivante est survenue : ${stockTransferError}`);
    }

    const { stockTransfer: apolloStockTransferDetails } = stockTransferData;
    const stockTransferDetails = updatedStockTransferDetails || apolloStockTransferDetails;

    const {
        createdAt,
        deliveryId,
        delivery,
        destinationSiteId,
        originSiteId,
        products,
        shouldUpdateStockImmediately,
        state,
        stateHistory,
        transferDate,
        updatedAt,
    } = stockTransferDetails;

    const sitesOptions = sitesData.sites
        .map((site) => ({ value: site._id, label: site.name }))
        .sort((siteA, siteB) => siteA.label.toLowerCase().localeCompare(siteB.label.toLowerCase()));
    const selectedOriginSiteOption = sitesOptions.find((option) => option.value === originSiteId);
    const selectedDestinationSiteOption = sitesOptions.find((option) => option.value === destinationSiteId);

    const handleOriginSiteSelect = (originSiteOption: SelectedOption<string>) => {
        if (originSiteOption) {
            const { value: originSiteId } = originSiteOption;
            setUpdatedStockTransferDetails({ ...stockTransferDetails, originSiteId });
        }
    };

    const handleDestinationSiteSelect = (destinationSiteOption: SelectedOption<string>) => {
        if (destinationSiteOption) {
            const { value: destinationSiteId } = destinationSiteOption;
            setUpdatedStockTransferDetails({ ...stockTransferDetails, destinationSiteId });
        }
    };

    const handleProductSelect = (product: GET_TRANSFERABLE_PRODUCTS_transferableProducts) => {
        if (!stockTransferDetails.products.map((product) => product.productId).includes(product.productId))
            setUpdatedStockTransferDetails({
                ...stockTransferDetails,
                products: [...stockTransferDetails.products, product],
            });
    };

    const handleQuantityToTransferUpdate = (value: string, product: GET_TRANSFERABLE_PRODUCTS_transferableProducts) => {
        const { productId } = product;
        // Check that the string only contains digits
        if (/^(\d+)?$/.test(value)) {
            setUpdatedStockTransferDetails({
                ...stockTransferDetails,
                products: stockTransferDetails.products.map((stockTransferProduct) => {
                    if (stockTransferProduct.productId !== productId) {
                        return stockTransferProduct;
                    }
                    return {
                        ...product,
                        quantity: Number(value),
                    };
                }),
            });
        }
    };

    const handleChangedAvailableQuantitiesAfterDateChange = (
        transferableProducts: GET_TRANSFERABLE_PRODUCTS_transferableProducts[],
    ) => {
        const productsWithUpdatedAvailableStocks = products
            .map((product) => {
                const transferableProduct = transferableProducts.find(
                    ({ productId }) => product.productId === productId,
                );

                return {
                    ...product,
                    originStockAvailable: transferableProduct?.originStockAvailable ?? 0,
                    destinationStockAvailable: transferableProduct?.destinationStockAvailable ?? 0,
                };
            })
            .filter((product): product is GET_STOCK_TRANSFER_stockTransfer_products => !!product);

        setUpdatedStockTransferDetails({
            ...stockTransferDetails,
            products: productsWithUpdatedAvailableStocks,
        });
    };

    const handleProductDelete = (product: GET_TRANSFERABLE_PRODUCTS_transferableProducts) => {
        const { productId } = product;
        setUpdatedStockTransferDetails({
            ...stockTransferDetails,
            products: stockTransferDetails.products.filter(
                (stockTransferProduct) => stockTransferProduct.productId !== productId,
            ),
        });
    };

    const handleModify = async () => {
        const { _id: stockTransferId, products } = stockTransferDetails;
        const formattedProducts = products.map((product) => ({
            expiryDates: [], //for now we don't modify expiry dates when creating ST manually
            productId: product.productId,
            quantity: product.quantity,
        }));
        const { data } = await updateStockTransfer({
            variables: {
                stockTransferId,
                transferDate,
                originSiteId,
                destinationSiteId,
                products: formattedProducts,
                shouldUpdateStockImmediately,
            },
        });
        if (!data) {
            toast.error('Une erreur inconnue est survenue lors de la modification du transfert de stock');
            return;
        }
        if (!data.updateStockTransfer.success) {
            toast.error(`L'erreur suivante est survenue : "${data.updateStockTransfer.error}"`);
            return;
        }
        setUpdatedStockTransferDetails(null);
        toast.success('Le transfert de stock a bien été modifié');
    };

    const isTransferValidated = state === StockTransferState.Validated;

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.stockTransferDetails} />
                </HeaderTitle>
                <CTAsContainer>
                    {!isTransferValidated ? (
                        <TotemPrimaryButton
                            onClick={handleModify}
                            disabled={!updatedStockTransferDetails || updateLoading}
                            data-test="stock-transfer-details_submit-button"
                        >
                            {updateLoading ? (
                                <Loader size="22px" mode={LoaderModeType.Spin} />
                            ) : (
                                <>
                                    <FaSave size="13" />
                                    <SaveLabel>Mettre à jour</SaveLabel>
                                </>
                            )}
                        </TotemPrimaryButton>
                    ) : null}
                    <Link to="/stockTransfers">
                        <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                    </Link>
                </CTAsContainer>
            </Header>
            <Content>
                <ScrollableContent>
                    <SectionColumn>
                        <InfoDetails>
                            <TotemDatePicker
                                label="Date du transfert"
                                selected={dateFromString(transferDate)}
                                isDisabled={isTransferValidated}
                                onChange={(date) => {
                                    if (date) {
                                        setUpdatedStockTransferDetails({
                                            ...stockTransferDetails,
                                            transferDate: formatDateAsAnniversary({ dateTime: date }),
                                        });
                                    }
                                }}
                            />
                            <DetailValue label="Date de création" value={getExtendedFormattedDatetime(createdAt)} />
                            <DetailValue label="Dernière mise à jour" value={getExtendedFormattedDatetime(updatedAt)} />
                            {deliveryId ? (
                                <DetailLink label="Livraison associée" path="delivery" value={deliveryId} />
                            ) : (
                                <DetailValue label="Livraison associée" value="Pas de livraison associée" />
                            )}
                            <DetailValue label="Statut de livraison" value={delivery?.state || '-'} />
                        </InfoDetails>

                        {!isTransferValidated ? (
                            <WarningText>
                                Attention ! Les transferts de stock prévus pour aujourd'hui ou demain seront validés
                                automatiquement à 20h15
                            </WarningText>
                        ) : null}
                        <SitesSelector
                            sitesOptions={sitesOptions}
                            originSiteOption={selectedOriginSiteOption}
                            destinationSiteOption={selectedDestinationSiteOption}
                            handleOriginSiteSelect={handleOriginSiteSelect}
                            handleDestinationSiteSelect={handleDestinationSiteSelect}
                            isDisabled={isTransferValidated}
                        />
                        <TotemCheckbox
                            checked={shouldUpdateStockImmediately}
                            onChange={() =>
                                setUpdatedStockTransferDetails({
                                    ...stockTransferDetails,
                                    shouldUpdateStockImmediately: !shouldUpdateStockImmediately,
                                })
                            }
                            label="Mettre à jour les stocks immediatement, sans création de livraison ni de RE. ⚠️⚠️⚠️ À faire que par des professionnels ⚠️⚠️⚠️"
                        />
                        <StatesSection
                            stateHistory={stateHistory}
                            stockTransfer={apolloStockTransferDetails}
                            updatedStockTransferDetails={updatedStockTransferDetails}
                        />
                        <ProductsToTransferTable
                            handleProductSelect={handleProductSelect}
                            originSiteId={originSiteId}
                            destinationSiteId={destinationSiteId}
                            productsToTransfer={products}
                            handleQuantityToTransferUpdate={handleQuantityToTransferUpdate}
                            handleChangedAvailableQuantitiesAfterDateChange={
                                handleChangedAvailableQuantitiesAfterDateChange
                            }
                            handleProductDelete={handleProductDelete}
                            isTransferValidated={isTransferValidated}
                            transferDate={transferDate}
                        />
                    </SectionColumn>
                </ScrollableContent>
            </Content>
        </Container>
    );
};

const SaveLabel = styled.span`
    margin-left: 5px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const CTAsContainer = styled.div`
    display: flex;
    align-items: center;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const Content = styled.div`
    flex: 1;
    overflow: hidden;
`;

const WarningText = styled.div`
    color: ${colors.orange};
`;

const ScrollableContent = styled.div`
    padding: 15px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
`;

const InfoDetails = styled.div`
    display: flex;
    width: 100%;

    & > * {
        flex: 1;
    }
`;

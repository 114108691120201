import React from 'react';

// eslint-disable-next-line import/no-unresolved
import { createRoot } from 'react-dom/client';

import * as Sentry from '@sentry/react';
import { createGlobalStyle } from 'styled-components';

import App from './App';

import './assets/font/fonts.css';

const GlobalStyle = createGlobalStyle`
    body, #root {
        width: 100vw;
        height: 100vh;
        margin: 0;
        font-weight: 500;
        font-family: 'Aeonik', sans-serif;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-family: 'Sharp Grotesk', sans-serif;
        }
    }

    input, button {
        font-family: inherit;
    }

    * {
        box-sizing: border-box;
    }
`;

Sentry.init({
    dsn: 'https://e4dd2c212c6c4057a8c7933edec6c9f5@o79738.ingest.sentry.io/5508657',
    environment: window.__TOTENV__.ENV,
});

const container = document.getElementById('root');

const root = createRoot(container!);
root.render(
    <React.StrictMode>
        <App />
        <GlobalStyle />
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

import React from 'react';

import { useQuery } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { PAGES } from 'constants/pages';

import { GET_WAREHOUSE_RETURN, GET_WAREHOUSE_RETURNVariables } from 'data/queries/__generated__/GET_WAREHOUSE_RETURN';
import { GET_WAREHOUSE_RETURN_QUERY } from 'data/queries/warehouseReturn';
import { GET_MICROSTORE_PRODUCTS_QUERY } from 'data/queries/product';
import { GET_MICROSTORE_PRODUCTS } from 'data/queries/__generated__/GET_MICROSTORE_PRODUCTS';

import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { Header, HeaderTitle } from 'components/Header';
import { Loader } from 'components/Loader';
import { PageTitle } from 'components/PageTitle';
import { GeneralInfoSection } from './GeneralInfoSection';
import { ProductsSection } from './ProductsSection';
import { ActionSection } from './ActionSection';

type ParamTypes = {
    siteId: string;
    warehouseReturnId: string;
};

export const WarehouseReturnDetails = () => {
    const { warehouseReturnId = '' } = useParams<ParamTypes>();

    const {
        loading: warehouseReturnLoading,
        data: warehouseReturnData,
        error: warehouseReturnError,
    } = useQuery<GET_WAREHOUSE_RETURN, GET_WAREHOUSE_RETURNVariables>(GET_WAREHOUSE_RETURN_QUERY, {
        variables: { warehouseReturnId },
    });

    const {
        data: microstoreProductsData,
        loading: microstoreProductsLoading,
        error: microstoreProductsError,
    } = useQuery<GET_MICROSTORE_PRODUCTS>(GET_MICROSTORE_PRODUCTS_QUERY);

    if (warehouseReturnLoading || microstoreProductsLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (warehouseReturnError || !warehouseReturnData || microstoreProductsError || !microstoreProductsData) {
        throw new Error(`L'erreur suivante est survenue : ${warehouseReturnError || microstoreProductsError}`);
    }

    const { getWarehouseReturn: warehouseReturn } = warehouseReturnData;
    const { getMicrostoreProducts: products } = microstoreProductsData;

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.warehouseReturnDetails} />
                </HeaderTitle>
                <CTAsContainer>
                    <Link to="/warehouse-returns">
                        <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                    </Link>
                </CTAsContainer>
            </Header>
            <Content>
                <ScrollableContent>
                    <GeneralInfoSection warehouseReturn={warehouseReturn} />
                    <ActionSection warehouseReturn={warehouseReturn} />
                    <ProductsSection products={products} warehouseReturn={warehouseReturn} />
                </ScrollableContent>
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const CTAsContainer = styled.div`
    display: flex;
    align-items: center;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const Content = styled.div`
    flex: 1;
    overflow: hidden;
`;

const ScrollableContent = styled.div`
    padding: 15px;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

import gql from 'graphql-tag';

export const TOTEM_CLOSED_DAY_FRAGMENT = gql`
    fragment TotemClosedDayFragment on TotemClosedDay {
        _id
        createdAt
        date
        state
        type
        updatedAt
    }
`;

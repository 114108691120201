import gql from 'graphql-tag';

export const GET_ORGANIZATIONS_QUERY = gql`
    query GET_ORGANIZATIONS {
        organizations {
            _id
            hasSubscription
            name
            paymentType
            state
        }
    }
`;

export const GET_ACTIVE_ORGANIZATIONS_QUERY = gql`
    query GET_ACTIVE_ORGANIZATIONS {
        activeOrganizations {
            _id
            name
        }
    }
`;

export const GET_ORGANIZATIONS_ELIGIBLE_TO_CREDITS_QUERY = gql`
    query GET_ORGANIZATIONS_ELIGIBLE_TO_CREDITS {
        organizationsEligibleToCredits {
            _id
            name
        }
    }
`;

export const GET_ORGANIZATION_QUERY = gql`
    query GET_ORGANIZATION($organizationId: ID!) {
        organization(organizationId: $organizationId) {
            _id
            admins {
                _id
                fullname
            }
            automaticInvoicing
            B2BPriceRange
            createdAt
            domains
            hasFreefoodIncluded
            hasSubscription
            imageUrl
            mandateSEPA
            maxUsers
            name
            octobatId
            paymentTimeDays
            paymentTimeType
            paymentType
            hasPrepaidOrganizationCards
            hasPrepaidSubventions
            sales {
                _id
                fullName
            }
            salesforceId
            segregatedInvoicing
            state
            stateHistory {
                createdAt
                createdBy
                createdByName
                state
            }
            stripeId
            termsAcceptanceDate
            totalCoworkers
            updatedAt
            zuoraAccountId
        }
    }
`;

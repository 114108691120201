import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useQuery } from '@apollo/client';

import styled from 'styled-components';

import {
    GET_SITE_PRODUCT_WITH_STOCKS,
    GET_SITE_PRODUCT_WITH_STOCKSVariables,
    GET_SITE_PRODUCT_WITH_STOCKS_getProductWithVariations_variations,
} from 'data/queries/__generated__/GET_SITE_PRODUCT_WITH_STOCKS';
import { GET_SITE_PRODUCT_WITH_STOCKS_QUERY } from 'data/queries/product';
import { GET_SITE_QUERY } from 'data/queries/site';
import { GET_SITE, GET_SITEVariables } from 'data/queries/__generated__/GET_SITE';

import { StockVariationSplitPopup } from 'pages/Stocks/StockVariationSplitPopup';
import { Loader, LoaderModeType } from 'components/Loader';
import { Header, HeaderTitle } from 'components/Header';
import { DetailValue } from 'components/DetailsView';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

import { getExtendedFormattedDatetime } from 'helpers/dateTimes';
import { FutureStockVariationsList } from './components/FutureStockVariationsList';
import { StockVariationsList } from './components/StockVariationsList';
import { SiteType } from 'data/__generated__';
import { AvailableStocksDetailsPopup } from './AvailableStocksDetailsPopup';

type ParamTypes = {
    siteId: string;
    productId: string;
};

export const SiteProductStocks = () => {
    const { siteId = '', productId = '' } = useParams<ParamTypes>();

    const [selectedStockVariation, setSelectedStockVariation] =
        useState<GET_SITE_PRODUCT_WITH_STOCKS_getProductWithVariations_variations | null>(null);
    const [isAvailableStockDetailsPopupOpen, setIsAvailableStockDetailsPopupOpen] = useState<boolean>(false);

    const {
        loading: siteLoading,
        data: siteData,
        error: siteError,
    } = useQuery<GET_SITE, GET_SITEVariables>(GET_SITE_QUERY, {
        variables: {
            siteId,
        },
    });

    const {
        loading: productLoading,
        data: productData,
        error: productError,
    } = useQuery<GET_SITE_PRODUCT_WITH_STOCKS, GET_SITE_PRODUCT_WITH_STOCKSVariables>(
        GET_SITE_PRODUCT_WITH_STOCKS_QUERY,
        {
            variables: {
                siteId,
                productId,
            },
        },
    );

    if (siteLoading || productLoading) {
        return (
            <Container>
                <Loader mode={LoaderModeType.Spin} />
            </Container>
        );
    }

    if (productError || !productData || siteError || !siteData) {
        toast.error("Une erreur est survenue lors de la récupération de l'historique de stock du produit");
        return null;
    }

    if (!productData.getProductWithVariations.product || !productData.getProductWithVariations.variations) {
        toast.error("Une erreur est survenue lors de la récupération de l'historique de stock du produit");
        return null;
    }

    const { product, availableStock, variations } = productData.getProductWithVariations;
    const site = siteData.site;

    return (
        <Container>
            <Header>
                <HeaderTitle>Historique du stock</HeaderTitle>
                <CTAsContainer>
                    <Link to={`/product/${product._id}`}>
                        <TotemPrimaryButton>Voir le produit</TotemPrimaryButton>
                    </Link>
                    <Link to={`/site/${siteId}/stocks`}>
                        <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                    </Link>
                </CTAsContainer>
            </Header>
            <Content>
                <InfoContainer onClick={() => setIsAvailableStockDetailsPopupOpen(true)}>
                    <DetailValue label="Site" value={site.name} />
                    <DetailValue label="Produit" value={product.fullname} />
                    <DetailValue label="Stock" value={product.stock} />
                    <DetailValue label="Stock disponible (pas réservé) aujourd'hui" value={availableStock.value} />
                    <AvailableStocksDetailsPopup
                        details={availableStock.details}
                        isOpen={isAvailableStockDetailsPopupOpen}
                        setIsOpen={setIsAvailableStockDetailsPopupOpen}
                    />
                </InfoContainer>

                <InfoContainer>
                    <DetailValue label="Dernier inventaire" value={product.lastSiteInventoryStock._id} />
                    <DetailValue label="Crée par" value={product.lastSiteInventoryStock.createdByName || 'Inconnu'} />
                    <DetailValue
                        label="Date"
                        value={getExtendedFormattedDatetime(product.lastSiteInventoryStock.createdAt)}
                    />
                    <DetailValue label="Stock" value={product.lastSiteInventoryStock.value} />
                </InfoContainer>

                <Section>
                    <SectionTitle> Variations à venir </SectionTitle>
                    {site.type === SiteType.Warehouse ? (
                        <SectionContent>
                            <FutureStockVariationsList productId={productId} />
                        </SectionContent>
                    ) : (
                        <SectionCenterContent>
                            Bientôt vous retrouverez les futures stocks variations de chaque site içi
                        </SectionCenterContent>
                    )}
                </Section>
                <Section>
                    <SectionTitle> Variations passées </SectionTitle>
                    <SectionContent>
                        <StockVariationsList stockVariations={variations} />
                    </SectionContent>
                </Section>
            </Content>

            {selectedStockVariation ? (
                <StockVariationSplitPopup
                    selectedStockVariation={selectedStockVariation}
                    setSelectedStockVariation={setSelectedStockVariation}
                />
            ) : null}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Content = styled.div`
    padding: 0 15px 15px;
    flex: 1;
    overflow: auto;
`;

const InfoContainer = styled.div`
    display: flex;
    background-color: ${({ theme }) => theme.cardBackgroundColor};
    border-radius: ${({ theme }) => theme.borderRadius};
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
    margin-top: 15px;
    padding: 10px;

    & > :not(:first-child) {
        margin-left: 20px;
    }
`;

const Section = styled.div`
    border-radius: ${({ theme }) => theme.borderRadius};
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
    margin-top: 15px;
    padding: 20px;
`;

const SectionContent = styled.div`
    display: flex;
`;

const SectionCenterContent = styled.div`
    text-align: center;
`;

const SectionTitle = styled.h2`
    text-align: center;
`;

const CTAsContainer = styled.div`
    display: flex;
    align-items: center;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

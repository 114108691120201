import React from 'react';

import styled from 'styled-components';

import {
    GET_MICROSTORE_SITES_WITH_LOCATIONS_INFO_sites_microstoreColumnGroups,
    GET_MICROSTORE_SITES_WITH_LOCATIONS_INFO_sites_microstoreColumnGroups_columns,
} from 'data/queries/__generated__/GET_MICROSTORE_SITES_WITH_LOCATIONS_INFO';
import { GET_ALL_PRODUCTS_getAllProducts } from 'data/queries/__generated__/GET_ALL_PRODUCTS';
import { PlanogramLocation } from './PlanogramLocation';
import { LocationPlanogramFragment } from 'data/fragments/__generated__/LocationPlanogramFragment';
import { TotemSelect } from 'components/TotemSelect';

const SHELF_BACKGROUND_COLOR = ['#fff7ef', '#f3ffef', '#eff6ff'];

export const PlanogramColumn = ({
    locationPlanogramsByDate,
    products,
    selectedDate,
    setSelectedDate,
    selectedColumn,
    selectedColumnGroup,
    siteName,
}: {
    locationPlanogramsByDate: Record<string, LocationPlanogramFragment[]>;
    products: GET_ALL_PRODUCTS_getAllProducts[];
    selectedDate: string | null;
    setSelectedDate: React.Dispatch<React.SetStateAction<string | null>>;
    selectedColumn: GET_MICROSTORE_SITES_WITH_LOCATIONS_INFO_sites_microstoreColumnGroups_columns;
    selectedColumnGroup: GET_MICROSTORE_SITES_WITH_LOCATIONS_INFO_sites_microstoreColumnGroups;
    siteName: string;
}) => {
    const dateOptions =
        Object.keys(locationPlanogramsByDate)
            .map((date) => {
                const locationPanograms = locationPlanogramsByDate[date];
                const createdAtDates = Array.from(
                    new Set(
                        locationPanograms.map(
                            ({ createdAt }) =>
                                `${new Date(createdAt).toLocaleDateString()} à ${new Date(
                                    createdAt,
                                ).toLocaleTimeString()}`,
                        ),
                    ),
                );
                const formattedDate = new Date(date).toLocaleDateString();
                return {
                    value: date,
                    label: `${formattedDate} (Créés le ${createdAtDates.join(' et le ')})`,
                };
            })
            .sort((a, b) => a.label.localeCompare(b.label)) || [];

    return (
        <Container>
            <TitleContainer>
                <Title>
                    {selectedColumnGroup.name} - C{selectedColumn.positionInColumnGroup + 1} -{' '}
                    <TitleColumnName>{selectedColumn.name}</TitleColumnName>
                </Title>
                <SubTitle>({siteName})</SubTitle>
            </TitleContainer>

            <InfoContainer>
                <DateText>
                    Début :{' '}
                    {selectedColumn.startingDate ? new Date(selectedColumn.startingDate).toLocaleDateString() : 'NA'}
                </DateText>
                <DateText>
                    Retrait :{' '}
                    {selectedColumn.removalDate ? new Date(selectedColumn.removalDate).toLocaleDateString() : 'NA'}
                </DateText>
                {selectedColumn.isPaused ? <DateText>Colonne mise en pause</DateText> : null}
            </InfoContainer>

            <SelectContainer>
                <TotemSelect
                    placeholder="Sélectionner une date"
                    value={dateOptions.find(({ value }) => value === selectedDate) ?? null}
                    options={dateOptions}
                    onChange={(option) => {
                        if (option) {
                            setSelectedDate(option.value);
                        }
                    }}
                />
            </SelectContainer>

            <ColumnEditorContainer>
                <Column>
                    {selectedColumn.shelves.map((shelf, shelfIndex) => (
                        <Shelf
                            key={shelfIndex}
                            color={SHELF_BACKGROUND_COLOR[shelfIndex % SHELF_BACKGROUND_COLOR.length]}
                        >
                            {shelf.locationTable.map((locationRow, locationRowIndex) => {
                                return (
                                    <LocationRow key={locationRowIndex}>
                                        {locationRow.map((location) => {
                                            const locationPlanogram = selectedDate
                                                ? locationPlanogramsByDate[selectedDate]?.find(
                                                      ({ locationId }) => locationId === location._id,
                                                  ) ?? null
                                                : null;
                                            return (
                                                <PlanogramLocation
                                                    key={location._id}
                                                    location={location}
                                                    locationPlanogram={locationPlanogram}
                                                    products={products}
                                                />
                                            );
                                        })}
                                    </LocationRow>
                                );
                            })}
                        </Shelf>
                    ))}
                </Column>
            </ColumnEditorContainer>
        </Container>
    );
};

const Container = styled.div`
    // we compute the height with vh because we want the column to be visible while taking the maximum height
    min-height: calc(100vh - 200px);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
`;

const Title = styled.span`
    font-size: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-flex;
    align-items: center;
`;

const TitleColumnName = styled.span``;

const SubTitle = styled.span`
    margin-left: 15px;
    font-size: 20px;
    color: ${({ theme }) => theme.infoTextColor};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const InfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const DateText = styled.div`
    display: flex;
    flex-grow: 1;
    text-align: center;
`;

const ColumnEditorContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    flex: 1 0 0;
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
    border-radius: ${({ theme }) => theme.borderRadius};

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

const Shelf = styled.div<{ color: string }>`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    background-color: ${({ color }) => color};
    padding: 2px;

    & > :not(:first-child) {
        margin-top: 2px;
    }
`;

const LocationRow = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1 0 0;

    & > :not(:first-child) {
        margin-left: 2px;
    }
`;

const SelectContainer = styled.div`
    margin-top: 10px;
`;

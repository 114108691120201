import React from 'react';

import { useMutation } from '@apollo/client';
import { Link, useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';
import styled from 'styled-components';

import { PAGES } from '../../constants/pages';
import { CREATE_TAG_MUTATION } from 'data/mutations/tag';
import { CREATE_TAG, CREATE_TAGVariables } from 'data/mutations/__generated__/CREATE_TAG';

import { Header, HeaderTitle } from 'components/Header';
import { PageTitle } from 'components/PageTitle';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

import { TagForm } from './components/TagForm';
import { SelectedOption } from 'components/TotemSelect';
import { TagType, TagState } from 'data/__generated__';

type FormType = {
    displayIndex: number;
    name: string;
    type: SelectedOption<TagType> | null;
    state: SelectedOption<TagState> | null;
};

export const TagCreate = () => {
    const navigate = useNavigate();
    const tagTypeOption = { value: TagType.Principal, label: TagType.Principal };
    const tagStateOption = { value: TagState.Regular, label: TagState.Regular };
    const initialFormData = {
        _id: null,
        displayIndex: 0,
        name: '',
        type: tagTypeOption,
        state: tagStateOption,
    };

    const [createTag] = useMutation<CREATE_TAG, CREATE_TAGVariables>(CREATE_TAG_MUTATION);
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>, formData: FormType) => {
        e.preventDefault();
        const { name, type, state, displayIndex } = formData;
        if (!type) {
            toast.error('Il manque le champ type');
            return;
        }

        if (!state) {
            toast.error('Il manque le champ state');
            return;
        }

        if (!displayIndex || !Number.isInteger(Number(displayIndex))) {
            toast.error("Index d'affichage devrait être un nombre entier");
            return;
        }

        const { value: typeValue } = type;
        const { value: stateValue } = state;

        const result = await createTag({
            variables: {
                tag: { name, displayIndex: Number(displayIndex), type: typeValue, state: stateValue },
            },
        });
        const { data } = result;
        if (data) {
            const {
                createTag: { success },
            } = data;
            if (success) {
                toast.success(`Le tag "${formData.name}" a bien été créé !`);
                navigate('/tags');
            } else {
                const {
                    createTag: { error },
                } = data;
                if (error) {
                    toast.error(`L'erreur suivante est survenue : ${error}`);
                } else {
                    throw Error("Une erreur inconnue s'est produite");
                }
            }
        } else {
            throw Error("Une erreur inconnue s'est produite");
        }
    };

    function canISubmit(submitData: FormType): boolean {
        return (
            submitData.name !== '' && submitData.name !== null && submitData.type !== null && submitData.state !== null
        );
    }

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.tagCreate} />
                </HeaderTitle>
                <Link to="/tags">
                    <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                </Link>
            </Header>
            <Content>
                <TagForm<FormType>
                    handleSubmit={handleSubmit}
                    initialFormData={initialFormData}
                    canISubmit={canISubmit}
                    submitMessage="Créer un tag"
                />
            </Content>
        </Container>
    );
};

const Container = styled.div`
    flex-direction: column;
    flex: 1;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Content = styled.div`
    padding: 15px;
`;

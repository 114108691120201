import React, { useRef } from 'react';

import ReactToPrint from 'react-to-print';
import { FcPrint } from 'react-icons/fc';
import styled from 'styled-components';

import { GET_DELIVERY_delivery } from 'data/queries/__generated__/GET_DELIVERY';

import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

import { colors } from 'constants/colors';
import { PageHeader } from './PageHeader';

export const OrderDeliveryNote = ({ delivery }: { delivery: GET_DELIVERY_delivery }) => {
    const componentRef = useRef(null);

    const { boxes } = delivery;

    return (
        <Container>
            <ReactToPrint
                trigger={() => {
                    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                    // to the root node of the returned component as it will be overwritten.
                    return (
                        <span>
                            <TotemPrimaryButton>
                                <PrintButtonContent>
                                    <FcPrint size={20} />
                                    <PrintLabel>Imprimer</PrintLabel>
                                </PrintButtonContent>
                            </TotemPrimaryButton>
                        </span>
                    );
                }}
                content={() => componentRef.current}
            />
            <HiddenComponent>
                <Printable ref={componentRef}>
                    <PageHeader delivery={delivery} />
                    {boxes.map((box) => {
                        const { _id, code, number, products } = box;

                        return (
                            <BoxContainer key={_id}>
                                <Title>
                                    Caisse n°{number} - Code {code || '_____'}
                                </Title>
                                <Products>
                                    <thead>
                                        <tr>
                                            <HeaderCell>Produit</HeaderCell>
                                            <HeaderCell center>Quantité commandée</HeaderCell>
                                            <HeaderCell center>Quantité reçue</HeaderCell>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {products.map((product) => {
                                            const { _id, location, productLabel, quantity, quantityPicked } = product;

                                            return (
                                                <BodyRow key={_id}>
                                                    <BodyCell>
                                                        <ProductDetails>
                                                            <ProductName>{productLabel}</ProductName>
                                                            <ProductLocation>Référence : {location}</ProductLocation>
                                                        </ProductDetails>
                                                    </BodyCell>
                                                    <BodyCell center>
                                                        <QuantityValue>{quantity}</QuantityValue>
                                                    </BodyCell>
                                                    <BodyCell
                                                        center
                                                        hasError={
                                                            quantityPicked !== null && quantityPicked !== quantity
                                                        }
                                                    >
                                                        <QuantityValue>{quantityPicked ?? ''}</QuantityValue>
                                                    </BodyCell>
                                                </BodyRow>
                                            );
                                        })}
                                    </tbody>
                                </Products>
                            </BoxContainer>
                        );
                    })}
                </Printable>
            </HiddenComponent>
        </Container>
    );
};

const Container = styled.div`
    color: ${colors.black};
`;

const PrintButtonContent = styled.div`
    display: flex;
    align-items: center;
`;

const PrintLabel = styled.span`
    margin-left: 5px;
`;

const HiddenComponent = styled.div`
    display: none;
`;

const Printable = styled.div`
    display: table;

    @page {
        margin: 10mm;
    }
`;

const BoxContainer = styled.div`
    width: 100%;
    page-break-after: always;
`;

const Title = styled.span`
    font-size: 22px;
    font-weight: 800;
    color: ${colors.black};
`;

const Products = styled.table`
    width: 100%;
    border-spacing: 0;
    margin-top: 10px;
`;

const HeaderCell = styled.th`
    padding: 5px;
    font-size: 22px;
    text-align: ${({ center }: { center?: boolean }) => (center ? 'center' : 'left')};
    background-color: ${colors.yellow};
`;

const BodyRow = styled.tr`
    page-break-inside: avoid;
`;

type BodyCellProps = {
    center?: boolean;
    hasError?: boolean;
};

const BodyCell = styled.td`
    border-top: 1px solid ${colors.pantinGrey};
    text-align: ${({ center }: BodyCellProps) => (center ? 'center' : 'left')};
    color: ${({ hasError }: BodyCellProps) => (hasError ? colors.pantinGrey : colors.black)};
`;

const ProductDetails = styled.div`
    color: ${colors.black};
`;

const ProductName = styled.div`
    font-size: 20px;
`;

const ProductLocation = styled.div``;

const QuantityValue = styled.span`
    font-size: 22px;
`;

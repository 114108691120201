import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import React from 'react';
import { FaArrowUp, FaMinus, FaPlus, FaTrash } from 'react-icons/fa';
import { FcCheckmark, FcLeave } from 'react-icons/fc';

import styled from 'styled-components';

import { MicrostoreColumnTemplateFormValues, MicrostoreLocationTemplateFormValues } from './FormHelper/DataMapper';

export const ShelfEditor = ({
    microstoreColumnTemplateFormValues,
    selectedShelfTemplateIndex,
    selectedLocationTemplatePosition,
    setSelectedLocationTemplatePosition,
    setMicrostoreColumnTemplateFormValuesAndIsFormDirty,
}: {
    microstoreColumnTemplateFormValues: MicrostoreColumnTemplateFormValues;
    selectedShelfTemplateIndex: number | null;
    selectedLocationTemplatePosition: {
        column: number;
        row: number;
    } | null;
    setSelectedLocationTemplatePosition: (
        value: React.SetStateAction<{
            column: number;
            row: number;
        } | null>,
    ) => void;
    setMicrostoreColumnTemplateFormValuesAndIsFormDirty: ({
        newMicrostoreColumnTemplateFormValues,
        newIsDirty,
    }: {
        newMicrostoreColumnTemplateFormValues: MicrostoreColumnTemplateFormValues;
        newIsDirty?: boolean | undefined;
    }) => void;
}) => {
    const selectedShelfTemplate =
        selectedShelfTemplateIndex !== null
            ? microstoreColumnTemplateFormValues.shelfTemplates[selectedShelfTemplateIndex]
            : null;

    if (selectedShelfTemplateIndex === null || !selectedShelfTemplate) {
        return <ShelfDetails>Sélectionnez une étagère</ShelfDetails>;
    }

    function addLocationRow() {
        if (selectedShelfTemplateIndex === null || !selectedShelfTemplate) {
            return;
        }
        setSelectedLocationTemplatePosition(null);

        const locationTemplateTable = selectedShelfTemplate.locationTemplateTable;

        const newLocationRow: MicrostoreLocationTemplateFormValues[] = [];
        const newLocationTemplateTable = [...locationTemplateTable, newLocationRow];

        const newShelfTemplates = [...microstoreColumnTemplateFormValues.shelfTemplates];
        newShelfTemplates[selectedShelfTemplateIndex] = {
            ...selectedShelfTemplate,
            locationTemplateTable: newLocationTemplateTable,
        };
        setMicrostoreColumnTemplateFormValuesAndIsFormDirty({
            newMicrostoreColumnTemplateFormValues: {
                ...microstoreColumnTemplateFormValues,
                shelfTemplates: newShelfTemplates,
            },
        });
    }

    function removeLocationRow(rowIndexToRemove: number) {
        if (selectedShelfTemplateIndex === null || !selectedShelfTemplate) {
            return;
        }
        setSelectedLocationTemplatePosition(null);

        const locationTemplateTable = selectedShelfTemplate.locationTemplateTable;

        const newLocationTemplateTable = locationTemplateTable.filter(
            (_locationTemplateRow, index) => index !== rowIndexToRemove,
        );

        const newShelfTemplates = [...microstoreColumnTemplateFormValues.shelfTemplates];
        newShelfTemplates[selectedShelfTemplateIndex] = {
            ...selectedShelfTemplate,
            locationTemplateTable: newLocationTemplateTable,
        };
        setMicrostoreColumnTemplateFormValuesAndIsFormDirty({
            newMicrostoreColumnTemplateFormValues: {
                ...microstoreColumnTemplateFormValues,
                shelfTemplates: newShelfTemplates,
            },
        });
    }

    function moveLocationRowUp(rowIndexToMoveUp: number) {
        if (selectedShelfTemplateIndex === null || !selectedShelfTemplate) {
            return;
        }

        setSelectedLocationTemplatePosition(null);

        const locationTemplateTable = selectedShelfTemplate.locationTemplateTable;

        const newLocationTemplateTable = [
            ...locationTemplateTable.slice(0, rowIndexToMoveUp - 1),
            ...locationTemplateTable.slice(rowIndexToMoveUp, rowIndexToMoveUp + 1),
            ...locationTemplateTable.slice(rowIndexToMoveUp - 1, rowIndexToMoveUp),
            ...locationTemplateTable.slice(rowIndexToMoveUp + 1),
        ];

        const newShelfTemplates = [...microstoreColumnTemplateFormValues.shelfTemplates];
        newShelfTemplates[selectedShelfTemplateIndex] = {
            ...selectedShelfTemplate,
            locationTemplateTable: newLocationTemplateTable,
        };
        setMicrostoreColumnTemplateFormValuesAndIsFormDirty({
            newMicrostoreColumnTemplateFormValues: {
                ...microstoreColumnTemplateFormValues,
                shelfTemplates: newShelfTemplates,
            },
        });
    }

    function addLocationToRow(rowIndex: number) {
        if (selectedShelfTemplateIndex === null || !selectedShelfTemplate) {
            return;
        }

        setSelectedLocationTemplatePosition(null);

        const locationTemplateTable = selectedShelfTemplate.locationTemplateTable;
        const locationTemplateRow = locationTemplateTable[rowIndex];

        const newLocation: MicrostoreLocationTemplateFormValues = {
            _id: null,
            allowProductRotation: false,
            productArrangement: null,
            alternativeProductArrangementIds: null,
            productArrangementSingleProductInfo: null,
            shouldOrderProductIfNotInSite: false,
            shouldSmoothProductTransition: true,
        };
        const newLocationTemplateRow = [...locationTemplateRow, newLocation];

        const newLocationTemplateTable = [...locationTemplateTable];
        newLocationTemplateTable[rowIndex] = newLocationTemplateRow;

        const newShelfTemplates = [...microstoreColumnTemplateFormValues.shelfTemplates];
        newShelfTemplates[selectedShelfTemplateIndex] = {
            ...selectedShelfTemplate,
            locationTemplateTable: newLocationTemplateTable,
        };
        setMicrostoreColumnTemplateFormValuesAndIsFormDirty({
            newMicrostoreColumnTemplateFormValues: {
                ...microstoreColumnTemplateFormValues,
                shelfTemplates: newShelfTemplates,
            },
        });
    }

    function removeLocationFromRow(rowIndex: number) {
        if (selectedShelfTemplateIndex === null || !selectedShelfTemplate) {
            return;
        }

        const locationTemplateTable = selectedShelfTemplate.locationTemplateTable;
        const locationTemplateRow = locationTemplateTable[rowIndex];

        const locationColumnIndexToRemove = selectedLocationTemplatePosition?.column ?? locationTemplateRow.length - 1;

        const newLocationTemplateRow = [
            ...locationTemplateRow.slice(0, locationColumnIndexToRemove),
            ...locationTemplateRow.slice(locationColumnIndexToRemove + 1),
        ];

        const newLocationTemplateTable = [...locationTemplateTable];
        newLocationTemplateTable[rowIndex] = newLocationTemplateRow;

        const newShelfTemplates = [...microstoreColumnTemplateFormValues.shelfTemplates];
        newShelfTemplates[selectedShelfTemplateIndex] = {
            ...selectedShelfTemplate,
            locationTemplateTable: newLocationTemplateTable,
        };
        setSelectedLocationTemplatePosition(null);
        setMicrostoreColumnTemplateFormValuesAndIsFormDirty({
            newMicrostoreColumnTemplateFormValues: {
                ...microstoreColumnTemplateFormValues,
                shelfTemplates: newShelfTemplates,
            },
        });
    }

    return (
        <ShelfDetails>
            {selectedShelfTemplate.locationTemplateTable.map((locationTemplateRow, rowIndex) => {
                return (
                    <LocationRowInShelfContainer key={rowIndex}>
                        <LocationRowInShelf>
                            <SmallButton
                                type="button"
                                onClick={() => {
                                    removeLocationFromRow(rowIndex);
                                }}
                            >
                                <FaMinus size={13} />
                            </SmallButton>
                            <LocationInRownContainer>
                                {locationTemplateRow.map((locationTemplate, columnIndex) => {
                                    return (
                                        <LocationInRow
                                            key={columnIndex}
                                            isSelected={
                                                columnIndex === selectedLocationTemplatePosition?.column &&
                                                rowIndex === selectedLocationTemplatePosition?.row
                                            }
                                            onClick={() => {
                                                setSelectedLocationTemplatePosition({
                                                    column: columnIndex,
                                                    row: rowIndex,
                                                });
                                            }}
                                        >
                                            {locationTemplate.productArrangement ||
                                            locationTemplate.productArrangementSingleProductInfo ? (
                                                <FcCheckmark size={13} />
                                            ) : (
                                                <FcLeave size={13} />
                                            )}
                                        </LocationInRow>
                                    );
                                })}
                            </LocationInRownContainer>
                            <SmallButton
                                type="button"
                                onClick={() => {
                                    addLocationToRow(rowIndex);
                                }}
                            >
                                <FaPlus size={13} />
                            </SmallButton>
                        </LocationRowInShelf>

                        <LocationRowInShelfButtonsContainer>
                            <SmallButton
                                type="button"
                                onClick={() => {
                                    moveLocationRowUp(rowIndex);
                                }}
                            >
                                <FaArrowUp size={15} />
                            </SmallButton>
                            <SmallButton
                                type="button"
                                onClick={() => {
                                    removeLocationRow(rowIndex);
                                }}
                            >
                                <FaTrash size={15} />
                            </SmallButton>
                        </LocationRowInShelfButtonsContainer>
                    </LocationRowInShelfContainer>
                );
            })}
            <SmallButton type="button" onClick={addLocationRow}>
                Ajouter une rangée
            </SmallButton>
        </ShelfDetails>
    );
};

const SmallButton = styled(TotemPrimaryButton)`
    padding: 5px;
    font-size: 10px;
`;

const ShelfDetails = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
    border-radius: ${({ theme }) => theme.borderRadius};
    height: 100%;
    background-color: ${({ theme }) => theme.cardBackgroundColor};
`;

const LocationRowInShelfContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    height: 18%;
`;

const LocationRowInShelf = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;
    border: 1px solid ${({ theme }) => theme.darkBorderColor};
    flex-grow: 1;
    height: 100%;
`;

const LocationRowInShelfButtonsContainer = styled.div`
    margin-left: 5px;
    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const LocationInRownContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    height: 100%;
`;

const LocationInRow = styled.div<{ isSelected: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    border: ${({ theme, isSelected }) =>
        isSelected ? `2px solid ${theme.ctaPrimaryColor}` : `1px solid ${theme.darkBorderColor}`};
    flex-grow: 1;
    height: 90%;
    max-width: 10%;
    margin: 1px;
`;

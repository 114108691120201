import React from 'react';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { WarehouseReturnFragment } from 'data/fragments/__generated__/WarehouseReturnFragment';
import { WarehouseReturnState } from 'data/__generated__';
import {
    PASS_WAREHOUSE_RETURN_TO_IN_PROGRESS,
    PASS_WAREHOUSE_RETURN_TO_IN_PROGRESSVariables,
} from 'data/mutations/__generated__/PASS_WAREHOUSE_RETURN_TO_IN_PROGRESS';
import {
    PASS_WAREHOUSE_RETURN_TO_DELIVERED_MUTATION,
    PASS_WAREHOUSE_RETURN_TO_IN_PROGRESS_MUTATION,
    PASS_WAREHOUSE_RETURN_TO_STORED_MUTATION,
} from 'data/mutations/warehouseReturn';
import {
    PASS_WAREHOUSE_RETURN_TO_DELIVERED,
    PASS_WAREHOUSE_RETURN_TO_DELIVEREDVariables,
} from 'data/mutations/__generated__/PASS_WAREHOUSE_RETURN_TO_DELIVERED';
import {
    PASS_WAREHOUSE_RETURN_TO_STORED,
    PASS_WAREHOUSE_RETURN_TO_STOREDVariables,
} from 'data/mutations/__generated__/PASS_WAREHOUSE_RETURN_TO_STORED';

import { SectionContainer } from 'components/DetailsView/Section';
import { Loader, LoaderModeType } from 'components/Loader';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

export const ActionSection = ({ warehouseReturn }: { warehouseReturn: WarehouseReturnFragment }) => {
    const [passWarehouseReturnToInProgress, { loading: passWarehouseReturnToInProgressLoading }] = useMutation<
        PASS_WAREHOUSE_RETURN_TO_IN_PROGRESS,
        PASS_WAREHOUSE_RETURN_TO_IN_PROGRESSVariables
    >(PASS_WAREHOUSE_RETURN_TO_IN_PROGRESS_MUTATION, { variables: { warehouseReturnId: warehouseReturn._id } });
    const [passWarehouseReturnToDelivered, { loading: passWarehouseReturnToDeliveredLoading }] = useMutation<
        PASS_WAREHOUSE_RETURN_TO_DELIVERED,
        PASS_WAREHOUSE_RETURN_TO_DELIVEREDVariables
    >(PASS_WAREHOUSE_RETURN_TO_DELIVERED_MUTATION, {
        variables: { warehouseReturnId: warehouseReturn._id },
    });
    const [passWarehouseReturnToStored, { loading: passWarehouseReturnToStoredLoading }] = useMutation<
        PASS_WAREHOUSE_RETURN_TO_STORED,
        PASS_WAREHOUSE_RETURN_TO_STOREDVariables
    >(PASS_WAREHOUSE_RETURN_TO_STORED_MUTATION, {
        variables: { warehouseReturnId: warehouseReturn._id },
    });

    function displayToast({ success, errorMessage }: { success: boolean; errorMessage: string }) {
        if (success) {
            toast.success('Le changement de statut a bien été fait !');
        } else {
            toast.error(errorMessage);
        }
    }

    async function passToInProgress() {
        const { data, errors } = await passWarehouseReturnToInProgress();

        displayToast({
            success: !!data?.passWarehouseReturnToInProgress,
            errorMessage: "Une erreur inconnue s'est produite : " + errors?.join(', '),
        });
    }

    async function passToDelivered() {
        const { data, errors } = await passWarehouseReturnToDelivered();

        displayToast({
            success: !!data?.passWarehouseReturnToDelivered,
            errorMessage: "Une erreur inconnue s'est produite : " + errors?.join(', '),
        });
    }

    async function passToStored() {
        const { data, errors } = await passWarehouseReturnToStored();

        displayToast({
            success: !!data?.passWarehouseReturnToStored,
            errorMessage: "Une erreur inconnue s'est produite : " + errors?.join(', '),
        });
    }

    return (
        <SectionContainer title="Actions" isInitiallyOpen>
            <ButtonContainer>
                <TotemPrimaryButton
                    onClick={passToInProgress}
                    disabled={
                        passWarehouseReturnToInProgressLoading || warehouseReturn.state !== WarehouseReturnState.Created
                    }
                >
                    Passer en "In progress" (sur la route)
                    {passWarehouseReturnToInProgressLoading ? <Loader mode={LoaderModeType.Spin} size="22px" /> : null}
                </TotemPrimaryButton>
                <TotemPrimaryButton
                    onClick={passToDelivered}
                    disabled={
                        passWarehouseReturnToDeliveredLoading ||
                        warehouseReturn.state !== WarehouseReturnState.InProgress
                    }
                >
                    Livrer le retour entrepôt (= check des livreurs)
                    {passWarehouseReturnToDeliveredLoading ? <Loader mode={LoaderModeType.Spin} size="22px" /> : null}
                </TotemPrimaryButton>
                <TotemPrimaryButton
                    onClick={passToStored}
                    disabled={
                        passWarehouseReturnToStoredLoading || warehouseReturn.state !== WarehouseReturnState.Delivered
                    }
                >
                    Ranger le retour entrepôt (mise à jour des stock entrepôt)
                    {passWarehouseReturnToStoredLoading ? <Loader mode={LoaderModeType.Spin} size="22px" /> : null}
                </TotemPrimaryButton>
            </ButtonContainer>
        </SectionContainer>
    );
};

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-around;

    & > :not(:first-child) {
        margin-left: 20px;
    }
`;

import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import styled from 'styled-components';

import { PAGES } from '../../constants/pages';

import { GET_HOT_DRINKS_ARRANGEMENTS_QUERY } from 'data/queries/hotDrinksArrangement';
import { GET_HOT_DRINKS_ARRANGEMENTS } from 'data/queries/__generated__/GET_HOT_DRINKS_ARRANGEMENTS';

import { Header, HeaderTitle } from 'components/Header';
import { Loader } from 'components/Loader';
import { PageTitle } from 'components/PageTitle';
import { FieldToDisplay, ListView } from 'components/ListView';
import { TotemInput } from 'components/TotemInput';

type FormattedHotDrinksArrangementType = {
    _id: string;
    numberOfProducts: number;
};

export const HotDrinksArrangements = () => {
    const [filterString, setFilterString] = useState('');

    const {
        loading: hotDrinksArrangementsLoading,
        data: hotDrinksArrangementsData,
        error: hotDrinksArrangementsError,
    } = useQuery<GET_HOT_DRINKS_ARRANGEMENTS>(GET_HOT_DRINKS_ARRANGEMENTS_QUERY);

    if (hotDrinksArrangementsLoading && !hotDrinksArrangementsData) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (hotDrinksArrangementsError || !hotDrinksArrangementsData) {
        throw new Error('Une erreur est survenue lors de la récupération des gammes de produits café');
    }

    const { getHotDrinksArrangements: hotDrinksArrangements } = hotDrinksArrangementsData;

    const MICROSTORE_COLUMN_TEMPLATE_FIELDS_TO_DISPLAY: FieldToDisplay<FormattedHotDrinksArrangementType>[] = [
        {
            fieldName: '_id',
            label: 'ID',
        },
        {
            fieldName: 'numberOfProducts',
            label: 'Nombre de produits',
        },
    ];

    // todo, if we need multiple hotDrinksArrangements, add a name and filter then sort by name instead of id.
    const filteredHotDrinksArrangements = hotDrinksArrangements.filter(
        ({ _id }) => _id.toLowerCase().search(filterString.toLowerCase()) !== -1,
    );

    const sortedHotDrinksArrangements = [...filteredHotDrinksArrangements].sort(
        (hotDrinksArrangementA, hotDrinksArrangementB) =>
            hotDrinksArrangementA._id.localeCompare(hotDrinksArrangementB._id),
    );

    const formattedHotDrinksArrangements: FormattedHotDrinksArrangementType[] = sortedHotDrinksArrangements.map(
        ({ _id, productIds }) => {
            return {
                _id,
                numberOfProducts: productIds.length,
            };
        },
    );

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.hotDrinksArrangements} />
                </HeaderTitle>
            </Header>
            <Content>
                <TotemInput
                    label="Recherche"
                    onChange={setFilterString}
                    placeholder="Id des gammes de produits café"
                    value={filterString}
                    autoFocus={true}
                />
                <ListView<FormattedHotDrinksArrangementType>
                    fieldsToDisplay={MICROSTORE_COLUMN_TEMPLATE_FIELDS_TO_DISPLAY}
                    items={formattedHotDrinksArrangements}
                    keyExtractor={(item) => item._id}
                    linkBasePath="/hot-drinks-arrangement/"
                />
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    flex: 1;
    overflow: hidden;
`;

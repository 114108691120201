import React from 'react';

import { CommonStatusTag } from 'components/CommonStatusTag';

import { colors } from 'constants/colors';

import { SupplierState } from 'data/__generated__';
import { SUPPLIER_STATES_LABELS } from '../constants/states';

const getColorsFromState = (state: SupplierState) => {
    const { blue, pantinGrey, pureWhite } = colors;

    switch (state) {
        case SupplierState.Regular:
            return { backgroundColor: blue, labelColor: pureWhite };
        case SupplierState.Archived:
            return { backgroundColor: pantinGrey, labelColor: pureWhite };
    }
};

export const StatusTag = ({ state }: { state: SupplierState }) => {
    const { backgroundColor, labelColor } = getColorsFromState(state);
    return (
        <CommonStatusTag
            backgroundColor={backgroundColor}
            labelColor={labelColor}
            label={SUPPLIER_STATES_LABELS[state]}
        />
    );
};

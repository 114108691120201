import React from 'react';

import styled from 'styled-components';

import { BasicInput, BasicInputProps } from './BasicInput';
import { TotemLabel } from './TotemLabel';

type TotemInputProps = Omit<BasicInputProps, 'onChange'> & {
    className?: string;
    onChange: (value: string) => void;
    label?: string;
    sublabel?: string;
    dataTest?: string;
    textColor?: string;
    inputRef?: React.RefObject<HTMLInputElement>;
};

export const TotemInput = ({ label, sublabel, onChange, dataTest, inputRef, ...props }: TotemInputProps) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {
            target: { value },
        } = e;
        onChange(value);
    };

    return (
        <InputContainer>
            {label ? <TotemLabel>{label}</TotemLabel> : null}
            {sublabel ? <Sublabel>{sublabel}</Sublabel> : null}
            <BasicInput ref={inputRef} onChange={handleChange} data-test={dataTest} {...props} />
        </InputContainer>
    );
};

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;

    & > :not(:first-child) {
        margin-top: 5px;
    }
`;

const Sublabel = styled.span`
    color: ${({ theme }) => theme.infoTextColor};
    font-size: 13px;
`;

import gql from 'graphql-tag';

import { PAYMENT_FRAGMENT } from 'data/fragments/payment';

export const GET_PAYMENTS_QUERY = gql`
    query GET_PAYMENTS($offset: Int!, $stateFilter: [String!], $organizationIdFilter: ID) {
        payments(offset: $offset, stateFilter: $stateFilter, organizationIdFilter: $organizationIdFilter) {
            hasMore
            payments {
                ...PaymentFragment
            }
        }
    }
    ${PAYMENT_FRAGMENT}
`;

export const GET_PAYMENT_QUERY = gql`
    query GET_PAYMENT($paymentId: ID!) {
        payment(paymentId: $paymentId) {
            ...PaymentFragment
        }
    }
    ${PAYMENT_FRAGMENT}
`;

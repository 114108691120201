import React from 'react';

import { ListView, FieldToDisplay } from 'components/ListView';
import { StatusTag } from './StatusTag';

import { GET_STOCK_TRANSFERS_stockTransfers_stockTransfers } from 'data/queries/__generated__/GET_STOCK_TRANSFERS';

import { getExtendedFormattedDatetime } from '../../../helpers/dateTimes';

type FormattedStockTransfersType = {
    _id: string;
    transferDate: string;
    state: JSX.Element;
    originSiteName: string;
    destinationSiteName: string;
    quantityOfProducts: number;
    numberOfRefs: number;
    updatedAt: string;
};

export const StockTransfersList = ({
    hasMore,
    loadMore,
    selectedStockTransfers,
    setSelectedStockTransfers,
    stockTransfers,
}: {
    hasMore: boolean;
    loadMore: () => void;
    selectedStockTransfers: GET_STOCK_TRANSFERS_stockTransfers_stockTransfers[];
    setSelectedStockTransfers: React.Dispatch<
        React.SetStateAction<GET_STOCK_TRANSFERS_stockTransfers_stockTransfers[]>
    >;
    stockTransfers: GET_STOCK_TRANSFERS_stockTransfers_stockTransfers[];
}) => {
    const STOCK_TRANSFER_FIELDS_TO_DISPLAY: FieldToDisplay<FormattedStockTransfersType>[] = [
        {
            fieldName: 'transferDate',
            label: 'Date de transfert',
        },
        {
            fieldName: 'state',
            label: 'Statut',
        },
        {
            fieldName: 'originSiteName',
            label: 'Site de départ',
        },
        {
            fieldName: 'destinationSiteName',
            label: "Site d'arrivée",
        },
        {
            fieldName: 'numberOfRefs',
            label: 'Nombre de références',
        },
        {
            fieldName: 'quantityOfProducts',
            label: 'Nombre de produits',
        },
        {
            fieldName: 'updatedAt',
            label: 'Dernière modif.',
        },
    ];

    const formattedStockTransfers: FormattedStockTransfersType[] = stockTransfers.map(
        ({
            _id,
            transferDate,
            state,
            originSiteName,
            destinationSiteName,
            numberOfRefs,
            quantityOfProducts,
            updatedAt,
        }) => ({
            _id,
            state: <StatusTag state={state} />,
            transferDate,
            originSiteName,
            destinationSiteName,
            numberOfRefs,
            quantityOfProducts,
            updatedAt: getExtendedFormattedDatetime(updatedAt),
        }),
    );

    const selectedStockTransferIds = selectedStockTransfers.map(({ _id }) => _id);

    function setSelectedStockTransferIds(stockTransferIds: string[]) {
        setSelectedStockTransfers(stockTransfers.filter(({ _id }) => stockTransferIds.includes(_id)));
    }

    return (
        <ListView<FormattedStockTransfersType>
            fieldsToDisplay={STOCK_TRANSFER_FIELDS_TO_DISPLAY}
            items={formattedStockTransfers}
            linkBasePath="/stockTransfer/"
            keyExtractor={(item) => item._id}
            loadMore={loadMore}
            hasMore={hasMore}
            selectedKeys={selectedStockTransferIds}
            setSelectedKeys={setSelectedStockTransferIds}
        />
    );
};

import React from 'react';

import { Link, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';

import styled from 'styled-components';
import { FaSave } from 'react-icons/fa';

import { PAGES } from '../../constants/pages';

import { Header, HeaderTitle } from 'components/Header';
import { Loader, LoaderModeType } from 'components/Loader';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { PageTitle } from 'components/PageTitle';

import { GeneralInfoSection } from 'pages/Subventions/SubventionDetails/GeneralInfoSection';

import { SubventionReloadPeriod, useGetSubventionQuery, useUpdateSubventionMutation } from 'data/__generated__';
import { toast } from 'react-toastify';

type ParamTypes = {
    subventionId: string;
};

type FormData = {
    archivedAt: Date | null;
    billToOrganizationId: string | null;
    categories: string[];
    cumulative: boolean;
    currentAmount: number;
    description: string;
    hoursIntervalsAvailable: string[][];
    imageUrl: string;
    name: string;
    organizationId: string;
    reload: SubventionReloadPeriod;
};

export const SubventionDetails = () => {
    const { subventionId = '' } = useParams<ParamTypes>();

    const [updateSubvention, { loading: updateRitualLoading }] = useUpdateSubventionMutation();

    const {
        loading: subventionLoading,
        data: subventionData,
        error: subventionError,
    } = useGetSubventionQuery({
        variables: { subventionId },
    });

    const methods = useForm<FormData>();
    const {
        handleSubmit,
        formState: { isDirty },
        reset,
    } = methods;

    const onSubmit = handleSubmit(async (fields) => {
        const { organizationId, billToOrganizationId, ...rest } = fields;
        const { data } = await updateSubvention({
            variables: {
                subventionId,
                // @ts-ignore
                updateInput: {
                    ...(billToOrganizationId !== organizationId && { billToOrganizationId }),
                    ...rest,
                },
            },
        });

        if (data) {
            const {
                updateSubvention: { subvention: updatedSubvention, errors },
            } = data;
            if (!errors?.length && updatedSubvention) {
                toast.success('La subvention a bien été mise à jour !');
                reset(fields);
            } else {
                if (errors) {
                    errors.forEach((error) => {
                        toast.error(error, { autoClose: false });
                    });

                    if (errors.length > 1) {
                        toast.info('Cliquez pour fermer toutes les notifications', {
                            autoClose: false,
                            onClick: () => toast.dismiss(),
                        });
                    }
                } else {
                    throw Error("Une erreur inconnue s'est produite");
                }
            }
        } else {
            throw Error("Une erreur inconnue s'est produite");
        }
    });

    if (subventionLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (subventionError || !subventionData) {
        throw new Error('Une erreur est survenue lors de la récupération de la subvention');
    }

    const { subvention } = subventionData;

    return (
        <Container>
            <FormProvider {...methods}>
                <Form onSubmit={onSubmit}>
                    <Header>
                        <HeaderTitle>
                            <PageTitle page={PAGES.subventionDetails} />
                        </HeaderTitle>
                        <CTAsContainer>
                            <TotemPrimaryButton type="submit" minWidth="78px" disabled={!isDirty}>
                                {updateRitualLoading ? (
                                    <Loader size="18px" mode={LoaderModeType.Spin} />
                                ) : (
                                    <>
                                        <FaSave size={13} />
                                        <SaveLabel>Mettre à jour</SaveLabel>
                                    </>
                                )}
                            </TotemPrimaryButton>
                            <Link to="/subventions">
                                <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                            </Link>
                        </CTAsContainer>
                    </Header>
                    <Content>
                        <ScrollableContent>
                            <GeneralInfoSection subventionDetails={subvention} />
                        </ScrollableContent>
                    </Content>
                </Form>
            </FormProvider>
        </Container>
    );
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
`;

const SaveLabel = styled.span`
    margin-left: 5px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const CTAsContainer = styled.div`
    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const ScrollableContent = styled.div`
    padding: 15px;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    & > :not(:first-child) {
        margin-top: 15px;
    }
`;

const Content = styled.div`
    flex: 1;
    overflow: hidden;
`;

import {
    ProductArrangementWithProductsFragment,
    ProductArrangementWithProductsFragment_productsWithStockMax,
    ProductArrangementWithProductsFragment_productsWithStockMax_product,
} from 'data/fragments/__generated__/ProductArrangementWithProductsFragment';
import { ProductArrangementUpdateInput } from 'data/__generated__';

export type ProductArrangementProduct = Omit<
    ProductArrangementWithProductsFragment_productsWithStockMax_product,
    '__typename'
>;

export type ProductArrangementProductWithStockMaxFormValues = Omit<
    ProductArrangementWithProductsFragment_productsWithStockMax,
    'stockMaxUnsecured' | 'stockMaxSensei' | '__typename' | 'product'
> & { product: ProductArrangementProduct; stockMaxUnsecured: number | null; stockMaxSensei: number | null };

export type ProductArrangementFormValues = Omit<
    ProductArrangementWithProductsFragment,
    'productsWithStockMax' | '__typename'
> & {
    productsWithStockMax: ProductArrangementProductWithStockMaxFormValues[];
};

export function mapProductArrangementToProductArrangementFormValues({
    productArrangement: { __typename, ...productArrangementValues },
}: {
    productArrangement: ProductArrangementWithProductsFragment;
}): ProductArrangementFormValues {
    return {
        ...productArrangementValues,
    };
}

export function mapProductArrangementFormValuesToProductArrangementUpdateInput({
    productArrangementFormValues,
}: {
    productArrangementFormValues: ProductArrangementFormValues;
}): ProductArrangementUpdateInput {
    return {
        _id: productArrangementFormValues._id,
        name: productArrangementFormValues.name,
        productIdsWithStockMax: productArrangementFormValues.productsWithStockMax
            .filter(({ stockMaxUnsecured }) => stockMaxUnsecured !== null)
            .filter(({ stockMaxSensei }) => stockMaxSensei !== null)
            .map(({ product, stockMaxUnsecured, stockMaxSensei }) => ({
                productId: product._id,
                stockMaxUnsecured: stockMaxUnsecured as number,
                stockMaxSensei: stockMaxSensei as number,
            })),
        reassortmentType: productArrangementFormValues.reassortmentType,
    };
}

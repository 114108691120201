import React from 'react';

import styled from 'styled-components';
import { IconEdit } from '@tabler/icons';
import { Link } from 'react-router-dom';

import { colors } from 'constants/colors';
import { WAREHOUSE_RETURN_STATE_COLORS } from '../constants/states';

import { GET_SIMPLE_MICROSTORE_SITES_microstoreSites } from 'data/queries/__generated__/GET_SIMPLE_MICROSTORE_SITES';
import { GET_MICROSTORE_SITE_siteWithLocationInfo } from 'data/queries/__generated__/GET_MICROSTORE_SITE';
import { WarehouseReturnFragment } from 'data/fragments/__generated__/WarehouseReturnFragment';

export const WarehouseReturnTrelloItem = ({
    warehouseReturn,
    originSite,
}: {
    warehouseReturn: WarehouseReturnFragment;
    originSite: GET_SIMPLE_MICROSTORE_SITES_microstoreSites | GET_MICROSTORE_SITE_siteWithLocationInfo;
}) => {
    return (
        <ItemContent>
            <ItemHeader backgroundColor={WAREHOUSE_RETURN_STATE_COLORS[warehouseReturn.state]}>
                {originSite.name}
                <Link to={`/warehouse-return/${warehouseReturn._id}`}>
                    <EditIcon color={colors.pureWhite} size="15" />
                </Link>
            </ItemHeader>
            <ItemBody>
                <Field>
                    Nombre de produits retour entrepôt : Ref {warehouseReturn.returnedProducts.length}, Quantité{' '}
                    {warehouseReturn.returnedProducts.reduce((acc, { quantity }) => acc + quantity, 0)}
                </Field>
                <Field>
                    Nombre de produits retour entrepôt sans update de stock (car non prévus dans la livraison) : Ref{' '}
                    {warehouseReturn.returnedWithNoStockUpdateProducts.length}, Quantité{' '}
                    {warehouseReturn.returnedWithNoStockUpdateProducts.reduce((acc, { quantity }) => acc + quantity, 0)}
                </Field>
                <Field>
                    Nombre de produits expirés : Ref {warehouseReturn.expiredProducts.length}, Quantité{' '}
                    {warehouseReturn.expiredProducts.reduce((acc, { quantity }) => acc + quantity, 0)}
                </Field>
                <Field>
                    Nombre de produits cassés : Ref {warehouseReturn.brokenProducts.length}, Quantité{' '}
                    {warehouseReturn.brokenProducts.reduce((acc, { quantity }) => acc + quantity, 0)}
                </Field>
            </ItemBody>
        </ItemContent>
    );
};

const ItemContent = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: ${({ theme }) => theme.borderRadius};
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
    overflow: hidden;
`;

const ItemHeader = styled.div<{ backgroundColor: string }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${({ backgroundColor }) => backgroundColor};
    color: ${colors.pureWhite};
    padding: 10px;
    font-weight: 800;
    text-align: center;
`;

const ItemBody = styled.div`
    padding: 10px;
`;

const EditIcon = styled(IconEdit)`
    flex-shrink: 0;
    cursor: pointer;

    &:hover {
        transform: scale(1.1);
    }
`;

const Field = styled.div`
    padding: 10px;
`;

import React from 'react';

import styled from 'styled-components';

import { PAGES } from '../../constants/pages';

import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { Header, HeaderTitle } from 'components/Header';
import { PageTitle } from 'components/PageTitle';
import { useNavigate } from 'react-router-dom';
import { PROCESS_NOTIFICATION_TOKEN_MUTATION } from 'data/mutations/notification';
import { PROCESS_NOTIFICATION_TOKEN } from 'data/mutations/__generated__/PROCESS_NOTIFICATION_TOKEN';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

export const Notifications = () => {
    const navigate = useNavigate();

    const [processNotificationTickets, { loading: processNotificationTicketsLoading }] =
        useMutation<PROCESS_NOTIFICATION_TOKEN>(PROCESS_NOTIFICATION_TOKEN_MUTATION, {
            onCompleted: ({ processNotificationTickets }) => {
                if (processNotificationTickets) {
                    toast.success('Les erreurs ont bien été traitées');
                } else {
                    toast.error('Une erreur est survenue pendant le traitement des notifications');
                }
            },
        });

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.notifications} />
                </HeaderTitle>
            </Header>
            <ButtonsContainer>
                <TotemPrimaryButton onClick={() => navigate('/notifications/organization')}>
                    Notifier une organisation
                </TotemPrimaryButton>
                <TotemPrimaryButton onClick={() => navigate('/notifications/site')}>
                    Notifier un site
                </TotemPrimaryButton>
                <TotemPrimaryButton onClick={() => navigate('/notifications/user')}>
                    Notifier un utilisateur
                </TotemPrimaryButton>
                <TotemPrimaryButton
                    onClick={(event) => {
                        // We're in a form, this will submit
                        event.preventDefault();
                        processNotificationTickets();
                    }}
                    isSecondaryStyle
                    disabled={processNotificationTicketsLoading}
                >
                    Traiter les erreurs de notifications
                </TotemPrimaryButton>
            </ButtonsContainer>
        </Container>
    );
};

const Container = styled.form`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const ButtonsContainer = styled.div`
    margin-top: 30px;
    margin-left: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    & ${TotemPrimaryButton}:not(:first-child) {
        margin-top: 20px;
    }
`;

import { gql } from '@apollo/client';

import { CATEGORY_FRAGMENT } from '../fragments/category';

export const UPDATE_CATEGORY_MUTATION = gql`
    mutation UPDATE_CATEGORY($categoryId: ID!, $fields: CategoryUpdateInput!) {
        updateCategory(categoryId: $categoryId, fields: $fields) {
            success
            error
            category {
                ...CategoryFragment
            }
        }
    }
    ${CATEGORY_FRAGMENT}
`;

export const UPDATE_CATEGORY_STATE_MUTATION = gql`
    mutation UPDATE_CATEGORY_STATE($categoryId: ID!, $stateToSet: CategoryState!) {
        updateCategoryState(categoryId: $categoryId, stateToSet: $stateToSet) {
            success
            error
            category {
                ...CategoryFragment
            }
        }
    }
    ${CATEGORY_FRAGMENT}
`;

export const CREATE_CATEGORY_MUTATION = gql`
    mutation CREATE_CATEGORY($fields: CategoryUpdateInput!) {
        createCategory(fields: $fields) {
            success
            error
            categoryId
        }
    }
`;

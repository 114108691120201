import gql from 'graphql-tag';

export const BundleFragment = gql`
    fragment BundleFragment on Bundle {
        _id
        boxes {
            _id
            deliveryId
            hasOnsiteSetup
            label
            orderId
            position
            roundColor
            roundName
        }
        createdAt
        number
        numberOfReferences
        numberOfUnits
        operatorName
        preparationDates {
            start
            end
        }
        preparationDuration
        state
        stateHistory {
            state
        }
        type
    }
`;

import gql from 'graphql-tag';

export const URBANTZ_ROUND_PRESET_FRAGMENT = gql`
    fragment UrbantzRoundPresetFragment on UrbantzRoundPreset {
        _id
        createdAt
        dayIndex
        totemIds
        updatedAt
    }
`;

import React from 'react';
import { useParams, Link } from 'react-router-dom';

import { useMutation, useQuery } from '@apollo/client';

import { toast } from 'react-toastify';
import styled from 'styled-components';

import { PAGES } from '../../constants/pages';
import { UPDATE_TAG_MUTATION } from 'data/mutations/tag';
import { GET_TAG_QUERY } from 'data/queries/tag';
import { UPDATE_TAG, UPDATE_TAGVariables } from 'data/mutations/__generated__/UPDATE_TAG';
import { GET_TAG, GET_TAGVariables } from 'data/queries/__generated__/GET_TAG';
import { GET_TAG_PRODUCTS_QUERY } from 'data/queries/product';
import { GET_TAG_PRODUCTS, GET_TAG_PRODUCTSVariables } from 'data/queries/__generated__/GET_TAG_PRODUCTS';
import { TagType, TagState } from 'data/__generated__';

import { Header, HeaderTitle } from 'components/Header';
import { PageTitle } from 'components/PageTitle';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

import { TagForm } from './components/TagForm';
import { SelectedOption } from 'components/TotemSelect';
import { Loader, LoaderModeType } from 'components/Loader';

type FormType = {
    _id: string;
    displayIndex: number;
    name: string;
    type: SelectedOption<TagType> | null;
    state: SelectedOption<TagState> | null;
};

type ParamTypes = {
    tagId: string;
};

export const TagUpdate = () => {
    const { tagId = '' } = useParams<ParamTypes>();

    const {
        loading: tagLoading,
        data: tagData,
        error: tagError,
    } = useQuery<GET_TAG, GET_TAGVariables>(GET_TAG_QUERY, {
        variables: { tagId },
    });

    const { loading: productsLoading, data: productsData } = useQuery<GET_TAG_PRODUCTS, GET_TAG_PRODUCTSVariables>(
        GET_TAG_PRODUCTS_QUERY,
        {
            variables: { tagId },
        },
    );

    const [updateTag] = useMutation<UPDATE_TAG, UPDATE_TAGVariables>(UPDATE_TAG_MUTATION);
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>, formData: FormType) => {
        e.preventDefault();
        const { displayIndex, name, type, state, _id } = formData;
        if (!type) {
            toast.error('Il manque le champ type');
            return;
        }

        if (!state) {
            toast.error('Il manque le champ state');
            return;
        }

        if (!displayIndex || !Number.isInteger(Number(displayIndex))) {
            toast.error("Index d'affichage devrait être un nombre entier");
            return;
        }

        const { value: typeOption } = type;
        const { value: stateOption } = state;

        if (stateOption === TagState.Archived && productsData?.productsWithTagId.length) {
            toast.error('Un tag ne devrait avoir aucun produit associé pour être archivé');
            return;
        }

        const result = await updateTag({
            variables: {
                tag: {
                    _id,
                    displayIndex: Number(displayIndex),
                    name,
                    type: typeOption,
                    state: stateOption,
                },
            },
        });
        const { data } = result;
        if (data) {
            const {
                updateTag: { success },
            } = data;
            if (success) {
                toast.success(`Le tag "${formData.name}" a bien été mis à jour !`);
            } else {
                const {
                    updateTag: { error },
                } = data;
                if (error) {
                    toast.error(`L'erreur suivante est survenue : ${error}`);
                } else {
                    throw Error("Une erreur inconnue s'est produite");
                }
            }
        } else {
            throw Error("Une erreur inconnue s'est produite");
        }
    };

    function canISubmit(submitData: FormType): boolean {
        return submitData.name !== '';
    }

    if (tagLoading || tagError || !tagData) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    const {
        tag: { _id, displayIndex, name, type, state },
    } = tagData;

    const initialFormData = {
        _id,
        displayIndex,
        name,
        type: { value: type, label: type },
        state: { value: state, label: state },
    };

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.tagCreate} />
                </HeaderTitle>
                <Link to="/tags">
                    <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                </Link>
            </Header>
            <Content>
                <TagForm<FormType>
                    handleSubmit={handleSubmit}
                    initialFormData={initialFormData}
                    canISubmit={canISubmit}
                    submitMessage="Mettre à jour le tag"
                />
                <h4>Produits dans lequels ce tag est actuellement utilisé:</h4>
                <ProductsContainer>
                    {productsLoading ? (
                        <Loader mode={LoaderModeType.Spin} />
                    ) : productsData?.productsWithTagId.length ? (
                        productsData?.productsWithTagId?.map((product) => {
                            return <StyledLink to={`/product/${product._id}`}>• {product.fullname}</StyledLink>;
                        })
                    ) : (
                        'Aucun produit associé'
                    )}
                </ProductsContainer>
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    overflow: hidden;
`;

const ProductsContainer = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
`;

const StyledLink = styled(Link)`
    color: ${({ theme }) => theme.ctaPrimaryHoveredColor};
    text-decoration: none;
    font-size: 16px;
    text-overflow: ellipsis;
    &:hover {
        text-decoration: underline;
    }
`;

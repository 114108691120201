import gql from 'graphql-tag';
import { FORM_ERROR_FRAGMENT, PRODUCT_FRAGMENT } from '../fragments/product';

export const UPDATE_PRODUCT_MUTATION = gql`
    mutation UPDATE_PRODUCT($productId: ID!, $fields: ProductUpdateInput!) {
        updateProduct(productId: $productId, fields: $fields) {
            success
            formErrors {
                ...FormErrorFragment
            }
            updatedProduct {
                ...ProductFragment
            }
            errors
        }
    }
    ${PRODUCT_FRAGMENT},
    ${FORM_ERROR_FRAGMENT}
`;

export const CREATE_PRODUCT_MUTATION = gql`
    mutation CREATE_PRODUCT($fields: ProductUpdateInput!) {
        createProductResolver(fields: $fields) {
            success
            formErrors {
                ...FormErrorFragment
            }
            createdProductId
        }
    }
    ${FORM_ERROR_FRAGMENT}
`;

export const CREATE_PRODUCTS_FROM_IMPORT_MUTATION = gql`
    mutation CREATE_PRODUCTS_FROM_IMPORT($productsToCreate: [ProductCreateCsvImport!]!) {
        createProductsFromImport(productsToCreate: $productsToCreate) {
            success
            error
            products {
                ...ProductFragment
            }
        }
    }
    ${PRODUCT_FRAGMENT}
`;

export const UPDATE_PRODUCTS_FROM_IMPORT_MUTATION = gql`
    mutation UPDATE_PRODUCTS_FROM_IMPORT($productsToUpdate: [ProductUpdateCsvImport!]!) {
        updateProductsFromImport(productsToUpdate: $productsToUpdate) {
            success
            error
            products {
                ...ProductFragment
            }
        }
    }
    ${PRODUCT_FRAGMENT}
`;

export const UPDATE_PRODUCT_STATE_MUTATION = gql`
    mutation UPDATE_PRODUCT_STATE($stateToSet: ProductState!, $productId: ID!) {
        updateProductStateResolver(stateToSet: $stateToSet, productId: $productId) {
            success
            error
            formErrors {
                ...FormErrorFragment
            }
            product {
                ...ProductFragment
            }
        }
    }
    ${PRODUCT_FRAGMENT},
    ${FORM_ERROR_FRAGMENT}
`;

export const REPLACE_PRODUCT_IN_ORDERS_MUTATION = gql`
    mutation REPLACE_PRODUCT_IN_ORDERS($productToRemoveId: ID!, $productToAddId: ID!) {
        replaceProductInOrders(productToRemoveId: $productToRemoveId, productToAddId: $productToAddId) {
            success
            errors
        }
    }
`;

export const REMOVE_PRODUCT_IN_ORDERS_MUTATION = gql`
    mutation REMOVE_PRODUCT_IN_ORDERS($productToRemoveId: ID!) {
        removeProductInOrders(productToRemoveId: $productToRemoveId) {
            success
            errors
        }
    }
`;

import React from 'react';

import { BsCaretRightFill } from 'react-icons/bs';
import styled from 'styled-components';

import { colors } from 'constants/colors';

import { TotemLabel } from 'components/TotemLabel';
import { Option, SelectedOption, TotemSelect } from 'components/TotemSelect';

export const SitesSelector = ({
    sitesOptions,
    originSiteOption,
    destinationSiteOption,
    handleOriginSiteSelect,
    handleDestinationSiteSelect,
    isDisabled,
}: {
    sitesOptions: Option<string>[];
    originSiteOption: SelectedOption<string>;
    destinationSiteOption: SelectedOption<string>;
    handleOriginSiteSelect: (option: SelectedOption<string>) => void;
    handleDestinationSiteSelect: (option: SelectedOption<string>) => void;
    isDisabled?: boolean;
}) => {
    return (
        <Container>
            <SiteInput>
                <TotemSelect
                    dataTest="origin-site"
                    label="Site de départ"
                    placeholder="Sélectionner un site (recherche par nom ou par _id)"
                    value={originSiteOption}
                    options={sitesOptions}
                    isDisabled={isDisabled}
                    onChange={handleOriginSiteSelect}
                />
            </SiteInput>
            <TransferIcon>
                <TotemLabel>&nbsp;</TotemLabel> {/* Placeholder */}
                <BsCaretRightFill size={25} color={colors.green} />
            </TransferIcon>
            <SiteInput>
                <TotemSelect
                    dataTest="destination-site"
                    label="Site d'arrivée"
                    placeholder="Sélectionner un site (recherche par nom ou par _id)"
                    value={destinationSiteOption}
                    options={sitesOptions}
                    isDisabled={isDisabled}
                    onChange={handleDestinationSiteSelect}
                />
            </SiteInput>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

const TransferIcon = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 5px;

    & > :not(:first-child) {
        margin-top: 5px;
    }
`;

const SiteInput = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    & > :not(:first-child) {
        margin-top: 5px;
    }
`;

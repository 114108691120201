import gql from 'graphql-tag';
import { LOGGED_IN_USER_FRAGMENT, USER_NOTIFICATION_PREFERENCES } from '../fragments/user';

export const TOTEM_ADMIN_SIGNIN = gql`
    mutation TOTEM_ADMIN_SIGNIN($username: String!, $password: String!) {
        signinTotemAdmin(username: $username, password: $password) {
            success
            error
            token
            user {
                ...LoggedInUserFragment
            }
        }
    }
    ${LOGGED_IN_USER_FRAGMENT}
`;

export const VERIFY_LOGGED_IN = gql`
    mutation VERIFY_LOGGED_IN {
        verifyLoggedIn {
            success
            user {
                ...LoggedInUserFragment
            }
        }
    }
    ${LOGGED_IN_USER_FRAGMENT}
`;

export const UPDATE_ADMIN_USER_MUTATION = gql`
    mutation UPDATE_ADMIN_USER($fieldsToUpdate: UserUpdateInput, $userId: ID!) {
        updateAdminUser(fieldsToUpdate: $fieldsToUpdate, userId: $userId) {
            success
            error
        }
    }
`;

export const TOGGLE_USER_NOTIFICATION_PREFERENCE_FROM_ADMIN = gql`
    mutation toggleUserNotificationPreferenceFromAdmin(
        $notificationPreference: String!
        $channel: String!
        $userId: ID!
    ) {
        toggleUserNotificationPreferenceFromAdmin(
            notificationPreference: $notificationPreference
            channel: $channel
            userId: $userId
        ) {
            ...NotificationPreferences
        }
    }
    ${USER_NOTIFICATION_PREFERENCES}
`;

export const CREATE_TOTEAM_USER_MUTATION = gql`
    mutation CREATE_TOTEAM_USER($firstname: String!, $lastname: String!, $emailAddress: String!) {
        createToteamUserResolver(firstname: $firstname, lastname: $lastname, emailAddress: $emailAddress) {
            user {
                _id
            }
            success
            error
        }
    }
`;

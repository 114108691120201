import React from 'react';

import { GET_USER_userFromAdmin_wallet } from 'data/queries/__generated__/GET_USER';
import { CreditType, GetTotemCashHistoryQueryResult } from 'data/__generated__';
import { PaymentMethodType } from './types';

import { DetailValue } from 'components/DetailsView';
import { TotemLabel } from 'components/TotemLabel';
import { CardAndMealVoucherPaymentMethods } from 'pages/Users/components/CardAndMealVoucherPaymentMethods';
import { Credits } from 'pages/Users/components/Credits';
import { TotemCashHistory } from 'pages/Users/components/TotemCashHistory';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export function PaymentMethods({
    userId,
    wallet,
    getTotemCashHistoryResult,
}: {
    userId: string;
    wallet: GET_USER_userFromAdmin_wallet;
    getTotemCashHistoryResult: GetTotemCashHistoryQueryResult;
}) {
    const { creditCards, mealVouchers, subventions, credits } = wallet;
    const { data } = getTotemCashHistoryResult;
    const paymentMethods: PaymentMethodType[] = [...(creditCards || []), ...(mealVouchers || [])];
    return (
        <>
            {subventions &&
                subventions.length > 0 &&
                subventions.map(({ _id, name }) => <DetailValue key={_id} label="subvention" value={name} />)}

            {credits ? (
                <>
                    <TotemLabel>Crédits Actifs (dont Totem Cash)</TotemLabel>
                    <Credits credits={credits} />
                </>
            ) : null}
            {data?.totemCashHistory ? (
                <>
                    <TotemLabel>
                        Historique Totem Cash (5 dernières recharges,{' '}
                        <SeeMoreLink to={`/credits?userId=${userId}&type=${CreditType.Cash}`}>voir plus</SeeMoreLink>)
                    </TotemLabel>
                    <TotemCashHistory totemCashHistory={data.totemCashHistory} />
                </>
            ) : null}
            <TotemLabel>Cartes Bancaires et Titres Restaurants</TotemLabel>
            <CardAndMealVoucherPaymentMethods paymentMethods={paymentMethods} />
        </>
    );
}

const SeeMoreLink = styled(Link)`
    text-transform: lowercase;
    color: ${({ theme }) => theme.textColor};
`;

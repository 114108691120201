import React from 'react';

import { useQuery } from '@apollo/client';
import styled from 'styled-components';

import { GET_MICROSTORE_SITE_QUERY } from 'data/queries/site';
import { GET_MICROSTORE_SITE } from 'data/queries/__generated__/GET_MICROSTORE_SITE';
import { GET_SETUP_SITE_INVENTORY_QUERY } from 'data/queries/siteInventory';
import {
    GET_SETUP_SITE_INVENTORY,
    GET_SETUP_SITE_INVENTORYVariables,
} from 'data/queries/__generated__/GET_SETUP_SITE_INVENTORY';
import { GET_MICROSTORE_PRODUCTS_QUERY } from 'data/queries/product';
import { GET_MICROSTORE_PRODUCTS } from 'data/queries/__generated__/GET_MICROSTORE_PRODUCTS';

import { Loader } from 'components/Loader';
import { GeneralInfoSection } from './GeneralInfoSection';
import { ColumnDetailsSection } from './ColumnDetailsSection';
import { TimingSection } from './TimingSection';
import { ActionSection } from './ActionSection';
import { PhotosSection } from './PhotosSection';
import { GET_SITE_DELIVERY_OF_DATE_QUERY } from 'data/queries/delivery';
import {
    GET_SITE_DELIVERY_OF_DATE,
    GET_SITE_DELIVERY_OF_DATEVariables,
} from 'data/queries/__generated__/GET_SITE_DELIVERY_OF_DATE';
import { SiteSetupFragment } from 'data/fragments/__generated__/SiteSetupFragment';

export const SiteSetupDetailsContent = ({ siteSetup }: { siteSetup: SiteSetupFragment }) => {
    // todo refacto the microstore queries
    const {
        data: microstoreSiteData,
        loading: microstoreSiteLoading,
        error: microstoreSiteError,
    } = useQuery<GET_MICROSTORE_SITE>(GET_MICROSTORE_SITE_QUERY, { variables: { siteId: siteSetup.siteId } });

    const {
        data: siteInventoryData,
        loading: siteInventoryLoading,
        error: siteInventoryError,
    } = useQuery<GET_SETUP_SITE_INVENTORY, GET_SETUP_SITE_INVENTORYVariables>(GET_SETUP_SITE_INVENTORY_QUERY, {
        variables: { siteSetupId: siteSetup._id },
    });

    const {
        data: getMicrostoreProductsData,
        loading: getMicrostoreProductsLoading,
        error: getMicrostoreProductsError,
    } = useQuery<GET_MICROSTORE_PRODUCTS>(GET_MICROSTORE_PRODUCTS_QUERY);

    const {
        data: getSiteDeliveryData,
        loading: getSiteDeliveryLoading,
        error: getSiteDeliveryError,
    } = useQuery<GET_SITE_DELIVERY_OF_DATE, GET_SITE_DELIVERY_OF_DATEVariables>(GET_SITE_DELIVERY_OF_DATE_QUERY, {
        variables: {
            deliveryDateFormatted: siteSetup.date,
            siteId: siteSetup.siteId,
        },
    });

    if (getSiteDeliveryLoading || microstoreSiteLoading || siteInventoryLoading || getMicrostoreProductsLoading) {
        return (
            <Content>
                <Loader />
            </Content>
        );
    }

    if (
        getSiteDeliveryError ||
        !getSiteDeliveryData ||
        microstoreSiteError ||
        !microstoreSiteData ||
        siteInventoryError ||
        !siteInventoryData ||
        getMicrostoreProductsError ||
        !getMicrostoreProductsData
    ) {
        throw new Error(
            `L'erreur suivante est survenue : ${
                getSiteDeliveryError || microstoreSiteError || siteInventoryError || getMicrostoreProductsError
            }`,
        );
    }

    const { siteWithLocationInfo: site } = microstoreSiteData;
    const { getSetupSiteInventory: siteInventory } = siteInventoryData;
    const { getMicrostoreProducts: products } = getMicrostoreProductsData;
    const { getSiteDeliveryOfDate: delivery } = getSiteDeliveryData;

    if (!delivery) {
        throw new Error(`Il n'y a pas de livraison prévu pour ce rangement à la date ${siteSetup.date}`);
    }

    const deliveredStoreProductQuantities = delivery.boxes
        .filter(({ siteId }) => siteId)
        .reduce<Record<string, number>>((acc, box) => {
            box.products.forEach((product) => {
                const { _id: productId, quantity, quantityPicked } = product;
                acc[productId] = (acc[productId] ?? 0) + (quantityPicked ?? quantity);
            });

            return acc;
        }, {});

    return (
        <Content>
            <ScrollableContent>
                <GeneralInfoSection site={site} siteSetup={siteSetup} />
                <ActionSection siteSetup={siteSetup} />
                <TimingSection site={site} siteSetup={siteSetup} />
                <ColumnDetailsSection
                    deliveredStoreProductQuantities={deliveredStoreProductQuantities}
                    products={products}
                    site={site}
                    siteSetup={siteSetup}
                    siteInventory={siteInventory}
                />
                <PhotosSection siteSetup={siteSetup} />
            </ScrollableContent>
        </Content>
    );
};

const Content = styled.div`
    flex: 1;
    overflow: hidden;
`;

const ScrollableContent = styled.div`
    padding: 15px;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

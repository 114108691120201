import React from 'react';

import { useQuery } from '@apollo/client';
import type { ActionMeta } from 'react-select';

import { GET_PRODUCTS_WITH_STOCKS_QUERY } from 'data/queries/product';
import {
    GET_PRODUCTS_WITH_STOCKS,
    GET_PRODUCTS_WITH_STOCKSVariables,
} from 'data/queries/__generated__/GET_PRODUCTS_WITH_STOCKS';

import { Loader, LoaderModeType } from 'components/Loader';
import { Option, SelectedOption, TotemSelect } from 'components/TotemSelect';
import { getProductFullName } from 'pages/Products/utils';

export const ProductWithStocksSelector = ({
    productOption,
    handleProductSelect,
    siteId,
    label = 'Ajouter un produit',
}: {
    productOption: SelectedOption<string>;
    handleProductSelect: (option: Option<string> | null | undefined, action: ActionMeta<Option<string>>) => void;
    siteId: string;
    label?: string;
}) => {
    const {
        loading: productsLoading,
        data: productsData,
        error: productsError,
    } = useQuery<GET_PRODUCTS_WITH_STOCKS, GET_PRODUCTS_WITH_STOCKSVariables>(GET_PRODUCTS_WITH_STOCKS_QUERY, {
        variables: { siteId },
    });

    if (productsLoading) {
        return <Loader mode={LoaderModeType.Spin} />;
    }

    if (productsError || !productsData) {
        throw new Error('Une erreur est survenue lors de la récupération des produits');
    }

    const productsWithStocks = [...productsData.productsWithStocks]?.sort(
        ({ stock: stockA }, { stock: stockB }) => stockB - stockA,
    );
    const productsOptions = productsWithStocks?.map(({ _id, name, brand, stock, volume }) => ({
        value: _id,
        label: `${getProductFullName({ name, brand, volume })} (stock: ${stock})`,
    }));
    return (
        <TotemSelect
            label={label}
            placeholder="Sélectionner un produit (recherche par nom ou par _id)"
            value={productOption}
            options={productsOptions}
            onChange={handleProductSelect}
            dataTest="product-selector"
        />
    );
};

import React, { Dispatch, SetStateAction, useState } from 'react';

import { RITUAL_GAME_TYPE_LABELS, RITUAL_TYPE_LABELS } from '../constants/types';

import { GetDetailedRitualQuery, RitualGameType, RitualType } from 'data/__generated__';
import { RitualGameFragment } from 'data/fragments/__generated__/RitualGameFragment';

import { TotemPopup } from 'components/TotemPopup';
import { TotemSelect } from 'components/TotemSelect';

export const ChangeTypePopup = ({
    isOpen,
    setIsOpen,
    ritual,
    ritualGame,
}: {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    ritual: NonNullable<GetDetailedRitualQuery['detailedRitual']>;
    ritualGame: RitualGameFragment | null;
}) => {
    const [selectedType, setSelectedType] = useState<RitualType>(ritual.type);
    const [selectedGameType, setSelectedGameType] = useState<RitualGameType | null>(ritualGame?.type ?? null);

    const typeOptions = Object.values(RitualType).map((type) => ({
        value: type,
        label: RITUAL_TYPE_LABELS[type],
    }));

    const gameTypeOptions = Object.values(RitualGameType).map((type) => ({
        value: type,
        label: RITUAL_GAME_TYPE_LABELS[type],
    }));

    return (
        <TotemPopup
            title="Sélectionnez votre type de rituel"
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            contentOverflow="visible"
        >
            <TotemSelect<RitualType>
                label="Type"
                value={typeOptions.find((option) => option.value === selectedType)}
                options={typeOptions}
                placeholder="Type"
                onChange={async (option) => {
                    if (option?.value) {
                        setSelectedType(option.value);
                    }
                }}
            />
            {selectedType === RitualType.Game ? (
                <TotemSelect<RitualGameType>
                    label="Type du jeu"
                    value={gameTypeOptions.find((option) => option.value === selectedGameType)}
                    options={gameTypeOptions}
                    placeholder="Type du jeu"
                    onChange={async (option) => {
                        if (option?.value) {
                            setSelectedGameType(option.value);
                        }
                    }}
                />
            ) : null}
        </TotemPopup>
    );
};

import React, { useContext } from 'react';

import styled, { ThemeContext } from 'styled-components';
import { BiArrowFromTop } from 'react-icons/bi';

import { Theme } from 'contexts/theme';

import { MicrostoreProductFragment_expiryDateRecordsWarehouse } from 'data/fragments/__generated__/MicrostoreProductFragment';
import {
    GetMicrostoreSitesWithLocationsInfoQuery,
    MicrostoreColumnWithShelvesFragmentFragment,
    ProductArrangementFragmentFragment,
    ProductArrangementReassortmentType,
    ProductFlow,
} from 'data/__generated__';

import {
    MicrostoreColumnFormValues,
    microstoreColumnSomeLocationHelper,
    MicrostoreLocationFormValues,
    MicrostoreProductsStockInfoFormValues,
    MicrostoreShelfFormValues,
} from './FormHelper/DataMapper';

import { BirthdayDate, dateFromString, formatDateAsAnniversary } from 'helpers/dateTimes';
import { getReplacementProductStockInLocation, getStockToRemoveInLocation } from './FormHelper/stockInLocationHelper';
import { ProductArrangementFragment_productIdsWithStockMax } from 'data/fragments/__generated__/ProductArrangementFragment';
import { GET_MICROSTORE_SITE_siteWithLocationInfo } from 'data/queries/__generated__/GET_MICROSTORE_SITE';

function getProductInfoInArrangement({
    productId,
    productArrangement,
    productArrangementSingleProductInfo,
}: {
    productId: string | null;
    productArrangement: ProductArrangementFragmentFragment | null;
    productArrangementSingleProductInfo:
        | MicrostoreColumnWithShelvesFragmentFragment['shelves'][number]['locationTable'][number][number]['microstoreLocationTemplate']['productArrangementSingleProductInfo']
        | null;
}): Omit<ProductArrangementFragment_productIdsWithStockMax, '__typename'> | null {
    if (!productId) {
        return null;
    }

    if (productArrangement) {
        return (
            productArrangement.productIdsWithStockMax.find(
                (productIdWithStockMax) => productId === productIdWithStockMax.productId,
            ) ?? null
        );
    }

    if (productArrangementSingleProductInfo && productArrangementSingleProductInfo.productId === productId) {
        return productArrangementSingleProductInfo;
    }

    return null;
}

function getStockMaxFromProductInfoInArrangement({
    productInfoInArrangement,
    isSecured,
}: {
    productInfoInArrangement: Omit<ProductArrangementFragment_productIdsWithStockMax, '__typename'> | null;
    isSecured: boolean;
}): number | null {
    return (isSecured ? productInfoInArrangement?.stockMaxSensei : productInfoInArrangement?.stockMaxUnsecured) ?? null;
}

function getExpiryDateColor(expiryDate: string, theme: Theme) {
    const today = new Date();
    if (formatDateAsAnniversary({ dateTime: today }) === expiryDate) {
        return theme.warningColor;
    }

    if (today > dateFromString(expiryDate)) {
        return theme.errorColor;
    }

    return 'inherit';
}

function getExpiryDateDisplay(expiryDateRecords: MicrostoreProductFragment_expiryDateRecordsWarehouse[], theme: any) {
    if (!expiryDateRecords.length) {
        return {
            label: 'No DLC',
            color: 'inherit',
        };
    }
    const sortedExpiryDateRecords = [...expiryDateRecords].sort((expiryDateRecordA, expiryDateRecordB) =>
        dateFromString(expiryDateRecordA.expiryDate as string) < dateFromString(expiryDateRecordB.expiryDate as string)
            ? -1
            : 1,
    );

    return {
        label: sortedExpiryDateRecords[0].expiryDate + (sortedExpiryDateRecords.length > 1 ? '...' : ''),
        color: getExpiryDateColor(sortedExpiryDateRecords[0].expiryDate, theme),
    };
}

function getLocationBackgroundColor({
    column,
    displayOnlyTomorrowForPrint,
    flow,
    location,
    switchColumn,
    trackExpiryDate,
}: {
    column: MicrostoreColumnFormValues;
    displayOnlyTomorrowForPrint: boolean;
    flow: ProductFlow | undefined;
    location: MicrostoreLocationFormValues;
    switchColumn: MicrostoreColumnFormValues | null;
    trackExpiryDate: boolean;
}) {
    if (location.replacementProductId === null) {
        return 'inherit';
    }

    const isPresentToday = microstoreColumnSomeLocationHelper({
        microstoreColumnFormValues: column,
        someFunction: (otherLocation) => location.replacementProductId === otherLocation.productId,
    });
    const isPresentInSwitchToday =
        switchColumn &&
        microstoreColumnSomeLocationHelper({
            microstoreColumnFormValues: switchColumn,
            someFunction: (otherLocation) => location.replacementProductId === otherLocation.productId,
        });

    const isRelocated =
        location.replacementProductId !== location.productId && (isPresentToday || isPresentInSwitchToday);

    if (isRelocated) {
        return '#fffb00c8';
    }

    if (displayOnlyTomorrowForPrint && trackExpiryDate && flow !== ProductFlow.Fresh) {
        // we use colors instead of theme because when printing you don't want to be in a darkmode
        return '#e181ff60';
    }

    if (location.replacementProductId === location.productId) {
        return 'inherit';
    }

    return '#1bbe4f5a';
}

export const TomorrowShelf = ({
    allSiteLocations,
    canBeUpdated = true,
    displayOnlyTomorrowForPrint,
    hideTomorrowInfo = false,
    isToday,
    microstoreProductsStockInfo,
    nextSiteDeliveryDate,
    earliestDLCDateToKeepForStockCalculation,
    selectedColumn,
    selectedLocationPosition,
    selectedShelfIndex,
    setIsToday,
    setSelectedLocationPosition,
    setSelectedShelfIndex,
    shelf,
    shelfIndex,
    site,
    switchColumn,
    updateSelectedLocation,
}: {
    allSiteLocations: MicrostoreLocationFormValues[];
    canBeUpdated?: boolean;
    displayOnlyTomorrowForPrint: boolean;
    hideTomorrowInfo?: boolean;
    isToday: boolean;
    microstoreProductsStockInfo: MicrostoreProductsStockInfoFormValues | null;
    nextSiteDeliveryDate: BirthdayDate | null;
    earliestDLCDateToKeepForStockCalculation: BirthdayDate | null;
    selectedColumn: MicrostoreColumnFormValues;
    selectedLocationPosition: { column: number; row: number } | null;
    selectedShelfIndex: number | null;
    setIsToday: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedLocationPosition: React.Dispatch<React.SetStateAction<{ column: number; row: number } | null>>;
    setSelectedShelfIndex: React.Dispatch<React.SetStateAction<number | null>>;
    shelf: MicrostoreShelfFormValues;
    shelfIndex: number;
    site: GET_MICROSTORE_SITE_siteWithLocationInfo | GetMicrostoreSitesWithLocationsInfoQuery['sites'][number];
    switchColumn: MicrostoreColumnFormValues | null;
    updateSelectedLocation: (values: Partial<MicrostoreLocationFormValues>) => void;
}) => {
    const theme = useContext(ThemeContext);

    const canSelectLocation =
        canBeUpdated &&
        (!selectedColumn.removalDate || (!!nextSiteDeliveryDate && nextSiteDeliveryDate < selectedColumn.removalDate));

    return (
        <Shelf>
            <ShelfHeader>J+1</ShelfHeader>
            <ShelfBody>
                {shelf.locationTable.map((locationRow, locationRowIndex) => {
                    return (
                        <LocationRow key={locationRowIndex}>
                            {locationRow.map((location, locationColumnIndex) => {
                                if (hideTomorrowInfo) {
                                    return (
                                        <EmptyLocation
                                            key={locationColumnIndex}
                                            canSelectLocation={canSelectLocation}
                                            isSelected={false}
                                            backgroundColor="inherit"
                                        />
                                    );
                                }

                                const {
                                    microstoreLocationTemplate: {
                                        productArrangement,
                                        productArrangementSingleProductInfo,
                                    },
                                    replacementProductId,
                                    productId,
                                    targetStock,
                                } = location;

                                const replacementProduct = microstoreProductsStockInfo?.find(
                                    ({ _id }) => _id === replacementProductId,
                                );

                                const { label: expiryDateLabel, color: expiryDateColor } = getExpiryDateDisplay(
                                    replacementProduct?.expiryDateRecordsWarehouse ?? [],
                                    theme,
                                );
                                const locationBackgroundColor = getLocationBackgroundColor({
                                    displayOnlyTomorrowForPrint,
                                    flow: replacementProduct?.flow,
                                    location,
                                    column: selectedColumn,
                                    switchColumn,
                                    trackExpiryDate: !!replacementProduct?.trackExpiryDate,
                                });

                                const replacementProductStockInLocation = getReplacementProductStockInLocation({
                                    locationId: location._id,
                                    allSiteLocations,
                                    microstoreProductsStockInfo,
                                    replacementProductId: replacementProductId || null,
                                });

                                const stockToRemoveInLocation = getStockToRemoveInLocation({
                                    locationId: location._id,
                                    allSiteLocations,
                                    microstoreProductsStockInfo,
                                    earliestDLCDateToKeepForStockCalculation,
                                    replacementProductId: replacementProductId || null,
                                });

                                const isLocationSelected =
                                    canBeUpdated &&
                                    shelfIndex === selectedShelfIndex &&
                                    locationColumnIndex === selectedLocationPosition?.column &&
                                    locationRowIndex === selectedLocationPosition?.row &&
                                    !isToday;

                                const stockAvailableForReassortment = replacementProduct
                                    ? replacementProduct.stockAvailableWarehouse -
                                      replacementProduct.stockUsedInMicrostore -
                                      replacementProduct.stockUsedInOtherMicrostores
                                    : 0;

                                const reassortmentType = productArrangement
                                    ? productArrangement.reassortmentType
                                    : productArrangementSingleProductInfo?.reassortmentType;

                                const productInfoInArrangement = getProductInfoInArrangement({
                                    productArrangement: productArrangement || null,
                                    productId: productId || null,
                                    productArrangementSingleProductInfo,
                                });
                                const replacementProductStockMax =
                                    getStockMaxFromProductInfoInArrangement({
                                        isSecured: site.isSecured,
                                        productInfoInArrangement,
                                    }) ?? 0;

                                return (
                                    <Location
                                        key={locationColumnIndex}
                                        canSelectLocation={canSelectLocation}
                                        isSelected={isLocationSelected}
                                        hasStockToAdd={!!location.stockToAdd}
                                        onClick={(event) => {
                                            if (!canSelectLocation) {
                                                return;
                                            }
                                            event.stopPropagation();
                                            setSelectedShelfIndex(shelfIndex);
                                            setSelectedLocationPosition({
                                                column: locationColumnIndex,
                                                row: locationRowIndex,
                                            });
                                            setIsToday(false);
                                        }}
                                        backgroundColor={locationBackgroundColor}
                                    >
                                        {replacementProduct ? (
                                            <>
                                                <LocationProductName>{replacementProduct.name}</LocationProductName>
                                                {/* web section */}
                                                <LocationProductInfoRow hideOnPrint={true}>
                                                    <LocationProductInfo color={expiryDateColor}>
                                                        {expiryDateLabel}
                                                    </LocationProductInfo>
                                                </LocationProductInfoRow>
                                                <LocationProductInfoRow hideOnPrint={true}>
                                                    <LocationProductInfo
                                                        color={
                                                            stockAvailableForReassortment > 0
                                                                ? 'inherit'
                                                                : theme.errorColor
                                                        }
                                                    >
                                                        Disp&nbsp;
                                                        {stockAvailableForReassortment}
                                                    </LocationProductInfo>
                                                    {stockToRemoveInLocation !== 0 ? (
                                                        <>
                                                            <LocationProductInfo> | </LocationProductInfo>
                                                            <LocationProductInfo color={theme.errorColor}>
                                                                S-:&nbsp;{stockToRemoveInLocation}
                                                            </LocationProductInfo>
                                                        </>
                                                    ) : null}
                                                    <LocationProductInfo> | </LocationProductInfo>
                                                    <LocationProductInfo>
                                                        Liv&nbsp;+{location.stockToAdd}
                                                    </LocationProductInfo>
                                                    <LocationProductInfo> | </LocationProductInfo>
                                                    <LocationProductInfo
                                                        isBold
                                                        color={
                                                            replacementProductStockInLocation + location.stockToAdd > 0
                                                                ? 'inherit'
                                                                : theme.errorColor
                                                        }
                                                    >
                                                        Fin&nbsp;
                                                        {microstoreProductsStockInfo
                                                            ? replacementProductStockInLocation +
                                                              location.stockToAdd -
                                                              stockToRemoveInLocation
                                                            : 'Loading'}
                                                    </LocationProductInfo>
                                                </LocationProductInfoRow>

                                                {/* printer section */}
                                                <LocationProductInfoRow hideOnWeb={true}>
                                                    <LocationProductInfo isBold>
                                                        ST:&nbsp;
                                                        {replacementProductStockInLocation +
                                                            location.stockToAdd -
                                                            stockToRemoveInLocation}
                                                    </LocationProductInfo>
                                                </LocationProductInfoRow>
                                            </>
                                        ) : location.productId && isLocationSelected ? (
                                            <LocationProductInfoRow
                                                hideOnPrint={true}
                                                onClick={() =>
                                                    updateSelectedLocation({
                                                        replacementProductId: location.productId,
                                                        stockToAdd: 0,
                                                    })
                                                }
                                            >
                                                <BiArrowFromTop size={20} />
                                            </LocationProductInfoRow>
                                        ) : null}

                                        <LocationProductInfoRow hideOnPrint={true}>
                                            {reassortmentType === ProductArrangementReassortmentType.Target ? (
                                                <LocationProductInfo>
                                                    Auto SC : {targetStock === null ? '-' : targetStock}/
                                                    {replacementProductStockMax}
                                                </LocationProductInfo>
                                            ) : (
                                                <LocationProductInfo>Manuel</LocationProductInfo>
                                            )}
                                        </LocationProductInfoRow>
                                    </Location>
                                );
                            })}
                        </LocationRow>
                    );
                })}
            </ShelfBody>
        </Shelf>
    );
};

const Shelf = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    flex: 1 0 0;
    background-color: ${({ theme }) => theme.darkBackgroundColor};
`;

const ShelfHeader = styled.div`
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    flex-shrink: 0;
    border-right: 1px solid ${({ theme }) => theme.lightBorderColor};

    @media print {
        font-size: 17px;
    }
`;

const ShelfBody = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    border-radius: ${({ theme }) => theme.borderRadius};
    padding: 2px;

    & > :not(:first-child) {
        margin-top: 2px;
    }

    @media print {
        & > :not(:first-child) {
            margin-top: 1px;
        }
    }
`;

const LocationRow = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1 0 0;

    & > :not(:first-child) {
        margin-left: 2px;
    }

    @media print {
        & > :not(:first-child) {
            margin-left: 1px;
        }
    }
`;

const Location = styled.div<{
    canSelectLocation: boolean;
    isSelected: boolean;
    backgroundColor: string;
    hasStockToAdd?: boolean;
}>`
    cursor: ${({ canSelectLocation }) => (canSelectLocation ? 'pointer' : 'not-allowed')};
    flex: 1 0 0;
    border: 1px solid ${({ theme, isSelected }) => (isSelected ? theme.ctaPrimaryColor : theme.lightBorderColor)};
    box-shadow: ${({ theme, isSelected }) => (isSelected ? theme.boxShadow : 'none')};
    background-color: ${({ backgroundColor }) => backgroundColor};
    border-radius: ${({ theme }) => theme.borderRadius};
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    & > :not(:first-child) {
        margin-top: 5px;
    }

    @media print {
        padding: 1px;
        border: 1px solid ${({ theme, hasStockToAdd }) => (hasStockToAdd ? theme.successColor : theme.lightBorderColor)};

        & > :not(:first-child) {
            margin-top: 1px;
        }
    }
`;

const EmptyLocation = styled(Location)`
    min-height: 25px;
`;

const LocationProductName = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-grow: 1;
    font-size: 13px;

    @media print {
        font-size: 9px;
    }
`;

const LocationProductInfoRow = styled.div<{ hideOnPrint?: boolean; hideOnWeb?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    display: ${({ hideOnWeb }) => (hideOnWeb ? 'none' : 'initial')};

    & > :not(:first-child) {
        margin-left: 2px;
    }

    @media print {
        display: ${({ hideOnPrint }) => (hideOnPrint ? 'none' : 'initial')};
    }
`;

const LocationProductInfo = styled.span<{ hideOnPrint?: boolean; isBold?: boolean }>`
    text-align: center;
    font-size: 13px;
    color: ${({ color }) => color ?? 'inherit'};
    font-weight: ${({ isBold }) => (isBold ? 800 : 500)};

    @media print {
        display: ${({ hideOnPrint }) => (hideOnPrint ? 'none' : 'initial')};
        font-size: 9px;
    }
`;

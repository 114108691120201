import React from 'react';

import { useMutation } from '@apollo/client';
import styled from 'styled-components';

import { ARCHIVE_PRODUCT_SWITCH_MUTATION } from 'data/mutations/productSwitch';
import {
    ARCHIVE_PRODUCT_SWITCH,
    ARCHIVE_PRODUCT_SWITCHVariables,
} from 'data/mutations/__generated__/ARCHIVE_PRODUCT_SWITCH';

import { Loader, LoaderModeType } from 'components/Loader';
import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

import { productSwitchArchivedHandler } from '../cacheHandlers/productSwitchesCacheHandler';
import { GET_ALL_PRODUCTS_getAllProducts } from 'data/queries/__generated__/GET_ALL_PRODUCTS';
import { ProductSwitchFragment } from 'data/fragments/__generated__/ProductSwitchFragment';

export function ArchiveProductSwitchPopup({
    isOpen,
    setIsOpen,
    products,
    productSwitch,
}: {
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
    products: GET_ALL_PRODUCTS_getAllProducts[];
    productSwitch: ProductSwitchFragment;
}) {
    const [archiveProductSwitch, { loading: creationLoading }] = useMutation<
        ARCHIVE_PRODUCT_SWITCH,
        ARCHIVE_PRODUCT_SWITCHVariables
    >(ARCHIVE_PRODUCT_SWITCH_MUTATION);

    async function handleSubmit() {
        const { data } = await archiveProductSwitch({ variables: { productSwitchId: productSwitch._id } });

        if (!data) {
            throw new Error("Une erreur est survenue lors de l'archivage du switch de produit");
        }

        const { archiveProductSwitch: updatedProductSwitch } = data;

        productSwitchArchivedHandler(updatedProductSwitch);

        setIsOpen(false);
    }

    const productA = products.find(({ _id }) => _id === productSwitch.productAId);
    const productB = products.find(({ _id }) => _id === productSwitch.productBId);

    return (
        <TotemPopup
            title="Archiver un switch de produit"
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            contentOverflow="visible"
        >
            Voulez-vous archiver le switch de produit ?
            <br />
            Produit A : {productA?.fullname ?? 'Erreur'}
            <br />
            Produit B : {productB?.fullname ?? 'Erreur'}
            <SubmitContainer>
                <ValidationButton onClick={handleSubmit}>
                    {creationLoading ? <Loader size="20px" mode={LoaderModeType.Spin} /> : 'Confirmer'}
                </ValidationButton>
            </SubmitContainer>
        </TotemPopup>
    );
}

const SubmitContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 15px;
`;

const ValidationButton = styled(TotemPrimaryButton)`
    margin-top: 15px;
`;

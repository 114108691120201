import React from 'react';

import type { FileError } from 'react-dropzone';
import { FaTrash } from 'react-icons/fa';
import styled from 'styled-components';

import { TotemInput } from 'components/TotemInput';
import { TotemLabel } from 'components/TotemLabel';
import { PollAudience, PollAudienceSelector } from './PollAudienceSelector';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { TotemDatePicker } from 'components/TotemDatePicker';
import { FileWithPreview, FileDragAndDrop } from 'components/FileDragAndDrop';
import { TotemImage } from 'components/TotemImage';

export type PollFormType = {
    answerOptions: string[];
    audience: PollAudience;
    dateWindow: {
        startDate: Date | null;
        endDate: Date | null;
    };
    imageUrl: string | null;
    image: FileWithPreview | null;
    question: string | null;
    sectionTitle: string | null;
    title: string | null;
};

export const PollForm = ({
    pollForm,
    setPollForm,
}: {
    pollForm: PollFormType;
    setPollForm: (pollForm: PollFormType) => void;
}) => {
    async function fileValidationFunction(file: FileWithPreview) {
        return new Promise<FileError[]>((resolve, reject) => {
            const image = new Image();
            image.onload = function () {
                const widthErrors =
                    image.width >= 600 && image.height >= 400
                        ? []
                        : [
                              {
                                  message: `L'image doit avoir une dimension d'au moins 600x400px, votre image fait ${image.width}x${image.height}px`,
                                  code: 'file-invalid-dimension',
                              },
                          ];
                const fileErrors = [...widthErrors];
                resolve(fileErrors);
            };
            image.onerror = reject;
            image.src = file.preview;
        });
    }

    function updateAnswer({ index, value }: { index: number; value: string }) {
        setPollForm({
            ...pollForm,
            answerOptions: pollForm.answerOptions.map((answerOption, answerIndex) =>
                answerIndex === index ? value : answerOption,
            ),
        });
    }

    function deleteAnswer({ index }: { index: number }) {
        setPollForm({
            ...pollForm,
            answerOptions: pollForm.answerOptions.filter((answerOption, answerIndex) => answerIndex !== index),
        });
    }

    return (
        <Container>
            <DragAndDropContainer>
                <FileDragAndDrop
                    multiple={false}
                    hasPadding={false}
                    hasBackground={false}
                    fileTypes={['image/jpeg', 'image/png', 'image/svg+xml', 'image/gif']}
                    fileValidationFunction={fileValidationFunction}
                    onFileDropCallback={(files) => {
                        if (files?.[0]) {
                            setPollForm({ ...pollForm, image: files[0] });
                        }
                    }}
                >
                    <ImageContainer>
                        <TotemImage
                            src={pollForm.image?.preview || pollForm.imageUrl}
                            alt={pollForm.image?.name || 'Sondage'}
                            size={300}
                        />
                    </ImageContainer>
                </FileDragAndDrop>
            </DragAndDropContainer>
            <TotemInput
                label="Titre de la section"
                sublabel="Titre de la section de la home sur l'app mobile"
                value={pollForm.sectionTitle ?? undefined}
                placeholder='Par défaut : "Sondage de la semaine"'
                onChange={(sectionTitle) => setPollForm({ ...pollForm, sectionTitle })}
            />
            <TotemInput
                label="Titre"
                sublabel="Titre tel qu'il sera affiché dans le carrousel de la home"
                value={pollForm.title ?? undefined}
                onChange={(title) => setPollForm({ ...pollForm, title })}
            />
            <TotemInput
                label="Question"
                value={pollForm.question ?? undefined}
                onChange={(question) => setPollForm({ ...pollForm, question })}
            />
            <AnswerOptions>
                <TotemLabel>Options de réponse</TotemLabel>
                {pollForm.answerOptions.map((answerOption, index) => (
                    <AnswerOption key={`${index}${pollForm.answerOptions.length}`}>
                        <InputContainer>
                            <TotemInput value={answerOption} onChange={(value) => updateAnswer({ index, value })} />
                        </InputContainer>
                        <IconContainer onClick={() => deleteAnswer({ index })}>
                            <TrashIcon />
                        </IconContainer>
                    </AnswerOption>
                ))}
            </AnswerOptions>
            <TotemPrimaryButton
                onClick={() => setPollForm({ ...pollForm, answerOptions: [...pollForm.answerOptions, ''] })}
            >
                Ajouter une option de réponse
            </TotemPrimaryButton>
            <TotemDatePicker
                label="Date de début du sondage"
                sublabel="Le sondage apparaîtra à cette date à partir de minuit"
                selected={pollForm.dateWindow.startDate}
                onChange={(date) =>
                    setPollForm({ ...pollForm, dateWindow: { ...pollForm.dateWindow, startDate: date } })
                }
            />
            <TotemDatePicker
                label="Date de fin du sondage"
                sublabel="Le sondage sera disponible jusqu'à cette date à 23h59"
                selected={pollForm.dateWindow.endDate}
                onChange={(date) => setPollForm({ ...pollForm, dateWindow: { ...pollForm.dateWindow, endDate: date } })}
            />
            <PollAudienceSelector
                audience={pollForm.audience}
                setAudience={(audience) => setPollForm({ ...pollForm, audience })}
            />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const DragAndDropContainer = styled.div`
    align-self: flex-start;
`;

const ImageContainer = styled.div`
    cursor: pointer;
    &:hover {
        opacity: 0.4;
    }
`;

const AnswerOptions = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const AnswerOption = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    width: 100%;
`;

const InputContainer = styled.div`
    flex: 1;
`;

const IconContainer = styled.div<{ disabled?: boolean }>`
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background-color: ${({ disabled, theme }) => (disabled ? theme.disabledColor : theme.errorColor)};
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
    cursor: pointer;
`;

const TrashIcon = styled(FaTrash)`
    color: ${({ theme }) => theme.menuTextColor};
`;

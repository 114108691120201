import React from 'react';

import { CommonStatusTag } from 'components/CommonStatusTag';

import { colors } from 'constants/colors';

export const PAYMENT_PROVIDERS = [
    'Totem',
    'External', // Shift4 CB payment on sensei, Nayax
];

export type AllowedProviders = (typeof PAYMENT_PROVIDERS)[number];

const getColorsFromProvider = (provider: AllowedProviders) => {
    switch (provider) {
        case 'Totem':
            return { backgroundColor: colors.green, labelColor: colors.black };
        case 'External':
            return { backgroundColor: colors.yellow, labelColor: colors.black };
        default:
            return { backgroundColor: colors.orange, labelColor: colors.black };
    }
};

export const ProviderTag = ({ provider }: { provider: AllowedProviders }) => {
    const { backgroundColor, labelColor } = getColorsFromProvider(provider);
    return <CommonStatusTag backgroundColor={backgroundColor} labelColor={labelColor} label={provider} />;
};

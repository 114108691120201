import React from 'react';

import { FaPlus, FaTrash } from 'react-icons/fa';
import styled from 'styled-components';

import { colors } from 'constants/colors';

import { TotemDatePicker } from 'components/TotemDatePicker';
import { TotemLabel } from 'components/TotemLabel';
import { dateFromString, formatDateAsAnniversary } from 'helpers/dateTimes';
import { SupplierClosingDatesRangeInput } from 'data/__generated__';

export const SupplierClosingDates = ({
    closingDatesRanges,
    setClosingDatesRanges,
}: {
    closingDatesRanges: SupplierClosingDatesRangeInput[];
    setClosingDatesRanges: (closingDatesRanges: SupplierClosingDatesRangeInput[]) => void;
}) => {
    function onClosingDateChange(date: Date, index: number) {
        setClosingDatesRanges(
            closingDatesRanges.map((closingDatesRange, closingDatesRangeIndex) => {
                if (closingDatesRangeIndex !== index) {
                    return closingDatesRange;
                }

                return {
                    ...closingDatesRange,
                    closingDate: formatDateAsAnniversary({ dateTime: date, useNewFormat: true }),
                };
            }),
        );
    }

    function onOpeningDateChange(date: Date, index: number) {
        setClosingDatesRanges(
            closingDatesRanges.map((closingDatesRange, closingDatesRangeIndex) => {
                if (closingDatesRangeIndex !== index) {
                    return closingDatesRange;
                }

                return {
                    ...closingDatesRange,
                    openingDate: formatDateAsAnniversary({ dateTime: date, useNewFormat: true }),
                };
            }),
        );
    }

    function removeRange(index: number) {
        setClosingDatesRanges(
            closingDatesRanges.filter((_, closingDatesRangeIndex) => closingDatesRangeIndex !== index),
        );
    }

    const errors = [] as string[];

    closingDatesRanges.forEach((closingDatesRange, index) => {
        const { closingDate, openingDate } = closingDatesRange;

        if (dateFromString(closingDate, true) >= dateFromString(openingDate, true)) {
            errors.push(`L'intervalle de fermeture n°${index + 1} a une date de réouverture avant celle de fermeture`);
        }
    });

    return (
        <Container>
            <ArrayLabel>Dates de fermeture</ArrayLabel>
            {errors.map((error, index) => (
                <Error key={index}>{error}</Error>
            ))}
            <List>
                {closingDatesRanges.map((closingDatesRange, index) => {
                    const { closingDate: closingDateDate, openingDate: openingDateDate } = closingDatesRange;

                    return (
                        <Entry key={index} hasBorderTop={index > 0}>
                            <TotemDatePicker
                                label="Fermeture"
                                selected={closingDateDate ? dateFromString(closingDateDate, true) : null}
                                onChange={(date) => {
                                    if (date) {
                                        onClosingDateChange(date, index);
                                    }
                                }}
                            />

                            <TotemDatePicker
                                label="Réouverture"
                                selected={openingDateDate ? dateFromString(openingDateDate, true) : null}
                                onChange={(date) => {
                                    if (date) {
                                        onOpeningDateChange(date, index);
                                    }
                                }}
                            />
                            <IconContainer
                                onClick={() => {
                                    removeRange(index);
                                }}
                            >
                                <TrashIcon color={colors.pureWhite} />
                            </IconContainer>
                        </Entry>
                    );
                })}
                <Entry hasBorderTop={closingDatesRanges.length > 0}>
                    <IconContainer
                        onClick={() =>
                            setClosingDatesRanges([
                                ...closingDatesRanges,
                                {
                                    closingDate: formatDateAsAnniversary({ dateTime: new Date(), useNewFormat: true }),
                                    openingDate: formatDateAsAnniversary({ dateTime: new Date(), useNewFormat: true }),
                                },
                            ])
                        }
                    >
                        <PlusIcon color={colors.pureWhite} />
                    </IconContainer>
                </Entry>
            </List>
        </Container>
    );
};

const Container = styled.div`
    display: inline-flex;
    flex-direction: column;

    & > :not(:first-child) {
        margin-top: 5px;
    }
`;

const List = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
    min-width: 200px;
`;

const Entry = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-top: ${({ hasBorderTop }: { hasBorderTop: boolean }) => (hasBorderTop ? 1 : 0)}px solid
        ${({ theme }) => theme.lightBorderColor};

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const IconContainer = styled.div<{ disabled?: boolean }>`
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background-color: ${({ disabled, theme }) => (disabled ? theme.disabledColor : theme.ctaPrimaryColor)};
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
    cursor: pointer;
`;

const TrashIcon = styled(FaTrash)`
    color: ${({ theme }) => theme.menuTextColor};
`;

const PlusIcon = styled(FaPlus)`
    color: ${({ theme }) => theme.menuTextColor};
`;

const ArrayLabel = styled(TotemLabel)`
    margin: 5px 0;
`;

const Error = styled.div`
    font-size: 14px;
    color: ${({ theme }) => theme.errorColor};
`;

import gql from 'graphql-tag';

import { DELIVERY_FRAGMENT } from '../fragments/delivery';

export const DELIVERIES_UPDATED_SUBSCRIPTION = gql`
    subscription DELIVERIES_UPDATED {
        deliveriesUpdated {
            ...DeliveryFragment
        }
    }
    ${DELIVERY_FRAGMENT}
`;

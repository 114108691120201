import gql from 'graphql-tag';

import { SUPPLIER_PARTNERSHIP_FRAGMENT } from '../fragments/supplierPartnership';

export const GET_SUPPLIER_PARTNERSHIPS_QUERY = gql`
    query GET_SUPPLIER_PARTNERSHIPS {
        supplierPartnerships {
            ...SupplierPartnershipFragment
        }
    }
    ${SUPPLIER_PARTNERSHIP_FRAGMENT}
`;

import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import {
    CREATE_DELIVERY_FOR_ORDER,
    CREATE_DELIVERY_FOR_ORDERVariables,
} from 'data/mutations/__generated__/CREATE_DELIVERY_FOR_ORDER';
import { CREATE_DELIVERY_FOR_ORDER_MUTATION } from 'data/mutations/delivery';

import { Loader, LoaderModeType } from 'components/Loader';
import { OrderSelector } from './components/OrderSelector';
import { OrganizationSelector } from './components/OrganizationSelector';
import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { SelectedOption } from 'components/TotemSelect';

import { deliveriesUpdatedHandler } from '../utils/deliverySubscriptionsHandlers';

export function CreateDeliveryPopup({ isOpen, setIsOpen }: { isOpen: boolean; setIsOpen: (arg: boolean) => void }) {
    const [orderOption, setOrderOption] = useState<SelectedOption<string>>(null);
    const [organizationOption, setOrganizationOption] = useState<SelectedOption<string>>(null);

    const [createDeliveryForOrder, { loading }] = useMutation<
        CREATE_DELIVERY_FOR_ORDER,
        CREATE_DELIVERY_FOR_ORDERVariables
    >(CREATE_DELIVERY_FOR_ORDER_MUTATION);

    async function handleSubmit() {
        if (orderOption) {
            const { data } = await createDeliveryForOrder({ variables: { orderId: orderOption.value } });

            if (!data) {
                throw new Error('Une erreur est survenue lors de la création de la livraison');
            }

            const { createDeliveryForOrder: createdOrder } = data;

            if (createdOrder) {
                deliveriesUpdatedHandler([createdOrder]);
                toast.success('Livraison créée !');
                setIsOpen(false);
            }
        }
    }

    return (
        <TotemPopup title="Créer une livraison" isOpen={isOpen} setIsOpen={setIsOpen} contentOverflow="visible">
            <OrganizationSelector selectedOption={organizationOption} setSelectedOption={setOrganizationOption} />
            {organizationOption ? (
                <OrderSelector
                    selectedOption={orderOption}
                    setSelectedOption={setOrderOption}
                    organizationId={organizationOption.value}
                />
            ) : null}
            <SubmitContainer>
                <TotemPrimaryButton onClick={handleSubmit} disabled={!orderOption}>
                    {loading ? <Loader size="20px" mode={LoaderModeType.Spin} /> : 'Confirmer'}
                </TotemPrimaryButton>
            </SubmitContainer>
        </TotemPopup>
    );
}

const SubmitContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 15px;
`;

import { addDaysToDate, getDaysToNextWeekday, getTotemDayOfTheWeek } from 'helpers/dateTimes';

export function getWeekdateBeforeNextSetup({
    deliveryDays,
    startDate,
}: {
    deliveryDays: boolean[];
    startDate: Date;
}): Date {
    let weekdateBeforeNextSetup = startDate;
    let daysToNextSetup = getDaysToNextWeekday(weekdateBeforeNextSetup.getDay());
    let nextSetupDate = addDaysToDate({ daysToAdd: daysToNextSetup, date: startDate });
    let nextSetupDateIndex = getTotemDayOfTheWeek(nextSetupDate);
    while (!deliveryDays[nextSetupDateIndex]) {
        weekdateBeforeNextSetup = new Date(nextSetupDate);
        daysToNextSetup = daysToNextSetup + getDaysToNextWeekday(weekdateBeforeNextSetup.getDay());
        nextSetupDate = addDaysToDate({ daysToAdd: daysToNextSetup, date: startDate });
        nextSetupDateIndex = getTotemDayOfTheWeek(nextSetupDate);
    }
    weekdateBeforeNextSetup.setHours(0, 0, 0, 0);

    return weekdateBeforeNextSetup;
}

import React from 'react';
import { useQuery } from '@apollo/client';
import { DetailLink } from 'components/DetailsView';
import { Loader, LoaderModeType } from 'components/Loader';
import { TotemInput } from 'components/TotemInput';
import { colors } from 'constants/colors';
import { GET_RETURNED_STOCK_TRANSFERS_QUERY } from 'data/queries/stockTransfer';
import {
    GET_RETURNED_STOCK_TRANSFERS,
    GET_RETURNED_STOCK_TRANSFERSVariables,
} from 'data/queries/__generated__/GET_RETURNED_STOCK_TRANSFERS';
import { formatDateAsAnniversary } from 'helpers/dateTimes';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { IoWarning } from 'react-icons/io5';
import styled from 'styled-components';

export const StockTransferReturnsProducts = ({ transferDate }: { transferDate: Date }) => {
    const { data, loading, error } = useQuery<GET_RETURNED_STOCK_TRANSFERS, GET_RETURNED_STOCK_TRANSFERSVariables>(
        GET_RETURNED_STOCK_TRANSFERS_QUERY,
        {
            variables: {
                transferDate: formatDateAsAnniversary({ dateTime: transferDate }),
            },
        },
    );

    if (loading) {
        return <Loader mode={LoaderModeType.Spin} />;
    }

    if (!data || error) {
        throw new Error('Une erreur est survenue lors de la récupération des transferts de stock');
    }

    const { returnedStockTransfers } = data;

    const stockTransfersWithProducts = returnedStockTransfers.filter(({ products }) => !!products.length);

    return (
        <ContentScrollable>
            {stockTransfersWithProducts.map(({ _id, originSiteName, products }) => {
                return (
                    <ProductsTable key={_id}>
                        <thead>
                            <tr>
                                <ProductsTableHeaderCell>{originSiteName}</ProductsTableHeaderCell>
                                <ProductsTableHeaderCell isDataCentered>Quantité transférée</ProductsTableHeaderCell>
                                <ProductsTableHeaderCell isDataCentered>
                                    Stock dispo à l'origine
                                </ProductsTableHeaderCell>
                                <ProductsTableHeaderCell isDataCentered>
                                    Stock dispo à destination
                                </ProductsTableHeaderCell>
                            </tr>
                        </thead>
                        <ProductsTableBody>
                            {products.map((product) => {
                                const {
                                    productId,
                                    fullname,
                                    quantity,
                                    originStockAvailable,
                                    destinationStockAvailable,
                                } = product;

                                return (
                                    <tr key={productId}>
                                        <ProductsNameBodyCell>
                                            <ProductNameContainer>
                                                <DetailLink name={fullname} value={productId} path="product" />
                                            </ProductNameContainer>
                                        </ProductsNameBodyCell>
                                        <ProductsTableBodyCell isDataCentered>
                                            <QuantityInputOuterContainer>
                                                <QuantityInputInnerContainer>
                                                    <TotemInput
                                                        type="number"
                                                        value={quantity || ''}
                                                        disabled
                                                        onChange={() => null}
                                                        centerText
                                                    />
                                                </QuantityInputInnerContainer>
                                            </QuantityInputOuterContainer>
                                        </ProductsTableBodyCell>
                                        <ProductsTableBodyCell isDataCentered>
                                            <StockDelta>
                                                <>
                                                    <span>{originStockAvailable + quantity}</span>
                                                    <FaLongArrowAltRight size={25} color={colors.red} />
                                                    <span>{originStockAvailable}</span>
                                                </>

                                                {originStockAvailable - quantity < 0 ? (
                                                    <WarningIcon data-test="warning-icon" size={25} />
                                                ) : null}
                                            </StockDelta>
                                        </ProductsTableBodyCell>
                                        <ProductsTableBodyCell isDataCentered>
                                            <StockDelta>
                                                <>
                                                    <span>{destinationStockAvailable - quantity}</span>
                                                    <FaLongArrowAltRight size={25} color={colors.green} />
                                                    <span>{destinationStockAvailable}</span>
                                                </>
                                            </StockDelta>
                                        </ProductsTableBodyCell>
                                    </tr>
                                );
                            })}
                        </ProductsTableBody>
                    </ProductsTable>
                );
            })}
        </ContentScrollable>
    );
};

const ProductsTable = styled.table`
    width: 100%;
    border-radius: ${({ theme }) => theme.borderRadius};
    border-spacing: 0px;
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
    margin-top: 10px;
`;

const ProductsTableBody = styled.thead`
    & > :not(:first-child) td {
        border-top: 1px solid ${({ theme }) => theme.lightBorderColor};
    }
`;

const ProductsTableHeaderCell = styled.th`
    padding: 10px;
    text-align: ${({ isDataCentered }: { isDataCentered?: boolean }) => (isDataCentered ? 'center' : 'left')};
    border-bottom: 1px solid ${({ theme }) => theme.lightBorderColor};
`;

const ProductsTableBodyCell = styled.td`
    padding: 10px;
    text-align: ${({ isDataCentered }: { isDataCentered?: boolean }) => (isDataCentered ? 'center' : 'left')};
`;

const ProductsNameBodyCell = styled.td`
    width: 50%;
    padding: 10px;
    text-align: ${({ isDataCentered }: { isDataCentered?: boolean }) => (isDataCentered ? 'center' : 'left')};
`;

const ProductNameContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const QuantityInputOuterContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const QuantityInputInnerContainer = styled.div`
    width: 70px;
`;

const WarningIcon = styled(IoWarning)`
    color: ${({ theme }) => theme.warningColor};
`;

const StockDelta = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const ContentScrollable = styled.div`
    width: 100%;
    overflow-y: scroll;
`;

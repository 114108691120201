import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import { FaSave } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { PAGES } from 'constants/pages';

import { MessagesSetState } from 'data/__generated__';

import { Header, HeaderTitle } from 'components/Header';
import { Loader, LoaderModeType } from 'components/Loader';
import { PageTitle } from 'components/PageTitle';

import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { GET_MESSAGES_SET_messagesSet } from 'data/queries/__generated__/GET_MESSAGES_SET';
import { UPDATE_MESSAGES_SET, UPDATE_MESSAGES_SETVariables } from 'data/mutations/__generated__/UPDATE_MESSAGES_SET';
import { UPDATE_MESSAGES_SET_MUTATION } from 'data/mutations/messagesSet';
import { FormValue, MessagesSetFormContent } from './MessagesSetFormContent';

type ParamTypes = {
    messagesSetId: string;
};

export const MessagesSetForm = ({ messagesSet }: { messagesSet: GET_MESSAGES_SET_messagesSet }) => {
    const { messagesSetId = '' } = useParams<ParamTypes>();

    const [updateMessagesSet, { loading: updateMessagesSetLoading }] = useMutation<
        UPDATE_MESSAGES_SET,
        UPDATE_MESSAGES_SETVariables
    >(UPDATE_MESSAGES_SET_MUTATION);

    const [formValue, setFormValue] = useState<FormValue>({
        name: messagesSet.name,
        messages: messagesSet.messages.map((message) => {
            return { body: message.body, redirectUrl: message.redirectUrl };
        }),
    });

    async function onSubmit() {
        const updateInput = {
            ...formValue,
            messages: formValue.messages.map((message) => {
                return { ...message, redirectUrl: message.redirectUrl || null };
            }),
            state: MessagesSetState.Regular,
        };
        const { data } = await updateMessagesSet({
            variables: {
                messagesSetId,
                updateInput,
            },
        });

        if (data) {
            const {
                updateMessagesSet: { updatedMessagesSet, success, errors },
            } = data;
            if (success && updatedMessagesSet) {
                toast.success('La série a bien été mise à jour !');
            }

            if (errors) {
                errors.forEach((error) => {
                    toast.error(error, { autoClose: false });
                });
                if (errors.length > 1) {
                    toast.info('Cliquez pour fermer toutes les notifications', {
                        autoClose: false,
                        onClick: () => toast.dismiss(),
                    });
                }

                throw Error("Une erreur inconnue s'est produite");
            }
        } else {
            throw Error("Une erreur inconnue s'est produite");
        }
    }

    return (
        <Form>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.messagesSetDetails} />
                </HeaderTitle>
                <CTAsContainer>
                    <TotemPrimaryButton onClick={onSubmit} minWidth="78px">
                        {updateMessagesSetLoading ? (
                            <Loader size="18px" mode={LoaderModeType.Spin} />
                        ) : (
                            <>
                                <FaSave size={13} />
                                <SaveLabel>Mettre à jour</SaveLabel>
                            </>
                        )}
                    </TotemPrimaryButton>
                    <Link to="/messagesSets">
                        <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                    </Link>
                </CTAsContainer>
            </Header>
            <Content>
                <ScrollableContent>
                    <MessagesSetFormContent formValue={formValue} setFormValue={setFormValue} />
                </ScrollableContent>
            </Content>
        </Form>
    );
};

const Form = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
`;

const CTAsContainer = styled.div`
    display: flex;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const SaveLabel = styled.span`
    margin-left: 5px;
`;

const Content = styled.div`
    flex: 1;
    overflow: hidden;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const ScrollableContent = styled.div`
    padding: 15px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
`;

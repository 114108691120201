import gql from 'graphql-tag';

export const GET_SNAPSHOT_MICROSTORE_FOR_DATE_QUERY = gql`
    query GET_SNAPSHOT_MICROSTORE_FOR_DATE($siteId: String!, $snapshotDate: String!) {
        getSnapshotMicrostoreForDate(siteId: $siteId, snapshotDate: $snapshotDate) {
            _id
            createdAt
            columnGroups {
                columnGroupId
                columns {
                    columnId
                    columnIdToReplace
                    isPaused
                    removalDate
                    shelves {
                        shelfId
                        locationTable {
                            locationId
                            productId
                        }
                    }
                    startingDate
                }
            }
            siteId
            snapshotDate
        }
    }
`;

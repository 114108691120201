import gql from 'graphql-tag';

export const GET_RITUALS_QUERY = gql`
    query GET_RITUALS {
        rituals {
            _id
            activeDate
            activeWeekdays
            expiringProductsOnly
            imageUrl
            name
            state
            timeWindow {
                start
                end
            }
        }
    }
`;

export const GET_DETAILED_RITUAL_QUERY = gql`
    query GET_DETAILED_RITUAL($ritualId: ID!) {
        detailedRitual(ritualId: $ritualId) {
            _id
            activeDate
            activeWeekdays
            associatedDiscount
            expiringProductsOnly
            imageUrl
            messagesSetId
            name
            repetition
            requiresParticipation
            restrictedBadgeIds
            restrictedCategoryIds
            restrictedOrganizationIds
            restrictedProductIds
            restrictedSiteIds
            restrictedSubcategoryIds
            restrictedTagIds
            restrictedUserIds
            state
            stateHistory {
                createdAt
                createdBy
                createdByName
                state
            }
            timeWindow {
                start
                end
            }
            type
            supplierPartnershipId
        }
    }
`;

export const GET_SITE_RITUALS_QUERY = gql`
    query GET_SITE_RITUALS($siteId: ID!) {
        siteRituals(siteId: $siteId) {
            _id
            activeWeekdays
            associatedDiscount
            imageUrl
            name
            timeWindow {
                start
                end
            }
        }
    }
`;

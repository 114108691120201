import React from 'react';

import { CommonStatusTag } from 'components/CommonStatusTag';

import { colors } from 'constants/colors';

import { SiteState } from 'data/__generated__';
import { SITE_STATES_LABELS } from '../constants/states';

const getColorsFromState = (state: SiteState) => {
    const { lightGrey, black, green } = colors;

    switch (state) {
        case SiteState.Regular:
            return { backgroundColor: green, labelColor: black };
        case SiteState.Archived:
            return { backgroundColor: lightGrey, labelColor: black };
    }
};

export const StatusTag = ({ state }: { state: SiteState }) => {
    const { backgroundColor, labelColor } = getColorsFromState(state);
    return (
        <CommonStatusTag backgroundColor={backgroundColor} labelColor={labelColor} label={SITE_STATES_LABELS[state]} />
    );
};

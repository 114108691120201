import React from 'react';

import styled from 'styled-components';

import { PAGES } from 'constants/pages';

import { Header, HeaderTitle } from 'components/Header';
import { PageTitle } from 'components/PageTitle';
import { RitualTemplatesList } from './RitualTemplatesList';

export const RitualTemplates = () => {
    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.ritualTemplates} />
                </HeaderTitle>
            </Header>
            <Content>
                <ScrollableContent>
                    <RitualTemplatesList />
                </ScrollableContent>
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
`;

const Content = styled.div`
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 15px;
    flex: 1;
    overflow: hidden;
`;

const ScrollableContent = styled.div`
    height: 100%;
    overflow-y: scroll;
`;

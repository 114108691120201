import React, { useEffect, useRef, useState } from 'react';

import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { MOVE_COLUMN_MUTATION } from 'data/mutations/microstoreColumn';

import {
    GET_MICROSTORE_SITES_microstoreSitesWithColumnGroups_microstoreColumnGroups,
    GET_MICROSTORE_SITES_microstoreSitesWithColumnGroups_microstoreColumnGroups_columns,
} from 'data/queries/__generated__/GET_MICROSTORE_SITES';
import { MOVE_COLUMN, MOVE_COLUMNVariables } from 'data/mutations/__generated__/MOVE_COLUMN';

import { Loader, LoaderModeType } from 'components/Loader';
import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { TotemSelect } from 'components/TotemSelect';

export function MoveColumnPopup({
    isOpen,
    setMicrostoreColumnToMove,
    column,
    columnGroups,
}: {
    isOpen: boolean;
    setMicrostoreColumnToMove: (
        column: GET_MICROSTORE_SITES_microstoreSitesWithColumnGroups_microstoreColumnGroups_columns | null,
    ) => void;
    column: GET_MICROSTORE_SITES_microstoreSitesWithColumnGroups_microstoreColumnGroups_columns;
    columnGroups: GET_MICROSTORE_SITES_microstoreSitesWithColumnGroups_microstoreColumnGroups[];
}) {
    const [positionInColumnGroup, setPositionInColumnGroup] = useState<number>(column.positionInColumnGroup);
    const [columnGroupId, setColumnGroupId] = useState<string>(column.columnGroupId);

    const notInitialRender = useRef<boolean>(false); // hack to ignore useEffect during initial render (setting state in select onChange doesn't work)

    useEffect(() => {
        if (notInitialRender.current) {
            setPositionInColumnGroup(columnGroups.find(({ _id }) => _id === columnGroupId)?.columns.length as number);
        } else {
            notInitialRender.current = true;
        }
    }, [columnGroupId, columnGroups]);

    const [moveColumn, { loading: updateLoading }] = useMutation<MOVE_COLUMN, MOVE_COLUMNVariables>(
        MOVE_COLUMN_MUTATION,
    );

    async function handleSubmit() {
        const { data } = await moveColumn({
            variables: {
                columnId: column._id,
                positionInColumnGroup,
                columnGroupId,
            },
        });

        if (!data) {
            throw new Error('Une erreur est survenue lors de la creation du template de colonne');
        }

        const {
            moveColumnMutation: { errors },
        } = data;

        if (errors.length) {
            errors.forEach((error, index) => {
                toast.error(<span key={index}>Erreur : {error}</span>, { autoClose: false });
            });

            if (errors.length > 1) {
                toast.info('Cliquez pour fermer toutes les notifications', {
                    autoClose: false,
                    onClick: () => toast.dismiss(),
                });
            }
        }

        setMicrostoreColumnToMove(null);
    }

    const selectedColumnGroup = columnGroups.find(
        ({ _id }) => _id === columnGroupId,
    ) as GET_MICROSTORE_SITES_microstoreSitesWithColumnGroups_microstoreColumnGroups;
    const positionInColumnGroupOptions = Array(
        selectedColumnGroup.columns.length + (column.columnGroupId === columnGroupId ? 0 : 1),
    )
        .fill(undefined)
        .map((_, index) => ({
            value: index,
            label: `${index + 1}`,
        }));

    const groupOptions =
        columnGroups
            .map(({ _id: columnGroupId, name }) => ({
                value: columnGroupId,
                label: name,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)) || [];

    return (
        <TotemPopup
            title="Changer de place une colonne"
            isOpen={isOpen}
            setIsOpen={() => setMicrostoreColumnToMove(null)}
            contentOverflow="visible"
        >
            <SubmitContainer>
                <TotemSelect<number>
                    label="Position de la colonne"
                    placeholder="Sélectionner la position de la colonne"
                    value={positionInColumnGroupOptions.find(
                        (positionInColumnGroupOption) => positionInColumnGroup === positionInColumnGroupOption.value,
                    )}
                    options={positionInColumnGroupOptions}
                    onChange={(option) => {
                        if (option) {
                            setPositionInColumnGroup(option.value);
                        }
                    }}
                />
                <TotemSelect
                    label="Groupe de colonne"
                    placeholder="Sélectionner groupe de collone"
                    value={groupOptions.find((groupOption) => columnGroupId === groupOption.value)}
                    options={groupOptions}
                    onChange={(option) => {
                        if (option) {
                            setColumnGroupId(option.value);
                        }
                    }}
                />
                <TotemPrimaryButton onClick={handleSubmit}>
                    {updateLoading ? <Loader size="20px" mode={LoaderModeType.Spin} /> : 'Confirmer'}
                </TotemPrimaryButton>
            </SubmitContainer>
        </TotemPopup>
    );
}

const SubmitContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 15px;

    > :not(:first-child) {
        margin-top: 15px;
    }
`;

import React from 'react';

import { LocationState } from 'data/__generated__';

import { CommonStatusTag } from 'components/CommonStatusTag';
import { colors } from 'constants/colors';

export const getColorsFromState = (state: LocationState) => {
    const { green, pantinGrey, pureWhite, black } = colors;

    switch (state) {
        case LocationState.Regular:
            return { backgroundColor: green, labelColor: black };
        case LocationState.Hidden:
            return { backgroundColor: pantinGrey, labelColor: pureWhite };
    }
};

const LOCATION_STATE_LABELS = {
    [LocationState.Regular]: 'Regular',
    [LocationState.Hidden]: 'Caché',
};

export const StatusTag = ({
    state,
    showHeading,
    sublabel,
}: {
    state: LocationState;
    showHeading?: boolean;
    sublabel?: string;
}) => {
    const { labelColor, backgroundColor } = getColorsFromState(state);
    return (
        <CommonStatusTag
            backgroundColor={backgroundColor}
            labelColor={labelColor}
            label={LOCATION_STATE_LABELS[state]}
            showHeading={showHeading}
            sublabel={sublabel}
        />
    );
};

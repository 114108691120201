import React from 'react';
import styled from 'styled-components';
import { useFormContext, useFieldArray } from 'react-hook-form';

import { DetailFormSelect, DetailFormValue } from 'components/DetailsView';
import { TotemLabel } from 'components/TotemLabel';

import { ORDERING_REQUIREMENT_UNITS_LABELS } from '../constants/labels';

import { OrderingMinRequirementInput, OrderingRequirementUnit } from 'data/__generated__';

export const MinRequirementGroup = ({
    termIndex,
    requirementGroupIndex,
}: {
    termIndex: number;
    requirementGroupIndex: number;
}) => {
    const { register, control, watch } = useFormContext();
    const { fields } = useFieldArray({
        name: `orderingTerms.${termIndex}.minRequirements.${requirementGroupIndex}` as 'orderingTerms.0.minRequirements.0',
        control,
    });

    const requirementUnitLabelsOptions = [
        {
            label: ORDERING_REQUIREMENT_UNITS_LABELS[OrderingRequirementUnit.Box],
            value: OrderingRequirementUnit.Box,
        },
        {
            label: ORDERING_REQUIREMENT_UNITS_LABELS[OrderingRequirementUnit.Eur],
            value: OrderingRequirementUnit.Eur,
        },
        {
            label: ORDERING_REQUIREMENT_UNITS_LABELS[OrderingRequirementUnit.Quantity],
            value: OrderingRequirementUnit.Quantity,
        },
        {
            label: ORDERING_REQUIREMENT_UNITS_LABELS[OrderingRequirementUnit.G],
            value: OrderingRequirementUnit.G,
        },
        {
            label: ORDERING_REQUIREMENT_UNITS_LABELS[OrderingRequirementUnit.Ml],
            value: OrderingRequirementUnit.Ml,
        },
    ];
    return (
        <>
            {fields?.map((item, requirementItemIndex) => {
                const watchMinRequirements: undefined | OrderingMinRequirementInput = watch(
                    `orderingTerms.${termIndex}.minRequirements.${requirementGroupIndex}.${requirementItemIndex}`,
                    item,
                );
                return (
                    <RequirementItemContainer key={item.id}>
                        <Row hasValidValues={!!(watchMinRequirements?.unit && watchMinRequirements?.value)}>
                            <DetailFormValue
                                label="Valeur"
                                {...register(
                                    `orderingTerms.${termIndex}.minRequirements.${requirementGroupIndex}.${requirementItemIndex}.value` as const,
                                    { setValueAs: (value) => parseInt(value) },
                                )}
                                width="100%"
                                type="number"
                                min="0"
                                step={1}
                                data-test={
                                    `orderingTerms.${termIndex}.minRequirements.${requirementGroupIndex}.${requirementItemIndex}.value` as const
                                }
                            />

                            <DetailFormSelect
                                label="Unité"
                                placeholder="Sélectionner une unité"
                                name={
                                    `orderingTerms.${termIndex}.minRequirements.${requirementGroupIndex}.${requirementItemIndex}.unit` as const
                                }
                                isClearable={true}
                                //@ts-ignore need to provide default values in same component useForm to correctly type unit
                                defaultValue={item.unit}
                                options={requirementUnitLabelsOptions}
                                dataTest={`orderingTerms.${termIndex}.minRequirements.${requirementGroupIndex}.${requirementItemIndex}.unit`}
                            />
                        </Row>
                        {requirementItemIndex !== fields.length - 1 ? <JoinTotemLabel>et</JoinTotemLabel> : null}
                    </RequirementItemContainer>
                );
            })}
        </>
    );
};

const JoinTotemLabel = styled(TotemLabel)`
    margin-left: 10px;
`;

const RequirementItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Row = styled.div<{ hasValidValues: boolean }>`
    display: flex;
    align-items: flex-start;
    border-radius: ${({ theme }) => theme.borderRadius};
    margin: 10px;
    padding: 10px;
    border: ${({ theme, hasValidValues }) =>
        hasValidValues ? `2px solid ${theme.successColor}` : `1px solid ${theme.lightBorderColor}`};
    & > :not(:first-child) {
        margin-left: 10px;
    }
`;

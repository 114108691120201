import React from 'react';

import { CommonStatusTag } from 'components/CommonStatusTag';

import { colors } from 'constants/colors';

import { TotemState } from 'data/__generated__';
import { TOTEMS_STATES_LABELS } from './constants/states';

const getColorsFromState = (state: TotemState) => {
    switch (state) {
        case TotemState.Created:
            return { backgroundColor: colors.yellow, labelColor: colors.black };
        case TotemState.Rollout:
            return { backgroundColor: colors.green, labelColor: colors.black };
        case TotemState.Archived:
            return { backgroundColor: colors.pantinGrey, labelColor: colors.pureWhite };
    }
};

export const StatusTag = ({ state, showHeading }: { state: TotemState; showHeading?: boolean }) => {
    const { backgroundColor, labelColor } = getColorsFromState(state);
    return (
        <CommonStatusTag
            backgroundColor={backgroundColor}
            labelColor={labelColor}
            label={TOTEMS_STATES_LABELS[state]}
            showHeading={showHeading}
        />
    );
};

import gql from 'graphql-tag';

export const STOCK_VARIATION_FRAGMENT = gql`
    fragment StockVariationFragment on StockVariation {
        _id
        createdAt
        delta
        explanation
        productId
        ref
        siteId
        siteInventoryStockId
        type
    }
`;

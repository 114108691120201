import gql from 'graphql-tag';
import { SITE_SETUP_FRAGMENT } from 'data/fragments/siteSetup';

export const SITE_SETUP_CREATED_SUBSCRIPTION = gql`
    subscription SITE_SETUP_CREATED {
        siteSetupCreated {
            ...SiteSetupFragment
        }
    }
    ${SITE_SETUP_FRAGMENT}
`;

export const SITE_SETUP_UPDATED_SUBSCRIPTION = gql`
    subscription SITE_SETUP_UPDATED($siteSetupId: ID) {
        siteSetupUpdated(siteSetupId: $siteSetupId) {
            ...SiteSetupFragment
        }
    }
    ${SITE_SETUP_FRAGMENT}
`;

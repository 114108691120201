import { gql } from '@apollo/client';

export const PREVIEW_CREDIT_FRAGMENT = gql`
    fragment PreviewCreditFragment on Credit {
        _id
        createdAt
        createdBy {
            _id
            profile {
                fullname
            }
        }
        updatedAt
        name
        initialAmount
        currentAmount
        organizationBatched {
            name
        }
        expirationDate
        owner {
            _id
            profile {
                fullname
            }
        }
        startDate
        type
        refundedAt
    }
`;

import React from 'react';
import { useMutation } from '@apollo/client';
import { Link, useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { FaSave } from 'react-icons/fa';
import { toast } from 'react-toastify';

import styled from 'styled-components';

import { CREATE_CATEGORY_MUTATION } from 'data/mutations/category';
import { CREATE_CATEGORY, CREATE_CATEGORYVariables } from 'data/mutations/__generated__/CREATE_CATEGORY';

import { PAGES } from 'constants/pages';

import { GeneralInfoSection } from 'pages/Categories/CategoryDetails/GeneralInfoSection';

import { Loader, LoaderModeType } from 'components/Loader';
import { Header, HeaderTitle } from 'components/Header';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { PageTitle } from 'components/PageTitle';

type FormData = {
    image: File | null;
    title: string;
    indexDisplay: number;
    isPunctual: boolean;
    isFreefood: boolean;
    isMicrostore: boolean;
    shouldShowSubcategories: boolean;
};

export type InitialCategoryType = Omit<FormData, 'image'> & {
    imageUrl: string;
};

const defaultCategory = {
    title: '',
    imageUrl: '',
    indexDisplay: 0,
    isFreefood: false,
    isMicrostore: false,
    isPunctual: false,
    shouldShowSubcategories: false,
};

export const CreateCategory = () => {
    const navigate = useNavigate();

    const [createCategory, { loading: creationLoading }] = useMutation<CREATE_CATEGORY, CREATE_CATEGORYVariables>(
        CREATE_CATEGORY_MUTATION,
    );

    const methods = useForm<FormData>();

    const {
        formState: { isDirty },
        handleSubmit,
    } = methods;

    const initialCategory: InitialCategoryType = { ...defaultCategory };

    const handleFormSubmit = handleSubmit(async (fields) => {
        const { data } = await createCategory({
            variables: {
                fields,
            },
        });

        if (data) {
            const {
                createCategory: { success, error, categoryId },
            } = data;
            if (success) {
                toast.success(`La catégorie "${fields.title}" a bien été créée !`);
                navigate(`/category/${categoryId}`);
            } else {
                if (error) {
                    toast.error('error');
                } else {
                    throw Error("Une erreur inconnue s'est produite");
                }
            }
        } else {
            throw Error("Une erreur inconnue s'est produite");
        }
    });

    return (
        <Container>
            <FormProvider {...methods}>
                <Form onSubmit={handleFormSubmit}>
                    <Header>
                        <HeaderTitle>
                            <PageTitle page={PAGES.categoryDetails} />
                        </HeaderTitle>
                        <CTAsContainer>
                            <TotemPrimaryButton minWidth="140px" type="submit" disabled={!isDirty}>
                                {creationLoading ? (
                                    <Loader size="18px" mode={LoaderModeType.Spin} />
                                ) : (
                                    <>
                                        <FaSave size={13} />
                                        <SaveLabel>Créer</SaveLabel>
                                    </>
                                )}
                            </TotemPrimaryButton>
                            <ButtonLink to="/categories">
                                <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                            </ButtonLink>
                        </CTAsContainer>
                    </Header>
                    <Content>
                        <ScrollableContent>
                            <GeneralInfoSection category={initialCategory} />
                        </ScrollableContent>
                    </Content>
                </Form>
            </FormProvider>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
`;

const Content = styled.div`
    flex: 1;
    overflow: hidden;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const ScrollableContent = styled.div`
    padding: 15px;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

const ButtonLink = styled(Link)`
    margin-left: 5px;
`;

const CTAsContainer = styled.div`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.textColor};

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const SaveLabel = styled.span`
    margin-left: 5px;
`;

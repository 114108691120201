import gql from 'graphql-tag';
import { SUPPLIER_FRAGMENT } from '../fragments/supplier';

export const GET_SUPPLIERS_QUERY = gql`
    query GET_SUPPLIERS {
        suppliers {
            _id
            name
            state
        }
    }
`;

export const GET_PAGINATED_FILTERED_SUPPLIERS_QUERY = gql`
    query GET_PAGINATED_FILTERED_SUPPLIERS($offset: Int!, $filterString: String, $shouldHideArchived: Boolean) {
        paginatedFilteredSuppliers(
            offset: $offset
            filterString: $filterString
            shouldHideArchived: $shouldHideArchived
        ) {
            hasMore
            suppliers {
                _id
                name
                state
            }
        }
    }
`;

export const GET_DETAILED_SUPPLIER_QUERY = gql`
    query GET_DETAILED_SUPPLIER($supplierId: ID!) {
        detailedSupplier(supplierId: $supplierId) {
            ...SupplierFragment
        }
    }
    ${SUPPLIER_FRAGMENT},
`;

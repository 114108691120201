export function getProductFullName({
    name,
    brand,
    volume,
}: {
    name?: string | null;
    brand?: string | null;
    volume?: string | null;
}): string {
    return `${name}${brand ? ` - ${brand}` : ''}${volume ? ` - ${volume}` : ''}`;
}

export function formatFormArray(array: { value?: string }[]): string[] {
    return array.reduce<string[]>((acc, { value }) => {
        if (value) {
            acc.push(value);
        }
        return acc;
    }, []);
}

export function formatMultilineToArray(value: string): string[] {
    return value.split('\n').filter(Boolean);
}

import React from 'react';

import styled, { useTheme } from 'styled-components';

import {
    GetMicrostoreSitesWithLocationsInfoQuery,
    MicrostoreColumnTemplateType,
    ProductArrangementReassortmentType,
    ProductFlow,
} from 'data/__generated__';

import { ColumnLocationSelector } from '../ColumnLocationSelector';
import { TotemPopup } from 'components/TotemPopup';

import {
    MicrostoreColumnGroupFormValues,
    MicrostoreColumnFormValues,
    MicrostoreProductsStockInfoFormValues,
} from '../FormHelper/DataMapper';
import { formatDateAsAnniversary } from 'helpers/dateTimes';
import { computeColorFromName } from 'helpers/computeColorFromName';

export function MicrostoreColumnEditorInfoPopup({
    isOpen,
    setIsOpen,
}: {
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
}) {
    const theme = useTheme();
    const today = new Date();
    const todayAnniversaryDate = formatDateAsAnniversary({ dateTime: today });

    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    const yesterdayAnniversaryDate = formatDateAsAnniversary({ dateTime: yesterday });

    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const tomorrowAnniversaryDate = formatDateAsAnniversary({ dateTime: tomorrow });

    const inTwoDays = new Date(today);
    inTwoDays.setDate(inTwoDays.getDate() + 2);
    const inTwoDaysAnniversaryDate = formatDateAsAnniversary({ dateTime: inTwoDays });

    const fakeSite = {
        name: 'fakeSite',
        isSecured: false,
        __typename: 'Site',
    } as unknown as GetMicrostoreSitesWithLocationsInfoQuery['sites'][number];

    const fakeMicrostoreProductsStockInfo: MicrostoreProductsStockInfoFormValues = [
        {
            __typename: 'ProductWithMicrostoreNeededInfo',
            _id: 'productId1',
            brand: 'Marque',
            categoryId: 'categoryId',
            expiryDateRecordsWarehouse: [
                {
                    __typename: 'ExpiryDateRecord',
                    _id: 'expiryDateId1',
                    expiryDate: tomorrowAnniversaryDate,
                    initialQuantity: 23,
                    quantityAtInventory: 23,
                },
            ],
            flow: ProductFlow.Fresh,
            isPresentTomorrow: true,
            name: 'Mon super produit',
            stockOnSite: 2,
            stockAvailableOnSite: 2,
            stockAvailableWarehouse: 2,
            stockExpectedInSupplyOrders: 1,
            stockToRemove: 1,
            stockUsedInMicrostore: 1,
            stockUsedInOtherMicrostores: 2,
            stockWarehouse: 5,
            transactionsCountForToday: 2,
            volume: '5g',
            trackExpiryDate: true,
        },
        {
            __typename: 'ProductWithMicrostoreNeededInfo',
            _id: 'productId2',
            brand: 'Marque',
            categoryId: 'categoryId',
            expiryDateRecordsWarehouse: [],
            flow: ProductFlow.Dry,
            isPresentTomorrow: false,
            name: "Un produit qu'on enlève",
            stockOnSite: 8,
            stockAvailableOnSite: 8,
            stockAvailableWarehouse: 4,
            stockExpectedInSupplyOrders: 0,
            stockToRemove: 0,
            stockUsedInMicrostore: 0,
            stockUsedInOtherMicrostores: 0,
            stockWarehouse: 1,
            transactionsCountForToday: 0,
            volume: '5g',
            trackExpiryDate: false,
        },
        {
            __typename: 'ProductWithMicrostoreNeededInfo',
            _id: 'productId3',
            brand: 'Marque',
            categoryId: 'categoryId',
            expiryDateRecordsWarehouse: [
                {
                    __typename: 'ExpiryDateRecord',
                    _id: 'expiryDateId1',
                    expiryDate: yesterdayAnniversaryDate,
                    initialQuantity: 12,
                    quantityAtInventory: 12,
                },
                {
                    __typename: 'ExpiryDateRecord',
                    _id: 'expiryDateId2',
                    expiryDate: inTwoDaysAnniversaryDate,
                    initialQuantity: 23,
                    quantityAtInventory: 23,
                },
            ],
            flow: ProductFlow.Dry,
            isPresentTomorrow: true,
            name: "Un produit qu'on ajoute",
            stockOnSite: 8,
            stockAvailableOnSite: 8,
            stockAvailableWarehouse: 5,
            stockExpectedInSupplyOrders: 0,
            stockToRemove: 1,
            stockUsedInMicrostore: 0,
            stockUsedInOtherMicrostores: 0,
            stockWarehouse: 2,
            transactionsCountForToday: 0,
            volume: '5g',
            trackExpiryDate: false,
        },
        {
            __typename: 'ProductWithMicrostoreNeededInfo',
            _id: 'productId4',
            brand: 'Marque',
            categoryId: 'categoryId',
            expiryDateRecordsWarehouse: [
                {
                    __typename: 'ExpiryDateRecord',
                    _id: 'expiryDateId1',
                    expiryDate: tomorrowAnniversaryDate,
                    initialQuantity: 12,
                    quantityAtInventory: 12,
                },
                {
                    __typename: 'ExpiryDateRecord',
                    _id: 'expiryDateId2',
                    expiryDate: inTwoDaysAnniversaryDate,
                    initialQuantity: 23,
                    quantityAtInventory: 23,
                },
            ],
            flow: ProductFlow.Dry,
            isPresentTomorrow: true,
            name: "Un produit qu'on change d'endroit",
            stockOnSite: 10,
            stockAvailableOnSite: 10,
            stockAvailableWarehouse: 0,
            stockExpectedInSupplyOrders: 0,
            stockToRemove: 0,
            stockUsedInMicrostore: 3,
            stockUsedInOtherMicrostores: 0,
            stockWarehouse: 0,
            transactionsCountForToday: 1,
            volume: '5g',
            trackExpiryDate: true,
        },
    ];

    const fakeMicrostoreColumnFormValues: MicrostoreColumnFormValues = {
        __typename: 'MicrostoreColumn',
        _id: 'columnId',
        columnGroupId: 'columnGroupId',
        microstoreColumnTemplateId: 'microstoreColumnTemplateId',
        name: 'Name',
        templateName: 'templateName',
        positionInColumnGroup: 0,
        shelves: [
            {
                __typename: 'MicrostoreShelf',
                _id: 'shelfId1',
                positionInColumn: 0,
                locationTable: [
                    [
                        {
                            _id: 'locationId',
                            expiryDates: [
                                {
                                    expiryDate: todayAnniversaryDate,
                                    quantity: 4,
                                    shouldRemoveFromSite: false,
                                },
                            ],
                            locationTemplateId: 'locationTemplateId',
                            positionInShelf: {
                                __typename: 'MicrostoreLocationPositionInShelf',
                                column: 0,
                                row: 0,
                            },
                            productId: 'productId1',
                            microstoreLocationTemplate: {
                                __typename: 'MicrostoreLocationTemplate',
                                _id: 'microstoreLocationTemplate1',
                                productArrangement: {
                                    __typename: 'ProductArrangement',
                                    _id: 'productArrangementId',
                                    name: 'productArrangementName',
                                    productIdsWithStockMax: [],
                                    reassortmentType: ProductArrangementReassortmentType.Manual,
                                },
                                productArrangementSingleProductInfo: null,
                            },
                            replacementProductId: 'productId1',
                            stockToAdd: 10,
                        },
                        {
                            _id: 'locationId',
                            expiryDates: [
                                {
                                    expiryDate: yesterdayAnniversaryDate,
                                    quantity: 4,
                                    shouldRemoveFromSite: true,
                                },
                            ],
                            locationTemplateId: 'locationTemplateId',
                            positionInShelf: {
                                __typename: 'MicrostoreLocationPositionInShelf',
                                column: 1,
                                row: 0,
                            },
                            productId: 'productId2',
                            microstoreLocationTemplate: {
                                __typename: 'MicrostoreLocationTemplate',
                                _id: 'microstoreLocationTemplate2',
                                productArrangement: {
                                    __typename: 'ProductArrangement',
                                    _id: 'productArrangementId',
                                    name: 'productArrangementName',
                                    productIdsWithStockMax: [],
                                    reassortmentType: ProductArrangementReassortmentType.Manual,
                                },
                                productArrangementSingleProductInfo: null,
                            },
                            replacementProductId: 'productId4',
                            stockToAdd: 3,
                        },
                        {
                            _id: 'locationId',
                            expiryDates: [
                                {
                                    expiryDate: tomorrowAnniversaryDate,
                                    quantity: 4,
                                    shouldRemoveFromSite: true,
                                },
                            ],
                            locationTemplateId: 'locationTemplateId',
                            positionInShelf: {
                                __typename: 'MicrostoreLocationPositionInShelf',
                                column: 2,
                                row: 0,
                            },
                            productId: 'productId4',
                            microstoreLocationTemplate: {
                                __typename: 'MicrostoreLocationTemplate',
                                _id: 'microstoreLocationTemplate3',
                                productArrangement: {
                                    __typename: 'ProductArrangement',
                                    _id: 'productArrangementId',
                                    name: 'productArrangementName',
                                    productIdsWithStockMax: [],
                                    reassortmentType: ProductArrangementReassortmentType.Manual,
                                },
                                productArrangementSingleProductInfo: null,
                            },
                            replacementProductId: 'productId3',
                            stockToAdd: 3,
                        },
                    ],
                ],
            },
        ],
        type: MicrostoreColumnTemplateType.Fridge,
    } as unknown as MicrostoreColumnFormValues;

    const fakeMicrostoreColumnGroupFormValues: MicrostoreColumnGroupFormValues = {
        __typename: 'MicrostoreColumnGroup',
        _id: 'columnGroupId',
        columns: [fakeMicrostoreColumnFormValues],
        name: 'columnGroupName',
        positionInSite: 0,
        siteId: 'siteId',
    };

    const fakeNextMSDeliveryDate = '2022-10-10';

    return (
        <TotemPopup
            title="Infos interface"
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            contentOverflow="auto"
            position="fixed"
        >
            {/* THIS IS A FAKE COMPONENT ONLY USED TO DISPLAY INFO EXAMPLE */}
            <Content>
                <ColumnLocationSelector
                    isToday={true}
                    displayOnlyTomorrowForPrint={false}
                    microstoreProductsStockInfo={fakeMicrostoreProductsStockInfo}
                    selectedColumn={fakeMicrostoreColumnFormValues}
                    microstoreColumnGroupFormValuesArray={[fakeMicrostoreColumnGroupFormValues]}
                    nextSiteDeliveryDate={fakeNextMSDeliveryDate}
                    earliestDLCDateToKeepForStockCalculation={fakeNextMSDeliveryDate}
                    selectedLocationPosition={{ column: 0, row: 0 }}
                    selectedShelfIndex={0}
                    setIsToday={() => null}
                    setSelectedLocationPosition={() => null}
                    setSelectedShelfIndex={() => null}
                    site={fakeSite}
                    switchColumn={null}
                    updateSelectedLocation={() => null}
                />
                <ColumnsContainer>
                    <ul>
                        <li>
                            Chaque étagère est séparée en deux partie : J et J+1.
                            <ul>
                                <li>J représente l'état de l'étagère dans le site aujourd'hui (avant réappro). </li>
                                <li>
                                    J + 1 représente l'état voulu de l'étagère demain (après réappro), cela représente
                                    le réapprovisionnement.
                                </li>
                            </ul>
                        </li>
                    </ul>
                </ColumnsContainer>
                <ColumnsContainer>
                    <ul>
                        <li>
                            Analyse de J :
                            <ul>
                                <li>
                                    <LineContainer>
                                        <LocationProductArrangementColor
                                            color={computeColorFromName('productArrangementName')}
                                            margin="right"
                                        />
                                        Couleur représentant la gamme de produit
                                        <LocationProductArrangementColor
                                            color={computeColorFromName('productArrangementName')}
                                        />
                                    </LineContainer>
                                </li>
                                <li>Nom du produit</li>
                                <li>
                                    DLC : pour J, les DLC représente les DLC <b>sur site</b>.
                                    <ul>
                                        <li>Elles sont a remplir à la main dans le panneau DLC.</li>
                                        <li>
                                            <span style={{ color: theme.errorColor }}>Rouge, périmé</span>,{' '}
                                            <span style={{ color: theme.warningColor }}>
                                                orange périme aujourd'hui.
                                            </span>
                                        </li>
                                        <li>
                                            Si il y a plusieurs DLC, une seule est affiché mais les '...' indique qu'il
                                            y en a d'autre.
                                        </li>
                                        <li>
                                            Si le produit reste dans le microstore, il est possible de demander à
                                            retirer une partie du stock pour retirer les DLC expirés (en plus de
                                            restocker) (checkbox dans le panneau DLC).
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <b>S</b>: stock sur site
                                </li>
                                <li>
                                    <b>V</b>: Vendu
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            Analyse de J+1 :
                            <ul>
                                <li>Nom du produit de remplacement</li>
                                <li>
                                    DLC : pour J+1, les DLC représente les DLC <b>en entrepôt</b>.
                                    <ul>
                                        <li>Elles viennent des inventaires et des arrivages totops</li>
                                        <li>
                                            <span style={{ color: theme.errorColor }}>Rouge, périmé</span>,{' '}
                                            <span style={{ color: theme.warningColor }}>
                                                orange périme aujourd'hui.
                                            </span>
                                        </li>
                                        <li>
                                            Si il y a plusieurs DLC, une seule est affiché mais les '...' indique qu'il
                                            y en a d'autre.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <b>Disp:</b>: stock disponible en entrepôt (pas stock physique, mais stock pas
                                    réservé pour autres stores ou freefood )
                                </li>
                                <li>
                                    <b>S+</b>: stock a (re)mettre. Combien de produits on veut rajouter à l'emplacement
                                    demain.
                                </li>
                                <li>
                                    <b>S-</b>: stock à retirer (cf DLC). Le produit est à retirer si son DLC sera
                                    dépassé avant le prochain restocking de site
                                </li>
                                <li>
                                    <b>ST</b>: stock total sur site
                                </li>
                                <li>
                                    <b>Manuel/ Auto SC</b>: Restocking soit manuel, soit automatique avec les stocks
                                    cibles
                                </li>
                            </ul>
                        </li>
                    </ul>
                </ColumnsContainer>
                <ColumnsContainer>
                    <ul>
                        <li>
                            Analyse des couleurs
                            <ul>
                                <ListItemWithBackground color="#e181ff60">
                                    Violet : produit de flux pas frais avec DLC
                                </ListItemWithBackground>
                                <ListItemWithBackground color="#ff001157">
                                    Rouge : on retire le produit
                                </ListItemWithBackground>
                                <ListItemWithBackground color="#1bbe4f5a">
                                    Vert : on rajoute le produit
                                </ListItemWithBackground>
                                <ListItemWithBackground color="#fffb00c8">
                                    Jaune : on change de place le produit
                                </ListItemWithBackground>
                            </ul>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            Astuces
                            <ul>
                                <li>
                                    Si quelques uns de mes produits sont périmés et que je veux le restocker. Je dois
                                    définir un stock cible et un stock à retirer. Exemple : j'ai 4 produits en stock
                                    dont 2 qui périment. Je veux en avoir 8. Dans ce cas je met mon stock cible à 8 et
                                    marque les 2 produits comme étant à retirer. Lors du transfert de stock cela se
                                    traduira par le rajout de 6 produits (4 + 2)
                                </li>
                            </ul>
                        </li>
                    </ul>
                </ColumnsContainer>
            </Content>
        </TotemPopup>
    );
}

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const ColumnsContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    padding-top: 5px;
    border-top: 1px solid ${({ theme }) => theme.lightBorderColor};

    > * {
        flex: 1 0 0;
        margin: 0;
    }
`;

const LocationProductArrangementColor = styled.div<{ color: string; margin?: string }>`
    width: 5px;
    height: 5px;

    background-color: ${({ color }) => color};
    ${({ margin }) => (margin === 'right' ? 'margin-right: 5px' : 'margin-left: 5px')};
`;

const ListItemWithBackground = styled.li`
    ${({ color }) => color && `background-color: ${color}`};
`;

const LineContainer = styled.div`
    display: flex;
    align-items: center;
`;

import React from 'react';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { GET_DETAILED_SUPPLIER_detailedSupplier } from 'data/queries/__generated__/GET_DETAILED_SUPPLIER';
import { SupplierState } from 'data/__generated__';
import { RESTORE_SUPPLIER, RESTORE_SUPPLIERVariables } from 'data/mutations/__generated__/RESTORE_SUPPLIER';
import { ARCHIVE_SUPPLIER, ARCHIVE_SUPPLIERVariables } from 'data/mutations/__generated__/ARCHIVE_SUPPLIER';
import { ARCHIVE_SUPPLIER_MUTATION, RESTORE_SUPPLIER_MUTATION } from 'data/mutations/supplier';

import { ColumnsSectionContainer, SectionColumn, SectionContainer } from 'components/DetailsView/Section';
import { StateHistory } from 'components/DetailsView';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { StatusTag } from '../components/StatusTag';
import { Loader, LoaderModeType } from 'components/Loader';

export const StatesSection = ({ supplier }: { supplier: GET_DETAILED_SUPPLIER_detailedSupplier }) => {
    const { _id: supplierId, state, stateHistory } = supplier;
    const [restoreSupplier, { loading: restoreSupplierLoading }] = useMutation<
        RESTORE_SUPPLIER,
        RESTORE_SUPPLIERVariables
    >(RESTORE_SUPPLIER_MUTATION, { variables: { supplierId } });
    const [archiveSupplier, { loading: archiveSupplierLoading }] = useMutation<
        ARCHIVE_SUPPLIER,
        ARCHIVE_SUPPLIERVariables
    >(ARCHIVE_SUPPLIER_MUTATION, { variables: { supplierId } });
    const formattedStateHistory = stateHistory.map((stateRecord) => ({
        ...stateRecord,
        state: <StatusTag state={stateRecord.state} />,
    }));

    async function restoreSupplierFunction() {
        const { data } = await restoreSupplier();
        if (!data) {
            throw new Error('Une erreur est survenue lors de la restauration du fournisseur');
        }
        const {
            restoreSupplier: { updatedSupplier, error },
        } = data;
        if (updatedSupplier) {
            toast.success('Le statut du fournisseur a bien été passé en "Normal"');
        }
        if (error) {
            toast.error(error);
        }
    }

    async function archiveSupplierFunction() {
        const { data } = await archiveSupplier();
        if (!data) {
            throw new Error("Une erreur est survenue lors de l'archivage du fournisseur");
        }
        const {
            archiveSupplier: { updatedSupplier, error },
        } = data;
        if (updatedSupplier) {
            toast.success('Le statut du fournisseur a bien été passé en "Archivé"');
        }
        if (error) {
            toast.error(error);
        }
    }

    const STATE_CHANGE_ACTIONS = [
        {
            label: 'Restaurer le fournisseur',
            action: restoreSupplierFunction,
            isDisabled: state === SupplierState.Regular,
            loading: restoreSupplierLoading,
        },
        {
            label: 'Archiver le fournisseur',
            action: archiveSupplierFunction,
            isDisabled: state === SupplierState.Archived,
            loading: archiveSupplierLoading,
        },
    ];

    return (
        <SectionContainer title="Information des statuts" isInitiallyOpen>
            <ColumnsSectionContainer numberOfColumns={3}>
                <SectionColumn>{state ? <StatusTag state={state} /> : null}</SectionColumn>
                <SectionColumn>
                    <StateHistory records={formattedStateHistory} />
                </SectionColumn>
                <SectionColumn>
                    <SectionColumn>
                        <>
                            <OverlaySectionColumn isDirty={false}>
                                {STATE_CHANGE_ACTIONS.map((stateChange, index) => {
                                    const { label, loading, isDisabled, action } = stateChange;

                                    function updateState(e: React.MouseEvent<HTMLElement, MouseEvent>) {
                                        e.preventDefault();
                                        const hasConfirmed = window.confirm(
                                            `Êtes-vous sûr.e de vouloir ${label.toLowerCase()} ?`,
                                        );
                                        if (hasConfirmed) {
                                            action();
                                        }
                                    }

                                    return (
                                        <TotemPrimaryButton key={index} onClick={updateState} disabled={isDisabled}>
                                            {label}
                                            {loading ? <Loader size="20px" mode={LoaderModeType.Spin} /> : null}
                                        </TotemPrimaryButton>
                                    );
                                })}
                            </OverlaySectionColumn>
                        </>
                    </SectionColumn>
                </SectionColumn>
            </ColumnsSectionContainer>
        </SectionContainer>
    );
};

const OverlaySectionColumn = styled(SectionColumn)`
    opacity: ${({ isDirty }: { isDirty: boolean }) => (isDirty ? 0.4 : 1)};
`;

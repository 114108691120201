import React, { FunctionComponent } from 'react';

import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { SupplierDiscountsQuery, useDeleteSupplierDiscountMutation } from 'data/__generated__';
import { GET_SUPPLIER_DISCOUNTS_QUERY } from 'data/queries/supplierDiscounts';

type DeleteConfirmationModalProps = {
    setIsOpen: (arg: boolean) => void;
    isOpen: boolean;
    supplierDiscountId: string;
};
export const DeleteConfirmationModal: FunctionComponent<DeleteConfirmationModalProps> = ({
    setIsOpen,
    isOpen,
    supplierDiscountId,
}) => {
    const [deleteSupplierDiscount] = useDeleteSupplierDiscountMutation({
        update: (cache, { data }, { variables }) => {
            if (!data?.deleteSupplierDiscount) return;

            const supplierDiscountsQuery = cache.readQuery<SupplierDiscountsQuery>({
                query: GET_SUPPLIER_DISCOUNTS_QUERY,
            });

            if (!supplierDiscountsQuery) return;

            cache.writeQuery<SupplierDiscountsQuery>({
                query: GET_SUPPLIER_DISCOUNTS_QUERY,
                data: {
                    supplierDiscounts: supplierDiscountsQuery.supplierDiscounts.filter(
                        (supplierDiscount) => supplierDiscount._id !== variables?.id,
                    ),
                },
            });
        },
        onCompleted: (data) => {
            if (!data?.deleteSupplierDiscount) return;

            setIsOpen(false);
        },
    });

    return (
        <TotemPopup isOpen={isOpen} title="Supprimer la remise fournisseur" setIsOpen={setIsOpen}>
            <p>Êtes-vous sûr de vouloir supprimer cette remise fournisseur ?</p>
            <TotemPrimaryButton
                onClick={() => {
                    deleteSupplierDiscount({ variables: { id: supplierDiscountId } });
                }}
            >
                Oui
            </TotemPrimaryButton>
        </TotemPopup>
    );
};

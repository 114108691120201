import React from 'react';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';

import { CategoryFragmentFragment } from 'data/__generated__';

import { SectionColumn, SectionContainer, ColumnsSectionContainer } from 'components/DetailsView/Section';
import { DetailFormCheckbox, DetailFormImage, DetailFormValue, DetailValue } from 'components/DetailsView';
import { CopyValue } from 'components/CopyValue';
import { StatusTag } from 'pages/Categories/components/StatusTag';

import { getExtendedFormattedDatetime } from 'helpers/dateTimes';

import { InitialCategoryType } from 'pages/Categories/CreateCategory';

export const GeneralInfoSection = ({ category }: { category: CategoryFragmentFragment | InitialCategoryType }) => {
    const { title, indexDisplay, isFreefood, isMicrostore, isPunctual, imageUrl, shouldShowSubcategories } = category;

    const { register } = useFormContext();

    return (
        <SectionContainer title="Informations générales" isInitiallyOpen>
            <ColumnsSectionContainer numberOfColumns={3}>
                <SectionColumn>
                    <Row>
                        <DetailFormImage name="image" label="Image" defaultValue={imageUrl} size={150} />
                        {'createdAt' in category ? (
                            <ModificationInfo>
                                {category._id ? <CopyValue value={category._id} /> : null}
                                <StatusTag state={category.state} showHeading />
                                <Row>
                                    <DetailValue
                                        label="Dernière mise à jour"
                                        value={getExtendedFormattedDatetime(category.updatedAt)}
                                    />
                                    <DetailValue
                                        label="Date de création"
                                        value={getExtendedFormattedDatetime(category.createdAt)}
                                    />
                                </Row>
                            </ModificationInfo>
                        ) : null}
                    </Row>
                </SectionColumn>
                <SectionColumn>
                    <DetailFormValue label="Titre" defaultValue={title} {...register('title', { required: true })} />
                    <DetailFormValue
                        label="Index d'affichage"
                        defaultValue={indexDisplay}
                        type="number"
                        step="1"
                        {...register('indexDisplay', {
                            required: true,
                            valueAsNumber: true,
                        })}
                    />
                </SectionColumn>
                <SectionColumn>
                    <SectionColumn>
                        <DetailFormCheckbox
                            name="isFreefood"
                            label="Disponible en freefood"
                            defaultChecked={isFreefood}
                        />
                        <DetailFormCheckbox
                            name="isPunctual"
                            label="Disponible en ponctuel"
                            defaultChecked={isPunctual}
                        />
                        <DetailFormCheckbox
                            name="isMicrostore"
                            label="Disponible en microstore"
                            defaultChecked={isMicrostore}
                        />
                        <DetailFormCheckbox
                            name="shouldShowSubcategories"
                            label="Afficher sous-catégories"
                            sublabel="Pour afficher les sous-catégories de section 'Catégorie' sur l'app mobile"
                            defaultChecked={shouldShowSubcategories}
                        />
                    </SectionColumn>
                </SectionColumn>
            </ColumnsSectionContainer>
        </SectionContainer>
    );
};

const Row = styled.div`
    display: flex;

    & > :not(:first-child) {
        margin-left: 15px;
    }
`;

const ModificationInfo = styled.div`
    display: flex;
    flex-direction: column;
    margin: 15px;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

import React, { useState } from 'react';
import Switch from 'react-switch';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { PAGES } from '../../constants/pages';

import { Header, HeaderTitle } from 'components/Header';
import { PageTitle } from 'components/PageTitle';
import { TotemInput } from 'components/TotemInput';
import { SuppliersList } from './components/SuppliersList';
import { colors } from 'constants/colors';
import { TotemLabel } from 'components/TotemLabel';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

export const Suppliers = () => {
    const [filterString, setFilterString] = useState('');
    const [shouldHideArchived, setShouldHideArchived] = useState(true);

    async function updateSearchParams(value: string) {
        setFilterString(value);
    }

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.suppliers} />
                </HeaderTitle>
                <ButtonsContainer>
                    <LabelContainer>
                        <Label>Cacher les fournisseurs archivés</Label>
                    </LabelContainer>
                    <Switch
                        onChange={() => setShouldHideArchived(!shouldHideArchived)}
                        checked={shouldHideArchived}
                        onColor={colors.green}
                        offColor={colors.pantinGrey}
                        data-test="hide-archived-suppliers-switch"
                    />
                    <Link to={PAGES.supplierCreate.url}>
                        <TotemPrimaryButton>Créer un fournisseur</TotemPrimaryButton>
                    </Link>
                </ButtonsContainer>
            </Header>
            <Content>
                <TotemInput
                    label="Recherche"
                    onChange={updateSearchParams}
                    placeholder="Nom du fournisseur"
                    value={filterString}
                    autoFocus={true}
                />
                <ListContainer>
                    <SuppliersList filterString={filterString} shouldHideArchived={shouldHideArchived} />
                </ListContainer>
            </Content>
        </Container>
    );
};

const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    ${TotemPrimaryButton} {
        margin-left: 5px;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    flex: 1;
    overflow: hidden;
`;

const ListContainer = styled.div`
    flex: 1;
    overflow: hidden;
`;

const LabelContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const Label = styled(TotemLabel)`
    margin-right: 5px;
`;

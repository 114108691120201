import gql from 'graphql-tag';
import { SITE_FRAGMENT } from '../fragments/site';
import { MICROSTORE_COLUMN_GROUP_WITH_COLUMNS_FRAGMENT } from 'data/fragments/microstoreColumnGroup';

export const GET_SITES_QUERY = gql`
    query GET_SITES {
        sites {
            _id
            deliveryDays
            name
            organizationId
            state
            totemId
            type
        }
    }
`;

export const GET_SIMPLE_MICROSTORE_SITES_QUERY = gql`
    query GET_SIMPLE_MICROSTORE_SITES {
        microstoreSites {
            _id
            microstoreColumnGroups {
                _id
                name
                columns {
                    _id
                    name
                }
            }
            name
            state
            type
        }
    }
`;

export const GET_MICROSTORE_SITES_QUERY = gql`
    query GET_MICROSTORE_SITES {
        microstoreSitesWithColumnGroups {
            ...SiteFragment
            microstoreColumnGroups {
                _id
                name
                columns {
                    _id
                    columnGroupId
                    columnIdToReplace
                    isPaused
                    microstoreColumnTemplateId
                    name
                    positionInColumnGroup
                    removalDate
                    startingDate
                    templateName
                    type
                }
            }
        }
    }
    ${SITE_FRAGMENT}
`;

export const GET_MICROSTORE_SITES_WITH_REASSORTMENT_STOCK_QUERY = gql`
    query GET_MICROSTORE_SITES_WITH_REASSORTMENT_STOCK {
        microstoreSitesWithReassortmentInfo {
            _id
            freshReassortmentInfos {
                filledLocationsRatio
                hasStartedReassortment
                stockByCategories {
                    dessert
                    main
                    smallBites
                    starter
                }
            }
            name
            nextDeliveryDate
            reassortmentStockTargets {
                dessert
                main
                smallBites
                starter
            }
            reassortmentOnboardingStockTargets {
                dessert
                main
                smallBites
                starter
            }
        }
    }
`;

export const GET_SITE_QUERY = gql`
    query GET_SITE($siteId: ID!) {
        site(siteId: $siteId) {
            ...SiteFragment
        }
    }
    ${SITE_FRAGMENT}
`;

export const GET_MICROSTORE_SITE_QUERY = gql`
    query GET_MICROSTORE_SITE($siteId: ID!) {
        siteWithLocationInfo(siteId: $siteId) {
            _id
            deliveryDays
            earliestDLCDateToKeepForStockCalculation
            isSecured
            name
            nextDeliveryDate
            reassortmentStockTargets {
                dessert
                main
                smallBites
                starter
            }
            reassortmentOnboardingStockTargets {
                dessert
                main
                smallBites
                starter
            }
            microstoreColumnGroups {
                ...MicrostoreColumnGroupWithColumnsFragment
            }
            microstoreReassortmentStockTransfers {
                _id
                delivery {
                    _id
                    state
                }
                reassortmentFlow
                transferDate
                state
            }
        }
    }
    ${MICROSTORE_COLUMN_GROUP_WITH_COLUMNS_FRAGMENT}
`;

export const GET_MICROSTORE_SITES_WITH_LOCATIONS_INFO_QUERY = gql`
    query GET_MICROSTORE_SITES_WITH_LOCATIONS_INFO($siteIds: [ID!]!) {
        sites(siteIds: $siteIds) {
            _id
            isSecured
            name
            microstoreColumnGroups {
                ...MicrostoreColumnGroupWithColumnsFragment
            }
        }
    }
    ${MICROSTORE_COLUMN_GROUP_WITH_COLUMNS_FRAGMENT}
`;

export const GET_MICROSTORE_PRODUCTS_STOCK_INFO_QUERY = gql`
    query GET_MICROSTORE_PRODUCTS_STOCK_INFO($siteId: ID!) {
        getMicrostoreProductsStockInfoQuery(siteId: $siteId) {
            _id
            brand
            categoryId
            expiryDateRecordsWarehouse {
                _id
                expiryDate
                initialQuantity
                quantityAtInventory
            }
            flow
            name
            stockOnSite
            stockAvailableOnSite
            stockWarehouse
            stockAvailableWarehouse
            stockToRemove
            stockUsedInOtherMicrostores
            stockExpectedInSupplyOrders
            trackExpiryDate
            transactionsCountForToday
            volume
        }
    }
`;

export const GET_FRESH_PRODUCTS_WAREHOUSE_STOCK_INFO_QUERY = gql`
    query GET_FRESH_PRODUCTS_WAREHOUSE_STOCK_INFO {
        getFreshProductsWarehouseStockInfo {
            dessert {
                stockAvailableWithReassortment
                stockExpectedInSupplyOrders
                availableWarehouseStock
            }
            main {
                stockAvailableWithReassortment
                stockExpectedInSupplyOrders
                availableWarehouseStock
            }
            smallBites {
                stockAvailableWithReassortment
                stockExpectedInSupplyOrders
                availableWarehouseStock
            }
            starter {
                stockAvailableWithReassortment
                stockExpectedInSupplyOrders
                availableWarehouseStock
            }
        }
    }
`;

export const GET_NEXT_MS_DELIVERY_DATE_QUERY = gql`
    query GET_NEXT_MS_DELIVERY_DATE {
        getNextMSDeliveryDate
    }
`;

import React from 'react';

import type { FileError } from 'react-dropzone';
import { FaTrash } from 'react-icons/fa';
import styled from 'styled-components';

import { TotemInput } from 'components/TotemInput';
import { TotemLabel } from 'components/TotemLabel';
import { QuizAudienceSelector } from './QuizAudienceSelector';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { TotemDatePicker } from 'components/TotemDatePicker';
import { FileWithPreview, FileDragAndDrop } from 'components/FileDragAndDrop';
import { TotemImage } from 'components/TotemImage';
import { QuizQuestionForm } from './QuizQuestionForm';

export type QuizQuestion = {
    label: string;
    answerOptions: string[];
    correctAnswerIndex: number;
    imageUrl: string | null;
    image: FileWithPreview | null;
};

export type QuizAudience = {
    organizationIds: string[];
    siteIds: string[];
    userIds: string[];
};

export type QuizFormType = {
    audience: QuizAudience;
    date: Date | null;
    description: string | null;
    imageUrl: string | null;
    image: FileWithPreview | null;
    questions: QuizQuestion[];
    title: string;
};

export const QuizForm = ({
    quizForm,
    setQuizForm,
}: {
    quizForm: QuizFormType;
    setQuizForm: (quizForm: QuizFormType) => void;
}) => {
    async function fileValidationFunction(file: FileWithPreview) {
        return new Promise<FileError[]>((resolve, reject) => {
            const image = new Image();
            image.onload = function () {
                const widthErrors =
                    image.width >= 600 && image.height >= 400
                        ? []
                        : [
                              {
                                  message: `L'image doit avoir une dimension d'au moins 600x400px, votre image fait ${image.width}x${image.height}px`,
                                  code: 'file-invalid-dimension',
                              },
                          ];
                const fileErrors = [...widthErrors];
                resolve(fileErrors);
            };
            image.onerror = reject;
            image.src = file.preview;
        });
    }

    function setQuestion({ question, questionIndex }: { question: QuizQuestion; questionIndex: number }) {
        const newQuestions = [...quizForm.questions];
        newQuestions[questionIndex] = question;

        setQuizForm({ ...quizForm, questions: newQuestions });
    }

    function deleteQuestion({ index }: { index: number }) {
        setQuizForm({
            ...quizForm,
            questions: quizForm.questions.filter((_, questionIndex) => questionIndex !== index),
        });
    }

    return (
        <Container>
            <DragAndDropContainer>
                <FileDragAndDrop
                    multiple={false}
                    hasPadding={false}
                    hasBackground={false}
                    fileTypes={['image/jpeg', 'image/png', 'image/svg+xml', 'image/gif']}
                    fileValidationFunction={fileValidationFunction}
                    onFileDropCallback={(files) => {
                        if (files?.[0]) {
                            setQuizForm({ ...quizForm, image: files[0] });
                        }
                    }}
                >
                    <ImageContainer>
                        <TotemImage
                            src={quizForm.image?.preview || quizForm.imageUrl}
                            alt={quizForm.image?.name || 'Quiz'}
                            size={300}
                        />
                    </ImageContainer>
                </FileDragAndDrop>
            </DragAndDropContainer>
            <TotemInput
                label="Titre"
                sublabel="Titre tel qu'il sera affiché dans le carrousel de la home"
                value={quizForm.title ?? undefined}
                onChange={(title) => setQuizForm({ ...quizForm, title })}
            />
            <TotemInput
                label="Description"
                sublabel="Description"
                value={quizForm.description ?? undefined}
                onChange={(description) => setQuizForm({ ...quizForm, description })}
            />

            <TotemDatePicker
                label="Date du quiz"
                selected={quizForm.date}
                onChange={(date) => setQuizForm({ ...quizForm, date })}
            />
            <QuizAudienceSelector
                audience={quizForm.audience}
                setAudience={(audience) => setQuizForm({ ...quizForm, audience })}
            />

            {quizForm.questions.map((question, questionIndex) => (
                <QuestionContainer key={questionIndex}>
                    <QuestionHeader>
                        <TotemLabel>Question {questionIndex + 1}</TotemLabel>
                        <TotemPrimaryButton onClick={() => deleteQuestion({ index: questionIndex })}>
                            <FaTrash />
                        </TotemPrimaryButton>
                    </QuestionHeader>
                    <QuizQuestionForm
                        question={question}
                        setQuestion={(newQuestion) => setQuestion({ question: newQuestion, questionIndex })}
                    />
                </QuestionContainer>
            ))}

            <ButtonContainer>
                <TotemPrimaryButton
                    onClick={() =>
                        setQuizForm({
                            ...quizForm,
                            questions: [
                                ...quizForm.questions,
                                {
                                    answerOptions: ['Réponse'],
                                    correctAnswerIndex: 0,
                                    label: 'Question',
                                    image: null,
                                    imageUrl: null,
                                },
                            ],
                        })
                    }
                >
                    Ajouter une question
                </TotemPrimaryButton>
            </ButtonContainer>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const DragAndDropContainer = styled.div`
    align-self: flex-start;
`;

const ImageContainer = styled.div`
    cursor: pointer;
    &:hover {
        opacity: 0.4;
    }
`;

const QuestionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    margin-top: 20px;
    border: 1px solid black;
`;

const QuestionHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const ButtonContainer = styled.div`
    margin-top: 20px;
`;

import React from 'react';

import { useQuery } from '@apollo/client';
import styled from 'styled-components';

import { BoxType } from 'data/__generated__';
import { GET_DELIVERY_delivery } from 'data/queries/__generated__/GET_DELIVERY';
import { GET_BOX_OPS_PARAMETERS } from 'data/queries/__generated__/GET_BOX_OPS_PARAMETERS';
import { GET_BOX_OPS_PARAMETERS_QUERY } from 'data/queries/opsParameter';

import { SectionColumn, SectionContainer, ColumnsSectionContainer } from 'components/DetailsView/Section';

import { BoxProductTile } from '../components/BoxProductTile';
import { OrderDeliveryNote } from '../components/OrderDeliveryNote';
import { DetailValue } from 'components/DetailsView/DetailValue';
import { Loader } from 'components/Loader';
import { getExtendedFormattedDatetime } from 'helpers/dateTimes';

function getTranslatedType(type: BoxType | null) {
    switch (type) {
        case BoxType.Bakery:
            return 'Boulangerie';
        case BoxType.Dry:
            return 'Sec';
        case BoxType.Fresh:
            return 'Frais';
        case BoxType.Fruit:
            return 'Fruits';
        default:
            return 'Non précisé';
    }
}

export const BoxesSection = ({ delivery }: { delivery: GET_DELIVERY_delivery }) => {
    const { boxes, orders } = delivery;

    const {
        loading: boxOpsParametersLoading,
        data: boxOpsParametersData,
        error: boxOpsParametersError,
    } = useQuery<GET_BOX_OPS_PARAMETERS>(GET_BOX_OPS_PARAMETERS_QUERY);

    if (boxOpsParametersLoading) {
        return <Loader />;
    }

    if (boxOpsParametersError || !boxOpsParametersData) {
        throw new Error('Une erreur est survenue lors de la récupération des paramètres ops');
    }

    const { boxOpsParameters } = boxOpsParametersData;

    function getVolumeCapacity(type: BoxType | null) {
        if (!type) {
            return '?';
        }

        return boxOpsParameters.volume[type].numericalValue;
    }

    function getWeightCapacity(type: BoxType | null) {
        if (!type) {
            return '?';
        }

        return boxOpsParameters.weight[type].numericalValue;
    }

    return (
        <SectionContainer title={`Caisses (${boxes.length})`} isInitiallyOpen>
            <ColumnsSectionContainer numberOfColumns={1}>
                {orders.length > 0 ? <OrderDeliveryNote delivery={delivery} /> : null}
                <SectionColumn>
                    {[...boxes]
                        .sort((boxA, boxB) => boxA.number - boxB.number)
                        .map((box) => {
                            const {
                                _id: boxId,
                                createdAt,
                                code,
                                label,
                                number,
                                preparedAt,
                                products,
                                type,
                                volumeTaken,
                                weightTaken,
                            } = box;

                            const boxVolume = getVolumeCapacity(type);
                            const boxWeight = getWeightCapacity(type);

                            return (
                                <BoxContainer key={boxId}>
                                    <Title>
                                        Caisse n°{number} - Code {code || 'inexistant'} - Type "
                                        {getTranslatedType(type)}"
                                    </Title>
                                    <Details>
                                        <Row>
                                            <DetailValue label="Label" value={label} />
                                            <DetailValue label="ID" value={boxId} />
                                        </Row>
                                        <Row>
                                            <DetailValue
                                                label="Volume occupé"
                                                value={`${volumeTaken} / ${boxVolume} cm3`}
                                            />
                                            <DetailValue label="Poids" value={`${weightTaken} / ${boxWeight} g`} />
                                        </Row>
                                        <Row>
                                            <DetailValue
                                                label="Créée"
                                                value={getExtendedFormattedDatetime(createdAt)}
                                            />
                                            <DetailValue
                                                label="Préparée"
                                                value={
                                                    preparedAt
                                                        ? getExtendedFormattedDatetime(preparedAt)
                                                        : 'Pas encore préparée'
                                                }
                                            />
                                        </Row>
                                    </Details>
                                    <Products>
                                        {products.map((product) => {
                                            const { _id: productId } = product;
                                            return <BoxProductTile key={`${boxId}${productId}`} boxProduct={product} />;
                                        })}
                                    </Products>
                                </BoxContainer>
                            );
                        })}
                </SectionColumn>
            </ColumnsSectionContainer>
        </SectionContainer>
    );
};

const BoxContainer = styled.div`
    width: 100%;
`;

const Title = styled.span`
    font-size: 22px;
    font-weight: 800;
    color: ${({ theme }) => theme.textColor};
`;

const Details = styled.div`
    border-left: 3px solid ${({ theme }) => theme.lightBorderColor};
    padding-left: 10px;
    margin-top: 10px;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

const Row = styled.div`
    display: flex;
    flex: 1;
    & > :not(:first-child) {
        margin-left: 15px;
    }
`;

const numberOfColumns = 4;
const Products = styled.div`
    display: flex;
    flex-flow: wrap;
    margin-top: 10px;

    @media screen {
        & > :not(:nth-child(${numberOfColumns}n + 1)) {
            margin-left: 10px;
        }

        & > :nth-child(n + ${numberOfColumns + 1}) {
            margin-top: 10px;
        }
    }

    @media print {
        & > :not(:first-child) {
            margin-top: 10px;
        }
    }
`;

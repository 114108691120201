import gql from 'graphql-tag';

export const SEND_NOTIFICATION_TO_ORGANIZATION_MUTATION = gql`
    mutation SEND_NOTIFICATION_TO_ORGANIZATION(
        $organizationId: ID!
        $title: String
        $body: String
        $notificationCategory: NotificationCategory
        $deeplink: String
        $slug: String!
    ) {
        sendNotificationToOrganization(
            organizationId: $organizationId
            title: $title
            body: $body
            notificationCategory: $notificationCategory
            deeplink: $deeplink
            slug: $slug
        )
    }
`;

export const SEND_NOTIFICATION_TO_USERS_MUTATION = gql`
    mutation SEND_NOTIFICATION_TO_USERS(
        $userIds: [ID!]!
        $title: String
        $body: String
        $notificationCategory: NotificationCategory
        $deeplink: String
        $slug: String!
    ) {
        sendNotificationToUsers(
            userIds: $userIds
            title: $title
            body: $body
            notificationCategory: $notificationCategory
            deeplink: $deeplink
            slug: $slug
        )
    }
`;

export const SEND_NOTIFICATION_TO_SITE_MUTATION = gql`
    mutation SEND_NOTIFICATION_TO_SITE(
        $siteId: ID!
        $title: String
        $body: String
        $notificationCategory: NotificationCategory
        $deeplink: String
        $slug: String!
    ) {
        sendNotificationToSite(
            siteId: $siteId
            title: $title
            body: $body
            notificationCategory: $notificationCategory
            deeplink: $deeplink
            slug: $slug
        )
    }
`;

export const PROCESS_NOTIFICATION_TOKEN_MUTATION = gql`
    mutation PROCESS_NOTIFICATION_TOKEN {
        processNotificationTickets
    }
`;

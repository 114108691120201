import React, { useState } from 'react';
import { ImArrowLeft2 } from 'react-icons/im';
import styled from 'styled-components';

import { SplitPanels } from 'components/SplitPanels';
import { GET_MICROSTORE_SITES_WITH_LOCATIONS_INFO_sites } from 'data/queries/__generated__/GET_MICROSTORE_SITES_WITH_LOCATIONS_INFO';
import { GET_ALL_PRODUCTS_getAllProducts } from 'data/queries/__generated__/GET_ALL_PRODUCTS';
import { ColumnSelector } from './ColumnSelector';
import { PlanogramColumn } from './PlanogramColumn';
import { LocationPlanogramFragment } from 'data/fragments/__generated__/LocationPlanogramFragment';

export const PlanogramForm = ({
    locationPlanogramsByDate,
    products,
    site,
}: {
    locationPlanogramsByDate: Record<string, LocationPlanogramFragment[]>;
    products: GET_ALL_PRODUCTS_getAllProducts[];
    site: GET_MICROSTORE_SITES_WITH_LOCATIONS_INFO_sites;
}) => {
    const firstColumnGroup = site.microstoreColumnGroups.find(({ positionInSite }) => positionInSite === 0);
    const firstColumn = firstColumnGroup?.columns?.find(({ positionInColumnGroup }) => positionInColumnGroup === 0);

    const [selectedColumnId, setSelectedColumnId] = useState<string | null>(firstColumn?._id ?? null);
    const [selectedDate, setSelectedDate] = useState<string | null>(null);

    const selectedColumnGroup = site.microstoreColumnGroups.find(({ columns }) =>
        columns.some(({ _id }) => _id === selectedColumnId),
    );
    const selectedColumn = selectedColumnGroup?.columns?.find(({ _id }) => _id === selectedColumnId);

    return (
        <Container>
            <SplitPanels
                displayEnlarge={[false, false]}
                displayShrink={[false, true]}
                haveMargins={[false, false]}
                initialSizes={[5, 95]}
                name="planogram"
            >
                <PanelContainer>
                    <ColumnSelector
                        setSelectedColumnId={setSelectedColumnId}
                        selectedColumnId={selectedColumnId}
                        site={site}
                    />
                </PanelContainer>
                <PanelContainer>
                    {selectedColumnGroup && selectedColumn ? (
                        <PlanogramColumn
                            locationPlanogramsByDate={locationPlanogramsByDate}
                            products={products}
                            selectedDate={selectedDate}
                            setSelectedDate={setSelectedDate}
                            selectedColumn={selectedColumn}
                            selectedColumnGroup={selectedColumnGroup}
                            siteName={site.name}
                        />
                    ) : (
                        <SelectColumnInfo>
                            <ImArrowLeft2 size={30} />
                            <span>VEUILLEZ SÉLECTIONNER UNE COLONNE</span>
                        </SelectColumnInfo>
                    )}
                </PanelContainer>
            </SplitPanels>
        </Container>
    );
};

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
    overflow: hidden;
`;

const PanelContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    overflow: hidden;
`;

const SelectInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    font-weight: 800;
    text-align: center;
`;

const SelectColumnInfo = styled(SelectInfo)`
    flex-direction: row;

    & > :not(:first-child) {
        margin-left: 20px;
    }
`;

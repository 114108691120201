import React from 'react';
import styled from 'styled-components';

import { TotemLabel } from '../TotemLabel';

export const DetailTable = ({
    label,
    columnNames,
    items,
}: {
    label?: string;
    columnNames: string[];
    items: (string | number)[][];
}) => {
    return (
        <Container>
            {label && <TotemLabel>{label}</TotemLabel>}
            <List hasLabel={!!label}>
                <thead>
                    <tr>
                        {columnNames.map((name, index) => {
                            return <HeaderCell key={index}>{name}</HeaderCell>;
                        })}
                    </tr>
                </thead>
                <tbody>
                    {items.map((item, index) => (
                        <tr key={index}>
                            {item.map((field, index) => (
                                <Cell key={String(field) + index}>{field}</Cell>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </List>
        </Container>
    );
};

const HeaderCell = styled.th`
    text-align: left;
    padding: 0 0 0 10px;
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
`;

const Container = styled.div`
    display: inline-flex;
    flex-direction: column;
`;

const List = styled.table<{ hasLabel: boolean }>`
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
    border-spacing: 0;
    border-collapse: collapse;
    margin-top: ${({ hasLabel }: { hasLabel: boolean }) => (hasLabel ? '10px' : 0)};
`;

const Cell = styled.td<{ isBold?: boolean }>`
    text-align: left;
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
    border-spacing: 1px;
    padding: 0 0 0 10px;
    font-weight: ${({ isBold }) => (isBold ? 800 : 500)};
    color: ${({ theme }) => theme.textColor};
`;

import { apolloClient } from '../../../App';
import { WarehouseReturnFragment } from 'data/fragments/__generated__/WarehouseReturnFragment';
import { GET_WAREHOUSE_RETURN_FOR_DATE_QUERY } from 'data/queries/warehouseReturn';
import {
    GET_WAREHOUSE_RETURN_FOR_DATE,
    GET_WAREHOUSE_RETURN_FOR_DATEVariables,
} from 'data/queries/__generated__/GET_WAREHOUSE_RETURN_FOR_DATE';
import { WAREHOUSE_RETURN_FRAGMENT } from 'data/fragments/warehouseReturn';

export function warehouseReturnCreatedOrUpdatedHandler(warehouseReturn: WarehouseReturnFragment) {
    // 1. check if we already have fragment (means updated)
    const warehouseReturnCached = apolloClient.readFragment({
        id: `WarehouseReturn:${warehouseReturn._id}`,
        fragment: WAREHOUSE_RETURN_FRAGMENT,
        fragmentName: 'WarehouseReturnFragment',
    });

    if (warehouseReturnCached) {
        apolloClient.writeFragment({
            id: `WarehouseReturn:${warehouseReturn._id}`,
            fragment: WAREHOUSE_RETURN_FRAGMENT,
            fragmentName: 'WarehouseReturnFragment',
            data: {
                ...warehouseReturn,
            },
        });

        return;
    }

    //2. else it's created and we update every queries that needs it.
    const result = apolloClient.readQuery<GET_WAREHOUSE_RETURN_FOR_DATE, GET_WAREHOUSE_RETURN_FOR_DATEVariables>({
        query: GET_WAREHOUSE_RETURN_FOR_DATE_QUERY,
        variables: {
            date: warehouseReturn.date,
        },
    });
    if (result === null) {
        return;
    }
    const currentWarehouseReturns = result.getWarehouseReturnsForDate;

    // if the current warehouse returns already contains the created one, then we need to update it with the sent values (and keep it at the same index)
    const newWarehouseReturns = currentWarehouseReturns.find(({ _id }) => _id === warehouseReturn._id)
        ? currentWarehouseReturns.map(({ _id }) => (_id === warehouseReturn._id ? warehouseReturn : warehouseReturn))
        : [...currentWarehouseReturns, warehouseReturn];

    apolloClient.writeQuery({
        query: GET_WAREHOUSE_RETURN_FOR_DATE_QUERY,
        data: { getWarehouseReturnsForDate: newWarehouseReturns },
        variables: {
            date: warehouseReturn.date,
        },
    });
}

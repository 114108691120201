import { colors } from 'constants/colors';
import { SupplyOrderState } from 'data/__generated__';

export const SUPPLY_ORDER_STATES_LABELS = {
    [SupplyOrderState.Aborted]: 'Annulé',
    [SupplyOrderState.Checked]: 'Vérifié',
    [SupplyOrderState.Confirmed]: 'Confirmé',
    [SupplyOrderState.Created]: 'Créé',
    [SupplyOrderState.InRack]: 'Rangé',
    [SupplyOrderState.OnHold]: 'En attente',
    [SupplyOrderState.Ordered]: 'Commandé',
    [SupplyOrderState.Ready]: 'À envoyer',
    [SupplyOrderState.Received]: 'Reçu',
};

export const SUPPLY_ORDER_PLURAL_STATES_LABELS = {
    [SupplyOrderState.Aborted]: 'Annulés',
    [SupplyOrderState.Checked]: 'Vérifiés',
    [SupplyOrderState.Confirmed]: 'Confirmés',
    [SupplyOrderState.Created]: 'Créés',
    [SupplyOrderState.InRack]: 'Rangés',
    [SupplyOrderState.OnHold]: 'En attente',
    [SupplyOrderState.Ordered]: 'Commandés',
    [SupplyOrderState.Ready]: 'À envoyer',
    [SupplyOrderState.Received]: 'Reçus',
};

export const getColorsFromState = (
    state: SupplyOrderState,
    weeklyMenusStartDate?: string | null,
    isMerged?: boolean | null,
) => {
    const { black, yellow, orange, emeraldBlue, blue, blandBlue, lightGrey, green, violet, pureWhite } = colors;

    switch (state) {
        case SupplyOrderState.Aborted:
            return { backgroundColor: lightGrey, labelColor: black };
        case SupplyOrderState.Created:
            if (isMerged) {
                return { backgroundColor: emeraldBlue, labelColor: pureWhite };
            }
            if (weeklyMenusStartDate) {
                return { backgroundColor: violet, labelColor: pureWhite };
            }
            return { backgroundColor: lightGrey, labelColor: black };
        case SupplyOrderState.Ready:
            return { backgroundColor: green, labelColor: black };
        case SupplyOrderState.Ordered:
            return { backgroundColor: blue, labelColor: pureWhite };
        case SupplyOrderState.Confirmed:
            return { backgroundColor: blandBlue, labelColor: black };
        case SupplyOrderState.OnHold:
            return { backgroundColor: orange, labelColor: black };
        case SupplyOrderState.Received:
            return { backgroundColor: emeraldBlue, labelColor: black };
        case SupplyOrderState.Checked:
            return { backgroundColor: yellow, labelColor: black };
        case SupplyOrderState.InRack:
            return { backgroundColor: green, labelColor: black };
    }
};

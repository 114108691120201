import React, { FunctionComponent } from 'react';
import { TotemPopup } from 'components/TotemPopup';
import { useDuplicateOrderMutation } from 'data/__generated__';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { BasicInput } from 'components/BasicInput';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { formatDateAsAnniversary } from 'helpers/dateTimes';

type DuplicateOrderPopupProps = {
    orderId: string;
    destinationTotemId: string;
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
    initialFormValues: {
        deliveryTimeWindowStart: string;
        deliveryTimeWindowStop: string;
        isOrderPunctual: boolean;
    };
};

type DuplicateOrderFormValues = {
    deliveryDate: string;
    deliveryTimeWindowStart: string;
    deliveryTimeWindowStop: string;
    isOrderPunctual: boolean;
};

export const DuplicateOrderPopup: FunctionComponent<DuplicateOrderPopupProps> = ({
    orderId,
    isOpen,
    setIsOpen,
    destinationTotemId,
    initialFormValues,
}) => {
    const [duplicateOrder] = useDuplicateOrderMutation({
        onCompleted: (data) => {
            if (!data.duplicateOrder) {
                return;
            }
            setIsOpen(false);
            window.open(`${data.duplicateOrder._id}`, '_blank', 'noopener,noreferrer');
        },
    });

    const handleDuplicateOrder = async ({
        deliveryDate,
        deliveryTimeWindowStart,
        deliveryTimeWindowStop,
        isOrderPunctual,
    }: DuplicateOrderFormValues) => {
        duplicateOrder({
            variables: {
                orderToDuplicateId: orderId,
                deliveryDate: formatDateAsAnniversary({ dateTime: deliveryDate }),
                deliveryTimeWindow: {
                    start: deliveryTimeWindowStart,
                    stop: deliveryTimeWindowStop,
                },
                isOrderPunctual,
                destinationTotemId,
            },
        });
    };

    const { register, handleSubmit } = useForm<DuplicateOrderFormValues>({
        defaultValues: {
            deliveryTimeWindowStart: initialFormValues.deliveryTimeWindowStart,
            deliveryTimeWindowStop: initialFormValues.deliveryTimeWindowStop,
            isOrderPunctual: initialFormValues.isOrderPunctual,
        },
    });

    return (
        <TotemPopup isOpen={isOpen} setIsOpen={setIsOpen} title="Dupliquer une offre">
            <Form onSubmit={handleSubmit(handleDuplicateOrder)}>
                <InputContainer>
                    <span>Date de livraison</span>
                    <BasicInput type="date" {...register('deliveryDate', { required: true })} />
                </InputContainer>
                <InputContainer>
                    <span>Créneau de livraison</span>
                    <BasicInput type="time" {...register('deliveryTimeWindowStart', { required: true })} />
                    <BasicInput type="time" {...register('deliveryTimeWindowStop', { required: true })} />
                </InputContainer>
                <CheckboxContainer>
                    <BasicInput type="checkbox" {...register('isOrderPunctual')} />
                    <span>Commande ponctuelle</span>
                </CheckboxContainer>
                <TotemPrimaryButton type="submit">Ok</TotemPrimaryButton>
            </Form>
        </TotemPopup>
    );
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const CheckboxContainer = styled.div`
    display: flex;
    gap: 4px;
`;

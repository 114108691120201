import React from 'react';

import { CommonStatusTag } from 'components/CommonStatusTag';

import { colors } from 'constants/colors';

import { TransactionState } from 'data/__generated__';
import { TRANSACTIONS_STATES_LABELS } from '../constants/states';

const getColorsFromState = (state: TransactionState) => {
    switch (state) {
        case TransactionState.Canceled:
            return { backgroundColor: colors.darkGrey, labelColor: colors.pureWhite };
        case TransactionState.Failed:
            return { backgroundColor: colors.red, labelColor: colors.pureWhite };
        case TransactionState.Paid:
            return { backgroundColor: colors.green, labelColor: colors.black };
        case TransactionState.Refunded:
            return { backgroundColor: colors.blue, labelColor: colors.pureWhite };
        case TransactionState.Pending:
            return { backgroundColor: colors.yellow, labelColor: colors.black };
        case TransactionState.Processing:
            return { backgroundColor: colors.orange, labelColor: colors.black };
    }
};

export const StatusTag = ({ state }: { state: TransactionState }) => {
    const { backgroundColor, labelColor } = getColorsFromState(state);
    return (
        <CommonStatusTag
            backgroundColor={backgroundColor}
            labelColor={labelColor}
            label={TRANSACTIONS_STATES_LABELS[state]}
        />
    );
};

import gql from 'graphql-tag';

export const SUPPLIER_PARTNERSHIP_FRAGMENT = gql`
    fragment SupplierPartnershipFragment on SupplierPartnership {
        _id
        budget
        createdAt
        endDate
        name
        productIds
        startDate
        updatedAt
    }
`;

import gql from 'graphql-tag';
import { POLL_FRAGMENT } from '../fragments/poll';

export const CREATE_POLL_MUTATION = gql`
    mutation CreatePoll($input: PollInput!) {
        createPoll(input: $input) {
            success
            errors
            poll {
                ...PollFragment
            }
        }
    }
    ${POLL_FRAGMENT}
`;

export const UPDATE_POLL_MUTATION = gql`
    mutation UpdatePoll($pollId: ID!, $input: PollInput!) {
        updatePoll(pollId: $pollId, input: $input) {
            success
            errors
            poll {
                ...PollFragment
            }
        }
    }
    ${POLL_FRAGMENT}
`;

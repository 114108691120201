import React, { useEffect } from 'react';

import { useQuery } from '@apollo/client';
import { useForm, FormProvider } from 'react-hook-form';
import { FaSave } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { PAGES } from 'constants/pages';

import { RitualRepetition, useGetDetailedRitualQuery, useUpdateRitualMutation } from 'data/__generated__';

import { Header, HeaderTitle } from 'components/Header';
import { Loader, LoaderModeType } from 'components/Loader';
import { PageTitle } from 'components/PageTitle';

import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { RitualFormContent } from './components/RitualFormContent';
import { StatesSection } from 'pages/Rituals/components/StatesSection';
import { TypeSection } from './components/TypeSection';
import { GET_RITUAL_GAME_FOR_RITUAL_QUERY } from 'data/queries/ritualGame';
import { GET_RITUAL_GAME_FOR_RITUAL } from 'data/queries/__generated__/GET_RITUAL_GAME_FOR_RITUAL';

type FormData = {
    activeDate: string;
    activeWeekdays: boolean[];
    associatedDiscount: number;
    expiringProductsOnly: boolean;
    image: File;
    messagesSetId: string | null;
    name: string;
    repetition: RitualRepetition;
    requiresParticipation: boolean;
    restrictedBadgeIds: string[];
    restrictedCategoryIds: string[];
    restrictedOrganizationIds: string[];
    restrictedProductIds: string[];
    restrictedSiteIds: string[];
    restrictedSubcategoryIds: string[];
    restrictedTagIds: string[];
    restrictedUserIds: string;
    timeWindow: {
        start: string;
        end: string;
    };
    supplierPartnershipId: string | null;
};

type ParamTypes = {
    ritualId: string;
};

export const RitualDetails = () => {
    const { ritualId = '' } = useParams<ParamTypes>();

    const {
        loading: ritualLoading,
        data: ritualData,
        error: ritualError,
    } = useGetDetailedRitualQuery({
        variables: { ritualId },
    });

    const {
        loading: ritualGameLoading,
        data: ritualGameData,
        error: ritualGameError,
        refetch,
    } = useQuery<GET_RITUAL_GAME_FOR_RITUAL>(GET_RITUAL_GAME_FOR_RITUAL_QUERY, {
        variables: { ritualId },
    });

    const [updateRitual, { loading: updateRitualLoading }] = useUpdateRitualMutation();

    useEffect(() => {
        if (ritualGameData?.getRitualGameForRitual?.type) {
            refetch();
        }
    }, [ritualGameData?.getRitualGameForRitual?.type, refetch]);

    const methods = useForm<FormData>();
    const {
        handleSubmit,
        formState: { isDirty },
        reset,
    } = methods;

    if (ritualLoading || ritualGameLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (!ritualData || ritualError || !ritualGameData || ritualGameError) {
        throw new Error('Une erreur est survenue lors de la récupération du rituel');
    }

    const onSubmit = handleSubmit(async (fields) => {
        const { activeDate, activeWeekdays, repetition } = fields;
        const updateInput = {
            ...fields,
            activeDate: repetition === RitualRepetition.Punctual ? activeDate : null,
            activeWeekdays: repetition === RitualRepetition.Weekly ? activeWeekdays : null,
            restrictedUserIds: fields.restrictedUserIds ? fields.restrictedUserIds.trim().split(',') : [],
        };
        const { data } = await updateRitual({
            variables: {
                ritualId,
                updateInput,
            },
        });

        if (data) {
            const {
                updateRitual: { updatedRitual, success, errors },
            } = data;
            if (success && updatedRitual) {
                toast.success('Le rituel a bien été mis à jour !');
                reset(fields);
            } else {
                if (errors) {
                    errors.forEach((error) => {
                        toast.error(error, { autoClose: false });
                    });

                    if (errors.length > 1) {
                        toast.info('Cliquez pour fermer toutes les notifications', {
                            autoClose: false,
                            onClick: () => toast.dismiss(),
                        });
                    }
                } else {
                    throw Error("Une erreur inconnue s'est produite");
                }
            }
        } else {
            throw Error("Une erreur inconnue s'est produite");
        }
    });

    const { detailedRitual } = ritualData;
    const { getRitualGameForRitual: ritualGame } = ritualGameData;

    if (!detailedRitual) {
        throw new Error('Une erreur est survenue lors de la récupération du rituel');
    }

    return (
        <Container>
            <FormProvider {...methods}>
                <Form onSubmit={onSubmit}>
                    <Header>
                        <HeaderTitle>
                            <PageTitle page={PAGES.ritualDetails} />
                        </HeaderTitle>
                        <CTAsContainer>
                            <TotemPrimaryButton type="submit" minWidth="78px" disabled={!isDirty}>
                                {updateRitualLoading ? (
                                    <Loader size="18px" mode={LoaderModeType.Spin} />
                                ) : (
                                    <>
                                        <FaSave size={13} />
                                        <SaveLabel>Mettre à jour</SaveLabel>
                                    </>
                                )}
                            </TotemPrimaryButton>
                            <Link to="/rituals">
                                <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                            </Link>
                        </CTAsContainer>
                    </Header>
                    <Content>
                        <ScrollableContent>
                            <RitualFormContent ritual={detailedRitual} />
                            <StatesSection ritual={detailedRitual} isDirty={isDirty} />
                            <TypeSection ritual={detailedRitual} ritualGame={ritualGame} isDirty={isDirty} />
                        </ScrollableContent>
                    </Content>
                </Form>
            </FormProvider>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex: 1;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
`;

const CTAsContainer = styled.div`
    display: flex;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const SaveLabel = styled.span`
    margin-left: 5px;
`;

const Content = styled.div`
    flex: 1;
    overflow: hidden;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const ScrollableContent = styled.div`
    padding: 15px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
`;

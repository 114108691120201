import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import { FcPlus } from 'react-icons/fc';
import styled from 'styled-components';

import { GET_RITUAL_TEMPLATES_QUERY } from 'data/queries/ritualTemplates';
import { GET_RITUAL_TEMPLATES } from 'data/queries/__generated__/GET_RITUAL_TEMPLATES';

import { Loader } from 'components/Loader';
import { colors } from 'constants/colors';
import { AddRitualTemplateModal } from './AddRitualTemplateModal';

export const RitualTemplatesList = () => {
    const [isRitualAdditionModalOpen, setIsRitualAdditionModalOpen] = useState(false);

    const {
        data: ritualTemplatesData,
        loading: ritualTemplatesLoading,
        error: ritualTemplatesError,
    } = useQuery<GET_RITUAL_TEMPLATES>(GET_RITUAL_TEMPLATES_QUERY);

    if (ritualTemplatesLoading) {
        return <Loader />;
    }

    if (ritualTemplatesError || !ritualTemplatesData) {
        throw new Error('An error occurred');
    }

    const { ritualTemplates } = ritualTemplatesData;

    return (
        <TilesList>
            <AddRitualTemplateTile>
                {isRitualAdditionModalOpen ? (
                    <AddRitualTemplateModal onClose={() => setIsRitualAdditionModalOpen(false)} />
                ) : null}
                <ClickableArea onClick={() => setIsRitualAdditionModalOpen(true)}>
                    <FcPlus size={50} />
                </ClickableArea>
            </AddRitualTemplateTile>
            {ritualTemplates.map((ritualTemplate) => {
                const { _id, imageUrl, name } = ritualTemplate;
                return (
                    <RitualTemplateTile key={_id}>
                        <ImageContainer>
                            <img style={{ objectFit: 'cover' }} src={imageUrl} alt={name} width="100%" height="100%" />
                            <Gradient />
                        </ImageContainer>
                        <TileFooter>
                            <NameContainer>
                                <Name>{name}</Name>
                            </NameContainer>
                        </TileFooter>
                    </RitualTemplateTile>
                );
            })}
        </TilesList>
    );
};

const IMAGES_PER_ROW = 5;

const TilesList = styled.div`
    display: flex;
    justify-content: stretch;
    align-items: flex-start;
    flex-wrap: wrap;
    flex: 1;

    & > :not(:nth-child(5n + 1)) {
        margin-left: 10px;
    }

    & > :nth-child(n + 6) {
        margin-top: 10px;
    }
`;

const AddRitualTemplateTile = styled.div`
    display: flex;
    align-self: stretch;
    width: calc(20% - ${((IMAGES_PER_ROW - 1) / IMAGES_PER_ROW) * 10}px);
    border: 4px solid ${({ theme }) => theme.ctaPrimaryColor};
    border-radius: 5px;
`;

const ClickableArea = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
`;

const ImageContainer = styled.div`
    position: relative;
    width: 100%;
    aspect-ratio: 1;
`;

const Gradient = styled.div`
    position: absolute;
    width: 100%;
    height: 33%;
    bottom: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 62.5%, #ffffff 100%);
`;

const RitualTemplateTile = styled.div`
    display: flex;
    flex-direction: column;
    align-self: stretch;
    width: calc(20% - 8px);
    overflow: hidden;
    border-radius: 5px;
`;

const TileFooter = styled.div`
    padding: 0px 15px 20px;
    flex: 1;
    background-color: ${colors.pureWhite};
`;

const NameContainer = styled.div`
    margin-top: -10px;
    flex: 1;
    transform: rotate(-3deg);
`;

const Name = styled.span`
    font-family: Totem;
    font-size: 26px;
    line-height: 25px;
    color: ${colors.pink};
`;

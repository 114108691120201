import React from 'react';

import { formatPrice } from '@totem/constants';
import { getExtendedFormattedDatetime } from 'helpers/dateTimes';

import { StatusTag } from 'pages/Transactions/components/StatusTag';

import { GET_USER_userFromAdmin_getLastTransactions } from 'data/queries/__generated__/GET_USER';

import { FieldToDisplay, ListView } from 'components/ListView';

type FormattedLastTransactionType = {
    _id: string;
    createdAt: string;
    siteName: string;
    state: JSX.Element;
    productName: string;
    price: string;
};

const LAST_TRANSACTION_FIELDS_TO_DISPLAY: FieldToDisplay<FormattedLastTransactionType>[] = [
    {
        fieldName: 'createdAt',
        label: 'Date',
    },
    {
        fieldName: 'state',
        label: 'Statut',
    },
    {
        fieldName: 'siteName',
        label: 'Site',
    },
    {
        fieldName: 'productName',
        label: 'Produit',
    },
    {
        fieldName: 'price',
        label: 'Montant',
    },
];

export function LastTransactions({
    lastTransactions,
}: {
    lastTransactions: GET_USER_userFromAdmin_getLastTransactions[];
}) {
    const sortedTransactions = [...lastTransactions].sort(
        (transactionA, transactionB) => transactionB.createdAt - transactionA.createdAt,
    );

    const formattedTransactions: FormattedLastTransactionType[] = sortedTransactions.map(
        ({ _id, createdAt, productName, siteName, priceHT, newState, tva }) => ({
            _id,
            siteName,
            state: <StatusTag state={newState} />,
            productName,
            price: formatPrice(priceHT * (1 + tva)),
            createdAt: getExtendedFormattedDatetime(createdAt),
        }),
    );
    return (
        <ListView<FormattedLastTransactionType>
            fieldsToDisplay={LAST_TRANSACTION_FIELDS_TO_DISPLAY}
            items={formattedTransactions}
            keyExtractor={(item) => item._id}
            linkBasePath="/transaction/"
        />
    );
}

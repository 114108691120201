import gql from 'graphql-tag';

import { EXPIRY_DATE_RECORD_FRAGMENT } from 'data/fragments/expiryDateRecord';

export const GET_EXPIRY_DATE_RECORDS_FOR_SITE_QUERY = gql`
    query GET_EXPIRY_DATE_RECORDS_FOR_SITE($siteId: ID!) {
        getExpiryDateRecordsForSiteQuery(siteId: $siteId) {
            ...ExpiryDateRecordFragment
        }
    }
    ${EXPIRY_DATE_RECORD_FRAGMENT}
`;

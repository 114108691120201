import {
    PRODUCT_ARRANGEMENT_FRAGMENT,
    PRODUCT_ARRANGEMENT_WITH_PRODUCTS_FRAGMENT,
} from 'data/fragments/productArrangement';
import gql from 'graphql-tag';

export const CREATE_PRODUCT_ARRANGEMENT_MUTATION = gql`
    mutation CREATE_PRODUCT_ARRANGEMENT($name: String!) {
        createProductArrangementMutation(name: $name) {
            newProductArrangement {
                ...ProductArrangementFragment
            }
            errors
        }
    }
    ${PRODUCT_ARRANGEMENT_FRAGMENT}
`;

export const ARCHIVE_PRODUCT_ARRANGEMENTS_MUTATION = gql`
    mutation ARCHIVE_PRODUCT_ARRANGEMENTS($productArrangementIds: [String!]!) {
        archiveProductArrangementsMutation(productArrangementIds: $productArrangementIds) {
            archivedProductArrangementIds
            errors
        }
    }
`;

export const UPDATE_PRODUCT_ARRANGEMENT_MUTATION = gql`
    mutation UPDATE_PRODUCT_ARRANGEMENT($productArrangement: ProductArrangementUpdateInput!) {
        updateProductArrangementMutation(productArrangement: $productArrangement) {
            newProductArrangement {
                ...ProductArrangementWithProductsFragment
            }
            errors
        }
    }
    ${PRODUCT_ARRANGEMENT_WITH_PRODUCTS_FRAGMENT}
`;

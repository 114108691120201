import React from 'react';

import styled from 'styled-components';

import { colors } from 'constants/colors';

import { GET_LOCATIONS_locations } from 'data/queries/__generated__/GET_LOCATIONS';

import { getColorsFromState } from 'pages/Locations/components/StatusTag';
import { DetailLink } from 'components/DetailsView';
import { TotemCheckbox } from 'components/TotemCheckbox';

export const LocationTrelloItem = ({
    location,
    isSelected,
    toggleSelection,
}: {
    location: GET_LOCATIONS_locations;
    isSelected: boolean;
    toggleSelection: () => void;
}) => {
    const { state, datamatrix, productId, productName, index } = location;

    const { backgroundColor, labelColor } = getColorsFromState(state);
    return (
        <ItemContent>
            <ItemHeader onClick={toggleSelection} backgroundColor={backgroundColor} labelColor={labelColor}>
                <HeaderTitle>{datamatrix}</HeaderTitle>
                <TotemCheckbox checked={isSelected} onChange={toggleSelection} />
            </ItemHeader>
            <ItemBody>
                <Details>
                    <Detail>
                        <DetailLabel>Index de préparation</DetailLabel>
                        <DetailValue>{index}</DetailValue>
                    </Detail>
                    <Detail>
                        <DetailLabel>Produit</DetailLabel>
                        {productId ? (
                            <DetailLink
                                path="product"
                                name={productName || productId}
                                value={productId}
                                fontSize="14px"
                            />
                        ) : (
                            <AccentValue>Emplacement libre</AccentValue>
                        )}
                    </Detail>
                </Details>
            </ItemBody>
        </ItemContent>
    );
};

const ItemContent = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: ${({ theme }) => theme.borderRadius};
    border: 1px solid ${colors.pantinGrey};
    background-color: ${({ theme }) => theme.cardBackgroundColor};
    color: ${({ theme }) => theme.textColor};
    cursor: default;
`;

const ItemHeader = styled.div<{ backgroundColor: string; labelColor: string }>`
    border-bottom: 1px solid ${colors.pantinGrey};
    background-color: ${({ backgroundColor }) => backgroundColor};
    border-radius: ${({ theme }) => theme.borderRadius} ${({ theme }) => theme.borderRadius} 0 0;
    color: ${({ labelColor }) => labelColor};
    padding: 2px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`;

const HeaderTitle = styled.div`
    display: flex;
    margin-left: 5px;
    font-weight: 800;
    align-items: center;
`;

const ItemBody = styled.div`
    padding: 5px 10px;
`;

const Details = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
`;

const Detail = styled.div`
    display: flex;
    flex: 1;
    white-space: nowrap;
    justify-content: space-between;
    color: 'inherit';

    &:not(:first-child) {
        margin-top: 5px;
    }
`;

const DetailLabel = styled.span`
    font-size: 14px;
    white-space: nowrap;
    margin-right: 10px;
`;

const DetailValue = styled.span`
    font-size: 14px;
    font-weight: 800;
    margin-left: 5px;
    text-align: right;
`;

const AccentValue = styled.span`
    font-size: 14px;
    font-weight: 800;
    margin-left: 5px;
    text-align: right;
    color: ${colors.blue};
`;

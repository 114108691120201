import { apolloClient } from '../../../App';
import { TotemClosedDayFragment } from 'data/fragments/__generated__/TotemClosedDayFragment';
import { GET_ALL_REGULAR_TOTEM_CLOSED_DAY_QUERY } from 'data/queries/totemClosedDay';
import { GET_ALL_REGULAR_TOTEM_CLOSED_DAY } from 'data/queries/__generated__/GET_ALL_REGULAR_TOTEM_CLOSED_DAY';

export const totemClosedDayCreatedHandler = (createdTotemClosedDay: TotemClosedDayFragment) => {
    const result = apolloClient.readQuery<GET_ALL_REGULAR_TOTEM_CLOSED_DAY>({
        query: GET_ALL_REGULAR_TOTEM_CLOSED_DAY_QUERY,
    });
    if (result === null) {
        return;
    }
    const currentTotemClosedDays = result.getAllRegularTotemClosedDay;

    // if the current totem closed days already contains the created one, then we need to update it with the sent values (and keep it at the same index)
    const newTotemClosedDays = currentTotemClosedDays.find(({ _id }) => _id === createdTotemClosedDay._id)
        ? currentTotemClosedDays.map((totemClosedDay) =>
              totemClosedDay._id === createdTotemClosedDay._id ? createdTotemClosedDay : totemClosedDay,
          )
        : [...currentTotemClosedDays, createdTotemClosedDay];

    apolloClient.writeQuery<GET_ALL_REGULAR_TOTEM_CLOSED_DAY>({
        query: GET_ALL_REGULAR_TOTEM_CLOSED_DAY_QUERY,
        data: { getAllRegularTotemClosedDay: newTotemClosedDays },
    });
};

export const totemClosedDayRemovedHandler = (removedTotemClosedDayId: string) => {
    const result = apolloClient.readQuery<GET_ALL_REGULAR_TOTEM_CLOSED_DAY>({
        query: GET_ALL_REGULAR_TOTEM_CLOSED_DAY_QUERY,
    });
    if (result === null) {
        return;
    }
    const currentTotemClosedDays = result.getAllRegularTotemClosedDay;

    // if the current totem closed days already contains the created one, then we need to update it with the sent values (and keep it at the same index)
    const newTotemClosedDays = currentTotemClosedDays.filter(
        (totemClosedDay) => totemClosedDay._id !== removedTotemClosedDayId,
    );

    apolloClient.writeQuery<GET_ALL_REGULAR_TOTEM_CLOSED_DAY>({
        query: GET_ALL_REGULAR_TOTEM_CLOSED_DAY_QUERY,
        data: { getAllRegularTotemClosedDay: newTotemClosedDays },
    });
};

import { apolloClient } from '../../../App';
import { SupplierPartnershipFragment } from 'data/fragments/__generated__/SupplierPartnershipFragment';
import { GET_SUPPLIER_PARTNERSHIPS_QUERY } from 'data/queries/supplierPartnership';
import {
    GET_SUPPLIER_PARTNERSHIPS,
    GET_SUPPLIER_PARTNERSHIPS_supplierPartnerships,
} from 'data/queries/__generated__/GET_SUPPLIER_PARTNERSHIPS';

export const supplierPartnershipsUpdatedOrCreatedHandler = (
    supplierPartnershipsUpdatedOrCreated: SupplierPartnershipFragment[],
) => {
    let currentSupplierPartnerships = [] as GET_SUPPLIER_PARTNERSHIPS_supplierPartnerships[];

    const result = apolloClient.readQuery<GET_SUPPLIER_PARTNERSHIPS>({ query: GET_SUPPLIER_PARTNERSHIPS_QUERY });
    if (!result) {
        return;
    }
    currentSupplierPartnerships = result.supplierPartnerships;

    const updatedOrCreatedSupplierPartnershipIds = supplierPartnershipsUpdatedOrCreated.map(({ _id }) => _id);
    const nonUpdatedOrCreatedSupplierPartnerships = currentSupplierPartnerships.filter(
        ({ _id }) => !updatedOrCreatedSupplierPartnershipIds.includes(_id),
    );
    const newSupplierPartnerships = [
        ...supplierPartnershipsUpdatedOrCreated,
        ...nonUpdatedOrCreatedSupplierPartnerships,
    ];

    apolloClient.writeQuery({
        query: GET_SUPPLIER_PARTNERSHIPS_QUERY,
        data: { supplierPartnerships: newSupplierPartnerships },
    });
};

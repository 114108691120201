import React, { useEffect, useState } from 'react';
import { generatePath, Link, useNavigate } from 'react-router-dom';
import { ImArrowLeft2, ImArrowRight2, ImShrink } from 'react-icons/im';
import { FaPlus } from 'react-icons/fa';
import styled from 'styled-components';

import { GetMicrostoreSiteQuery } from 'data/__generated__';
import {
    GET_MICROSTORE_SITES_microstoreSitesWithColumnGroups,
    GET_MICROSTORE_SITES_microstoreSitesWithColumnGroups_microstoreColumnGroups,
    GET_MICROSTORE_SITES_microstoreSitesWithColumnGroups_microstoreColumnGroups_columns,
} from 'data/queries/__generated__/GET_MICROSTORE_SITES';

import { PAGES } from 'constants/pages';

import { TotemSelect } from 'components/TotemSelect';
import { Loader } from 'components/Loader';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { DeleteMicrostoreColumnPopup } from '../Popups/DeleteMicrostoreColumnPopup';
import { CreateMicrostoreColumnPopup } from '../Popups/CreateMicrostoreColumnPopup';
import { SiteUpdateForm } from 'pages/MicrostoreColumns/MicrostoreColumnDetails/SiteUpdateForm';
import { CreateMicrostoreColumnGroupPopup } from 'pages/MicrostoreColumns/Popups/CreateMicrostoreColumnGroupPopup';
import { DeleteMicrostoreColumnGroupPopup } from 'pages/MicrostoreColumns/Popups/DeleteMicrostoreColumnGroupPopup';
import { MicrostoreColumnGroupEditor } from 'pages/MicrostoreColumns/MicrostoreColumnDetails/MicrostoreColumnGroupEditor';
import { EditColumnPopup } from 'pages/MicrostoreColumns/Popups/EditColumnPopup';
import { ReplaceMicrostoreColumnPopup } from '../Popups/ReplaceMicrostoreColumnPopup';
import { MoveColumnPopup } from '../Popups/MoveColumnPopup';

export const MicrostoreEditor = ({
    changeSelectedColumnId,
    currentSite,
    isOpen,
    microstoreSites,
    setIsOpen,
}: {
    changeSelectedColumnId: (value: string | null) => void;
    currentSite: GetMicrostoreSiteQuery['siteWithLocationInfo'];
    isOpen: boolean;
    microstoreSites: GET_MICROSTORE_SITES_microstoreSitesWithColumnGroups[] | null;
    setIsOpen: (value: boolean) => void;
}) => {
    const navigate = useNavigate();

    const [displayedSiteIndex, setDisplayedSiteIndex] = useState<number | null>(null);

    useEffect(() => {
        if (displayedSiteIndex === null) {
            setDisplayedSiteIndex(microstoreSites?.findIndex((site) => site._id === currentSite._id) ?? null);
        }
    }, [currentSite, displayedSiteIndex, microstoreSites, setDisplayedSiteIndex]);

    const [isCreateMicrostoreColumnPopupOpen, setIsCreateMicrostoreColumnPopupOpen] = useState<boolean>(false);
    const [isDeleteMicrostoreColumnPopupOpen, setIsDeleteMicrostoreColumnPopupOpen] = useState<boolean>(false);

    const [isCreateMicrostoreColumnGroupPopupOpen, setIsCreateMicrostoreColumnGroupPopupOpen] =
        useState<boolean>(false);
    const [isDeleteMicrostoreColumnGroupPopupOpen, setIsDeleteMicrostoreColumnGroupPopupOpen] =
        useState<boolean>(false);

    const [microstoreColumnGroupToAddColumn, setMicrostoreColumnGroupToAddColumn] =
        useState<GET_MICROSTORE_SITES_microstoreSitesWithColumnGroups_microstoreColumnGroups | null>(null);

    const [microstoreColumnToEdit, setMicrostoreColumnToEdit] =
        useState<GET_MICROSTORE_SITES_microstoreSitesWithColumnGroups_microstoreColumnGroups_columns | null>(null);

    const [microstoreColumnToMove, setMicrostoreColumnToMove] =
        useState<GET_MICROSTORE_SITES_microstoreSitesWithColumnGroups_microstoreColumnGroups_columns | null>(null);

    const [microstoreColumnToReplace, setMicrostoreColumnToReplace] =
        useState<GET_MICROSTORE_SITES_microstoreSitesWithColumnGroups_microstoreColumnGroups_columns | null>(null);

    const [microstoreColumnToDelete, setMicrostoreColumnToDelete] =
        useState<GET_MICROSTORE_SITES_microstoreSitesWithColumnGroups_microstoreColumnGroups_columns | null>(null);

    const [microstoreColumnGroupToDelete, setMicrostoreColumnGroupToDelete] =
        useState<GET_MICROSTORE_SITES_microstoreSitesWithColumnGroups_microstoreColumnGroups | null>(null);

    if (!microstoreSites || displayedSiteIndex === null) {
        return (
            <Container isOpen={isOpen}>
                <Content>
                    <HeaderContainer>
                        <SelectContainer />
                        <CloseButton size={20} onClick={() => setIsOpen(false)} />
                    </HeaderContainer>
                    <Loader />
                </Content>
            </Container>
        );
    }

    const displayedSite = microstoreSites[displayedSiteIndex];
    const sitesOptions = microstoreSites
        .map((site, index) => ({ value: index, label: site.name }))
        .sort((siteA, siteB) => siteA.label.toLowerCase().localeCompare(siteB.label.toLowerCase()));

    return (
        <Container isOpen={isOpen}>
            <Content>
                <HeaderContainer>
                    <SelectContainer>
                        <TotemSelect
                            placeholder="Sélectionner un site (recherche par nom ou par _id)"
                            value={sitesOptions.find((siteOption) => siteOption.value === displayedSiteIndex)}
                            options={sitesOptions}
                            onChange={(option) => {
                                if (option) {
                                    setDisplayedSiteIndex(option.value);
                                }
                            }}
                        />
                    </SelectContainer>
                    <CloseButton size={20} onClick={() => setIsOpen(false)} />
                </HeaderContainer>
                <ColumnsGroupContainer>
                    {displayedSite.microstoreColumnGroups.map((microstoreColumnGroup) => {
                        return (
                            <MicrostoreColumnGroupEditor
                                key={microstoreColumnGroup._id}
                                microstoreColumnGroup={microstoreColumnGroup}
                                addColumn={() => {
                                    changeSelectedColumnId(null);
                                    setMicrostoreColumnGroupToAddColumn(microstoreColumnGroup);
                                    setIsCreateMicrostoreColumnPopupOpen(true);
                                }}
                                editColumn={(
                                    microstoreColumn: GET_MICROSTORE_SITES_microstoreSitesWithColumnGroups_microstoreColumnGroups_columns,
                                ) => {
                                    changeSelectedColumnId(null);
                                    setMicrostoreColumnToEdit(microstoreColumn);
                                }}
                                replaceColumn={(
                                    microstoreColumn: GET_MICROSTORE_SITES_microstoreSitesWithColumnGroups_microstoreColumnGroups_columns,
                                ) => {
                                    changeSelectedColumnId(null);
                                    setMicrostoreColumnToReplace(microstoreColumn);
                                }}
                                deleteColumnGroup={() => {
                                    changeSelectedColumnId(null);
                                    setMicrostoreColumnGroupToDelete(microstoreColumnGroup);
                                    setIsDeleteMicrostoreColumnGroupPopupOpen(true);
                                }}
                                deleteColumn={(
                                    microstoreColumn: GET_MICROSTORE_SITES_microstoreSitesWithColumnGroups_microstoreColumnGroups_columns,
                                ) => {
                                    changeSelectedColumnId(null);
                                    setMicrostoreColumnToDelete(microstoreColumn);
                                    setIsDeleteMicrostoreColumnPopupOpen(true);
                                }}
                                moveColumn={(
                                    microstoreColumn: GET_MICROSTORE_SITES_microstoreSitesWithColumnGroups_microstoreColumnGroups_columns,
                                ) => {
                                    changeSelectedColumnId(null);
                                    setMicrostoreColumnToMove(microstoreColumn);
                                }}
                            />
                        );
                    })}

                    <AddColumnGroupButtonContainer>
                        <AddButton
                            type="button"
                            onClick={() => {
                                changeSelectedColumnId(null);
                                setIsCreateMicrostoreColumnGroupPopupOpen(true);
                            }}
                        >
                            <FaPlus size={20} />
                        </AddButton>
                    </AddColumnGroupButtonContainer>
                </ColumnsGroupContainer>
                <div>
                    <SiteUpdateFormContainer>
                        <SiteUpdateForm
                            initialReassortmentStockTargets={displayedSite.reassortmentStockTargets}
                            initialReassortmentOnboardingStockTargets={displayedSite.reassortmentOnboardingStockTargets}
                            initialReassortmentStockTargetRatios={displayedSite.reassortmentStockTargetRatios}
                            siteId={displayedSite._id}
                        />
                    </SiteUpdateFormContainer>
                    <SiteSelectionContainer>
                        <SiteInfoContainer>
                            <Link to={`/microstore-snapshot/${displayedSite._id}`}>
                                <TotemPrimaryButton type="button">Historique</TotemPrimaryButton>
                            </Link>
                            <IconContainer>
                                <ImArrowLeft2
                                    size={40}
                                    onClick={() =>
                                        setDisplayedSiteIndex(
                                            displayedSiteIndex - 1 < 0
                                                ? microstoreSites.length - 1
                                                : displayedSiteIndex - 1,
                                        )
                                    }
                                />
                            </IconContainer>
                            <IconContainer>
                                <ImArrowRight2
                                    size={40}
                                    onClick={() =>
                                        setDisplayedSiteIndex(
                                            displayedSiteIndex + 1 > microstoreSites.length - 1
                                                ? 0
                                                : displayedSiteIndex + 1,
                                        )
                                    }
                                />
                            </IconContainer>
                            <SiteName>{displayedSite.name}</SiteName>
                            {displayedSite._id !== currentSite._id ? (
                                <TotemPrimaryButton
                                    type="button"
                                    onClick={() => {
                                        setIsOpen(false);
                                        changeSelectedColumnId(null);
                                        navigate(
                                            generatePath(PAGES.microstoreColumnDetails.url, {
                                                siteId: displayedSite._id,
                                            }),
                                        );
                                    }}
                                >
                                    Sélectionner
                                </TotemPrimaryButton>
                            ) : null}
                        </SiteInfoContainer>
                    </SiteSelectionContainer>
                </div>
            </Content>

            {microstoreColumnGroupToAddColumn ? (
                <CreateMicrostoreColumnPopup
                    isOpen={isCreateMicrostoreColumnPopupOpen}
                    setIsOpen={setIsCreateMicrostoreColumnPopupOpen}
                    columnGroup={microstoreColumnGroupToAddColumn}
                />
            ) : null}
            {microstoreColumnToReplace ? (
                <ReplaceMicrostoreColumnPopup
                    isOpen={!!microstoreColumnToReplace}
                    setMicrostoreColumnToReplace={setMicrostoreColumnToReplace}
                    column={microstoreColumnToReplace}
                />
            ) : null}
            {microstoreColumnToEdit ? (
                <EditColumnPopup
                    isOpen={!!microstoreColumnToEdit}
                    setMicrostoreColumnToEdit={setMicrostoreColumnToEdit}
                    column={microstoreColumnToEdit}
                />
            ) : null}

            {microstoreColumnToMove ? (
                <MoveColumnPopup
                    isOpen={!!microstoreColumnToMove}
                    setMicrostoreColumnToMove={setMicrostoreColumnToMove}
                    column={microstoreColumnToMove}
                    columnGroups={displayedSite.microstoreColumnGroups}
                />
            ) : null}

            <CreateMicrostoreColumnGroupPopup
                isOpen={isCreateMicrostoreColumnGroupPopupOpen}
                setIsOpen={setIsCreateMicrostoreColumnGroupPopupOpen}
                site={displayedSite}
            />
            <DeleteMicrostoreColumnPopup
                isOpen={isDeleteMicrostoreColumnPopupOpen}
                microstoreColumnToDelete={microstoreColumnToDelete}
                setIsOpen={setIsDeleteMicrostoreColumnPopupOpen}
            />

            {microstoreColumnGroupToDelete ? (
                <DeleteMicrostoreColumnGroupPopup
                    isOpen={isDeleteMicrostoreColumnGroupPopupOpen}
                    microstoreColumnGroupToDelete={microstoreColumnGroupToDelete}
                    setIsOpen={setIsDeleteMicrostoreColumnGroupPopupOpen}
                />
            ) : null}
        </Container>
    );
};

const Container = styled.div<{ isOpen: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: ${({ isOpen }) => (isOpen ? '100%' : '0')};
    transition: width 0.6s ease-in-out;
    background-color: ${({ theme }) => theme.cardBackgroundColor};
    overflow: hidden;
    z-index: 2;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    padding: 25px;
    height: 100%;
    width: 100%;
`;

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const SelectContainer = styled.div`
    flex-grow: 1;
`;

const CloseButton = styled(ImShrink)`
    cursor: pointer;
    margin-left: 25px;
`;

const ColumnsGroupContainer = styled.div`
    overflow: auto;
    padding: 0 60px;
    margin-top: 40px;

    // css tricks to have inside shadow when "in scroll"
    background:
        linear-gradient(white 30%, rgba(255, 255, 255, 0)) center top,
        linear-gradient(rgba(255, 255, 255, 0), white 70%) center bottom,
        radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) center top,
        radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) center bottom;

    background-repeat: no-repeat;
    background-size:
        100% 40px,
        100% 40px,
        100% 14px,
        100% 14px;
    background-attachment: local, local, scroll, scroll;

    // always display scrollbar so user know that it's scrollable
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }

    & > :not(:first-child) {
        margin-top: 30px;
    }
`;

const AddColumnGroupButtonContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const NUMBER_SIZE = 70;

const AddButton = styled.button`
    cursor: pointer;
    height: ${NUMBER_SIZE}px;
    width: ${NUMBER_SIZE}px;
    border-radius: 100%;
    border: 2px solid ${({ theme }) => theme.darkBorderColor};
    background-color: ${({ theme }) => theme.cardBackgroundColor};
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-shrink: 0;
`;

const SiteUpdateFormContainer = styled.div`
    margin-top: 20px;
`;

const SiteSelectionContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const SiteInfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding-left: 25%;
    margin-top: 25px;

    & > :not(:first-child) {
        margin-left: 60px;
    }
`;

const SiteName = styled.div`
    text-align: center;
    font-size: 50px;
    font-weight: 800;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const IconContainer = styled.div`
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    cursor: pointer;
`;

import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { FcCancel, FcCheckmark } from 'react-icons/fc';
import { RiSpam2Fill } from 'react-icons/ri';

import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import Switch from 'react-switch';

import { GET_SITES_sites } from 'data/queries/__generated__/GET_SITES';
import { GET_USER_userFromAdmin } from 'data/queries/__generated__/GET_USER';
import { GET_ACTIVE_ORGANIZATIONS_activeOrganizations } from 'data/queries/__generated__/GET_ACTIVE_ORGANIZATIONS';
import { UserState } from 'data/__generated__';

import { colors } from 'constants/colors';
import { EMPLOYEE } from 'constants/roles';

import { TotemLabel } from 'components/TotemLabel';
import { DetailValue, DetailFormSelect, DetailLink } from 'components/DetailsView';
import { SectionColumn, SectionContainer } from 'components/DetailsView/Section';
import { LeftPanel } from 'components/DetailsView/Panel';
import { TotemImage } from 'components/TotemImage';
import { CopyValue } from 'components/CopyValue';
import { SelectedOption, TotemSelect } from 'components/TotemSelect';
import { NotificationPreferencesDetails } from './NotificationPreferencesDetails';

import { getExtendedFormattedDatetime } from 'helpers/dateTimes';

const SALESFORCE_LINK = 'https://thetotem.lightning.force.com';

const initialStates = [UserState.OnBoarding, UserState.Created, UserState.AddedToTeam];

const UserStateLabel: { [key: string]: string } = {
    [UserState.AddedToTeam]: "Ajouté à l'organisation",
    [UserState.Archived]: 'Archivé',
    [UserState.Banned]: 'Banni',
    [UserState.Created]: 'Créé',
    [UserState.OnBoarding]: 'OnBoarding',
    [UserState.Regular]: 'Regular',
};

export const DetailsPanel = ({
    user,
    organizations,
    sites,
}: {
    user: GET_USER_userFromAdmin;
    organizations: GET_ACTIVE_ORGANIZATIONS_activeOrganizations[];
    sites: GET_SITES_sites[];
}) => {
    const { control, setValue } = useFormContext();

    const [selectedSite, setSelectedSite] = useState<{ label: string; value: string }>();

    const {
        _id: userId,
        createdAt,
        emails,
        lastSeenAt,
        notificationPreferences,
        organization,
        profile: { firstname, lastname, state, phone, salesforceId, salesforceLeadId },
        roles,
        stripeCustomerId,
        userGroupIds,
        username,
        visitedSites,
    } = user;

    const userGroupOptions: Record<string, { label: string; value: string }[]> = {
        // MWM test
        RQRwTBvQMhwd92XsD: [{ label: 'MWM in office employees', value: 'd3817dc2-84a0-4293-b0d3-016146f0ecbd' }],
    };

    const { fields, append, remove } = useFieldArray<
        {
            visitedSites: { _id: string; name: string }[];
        },
        'visitedSites',
        '_id'
    >({ name: 'visitedSites' as const });

    useEffect(() => {
        if (visitedSites) {
            setValue(
                'visitedSites',
                visitedSites.map(({ _id, name, visitedAt }) => ({ _id, name, visitedAt })),
            );
        }
    }, [visitedSites, setValue]);

    const organizationOptions = organizations.map(({ _id, name }) => ({ value: _id, label: name }));
    const sitesOptions = sites.map(({ _id, name }) => ({ value: _id, label: name }));

    const statesOptions = Object.keys(UserStateLabel).map((key) => ({
        label: UserStateLabel[key],
        value: key as UserState,
        isDisabled: initialStates.includes(key as UserState),
    }));

    //currently can be editor and analyst but will remove them soon
    const isAdmin = roles.global && !roles.global.includes(EMPLOYEE);

    function handleOriginSiteSelect(siteOption: SelectedOption<string>): void {
        if (siteOption) {
            setSelectedSite(siteOption);
        }
    }

    return (
        <LeftPanel>
            <SectionContainer title="Détails" keepOpen>
                <SectionColumn>
                    <CopyValue value={userId} />
                    <TotemLabel>Liens</TotemLabel>
                    <ExternalServicesList>
                        <ExternalServiceLink
                            href={`https://account.postmarkapp.com/servers/5335708/streams/outbound/addresses/${username}`}
                            target="_blank"
                            rel="nooperer noreferrer"
                        >
                            💌 Postmark
                        </ExternalServiceLink>
                        <ExternalServiceLink
                            href={`https://dashboard.stripe.com/customers${
                                stripeCustomerId ? `/${stripeCustomerId}` : `?email=${username}`
                            }`}
                            target="_blank"
                            rel="nooperer noreferrer"
                        >
                            💰 Stripe
                        </ExternalServiceLink>
                        <ExternalServiceLink
                            href={`https://eu.mixpanel.com/project/2786792/view/3321576/app/profile#distinct_id=${userId}`}
                            target="_blank"
                            rel="nooperer noreferrer"
                        >
                            🧐 Mixpanel
                        </ExternalServiceLink>
                        <ExternalServiceLink
                            href={`https://fly.customer.io/env/108000/people?email=${username}`}
                            target="_blank"
                            rel="nooperer noreferrer"
                        >
                            🛎 Customer.io
                        </ExternalServiceLink>
                        <ExternalServiceLink
                            href={`https://totem.sentry.io/issues/?environment=prod&?project=2204925&query=user.id%3A${userId}&referrer=issue-list&statsPeriod=360d`}
                            target="_blank"
                            rel="nooperer noreferrer"
                        >
                            🐞 Sentry
                        </ExternalServiceLink>
                    </ExternalServicesList>
                    <div>
                        <TotemLabel>Emails</TotemLabel>
                        {emails.map(({ address, verified, bounced }) => {
                            return (
                                <EmailContainer key={address}>
                                    <EmailAddress>{address}</EmailAddress>
                                    {bounced ? (
                                        <RiSpam2Fill size={19} />
                                    ) : verified ? (
                                        <FcCheckmark size={19} />
                                    ) : (
                                        <FcCancel size={19} />
                                    )}
                                </EmailContainer>
                            );
                        })}
                    </div>
                    <DetailValue label="Prénom" value={firstname} />
                    <DetailValue label="Nom" value={lastname} />
                    <DetailFormSelect<string | null>
                        label="Statut"
                        placeholder="Statut"
                        defaultValue={state}
                        name="state"
                        options={statesOptions}
                        required={true}
                    />
                    <DetailValue label="Numéro téléphone" value={phone} />
                    <div>
                        <TotemLabel>Lien Salesforce</TotemLabel>
                        <ExternalResourceLink
                            href={`${SALESFORCE_LINK}/${salesforceId || salesforceLeadId}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            disabled={!(salesforceId || salesforceLeadId)}
                        >
                            <TotemImage
                                src="https://thetotem.my.salesforce.com/img/logo214.svg"
                                alt="Logo Stripe"
                                size={30}
                            />
                            <DetailValue value={salesforceId || salesforceLeadId || 'Pas de salesforceId'} />
                        </ExternalResourceLink>
                    </div>
                    <DetailValue
                        label="Date d'inscription"
                        value={createdAt ? getExtendedFormattedDatetime(Number(createdAt)) : 'jamais'}
                    />
                    <DetailValue
                        label="Dernière connexion"
                        value={lastSeenAt ? getExtendedFormattedDatetime(lastSeenAt) : 'jamais'}
                    />
                    <TotemLabel>Sites visités</TotemLabel>
                    <VisitedSitesContainer>
                        {fields?.map((site, index) => (
                            <SiteRow key={site._id}>
                                <DetailValue value={site.name || 'inconnu'} />
                                <IconContainer onClick={() => remove(index)}>
                                    <TrashIcon data-test="trash-icon" size={16} color={colors.pureWhite} />
                                </IconContainer>
                            </SiteRow>
                        ))}
                        <SiteRow>
                            <TotemSelect
                                label="Ajouter un site"
                                placeholder="Sélectionner un site (recherche par nom ou par _id)"
                                value={selectedSite}
                                options={sitesOptions}
                                onChange={handleOriginSiteSelect}
                            />
                            {selectedSite ? (
                                <IconContainer
                                    onClick={() =>
                                        append({
                                            _id: selectedSite.value,
                                            name: selectedSite.label,
                                        })
                                    }
                                >
                                    <PlusIcon color={colors.pureWhite} />
                                </IconContainer>
                            ) : null}
                        </SiteRow>
                    </VisitedSitesContainer>
                    <DetailFormSelect<string | null>
                        label="Organisation"
                        placeholder="Sélectionner une organisation"
                        defaultValue={organization?._id || null}
                        name="organizationId"
                        options={organizationOptions}
                        isClearable
                    />
                    {organization?._id ? (
                        <DetailLink
                            path="organization"
                            name={`Page de l'organisation ${organization.name}`}
                            value={organization._id}
                        />
                    ) : null}
                    {organization?._id && userGroupOptions[organization._id] ? (
                        <DetailFormSelect<string>
                            label="Groupes"
                            placeholder="Sélectionnez un ou plusieurs groupes"
                            defaultValue={userGroupIds}
                            isMulti
                            isClearable
                            name="userGroupIds"
                            options={userGroupOptions[organization._id] || []}
                        />
                    ) : null}
                    <div>
                        <TotemLabel>Rôles</TotemLabel>
                        <SwitchContainer>
                            <TotemLabel>Employé(e)</TotemLabel>
                            <Controller
                                name="isAdmin"
                                render={({ field: { onChange, value } }) => (
                                    <Switch
                                        onChange={onChange}
                                        checked={value}
                                        onColor={colors.green}
                                        offColor={colors.pantinGrey}
                                    />
                                )}
                                control={control}
                                defaultValue={isAdmin}
                            />
                            <TotemLabel>Admin</TotemLabel>
                        </SwitchContainer>
                    </div>
                    <NotificationPreferencesDetails notificationPreferences={notificationPreferences} userId={userId} />
                    <DetailValue label="Rôles globaux (*)" value={roles?.global?.join(', ') || 'non'} />
                </SectionColumn>
            </SectionContainer>
        </LeftPanel>
    );
};

const SwitchContainer = styled.div`
    display: flex;
    align-items: center;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const EmailAddress = styled.div`
    color: ${({ theme }) => theme.textColor};
`;

const EmailContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex: 1;
`;

const ExternalServicesList = styled.div`
    display: flex;
    flex-direction: column;
`;

const ExternalServiceLink = styled.a`
    color: ${({ theme }) => theme.ctaPrimaryColor};
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
`;

const ExternalResourceLink = styled.a<{ disabled: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;

    color: ${({ theme }) => theme.ctaPrimaryColor};
    text-decoration: none;
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'default')};
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

    &:hover {
        text-decoration: underline;
    }

    & > :not(:first-child) {
        margin-left: 10px;
    }
`;

const VisitedSitesContainer = styled.div`
    padding: 10px;
    border: 1px solid ${({ theme }) => theme.lightBorderColor};

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

const SiteRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const TrashIcon = styled(FaTrash)`
    color: ${({ theme }) => theme.menuTextColor};
`;

const IconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background-color: ${({ theme }) => theme.ctaPrimaryColor};
    cursor: pointer;
    margin-left: 10px;
`;

const PlusIcon = styled(FaPlus)`
    color: ${({ theme }) => theme.menuTextColor};
`;

import { gql } from '@apollo/client';
import { RITUAL_GAME_FRAGMENT } from 'data/fragments/ritualGame';

export const GET_RITUAL_GAME_FOR_RITUAL_QUERY = gql`
    query GET_RITUAL_GAME_FOR_RITUAL($ritualId: ID!) {
        getRitualGameForRitual(ritualId: $ritualId) {
            ...RitualGameFragment
        }
    }
    ${RITUAL_GAME_FRAGMENT}
`;

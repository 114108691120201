import React, { useEffect, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { PAGES } from 'constants/pages';

import { UpdatePoll, UpdatePollVariables } from 'data/mutations/__generated__/UpdatePoll';
import { UPDATE_POLL_MUTATION } from 'data/mutations/poll';
import { GetPoll, GetPollVariables } from 'data/queries/__generated__/GetPoll';
import { GET_POLL_QUERY } from 'data/queries/poll';

import { Loader } from 'components/Loader';
import { Header, HeaderTitle } from 'components/Header';
import { PageTitle } from 'components/PageTitle';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { ColumnsSectionContainer, SectionColumn } from 'components/DetailsView/Section';
import { CopyValue } from 'components/CopyValue';
import { PollForm, PollFormType } from './components/PollForm';

import { formatDateAsAnniversary } from 'helpers/dateTimes';

type ParamTypes = {
    pollId: string;
};

export const PollDetails = () => {
    const { pollId = '' } = useParams<ParamTypes>();
    const initialForm = {
        audience: { organizationIds: [], siteIds: [], userIds: [] },
        answerOptions: [],
        dateWindow: { startDate: null, endDate: null },
        image: null,
        imageUrl: null,
        question: null,
        sectionTitle: null,
        title: null,
    };
    const [pollForm, setPollForm] = useState<PollFormType>(initialForm);

    const navigate = useNavigate();

    const {
        loading: pollLoading,
        error: pollError,
        data: pollData,
    } = useQuery<GetPoll, GetPollVariables>(GET_POLL_QUERY, { variables: { pollId } });

    const [updatePollMutation, { loading: updateLoading }] = useMutation<UpdatePoll, UpdatePollVariables>(
        UPDATE_POLL_MUTATION,
    );

    useEffect(() => {
        if (pollData) {
            const { poll } = pollData;

            if (poll) {
                setPollForm({
                    audience: {
                        organizationIds: poll.audience.organizationIds,
                        siteIds: poll.audience.siteIds,
                        userIds: poll.audience.userIds,
                    },
                    answerOptions: poll.answerOptions,
                    dateWindow: {
                        startDate: new Date(poll.dateWindow.startDate),
                        endDate: new Date(poll.dateWindow.endDate),
                    },
                    image: null,
                    imageUrl: poll.imageUrl,
                    question: poll.question,
                    sectionTitle: poll.sectionTitle,
                    title: poll.title,
                });
            }
        }
    }, [pollData]);

    if (pollLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (pollError || !pollData || !pollForm) {
        throw new Error('Une erreur est survenue lors de la récupération de ce sondage');
    }

    const { poll } = pollData;

    if (!poll) {
        throw new Error(`Impossible de récupérer un sondage avec cet identifiant (${pollId})`);
    }

    async function updatePoll() {
        if (!pollForm.question) {
            toast.error('Question non renseignée.');
            return;
        }
        if (!pollForm.dateWindow.startDate || !pollForm.dateWindow.endDate) {
            toast.error('Date(s) non renseignée(s).');
            return;
        }
        if (!pollForm.title) {
            toast.error('Titre non renseigné.');
            return;
        }
        const { data } = await updatePollMutation({
            variables: {
                pollId,
                input: {
                    audience: pollForm.audience,
                    answerOptions: pollForm.answerOptions,
                    dateWindow: {
                        startDate: formatDateAsAnniversary({
                            dateTime: pollForm.dateWindow.startDate,
                            useNewFormat: true,
                        }),
                        endDate: formatDateAsAnniversary({ dateTime: pollForm.dateWindow.endDate, useNewFormat: true }),
                    },
                    image: pollForm.image,
                    imageUrl: pollForm.imageUrl,
                    question: pollForm.question,
                    sectionTitle: pollForm.sectionTitle || null,
                    title: pollForm.title,
                },
            },
        });

        if (data) {
            const {
                updatePoll: { poll, errors },
            } = data;
            if (poll) {
                toast.success('Le sondage a bien été mis à jour !');
                navigate(`/poll/${poll._id}`);
            } else {
                if (errors) {
                    errors.forEach((error) => {
                        toast.error(error, { autoClose: false });
                    });

                    if (errors.length > 1) {
                        toast.info('Cliquez pour fermer toutes les notifications', {
                            autoClose: false,
                            onClick: () => toast.dismiss(),
                        });
                    }
                } else {
                    throw Error("Une erreur inconnue s'est produite");
                }
            }
        } else {
            throw Error("Une erreur inconnue s'est produite");
        }
    }

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.pollDetails} />
                </HeaderTitle>
                <CTAsContainer>
                    <TotemPrimaryButton disabled={updateLoading} onClick={updatePoll}>
                        Mettre à jour
                    </TotemPrimaryButton>
                    <Link to="/polls">
                        <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                    </Link>
                </CTAsContainer>
            </Header>
            <Content>
                <ScrollableContent>
                    <ColumnsSectionContainer numberOfColumns={1}>
                        <SectionColumn>
                            <CopyValue value={pollId} />
                            <ExternalLink
                                href={
                                    'https://metabase.totem.co/dashboard/533-poll-answers?question=' + pollForm.question
                                }
                                target="_blank"
                                rel="nooperer noreferrer"
                            >
                                Résultats du sondage (metabase)
                            </ExternalLink>
                            <PollForm pollForm={pollForm} setPollForm={setPollForm} />
                        </SectionColumn>
                    </ColumnsSectionContainer>
                </ScrollableContent>
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const ExternalLink = styled.a`
    color: ${({ theme }) => theme.textColor};
`;

const CTAsContainer = styled.div`
    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const Content = styled.div`
    width: 100%;
    flex: 1;
    overflow: hidden;
    color: ${({ theme }) => theme.textColor};
`;

const ScrollableContent = styled.div`
    padding: 15px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
`;

import gql from 'graphql-tag';

export const PAYMENT_FRAGMENT = gql`
    fragment PaymentFragment on Payment {
        _id
        createdAt
        invoiceId
        invoicePdfUrl
        month
        orders {
            _id
            amountDelivered
            deliveryDate
        }
        organizationId
        organizationName
        state
        stateHistory {
            createdAt
            createdBy
            createdByName
            state
        }
        totalAmountDisplay
        updatedAt
    }
`;

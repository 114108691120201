import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { FaTrash } from 'react-icons/fa';

import { HotDrinksArrangementFragment } from 'data/fragments/__generated__/HotDrinksArrangementFragment';
import { GET_NAYAX_PRODUCTS_getNayaxProducts } from 'data/queries/__generated__/GET_NAYAX_PRODUCTS';
import {
    UPDATE_HOT_DRINKS_ARRANGEMENT,
    UPDATE_HOT_DRINKS_ARRANGEMENTVariables,
} from 'data/mutations/__generated__/UPDATE_HOT_DRINKS_ARRANGEMENT';
import { UPDATE_HOT_DRINKS_ARRANGEMENT_MUTATION } from 'data/mutations/hotDrinksArrangement';

import { SelectedOption, TotemSelect } from 'components/TotemSelect';
import { TotemLabel } from 'components/TotemLabel';
import { TotemImage } from 'components/TotemImage';
import { DetailLink } from 'components/DetailsView';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { StatusTag } from 'pages/Products/components/StatusTag';
import { Loader, LoaderModeType } from 'components/Loader';

export const HotDrinksArrangementDetailsContent = ({
    hotDrinksArrangement,
    products,
}: {
    hotDrinksArrangement: HotDrinksArrangementFragment;
    products: GET_NAYAX_PRODUCTS_getNayaxProducts[];
}) => {
    const [selectedProductIds, setSelectedProductIds] = useState<string[]>(hotDrinksArrangement.productIds);

    const [updateHotDrinksArrangement, { loading: updateHotDrinksArrangementLoading }] = useMutation<
        UPDATE_HOT_DRINKS_ARRANGEMENT,
        UPDATE_HOT_DRINKS_ARRANGEMENTVariables
    >(UPDATE_HOT_DRINKS_ARRANGEMENT_MUTATION);

    function addProductId(newProductId: string) {
        if (!selectedProductIds.some((productId) => productId === newProductId)) {
            setSelectedProductIds([...selectedProductIds, newProductId]);
        }
    }

    function removeProductId(oldProductId: string) {
        setSelectedProductIds(selectedProductIds.filter((productId) => productId !== oldProductId));
    }

    async function handleSubmit() {
        const { data } = await updateHotDrinksArrangement({
            variables: { hotDrinksArrangementId: hotDrinksArrangement._id, productIds: selectedProductIds },
        });

        if (!data) {
            throw new Error('Une erreur est survenue lors de la mise à jour');
        }

        toast.success('Produits et stocks mis à jour');
    }

    const productsOptions =
        products
            .map(({ _id, fullname }) => ({
                value: _id,
                label: fullname,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)) || [];

    const hasProductIdsChanged =
        hotDrinksArrangement.productIds.some((x) => !selectedProductIds.includes(x)) ||
        selectedProductIds.some((x) => !hotDrinksArrangement.productIds.includes(x));

    return (
        <Content>
            <ScrollableContent>
                <TotemPrimaryButton
                    disabled={!hasProductIdsChanged || updateHotDrinksArrangementLoading}
                    type="button"
                    onClick={handleSubmit}
                >
                    {updateHotDrinksArrangementLoading ? (
                        <Loader size="20px" mode={LoaderModeType.Spin} />
                    ) : (
                        'Mettre à jour'
                    )}
                </TotemPrimaryButton>
                <SelectContainer>
                    <TotemSelect
                        label="Ajouter un produit"
                        placeholder="Sélectionner un produit (recherche par nom ou par _id)"
                        value={null}
                        options={productsOptions}
                        onChange={(option: SelectedOption<string>) => {
                            if (option) {
                                addProductId(option.value);
                            }
                        }}
                    />
                </SelectContainer>
                <TotemLabel>Produits de la gamme</TotemLabel>
                <ProductsTable>
                    <thead>
                        <tr>
                            <ProductsTableHeaderCell />
                            <ProductsTableHeaderCell>Produit</ProductsTableHeaderCell>
                            <ProductsTableHeaderCell>Est un produit microstore</ProductsTableHeaderCell>
                            <ProductsTableHeaderCell>Statut du produit</ProductsTableHeaderCell>
                            <ProductsTableHeaderCell />
                        </tr>
                    </thead>
                    <tbody>
                        {selectedProductIds.map((productId) => {
                            const product = products.find(({ _id }) => productId === _id);
                            if (!product) {
                                return null;
                            }
                            const { fullname, imageUrl, isMicrostore, state } = product;

                            return (
                                <tr key={productId}>
                                    <ProductsTableBodyCell>
                                        <TotemImage src={imageUrl} alt={fullname} size={50} />
                                    </ProductsTableBodyCell>
                                    <ProductsTableBodyCell>
                                        <DetailLink name={fullname} value={productId} path="product" />
                                    </ProductsTableBodyCell>
                                    <ProductsTableBodyCell>{isMicrostore ? 'Oui' : 'Non'}</ProductsTableBodyCell>
                                    <ProductsTableBodyCell>
                                        <StatusTag state={state} />
                                    </ProductsTableBodyCell>
                                    <ProductsTableBodyCell isDataCentered>
                                        <TotemPrimaryButton type="button" onClick={() => removeProductId(productId)}>
                                            <FaTrash size={20} />
                                        </TotemPrimaryButton>
                                    </ProductsTableBodyCell>
                                </tr>
                            );
                        })}
                    </tbody>
                </ProductsTable>
            </ScrollableContent>
        </Content>
    );
};

const Content = styled.div`
    flex: 1;
    overflow: hidden;
`;

const ScrollableContent = styled.div`
    padding: 15px;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

const SelectContainer = styled.div``;

const ProductsTable = styled.table`
    width: 100%;
    margin-top: 10px;
    border-radius: ${({ theme }) => theme.borderRadius};
    border-spacing: 0px;
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
`;

const ProductsTableHeaderCell = styled.th`
    padding: 10px;
    text-align: ${({ isDataCentered }: { isDataCentered?: boolean }) => (isDataCentered ? 'center' : 'left')};
`;

const ProductsTableBodyCell = styled.td`
    padding: 10px;
    border-top: 1px solid ${({ theme }) => theme.lightBorderColor};
    text-align: ${({ isDataCentered }: { isDataCentered?: boolean }) => (isDataCentered ? 'center' : 'left')};
`;

import gql from 'graphql-tag';

export const DELIVERY_FRAGMENT = gql`
    fragment DeliveryFragment on Delivery {
        _id
        deliveryDate
        label
        orders {
            number
        }
        organizationName
        roundColor
        roundName
        siteName
        state
        totem {
            number
        }
        updatedAt
    }
`;

export const UPDATE_STATE_RESULT_FRAGMENT = gql`
    fragment UpdateStateResultFragment on DeliveriesStateUpdateResult {
        updatedDeliveries {
            _id
            state
            stateHistory {
                state
                createdAt
                createdByName
            }
        }
        errors
    }
`;

import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import styled from 'styled-components';

import { ProductWithInfoType, SupplyOrderDetailsDisplayValues } from './supplyOrderDetailsFormHelper';
import { getSupplyOrderProductReceivedTotal } from '../helper/getSupplyOrderProductReceivedTotal';

export const ProductsInfoTableFooter = ({
    supplyOrderDetailsDisplayValues,
    isSupplyOrderReceived,
}: {
    supplyOrderDetailsDisplayValues: SupplyOrderDetailsDisplayValues;
    isSupplyOrderReceived: boolean;
}) => {
    const { productsWithInfo } = supplyOrderDetailsDisplayValues;

    const { control } = useFormContext();

    const updatedProductsWithInfo: ProductWithInfoType[] = useWatch({
        control,
        name: 'productsWithInfo',
        defaultValue: productsWithInfo,
    });

    const totalInfo = updatedProductsWithInfo.reduce(
        (
            {
                colis: totalColis,
                colisReceived: totalColisReceived,
                quantity: totalQuantity,
                quantityReceived: totalQuantityReceived,
                totalTotalHT,
                totalTotalHTReceived,
            },
            { colis, productReceivedDetails, confirmedQuantity, quantity, unitPrice, unitPriceSupplier },
        ) => {
            const { colisReceived, quantityReceived } = getSupplyOrderProductReceivedTotal(productReceivedDetails);
            return {
                colis: totalColis + (colis ?? 0),
                colisReceived: totalColisReceived + (colisReceived ?? 0),
                quantity: totalQuantity + (confirmedQuantity ?? quantity ?? 0),
                quantityReceived: totalQuantityReceived + (quantityReceived ?? 0),
                totalTotalHT: totalTotalHT + (confirmedQuantity ?? quantity ?? 0) * (unitPrice ?? unitPriceSupplier),
                totalTotalHTReceived: totalTotalHTReceived + (quantityReceived ?? 0) * (unitPrice ?? unitPriceSupplier),
            };
        },
        {
            colis: 0,
            colisReceived: 0,
            quantity: 0,
            quantityReceived: 0,
            totalTotalHT: 0,
            totalTotalHTReceived: 0,
        },
    );

    return (
        <tfoot>
            <SupplierProductInfoFooterRow>
                <SupplierProductInfoFooterCell>Total</SupplierProductInfoFooterCell>
                <SupplierProductInfoFooterCellSmall />
                <SupplierProductInfoFooterCellSmall />
                <SupplierProductInfoFooterCell />
                <SupplierProductInfoFooterCellSmall />
                <SupplierProductInfoFooterCellSmall>{totalInfo.colis}</SupplierProductInfoFooterCellSmall>
                <SupplierProductInfoFooterCellSmall>
                    <div>Quantité à recevoir:</div>
                </SupplierProductInfoFooterCellSmall>
                <SupplierProductInfoFooterCellSmall>{totalInfo.quantity}</SupplierProductInfoFooterCellSmall>
                <SupplierProductInfoFooterCellSmall />
                <SupplierProductInfoFooterCellSmall />
                {isSupplyOrderReceived ? (
                    <>
                        <SupplierProductInfoFooterCellSmall />
                        <SupplierProductInfoFooterCellSmall>
                            {totalInfo.colisReceived}
                        </SupplierProductInfoFooterCellSmall>
                        <SupplierProductInfoFooterCellSmall>
                            {totalInfo.quantityReceived}
                        </SupplierProductInfoFooterCellSmall>
                        <SupplierProductInfoFooterCellSmall />
                    </>
                ) : null}

                <SupplierProductInfoFooterCellSmall />
                <SupplierProductInfoFooterCellSmall />
                <SupplierProductInfoFooterCellSmall>
                    <div>Prix:</div>
                </SupplierProductInfoFooterCellSmall>
                <SupplierProductInfoFooterCellSmall>
                    {totalInfo.totalTotalHT.toFixed(2)}
                </SupplierProductInfoFooterCellSmall>
                {isSupplyOrderReceived ? (
                    <SupplierProductInfoFooterCellSmall>
                        {totalInfo.totalTotalHTReceived.toFixed(2)}
                    </SupplierProductInfoFooterCellSmall>
                ) : null}
            </SupplierProductInfoFooterRow>
        </tfoot>
    );
};

const SupplierProductInfoFooterRow = styled.tr`
    border-top: ${({ theme }) => `1px solid ${theme.lightBorderColor}`};
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
`;

const SupplierProductInfoFooterCell = styled.th`
    position: sticky;
    bottom: 0;
    z-index: 1;
    padding: 15px;
    font-weight: 800;
    color: ${({ theme }) => theme.ctaPrimaryColor};
    text-align: left;
    background-color: ${({ theme }) => theme.cardBackgroundColor};
`;

const SupplierProductInfoFooterCellSmall = styled(SupplierProductInfoFooterCell)`
    max-width: 85px;
    min-width: 85px;
    text-align: center;
`;

import React, { useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { CREATE_SUPPLY_ORDER_MUTATION } from 'data/mutations/supplyOrder';
import { CREATE_SUPPLY_ORDER, CREATE_SUPPLY_ORDERVariables } from 'data/mutations/__generated__/CREATE_SUPPLY_ORDER';
import { GET_SUPPLIERS_QUERY } from 'data/queries/supplier';
import { GET_SUPPLIERS } from 'data/queries/__generated__/GET_SUPPLIERS';
import { SupplierState } from 'data/__generated__';

import { Loader, LoaderModeType } from 'components/Loader';
import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { SelectedOption, TotemSelect } from 'components/TotemSelect';

export function CreateSupplyOrderPopup({ isOpen, setIsOpen }: { isOpen: boolean; setIsOpen: (arg: boolean) => void }) {
    const [selectedSupplierOption, setSelectedSupplierOption] = useState<SelectedOption<string>>(null);

    const {
        loading: suppliersLoading,
        data: suppliersData,
        error: suppliersError,
    } = useQuery<GET_SUPPLIERS>(GET_SUPPLIERS_QUERY);

    const [createSupplyOrder, { loading: creationLoading }] = useMutation<
        CREATE_SUPPLY_ORDER,
        CREATE_SUPPLY_ORDERVariables
    >(CREATE_SUPPLY_ORDER_MUTATION, { variables: { supplierId: selectedSupplierOption?.value ?? '' } });

    async function handleSubmit() {
        const { data } = await createSupplyOrder();

        if (!data) {
            throw new Error('Une erreur est survenue lors de la suppression des achats');
        }

        const {
            createSupplyOrderMutation: { createdSupplyOrder, errors, warnings },
        } = data;

        if (errors.length) {
            errors.forEach((error, index) => {
                toast.error(<span key={index}>Erreur : {error}</span>, { autoClose: false });
            });
        }
        if (warnings.length) {
            warnings.forEach((warning, index) => {
                toast.warning(<span key={index}>Warning : {warning}</span>, { autoClose: false });
            });
        }
        if (errors.length + warnings.length > 1) {
            toast.info('Cliquez pour fermer toutes les notifications', {
                autoClose: false,
                onClick: () => toast.dismiss(),
            });
        }

        if (createdSupplyOrder) {
            const newWindow = window.open(`supplyOrder/${createdSupplyOrder._id}`, '_blank', 'noopener,noreferrer');
            if (newWindow) {
                newWindow.opener = null;
            }
        }

        setIsOpen(false);
    }

    if (suppliersLoading) {
        return (
            <TotemPopup title="Créer un achat" isOpen={isOpen} setIsOpen={setIsOpen}>
                Chargement des fournisseurs
                <Loader size="20px" mode={LoaderModeType.Spin} />
            </TotemPopup>
        );
    }

    if (suppliersError || !suppliersData) {
        throw new Error('Une erreur est survenue lors de la récupération des fournisseurs');
    }

    const supplierOptions = suppliersData.suppliers
        .filter(({ state }) => state !== SupplierState.Archived)
        .map((supplier) => ({ value: supplier._id, label: supplier.name }))
        .sort((supplierOptionA, supplierOptionB) =>
            supplierOptionA.label.toLowerCase().localeCompare(supplierOptionB.label.toLowerCase()),
        );

    return (
        <TotemPopup title="Créer un achat" isOpen={isOpen} setIsOpen={setIsOpen} contentOverflow="visible">
            Veuillez sélectionner le fournisseur pour lequel vous voulez créer l'achat :
            <SubmitContainer>
                <TotemSelect
                    label="Fournisseur"
                    placeholder="Sélectionner un fournisseur (recherche par nom ou par _id)"
                    value={selectedSupplierOption}
                    options={supplierOptions}
                    onChange={setSelectedSupplierOption}
                />
                <ValidationButton onClick={handleSubmit}>
                    {suppliersLoading || creationLoading ? (
                        <Loader size="20px" mode={LoaderModeType.Spin} />
                    ) : (
                        'Confirmer'
                    )}
                </ValidationButton>
            </SubmitContainer>
        </TotemPopup>
    );
}

const SubmitContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 15px;
`;

const ValidationButton = styled(TotemPrimaryButton)`
    margin-top: 15px;
`;

import React, { useState } from 'react';
import styled from 'styled-components';
import Switch from 'react-switch';
import { useQuery } from '@apollo/client';

import { colors } from 'constants/colors';
import { GET_FUTURE_STOCK_VARIATIONS_BY_PRODUCT_QUERY } from 'data/queries/futureStockVariation';
import {
    GET_FUTURE_STOCK_VARIATIONS_BY_PRODUCT,
    GET_FUTURE_STOCK_VARIATIONS_BY_PRODUCTVariables,
} from 'data/queries/__generated__/GET_FUTURE_STOCK_VARIATIONS_BY_PRODUCT';
import { FutureStockVariationState } from 'data/__generated__';

import { Loader, LoaderModeType } from 'components/Loader';
import { TotemLabel } from 'components/TotemLabel';
import { FutureStockVariationsTable } from 'pages/Stocks/components/FutureStockVariationsTable';

export const FutureStockVariationsList = ({ productId }: { productId: string }) => {
    const [hideNotActive, setHideNotActive] = useState(true);
    const { data, loading, error } = useQuery<
        GET_FUTURE_STOCK_VARIATIONS_BY_PRODUCT,
        GET_FUTURE_STOCK_VARIATIONS_BY_PRODUCTVariables
    >(GET_FUTURE_STOCK_VARIATIONS_BY_PRODUCT_QUERY, {
        variables: {
            productId,
        },
    });

    if (loading) {
        return <Loader mode={LoaderModeType.Spin} />;
    }

    if (error || !data) {
        throw new Error('Une erreur est survenue lors de la récupération des variations de stock');
    }

    const { futureStockVariationsByProduct } = data;

    const filteredFutureStockVariations = futureStockVariationsByProduct.filter(({ state }) =>
        hideNotActive ? state === FutureStockVariationState.Ongoing : true,
    );

    return (
        <Container>
            {futureStockVariationsByProduct.length ? (
                <>
                    <SwitchContainer>
                        <SwitchLabel>Afficher tout</SwitchLabel>
                        <Switch
                            onChange={() => setHideNotActive(!hideNotActive)}
                            checked={hideNotActive}
                            onColor={colors.green}
                            offColor={colors.pantinGrey}
                            checkedIcon={false}
                            uncheckedIcon={false}
                        />
                        <SwitchLabel>Cacher pas actifs</SwitchLabel>
                    </SwitchContainer>
                    <FutureStockVariationsTable futureStockVariations={filteredFutureStockVariations} />
                </>
            ) : (
                'Pas de variations de stock à venir trouvées'
            )}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const SwitchContainer = styled.div`
    display: flex;
    align-items: center;
`;

const SwitchLabel = styled(TotemLabel)`
    margin: 0 5px;
`;

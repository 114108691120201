import { gql } from '@apollo/client';

export const EQUIPMENT_FRAGMENT = gql`
    fragment Equipment on Equipment {
        _id
        createdAt
        updatedAt
        name
        brand
        volume
        price
        imageUrl
        description
        hidden
        height
        width
        length
        weight
        state
        stateHistory {
            createdAt
            createdBy
            state
        }
    }
`;

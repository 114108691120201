import gql from 'graphql-tag';
import { MICROSTORE_COLUMN_FRAGMENT, MICROSTORE_COLUMN_WITH_SHELVES_FRAGMENT } from 'data/fragments/microstoreColumn';

export const MICROSTORE_COLUMN_GROUP_FRAGMENT = gql`
    fragment MicrostoreColumnGroupFragment on MicrostoreColumnGroup {
        _id
        columns {
            ...MicrostoreColumnFragment
        }
        name
        positionInSite
        siteId
    }
    ${MICROSTORE_COLUMN_FRAGMENT}
`;

export const MICROSTORE_COLUMN_GROUP_WITH_COLUMNS_FRAGMENT = gql`
    fragment MicrostoreColumnGroupWithColumnsFragment on MicrostoreColumnGroup {
        _id
        columns {
            ...MicrostoreColumnWithShelvesFragment
        }
        name
        positionInSite
        siteId
    }
    ${MICROSTORE_COLUMN_WITH_SHELVES_FRAGMENT}
`;

import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from '@apollo/client';
import styled from 'styled-components';

import { formatDateAsAnniversary } from 'helpers/dateTimes';

import { GENERATE_SITE_MENUS_MUTATION } from 'data/mutations/menus';
import { SITE_MENUS_GENERATION_STATUS_QUERY } from 'data/queries/menus';
import { SITE_MENUS_GENERATION_STATUS } from 'data/queries/__generated__/SITE_MENUS_GENERATION_STATUS';
import { GENERATE_SITE_MENUS, GENERATE_SITE_MENUSVariables } from 'data/mutations/__generated__/GENERATE_SITE_MENUS';
import { PredictionServiceStatus } from 'data/__generated__';

import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { TotemInput } from 'components/TotemInput';
import { SectionColumn } from 'components/DetailsView/Section';
import { TotemCheckbox } from 'components/TotemCheckbox';

const SITE_MENU_MIN_STOCK = 1;

export function GenerateSiteMenusButton({ date }: { date: Date }) {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [polling, setPolling] = useState<boolean>(false);
    const {
        data,
        loading: statusLoading,
        error,
        startPolling,
        stopPolling,
    } = useQuery<SITE_MENUS_GENERATION_STATUS>(SITE_MENUS_GENERATION_STATUS_QUERY);
    const [generateSiteMenus, { loading }] = useMutation<GENERATE_SITE_MENUS, GENERATE_SITE_MENUSVariables>(
        GENERATE_SITE_MENUS_MUTATION,
    );
    const [stockMin, setStockMin] = useState<number | undefined>(SITE_MENU_MIN_STOCK);
    const [consumptionRatioToApply, setConsumptionRatioToApply] = useState<number | undefined>(100);
    const [unknownShrinkageRatioToApply, setUnknownShrinkageRatioToApply] = useState<number | undefined>(70);
    const [withoutRotation, setWithoutRotation] = useState<boolean>(false);

    const siteMenusGenerationStatus = data?.siteMenusGenerationStatus;

    async function handleClick() {
        try {
            await generateSiteMenus({
                variables: {
                    date: formatDateAsAnniversary({ dateTime: date, useNewFormat: true }),
                    stockMin,
                    consumptionRatioToApply,
                    unknownShrinkageRatioToApply,
                    withoutRotation,
                },
                onCompleted: ({ generateSiteMenus }) => {
                    if (generateSiteMenus?.success) {
                        toast.success('La génération des menus a bien été relancée. Patientez');
                        startPolling(10000);
                        setPolling(true);
                    } else {
                        toast.error(`Une erreur s'est produite : ${generateSiteMenus?.error || 'erreur inconnue'}`);
                    }
                },
                onError: (error) => {
                    toast.error(`Impossible de générer les menus : ${error}`);
                },
            });
        } catch (error) {
            toast.error(`Impossible de générer les menus : ${error}`);
            throw Error('Impossible de générer les menus');
        }
    }

    useEffect(() => {
        if (!statusLoading && siteMenusGenerationStatus !== PredictionServiceStatus.Loading) {
            stopPolling();
            setPolling(false);
        }
    }, [siteMenusGenerationStatus, statusLoading]);

    return (
        <>
            <TotemPopup
                title="Confirmer la re-génération des menus"
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                contentOverflow="visible"
            >
                <SectionColumn>
                    <TotemInput
                        label="Stock Min"
                        onChange={(value) => {
                            if (value === '') {
                                return setStockMin(undefined);
                            }
                            const parsed = parseInt(value);
                            setStockMin(!isNaN(parsed) ? parsed : SITE_MENU_MIN_STOCK);
                        }}
                        placeholder={`${SITE_MENU_MIN_STOCK}`}
                        value={stockMin}
                    />
                    <TotemInput
                        label="% de consommation prévue (en % de la consommation moyenne)"
                        onChange={(value) => {
                            if (value === '') {
                                return setConsumptionRatioToApply(undefined);
                            }
                            const parsed = parseFloat(value);
                            setConsumptionRatioToApply(!isNaN(parsed) ? parsed : 100);
                        }}
                        placeholder="100"
                        value={consumptionRatioToApply}
                    />
                    <TotemInput
                        label="[fixé] % de démarque inconnue à prendre en compte dans les achats (0 = ne pas prévoir d'acheter de produits en DI)"
                        onChange={(value) => {
                            if (value === '') {
                                return setUnknownShrinkageRatioToApply(undefined);
                            }
                            const parsed = parseFloat(value);
                            setUnknownShrinkageRatioToApply(!isNaN(parsed) ? parsed : 70);
                        }}
                        placeholder="100"
                        // for now we don't allow yugi to update this
                        disabled
                        value={unknownShrinkageRatioToApply}
                    />
                    <TotemCheckbox
                        label="Ne pas avoir de rotation ?"
                        onChange={() => {
                            setWithoutRotation(!withoutRotation);
                        }}
                        checked={withoutRotation}
                    />
                </SectionColumn>

                <InfoText>
                    En confirmant vous remplacerez les menus existantes pour la semaine du{' '}
                    {formatDateAsAnniversary({ dateTime: date })}.
                </InfoText>
                <InfoText>Cette opération peut prendre un certain temps, ne la relancez pas en parallèle ;)</InfoText>
                <TotemPrimaryButton disabled={loading} onClick={handleClick}>
                    confirmer
                </TotemPrimaryButton>
            </TotemPopup>
            <TotemPrimaryButton
                disabled={
                    loading ||
                    statusLoading ||
                    polling ||
                    !!error ||
                    siteMenusGenerationStatus === PredictionServiceStatus.Loading ||
                    siteMenusGenerationStatus === PredictionServiceStatus.Error
                }
                onClick={() => setIsOpen(true)}
            >
                Menus
            </TotemPrimaryButton>
        </>
    );
}

const InfoText = styled.div`
    padding: 20px 0px;
`;

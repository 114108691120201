const startHour = 0;
const endHour = 23;

const ritualWindowHours: string[] = [];

for (let i = startHour; i <= endHour; i++) {
    ritualWindowHours.push(`${i}:00`);
    ritualWindowHours.push(`${i}:30`);
}

export const ritualWindowOptions = ritualWindowHours.map((hour) => ({ value: hour, label: hour }));

import React, { useContext, useEffect, useRef, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { FaAppleAlt, FaTrash, FaWarehouse } from 'react-icons/fa';
import { BiArrowFromTop } from 'react-icons/bi';

import { DetailValue } from 'components/DetailsView';
import { FieldToDisplay, ListView } from 'components/ListView';
import { Loader } from 'components/Loader';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { TotemInput } from 'components/TotemInput';
import { TotemCheckbox } from 'components/TotemCheckbox';

import {
    MicrostoreColumnFormValues,
    MicrostoreLocationFormValues,
    MicrostoreProductsStockInfoFormValues,
} from './FormHelper/DataMapper';
import { computeColorFromName } from 'helpers/computeColorFromName';
import { dateFromString, formatDateAsAnniversary } from 'helpers/dateTimes';

type FormattedProductType = {
    _id: string;
    fullname: string;
    fullnameComponent: JSX.Element;
    expiryDate: string;
    expiryDateComponent: JSX.Element;
    stockOnSite: number;
    stockAvailableWarehouse: number;
    stockAvailableWarehouseAfterReassortment: JSX.Element;
    stockAvailableInSupplyOrdersAfterReassortment: JSX.Element;
    stockExpectedInSupplyOrders: number;
    stockUsedInAllMicrostores: number;
};

function getExpiryDateColor(expiryDate: string, theme: any) {
    const today = new Date();
    if (formatDateAsAnniversary({ dateTime: today }) === expiryDate) {
        return theme.warningColor;
    }

    if (today > dateFromString(expiryDate)) {
        return theme.errorColor;
    }

    return theme.textColor;
}

export const LocationEditor = ({
    isToday,
    microstoreProductsStockInfo,
    selectedColumn,
    selectedLocation,
    updateSelectedLocation,
}: {
    isToday: boolean;
    microstoreProductsStockInfo: MicrostoreProductsStockInfoFormValues | null;
    selectedColumn: MicrostoreColumnFormValues;
    selectedLocation: MicrostoreLocationFormValues;
    updateSelectedLocation: (values: Partial<MicrostoreLocationFormValues>) => void;
}) => {
    const {
        expiryDates,
        locationTemplateId,
        microstoreLocationTemplate: { productArrangement, productArrangementSingleProductInfo },
        productId,
        replacementProductId,
        stockToAdd,
    } = selectedLocation;

    const theme = useContext(ThemeContext);
    const inputElement = useRef<HTMLInputElement | null>(null);

    const [displayProductsFromWarehouse, setDisplayProductsFromWarehouse] = useState<boolean>(false);
    const [displayProductsWithNoStock, setDisplayProductsWithNoStock] = useState<boolean>(false);
    const [filterString, setFilterString] = useState('');

    useEffect(() => {
        if (inputElement.current) {
            inputElement.current.focus();
            inputElement.current.select();
        }
    }, [inputElement, locationTemplateId, isToday]);

    function addProductToLocation(productId: string) {
        if (isToday) {
            // Todo, null liquidity here is a quick fix.
            // Getting the liquidity for all products takes times. Therefore we need to get it after selecting a product
            // But once the column is set, we should not change today product. So we don't need to update liquidity directly.
            updateSelectedLocation({
                productId,
            });
        } else {
            updateSelectedLocation({
                replacementProductId: productId,
                stockToAdd: 0,
            });
        }
    }

    if (!productArrangement && !productArrangementSingleProductInfo) {
        return (
            <Container>
                L'emplacement selectionné n'est pas lié à une gamme de produit (il s'agit sûrement d'un bug)
            </Container>
        );
    }

    if (!microstoreProductsStockInfo) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    const products = microstoreProductsStockInfo;

    const filteredProducts = products
        .filter(({ _id }) =>
            displayProductsFromWarehouse
                ? true
                : productArrangement
                  ? productArrangement?.productIdsWithStockMax?.find(({ productId }) => _id === productId)
                  : productArrangementSingleProductInfo?.product?._id === _id,
        )
        .filter(
            ({
                stockAvailableWarehouse,
                stockExpectedInSupplyOrders,
                stockOnSite,
                stockUsedInMicrostore,
                stockUsedInOtherMicrostores,
            }) =>
                displayProductsWithNoStock
                    ? true
                    : stockAvailableWarehouse !== 0 ||
                      stockExpectedInSupplyOrders !== 0 ||
                      stockOnSite !== 0 ||
                      stockUsedInMicrostore !== 0 ||
                      stockUsedInOtherMicrostores !== 0,
        )
        .filter(
            ({ name, brand, volume }) =>
                `${name} - ${brand} - ${volume}`.toLowerCase().search(filterString.toLowerCase()) !== -1,
        );

    const formattedProducts: FormattedProductType[] = filteredProducts.map(
        ({
            _id,
            name,
            brand,
            expiryDateRecordsWarehouse,
            stockAvailableWarehouse,
            stockExpectedInSupplyOrders,
            stockOnSite,
            stockUsedInMicrostore,
            stockUsedInOtherMicrostores,
            volume,
        }) => {
            const stockUsedInAllMicrostores = stockUsedInOtherMicrostores + stockUsedInMicrostore;
            const stockUsedFromWarehouse = Math.min(stockAvailableWarehouse, stockUsedInAllMicrostores);
            const stockNotAvailableUsed = Math.max(0, stockUsedInAllMicrostores - stockUsedFromWarehouse);

            const sortedExpiryDateRecords = [...expiryDateRecordsWarehouse].sort(
                (expiryDateRecordA, expiryDateRecordB) =>
                    dateFromString(expiryDateRecordA.expiryDate as string) <
                    dateFromString(expiryDateRecordB.expiryDate as string)
                        ? -1
                        : 1,
            );

            const expiryDateColor = sortedExpiryDateRecords.length
                ? getExpiryDateColor(sortedExpiryDateRecords[0].expiryDate, theme)
                : 'inherit';
            const expiryDateTitle = sortedExpiryDateRecords.reduce(
                (titleAcc, { expiryDate }) => `${titleAcc} ${expiryDate},`,
                '',
            );

            return {
                _id,
                fullname: `${name} - ${brand} - ${volume}`,
                fullnameComponent: (
                    <ProductName>
                        {name} - {brand} - {volume}
                    </ProductName>
                ),
                expiryDate: sortedExpiryDateRecords[0]?.expiryDate ?? null,
                expiryDateComponent: (
                    <ExpiryDate color={expiryDateColor} title={expiryDateTitle}>
                        {sortedExpiryDateRecords.length
                            ? (sortedExpiryDateRecords.length > 1 ? ' +' : '') + sortedExpiryDateRecords[0].expiryDate
                            : 'NA'}
                    </ExpiryDate>
                ),
                stockOnSite: stockOnSite,
                stockAvailableWarehouse,
                stockAvailableWarehouseAfterReassortment: (
                    <>
                        <span>{stockAvailableWarehouse - stockUsedFromWarehouse - stockNotAvailableUsed}</span>
                        {stockUsedFromWarehouse + stockNotAvailableUsed > 0 ? (
                            <SubValue> ({stockAvailableWarehouse})</SubValue>
                        ) : null}
                    </>
                ),
                stockAvailableInSupplyOrdersAfterReassortment: (
                    <>
                        <span>{stockExpectedInSupplyOrders}</span>
                    </>
                ),
                stockExpectedInSupplyOrders,
                stockUsedInAllMicrostores,
            };
        },
    );

    const sortedFormattedProducts = [...formattedProducts];
    if (isToday) {
        sortedFormattedProducts.sort((productA, productB) => (productA.stockOnSite < productB.stockOnSite ? 1 : -1));
    } else {
        sortedFormattedProducts.sort((productA, productB) =>
            productA.stockAvailableWarehouse < productB.stockAvailableWarehouse ? 1 : -1,
        );
    }
    if (isToday || !replacementProductId) {
        sortedFormattedProducts.sort((productA, productB) =>
            productA._id === productId ? -1 : productB._id === productId ? 1 : 0,
        );
    } else {
        sortedFormattedProducts.sort((productA, productB) =>
            productA._id === replacementProductId ? -1 : productB._id === replacementProductId ? 1 : 0,
        );
    }

    const selectedProduct = microstoreProductsStockInfo.find(
        ({ _id }) => _id === (isToday ? productId : replacementProductId),
    );

    const stockToRemove = expiryDates.reduce(
        (stockToRemoveAcc, expiryDate) =>
            stockToRemoveAcc + (expiryDate.shouldRemoveFromSite ? expiryDate.quantity : 0),
        0,
    );

    const isRelocated = selectedColumn.shelves.some((shelf) =>
        shelf.locationTable.some((locationRow) =>
            locationRow.some((otherLocation) => productId === otherLocation.replacementProductId),
        ),
    );

    const PRODUCTS_FIELDS_TO_DISPLAY: FieldToDisplay<FormattedProductType>[] = [
        {
            fieldName: 'fullnameComponent',
            label: 'Nom',
            maxWidth: '100px',
            sortFunction: (productA, productB) =>
                (productA.fullname as string).localeCompare(productB.fullname as string),
        },
    ];

    if (isToday) {
        PRODUCTS_FIELDS_TO_DISPLAY.push({
            fieldName: 'stockOnSite',
            label: 'Stock Site',
            maxWidth: '30px',
            sortFunction: (productA, productB) => (productA.stockOnSite < productB.stockOnSite ? 1 : -1),
        });
    } else {
        PRODUCTS_FIELDS_TO_DISPLAY.push(
            {
                fieldName: 'stockAvailableWarehouseAfterReassortment',
                label: 'S. Disp. Entrepot',
                maxWidth: '30px',
                sortFunction: (productA, productB) =>
                    productA.stockAvailableWarehouse < productB.stockAvailableWarehouse ? 1 : -1,
            },
            {
                fieldName: 'stockAvailableInSupplyOrdersAfterReassortment',
                label: 'S. Arr Demain',
                maxWidth: '30px',
                sortFunction: (productA, productB) =>
                    productA.stockExpectedInSupplyOrders < productB.stockExpectedInSupplyOrders ? 1 : -1,
            },
            {
                fieldName: 'stockUsedInAllMicrostores',
                label: 'Qté utilisée pour réassort',
                maxWidth: '30px',
            },
            {
                fieldName: 'expiryDateComponent',
                label: 'DLC',
                maxWidth: '30px',
                sortFunction: (productA, productB) =>
                    productA.expiryDate !== null &&
                    (productB.expiryDate === null ||
                        dateFromString(productA.expiryDate as string) < dateFromString(productB.expiryDate as string))
                        ? -1
                        : 1,
            },
        );
    }

    return (
        <Container>
            <HeaderContainer>
                <ButtonsContainer>
                    <Button
                        type="button"
                        title="Retirer produit"
                        onClick={() => {
                            if (isToday) {
                                updateSelectedLocation({
                                    productId: null,
                                });
                            } else {
                                updateSelectedLocation({
                                    replacementProductId: null,
                                    stockToAdd: 0,
                                });
                            }
                        }}
                    >
                        <FaTrash size={13} />
                    </Button>
                    <Button
                        type="button"
                        title="Garder le même produit de J à J+1"
                        onClick={() => {
                            updateSelectedLocation({
                                replacementProductId: productId,
                                stockToAdd: 0,
                            });
                        }}
                    >
                        <BiArrowFromTop size={13} />
                    </Button>
                </ButtonsContainer>
                <ProductsOriginTitle>
                    {displayProductsFromWarehouse ? (
                        <ProductsOriginLabel>Entrepôt</ProductsOriginLabel>
                    ) : productArrangement ? (
                        <>
                            <ProductArrangementColor color={computeColorFromName(productArrangement.name)} />
                            <ProductsOriginLabel color={computeColorFromName(productArrangement.name)}>
                                {productArrangement.name}
                            </ProductsOriginLabel>
                        </>
                    ) : (
                        <>
                            <ProductsOriginLabel>Produit seul</ProductsOriginLabel>
                        </>
                    )}
                </ProductsOriginTitle>
                <ButtonsContainer>
                    <TotemCheckbox
                        checked={displayProductsWithNoStock}
                        label="0"
                        onChange={() => setDisplayProductsWithNoStock(!displayProductsWithNoStock)}
                    />
                    <Button
                        type="button"
                        title={
                            displayProductsFromWarehouse ? 'Produit de la gamme de produit' : "Produit de l'entrepôt"
                        }
                        onClick={() => setDisplayProductsFromWarehouse(!displayProductsFromWarehouse)}
                    >
                        {displayProductsFromWarehouse ? <FaAppleAlt size={13} /> : <FaWarehouse size={13} />}
                    </Button>
                </ButtonsContainer>
            </HeaderContainer>

            <ProductSelectorContainer>
                <TotemInput
                    inputRef={inputElement}
                    onChange={setFilterString}
                    placeholder="Nom du produit"
                    value={filterString}
                    autoFocus={true}
                />
                <ProductListContainer>
                    <ListView<FormattedProductType>
                        fieldsToDisplay={PRODUCTS_FIELDS_TO_DISPLAY}
                        items={sortedFormattedProducts}
                        keyExtractor={(item) => item._id}
                        onItemClick={(item) => {
                            if (item._id) {
                                addProductToLocation(item._id as string);
                            }
                        }}
                        highlightedKeys={selectedProduct ? [selectedProduct._id] : []}
                        isSmallDisplay={true}
                    />
                </ProductListContainer>
            </ProductSelectorContainer>

            <StockInputContainer>
                {replacementProductId ? (
                    <TotemInput
                        label="Stock à (re)mettre"
                        type="number"
                        placeholder="0"
                        value={stockToAdd || ''}
                        onChange={(value) => {
                            const newStockToAdd = parseInt(value) || 0;

                            updateSelectedLocation({
                                stockToAdd: newStockToAdd,
                            });
                        }}
                        width="100%"
                        step="1"
                        min="0"
                        required={true}
                    />
                ) : null}
                <DetailValue label="Stock à retirer" value={isRelocated ? stockToRemove : 'Tous'} />
            </StockInputContainer>
        </Container>
    );
};

const Container = styled.div`
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
`;

const HeaderContainer = styled.div`
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const ProductsOriginTitle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
`;

const ProductArrangementColor = styled.div`
    width: 15px;
    height: 2px;
    background-color: ${({ theme, color }) => color ?? theme.textColor};
`;

const ProductsOriginLabel = styled.span`
    color: ${({ theme, color }) => color ?? theme.textColor};
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Button = styled(TotemPrimaryButton)`
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ProductSelectorContainer = styled.div`
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

const ProductListContainer = styled.div`
    margin-top: 5px;
    overflow: hidden;
`;

const ProductName = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const StockInputContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 10px;
    border-top: 2px solid ${({ theme }) => theme.darkBorderColor};

    & > :not(:first-child) {
        margin-left: 20px;
    }
`;

const SubValue = styled.span`
    font-size: 13px;
    color: ${({ theme }) => theme.infoTextColor};
`;

const ExpiryDate = styled.span<{ color: string }>`
    color: ${({ color }) => color};
`;

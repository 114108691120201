import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_SUBCATEGORIES_OF_CATEGORY_QUERY } from 'data/queries/subcategory';
import {
    GetSubcategoriesOfCategory,
    GetSubcategoriesOfCategoryVariables,
} from 'data/queries/__generated__/GetSubcategoriesOfCategory';
import styled from 'styled-components';
import { SubcategoryState } from 'data/__generated__';
import { TotemSelect } from 'components/TotemSelect';

export const SubcategorySelector = ({
    subcategoryId,
    categoryId,
    onChange,
}: {
    subcategoryId?: string | null;
    categoryId: string;
    onChange: (value: string | null) => void;
}) => {
    const { data: subcategoriesData } = useQuery<GetSubcategoriesOfCategory, GetSubcategoriesOfCategoryVariables>(
        GET_SUBCATEGORIES_OF_CATEGORY_QUERY,
        { variables: { categoryId } },
    );

    if (!subcategoriesData) {
        return null;
    }

    const { subcategoriesOfCategory } = subcategoriesData;

    const subcategoryOptions = subcategoriesOfCategory
        .filter(({ state }) => state === SubcategoryState.Regular)
        .map(({ _id, name }) => ({ value: _id, label: name }));

    const initiallySelectedOption = subcategoryOptions.find(({ value }) => value === subcategoryId);

    return (
        <Row key={categoryId}>
            ↳
            <TotemSelect<string>
                label="Sous-catégorie"
                placeholder="Sélectionner une catégorie"
                value={initiallySelectedOption}
                name="subcategoryId"
                options={subcategoryOptions}
                dataTest="product-subcategory"
                onChange={(option) => {
                    if (option) {
                        onChange(option.value);
                    }
                }}
            />
        </Row>
    );
};

const Row = styled.div`
    display: flex;
    flex-direction: row;
    color: white;
    gap: 5px;
`;

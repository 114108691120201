import React, { useState } from 'react';

import ReactDatePicker from 'react-datepicker';
import { Controller, useFormContext } from 'react-hook-form';
import { FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { BasicInput } from 'components/BasicInput';
import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import {
    SupplyOrderDetailsDisplayValues,
    SupplyOrderProductReceivedDetailWithoutTypeName,
} from 'pages/SupplyOrders/SupplyOrderDetails/supplyOrderDetailsFormHelper';

import { dateFromString, formatDateAsAnniversary } from 'helpers/dateTimes';
import { getSupplyOrderProductReceivedTotal } from 'pages/SupplyOrders/helper/getSupplyOrderProductReceivedTotal';

export function areExpiryDatesUnique(newProductReceivedDetails: SupplyOrderProductReceivedDetailWithoutTypeName[]) {
    return (
        new Set(newProductReceivedDetails.map((productReceivedDetail) => productReceivedDetail.expiryDate)).size ===
        newProductReceivedDetails.length
    );
}

export function SupplyOrderProductInfoReceivedDetailsPopup({
    isOpen,
    setIsOpen,
    productIndex,
    supplyOrderDetailsDisplayValues,
}: {
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
    productIndex: number | null;
    supplyOrderDetailsDisplayValues: SupplyOrderDetailsDisplayValues;
}) {
    const [addDetailExpiryDate, setAddDetailExpiryDate] = useState<string | null>(null);
    const { control } = useFormContext();

    if (productIndex === null) {
        return null;
    }

    const product = supplyOrderDetailsDisplayValues.productsWithInfo[productIndex];

    if (!product) {
        return null;
    }

    const productReceivedDetails = product.productReceivedDetails;

    return (
        <TotemPopup
            title="Changer les quantités reçues"
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            contentOverflow="visible"
        >
            <Container>
                <Controller
                    control={control}
                    name={`productsWithInfo.${productIndex}.productReceivedDetails`}
                    defaultValue={productReceivedDetails}
                    render={({ field: { onChange, value } }) => {
                        function onDetailChange(
                            productReceivedDetailIndex: number,
                            productReceivedDetail: SupplyOrderProductReceivedDetailWithoutTypeName,
                        ) {
                            const newProductReceivedDetails = [...value];
                            newProductReceivedDetails[productReceivedDetailIndex] = {
                                ...productReceivedDetail,
                                colis: Math.floor(
                                    productReceivedDetail.conditionning
                                        ? productReceivedDetail.quantity / productReceivedDetail.conditionning
                                        : productReceivedDetail.quantity,
                                ),
                            };

                            if (!areExpiryDatesUnique(newProductReceivedDetails)) {
                                toast.error('Vous ne pouvez avoir deux DLC identiques');
                                return;
                            }

                            onChange(newProductReceivedDetails);
                        }

                        function addDetail(expiryDate: string | null) {
                            const newProductReceivedDetails = [
                                ...value,
                                {
                                    brokenItems: 0,
                                    colis: 0,
                                    conditionning: product.conditionning,
                                    expiryDate,
                                    quantity: 0,
                                },
                            ];

                            if (!areExpiryDatesUnique(newProductReceivedDetails)) {
                                toast.error('Vous ne pouvez avoir deux DLC identiques');
                                return;
                            }
                            onChange(newProductReceivedDetails);
                        }

                        function removeDetail(productReceivedDetailIndex: number) {
                            const newProductReceivedDetails = [
                                ...value.slice(0, productReceivedDetailIndex),
                                ...value.slice(productReceivedDetailIndex + 1),
                            ];
                            onChange(newProductReceivedDetails);
                        }

                        const { brokenItemsReceived, colisReceived, conditionningReceived, quantityReceived } =
                            getSupplyOrderProductReceivedTotal(value);

                        return (
                            <>
                                <ReceivedDetailsTable>
                                    <thead>
                                        <tr>
                                            <ReceivedDetailsHeaderCell>Quantité reçue</ReceivedDetailsHeaderCell>
                                            <ReceivedDetailsHeaderCell>Quantité reçue cassée</ReceivedDetailsHeaderCell>
                                            <ReceivedDetailsHeaderCell>Nb. Colis reçus</ReceivedDetailsHeaderCell>
                                            <ReceivedDetailsHeaderCell>DLC</ReceivedDetailsHeaderCell>
                                            <ReceivedDetailsHeaderCell>Colisage reçu</ReceivedDetailsHeaderCell>
                                            <ReceivedDetailsHeaderCell />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {value.map(
                                            (
                                                productReceivedDetail: SupplyOrderProductReceivedDetailWithoutTypeName,
                                                productReceivedDetailIndex: number,
                                            ) => {
                                                const { brokenItems, colis, conditionning, expiryDate, quantity } =
                                                    productReceivedDetail;
                                                return (
                                                    <ReceivedDetailsRow key={productReceivedDetailIndex}>
                                                        <ReceivedDetailsCell>
                                                            <BasicInput
                                                                value={quantity}
                                                                onChange={(e) => {
                                                                    const newQuantity = parseInt(e.target.value) || 0;
                                                                    onDetailChange(productReceivedDetailIndex, {
                                                                        ...productReceivedDetail,
                                                                        quantity: newQuantity,
                                                                    });
                                                                }}
                                                                centerText
                                                                width="100%"
                                                                type="number"
                                                                step="1"
                                                                min="0"
                                                                required
                                                            />
                                                        </ReceivedDetailsCell>
                                                        <ReceivedDetailsCell>
                                                            <BasicInput
                                                                value={brokenItems}
                                                                onChange={(e) => {
                                                                    const newBrokenItems =
                                                                        parseInt(e.target.value) || 0;
                                                                    onDetailChange(productReceivedDetailIndex, {
                                                                        ...productReceivedDetail,
                                                                        brokenItems: newBrokenItems,
                                                                    });
                                                                }}
                                                                centerText
                                                                width="100%"
                                                                type="number"
                                                                step="1"
                                                                min="0"
                                                                required
                                                            />
                                                        </ReceivedDetailsCell>
                                                        <ReceivedDetailsCell>{`${colis} (+${
                                                            conditionning ? quantity % conditionning : 0
                                                        }p)`}</ReceivedDetailsCell>
                                                        <ReceivedDetailsCell>
                                                            <ReactDatePicker
                                                                selected={
                                                                    expiryDate ? dateFromString(expiryDate) : null
                                                                }
                                                                onChange={(date) => {
                                                                    if (Array.isArray(date)) {
                                                                        return;
                                                                    }
                                                                    const newExpiryDate = date
                                                                        ? formatDateAsAnniversary({ dateTime: date })
                                                                        : null;

                                                                    onDetailChange(productReceivedDetailIndex, {
                                                                        ...productReceivedDetail,
                                                                        expiryDate: newExpiryDate,
                                                                    });
                                                                }}
                                                                placeholderText="DLC"
                                                                locale="fr"
                                                                isClearable={true}
                                                                dateFormat="dd/MM/yyyy"
                                                                customInput={<BasicInput />}
                                                            />
                                                        </ReceivedDetailsCell>
                                                        <ReceivedDetailsCell>
                                                            <BasicInput
                                                                value={conditionning}
                                                                onChange={(e) => {
                                                                    const newConditionning =
                                                                        parseInt(e.target.value) || 1;
                                                                    onDetailChange(productReceivedDetailIndex, {
                                                                        ...productReceivedDetail,
                                                                        conditionning: newConditionning,
                                                                    });
                                                                }}
                                                                centerText
                                                                width="100%"
                                                                type="number"
                                                                step="1"
                                                                min="1"
                                                                required
                                                            />
                                                        </ReceivedDetailsCell>
                                                        <ReceivedDetailsCell>
                                                            <TotemPrimaryButton
                                                                type="button"
                                                                onClick={() => removeDetail(productReceivedDetailIndex)}
                                                            >
                                                                <FaTrash size={13} />
                                                            </TotemPrimaryButton>
                                                        </ReceivedDetailsCell>
                                                    </ReceivedDetailsRow>
                                                );
                                            },
                                        )}
                                    </tbody>
                                    <tfoot>
                                        <ReceivedDetailsFooterRow>
                                            <ReceivedDetailsFooterCell>{quantityReceived}</ReceivedDetailsFooterCell>
                                            <ReceivedDetailsFooterCell>{brokenItemsReceived}</ReceivedDetailsFooterCell>
                                            <ReceivedDetailsFooterCell>{colisReceived}</ReceivedDetailsFooterCell>
                                            <ReceivedDetailsFooterCell />
                                            <ReceivedDetailsFooterCell>
                                                {conditionningReceived}
                                            </ReceivedDetailsFooterCell>
                                            <ReceivedDetailsFooterCell />
                                        </ReceivedDetailsFooterRow>
                                    </tfoot>
                                </ReceivedDetailsTable>

                                <AddDetailContainer>
                                    <ReactDatePicker
                                        selected={addDetailExpiryDate ? dateFromString(addDetailExpiryDate) : null}
                                        onChange={(date) => {
                                            if (Array.isArray(date)) {
                                                return;
                                            }
                                            const newExpiryDate = date
                                                ? formatDateAsAnniversary({ dateTime: date })
                                                : null;
                                            setAddDetailExpiryDate(newExpiryDate);
                                        }}
                                        placeholderText="Nouvelle DLC"
                                        locale="fr"
                                        isClearable={true}
                                        dateFormat="dd/MM/yyyy"
                                        customInput={<BasicInput />}
                                    />

                                    <TotemPrimaryButton type="button" onClick={() => addDetail(addDetailExpiryDate)}>
                                        Ajouter une DLC
                                    </TotemPrimaryButton>
                                </AddDetailContainer>
                            </>
                        );
                    }}
                />
                <TotemPrimaryButton type="button" onClick={() => setIsOpen(false)}>
                    Fermer
                </TotemPrimaryButton>
            </Container>
        </TotemPopup>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    & > :not(:first-child) {
        margin-top: 15px;
    }
`;

const ReceivedDetailsTable = styled.table`
    margin-top: 15px;
    border-collapse: collapse;
    background-color: ${({ theme }) => theme.cardBackgroundColor};
    border: ${({ theme }) => `1px solid ${theme.lightBorderColor}`};
`;

const ReceivedDetailsHeaderCell = styled.th`
    padding: 15px;
    font-weight: 800;
    color: ${({ theme }) => theme.ctaPrimaryColor};
    text-align: center;
`;

const ReceivedDetailsRow = styled.tr`
    border-top: ${({ theme }) => `1px solid ${theme.lightBorderColor}`};
`;

const ReceivedDetailsCell = styled.td`
    padding: 15px;
    color: ${({ theme }) => theme.textColor};
`;

const ReceivedDetailsFooterRow = styled.tr`
    border-top: ${({ theme }) => `1px solid ${theme.lightBorderColor}`};
`;

const ReceivedDetailsFooterCell = styled.th`
    padding: 15px;
    font-weight: 800;
    color: ${({ theme }) => theme.ctaPrimaryColor};
    text-align: center;
`;

const AddDetailContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    & > :not(:first-child) {
        margin-left: 15px;
    }
`;

import { PRODUCT_GROUP_FRAGMENT } from 'data/fragments/productGroup';
import gql from 'graphql-tag';

import {
    CATALOG_PRODUCT_FRAGMENT,
    PRODUCT_FRAGMENT,
    PRODUCT_WITH_STOCK_VARIATIONS_FRAGMENT,
} from '../fragments/product';

export const GET_TRANSFERABLE_PRODUCTS_QUERY = gql`
    query GET_TRANSFERABLE_PRODUCTS($originSiteId: ID!, $destinationSiteId: ID!, $transferDate: String) {
        transferableProducts(
            originSiteId: $originSiteId
            destinationSiteId: $destinationSiteId
            transferDate: $transferDate
        ) {
            productId
            fullname
            originStockAvailable
            destinationStockAvailable
            quantity
        }
    }
`;

export const GET_TRANSFERABLE_PRODUCTS_FROM_CSV_QUERY = gql`
    query GET_TRANSFERABLE_PRODUCTS_FROM_CSV(
        $originSiteId: ID!
        $destinationSiteId: ID!
        $csvData: [TransferCsvData]!
        $isDestinationQuantity: Boolean!
    ) {
        transferableProductsFromCsv(
            originSiteId: $originSiteId
            destinationSiteId: $destinationSiteId
            csvData: $csvData
            isDestinationQuantity: $isDestinationQuantity
        ) {
            transferableProducts {
                productId
                fullname
                originStockAvailable
                destinationStockAvailable
                quantity
            }
            idsNotFound
            idsArchived
        }
    }
`;

export const GET_PRODUCTS_WITH_STOCKS_QUERY = gql`
    query GET_PRODUCTS_WITH_STOCKS($siteId: ID!) {
        productsWithStocks(siteId: $siteId) {
            _id
            name
            brand
            stock(siteId: $siteId)
            volume
        }
    }
`;

export const GET_PRODUCTS_WITH_STOCK_BY_SITES_QUERY = gql`
    query GET_PRODUCTS_WITH_STOCK_BY_SITES($siteIds: [ID!]!) {
        productsWithStockBySites(siteIds: $siteIds) {
            _id
            brand
            categoryId
            expiryDateRecordsWarehouse {
                _id
                expiryDate
                initialQuantity
                quantityAtInventory
            }
            flow
            name
            stockBySites {
                siteId
                stock
                stockAvailable
            }
            trackExpiryDate
            volume
        }
    }
`;

export const GET_CATALOG_PRODUCTS_QUERY = gql`
    query GET_CATALOG_PRODUCTS(
        $offset: Int!
        $filterString: String
        $stateFilter: [String]
        $categoryFilter: [String]
        $subcategoryFilter: [ID!]
        $supplierFilter: [String]
        $tagFilter: [String]
        $infoFilters: [InfoType!]
    ) {
        catalogProducts(
            offset: $offset
            filterString: $filterString
            stateFilter: $stateFilter
            categoryFilter: $categoryFilter
            subcategoryFilter: $subcategoryFilter
            supplierFilter: $supplierFilter
            tagFilter: $tagFilter
            infoFilters: $infoFilters
        ) {
            hasMore
            products {
                ...CatalogProductFragment
            }
        }
    }
    ${CATALOG_PRODUCT_FRAGMENT}
`;

export const GET_DETAILED_PRODUCT_QUERY = gql`
    query GET_DETAILED_PRODUCT($productId: ID!) {
        detailedProduct(productId: $productId) {
            _id
            comment
            ...ProductFragment
            isOutOfStock
            location {
                _id
                datamatrix
            }
            productGroup {
                ...ProductGroupFragment
            }
            productGroupsUsedIn {
                ...ProductGroupFragment
            }
            shouldOrderOnce
        }
    }
    ${PRODUCT_FRAGMENT}
    ${PRODUCT_GROUP_FRAGMENT}
`;

export const GET_NON_ARCHIVED_PRODUCTS_QUERY = gql`
    query GET_NON_ARCHIVED_PRODUCTS {
        nonArchivedProducts {
            _id
            name
            brand
            volume
        }
    }
`;

export const GET_ALL_PRODUCTS_QUERY = gql`
    query GET_ALL_PRODUCTS {
        getAllProducts {
            _id
            brand
            categoryId
            fullname
            height
            imageUrl
            isMicrostore
            shouldBeHiddenInStore
            isToBeArchived
            length
            name
            state
            volume
            weight
            width
        }
    }
`;

export const GET_AVAILABLE_FOR_MICROSTORE_QUERY = gql`
    query GET_AVAILABLE_FOR_MICROSTORE {
        availableForMicrostore {
            _id
            brand
            fullname
            height
            imageUrl
            isMicrostore
            shouldBeHiddenInStore
            length
            name
            state
            volume
            weight
            width
        }
    }
`;

export const GET_OFF_DATA_QUERY = gql`
    query GET_OFF_DATA($barcodes: [String!]!) {
        OFFProductData(barcodes: $barcodes) {
            success
            error
            productNutritionData {
                allergens
                ingredients
                nutrition {
                    carbohydrates
                    energyKcal
                    fat
                    fiber
                    proteins
                    saturatedFat
                    sodium
                    sugars
                }
                nutriscore
                traces
            }
        }
    }
`;

export const GET_PRODUCT_STOCK_QUERY = gql`
    query GET_PRODUCT_STOCK($productId: ID!) {
        productStock(productId: $productId) {
            success
            error
            sitesWithStock {
                name
                stock
            }
        }
    }
`;

export const GET_PRODUCTS_BY_CATEGORY_QUERY = gql`
    query GET_PRODUCTS_BY_CATEGORY($categoryId: ID!) {
        getProductsByCategory(categoryId: $categoryId) {
            _id
            availableStock
            name
            portion
        }
    }
`;

export const GET_TAG_PRODUCTS_QUERY = gql`
    query GET_TAG_PRODUCTS($tagId: ID!) {
        productsWithTagId(tagId: $tagId) {
            _id
            fullname
        }
    }
`;

export const GET_SITE_PRODUCTS_WITH_LATEST_STOCKS_QUERY = gql`
    query GET_SITE_PRODUCTS_WITH_LATEST_STOCKS($siteId: ID!) {
        getProductsWithLatestVariations(siteId: $siteId) {
            ...ProductWithStockVariations
        }
    }
    ${PRODUCT_WITH_STOCK_VARIATIONS_FRAGMENT}
`;

export const GET_SITE_PRODUCT_WITH_STOCKS_QUERY = gql`
    query GET_SITE_PRODUCT_WITH_STOCKS($siteId: ID!, $productId: ID!) {
        getProductWithVariations(siteId: $siteId, productId: $productId) {
            ...ProductWithStockVariations
        }
    }
    ${PRODUCT_WITH_STOCK_VARIATIONS_FRAGMENT}
`;

export const GET_MICROSTORE_PRODUCTS_QUERY = gql`
    query GET_MICROSTORE_PRODUCTS {
        getMicrostoreProducts {
            _id
            brand
            flow
            imageUrl
            name
            trackExpiryDate
            volume
        }
    }
`;

export const GET_PRODUCT_PREPARATION_DELAY_QUERY = gql`
    query GET_PRODUCT_PREPARATION_DELAY($productId: ID!) {
        productPreparationDelay(productId: $productId)
    }
`;

export const GET_NAYAX_PRODUCTS_QUERY = gql`
    query GET_NAYAX_PRODUCTS {
        getNayaxProducts {
            _id
            fullname
            imageUrl
            isMicrostore
            state
        }
    }
`;

export const GET_PRODUCTS_BY_ID_QUERY = gql`
    query GET_PRODUCTS_BY_ID($productIds: [ID!]!) {
        getProductsById(productIds: $productIds) {
            _id
            fullname
        }
    }
`;

export const GET_SUPPLIER_PRODUCTS_QUERY = gql`
    query GET_SUPPLIER_PRODUCTS($supplierId: ID!) {
        getSupplierProducts(supplierId: $supplierId) {
            _id
            fullname
        }
    }
`;

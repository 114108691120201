import gql from 'graphql-tag';

import {
    MICROSTORE_COLUMN_TEMPLATE_FRAGMENT,
    MICROSTORE_COLUMN_TEMPLATE_WITH_ALL_INFO,
} from '../fragments/microstoreColumnTemplate';

export const GET_MICROSTORE_COLUMN_TEMPLATES_QUERY = gql`
    query GET_MICROSTORE_COLUMN_TEMPLATES {
        microstoreColumnTemplatesQuery {
            ...MicrostoreColumnTemplateFragment
        }
    }
    ${MICROSTORE_COLUMN_TEMPLATE_FRAGMENT}
`;

export const GET_MICROSTORE_COLUMN_TEMPLATE_QUERY = gql`
    query GET_MICROSTORE_COLUMN_TEMPLATE($microstoreColumnTemplateId: String!) {
        microstoreColumnTemplateQuery(microstoreColumnTemplateId: $microstoreColumnTemplateId) {
            ...MicrostoreColumnTemplateWithAllInfo
        }
    }
    ${MICROSTORE_COLUMN_TEMPLATE_WITH_ALL_INFO}
`;

import gql from 'graphql-tag';

export const MESSAGES_SET_FRAGMENT = gql`
    fragment MessagesSetFragment on MessagesSet {
        _id
        createdAt
        name
        messages {
            body
            redirectUrl
        }
        state
        updatedAt
    }
`;

import React from 'react';

import { CommonStatusTag } from 'components/CommonStatusTag';

import { colors } from 'constants/colors';

import { ExpiryDateRecordState } from 'data/__generated__';
import { EXPIRY_DATE_RECORD_STATES_LABELS } from '../constants/states';

const getColorsFromState = (state: ExpiryDateRecordState) => {
    switch (state) {
        case ExpiryDateRecordState.Regular:
            return { backgroundColor: colors.green, labelColor: colors.black };
        case ExpiryDateRecordState.Archived:
            return { backgroundColor: colors.pantinGrey, labelColor: colors.pureWhite };
    }
};

export const StatusTag = ({ state }: { state: ExpiryDateRecordState }) => {
    const { backgroundColor, labelColor } = getColorsFromState(state);
    return (
        <CommonStatusTag
            backgroundColor={backgroundColor}
            labelColor={labelColor}
            label={EXPIRY_DATE_RECORD_STATES_LABELS[state]}
        />
    );
};

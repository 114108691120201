import gql from 'graphql-tag';
import { PRODUCT_GROUP_FRAGMENT } from 'data/fragments/productGroup';

export const CREATE_PRODUCT_GROUP_MUTATION = gql`
    mutation CREATE_PRODUCT_GROUP($productId: ID!) {
        createProductGroup(productId: $productId) {
            ...ProductGroupFragment
        }
    }
    ${PRODUCT_GROUP_FRAGMENT}
`;

export const UPDATE_PRODUCT_GROUP_MUTATION = gql`
    mutation UPDATE_PRODUCT_GROUP($composition: [ProductGroupCompositionEntryInput!]!, $productGroupId: ID!) {
        updateProductGroup(composition: $composition, productGroupId: $productGroupId) {
            ...ProductGroupFragment
        }
    }
    ${PRODUCT_GROUP_FRAGMENT}
`;

import React from 'react';
import { IconSearch, IconClock } from '@tabler/icons';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { GET_ACTIVE_OR_FOR_DATE_ORDERS_activeOrForDateOrders } from 'data/queries/__generated__/GET_ACTIVE_OR_FOR_DATE_ORDERS';
import { OrderState } from 'data/__generated__';
import { getColorsFromState } from '../constants/states';

import { TotemCheckbox } from 'components/TotemCheckbox';
import { DELIVERY_DAYS } from 'pages/Products/constants/weekdays';
import { colors } from 'constants/colors';
import { formatDateAsAnniversary } from 'helpers/dateTimes';

export const OrderTrelloItem = ({
    order,
    isSelected,
    toggleSelection,
    shouldShowState = false,
}: {
    order: GET_ACTIVE_OR_FOR_DATE_ORDERS_activeOrForDateOrders;
    isSelected: boolean;
    toggleSelection: () => void;
    shouldShowState?: boolean;
}) => {
    const {
        _id: orderId,
        date_delivery: deliveryDate,
        isPunctual,
        number,
        organization: { name },
        pausedInfo,
        prices: { priceTTC, priceHT },
        state,
        totem: { number: totemNumber },
        weekday,
    } = order;

    const { backgroundColor, labelColor } = getColorsFromState(state);
    const translatedWeekday = DELIVERY_DAYS.find(({ name }) => name === weekday)?.label || weekday;
    return (
        <ItemContent isPunctual={isPunctual}>
            <ItemHeader backgroundColor={backgroundColor} labelColor={labelColor}>
                <TotemCheckbox checked={isSelected} onChange={toggleSelection} />
                <HeaderTitle>
                    {name} N°{number} {isPunctual ? <PunctualIcon size="15" /> : null}
                </HeaderTitle>
                <DetailIconLink to={`/order/${orderId}`}>
                    <IconSearch color={colors.pureWhite} size="15" />
                </DetailIconLink>
            </ItemHeader>
            <ItemBody>
                <Details>
                    <Detail>
                        <DetailLabel>
                            Totem <b>N°{totemNumber}</b> • Order <b>N°{number}</b> • <b>{translatedWeekday}</b>
                        </DetailLabel>
                    </Detail>
                    {state === OrderState.Paused ? (
                        <Detail>
                            <DetailLabel>Date de retour</DetailLabel>
                            <DetailValue>
                                {(pausedInfo?.returnDate &&
                                    formatDateAsAnniversary({ dateTime: new Date(pausedInfo?.returnDate) })) ||
                                    'Inconnue'}
                            </DetailValue>
                        </Detail>
                    ) : null}
                    <Detail>
                        <DetailLabel>Date de livraison</DetailLabel>
                        <DetailValue>{deliveryDate}</DetailValue>
                    </Detail>
                    <Detail>
                        <DetailLabel>Prix HT</DetailLabel>
                        <DetailValue>{`${priceHT}€`}</DetailValue>
                    </Detail>
                    <Detail>
                        <DetailLabel>Prix TTC</DetailLabel>
                        <DetailValue>{`${priceTTC}€`}</DetailValue>
                    </Detail>
                    {shouldShowState ? (
                        <Detail>
                            <DetailLabel>Statut</DetailLabel>
                            <DetailValue>{state}</DetailValue>
                        </Detail>
                    ) : null}
                </Details>
            </ItemBody>
        </ItemContent>
    );
};

const ItemContent = styled.div<{ isPunctual: boolean }>`
    display: flex;
    flex-direction: column;
    border-radius: ${({ theme }) => theme.borderRadius};
    border: 1px solid ${colors.borderGrey};
    background-color: ${({ theme }) => theme.cardBackgroundColor};
    color: ${({ theme }) => theme.textColor};
    cursor: default;
`;

const ItemHeader = styled.div<{ backgroundColor: string; labelColor: string }>`
    border-bottom: 1px solid ${colors.pantinGrey};
    border-radius: 3px 3px 0 0;
    background-color: ${({ backgroundColor }) => backgroundColor};
    color: ${({ labelColor }) => labelColor};
    padding: 5px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const HeaderTitle = styled.div`
    display: flex;
    margin: 5px 10px;
    align-items: center;
`;

const ItemBody = styled.div`
    padding: 5px 10px;
`;

const DetailIconLink = styled(Link)`
    display: flex;
    align-items: center;
    cursor: pointer;
`;

const Details = styled.div`
    display: flex;
    flex-direction: column;
    margin: 10px 0;
`;

const Detail = styled.div<{ isHighlighted?: boolean }>`
    display: flex;
    justify-content: space-between;
    color: ${({ isHighlighted, theme }) => (isHighlighted ? theme.errorColor : 'inherit')};

    &:not(:first-child) {
        margin-top: 5px;
    }
`;

const DetailLabel = styled.span`
    font-size: 14px;
`;

const DetailValue = styled.span`
    font-size: 14px;
    font-weight: 800;
    margin-left: 5px;
    text-align: right;
`;

const PunctualIcon = styled(IconClock)`
    margin-left: 5px;
    color: black;
`;

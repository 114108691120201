const deliveryWindowHours = [
    '7:00',
    '7:30',
    '8:00',
    '8:30',
    '9:00',
    '9:30',
    '10:00',
    '10:30',
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '13:00',
];
export const deliveryWindowOptions = deliveryWindowHours.map((hour) => ({ value: hour, label: hour }));

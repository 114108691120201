import React from 'react';

import {
    useAbortWarehouseDeliveryMutation,
    useArchiveWarehouseDeliveryMutation,
    useBeginWarehouseDeliveryPreparationMutation,
    useFinishWarehouseDeliveryPreparationMutation,
    useMarkWarehouseDeliveryAsDeliveredMutation,
    useMarkWarehouseDeliveryAsInProgressMutation,
    WarehouseDeliveryFragmentFragment,
    WarehouseDeliveryState,
} from 'data/__generated__';

import { SectionColumn, SectionContainer, ColumnsSectionContainer } from 'components/DetailsView/Section';
import { StateHistory } from 'components/DetailsView/StateHistory';
import { StatusTag } from './StatusTag';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { Loader, LoaderModeType } from 'components/Loader';

export const StatesSection = ({ warehouseDelivery }: { warehouseDelivery: WarehouseDeliveryFragmentFragment }) => {
    const { _id: warehouseDeliveryId, state, stateHistory } = warehouseDelivery;

    const [markDeliveriesAsAborted, { loading: markAsAbortedLoading }] = useAbortWarehouseDeliveryMutation({
        variables: { warehouseDeliveryId },
    });
    const [markDeliveriesAsArchived, { loading: markAsArchivedLoading }] = useArchiveWarehouseDeliveryMutation({
        variables: { warehouseDeliveryId },
    });
    const [markDeliveriesAsDelivered, { loading: markAsDeliveredLoading }] =
        useMarkWarehouseDeliveryAsDeliveredMutation({
            variables: { warehouseDeliveryId },
        });
    const [markDeliveriesAsInProgress, { loading: markAsInProgressLoading }] =
        useMarkWarehouseDeliveryAsInProgressMutation({ variables: { warehouseDeliveryId } });
    const [markDeliveriesAsPackaging, { loading: markAsPackagingLoading }] =
        useBeginWarehouseDeliveryPreparationMutation({ variables: { warehouseDeliveryId } });
    const [markDeliveriesAsReady, { loading: markAsReadyLoading }] = useFinishWarehouseDeliveryPreparationMutation({
        variables: { warehouseDeliveryId },
    });

    const formattedStateHistory = stateHistory.map((stateRecord) => ({
        ...stateRecord,
        state: <StatusTag state={stateRecord.state} />,
    }));

    const STATE_CHANGE_ACTIONS = [
        {
            label: 'Passer en préparation',
            action: markDeliveriesAsPackaging,
            loading: markAsPackagingLoading,
            isDisabled: state !== WarehouseDeliveryState.Created,
        },
        {
            label: 'Marquer comme prête',
            action: markDeliveriesAsReady,
            loading: markAsReadyLoading,
            isDisabled: state !== WarehouseDeliveryState.Packaging && state !== WarehouseDeliveryState.Paused,
        },
        {
            label: 'Passer en livraison',
            action: markDeliveriesAsInProgress,
            loading: markAsInProgressLoading,
            isDisabled: state !== WarehouseDeliveryState.Ready,
        },
        {
            label: 'Marquer comme livrée',
            action: markDeliveriesAsDelivered,
            loading: markAsDeliveredLoading,
            isDisabled: ![
                WarehouseDeliveryState.InProgress,
                WarehouseDeliveryState.Packaging,
                WarehouseDeliveryState.Ready,
                WarehouseDeliveryState.Paused,
            ].includes(state),
        },
        {
            label: 'Annuler la livraison',
            action: markDeliveriesAsAborted,
            loading: markAsAbortedLoading,
            isDisabled: ![
                WarehouseDeliveryState.Created,
                WarehouseDeliveryState.InProgress,
                WarehouseDeliveryState.Packaging,
                WarehouseDeliveryState.Ready,
                WarehouseDeliveryState.Paused,
            ].includes(state),
        },
        {
            label: 'Archiver la livraison',
            action: markDeliveriesAsArchived,
            loading: markAsArchivedLoading,
            isDisabled: ![WarehouseDeliveryState.Delivered, WarehouseDeliveryState.Aborted].includes(state),
        },
    ];

    return (
        <SectionContainer title="Historique de statuts" isInitiallyOpen>
            <ColumnsSectionContainer numberOfColumns={3}>
                <SectionColumn>
                    <StateHistory records={formattedStateHistory} />
                </SectionColumn>
                <SectionColumn>
                    <StatusTag state={state} showHeading />
                </SectionColumn>
                <SectionColumn>
                    {STATE_CHANGE_ACTIONS.map((stateChange, index) => {
                        const { label, action, isDisabled, loading } = stateChange;

                        function updateState() {
                            const hasConfirmed = window.confirm(`Êtes-vous sûr.e de vouloir ${label.toLowerCase()} ?`);
                            if (hasConfirmed) {
                                action();
                            }
                        }

                        return (
                            <TotemPrimaryButton key={index} onClick={updateState} disabled={isDisabled}>
                                {label}
                                {loading ? <Loader size="20px" mode={LoaderModeType.Spin} /> : null}
                            </TotemPrimaryButton>
                        );
                    })}
                </SectionColumn>
            </ColumnsSectionContainer>
        </SectionContainer>
    );
};

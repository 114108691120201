import React, { useState } from 'react';
import styled from 'styled-components';

import { BADGE_TYPES } from '../constants/types';
import { BadgeType } from 'data/__generated__';
import { TotemInput } from 'components/TotemInput';
import { TotemLabel } from 'components/TotemLabel';
import { TotemImage } from 'components/TotemImage';

import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { SelectedOption, TotemSelect } from 'components/TotemSelect';
import { FileDragAndDrop } from 'components/FileDragAndDrop';

export function BadgeForm<
    T extends {
        _id: string | null;
        name: string;
        typeOption: SelectedOption<BadgeType> | null;
        image: File | null;
        imageUrl: string | null;
    },
>({
    handleSubmit,
    canISubmit,
    initialFormData,
    submitMessage,
}: {
    initialFormData: T;
    handleSubmit: (e: React.FormEvent<HTMLFormElement>, formData: T) => void;
    canISubmit: (formData: T) => boolean;
    submitMessage: string;
}) {
    const preferenceTypeOption = { value: BadgeType.Preference, label: BADGE_TYPES.preference };
    const dietTypeOption = { value: BadgeType.Diet, label: BADGE_TYPES.diet };
    const [formData, setFormData] = useState<T>(initialFormData);
    const typesOptions = [preferenceTypeOption, dietTypeOption];

    return (
        <Form onSubmit={(e) => handleSubmit(e, formData)}>
            <Fields>
                <TotemInput
                    label="Nom du badge"
                    placeholder="Hallal, Diabétique, ..."
                    value={formData.name}
                    onChange={(value) => setFormData({ ...formData, name: value })}
                    dataTest="badge-create_name"
                />
                <TotemSelect<BadgeType>
                    label="Type"
                    placeholder="Sélectionner un type"
                    value={formData.typeOption || preferenceTypeOption}
                    options={typesOptions}
                    onChange={(option) => setFormData({ ...formData, typeOption: option })}
                    dataTest="badge-create_type"
                />
                <TotemLabel>Icon</TotemLabel>
                {formData.imageUrl !== '' ? <TotemImage src={formData.imageUrl} alt="bagde icon" size={200} /> : null}
                <FileDragAndDrop
                    multiple={false}
                    fileTypes={['image/svg+xml']}
                    onFileDropCallback={(files) => setFormData({ ...formData, image: files ? files[0] : null })}
                />
            </Fields>
            <TotemPrimaryButton disabled={!canISubmit(formData)} data-test="badge-create_submit-button">
                {submitMessage}
            </TotemPrimaryButton>
        </Form>
    );
}

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

const Fields = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

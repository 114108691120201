import gql from 'graphql-tag';
import { SETUP_INSTRUCTION_FRAGMENT } from './setupInstruction';

export const FREEFOOD_SETUP_FRAGMENT = gql`
    fragment FreefoodSetupFragment on FreefoodSetup {
        _id
        createdAt
        isNeeded
        instructions {
            ...SetupInstructionFragment
        }
        state
        updatedAt
    }
    ${SETUP_INSTRUCTION_FRAGMENT}
`;

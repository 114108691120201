import { CreateSupplierDiscountInput } from 'data/__generated__';

import { round } from 'helpers/price';
import { UpsertSupplierDiscountFormValues } from './UpsertSupplierDiscountForm';

export const formatFormValues = (values: UpsertSupplierDiscountFormValues): CreateSupplierDiscountInput => {
    return {
        name: values.name,
        rate: round(parseFloat(values.rate) / 100, 4),
        productIds: values.productIds.split('\n'),
        purchaseStartDate: new Date(values.purchaseStartDate).getTime(),
        purchaseEndDate: new Date(values.purchaseEndDate).getTime(),
        salesStartDate: new Date(values.salesStartDate).getTime(),
        salesEndDate: new Date(values.salesEndDate).getTime(),
        image: values.image ?? undefined,
    };
};

export const stringifyToFormValues = (values: CreateSupplierDiscountInput): UpsertSupplierDiscountFormValues => {
    return {
        name: values.name,
        rate: `${round(values.rate * 100, 2)}`,
        productIds: values.productIds.join('\n'),
        purchaseStartDate: new Date(values.purchaseStartDate).toISOString().split('T')[0],
        purchaseEndDate: new Date(values.purchaseEndDate).toISOString().split('T')[0],
        salesStartDate: new Date(values.salesStartDate).toISOString().split('T')[0],
        salesEndDate: new Date(values.salesEndDate).toISOString().split('T')[0],
    };
};

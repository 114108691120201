import React from 'react';
import { getExtendedFormattedDatetime } from 'helpers/dateTimes';

import { GetTotemCashHistoryQuery } from 'data/__generated__';

import { FieldToDisplay, ListView } from 'components/ListView';
import { CREDIT_TYPE_LABELS } from 'pages/Credits/constants';

type FormattedCreditType = {
    _id: string;
    createdAt: string;
    updatedAt: string;
    refundedAt: string;
    createdBy: string;
    name: string;
    initialAmount: number;
    currentAmount: number;
    expirationDate: string;
    startDate: string;
    type: string;
};

const CREDIT_FIELDS_TO_DISPLAY: FieldToDisplay<FormattedCreditType>[] = [
    {
        fieldName: 'createdAt',
        label: 'Date de création',
    },
    {
        fieldName: 'updatedAt',
        label: 'Dernière mise à jour',
    },
    {
        fieldName: 'name',
        label: 'Nom du crédit',
    },
    {
        fieldName: 'initialAmount',
        label: 'Montant initial',
    },
    {
        fieldName: 'currentAmount',
        label: 'Montant restant',
    },
    {
        fieldName: 'refundedAt',
        label: 'Date de remboursement',
    },
];

export function TotemCashHistory({
    totemCashHistory,
}: {
    totemCashHistory: GetTotemCashHistoryQuery['totemCashHistory'];
}) {
    const { credits } = totemCashHistory;

    const formattedCredits: FormattedCreditType[] = credits.map((credit) => {
        return {
            _id: credit._id || '',
            createdAt: getExtendedFormattedDatetime(credit.createdAt),
            updatedAt: getExtendedFormattedDatetime(credit.updatedAt),
            createdBy: credit?.createdBy?.profile?.fullname || '',
            name: credit.name || '',
            initialAmount: credit.initialAmount || 0,
            currentAmount: credit.currentAmount || 0,
            expirationDate: credit.expirationDate ? getExtendedFormattedDatetime(credit.expirationDate) : '',
            refundedAt: credit.refundedAt ? getExtendedFormattedDatetime(credit.expirationDate) : '',
            startDate: credit.startDate ? getExtendedFormattedDatetime(credit.startDate) : '',
            type: CREDIT_TYPE_LABELS[credit.type],
        };
    });
    return (
        <ListView<FormattedCreditType>
            fieldsToDisplay={CREDIT_FIELDS_TO_DISPLAY}
            items={formattedCredits}
            keyExtractor={(item) => item._id}
        />
    );
}

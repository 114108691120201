import { Header, HeaderTitle } from 'components/Header';
import { PageTitle } from 'components/PageTitle';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { PAGES } from 'constants/pages';
import { MobilePopupsQuery, useMobilePopupsQuery } from 'data/__generated__';
import { formatDateAsAnniversary } from 'helpers/dateTimes';
import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';

import { CreateMobilePopupModal } from './components/CreateMobilePopupModal';
import { UpdateMobilePopupModal } from './components/UpdateMobilePopupModal';
import { colors } from 'constants/colors';

import { DeleteConfirmationModal } from './components/DeleteConfirmationModal';
import { TotemImage } from 'components/TotemImage';

const { darkGrey, pantinGrey } = colors;

export const MobilePopupsPage: FunctionComponent = () => {
    const { data } = useMobilePopupsQuery();

    const mobilePopups = [...(data?.mobilePopups ?? [])].sort((a, b) => (a.startsAt > b.startsAt ? 1 : -1));

    const [isCreateMobilePopupModalOpen, setIsCreateMobilePopupModalOpen] = useState(false);
    const [isUpdateMobilePopupModalOpen, setIsUpdateMobilePopupModalOpen] = useState(false);
    const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);

    const [selectedMobilePopup, setSelectedMobilePopup] = useState<MobilePopupsQuery['mobilePopups'][number] | null>(
        null,
    );

    const handleUpdateMobilePopup = (mobilePopup: MobilePopupsQuery['mobilePopups'][number]) => {
        setSelectedMobilePopup(mobilePopup);
        setIsUpdateMobilePopupModalOpen(true);
    };

    const handleDeleteMobilePopup = (mobilePopup: MobilePopupsQuery['mobilePopups'][number]) => {
        setSelectedMobilePopup(mobilePopup);
        setIsDeleteConfirmationModalOpen(true);
    };

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.mobilePopups} />
                </HeaderTitle>
                <TotemPrimaryButton onClick={() => setIsCreateMobilePopupModalOpen(true)}>
                    Ajouter popup mobile
                </TotemPrimaryButton>
            </Header>
            <Content>
                {mobilePopups.map(({ imageUrl, startsAt, endsAt, title, body, sites }, index) => {
                    const filteredSites = (sites ?? []).filter(Boolean);

                    return (
                        <Card key={index}>
                            <TotemImage src={imageUrl ?? null} alt={imageUrl ?? 'no image'} size={300} />
                            <CardContent>
                                <CardTitle>{title}</CardTitle>
                                <CardBody>{body}</CardBody>
                                <CardFooter>
                                    <div>
                                        {formatDateAsAnniversary({ dateTime: new Date(startsAt) })} -{' '}
                                        {formatDateAsAnniversary({ dateTime: new Date(endsAt) })}
                                    </div>
                                    {filteredSites.length ? (
                                        <div>
                                            Seulement sur les sites : {filteredSites.map((s) => s?.name).join(', ')}
                                        </div>
                                    ) : (
                                        'Sur tous les sites'
                                    )}
                                </CardFooter>
                            </CardContent>
                            <ButtonsContainer>
                                <button onClick={() => handleUpdateMobilePopup(mobilePopups[index])}>Modifier</button>
                                <button onClick={() => handleDeleteMobilePopup(mobilePopups[index])} color="red">
                                    Supprimer
                                </button>
                            </ButtonsContainer>
                        </Card>
                    );
                })}
            </Content>
            <CreateMobilePopupModal
                isOpen={isCreateMobilePopupModalOpen}
                setIsOpen={(arg) => setIsCreateMobilePopupModalOpen(arg)}
            />
            {!!selectedMobilePopup && (
                <UpdateMobilePopupModal
                    isOpen={isUpdateMobilePopupModalOpen}
                    setIsOpen={(arg) => setIsUpdateMobilePopupModalOpen(arg)}
                    mobilePopup={selectedMobilePopup}
                />
            )}
            {!!selectedMobilePopup && (
                <DeleteConfirmationModal
                    isOpen={isDeleteConfirmationModalOpen}
                    setIsOpen={(arg) => setIsDeleteConfirmationModalOpen(arg)}
                    mobilePopupId={selectedMobilePopup._id}
                />
            )}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Content = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: start;
    gap: 20px;
    padding: 20px;
    flex: 1;
    overflow: auto;
    background-color: #f9f9f9;
`;

const Card = styled.div`
    background-color: white;
    width: 300px;
    /* flex: 1; */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
`;

const CardContent = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const CardTitle = styled.h2`
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    color: ${darkGrey};
`;

const CardBody = styled.p`
    text-align: center;
    font-size: 16px;
    color: ${darkGrey};
    margin-bottom: 20px;
    flex: 1;
`;

const CardFooter = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 14px;
    color: ${pantinGrey};
    gap: 8px;
`;

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 20px;
    gap: 20px;
`;

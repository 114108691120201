import { apolloClient } from '../../../App';

import { STOCK_TRANSFERS_UPDATED_stockTransferUpdated } from 'data/subscriptions/__generated__/STOCK_TRANSFERS_UPDATED';
import {
    GET_STOCK_TRANSFERS,
    GET_STOCK_TRANSFERS_stockTransfers_stockTransfers,
} from 'data/queries/__generated__/GET_STOCK_TRANSFERS';
import { GET_STOCK_TRANSFERS_QUERY } from 'data/queries/stockTransfer';
import { STOCK_TRANSFER_FRAGMENT } from 'data/fragments/stockTransfer';

export const stockTransfersUpdatedHandler = (stockTransferUpdated: STOCK_TRANSFERS_UPDATED_stockTransferUpdated) => {
    let currentStockTransfers = [] as GET_STOCK_TRANSFERS_stockTransfers_stockTransfers[];
    let currentHasMore = false;
    // We can read query only if it has been run
    try {
        const result = apolloClient.readQuery<GET_STOCK_TRANSFERS>({ query: GET_STOCK_TRANSFERS_QUERY });
        if (!result) {
            throw Error('An error occurred when reading GET_STOCK_TRANSFERS_QUERY');
        }
        currentStockTransfers = result.stockTransfers.stockTransfers;
        currentHasMore = result.stockTransfers.hasMore;
    } catch {
        // do nothing
    }

    const stockTransferIds = currentStockTransfers.map(({ _id }) => _id);

    if (stockTransferIds.includes(stockTransferUpdated._id)) {
        // If this is a stockTransfer existing in the cache
        const data = {
            stockTransfers: {
                hasMore: currentHasMore,
                stockTransfers: currentStockTransfers.map((stockTransfer) => {
                    if (stockTransfer._id === stockTransferUpdated._id) {
                        return stockTransferUpdated;
                    }
                    return stockTransfer;
                }),
            },
        };
        apolloClient.writeQuery({
            query: GET_STOCK_TRANSFERS_QUERY,
            data,
        });
    } else {
        // If this is a new stockTransfer, not present in the cache
        const data = {
            stockTransfers: {
                hasMore: currentHasMore,
                stockTransfers: [...currentStockTransfers, stockTransferUpdated],
            },
        };
        apolloClient.writeQuery({
            query: GET_STOCK_TRANSFERS_QUERY,
            data,
        });
    }
};

export const stockTransferUpdatedHandler = (
    stockTransferUpdated: STOCK_TRANSFERS_UPDATED_stockTransferUpdated,
    stockTransferId: string,
) => {
    const data = { stockTransfer: stockTransferUpdated };
    apolloClient.writeFragment({
        id: stockTransferId,
        fragment: STOCK_TRANSFER_FRAGMENT,
        data,
    });
};

import React from 'react';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';
import { FaTrash, FaPlus } from 'react-icons/fa';
import styled from 'styled-components';

import { TotemSelect } from '../TotemSelect';
import { TotemLabel } from '../TotemLabel';
import { colors } from 'constants/colors';

export type Value<T = string> = {
    label: string | null;
    value: T | null;
    isDisabled?: boolean;
};

export type Option<T = string> = {
    label: string;
    value: T | null;
    isDisabled?: boolean;
};

/**
 * For this component, it is mandatory to define defaultValues (if any) in the useForm.
 * The name should be the same as in the defaultValues.
 * Important : make sure name is in object shape: `name=test.0.name`
 * The objects defined in the array should have the following structure :
 * {
 *      value: "123456",
 *      label: "My favorite suite",
 * }
 * See https://react-hook-form.com/api/usefieldarray for more info
 * @author benjamin-vaysse
 */
export function DetailFormArrayValueWithSelect({
    name,
    label,
    sublabel,
    options,
}: {
    name: string;
    label?: string;
    sublabel?: string;
    options: Option[];
}) {
    const { control } = useFormContext();
    const { fields, append, remove } = useFieldArray({ name });

    return (
        <Container>
            <TotemLabel>{label}</TotemLabel>
            {sublabel ? <Sublabel>{sublabel}</Sublabel> : null}
            <List>
                {fields.map((field, index) => (
                    <Entry key={field.id} hasBorderTop={index > 0}>
                        <Controller
                            name={`${name}.${index}` as const}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <TotemSelectList
                                        onChange={onChange}
                                        value={value}
                                        options={options}
                                        menuPosition="fixed"
                                    />
                                    <IconContainer
                                        onClick={() => {
                                            remove(index);
                                        }}
                                    >
                                        <TrashIcon />
                                    </IconContainer>
                                </>
                            )}
                            control={control}
                        />
                    </Entry>
                ))}
                {options?.some((option) => !option.isDisabled) ? (
                    <Entry hasBorderTop={fields.length > 0}>
                        <IconContainer onClick={() => append(options?.find((option) => !option.isDisabled) || {})}>
                            <PlusIcon color={colors.pureWhite} />
                        </IconContainer>
                    </Entry>
                ) : null}
            </List>
        </Container>
    );
}

const TotemSelectList = styled(TotemSelect)`
    min-width: 260px;
`;

const Container = styled.div`
    display: inline-flex;
    flex-direction: column;

    & > :not(:first-child) {
        margin-top: 5px;
    }
`;

const List = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
    min-width: 200px;
`;

const Entry = styled.div<{ hasBorderTop: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-top: ${({ hasBorderTop }: { hasBorderTop: boolean }) => (hasBorderTop ? 1 : 0)}px solid
        ${({ theme }) => theme.lightBorderColor};

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const IconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background-color: ${({ theme }) => theme.ctaPrimaryColor};
    cursor: pointer;
`;

const TrashIcon = styled(FaTrash)`
    color: ${({ theme }) => theme.menuTextColor};
`;

const PlusIcon = styled(FaPlus)`
    color: ${({ theme }) => theme.menuTextColor};
`;

const Sublabel = styled.span`
    color: ${({ theme }) => theme.infoTextColor};
    font-size: 13px;
`;

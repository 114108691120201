import React, { useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { Loader, LoaderModeType } from 'components/Loader';
import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { TotemDatePicker } from 'components/TotemDatePicker';

import { useCreateTotemClosedReceptionDayMutation } from 'data/__generated__';

import { formatDateAsAnniversary } from 'helpers/dateTimes';
import { totemClosedReceptionDayCreatedHandler } from '../cacheHandlers/totemClosedReceptionDayCacheHandler';

export function CreateTotemClosedReceptionDayPopup({
    isOpen,
    setIsOpen,
}: {
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
}) {
    const [date, setDate] = useState<Date | null>(null);

    const [createTotemClosedReceptionDay, { loading }] = useCreateTotemClosedReceptionDayMutation();

    async function handleSubmit() {
        if (!date) {
            return;
        }

        const { data } = await createTotemClosedReceptionDay({
            variables: {
                createTotemClosedReceptionDayInput: {
                    date: formatDateAsAnniversary({ dateTime: date, useNewFormat: true }),
                },
            },
        });

        if (!data) {
            throw new Error('Une erreur est survenue lors de la création du jour de fermeture');
        }

        const { createTotemClosedReceptionDay: newTotemClosedReceptionDay } = data;

        if (newTotemClosedReceptionDay) {
            totemClosedReceptionDayCreatedHandler(newTotemClosedReceptionDay);
            toast.success('Jour de fermeture créé !');
            setIsOpen(false);
        } else {
            toast.error('Une erreur est survenue');
        }
    }

    return (
        <TotemPopup title="Créer un jour de fermeture" isOpen={isOpen} setIsOpen={setIsOpen} contentOverflow="visible">
            <SelectorContainer>
                <TotemDatePicker
                    label="Date"
                    selected={date}
                    onChange={(date) => {
                        setDate(date as Date);
                    }}
                />
            </SelectorContainer>

            <SubmitContainer>
                <TotemPrimaryButton onClick={handleSubmit} disabled={!date}>
                    {loading ? <Loader size="20px" mode={LoaderModeType.Spin} /> : 'Créer le jour de fermeture'}
                </TotemPrimaryButton>
            </SubmitContainer>
        </TotemPopup>
    );
}

const SelectorContainer = styled.div`
    margin: 10px 0px;
`;

const SubmitContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 15px;
`;

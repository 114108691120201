import { CommentFragmentFragment, useDeleteCommentMutation } from 'data/__generated__';
import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { CreateCommentAnswerModal } from './CreateCommentAnswerModal';
import { GET_COMMENTS_QUERY } from 'data/queries/comment';
import { Link } from 'react-router-dom';

type CommentItemProps = { comment: CommentFragmentFragment; isAnswer?: boolean };

const CommentContainer = styled.div`
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    background-color: #fff;
`;

const CommentHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const CommentUser = styled.div`
    font-weight: bold;
`;

const CommentProduct = styled.div`
    color: #5a5a5a;
`;

const CommentDate = styled.div`
    font-size: 0.9em;
    color: #999;
`;

const CommentBody = styled.div`
    margin-top: 10px;
    color: #444;
`;

const CommentUserAndProduct = styled.div`
    display: flex;
    gap: 8px;
`;

export const CommentItem: FunctionComponent<CommentItemProps> = ({
    comment: { _id, user, createdAt, body, product },
    isAnswer,
}) => {
    const [isCreateAnswerModalOpen, setIsCreateAnswerModalOpen] = useState(false);

    const [deleteComment] = useDeleteCommentMutation({
        variables: { id: _id },
        refetchQueries: [GET_COMMENTS_QUERY],
        awaitRefetchQueries: true,
    });

    return (
        <>
            <CommentContainer>
                <CommentHeader>
                    <CommentUserAndProduct>
                        <Link to={`/user/${user._id}`}>
                            <CommentUser>{user?.label}</CommentUser>
                        </Link>
                        <CommentProduct>({product?.name})</CommentProduct>
                    </CommentUserAndProduct>
                    <CommentUserAndProduct>
                        {!isAnswer && <button onClick={() => setIsCreateAnswerModalOpen(true)}>Répondre</button>}
                        <button onClick={() => deleteComment()}>Supprimer</button>
                        <CommentDate>{new Date(createdAt).toLocaleString()}</CommentDate>
                    </CommentUserAndProduct>
                </CommentHeader>
                <CommentBody>{body}</CommentBody>
            </CommentContainer>
            {product?._id && (
                <CreateCommentAnswerModal
                    isOpen={isCreateAnswerModalOpen}
                    setIsOpen={setIsCreateAnswerModalOpen}
                    commentId={_id}
                />
            )}
        </>
    );
};

import React, { useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { GET_SUPPLY_ORDERS_supplyOrders } from 'data/queries/__generated__/GET_SUPPLY_ORDERS';
import { MARK_SUPPLY_ORDERS_AS_ABORTED_MUTATION } from 'data/mutations/supplyOrder';
import {
    MARK_SUPPLY_ORDERS_AS_ABORTED,
    MARK_SUPPLY_ORDERS_AS_ABORTEDVariables,
} from 'data/mutations/__generated__/MARK_SUPPLY_ORDERS_AS_ABORTED';

import { Loader, LoaderModeType } from 'components/Loader';
import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { TotemInput } from 'components/TotemInput';
import { SelectedOption, TotemSelect } from 'components/TotemSelect';
import {
    ABORT_WITHOUT_REASON_LABEL,
    STATE_CHANGE_REASONS,
} from 'pages/SupplyOrders/SupplyOrderDetails/Popups/AbortSupplyOrderPopup';

export function MarkAsAbortedPopup({
    supplyOrders,
    selectedSupplyOrderIds,
    setSelectedSupplyOrderIds,
    isOpen,
    setIsOpen,
}: {
    supplyOrders: GET_SUPPLY_ORDERS_supplyOrders[];
    selectedSupplyOrderIds: string[];
    setSelectedSupplyOrderIds: (supplyOrderIds: string[]) => void;
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
}) {
    const [selectedAbortingReasonOptions, setSelectedAbortingReasonOptions] = useState<SelectedOption<string>[]>(
        new Array(selectedSupplyOrderIds.length).fill(null),
    );
    const [otherAbortingReasons, setOtherAbortingReasons] = useState<(string | undefined)[]>(
        new Array(selectedSupplyOrderIds.length).fill(undefined),
    );

    useEffect(() => {
        setSelectedAbortingReasonOptions([]);
        setOtherAbortingReasons([]);
    }, [selectedSupplyOrderIds]);

    const [markSupplyOrdersAsAborted, { loading }] = useMutation<
        MARK_SUPPLY_ORDERS_AS_ABORTED,
        MARK_SUPPLY_ORDERS_AS_ABORTEDVariables
    >(MARK_SUPPLY_ORDERS_AS_ABORTED_MUTATION);

    const selectedSupplyOrders = supplyOrders.filter((supplyOrder) => selectedSupplyOrderIds.includes(supplyOrder._id));

    async function handleSubmit() {
        if (
            selectedAbortingReasonOptions.length !== selectedSupplyOrderIds.length ||
            selectedAbortingReasonOptions.some(
                (reasonOption, index) =>
                    !(reasonOption?.value === 'other' ? !!otherAbortingReasons[index] : !!reasonOption?.value),
            )
        )
            return;

        const supplyOrderIdsWithReasons = selectedSupplyOrderIds.map((_id, index) => {
            const stateChangeReasons =
                selectedAbortingReasonOptions?.[index]?.value === 'other'
                    ? [otherAbortingReasons[index] || ABORT_WITHOUT_REASON_LABEL]
                    : [selectedAbortingReasonOptions?.[index]?.value || ABORT_WITHOUT_REASON_LABEL];

            return {
                supplyOrderId: _id,
                stateChangeReasons,
            };
        });
        const { data } = await markSupplyOrdersAsAborted({
            variables: { supplyOrderIdsWithReasons },
        });

        if (!data) {
            throw new Error("Une erreur est survenue lors du passage des achats en 'reçus'");
        }

        const {
            markSupplyOrdersAsAborted: { updatedSupplyOrders, errors },
        } = data;

        if (updatedSupplyOrders.length) {
            toast.success(`Achat(s) signalé(s) comme 'annulé(s)' : ${updatedSupplyOrders.length}`);
        }

        if (errors.length) {
            errors.forEach((error, index) => {
                toast.error(<span key={index}>Erreur : {error}</span>, { autoClose: false });
            });

            if (errors.length > 1) {
                toast.info('Cliquez pour fermer toutes les notifications', {
                    autoClose: false,
                    onClick: () => toast.dismiss(),
                });
            }
        }
        setSelectedSupplyOrderIds([]);
        setIsOpen(false);
    }

    const updateOtherAbortingReasons = (value: string, index: number) => {
        const initialReasons = [...otherAbortingReasons];
        initialReasons[index] = value;
        setOtherAbortingReasons(initialReasons);
    };

    const updateSelectedAbortingReasonOptions = (value: SelectedOption<string>, index: number) => {
        const initialReasons = [...selectedAbortingReasonOptions];
        initialReasons[index] = value;
        setSelectedAbortingReasonOptions(initialReasons);
    };

    return (
        <TotemPopup title="Êtes-vous sûr.e ?" isOpen={isOpen} setIsOpen={setIsOpen} contentOverflow="visible">
            Vous vous apprêtez à signaler comme 'annulé(s)' {selectedSupplyOrders.length} achat(s). Souhaitez-vous
            vraiment modifier le statut du/des achat(s) suivant(s) ?
            <SupplyOrderlist>
                {selectedSupplyOrders.map((supplyOrder, index) => {
                    const { _id: supplyOrderId, number, supplier } = supplyOrder;

                    return (
                        <SupplyOrder key={supplyOrderId}>
                            <SupplyOrderTitle>
                                {supplier.name} #{number}
                            </SupplyOrderTitle>
                            {selectedAbortingReasonOptions?.[index]?.value === 'other' ? (
                                <>
                                    <TotemInput
                                        onChange={(value) => updateOtherAbortingReasons(value, index)}
                                        placeholder="Autre raison de l'annulation de l'achat"
                                        value={otherAbortingReasons[index]}
                                        autoFocus={true}
                                        width="300px"
                                    />
                                    <SmallPrimaryButton
                                        onClick={() => updateSelectedAbortingReasonOptions(null, index)}
                                    >
                                        Choisir une raison de la liste
                                    </SmallPrimaryButton>
                                </>
                            ) : (
                                <TotemSelect
                                    placeholder="Indiquer la raison de l'annulation de l'achat"
                                    value={selectedAbortingReasonOptions[index]}
                                    options={STATE_CHANGE_REASONS}
                                    onChange={(value) => updateSelectedAbortingReasonOptions(value, index)}
                                />
                            )}
                        </SupplyOrder>
                    );
                })}
            </SupplyOrderlist>
            <SubmitContainer>
                <TotemPrimaryButton
                    onClick={handleSubmit}
                    disabled={
                        selectedAbortingReasonOptions.length !== selectedSupplyOrderIds.length ||
                        selectedAbortingReasonOptions.some(
                            (reasonOption, index) =>
                                !(reasonOption?.value === 'other'
                                    ? !!otherAbortingReasons[index]
                                    : !!reasonOption?.value),
                        )
                    }
                >
                    {loading ? <Loader size="20px" mode={LoaderModeType.Spin} /> : 'Confirmer'}
                </TotemPrimaryButton>
            </SubmitContainer>
        </TotemPopup>
    );
}

const SupplyOrderlist = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    margin-top: 10px;

    & > :not(:first-child) {
        margin-top: 5px;
    }
`;

const SupplyOrder = styled.div`
    display: flex;
    flex-basis: calc(25% - 5px);
    margin-top: 5px;
    margin-left: 5px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow: visible;
`;

const SupplyOrderTitle = styled.div`
    font-weight: 800;
    margin-bottom: 5px;
`;

const SubmitContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 15px;
`;

const SmallPrimaryButton = styled(TotemPrimaryButton)`
    margin-top: 5px;
    font-size: 12px;
    padding: 10px;
`;

import React, { useContext } from 'react';
import Lottie from 'react-lottie';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import rockerLunch from './rocket-lunch.json';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { UserContext } from 'contexts';

export const Home = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: rockerLunch,
    };

    const user = useContext(UserContext);

    const {
        profile: { firstname },
    } = user;

    return (
        <Container>
            <Helmet title="Accueil · TOTADMIN" />
            <Title>Jean-{firstname},</Title>
            <Title>Bienvenue sur le panel d'administration de TOTEM</Title>
            <Lottie options={defaultOptions} height={450} width={450} />
            <ButtonLinksContainer>
                <TotemPrimaryButton
                    as="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.notion.so/thetotem/Homepage-5b374895a8db4bbf9903d185fe2efd4f"
                >
                    Se rendre sur Notion
                </TotemPrimaryButton>
            </ButtonLinksContainer>
        </Container>
    );
};

const ButtonLinksContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    a {
        text-decoration: none;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 100%;
    padding: 15px;
`;

const Title = styled.h1`
    font-size: 40px;
    margin-bottom: 30px;
`;

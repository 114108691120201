import { FORM_ERROR_FRAGMENT } from 'data/fragments/product';
import gql from 'graphql-tag';

export const ARCHIVE_TOTEM_MUTATION = gql`
    mutation ARCHIVE_TOTEM($totemId: ID!) {
        archiveTotem(totemId: $totemId) {
            errors
            organization {
                _id
                state
                stateHistory {
                    createdAt
                    createdBy
                    createdByName
                    state
                }
            }
            totem {
                _id
                state
                stateHistory {
                    createdAt
                    createdBy
                    createdByName
                    state
                }
            }
        }
    }
`;

export const REACTIVATE_TOTEM_MUTATION = gql`
    mutation REACTIVATE_TOTEM($totemId: ID!) {
        reactivateTotem(totemId: $totemId) {
            errors
            organization {
                _id
                state
                stateHistory {
                    createdAt
                    createdBy
                    createdByName
                    state
                }
            }
            totem {
                _id
                state
                stateHistory {
                    createdAt
                    createdBy
                    createdByName
                    state
                }
            }
        }
    }
`;

export const UPDATE_TOTEM_MUTATION = gql`
    mutation UPDATE_TOTEM($totemId: ID!, $fields: TotemUpdateInput!) {
        updateTotem(totemId: $totemId, fields: $fields) {
            error
            formErrors {
                ...FormErrorFragment
            }
            updatedTotem {
                _id
                accessTime
                minimumOrderAmountInfo {
                    minimumBakeryAmount
                    minimumDryAmount
                    minimumFreshAmount
                    minimumFruitAmount
                    minimumTotalAmount
                }
                needSignature
            }
        }
    }
    ${FORM_ERROR_FRAGMENT}
`;

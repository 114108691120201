import React from 'react';
import styled from 'styled-components';

import { useMutation } from '@apollo/client';
import {
    FcAssistant,
    FcCalendar,
    FcCancel,
    FcClock,
    FcDeployment,
    FcInvite,
    FcCheckmark,
    FcSafe,
} from 'react-icons/fc';

import { SupplyOrderState } from 'data/__generated__';
import {
    MARK_SUPPLY_ORDER_AS_CHECKED_MUTATION,
    MARK_SUPPLY_ORDER_AS_CONFIRMED_MUTATION,
    MARK_SUPPLY_ORDER_AS_INRACK_MUTATION,
    MARK_SUPPLY_ORDER_AS_ON_HOLD_MUTATION,
    MARK_SUPPLY_ORDER_AS_ORDERED_MUTATION,
    MARK_SUPPLY_ORDER_AS_READY_MUTATION,
    MARK_SUPPLY_ORDER_AS_RECEIVED_MUTATION,
} from 'data/mutations/supplyOrder';
import {
    MARK_SUPPLY_ORDER_AS_CHECKED,
    MARK_SUPPLY_ORDER_AS_CHECKEDVariables,
} from 'data/mutations/__generated__/MARK_SUPPLY_ORDER_AS_CHECKED';

import {
    MARK_SUPPLY_ORDER_AS_RECEIVED,
    MARK_SUPPLY_ORDER_AS_RECEIVEDVariables,
} from 'data/mutations/__generated__/MARK_SUPPLY_ORDER_AS_RECEIVED';
import {
    MARK_SUPPLY_ORDER_AS_CONFIRMED,
    MARK_SUPPLY_ORDER_AS_CONFIRMEDVariables,
} from 'data/mutations/__generated__/MARK_SUPPLY_ORDER_AS_CONFIRMED';
import {
    MARK_SUPPLY_ORDER_AS_ORDERED,
    MARK_SUPPLY_ORDER_AS_ORDEREDVariables,
} from 'data/mutations/__generated__/MARK_SUPPLY_ORDER_AS_ORDERED';
import {
    MARK_SUPPLY_ORDER_AS_READY,
    MARK_SUPPLY_ORDER_AS_READYVariables,
} from 'data/mutations/__generated__/MARK_SUPPLY_ORDER_AS_READY';
import {
    MARK_SUPPLY_ORDER_AS_INRACK,
    MARK_SUPPLY_ORDER_AS_INRACKVariables,
} from 'data/mutations/__generated__/MARK_SUPPLY_ORDER_AS_INRACK';
import {
    MARK_SUPPLY_ORDER_AS_ON_HOLD,
    MARK_SUPPLY_ORDER_AS_ON_HOLDVariables,
} from 'data/mutations/__generated__/MARK_SUPPLY_ORDER_AS_ON_HOLD';

import { ActionButtons } from 'components/ActionButtons';
import { SectionContainer } from 'components/DetailsView/Section';
import { FullPageLoader } from 'components/FullPageLoader';

import { SupplyOrderDetailsDisplayValues } from './supplyOrderDetailsFormHelper';

import { computeHourDeliveryFromDate } from 'helpers/dateTimes';
import { handleUpdateActionEndToast } from './utils/handleUpdateActionEndToast';

export const ActionSection = ({
    isDirty,
    setShowAbortPopup,
    supplyOrderDetailsDisplayValues,
}: {
    isDirty: boolean;
    setShowAbortPopup: React.Dispatch<React.SetStateAction<boolean>>;
    supplyOrderDetailsDisplayValues: SupplyOrderDetailsDisplayValues;
}) => {
    const { _id: supplyOrderId, state, stateHistory } = supplyOrderDetailsDisplayValues;

    const [markSupplyOrderAsChecked, { loading: loadingChecked }] = useMutation<
        MARK_SUPPLY_ORDER_AS_CHECKED,
        MARK_SUPPLY_ORDER_AS_CHECKEDVariables
    >(MARK_SUPPLY_ORDER_AS_CHECKED_MUTATION, { variables: { supplyOrderIds: [supplyOrderId] } });
    const [markSupplyOrderAsConfirmed, { loading: loadingConfirmed }] = useMutation<
        MARK_SUPPLY_ORDER_AS_CONFIRMED,
        MARK_SUPPLY_ORDER_AS_CONFIRMEDVariables
    >(MARK_SUPPLY_ORDER_AS_CONFIRMED_MUTATION, { variables: { supplyOrderIds: [supplyOrderId] } });
    const [markSupplyOrderAsInRack, { loading: loadingInRack }] = useMutation<
        MARK_SUPPLY_ORDER_AS_INRACK,
        MARK_SUPPLY_ORDER_AS_INRACKVariables
    >(MARK_SUPPLY_ORDER_AS_INRACK_MUTATION, { variables: { supplyOrderIds: [supplyOrderId] } });
    const [markSupplyOrderAsOnHold, { loading: loadingOnHold }] = useMutation<
        MARK_SUPPLY_ORDER_AS_ON_HOLD,
        MARK_SUPPLY_ORDER_AS_ON_HOLDVariables
    >(MARK_SUPPLY_ORDER_AS_ON_HOLD_MUTATION, { variables: { supplyOrderId } });

    const [markSupplyOrderAsOrdered, { loading: loadingOrdered }] = useMutation<
        MARK_SUPPLY_ORDER_AS_ORDERED,
        MARK_SUPPLY_ORDER_AS_ORDEREDVariables
    >(MARK_SUPPLY_ORDER_AS_ORDERED_MUTATION);
    const [markSupplyOrderAsReady, { loading: loadingReady }] = useMutation<
        MARK_SUPPLY_ORDER_AS_READY,
        MARK_SUPPLY_ORDER_AS_READYVariables
    >(MARK_SUPPLY_ORDER_AS_READY_MUTATION, { variables: { supplyOrderIds: [supplyOrderId] } });
    const [markSupplyOrderAsReceived, { loading: loadingReceived }] = useMutation<
        MARK_SUPPLY_ORDER_AS_RECEIVED,
        MARK_SUPPLY_ORDER_AS_RECEIVEDVariables
    >(MARK_SUPPLY_ORDER_AS_RECEIVED_MUTATION);

    const loading =
        loadingConfirmed ||
        loadingOrdered ||
        loadingReady ||
        loadingReceived ||
        loadingChecked ||
        loadingInRack ||
        loadingOnHold;

    async function excecuteActionOnConfirm(action: () => Promise<void>, confirmText: string) {
        const hasConfirmed = window.confirm(confirmText);
        if (hasConfirmed) {
            await action();
        }
    }

    const wasAlreadyOrdered = stateHistory.some(({ state }) => state === SupplyOrderState.Ordered);

    const actionSections = [
        {
            title: 'Changer le statut',
            actions: [
                {
                    icon: <FcInvite size={22} />,
                    label: 'À envoyer',
                    onClick: async () => {
                        await excecuteActionOnConfirm(async () => {
                            const { data } = await markSupplyOrderAsReady();

                            if (!data) {
                                throw new Error("Une erreur est survenue lors du passage de l'achat en 'à envoyer'");
                            }

                            const {
                                markSupplyOrdersAsReady: { updatedSupplyOrders, errors, warnings },
                            } = data;
                            const isSuccess = !!updatedSupplyOrders.length;
                            handleUpdateActionEndToast({
                                displaySuccess: isSuccess,
                                successText: "L'achat a bien été marqué comme 'à envoyer",
                                errors,
                                warnings,
                            });
                        }, "Souhaitez-vous vraiment marquer l'achat comme 'à envoyer' ?");
                    },
                    disabled: state !== SupplyOrderState.Created,
                },
                {
                    icon: <FcAssistant size={22} />,
                    label: 'Commandé',
                    onClick: async () => {
                        await excecuteActionOnConfirm(
                            async () => {
                                const { data } = await markSupplyOrderAsOrdered({
                                    variables: { supplyOrderIds: [supplyOrderId], shouldSendMailToSupplier: true },
                                });

                                if (!data) {
                                    throw new Error("Une erreur est survenue lors du passage de l'achat en 'commandé'");
                                }

                                const {
                                    markSupplyOrdersAsOrdered: { updatedSupplyOrders, errors },
                                } = data;
                                handleUpdateActionEndToast({
                                    displaySuccess: !!updatedSupplyOrders.length,
                                    successText: "L'achat a bien été marqué comme 'commandé",
                                    errors,
                                });
                            },
                            `Souhaitez-vous vraiment marquer l'achat comme 'commandé' ?\n\n${wasAlreadyOrdered ? "Aucun mail ne sera envoyé au fournisseur car cette commande était déjà en 'Commandé' avant" : 'Attention ! \nUn mail sera envoyé au fournisseur avec le bon de commande généré sur le google drive.'}`,
                        );
                    },
                    disabled: ![
                        SupplyOrderState.Ready,
                        SupplyOrderState.Confirmed,
                        SupplyOrderState.OnHold,
                        SupplyOrderState.Received,
                    ].includes(state),
                },
                {
                    icon: <FcCalendar size={22} />,
                    label: 'Confirmé',
                    onClick: async () => {
                        await excecuteActionOnConfirm(async () => {
                            const { data } = await markSupplyOrderAsConfirmed();

                            if (!data) {
                                throw new Error("Une erreur est survenue lors du passage de l'achat en 'confirmé'");
                            }

                            const {
                                markSupplyOrdersAsConfirmed: { updatedSupplyOrders, errors },
                            } = data;
                            handleUpdateActionEndToast({
                                displaySuccess: !!updatedSupplyOrders.length,
                                successText: "L'achat a bien été marqué comme 'confirmé",
                                errors,
                            });
                        }, "Souhaitez-vous vraiment marquer l'achat comme 'confirmé' ?");
                    },
                    disabled: ![SupplyOrderState.Ordered, SupplyOrderState.OnHold, SupplyOrderState.Received].includes(
                        state,
                    ),
                },
                {
                    icon: <FcClock size={22} />,
                    label: 'En Attente',
                    onClick: async () => {
                        await excecuteActionOnConfirm(async () => {
                            const { data } = await markSupplyOrderAsOnHold();

                            if (!data) {
                                throw new Error("Une erreur est survenue lors du passage de l'achat en 'En Attente'");
                            }

                            const { markSupplyOrderAsOnHold: updatedSupplyOrder } = data;
                            handleUpdateActionEndToast({
                                displaySuccess: !!updatedSupplyOrder,
                                successText: "L'achat a bien été marqué comme 'En Attente",
                                errors: [],
                            });
                        }, "Souhaitez-vous vraiment marquer l'achat comme 'En Attente' ?");
                    },
                    disabled: ![
                        SupplyOrderState.Ordered,
                        SupplyOrderState.Confirmed,
                        SupplyOrderState.Received,
                    ].includes(state),
                },
                {
                    icon: <FcDeployment size={22} />,
                    label: 'Reçu',
                    onClick: async () => {
                        await excecuteActionOnConfirm(async () => {
                            const hourDeliveryReceivedAt = computeHourDeliveryFromDate(new Date());
                            const { data } = await markSupplyOrderAsReceived({
                                variables: { supplyOrderIds: [supplyOrderId], hourDeliveryReceivedAt },
                            });

                            if (!data) {
                                throw new Error("Une erreur est survenue lors du passage de l'achat en 'reçu'");
                            }

                            const {
                                markSupplyOrdersAsReceived: { updatedSupplyOrders, errors, warnings },
                            } = data;

                            const isSuccess = !!updatedSupplyOrders.length;
                            handleUpdateActionEndToast({
                                displaySuccess: isSuccess,
                                successText: "L'achat a bien été marqué comme 'reçu",
                                errors,
                                warnings,
                            });
                        }, "Souhaitez-vous vraiment marquer l'achat comme 'reçu' ?");
                    },
                    disabled: ![
                        SupplyOrderState.Ordered,
                        SupplyOrderState.Confirmed,
                        SupplyOrderState.OnHold,
                        SupplyOrderState.Checked,
                        SupplyOrderState.OnHold,
                    ].includes(state),
                },
                {
                    icon: <FcCheckmark size={22} />,
                    label: 'Vérifié',
                    onClick: async () => {
                        await excecuteActionOnConfirm(async () => {
                            const { data } = await markSupplyOrderAsChecked();

                            if (!data) {
                                throw new Error("Une erreur est survenue lors du passage de l'achat en 'vérifié'");
                            }

                            const {
                                markSupplyOrdersAsChecked: { updatedSupplyOrders, errors, warnings },
                            } = data;
                            const isSuccess = !!updatedSupplyOrders.length;
                            handleUpdateActionEndToast({
                                displaySuccess: isSuccess,
                                successText: "L'achat a bien été marqué comme 'vérifié",
                                errors,
                                warnings,
                            });
                        }, "Souhaitez-vous vraiment marquer l'achat comme 'vérifié' ? \n\nAttention ! \nLes quantitées reçues non renseignées seront considérées comme étant celles confirmées par fournisseur (si renseigné) ou commandées.");
                    },
                    disabled: state !== SupplyOrderState.Received,
                },
                {
                    icon: <FcSafe size={22} />,
                    label: 'Rangé',
                    onClick: async () => {
                        await excecuteActionOnConfirm(async () => {
                            const { data } = await markSupplyOrderAsInRack();

                            if (!data) {
                                throw new Error("Une erreur est survenue lors du passage de l'achat en 'rangé'");
                            }

                            const {
                                markSupplyOrdersAsInRack: { updatedSupplyOrders, errors, warnings },
                            } = data;
                            const isSuccess = !!updatedSupplyOrders.length;
                            handleUpdateActionEndToast({
                                displaySuccess: isSuccess,
                                successText: "L'achat a bien été marqué comme 'rangé",
                                errors,
                                warnings,
                            });
                        }, "Souhaitez-vous vraiment marquer l'achat comme 'rangé' ? \n\nAttention ! \nLe stock sera mis à jour avec les quantitées renseignées comme reçues. \nL'achat ne sera plus rangeable sur la totops.");
                    },
                    disabled: state !== SupplyOrderState.Checked,
                },
                {
                    icon: <FcCancel size={22} />,
                    label: 'Annulé',
                    onClick: async () => {
                        setShowAbortPopup(true);
                    },
                    disabled: ![
                        SupplyOrderState.Created,
                        SupplyOrderState.Ready,
                        SupplyOrderState.Ordered,
                        SupplyOrderState.Confirmed,
                        SupplyOrderState.OnHold,
                    ].includes(state),
                },
            ],
        },
        {
            title: 'Cas particuliers',
            actions: [
                {
                    icon: <FcAssistant size={22} />,
                    label: "Passer l'achat en 'Commandé' sans envoyer le mail",
                    onClick: async () => {
                        await excecuteActionOnConfirm(async () => {
                            const { data } = await markSupplyOrderAsOrdered({
                                variables: { supplyOrderIds: [supplyOrderId], shouldSendMailToSupplier: false },
                            });

                            if (!data) {
                                throw new Error("Une erreur est survenue lors du passage de l'achat en 'commandé'");
                            }

                            const {
                                markSupplyOrdersAsOrdered: { updatedSupplyOrders, errors, warnings },
                            } = data;
                            handleUpdateActionEndToast({
                                displaySuccess: !!updatedSupplyOrders.length,
                                successText: "L'achat a bien été marqué comme 'commandé",
                                errors,
                                warnings,
                            });
                        }, "Souhaitez-vous vraiment marquer l'achat comme 'commandé' (sans envoi de mail automatique) ?");
                    },
                    disabled: SupplyOrderState.Ready !== state,
                },
            ],
        },
    ];

    return (
        <SectionContainer title="Actions" isInitiallyOpen>
            <ActionContainer>
                <ActionButtons actionSections={actionSections} />
                {loading ? <FullPageLoader /> : null}
                {isDirty ? (
                    <DirtyOverlay>
                        <OverlayText>
                            Veuillez valider ou réinitialiser les changements en cours avant d'effectuer une action
                        </OverlayText>
                    </DirtyOverlay>
                ) : null}
            </ActionContainer>
        </SectionContainer>
    );
};

const ActionContainer = styled.div`
    position: relative;
`;

const DirtyOverlay = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.popupOverlayColor};
`;

const OverlayText = styled.div`
    color: ${({ theme }) => theme.ctaPrimaryColor};
    background-color: ${({ theme }) => theme.backgroundColor};
    padding: 5px;
`;

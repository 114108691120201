import React from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { Loader, LoaderModeType } from 'components/Loader';
import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

import { totemClosedReceptionDayRemovedHandler } from '../cacheHandlers/totemClosedReceptionDayCacheHandler';
import { TotemClosedReceptionDayFragmentFragment, useAbortTotemClosedReceptionDayMutation } from 'data/__generated__';

export function AbortTotemClosedReceptionDayPopup({
    isOpen,
    setIsOpen,
    totemClosedReceptionDay,
}: {
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
    totemClosedReceptionDay: TotemClosedReceptionDayFragmentFragment;
}) {
    const [abortTotemClosedReceptionDay, { loading }] = useAbortTotemClosedReceptionDayMutation();

    async function handleSubmit() {
        const { data } = await abortTotemClosedReceptionDay({
            variables: {
                totemClosedReceptionDayId: totemClosedReceptionDay._id,
            },
        });

        if (!data) {
            throw new Error('Une erreur est survenue lors de la création du jour de fermeture');
        }

        const { abortTotemClosedReceptionDay: abortedTotemClosedReceptionDay } = data;

        if (abortedTotemClosedReceptionDay) {
            totemClosedReceptionDayRemovedHandler(abortedTotemClosedReceptionDay._id);
            toast.success('Jour de fermeture annulé');
            setIsOpen(false);
        } else {
            toast.error('Une erreur est survenue');
        }
    }

    return (
        <TotemPopup
            title="Annuler un jour de fermeture"
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            contentOverflow="visible"
        >
            <SubmitContainer>
                <TotemPrimaryButton onClick={handleSubmit}>
                    {loading ? <Loader size="20px" mode={LoaderModeType.Spin} /> : 'Annuler le jour de fermeture'}
                </TotemPrimaryButton>
            </SubmitContainer>
        </TotemPopup>
    );
}

const SubmitContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 15px;
`;

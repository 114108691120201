import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { VALIDATE_STOCK_TRANSFERS_AT_DATE_MUTATION } from 'data/mutations/stockTransfer';
import {
    VALIDATE_STOCK_TRANSFERS_AT_DATE,
    VALIDATE_STOCK_TRANSFERS_AT_DATEVariables,
} from 'data/mutations/__generated__/VALIDATE_STOCK_TRANSFERS_AT_DATE';

import { Loader, LoaderModeType } from 'components/Loader';
import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { TotemDatePicker } from 'components/TotemDatePicker';

import { formatDateAsAnniversary } from 'helpers/dateTimes';

export function ValidateStockTransfersAtDatePopup({
    isOpen,
    setIsOpen,
}: {
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
}) {
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);

    const [validateStockTransfersAtDate, { loading }] = useMutation<
        VALIDATE_STOCK_TRANSFERS_AT_DATE,
        VALIDATE_STOCK_TRANSFERS_AT_DATEVariables
    >(VALIDATE_STOCK_TRANSFERS_AT_DATE_MUTATION);

    async function handleSubmit() {
        if (selectedDate) {
            const { data } = await validateStockTransfersAtDate({
                variables: {
                    date: formatDateAsAnniversary({ dateTime: selectedDate }),
                },
            });

            if (!data) {
                throw new Error('Une erreur est survenue lors de la création de la livraison');
            }

            const { validateStockTransfersAtDate: result } = data;

            if (result.errors.length) {
                result?.errors?.forEach((error, index) => {
                    toast.error(<span key={index}>Erreur : {error}</span>, { autoClose: false });
                });

                if (result?.errors?.length && result?.errors?.length > 1) {
                    toast.info('Cliquez pour fermer toutes les notifications', {
                        autoClose: false,
                        onClick: () => toast.dismiss(),
                    });
                }
            } else {
                toast.success('STs validés !');
                setIsOpen(false);
            }
        }
    }

    return (
        <TotemPopup
            title="Valider les STs à la date de transfert"
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            contentOverflow="visible"
        >
            <SelectorContainer>
                <TotemDatePicker
                    label="Date"
                    selected={selectedDate}
                    onChange={(date) => {
                        setSelectedDate(date);
                    }}
                />
            </SelectorContainer>
            <SubmitContainer>
                <TotemPrimaryButton onClick={handleSubmit} disabled={!selectedDate}>
                    {loading ? (
                        <Loader size="20px" mode={LoaderModeType.Spin} />
                    ) : (
                        'Valider les STs pour cette date de transfert'
                    )}
                </TotemPrimaryButton>
            </SubmitContainer>
        </TotemPopup>
    );
}

const SelectorContainer = styled.div`
    margin: 10px 0px;
`;

const SubmitContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 15px;
`;

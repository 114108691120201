import React from 'react';

import styled from 'styled-components';

import { TotemLabel } from '../TotemLabel';

export const DetailValue = ({
    label,
    sublabel,
    value,
    isValid,
}: {
    label?: string;
    sublabel?: string;
    value?: JSX.Element | string | number | null;
    isValid?: boolean;
}) => {
    return (
        <Container>
            <TotemLabel>{label}</TotemLabel>
            {sublabel ? <Sublabel>{sublabel}</Sublabel> : null}
            <Value isValid={isValid}>{value ?? '⚠️ Valeur manquante'}</Value>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    color: ${({ theme }) => theme.textColor};
`;

const Sublabel = styled.span`
    color: ${({ theme }) => theme.infoTextColor};
    font-size: 13px;
`;

const Value = styled.span<{ isValid?: boolean }>`
    margin-top: 5px;
    color: ${({ isValid, theme }) =>
        isValid !== undefined ? (isValid ? theme.successColor : theme.errorColor) : theme.textColor};
`;

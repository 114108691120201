import gql from 'graphql-tag';
import { PRODUCT_ARRANGEMENT_FRAGMENT, PRODUCT_ARRANGEMENT_PRODUCT_FRAGMENT } from './productArrangement';

export const MICROSTORE_LOCATION_TEMPLATE_FRAGMENT = gql`
    fragment MicrostoreLocationTemplateFragment on MicrostoreLocationTemplate {
        _id
        productArrangement {
            ...ProductArrangementFragment
        }
        alternativeProductArrangementIds
        productArrangementSingleProductInfo {
            productId
            product {
                ...ProductArrangementProductFragment
            }
            stockMaxUnsecured
            stockMaxSensei
            reassortmentType
        }
    }
    ${PRODUCT_ARRANGEMENT_FRAGMENT}
    ${PRODUCT_ARRANGEMENT_PRODUCT_FRAGMENT}
`;

import React from 'react';
import styled from 'styled-components';

import { SupplyOrderWithSupplierProductsInfoFragment_products_confirmedQuantityHistory } from 'data/fragments/__generated__/SupplyOrderWithSupplierProductsInfoFragment';

import { TotemPopup } from 'components/TotemPopup';
import { TotemLabel } from 'components/TotemLabel';
import { getExtendedFormattedDatetime } from 'helpers/dateTimes';

export const ConfirmedQuantityHistoryPopup = ({
    quantityHistoryValuesToDisplay,
    setQuantityHistoryValuesToDisplay,
}: {
    quantityHistoryValuesToDisplay:
        | SupplyOrderWithSupplierProductsInfoFragment_products_confirmedQuantityHistory[]
        | null;
    setQuantityHistoryValuesToDisplay: (
        value: SupplyOrderWithSupplierProductsInfoFragment_products_confirmedQuantityHistory[] | null,
    ) => void;
}) => {
    return (
        <TotemPopup
            title=""
            isOpen={!!quantityHistoryValuesToDisplay}
            setIsOpen={() => setQuantityHistoryValuesToDisplay(null)}
            contentOverflow="visible"
        >
            <Container>
                <TableContainer>
                    <TotemLabel>Historique des quantités confirmées de produit</TotemLabel>
                    <table>
                        <thead>
                            <tr>
                                <th>Créé le</th>
                                <th>Quantité</th>
                            </tr>
                        </thead>
                        <tbody>
                            {quantityHistoryValuesToDisplay?.map(({ createdAt, quantity }, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{getExtendedFormattedDatetime(createdAt)}</td>
                                        <td>{quantity ?? 'Pas de valeur'}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </TableContainer>
            </Container>
        </TotemPopup>
    );
};
const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    & > :not(:first-child) {
        margin-top: 15px;
    }
`;

const TableContainer = styled.div`
    display: flex;
    flex-direction: column;
    & > :not(:first-child) {
        margin-top: 5px;
    }
    table {
        border-spacing: 0;
        border-collapse: collapse;
        color: ${({ theme }) => theme.textColor};
        th,
        td {
            text-align: left;
            border: 1px solid ${({ theme }) => theme.lightBorderColor};
            border-spacing: 1px;
        }
        th {
            padding: 10px;
        }
        td {
            padding: 5px 10px;
        }
    }
`;

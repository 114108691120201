import { createContext } from 'react';
import { VERIFY_LOGGED_IN_verifyLoggedIn_user } from 'data/mutations/__generated__/VERIFY_LOGGED_IN';

export const InitialUserContext = createContext<{
    user: VERIFY_LOGGED_IN_verifyLoggedIn_user | null;
    setUser: (user: VERIFY_LOGGED_IN_verifyLoggedIn_user | null) => void;
}>({
    user: null,
    setUser: (_user) => null,
});

export const WAREHOUSE_DELIVERY_STATES_LABELS = {
    Aborted: 'Annulée',
    Archived: 'Archivée',
    Created: 'Créée',
    Delivered: 'Livrée',
    InProgress: 'En livraison',
    Paused: 'En pause',
    Packaging: 'En préparation',
    Ready: 'Prête',
};

export const WAREHOUSE_DELIVERY_PLURAL_STATES_LABELS = {
    Aborted: 'Annulées',
    Archived: 'Archivées',
    Created: 'Créées',
    Delivered: 'Livrées',
    InProgress: 'En livraison',
    Paused: 'En pause',
    Packaging: 'En préparation',
    Ready: 'Prêtes',
};

import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { MICROSTORE_COLUMN_TEMPLATE_TYPE_LABELS } from '../constants/types';

import { CREATE_MICROSTORE_COLUMN_TEMPLATE_MUTATION } from 'data/mutations/microstoreColumnTemplate';
import {
    CREATE_MICROSTORE_COLUMN_TEMPLATE,
    CREATE_MICROSTORE_COLUMN_TEMPLATEVariables,
} from 'data/mutations/__generated__/CREATE_MICROSTORE_COLUMN_TEMPLATE';
import { MicrostoreColumnTemplateType } from 'data/__generated__';

import { Loader, LoaderModeType } from 'components/Loader';
import { TotemInput } from 'components/TotemInput';
import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { SelectedOption, TotemSelect } from 'components/TotemSelect';

import { microstoreColumnTemplatesUpdatedOrCreatedHandler } from '../cacheHandlers/microstoreColumnTemplatesCacheHandler';

export function CreateMicrostoreColumnTemplatePopup({
    isOpen,
    setIsOpen,
}: {
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
}) {
    const [microstoreColumnTemplateName, setMicrostoreColumnTemplateName] = useState<string>('');
    const [microstoreColumnTemplateType, setMicrostoreColumnTemplateType] = useState<MicrostoreColumnTemplateType>(
        MicrostoreColumnTemplateType.Fridge,
    );

    const [createMicrostoreColumnTemplate, { loading: creationLoading }] = useMutation<
        CREATE_MICROSTORE_COLUMN_TEMPLATE,
        CREATE_MICROSTORE_COLUMN_TEMPLATEVariables
    >(CREATE_MICROSTORE_COLUMN_TEMPLATE_MUTATION, {
        variables: {
            microstoreColumnTemplate: { name: microstoreColumnTemplateName, type: microstoreColumnTemplateType },
        },
    });

    async function handleSubmit() {
        const { data } = await createMicrostoreColumnTemplate();

        if (!data) {
            throw new Error('Une erreur est survenue lors de la creation du template de colonne');
        }

        const {
            createMicrostoreColumnTemplateMutation: { newMicrostoreColumnTemplate, errors },
        } = data;

        if (errors.length) {
            errors.forEach((error, index) => {
                toast.error(<span key={index}>Erreur : {error}</span>, { autoClose: false });
            });

            if (errors.length > 1) {
                toast.info('Cliquez pour fermer toutes les notifications', {
                    autoClose: false,
                    onClick: () => toast.dismiss(),
                });
            }
        }

        if (newMicrostoreColumnTemplate) {
            microstoreColumnTemplatesUpdatedOrCreatedHandler([newMicrostoreColumnTemplate]);

            const newWindow = window.open(
                `microstoreColumnTemplate/${newMicrostoreColumnTemplate._id}`,
                '_blank',
                'noopener,noreferrer',
            );
            if (newWindow) {
                newWindow.opener = null;
            }
        }

        setIsOpen(false);
    }

    const typeOptions =
        Object.keys(MicrostoreColumnTemplateType)
            .map((type) => ({
                value: type as MicrostoreColumnTemplateType,
                label: MICROSTORE_COLUMN_TEMPLATE_TYPE_LABELS[type as MicrostoreColumnTemplateType],
            }))
            .sort((a, b) => a.label.localeCompare(b.label)) || [];

    function onTypeSelect(option: SelectedOption<MicrostoreColumnTemplateType>) {
        if (!option) {
            return;
        }
        setMicrostoreColumnTemplateType(option.value);
    }

    return (
        <TotemPopup
            title="Créer un template de colonne"
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            contentOverflow="visible"
        >
            Veuillez donner un nom unique à votre template de colonne
            <SubmitContainer>
                <TotemInput
                    label="Nom du template de colonne"
                    onChange={setMicrostoreColumnTemplateName}
                    placeholder="Frigo basique"
                    value={microstoreColumnTemplateName}
                    autoFocus={true}
                />
                <TotemSelect<MicrostoreColumnTemplateType>
                    label="Type de colonne"
                    placeholder=""
                    value={typeOptions.find((typeOption) => typeOption.value === microstoreColumnTemplateType)}
                    options={typeOptions}
                    onChange={(option: SelectedOption<MicrostoreColumnTemplateType>) => onTypeSelect(option)}
                    ignore-accents={false}
                />
                <TotemPrimaryButton onClick={handleSubmit}>
                    {creationLoading ? <Loader size="20px" mode={LoaderModeType.Spin} /> : 'Confirmer'}
                </TotemPrimaryButton>
            </SubmitContainer>
        </TotemPopup>
    );
}

const SubmitContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 15px;

    > :not(:first-child) {
        margin-top: 15px;
    }
`;

const startHour = 0;
const endHour = 23;

const subventionWindowHours: string[] = [];

for (let i = startHour; i <= endHour; i++) {
    if (i < 10) {
        subventionWindowHours.push(`0${i}:00`);
        subventionWindowHours.push(`0${i}:30`);
    } else {
        subventionWindowHours.push(`${i}:00`);
        subventionWindowHours.push(`${i}:30`);
    }
}
subventionWindowHours.push('23:59');

export const subventionWindowOptions = subventionWindowHours.map((hour) => ({ value: hour, label: hour }));

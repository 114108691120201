import React from 'react';

import styled from 'styled-components';

import { TotemCheckbox } from 'components/TotemCheckbox';

export type ColumnType = {
    title: string;
    items: ItemType[];
};

export type ItemType = {
    _id: string;
    content: JSX.Element | string;
};

export const Column = ({
    column,
    selectedItemIds,
    setSelectedItemIds,
    columnWidth = '220px',
}: {
    column: ColumnType;
    selectedItemIds?: string[];
    setSelectedItemIds?: (value: React.SetStateAction<string[]>) => void;
    columnWidth?: string;
}) => {
    const { title, items } = column;
    const columnItemIds = items.map(({ _id }) => _id);
    const isSelected = selectedItemIds
        ? !!columnItemIds.length && columnItemIds.every((columnItemId) => selectedItemIds.includes(columnItemId))
        : false;

    function toggleColumnSelection(columnItemIds: string[]) {
        if (!selectedItemIds || !setSelectedItemIds) {
            return;
        }
        const columnItemIdsNotIncludedInSelected = columnItemIds.filter(
            (columnItemId) => !selectedItemIds?.includes(columnItemId),
        );
        if (!columnItemIdsNotIncludedInSelected.length) {
            setSelectedItemIds(
                selectedItemIds?.filter((supplyOrderId) => !columnItemIds.includes(supplyOrderId)) ?? [],
            );
        } else {
            setSelectedItemIds([...(selectedItemIds ?? []), ...columnItemIdsNotIncludedInSelected]);
        }
    }

    return (
        <Container columnWidth={columnWidth}>
            <ColumnHeader>
                {selectedItemIds && setSelectedItemIds ? (
                    <CheckboxContainer>
                        <TotemCheckbox checked={isSelected} onChange={() => toggleColumnSelection(columnItemIds)} />
                    </CheckboxContainer>
                ) : null}
                <Title>
                    {title} ({items.length})
                </Title>
            </ColumnHeader>
            <TaskList>
                {items.map(({ _id, content }) => (
                    <div key={_id}>{content}</div>
                ))}
            </TaskList>
        </Container>
    );
};

const Container = styled.div`
    border-radius: ${({ theme }) => theme.borderRadius};
    width: ${({ columnWidth }: { columnWidth: string }) => columnWidth};
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.cardBackgroundColor};
`;

const ColumnHeader = styled.div`
    border-radius: ${({ theme }) => theme.borderRadius} ${({ theme }) => theme.borderRadius} 0 0;
    display: flex;
    align-items: center;
    padding: 10px;
    font-size: 20px;
`;

const CheckboxContainer = styled.div`
    margin-right: 10px;
`;

const Title = styled.span`
    font-size: 20px;
    color: ${({ theme }) => theme.textColor};
`;

const TaskList = styled.div`
    border-radius: 0 0 ${({ theme }) => theme.borderRadius} ${({ theme }) => theme.borderRadius};
    border-top: 1px solid ${({ theme }) => theme.lightBorderColor};
    padding: 10px;
    flex-grow: 1;
    overflow-y: scroll;
    scrollbar-width: 10px;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

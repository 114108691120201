import React from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { PAGES } from '../../constants/pages';
import { BADGE_TYPES } from './constants/types';

import { FieldToDisplay, ListView } from 'components/ListView';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { Header, HeaderTitle } from 'components/Header';
import { Loader } from 'components/Loader';

import { PageTitle } from 'components/PageTitle';
import { TotemImage } from 'components/TotemImage';
import { useGetBadgesQuery } from 'data/__generated__';

type FormattedBadgeType = {
    _id: string;
    name: string;
    type: string;
    image: JSX.Element;
};

export const Badges = () => {
    const { loading: badgesLoading, data: badgesData, error: badgesError } = useGetBadgesQuery();
    if (badgesLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (!badgesData || badgesError) {
        throw new Error('Une erreur est survenue lors de la récupération des badges');
    }

    const { badges } = badgesData;

    const BADGES_FIELDS_TO_DISPLAY: FieldToDisplay<FormattedBadgeType>[] = [
        //useful for product csv import creation
        {
            fieldName: 'image',
            label: 'Logo',
        },
        {
            fieldName: 'name',
            label: 'Nom',
        },
        { fieldName: 'type', label: 'Type' },
        { fieldName: '_id', label: 'ID' },
    ];

    const formattedBadges: FormattedBadgeType[] = badges.map(({ _id, name, type, imageUrl }) => ({
        _id,
        name,
        type: BADGE_TYPES[type],
        image: <TotemImage src={`${window.__TOTENV__.BASE_CLOUDFRONT_IMAGE_URL}${imageUrl}`} alt={name} size={28} />,
    }));

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.badges} />
                </HeaderTitle>
                <Link to="/badge/create">
                    <TotemPrimaryButton>Créer un badge</TotemPrimaryButton>
                </Link>
            </Header>
            <Content>
                <ListView<FormattedBadgeType>
                    fieldsToDisplay={BADGES_FIELDS_TO_DISPLAY}
                    items={formattedBadges}
                    linkBasePath="/badge/"
                    keyExtractor={(item) => item._id}
                />
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Content = styled.div`
    padding: 15px;
    flex: 1;
    overflow: hidden;
`;

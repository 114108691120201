import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { FaSave } from 'react-icons/fa';
import { toast } from 'react-toastify';

import styled from 'styled-components';

import { GET_DETAILED_ORDER_QUERY } from 'data/queries/order';
import { GET_DETAILED_ORDER, GET_DETAILED_ORDERVariables } from 'data/queries/__generated__/GET_DETAILED_ORDER';
import { UPDATE_ORDER_INFO, UPDATE_ORDER_INFOVariables } from 'data/mutations/__generated__/UPDATE_ORDER_INFO';
import { UPDATE_ORDER_INFO_MUTATION } from 'data/mutations/order';
import { OrderUpdateInput } from 'data/__generated__';

import { PAGES } from 'constants/pages';

import { GeneralInfoSection } from 'pages/Orders/OrderDetails/GeneralInfoSection';
import { StatesSection } from 'pages/Orders/OrderDetails/StatesSection';

import { Header, HeaderTitle } from 'components/Header';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { PageTitle } from 'components/PageTitle';
import { Loader, LoaderModeType } from 'components/Loader';
import { PricesSection } from './PricesSection';
import { ProductsSection } from './ProductsSection';
import { DuplicateOrderPopup } from './DuplicateOrderPopup';

export const OrderDetails = () => {
    const { orderId = '' }: { orderId?: string } = useParams<'orderId'>();
    const methods = useForm<OrderUpdateInput>();

    const [updateOrderInfo, { loading: updateLoading }] = useMutation<UPDATE_ORDER_INFO, UPDATE_ORDER_INFOVariables>(
        UPDATE_ORDER_INFO_MUTATION,
    );

    const [isDuplicateOrderPopupOpen, setIsDuplicateOrderPopupOpen] = useState(false);

    const {
        data: orderData,
        loading: orderLoading,
        error: orderError,
    } = useQuery<GET_DETAILED_ORDER, GET_DETAILED_ORDERVariables>(GET_DETAILED_ORDER_QUERY, {
        variables: {
            orderId,
        },
    });

    if (orderLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (orderError || !orderData) {
        throw new Error('Une erreur est survenue lors de la récupération de la commande');
    }

    const { detailedOrder } = orderData;

    if (!detailedOrder) {
        throw new Error(`Aucun produit avec l'id ${orderId} n'a été trouvé`);
    }

    const {
        formState: { isDirty },
        handleSubmit,
        reset,
    } = methods;

    const handleFormSubmit = handleSubmit(async (fields) => {
        const { data } = await updateOrderInfo({
            variables: {
                orderId,
                fieldsToUpdate: fields,
            },
        });

        if (data) {
            const {
                updateOrderInfo: { success, error },
            } = data;
            if (success) {
                toast.success(`La commande "${orderId}" a bien été modifiée`);
                reset(fields);
            } else {
                if (error) {
                    toast.error(error);
                } else {
                    throw Error("Une erreur inconnue s'est produite");
                }
            }
        } else {
            throw Error("Une erreur inconnue s'est produite");
        }
    });

    return (
        <Container>
            <FormProvider {...methods}>
                <Form onSubmit={handleFormSubmit}>
                    <Header>
                        <HeaderTitle>
                            <PageTitle page={PAGES.orderDetails} />
                        </HeaderTitle>
                        <CTAsContainer>
                            Si besoin, veuillez mettre à jour la commande sur
                            <WebappLink href="https://app.totem.co/admin" target="_blank" rel="nooperer noreferrer">
                                webapp
                            </WebappLink>
                            {!detailedOrder?.delivery ? (
                                <TotemPrimaryButton minWidth="140px" type="submit" disabled={!isDirty}>
                                    {updateLoading ? (
                                        <Loader size="18px" mode={LoaderModeType.Spin} />
                                    ) : (
                                        <>
                                            <FaSave size={13} />
                                            <SaveLabel>Mettre à jour</SaveLabel>
                                        </>
                                    )}
                                </TotemPrimaryButton>
                            ) : null}
                            <TotemPrimaryButton type="button" onClick={() => setIsDuplicateOrderPopupOpen(true)}>
                                Dupliquer
                            </TotemPrimaryButton>
                            <ButtonLink to="/orders">
                                <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                            </ButtonLink>
                        </CTAsContainer>
                    </Header>
                    <Content>
                        <ScrollableContent>
                            <GeneralInfoSection order={detailedOrder} />
                            <PricesSection order={detailedOrder} />
                            <StatesSection order={detailedOrder} />
                            <ProductsSection order={detailedOrder} />
                        </ScrollableContent>
                    </Content>
                </Form>
            </FormProvider>
            <DuplicateOrderPopup
                orderId={orderId}
                isOpen={isDuplicateOrderPopupOpen}
                setIsOpen={(arg) => setIsDuplicateOrderPopupOpen(arg)}
                destinationTotemId={detailedOrder.totem._id}
                initialFormValues={{
                    deliveryTimeWindowStart: detailedOrder.deliveryTimeWindows[0].start,
                    deliveryTimeWindowStop: detailedOrder.deliveryTimeWindows[0].stop,
                    isOrderPunctual: true,
                }}
            />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
`;

const Content = styled.div`
    flex: 1;
    overflow: hidden;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const ScrollableContent = styled.div`
    padding: 15px;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

const ButtonLink = styled(Link)`
    margin-left: 5px;
`;

const CTAsContainer = styled.div`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.textColor};

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const WebappLink = styled.a`
    color: ${({ theme }) => theme.ctaPrimaryColor};
    text-decoration: none;
    margin-left: 3px;

    &:hover {
        text-decoration: underline;
    }
`;

const SaveLabel = styled.span`
    margin-left: 5px;
`;

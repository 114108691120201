import React from 'react';
import styled from 'styled-components';

import { QuantityVariationType } from 'data/__generated__';

import { ConsumptionVariationFragment_main } from 'data/fragments/__generated__/ConsumptionVariationFragment';
import { ColumnsSectionContainer, SectionColumn } from 'components/DetailsView/Section';
import { TotemLabel } from 'components/TotemLabel';
import { TotemInput } from 'components/TotemInput';
import { TotemSelect } from 'components/TotemSelect';

const TYPE_OPTIONS = [
    { label: 'Pourcentage', value: QuantityVariationType.Percentage },
    { label: 'Unit', value: QuantityVariationType.Unit },
];

export const QuantityVariationDetails = ({
    title,
    quantityVariation,
    updateQuantityVariation,
}: {
    title: string;
    quantityVariation: Omit<ConsumptionVariationFragment_main, '__typename'>;
    updateQuantityVariation: (quantityVariation: Omit<ConsumptionVariationFragment_main, '__typename'>) => void;
}) => {
    return (
        <Container>
            <TotemLabel>{title}</TotemLabel>
            <ColumnsSectionContainer numberOfColumns={2}>
                <SectionColumn>
                    <TotemSelect
                        label="Type"
                        value={TYPE_OPTIONS.find(({ value }) => value === quantityVariation.type)}
                        options={TYPE_OPTIONS}
                        onChange={(newType) => {
                            if (!newType) {
                                return;
                            }
                            updateQuantityVariation({
                                ...quantityVariation,
                                type: newType.value,
                            });
                        }}
                    />
                </SectionColumn>
                <SectionColumn>
                    <TotemInput
                        label="Valeur"
                        type="number"
                        value={quantityVariation.value}
                        onChange={(value) => {
                            const newValue = parseInt(value) || 0;
                            updateQuantityVariation({
                                ...quantityVariation,
                                value: newValue,
                            });
                        }}
                        step="1"
                    />
                </SectionColumn>
            </ColumnsSectionContainer>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 10px;
    margin-top: 20px;
    background-color: ${({ theme }) => theme.cardBackgroundColor};
    border-radius: ${({ theme }) => theme.borderRadius};
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
`;

import React, { useState } from 'react';

import styled from 'styled-components';

import { PAGES } from '../../constants/pages';

import { Header, HeaderTitle } from 'components/Header';
import { PageTitle } from 'components/PageTitle';
import { TotemInput } from 'components/TotemInput';
import { UserList } from './components';
import { Link } from 'react-router-dom';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

export const Users = () => {
    const [filterString, setFilterString] = useState('');

    async function updateSearchParams(value: string) {
        setFilterString(value);
    }

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.users} />
                </HeaderTitle>
                <ButtonsContainer>
                    <Link to="/user/create-toteam">
                        <TotemPrimaryButton>Ajouter un administrateur</TotemPrimaryButton>
                    </Link>
                </ButtonsContainer>
            </Header>
            <Content>
                <TotemInput
                    label="Recherche"
                    onChange={updateSearchParams}
                    placeholder="Email, nom ou id de l'utilisateur"
                    value={filterString}
                    autoFocus={true}
                />
                <ListContainer>
                    <UserList filterString={filterString} />
                </ListContainer>
            </Content>
        </Container>
    );
};

const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    ${TotemPrimaryButton} {
        margin-left: 5px;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    flex: 1;
    overflow: hidden;
`;

const ListContainer = styled.div`
    flex: 1;
    overflow: hidden;
`;

import React from 'react';
import { FutureStockVariationType } from 'data/__generated__';
import { CommonStatusTag } from 'components/CommonStatusTag';
import { colors } from 'constants/colors';

export const TypeTag = ({ type }: { type: FutureStockVariationType }) => {
    const getColorsFromType = (type: FutureStockVariationType) => {
        const { pink, blue, orange, green, pureWhite } = colors;

        switch (type) {
            case FutureStockVariationType.PunctualOrder:
                return { backgroundColor: blue, labelColor: pureWhite };
            case FutureStockVariationType.RecurringOrder:
                return { backgroundColor: blue, labelColor: pureWhite };
            case FutureStockVariationType.StockTransfer:
                return { backgroundColor: orange, labelColor: pureWhite };
            case FutureStockVariationType.SupplyOrder:
                return { backgroundColor: green, labelColor: pureWhite };
            case FutureStockVariationType.OrderPrediction:
                return { backgroundColor: pink, labelColor: pureWhite };
            case FutureStockVariationType.StockTransferPrediction:
                return { backgroundColor: pink, labelColor: pureWhite };
        }
    };

    const { backgroundColor, labelColor } = getColorsFromType(type);

    return <CommonStatusTag backgroundColor={backgroundColor} labelColor={labelColor} label={type} />;
};

import gql from 'graphql-tag';

export const ATTACH_LAST_STRIPE_PAYMENT_METHOD_MUTATION = gql`
    mutation ATTACH_LAST_STRIPE_PAYMENT_METHOD($userId: ID!) {
        attachLastStripePaymentMethod(userId: $userId) {
            error
            success
            user {
                _id
                wallet {
                    ownerId
                    creditCards {
                        _id
                        default
                        details {
                            number
                            expiration
                            bank
                            country
                            expYear
                            expMonth
                        }
                        expired
                        paymentType
                        provider
                        retries
                        type
                    }
                }
            }
        }
    }
`;

import React, { useContext, useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import { InitialUserContext } from '../../contexts';
import { TOTEM_ADMIN_SIGNIN, VERIFY_LOGGED_IN } from 'data/mutations/user';

import { TotemInput } from 'components/TotemInput';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

import { TOTEM_BEARER_TOKEN } from '../../constants/token';

export const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [signIn] = useMutation(TOTEM_ADMIN_SIGNIN);
    const [verifyLoggedIn] = useMutation(VERIFY_LOGGED_IN);
    const { setUser } = useContext(InitialUserContext);

    useEffect(() => {
        const checkAuthToken = async () => {
            const {
                data: { verifyLoggedIn: result },
            } = await verifyLoggedIn();
            if (result.success) {
                setUser(result.user);
            }
            setLoading(false);
        };

        checkAuthToken();
    }, [verifyLoggedIn, setUser, setError]);

    const submitForm = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setError(null);

        const {
            data: { signinTotemAdmin: result },
        } = await signIn({ variables: { username, password } });

        if (result.success) {
            const { token, user } = result;
            setUser(user);
            window.localStorage.setItem(TOTEM_BEARER_TOKEN, token);
        } else {
            setError(result.error);
        }
    };

    if (loading) {
        return null;
    }

    return (
        <Container>
            <Helmet title="Connexion · TOTADMIN" />
            <Title>Connexion</Title>
            {error ? <ErrorMessage>L'erreur suivante est survenue : {error}</ErrorMessage> : null}
            <LoginForm onSubmit={submitForm}>
                <TotemInput
                    value={username}
                    placeholder="Adresse email"
                    onChange={(value) => setUsername(value)}
                    dataTest="login-username"
                />
                <TotemInput
                    value={password}
                    placeholder="Mot de passe"
                    type="password"
                    onChange={(value) => setPassword(value)}
                    dataTest="login-password"
                />
                <ExternalLink href="https://app.totem.co/recover-password" target="_blank" rel="nooperer noreferrer">
                    Mot de passe oublié ?
                </ExternalLink>
                <SubmitButton type="submit" data-test="login-submit">
                    Se connecter
                </SubmitButton>
            </LoginForm>
        </Container>
    );
};

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const ExternalLink = styled.a`
    color: ${({ theme }) => theme.ctaPrimaryTextColor};
`;

const ErrorMessage = styled.span`
    color: ${({ theme }) => theme.errorColor};
`;

const Title = styled.h1`
    text-transform: uppercase;
    color: ${({ theme }) => theme.textColor};
    letter-spacing: 0.7px;
    font-size: 40px;
`;

const LoginForm = styled.form`
    display: flex;
    flex-direction: column;
    width: 250px;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

const SubmitButton = styled(TotemPrimaryButton)`
    margin-top: 15px;
`;

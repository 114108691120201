import React, { useState } from 'react';

import styled from 'styled-components';
import { AiOutlineInfoCircle } from 'react-icons/ai';

import { PAGES } from '../../constants/pages';
import { colors } from 'constants/colors';

import { TrelloCardInfoPopup } from 'pages/SupplyOrders/Popups/TrelloCardInfoPopup';
import { Header, HeaderTitle } from 'components/Header';
import { PageTitle } from 'components/PageTitle';
import { TotemDatePicker } from 'components/TotemDatePicker';
import { SupplyOrdersTrelloView } from './SupplyOrdersTrelloView';

export const SupplyOrders = () => {
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    const [displayLimitDate, setDisplayLimitDate] = useState<Date>(oneWeekAgo);
    const [trelloCardInfoPopup, setTrelloCardInfoPopup] = useState(false);

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.supplyOrders} />
                </HeaderTitle>
                <SideContainer>
                    <InfoIcon size={25} onClick={() => setTrelloCardInfoPopup(true)} />
                    <DateContainer>
                        <TotemDatePicker
                            label="Date limite affichage rangés et annulés"
                            selected={displayLimitDate}
                            onChange={(date) => {
                                setDisplayLimitDate(date as Date);
                            }}
                        />
                    </DateContainer>
                </SideContainer>
            </Header>
            <SupplyOrdersTrelloView displayLimitDate={displayLimitDate} />
            <TrelloCardInfoPopup setIsOpen={setTrelloCardInfoPopup} isOpen={trelloCardInfoPopup} />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const DateContainer = styled.div`
    min-width: 300px;
    justify-self: end;
`;

const SideContainer = styled.div`
    display: flex;
    align-items: center;
`;

const InfoIcon = styled(AiOutlineInfoCircle)`
    margin-right: 15px;
    cursor: pointer;
    color: ${colors.blue};
    z-index: 1;

    &:hover {
        transform: scale(1.1);
    }
`;

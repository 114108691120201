import gql from 'graphql-tag';
import { HOT_DRINKS_ARRANGEMENT_FRAGMENT } from 'data/fragments/hotDrinksArrangement';

export const UPDATE_HOT_DRINKS_ARRANGEMENT_MUTATION = gql`
    mutation UPDATE_HOT_DRINKS_ARRANGEMENT($hotDrinksArrangementId: ID!, $productIds: [String!]!) {
        updateHotDrinksArrangement(hotDrinksArrangementId: $hotDrinksArrangementId, productIds: $productIds) {
            ...HotDrinksArrangementFragment
        }
    }
    ${HOT_DRINKS_ARRANGEMENT_FRAGMENT}
`;

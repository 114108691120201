import { FORM_ERROR_FRAGMENT } from 'data/fragments/product';
import gql from 'graphql-tag';

export const UPDATE_ORGANIZATION_MUTATION = gql`
    mutation UPDATE_ORGANIZATION($organizationId: ID!, $fields: OrganizationUpdateInput!) {
        updateOrganization(organizationId: $organizationId, fields: $fields) {
            error
            formErrors {
                ...FormErrorFragment
            }
            updatedOrganization {
                _id
                B2BPriceRange
                domains
                maxUsers
                sales {
                    _id
                    fullName
                }
                totalCoworkers
                zuoraAccountId
            }
        }
    }
    ${FORM_ERROR_FRAGMENT}
`;

export const CREATE_ORGANIZATION_MUTATION = gql`
    mutation CREATE_ORGANIZATION($adminUserId: ID!, $name: String!, $domain: String) {
        createOrganization(adminUserId: $adminUserId, name: $name, domain: $domain) {
            error
            createdOrganization {
                _id
                name
            }
        }
    }
`;

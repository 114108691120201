import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { UPDATE_DELIVERY_DATE_MUTATION } from 'data/mutations/delivery';
import { UPDATE_DELIVERY_DATE, UPDATE_DELIVERY_DATEVariables } from 'data/mutations/__generated__/UPDATE_DELIVERY_DATE';

import { Loader, LoaderModeType } from 'components/Loader';
import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { TotemDatePicker } from 'components/TotemDatePicker';

import { formatDateAsAnniversary } from 'helpers/dateTimes';

export function UpdateDeliveryDatePopup({
    deliveryId,
    isOpen,
    setIsOpen,
}: {
    deliveryId: string;
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
}) {
    const [deliveryDate, setDeliveryDate] = useState<Date | null>(null);

    const [updateDeliveryDate, { loading }] = useMutation<UPDATE_DELIVERY_DATE, UPDATE_DELIVERY_DATEVariables>(
        UPDATE_DELIVERY_DATE_MUTATION,
    );

    async function handleSubmit() {
        if (deliveryDate) {
            const { data } = await updateDeliveryDate({
                variables: {
                    deliveryId,
                    deliveryDate: formatDateAsAnniversary({ dateTime: deliveryDate }),
                },
            });

            if (!data) {
                throw new Error('Une erreur est survenue lors de la création de la livraison');
            }

            const { updateDeliveryDate: result } = data;

            if (result.updatedDelivery) {
                toast.success('La date de livraison a été modifiée !');
                setIsOpen(false);
            } else {
                result?.errors?.forEach((error, index) => {
                    toast.error(<span key={index}>Erreur : {error}</span>, { autoClose: false });
                });

                if (result?.errors?.length && result?.errors?.length > 1) {
                    toast.info('Cliquez pour fermer toutes les notifications', {
                        autoClose: false,
                        onClick: () => toast.dismiss(),
                    });
                }
            }
        }
    }

    return (
        <TotemPopup
            title="Modifier une date de livraison"
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            contentOverflow="visible"
        >
            <InfoContainer>
                <p>
                    Vous ne pouvez modifier la date d'une livraison que si elle n'est pas/plus assignée à une tournée
                    sur Urbantz.
                </p>
                <p>
                    Ceci ne modifie pas la commande ou le transfert de stock associé, uniquement la logique
                    opérationnelle attachée (la livraison et la tache Urbantz associées)
                </p>
                <p>
                    En particulier cela ne permet pas de décaler une commande Ready dans plus de 2 jours pour la
                    modifier.
                </p>
            </InfoContainer>
            <SelectorContainer>
                <TotemDatePicker
                    label="Nouvelle date de livraison"
                    selected={deliveryDate || new Date()}
                    onChange={(date) => {
                        setDeliveryDate(date as Date);
                    }}
                />
            </SelectorContainer>
            <SubmitContainer>
                <TotemPrimaryButton onClick={handleSubmit} disabled={!deliveryDate}>
                    {loading ? <Loader size="20px" mode={LoaderModeType.Spin} /> : 'Modifier la date de livraison'}
                </TotemPrimaryButton>
            </SubmitContainer>
        </TotemPopup>
    );
}

const SelectorContainer = styled.div`
    margin: 10px 0px;
`;

const SubmitContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 15px;
`;

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 400px;
`;

import { apolloClient } from 'App';

import { toggleUserNotificationPreferenceFromAdmin_toggleUserNotificationPreferenceFromAdmin } from 'data/mutations/__generated__/toggleUserNotificationPreferenceFromAdmin';
import { GET_USER } from 'data/queries/__generated__/GET_USER';
import { GET_USER_QUERY } from 'data/queries/user';

export function notificationPreferencesUpdatedHandler({
    notificationPreferences,
    userId,
}: {
    notificationPreferences: toggleUserNotificationPreferenceFromAdmin_toggleUserNotificationPreferenceFromAdmin;
    userId: string;
}) {
    const result = apolloClient.readQuery<GET_USER>({ query: GET_USER_QUERY, variables: { userId } });
    if (!result) {
        return;
    }
    const { userFromAdmin } = result;

    const data = {
        userFromAdmin: {
            ...userFromAdmin,
            notificationPreferences,
        },
    };

    apolloClient.writeQuery({
        query: GET_USER_QUERY,
        data,
    });
}

import { Header, HeaderTitle } from 'components/Header';
import { PageTitle } from 'components/PageTitle';

import { PAGES } from 'constants/pages';
import { useCommentsQuery } from 'data/__generated__';

import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { CommentItem } from './components/CommentItem';

export const CommentsPage: FunctionComponent = () => {
    const { data, fetchMore } = useCommentsQuery({ variables: { limit: 10, offset: 0 } });

    const [isFetchingMore, setIsFetchingMore] = useState(false);

    if (!data?.comments) {
        return null;
    }

    const {
        comments: { comments, hasMore },
    } = data;

    const handleScroll = async (e: React.UIEvent<HTMLElement>) => {
        const element = e.currentTarget;
        const scrollThreshold = 0.8;
        if (
            !isFetchingMore &&
            hasMore &&
            loadMore &&
            element.scrollTop > scrollThreshold * (element.scrollHeight - element.clientHeight)
        ) {
            setIsFetchingMore(true);
            await loadMore();
            setIsFetchingMore(false);
        }
    };

    const loadMore = async () => {
        await fetchMore({
            variables: {
                offset: comments.length,
            },
        });
    };

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.comments} />
                </HeaderTitle>
            </Header>
            <Content onScroll={handleScroll}>
                {comments.map((el) => (
                    <div key={el._id}>
                        <CommentItem comment={el} isAnswer={false} />
                        <Answers>
                            {!!el.answers.length && <AnswersHeader>RÉPONSES</AnswersHeader>}
                            {el.answers.map((answer) => (
                                <div key={answer._id}>
                                    <CommentItem comment={answer} isAnswer={true} />
                                </div>
                            ))}
                        </Answers>
                    </div>
                ))}
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Content = styled.div`
    display: flex;
    padding: 20px;
    flex-direction: column;
    flex: 1;
    gap: 16px;
    overflow: auto;
    background-color: #f9f9f9;
    border-radius: 8px;
`;

const Answers = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    gap: 10px;
    padding-left: 20px;
    border-left: 2px solid #ddd;
`;

const AnswersHeader = styled.div`
    font-size: 10px;
    color: #686868;
`;

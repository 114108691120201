import React from 'react';

import { useQuery } from '@apollo/client';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { DEFAULT_RANGE_IDS } from 'constants/prices';

import { GET_ORGANIZATION_organization } from 'data/queries/__generated__/GET_ORGANIZATION';
import { GET_PRODUCT_PRICE_RANGES } from 'data/queries/__generated__/GET_PRODUCT_PRICE_RANGES';
import { GET_PRODUCT_PRICE_RANGES_QUERY } from 'data/queries/price';

import { SectionColumn, SectionContainer } from 'components/DetailsView/Section';
import { DetailFormArrayValue } from 'components/DetailsView/DetailFormArrayValue';
import { DetailFormCheckbox, DetailFormSelect, DetailFormValue, DetailValue } from 'components/DetailsView';
import { Loader } from 'components/Loader';
import { TotemImage } from 'components/TotemImage';
import { CopyValue } from 'components/CopyValue';

import { getExtendedFormattedDatetime } from 'helpers/dateTimes';

function getOctobatLink(octobatId: string | null): string {
    return 'https://app.octobat.com/customers/' + octobatId;
}

function getStripeLink(stripeId: string | null): string {
    return 'https://dashboard.stripe.com/customers/' + stripeId;
}

function getZuoraLink(zuoraAccountId: string | null): string {
    return 'https://eu.zuora.com/apps/CustomerAccount.do?method=view&id=' + zuoraAccountId;
}

function getSalesforceLink(salesforceId: string | null): string {
    return `https://thetotem.lightning.force.com/lightning/r/Account/${salesforceId}/view`;
}

function getInterventionsLink(organizationId: string): string {
    return `https://app.totem.co/intervention/${organizationId}`;
}

export const GeneralInfoSection = ({ organization }: { organization: GET_ORGANIZATION_organization }) => {
    const {
        _id,
        B2BPriceRange,
        createdAt,
        hasSubscription,
        imageUrl,
        name,
        octobatId,
        salesforceId,
        stripeId,
        updatedAt,
        zuoraAccountId,
    } = organization;

    const {
        loading: priceRangesLoading,
        data: priceRangesData,
        error: priceRangesError,
    } = useQuery<GET_PRODUCT_PRICE_RANGES>(GET_PRODUCT_PRICE_RANGES_QUERY);

    const { register } = useFormContext();

    if (priceRangesLoading) {
        return (
            <SectionContainer title="Informations générales" isInitiallyOpen>
                <Loader />
            </SectionContainer>
        );
    }

    if (priceRangesError || !priceRangesData) {
        throw new Error('Une erreur est survenue lors de la récupération des gammes');
    }

    const { productPriceRanges } = priceRangesData;
    const rangesWithoutDefault = productPriceRanges.filter(({ _id }) => !DEFAULT_RANGE_IDS.includes(_id));
    const rangeOptions = rangesWithoutDefault.map(({ _id, name }) => ({ label: name, value: _id }));

    return (
        <SectionContainer title="Informations générales" isInitiallyOpen>
            <SectionColumn>
                <CopyValue value={_id} />
                <Header>
                    <TotemImage src={imageUrl} alt="Logo de l'organisation" size={150} />
                    <SideValues>
                        <DetailValue label="Nom" value={name} />
                        <DetailValue label="Date de création" value={getExtendedFormattedDatetime(createdAt)} />
                        <DetailValue label="Dernière mise à jour" value={getExtendedFormattedDatetime(updatedAt)} />
                    </SideValues>
                </Header>
                <DetailFormArrayValue name="domains" label="Domaines" />
                <DetailFormSelect
                    label="Gamme de prix"
                    placeholder="Sélectionner une gamme de prix"
                    defaultValue={B2BPriceRange}
                    name="B2BPriceRange"
                    options={rangeOptions}
                />
                <DetailFormCheckbox
                    name="hasSubscription"
                    label="Le client ne paie pas les livraisons"
                    sublabel="Client store avec les livraisons incluses ou abonnement livraisons illimitées. Attention : mettre à jour l'abonnement sur Stripe également pour les clients freefood"
                    defaultChecked={!!hasSubscription}
                />
                <ExternalResource>
                    <ExternalResourceLink
                        href={getStripeLink(stripeId)}
                        target="_blank"
                        rel="noopener noreferrer"
                        disabled={!stripeId}
                    >
                        <TotemImage src="https://dashboard.stripe.com/favicon.ico" alt="Logo Stripe" size={30} />
                        <span>{stripeId || 'Pas de stripeId'}</span>
                    </ExternalResourceLink>
                </ExternalResource>
                <ExternalResource>
                    <ExternalResourceLink
                        href={getOctobatLink(octobatId)}
                        target="_blank"
                        rel="noopener noreferrer"
                        disabled={!octobatId}
                    >
                        <TotemImage src="https://app.octobat.com/favicon-32x32.png" alt="Logo Octobat" size={30} />
                        <span>{octobatId || "Pas d'octobatId"}</span>
                    </ExternalResourceLink>
                </ExternalResource>
                <ExternalResource>
                    <ExternalResourceLink
                        href={getZuoraLink(zuoraAccountId)}
                        target="_blank"
                        rel="noopener noreferrer"
                        disabled={!zuoraAccountId}
                    >
                        <TotemImage
                            src="https://www.zuora.com/wp-content/themes/zuora/img/zuora-logo.svg"
                            alt="Logo Zuora"
                            size={30}
                        />
                    </ExternalResourceLink>
                    <DetailFormValue
                        defaultValue={zuoraAccountId}
                        {...register('zuoraAccountId')}
                        width="100%"
                        style={{ flex: 1 }}
                    />
                </ExternalResource>
                <ExternalResource>
                    <ExternalResourceLink
                        href={getSalesforceLink(salesforceId)}
                        target="_blank"
                        rel="noopener noreferrer"
                        disabled={!salesforceId}
                    >
                        <TotemImage
                            src="https://thetotem.my.salesforce.com/img/logo214.svg"
                            alt="Logo Salesforce"
                            size={30}
                        />
                        <span>{salesforceId || 'Pas de salesforceId'}</span>
                    </ExternalResourceLink>
                </ExternalResource>
                <ExternalResource>
                    <ExternalResourceLink
                        href={getInterventionsLink(_id)}
                        target="_blank"
                        rel="noopener noreferrer"
                        disabled={false}
                    >
                        <span>Suivi des interventions</span>
                    </ExternalResourceLink>
                </ExternalResource>
            </SectionColumn>
        </SectionContainer>
    );
};

const Header = styled.div`
    display: flex;
`;

const SideValues = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 15px;

    & > :not(:first-child) {
        margin-top: 15px;
    }
`;

const ExternalResource = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;

    & > :not(:first-child) {
        margin-left: 10px;
    }
`;

const ExternalResourceLink = styled.a<{ disabled: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;

    color: ${({ theme }) => theme.ctaPrimaryColor};
    text-decoration: none;
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'default')};
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

    &:hover {
        text-decoration: underline;
    }

    & > :not(:first-child) {
        margin-left: 10px;
    }
`;

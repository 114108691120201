import React, { useState } from 'react';

import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';

import { PAGES } from 'constants/pages';

import { GET_EXPIRED_FUTURE_STOCK_VARIATIONS_QUERY } from 'data/queries/futureStockVariation';
import { GET_EXPIRED_FUTURE_STOCK_VARIATIONS } from 'data/queries/__generated__/GET_EXPIRED_FUTURE_STOCK_VARIATIONS';
import { FutureStockVariationType } from 'data/__generated__';

import { Loader, LoaderModeType } from 'components/Loader';
import { Header, HeaderTitle } from 'components/Header';
import { PageTitle } from 'components/PageTitle';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { TotemLabel } from 'components/TotemLabel';
import { FutureStockVariationsTable } from 'pages/Stocks/components/FutureStockVariationsTable';
import { TypeTag } from 'pages/Stocks/components/FutureStockVariationTypeTag';

export const ExpiredFutureStockVariations = () => {
    const [typeFilter, setTypeFilter] = useState<FutureStockVariationType[]>([]);
    const { data, loading, error } = useQuery<GET_EXPIRED_FUTURE_STOCK_VARIATIONS>(
        GET_EXPIRED_FUTURE_STOCK_VARIATIONS_QUERY,
    );

    if (loading) {
        return <Loader mode={LoaderModeType.Spin} />;
    }

    if (error || !data) {
        throw new Error('Une erreur est survenue lors de la récupération des variations de stock');
    }

    const { expiredFutureStockVariations } = data;

    function updateTypeFilter(typeToUpdate: FutureStockVariationType) {
        if (typeFilter?.includes(typeToUpdate)) {
            setTypeFilter(typeFilter.filter((type) => type !== typeToUpdate));
        } else {
            setTypeFilter([...typeFilter, typeToUpdate]);
        }
    }

    const filteredVariations = typeFilter.length
        ? expiredFutureStockVariations.filter(({ type }) => typeFilter.includes(type))
        : expiredFutureStockVariations;

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.expiredFutureStockVariations} />
                </HeaderTitle>
                <Link to="/stockVariation/futureStockVariations">
                    <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                </Link>
            </Header>
            <Content>
                <InnerContainer>
                    {expiredFutureStockVariations.length ? (
                        <>
                            <Info>
                                <TotemLabel>Total: {filteredVariations.length},</TotemLabel>
                                <TotemLabel>
                                    Réfs uniques: {Array.from(new Set(filteredVariations.map(({ ref }) => ref))).length}
                                </TotemLabel>
                            </Info>
                            <Filters>
                                <Title>Filtres</Title>
                                {[
                                    FutureStockVariationType.PunctualOrder,
                                    FutureStockVariationType.RecurringOrder,
                                    FutureStockVariationType.StockTransfer,
                                    FutureStockVariationType.SupplyOrder,
                                ].map((type) => (
                                    <FilterContainer
                                        key={type}
                                        isSelected={typeFilter.includes(type)}
                                        onClick={() => updateTypeFilter(type)}
                                    >
                                        <TypeTag type={type} />
                                    </FilterContainer>
                                ))}
                            </Filters>
                            <FutureStockVariationsTable futureStockVariations={filteredVariations} displayProductName />
                        </>
                    ) : (
                        'Pas de variations de stock à venir expirées trouvées'
                    )}
                </InnerContainer>
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Content = styled.div`
    padding: 15px;
    flex: 1;
    overflow: auto;
`;

const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Title = styled.h3`
    margin: 0;
    font-size: 20px;
    font-weight: 800;
`;

const Info = styled.div`
    display: flex;
    flex-direction: row;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const Filters = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const FilterContainer = styled.div<{ isSelected: boolean }>`
    cursor: pointer;
    border-radius: 20px;
    border: 2px solid ${({ isSelected, theme }) => (isSelected ? theme.darkBorderColor : 'transparent')};
`;

import gql from 'graphql-tag';

export const UPDATE_PAYMENT_MUTATION = gql`
    mutation UPDATE_PAYMENT($paymentId: ID!, $fields: PaymentUpdateInput!) {
        updatePayment(paymentId: $paymentId, fields: $fields) {
            error
            updatedPayment {
                _id
                invoiceId
            }
        }
    }
`;

import { useQuery } from '@apollo/client';
import React from 'react';
import styled from 'styled-components';

import { GET_ORDERS_FROM_ORGANIZATION_QUERY } from 'data/queries/order';
import { GET_ORDERS_FROM_ORGANIZATION } from 'data/queries/__generated__/GET_ORDERS_FROM_ORGANIZATION';

import { Loader, LoaderModeType } from 'components/Loader';
import { SelectedOption, TotemSelect } from 'components/TotemSelect';

export function OrderSelector({
    organizationId,
    selectedOption,
    setSelectedOption,
}: {
    organizationId: string;
    selectedOption: SelectedOption<string>;
    setSelectedOption: (option: SelectedOption<string>) => void;
}) {
    const {
        loading: ordersLoading,
        data: ordersData,
        error: ordersError,
    } = useQuery<GET_ORDERS_FROM_ORGANIZATION>(GET_ORDERS_FROM_ORGANIZATION_QUERY, { variables: { organizationId } });

    if (ordersLoading) {
        return <Loader mode={LoaderModeType.Spin} />;
    }

    if (ordersError || !ordersData) {
        throw new Error('Une erreur est survenue lors de la récupération des organisations');
    }

    const handleOrderSelect = (option: SelectedOption<string>) => {
        setSelectedOption(option);
    };

    const ordersOptions = ordersData.activeOrdersFromOrganization
        .map((order) => ({
            value: order._id,
            label: `TOTEM #${order.totem.number} (${order.totem.name || 'sans nom'}) ● ${
                order.isPunctual ? 'Ponctuelle' : 'Récurrente'
            } ● Commande n°${order.number} ● ${order.deliveryDate}`,
        }))
        .sort((orderA, orderB) => orderA.label.toLowerCase().localeCompare(orderB.label.toLowerCase()));

    return (
        <Container>
            <TotemSelect
                label="Commande"
                placeholder="Sélectionner une commande"
                value={selectedOption}
                options={ordersOptions}
                onChange={handleOrderSelect}
            />
        </Container>
    );
}

const Container = styled.div`
    /* These lines are a placeholder for the select options */
    min-width: 400px;
    min-height: 150px;
`;

import { gql } from '@apollo/client';
import { FUTURE_STOCK_VARIATION_FRAGMENT } from 'data/fragments/futureStockVariation';

export const GET_FUTURE_STOCK_VARIATIONS_BY_PRODUCT_QUERY = gql`
    query GET_FUTURE_STOCK_VARIATIONS_BY_PRODUCT($productId: String!) {
        futureStockVariationsByProduct(productId: $productId) {
            ...FutureStockVariationFragment
        }
    }
    ${FUTURE_STOCK_VARIATION_FRAGMENT}
`;

export const GET_EXPIRED_FUTURE_STOCK_VARIATIONS_QUERY = gql`
    query GET_EXPIRED_FUTURE_STOCK_VARIATIONS {
        expiredFutureStockVariations {
            ...FutureStockVariationFragment
            productName
        }
    }
    ${FUTURE_STOCK_VARIATION_FRAGMENT}
`;

export const REPLACEABLE_PRODUCT_ORDERS_AND_FUTURE_STOCK_VARIATIONS_QUERY = gql`
    query REPLACEABLE_PRODUCT_ORDERS_AND_FUTURE_STOCK_VARIATIONS($productId: String!) {
        replaceableProductOrdersAndFutureStockVariations(productId: $productId) {
            futureStockVariations {
                ...FutureStockVariationFragment
            }
            orders {
                _id
                date_delivery
                number

                organization {
                    name
                }
                products {
                    _id
                    quantity
                }
                state
                totem {
                    _id
                    number
                }
            }
        }
    }
    ${FUTURE_STOCK_VARIATION_FRAGMENT}
`;

import React, { useState, useEffect } from 'react';

import { useMutation } from '@apollo/client';
import { useForm, FormProvider } from 'react-hook-form';
import { FaSave } from 'react-icons/fa';
import { Link, unstable_usePrompt } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { PAGES } from 'constants/pages';
import { DEFAULT_PANTIN_WAREHOUSE_SITE_ID } from 'pages/Stocks/constants';

import { UPDATE_PRODUCT_MUTATION } from 'data/mutations/product';

import { GET_ACTIVE_ORGANIZATIONS_activeOrganizations } from 'data/queries/__generated__/GET_ACTIVE_ORGANIZATIONS';
import { GET_PRODUCT_PRICES_productPrices } from 'data/queries/__generated__/GET_PRODUCT_PRICES';
import { GET_PRODUCT_PRICE_RANGES_productPriceRanges } from 'data/queries/__generated__/GET_PRODUCT_PRICE_RANGES';
import { GET_ACTIVE_CATEGORIES_activeCategories } from 'data/queries/__generated__/GET_ACTIVE_CATEGORIES';
import { GET_LOCATIONS_FOR_SELECTION_locationSelectOptions } from 'data/queries/__generated__/GET_LOCATIONS_FOR_SELECTION';
import {
    ProductSupplierUpdate,
    ProductState,
    BarcodeType,
    GetDetailedProductQuery,
    UpdateProductMutation,
    UpdateProductMutationVariables,
} from 'data/__generated__';
import { GET_TAGS_tags } from 'data/queries/__generated__/GET_TAGS';
import { GET_SUPPLIERS_suppliers } from 'data/queries/__generated__/GET_SUPPLIERS';

import { PageTitle } from 'components/PageTitle';
import { Header, HeaderTitle } from 'components/Header';
import { Loader, LoaderModeType } from 'components/Loader';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

import { DimensionsSection } from './DimensionsSection';
import { GeneralInfoSection } from './GeneralInfoSection';
import { SalesInfoSection } from './SalesInfoSection';
import { StatesSection } from './StatesSection';
import { NutritionalInfoSection } from './NutritionalInfoSection';
import { StockAndSupplierInfoSection } from './StockAndSupplierInfoSection';
import { TagsAndBadgesSection } from './TagsAndBadgesSection';
import { ProductModal } from 'pages/Products/ProductDetails/components/ProductModal';
import { formatFormArray, formatMultilineToArray } from 'pages/Products/utils';
import { ProductGroupSection } from './ProductGroupSection';
import { ProductForm } from './types';
import { ProductArrangementsSection } from './ProductArrangementsSection';

type RangePrice = {
    rangeId: string;
    price: number;
    crossedPrice?: number;
};

export const ProductGlobalForm = ({
    tags,
    categories,
    organizations,
    detailedProduct,
    productPrices,
    productPriceRanges,
    locations,
    suppliers,
}: {
    tags: GET_TAGS_tags[];
    categories: GET_ACTIVE_CATEGORIES_activeCategories[];
    organizations: GET_ACTIVE_ORGANIZATIONS_activeOrganizations[];
    detailedProduct: NonNullable<GetDetailedProductQuery['detailedProduct']>;
    productPrices: GET_PRODUCT_PRICES_productPrices[];
    productPriceRanges: GET_PRODUCT_PRICE_RANGES_productPriceRanges[];
    locations: GET_LOCATIONS_FOR_SELECTION_locationSelectOptions[];
    suppliers: GET_SUPPLIERS_suppliers[];
}) => {
    const { _id, displayKg, name, access, barcodes, diets, portion, preferences, tagIds, category } = detailedProduct;
    const [modalIsOpen, setIsOpen] = useState(false);

    const [updateProduct, { loading: updateLoading }] = useMutation<
        UpdateProductMutation,
        UpdateProductMutationVariables
    >(UPDATE_PRODUCT_MUTATION);

    // This aims at progressively replacing obnoxious react-hook-form
    const initialForm = {
        categoryId: detailedProduct.category._id,
        subcategoryId: detailedProduct.subcategory?._id ?? null,
    };
    const [productForm, setProductForm] = useState<ProductForm>(initialForm);

    type FormData = {
        externalProductId: string | null;
        access: { value: string; label: string }[];
        barcodes: { barcode: string; type: BarcodeType }[];
        brand: string;
        canExpire: boolean;
        categoryId: string;
        checkSwollenLid: boolean;
        diets: { value: string; label: string }[];
        ingredients?: string | null;
        isBeingTested: boolean;
        isFreefood: boolean;
        isMicrostore: boolean;
        isPunctual: boolean;
        isToBeArchived: boolean;
        locationId?: string;
        name: string;
        newUntil: string | null;
        onboardedForSensei: boolean;
        preferences: { value: string; label: string }[];
        rangePrices: RangePrice[];
        shouldBeHiddenInStore: boolean;
        state: ProductState;
        subcategoryId: string | null;
        suppliers: ProductSupplierUpdate[];
        supplierShortageInfo: {
            inShortage: boolean;
            returnDate: string | null;
        };
        tagOptions: { value: string; label: string }[]; //object type used with UI Select, stocking as strings in DB
        trackExpiryDate: boolean;
        volume: string;
        searchKeywords: string | null;
    };

    const openModal = () => {
        setIsOpen(true);
    };

    const methods = useForm<FormData>({
        // need to define defaultValues here in order for useFieldArray to work
        defaultValues: {
            // need to transform string to object because useFieldArray doesn't support strings in arrays
            barcodes,
            access: access.reduce<{ value: string; label: string }[]>((acc, organizationId) => {
                const activeOrganization = organizations.find(({ _id }) => _id === organizationId);
                if (activeOrganization) {
                    acc.push({
                        value: organizationId,
                        label: activeOrganization.name,
                    });
                }

                return acc;
            }, []),
            tagOptions: tagIds.map((tagId) => {
                const tag = tags.find(({ _id }) => _id === tagId);
                return {
                    value: tagId,
                    label: tag?.name || 'Sans nom',
                };
            }),
            preferences: preferences.map(({ _id, name }) => {
                return {
                    value: _id,
                    label: name,
                };
            }),
            diets: diets.map(({ _id, name }) => {
                return {
                    value: _id,
                    label: name,
                };
            }),
        },
    });

    const {
        handleSubmit,
        formState: { isDirty, isSubmitSuccessful },
        reset,
    } = methods;

    unstable_usePrompt({
        message: 'Êtes-vous sûr.e de vouloir quitter cette page sans enregistrer ?',
        when: ({ currentLocation, nextLocation }) => isDirty && currentLocation.pathname !== nextLocation.pathname,
    });

    // resetting the form here because it shouldn't be done
    // inside the handleSubmit function (#readthedocs)
    useEffect(() => {
        if (isSubmitSuccessful) {
            reset({}, { keepValues: true });
        }
    }, [isSubmitSuccessful, reset]);

    const onSubmit = handleSubmit(async (fields) => {
        fields.isBeingTested = fields.isBeingTested || detailedProduct.isBeingTested;

        const tagIds = formatFormArray(fields.tagOptions);
        const formattedAccess = formatFormArray(fields.access);
        const formattedBarcodes = fields.barcodes.map(({ barcode, type }) => ({ barcode, type }));
        const formattedDiets = formatFormArray(fields.diets);
        const formattedPreferences = formatFormArray(fields.preferences);
        const formattedSearchKeywords = formatMultilineToArray(fields.searchKeywords || '');

        const { tagOptions, ...fieldsWithoutTags } = fields;

        if (!productForm.categoryId) {
            toast.error('Un produit doit être associé à une catégorie.');
            return;
        }

        const { data } = await updateProduct({
            variables: {
                productId: detailedProduct._id,
                fields: {
                    ...fieldsWithoutTags,
                    ...productForm,
                    access: formattedAccess,
                    barcodes: formattedBarcodes,
                    diets: formattedDiets,
                    preferences: formattedPreferences,
                    searchKeywords: formattedSearchKeywords,
                    tagIds,
                    state: detailedProduct.state,
                },
            },
        });
        if (data) {
            const {
                updateProduct: { success, errors, formErrors },
            } = data;

            errors?.forEach((error, index) => {
                toast.error(<span key={index}>Erreur : {error}</span>, { autoClose: false });
            });

            if (success) {
                toast.success(`Le produit "${fields.name}" a bien été modifié !`);
            } else {
                if (formErrors) {
                    formErrors.forEach(({ sectionName, sectionErrors }, index) => {
                        toast.error(
                            <span key={index}>
                                Erreur dans la section "{sectionName}" :
                                {sectionErrors.map(({ fieldName, fieldError }, index) => (
                                    <span key={index}>
                                        <br />- {fieldName} : "{fieldError}"
                                    </span>
                                ))}
                            </span>,
                            { autoClose: false },
                        );
                    });

                    if (formErrors.length > 1) {
                        toast.info('Cliquez pour fermer toutes les notifications', {
                            autoClose: false,
                            onClick: () => toast.dismiss(),
                        });
                    }
                } else {
                    throw Error("Une erreur inconnue s'est produite");
                }
            }
        } else {
            throw Error("Une erreur inconnue s'est produite");
        }
    });

    const hasModifications =
        isDirty ||
        Object.keys(productForm).some(
            (key) => productForm[key as keyof ProductForm] !== initialForm[key as keyof ProductForm],
        );

    return (
        <>
            {modalIsOpen ? (
                <ProductModal
                    name={name}
                    productId={_id}
                    displayKg={displayKg}
                    categoryId={category._id}
                    portion={portion}
                    setIsOpen={setIsOpen}
                />
            ) : null}
            <FormProvider {...methods}>
                <Form onSubmit={onSubmit}>
                    <Header>
                        <HeaderTitle>
                            <PageTitle page={PAGES.productDetails} />
                        </HeaderTitle>
                        <CTAsContainer>
                            <TotemPrimaryButton minWidth="305px" type="button" onClick={openModal}>
                                {updateLoading ? (
                                    <Loader size="18px" mode={LoaderModeType.Spin} />
                                ) : (
                                    <SaveLabel>Gérer les produits en fin de vie</SaveLabel>
                                )}
                            </TotemPrimaryButton>
                            <Link to={`/site/${DEFAULT_PANTIN_WAREHOUSE_SITE_ID}/stocks/${_id}`}>
                                <TotemPrimaryButton type="button">Voir les stocks dans l'entrepôt</TotemPrimaryButton>
                            </Link>
                            <TotemPrimaryButton
                                data-test="submit-button"
                                minWidth="140px"
                                type="submit"
                                disabled={!hasModifications}
                            >
                                {updateLoading ? (
                                    <Loader size="18px" mode={LoaderModeType.Spin} />
                                ) : (
                                    <>
                                        <FaSave size={13} />
                                        <SaveLabel>Mettre à jour</SaveLabel>
                                    </>
                                )}
                            </TotemPrimaryButton>
                            <Link to="/products">
                                <TotemPrimaryButton type="button" isSecondaryStyle>
                                    Retour
                                </TotemPrimaryButton>
                            </Link>
                        </CTAsContainer>
                    </Header>
                    <Content>
                        <ScrollableContent>
                            <GeneralInfoSection
                                product={detailedProduct}
                                categories={categories}
                                productForm={productForm}
                                setProductForm={setProductForm}
                            />
                            <SalesInfoSection
                                product={detailedProduct}
                                productPrices={productPrices}
                                productPriceRanges={productPriceRanges}
                                organizations={organizations}
                            />
                            <TagsAndBadgesSection tags={tags} />
                            <NutritionalInfoSection product={detailedProduct} />
                            <DimensionsSection product={detailedProduct} />
                            <StockAndSupplierInfoSection
                                product={detailedProduct}
                                locations={locations}
                                suppliers={suppliers}
                            />
                            <StatesSection product={detailedProduct} isDirty={hasModifications} />
                            <ProductGroupSection product={detailedProduct} />
                            <ProductArrangementsSection product={detailedProduct} />
                        </ScrollableContent>
                    </Content>
                </Form>
            </FormProvider>
        </>
    );
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
`;

const CTAsContainer = styled.div`
    display: flex;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const SaveLabel = styled.span`
    margin-left: 5px;
`;

const Content = styled.div`
    flex: 1;
    overflow: hidden;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const ScrollableContent = styled.div`
    padding: 15px;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

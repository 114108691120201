import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import styled from 'styled-components';

import { PAGES } from '../../constants/pages';
import { TOTEM_CLOSED_DAY_TYPE_LABELS } from './constants/types';

import { GET_ALL_REGULAR_TOTEM_CLOSED_DAY_QUERY } from 'data/queries/totemClosedDay';
import { GET_ALL_REGULAR_TOTEM_CLOSED_DAY } from 'data/queries/__generated__/GET_ALL_REGULAR_TOTEM_CLOSED_DAY';

import { FieldToDisplay, ListView } from 'components/ListView';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { Header, HeaderTitle } from 'components/Header';
import { Loader } from 'components/Loader';
import { PageTitle } from 'components/PageTitle';
import { CreateTotemClosedDayPopup } from './components/CreateTotemClosedDayPopup';
import { AbortTotemClosedDayPopup } from './components/AbortTotemClosedDayPopup';
import { TotemClosedDayFragment } from 'data/fragments/__generated__/TotemClosedDayFragment';
import { ARCHIVE_TOTEM_CLOSED_DAY_MUTATION } from 'data/mutations/totemClosedDay';
import {
    ARCHIVE_TOTEM_CLOSED_DAY,
    ARCHIVE_TOTEM_CLOSED_DAYVariables,
} from 'data/mutations/__generated__/ARCHIVE_TOTEM_CLOSED_DAY';
import { totemClosedDayRemovedHandler } from './cacheHandlers/totemClosedDayCacheHandler';
import { toast } from 'react-toastify';
import { formatDateAsAnniversary } from 'helpers/dateTimes';

type FormattedTotemClosedDayType = {
    _id: string;
    date: string;
    type: string;
    abortButton: JSX.Element;
    archiveButton: JSX.Element;
};

export const TotemClosedDays = () => {
    const [isCreatePopupOpen, setIsCreatePopupOpen] = useState<boolean>(false);
    const [isAbortPopupOpen, setIsAbortPopupOpen] = useState<boolean>(false);
    const [totemClosedDayToAbort, setTotemClosedDayToAbort] = useState<TotemClosedDayFragment | null>(null);

    const {
        loading: totemClosedDaysLoading,
        data: totemClosedDaysData,
        error: totemClosedDaysError,
    } = useQuery<GET_ALL_REGULAR_TOTEM_CLOSED_DAY>(GET_ALL_REGULAR_TOTEM_CLOSED_DAY_QUERY);

    const [archiveTotemClosedDay, { loading }] = useMutation<
        ARCHIVE_TOTEM_CLOSED_DAY,
        ARCHIVE_TOTEM_CLOSED_DAYVariables
    >(ARCHIVE_TOTEM_CLOSED_DAY_MUTATION);

    if (totemClosedDaysLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (!totemClosedDaysData || totemClosedDaysError) {
        throw new Error('Une erreur est survenue lors de la récupération des jours de fermeture');
    }

    const todayDateFormatted = formatDateAsAnniversary({ dateTime: new Date(), useNewFormat: true });

    async function archive(totemClosedDayId: string) {
        if (window.confirm('Voulez-vous archiver ce jour de fermeture ?')) {
            const { data } = await archiveTotemClosedDay({ variables: { totemClosedDayId } });

            if (!data) {
                throw new Error('Une erreur est survenue lors de la création du jour de fermeture');
            }

            const { archiveTotemClosedDay: archivedTotemClosedDay } = data;

            if (archivedTotemClosedDay) {
                totemClosedDayRemovedHandler(archivedTotemClosedDay._id);
                toast.success('Jour de fermeture archivé');
            } else {
                toast.error('Une erreur est survenue');
            }
        }
    }

    const { getAllRegularTotemClosedDay: totemClosedDays } = totemClosedDaysData;

    const SITES_FIELDS_TO_DISPLAY: FieldToDisplay<FormattedTotemClosedDayType>[] = [
        {
            fieldName: 'date',
            label: 'Date',
        },
        { fieldName: 'type', label: 'Type' },
        { fieldName: 'abortButton', label: 'Annuler' },
        { fieldName: 'archiveButton', label: 'Archiver' },
    ];

    const formattedTotemClosedDays: FormattedTotemClosedDayType[] = totemClosedDays.map((totemClosedDay) => {
        const { _id, date, type } = totemClosedDay;
        return {
            _id,
            date,
            type: TOTEM_CLOSED_DAY_TYPE_LABELS[type],
            abortButton: (
                <TotemPrimaryButton
                    onClick={() => {
                        setTotemClosedDayToAbort(totemClosedDay);
                        setIsAbortPopupOpen(true);
                    }}
                    disabled={loading || totemClosedDay.date < todayDateFormatted}
                >
                    Annuler
                </TotemPrimaryButton>
            ),
            archiveButton: (
                <TotemPrimaryButton
                    onClick={() => {
                        archive(totemClosedDay._id);
                    }}
                    disabled={loading || totemClosedDay.date > todayDateFormatted}
                >
                    Archiver
                </TotemPrimaryButton>
            ),
        };
    });

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.totemClosedDays} />
                </HeaderTitle>
                <TotemPrimaryButton onClick={() => setIsCreatePopupOpen(true)}>
                    Créer un jour de fermeture pour totem
                </TotemPrimaryButton>
            </Header>
            <Content>
                <ListView<FormattedTotemClosedDayType>
                    fieldsToDisplay={SITES_FIELDS_TO_DISPLAY}
                    items={formattedTotemClosedDays}
                    keyExtractor={(item) => item._id}
                />
            </Content>
            <CreateTotemClosedDayPopup isOpen={isCreatePopupOpen} setIsOpen={setIsCreatePopupOpen} />
            {totemClosedDayToAbort ? (
                <AbortTotemClosedDayPopup
                    totemClosedDay={totemClosedDayToAbort}
                    isOpen={isAbortPopupOpen}
                    setIsOpen={setIsAbortPopupOpen}
                />
            ) : null}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Content = styled.div`
    padding: 15px;
    flex: 1;
    overflow: hidden;
`;

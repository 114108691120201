import React from 'react';
import { useFormContext } from 'react-hook-form';

import { SectionColumn, SectionContainer, ColumnsSectionContainer } from 'components/DetailsView/Section';
import { DetailFormSelect, DetailFormValue } from 'components/DetailsView';
import { PAYMENT_TIME_TYPE_LABELS, PAYMENT_TYPE_LABELS } from '../constants/labels';
import { PaymentTimeType, SupplierPaymentType } from 'data/__generated__';

export const PaymentInfoSection = ({
    supplier,
}: {
    supplier: {
        paymentTimeType?: PaymentTimeType | null;
        paymentTimeDays?: number | null;
        paymentType: SupplierPaymentType;
    };
}) => {
    const { paymentTimeType, paymentTimeDays, paymentType } = supplier;
    const { register, watch } = useFormContext();
    const watchPaymentTimeType: undefined | PaymentTimeType = watch('paymentTimeType', paymentTimeType);

    const paymentTimeTypeOptions = [
        {
            label: PAYMENT_TIME_TYPE_LABELS[PaymentTimeType.NumberOfDay],
            value: PaymentTimeType.NumberOfDay,
        },
        {
            label: PAYMENT_TIME_TYPE_LABELS[PaymentTimeType.EndOfMonth],
            value: PaymentTimeType.EndOfMonth,
        },
    ];

    const paymentTypeOptions = Object.keys(SupplierPaymentType).map((paymentType) => ({
        value: paymentType,
        label: PAYMENT_TYPE_LABELS[paymentType as SupplierPaymentType],
    }));

    return (
        <SectionContainer title="Informations de paiement" isInitiallyOpen>
            <ColumnsSectionContainer numberOfColumns={3}>
                <SectionColumn>
                    <DetailFormSelect<string | null>
                        label="Type de délais de paiement"
                        placeholder="Sélectionner un type de délai de paiement"
                        name="paymentTimeType"
                        defaultValue={paymentTimeType}
                        options={paymentTimeTypeOptions}
                        required={false}
                        dataTest="supplier-paymentTimeType"
                    />
                    {watchPaymentTimeType === PaymentTimeType.NumberOfDay ? (
                        <DetailFormValue
                            label="Nombre de jours de délais pour le paiement"
                            defaultValue={paymentTimeDays}
                            {...register('paymentTimeDays', { required: true, valueAsNumber: true })}
                            width="100%"
                            type="number"
                            min="0"
                            data-test="supplier-paymentTimeDays"
                        />
                    ) : null}
                </SectionColumn>
                <SectionColumn>
                    <DetailFormSelect<string | null>
                        label="Type de paiement"
                        placeholder="Sélectionner un type de paiement"
                        name="paymentType"
                        defaultValue={paymentType}
                        options={paymentTypeOptions}
                        required={true}
                        dataTest="supplier-paymentType"
                    />
                </SectionColumn>
            </ColumnsSectionContainer>
        </SectionContainer>
    );
};

import React from 'react';
import styled from 'styled-components';

import { colors } from 'constants/colors';

import { SiteSetupFragment } from 'data/fragments/__generated__/SiteSetupFragment';
import { GET_MICROSTORE_SITE_siteWithLocationInfo } from 'data/queries/__generated__/GET_MICROSTORE_SITE';
import { MicrostoreColumnGroupSetupFragment } from 'data/fragments/__generated__/MicrostoreColumnGroupSetupFragment';
import { MicrostoreColumnGroupFragment } from 'data/fragments/__generated__/MicrostoreColumnGroupFragment';
import { MicrostoreColumnSetupFragment } from 'data/fragments/__generated__/MicrostoreColumnSetupFragment';

export const ColumnSelection = ({
    site,
    siteSetup,
    selectedColumnGroupSetup,
    setSelectedColumnGroupSetup,
    selectedColumnGroup,
    setSelectedColumnSetup,
    selectedColumnSetup,
}: {
    site: GET_MICROSTORE_SITE_siteWithLocationInfo;
    siteSetup: SiteSetupFragment;
    selectedColumnGroupSetup: MicrostoreColumnGroupSetupFragment | null;
    setSelectedColumnGroupSetup: React.Dispatch<React.SetStateAction<MicrostoreColumnGroupSetupFragment | null>>;
    selectedColumnGroup: MicrostoreColumnGroupFragment | null;
    setSelectedColumnSetup: React.Dispatch<React.SetStateAction<MicrostoreColumnSetupFragment | null>>;
    selectedColumnSetup: MicrostoreColumnSetupFragment | null;
}) => {
    return (
        <Container>
            <ColumnGroupSelectionContainer>
                {siteSetup.microstoreColumnGroupSetups.map((microstoreColumnGroupSetup) => {
                    const microstoreColumnGroup = site.microstoreColumnGroups.find(
                        (microstoreColumnGroup) =>
                            microstoreColumnGroup._id === microstoreColumnGroupSetup.microstoreColumnGroupId,
                    );
                    return (
                        <ColumnGroupTab
                            key={microstoreColumnGroupSetup._id}
                            onClick={() => {
                                setSelectedColumnGroupSetup(microstoreColumnGroupSetup);
                                setSelectedColumnSetup(null);
                            }}
                            isSelected={selectedColumnGroupSetup?._id === microstoreColumnGroupSetup._id}
                        >
                            {microstoreColumnGroup?.name}
                        </ColumnGroupTab>
                    );
                })}
            </ColumnGroupSelectionContainer>

            {selectedColumnGroupSetup ? (
                <ColumnSelectionContainer>
                    {selectedColumnGroupSetup.microstoreColumnSetups.map((microstoreColumnSetup) => {
                        const microstoreColumn = selectedColumnGroup?.columns.find(
                            (column) => column._id === microstoreColumnSetup.microstoreColumnId,
                        );
                        return (
                            <ColumnTab
                                key={microstoreColumnSetup._id}
                                onClick={() => setSelectedColumnSetup(microstoreColumnSetup)}
                                isSelected={selectedColumnSetup?._id === microstoreColumnSetup._id}
                            >
                                {microstoreColumn?.name}
                            </ColumnTab>
                        );
                    })}
                </ColumnSelectionContainer>
            ) : null}
        </Container>
    );
};

const Container = styled.div``;
const ColumnGroupSelectionContainer = styled.div`
    display: flex;
`;
const ColumnGroupTab = styled.div<{ isSelected: boolean }>`
    width: 20%;
    padding: 5px;
    border: 1px solid ${colors.pureBlack};
    border-radius: 10px;
    color: ${({ isSelected, theme }) => (isSelected ? colors.greenDarker : theme.textColor)};
`;
const ColumnSelectionContainer = styled.div`
    display: flex;
    margin-top: 5px;
    border-bottom: 1px solid ${colors.pureBlack};
`;
const ColumnTab = styled.div<{ isSelected: boolean }>`
    display: flex;
    background-color: ${colors.pureWhite};
    width: 20%;
    padding: 5px;
    border: 1px solid ${colors.pureBlack};
    border-radius: 10px 10px 0 0;
    border-bottom: ${({ isSelected }) => (isSelected ? '0' : '1px')};
    transform: ${({ isSelected }) => (isSelected ? 'scaleY(1.1)' : 'scaleY(1)')};
    color: ${({ isSelected, theme }) => (isSelected ? colors.greenDarker : theme.textColor)};
    white-space: nowrap;
    overflow: hidden;
`;

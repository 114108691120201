import React from 'react';
import styled from 'styled-components';
import { Control, Controller, FieldErrors, FieldValues } from 'react-hook-form';

import { getFormError } from 'constants/formErrors';

import { FileDragAndDrop, FileWithPreview } from 'components/FileDragAndDrop';
import { TotemImage } from 'components/TotemImage';
import type { FileError } from 'react-dropzone';
import { TotemLabel } from 'components/TotemLabel';

type ImagePickerProps = {
    label?: string;
    sublabel?: string;
    name: string;
    defaultValue: string | null | undefined;
    size?: number;
    required?: boolean;
    fileValidationFunction?: (file: FileWithPreview) => Promise<FileError[]>;
    control: Control<any>;
    errors: FieldErrors<FieldValues>;
};

export const ImagePicker = ({
    label,
    sublabel,
    name,
    defaultValue,
    size = 200,
    required = false,
    fileValidationFunction,
    control,
    errors,
}: ImagePickerProps) => {
    // @ts-expect-error to fix
    const error = required && getFormError(errors?.[name]?.type);

    return (
        <Container width={`${size}px`}>
            <LabelContainer>
                <TotemLabel>{label}</TotemLabel>
                {sublabel ? <Sublabel>{sublabel}</Sublabel> : null}
            </LabelContainer>
            <ValueContainer>
                <Controller
                    control={control}
                    name={name}
                    rules={{ required }}
                    render={({ field: { onChange, value } }) => (
                        <FileDragAndDrop
                            multiple={false}
                            hasPadding={false}
                            fileTypes={['image/jpeg', 'image/png', 'image/svg+xml', 'image/gif']}
                            onFileDropCallback={(files) => {
                                onChange(files ? files[0] : null);
                            }}
                            fileValidationFunction={fileValidationFunction}
                        >
                            <ImageContainer>
                                <TotemImage src={value?.preview || defaultValue} alt={value?.name} size={size} />
                            </ImageContainer>
                        </FileDragAndDrop>
                    )}
                />
                {error ? <Error>{error}</Error> : null}
            </ValueContainer>
        </Container>
    );
};

const Error = styled.div`
    position: absolute;
    right: -2px;
    top: -16px;
    font-size: 14px;
    color: ${({ theme }) => theme.errorColor};
    width: max-content;
`;

const Container = styled.div<{ width?: string; height?: string }>`
    display: flex;
    flex-direction: column;
    width: ${({ width }) => width || '100%'};
`;

const LabelContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const Sublabel = styled.span`
    color: ${({ theme }) => theme.infoTextColor};
    font-size: 13px;
`;

const ValueContainer = styled.div`
    position: relative;
    margin-top: 5px;
`;

const ImageContainer = styled.div`
    cursor: pointer;
    &:hover {
        opacity: 0.4;
    }
`;

import { URBANTZ_ROUND_PRESET_FRAGMENT } from 'data/fragments/urbantzRoundPreset';
import gql from 'graphql-tag';

export const CREATE_URBANTZ_ROUND_PRESET_MUTATION = gql`
    mutation CREATE_URBANTZ_ROUND_PRESET($dayIndex: Int!) {
        createUrbantzRoundPreset(dayIndex: $dayIndex) {
            ...UrbantzRoundPresetFragment
        }
    }
    ${URBANTZ_ROUND_PRESET_FRAGMENT}
`;

export const UPDATE_URBANTZ_ROUND_PRESET_MUTATION = gql`
    mutation UPDATE_URBANTZ_ROUND_PRESET($urbantzRoundPresetUpdateInput: UrbantzRoundPresetUpdateInput!) {
        updateUrbantzRoundPreset(urbantzRoundPresetUpdateInput: $urbantzRoundPresetUpdateInput) {
            ...UrbantzRoundPresetFragment
        }
    }
    ${URBANTZ_ROUND_PRESET_FRAGMENT}
`;

export const DELETE_URBANTZ_ROUND_PRESET_MUTATION = gql`
    mutation DELETE_URBANTZ_ROUND_PRESET($urbantzRoundPresetId: ID!) {
        deleteUrbantzRoundPreset(urbantzRoundPresetId: $urbantzRoundPresetId)
    }
`;

export const CREATE_ROUNDS_WITH_PRESETS_MUTATION = gql`
    mutation CREATE_ROUNDS_WITH_PRESETS($birthdayDate: String!) {
        createRoundsWithPresets(birthdayDate: $birthdayDate)
    }
`;

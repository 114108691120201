import React from 'react';
import styled from 'styled-components';

import { WarehouseReturnFragment_returnedProducts } from 'data/fragments/__generated__/WarehouseReturnFragment';
import { GET_MICROSTORE_PRODUCTS_getMicrostoreProducts } from 'data/queries/__generated__/GET_MICROSTORE_PRODUCTS';

export const ProductDetail = ({
    productFromCatalog,
    warehouseReturnProduct,
}: {
    productFromCatalog?: GET_MICROSTORE_PRODUCTS_getMicrostoreProducts;
    warehouseReturnProduct: WarehouseReturnFragment_returnedProducts;
}) => {
    return (
        <ProductContainer>
            <ProductName>
                {productFromCatalog
                    ? `${productFromCatalog?.name} - ${productFromCatalog?.brand} - ${productFromCatalog?.volume}`
                    : warehouseReturnProduct.productId}
            </ProductName>
            <ProductQuantity>Quantité: {warehouseReturnProduct.quantity}</ProductQuantity>
            <ProductQuantity>Quantité délivrée: {warehouseReturnProduct.deliveredQuantity ?? 'NA'}</ProductQuantity>
            <ProductQuantity>Quantité rangée: {warehouseReturnProduct.storedQuantity ?? 'NA'}</ProductQuantity>
        </ProductContainer>
    );
};

const ProductContainer = styled.div`
    margin-top: 10px;
`;
const ProductName = styled.div`
    font-weight: bold;
`;
const ProductQuantity = styled.div`
    margin-top: 5px;
`;

import { CONSUMPTION_VARIATION_FRAGMENT } from 'data/fragments/consumptionVariation';
import gql from 'graphql-tag';

export const CREATE_CONSUMPTION_VARIATION_MUTATION = gql`
    mutation CREATE_CONSUMPTION_VARIATION($consumptionVariationCreateInput: ConsumptionVariationCreateInput!) {
        createConsumptionVariation(consumptionVariationCreateInput: $consumptionVariationCreateInput) {
            ...ConsumptionVariationFragment
        }
    }
    ${CONSUMPTION_VARIATION_FRAGMENT}
`;

export const UPDATE_CONSUMPTION_VARIATION_MUTATION = gql`
    mutation UPDATE_CONSUMPTION_VARIATION($consumptionVariationUpdateInput: ConsumptionVariationUpdateInput!) {
        updateConsumptionVariation(consumptionVariationUpdateInput: $consumptionVariationUpdateInput) {
            ...ConsumptionVariationFragment
        }
    }
    ${CONSUMPTION_VARIATION_FRAGMENT}
`;

import React, { useState } from 'react';

import { availableDeeplinks } from 'constants/notification';

import { TotemInput } from 'components/TotemInput';
import { SelectedOption, TotemSelect } from 'components/TotemSelect';

export const DeeplinkSelector = ({ setDeeplink }: { setDeeplink: (value?: string) => void }) => {
    const [availableDeeplinkOption, setAvailableDeeplinkOption] = useState<SelectedOption<string>>(null);
    const [selectedDeeplink, setSelectedDeeplink] = useState<{
        path: string | null;
        parameters?: { label: string; value: string }[];
    }>({
        path: null,
        parameters: [],
    });

    function updateDeeplink({
        path,
        parameters,
    }: {
        path: string | null;
        parameters?: { label: string; value: string }[];
    }) {
        if (!path) {
            setDeeplink(undefined);
        }

        const prefix = 'https://graphql.totem.co/';

        const queryParams = parameters
            ? `?${parameters?.map((parameter) => `${parameter.label}=${parameter.value}`)}`
            : '';

        const deeplink = prefix + path + queryParams;

        setDeeplink(deeplink);
    }

    function handleDeeplinkSelect(option: SelectedOption<string>) {
        setAvailableDeeplinkOption(option);

        const deeplink = availableDeeplinks.find((availableDeeplink) => availableDeeplink.path === option?.value);

        if (deeplink && option) {
            const emptyParameters = deeplink.parameters?.map((parameter) => ({ label: parameter, value: '' }));

            setSelectedDeeplink({
                path: option.value,
                parameters: emptyParameters,
            });

            updateDeeplink({ path: option.value, parameters: emptyParameters });
            return;
        }

        updateDeeplink({ path: null });
    }

    const availableDeeplinkOptions = availableDeeplinks.map((availableDeeplink) => ({
        value: availableDeeplink.path,
        label: availableDeeplink.destinationScreenLabel,
    }));

    return (
        <>
            <TotemSelect
                label="Redirection (optionnel)"
                placeholder="Sélectionner un écran de redirection"
                value={availableDeeplinkOption}
                options={availableDeeplinkOptions}
                onChange={handleDeeplinkSelect}
            />
            {selectedDeeplink?.parameters &&
                selectedDeeplink.parameters.map((parameter, index) => {
                    return (
                        <TotemInput
                            key={parameter.label}
                            label={parameter.label}
                            value={parameter.value}
                            onChange={(value) => {
                                setSelectedDeeplink((prevValue) => {
                                    const newParameters = prevValue.parameters?.map((prevParameter, prevIndex) => {
                                        if (index === prevIndex) {
                                            return { ...prevParameter, value };
                                        }
                                        return prevParameter;
                                    });

                                    updateDeeplink({ path: selectedDeeplink.path, parameters: newParameters });

                                    return { ...prevValue, parameters: newParameters };
                                });
                            }}
                        />
                    );
                })}
        </>
    );
};

import React, { useState } from 'react';
import styled from 'styled-components';

import { SiteSetupFragment } from 'data/fragments/__generated__/SiteSetupFragment';
import { MicrostoreColumnGroupSetupFragment } from 'data/fragments/__generated__/MicrostoreColumnGroupSetupFragment';
import { MicrostoreColumnSetupFragment } from 'data/fragments/__generated__/MicrostoreColumnSetupFragment';
import { GET_MICROSTORE_SITE_siteWithLocationInfo } from 'data/queries/__generated__/GET_MICROSTORE_SITE';
import { SiteInventoryFragment } from 'data/fragments/__generated__/SiteInventoryFragment';
import { GET_MICROSTORE_PRODUCTS_getMicrostoreProducts } from 'data/queries/__generated__/GET_MICROSTORE_PRODUCTS';

import { SectionContainer } from 'components/DetailsView/Section';
import { ColumnSelection } from './components/ColumnSelection';
import { ColumnDetails } from './components/ColumnDetails';
import { ColumnGroupDetails } from './components/ColumnGroupDetails';
import { FreefoodDetails } from './components/FreefoodDetails';

export const ColumnDetailsSection = ({
    deliveredStoreProductQuantities,
    products,
    site,
    siteInventory,
    siteSetup,
}: {
    deliveredStoreProductQuantities: Record<string, number>;
    products: GET_MICROSTORE_PRODUCTS_getMicrostoreProducts[];
    site: GET_MICROSTORE_SITE_siteWithLocationInfo;
    siteInventory: SiteInventoryFragment;
    siteSetup: SiteSetupFragment;
}) => {
    const [selectedColumnGroupSetup, setSelectedColumnGroupSetup] = useState<MicrostoreColumnGroupSetupFragment | null>(
        null,
    );
    const selectedColumnGroup =
        site.microstoreColumnGroups.find(
            (microstoreColumnGroup) => microstoreColumnGroup._id === selectedColumnGroupSetup?.microstoreColumnGroupId,
        ) ?? null;

    const [selectedColumnSetup, setSelectedColumnSetup] = useState<MicrostoreColumnSetupFragment | null>(null);

    const selectedColumn =
        selectedColumnGroup?.columns?.find((column) => column._id === selectedColumnSetup?.microstoreColumnId) ?? null;

    const selectedColumnInventory = siteInventory.microstoreColumnInventories.find(
        ({ _id }) => _id === selectedColumnSetup?.microstoreColumnInventoryId,
    );

    return (
        <SectionContainer title="Détail du rangement" isInitiallyOpen>
            <>
                <WarningText>
                    Si la structure des colonnes a changé entre temps, les représentations sous forme de colonne seront
                    caduques
                </WarningText>
                <ColumnSelection
                    site={site}
                    siteSetup={siteSetup}
                    selectedColumnGroupSetup={selectedColumnGroupSetup}
                    setSelectedColumnGroupSetup={setSelectedColumnGroupSetup}
                    selectedColumnGroup={selectedColumnGroup}
                    setSelectedColumnSetup={setSelectedColumnSetup}
                    selectedColumnSetup={selectedColumnSetup}
                />
                {selectedColumn && selectedColumnSetup && selectedColumnInventory ? (
                    <ColumnDetails
                        inventoryDate={siteInventory.date}
                        products={products}
                        selectedColumn={selectedColumn}
                        selectedColumnInventory={selectedColumnInventory}
                        selectedColumnSetup={selectedColumnSetup}
                        site={site}
                    />
                ) : null}

                <SectionText>Photos du regroupement de colonne.</SectionText>
                {selectedColumnGroupSetup ? (
                    <ColumnGroupDetails selectedColumnGroupSetup={selectedColumnGroupSetup} />
                ) : null}
                <SectionText>Photos de la freefood.</SectionText>
                {siteSetup.freefoodSetup ? <FreefoodDetails freefoodSetup={siteSetup.freefoodSetup} /> : null}
                <SectionText>Problèmes rencontrés.</SectionText>
                {siteSetup.issues.productsIssues.map((productIssues) => {
                    const product = products.find(({ _id }) => _id === productIssues.productId);
                    return (
                        <ProducIssue key={productIssues.productId}>
                            <ProductName>
                                {product
                                    ? `${product?.name} - ${product?.brand} - ${product?.volume}`
                                    : productIssues.productId}
                            </ProductName>
                            <IssueComment>{productIssues.comment}</IssueComment>

                            {productIssues.brokenDuringDeliveryQuantity ? (
                                <Issue>
                                    Produit(s) cassé(s) durant le transport.:{' '}
                                    {productIssues.brokenDuringDeliveryQuantity}
                                </Issue>
                            ) : null}
                            {productIssues.brokenOnSiteBeforeInventoryQuantity ? (
                                <Issue>
                                    Produit(s) cassé(s) avant l'inventaire:{' '}
                                    {productIssues.brokenOnSiteBeforeInventoryQuantity}
                                </Issue>
                            ) : null}
                            {productIssues.brokenOnSiteAfterInventoryQuantity ? (
                                <Issue>
                                    Produit(s) cassé(s) après l'inventaire.:{' '}
                                    {productIssues.brokenOnSiteAfterInventoryQuantity}
                                </Issue>
                            ) : null}
                            {productIssues.expiredDeliveredQuantity ? (
                                <Issue>Produit(s) livré(s) périmé(s).: {productIssues.expiredDeliveredQuantity}</Issue>
                            ) : null}
                            {productIssues.expiredOnSiteBeforeInventoryQuantity ? (
                                <Issue>
                                    Produit(s) périmé(s) avant l'inventaire:{' '}
                                    {productIssues.expiredOnSiteBeforeInventoryQuantity}
                                </Issue>
                            ) : null}
                            {productIssues.expiredOnSiteAfterInventoryQuantity ? (
                                <Issue>
                                    Produit(s) périmé(s) après l'inventaire.:{' '}
                                    {productIssues.expiredOnSiteAfterInventoryQuantity}
                                </Issue>
                            ) : null}
                            {productIssues.notEnoughPlaceQuantity ? (
                                <Issue>Pas assez de place: {productIssues.notEnoughPlaceQuantity}</Issue>
                            ) : null}
                            {productIssues.otherBeforeInventoryQuantity ? (
                                <Issue>
                                    Problème(s) autre(s) avant l'inventaire:{' '}
                                    {productIssues.otherBeforeInventoryQuantity}
                                </Issue>
                            ) : null}
                            {productIssues.otherAfterInventoryOrDuringDeliveryQuantity ? (
                                <Issue>
                                    Problème(s) autre(s) après l'inventaire ou durant le transport.:{' '}
                                    {productIssues.otherAfterInventoryOrDuringDeliveryQuantity}
                                </Issue>
                            ) : null}
                            {productIssues.unexpectedOnSiteQuantity ? (
                                <Issue>
                                    Produit(s) non prévu(s) sur site: {productIssues.unexpectedOnSiteQuantity}
                                </Issue>
                            ) : null}
                            {productIssues.wrongQuantityDelivered !==
                            deliveredStoreProductQuantities[productIssues.productId] ? (
                                <Issue>
                                    Quantité livrée : {productIssues.wrongQuantityDelivered} au lieu de{' '}
                                    {deliveredStoreProductQuantities[productIssues.productId] ?? 0}
                                </Issue>
                            ) : null}
                        </ProducIssue>
                    );
                })}
            </>
        </SectionContainer>
    );
};

const WarningText = styled.div`
    color: ${({ theme }) => theme.warningColor};
    font-weight: bold;
    margin-bottom: 10px;
`;

const SectionText = styled.div`
    font-weight: bold;
    font-size: 18px;
    margin-top: 20px;
`;

const ProducIssue = styled.div`
    margin-top: 10px;
`;
const ProductName = styled.div`
    font-weight: bold;
`;
const IssueComment = styled.div`
    margin-top: 5px;
`;
const Issue = styled.div`
    margin-top: 5px;
`;

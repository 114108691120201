import { gql } from '@apollo/client';

export const COMMENT_FRAGMENT = gql`
    fragment CommentFragment on Comment {
        _id
        body
        createdAt
        updatedAt
        user {
            _id
            label
        }
        product {
            _id
            name
        }
    }
`;

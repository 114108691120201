import React from 'react';

import { WarehouseReturnFragment } from 'data/fragments/__generated__/WarehouseReturnFragment';

import { DetailValue } from 'components/DetailsView/DetailValue';
import { SectionColumn, SectionContainer, ColumnsSectionContainer } from 'components/DetailsView/Section';
import { DetailLink } from 'components/DetailsView';

export const GeneralInfoSection = ({ warehouseReturn }: { warehouseReturn: WarehouseReturnFragment }) => {
    return (
        <SectionContainer title="Informations générales" isInitiallyOpen>
            <ColumnsSectionContainer numberOfColumns={2}>
                <SectionColumn>
                    <DetailValue label="ID" value={warehouseReturn._id} />
                    <DetailValue label="Date" value={warehouseReturn.date} />
                </SectionColumn>
                <SectionColumn>
                    <DetailLink
                        label="Site d'origine"
                        path="site"
                        name={warehouseReturn.originSiteId}
                        value={warehouseReturn.originSiteId}
                    />
                </SectionColumn>
            </ColumnsSectionContainer>
        </SectionContainer>
    );
};

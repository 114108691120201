import { TAG_QUERY_FRAGMENT } from 'data/fragments/tag';
import gql from 'graphql-tag';

export const GET_TAGS_QUERY = gql`
    query GET_TAGS {
        tags {
            ...TagQueryFragment
        }
    }
    ${TAG_QUERY_FRAGMENT}
`;

export const GET_TAG_QUERY = gql`
    query GET_TAG($tagId: ID!) {
        tag(tagId: $tagId) {
            ...TagQueryFragment
        }
    }
    ${TAG_QUERY_FRAGMENT}
`;

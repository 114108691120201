import React from 'react';

import { IconSearch, IconCalendarEvent } from '@tabler/icons';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { TotemCheckbox } from 'components/TotemCheckbox';

import { colors } from 'constants/colors';

import { WarehouseDeliveryFragmentFragment } from 'data/__generated__';
import { formatDateAsAnniversary } from 'helpers/dateTimes';
import { getWarehouseDeliveryLabel } from './constants/utils';

export const WarehouseDeliveryTrelloItem = ({
    warehouseDelivery,
    isSelected,
    toggleDeliverySelection,
}: {
    warehouseDelivery: WarehouseDeliveryFragmentFragment;
    isSelected: boolean;
    toggleDeliverySelection: (deliveryId: string) => void;
}) => {
    const { _id: warehouseDeliveryId, transferDate } = warehouseDelivery;

    function toggleDelivery() {
        toggleDeliverySelection(warehouseDeliveryId);
    }

    return (
        <ItemContent>
            <ItemHeader backgroundColor={colors.pantinGrey}>
                <TotemCheckbox checked={isSelected} onChange={toggleDelivery} />
                <Link to={`/warehouseDelivery/${warehouseDeliveryId}`}>
                    <IconSearch color={colors.pureWhite} size="15" />
                </Link>
            </ItemHeader>
            <ItemBody>
                <Title>{getWarehouseDeliveryLabel(warehouseDelivery)}</Title>
                <Details>
                    <Detail>DETAILS</Detail>
                    <Detail>
                        <IconCalendarEvent size="20" />
                        <DeliveryDate>
                            {formatDateAsAnniversary({ dateTime: transferDate, displayDay: true })}
                        </DeliveryDate>
                    </Detail>
                </Details>
            </ItemBody>
        </ItemContent>
    );
};

const ItemContent = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: ${({ theme }) => theme.borderRadius};
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
    overflow: hidden;
`;

type ItemHeaderProps = {
    backgroundColor: string;
};

const ItemHeader = styled.div<ItemHeaderProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${({ backgroundColor }) => backgroundColor};
    color: ${colors.pureWhite};
    padding: 10px;
    font-weight: 800;
    text-align: center;
`;

const ItemBody = styled.div`
    padding: 10px;
`;

const DeliveryDate = styled.span`
    margin-left: 5px;
`;

const Title = styled.div`
    font-size: 20px;
    font-weight: 800;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Details = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 5px;
`;

const Detail = styled.span`
    display: flex;
    align-items: center;
    font-size: 16px;
`;

import React, { FunctionComponent } from 'react';
import { TotemPopup } from 'components/TotemPopup';

import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useAnswerToCommentMutation } from 'data/__generated__';
import { GET_COMMENTS_QUERY } from 'data/queries/comment';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

type CreateCommentAnswerModalProps = {
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
    commentId: string;
};

type UpsertCommentAnswerFormValues = {
    body: string;
};

export const CreateCommentAnswerModal: FunctionComponent<CreateCommentAnswerModalProps> = ({
    isOpen,
    setIsOpen,
    commentId,
}) => {
    const { register, handleSubmit } = useForm<UpsertCommentAnswerFormValues>();

    const [postComment] = useAnswerToCommentMutation({
        onCompleted: (data) => {
            if (!data?.answerToComment) {
                return;
            }
            setIsOpen(false);
        },
        awaitRefetchQueries: true,
        refetchQueries: [GET_COMMENTS_QUERY],
    });

    return (
        <TotemPopup isOpen={isOpen} title="Répondre au commentaire" setIsOpen={setIsOpen}>
            <Form
                onSubmit={handleSubmit((values) => {
                    postComment({ variables: { body: values.body, answerToCommentId: commentId } });
                    setIsOpen(false);
                })}
            >
                <Input type="text" style={{ flex: 1 }} {...register('body', { required: true })} />
                <TotemPrimaryButton type="submit">Envoyer</TotemPrimaryButton>
            </Form>
        </TotemPopup>
    );
};

const Input = styled.input`
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    outline: none;
    &:focus {
        border-color: #333;
    }
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

import gql from 'graphql-tag';

export const DETAILED_RITUAL_FRAGMENT = gql`
    fragment DetailedRitualFragment on Ritual {
        _id
        activeDate
        activeWeekdays
        associatedDiscount
        imageUrl
        messagesSetId
        name
        repetition
        restrictedOrganizationIds
        restrictedSiteIds
        restrictedUserIds
        state
        timeWindow {
            start
            end
        }
    }
`;

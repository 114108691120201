import React, { useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { createApolloClient } from 'data/connection/client.connection';
import { ApolloProvider } from '@apollo/client';

import { VERIFY_LOGGED_IN_verifyLoggedIn_user } from 'data/mutations/__generated__/VERIFY_LOGGED_IN';

import { Privatize } from './pages';

import { ThemeContextProvider } from './ThemeContextProvider';
import { InitialUserContext } from '../src/contexts';
import { lightTheme, darkTheme } from './constants/theme';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ENV_PROD } from '@totem/envs';
import { publicRouter } from 'pages/publicRouter';

export const apolloClient = createApolloClient();

function getTheme() {
    const themeFromStorage = window.localStorage.getItem('theme');

    switch (themeFromStorage) {
        case 'dark':
            return darkTheme;
        case 'light':
        default:
            return lightTheme;
    }
}

function App() {
    const [user, setUser] = useState<VERIFY_LOGGED_IN_verifyLoggedIn_user | null>(null);

    useEffect(() => {
        // We want a different icon in PROD than in other environments
        if (window.__TOTENV__.ENV !== ENV_PROD) {
            // 1. Update icon
            const favicon = document.getElementById('favicon');

            if (favicon) {
                favicon.setAttribute('href', '/favicon-preprod.svg');
            }
        }
    });

    return (
        <ThemeContextProvider defaultTheme={getTheme()}>
            <ApolloProvider client={apolloClient}>
                <ToastContainer position="bottom-right" />
                <InitialUserContext.Provider value={{ user, setUser }}>
                    {user ? <Privatize user={user} /> : <RouterProvider router={publicRouter} />}
                </InitialUserContext.Provider>
            </ApolloProvider>
        </ThemeContextProvider>
    );
}

export default App;

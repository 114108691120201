import React from 'react';

import styled from 'styled-components';
import { TotemLabel } from './TotemLabel';

export const CommonStatusTag = ({
    label,
    sublabel,
    backgroundColor,
    marginTop = null,
    labelColor,
    showHeading = false,
}: {
    label: string;
    sublabel?: string;
    backgroundColor: string;
    labelColor: string;
    marginTop?: string | null;
    showHeading?: boolean;
}) => {
    return (
        <Container>
            {showHeading ? <Heading hasSublabel={!!sublabel}>Statut</Heading> : null}
            {sublabel ? <Sublabel>{sublabel}</Sublabel> : null}
            <TagContainer color={backgroundColor} marginTop={marginTop}>
                <TagLabel color={labelColor}>{label}</TagLabel>
            </TagContainer>
        </Container>
    );
};

const Sublabel = styled.span`
    margin-bottom: 5px;
    color: ${({ theme }) => theme.infoTextColor};
    font-size: 13px;
`;

const Container = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
`;

const Heading = styled(TotemLabel)`
    margin-bottom: ${({ hasSublabel }: { hasSublabel: boolean }) => (hasSublabel ? 0 : '5px')};
`;

const TagContainer = styled.div<{ marginTop: string | null; color: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ color }: { color: string }) => color}dd;
    padding: 5px 15px;
    border-radius: 20px;
    ${({ marginTop }: { marginTop: string | null }) => marginTop && `margin-top: ${marginTop}`}
`;

const TagLabel = styled.span`
    color: ${({ color }: { color: string }) => color};
    font-size: 14px;
`;

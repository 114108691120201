export const REQUIRED = 'required';
export const MIN = 'min';
export const MAX = 'max';
export const MAX_LENGTH = 'maxLength';

export const FORM_ERRORS = {
    [REQUIRED]: 'Ce champ est requis',
    [MIN]: 'La valeur ne peut pas être moins que ',
    [MAX]: 'La valeur ne peut pas être plus que ',
    [MAX_LENGTH]: 'Ce champ ne doit pas dépasser ',
};

export function getFormError(type?: string, message?: string) {
    switch (type) {
        case REQUIRED:
            return FORM_ERRORS[REQUIRED];
        case MIN:
            return FORM_ERRORS[MIN] + message;
        case MAX:
            return FORM_ERRORS[MAX] + message;
        case MAX_LENGTH:
            return FORM_ERRORS[MAX_LENGTH] + message;
        default:
            return message;
    }
}

export type ErrorType = keyof typeof FORM_ERRORS;

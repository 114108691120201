import { BarcodeType, ProductState } from 'data/__generated__';

// TODO use default subfields values instead of entire default product and enums instead
export const defaultProduct = {
    externalProductId: null,
    access: [],
    allergen: {
        gluten: false,
        crustaceans: false,
        eggs: false,
        fish: false,
        peanuts: false,
        soybeans: false,
        milk: false,
        nuts: false,
        celery: false,
        mustard: false,
        sesame: false,
        sulphites: false,
        lupin: false,
        molluscs: false,
        canContain: '',
    },
    allowNegativeStock: false,
    barcodes: [],
    brand: '',
    canExpire: true,
    categoryId: '',
    checkSwollenLid: false,
    cerealsFriendly: false,
    comment: null,
    conditionningTotem: 1,
    deliveryDays: {
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
    },
    description: '',
    dispenserFriendly: false,
    dispenserMaxPortion: 0,
    displayKg: false,
    flow: '',
    fullname: '',
    height: 0,
    imagePath: '',
    imageUrl: '',
    indexDisplay: null,
    ingredients: '',
    isBeingTested: false,
    isFreefood: false,
    isFromNayax: false,
    isMicrostore: false,
    shouldBeHiddenInStore: false,
    isPaused: false,
    isPunctual: false,
    isScannable: true,
    isTitreRestaurantAllowed: false,
    isToBeArchived: false,
    isWithoutStock: false,
    justInTime: false,
    length: 0,
    minSupplierExpiryDays: null,
    name: '',
    nbRatings: 0,
    newUntil: null,
    nutriscore: null,
    nutrition: {
        caloriesKCal: 0,
        totalFat: 0,
        sturatedFat: 0,
        carbohydrates: 0,
        sugars: 0,
        fibers: 0,
        protein: 0,
        sodium: 0,
    },
    onboardedForSensei: false,
    packagingImageUrl: null,
    hasShortExpiryDate: false,
    portion: 1,
    price: 0,
    priceB2c: 0,
    priceMicrostore: 0,
    proportion: null,
    proportionMax: null,
    rating: 0,
    scan: true,
    shouldOrderOnce: false,
    state: ProductState.Created,
    stockThreshold: 1,
    subcategoryId: null,
    suppliers: [],
    tagIds: [],
    toleranceMax: 10,
    toleranceMin: 10,
    trackExpiryDate: false,
    tva: 0.055,
    updatedBy: '',
    volume: '',
    weight: 0,
    weightWithoutPackaging: 0,
    width: 0,
    searchKeywords: null,
};

export type SupplierPriceType = {
    value: number;
    conditionning: number;
    packagesPerBatch: number;
};

export type SupplierType = {
    name?: string | null;
    _id: string;
    code?: string | null;
    prices: SupplierPriceType[];
};

export type DeliveryDaysType = {
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
};

export type Barcode = {
    barcode: string;
    type: BarcodeType;
};

// TODO use default ProductInputType instead
export type DefaultProductType = {
    externalProductId: string | null;
    access: string[];
    allergen: {
        gluten: boolean;
        crustaceans: boolean;
        eggs: boolean;
        fish: boolean;
        peanuts: boolean;
        soybeans: boolean;
        milk: boolean;
        nuts: boolean;
        celery: boolean;
        mustard: boolean;
        sesame: boolean;
        sulphites: boolean;
        lupin: boolean;
        molluscs: boolean;
        canContain: string;
    };
    allowNegativeStock: boolean;
    barcodes: Barcode[];
    brand: string;
    canExpire: boolean;
    categoryId: string;
    cerealsFriendly: boolean;
    checkSwollenLid: boolean;
    comment: string | null;
    conditionningTotem: number;
    deliveryDays: DeliveryDaysType;
    description: string;
    dispenserFriendly: boolean;
    dispenserMaxPortion: number;
    displayKg: boolean;
    flow: string;
    fullname: string;
    height: number;
    imagePath: string;
    imageUrl: string;
    indexDisplay: number | null;
    ingredients: string;
    isBeingTested: boolean;
    isFreefood: boolean;
    isFromNayax: boolean;
    isMicrostore: boolean;
    shouldBeHiddenInStore: boolean;
    isPaused: boolean;
    isPunctual: boolean;
    isScannable: boolean;
    isTitreRestaurantAllowed: boolean;
    isToBeArchived: boolean;
    isWithoutStock: boolean;
    justInTime: boolean;
    length: number;
    minSupplierExpiryDays: number | null;
    name: string;
    nbRatings: number;
    newUntil: string | null;
    nutriscore: string | null;
    nutrition: {
        caloriesKCal: number;
        totalFat: number;
        sturatedFat: number;
        carbohydrates: number;
        sugars: number;
        fibers: number;
        protein: number;
        sodium: number;
    };
    onboardedForSensei: boolean;
    packagingImageUrl: string | null;
    hasShortExpiryDate: boolean;
    portion: number;
    price: number;
    priceB2c: number;
    priceMicrostore: number;
    proportion: number | null;
    proportionMax: number | null;
    rating: number;
    scan: boolean;
    shouldOrderOnce: boolean;
    state: ProductState;
    stockThreshold: number;
    subcategoryId: string | null;
    suppliers: SupplierType[];
    tagIds: string[];
    toleranceMax: number;
    toleranceMin: number;
    trackExpiryDate: boolean;
    tva: number;
    updatedBy: string;
    volume: string;
    weight: number;
    weightWithoutPackaging: number;
    width: number;
    searchKeywords: string[] | null;
};

import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import type { FieldArrayWithId } from 'react-hook-form';

import { colors } from 'constants/colors';

import { TotemLabel } from 'components/TotemLabel';
import { roundPrice } from 'helpers/price';
import { BasicInput } from 'components/BasicInput';

type Margin = {
    price: string; //string, so that percent displays with ','
    isLow: boolean;
};

type TTCPrice = {
    ttcPrice: string; //string, so that price displays with ','
    margin: Margin;
    rangeId: string;
    discount?: number;
    crossedPrice?: number;
};

export type RangePrice = {
    price: number;
    rangeId: string;
    crossedPrice?: number;
};

type TTCPriceItem = FieldArrayWithId<
    {
        rangePrices: RangePrice[];
    },
    'rangePrices',
    'id'
>;

export const TTCPrices = ({
    updatedTva,
    updatedRangePrices,
    item,
    index,
    supplierPrice,
}: {
    updatedTva: number;
    item: TTCPriceItem;
    index: number;
    updatedRangePrices: Array<RangePrice>;
    supplierPrice: number;
}) => {
    const [TTCPrices, setTTCPrices] = useState<Array<TTCPrice>>([]);

    const getTTCPrice = (price: number, tva: number) => {
        const tax = price * tva;
        return (price + tax).toFixed(2).replace('.', ',');
    };

    const getMargin = (price: number, supplierPrice: number) => {
        if (price) {
            const margin = ((price - supplierPrice) / price) * 100;
            const isLow = margin < 10;

            return { price: margin.toFixed(2).replace('.', ',') + ' %', isLow };
        }
        return { price: '?', isLow: false };
    };

    const getDiscountPercent = (price: number, crossedPrice: number | undefined) => {
        if (price && crossedPrice) {
            return Math.round(((crossedPrice - price) * 10000) / crossedPrice) / 100;
        }
        return 0;
    };

    // doing this because useEffect is not getting triggered on array changes
    const updatedRangePricesAsJSON = JSON.stringify(updatedRangePrices);

    useEffect(() => {
        const ttcPrices = updatedRangePrices.map(({ rangeId, price, crossedPrice }) => {
            const calculatedPrice = getTTCPrice(price, updatedTva);
            const calculatedMargin = getMargin(price, supplierPrice);
            const calculatedDiscount = getDiscountPercent(price, crossedPrice);
            const calculatedCrossedPrice = crossedPrice ? roundPrice(crossedPrice * (1 + updatedTva)) : undefined;
            return {
                rangeId,
                ttcPrice: calculatedPrice,
                margin: calculatedMargin,
                discount: calculatedDiscount,
                crossedPrice: calculatedCrossedPrice,
            };
        });
        setTTCPrices(ttcPrices);
    }, [updatedTva, updatedRangePrices, supplierPrice, updatedRangePricesAsJSON]);

    const calculatedPrice = TTCPrices.find((ttcPrice) => ttcPrice.rangeId === item.rangeId);
    return (
        <Container>
            <Line>
                <TTCPricesContainer>
                    <Fragment key={index}>
                        <PriceLabel>Marge</PriceLabel>
                        <TVADisplay
                            isLow={calculatedPrice?.margin?.isLow}
                            disabled
                            defaultValue={calculatedPrice?.margin?.price}
                        />
                    </Fragment>
                </TTCPricesContainer>
                <TTCPricesContainer>
                    <Fragment key={index}>
                        <PriceLabel>Prix TTC</PriceLabel>
                        <TVADisplay disabled defaultValue={calculatedPrice?.ttcPrice} />
                    </Fragment>
                </TTCPricesContainer>
                <TTCPricesContainer>
                    <Fragment key={index}>
                        <PriceLabel>Discount %</PriceLabel>
                        <TVADisplay disabled defaultValue={calculatedPrice?.discount} />
                    </Fragment>
                </TTCPricesContainer>
                <TTCPricesContainer>
                    <Fragment key={index}>
                        <PriceLabel>Prix barré TTC</PriceLabel>
                        <TVADisplay disabled defaultValue={calculatedPrice?.crossedPrice} />
                    </Fragment>
                </TTCPricesContainer>
            </Line>
        </Container>
    );
};

const PriceLabel = styled(TotemLabel)`
    margin-bottom: 10px;
`;

const Container = styled.div`
    display: flex;
`;

const Line = styled.div`
    display: flex;
`;

const TTCPricesContainer = styled.div``;

const TVADisplay = styled(BasicInput)`
    ${({ isLow }: { isLow?: boolean }) => isLow && `color: ${colors.red}`};
    margin: 8px 5px 5px;

    &:disabled {
        color: ${({ theme, isLow }: { theme: any; isLow?: boolean }) => (isLow ? colors.red : theme.textColor)};
    }
`;

import { PaymentTimeType, OrderingRequirementUnit, OrderMode, SupplierPaymentType } from 'data/__generated__';

export const PAYMENT_TIME_TYPE_LABELS = {
    [PaymentTimeType.NumberOfDay]: 'Nombre de jours',
    [PaymentTimeType.EndOfMonth]: 'Fin de mois',
};

export const PAYMENT_TYPE_LABELS: Record<SupplierPaymentType, string> = {
    [SupplierPaymentType.BankTransfer]: 'Virement',
    [SupplierPaymentType.CreditCard]: 'Carte bancaire',
    [SupplierPaymentType.PrelevementLcrbnp]: 'Prélèvement LCR BNP',
    [SupplierPaymentType.SepaDirectDebit]: 'SEPA Direct Debit',
};

export const ORDERING_REQUIREMENT_UNITS_LABELS = {
    [OrderingRequirementUnit.Box]: 'Coli.s',
    [OrderingRequirementUnit.Eur]: 'euros (€)',
    [OrderingRequirementUnit.Quantity]: 'Unité.s',
    [OrderingRequirementUnit.G]: 'g',
    [OrderingRequirementUnit.Ml]: 'ml',
};

export const ORDER_MODE_LABELS = {
    [OrderMode.Email]: 'Par email',
    [OrderMode.Platform]: 'Espace de commande web/mobile',
};

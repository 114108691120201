import React, { useState } from 'react';

import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { PAGES } from '../../constants/pages';
import { useGetDailyDeliveriesWithPhotosQuery } from 'data/__generated__';

import { Header, HeaderTitle } from 'components/Header';
import { Loader } from 'components/Loader';
import { PageTitle } from 'components/PageTitle';
import { TotemDatePicker } from 'components/TotemDatePicker';
import { formatDateAsAnniversary } from 'helpers/dateTimes';
import { PhotosSectionContent } from './DeliveryDetails/PhotosSectionContent';
import { SectionContainer } from 'components/DetailsView/Section';

export const DeliveriesDailyPhotos = () => {
    const [deliverySearchDate, setDeliverySearchDate] = useState<Date>(new Date());

    const {
        data: deliveriesData,
        loading: deliveriesLoading,
        error: deliveriesError,
    } = useGetDailyDeliveriesWithPhotosQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            searchDate: formatDateAsAnniversary({ dateTime: deliverySearchDate }),
        },
    });

    if (deliveriesLoading && !deliveriesData) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (deliveriesError || !deliveriesData) {
        throw new Error('Une erreur est survenue lors de la récupération des livraisons');
    }

    const { deliveries } = deliveriesData;
    const sortedDeliveries = [...deliveries].sort((deliveryA, deliveryB) =>
        (deliveryA.organizationName || deliveryA.siteName || deliveryA.label).localeCompare(
            deliveryB.organizationName || deliveryB.siteName || deliveryB.label,
        ),
    );

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.deliveriesDailyPhoto} />
                </HeaderTitle>

                <DateContainer>
                    <TotemDatePicker
                        label="Date de livraison"
                        selected={deliverySearchDate}
                        onChange={(date) => {
                            setDeliverySearchDate(date as Date);
                        }}
                    />
                </DateContainer>
            </Header>
            <Content>
                {sortedDeliveries.map((delivery) => (
                    <SectionContainer
                        key={delivery._id}
                        title={`${delivery.organizationName || delivery.siteName || delivery.label} (${
                            delivery.label
                        })`}
                        keepOpen
                    >
                        <>
                            <Link to={`/delivery/${delivery._id}`}>Page détails</Link>
                            <PhotosSectionContent delivery={delivery} />
                        </>
                    </SectionContainer>
                ))}
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: ${({ theme }) => theme.backgroundColor};
    overflow: auto;
`;

const Content = styled.div`
    padding: 20px;
    position: relative;

    & > :not(:first-child) {
        margin-top: 15px;
    }
`;

const DateContainer = styled.div`
    min-width: 300px;
    justify-self: end;
`;

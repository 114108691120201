import React from 'react';
import { Link } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import styled from 'styled-components';

import { GET_SITE_RITUALS_QUERY } from 'data/queries/ritual';
import { GET_SITE_RITUALS, GET_SITE_RITUALSVariables } from 'data/queries/__generated__/GET_SITE_RITUALS';

import { FRENCH_DAYS } from 'constants/dateAndTime';
import { colors } from 'constants/colors';

import { getRitualCalendarExtremeHours } from 'helpers/rituals';
import { getDifferenceInMinutes } from 'helpers/dateTimes';

export const SiteRituals = ({ siteId }: { siteId: string }) => {
    const { data, loading, error } = useQuery<GET_SITE_RITUALS, GET_SITE_RITUALSVariables>(GET_SITE_RITUALS_QUERY, {
        variables: { siteId },
    });

    if (loading) {
        return null;
    }

    if (error || !data) {
        throw new Error('Impossible de récupérer les rituels associés à ce site');
    }

    const { siteRituals: rituals } = data;

    if (!rituals.length) {
        return <Message>Aucun rituel n'existe pour ce site</Message>;
    }

    const { minHour, maxHour } = getRitualCalendarExtremeHours(rituals);

    const totalAmplitude = getDifferenceInMinutes(minHour, maxHour);

    return (
        <Container>
            <CalendarTable>
                <thead>
                    <tr>
                        {FRENCH_DAYS.map((day) => (
                            <th key={day}>{day}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {FRENCH_DAYS.map((day, index) => {
                            const dayRituals = rituals.filter((ritual) => ritual.activeWeekdays?.[index]);

                            return (
                                <td key={day}>
                                    <TableColumn>
                                        {dayRituals.map((ritual) => {
                                            const {
                                                _id,
                                                associatedDiscount,
                                                imageUrl,
                                                name,
                                                timeWindow: { start, end },
                                            } = ritual;
                                            const topOffsetRatio =
                                                getDifferenceInMinutes(minHour, start) / totalAmplitude;
                                            const tileHeightRatio = getDifferenceInMinutes(start, end) / totalAmplitude;

                                            return (
                                                <Link key={_id} to={`/ritual/${_id}`}>
                                                    <RitualTileOuterContainer
                                                        topOffsetRatio={topOffsetRatio}
                                                        tileHeightRatio={tileHeightRatio}
                                                    >
                                                        <RitualTileInnerContainer>
                                                            <ImageContainer>
                                                                <img
                                                                    style={{ objectFit: 'cover' }}
                                                                    src={imageUrl}
                                                                    alt={name}
                                                                    width="100%"
                                                                    height="100%"
                                                                />
                                                                <Gradient />
                                                            </ImageContainer>
                                                            <TileFooter>
                                                                <NameContainer>
                                                                    <Name>{name}</Name>
                                                                </NameContainer>
                                                                <AdditionalInfoContainer>
                                                                    <AdditionalInfo>{`${start} > ${end}`}</AdditionalInfo>
                                                                    <AdditionalInfo>
                                                                        Réduction :{' '}
                                                                        {associatedDiscount
                                                                            ? associatedDiscount + '€'
                                                                            : '-'}
                                                                    </AdditionalInfo>
                                                                </AdditionalInfoContainer>
                                                            </TileFooter>
                                                        </RitualTileInnerContainer>
                                                    </RitualTileOuterContainer>
                                                </Link>
                                            );
                                        })}
                                    </TableColumn>
                                </td>
                            );
                        })}
                    </tr>
                </tbody>
            </CalendarTable>
        </Container>
    );
};

const Container = styled.div``;

const Message = styled.span`
    color: ${({ theme }) => theme.textColor};
`;

const CalendarTable = styled.table`
    width: 100%;
    table-layout: fixed;
    color: ${({ theme }) => theme.textColor};
`;

const TableColumn = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    height: 600px;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const RitualTileOuterContainer = styled.div<{ topOffsetRatio: number; tileHeightRatio: number }>`
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    top: ${({ topOffsetRatio }) => topOffsetRatio * 100}%;
    height: ${({ tileHeightRatio }) => tileHeightRatio * 100}%;
    padding: 2px;
`;

const RitualTileInnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-self: stretch;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 5px;
`;

const ImageContainer = styled.div`
    position: relative;
    width: 100%;
    flex: 1;
    overflow: hidden;
`;

const Gradient = styled.div`
    position: absolute;
    width: 100%;
    height: 33%;
    bottom: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 62.5%, #ffffff 100%);
`;

const TileFooter = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 5px 5px;
    background-color: ${colors.pureWhite};
`;

const NameContainer = styled.div`
    margin-top: -10px;
    flex: 1;
    transform: rotate(-3deg);
`;

const Name = styled.span`
    font-family: Totem;
    font-size: 16px;
    line-height: 15px;
    color: ${colors.pink};
`;

const AdditionalInfoContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 3px;
`;

const AdditionalInfo = styled.span`
    font-size: 12px;
    color: ${colors.black};
`;

import React, { useEffect, useState } from 'react';

import { useNavigate, useParams, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { PAGES } from 'constants/pages';

import { useGetQuizQuery, useUpdateQuizMutation } from 'data/__generated__';

import { Loader } from 'components/Loader';
import { Header, HeaderTitle } from 'components/Header';
import { PageTitle } from 'components/PageTitle';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { ColumnsSectionContainer, SectionColumn } from 'components/DetailsView/Section';
import { CopyValue } from 'components/CopyValue';
import { QuizForm, QuizFormType } from './components/QuizForm';

import { dateFromString, formatDateAsAnniversary } from 'helpers/dateTimes';

type ParamTypes = {
    quizId: string;
};

export const QuizDetails = () => {
    const { quizId = '' } = useParams<ParamTypes>();
    const initialForm: QuizFormType = {
        audience: { organizationIds: [], siteIds: [], userIds: [] },
        date: null,
        description: null,
        image: null,
        imageUrl: null,
        questions: [],
        title: '',
    };
    const [quizForm, setQuizForm] = useState<QuizFormType>(initialForm);

    const navigate = useNavigate();

    const { loading: quizLoading, error: quizError, data: quizData } = useGetQuizQuery({ variables: { quizId } });

    const [updateQuizMutation, { loading: updateLoading }] = useUpdateQuizMutation();

    useEffect(() => {
        if (quizData) {
            const { quiz } = quizData;

            if (quiz) {
                setQuizForm({
                    audience: {
                        organizationIds: quiz.audience.organizationIds,
                        siteIds: quiz.audience.siteIds,
                        userIds: quiz.audience.userIds,
                    },
                    date: dateFromString(quiz.date, true),
                    image: null,
                    description: quiz.description,
                    imageUrl: quiz.imageUrl,
                    questions: quiz.questions.map(({ __typename, ...otherFields }) => ({
                        ...otherFields,
                        image: null,
                    })),
                    title: quiz.title,
                });
            }
        }
    }, [quizData]);

    if (quizLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (quizError || !quizData || !quizForm) {
        throw new Error('Une erreur est survenue lors de la récupération de ce quiz');
    }

    const { quiz } = quizData;

    if (!quiz) {
        throw new Error(`Impossible de récupérer un quiz avec cet identifiant (${quizId})`);
    }

    async function updateQuiz() {
        if (!quizForm.questions.length) {
            toast.error('Questions non renseignée.');
            return;
        }
        if (
            quizForm.questions.some(
                ({ answerOptions, correctAnswerIndex, label, image, imageUrl }) =>
                    !label ||
                    !answerOptions.length ||
                    answerOptions.length <= correctAnswerIndex ||
                    (!image && !imageUrl),
            )
        ) {
            toast.error('Une ou plusieurs questions mal renseignée.');
            return;
        }
        if (!quizForm.date) {
            toast.error('Date non renseignée.');
            return;
        }
        if (!quizForm.description) {
            toast.error('Description non renseignée.');
            return;
        }
        if (!quizForm.title) {
            toast.error('Titre non renseigné.');
            return;
        }
        if (!quizForm.image && !quizForm.imageUrl) {
            toast.error('Image non renseigné.');
            return;
        }

        const { data } = await updateQuizMutation({
            variables: {
                quizId,
                input: {
                    audience: quizForm.audience,
                    date: formatDateAsAnniversary({ dateTime: quizForm.date, useNewFormat: true }),
                    description: quizForm.description,
                    image: quizForm.image,
                    imageUrl: quizForm.imageUrl,
                    questions: quizForm.questions,
                    title: quizForm.title,
                },
            },
        });

        if (data) {
            const {
                updateQuiz: { quiz, errors },
            } = data;
            if (quiz) {
                toast.success('Le quiz a bien été mis à jour !');
                navigate(`/quiz/${quiz._id}`);
            } else {
                if (errors) {
                    errors.forEach((error) => {
                        toast.error(error, { autoClose: false });
                    });

                    if (errors.length > 1) {
                        toast.info('Cliquez pour fermer toutes les notifications', {
                            autoClose: false,
                            onClick: () => toast.dismiss(),
                        });
                    }
                } else {
                    throw Error("Une erreur inconnue s'est produite");
                }
            }
        } else {
            throw Error("Une erreur inconnue s'est produite");
        }
    }

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.quizDetails} />
                </HeaderTitle>
                <CTAsContainer>
                    <TotemPrimaryButton disabled={updateLoading} onClick={updateQuiz}>
                        Mettre à jour
                    </TotemPrimaryButton>
                    <Link to="/quizzes">
                        <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                    </Link>
                </CTAsContainer>
            </Header>
            <Content>
                <ScrollableContent>
                    <ColumnsSectionContainer numberOfColumns={1}>
                        <SectionColumn>
                            <CopyValue value={quizId} />
                            <QuizForm quizForm={quizForm} setQuizForm={setQuizForm} />
                        </SectionColumn>
                    </ColumnsSectionContainer>
                </ScrollableContent>
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const CTAsContainer = styled.div`
    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const Content = styled.div`
    width: 100%;
    flex: 1;
    overflow: hidden;
    color: ${({ theme }) => theme.textColor};
`;

const ScrollableContent = styled.div`
    padding: 15px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
`;

import React from 'react';

import styled, { css } from 'styled-components';

export const ColumnsSectionContainer = ({
    numberOfColumns,
    children,
}: {
    numberOfColumns: number;
    children: React.ReactNode;
}) => {
    return <Container numberOfColumns={numberOfColumns}>{children}</Container>;
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    & > * {
        ${({ numberOfColumns }: { numberOfColumns: number }) => css`
            width: calc(
                (100% - ${20 * (numberOfColumns - 1)}px) / ${numberOfColumns}
            ); // There are (numberOfColumns - 1) margin-lefts to spread over numberOfColumns columns
        `};
    }

    & > :not(:nth-child(${({ numberOfColumns }: { numberOfColumns: number }) => numberOfColumns}n + 1)) {
        margin-left: 20px;
    }

    & > :nth-child(n + ${({ numberOfColumns }: { numberOfColumns: number }) => numberOfColumns + 1}) {
        margin-top: 15px;
    }
`;

import gql from 'graphql-tag';
import { CATEGORY_FRAGMENT } from 'data/fragments/category';

export const GET_ACTIVE_CATEGORIES_QUERY = gql`
    query GET_ACTIVE_CATEGORIES {
        activeCategories {
            _id
            title
            shouldShowSubcategories
        }
    }
`;

export const GET_CATEGORIES_QUERY = gql`
    query GET_CATEGORIES {
        categories {
            _id
            title
            imageUrl
            state
            indexDisplay
            shouldShowSubcategories
        }
    }
`;

export const GET_DETAILED_CATEGORY_QUERY = gql`
    query GET_DETAILED_CATEGORY($categoryId: String!) {
        detailedCategory(categoryId: $categoryId) {
            ...CategoryFragment
        }
    }
    ${CATEGORY_FRAGMENT}
`;

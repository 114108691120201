import React from 'react';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';

import { apolloClient } from 'App';
import { formatDateAsAnniversary } from 'helpers/dateTimes';
import { LocationsToUpdate } from './types';

import { UPDATE_SITE_MENUS_MUTATION } from 'data/mutations/menus';
import { GET_SITE_MENUS_QUERY } from 'data/queries/menus';
import { UPDATE_SITE_MENUS, UPDATE_SITE_MENUSVariables } from 'data/mutations/__generated__/UPDATE_SITE_MENUS';
import { GET_SITE_MENUS } from 'data/queries/__generated__/GET_SITE_MENUS';

import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

export function UpdateSiteMenusButton({
    columnId,
    date,
    locationsToUpdate,
    resetLocationsToUpdate,
    siteId,
}: {
    columnId: string;
    date: Date;
    locationsToUpdate: LocationsToUpdate;
    resetLocationsToUpdate: () => void;
    siteId: string;
}) {
    const [updateSiteMenus, { loading }] = useMutation<UPDATE_SITE_MENUS, UPDATE_SITE_MENUSVariables>(
        UPDATE_SITE_MENUS_MUTATION,
    );

    async function handleClick() {
        const formattedLocationsToUpdate = Object.entries(locationsToUpdate)
            .map(([locationId, dailyInfo]) =>
                Object.entries(dailyInfo)
                    .map(([dayIndex, productInfo]) => {
                        const { currentProductId, newProduct } = productInfo;
                        return {
                            locationId,
                            currentProductId,
                            newProductId: newProduct?._id || null,
                            dayIndex: parseInt(dayIndex, 10),
                        };
                    })
                    .flat(),
            )
            .flat();
        try {
            await updateSiteMenus({
                variables: {
                    locationsToUpdate: formattedLocationsToUpdate,
                    columnId,
                    date: formatDateAsAnniversary({ dateTime: date, useNewFormat: true }),
                },
                onCompleted: ({ updateSiteMenus: updatedSiteMenus }) => {
                    apolloClient.writeQuery<GET_SITE_MENUS>({
                        query: GET_SITE_MENUS_QUERY,
                        variables: {
                            siteId,
                            date: formatDateAsAnniversary({ dateTime: date, useNewFormat: true }),
                        },
                        data: { siteMenus: updatedSiteMenus },
                    });
                    toast.success('Les menus ont bien été mis à jour');
                    resetLocationsToUpdate();
                },
                onError: (error) => {
                    toast.error(`Impossible de mettre à jour les menus: ${error}`);
                },
            });

            // update client with updated SiteMenusFromPrediction
        } catch (error) {
            toast.error(`Impossible de mettre à jour les menus: ${error}`);
            throw Error('Impossible de mettre à jour les menus');
        }
    }

    const disabled = Object.keys(locationsToUpdate).length === 0;

    return (
        <TotemPrimaryButton disabled={disabled || loading} onClick={handleClick}>
            Enregistrer
        </TotemPrimaryButton>
    );
}

import React from 'react';

import { CommonStatusTag } from 'components/CommonStatusTag';

import { colors } from 'constants/colors';

import { SUBVENTIONS_STATES_LABELS } from '../constants/states';

const getColorsFromState = (active: boolean) => {
    const { lightGrey, black, green } = colors;

    switch (active) {
        case true:
            return { backgroundColor: green, labelColor: black };
        case false:
            return { backgroundColor: lightGrey, labelColor: black };
    }
};

export const StatusTag = ({ active }: { active: boolean }) => {
    const { backgroundColor, labelColor } = getColorsFromState(active);
    return (
        <CommonStatusTag
            backgroundColor={backgroundColor}
            labelColor={labelColor}
            label={SUBVENTIONS_STATES_LABELS[active ? 1 : 0]}
        />
    );
};

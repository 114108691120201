import React from 'react';

import { useQuery } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { PAGES } from 'constants/pages';

import { GET_SITE_SETUP, GET_SITE_SETUPVariables } from 'data/queries/__generated__/GET_SITE_SETUP';
import { GET_SITE_SETUP_QUERY } from 'data/queries/siteSetup';

import { Loader } from 'components/Loader';
import { SiteSetupDetailsContent } from './SiteSetupDetailsContent';
import { Header, HeaderTitle } from 'components/Header';
import { PageTitle } from 'components/PageTitle';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

type ParamTypes = {
    siteSetupId: string;
};

export const SiteSetupDetails = () => {
    const { siteSetupId = '' } = useParams<ParamTypes>();

    const {
        loading: siteSetupLoading,
        data: siteSetupData,
        error: siteSetupError,
    } = useQuery<GET_SITE_SETUP, GET_SITE_SETUPVariables>(GET_SITE_SETUP_QUERY, {
        variables: { siteSetupId },
    });

    if (siteSetupLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (siteSetupError || !siteSetupData) {
        throw new Error(`L'erreur suivante est survenue : ${siteSetupError}`);
    }

    const { getSiteSetup: siteSetup } = siteSetupData;

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.siteSetupDetails} />
                </HeaderTitle>
                <CTAsContainer>
                    <Link to="/site-setups">
                        <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                    </Link>
                </CTAsContainer>
            </Header>
            <SiteSetupDetailsContent siteSetup={siteSetup} />;
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const CTAsContainer = styled.div`
    display: flex;
    align-items: center;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

import React from 'react';
import styled from 'styled-components';

import { TypeTag } from 'pages/Stocks/components/StockVariationTypeTag';
import { Loader, LoaderModeType } from 'components/Loader';
import { DetailLink } from 'components/DetailsView';

import { GET_SITE_PRODUCT_WITH_STOCKS_getProductWithVariations_variations } from 'data/queries/__generated__/GET_SITE_PRODUCT_WITH_STOCKS';
import { getExtendedFormattedDatetime } from 'helpers/dateTimes';
import { toast } from 'react-toastify';

export const StockVariationsList = ({
    stockVariations,
}: {
    stockVariations: GET_SITE_PRODUCT_WITH_STOCKS_getProductWithVariations_variations[];
}) => {
    if (!stockVariations) {
        return <Loader mode={LoaderModeType.Spin} />;
    }

    const getPath = (ref: string) => {
        if (ref.match('-manually-prepared')) {
            ref = ref.replace('-manually-prepared', '');
        }

        const splittedAndReversedRef = ref.split('-').reverse();

        // Camelcase
        const pathRoot = splittedAndReversedRef
            .slice(5, ref.length)
            .reverse()
            .map((str, index) => {
                return index > 0 ? str.charAt(0).toUpperCase() + str.slice(1) : str;
            })
            .join('');

        const _id = splittedAndReversedRef.slice(0, 5).reverse().join('-');

        if (ref.match('aborted')) {
            return null;
        }

        if (pathRoot === 'bundle') {
            return null;
        }

        return {
            pathRoot,
            _id,
        };
    };

    return (
        <Container>
            {stockVariations.length ? (
                <>
                    <Table>
                        <thead>
                            <TableHeaderCell>Id</TableHeaderCell>
                            <TableHeaderCell>Date de création</TableHeaderCell>
                            <TableHeaderCell>Delta</TableHeaderCell>
                            <TableHeaderCell>Référence</TableHeaderCell>
                            <TableHeaderCell>Type</TableHeaderCell>
                            <TableHeaderCell>Explication</TableHeaderCell>
                        </thead>
                        <TableBody>
                            {stockVariations.map((stockVariation) => {
                                const { _id, delta, type, ref, createdAt, explanation } = stockVariation;
                                const refInfos = ref ? getPath(ref) : null;
                                return (
                                    <TableRow key={_id}>
                                        <TableBodyCell
                                            style={{
                                                cursor: 'copy',
                                            }}
                                            onClick={() => {
                                                toast(`${_id} copié`, {
                                                    position: 'top-center',
                                                    hideProgressBar: true,
                                                });
                                                navigator.clipboard.writeText(_id);
                                            }}
                                            isDataCentered
                                        >
                                            {`...${_id.split('-').reverse()[0]}`}
                                        </TableBodyCell>
                                        <TableBodyCell isDataCentered>
                                            {getExtendedFormattedDatetime(createdAt)}
                                        </TableBodyCell>
                                        <DeltaCell isDataCentered isPositive={delta > 0}>
                                            {delta}
                                        </DeltaCell>
                                        <TableBodyCell isDataCentered>
                                            {refInfos ? (
                                                <DetailLink
                                                    path={refInfos.pathRoot}
                                                    name={ref || 'Inconnu'}
                                                    value={refInfos._id}
                                                />
                                            ) : (
                                                ref
                                            )}
                                        </TableBodyCell>
                                        <TableBodyCell isDataCentered>
                                            <TypeTag type={type} />
                                        </TableBodyCell>
                                        <TableBodyCell isDataCentered>{explanation}</TableBodyCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </>
            ) : (
                'Pas de variations de stock trouvées'
            )}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Table = styled.table`
    margin-top: 10px;
    border-radius: ${({ theme }) => theme.borderRadius};
    border-spacing: 0px;
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
`;

const TableBody = styled.tbody`
    & > :not(:first-child) td {
        border-top: 1px solid ${({ theme }) => theme.lightBorderColor};
    }
`;

const TableRow = styled.tr`
    border-bottom: 1px solid ${({ theme }) => theme.lightBorderColor};
`;

const TableHeaderCell = styled.th`
    padding: 10px;
`;

const TableBodyCell = styled.td`
    padding: 10px;
    text-align: ${({ isDataCentered }: { isDataCentered?: boolean }) => (isDataCentered ? 'center' : 'left')};
`;

const DeltaCell = styled(TableBodyCell)`
    color: ${({ theme, isPositive }: { theme: any; isPositive: boolean }) =>
        isPositive ? theme.successColor : theme.errorColor};
`;

import React from 'react';

import styled from 'styled-components';
import { IoWarning } from 'react-icons/io5';
import { toast } from 'react-toastify';

import { StatusTag } from 'pages/Stocks/components/FutureStockVariationStateTag';
import { TypeTag } from 'pages/Stocks/components/FutureStockVariationTypeTag';
import { DetailLink } from 'components/DetailsView';
import { GET_FUTURE_STOCK_VARIATIONS_BY_PRODUCT_futureStockVariationsByProduct } from 'data/queries/__generated__/GET_FUTURE_STOCK_VARIATIONS_BY_PRODUCT';
import { FutureStockVariationState, FutureStockVariationType } from 'data/__generated__';
import { GET_EXPIRED_FUTURE_STOCK_VARIATIONS_expiredFutureStockVariations } from 'data/queries/__generated__/GET_EXPIRED_FUTURE_STOCK_VARIATIONS';

export const FutureStockVariationsTable = ({
    futureStockVariations,
    displayProductName,
}: {
    futureStockVariations:
        | GET_FUTURE_STOCK_VARIATIONS_BY_PRODUCT_futureStockVariationsByProduct[]
        | GET_EXPIRED_FUTURE_STOCK_VARIATIONS_expiredFutureStockVariations[];
    displayProductName?: boolean;
}) => {
    const today = new Date();
    const birthdayDateToday = today.toISOString().split('T')[0];

    const getPath = (type: FutureStockVariationType) => {
        switch (type) {
            case FutureStockVariationType.PunctualOrder:
                return 'order';
            case FutureStockVariationType.RecurringOrder:
                return 'order';
            case FutureStockVariationType.StockTransfer:
                return 'stockTransfer';
            case FutureStockVariationType.SupplyOrder:
                return 'supplyOrder';
            case FutureStockVariationType.StockTransferPrediction:
                return '';
            case FutureStockVariationType.OrderPrediction:
                return '';
        }
    };

    return (
        <Table>
            <thead>
                <TableHeaderCell>Id</TableHeaderCell>
                <TableHeaderCell>Date d'usage</TableHeaderCell>
                <TableHeaderCell>Delta</TableHeaderCell>
                {displayProductName ? <TableHeaderCell>Product</TableHeaderCell> : null}
                <TableHeaderCell>Référence</TableHeaderCell>
                <TableHeaderCell>Référence de stock</TableHeaderCell>
                <TableHeaderCell>Rémarques</TableHeaderCell>
                <TableHeaderCell>Type</TableHeaderCell>
                <TableHeaderCell>Statut</TableHeaderCell>
            </thead>
            <TableBody>
                {futureStockVariations.map((futureStockVariation) => {
                    const { _id, useDate, delta, type, ref, state, processInfo, issues, productId } =
                        futureStockVariation;

                    const isBeforeToday = useDate < birthdayDateToday;

                    const formattedUseDate = useDate.split('-').reverse().join('/');
                    const refId = ref.includes('order-') ? ref.split('order-')[1] : ref.split('transfer-')[1];

                    const refInfo = processInfo?.supplyOrderStockVariationRef
                        ? processInfo?.supplyOrderStockVariationRef
                        : processInfo?.boxInfo
                          ? processInfo?.boxInfo.map((info) => (
                                <div key={info.stockVariationRef}>
                                    <div>box id: {info.boxId}</div>
                                    {info.stockVariationRef ? (
                                        <div>stock variation: {info.stockVariationRef}</div>
                                    ) : null}
                                </div>
                            ))
                          : '';

                    const issuesInfo = issues.map(({ explanation }) => explanation).join(', ');
                    return (
                        <TableRow key={_id}>
                            <TableBodyCell
                                style={{
                                    cursor: 'copy',
                                }}
                                onClick={() => {
                                    toast(`${_id} copié dans le press papier !`, {
                                        position: 'top-center',
                                        hideProgressBar: true,
                                    });
                                    navigator.clipboard.writeText(_id);
                                }}
                                isDataCentered
                            >
                                {`...${_id.split('-').reverse()[0]}`}
                            </TableBodyCell>
                            <TableBodyCell isDataCentered>
                                {isBeforeToday && state === FutureStockVariationState.Ongoing ? (
                                    <WarningIcon data-test="warning-icon" size={20} />
                                ) : null}
                                {formattedUseDate}
                            </TableBodyCell>
                            <DeltaCell isDataCentered isPositive={delta > 0}>
                                {delta}
                            </DeltaCell>
                            {displayProductName && 'productName' in futureStockVariation ? (
                                <TableBodyCell isDataCentered>
                                    <DetailLink
                                        path="product"
                                        name={futureStockVariation.productName}
                                        value={productId}
                                        shouldWrap
                                    />
                                </TableBodyCell>
                            ) : null}
                            <TableBodyCell isDataCentered>
                                <DetailLink path={getPath(type)} name={ref} value={refId} shouldWrap />
                            </TableBodyCell>
                            <TableBodyCell isDataCentered>{refInfo}</TableBodyCell>
                            <TableBodyCell isDataCentered>{issuesInfo}</TableBodyCell>
                            <TableBodyCell isDataCentered>
                                <TypeTag type={type} />
                            </TableBodyCell>
                            <TableBodyCell isDataCentered>
                                <StatusTag state={state} />
                            </TableBodyCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
};

const Table = styled.table`
    margin-top: 10px;
    border-radius: ${({ theme }) => theme.borderRadius};
    border-spacing: 0px;
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
`;

const TableBody = styled.tbody`
    & > :not(:first-child) td {
        border-top: 1px solid ${({ theme }) => theme.lightBorderColor};
    }
`;

const TableRow = styled.tr`
    border-bottom: 1px solid ${({ theme }) => theme.lightBorderColor};
`;

const TableHeaderCell = styled.th`
    padding: 10px;
`;

const TableBodyCell = styled.td`
    padding: 10px;
    text-align: ${({ isDataCentered }: { isDataCentered?: boolean }) => (isDataCentered ? 'center' : 'left')};
`;

const DeltaCell = styled(TableBodyCell)`
    color: ${({ theme, isPositive }: { theme: any; isPositive: boolean }) =>
        isPositive ? theme.successColor : theme.errorColor};
`;

const WarningIcon = styled(IoWarning)`
    color: ${({ theme }) => theme.warningColor};
    position: relative;
    top: 4px;
`;

// This function takes a string, compute a hash from it then use this hash to create an hexadecimal color.
// This will return a 'random' color for a given string.
export function computeColorFromName(name: string): string {
    let hash = 0;
    if (name.length === 0) {
        return '';
    }

    for (let i = 0; i < name.length; i++) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash;
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 255;
        color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
}

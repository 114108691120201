export const ALLERGENS = [
    { label: 'Gluten', name: 'gluten' },
    { label: 'Crustacés', name: 'crustaceans' },
    { label: 'Œufs', name: 'eggs' },
    { label: 'Poissons', name: 'fish' },
    { label: 'Arachides', name: 'peanuts' },
    { label: 'Soja', name: 'soybeans' },
    { label: 'Lait', name: 'milk' },
    { label: 'Fruits à coque', name: 'nuts' },
    { label: 'Céleri', name: 'celery' },
    { label: 'Moutarde', name: 'mustard' },
    { label: 'Sésame', name: 'sesame' },
    { label: 'Sulfites', name: 'sulphites' },
    { label: 'Lupin', name: 'lupin' },
    { label: 'Mollusques', name: 'molluscs' },
];

export const NUTRISCORE = [
    { label: 'A', value: 'A' },
    { label: 'B', value: 'B' },
    { label: 'C', value: 'C' },
    { label: 'D', value: 'D' },
    { label: 'E', value: 'E' },
];

export const NUTRITION = [
    { label: 'Calories (en kCal)', name: 'caloriesKCal' },
    { label: 'Lipides (en g)', name: 'totalFat' },
    { label: '- dont saturés (en g)', name: 'sturatedFat' },
    { label: 'Glucides (en g)', name: 'carbohydrates' },
    { label: '- dont sucres (en g)', name: 'sugars' },
    { label: 'Fibres (en g)', name: 'fibers' },
    { label: 'Protéines (en g)', name: 'protein' },
    { label: 'Sodium (en g)', name: 'sodium' },
];

import gql from 'graphql-tag';
import { FORM_ERROR_FRAGMENT, SUPPLIER_FRAGMENT } from '../fragments/supplier';

export const CREATE_SUPPLIER_MUTATION = gql`
     mutation CREATE_SUPPLIER($fields: SupplierFormInput!) {
        createSupplier(fields: $fields) {
            formErrors {
                ...FormErrorFragment
            }
            createdSupplier {
                ...SupplierFragment
            }
            errors
        }
    }
    ${SUPPLIER_FRAGMENT},
    ${FORM_ERROR_FRAGMENT}
`;

export const ARCHIVE_SUPPLIER_MUTATION = gql`
    mutation ARCHIVE_SUPPLIER($supplierId: ID!) {
        archiveSupplier(supplierId: $supplierId) {
            updatedSupplier {
                _id
                state
                stateHistory {
                    createdAt
                    createdBy
                    state
                }
            }
            error
        }
    }
`;

export const RESTORE_SUPPLIER_MUTATION = gql`
    mutation RESTORE_SUPPLIER($supplierId: ID!) {
        restoreSupplier(supplierId: $supplierId) {
            updatedSupplier {
                _id
                state
                stateHistory {
                    createdAt
                    createdBy
                    state
                }
            }
            error
        }
    }
`;

export const UPDATE_SUPPLIER_MUTATION = gql`
    mutation UPDATE_SUPPLIER($supplierId: ID!, $fields: SupplierFormInput!) {
        updateSupplier(supplierId: $supplierId, fields: $fields) {
            formErrors {
                ...FormErrorFragment
            }
            updatedSupplier {
                ...SupplierFragment
            }
            error
        }
    }
    ${SUPPLIER_FRAGMENT},
    ${FORM_ERROR_FRAGMENT}
`;

import React, { useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import styled from 'styled-components';

import { PAGES } from '../../constants/pages';

import { GET_THERMOMETERS_QUERY } from 'data/queries/thermometer';
import { GET_THERMOMETERS } from 'data/queries/__generated__/GET_THERMOMETERS';
import { UPDATE_THERMOMETER, UPDATE_THERMOMETERVariables } from 'data/mutations/__generated__/UPDATE_THERMOMETER';
import { UPDATE_THERMOMETER_MUTATION } from 'data/mutations/thermometer';
import { ThermometerUpdateInput } from 'data/__generated__';
import { GET_DELIVERY_PEOPLE } from 'data/queries/__generated__/GET_DELIVERY_PEOPLE';
import { GET_DELIVERY_PEOPLE_QUERY } from 'data/queries/user';

import { thermometersUpdatedOrCreatedHandler } from './cacheHandlers/thermometersCacheHandler';

import { Header, HeaderTitle } from 'components/Header';
import { FieldToDisplay, ListView } from 'components/ListView';
import { Loader } from 'components/Loader';
import { PageTitle } from 'components/PageTitle';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { CreateThermometerPopup } from './Popups/CreateThermometerPopup';
import { TotemCheckbox } from 'components/TotemCheckbox';
import { SelectedOption, TotemSelect } from 'components/TotemSelect';

type FormattedThermometerType = {
    _id: string;
    associatedUser: JSX.Element;
    isOperational: JSX.Element;
    number: number;
};

export const Thermometers = () => {
    const [isCreateThermometerPopupOpen, setIsCreateThermometerPopupOpen] = useState<boolean>(false);

    const {
        loading: thermometersLoading,
        data: thermometersData,
        error: thermometersError,
    } = useQuery<GET_THERMOMETERS>(GET_THERMOMETERS_QUERY);

    const {
        loading: deliveryPeopleLoading,
        data: deliveryPeopleData,
        error: deliveryPeopleError,
    } = useQuery<GET_DELIVERY_PEOPLE>(GET_DELIVERY_PEOPLE_QUERY);

    const [updateThermometer, { loading: updateLoading }] = useMutation<
        UPDATE_THERMOMETER,
        UPDATE_THERMOMETERVariables
    >(UPDATE_THERMOMETER_MUTATION);

    if ((thermometersLoading && !thermometersData) || (deliveryPeopleLoading && !deliveryPeopleData)) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (thermometersError || !thermometersData || deliveryPeopleError || !deliveryPeopleData) {
        throw new Error('Une erreur est survenue lors de la récupération des thermomètres');
    }

    const { getThermometers: thermometers } = thermometersData;
    const { getDeliveryPeople: deliveryPeople } = deliveryPeopleData;

    async function update({ _id, associatedUserId, isOperational }: ThermometerUpdateInput) {
        const { data } = await updateThermometer({
            variables: { thermometerUpdateInput: { _id, associatedUserId, isOperational } },
        });

        if (!data) {
            throw new Error('Une erreur est survenue lors de la création du thermomètre');
        }

        const { updateThermometer: newThermometer } = data;

        thermometersUpdatedOrCreatedHandler([newThermometer]);
    }

    const THERMOMETER_FIELDS_TO_DISPLAY: FieldToDisplay<FormattedThermometerType>[] = [
        {
            fieldName: 'number',
            label: 'Numéro',
        },
        {
            fieldName: 'associatedUser',
            label: 'Livreur',
        },
        {
            fieldName: 'isOperational',
            label: 'Opérationnel',
        },
    ];

    const sortedThermometers = [...thermometers].sort(
        (thermometerA, thermometerB) => thermometerA.number - thermometerB.number,
    );

    const nextThermometerNumber = (sortedThermometers?.[sortedThermometers.length - 1]?.number ?? 0) + 1;

    const deliveryPeopleOptions =
        deliveryPeople
            .map(({ _id, profile: { fullname }, username }) => ({
                value: _id,
                label: `${fullname} (${username})`,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)) || [];

    const formattedThermometers: FormattedThermometerType[] = sortedThermometers.map((thermometer) => {
        const selectedDeliveryPeopleOption = deliveryPeopleOptions.find(
            ({ value }) => value === thermometer.associatedUserId,
        );
        return {
            _id: thermometer._id,
            associatedUser: (
                <TotemSelect
                    placeholder="Sélectionner un livreur"
                    isClearable
                    isDisabled={updateLoading}
                    value={selectedDeliveryPeopleOption}
                    options={deliveryPeopleOptions}
                    onChange={(option: SelectedOption<string>) => {
                        update({ ...thermometer, associatedUserId: option?.value ?? null });
                    }}
                />
            ),
            isOperational: (
                <TotemCheckbox
                    checked={thermometer.isOperational}
                    disabled={updateLoading}
                    onChange={() => update({ ...thermometer, isOperational: !thermometer.isOperational })}
                />
            ),
            number: thermometer.number,
        };
    });

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.thermometers} />
                </HeaderTitle>
                <ActionContainer>
                    <TotemPrimaryButton
                        onClick={() => {
                            setIsCreateThermometerPopupOpen(true);
                        }}
                    >
                        Ajouter un thermomètre
                    </TotemPrimaryButton>
                </ActionContainer>
            </Header>
            <Content>
                <ListView<FormattedThermometerType>
                    fieldsToDisplay={THERMOMETER_FIELDS_TO_DISPLAY}
                    items={formattedThermometers}
                    keyExtractor={(item) => item._id}
                />
            </Content>
            <CreateThermometerPopup
                nextThermometerNumber={nextThermometerNumber}
                isOpen={isCreateThermometerPopupOpen}
                setIsOpen={setIsCreateThermometerPopupOpen}
            />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    flex: 1;
    overflow: hidden;
`;

const ActionContainer = styled.div`
    display: flex;

    & > :not(:first-child) {
        margin-left: 10px;
    }
`;

export const DAYS_OF_THE_WEEK = [
    { label: 'Lundi', name: 'monday' },
    { label: 'Mardi', name: 'tuesday' },
    { label: 'Mercredi', name: 'wednesday' },
    { label: 'Jeudi', name: 'thursday' },
    { label: 'Vendredi', name: 'friday' },
    { label: 'Samedi', name: 'saturday' },
    { label: 'Dimanche', name: 'sunday' },
];

export const FRENCH_DAYS = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];

export const NEW_DAY_LABELS = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];

export const DAY_SELECT_OPTIONS = [
    { value: 1, label: 'Lundi' },
    { value: 2, label: 'Mardi' },
    { value: 3, label: 'Mercredi' },
    { value: 4, label: 'Jeudi' },
    { value: 5, label: 'Vendredi' },
    { value: 6, label: 'Samedi' },
    { value: 0, label: 'Dimanche' },
];

export function transformSundayFirstToMondayFirstDayIndex(dayIndex: number) {
    return (dayIndex + 6) % 7;
}

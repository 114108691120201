import gql from 'graphql-tag';
import { STOCK_VARIATION_FRAGMENT } from 'data/fragments/stockVariation';

export const GET_STOCK_VARIATIONS_FOR_DATE_QUERY = gql`
    query GET_STOCK_VARIATIONS_FOR_DATE($date: String!) {
        getStockVariationsForDate(date: $date) {
            ...StockVariationFragment
        }
    }
    ${STOCK_VARIATION_FRAGMENT}
`;

import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { GET_MICROSTORE_SITES_WITH_LOCATIONS_INFO_QUERY } from 'data/queries/site';
import { GET_ALL_PRODUCTS_QUERY } from 'data/queries/product';

import { Loader } from 'components/Loader';
import {
    GET_MICROSTORE_SITES_WITH_LOCATIONS_INFO,
    GET_MICROSTORE_SITES_WITH_LOCATIONS_INFOVariables,
} from 'data/queries/__generated__/GET_MICROSTORE_SITES_WITH_LOCATIONS_INFO';
import { GET_ALL_PRODUCTS } from 'data/queries/__generated__/GET_ALL_PRODUCTS';
import { PlanogramForm } from './PlanogramForm';
import { GET_SITE_LOCATION_PLANOGRAMS_QUERY } from 'data/queries/locationPlanogram';
import {
    GET_SITE_LOCATION_PLANOGRAMS,
    GET_SITE_LOCATION_PLANOGRAMSVariables,
} from 'data/queries/__generated__/GET_SITE_LOCATION_PLANOGRAMS';
import { LocationPlanogramFragment } from 'data/fragments/__generated__/LocationPlanogramFragment';

type ParamTypes = {
    siteId: string;
};

const tomorrowDate = new Date();
tomorrowDate.setDate(tomorrowDate.getDate() + 1);

export const MicrostorePlanogram = () => {
    const { siteId = '' } = useParams<ParamTypes>();

    const {
        loading: microstoreSitesLoading,
        data: microstoreSitesData,
        error: microstoreSitesError,
    } = useQuery<GET_MICROSTORE_SITES_WITH_LOCATIONS_INFO, GET_MICROSTORE_SITES_WITH_LOCATIONS_INFOVariables>(
        GET_MICROSTORE_SITES_WITH_LOCATIONS_INFO_QUERY,
        {
            variables: {
                siteIds: [siteId],
            },
        },
    );

    const {
        loading: locationPlanogramsLoading,
        data: locationPlanogramsData,
        error: locationPlanogramsError,
    } = useQuery<GET_SITE_LOCATION_PLANOGRAMS, GET_SITE_LOCATION_PLANOGRAMSVariables>(
        GET_SITE_LOCATION_PLANOGRAMS_QUERY,
        {
            variables: {
                siteId,
            },
        },
    );

    const {
        loading: productsLoading,
        data: productsData,
        error: productsError,
    } = useQuery<GET_ALL_PRODUCTS>(GET_ALL_PRODUCTS_QUERY, {
        variables: {
            siteId,
        },
        fetchPolicy: 'no-cache',
    });

    if (microstoreSitesLoading || productsLoading || locationPlanogramsLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (
        microstoreSitesError ||
        !microstoreSitesData ||
        productsError ||
        !productsData ||
        locationPlanogramsError ||
        !locationPlanogramsData
    ) {
        throw new Error('Une erreur est survenue lors de la récupération du plano');
    }

    const { sites } = microstoreSitesData;
    const { getSiteLocationPlanograms: locationPlanograms } = locationPlanogramsData;
    const { getAllProducts: products } = productsData;

    const [site] = sites;

    const locationPlanogramsByDate: Record<string, LocationPlanogramFragment[]> = {};
    locationPlanograms.forEach((locationPlanogram) => {
        if (!locationPlanogramsByDate[locationPlanogram.startDate]) {
            locationPlanogramsByDate[locationPlanogram.startDate] = [];
        }
        locationPlanogramsByDate[locationPlanogram.startDate].push(locationPlanogram);
    });

    if (!site) {
        throw new Error('Une erreur est survenue lors de la récupération du plano');
    }

    return <PlanogramForm locationPlanogramsByDate={locationPlanogramsByDate} products={products} site={site} />;
};

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
    overflow: hidden;
`;

import React from 'react';
import styled from 'styled-components';
import { PageType } from 'constants/pages';

export const NavBarPageTitle = ({
    page,
    shouldDisplayShortLabel = false,
}: {
    page: PageType;
    shouldDisplayShortLabel?: boolean;
}) => {
    return (
        <PageTitleContainer>
            <IconContainer>{page.icon}</IconContainer>
            <PageTitleLabel>{shouldDisplayShortLabel ? page.shortLabel ?? page.label : page.label}</PageTitleLabel>
        </PageTitleContainer>
    );
};

const PageTitleContainer = styled.div`
    display: flex;
    align-items: center;
`;

const IconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const PageTitleLabel = styled.span`
    margin-left: 10px;
`;

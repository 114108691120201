import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import {
    UPDATE_SITE_REASSORTMENT_QUANTITY_TARGETS_MUTATION,
    UPDATE_SITE_REASSORTMENT_QUANTITY_TARGET_RATIOS_MUTATION,
} from 'data/mutations/site';
import {
    UPDATE_SITE_REASSORTMENT_QUANTITY_TARGETS,
    UPDATE_SITE_REASSORTMENT_QUANTITY_TARGETSVariables,
} from 'data/mutations/__generated__/UPDATE_SITE_REASSORTMENT_QUANTITY_TARGETS';
import {
    SiteFragment_reassortmentStockTargetRatios,
    SiteFragment_reassortmentStockTargets,
} from 'data/fragments/__generated__/SiteFragment';
import {
    UPDATE_SITE_REASSORTMENT_QUANTITY_TARGET_RATIOS,
    UPDATE_SITE_REASSORTMENT_QUANTITY_TARGET_RATIOSVariables,
} from 'data/mutations/__generated__/UPDATE_SITE_REASSORTMENT_QUANTITY_TARGET_RATIOS';

import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { TotemInput } from 'components/TotemInput';
import { getNumericValue, stockTargetRatiosCheck } from 'pages/MicrostoreColumns/MicrostoreColumnDetails/utils';

export type ReassortmentStockTargetRatiosFormInput = {
    monday: string | number;
    tuesday: string | number;
    wednesday: string | number;
    thursday: string | number;
    friday: string | number;
};

export const SiteUpdateForm = ({
    initialReassortmentStockTargets,
    initialReassortmentOnboardingStockTargets,
    initialReassortmentStockTargetRatios,
    siteId,
}: {
    initialReassortmentStockTargets: SiteFragment_reassortmentStockTargets;
    initialReassortmentOnboardingStockTargets: SiteFragment_reassortmentStockTargets;
    initialReassortmentStockTargetRatios: SiteFragment_reassortmentStockTargetRatios;
    siteId: string;
}) => {
    const [reassortmentStockTargets, setReassortmentStockTargets] = useState<SiteFragment_reassortmentStockTargets>(
        initialReassortmentStockTargets,
    );
    const [reassortmentOnboardingStockTargets, setReassortmentOnboardingStockTargets] =
        useState<SiteFragment_reassortmentStockTargets>(initialReassortmentOnboardingStockTargets);

    useEffect(() => {
        setReassortmentStockTargets(initialReassortmentStockTargets);
        setReassortmentOnboardingStockTargets(initialReassortmentOnboardingStockTargets);
    }, [initialReassortmentStockTargets, initialReassortmentOnboardingStockTargets, siteId]);

    const [reassortmentStockTargetRatios, setReassortmentStockTargetRatios] =
        useState<ReassortmentStockTargetRatiosFormInput>(initialReassortmentStockTargetRatios);

    useEffect(() => {
        setReassortmentStockTargetRatios(initialReassortmentStockTargetRatios);
    }, [initialReassortmentStockTargetRatios, siteId]);

    const isStockTargetFormDirty =
        reassortmentStockTargets.dessert !== initialReassortmentStockTargets.dessert ||
        reassortmentStockTargets.main !== initialReassortmentStockTargets.main ||
        reassortmentStockTargets.smallBites !== initialReassortmentStockTargets.smallBites ||
        reassortmentStockTargets.starter !== initialReassortmentStockTargets.starter;

    const isOnboardingStockTargetFormDirty =
        reassortmentOnboardingStockTargets.dessert !== initialReassortmentOnboardingStockTargets.dessert ||
        reassortmentOnboardingStockTargets.main !== initialReassortmentOnboardingStockTargets.main ||
        reassortmentOnboardingStockTargets.smallBites !== initialReassortmentOnboardingStockTargets.smallBites ||
        reassortmentOnboardingStockTargets.starter !== initialReassortmentOnboardingStockTargets.starter;

    const isStockTargetRatiosFormDirty =
        reassortmentStockTargetRatios.monday !== initialReassortmentStockTargetRatios.monday ||
        reassortmentStockTargetRatios.tuesday !== initialReassortmentStockTargetRatios.tuesday ||
        reassortmentStockTargetRatios.wednesday !== initialReassortmentStockTargetRatios.wednesday ||
        reassortmentStockTargetRatios.thursday !== initialReassortmentStockTargetRatios.thursday ||
        reassortmentStockTargetRatios.friday !== initialReassortmentStockTargetRatios.friday;

    const [updateSiteReassortmentStockTargets] = useMutation<
        UPDATE_SITE_REASSORTMENT_QUANTITY_TARGETS,
        UPDATE_SITE_REASSORTMENT_QUANTITY_TARGETSVariables
    >(UPDATE_SITE_REASSORTMENT_QUANTITY_TARGETS_MUTATION);

    const [updateSiteReassortmentStockTargetRatios] = useMutation<
        UPDATE_SITE_REASSORTMENT_QUANTITY_TARGET_RATIOS,
        UPDATE_SITE_REASSORTMENT_QUANTITY_TARGET_RATIOSVariables
    >(UPDATE_SITE_REASSORTMENT_QUANTITY_TARGET_RATIOS_MUTATION);

    const handleStockTargetsUpdate = async () => {
        // eslint-disable-next-line
        const { __typename: reassortmentTypename, ...reassortmentStockByCategoriesInput } = reassortmentStockTargets;
        const { __typename: reassortmentOnboardingTypename, ...reassortmentOnboardingStockByCategoriesInput } =
            reassortmentOnboardingStockTargets;
        const { data } = await updateSiteReassortmentStockTargets({
            variables: {
                siteId,
                reassortmentStockTargets: reassortmentStockByCategoriesInput,
                reassortmentOnboardingStockTargets: reassortmentOnboardingStockByCategoriesInput,
            },
        });
        if (!data) {
            toast.error('Une erreur inconnue est survenue lors de la modification du site');
            return;
        }

        const {
            updateSiteReassortmentStockTargets: { error, success },
        } = data;

        if (!success) {
            toast.error(`L'erreur suivante est survenue : "${error}"`);
        } else {
            toast.success('Le site a bien été modifié');
        }
    };

    const handleStockTargetRatiosUpdate = async () => {
        const stockTargetRatiosError = stockTargetRatiosCheck(reassortmentStockTargetRatios);
        if (stockTargetRatiosError) {
            toast.warning(stockTargetRatiosError);
            return;
        }

        const { monday, tuesday, wednesday, thursday, friday } = reassortmentStockTargetRatios;

        // eslint-disable-next-line
        const { data } = await updateSiteReassortmentStockTargetRatios({
            variables: {
                siteId,
                reassortmentStockTargetRatios: {
                    monday: getNumericValue(monday),
                    tuesday: getNumericValue(tuesday),
                    wednesday: getNumericValue(wednesday),
                    thursday: getNumericValue(thursday),
                    friday: getNumericValue(friday),
                },
            },
        });
        if (!data) {
            toast.error('Une erreur inconnue est survenue lors de la modification du site');
            return;
        }

        const {
            updateSiteReassortmentStockTargetRatios: { error, success },
        } = data;

        if (!success) {
            toast.error(`L'erreur suivante est survenue : "${error}"`);
        } else {
            toast.success('Le site a bien été modifié');
        }
    };

    return (
        <Container>
            <StockTargetContainer>
                <div>
                    <Title>Consommation quotidienne moyenne manuelle (à mettre à la main pour l'onboarding)</Title>
                    <InputsContainer>
                        <TotemInput
                            label="Entrées"
                            type="number"
                            value={reassortmentOnboardingStockTargets.starter}
                            onChange={(value) => {
                                const newStarter = parseInt(value) || 0;
                                setReassortmentOnboardingStockTargets({
                                    ...reassortmentOnboardingStockTargets,
                                    starter: newStarter,
                                });
                            }}
                            step="1"
                            min="0"
                            required={true}
                        />
                        <TotemInput
                            label="Petites faims"
                            type="number"
                            value={reassortmentOnboardingStockTargets.smallBites}
                            onChange={(value) => {
                                const newSmallBites = parseInt(value) || 0;
                                setReassortmentOnboardingStockTargets({
                                    ...reassortmentOnboardingStockTargets,
                                    smallBites: newSmallBites,
                                });
                            }}
                            step="1"
                            min="0"
                            required={true}
                        />
                        <TotemInput
                            label="Plats"
                            type="number"
                            value={reassortmentOnboardingStockTargets.main}
                            onChange={(value) => {
                                const newMain = parseInt(value) || 0;
                                setReassortmentOnboardingStockTargets({
                                    ...reassortmentOnboardingStockTargets,
                                    main: newMain,
                                });
                            }}
                            step="1"
                            min="0"
                            required={true}
                        />
                        <TotemInput
                            label="Désserts"
                            type="number"
                            value={reassortmentOnboardingStockTargets.dessert}
                            onChange={(value) => {
                                const newDessert = parseInt(value) || 0;
                                setReassortmentOnboardingStockTargets({
                                    ...reassortmentOnboardingStockTargets,
                                    dessert: newDessert,
                                });
                            }}
                            step="1"
                            min="0"
                            required={true}
                        />
                    </InputsContainer>
                </div>
                <TotemPrimaryButton
                    type="button"
                    disabled={!isStockTargetFormDirty && !isOnboardingStockTargetFormDirty}
                    onClick={handleStockTargetsUpdate}
                >
                    Sauvegarder
                </TotemPrimaryButton>
            </StockTargetContainer>
            <div>
                <Title>
                    Ratio de consommation de 0 (rien consommé) à 1 (consommation max) (à mettre à la main pour
                    l'onboarding)
                </Title>
                <InputsContainer>
                    <TotemInput
                        label="Lundi"
                        type="number"
                        value={reassortmentStockTargetRatios.monday}
                        onChange={(value) => {
                            setReassortmentStockTargetRatios({ ...reassortmentStockTargetRatios, monday: value });
                        }}
                        min="0"
                        required={true}
                    />
                    <TotemInput
                        label="Mardi"
                        type="number"
                        value={reassortmentStockTargetRatios.tuesday}
                        onChange={(value) => {
                            setReassortmentStockTargetRatios({ ...reassortmentStockTargetRatios, tuesday: value });
                        }}
                        min="0"
                        required={true}
                    />
                    <TotemInput
                        label="Mercredi"
                        type="number"
                        value={reassortmentStockTargetRatios.wednesday}
                        onChange={(value) => {
                            setReassortmentStockTargetRatios({ ...reassortmentStockTargetRatios, wednesday: value });
                        }}
                        min="0"
                        required={true}
                    />
                    <TotemInput
                        label="Jeudi"
                        type="number"
                        value={reassortmentStockTargetRatios.thursday}
                        onChange={(value) => {
                            setReassortmentStockTargetRatios({ ...reassortmentStockTargetRatios, thursday: value });
                        }}
                        min="0"
                        required={true}
                    />
                    <TotemInput
                        label="Vendredi"
                        type="number"
                        value={reassortmentStockTargetRatios.friday}
                        onChange={(value) => {
                            setReassortmentStockTargetRatios({ ...reassortmentStockTargetRatios, friday: value });
                        }}
                        min="0"
                        required={true}
                    />
                    <TotemPrimaryButton
                        type="button"
                        disabled={!isStockTargetRatiosFormDirty}
                        onClick={handleStockTargetRatiosUpdate}
                    >
                        Sauvegarder
                    </TotemPrimaryButton>
                </InputsContainer>
            </div>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const InputsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    margin-top: 20px;

    & > :not(:first-child) {
        margin-left: 20px;
    }
`;

const StockTargetContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    gap: 30px;
`;

const Title = styled.div`
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    margin-top: 5px;
`;
